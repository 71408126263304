import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-newcontacttype',
  templateUrl: './newcontacttype.component.html',
  styleUrls: ['./newcontacttype.component.css']
})
export class NewcontacttypeComponent extends BaseComponent implements OnInit {
  contact_type_name;
  contact_type_description;
  contact_type_parent_id;
  isactive;
  contact_type_id;
  contacttypeObj;
  status: boolean = true;
  submitted = false;
  AddContact: FormGroup;
  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    ) {
      super();
     }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.contact_type_id = params.contact_type_id;
      });
    //get item details using id
    if (this.contact_type_id) {
      this.ngxSpinnerService.show();
      this.master.getContacttypeById(this.contact_type_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.contacttypeObj = response.data;
          this.ngxSpinnerService.hide();
         // console.log(self.contacttypeObj)
          self.dataBinding();
        } else {

        }
      });
    }
    this.AddContact = this.formBuilder.group({
      contact_type_name: ['',[Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      contact_type_parent_id: [0],
      contact_type_description:[],
    });
  }
  get f() { return this.AddContact.controls; }
  addNewContacttype() {
    this.submitted = true;
    if (this.AddContact.invalid) {
      return;
    }
    let cn = this.AddContact.value.contact_type_name.trim();
    if (cn === '' || cn === null || cn === undefined){
      this.toastrService.error('Please provide valid Contact Type Name');
      return;
    }
    let contacttypeObj = {
      name: this.AddContact.value.contact_type_name.trim(),
      description: this.AddContact.value.contact_type_description,
      contact_type_parent_id: 0,
      isactive: this.isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingContacttype(contacttypeObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/contacttype`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    // this.contact_type_name = this.contacttypeObj.contact_type_name;
    // this.contact_type_description = this.contacttypeObj.contact_type_description;
    // this.contact_type_parent_id = this.contacttypeObj.contact_type_parent_id;
    // this.isactive = this.contacttypeObj.isactive;
    this.AddContact = this.formBuilder.group({
      contact_type_name: [this.contacttypeObj.contact_type_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      contact_type_description: [this.contacttypeObj.contact_type_description],
      contact_type_parent_id:[this.contacttypeObj.contact_type_parent_id]
    });this.isactive = this.contacttypeObj.isactive;
  }
  updateContacttype() {
    this.submitted = true;
    if (this.AddContact.invalid) {
      return;
    }
    let cn = this.AddContact.value.contact_type_name.trim();
    if (cn === '' || cn === null || cn === undefined){
      this.toastrService.error('Please provide valid Contact Type Name');
      return;
    }
      let contacttypeObj = {
        contact_type_name: this.AddContact.value.contact_type_name.trim(),
        contact_type_description: this.AddContact.value.contact_type_description,
        contact_type_parent_id: this.AddContact.value.contact_type_parent_id,
        isactive: this.isactive,
        contact_type_id: this.contact_type_id
      }
      this.ngxSpinnerService.show();
      this.master.UpdateContacttype(contacttypeObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/contacttype`);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }
    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }

}



