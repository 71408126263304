import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-attribute-operator-association',
  templateUrl: './attribute-operator-association.component.html',
  styleUrls: ['./attribute-operator-association.component.css']
})
export class AttributeOperatorAssociationComponent extends BaseComponent implements OnInit{

  attrid = '';
  attributes = [];
  fullAssocList = [];
  operatorsObj = [];
  operatorList = [];
  limit = 9999;
  offset = 0;
  show = false;
  constructor (private segmentService: SegmentationService) {
    super();
  }
  ngOnInit() {

    this.getAttributesList();
    this.getList();
    this.getAllAttributeOperatorAssociationList();
  }

  //  ################  Get Full List of Attribute and Operator Association  ######  //

  getAllAttributeOperatorAssociationList() {
    this.segmentService.getAllAttributeOperatorAssoc().subscribe(data => {

      if (data.status) {
        this.fullAssocList = data.data;
      } else  {
        this.fullAssocList = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });
  }

  //  ###############  Get Attributes List  #######  //

  getAttributesList() {
    this.ngxSpinnerService.show();
    this.segmentService.getSegementAttributeList(this.limit, this.offset, 'createdon', 'desc').subscribe(data => {
      if (data.status) {
        this.attributes = data.data.rows;
      } else {
        this.attributes = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error);
    });
  }

  //  ###############  Get Operators List  #######  //

  getList(){
    this.segmentService.getOperatorList(this.limit, this.offset, 'createdon', 'desc').subscribe(data => {
      if (data.status){
        this.operatorList = data.data.rows;
      } else {
        this.operatorList = [];
      }
    }, error => {
      this.toastrService.error(error);
    });
  }

  //  #############  Get Associated Operators on Attribute selected from Dropdown  ##########  //

  getAssociatedOperators(id){
    this.show = false;
    this.fullAssocList = [];
    this.operatorsObj = [];
    if (id === '') {
      this.getAllAttributeOperatorAssociationList();
      return;
    }

    this.ngxSpinnerService.show();
    this.segmentService.getAssociatedOperatorsForAttributes(id).subscribe(data => {
      if (data.status) {
        let arr = data.data;
        if (arr.length > 0) {
          for (let obj of data.data) {
            let val = this.operatorList.filter(elem => elem.operator_id === obj.operator_id);
            this.operatorsObj.push(val[0]);
          }
        }
      } else  {
        this.toastrService.error(data.message);
      }
      this.show = true;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###########  Edit Association (Redirect to Assoc. Add Page)   ########  //

  editAssociation(){
    let URL = '/corporate/add-attribute-operator-association';


    this.router.navigate([URL], { queryParams: {'assocId': this.attrid}});

  }

  //  ###########  Edit Association (Redirect to Assoc. Add Page)   ########  //

  editAssociationById(id){
    let URL = '/corporate/add-attribute-operator-association';


    this.router.navigate([URL], { queryParams: {'assocId': id}});

  }

}
