import { Component, OnInit ,Input,PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-cb-form-preview',
  templateUrl: './cb-form-preview.component.html',
  styleUrls: ['./cb-form-preview.component.css']
})
export class CbFormPreviewComponent implements OnInit,PipeTransform{

  @Input() childFormData: string;
  

  constructor(private cb: CbService,
  private sanitized: DomSanitizer,
  private ngxSpinnerService: NgxSpinnerService) { }

  templatePreview;

  ngOnInit() {
    //console.log(this.childFormData)
    this.templatePreview=this.childFormData['previewHTML'];
  }
    //Bypass HTML Security - Load HTML with styles
    transform(value) {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }

  

}
