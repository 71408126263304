import {BaseComponent} from '../../base.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {McConnectorService} from '../../../services/mcconnector/mc-connector.service';

@Component({
  selector: 'app-mc-data-extension-fields',
  templateUrl: './mc-data-extension-fields.component.html',
  styleUrls: ['./mc-data-extension-fields.component.css']
})
export class McDataExtensionFieldsComponent extends BaseComponent implements OnInit, OnDestroy{

  dataextensionfieldform: FormGroup;
  submitted = false;
  dataId: any;
  companyId: any;
  mcData: any;
  connectionStatus: any;


  mcDataExtension = [];

  selectedDataExtension: any;

  selectedAttributes = [];
  subscription: any;
  constructor(private formBuilder: FormBuilder, private mcService: McConnectorService) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
    this.checkMCInformationData();
  }

  ngOnInit() {
    this.createForm('');

  }

  //  ##############  MC Mapping form  ##############  //

  createForm(extid) {
    this.dataextensionfieldform = this.formBuilder.group({
      dataExtension: [extid, Validators.required],
      contact_fname: ['', Validators.required],
      contact_mname: [''],
      contact_lname: ['', Validators.required],
      contact_phone: [''],
      contact_phone_other: [''],
      contact_phone_type: [''],
      contact_email: ['', Validators.required],
      contact_email1: [''],
      contact_email2: [''],
      contact_birthdate: [''],
      contact_age_range: [''],
      contact_sex: [''],
      loyalty_type: [''],
      contact_address: [''],
      contact_address_2: [''],
      contact_country: [''],
      contact_state: [''],
      contact_city: [''],
      contact_zip: [''],
      contact_region: ['']
    });
  }

  //  ##########  Check if MC Information is added for BU or not #############  //

  checkMCInformationData() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcInfoData(this.companyId, 10, 0).subscribe(data => {
      if (data.status && data.data.length > 0) {
        this.mcData = data.data[0];
        this.checkTokenUrl();
      } else {
        this.toastrService.error(data.message);
        this.router.navigateByUrl('/corporate/mc-information');
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##########  Check Connectivity with Current Data  ##   //

  checkTokenUrl() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcTokenURL(this.companyId).subscribe(data => {
      if (data.status) {
        this.connectionStatus = true;
        this.getExtensionData();
      } else {
        this.toastrService.error("Can't connect with provided Credentials");
        this.connectionStatus = false;
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/mc-information');
        // this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/mc-information');
    });
  }

  getExtensionData() {
    this.ngxSpinnerService.show();
    this.mcService.getDataExtensionMapping(this.companyId).subscribe(data => {
      if (data.status) {
        this.mcDataExtension = data.data.dataExtension;

        this.checkMcMappingData();

      } else {
        this.mcDataExtension = [];
        this.selectedDataExtension = {};

        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
        // this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #################  Check for Mapping Data ###############  //

  checkMcMappingData() {
    this.mcService.checkDataExtensionMapping(this.companyId).subscribe(data => {

      if (data.status) {
         let ext = this.mcDataExtension.filter(element => element.CustomerKey === data.data[0].extention_id);
        if (ext.length > 0) {
          this.dataextensionfieldform.get('dataExtension').patchValue(data.data[0].extention_id);
         // this.patchMappingValues(data.data);
          this.selectDataExtensionFromDropdown(data.data[0].extention_id, true, data.data);
        }

       // this.selectDataExtensionFromDropdown(this.dataextensionfieldform.value.dataExtension);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############  Patch Mapped Values  #########################  //

  patchMappingValues(mapDataArray) {
    for (let obj of mapDataArray) {
      this.dataextensionfieldform.get(obj.margo_attribute_name).patchValue(obj.mc_attribute_id);
    }
  }

  //  ############  On Extension selected from Dropdown  #########  //

  selectDataExtensionFromDropdown(ckey, updateStatus, mappedData) {
    this.selectedAttributes = [];
    this.createForm(ckey);
    if (ckey === '') {
      return;
    }
    this.ngxSpinnerService.show();
    this.mcService.getDataExtensionAttributesbyCustomerKey(ckey, this.companyId).subscribe(data => {
      if (data.status) {
        this.selectedAttributes = data.data.dataExtensionColumns;
        if (updateStatus) {
          this.patchMappingValues(mappedData);
        }
        this.ngxSpinnerService.hide();
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
        // this.toastrService.error(data.message);
      }
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  get f() { return this.dataextensionfieldform.controls; }

  //  #########  On Submit Data Extension Field Form  #############  //

  submit() {
    this.submitted = true;
    if (this.dataextensionfieldform.invalid) {

      return;
    }


    this.ngxSpinnerService.show();

    let attr = {};
    Object.keys(this.dataextensionfieldform.controls).forEach(key => {
      if (key !== 'dataExtension') {
        let tmp = {};
        if (this.dataextensionfieldform.get(key).value === '') {
          tmp[key] = ''; tmp['mc_attribute_name'] = ''; tmp['mc_attribute_key'] = '';
        } else {
          let oj = this.selectedAttributes.filter(elem => elem.ObjectID === this.dataextensionfieldform.get(key).value);
          tmp[key] = oj[0].ObjectID; tmp['mc_attribute_name'] = oj[0].Name; tmp['mc_attribute_key'] = oj[0].Name;
        }
        attr[key] = tmp;
      }
    });


    let ext = this.mcDataExtension.filter(element => element.CustomerKey === this.dataextensionfieldform.value.dataExtension);
    let obj = {
      company: this.companyId,
      attibute: attr,
      extention_id: this.dataextensionfieldform.value.dataExtension,
      extension_id_1: this.dataextensionfieldform.value.dataExtension,
      extention_name: ext[0].Name,
      status: true
    };

    this.mcService.addMCDataExtension(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Mapping Details Added Successfully');
        this.ngxSpinnerService.hide();

        this.router.navigateByUrl('/corporate/mc-dataextension');

      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
      }
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
    });


  }

  //  ###########  Cancel Mapping fields Updates ##  //

  cancelUpdateMapping() {
    this.router.navigateByUrl('/corporate/mc-dataextension');

  }

  //  ########### On Destroy #####################  //

  ngOnDestroy() {
   // this.subscription.unsubscribe();
  }


}
