<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>


<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/license">License</a></li>
    <li class="breadcrumb-item active">License Bundle Assign</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
  
 
  <select class="form-control formstyle" 
   (change)="loadBundle($event.target.value)" [(ngModel)]="selectedCompany">
   <option value="0">Please select company</option>
<option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
{{userObj.customer_company_name}}
</option>
</select>


</div>
</div>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>

  </div>
<div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Assign Bundle" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/license-bundle-assign">
  <!--  <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
    -->
  </div>
 
</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0"> 
    <tr>
      <!-- <th>Theme Id</th> -->
      <th>Full Name</th>
      <th>Email ID</th>
      <th>Bundle Name</th>    
      
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>

    <tr *ngFor="let obj of bundleData | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">
      <!-- <td>{{obj.get_master_module_item_fun.moduleitemid}}</td> -->
      <td >{{obj.user_fname}} {{obj.user_lname}}</td>     
      <td>{{obj.user_email_primary}}</td>
      <td  >{{obj.bundle_name}}</td>
     
      <td  width="5"  *ngIf="isEdit || isDelete">
        <div class="actBtn">        
        <span class="fa fa-trash mr-1 cursor"
        (click)="removeBundle(obj.user_ds_id)"placement="bottom" *ngIf="isDelete" ngbTooltip="Remove"></span>
       
      </div>
      </td>
    </tr>


</table>
<pagination-controls class="my-pagination mt-2" (pageChange)="cp=$event"></pagination-controls>
</div>

</div>