import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
   }

  getRoleAction: any = [];
  getModuleData: any = [];
  objRoleActionData: any = {};
  globalObjRoleObj: any = {};
  getRoleObj: any = [];
  obj ;
  isUpdatePermission=false;
  getPermissionObj;
  checkedPermissions:any=[];
  checkedpermissionString;
  showTabRole = false;
  aclStatus = false;
  moduleNameCollapse = false;

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getRole(0);
    this.RoleLogData()
    this.checkAcl()
  }
  RoleLogData() {
    let RoleLogs = {
      log_type:"Role View" ,
      log_name:"Role View" ,
      log_description:"Role View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(RoleLogs).subscribe(data => {
   //   console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
  }
  getRole(id) {
    let self = this;
    this.registerService.getRoleData2(id).subscribe(data => {
      self.getRoleObj = data.data;
      this.changeSuit(self.getRoleObj[0].role_id);
      this.ngxSpinnerService.hide();
      this.showTabRole = true;
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  getRoleActionFunction(pid, name,role) {
    this.ngxSpinnerService.show();
    let self = this;    
    
    this.registerService.getRoleAction(pid).subscribe(data => {
      let tempObj = {
        title: name,
        data: data
      }
     
      self.getRoleAction.push(tempObj);   

self.getRoleAction = self.getRoleAction.filter((item, index, self) =>
  index === self.findIndex((t) => (
    t.title === item.title 
  ))
)

      self.globalObjRoleObj[role] = self.getRoleAction;   
      this.ngxSpinnerService.hide();
    }, err => {
    //  console.log(err);
      this.ngxSpinnerService.hide();
    });
  }
  checkAcl() {
    let self = this;    
    this.registerService.checkAcl().subscribe(data => {
      if(data.status){
        this.aclStatus = true;
      }
    }, err => {
     // console.log(err);
    });
  }
  updateAcl() {
    this.ngxSpinnerService.show();
    let self = this;    
    this.registerService.updateAcl().subscribe(data => {
      this.ngxSpinnerService.hide();
      this.toastrService.success("Updated Successfully, please check after 5 min");
    }, err => {
     // console.log(err);
      this.ngxSpinnerService.hide();
    });
  }
  changeSuit(event) {
    let self = this;
    //console.log("changeSuit====",event,this.globalObjRoleObj[event]!=undefined)
    if(this.globalObjRoleObj[event]!=undefined){
      self.getRoleAction = this.globalObjRoleObj[event]
     
    }else{
      this.registerService.getRoleData2(event).subscribe(data => {
        self.obj = data.data;
        self.getRoleAction = [];     
        self.obj.forEach(element => {
          self.getRoleActionFunction(element.role_id, element.role_name,event);
        });
      }, error => {
      //  console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  }
  editpermision(objRoleAction,objRole,objChildRole,roleindex,actionindex)
  {  
    this.isUpdatePermission=true;
    this.objRoleActionData={}
    this.objRoleActionData.rolename=objRole.role_name;
    this.objRoleActionData.childrolename=objChildRole.title;
    this.objRoleActionData.modulename=objRoleAction.modulename;
    this.objRoleActionData.moduleitemname=objRoleAction.moduleitemname;
    this.checkedPermissions=this.objRoleActionData.permissionlist=objRoleAction.permission_list;
    this.objRoleActionData.araid=objRoleAction.araid;
    this.objRoleActionData.roleindex=roleindex;
    this.objRoleActionData.actionindex=actionindex;
    this.checkedpermissionString=this.checkedPermissions.toString();
  //  console.log(Array.isArray(this.checkedPermissions)); 
    this.getPermissions();
  }
  getPermissions() {
    let self = this;
    this.ngxSpinnerService.show();
    this.registerService.getPermissionData().subscribe(data => {
      self.getPermissionObj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  changeCheckForPermissions(item){ 
      var index = this.checkedPermissions.indexOf(item.toString()); 
      if (index > -1) { 
        this.checkedPermissions.splice(index, 1);
        this.checkedpermissionString=this.checkedPermissions.toString()
        }
        else{
          this.checkedPermissions.push(item)
        this.checkedpermissionString=this.checkedPermissions.toString()       
        }  
      //  console.log(this.checkedpermissionString);          
  }
  updateRoleAction() {
  //  console.log(this.checkedPermissions.length);
    if(this.checkedPermissions.length>0 )
    {
    let obj = {
      permissionlist:this.checkedpermissionString,
      id: this.objRoleActionData.araid
    }
    this.ngxSpinnerService.show();
    this.registerService.updateRoleAction(obj).subscribe(data => {
      if (data.status) {
      //  console.log(this.checkedpermissionString);
      //  console.log(this.getRoleAction[this.objRoleActionData.roleindex].data[this.objRoleActionData.actionindex].get_admin_role_action_fun.permission_list) ;
      this.getRoleAction[this.objRoleActionData.roleindex].data[this.objRoleActionData.actionindex].get_admin_role_action_fun.permission_list=this.checkedpermissionString;
    // console.log(this.getRoleAction[this.objRoleActionData.roleindex].data[this.objRoleActionData.actionindex].get_admin_role_action_fun.permission_list) ;
      this.toastrService.success("Updated Successfully");
      this.isUpdatePermission=false;
      } else {
        this.toastrService.error("Something went wrong");
      }
      this.ngxSpinnerService.hide();
    }, error => {
    // console.log(error);
      this.toastrService.error("Something went wrong");
      this.ngxSpinnerService.hide();
    });
  }
  else
  {
    this.toastrService.error("Please select atleast one Permistion.");
  }
  }
  deteteRoleAction(objRoleAction,roleindex,actionindex) {
    let obj = {
      id: objRoleAction.araid
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.registerService.deteteRoleAction(obj).subscribe(data => {
           // console.log(data)
            if (data.status) {
             this.getRoleAction[roleindex].data.splice(actionindex, 1);
             this.toastrService.success("Deleted Successfully");
            } else {
              this.toastrService.error("Something went wrong");
            }
            this.ngxSpinnerService.hide();
          }, error => {
          //  console.log(error);
            this.toastrService.error("Something went wrong");
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
