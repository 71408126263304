import {BaseComponent} from '../../../base.component';
import {Component, OnInit} from '@angular/core';
import {BUSegmentationService} from '../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-bu-attribute-segmentation',
  templateUrl: './bu-attributes-segmentation.component.html',
  styleUrls: ['./bu-attributes-segmentation.component.css']
})
export class BuAttributesSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  attributes = [];
  cp = 1;
  ord = true;
  colname: 'attributes_name';
  text = '';
  limit = 9999;
  offset = 0;

  constructor(private segmentService: BUSegmentationService) {
    super();
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.getBUAttributes();
  }

  //  ################  Get BU Attributes LIST  ####################  //

  getBUAttributes(){
    this.ngxSpinnerService.show();
    this.segmentService.getBUAttributesListing(this.buid, this.limit, this.offset).subscribe(data => {

      if (data.status) {
        this.attributes = data.data;
      } else  {
        this.attributes = [];
      }
      this.ngxSpinnerService.hide();
    }, error2 => {
     // console.log(error2);
      this.toastrService.error('Error!!. Please try again after some time');
      this.ngxSpinnerService.hide();
    });
  }

  chgord(col) {
    this.ord = !this.ord;
    this.colname = col;
  }

  //  #############  Update BU Attribute Status #################  //

  updateStatus(event, obj){
    event.preventDefault();
    let stat = 'Active';
    if (obj.isactive) {
      stat = 'InActive';
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status to ' + stat + '?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {

          let ob = {
            'company': this.buid,
            'id': obj.field_id,
            'status': !obj.isactive
          };

          this.segmentService.updateBUAttributeStatus(ob).subscribe(data => {
            if (data.status) {
              this.text = '';
              this.cp = 1;
              this.getBUAttributes();
              this.toastrService.success('Status Changed Successfully');
            } else {
              this.toastrService.error(data.message);
            }

          }, error2 => {
            this.toastrService.error(error2);
           // console.log(error2);
            this.ngxSpinnerService.hide();

          });
        }
      }).catch(() => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  #############  Delete BU Attribute #######################  //

  deleteAttribute(id){
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to the delete the selected Attribute?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.segmentService.deleteBUAttribute(id, this.buid).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Attribute Deleted Successfully');
              this.cp = 1;
              this.text = '';
              this.getBUAttributes();
            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          }, error2 => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
    //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ############ Edit BU Attribute ###########################  //

  editAttribute(attrid){

  }

}
