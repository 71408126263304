import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { BaseComponent } from '../../base.component';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-contbuild-cust-theme-list',
  templateUrl: './contbuild-cust-theme-list.component.html',
  styleUrls: ['./contbuild-cust-theme-list.component.css']
})

export class ContbuildCustThemeListComponent extends BaseComponent implements OnInit {
  select_themeObj;
  allTablesobj_new;any;
  selectedAll: any;
  customer_id;
  allTableFieldsobj;
  submitted = false;
  checkedAll=false;  
  theCheckbox = false;
  show: boolean= false;
  show4:boolean=false;
  fdstatus:boolean=false;
  checkedTable:any=[];
  checkedTheme:any=[];
  changeCheckFileSize= {};
   checkedDatatype = {};
  id:any;
  themeObj: any = [];
  cp = 1;
  ord = true;
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID ;
  fields:any=[];
  field_size:any=[];
  allTablesobj;
  getCompanyfields; 
  editmoduleObj;
  moduleObj;
  colname: "Name";
  custid="";
  themeid="";
  companylist;
  ompany_id:any;
  today: number = Date.now();
  theme_cb_id;
  select_company_id;
  contentdataForm: FormGroup;
  PageCollection:any = ['text','file','integer'];
  PageValidCollection:any = [{staus: 'require'}, {status: 'none'}];
  themefieldObj;//for edit Content Builder Fields
  selectedItem;
  contentForm: FormGroup;
  fstatus:boolean=false;
  constructor(   
    private formBuilder: FormBuilder,
    private master: MasterService,   
    private orderPipe: OrderPipe,   
    private cb: CbService,
    private clientService:ClientService) 
    { 
    
      super();   
    // this.getContentBuildItemsData();
     
    
   
    }

  ngOnInit() {
    this.getCompany();  
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));    
    this.companyID = this.currentUser.admin_customer_id; 
    this.contentForm = this.formBuilder.group({
      cb_field_name : ['',Validators.required],
      cb_field_type :['',Validators.required],
      cb_field_size : ['',Validators.required ],
      cb_field_value : ['',Validators.required ],
      cbtaid : ['',Validators.required],
      customerId:['',Validators.required]

    });
          this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
          this.getContentBuildItemsData();
     
 
  }

  get f() { return this.contentForm.controls; }


  onchange(fieldname,value){

  //  alert(fieldname);
    //alert(value);
  }

  updateCBfield(){
       let dd = this.contentForm.value;
         this.cb.updateCBfield(dd).subscribe(res => {
          //  console.log('req completed', res);
          });
  }
  changeCheckTheme(d) {  
    this.show = !this.show;  
    this.show4=false;

    let abc = d;
    var index = this.checkedTheme.indexOf(abc); 
    if (index > -1) { 
      this.checkedTheme.splice(index, 1);
     // console.log(this.checkedTheme);
     }
     else{    
       this.checkedTheme.push(abc)
      // console.log(this.checkedTheme);
     }     
  }

  /***********************************************************/
  gettingThemeFields(cid:any,d:any){
 // alert(d);
    let self = this;
    this.fstatus=false;
   this.themeid=d;
    var obj ={ themeid:d, dbType:environment.awsKey,customerId:cid};  
  // alert(obj.customerid);
    this.cb.gettingThemeFields(obj).subscribe(data => {
      self.getCompanyfields = data.data;
   //   alert(data);
    //  console.log(self.getCompanyfields);
    }, error => {
     // console.log(error);
    });
  }

  getContentBuildItemsData() {
    let self = this;
    var obj ={customerId:this.companyID};
   // this.ngxSpinnerService.show();
    this.cb.getContentBuildItems(obj).subscribe(data => {
   //   console.log(data.data);
      self.moduleObj = data.data;  
     // this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
    //  this.ngxSpinnerService.hide();
      
    });
  }

  /************************************************************/
  changeCheckTables(a) {    
    this.fdstatus=!this.fdstatus;  
    let abc = a;
    var index = this.checkedTable.indexOf(abc); 
    if (index > -1) { 
      this.checkedTable.splice(index, 1);
    //  console.log(this.checkedTable);
     }
     else{       
       this.checkedTable.push(abc); 
     //  console.log(JSON.stringify(this.checkedTable));
     }     
  }

  

  /************************New***************************/
  gettingContentBuilderFields() {   
     let self = this;
      this.cb.gettingContentBuilderFields().subscribe(data => {
        self.allTablesobj = data.data;
       // console.log(data.data); 
        }, error => {
      //  console.log(error);
      });
    }
    
   
    getTheme() {
      let self = this;
      this.ngxSpinnerService.show();
      this.master.getTheme(this.companyID).subscribe(data => {
        self.themeObj = data;
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  
    getCompanyThemes(id){
      //event.preventDefault();
      this.show4=false;
      this.companyID =id;
    //  console.log("company themes:"+ id);
      let self = this;
      this.ngxSpinnerService.show();
      this.master.getCompanyTheme(id).subscribe(data => {
        self.themeObj = data.data.rows;
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
  
    }
    getSelectCompanyThemes(id){     
    
      var obj ={customerId:id,companyid:id};
    //  console.log("company themes:"+ id);     
      let self = this;
     // alert('yes');
      this.ngxSpinnerService.show();       
      this.cb.getSelectCompanyThemes(obj).subscribe(data => {
        self.select_themeObj = data.data;
        this.select_company_id =id;
        this.ngxSpinnerService.hide();
      }, error => {
      //  console.log(error);
     //   this.ngxSpinnerService.hide();
      });
  
    }
   

    gettingFieldsAttribute(cid,id){
      this.theme_cb_id =id;
    //  console.log("theme_cb_id:"+ id);     
      let self = this;
      this.show4=true;
     
    //  this.ngxSpinnerService.show();
      this.cb.gettingFieldsAttribute(cid,id).subscribe(data => {
        self.themefieldObj = data.data;
        this.selectedItem =  data.data[0].cb_field_value;
        this.contentForm.get("cb_field_name").setValue(data.data[0].cb_field_name);
        this.contentForm.get("cb_field_type").setValue(data.data[0].cb_field_type);
        this.contentForm.get("cb_field_size").setValue(data.data[0].cb_field_size);
        this.contentForm.get("cbtaid").setValue(data.data[0].cbtaid);
        this.contentForm.get("customerId").setValue(data.data[0].customer_id);
        this.contentForm.get("cb_field_value").setValue(data.data[0].cb_field_value);
     //  alert(this.selectedItem);
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }    
    
    addCBThemeFields(customer_id:any,theme_id:any){        
      let self = this;  
      this.customer_id =   customer_id;
      this.themeid=theme_id;
      var data ={customer_id:customer_id,themeid:theme_id,customerId:customer_id};
    //  this.ngxSpinnerService.show();
      this.cb.addCBThemeFields(data).subscribe(data => {
       this.fstatus=true;
      self.allTablesobj_new = data.data;
     // console.log(this.allTablesobj);
   //   this.ngxSpinnerService.hide();
      }, error => {
      //  console.log(error);
        this.ngxSpinnerService.hide();
      });
    }

    addCBField(col_name){     
      let abc = col_name;
      var index = this.checkedTable.indexOf(abc); 
      if (index > -1) { 
      this.checkedTable.splice(index, 1);
   //   console.log(this.checkedTable);
     }
     else{ 
       this.checkedTable.push(abc);     
       //console.log(JSON.stringify(this.checkedTable));
     }
    
     }

     changeCheckField(k1,v1) {    
      let key = k1;
      let value=v1;
      this.changeCheckFileSize[key] = value;
    }
   
    changeCheckDataType(key2,value2) {    
      let key = key2;
      let value=value2;
      this.checkedDatatype[key] = value;  
    }

    deletefield(customerid,id){     
     // console.log("cbtaid:"+ id);     
      let self = this;     
      this.ngxSpinnerService.show();
      this.cb.deletefield(customerid,id).subscribe(data => {
     
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });

    }

    getCompany() {     
      let self = this;
      this.clientService.getBUCompanies().subscribe(data => {
        self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
      }, error => {
       // console.log(error);
      });
    }

    addingCBField(cid,tid){
     //// alert('hi1');
      if (this.checkedTable.length>0) {      
       var obj ={         
         theme_id:tid,
         selectfields:this.checkedTable,
         selectedDatatype:this.checkedDatatype,
         selectedFileSize:this.changeCheckFileSize,      
         dbType:environment.awsKey,
         customerId:cid
             }
           //  alert('hi2');
        this.cb.addingNewThemeData(obj).subscribe(data => {
               if (data.status) {
                 this.toastrService.success("Record Inserted successfully");
                 this.router.navigateByUrl(`/corporate/contentbuilder-view`);                 
               } else {
                 this.toastrService.error(data.message)
               }
             }, error => {
               this.toastrService.error(error.message)
             });
           } else {
            
           }
     }
   
    
}
