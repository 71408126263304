<div class="container" xmlns="http://www.w3.org/1999/html">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/campaign-builder"> Campaign Builder</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Schedule Campaign 
      </li>
    </ol>
  </nav>
</div>
<div class="container mt-1 mt-d-0">
  <h5 class="font-weight-bold mt-4 mb-4">Schedule Campaign</h5>

  <!-- Panel 1 (For Product Listing)  -->

  <div class="mt-1 mt-md-0" *ngIf="panel === 1">

    <h4 class="mainHeading_1 mb-1" *ngIf="prodList"></h4>
    <div class="row">
      <div class="col-2 ml-1 mt-2">
        <h6>Products List</h6>
      </div>
      <div class="col-5">
        <input class="form-control" type="text" placeholder="Search" [formControl]="queryField" aria-label="Search" [(ngModel)]="text">
      </div>
    </div>
    <div class="row mt-2 offset-3" *ngIf="prodList?.length === 0">
      <p>No Result Available</p>
    </div>
    <div class="row mt-2" *ngIf="prodList && prodList?.length > 0">
      <div class="col-md-offset-1"></div>

      <div class="col-md-4 col-xl-3 col-sm-6" *ngFor="let item of prodList| paginate:{id: 'prodlist', totalItems: totProds, itemsPerPage: 10,currentPage:cp }">

        

        <div class="card cursor-pointer" >
          <div class="card-content text-center" aria-expanded="true">
            <div class="card-body productImgHt p-1">
              <img src="{{item.product_image}}" height="250px" (error)="setValue($event)" />
            </div>
          </div>
          <div class="card-footer text-center pb-1 pt-1">
            <span class="d-block limitLetter">{{item.product_name}}</span>
            <a href="javascript:void(0)"
               (click)="showProductInModal(productInformation,item)">QUICK VIEW</a>
          </div>
        </div>
        <div class="text-center">
          <label class="custRadio">
            <input type="radio" id="customRadio2" [(ngModel)]="selectedProdId" name="selectedProduct" [checked]="selectedProdId === item.product_id"
                   [value]="item.product_id">
            <span class="radiomark"></span>
          </label>
        </div>
      </div>
      
    </div>

    <div class="mt-2 text-center difPagination" *ngIf="totProds > 10">

      <ngb-pagination [collectionSize]="totProds" [(page)]="cp" [maxSize]="2" [pageSize]="12" [rotate]="true" (pageChange)="getProds(cp=$event)" aria-label="Custom pagination">

        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>
    <ng-template #productInformation let-modal>
      <div class="scroller">
        <div class="wholePop filtMain">

          <div class="modal-header dialHead w-100">
            <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
            <h4 class="modal-title mb-0 float-left">QUICK VIEW</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="clearfix"></div>
          </div>

          <div class="modal-body">
            <div class="scroller1">

              <div class="row ">
                <div class="col-sm-6 text-center popDialImg" *ngIf = "productForModal">
                  <img src="{{productForModal.product_image}}" width="200px" (error)="setValue($event)"/>
                </div>
                <div class="col-sm-6 text-center text-sm-left mt-1 mt-sm-0">
                  <h5 *ngIf = "productForModal" class="font-weight-bold mb-0">{{productForModal.product_name}}</h5>
                  <span>Item: # 123434</span>
                  <div class="top">
                    <h6 *ngIf = "productForModal" class="">{{productForModal.product_description}}</h6>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="d-block text-center modal-footer border-0">
            <button type="button" class="btn btn-margo-action btn-round" *ngIf = "productForModal" (click)="modal.close('Save click')" >CLOSE</button>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
          </div> -->
        </div>
      </div>
    </ng-template>

    <div class="card-footer1 mt-3 mb-3">
      <div class="text-center my_class ">



        <button type="button" class="custbtn" (click)="goBackToEditCampaign()">Back</button>



        <button type="button" class="custbtn" (click)="goToPanel2From1()">Submit</button>
      </div>
    </div>

  </div>

  <!--  Panel 2 (For Theme Listing) -->
  <div class="row">
  <div class="col-12"  *ngIf="themeData &&  themeData.length>0 && panel === 2">

    <h5 class="subTitleHead_3">Select Theme</h5>

    <div class="row">

      <div class="top col-sm-6 col-md-4 col-xl-4" *ngFor="let item of themeData | orderBy:'theme_name'">

        <div class="card mb-1" >
          <div class="card-content text-center position-relative" aria-expanded="true" (click)="getLibraryData(libraryModal)">
            <div class="card-body themeImgHt">
              <div class="imgThemeCentr themeImglarge">
                <!-- (load)="onImageLoad($event, overlapImage,0)"  -->
                <span *ngIf="product_thumb_image"><img src="{{item.theme_screenshot}}" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                <span *ngIf="!product_thumb_image"><img src="{{item.theme_screenshot}}" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                <!-- <img class="overlap-image"   src="{{product_thumb_image}}"  #overlapImage (error)="setValue($event)"> -->
              </div>
            </div>

          </div>
          <div class="card-footer text-center">
            {{item.theme_name}}
          </div>
        </div>

        <ng-template #libraryModal let-modal>
          <div class="scroller">
            <div class="wholePop filtMain">
              <div class="modal-header dialHead w-100">
                <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{item.theme_name}}</h4>

              </div>
              <div class="modal-body">

                <div class="card mb-2 mb-md-0 ">

                  <div class="card-header text-center" id="container">
                    <div class="imgThemeCentr themeImglarge">
                      <span *ngIf="product_thumb_image"><img class="w-100" src="{{item.theme_screenshot}}" (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                      <span *ngIf="!product_thumb_image"><img class="w-100" src="{{item.theme_screenshot}}" (error)="setValue($event)" title="Click here to preview the theme"/></span>

                      <!-- <img class="overlap-image2"  src="{{product_thumb_image}}"  #overlapImagemodal  (error)="setValue($event)"> -->

                    </div>
                  </div>

                  <!-- <div class="card-header scrlCont" id="container">
                    <img src="{{item.theme_screenshot}}" (error)="setValue($event)" width="100%"/>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="text-center">
          <label class="custRadio">
            <input type="radio" id="customRadio1" [(ngModel)]="theme_id" name="selectedTheme" [checked]="theme_id === item.theme_id"
                   [value]="item.theme_id">
            <span class="radiomark"></span>
          </label>


        </div>
      </div>

    </div>
    <div class="card-footer1 mt-3 mb-3">
      <div class="text-center my_class ">


        <button type="button" class="custbtn" *ngIf="isprod === false" (click)="goBackToEditCampaign()">Back</button>
        <button type="button" class="custbtn" *ngIf="isprod === true" (click)="goToPanel1From2()">Back</button>


        <button type="button" class="custbtn" (click)="goToPanel3From2()">Submit</button>
      </div>
    </div>
  </div>
</div>

  <!---- Panel 3  --------- -->
<div class="row">
  <div class="col-12"  *ngIf=" panel === 3">

    <h5 class="mt-1 subTitleHead_3">Schedule Campaign {{this.campaignObj.camp_name}} for {{this.audData.aud_name}}</h5>
    <div class="row">
    <div class="col-xl-9 col-md-12 col-sm-12 ">

      <div class="card mt-1">

        <div class="card-body" [formGroup]="scheduleForm">
          <div class="row">


          <div class="col-md-3">
            <h6 class="font-weight-bold" for="scheduleForm">Select Schedule Type <span style="color:red">*</span></h6>
          </div>
          <div class="col-md-9 mt-1 mt-md-0">

            <div class="row">

            <div class="col-12">
              <p [ngClass]="{ 'is-invalid': submitted && f.scheduleType.errors }">
                <input type="radio" value="true" formControlName="scheduleType"> Schedule
                <input type="radio" value="false" formControlName="scheduleType" class="ml-2"> Run Once
              </p>
              <div *ngIf="submitted && f.scheduleType.errors" class="invalid-feedback">
                <div *ngIf="f.scheduleType.errors.required">Schedule Type is required</div>
              </div>

            </div>
            </div>
          </div>

      <div class="col-12" *ngIf="scheduleForm.value.scheduleType === 'true'">
        <div class="form-group row">


          <div class="col-md-3">
            <label>Schedule Date <span style="color:red">*</span></label>
          </div>
          <div class="col-md-9">
            <div class="row">


          <div class="col-lg-4 col-md-4 col-12 col-sm-3 schedCal">


            <input class="form-control" matInput [min]="minDate" [matDatepicker]="picker2" readonly
                   formControlName="scheduleStartDate"
                   [ngClass]="{ 'is-invalid': submitted && f.scheduleStartDate.errors }">
            <mat-datepicker-toggle matSuffix [for]="picker2" class="cldrIcon"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <div *ngIf="submitted && f.scheduleStartDate.errors" class="invalid-feedback">
              <div *ngIf="f.scheduleStartDate.errors.required">Start Date is required</div>
            </div>
          </div>

          <div class="widhtFd-150 ng-star-inserted d-flex justify-content-center atwtimeclass" *ngIf="scheduleForm.value.scheduleType === 'true'">
            <p class="atWTime">at</p>
          </div>

              <div class="col-lg-6 col-12 col-sm-5 scheduleTm d-flex justify-content-center">

                <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="stTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
              </div>

        </div>
      </div>

        </div>
      </div>

          <div class="col-12 mt-2" *ngIf="scheduleForm.value.scheduleType === 'true'">
            <div class="row">
              <div class="col-md-3">
                <label for="scheduleForm">Time Zone <span style="color:red">*</span></label>
              </div>
              <div class="col-md-7">
                <select formControlName="tzone" autocomplete="off" class="form-control formstyle " [ngClass]="{ 'is-invalid': submitted && f.tzone.errors }">
                  <option value="">Select Time Zone</option>
                  <option *ngFor="let tz of timezones" value="{{tz.value}}">{{tz.text}}</option>
                </select>
                <div *ngIf="submitted && f.tzone.errors" class="invalid-feedback">
                  <div *ngIf="f.tzone.errors.required">Time Zone is required</div>
                </div>
              </div>
            </div>



          </div>

          <div class="col-12" *ngIf="scheduleForm.value.scheduleType === 'false'">
            <p>*This Automation will run only once upon saving the campaign.</p>
          </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center my_class ">
            <button type="button" class="custbtn" (click)="goToPanel2From3()">Back</button>
            <button type="button" class="custbtn" (click)="gotToPanel4From3()">Submit</button>
          </div>
        </div>
        </div>
    </div>

    </div>

  </div>
</div>

  <!--  Panel 5 -->
<div class="row">
  <div class="col-xl-9"  *ngIf=" panel === 4">

    <h5 class="mt-1 subTitleHead_3">Campaign Manager</h5>

      <div class="card mt-1">
        <div class="card-body" >
          <div class="row">
            <div class="col-md-6">
              <p>Campaign Name: <b>{{this.campaignObj.camp_name}}</b></p>
              <p *ngIf="scheduleForm.value.scheduleType === 'true'">Schedule Date: <b>{{this.scheduleForm.value.scheduleStartDate | date: 'medium'}}</b></p>
              <p *ngIf="scheduleForm.value.scheduleType === 'false'">Schedule: <b>Run Once</b></p>
              <p *ngIf="this.campaignObj.camp_desc">Campaign Description:  <b>{{this.campaignObj.camp_desc}}</b></p>
              <p *ngIf="scheduleForm.value.scheduleType === 'true'">Timezone:  <b>{{selectedUTCtext}} ({{selectedUTC}})</b></p>
              <p *ngIf="this.audData.total_count">Contacts:  <b>{{this.audData.total_count}}</b></p>
              <p *ngIf="!this.audData.total_count">Contacts:  <span style="color: red">No Contacts in Selected Audience</span></p>
              <p>Journey Type:  <b>{{this.selectedJourney.journey_name}}</b></p>

            </div>
            <div class="col-md-6">
              <div class="row1" *ngIf="isprod === true">
                <p class="w-100">Product Name:  <b>{{selectedProduct.product_name}}</b></p>
                <p><img src="{{selectedProduct.product_image}}" style="width: 50%"></p>
              </div>

              <div class="row1" *ngIf="isprod === false">
                <p class="w-100">Theme Name:  <b>{{selectedTheme.theme_name}}</b></p>
                <p><img src="{{selectedTheme.theme_screenshot}}" style="width: 150px" (error)="setValue($event)"></p>
              </div>

              <div class="row1" *ngIf="isprod === true">

                <div class="card mb-1" >
                  <div class="card-content text-center position-relative" aria-expanded="true" (click)="getLibraryData(libraryModal)">
                    <div class="card-body themeImgHt">
                      <div class="imgThemeCentr themeImglarge">
                        <span *ngIf="selectedProduct.product_image_thumb"><img src="{{selectedTheme.theme_screenshot}}" (load)="onImageLoad($event, overlapImage,0)" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                        <span *ngIf="!selectedProduct.product_image_thumb"><img src="{{selectedTheme.theme_screenshot}}" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                        <img class="overlap-image"   src="{{selectedProduct.product_image_thumb}}"  #overlapImage (error)="setValue($event)" >
                      </div>
                    </div>

                  </div>
                  <div class="card-footer text-center">
                    {{selectedTheme.theme_name}}
                  </div>
                </div>

                <ng-template #libraryModal let-modal>
                  <div class="scroller">
                    <div class="wholePop filtMain">
                      <div class="modal-header dialHead w-100">
                        <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">{{selectedTheme.theme_name}}</h4>

                      </div>
                      <div class="modal-body">

                        <div class="card mb-2 mb-md-0 ">

                          <div class="card-header " id="container2">

                            <div class="imgThemeCentr themeImglarge">
                              <span *ngIf="selectedProduct.product_image_thumb"><img class="w-100" src="{{selectedTheme.theme_screenshot}}" (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)" title="Click here to preview the theme"/></span>
                              <span *ngIf="!selectedProduct.product_image_thumb"><img class="w-100" src="{{selectedTheme.theme_screenshot}}" (error)="setValue($event)" title="Click here to preview the theme"/></span>

                              <img class="overlap-image2"  src="{{selectedProduct.product_image_thumb}}"  #overlapImagemodal  (error)="setValue($event)">


                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

              </div>

            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center my_class ">
            <button type="button" class="custbtn" (click)="gotToPanel3From4()">Back</button>
            <button type="button" class="custbtn" (click)="saveCampaign()">Confirm</button>

          </div>
        </div>

      </div>

  </div>
  </div>

</div>

