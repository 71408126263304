import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { FormControl } from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-cb-theme-template-association',
  templateUrl: './cb-theme-template-association.component.html',
  styleUrls: ['./cb-theme-template-association.component.css']
})
export class CbThemeTemplateAssociationComponent extends BaseComponent implements OnInit {
  queryContactField: FormControl = new FormControl();
  public themeObj: any = [];
  journeyObj = [];
  templateObj = [];
  companyId;
  themeId= '';
  journeyId= 'all';
  getCompanyobj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  text;
  image;
  currentUser;
  companyCheck;
  order: string = '';
  colname = '';

  totalNumber: number = 0;
  constructor(private master: MasterService,
    private clientService: ClientService,
    private cb: CbService,
  ) {
    super();
  }
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id) this.companyId = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getCompanyThemes(this.companyId);
    else this.getCompany();
    this.companyCheck = this.currentUser.admin_customer_id;

    this.searchSubscribe()
  }


  setOrder(col) {
    this.colname = col;
    if (this.order == 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    } this.getCompanyTemplates();
  }

  searchSubscribe(){
    this.queryContactField.valueChanges
      .pipe(
        map((query) => query),
        debounceTime(600),
        distinctUntilChanged(),
        tap(()=> {
          this.skip = 0;
          this.templateObj = [];
          this.ngxSpinnerService.show();
        })
      )
      .subscribe(query => {
        this.text = query;
        this.getCompanyTemplates();
      }, error=> {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }

  getCompanyThemes(id) {
    if (id) {
      this.templateObj = [];
      this.companyId = id;
      let self = this;
      this.ngxSpinnerService.show();
      let tempData = [] ;
      this.master.getCompanyTheme(id, 9999, 0, 'theme_name', 'asc', '').subscribe(response => {
        tempData = response.data.rows;
        const tempData2 = tempData.filter(item => item.isactive)
        this.themeObj = tempData2;
        this.ngxSpinnerService.hide();
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
    else {
      this.templateObj = [];
      this.themeObj = [];
      this.journeyObj = [];
    }
  }

  changeJourney() {
    this.cp = 1;
    this.getCompanyTemplates()
  }

  getCompanyJourneys() {
    this.skip = 0;
    this.cp=1;
    this.templateObj = [];
    this.journeyObj = [];
    this.journeyId = 'all'
    this.text = '';

    if (this.companyId != '' && this.themeId != '' && this.journeyId != '') {
      let self = this;
      this.ngxSpinnerService.show();
      this.cb.getCompanyThemesJourneys(this.companyId, this.themeId).subscribe(response => {
        if (response.status) {
            this.getCompanyTemplates();
            self.journeyObj = response.data;
        } else {
          self.journeyObj = [];
        }
        this.ngxSpinnerService.hide();
      }, error => {
        //  console.log(error);
        self.journeyObj = [];
        this.ngxSpinnerService.hide();
      });
    }
  }

  getCompanyTemplates() {
    if (this.companyId != '' && this.themeId != '' && this.journeyId != '') {
      let self = this;
      this.ngxSpinnerService.show();
      this.skip = (this.cp - 1) * this.limit;
      this.cb.getCompanyJourneyTemplates(this.companyId, this.themeId, this.limit, this.skip, this.colname, this.order, this.text, this.journeyId == 'all' ? '' : this.journeyId).subscribe(response => {
        if (response.data.rows) {
          self.templateObj = response.data.rows;
          self.totalCount = response.data.total;
        } else {
          self.templateObj = [];
          self.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        //  console.log(error);
        self.templateObj = [];
        this.ngxSpinnerService.hide();
      });
    }
  }

  getJourneyByTheme(id) {
    //event.preventDefault();
    //this.journeyId = id;
    // console.log("company themes:"+ id);
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getJourneyByTheme(this.companyId, id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.data) {
        self.templateObj = data.data;
      } else {
        self.templateObj = [];
      }
    }, error => {
      // console.log(error);
      self.templateObj = [];
      this.ngxSpinnerService.hide();
    });
  }



  editTemplate(id) {
    this.router.navigate(['/corporate/add-cb-theme-template-association'], { queryParams: { companyID: this.companyId, id: id } });
  }
  deleteTemplate(id) {

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.cb.deleteThemeTemplate(this.companyId, id).subscribe(data => {
            if (data.status) {
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
                this.getCompanyTemplates()
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  openImage(image) {
    let self = this
    if (image && image.indexOf("master_images") >= 0) {
      image = image.substring(image.indexOf("master_images"), image.length)
      if (!this.isUrlValid(image)) {
        let awsUrl = self.master.getUrlfile(image);
        this.image = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        this.image = '../../../assets/custom/images/broken.png';
      }
    } else {
      this.image = '../../../assets/custom/images/broken.png';
    }
    this.viewImageService.confirm('View Screenshot', this.image, 'Close')
      .then((confirmed) => {
        /// console.log(confirmed)
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
      self.getCompanyobj = [];
    });
  }
}
