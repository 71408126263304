import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-add-cb-field-attribute-association',
  templateUrl: './add-cb-field-attribute-association.component.html',
  styleUrls: ['./add-cb-field-attribute-association.component.css']
})
export class AddCbFieldAttributeAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  submitted = false;
  attributeForm: FormGroup;
  attributeObj: any = [];
  attributeid: any = [];
  getCompanyobj: any = [];
  selectedAttributes = [];
  companyID;
  attributeSelected:any = [];

  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCompany();

    this.attributeForm = this.formBuilder.group({
      company: ['', Validators.required],
      fid: ['', Validators.required],
      aid: ['']
    });
  }

  get f() { return this.attributeForm.controls; }

  selectAttributes(e, id) {
    if(e.target.checked) {
      this.attributeid.push(id)
    } else if(!e.target.checked) {
      this.attributeid.splice(this.attributeid.indexOf(id), 1);
    }
  }

  addAttribute() {
    this.submitted = true;
    if (this.attributeForm.invalid) {
      return;
    }

    if(this.attributeid.length>0) this.attributeid = this.attributeid.filter((el, i, a) => i === a.indexOf(el));

    const data = {
      attributeid: this.attributeid,
      fieldid: this.f.fid.value,
      company: this.f.company.value
    }
    this.ngxSpinnerService.show();
    this.cb.addFAAssociation(data).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-field-att-association`);
      } else {
        this.toastrService.error(data.message)
        this.ngxSpinnerService.hide();
      }
    }, error => {
     // console.log(error);
      this.toastrService.error('Something went wrong')
    });
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
     // console.log(this.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }

  getCompanyFields(id){

    if(id) {

    this.companyID = id;
    //event.preventDefault();
    this.ngxSpinnerService.show();
    this.attributeForm.controls.fid.setValue("");

    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        field: this.cb.getCompanyField(id, 9999, 0),
        //attribute: this.cb.getCompanyFieldAttribute(id)
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      this.attributeObj = [];
      this.themeObj = data.field.data.rows;
    //  console.log(data, 'forkJoin')
    }), error => {
    //  console.log(error);

    }
    }else {
      this.f.fid.setValue("");
      this.attributeObj = [];
    }
  }

  getSectionAttribute(id){

    if(id) {
          let self = this;

    this.ngxSpinnerService.show();
    this.selectedAttributes= [];
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        attribute: this.cb.getCompanyFieldAttribute(this.companyID),
        checkedAttribute: this.cb.getAssociatedFieldAttribute(this.companyID,id)
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      this.attributeObj = data.attribute.data.rows;
      this.attributeSelected = data.checkedAttribute.data;

      if(self.attributeSelected?.length>0){
      self.attributeSelected.forEach(element => {
        this.attributeid.push(element.attributeid);
      });
    }else  {
      this.attributeSelected = [];
    }
     // console.log(data, 'forkJoin')
    }), error => {
    //  console.log(error);
    }
    }else {

      this.attributeObj =[];
    }
  }


}

