// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,
  chatURI: 'https://dev-chat.margo.me/',
  apiUrl: 'https://dev-admin-api.margo.me/',
  segmentationApiUrl: 'https://dev-segment.margo.me/',
  dsURL:'https://dev-ds.margo.me/',
  accessKeyId: 'AKIA3AFAXT7OT4APBLFU',
  secretAccessKey: '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',

  region: 'us-east-1',
  cbAccessKeyId: 'AKIA3AFAXT7OT4APBLFU',
  cbSecretAccessKey: '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',
  cbRegion: 'us-east-1',
  cbBucket: 'margous-cb',
  // SFDomainURL: 'https://000000111111111111111111--dev.lightning.force.com',

  // SFDomainURL: 'https://000000111111111111111111--dev.sandbox.lightning.force.com/',
  SFDomainURL: 'https://000000111111111111111111--stage.my.salesforce.com',

  awsBucket: 'margous-me-2',
  projectName: 'marGo',
  awsKey: 'margo',
  product_catalogue_path: 'Products/dev/',
  awsUrl:'https://margous-me-2.s3.amazonaws.com'
};
