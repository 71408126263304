<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Premium Campaign</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Setting
      </li>
    </ol>
  </nav>
</div>
<div class="container mt-2 mb-2 themeTblReso">

  <div class="row">
    <div class="col-md-4 mt-2">
      <div>
        <select name="company" class="form-control formstyle" (change)="getCampaignSetting($event.target.value)">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-4 mt-2">
      <div>
        <select *ngIf="crosselDropDown" name="campaignType" class="form-control formstyle" 
        (change)="getCampaignSettingByAudType($event.target.value)">
          <ng-container>
            <option value="1">Cross-sell</option>
            <option value="2">Replenishment</option>
          </ng-container>
        </select>

        <select *ngIf="crosselDropDown==false" name="campaignType" class="form-control formstyle" 
        (change)="getCampaignSettingByAudType($event.target.value)">
          <ng-container>
            <option value="">Select</option>
          </ng-container>
        </select>

      </div>
    </div>


<div class="col-md-4" *ngIf="Status==true">
    <input type="button" value="Edit" *ngIf="isWrite" class="custbtn ml-0 mt-md-2 mt-0 float-md-right" (click)="edit(campaignSettingObj.aicampaignsettingid)">
  </div>

  <div class="col-md-4" *ngIf="addNewStatus">
    <input type="button" value="Add New" class="custbtn ml-0 mt-md-2 mt-0 float-md-right" (click)="addNew(campaignType)" routerLink="/corporate/addnewsetting">
  </div>
  </div>
</div>


<div class="tblPrt mt-2" *ngIf="Status==true" class="col-md-12">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="background-color: #fff;">



    <tr>
      <td colspan="3" class="main_heading"> Settings </td>
    </tr>

    <tr>
      <td class="srno"> 1. </td>
      <td class="heading"> Theme Name</td>
      <td>
        {{campaignSettingObj.theme_name}}
      </td>
    </tr>

    <tr>
      <td class="srno"> 2. </td>
      <td class="heading"> Market Show Id</td>
      <td>
        {{campaignSettingObj.marketshow_id}}
      </td>
    </tr>

    <tr>
      <td class="srno"> 3. </td>
      <td class="heading"> Day </td>
      <td>
        {{campaignSettingObj.day}}
      </td>
    </tr>

    <tr>
      <td class="srno"> 4. </td>
      <td class="heading"> Time </td>
      <td> {{campaignSettingObj.time}}</td>
    </tr>

    <tr>
      <td class="srno"> 5. </td>
      <td class="heading"> Automation for Campaign </td>
      <td *ngIf="campaignSettingObj.automation_campaign==true"> Active </td>
      <td *ngIf="campaignSettingObj.automation_campaign!=true"> In-Active </td>
    </tr>

    <tr>
      <td class="srno"> 6. </td>
      <td class="heading"> Automation for Audience</td>
      <td *ngIf="campaignSettingObj.automation_audience==true"> Active </td>
      <td *ngIf="campaignSettingObj.automation_audience!=true"> In-Active </td>
    </tr>

    <tr>
      <td class="srno"> 7. </td>
      <td class="heading"> Status </td>
      <td *ngIf="campaignSettingObj.isactive==true"> Active </td>
      <td *ngIf="campaignSettingObj.isactive!=true"> In-Active </td>
    </tr>
  </table>
</div>

<div class="tblPrt mt-2" *ngIf="addNewStatus" class="col-md-12">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="background-color: #fff;">
    <tr>
      <td style="text-align: center;">
        No Result Available
        
      </td>
    </tr>
  </table>
</div>