import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import csc from 'country-state-city';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { AudienceBuilderService } from 'src/app/services/segmentation/audience-builder.service';
import { MarketFlowService } from 'src/app/services/shop-live/marketFlow.service';

@Component({
  selector: 'app-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.css']
})
export class EventPreviewComponent extends BaseComponent implements OnInit {
  selectedThemeData:any;
  eventData:any;
  contactData: any;
  selectedCountry: string;
  campaign: any='';
  companyId: any;
  userData: any;
  header='Add new';
  country:any;
  product_id: any = 0;
  contact_id: any;
  selectedImage:any;
  constructor(
    private master : MasterService,
    private shopLiveService : ShopLiveService,
    private audienceBuilder : AudienceBuilderService,
    private marketFlowService:MarketFlowService

  ) {
    super();
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
  }

  ngOnInit(): void {
    this.header = sessionStorage.getItem('eventAction');
   this.selectedThemeData =  JSON.parse(sessionStorage.getItem('themes_list_data') || '');
   this.eventData = JSON.parse(sessionStorage.getItem('eventdata') || '');
   this.contactData = JSON.parse(sessionStorage.getItem('contactdata') || '');
   if(this.contactData){
    this.contact_id = this.contactData.contact_id;
   }
   if(this.eventData){
    this.getCountryById(this.eventData.event_country);
    let obj = {
      resource_type:this.eventData?.resource_type,
      resource_preview_url:this.eventData?.resource_preview_url,
      res_id:this.eventData?.res_id
    }
    this.selectedImage = obj;
   }

   if(this.eventData && this.eventData?.parent_event_type== 127 ){
    this.campaign = 'In-Person';
   }else if(this.eventData && this.eventData?.parent_event_type== 128){
    this.campaign = 'Virtual';
   }else if(this.eventData && this.eventData?.parent_event_type== 129){
    this.campaign = 'In-Person & Virtual';
   }


  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  addEvent(){
    this.ngxSpinnerService.show();
    let payload = {
      event_type:this.eventData?.event_type,
      event_name:this.eventData?.event_name,
      event_time_zone:this.eventData?.event_time_zone,
      event_type_id:this.eventData?.parent_event_type,
      theme_id:this.selectedThemeData?.theme_id,
      event_details:this.eventData?.event_details,
      event_address1:this.eventData?.event_address1 ||'',
      event_address2:this.eventData?.event_address2 || '',
      event_country:this.eventData?.event_country || '',
      event_city:this.eventData?.event_city || '',
      event_state:this.eventData?.event_state || '',
      event_zip:this.eventData?.event_zip || '',
      event_url:this.eventData?.event_url || '',
      hostid:this.contactData?.contact_id,
      pws:this.eventData?.pws,
      event_shopping_url:this.eventData?.event_shopping_url,
      event_start_time:this.eventData?.event_start_time,
      event_date:this.eventData?.event_date,
      event_url_id:this.eventData?.event_url_id,
      event_description:this.eventData?.event_description,

      selectedTimezone:this.eventData?.event_time_zone,
    }
    this.shopLiveService.addNewEvent(payload,this.companyId).subscribe((res:any)=>{
      this.toastrService.success('Event created successfully !')
      this.router.navigateByUrl('/corporate/events')
      this.ngxSpinnerService.hide();
    })
  }
  onCancel(){
    sessionStorage.removeItem('eventdata');
    sessionStorage.removeItem('contactdata');
    sessionStorage.removeItem('themes_list_data');
    this.router.navigateByUrl('/corporate/events');
  }
  ConfirmCampaign(){
    let msg = '';
    if(this.header=='Update' && this.eventData.event_id){
     msg='Are you sure you want to update this event?'
    }
    else{
      msg='Are you sure you want to create this event?'
    }
    this.confirmationDialogService.confirm('Please confirm',msg, 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          // this.addEvent();
          if(this.header=='Update' && this.eventData.event_id){
            this.updateEvent();
           }
           else{
            this.saveCampaign();
           }

        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }
  getCountryById(countryId: string) {
       let cObj = csc.getCountryByCode(countryId)
      this.country = cObj?.name
  }
  saveCampaign() {
    let self = this;
    var data = JSON.parse(sessionStorage.getItem("market"));
    this.ngxSpinnerService.show();
    this.marketFlowService.contactByIdData = this.contactData;
    let marketorder = {
      contact_id: self.contact_id,
      contact_type_id: 4,
      product_id: this.product_id,
      journey_id: parseInt(this.eventData?.parent_event_type),
      theme_id: self.selectedThemeData.theme_id,
      message: sessionStorage.getItem("message") ? sessionStorage.getItem("message") : '',
      company_name: this.userData?.customer_company_name
    }
    this.marketFlowService.MarkertOrder = marketorder;
    var cohost;
    if (sessionStorage.getItem("cohostdata")) {
      cohost = JSON.parse(sessionStorage.getItem("cohostdata"));
    }
    let eventHostCohostRel = {
      contact_id: self.contact_id,
      event_id: 0,
      host_flag: 1,
      isActive: true,
      cohost: cohost
    }
    this.marketFlowService.EventHostCohostRelData = eventHostCohostRel;
    if (this.eventData) {
      this.eventData.event_type_id = parseInt(this.eventData?.parent_event_type);
      this.eventData.theme_id = self.selectedThemeData.theme_id;
      this.eventData.hostid = this.contact_id;
      this.marketFlowService.EventData = this.eventData;
      this.marketFlowService.createevent().subscribe(result => {
        var data;
        if (!Array.isArray(result)) {
          data = result;
        }
        else {
          data = result[1];
        }

        if (data.status) {
          /*  Adding Invitees in Bulk to newly created Event */
          let inv = JSON.parse(sessionStorage.getItem('bulkInvitees'));
          if (inv && inv?.length > 0) {
            // this.addBulkInvitees(inv, this.contactData.contact_id);
          }
          // this.saveMetaFields();
          // sessionStorage.clear();
          // self.showSuccess(confrmBox);
          this.toastrService.success('Event created successfully !')
          // this.router.navigateByUrl('/corporate/events')
          this.onCancel();
          this.ngxSpinnerService.hide();
        } else {
          if (data.message) {
            this.toastrService.error(data.message);
          }
          this.ngxSpinnerService.hide();
        }

      }, error => {
        this.ngxSpinnerService.hide();
        console.log(error);
      });

    }
    else {
      this.marketFlowService.createmarketorder().subscribe(data => {
        if (data.status) {
          // sessionStorage.clear();
          // self.showSuccess(confrmBox);
          this.toastrService.success('market order created successfully !')
          // this.router.navigateByUrl('/corporate/events')
          // sessionStorage.clear();
          this.onCancel();
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
        // this.marketFlowService.syncDataWithMC();
      });
    }
  }
  ngOnDestroy(){
    // sessionStorage.removeItem('eventdata');
    // sessionStorage.removeItem('eventAction');
    // sessionStorage.removeItem('themes_list_data');
  }
  updateEvent(){
    this.ngxSpinnerService.show();
    let payload = {
      event_id:this.eventData?.event_id,
      event_type:this.eventData?.event_type,
      event_name:this.eventData?.event_name,
      event_time_zone:this.eventData?.event_time_zone,
      event_type_id:this.eventData?.parent_event_type,
      theme_id:this.selectedThemeData?.theme_id,
      event_details:this.eventData?.event_details,
      event_address1:this.eventData?.event_address1 ||'',
      event_address2:this.eventData?.event_address2 || '',
      event_country:this.eventData?.event_country || '',
      event_city:this.eventData?.event_city || '',
      event_state:this.eventData?.event_state || '',
      event_zip:this.eventData?.event_zip || '',
      event_url:this.eventData?.event_url,
      // hostid:this.contactData?.contact_id,
      event_host_pws:this.eventData?.pws,
      event_shopping_url:this.eventData?.event_shopping_url,
      event_start_time:this.eventData?.event_start_time,
      event_date:this.eventData?.event_date,
      event_url_id:this.eventData?.event_url_id,
      event_description:this.eventData?.event_description,
      res_id:this.eventData?.res_id,
      res_rel_id:this.eventData?.res_rel_id,
      selectedTimezone:this.eventData?.event_time_zone,
    }
    this.shopLiveService.updateEvent(payload,this.companyId).subscribe((res:any)=>{
      this.toastrService.success('Event Updated successfully !');
      sessionStorage.removeItem('eventdata');
      sessionStorage.removeItem('eventAction');
      sessionStorage.removeItem('themes_list_data');
      this.router.navigateByUrl('/corporate/events');
      this.ngxSpinnerService.hide();
    })
  }

}
