<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Resource Library</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-3 col-xl-6 mb-md-0 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText />
      </div>
    </div>

    <div class="col-md-3 col-xl-2 mb-md-0 mb-2" style="justify-content: right">
      <input type="button" value="Add New" class="custbtn mt-md-1 ml-0" routerLink="/corporate/add-resource" />
    </div>
  </div>
  <div class="row mt-2">

    <div class="col-md-3 mb-md-0 mb-2" *ngIf="companyID==0">
      <div>

        <select name="company" class="form-control formstyle" (change)="getProductList(); getCategoryData()"
          [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <!-- <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option> -->
            <option value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-3 mb-md-0 mb-2">

      <div>
        <select name="company" class="form-control formstyle" (change)="getProByCategoryId($event.target.value)">
          <option value="0">Select Category</option>
          <ng-container *ngFor="let obj of categoryObj">
            <option value={{obj.cate_id}}>
              {{obj.category_name}}
            </option>
          </ng-container>
        </select>
    </div>
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="productObj.length > 0">
      <tr>
        <th (click)="setOrder('sl_product_name')" style="cursor: pointer">
          Name
          <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Image</th>
        <th>Category Name</th>
        <th (click)="setOrder('createdon')" style="cursor: pointer">
          Created On<i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Actions</th>
      </tr>
      <tr *ngFor="
      let obj of productObj">
        <td>{{ obj.resource_title }}</td>
        <td>
          <img *ngIf="obj.imageType == 0" [src]="obj.resource_preview_url" alt="Not Found" (error)="setValue($event)"
            style="height: 50px" />
          <img *ngIf="obj.imageType == 1" src="../../../../assets/images/doc_image.jpg"
            style="max-height: 35px; min-width: 35px;" />
          <video *ngIf="obj.imageType == 2" src="" poster="assets/images/icons/video-play.svg"
            style="max-height: 40px"></video>
        </td>
        <td>{{obj.category_name}}</td>
        <td>{{ obj.created_dt | date: "mediumDate" }}</td>
        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1" (click)="editPost(obj.res_id)" placement="bottom" ngbTooltip="Edit"
              *ngIf="isEdit"></i>
            <i *ngIf="isEdit" class="fa fa-trash mr-1" (click)="deleteProduct(obj.res_id)" placement="bottom"
              ngbTooltip="Delete"></i>

            <mat-slide-toggle *ngIf="isEdit" class="my-slider" [checked]="obj.is_active"
              (click)="updateStatus($event, obj.res_id, obj.is_active)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="mt-3" *ngIf="slTotal == 0" style="text-align: center">
    <p>No Result Available</p>
  </div>

  <div class="mt-2 text-center difPagination" *ngIf="slTotal > 10">
    <ngb-pagination [collectionSize]="slTotal" [(page)]="slPage" [maxSize]="2" [rotate]="true"
      (pageChange)="getProductList(slPage=$event)" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div>

