import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-crosssellproductcatalog',
  templateUrl: './crosssellproductcatalog.component.html',
  styleUrls: ['./crosssellproductcatalog.component.css']
})
export class CrosssellproductcatalogComponent extends BaseComponent implements OnInit {
  productname;
  defaultImage = 'assedefault_product.JPG';
  productList;
  theme_description;
  screenshot: String;
  IsActive;
  product_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_company;
  getCompanyobj;
  theme_screenshot;
  status: boolean = true;
  headers = "Add";
  companyID;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: FormGroup;
  productSku = '';


  productCataloguePath = environment.product_catalogue_path;
  // productCataloguePath = 'Products/dev';



  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.product_id = params.id;
        this.companyID = params.companyID;

      });
    if (this.product_id) {
      this.UpdateProductCatalogLogData()
    }

    //get item details using id
    if (this.product_id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit";
      this.master.getProductCatalogById(this.companyID, this.product_id).subscribe(response => {
        this.ngxSpinnerService.hide();
        if (response.status) {
          this.status = false;
          self.themObj = response.data.rows[0];
          self.dataBinding();
        } else {
        }
      });
    }

    this.themeForm = this.formBuilder.group({
      productsku: [''],
      crosssell_1: [''],
      crosssell_2: [''],
      crosssell_3: [''],
      crosssell_4: [''],
      crosssell_5: [''],

    });

    this.crossSellItemList();
  }


  crossSellItemList() {
    let body = {
      company: this.companyID || 62,
      product_id : this.product_id
    };
    
    this.master.getCrossSellItems(body).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.productList = data.data;
      }
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }
  get f() { return this.themeForm.controls; }
  
  UpdateProductCatalogLogData() {
    let themeLogs = {
      log_type: "Updating cross-sell items",
      log_name: "Updating cross-sell items",
      log_description: "Updating cross-sell items",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      // console.log(error);
    });
  }
  
  dataBinding() {
    this.themeForm = this.formBuilder.group({
      productsku: [this.themObj.product_name + ' ' +this.themObj.product_sku],
      crosssell_1: [this.themObj.crosssell_1],
      crosssell_2: [this.themObj.crosssell_2],
      crosssell_3: [this.themObj.crosssell_3],
      crosssell_4: [this.themObj.crosssell_4],
      crosssell_5: [this.themObj.crosssell_5],
    });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     
    }, error => {
      // console.log(error);
    });
  }

  updateProduct() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      console.log("this.themeForm", this.themeForm);
      return;
    }
    
    let { productsku, crosssell_1, crosssell_2, crosssell_3, crosssell_4, crosssell_5 } = this.themeForm.value;

    let catalogObj = {
      company: this.companyID,
      product_catalog_id: this.product_id,
      sku: productsku,
      crosssell_1: parseInt(crosssell_1),
      crosssell_2: parseInt(crosssell_2),
      crosssell_3: parseInt(crosssell_3),
      crosssell_4: parseInt(crosssell_4),
      crosssell_5: parseInt(crosssell_5),

    }

    // console.log("---CatalogObj---", catalogObj);
    // return false;

    this.ngxSpinnerService.show();
    this.master.UpdateCrossSellItems(catalogObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully")
        this.router.navigateByUrl(`/corporate/productcatalog`);
        this.ngxSpinnerService.hide();
      } 
      else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message)
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  
  
}
