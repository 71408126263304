import { Component, OnInit } from '@angular/core';
//import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { BaseComponent } from '../base.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  userCustomer;
  reportAllDSUserobj:any=[];
  reportAllAdminUserobj:any=[];
  reportAllHostUserobj:any=[];
  reportAllProspectUserobj:any=[];  
  reportAllCustomerUserobj:any=[];
  reportAllProspectCampaignsobj:any=[];
  reportAllCustomerCampaignsobj:any=[];
  reportAllTeamCampaignsobj:any=[]; 
  reportAllContactObj:any=[];
  reportAllEventCampaignsobj:any=[]; 
  reportAllReplenishmentsobj:any=[]; 
  reportAllCrossSellobj:any=[]; 
  showProspect='percentage';
  showCustomer='percentage';
  showTeam='percentage';
  showEvent='percentage';
  //allTablesobj;
  //allTableFieldsobj;
  title = '';
  type = 'PieChart';
  customerISheadquarter = 1;

  prospectCampaigns: any = [];
  customerCampaigns: any = [];
  eventsCampaigns: any = [];
  teamCampaigns: any = [];
  marketerHideSection=true;
  companyObj;
  roleId;

  //roleId;





  

  
  columnNames = ['Campaign', 'Percentage'];
  options = { 
    legend : {position: 'bottom', textStyle: {color: 'blue', fontSize: 10}}
  };

  width = 230;
  height = 230;


  

  

  constructor(
    private clientsService: ClientService,

    private deviceService: DeviceDetectorService
    //private master:MasterService
  ) { super(); }
 
  ngOnInit() {

    console.log("-- Test Home Component --");

    ///console.log('sssss',this.reportAllCustomerCampaignsobj);


    var data = JSON.parse(sessionStorage.getItem("userData"));
    this.userCustomer = data.admin_customer_id;

    this.roleId = data.role_id;

    //this.admin_customer_id = data.admin_customer_id;

    //console.log("ssss..",data.role_id)

    if(this.userCustomer!=0){
        this.getCompanyDetails();
    }
    
    if(data.role_id==102){
          this.marketerHideSection=false;
    }
    this.ngxSpinnerService.show();
    
  

     // console.log(this.userCustomer);

   // console.log("testing ......" + this.customerISheadquarter)

   if (this.userCustomer != 0) {
    
    this.clientsService.getClientById(this.userCustomer).subscribe(data => {

    //  console.log("head" + data.data.customer_isheadquarter);
      if (!data.data.customer_isheadquarter) {
        this.customerISheadquarter = data.data.customer_isheadquarter;
        this.getCustomerInfo(this.userCustomer);
        this.reportAllDSUser(this.userCustomer);
        this.reportAllAdminUser(this.userCustomer);
        this.reportAllHostUser(this.userCustomer);
        this.reportAllProspectUser(this.userCustomer);
        this.reportAllCustomerUser(this.userCustomer);
        this.reportAllProspectCampaigns(this.userCustomer);
        this.reportAllCustomerCampaigns(this.userCustomer);
        this.reportAllTeamCampaigns(this.userCustomer);
        this.reportAllEventCampaigns(this.userCustomer);
        this.reportAllReplenishment(this.userCustomer);
        this.reportAllCrossSell(this.userCustomer);
        this.reportAllContacts(this.userCustomer);
      }

    }, error => {
     // console.log(error);

    });
    
    
    
      
      //this.gettingTables();
     // this.gettingTableFields('admin_type_tbl');

      

    }
    this.ngxSpinnerService.hide();

  }

  reportAllDSUser(id) {
    let self = this;
    this.clientsService.reportAllDSUser(id).subscribe(data => {
      self.reportAllDSUserobj = data.data;

  
    }, error => {
      console.log(error);
    });
   }

   reportAllAdminUser(id) {

    let self = this;

    this.clientsService.reportAllAdminUser(id).subscribe(data => {
    if( data.data) {
      this.reportAllAdminUserobj = data.data;
      console.log(this.reportAllAdminUserobj);
    }else {
      this.reportAllAdminUserobj = [];

    }
  

  
    }, error => {
      console.log(error);
    });
   }
  

   reportAllHostUser(id) {
    let self = this;
    this.clientsService.reportAllHostUser(id).subscribe(data => {
      self.reportAllHostUserobj = data.data.totcount;
      console.log(self.reportAllHostUserobj);
  
    }, error => {
      console.log(error);
    });
   }

   reportAllProspectUser(id) {
    let self = this;
    this.clientsService.reportAllProspectUser(id).subscribe(data => {
      self.reportAllProspectUserobj = data.data.totcount;
      console.log(self.reportAllProspectUserobj);
  
    }, error => {
      console.log(error);
    });
   }

   reportAllCustomerUser(id) {
    let self = this;
    this.clientsService.reportAllCustomerUser(id).subscribe(data => {
      self.reportAllCustomerUserobj = data.data.totcount;
      console.log(self.reportAllCustomerUserobj);
  
    }, error => {
      console.log(error);
    });
   }

   reportAllProspectCampaigns(id) {
    let self = this;
    this.clientsService.reportAllProspectCampaigns(id,3).subscribe(data => { 
    var data1:any=[];
    
      self.reportAllProspectCampaignsobj = data.data;
  if(data.data) {
      for(var i=0; i< data.data.length;i++){
      //console.log(self.reportAllProspectCampaignsobj[i]['journey_name']);
      data1.push([self.reportAllProspectCampaignsobj[i]['journey_name'],self.reportAllProspectCampaignsobj[i]['product_percentage']]);      
         }
         this.prospectCampaigns = data1;
        } else self.reportAllProspectCampaignsobj = [];
      console.log(data1);
     

  
    }, error => {
      console.log(error);
    });
   }

   reportAllCustomerCampaigns(id) {
    let self = this;
    this.clientsService.reportAllCustomerCampaigns(id,2).subscribe(data => {

      var data1:any=[];
    
      
      if(data.data) {
        self.reportAllCustomerCampaignsobj = data.data;
      for(var i=0; i< data.data.length;i++){
      //console.log(self.reportAllProspectCampaignsobj[i]['journey_name']);
      data1.push([self.reportAllCustomerCampaignsobj[i]['journey_name'],self.reportAllCustomerCampaignsobj[i]['product_percentage']]);      
         }
         this.customerCampaigns = data1;
         console.log(data1);
        } else   self.reportAllCustomerCampaignsobj  =[]
      
      console.log(self.reportAllCustomerCampaignsobj);
  
    }, error => {
      console.log(error);
    });
   }

   reportAllTeamCampaigns(id) {
    let self = this;
    this.clientsService.reportAllTeamCampaigns(id,1).subscribe(data => {


      var data1:any=[];
    
     
      if(data.data) {  
        self.reportAllTeamCampaignsobj = data.data;
      for(var i=0; i< data.data.length;i++){    
         data1.push([self.reportAllTeamCampaignsobj[i]['journey_name'], self.reportAllTeamCampaignsobj[i]['product_percentage']]);      
         }     
        console.log(data1);
         this.teamCampaigns = data1;     
         console.log(self.reportAllTeamCampaignsobj);
        } else self.reportAllTeamCampaignsobj =[]
    }, error => {
      console.log(error);
    });
   }

   reportAllContacts(id) {
    let self = this;
    this.clientsService.reportAllContacts(id).subscribe(data => {
      self.reportAllContactObj = data.data.total;
      console.log(self.reportAllContactObj);
     
    }, error => {
      console.log(error);
    });
   }

   reportAllEventCampaigns(id) {
    let self = this;
    this.clientsService.reportAllEventCampaigns(id,4).subscribe(data => {
      var data1:any=[];
    
     
      if(data.data) {
        self.reportAllEventCampaignsobj = data.data;
      for(var i=0; i< data.data.length;i++){
    
      data1.push([self.reportAllEventCampaignsobj[i]['journey_name'],self.reportAllEventCampaignsobj[i]['product_percentage']]);      
         }
         console.log("test raj");
         console.log(data1);
         this.eventsCampaigns = data1;
        } else self.reportAllEventCampaignsobj =[]   
    }, error => {
      console.log(error);
    });
   }

   reportAllReplenishment(id) {
    let self = this;
    this.clientsService.reportByTrainingType(id,2).subscribe(data => {
      self.reportAllReplenishmentsobj = data.data.total;
      console.log(self.reportAllReplenishmentsobj);  
    }, error => {
      console.log(error);
    });
   }

   reportAllCrossSell(id) {
    let self = this;
    this.clientsService.reportByTrainingType(id,3).subscribe(data => {
      self.reportAllCrossSellobj = data.data.total;
      console.log(self.reportAllCrossSellobj);  
    }, error => {
      console.log(error);
    });
   }

   

   
   getCustomerInfo(id) {
    let self = this;
    this.clientsService.getClientById(id).subscribe(data => {      
      this.customerISheadquarter = data.data.customer_isheadquarter;
     
    }, error => {
      console.log(error);
    });
  }
  /*
     gettingTableFields(name) {
       console.log("testing message");
      let self = this;
      this.clientsService.gettingTableFields(name).subscribe(data => {
        self.allTableFieldsobj = data.data;
        console.log(self.allTableFieldsobj);  
      }, error => {
        console.log(error);
      });
     }
   */






  showProspectTotal(type) {
    this.showProspect = type;

  }
  showCustomerTotal(type) {
    this.showCustomer = type;

  }

  showTeamTotal(type) {
    this.showTeam = type;

  }

  showEventTotal(type) {
    this.showEvent = type;

  }




  openreport(type) {

    console.log("ddddddddd/",type)

    const isMobile = this.deviceService.isMobile();


    if(type==1) {
      if(isMobile==true)
     { localStorage.setItem("selectedDashboard","0FKDn000000XPd4OAG"); }
      //else localStorage.setItem("selectedDashboard","0FK4F000000CgVEWA0")
      else { localStorage.setItem("selectedDashboard","0FKDn000000XFKIOA4"); }

     // console.log("111111111111111...................");

      localStorage.setItem("DataSetName","Dataset_Premium_Services_Summary");
      

    }
    else if(type==2) {

      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FKDn000000XPcpOAG");
      else localStorage.setItem("selectedDashboard","0FKDn000000XFwvOAG")

      console.log("222222222222222222...................");
      localStorage.setItem("DataSetName","Dataset_Ecommerce_Details");
 

    }
    else if(type==3) {
      
      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FKDn000000XPd9OAG");
      else localStorage.setItem("selectedDashboard","0FKDn000000XNdQOAW")

      localStorage.setItem("DataSetName","Dataset_Customer_Subscription");
      console.log("333333333333333333...................");
 

    }
    else if(type==4) {
      
      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FKDn000000XPczOAG");
      else localStorage.setItem("selectedDashboard","0FKDn000000XJt4OAG")


   

      console.log("5555555555555555555...................");
      localStorage.setItem("DataSetName","Dataset_Contact_Optin_Out");
 

    }
    else if(type==5) {
      
      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FKDn000000XPdEOAW");
      else localStorage.setItem("selectedDashboard","0FKDn000000XGqdOAG")


   

      console.log("5555555555555555555...................");
      localStorage.setItem("DataSetName","Dataset_Campaign_Segment");
 

    }
    else  {
      
       localStorage.setItem("selectedDashboard","0FKDn000000XFKIOA4")
       console.log("44444444444444...................");
 

    }
  
    this.router.navigateByUrl('/corporate/einstein-reports');
    
   }

   


  getCompanyDetails() {

    this.clientsService.getCompanyDetails(this.userCustomer).subscribe(response => {
      if (response.status) {
       
        this.companyObj = response.data;      

        localStorage.setItem("CompanyName",this.companyObj.customer_company_name);
        localStorage.setItem("customer_isheadquarter",this.companyObj.customer_isheadquarter);
     
      } 
    });

  }



   


   
   

}