<!-- page 2 starts -->

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>


      <li class="breadcrumb-item">
        <a routerLink="/corporate/audience-builder">Audience List</a>

      </li>


      <li *ngIf="audId" class="breadcrumb-item active" aria-current="page">

        Edit Audience
      </li>
      <li *ngIf="!audId" class="breadcrumb-item active" aria-current="page">

        Add Audience
      </li>


    </ol>
  </nav>
</div>
<div class="container mt-2">


  <form *ngIf="audienceAddForm" class="form-horizontal mt-1" [formGroup]="audienceForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">


        <div class="card">
          <div class="card-body">
            <div class="mt-1">
              <label for="exampleForm2" class="position-relative pr-2"> Audience Name <span class="required">*</span>
              </label>
              <input type="text" id="exampleForm2" formControlName="audience_name" class="form-control form-control-md"
                required [ngClass]="{ 'is-invalid': submitted && f.audience_name.errors }" maxlength="80" appSpace>
              <div *ngIf="submitted && f.audience_name.errors" class="invalid-feedback">
                <div *ngIf="f.audience_name.errors.required || f.audience_name.hasError('whitespace')"> Name is required
                </div>
                <div *ngIf="f.audience_name.errors?.maxlength">You can enter only 80 charachters</div>


              </div>
            </div>
            <div class="mt-1">
              <label for="exampleForm2"> Audience Description</label>
              <textarea maxlength="400" class="form-control form-control-md" formControlName="audience_desc" appSpace
                style="min-height: 100px;"></textarea>

            </div>

            <div class="mt-1">
                <label for="exampleForm2"> 
                    Audience type
                </label>

              <select *ngIf="page=='premium_campaign'" formControlName="audienceType"
                 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.audienceType.errors }">
                <option value="cross-sell">Cross-sell</option>
                <option value="replenishment">Replenishment</option>
              </select>
              
              <select *ngIf="page!='premium_campaign'" formControlName="audienceType"
                 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.audienceType.errors }">
                <option value="cross-sell">Cross-sell</option>
                <option value="replenishment">Replenishment</option>
                <option value="segmentation">Segmentation</option>
              </select>
              <div *ngIf="submitted && f.audienceType.errors"  class="invalid-feedback">
                <div *ngIf="f.audienceType.errors.required" >Audience Type is required
                </div>
              </div>

              <!-- <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="audienceType1" formControlName="audienceType"
                    value="cross-sell">
                  <label class="custom-control-label" for="audienceType1">Cross</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="audienceType2" formControlName="audienceType"
                    value="replanishment">
                  <label class="custom-control-label" for="audienceType2">Replanishment</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="audienceType3" formControlName="audienceType"
                    value="segmentation">
                  <label class="custom-control-label" for="audienceType3">Segmentation</label>
                </div>


              </div> -->

            </div>

          </div>
          <div class="card-footer">
            <div class="text-center my_class " *ngIf="isWrite && audId"
              [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
              <button type="button" class="custbtn" (click)="updateBUAudience()">Submit</button>
            </div>
            <div class="text-center my_class " *ngIf="isWrite && !audId"
              [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
              <button type="button" class="custbtn" (click)="addBUAudience()">Submit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>













<!-- page 4 ends -->