import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-view-sender-profile',
  templateUrl: './view-sender-profile.component.html',
  styleUrls: ['./view-sender-profile.component.css']
})
export class ViewSenderProfileComponent extends BaseComponent implements OnInit {
  senderProfileId:any;
  company_id:any;
  profileObj:any;
  senderProfileLog: any=[];


  constructor(
    private master: MasterService
  ) { 
    super();}

  ngOnInit(): void {

    this.senderProfileId = localStorage.getItem('senderProfileId');
    this.company_id = localStorage.getItem('senderProfileCompanyId')
    if(this.senderProfileId){
      this.getSenderProfileData();
    
    }
    else{
      this.router.navigate(['/corporate/sender-profile'])
    }
  }

  getSenderProfileData(){
    this.ngxSpinnerService.show();
    this.master.viewSenderProfile(this.company_id,this.senderProfileId).subscribe({
      next:(response:any)=>{
        if (response.status) {
          this.profileObj = response.data;
          this.getSenderProfileLogData(this.profileObj?.user_ds_id);
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error('Something went wrong.');
          this.router.navigate(['/corporate/sender-profile']);
        }
      },
      error:(err:any)=>{
        this.toastrService.error('Something went wrong.')
        this.router.navigate(['/corporate/sender-profile'])
      }     
    });
  }

  getSenderProfileLogData(dsId:any){
    this.master.viewSenderProfileLog(this.company_id,dsId).subscribe({
      next:(response:any)=>{
        if (response.status) {
          this.senderProfileLog = response.data.rows;
       
        } else {
          this.senderProfileLog=[];
        }
      },
      error:(err:any)=>{
        this.senderProfileLog=[]
      }     
    });
  }

}
