<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Contact Market Activity</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 themeTblReso">
  <!--<div class="row" >
    <div class="col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="Search">
      </div>
    </div>
  </div>-->

  <div class="row">

    <div class="col-md-3 mt-2" *ngIf="isHeadquater">
      <select name="company" class="form-control formstyle"  [(ngModel)]="companyID" (change)="gettingReports($event.target.value)">
        <option selected value="0" >Select Company</option>
        <option *ngFor="let userObj of getCompanyobj" value="{{userObj.customer_id}}">
            {{userObj.customer_company_name}}
        </option>
      </select>
    </div>

    <!--<div class="col-md-2 mt-2">
      <select name="user_type" class="form-control formstyle"  [(ngModel)]="user_type">

          <option value="all" selected>All</option>
          <option value="client">Client</option>
      </select>
    </div>-->
    <div class="col-md-3 mt-2">
      <select name="report_status" class="form-control formstyle"  [(ngModel)]="report_status">
        <option value="180" >Half-year</option>
        <option value="7">Weekly</option>
        <option value="30">Monthly</option>
        <option value="365">Year</option>
      </select>
    </div>
    <div class="col-md-3  mt-2">
      <button class="btn btn-margo-action formstyle ml-0 mt-md-1" (click)="gettingReports(1)" style="align-items: center;">Search</button>
    </div>
    <!-- <div  class="col-md-4 mt-2">
      <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a>
    </div> -->
</div>


<div class="tblPrt" *ngIf="showResult">
     <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>DS Name</th>
        <th>{{projectName}} Conatct Name</th>
        <th>Date added to SFMC</th>
        <th>Last Marketed Date</th>
        <th>Days from last Marketing</th>
        <th>Date Deleted From SFMC</th>
        </tr>
        <tr *ngFor="let report of reportData | paginate:{itemsPerPage:10,currentPage:p}">
          <td>{{report.dsname}}</td>
          <td>{{report.contact_fname}}</td>
          <td>{{report.add_date |date}} </td>
          <td>{{report.last_mkt_date|date}}</td>
          <td>{{ report.diff_days }} days</td>
          <td >N/A</td>
        </tr>
        <tr *ngIf="companyID == 0">
          <td colspan="6" style= "text-align: center">No results available</td>
        </tr>
  </table>
  <div *ngIf="companyID != 0"><pagination-controls class="my-pagination" (pageChange)="p = $event"></pagination-controls></div>
</div>
<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td colspan="5">No results available</td>
      </tr>
</table>
</div>

