import { Component, OnInit,Renderer2} from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormBuilder,FormControl,FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-content-builder-data',
  templateUrl: './edit-content-builder-data.component.html',
  styleUrls: ['./edit-content-builder-data.component.css']
})
export class EditContentBuilderDataComponent extends BaseComponent implements OnInit {

  selectedAll: any;
  allTableFieldsobj;
  checkedAll=false;
  marked = false;
  theCheckbox = false;
  show: boolean= false;
  fdstatus:boolean=false;
  checkedTable:any=[];
  checkedTheme:any=[];
  changeCheckFileSize= {};
   checkedDatatype = {};
  sensitveTablesobj:any=[];
  nonSensitiveTables:any=[];
  contentForm: FormGroup;
//************** Company & Theme*********************/
public themeObj: any = [];
  cp = 1;
  ord = true;
  colname: "Theme Name";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID ;
  company_id;
  fields:any=[];
  field_size:any=[];
  allTablesobj;
  themeid=null;
  themedata=null;;
//***********************************/
  constructor( 
    private formBuilder: FormBuilder,
    private master: MasterService,  
  
    private cb: CbService,      
    private clientService:ClientService,
    private renderer: Renderer2,
    private route: ActivatedRoute
  ){  super();
    this.route.queryParams.subscribe(params => {
      this.company_id = params.company_id;
      this.themedata = params.theme_id;
    }); 
 
     this.getCompany();
     this.getCBThemes(this.company_id);
     this.gettingContentBuilderFields();
  }

  ngOnInit() {
   // this.gettingContentBuilderFields(); 
   let self = this;
  
    
     /* if(this.company_id==null &&  this.themedata==null){
        this.router.navigate(['/corporate/content-builder-customer-list']);
      } */

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.contentForm.controls['company'].setValue(this.company_id, {onlySelf: true});
    this.contentForm.controls['themeid'].setValue(this.themedata, {onlySelf: true});
        
   
  }
/************************New***************************/
changeCheckTheme(d) {  
 
  this.gettingContentBuilderFields();
  this.show =this.show;
  
  let abc = d;
  var index = this.checkedTheme.indexOf(abc); 
  if (index > -1) { 
    this.checkedTheme.splice(index, 1);
  //  console.log(this.checkedTheme);
   }
   else{
   // this.show = !this.show;
     this.checkedTheme.push(abc)
    // console.log(this.checkedTheme);
   }     
}

  changeCheckTables(a) {    
    this.fdstatus=!this.fdstatus;
    let abc = a;
    var index = this.checkedTable.indexOf(abc); 
    if (index > -1) { 
      this.checkedTable.splice(index, 1);
     // console.log(this.checkedTable);
     }
     else{       
       this.checkedTable.push(abc);   
     //  console.log(JSON.stringify(this.checkedTable));
     }     
  }

  changeCheckField(k1,v1) {    
    let key = k1;
    let value=v1;
    this.changeCheckFileSize[key] = value;
  }
 
  changeCheckDataType(key2,value2) {    
    let key = key2;
    let value=value2;
    this.checkedDatatype[key] = value;
  
  }

  addCBThemeField(){
     if (this.checkedTable.length>0 && this.checkedTheme.length>0) {
     //  alert(this.companyID);
      var obj ={
        selectedFields:this.checkedTable,
        selectedTheme:this.checkedTheme,
        selectedDatatype:this.checkedDatatype,
        selectedFileSize:this.changeCheckFileSize,      
        dbType:environment.awsKey,
        customerId:this.companyID
            }
       
       this.cb.addNewThemeData(obj).subscribe(data => {
              if (data.status) {
                this.toastrService.success("Record Inserted successfully");
                this.router.navigateByUrl(`/corporate/contentbuilder-view`);
                
              } else {
                this.toastrService.error(data.message)
              }
            }, error => {
              this.toastrService.error(error.message)
            });
          } else {
            this.toastrService.error("Please select at least one theme.")
          }
    }
  
  /************************New***************************/
  gettingContentBuilderFields() {   
     let self = this;
      this.cb.gettingContentBuilderFields().subscribe(data => {
        self.allTablesobj = data.data;
       // console.log(data.data); 
        }, error => {
      //  console.log(error);
      });
    }
   
    themeLogData() {
      let themeLogs = {
        log_type: "Theme Master View",
        log_name: "Theme Master View",
        log_description: "Theme Master View",
        moduleid: 1,
        moduleitemid: 7,
        per_id: 14
      }
      this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);
  
      }, error => {
      //  console.log(error);
      });
  
    }
    getTheme() {
      let self = this;
      this.ngxSpinnerService.show();
      this.master.getTheme(this.companyID).subscribe(data => {
        self.themeObj = data;
       // console.log(this.themeObj);
       // this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
       // this.ngxSpinnerService.hide();
      });
    }
  
    getCompanyThemes(id){
     event.preventDefault();
      this.companyID =id;
      //alert(id);
      
     // console.log("company themes:"+ id);
      let self = this;
      this.ngxSpinnerService.show();
      this.master.getCompanyTheme(id).subscribe(data => {
        self.themeObj = data.data.rows;
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
  
    }
    
    getCBThemes(id){
      //event.preventDefault();
      this.themeid.setValue(id, {onlySelf: true});
      this.companyID =id;
    //  alert(id);
    //  console.log("company themes:"+ id);
      let self = this;
     this.ngxSpinnerService.show();
      this.cb.getCBCompanyThemes(id).subscribe(data => {
        self.themeObj = data.data;
      this.ngxSpinnerService.hide();
      }, error => {
      //  console.log(error);
        this.ngxSpinnerService.hide();
      });
  
    }
   
    getCompany() {     
      let self = this;
      
      this.clientService.getBUCompanies().subscribe(data => {
        self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
      //  this.company.setValue(this.company_id,{onlySelf: true});
      }, error => {
      //  console.log(error);
      });
    }

}
