<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/campaign-builder"> Campaign Builder</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Theme List
      </li>
    </ol>
  </nav>
</div>
<div class="container mt-2">
  <h5 class="font-weight-bold mt-4 mb-4">Select Theme from List</h5>

  <div class="card-footer">
    <div class="text-center my_class ">
      <button type="button" class="custbtn" routerLink="/corporate/schedule-campaign-builder">Next</button>
    </div>
  </div>

</div>
