import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-newasset',
  templateUrl: './newasset.component.html',
  styleUrls: ['./newasset.component.css']
})
export class NewassetComponent extends BaseComponent implements OnInit {
  
  assests_name;
  assests_description;
  asset_image;
  isactive;
  assestsid;
  assetObj;
  status: boolean = true;
  submitted = false;
  AddAsset: FormGroup;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,) { 
      super();
    }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.assestsid = params.assestsid;
      });
    //get item details using id
    if (this.assestsid) {
      this.master.getAssetById(this.assestsid).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.assetObj = response.data;
        //  console.log(self.assetObj)
          self.dataBinding();
        } else {

        }
      });
    }
    this.AddAsset = this.formBuilder.group({
      assests_name: ['', Validators.required],
      asset_image: [],
      assests_description:[],
    });
  }
  get f() { return this.AddAsset.controls; }
  addNewAsset() {
    this.submitted = true;
    if (this.AddAsset.invalid) {
      return;
    }
    let assetObj = {
      name: this.AddAsset.value.assests_name,
      description: this.AddAsset.value.assests_description,
      asset_image: this.AddAsset.value.asset_image,
      isactive: this.isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingAsset(assetObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/asset`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    // this.assests_name = this.AddAsset.value.assests_name;
    // this.assests_description = this.AddAsset.value.assests_description;
    // this.asset_image = this.assetObj.asset_image;
    //this.isactive = this.assetObj.isactive;
    this.AddAsset = this.formBuilder.group({
      assests_name: [this.assetObj.assests_name, Validators.required],
      assests_description: [this.assetObj.assests_description],
      asset_image:[this.assetObj.asset_image]
    });this.isactive = this.assetObj.isactive;
  }
  updateAsset() {
    this.submitted = true;
    if (this.AddAsset.invalid) {
      return;
    }
      let assetObj = {
        assests_name: this.AddAsset.value.assests_name,
        assests_description: this.AddAsset.value.assests_description,
        asset_image: this.AddAsset.value.asset_image,
        isactive: this.isactive,
        assestsid: this.assestsid
      }
      this.ngxSpinnerService.show();
      this.master.UpdateAsset(assetObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/asset`);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }
  
}

