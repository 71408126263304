import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewprofession',
  templateUrl: './addnewprofession.component.html',
  styleUrls: ['./addnewprofession.component.css']
})
export class AddnewprofessionComponent extends BaseComponent  implements OnInit {

  // name;
  // Description;
  // Icon;
  // ParentId;
  // IsActive;
  prosfession_id;
  professionObjId;
  screenshot_type = true;
  status: boolean = true;
  profession_awskey;
  profession_icon;
  header = "Add New";

  submitted = false;
  themeForm: FormGroup;

  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder,
    private upload:UploadService
  ) {
   super();
  }

  ngOnInit() {
    let self = this;
    this.ngxSpinnerService.show();
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.prosfession_id = params.prosfession_id;
      });
    //get item details using id
    if (self.prosfession_id) {
      this.header = "Edit";
      this.ngxSpinnerService.show();
      this.UpdateProfessionLogData();
      this.master.getProfessionById(this.prosfession_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.professionObjId = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();
        } else {
        }
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addProfessionLogData();
      this.ngxSpinnerService.hide();
    }
    this.themeForm = this.formBuilder.group({
      professionName: ['', Validators.required],
      professionDescription: [],
      professionParentId: [0],
      firstActive: [true],
      professionIcon: []
    });
  }
  get f() { return this.themeForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  addProfessionLogData() {

    let ProfessionLogs = {
      log_type: "Adding New Profession",
      log_name: "Adding New Profession",
      log_description: "Adding New Profession",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ProfessionLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });

  }
  UpdateProfessionLogData() {

    let ProfessionLogs = {
      log_type: "Updating Profession Data",
      log_name: "Updating Profession Data",
      log_description: "Updating Profession Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ProfessionLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  dataBinding() {
    // this.name = this.professionObjId.profession_name;
    // this.Description = this.professionObjId.profession_desc;
    // this.Icon = this.professionObjId.profession_icon;
    // this.ParentId = this.professionObjId.pro_parent_id;
    // this.IsActive = this.professionObjId.isactive;

    this.profession_icon = this.professionObjId.profession_icon;
    if (this.isUrlValid(this.professionObjId.profession_icon)) {
      this.themeForm = this.formBuilder.group({
        professionName: [this.professionObjId.profession_name, Validators.required],
        professionDescription: [this.professionObjId.profession_desc],
        professionParentId: [this.professionObjId.pro_parent_id],
        firstActive: [true],
        professionIcon: [this.professionObjId.profession_icon]
      });
    } else {
      this.themeForm = this.formBuilder.group({
        professionName: [this.professionObjId.profession_name, Validators.required],
        professionDescription: [this.professionObjId.profession_desc],
        professionParentId: [this.professionObjId.pro_parent_id],
        firstActive: [false],
        professionIcon: []
      });
      this.profession_awskey = this.professionObjId.profession_icon;
      this.screenshot_type = false;
    }
  }
  addProffession() {
    this.submitted = true;
  //  console.log(this.themeForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
  //  console.log("themescreenshot -- ", this.themeForm);

    if (this.themeForm.invalid) {
      return;
    }
    this.themeForm.value.professionName = this.themeForm.value.professionName.trim();
    if (this.themeForm.value.professionName === '' || this.themeForm.value.professionName === null || this.themeForm.value.professionName === undefined){
      this.toastrService.error('Please provide correct Profession Name');
      return;
    }
    let { professionName, professionDescription, professionParentId, professionIcon } = this.themeForm.value;
    professionIcon = this.isUrlValid(professionIcon) ? professionIcon : this.profession_awskey ? this.profession_awskey : "";
  // console.log(professionIcon);
    let proffesionObj = {
      name: professionName,
      description: professionDescription,
      icon: professionIcon
    }
    this.ngxSpinnerService.show();
   // console.log(proffesionObj);
    this.master.addingProfession(proffesionObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/professionmaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  updateProfession() {
    this.submitted = true;


    if (this.themeForm.invalid) {
      return;
    }
    this.themeForm.value.professionName = this.themeForm.value.professionName.trim();
    if (this.themeForm.value.professionName === '' || this.themeForm.value.professionName === null || this.themeForm.value.professionName === undefined){
      this.toastrService.error('Please provide correct Profession Name');
      return;
    }
    let { professionName, professionDescription, professionParentId, professionIcon } = this.themeForm.value;
    professionIcon = this.isUrlValid(professionIcon) ? professionIcon : this.profession_awskey ? this.profession_awskey : "";
  //  console.log(professionIcon);

    let proffesionObj = {
      profession_name: professionName,
      profession_desc: professionDescription,
      profession_icon: professionIcon,
      pro_parent_id: professionParentId,
      isactive: this.professionObjId.isactive,
      proid: Number.parseInt(this.prosfession_id, 10)
    }
    this.ngxSpinnerService.show();
    this.master.updateProfession(proffesionObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully")
        this.router.navigateByUrl(`/corporate/professionmaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.profession_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show();
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.profession_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
}
