import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";
import { UploadService } from "src/app/services/uploadfile/upload.service";

@Component({
  selector: "app-readiness-edit",
  templateUrl: "./readiness-edit.component.html",
  styleUrls: ["./readiness-edit.component.css"],
})
export class ReadinessEditComponent extends BaseComponent implements OnInit {
  addActivityForm: FormGroup;
  customer_id;
  getCompanyobj: any = [];
  submitted = false;
  parentList: any[] = [];
  selectedCompany: any;
  readiness_id: any;
  readinessObj: any;
  theme_screenshot;
  theme_screenshot2;
  theme_screenshot3;
  them_awskey = "";
  screenshot_type = true;
  screenshot_type2 = true;
  screenshot_type3 = true;
  constructor(
    private clientsService: ClientService,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private upload: UploadService
  ) {
    super();
  }

  ngOnInit(): void {
    this.customer_id = JSON.parse(
      sessionStorage.getItem("userData")
    ).admin_customer_id;
    this.createForm();
    if (this.customer_id == 0) this.getCompany();
    if (localStorage.getItem("readiness_id")) {
      this.readiness_id = localStorage.getItem("readiness_id");
      this.selectedCompany = localStorage.getItem("readiness_company_id");
      this.ngxSpinnerService.show();
      this.masterService
        .getReadinessResourcesById(this.readiness_id, this.selectedCompany)
        .subscribe((response) => {
          this.ngxSpinnerService.hide();
          if (response.status) {
            this.readinessObj = response.data;
            this.addActivityForm.setValue({
              selectedCompany: this.selectedCompany,
              activity_id: this.readinessObj.activity_id,
              title: this.readinessObj.title,
              description: this.readinessObj.description,
              type: this.readinessObj.type,
              link: this.readinessObj.link,
              // application_link:this.readinessObj.application_link,
              // weight:this.readinessObj.weight,
              orderno: this.readinessObj.orderno,
              firstActive: true,
            });
            if (this.readinessObj.type === "image") {
              this.theme_screenshot = this.readinessObj.link;
            } else if (this.readinessObj.type === "video") {
              this.theme_screenshot2 = this.readinessObj.link;
            }
          }
          this.getParentList();
        });
    }
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  get f() {
    return this.addActivityForm.controls;
  }
  createForm() {
    this.addActivityForm = this.formBuilder.group({
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
      activity_id: ["", Validators.required],
      title: [
        "",
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      description: ["", [Validators.maxLength(100), Validators.required]],
      type: ["", Validators.required],
      link: ["", Validators.required],
      // application_link: ["", Validators.required],
      // weight: ["", Validators.required],
      orderno: ["", Validators.required],
      firstActive: [true],
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  getParentList() {
    this.parentList = [];
    let companyId = this.f.selectedCompany.value;
    this.masterService
      .getReadinessParentList(companyId)
      .subscribe((res: any) => {
        this.parentList = res.data || [];
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.addActivityForm.invalid) {
      return;
    }
    this.ngxSpinnerService.show();
    this.masterService
      .updateResourcesActivity(this.f.selectedCompany.value, {
        ...this.addActivityForm.value,
        company: this.f.selectedCompany.value,
        id: this.readinessObj.id,
      })
      .subscribe(
        (data) => {
          this.ngxSpinnerService.hide();
          if (data.status) {
            this.toastrService.success("Resources updated successfully");
            this.router.navigateByUrl(`/corporate/readiness-resources-list`);
          } else {
            this.toastrService.error(data.message);
          }
        },
        (error) => {
          this.ngxSpinnerService.hide();
          this.toastrService.error(error.message);
        }
      );
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.masterService.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl)
          ? awsUrl
          : "../../../assets/custom/images/broken.png";
      } else {
        event.target.src = "../../../assets/custom/images/broken.png";
      }
    } else {
      event.target.src = "../../../assets/custom/images/broken.png";
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }
  radioChange(type) {
    if (type == "image") {
      this.screenshot_type = !this.screenshot_type;
    } else if (type == "video") {
      this.screenshot_type2 = !this.screenshot_type2;
    } else if (type == "pdf") {
      this.screenshot_type3 = !this.screenshot_type3;
    }
  }
  processFile(event: any, imageInput: any, productImageType) {
    const file: File = imageInput.files[0];
    if (
      productImageType == "image" &&
      /\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false
    ) {
      this.toastrService.error(
        "Please select only (png, jpg, gif or jpeg) files"
      );
      event.target.value = null;
      return false;
    } else if (
      productImageType == "video" &&
      file &&
      /\.(mp4)$/i.test(file.name) === false
    ) {
      this.toastrService.error("Please select only (mp4) files");
      event.target.value = null;
      return false;
    } else if (
      productImageType == "pdf" &&
      file &&
      /\.(pdf)$/i.test(file.name) === false
    ) {
      this.toastrService.error("Please select only (pdf) files");
      event.target.value = null;
      return false;
    } else {
      let title;
      let newFileName;
      if (this.addActivityForm.value.title != "") {
        title = this.addActivityForm.value.title;
        newFileName = title + "." + file.name.split(".").pop();
      } else {
        this.toastrService.error("Before that please enter title");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(newFileName, reader.result, productImageType);
      };
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(newFileName, file, productImageType) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(newFileName, "readiness", file).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        if (res.status) {
          let params = res.data;
          if (params.key) {
            if (productImageType == "image") {
              self.them_awskey = params.Location;
              this.theme_screenshot = `${
                self.them_awskey
              }?timestamp=${new Date().getTime()}`;
              this.f.link.setValue(this.them_awskey);
            } else if (productImageType == "video") {
              self.them_awskey = params.Location;
              this.theme_screenshot2 = `${
                self.them_awskey
              }?timestamp=${new Date().getTime()}`;
              this.f.link.setValue(this.them_awskey);
            } else if (productImageType == "pdf") {
              self.them_awskey = params.Location;
              this.theme_screenshot3 = `${
                self.them_awskey
              }?timestamp=${new Date().getTime()}`;
              this.f.link.setValue(this.them_awskey);
            }
          }
        } else {
          this.toastrService.error("Something went wrong !");
        }
      },
      error: (err: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.error("Something went wrong !");
      },
    });
  }
  isValidYouTubeUrl(url) {
    const youtubeUrlPattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeUrlPattern.test(url);
  }
  typeChange() {
    this.f.firstActive.setValue(true);
    this.f.link.setValue('');
    this.theme_screenshot = "";
    this.theme_screenshot2 = "";
    this.theme_screenshot3 = "";
    this.them_awskey = "";
    this.screenshot_type = true;
    this.screenshot_type2 = true;
    this.screenshot_type3 = true;
  }
  getFileExtension(filename: string): string {
    return filename.split(".").pop().toLowerCase();
  }
}
