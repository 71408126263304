import { BaseComponent } from '../base.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AudienceBuilderService } from '../../services/segmentation/audience-builder.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from '../../services/master/master.service';
import { DatePipe } from '@angular/common';
import csc from 'country-state-city';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-audience-builder',
  templateUrl: './audience-builder.component.html',
  styleUrls: ['./audience-builder.component.css']
})
export class AudienceBuilderComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  currentUser;
  companyId;
  buAudienceObj: any = [];
  queryResult: any = [];

  // ord = 'asc';
  // colname = 'aud_name';
  ord = 'desc';
  colname = 'createdon';
  totalNumber: number = 0;
  page = 1;
  limit = 10;
  skip = 0;

  companyAttributeDataObj = [];
  showProductValue: boolean = true;
  audienceName;
  audienceTargetName;

  constructor(private audienceBuilderService: AudienceBuilderService,
    private modalService: NgbModal,
    private clientsService: ClientService,
    private master: MasterService,
    public datePipe: DatePipe,) {
    super();
  }

  ngOnInit() {
    localStorage.removeItem("editAudience");
    localStorage.removeItem("editAudienceID");
    localStorage.removeItem("cloneAudience");
    localStorage.removeItem("BackStepAudience");
    localStorage.removeItem("audienceTarget");

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.currentUser.admin_customer_id;
    this.getBUAudience();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.audienceBuilderService.getBUAudienceList(this.companyId, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.buAudienceObj = [] }
          else { this.buAudienceObj = res.data.rows }
          this.totalNumber = res.data.total;
        } else {
          this.buAudienceObj = [];
          this.totalNumber = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.buAudienceObj = [];
        this.totalNumber = 0;
        this.ngxSpinnerService.hide();
      });
  }

  //  ################  Get List of BU Audience  #################  //

  getBUAudience() {
    this.skip = (this.page - 1) * this.limit;
    let searchTerm = this.searchText.nativeElement.value;
    this.ngxSpinnerService.show();

    this.audienceBuilderService.getBUAudienceList(this.companyId, this.limit, this.skip, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.buAudienceObj = [] }
        else { this.buAudienceObj = data.data.rows }
        this.totalNumber = data.data.total;
      } else {
        this.buAudienceObj = [];
        this.totalNumber = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  setOrder(col) {
    this.colname = col;
    if (this.ord == 'asc') {
      this.ord = 'desc';
    } else {
      this.ord = 'asc';
    } this.getBUAudience();
  }

  deleteAudienceBuilder(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.audienceBuilderService.deleteAudienceBuilder(this.companyId, id).subscribe(data => {
            if (data.status) {
              this.getBUAudience();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            }
            else {
              this.toastrService.error(data.message)
            }
            this.ngxSpinnerService.hide();
          }, error => {
            //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  edit(id) {

    let URL = '/corporate/schedule-bu-built-audience?edit=1&audid=' + id;
    this.router.navigateByUrl(URL);
  }

  goToAudienceList(audid) {
    let URL = '/corporate/test-bu-built-audience' + '?campaign=no&audid=' + audid;
    this.router.navigateByUrl(URL);
  }

  previewdata(modalName, id) {

    this.ngxSpinnerService.show();

    this.queryResult = [];
    this.audienceBuilderService.getQueryBuilderView(this.companyId, id).subscribe(data => {
      if (data.status && data.data) {

        this.audienceName = data.data[0].aud_name;
        this.audienceTargetName = data.data[0].audience_name;
        data.data.forEach(element => {
          if (element.querydata.length > 0) {

            element.querydata.forEach(element1 => {


              if (element1.field_name.includes('Product')) {

                this.showProductValue = false;

                this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {
                  let productList = data.data.rows.filter(item => item.product_catalog_id == element1.query_value);
                  element1.query_value = productList[0].product_name;
                  this.showProductValue = true;


                }, error => {
                  // console.log(error);
                });
              }
              if (element1.field_name == "Attribute") {
                // this.ngxSpinnerService.show();
                this.showProductValue = false;
                this.master.getFullAttributes(this.companyId, 9999).subscribe(data => {
                  this.companyAttributeDataObj = data.data.rows;
                  let attributeList = this.companyAttributeDataObj.filter(item => item.attribute_id == element1.query_value);
                  element1.query_value = attributeList[0].attribute_name;
                  this.showProductValue = true;
                }, error => {
                  // console.log(error);

                });




              }
              if (element1.field_name == "Country") {
                let countryData = csc.getAllCountries().filter(item => item.sortname == element1.query_value);
                element1.query_value = countryData[0].name;
              }
              if (element1.field_name == "Type of Campaign") {
                if (element1.query_value == 67) element1.query_value = "JOIN";
                else if (element1.query_value == 68) element1.query_value = "HOST";
                else element1.query_value = "SHOP";
              }
              if (element1.field_name == "Sex") {
                element1.query_value = (element1.query_value == 2 ? "Female" : "Male");
              }

              if (element1.query_value == "true" || element1.query_value == 1) {
                element1.query_value = "Yes"
              }
              if (element1.query_value == "false" || element1.query_value == 0) {
                element1.query_value = "No"
              }


              if (element1.field_name && (element1.field_name.includes('Date'))) {
                element1.query_value = this.datePipe.transform(element1.query_value, 'MM-dd-yyyy');

              }

              this.queryResult.push(element1)



            });

          }
          else {

            this.ngxSpinnerService.hide();

          }

        });

        this.ngxSpinnerService.hide();


      } else {


        this.ngxSpinnerService.hide();
      }

    }, error => {
      this.ngxSpinnerService.hide();
    });


    this.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
      //  console.log("reason", reason);
    });

  }

  goEditAudience(id) {

    localStorage.setItem("editAudienceID", id);
    let URL = '/corporate/manage-audience-builder';
    this.router.navigateByUrl(URL);

  }
  closeModel() {

    this.modalService.dismissAll();
    //this.modalService.close();

  }

  onClone(id) {


    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Create Clone?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.audienceBuilderService.getCloneAudience(this.companyId, id).subscribe(data => {
            if (data.status) {
              localStorage.setItem("editAudienceID", data.data);
              localStorage.setItem("cloneAudience", '1');
              this.router.navigate(['/corporate/manage-audience-builder']);
            } else {
              this.buAudienceObj = [];
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }



}
