import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';



@Component({
  selector: 'app-content-builder-section',
  templateUrl: './content-builder-section.component.html',
  styleUrls: ['./content-builder-section.component.css']
})
export class ContentBuilderSectionComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  totalCount: number = 0;
  themeObj: any = [];
  ord = 'asc';
  colname = "section_name";
  limit = 10;
  offset = 0;
  page = 1;

  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;

  today: number = Date.now();
  constructor(
    private clientService: ClientService,
    private cb: CbService,
  ) {
    super();
  }
  ngOnInit() {
    //this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    // if(this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;     
    //if(this.currentUser.admin_customer_id!='0') this.getTheme();
    // this.templateLogData();
    this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.cb.getCompanySection(this.companyID, this.limit, this.offset, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.themeObj = [] }
          else { this.themeObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.themeObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  onCompanyChange(company) {
    this.companyID = company;
    if(this.companyID > 0){this.getCompanySection();}else{
      this.themeObj = [];
      this.totalCount = 0;
      this.page = 1;
        }
  }

  setOrder(value: string) {
    this.colname = value;
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else { this.ord = 'asc' }
    this.getCompanySection();
  }

  templateLogData() {
    let themeLogs = {
      log_type: "Template Content Builder Master View",
      log_name: "Template Content Builder Master View",
      log_description: "Template Content Builder Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);

    }, error => {
      // console.log(error);
    });
  }

  getCompanySection() {
    let searchTerm = this.searchText.nativeElement.value;
    this.offset = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.cb.getCompanySection(this.companyID, this.limit, this.offset, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.themeObj = [] }
        else { this.themeObj = data.data.rows }
        this.totalCount = data.data.total;
        this.ngxSpinnerService.hide();
      } else {
        this.themeObj = [];
        this.totalCount = 0;
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  editSection(id) {

    this.router.navigate(['/corporate/add-cb-section'], { queryParams: { companyID: this.companyID, id: id } });
  }
  deleteSection(id) {
    let obj = {
      id: id,

    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.cb.deleteSection(this.companyID, id).subscribe(data => {
            if (data.status) {
              this.getCompanySection()
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = this.themeObj.find(element => {
      if (element.sectionid === id) {
        return true;
      }
    });
    //obj = obj.get_master_theme;
    // obj.isactive = value;
    //console.log(obj);


    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;

          // console.log(obj);

          this.cb.updateSectionStatus(obj).subscribe(data => {
            // this.getTheme();
            this.getCompanySection()
            this.toastrService.success("Section Status Changed Successfully")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }


      }).catch((err) => {
        // console.log(err)
        // event.source.checked = !event.source.checked;
        // this.checked = obj.isactive;
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });


  }



  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }
}