<!-- Breadcrumb Section Start -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">Users</a></li> -->
      <li class="breadcrumb-item"><a routerLink="/corporate/teamassociation">Team Association</a></li>
      <li class="breadcrumb-item active" aria-current="page">Association {{!this.editMode ? "New" :  "Existing"}}  Team Member</li>
    </ol>
  </nav>
</div>
<!-- Breadcrumb Section End -->
<!-- Web Form Section Start -->
<div class="container">
  <div class="row">
    <div class="col-sm-10 col-md-8 col-xl-5">
      <div class="card mt-1">
        <div class="card-body">
          <div *ngIf="compId === 0">
            <label for="exampleForm2 " >Client / Company Name<span class="required">*</span> </label>
            <select class="form-control formstyle" [(ngModel)]="cid"  (change)="getTeamList(cid)" required >
             <!-- <option value="">--Select--</option> -->
              <ng-container *ngFor="let item of CustomerData">
              <option *ngIf="item.dbstatus == true" value={{item.customer_id}}> {{item.customer_company_name}}</option>
            </ng-container>
            </select>
          </div>
          <div class="mt-2">
            <label for="exampleForm2 " >Team Leader <span class="required">*</span></label>
            <select *ngIf="!editMode" class="form-control formstyle" [(ngModel)]="teamHead" (change)="getMemberList(teamHead)"  required >
              <!-- <option value="">--Select--</option> -->
              <option *ngFor="let item of TeamData" value={{item.userid}}> {{item.full_name}}  ({{item?.user_email_primary}})</option>
            </select>
            <select *ngIf="editMode" class="form-control formstyle" [(ngModel)]="teamHead"  required >
              <!-- <option value="">--Select--</option> -->

              <option *ngFor="let item of TeamData" value={{item.userid}}> {{item.full_name}} ({{item?.user_email_primary}})</option>

            </select>
          </div>
          <div class="mt-2">
            <label for="exampleForm2 " >Team Member <span class="required">*</span></label>
            <select class="form-control formstyle" [(ngModel)]="teamMember"  required >
              <!-- <option value="">--Select--</option>  -->
              <option *ngFor="let item of TeamMemberData" value={{item.userid}}>{{item.full_name}}  ({{item?.user_email_primary}})</option>
            </select>
          </div>
        </div>
        <div class="card-footer text-center">
          <button *ngIf="!editMode" type="button" class="custbtn" (click)="associateDsUser()">Submit</button>
          <button *ngIf="editMode" type="button" class="custbtn" (click)="updateAssociateDsUser()">Update</button>
        </div>
    </div>
  </div>
</div>
<!-- Web Form Section End -->