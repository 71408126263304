import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseserviceService} from '../baseservice.service';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BUSegmentationService {

  public baseUrl;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.segBaseUrl + 'api/segmentation/';
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  // tohandle the error

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');

  }

  //  ##########  Check for Audience Data for BU ###############  //

  checkAudienceDataForBU(buid){
    const URL = this.baseUrl + 'audienceBu/checkAudienceData' + '/' + buid;
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }





  //  #########   Get ACTIVE Audience List  ####################  //

  getActiveAudienceList(){
    const URL = this.baseUrl + 'audience/activeList';
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ########  Add Audience for BU ##########################  //

  addAudienceforBU(obj){
    const URL = this.baseUrl + 'audienceBu/pushSegmentData';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  //  ########  Get Audience List for BU  ####################  //

  getBUAudienceList(limit, offset, buid) {
    const URL = this.baseUrl  + 'audienceBu/' + buid + '/' + limit + '/' + offset;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #############  Update BU Audience #####################  //

  updateBUAudience(obj){
    const URL = this.baseUrl  + 'audienceBu/updatedStatus';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Delete BU Audience #######################  //

  deleteBUAudience(auid, buid) {
    const URL = this.baseUrl  + 'audienceBu' + '/' + buid + '/' + auid;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Add New Audience for BU  ##################  //

  addNewBUAudience(obj){
    const URL = this.baseUrl  + 'audienceBu';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #############  Get BU Audience data by ID #############  //

  getBUAudienceById(buid, id){
    const URL = this.baseUrl  + 'audienceBu/' + buid + '/' + id;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Update BU Audience data by ID  ###########  //

  updateBUAudienceObject(obj, id){
    const URL = this.baseUrl  + 'audienceBu/' + id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get BU Entities List ########################  //

  getBUEntitiesListing(buid, limit, offset) {
    const URL = this.baseUrl  + 'entitiesBu/' + buid + '/' + limit + '/' + offset;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ################  Add New BU Entity  #######################  //

  addNewBUEntity(obj){
    const URL = this.baseUrl  + 'entitiesBu';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ########### Update BU Entity Status  ###############  //

  updateBUEntityStatus(obj){
    const URL = this.baseUrl  + 'entitiesBu/updatedStatus';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Delete BU Entity ######################  //

  deleteBUEntity(id, buid){
    const URL = this.baseUrl  + 'entitiesBu' + '/' + buid + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Get BU Entity by ID ###################  //

  getBUEntityById(buid, entid) {
    const URL = this.baseUrl  + 'entitiesBu/' + buid + '/' + entid;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //   ###############  Update BU Entity Data ####################  //

  updateBUEntity(obj, id){
    const URL = this.baseUrl  + 'entitiesBu/' + id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Fetch BU Attributes List  #################  //

  getBUAttributesListing(buid, limit, offset){
    const URL = this.baseUrl  + 'attributeBu/' + buid + '/' + limit + '/' + offset;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Update BU Attribute Status  ####################  //

  updateBUAttributeStatus(obj){
    const URL = this.baseUrl  + 'attributeBu/updatedStatus';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Delete BU Attribute Status   #################  //

  deleteBUAttribute(id, buid){
    const URL = this.baseUrl  + 'attributeBu' + '/' + buid + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ####################  Get Active Audience List for BU  ##################  //

  getAudienceListforBU(id){
    const URL = this.baseUrl  + 'audienceBu/' + id + '/activeList';
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###################  Fetch Entities List for BU by Audience ID  ###############  //

  getEntitiesListforBUbyAudienceID(buid, id){
    const URL = this.baseUrl  + 'audienceEntitiesRelationBU/' + buid + '/' + id;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));


  }

  //  #################  Fetch Attributes and Operators Data by Entity ID ###############  //

  getAttributeandOperatorforBUEntity(buid, id){
    const URL = this.baseUrl  + 'entitiesAttibuteRelationBU/getAtributeOpratorByentityId/' + buid + '/' + id;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  // ################  Update Audience for BU ##############################  //

  updateAudienceForBU(obj){
    const URL = this.baseUrl  + 'audienceBu/updateSegmentData';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //   ##################  Update Entities data for BU  ###################  //

  updateEntitiesForBU(obj) {
    const URL = this.baseUrl  + 'entitiesBu/updateSegmentData';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #################  Update Attributes for BU  #####################  //

  updateAttributesForBU(obj){
    const URL = this.baseUrl  + 'attributeBu/updateSegmentData';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##################  Update Operator List for BU ###################  //

  updateOperatorsForBU(obj){
    const URL = this.baseUrl  + 'operatorBu/updateSegmentData';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

}
