import { Component, OnInit } from '@angular/core';
import { NgFlowchart, NgFlowchartStepComponent } from '@joelwenzel/ng-flowchart';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteStepComponent } from './route-step/route-step.component';

@Component({
  selector: 'app-custom-step',
  templateUrl: './custom-step.component.html',
  styleUrls: ['./custom-step.component.css']
})
export class CustomStepComponent extends NgFlowchartStepComponent {
  // rskmodellogoFlag = this.ReskinningServicr.rskModelLogoFlag;
  // rskmodellogo = this.ReskinningService.rskModelLogo;
  routes = [];
  conditions = [
    { id: 1, name: 'Contact campagin' },
    { id: 2, name: 'Contact device' },
    { id: 3, name: 'Contact segment' },
    { id: 4, name: 'Contact owner' },
    { id: 5, name: 'Contact tags' }
  ]
  showNewRouteOption: boolean = false;
  newRouteCondition = null;
  conditionValue = null;
  actionValue = null;
  activeButton = 'immediately';
  executionData = { type: '', value: '1' };
  routeOption = '';
  constructor(
    private ngbModal: NgbModal
  ) {
    super();
  }
  ngOnInit(): void {
  }

  canDrop(dropEvent: NgFlowchart.DropTarget): boolean {
    return true;
  }

  canDeleteStep(): boolean {
    return true;
  }

  getDropPositionsForStep(pendingStep: NgFlowchart.PendingStep): NgFlowchart.DropPosition[] {
    if (pendingStep.template !== RouteStepComponent) {
      return ['ABOVE', 'LEFT', 'RIGHT'];
    }
    else {
      return ['BELOW'];
    }
  }

  onAddRoute() {
    if (!this.actionValue) {
      return;
    }
    if (this.activeButton != 'immediately' && !this.executionData.value) { return };
    if (this.activeButton == 'date-time') { this.executionData.type = 'date' };

    let selectedAction = this.conditions.filter((e) => e.id == this.actionValue)
    let route = {
      name: selectedAction[0].name,
      condition: '',
      sequence: null,
      nodeType: 'action',
      executionType: '',
      executionData: {},
      conditionId: selectedAction[0].id,
      conditionFlowType: ''
    }
    let index = this.routes.push(route);
    route.sequence = index;
    route.executionType = this.activeButton;
    route.executionData = this.executionData;

    this.addChild({
      template: RouteStepComponent,
      type: 'route-step',
      data: route
    }, {
      sibling: true
    });
    this.ngbModal.dismissAll();
  }

  delete() {
    //recursively delete
    this.destroy(true);
  }

  ///////////////
  onAddNewRoute(modalEle: HTMLElement) {
    this.showNewRouteOption = false;
    this.ngbModal.open(modalEle, { size: 'lg', backdrop: false });
  }
  createConditionRoutes() {
    if (!this.conditionValue) {
      return;
    }
    if (this.activeButton != 'immediately' && !this.executionData.value) { return };
    if (this.activeButton == 'date-time') { this.executionData.type = 'date' }

    let conditionObj = this.conditions.filter((e) => e.id == this.conditionValue)
    let route = {
      name: conditionObj[0].name,
      condition: '',
      sequence: null,
      nodeType: 'condition',
      conditionId: 0,
      executionType: '',
      executionData: {},
      conditionFlowType: ''
    }
    let index = this.routes.push(route);
    route.sequence = index;
    route.conditionId = conditionObj[0].id;
    route.executionType = this.activeButton;
    route.executionData = this.executionData


    this.addChild({
      template: RouteStepComponent,
      type: 'route-step',
      data: route
    },
      {
        sibling: true
      });
    this.ngbModal.dismissAll();
  }

  resetValues() {
    this.activeButton = 'immediately';
    this.executionData = { type: '', value: '1' };
  }
}
