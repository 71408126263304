<div class="container mt-2" *ngIf="finalStatus">
  <h5 class="font-weight-bold mt-4 mb-0">MC Information</h5>
</div>

<div class="container mt-2" *ngIf="!finalStatus">

  <h5 class="font-weight-bold mt-4 mb-0">MC Information</h5>

  <form  class="form-horizontal mt-3" [formGroup]="mcinformationform" *ngIf="editStatus || !mcInfoStatus">

    <div class="row">
      <div class="col-md-10 col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="mt-1">
              <div class="row">
                <div class="col-3">
                  <label for="serviceURL" class="position-relative pr-2">Service URL <span style="color: red">*</span></label>
                </div>
                <div class="col-7">
                  <input type="password" id="serviceURL" formControlName="serviceURL"
                         class="form-control form-control-md" required
                         [ngClass]="{ 'is-invalid': submitted && f.serviceURL.errors }" maxlength="80" appSpace
                         maxlength="80">
                  <div *ngIf="submitted && f.serviceURL.errors" class="invalid-feedback">
                    <div *ngIf="f.serviceURL.errors.required"> Service URL is Required</div>
                    <div *ngIf="f.serviceURL.errors?.maxlength">You can enter only 80 characters</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="accid" class="position-relative pr-2">Account ID <span style="color: red">*</span></label>
                </div>
                <div class="col-7">
                  <input type="text" id="accid" formControlName="accountId"
                         class="form-control form-control-md" required
                         [ngClass]="{ 'is-invalid': submitted && f.accountId.errors }" maxlength="80" appSpace
                         maxlength="80">
                  <div *ngIf="submitted && f.accountId.errors" class="invalid-feedback">
                    <div *ngIf="f.accountId.errors.required || f.accountId.hasError('whitespace')"> Account ID is Required</div>
                    <div *ngIf="f.accountId.errors?.maxlength">You can enter only 80 characters</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="clientid" class="position-relative pr-2">Client ID <span style="color: red">*</span></label>
                </div>
                <div class="col-7">
                  <input type="text" id="clientid" formControlName="clientId"
                         class="form-control form-control-md" required
                         [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }" maxlength="80" appSpace
                         maxlength="80">
                  <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                    <div *ngIf="f.clientId.errors.required || f.clientId.hasError('whitespace')"> CLient ID is Required</div>
                    <div *ngIf="f.clientId.errors?.maxlength">You can enter only 80 characters</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="clientsecret" class="position-relative pr-2">Client Secret <span style="color: red">*</span></label>
                </div>
                <div class="col-7">
                  <input type="text" id="clientsecret" formControlName="clientSecret"
                         class="form-control form-control-md" required
                         [ngClass]="{ 'is-invalid': submitted && f.clientSecret.errors }" maxlength="80" appSpace
                         maxlength="80">
                  <div *ngIf="submitted && f.clientSecret.errors" class="invalid-feedback">
                    <div *ngIf="f.clientSecret.errors.required || f.clientSecret.hasError('whitespace')"> CLient Secret is Required</div>
                    <div *ngIf="f.clientSecret.errors?.maxlength">You can enter only 80 characters</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="tokenurl" class="position-relative pr-2">Token URL </label>
                </div>
                <div class="col-7">
                  <input type="text" id="tokenurl" formControlName="tokenUrl"
                         class="form-control form-control-md"
                          maxlength="80" appSpace
                         maxlength="80">
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="version" class="position-relative pr-2">Version <span style="color: red">*</span></label>
                </div>
                <div class="col-7">
                  <input type="text" id="version" formControlName="version"
                         class="form-control form-control-md" required
                         [ngClass]="{ 'is-invalid': submitted && f.version.errors }" maxlength="80" appSpace
                         maxlength="80">
                  <div *ngIf="submitted && f.version.errors" class="invalid-feedback">
                    <div *ngIf="f.version.errors.required || f.version.hasError('whitespace')"> Version is Required</div>
                    <div *ngIf="f.version.errors?.maxlength">You can enter only 80 characters</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="grant" class="position-relative pr-2">Grant Type</label>
                </div>
                <div class="col-7">
                  <input type="text" id="grant" formControlName="grantType"
                         class="form-control form-control-md"
                         maxlength="80" appSpace
                         maxlength="80">
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="scopes" class="position-relative pr-2">Scopes </label>
                </div>
                <div class="col-7">
                  <input type="text" id="scopes" formControlName="scopes"
                         class="form-control form-control-md"
                          maxlength="80" appSpace
                         maxlength="80">
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="rdtimeout" class="position-relative pr-2">Read Timeout </label>
                </div>
                <div class="col-7">
                  <input type="text" id="rdtimeout" (keydown)="isNumber($event)" class="form-control" name="rdtimeout" formControlName="readTimeout"
                          />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label for="maxidletimeout" class="position-relative pr-2">Max Idle Timeout </label>
                </div>
                <div class="col-7">
                  <input type="text" id="maxidletimeout" (keydown)="isNumber($event)" class="form-control" name="maxidletimeout" formControlName="maxIdleTimeout"
                          />
                </div>
              </div>

            </div>

          </div>

          <div class="card-footer text-center mt-1" >
            <div class="my_class text-center" >
              <button type="submit" class="custbtn" *ngIf="!editStatus"  (click)="submitMCInformation()" >Submit</button>
              <button type="submit" class="custbtn" *ngIf="editStatus" (click)="updateMCInformation()">Update</button>
              <button type="submit" class="custbtn" *ngIf="editStatus"  (click)="cancelMcInfoUpdate()">Cancel</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>

  <!-- Display View -->

  <!--  Panel 5 -->
  <div class="row">

    <div class="col-xl-10"  *ngIf="!editStatus && mcInfoStatus">

      <div class="row">
        <div class="col-12">
          <h5  *ngIf="!connectionStatus" class="float-right" style="color: red">Disconnected!!</h5>
        <h5 *ngIf="connectionStatus" class="float-right" style="color: green">Connected!!</h5>
        </div>
      </div>

        <div class="row">
        <div class="col-12">
      <div class="card mt-1">
        <div class="card-body" >
          <div class="row">
            <div class="col-sm-6">
<!--              <p><b>URL:</b> {{mcData.url}}</p>-->
              <p><b>URL:</b> **************</p>
<!--              <p><b>Client Secret:</b> {{mcData.client_secret}}</p>-->
              <p><b>Client Secret:</b> ****************</p>
              <p><b>Timeout Interval:</b> {{mcData.timeout_interval}}</p>

              <p><b>Version:</b> {{mcData.version}}</p>

            </div>
            <div class="col-sm-6">
<!--              <p><b>Account ID: </b>{{mcData.account_id}}</p>-->
              <p><b>Account ID: </b> *****************</p>
              <p><b>Grant Type:</b> {{mcData.grant_type}}</p>
              <p><b>Max Idle Timeout:</b> {{mcData.max_idle_timeout}}</p>
<!--              <p><b>Client ID:</b> {{mcData.client_id}}</p>-->
              <p><b>Client ID:</b> ****************</p>
              <p><b>Token URL:</b> {{mcData.token_url}}</p>
              <p><b>Scopes:</b> {{mcData.scopes}}</p>

            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center my_class ">
            <button type="button" class="custbtn" (click)="goToUpdateFormView()">Update Connection</button>
            <button type="button" class="custbtn"  [disabled]="checkConnStatus()" (click)="goToMcSchedule()">Schedule</button>

            <button type="button" class="custbtn"  [disabled]="checkConnStatus()" (click)="goToMcDataMapping()">Mapping</button>

          </div>
        </div>

      </div>

      </div>
      </div>


    </div>
  </div>

</div>
