import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import {map } from 'rxjs/operators';
import { throwError,BehaviorSubject} from 'rxjs';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  public baseUrl;
  public UserData = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 ;
    var userdata = JSON.parse(localStorage.getItem("UserData"));
    this.UserData.next(userdata);
  }

  
  getCustomerSubscriptionList(value:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `api/report/customerAdminSubscriptionReport`,body)
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
        //  console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }
 

  getDsSubscriptionList(value:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `api/report/dsAdminSubscriptionReport`,body)
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
        // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }

  getMsgConsumption(st_date, ed_date, custid) {
    return this.http.get<any>(this.baseUrl + 'api/report/getMsgConsumption/'+st_date+'/'+ed_date+"/"+custid)
      .pipe(
        map(data => {
          if (!data.status) {
          //  console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

  getHistoricalMsgConsumption(st_date, ed_date, customerId) {
    return this.http.get<any>(this.baseUrl + 'api/report/getHistoricalMsgCount/'+st_date+'/'+ed_date+'/'+customerId)
      .pipe(
        map(data => {
          if (!data.status) {
            // console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

  getHistoricalContactConsumption(st_date, ed_date, customerId) {
    return this.http.get<any>(this.baseUrl + 'api/report/getHistoricalContactCount/'+customerId+'/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
           // console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }


  getHistoricalMsgConsumedByAll(st_date,ed_date) {
    return this.http.get<any>(this.baseUrl + 'api/report/getHistoricalMsgConsumedByAll/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
          //  console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }
  getHistoricalContactConsumedByAll(st_date,ed_date,cid) {
    return this.http.get<any>(this.baseUrl + 'api/report/getHistoricalContactConsumedByAll/'+cid+'/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
            
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

  getMsgPurchased(st_date, ed_date) {
    return this.http.get<any>(this.baseUrl + 'api/report/msg_purchased/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        })
        );
  } 

  getMessageBalance(st_date, ed_date) {
    return this.http.get<any>(this.baseUrl + 'api/report/getMessageBalance/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  } 
  getContactBalance(st_date, ed_date) {
    return this.http.get<any>(this.baseUrl + 'api/report/getContactBalance/'+st_date+'/'+ed_date)
      .pipe(
        map(data => {
          if (!data.status) {
            
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

    gettotalpurchasecontacts(st_date,ed_date){    
          return this.http.get<any>(this.baseUrl + 'api/report/gettotalpurchasecontacts/'+st_date+'/'+ed_date)
          .pipe(
          map(data=>{
          if(!data.status)
          {  // console.error(data);
            // data.message="Something went wrong.Please try again.";
          }
          return data;
        }),
      );
    }

        getContactConsumption(st_date, ed_date,custid:any){
          
          return this.http.get<any>(this.baseUrl + `api/report/getContactConsumption/`+custid+'/'+st_date+'/'+ed_date)
          .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
            
            data.message="Something went wrong.Please try again."
          }
        }
        return data;
      }),
      );
        }

        getUsedcontacts(custid,date1,date2){
          var body ={custid:custid,date1:date1,date2:date2};
          return this.http.post<any>(this.baseUrl + `api/report/getUsedcontacts`,body)
          .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
            //console.error(data.message);
            data.message="Something went wrong.Please try again."
          }
        }
        return data;
      }),
      );
        }

        getMargoUsedcontacts(custid,date1,date2){
           var body ={custid:custid,date1:date1,date2:date2};
        return this.http.post<any>(this.baseUrl + `api/report/getMargoUsedcontacts`,body)
        .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
        //  console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
      }

    //  getMarketActivity(custid,date1,date2,user_status){
      getMarketActivity(custid,report_status,user_status,cname){
        var body ={customer_id:custid,custid:custid,report_status:report_status,user_status:user_status,search:cname};
        return this.http.post<any>(this.baseUrl + `api/report/getMarketActivity`,body)
        .pipe(
      map(data=>{      
      return data;
    }),
    );
      }

      getTotalCampaign(custid){
          var body ={custid:custid};
          return this.http.post<any>(this.baseUrl + `api/report/gettotalCampaign`,body)
          .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
           // console.error(data.message);
            data.message="Something went wrong.Please try again.";
          }
        }
        return data;
      }),
      );
        }

        getTotalCampaigndata(param){
          var body =param;
          return this.http.post<any>(this.baseUrl + `api/report/getTotalCampaigndata`,body)
          .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
            //console.error(data.message);
            data.message="Something went wrong.Please try again.";
          }
        }
        return data;
      }),
      );
        }
        

      ///DS//
      getMarketActivityDS(custid,report_status,user_status){
        var body ={custid:custid,report_status:report_status,user_status:user_status};
        return this.http.post<any>(this.baseUrl + `api/report/getMarketActivityDS`,body)
        .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
      }

      //optin-out//
      getOPTinoutDS(custid,report_status,user_status, limit, skip){
        var body ={custid:custid,report_status:report_status,user_status:user_status, limit:limit, skip:skip};
        console.log("payload->",body);
        return this.http.post<any>(this.baseUrl + `api/report/getOPTinoutDS`,body)
        .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
      }

                               /*CSV Download */

  LoginHistoryCsv(body) {
    return this.http.post<any>(this.baseUrl + `api/log/loginLogs/LoginHistoryCsv`,body )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }))
       }

     //**********************Toataldata******************************************/
     select_total_campaign(value:any) {
      var body = value;
      return this.http.post<any>(this.baseUrl + `api/report/select_total_campaign`,body)
         .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
          //  console.error(data.message);
            data.message="Something went wrong.Please try again."
          }
        }
        return data;
      }),
      );
    }
        select_bu_email(value:any) {
          var body = value;
          return this.http.post<any>(this.baseUrl + `api/report/select_bu_email`,body)
             .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
               // console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          );
        }
       
        select_bu_tot_snippet(value:any) {
          var body = value;
          return this.http.post<any>(this.baseUrl + `api/report/select_bu_tot_snippet`,body)
             .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
               // console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          );
        }
       
       
       
        select_bu_optout_email(value:any) {
          var body = value;
          return this.http.post<any>(this.baseUrl + `api/report/select_bu_optout_email`,body)
             .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
               // console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          );
        }
       
        select_bu_optout_sms(value:any) {
          var body = value;
          return this.http.post<any>(this.baseUrl + `api/report/select_bu_optout_sms`,body)
             .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
               // console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          );
        }
       
        select_bu_sms(value:any) {
          var body = value;
          return this.http.post<any>(this.baseUrl + `api/report/select_bu_sms`,body)
             .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
               // console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          );
        }
              
     //****************************************************************/ 
     select_click(value:any) {
      var body = value;
      return this.http.post<any>(this.baseUrl + `api/report/select_click`,body)
         .pipe(
        map(data=>{
        if(!data.status)
        {
          if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
          {
           // console.error(data.message);
            data.message="Something went wrong.Please try again."
          }
        }
        return data;
      }),
      );
    }
    
  //total user data
  select_ds_host_active(value:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `api/report/select_ds_host_active`,body)
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
        //  console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }
select_ds_host_inactive(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_ds_host_inactive`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        //console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_all_campaign(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_all_campaign`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_all_prospect(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_all_prospect`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_customer_campaign(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_customer_campaign`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_pros_shop(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_pros_shop`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_pros_host(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_pros_host`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_pros_join(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_pros_join`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_cust_shop(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_cust_shop`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
    //    console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_cust_host(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_cust_host`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        //console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
select_campaign_cust_join(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/select_campaign_cust_join`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
      //  console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_compaign_event(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_compaign_event`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_compaign_event_inperson(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_compaign_event_inperson`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
      //  console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_event_virtual(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_event_virtual`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}




tot_campaign_event_imperson_virtual(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_event_imperson_virtual`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_training(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_training`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
      //  console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_training_encouregement(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_training_encouregement`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_training_training(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_training_training`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_reimplencement(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_reimplencement`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_reimmplencement_price(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_reimmplencement_price`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
      //  console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}
tot_campaign_crosssell(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_crosssell`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}

tot_campaign_crosssell_price(value:any) {
  var body = value;
  return this.http.post<any>(this.baseUrl + `api/report/tot_campaign_crosssell_price`,body)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
       // console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  );
}    
    

DS_list(body) {
        var body = body;
        return this.http.post<any>(this.baseUrl + `api/report/ds_list`,body)
           .pipe(
          map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
             // console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        );
      }    
  
  select_ds_total(body) {
    var body = body;
    return this.http.post<any>(this.baseUrl + `api/report/select_ds_total`,body)
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
        //  console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }    

            select_ds_total_inactive(body) {
              var body = body;
              return this.http.post<any>(this.baseUrl + `api/report/select_ds_total_inactive`,body)
                 .pipe(
                map(data=>{
                if(!data.status)
                {
                  if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
                  {
                   // console.error(data.message);
                    data.message="Something went wrong.Please try again."
                  }
                }
                return data;
              }),
              );
            }    
         
            select_ds_total_active(body) {
              var body = body;
              return this.http.post<any>(this.baseUrl + `api/report/select_ds_total_active`,body)
                 .pipe(
                map(data=>{
                if(!data.status)
                {
                  if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
                  {
                   // console.error(data.message);
                    data.message="Something went wrong.Please try again."
                  }
                }
                return data;
              }),
              );
            }

            getClosingBalanceContacts(){
              var body = body;
              return this.http.post<any>(this.baseUrl + `api/report/getClosingBalanceContacts`,body)
                 .pipe(
                map(data=>{
                if(!data.status)
                {
                  if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
                  {
                  //  console.error(data.message);
                    data.message="Something went wrong.Please try again."
                  }
                }
                return data;
              }),
              );
            }
            
            getConsumtioncontactdata(custid){
              var body = body;
              return this.http.post<any>(this.baseUrl + `api/report/getConsumtioncontactdata`,body)
                 .pipe(
                map(data=>{
                if(!data.status)
                {
                  if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
                  {
                   // console.error(data.message);
                    data.message="Something went wrong.Please try again."
                  }
                }
                return data;
              }),
              );

            }
          

getConsumtioncontacts(startDate,endDate,cid){
  var body = {cid:cid,date1:startDate,date2:endDate};
  
              return this.http.post<any>(this.baseUrl + `api/report/getConsumtioncontacts`,body)
                 .pipe(
                map(data=>{
                if(!data.status)
                {
                  if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
                  {
                  //  console.error(data.message);
                    data.message="Something went wrong.Please try again."
                  }
                }
                return data;
              }),
              );

            }

          
          

  geteCommDetailReport(customerId, limit, offset) {
    return this.http.get<any>(this.baseUrl + 'api/report/geteCommDetailReport/'+customerId+'/'+offset+'/'+limit)
      .pipe(
        map(data => {
          if (!data.status) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

  geteCommSummaryReport(customerId) {
    return this.http.get<any>(this.baseUrl + 'api/report/geteCommSummaryReport/'+customerId)
      .pipe(
        map(data => {
          if (!data.status) {
           // console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
          return data;
        }),
        );
  }

  beginingrecords(customerId) {
      return this.http.get<any>(this.baseUrl + 'api/report/beginingrecords/'+customerId)
        .pipe(
          map(data => {
            if (!data.status) {
             // console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
            return data;
          }),
          );}
        

      addrecords(custid,start_date,end_date) {
        return this.http.get<any>(this.baseUrl + 'api/report/addrecords/'+custid+'/'+start_date+'/'+end_date)
          .pipe(
            map(data => {
              if (!data.status) {
               // console.error(data.message);
                data.message = "Something went wrong.Please try again.";
              }
              return data;
            }),
            );}
          }



