import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewindustry',
  templateUrl: './addnewindustry.component.html',
  styleUrls: ['./addnewindustry.component.css']
})
export class AddnewindustryComponent extends BaseComponent implements OnInit {

  constructor(
    public master: MasterService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private upload:UploadService
  ) {
    super();
  }
  // name;
  // description;
  // isactive;
  // logo;
  industry_id;
  industryObjId;
  status: boolean = true;
  industry_awskey;
  industry_log;
  screenshot_type = true;
  header = "Add New"

  submitted = false;
  themeForm: FormGroup;

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.industry_id = params.industry_id;
      });
    //get item details using id
    if (self.industry_id) {
      this.ngxSpinnerService.show();
      this.header = "Edit";
      this.UpdateIndustryLogData();
      this.master.getIndustryId(this.industry_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.industryObjId = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();
        } else { }
      });
    } else {
      this.addIndustryLogData();
    }
    this.themeForm = this.formBuilder.group({
      industryName: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      industryDescription: [],
      firstActive: [true],
      industryLogo: []
    });
  }
  get f() { return this.themeForm.controls; }
  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  addIndustryLogData() {
    let IndustryLogs = {
      log_type: "Adding New Industry",
      log_name: "Adding New Industry",
      log_description: "Adding New Industry",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(IndustryLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  UpdateIndustryLogData() {
    let IndustryLogs = {
      log_type: "Updating Industry Data",
      log_name: "Updating Industry Data",
      log_description: "Updating Industry Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(IndustryLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  dataBinding() {
    // this.name = this.industryObjId.industry_name;
    // this.description = this.industryObjId.industry_description;
    // this.logo = this.industryObjId.industry_logo;
    // this.isactive = this.industryObjId.isactive;
    this.industry_log = this.industryObjId.industry_logo;
    if (this.isUrlValid(this.industryObjId.industry_logo)) {
      this.themeForm = this.formBuilder.group({
        industryName: [this.industryObjId.industry_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
        industryDescription: [this.industryObjId.industry_description],
        firstActive: [true],
        industryLogo: [this.industryObjId.industry_logo]
      });
    } else {
      this.themeForm = this.formBuilder.group({
        industryName: [this.industryObjId.industry_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
        industryDescription: [this.industryObjId.industry_description],
        firstActive: [false],
        industryLogo: []
      });
      this.industry_awskey = this.industryObjId.industry_logo;
      this.screenshot_type = false;
    }
  }

  addNewIndustry() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { industryName, industryDescription, industryLogo } = this.themeForm.value;

    if (industryLogo) {



      if (industryLogo) {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(industryLogo);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }



    }


    industryLogo = this.isUrlValid(industryLogo) ? industryLogo : this.industry_awskey ? this.industry_awskey : "";
   // console.log(industryLogo);
    let addnewIndustryObj = {
      name: industryName,
      description: industryDescription,
      logo: industryLogo
    }
   this.ngxSpinnerService.show();
    this.master.addNewIndustry(addnewIndustryObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/industrymaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.success(error.message);
      this.ngxSpinnerService.hide();
    })
  }

  UpdateIndustry() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { industryName, industryDescription, industryLogo } = this.themeForm.value;


    if (industryLogo) {



      if (industryLogo) {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(industryLogo);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }



    }

    industryLogo = this.isUrlValid(industryLogo) ? industryLogo : this.industry_awskey ? this.industry_awskey : "";
    let industryObj = {
      industry_description: industryDescription,
      industry_id: this.industry_id,
      industry_logo: industryLogo,
      industry_name: industryName,
      isactive: this.industryObjId.isactive
    }
   // console.log(industryObj);
    this.ngxSpinnerService.show();
    this.master.updateIndustry(industryObj).subscribe(data => {
      this.toastrService.success("Record Update successfully");
      this.router.navigateByUrl(`/corporate/industrymaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.success(error.message);
      this.ngxSpinnerService.hide();
    });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.industry_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  toUploadFiles(name,file) {
    let self = this;
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.industry_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
