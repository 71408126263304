import { Injectable, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { AppInjector } from '../services/app-injector.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {BaseService} from './services/base.service';
import { RegisterService } from '../services/users/register.service';
import { LogsService } from '../services/adminlogs/logs.service';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ViewImageService } from '../_utils/view-image/view-image.service';
export enum Permission {
  Read="13",
  Write = "14",
  Edit = "15",
  Delete = "16",
  ViewAll="17",
  ModifyAll="18",
  Import="26",
  Export="27"
}

@Injectable()
export abstract class BaseComponent implements OnInit {
  protected router: Router;
  protected location: Location;  
  protected ngxSpinnerService: NgxSpinnerService;
  protected toastrService:ToastrService;
  protected baseService:BaseService;
  protected activatedRoute: ActivatedRoute;
  protected registerService:RegisterService;
  protected logs :LogsService;
  protected confirmationDialogService:ConfirmationDialogService;
  protected viewImageService: ViewImageService
  public isRead=false;
  public isWrite=false;
  public isEdit =false;
  public isDelete =false;
  public isViewAll=false;
  public isModifyAll=false;
  public isImport=false;
  public isExport=false;
  constructor() {
    const injector = AppInjector.getInjector();
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.ngxSpinnerService = injector.get(NgxSpinnerService);
    this.toastrService=injector.get(ToastrService);
    this.baseService=injector.get(BaseService);
    this.activatedRoute=injector.get(ActivatedRoute);
    this.registerService=injector.get(RegisterService);
    this.logs=injector.get(LogsService); 
    this.confirmationDialogService=injector.get(ConfirmationDialogService); 
    this.viewImageService = injector.get(ViewImageService);
    // console.log(Permission);
    // console.log(this.registerService.CurrentPagePermissions.value);  
    // console.log(this.registerService.CurrentPagePermissions.value.indexOf(Permission.Read+','));
    if(this.registerService.CurrentPagePermissions.value)
{
  var CurrentPagePermissions=this.registerService.CurrentPagePermissions.value.split(',');
  if(CurrentPagePermissions.indexOf(Permission.Delete)>=0)
  {
    this.isDelete=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.Edit)>=0)
  {
    this.isEdit=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.Read)>=0)
  {
    this.isRead=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.Write)>=0)
  {
    this.isWrite=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.Import)>=0)
  {
    this.isImport=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.Export)>=0)
  {
    this.isExport=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.ModifyAll)>=0)
  {
    this.isModifyAll=true;
  }
  if(CurrentPagePermissions.indexOf(Permission.ViewAll)>=0)
  {
    this.isViewAll=true;
  }
  // console.log("isDelete  "+ this.isDelete);
  // console.log("isViewAll  "+this.isViewAll);
  // console.log("isEdit  "+this.isEdit);
  // console.log("isRead  "+ this.isRead);
  // console.log("isWrite  "+this.isWrite);
  // console.log("isModifyAll  "+this.isModifyAll);
  // console.log("isImport  "+this.isImport);
  // console.log("isExport  "+this.isExport);
} 
  }

  ngOnInit() {

  }
}