import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-edit-sender-profile',
  templateUrl: './edit-sender-profile.component.html',
  styleUrls: ['./edit-sender-profile.component.css']
})
export class EditSenderProfileComponent extends BaseComponent implements OnInit {
  theme_name;
  theme_description;
  screenshot: String;
  IsActive;

  profileObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey='';
  them_company;
  getCompanyobj;
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;
  correctImage:boolean=true;
  controlEnabled:boolean = true;
  submitted = false;
  senderProfileForm: FormGroup;
  display_event_address:true;
  domainData: any;
  company_id: any;
  journeyDropDown: any;

  senderProfileId:any;
  mxRecordObj = [];
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster:ToastrService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.senderProfileId = localStorage.getItem('senderProfileId');
    this.company_id = localStorage.getItem('senderProfileCompanyId')

    //get item details using id
    if (this.senderProfileId) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.master.viewSenderProfile(this.company_id,this.senderProfileId).subscribe({
        next:(response:any)=>{
          if (response.status) {
            this.status = false;
            self.profileObj = response.data;
            this.ngxSpinnerService.hide();
            //console.log(self.profileObj)
            self.dataBinding();
          } else {
          }
        },
        error:(err:any)=>{

        }
      });
    }
    this.senderProfileForm = this.formBuilder.group({
      company: ['', Validators.required],
      sendername:[''],
      senderemail:['',[Validators.required, Validators.pattern(AppConstants.regex.email)]],
      emaildomain:['',[Validators.required]],
      status:['',Validators.required],
      comment:[''],
      sender_id:[''],
      maxrecord:this.formBuilder.array([])
    });

  }
  get f() { return this.senderProfileForm.controls; }

  dataBinding() {
   this.senderProfileForm.patchValue({
      company:this.company_id,
      sendername:this.profileObj.sendername,
      senderemail:this.profileObj.senderemail,
      emaildomain:this.profileObj.emaildomain,
      status:this.profileObj.status,
      comment:'',
      sender_id:this.profileObj.id,
   })
  //  this.senderProfileForm.get('maxrecord')
  if(this.profileObj.maxrecord){
    for(let rc of this.profileObj.maxrecord){
      let item_ele = this.formBuilder.group(
        {
          mx_record_key:[rc.mxkey],
          mx_record_type:[rc.mxtype],
          mx_record_value:[rc.mxvalue],
          mx_record_priority:[rc.mxpriority?rc.mxpriority:0],
          mx_status:[rc.status]
        }
      )
      this.item.push(item_ele)
     }
  }
  if(this.profileObj.is_margo_domain){
      this.f.senderemail.setValidators([Validators.required]);
      this.f.senderemail.updateValueAndValidity();
  }else{
      this.f.senderemail.setValidators([Validators.required, Validators.pattern(AppConstants.regex.email)]);
      this.f.senderemail.updateValueAndValidity();
    }
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  updateSenderProfile() {
    this.submitted = true;
    if (this.senderProfileForm.invalid) {
      return;
    }
    let payload = {
      ds_id:this.profileObj.user_ds_id,
      sender_name:this.profileObj.sendername,
      sender_email:this.senderProfileForm.value.senderemail,
      email_domain:this.senderProfileForm.value.emaildomain,
      status:this.senderProfileForm.value.status,
      comment:this.senderProfileForm.value.comment,
      sender_id:this.senderProfileForm.value.sender_id,
      maxrecord:this.senderProfileForm.value.maxrecord
    }


    this.ngxSpinnerService.show();
    this.master.updateSenderProfile(this.company_id,this.senderProfileId,payload).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/sender-profile-list`);
        this.ngxSpinnerService.hide();
      }else {this.ngxSpinnerService.hide();
        this.toaster.error('Something went wrong !')}
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
      this.toaster.error('Something went wrong !')
    });
  }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
addNewMxRecord(){
  let item = this.formBuilder.group(
    {
      mx_record_key:[''],
      mx_record_type:[''],
      mx_record_value:[''],
      mx_record_priority:[0],
      mx_status:['']
    }
  )
  let formArr = this.senderProfileForm.get('maxrecord') as FormArray
  formArr.push(item)
}
get item(){
  return this.senderProfileForm.get('maxrecord') as FormArray
}

}
