import { formatDate } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";
import { UploadService } from "src/app/services/uploadfile/upload.service";

@Component({
  selector: 'app-update-resource-image',
  templateUrl: './update-resource-image.component.html',
  styleUrls: ['./update-resource-image.component.css']
})
export class UpdateResourceImageComponent extends BaseComponent implements OnInit {

  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  customer_id;
  submitted: boolean = false;
  categoryObj;
  selectedCompany;
  screenshot_type = true;
  them_awskey;
  category_id;
  imgURL = [];
  videoURLs = [];
  fileURLs = [];
  product_files: any[];
  postUrl = [];
  screenshotUrl = [];
  fileUploadErrMsg: any;
  slCatPage = 1;
  slCatLimit = 100;
  slCatSkip = 0;
  slCatTotal: number = 0;
  status: boolean = true;
  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;
  imageChangedEvent: any;
  croppedImage: any;
  controlEnabled: boolean = true;
  @ViewChild("imageInput") imageInput!: ElementRef;
  post_id: string;
  constructor(
    private clientService: ClientService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
    private master: MasterService,
    private modalService: NgbModal,
    private upload:UploadService
  ) {
    super();
    this.addCategoryForm = this.formBuilder.group({
      categoryid: ["", Validators.required],
      name: [
        "",
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      description: [
        "",
        [
          Validators.required,
          Validators.maxLength(100),
          this.noWhitespaceValidator,
        ],
      ],
      // url: ['', Validators.pattern(AppConstants.regex.websiteURL)],
      resource_url: ["", Validators.required],
      resource_preview_url: ["", Validators.required],
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
    });
  }

  ngOnInit() {
    this.customer_id = JSON.parse(sessionStorage.getItem("userData")).admin_customer_id;
    this.controlEnabled = true;
    if (this.customer_id == 0) this.getCompany();
    if (this.customer_id != 0) {
      this.getCategoryData(this.customer_id);
    }
    let self = this;
    if (localStorage.getItem("edit_resource_id")) {
      this.post_id = localStorage.getItem("edit_resource_id");
      this.selectedCompany = localStorage.getItem("resource_edit_company_id");
      this.getCategory(this.selectedCompany);
      this.ngxSpinnerService.show();
      console.log(this.selectedCompany);

      this.masterService.getReosurceListById(this.selectedCompany,this.post_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.dataBinding(response?.data);

        }
      });
    }
  }
  dataBinding(data:any) {
    this.croppedImage=data?.resource_preview_url || null;
    this.addCategoryForm = this.formBuilder.group({
      categoryid: [data.cate_id, Validators.required],
      name: [data.resource_title, [Validators.required, this.noWhitespaceValidator]],
      description: [data.resource_desc, [Validators.required, this.noWhitespaceValidator]],
      resource_url: [data.resource_url],
      resource_preview_url:[data.resource_preview_url],
      selectedCompany: [this.selectedCompany, Validators.required],
    });
  }
  get f() {
    return this.addCategoryForm.controls;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCategory(company) {
    if (company) {
      this.getCategoryData(company);
      this.selectedCompany = company;
    }
  }
  getCategoryData(company) {
    if (company) {
      this.ngxSpinnerService.show();
      let status = true;

      this.masterService
        .getReosurceCategoryList(company, this.slCatLimit, this.slCatSkip, "")
        .subscribe(
          (data) => {
            if (data.status) {
              if (data.data.rows == null) {
                this.categoryObj = [];
              } else {
                this.categoryObj = data.data.rows;
              }
              this.slCatTotal = data.data.total;
            } else {
              this.categoryObj = [];
              this.slCatTotal = 0;
            }
            this.ngxSpinnerService.hide();
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.categoryObj = [];
      this.ngxSpinnerService.hide();
    }
  }

  fileChangeEvent(event: any, modal: any): void {
    let file = event.target.files[0];
    let regexImage = new RegExp("^image/");
    if (regexImage.test(file.type) === false) {
      this.toastrService.error("Please select a valid image format");
      event.target.value = null;
    } else {
      this.ngxSpinnerService.show();
      this.imageChangedEvent = event;
      this.modalService
        .open(modal, {
          ariaLabelledBy: "modal-basic-title",
          size: "sm",
          backdrop: "static",
          keyboard: false,
        })
        .result.then(
          (result) => {},
          (reason) => {}
        );
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  addNewPostData() {
    this.submitted = true;
    let { categoryid, name, description, resource_url, resource_preview_url } =
      this.addCategoryForm.value;
    if (this.addCategoryForm.invalid) {
      return;
    }

    let obj = {
      res_id:this.post_id,
      cate_id: categoryid,
      resource_type: "image",
      resource_title: name,
      resource_desc: description,
      resource_url: resource_url,
      resource_preview_url: resource_preview_url,
      company:this.selectedCompany
    };
    this.ngxSpinnerService.show();

    this.masterService.updateReosurceImage(obj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success("Record Updated successfully");
          this.router.navigateByUrl(`/corporate/resource`);
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.toastrService.error(error.message);
      }
    );
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: any) {
    this.ngxSpinnerService.hide();
    // show cropper
  }
  uploadOriginalImage() {
    let imageBase64: any;
    // self.modalService.open(modalName).result.then((result) => {
    this.ngxSpinnerService.show();
    let path = "resource_images";
    let imageExtension=this.imageChangedEvent.target.files[0].name.split('.').pop();
    let currentDateNewFormat = formatDate(
      new Date(),
      "MMddYYYYHHMMSSSS",
      "en_US"
    );
    // Random 3 digits
    let min = Math.ceil(100);
    let max = Math.floor(999);
    let randomThreeDigit = Math.floor(Math.random() * (max - min + 1) + min);
    let imageName =
      "resource_original" + currentDateNewFormat + randomThreeDigit.toString()+'.'+imageExtension;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageChangedEvent.target.files[0]);
    reader.onload = () => {
      imageBase64 = reader.result;
      this.upload.uploadImage(imageName,path,imageBase64).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Location) {
              this.addCategoryForm.get("resource_url").setValue(params.Location);
            }
          }else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    };
  }
  uploadImage() {
    let path = "resource_images";
    let imageExtension=this.imageChangedEvent.target.files[0].name.split('.').pop();
    let currentDateNewFormat = formatDate(
      new Date(),
      "MMddYYYYHHMMSSSS",
      "en_US"
    );
    // Random 3 digits
    let min = Math.ceil(100);
    let max = Math.floor(999);
    let randomThreeDigit = Math.floor(Math.random() * (max - min + 1) + min);
    let imageName =
      "resource_cropped" + currentDateNewFormat + randomThreeDigit.toString()+'.'+imageExtension;
    this.upload.uploadImage(imageName,path,this.croppedImage).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Location) {
              this.addCategoryForm
                .get("resource_preview_url")
                .setValue(params.Location);
            }
          }else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
  }
  closeModal() {
    this.imageInput.nativeElement.value = null;
  }

}
