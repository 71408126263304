<div class="text-center">
    <h4>Create Journey Flow</h4>
    <div class="row col-md-12">
        <div class="col-md-3">
          <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="onCompanyChange($event.target.value)">
            <option value= 0>------Select Company Name------</option>
            <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </select>
        </div>
    
        <div class="col-md-3">
          <select class="form-control formstyle" [(ngModel)]="jid" (change)="getCompanyJourneyThemeList($event.target.value)">
            <option value="0">------Select Journey Type------</option>
            <option *ngFor="let item of journeyTypeObj" value={{item.journey_id}}>
              {{item.journey_name}}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <select class="form-control formstyle" [(ngModel)]="themeId">
            <option value="0">------Select Theme------</option>
            <option *ngFor="let item of themeObj" value={{item.theme_id}}>
              {{item?.theme_name}}
          </option>
          </select>
        </div>
    </div>
  </div>
  <main>
  
    <div id="canvas" ngFlowchartCanvas [ngFlowchartOptions]="options" [disabled]="disabled"></div>
    <div id="palette">
      <section>
        <div class="plugin-op" *ngFor="let op of customOps" [ngFlowchartStep]="op.step">
          <span>{{ op.paletteName }}</span>
        </div>
      </section>
  
      <section>
        <button (click)="showFlowData()">Show JSON</button>
        <button (click)="showUpload()">Upload JSON</button>
      </section>
      <section>
        <div class="sub-section">
          <label for="step-gap-select" class="wide">Step Gap</label>
          <select name="step-gap-select" id="step-gap-select" class="wide" (change)="onGapChanged($event)">
            <option value="30">30</option>
            <option value="40" selected>40</option>
            <option value="60">60</option>
          </select>
        </div>
        <div class="sub-section">
          <label for="sequential">Sequential</label>
          <input type="checkbox" name="sequential" id="sequential" (change)="onSequentialChange($event)" />
        </div>
        <div class="sub-section">
          <label for="disabled">Disabled</label>
          <input type="checkbox" id="disabled" name="disabled" (change)="disabled = $event.target.checked" />
        </div>
      </section>
      <section>
        <button (click)="clearData()">Clear Flow</button>
      </section>
      <ng-template #normalStep let-data>
        <div class="card" [attr.name]="data.data.name" #card (click)="onDelete(data.id)">
          <span>{{ data.data.name }}</span>
        </div>
      </ng-template>
    </div>
  </main>