import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-companytypemaster',
  templateUrl: './companytypemaster.component.html',
  styleUrls: ['./companytypemaster.component.css']
})
export class CompanytypemasterComponent extends BaseComponent implements OnInit {
  p = 1
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();
  public companyTypeObj: any = [];
  order: string = 'company_type_name';
  reverse: boolean = false;
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
	private orderPipe: OrderPipe
  ) { 
    super();
  }
  ngOnInit() {
    this.getCompanyType();
    this.CompanyTypeLogData()
  }
	setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
  CompanyTypeLogData() {
    let CompanyTypeLogs = {
      log_type:"CompanyType Master View" ,
      log_name:"CompanyType Master View" ,
      log_description:"CompanyType Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(CompanyTypeLogs).subscribe(data => {
     // console.log(data);
    }, error => {
    //  console.log(error);
    });
}

  getCompanyType() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCompanyType().subscribe(data => {
      self.companyTypeObj = this.orderPipe.transform(data, 'company_type_name');
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  editCompanyType(id) {
    this.router.navigate(['/corporate/addnewcompany'], { queryParams: { company_id: id } });
  }

  deleteCompanyType(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
    .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delCompanyType(obj).subscribe(data => {
            if (data.status) {
              this.getCompanyType();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error.message);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.companyTypeObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.company_type_id === id) {
        return true;
      }
    });
    //obj = obj.get_master_company_type;
    this.confirmationDialogService.confirm('PLEASE CONFIRM.', 'Are you sure you want to change the status?','YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          obj.isactive = !obj.isactive;
          this.master.updateCompany(obj).subscribe(data => {
            this.toastrService.success("Company Type Master Status Updated Successfully");
            this.getCompanyType();
          });
        }
        // else {
        //   this.master.updateCompany(obj).subscribe(data => {
        //     this.toastrService.success("Status Updated Successfully")
        //     this.getCompanyType();
        //   });
        // }
      }).catch(() => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-company-import.csv`, 'addingCompanyTypeBulk')
      .then(data => {
      //  console.log(data);
        self.getCompanyType();
      })
      .catch(err => {
        //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_company_type"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
