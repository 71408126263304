import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "../../base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";
import { ConfirmationDialogService } from "src/app/_utils/confirmation-dialog/confirmation-dialog.service";
import { OrderPipe } from "ngx-order-pipe";
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { CommonMessages } from "src/app/shared/messages/messages.enum";

@Component({
  selector: "app-resource-image",
  templateUrl: "./resource-image.component.html",
  styleUrls: ["./resource-image.component.css"],
})
export class ResourceImageComponent extends BaseComponent implements OnInit {
  @ViewChild("searchText", { static: true }) searchText: ElementRef;
  getCompanyobj: any = [];
  companyID = 0;
  currentUser;
  selectedCompany: any = "";
  productObj: any = [];
  categoryId: any;
  categoryObj: any = [];

  slord = "desc";
  slColname = "createdon";
  slPage = 1;
  slLimit = 10;
  slSkip = 0;
  slTotal: number = 0;

  slCatPage = 1;
  slCatLimit = 1000;
  slCatSkip = 0;
  slCatTotal: number = 0;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;
  domain_id: any = "";

  constructor(
    protected confirmationDialogService: ConfirmationDialogService,
    private clientService: ClientService,
    public orderPipe: OrderPipe,
    private masterService: MasterService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    if (this.currentUser.admin_customer_id != "0") {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getProductList();
      this.getCategoryData();
    }
    if (this.currentUser.admin_customer_id == "0") {
      this.getCompany();
    }
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = "";
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slSkip = 0;
          this.slPage = 1;
        }),
        switchMap((data) =>
          this.masterService.getReosurceList(
            this.selectedCompany,
            this.slLimit,
            this.slSkip,
            this.searchText.nativeElement.value,
          )
        )
      )
      .subscribe(
        (res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.productObj = [];
            } else {
              this.productObj = res.data.rows;
            }
            this.slTotal = res.data.total;
          } else {
            this.productObj = [];
            this.slTotal = 0;
          }
          this.ngxSpinnerService.hide();
        },
        (error) => {
          this.productObj = [];
          this.slTotal = 0;
          this.ngxSpinnerService.hide();
        }
      );
  }

  setOrder(value: string) {
    this.slColname = value;
    if (this.slord == "asc") {
      this.slord = "desc";
    } else {
      this.slord = "asc";
    }
    this.getProductList();
  }

  deleteProduct(id) {
    let obj = {
      res_id:id,
      is_delete:true,
      company:this.selectedCompany
    };
    this.confirmationDialogService
      .confirm(
        CommonMessages.confirmationTitle,
        "Are you sure you want to Delete?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .updateResImageStatus(obj)
            .subscribe(
              (data) => {
                if (data.status) {
                  this.getProductList();
                  setTimeout(() => {
                    this.toastrService.success("Deleted Successfully");
                  }, 1000);
                  this.ngxSpinnerService.hide();
                } else {
                  this.ngxSpinnerService.hide();
                }
              },
              (error) => {
                this.toastrService.error(error.message);
              }
            );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
        console.log("this.companyID", this.getCompanyobj.customer_id);
        this.ngxSpinnerService.hide();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProductList() {
    let categoryId = this.categoryId > 0 ? this.categoryId : '';
    let searchTerm = this.searchText.nativeElement.value;
    this.slSkip = (this.slPage - 1) * this.slLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService
        .getReosurceList(
          this.selectedCompany,
          this.slLimit,
          this.slSkip,
          searchTerm,
          categoryId
        )
        .subscribe(
          (data) => {
            if (data.status) {
              if (data.data.rows == null) {
                this.productObj = [];
              } else {
                this.productObj = data.data.rows;
              }
              this.slTotal = data.data.total;
              this.productObj.forEach((element) => {
                if (element.resource_preview_url) {
                  if (
                    /\.(csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(
                      element.resource_preview_url
                    ) === true
                  ) {
                    // element.resource_preview_url = this.downloadFile(element.sl_product_files);
                    element.imageType = 1;
                  } else if (
                    /\.(mp4|mov)$/i.test(element.resource_preview_url) === true
                  ) {
                    element.imageType = 2;
                  } else {
                    element.imageType = 0;
                  }
                }
              });
            } else {
              this.productObj = [];
              this.slTotal = 0;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      this.ngxSpinnerService.hide();
    } else {
      this.productObj = [];
      this.selectedCompany = "";
      this.ngxSpinnerService.hide();
    }
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.masterService.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl)
          ? awsUrl
          : "../../../assets/custom/images/broken.png";
      } else {
        event.target.src = "../../../assets/custom/images/broken.png";
      }
    } else {
      event.target.src = "../../../assets/custom/images/broken.png";
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }

  editPost(id) {
    localStorage.setItem("edit_resource_id", id);
    localStorage.setItem("resource_edit_company_id", this.selectedCompany);
    this.router.navigate(["/corporate/update-resource"]);
  }

  updateStatus(event, id, is_active) {
    event.preventDefault();
    let obj = {
      res_id:id,
      is_active: is_active == true ? false : true,
      company:this.selectedCompany
    };
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status To" +
          (obj.is_active ? " Active?" : " In Active?"),
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .updateResImageStatus(obj)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Updated Successfully");
                this.getProductList();
              } else {
                this.toastrService.error(data.message);
              }
            });
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      })
      .catch(() => {
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        );
      });
  }

  getCategoryData() {
    if (this.selectedCompany != "") {
      let status = true;
      this.ngxSpinnerService.show();

      this.masterService
        .getReosurceCategoryList(
          this.selectedCompany,
          this.slCatLimit,
          this.slCatSkip,
          ""
        )
        .subscribe(
          (data) => {
            this.ngxSpinnerService.hide();

            if (data.status) {
              if (data.data.rows == null) {
                this.categoryObj = [];
              } else {
                this.categoryObj = data.data.rows;
              }
              this.slCatTotal = data.data.total;
            } else {
              this.categoryObj = [];
              this.slCatTotal = 0;
            }
            this.ngxSpinnerService.hide();
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.categoryObj = [];
      this.slCatTotal = 0;
    }
  }

  getProByCategoryId(val) {
    this.categoryId = val;
    this.getProductList();
  }
}
