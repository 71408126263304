import { Component, OnInit , PipeTransform, Pipe} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpandtipsService } from 'src/app/services/helpandtips/helpandtips.service';
import {KnowledgeArticleLayoutDetail} from 'src/app/shared/modelClasses/modelClasses';
import {ArticleDetailComponent} from '../tips/article-detail/article-detail.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
// @Pipe({ name: 'safeHtml'})
export class TipsComponent implements OnInit, PipeTransform {

  public knowledgeArticles:any=[];
  public knowledgeArticlesGlobal:any=[];
  public recentknowledgeArticles:any=[];
  public knowledgeArticleLayoutDetail:KnowledgeArticleLayoutDetail;
  selectedArticle:boolean=false;
  queryArticleString=[];
  showArticlesList:boolean=true;
  showArticleDetail:boolean=false;
  articleFound:boolean=false;
  resultHeaderText:string="All Articles";
  constructor(private helpandtipsService: HelpandtipsService,
    private sanitized: DomSanitizer,
    private ngxSpinnerService: NgxSpinnerService) {
    this.knowledgeArticleLayoutDetail = {
      title: "",
      summary: "",
      answer: ""
    }

  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getKnowledgeArticles();
    this.knowledgeArticleLayoutDetail = {
      title: "",
      summary: "",
      answer: ""
    }
  }

  //Bypass HTML Security - Load HTML with styles
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
  //Retrieve latest knowledge Articles
  getKnowledgeArticles(){
    let self = this;
    this.helpandtipsService.getKnowledgeArticles().subscribe(data => {
      if(data.status){
        self.knowledgeArticles = self.knowledgeArticlesGlobal = data.data.articles;
        self.recentknowledgeArticles = data.data.articles.slice(0, 5);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      //console.log(error);
    });
   
  }

  //Retrieve article details
  getKnowledgeArticleById(event,articleID){
    let self = this;
    // this.selectedArticle = !this.selectedArticle; 
    this.selectedArticle = articleID;
    this.ngxSpinnerService.show();
    this.helpandtipsService.getKnowledgeArticleByID(articleID).subscribe(data => {

      if(data.status){
        self.knowledgeArticleLayoutDetail.title = data.data.layoutItems.find(item=>item.label=='Title').value;
        self.knowledgeArticleLayoutDetail.summary = data.data.layoutItems.find(item=>item.label=='Summary').value;
        self.knowledgeArticleLayoutDetail.answer = data.data.layoutItems.find(item=>item.label=='Answer').value;
      }

      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });
    this.showArticlesList=!this.showArticlesList;
    this.showArticleDetail=!this.showArticleDetail;
    //this.ngxSpinnerService.hide();
  }

  SearchArticle() {
    // if (this.queryArticleString != "")
    //   this.resultHeaderText = "Search Results";
    // else
    //   this.resultHeaderText = "All Articles";

    let self = this;
    // this.selectedArticle = !this.selectedArticle; 
    if (this.queryArticleString.length >= 3) {
      this.ngxSpinnerService.show();
      this.helpandtipsService.getKnowledgeArticleByQuery(this.queryArticleString).subscribe(data => {
        self.knowledgeArticles = data.searchRecords;
        if (data.searchRecords == null || data.searchRecords.length == 0)
          this.articleFound = true;
        else
          this.articleFound = false;
        this.ngxSpinnerService.hide();
        this.resultHeaderText = "Search Results";
      }, error => {
       // console.log(error);
      });
    }
    else{
      self.knowledgeArticles=self.knowledgeArticlesGlobal;
      this.articleFound = false;
      this.resultHeaderText = "All Articles";
    }
    // this.showArticlesList=!this.showArticlesList;
    // this.showArticleDetail=!this.showArticleDetail;

  }

  showArticleList(){
    this.showArticlesList=true;
    this.showArticleDetail=false;
  }

}
