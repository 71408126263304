<!-- <h3><a routerLink="/corporate/campaign">&lt;Back</a></h3> -->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb" style="list-style: none;">
    <li class="breadcrumb-item"><a routerLink="/corporate/home">Admin</a></li>
    <li class="breadcrumb-item"><a routerLink="/corporate/fb">Facebook</a></li>
    <li class="breadcrumb-item active"><a routerLink="/corporate/campaign">Campaign</a></li>
    <!-- <li class="breadcrumb-item active"><a routerLink="/corporate/addnewcampaign">Add New Campaign</a></li> -->
  </ol>
</nav>

<div class="container">
 
  
<div class="row mt-1">
  <div class="col-lg-3">
     
    <div>
      <label for="exampleForm2"> Name</label>
      <input type="text" class="form-control" [(ngModel)]="name" class="form-control form-control-md">
    </div>
    <div>
      <label for="exampleForm2"> Description</label>
      <input type="text" class="form-control" [(ngModel)]="description" class="form-control form-control-md">
    </div>
    <!-- <div>
      <label for="exampleForm2"> ScreenShot</label>
      <input type="text" class="form-control" [(ngModel)]="screenshot" class="form-control form-control-md">
    </div> -->
    <div>
      <label for="exampleForm2">Screenshot</label>
      <div [ngClass]="status ? 'displaynone' :'displayblock' ">
              <img src="{{screenshot}}" alt="Margo" style="width:300px;height:250px;">
      </div>
      <div>
          <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="customRadio1" [(ngModel)]="screenshot_type"
                  name="active" [value]="true">
              <label class="custom-control-label" for="customRadio1">Url</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="customRadio2" name="active"
                  [(ngModel)]="screenshot_type" [value]="false">
              <label class="custom-control-label" for="customRadio2">Upload a file</label>
          </div>
      </div>
      <div *ngIf="screenshot_type" class="mt-1">
          <input type="text" id="exampleForm1" [(ngModel)]="screenshot"
              class="form-control form-control-md">
      </div>
      <div *ngIf="!screenshot_type" class="mt-1">
          <!-- <input type="file" id="exampleForm2" [(ngModel)]="themescreenshot"> -->
          <input #imageInput type="file" accept=".png, .jpg, .jpeg" (change)="processFile($event,imageInput)">
      </div>
  </div>
    <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
      <!-- <div class="col-12 col-md-3"> -->
        <button type="submit" class="custbtn mt-2" (click)="addNewCampaignData()">Submit</button>
      <!-- </div> -->
    </div>
    <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
      <!-- <div class="col-12 col-md-3"> -->
        <button type="button" class="custbtn mt-2" (click)="updateCampaignData()">Update</button>
      <!-- </div> -->
    </div>
  </div>
</div>

</div>
