import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';


@Component({
  selector: 'app-poll-options',
  templateUrl: './poll-options.component.html',
  styleUrls: ['./poll-options.component.css']
})
export class PollOptionsComponent extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  pollOptionData: any = [];
  getCompanyobj: any = [];
  resCatTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "id";

  constructor(private clientService: ClientService,
    private masterService:MasterService) {
    super();
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getPollOption();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getPollOption()
  }
getCompany() {
  this.clientService.getBUCompanies().subscribe(data => {
    this.getCompanyobj = data.data;
    this.ngxSpinnerService.hide();
  }, error => {
    console.log(error);
  });
}
  getPollOption() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getWebinarPollOptionList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.pollOptionData = [] }
          else { this.pollOptionData = data.data.rows }
          this.resCatTotal = data.data.total;
        } else {
          this.pollOptionData = [];
          this.resCatTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.pollOptionData = [];
      this.selectedCompany = "";
      this.resCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }

  deleOption(ev,id){
    this.confirmationDialogService
    .confirm(
      CommonMessages.confirmationTitle,
      "Are you sure you want to Delete?",
      "YES",
      "NO"
    )
    .then((confirmed) => {
      if (confirmed) {
        this.ngxSpinnerService.show();
        this.masterService
          .deletePollOption(this.selectedCompany,id)
          .subscribe(
            (data) => {
              if (data.status) {
                this.getPollOption();
                setTimeout(() => {
                  this.toastrService.success("Deleted Successfully");
                }, 1000);
                this.ngxSpinnerService.hide();
              } else {
                this.ngxSpinnerService.hide();
              }
            },
            (error) => {
              this.toastrService.error(error.message);
            }
          );
      }
    })
    .catch(() =>
      console.log(
        "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
      )
    );
  }
  editOption(id){
    localStorage.setItem("edit_poll_option_id", id);
    localStorage.setItem("poll_option_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/update-poll-option']);
  }


  updateStatus(event:any, id:any, poll:any) {
    event.preventDefault();
    let obj = {
      isActive: poll.is_active == true ? false : true,
      id: id,
      // title: poll.title,
      // description: poll.description
    };
    // poll.isActive = poll.is_active == true ? false : true,
    // poll.isCorporate = poll.is_corporate;
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status To" +
          (obj.isActive ? " Active?" : " In Active?"),
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .updatePollOptionStatus(this.selectedCompany,obj)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Updated Successfully");
                this.getPollOption();
              } else {
                this.toastrService.error(data.message);
              }
            });
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      })
      .catch(() => {
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        );
      });
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),
        switchMap(data => this.masterService.getWebinarPollOptionList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value,this.slCatColname,this.slCatord))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.pollOptionData = [] }
          else { this.pollOptionData = res.data.rows }
          this.resCatTotal = res.data.total;
        } else {
          this.pollOptionData = [];
          this.resCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.pollOptionData = [];
        this.resCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

}
