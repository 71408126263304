import { Component, OnInit } from '@angular/core';
import { FbService } from 'src/app/services/fbservice/fb.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ClientService}  from 'src/app/services/clients/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewcategory',
  templateUrl: './addnewcategory.component.html',
  styleUrls: ['./addnewcategory.component.css']
})
export class AddnewcategoryComponent implements OnInit {
  name;
  description;
  screenshot;
  isactive = true;
  status: boolean = true;
  category_id;
  categoryObj;
  fileData;
  fileUploaded;
  screenshot_type;
  profileForm: FormGroup;
  submitted = false;
  customer_id;
  getCompanyobj:any=[];
  selectedCompany;
  butDisabled: boolean = true;
  controlEnabled:boolean = true;
  constructor(
    private fb: FbService,
    private toastrService: ToastrService,
    private router: Router,
    private ar: ActivatedRoute,
    private master: MasterService,
    private formBuilder: FormBuilder,
    private clientsService:ClientService,
    private ngxSpinnerService:NgxSpinnerService,
    private upload:UploadService
  ) { }
  ngOnInit() {
    this.controlEnabled = true;
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if(this.customer_id==0)  this.getCompany();


    let self = this;

    //get item details using id
    if (localStorage.getItem("edit_category_id")) {


      this.category_id=  localStorage.getItem("edit_category_id");
      //this.customer_id =  localStorage.getItem("post_edit_company_id");
      this.selectedCompany= localStorage.getItem("category_edit_company_id");

      this.fb.getCategoryById(this.selectedCompany,this.category_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.categoryObj = response.data;
         // console.log(self.categoryObj);
          self.dataBinding();
        } else {

        }
      });
    }
    this.profileForm = this.formBuilder.group({
      name: ['', [Validators.required,this.noWhitespaceValidator]],
      description: [''],
      selectedCompany:[(this.customer_id==0)?'':this.customer_id, Validators.required]
    });
  }
  get f() { return this.profileForm.controls; }
  dataBinding() {
    // this.name = this.categoryObj.category_name;
    // this.description = this.categoryObj.category_description;
    // this.screenshot = this.categoryObj.category_screenshot;
    // this.isactive = this.categoryObj.isactive;

    this.profileForm = this.formBuilder.group({
      name: [this.categoryObj.category_name, [Validators.required,this.noWhitespaceValidator]],
      description: [this.categoryObj.category_description],
      selectedCompany:[ this.selectedCompany, Validators.required]
    });
  }


  addNewCategoryData() {
    this.submitted = true;

   // console.log("ddddd...",this.profileForm)
    if (this.profileForm.invalid) {
      return;
    }
    let { name, description,selectedCompany } = this.profileForm.value;
    var obj = {
      name: name,
      description: description,
      isactive: true,
      iscorporate: true,
      type: 6,
      company:selectedCompany
    }
    this.fb.addNewCategory(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/category`);
      } else {
        this.toastrService.error(data.message)
      }
    }, error => {
      this.toastrService.error(error.message)
    });
  }
  updateCategory() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    let { name, description ,selectedCompany} = this.profileForm.value;
    let categoryobj = {
      category_name: name,
      category_description: description,
      category_screenshot: '',
      isactive: this.categoryObj.isactive,
      category_id: Number.parseInt(this.category_id, 10),
      is_corporate: true,
      company:selectedCompany
    }
  //  console.log(categoryobj, "####");
    this.fb.updateCategory(categoryobj).subscribe(data => {
      this.toastrService.success("Record Updated successfully");
      this.router.navigateByUrl(`/corporate/category`);
    }, error => {
      console.error(error);
    });
  }
  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);

  }
  processFile(event: any, imageInput: any) {
    // const file: File = imageInput.files[0];
    // console.log(file);
    // const reader = new FileReader();
    // this.fileData = file;
    // this.fileUploaded = true;
    // this.toUploadFiles(file);
    // reader.readAsDataURL(file);
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
    //  console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let vm = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            vm.screenshot = params.Location;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  getCompany() {

    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     // console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
