import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {BUSegmentationService} from '../../../services/segmentation/bu-segmentation.service';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bu-segmentation',
  templateUrl: './bu-segmentation.component.html',
  styleUrls: ['./bu-segmentation.component.css']
})
export class BuSegmentationComponent extends BaseComponent implements OnInit {

  buselected = '';
  buid: any;
  audBUList = [];
  entBUList = [];
  show = false;
  entshow = false;
  attrAndOperators = [];
  @ViewChild('accordion', {static: false}) accordion: NgbAccordion;
  @ViewChild('accordion2', {static: false}) accordion2: NgbAccordion;
  constructor(private segmentService: BUSegmentationService) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
    this.checkAudienceData();
  }

  //  ############  Check for Audience Data for Selected BU  ##############  //

  checkAudienceData() {
    this.ngxSpinnerService.show();
    this.segmentService.checkAudienceDataForBU(this.buid).subscribe(data => {
      if (data.status) {
        this.show = true;
        this.ngxSpinnerService.hide();
        this.getAudienceForBU();
      } else {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/add-bu-audience');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############  Get Audience List for BU  #################  //

  getAudienceForBU() {
    this.segmentService.getAudienceListforBU(this.buid).subscribe(data => {
      if (data.status) {
        this.audBUList = data.data;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.audBUList = [];
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###################  Fetch Entities on Audience Panel click  ############  //

  getEntityforBUAudience(auid){
    this.ngxSpinnerService.show();
    this.entBUList = [];
    this.attrAndOperators = [];
    this.entshow = false;
    this.segmentService.getEntitiesListforBUbyAudienceID(this.buid, auid).subscribe(data => {
      if (data.status) {
        this.entBUList = data.data;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.entBUList = [];
      }
      this.entshow = true;
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  getAttributeandOperatorforBUEntity(entid){
    this.ngxSpinnerService.show();
    this.attrAndOperators = [];
    this.segmentService.getAttributeandOperatorforBUEntity(this.buid, entid).subscribe(data => {
      if (data.status) {
        this.attrAndOperators = data.data;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.attrAndOperators = [];
      }
      this.entshow = true;
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  editAudience(){
    this.router.navigateByUrl('/corporate/update-bu-audience');
  }

  //  ###############  On Entity selected for Update  ####################  //

  editEntities(audid, audienceid) {


    let URL = '/corporate/update-bu-entities';
    this.router.navigate([URL], { queryParams: {'auid': audid,'audid':audienceid} });

  }

  //  ############  On Attribute selected for Update ####################  //

  editAttributes(ent){
    let id = ent.entity_id;
    let bu_entity_id = ent.bu_entity_id;

    let URL = '/corporate/update-bu-attribute'  ;
    this.router.navigate([URL],  { queryParams: {'entid': id,'buentid':bu_entity_id}});

  }

  //  ##############  Update Operators  ###############################  //

  editOperators(ent, mfId){
    let entid = ent.entity_id;
    let mfid = mfId;

    let URL = '/corporate/update-bu-operator'  ;
    this.router.navigate([URL], { queryParams: {'entid': entid,'bumfid':mfId} });

  }

  isOpen(i): boolean {
    return this.accordion && this.accordion.activeIds.includes(i.toFixed());
  }

  is2Open(i): boolean {
    return this.accordion2 && this.accordion2.activeIds.includes(i.toFixed());
  }
}
