<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/subscriptionModule">Subscription Module</a></li>
          <li class="breadcrumb-item" disabled><a routerLink="/corporate/subscription-list">Subscription</a></li>
          <li class="breadcrumb-item active" aria-current="page">Update Subscription<li>

      </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="updateSubscriptionForm">
        <div class="card">
          <div class="card-body">
            <div class="form-group" *ngIf="customer_id===0">

              <label for="exampleForm2">Company Name <span class="required">*</span></label>

              <select formControlName="selectedCompany" class="form-control formstyle ssss"
                *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>

            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
            <div class="form-group">
              <label for="name">Title <span class="required">*</span></label>
              <input type="text" class="form-control" name="title" formControlName="title"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="50">
              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required || f.title.hasError('whitespace')">Title is required</div>
                <div *ngIf="f.title.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="name">Slug Title <span class="required">*</span></label>
              <input type="text" class="form-control" name="slug_title" formControlName="slug_title"
                [ngClass]="{ 'is-invalid': submitted && f.slug_title.errors }" maxlength="50">
              <div *ngIf="submitted && f.slug_title.errors" class="invalid-feedback">
                <div *ngIf="f.slug_title.errors.required || f.slug_title.hasError('whitespace')">Slug title is required</div>
                <div *ngIf="f.slug_title.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="exampleForm2">Subscription Type <span class="required">*</span></label>
              <select formControlName="subscription_type" class="form-control formstyle ssss"
                 [ngClass]="{ 'is-invalid': submitted && f.subscription_type.errors }">
                <option value="">Select Subscription Type</option>
                  <option value="14-Days">14-Days</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
              </select>

              <div *ngIf="submitted && f.subscription_type.errors" class="invalid-feedback">
                <div *ngIf="f.subscription_type.errors.required">Subscription type is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="price">Price <span class="required">*</span></label>
              <input type="number" class="form-control" name="price" formControlName="price" [ngClass]="{ 'is-invalid': submitted && f.price.errors }">
              <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                <div *ngIf="f.price.errors.required">Price is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="gst_rate">Gst Rate <span class="required">*</span></label>
              <input type="number" class="form-control" name="gst_rate" formControlName="gst_rate" [ngClass]="{ 'is-invalid': submitted && f.gst_rate.errors }">
              <div *ngIf="submitted && f.gst_rate.errors" class="invalid-feedback">
                <div *ngIf="f.gst_rate.errors.required">Gst rate is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="half_yearly_discount">Half Yearly Discount(%)</label>
              <input type="number" class="form-control" name="half_yearly_discount" formControlName="half_yearly_discount">
              <!-- <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                <div *ngIf="f.price.errors.required">Price is required</div>
                <div *ngIf="f.price.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div> -->
            </div>
            <div class="form-group">
              <label for="yearly_discount">Yearly Discount(%)</label>
              <input type="number" class="form-control" name="yearly_discount" formControlName="yearly_discount">
              <!-- <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                <div *ngIf="f.price.errors.required">Price is required</div>
                <div *ngIf="f.price.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div> -->
            </div>
            <div class="form-group">
              <label for="allocate_point">Allocate Point <span class="required">*</span></label>
              <input type="number" class="form-control" name="allocate_point" formControlName="allocate_point" [ngClass]="{ 'is-invalid': submitted && f.allocate_point.errors }">
              <div *ngIf="submitted && f.allocate_point.errors" class="invalid-feedback">
                <div *ngIf="f.allocate_point.errors.required">Allocate point is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="allocate_point">Self Referral Point <span class="required">*</span></label>
              <input type="number" class="form-control" name="self_referral_point" formControlName="self_referral_point" [ngClass]="{ 'is-invalid': submitted && f.self_referral_point.errors }">
              <div *ngIf="submitted && f.self_referral_point.errors" class="invalid-feedback">
                <div *ngIf="f.self_referral_point.errors.required">Self referral point is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="allocate_point">Ds Referral Point <span class="required">*</span></label>
              <input type="number" class="form-control" name="ds_referral_point" formControlName="ds_referral_point" [ngClass]="{ 'is-invalid': submitted && f.ds_referral_point.errors }">
              <div *ngIf="submitted && f.ds_referral_point.errors" class="invalid-feedback">
                <div *ngIf="f.ds_referral_point.errors.required">Ds referral point is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="orderno">Order Number</label>
              <input type="number" class="form-control" name="orderno" formControlName="orderno">
              <!-- <div *ngIf="submitted && f.allocate_point.errors" class="invalid-feedback">
                <div *ngIf="f.allocate_point.errors.required">Allocate point is required</div>
                <div *ngIf="f.allocate_point.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div> -->
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <button class="btn btn-round btn-margo-action" (click)="updateSubscriptionData()">Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
