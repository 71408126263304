
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class ExtcompanysellerbuService {
  public baseUrl;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/external/';
  }

  getTableList(companyId) {
    return this.http.get<any>(this.baseUrl + `list/tables/${companyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getBuListByTableName(companyId, tablename, limit, skip) {
    return this.http.get<any>(this.baseUrl + `listBu/${companyId}/${tablename}/${limit}/${skip}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  buListByFieldNameSearch(companyId, tablename, limit, skip, field_name, searchtext) {
    let fieldname;
    let searchText;
    if(field_name != undefined) {
      fieldname = field_name;
    }
    if(searchtext != undefined) {
      searchText = searchtext;
    }
    const params = new HttpParams()
      .set('fieldname', fieldname)
      .set('value', searchText);
    return this.http.get<any>(this.baseUrl + `listBu/${companyId}/${tablename}/${limit}/${skip}`, {params})
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }



  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
