
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-premium-services-summary',
  templateUrl: './premium-services-summary.component.html',
  styleUrls: ['./premium-services-summary.component.css']
})
export class PremiumServicesSummaryComponent  implements OnInit {  
    companyID=0;
    companyId;
    pendingrecord:number=0;
    currentUser;
    company_status:boolean=false;
    cpdata={customer_id:null};
    maxdate = new Date();
    getCompanyobj;
    customerID;
    obj;
    reportData;
    p: number = 1;
    total: number;    
    start_date;
    end_date;
    status='select';
    clicked=false;
    total_rep=0;
    total_crs=0;
    total_camp=0;
    total_msg=0;
    total_clicked=0;
    total_amount=0;
    total_purchase=0;
    total_product_doller=0;
    total_email=0;
    total_campaign=0;   
    total_campaig_replishement = 0; 
    total_campaig_cross = 0;
    showResult:boolean=true;
    isHeadquater;
    
    constructor(
      private report:ReportService,
      private spinner : NgxSpinnerService,
      public toastrService: ToastrService,
      private clientService : ClientService
    ) { }
  
    ngOnInit() {      
      this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
      this.companyId= this.currentUser.admin_customer_id;
      this.companyID = this.companyId;
    
      this.isHeadquater = localStorage.getItem("customer_isheadquarter");

      console.log("ssss,,,,.",this.isHeadquater)
      if(this.companyID==0 || this.isHeadquater ==="true"){
        this.showResult=false;
        this.getCompany(this.companyId);   
        this.companyID =0;
        this.isHeadquater = true;
        //this.tot_campaign_reimplencement(3,null,null);   
        //this.tot_campaign_reimmplencement_price(3,null,null);
       // this.tot_campaign_crosssell(3,null,null);
        //this.tot_campaign_crosssell_price(3,null,null);
       // this.select_total_campaign(3,null,null);
        //this.select_bu_optout_email(3,null,null);
       // this.select_bu_optout_sms(3,null,null);
      }else{
        this.isHeadquater = false;
        this.showResult=true;
        this.company_status=true;
        this.companyID=this.companyId;
        this.gettingReports(1,this.companyID)
      }     
    }
  
    getCompany(companyId:any) {    
      let self = this;     
      this.clientService.getBUCompanies().subscribe(data => {
        self.getCompanyobj = data.data;
      }, error => {
      //  console.log(error);
      });  
    }

    tot_campaign_reimplencement(id,date1:any,date2:any){
      var body={customer_id:id,date1:date1,date2:date2};
    this.report.tot_campaign_reimplencement(body).subscribe((data)=>{
      if(data.status){ 
              this.total_rep = data.data;        
      }else{
              this.reportData = [];
              this.total= 0;
        }      
          this.spinner.hide();
    });}
   
    tot_campaign_reimmplencement_price(id,date1:any,date2:any){
      var body={customer_id:id,date1:date1,date2:date2};
    this.report.tot_campaign_reimmplencement_price(body).subscribe((data)=>{
      if(data.status)
      { 
        this.total_campaig_replishement = parseInt(data.data);
        console.log("1111",this.total_campaig_replishement )

        this.total= data.totalCount;
      }
      else
      {
        this.reportData = [];
        this.total=null;
      }      
     this.spinner.hide();
    });
  }

    tot_campaign_crosssell(id,date1:any,date2:any){
      var body={customer_id:id,date1:date1,date2:date2};
    this.report.tot_campaign_crosssell(body).subscribe((data)=>{
      if(data.status)
      { 
        this.total_crs = data.data;
      }
      else
      {
        this.reportData = [];
        this.total_crs=null;
        this.total= 0;
      }      
     this.spinner.hide();
    });}

    tot_campaign_crosssell_price(id,date1:any,date2:any){
      var body={customer_id:id,date1:date1,date2:date2};
    this.report.tot_campaign_crosssell_price(body).subscribe((data)=>{
      if(data.status)
      { 
        this.total_campaig_cross = parseInt(data.data);
        console.log("2222",this.total_campaig_cross )
        this.total= data.totalCount;
        this.total_amount  = this.total_campaig_cross + this.total_campaig_replishement;
      }
      else
      {
        this.reportData = [];
        this.total= 0;
        this.total_campaign=null;
      }      
     this.spinner.hide();
    });}

    select_click(id,date1:any,date2:any){
      var body={customer_id:id,start_date:date1,end_date:date2};
      this.report.select_click(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total_clicked = data.data;
          this.total= data.totalCount;
        }
        else
        {
          this.reportData = [];
          this.total= 0;
          this.total_clicked=null;
        }      
       this.spinner.hide();
      });
 
    }

    select_total_campaign(id,date1:any,date2:any){
      var body={customer_id:id,start_date:date1,end_date:date2};

     
      this.report.select_total_campaign(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total_campaign = data.data;
          this.total= data.totalCount;
        }
        else
        {
          this.reportData = [];
          this.total= 0;
          this.total_campaign=null;
        }      
       this.spinner.hide();
      });

    }

        select_bu_optout_email(id,date1:any,date2:any){
          var body={customer_id:id,start_date:date1,end_date:date2};
           this.report.select_bu_optout_email(body).subscribe((data)=>{
             if(data.status)
             { 
               this.total_email = data.data;
               this.total= data.totalCount;
             }
             else
             {
               this.reportData = [];
               this.total= 0;
               this.total_email=null;
             }      
            this.spinner.hide();
           }); }
        select_bu_optout_sms(id,date1:any,date2:any){
          var body={customer_id:id,start_date:date1,end_date:date2};
           //         this.spinner.show();  
           this.report.select_bu_optout_sms(body).subscribe((data)=>{
             if(data.status && data.data>0)
             { 
               this.total_msg = data.data;
               this.total= data.totalCount;
             }
             else
             {
               this.reportData = [];               
               this.total_msg=null;
             }      
            this.spinner.hide();
           }); }
        select_bu_sms(id,date1:any,date2:any){
          var body={customer_id:id,date1:date1,date2:date2};
          this.spinner.show();  
           this.report.getTotalCampaigndata(id).subscribe((data)=>{
             if(data.status && data.totalCount>0)
             { 
               this.reportData = data.data;
               this.total= data.totalCount;
             }
             else
             {
               this.reportData = [];
               
               
               this.reportData=null;
             }      
            this.spinner.hide();
           }); }
        
        getTotalCampaigndata(id,date1:any,date2:any){
          var body={customer_id:id,date1:date1,date2:date2};
               this.spinner.show();  
      this.report.getTotalCampaigndata(id).subscribe((data)=>{
        if(data.status)
        { 
          this.reportData = data.data;
          this.total= data.totalCount;
        }
        else
        {
          this.reportData = [];
          this.total= 0;
        }      
       this.spinner.hide();
      }); }


    gettingReports(page,id) {



      this.companyID = id;

      if(this.companyID==0) {
        this.toastrService.error("Please select company");
        return false;
      }
      const parmas = {
        customer_id: this.companyID,
        page: page,    
        start_date:this.start_date,
        end_date:this.end_date
      };
      if(this.start_date > this.end_date) {
        setTimeout(() => {
          this.toastrService.error("Please select fromdate less than todate");
        }, 1000);
        return false;
      }
      
        this.showResult=true;
        this.tot_campaign_reimplencement(this.companyID,this.start_date,this.end_date);
        this.tot_campaign_reimmplencement_price(this.companyID,this.start_date,this.end_date);
        this.tot_campaign_crosssell(this.companyID,this.start_date,this.end_date);
        this.tot_campaign_crosssell_price(this.companyID,this.start_date,this.end_date);
        this.select_total_campaign(this.companyID,this.start_date,this.end_date);
        this.select_bu_optout_email(this.companyID,this.start_date,this.end_date);
        this.select_bu_optout_sms(this.companyID,this.start_date,this.end_date);

      
    }
  
    exportReportCsv() {
      this.spinner.show();
      const parmas = {
        customer_id: this.companyID,
        page: this.p,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
        csv : true
      };
      this.report.getDsSubscriptionList(parmas).subscribe(dataFile => {
      //  console.log(">>>>>", dataFile);
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
          this.spinner.hide();
        }
        this.spinner.hide();
      }, err => {
        this.toastrService.error(err.message);
        this.spinner.hide();
      });
    }
    resetReport(){
      this.start_date=null;
      this.end_date =null;     
      this.companyID = 0;
      this.showResult=false;

      //this.reportData(1,)
    }
  }