import {BaseComponent} from '../../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentationService} from '../../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-add-audience-entity-association',
  templateUrl: './add-entities-audience-association.component.html',
  styleUrls: ['./add-entities-audience-association.component.css']
})
export class AddEntitiesAudienceAssociationComponent extends BaseComponent implements OnInit{

  entitiesObj = [];
  entitiesid = [];
  entities = [];
  audienceList = [];
  audienceForm: FormGroup;
  submitted = false;
  show = false;
  limit = 9999;
  offset = 0;
  assocId;
  constructor(private formBuilder: FormBuilder, private segmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.assocId = params.assocId;
      });
  }

  ngOnInit() {

    this.getAudienceList();
   // this.getEntities();
    this.audienceForm = this.formBuilder.group({
      aid: ['', Validators.required],
      eid: ['']
    });
  }

  get f() { return this.audienceForm.controls; }

  //  ################ Get Values of Association for Edit/Update  ###  //

  getEditAssociation(){
    this.audienceForm = this.formBuilder.group({
      aid: [this.assocId, Validators.required],
      eid: ['']
    });
    this.ngxSpinnerService.hide();
    this.getEntityAudience(this.assocId);
  }

  //  ##############  Get Audience List ###################  //

  getAudienceList(){
    this.ngxSpinnerService.show();
    this.segmentService.getAudience(this.limit, this.offset, 'audience_name', 'asc','').subscribe(data => {
      if (data.status){
        this.audienceList = data.data.rows;
        this.getEntities();
      } else {
        this.ngxSpinnerService.hide();
        this.audienceList = [];
      }
    }, error => {
      this.audienceList = [];
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###########  Get Entities List  #################  //

  getEntities(){
    this.ngxSpinnerService.show();
    this.segmentService.getEntity(this.limit, this.offset, 'createdon', 'desc').subscribe(data => {

      if (data.status) {
        this.entities = data.data.rows;
        if (this.assocId){
          this.getEditAssociation();
        }
          this.ngxSpinnerService.hide();
      } else  {
        this.ngxSpinnerService.hide();
        this.entities = [];
      }
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###########  Get Entities List for Selected Audience ################  //

  getEntityAudience(val){
    this.entitiesObj = [];
    this.entitiesid = [];
    this.show = false;
    if (val === '') {
      return;
    }

    this.ngxSpinnerService.show();
    this.segmentService.getAssociatedAudienceForEntities(val).subscribe(data => {

      if (data.status) {

        this.show = true;
        this.entitiesObj = data.data;
        if (this.entitiesObj.length > 0) {
          this.entitiesObj.forEach(element => {
            this.entitiesid.push(element.entity_id);
          });
        }
      } else  {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #############  On Entity Selected  ##################  //

  selectEntities(event, id){
    if (event.target.checked) {
      this.entitiesid.push(id);
    } else {
      this.entitiesid.splice(this.entitiesid.indexOf(id), 1);
    }
  }

  //  ##########  Add Entities to Audience Association  #############  //

  addEntities(){
    this.submitted = true;
    if (this.audienceForm.invalid) {
      return;
    }
    if (this.entitiesid.length === 0) {
      this.toastrService.error('Please select at least one Entity');
      return;
    }
    let obj = {
      'audience_id': this.audienceForm.value.aid,
      'entity_id': this.entitiesid
    };

    this.segmentService.addEntityAudienceAssociation(obj).subscribe(data => {
      this.ngxSpinnerService.show();
      if (data.status) {
        this.toastrService.success('Association added Successfully');
        this.router.navigateByUrl('/corporate/entities-audience-association-list');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  checkForIdInList(id) {
    if (this.entitiesid.indexOf(parseInt(id, 10)) >= 0) {
      return true;
    }
    return false;
  }


}
