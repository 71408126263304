<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
        <li class="breadcrumb-item active">Support Ticket</li>
      </ol>
    </nav>
  </div>
  
  <div class="container themeTblReso">
  
    <div class="row">
      <div class="col-md-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                aria-hidden="true"></i></span>
          </div>
  
          <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText 
            [disabled]="selectedCompanyId == 0">
        </div>
  
      </div>
      <!-- <div class="col-md-4 mt-3 mt-md-0" style="justify-content: right;">
        <input type="button" value="Add New" class="custbtn ml-0 mt-md-1" *ngIf="isWrite" routerLink="/corporate/">
      </div> -->
    </div>
  </div>
  
  <div class="container" *ngIf="companyID == 0">
    <div class="row">
    <div class="col-md-6 mt-2">
        <select name="company" class="form-control formstyle"  (change)="getDsDropdown($event.target.value)">
          <option value = 0>Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>
  
    </div>
    <div class="col-md-6 mt-2">
        <select name="company" class="form-control formstyle" (change)="getSupportTicketOnFilt($event.target.value)">
          <option value = 0>Select Directseller</option>
          <ng-container *ngFor="let ds of dsDropdown">
            <option value={{ds.ds_id}}>
              {{ds.ds_username}}
            </option>
          </ng-container>
        </select>
  
    </div>
  </div>
  </div>
  
  <div class="container mt-2 themeTblReso">
    
  
    <div class="tblPrt">
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
        <tr>
          <th>Directseller</th>
          <th>Ticket Title</th>
          <th>Comments count</th>
          <th>Data Synced</th>    
          <th>Oodo Id</th>
          <th>Status</th>
          <th (click)="sorting('creation_dt')" class="curPoint">
            Created On <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
          <th (click)="sorting('updated_dt')" class="curPoint">
            Last Updated <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
            
          <th>Actions</th>
  
        </tr>
        <tr
          *ngFor="let obj of supportTicketObj">
          <td>{{obj?.ds_username}}</td>
          <td>{{obj?.title}}</td>
          <td class="text-center">{{obj?.comments_count}}</td>
          <td>{{obj?.is_synced ? 'Yes' : 'No'}}</td>
          <td>{{obj?.oodo_id}}</td>
          <td>{{obj?.status | titlecase}}</td>
          <td>{{obj?.creation_dt |date:'mediumDate'}}</td>
          <td>{{obj?.updated_dt |date:'mediumDate'}}</td>
          <td>
            <div class="actBtn">
              <i class="fa fa-eye mr-1 cursor" (click)="viewTicket(obj.ds_id,obj.id)" placement="bottom"
                ngbTooltip="view"></i>
              <!-- <span class="fa fa-trash mr-1 cursor" (click)="deleteJourney(obj.journey_id)" *ngIf="isDelete"
                placement="bottom" ngbTooltip="Delete"></span>
  
              <mat-slide-toggle [checked]="obj.isactive" class="my-slider cursor" *ngIf="isEdit"
                (click)="updateStatus($event,obj.journey_id)">
              </mat-slide-toggle> -->
            </div>
          </td>
        </tr>
  
      </table>
  
      <div style="text-align: center;" *ngIf="totalCount === 0">
        <p>No Result Available</p>
      </div>
      <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getSupportTicketList((cp=$event))" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination> 
      </div>

  
  
  
    </div>