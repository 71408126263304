<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/dsuser">Direct Seller</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a routerLink="/corporate/dsuser">Ds Users</a>
      </li> -->
      <li class="breadcrumb-item active" aria-current="page">
        {{ header }} DS User
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1 addduser" [formGroup]="dsRegisterForm" (ngSubmit)="addNewDsUser()">
    <div class="row">
      <div class="col-xl-8 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="pb-3">
                  <input type="hidden" formControlName="username" id="username" value="xxxxxx" />
                  <input type="hidden" formControlName="image" id="image" value="margo.jpg" />
                  <input type="hidden" formControlName="table" id="table" value="1" />
                  <input type="hidden" formControlName="uid" id="uid" value="5" />
                  <input type="hidden" formControlName="usertype" id="usertype" value="1" />
                  <input type="hidden" formControlName="useruniqueid" id="useruniqueid" value="1" />
                </div>

                <div class="pb-3" *ngIf="customerID != 0">
                  <input type="hidden" formControlName="cid" [ngModel]="customerID" />
                </div>

                <div *ngIf="customerID == 0" class="pb-3">
                  <label for="exampleForm2 ">Customer Id <span class="required">*</span>
                  </label>
                  <select formControlName="cid" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.cid.errors }" required
                    (change)="loadBundles($event.target.value)">
                    <option value="" disabled >Please Select Company</option>
                    <ng-container *ngFor="let item of CustomerData">
                      <option *ngIf="item.dbstatus == true && item.is_bu" value="{{ item.customer_id }}">
                        {{ item.customer_company_name }}
                      </option>
                    </ng-container>
                  </select>
                  <div *ngIf="submitted && f.cid.errors" class="invalid-feedback">
                    <div *ngIf="f.cid.errors.required">
                      Customer Id is required
                    </div>
                  </div>
                </div>

                <div class="pb-3">
                  <label for="exampleForm2 ">Plan Type <span class="required">*</span>
                  </label>
                  <select formControlName="planType" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.planType.errors }" required>
                    <option value="">Select plan type</option>
                    <option value="mc">Secure mc</option>
                    <option value="mu">Unsecure mautic</option>
                  </select>

                  <div *ngIf="submitted && f.planType.errors" class="invalid-feedback">
                    <div *ngIf="f.planType.errors.required">
                      Plan type is required
                    </div>
                  </div>

                </div>


                <div class="pb-3">
                  <label>First Name <span class="required">*</span> </label>
                  <input type="text" appSpace autocomplete="off" formControlName="fname" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" id="fname"
                    placeholder="Enter your First Name" required />
                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                    <div *ngIf="f.fname.errors.required">
                      First Name is required
                    </div>
                  </div>
                </div>

                <div class="pb-3">
                  <label>Last Name <span class="required">*</span></label>
                  <input type="text" appSpace autocomplete="off" formControlName="lname" class="form-control formstyle"
                    id="fname" placeholder="Enter your Last Name"
                    [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" />
                  <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                    <div *ngIf="f.lname.errors.required">
                      Last Name is required
                    </div>
                  </div>
                </div>

                <div class="pb-3">
                  <label>Email <span class="required">*</span></label>
                  <input type="text" autocomplete="off" formControlName="email" class="form-control formstyle"
                    placeholder="Enter Your Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>
                <!--Phone No.  -->
                <div class="pb-3">

                  <label> Phone No.<span class="required">*</span></label>

                  <!-- <select

                        class="form-control formstyle ml-2"
                        style="max-width: 240px"
                        id="inlineFormCustomSelect"
                        formControlName="countryCode"
                        [ngClass]="{ 'is-invalid': submitted && f.countryCode.errors }">
                        <option value="" selected>Country Code</option>
                        <option
                          *ngFor="let items of allCountryCode"
                          value="{{items.dial_code}}" >
                          {{ items.name +"  (" + items.dial_code +")" }}
                        </option>

                      </select> -->
                  <!-- <div class="custom-control custom-radio custom-control-inline"> -->
                  <input type="text" appSpace autocomplete="off" formControlName="user_home_phone"
                    class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.user_home_phone.errors || f.user_home_phone.touched && f.user_home_phone.errors}"
                    id="user_home_phone" placeholder="Phone No." required />
                  <div *ngIf="f.user_home_phone.touched && f.user_home_phone.invalid">
                    <small
                      *ngIf="submitted && f.user_home_phone.invalid || f.user_home_phone.touched && f.user_home_phone.invalid"
                      style="color: red">Invalid Phone No.</small>
                  </div>
                  <!-- </div> -->

                </div>

                <!--Phone No.  -->

                <div class="pb-3">
                  <label>Do you want to add user for messenger?<span class="required">*</span></label>
                  <div class="custom-control custom-radio custom-control-inline ml-2">
                    <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isChat"
                      [value]="1" (change)="radioChange(1)" />
                    <label class="custom-control-label" for="customRadio1">YES</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isChat"
                      [value]="2" (change)="radioChange(2)" />
                    <label class="custom-control-label" for="customRadio2">NO</label>
                  </div>
                </div>

                <div class="pb-3" *ngIf="showChatUsers">
                  <label>Do you want to add this user as director?<span class="required">*</span></label>
                  <div class="custom-control custom-radio custom-control-inline ml-2">
                    <input type="radio" class="custom-control-input" id="isDirector1" formControlName="isDirector"
                      [value]="1" />
                    <label class="custom-control-label" for="isDirector1">YES</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="isDirector2" formControlName="isDirector"
                      [value]="0" />
                    <label class="custom-control-label" for="isDirector2">NO</label>
                  </div>
                </div>
                <div class="pb-3">
                  <label>Is Independent DS?<span class="required">*</span></label>
                  <div class="custom-control custom-radio custom-control-inline ml-2">
                    <input type="radio" class="custom-control-input" id="customRadio3"
                      formControlName="is_independent_ds" [value]=true (change)="independentDsChange(1)" />
                    <label class="custom-control-label" for="customRadio3">YES</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio4"
                      formControlName="is_independent_ds" [value]=false (change)="independentDsChange(2)" />
                    <label class="custom-control-label" for="customRadio4">NO</label>
                  </div>
                </div>

                <div class="pb-3" [hidden]="!showBussinessDomain">
                  <label>Business Domain<span class="required">*</span></label>

                  <!-- <select class="form-control formstyle"  id="inlineFormCustomSelect2"
                    formControlName="business_domain_id"
                    [ngClass]="{ 'is-invalid': submitted && f.business_domain_id.errors }">
                    <option value="" selected>Business Domain Name</option>
                    <option *ngFor="let items of businessDomainData" value="{{ items.id }}">
                      {{ items.domain_name }}
                    </option>
                  </select> -->
                  <ng-multiselect-dropdown  class="formstyle"
                  [placeholder]="'Select Business Domain'"
                  [data]="businessDomainData"
                  formControlName="business_domain_id"
                  [settings]="dropdownSettings"
                >
                </ng-multiselect-dropdown>
                  <div *ngIf="submitted && f.business_domain_id.errors" class="invalid-feedback">
                    <div *ngIf="f.business_domain_id.errors.required">
                      Bundle Domain is required
                    </div>
                  </div>

                </div>

                <div class="pb-3 mb-3">
                  <label>Bundle Name<span class="required">*</span></label>

                  <select class="form-control formstyle"  id="inlineFormCustomSelect"
                    formControlName="bundleid" [ngClass]="{ 'is-invalid': submitted && f.bundleid.errors }">
                    <option value="" selected>Bundle Name</option>
                    <option *ngFor="let items of allBundleList" value="{{ items.bundle_id }}">
                      {{ items.bundle_name }}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.bundleid.errors" class="invalid-feedback">
                    <div *ngIf="f.bundleid.errors.required">
                      Bundle Name is required
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-md-8 text-center mt-4 mb-4">
        <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewDsUser()">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
