<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/productcatalog">Product Catalog</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/woocommerce">WooCommerce</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/woocommerce">Cron-Schedular</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{page}}</li>
    </ol>
  </nav>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-xl-12" *ngIf="page=='Update'">
      <div class="card">
          <form class="form-horizontal" [formGroup]="editCredentialForm">
              <div class="card-body">

                  <div class="form-group">
                      <label>Name</label>
                      <input readonly class="form-control" formControlName="name" type='text'
                      maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

                      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">Name is required</div>

                      </div>
                  </div>
                  <div class="form-group">
                    <label>API Name</label>
                    <input readonly class="form-control" formControlName="apiname" type='text'
                    maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.apiname.errors }">

                    <div *ngIf="submitted && f.apiname.errors" class="invalid-feedback">
                        <div *ngIf="f.apiname.errors.required">API Name is required</div>

                    </div>
                </div>
                <div class="form-group">
                  <label>Query Field</label>
                  <input readonly class="form-control" formControlName="query_field" type='text'
                      maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.query_field.errors }">
                      <div *ngIf="submitted && f.query_field.errors" class="invalid-feedback">
                          <div *ngIf="f.query_field.errors.required">Query Field is required</div>
                      </div>
              </div>
                <div class="form-group">
                  <label>Schedule Type<span class="required"></span></label>
                  <select  name="scheduletype" formControlName='scheduletype'
                  class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.scheduletype.errors }">
                  <option value='seconds'>Seconds</option>
                  <option value='minutes'>Minutes</option>
                  <option value='hours'>Hours</option>
                </select>
                <div *ngIf="submitted && f.scheduletype.errors" class="invalid-feedback">
                  <div *ngIf="f.scheduletype.errors.required">Schedule Type is required</div>
              </div>
                </div>
                <div class="form-group">
                  <label>Cron Time<span class="required"></span></label>
                  <input class="form-control" formControlName="crontime" type='number'
                      maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.crontime.errors }">

                      <div *ngIf="submitted && f.crontime.errors" class="invalid-feedback">
                          <div *ngIf="f.crontime.errors.required">Cron Time is required</div>
                      </div>
              </div>
                  <div class="row mt-2">
                      <div class="col-12 text-center">
                          <button type="button" class="btn btn-round btn-margo-action"
                              (click)="updateCredential()">Update</button>

                          <button class="btn btn-round btn-margo-action" (click)="onBack()">Back </button>

                      </div>
                  </div>
              </div>
          </form>
      </div>
  </div>
  </div>
</div>
