<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/fb">Facebook</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/msgsnippets">FB Message Snippets</a>
      </li>
      <li class="breadcrumb-item active">Add / Update Message Snippets</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 mt-md-2">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="profileForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">
              <label for="exampleForm2">Company Name</label>

            
                <select  formControlName="selectedCompany" *ngIf="msg_id!= undefined" class="form-control formstyle" disabled="controlEnabled"  [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }" >

                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj" >
                    <option *ngIf="Obj.dbstatus == true"  value={{Obj.customer_id}}>
                  <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                  </ng-container>
                </select>

                <select  formControlName="selectedCompany" *ngIf="msg_id == undefined" class="form-control formstyle"  [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }" >
                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj" >
                    <option *ngIf="Obj.dbstatus == true"  value={{Obj.customer_id}}>
                  <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                  </ng-container>
                </select>
                <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                  <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
                </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">



            <div class="form-group">
              <label for="name"
                >Snippet Name <span class="required">*</span>
              </label>
              <input
                type="email"
                class="form-control"
                name="name"
                formControlName="name" min="1"
                 maxlength="80" appSpace
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
              />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="name"
                >Snippet Text <span class="required">*</span>
              </label>
              <textarea
               class="form-control text-area h-25" rows="6" min="1"
               maxlength="1000"
                formControlName="desc"
                appSpace
                [ngClass]="{ 'is-invalid': submitted && f.desc.errors }"
              ></textarea>
              <div *ngIf="submitted && f.desc.errors" class="invalid-feedback">
                <div *ngIf="f.desc.errors.required || f.desc.hasError('whitespace')">Snippet Text is required</div>
              </div>
            </div>

            <!-- <div [ngClass]="status ? 'displaynone' :'displayblock'" class="text-center mt-2">
          <button class="btn btn-round btn-margo-action" (click)="updateMsg()">update</button>
        </div> -->

            <!-- <div>
        <label for="exampleForm2"> Message Type</label>
        <input type="text" class="form-control" [(ngModel)]="type" class="form-control form-control-md">
      </div>

      <div class="mt-1">
        <label for="exampleForm2">Message Description</label>
        <input type="text" class="form-control" [(ngModel)]="description" class="form-control form-control-md">
      </div>
      <div class="my_class text-center">
        <button type="submit" class="custbtn mt-2" (click)="addNewMessageData()">Submit</button>
      </div> -->
          </div>
          <div class="card-footer">
            <div
              [ngClass]="status ? 'displayblock' : 'displaynone'"
              class="text-center"
            >
              <button
                class="btn btn-round btn-margo-action"
                appThrottleClick
                [throttleTime]="5000"
                (throttledClick)="addNewMessageData()"
              >
                Save
              </button>
            </div>
            <div
              [ngClass]="status ? 'displaynone' : 'displayblock'"
              class="text-center"
            >
              <button
                class="btn btn-round btn-margo-action"
                appThrottleClick
                [throttleTime]="5000"
                (throttledClick)="updateMessage()"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
