import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  public baseUrl;
  public RoleActionsDataByUser = new BehaviorSubject<any>(null);
  public CurrentPagePermissions = new BehaviorSubject<any>(null);
  public UserProfileData = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1
  }
  createUser(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "api/user/adminRegister", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getUser(id, limit, skip, colname, colname_val, searchText, status) {
    return this.http.get<any>(this.baseUrl + `api/user/getUsers/${id}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}&isactive=${status}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getType() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "api/user/getAdminType", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getUserProfileDetails() {
    return this.http.get<any>(this.baseUrl + "api/user/getUserProfileDetails", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCountryData() {
    return this.http.get<any>(this.baseUrl + "api/user/getCountryData", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyData() {
    return this.http.get<any>(this.baseUrl + "api/user/getCompanyData", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRoleData() {
    return this.http.get<any>(this.baseUrl + "api/user/getRoleDataChild", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRoleData2(value) {
    return this.http.get<any>(this.baseUrl + "api/user/getRoleData/" + value, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleData() {
    return this.http.get<any>(this.baseUrl + "api/user/getModuleRoleAssignHierarchyData", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleData1(value) {
    return this.http.get<any>(this.baseUrl + "api/user/getModuleItemDataById/" + value, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleData2(value,roleid) {
    return this.http.get<any>(this.baseUrl + "api/auth/getModuleItemDataByIdAndRoleId/" + value+"/"+roleid, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getUserById(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `api/user/getUserById/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getPermissionData() {
    var body = {};
    return this.http.get<any>(this.baseUrl + `api/user/getPermissionsData`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  deteteRoleAction(obj) {
    var body = obj;
    return this.http.post<any>(this.baseUrl + `api/deteteRoleAction`,body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateRoleAction(obj) {
    var body = obj;
    return this.http.post<any>(this.baseUrl + `api/updateRoleAction`,body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateUser(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "api/user/updateUser", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateUserStatus(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "api/user/updateUserStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }





  getRoleAction(value) {
    let body = {};
    return this.http.get<any>(this.baseUrl + "api/roleAction/" + value, this.httpHeadersOptions())
      .pipe(
        map(data => data.map((item: any) => {
          item.get_admin_role_action_fun.permission_list = item.get_admin_role_action_fun.permission_list.split(",");
          return item;
        })))
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  checkAcl() {
    let body = {};
    return this.http.get<any>(this.baseUrl + "api/checkAcl", this.httpHeadersOptions())
      .pipe(
      map(data=>{
        return data;
      }),
      catchError(this.handleError));
  }
  updateAcl() {
    let body = {};
    return this.http.get<any>(this.baseUrl + "api/updateAclJson", this.httpHeadersOptions())
      .pipe(
      map(data=>{
        return data;
      }),
      catchError(this.handleError));
  }
  getAllRoleActionsByUser() {
    return this.http.get<any>(this.baseUrl + "api/getAllRoleActionByUser", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleItems() {
    return this.http.get<any>(this.baseUrl + "api/user/getModuleItemData", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getMenuDropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "api/user/getMenuData", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleDataChild() {
    return this.http.get<any>(this.baseUrl + "api/user/getModuleDataChild", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getViewLogs(limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/viewLogs/admin/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getAllLogs(limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/logs/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDsViewLogs(id, limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/viewLogs/directSeller/${id}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getNewDsViewLogs(id, limit, skip, colname, colname_val, searchText, uid) {
    return this.http.get<any>(this.baseUrl + `api/log/viewLogs/directSeller/${id}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}&uid=${uid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCrudLogs(limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/crudLogs/admin/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDsCrudLogs(id, limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/crudLogs/directSeller/${id}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getLoginLogs(limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/loginLogs/admin/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }



  // alternate API of getDsBuLoginLogs start

    getDsBuLoginLogs(id=222, limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `api/log/loginLogsByBu/admin/${id}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  // alternate for getDsBuLoginLogs API end


  getDirectSellerViewLogs(id) {
    return this.http.get<any>(this.baseUrl + "api/log/viewLogs/directSeller/"+id+"/1000/0", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getDirectSellerCrudLogs(id) {
    return this.http.get<any>(this.baseUrl + "api/log/crudLogs/directSeller/"+id+"/1000/0", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  checkifpageauthorised(url, router): Observable<boolean> | boolean {
    if (localStorage.getItem("accesToken")) {
      if (url.indexOf('?') >= 0) {
        url = url.split('?')[0];
      }
      if (this.RoleActionsDataByUser.value === null) {
        this.getAllRoleActionsByUser().subscribe(data => {
          if (data) {
            this.RoleActionsDataByUser.next(data);
            var isexits = data.filter(d => d.itemroute === url);

            if (isexits.length === 0) {
              router.navigateByUrl(['/notauthorised']);
              return false;
            }
            else {
              this.CurrentPagePermissions.next(isexits[0].permission_list);
              router.navigate([url]);
              return true;
            }
          }
        }, error => {

        });
      }
      else {
        var data = this.RoleActionsDataByUser.value;
        var isexits = data.filter(d => d.itemroute == url);

        if (isexits.length === 0) {
          //router.navigate(['/notauthorised']);
          return false;
        }
        else {
          this.CurrentPagePermissions.next(isexits[0].permission_list);
          return true;
        }
      }
    }
    else {
      router.navigate(['/login'], { queryParams: { returnUrl: url } });
      return false;
    }

  }
  filterdata(myArray, myFilter) {
    var filtered = [];
    var filteredroutes = [];
    if (myFilter === null || myFilter.length == 0) {
      this.getAllRoleActionsByUser().subscribe(data => {
        if (data) {
          this.RoleActionsDataByUser.next(data);
          myFilter = data;
          for (var arr in myArray) {
            for (var filter in myFilter) {
              if (myArray[arr].page.replace('/', '') === myFilter[filter].itemroute && (filteredroutes.length === 0 || filteredroutes.indexOf(myArray[arr].page) === -1)) {
                filteredroutes.push(myArray[arr].page);
                filtered.push(myArray[arr]);
              }
            }
          }
        }
      });
    }
    else {
      for (var arr in myArray) {
        for (var filter in myFilter) {
          if (myArray[arr].page.replace('/', '') === myFilter[filter].itemroute && (filteredroutes.length === 0 || filteredroutes.indexOf(myArray[arr].page) === -1)) {
            filteredroutes.push(myArray[arr].page);
            filtered.push(myArray[arr]);
          }
        }
      }
    }

    return filtered;
  }

   /** get support user log by case id */

   getDirectSellerSupportCrudLogs(id, bu_id, limit, skip, colname, colname_val) {
    return this.http.get<any>(this.baseUrl + `api/log/crudsupportLogs/directSeller/${id}/${bu_id}/${limit}/${skip}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDsLoginLogs(dsid, limit, skip, colname, colname_val) {
    return this.http.get<any>(this.baseUrl + `api/log/loginLogsByDs/admin/${dsid}/${limit}/${skip}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDirectSellerViewLogsById(id, dsid, limit, offset, colname, colname_val) {
    return this.http.get<any>(this.baseUrl + `api/log/viewLogsByDsId/directSeller/${id}/${dsid}/${limit}/${offset}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCrudDsLogs(id, dsid, limit, offset, colname, colname_val) {
    return this.http.get<any>(this.baseUrl + `api/log/crudDsLogsById/directSeller/${id}/${dsid}/${limit}/${offset}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDSInformationById(company_id,id) {
    return this.http.get<any>(this.baseUrl + `api/log/getDSInformationById/directSeller/${company_id}/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  activeInactiveDs(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "api/ds/directSellerActiveInactive", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getHQBUCompanies() {
    return this.http.get<any>(this.baseUrl + "api/HqbucompanyList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAllWebLogs(body) {
    var body = body;
    return this.http.post<any>(this.baseUrl + "api/log/dslog/list", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRegistrationLogs(companyId, limit, skip, colname, colname_val, searchText,dsId) {
    return this.http.get<any>(this.baseUrl + `api/log/register/directseller/${companyId}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}&ds_id=${dsId}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getDsWebLogs(body:any,companyId:any) {
    var body = body;
    return this.http.post<any>(this.baseUrl + `api/log/ds-web-log/${companyId}`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  ////tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
}
