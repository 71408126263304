import { Component, OnInit } from '@angular/core';
import { RegisterService } from 'src/app/services/users/register.service';

@Component({
  selector: 'app-usertypes',
  templateUrl: './usertypes.component.html',
  styleUrls: ['./usertypes.component.css']
})
export class UsertypesComponent implements OnInit {

  constructor(private registerService: RegisterService) { }
  currentUser;
  companyId;
  userProfileDetails: any;
  stat = false;

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;
    this.getUserProfileDetails();
  }

  getUserProfileDetails() {
    let self = this;
    self.registerService.getUserProfileDetails().subscribe(data => {
      if (data.status) {
        //self.status = true;
        self.userProfileDetails = data.data;
        this.stat = true;
      }
    }, error => {
      this.stat = true;
    });
  }

}
