<div class="route" [ngClass]="{
    'action_bg': data.nodeType=='action' && data.conditionFlowType=='',
    'condition_bg': data.nodeType=='condition' && data.conditionFlowType=='',
    'true_bg':data.conditionFlowType=='yes' , 'false_bg':data.conditionFlowType=='no' 
  }" #canvasContent>
  <div *ngIf="data.executionType!='immediately'">
    <span *ngIf="data.executionData.type=='date' ; else showtime">
      On {{data.executionData.value}}
    </span>
    <ng-template #showtime>
      In {{data.executionData.value}} {{data.executionData.type}}
    </ng-template>
  </div>
  <span>{{data.name}} <i class="fa fa-trash ml-2" (click)="delete()" aria-hidden="true"></i></span>
  <div *ngIf="data.nodeType=='condition'">
    <span class="d-flex">
      <button class="btn btn-sm true_bg" (click)="onAddNewConditionRoute(routeConditionModal,'yes');resetValues()">
        Yes <i class="fa fa-plus-circle" aria-hidden="true"></i>
      </button>
      <button class="btn btn-sm false_bg ml-2" (click)="onAddNewConditionRoute(routeConditionModal,'no');resetValues()">
        No <i class="fa fa-plus-circle" aria-hidden="true"></i>
      </button>
    </span>
  </div>
  <div *ngIf="data.nodeType=='action'">
    <span class="text-center">
      <i class="fa fa-plus-circle" (click)="onAddNewRoute(routeConditionModal);resetValues()" aria-hidden="true"></i>
    </span>
  </div>
</div>

<ng-template #routeConditionModal let-modal>
  <div class="modal-header dialHead">
    <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="col-md-12 row" *ngIf="!showNewRouteOption">
        <div class="col-md-6">
          <div class="card">
            <h5 class="card-header">Action</h5>
            <div class="card-body">
              <p class="card-text">An action is something executed by Mautic (e.g. send an email).</p>
              <button (click)="showNewRouteOption=true;routeOption='action'"
                class="btn btn-margo-action mt-1">Select</button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <h5 class="card-header">Condition</h5>
            <div class="card-body">
              <p class="card-text">A condition is based on known profile field values or submitted form data.</p>
              <button (click)="showNewRouteOption=true;routeOption='condition'"
                class="btn btn-margo-action mt-1">Select</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showNewRouteOption">
        <div class="col-md-12 mt-2" *ngIf="routeOption=='condition'">
          <label for="routeCondition">Conditions</label>
          <select name="newRouteCondition" [(ngModel)]="conditionValue" id="routeCondition"
            class="form-control custom-form-control">
            <option value=null>select</option>
            <option [value]="condition.id" *ngFor="let condition of conditions">{{ condition.name }}</option>
          </select>
        </div>
        <div class="col-md-12 mt-2" *ngIf="routeOption=='action'">
          <label for="routeCondition">Actions</label>
          <select name="newRouteAction" [(ngModel)]="actionValue" id="routeAction"
            class="form-control custom-form-control">
            <option value=null>select</option>
            <option [value]="condition.id" *ngFor="let condition of conditions">{{ condition.name }}</option>
          </select>
        </div>
        <div class="col-md-12 mt-2">
          <label for="routeCondition">Execute this event... </label>
          <div class="tab-container">
            <button type="button" class="tab-btn btn btn-margo-action"
              [ngClass]="{'btn btn-margo-action': activeButton === 'immediately'}"
              (click)="activeButton='immediately'">immediately</button>
            <button type="button" class="tab-btn" 
            [ngClass]="{'btn btn-margo-action': activeButton === 'time-period'}"  
            (click)="activeButton='time-period'">at a relative time period</button>
            <button type="button" class="tab-btn" 
            [ngClass]="{'btn btn-margo-action': activeButton === 'date-time'}"   
            (click)="activeButton='date-time'">at a specific date/time</button>
          </div>

        </div>
        <div class="col-md-12 row mt-2" *ngIf="activeButton === 'time-period'">
          <div class="col-md-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">#</span>
              </div>
              <input type="number" name="executionTimeDigit" min="1" [(ngModel)]="executionData.value"
                class="form-control" placeholder="" aria-label="days" aria-describedby="basic-addon1">
            </div>
          </div>
          <div class="col-md-8">
            <select class="form-control" id="executionTimeType" name="executionTimeType"
              [(ngModel)]="executionData.type">
              <option value=null selected></option>
              <option value="minute">minute(s)</option>
              <option value="hour">hours(s)</option>
              <option value="day">day(s)</option>
              <option value="month">month(s)</option>
              <option value="year">year(s)</option>
            </select>
          </div>

        </div>
        <div class="col-md-12 mt-2" *ngIf="activeButton === 'date-time'">
          <input type="date" class="form-control" id="executionDate" name="executionDate"
            [(ngModel)]="executionData.value">
        </div>

        <div class="col-md-12 text-center">
          <button type="button" (click)="routeOption=='condition'?createConditionRoutes():onAddRoute()"
            class="btn btn-margo-action mt-1">Add +</button>
          <button type="button" (click)="modal.close('closs-click')" class="btn btn-margo-cancel mt-1">Cancel</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>