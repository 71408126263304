<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Master Module</li>
    </ol>
  </nav>
</div>
<!-- Master Container 1 -->
<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Marketing Cloud Master</h5>
    </legend>

    <div class="row">

    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/themecategorymaster">
         <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">
          MC Theme Category
        </button>
    </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/thememaster">
          <i class="bi bi-palette green-lt-icon" aria-hidden="true"></i>MC Theme
        </button>
      </div>
      <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" routerLink="/corporate/journeymaster">
          <i class="fa fa-map-o blue-lt-icon" aria-hidden="true"></i>MC Journey
        </button>
      </div>
      <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" routerLink="/corporate/journey-span">
          <i class="fa fa-map-o blue-lt-icon" aria-hidden="true"></i>MC Journey Span
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/asset">
        <i class="fa fa-suitcase orange-dr-icon" aria-hidden="true"></i>MC Assets
      </button>
  </div>
      <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" (click)="navigateThemeAssociation('master')">
          <i class="bi bi-palette2 red-dr-icon"></i>
          <!-- <i class="fa fa-tachometer " aria-hidden="true"></i> -->
          MC Theme Associations
        </button>
      </div>

      <!-- <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" (click)="navigateThemeAssociation('direct-seller')">
          <i class="bi bi-palette2 red-dr-icon"></i>
          <i class="fa fa-tachometer " aria-hidden="true"></i>
          DS MC Theme Associations
        </button>
      </div> -->

      <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" (click)="navigateProductCatalogue('master')">
          <i class="bi bi-book orange-dr-icon"></i>
          Product Catalogue
        </button>
      </div>
<!--
      <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
        <button class="cbtn mt-1" (click)="navigateProductCatalogue('direct-seller')">
          <i class="bi bi-book orange-dr-icon"></i>
          DS Product Catalogue
        </button>
      </div> -->

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/journeytype">
          <i class="fa fa-map-o blue-lt-icon" aria-hidden="true"></i>MC Journey Type
        </button>
    </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/journeymaster">
          <i class="fa fa-map-o blue-lt-icon" aria-hidden="true"></i>MC Journey Associations
        </button>
    </div>

      <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/journeymaster">
        <i class="fa fa-suitcase orange-dr-icon" aria-hidden="true"></i>MC Asset Category
      </button>
    </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/journeymaster">
          <i class="fa fa-suitcase orange-dr-icon" aria-hidden="true"></i>MC Assets & Journey Associations
        </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="showMarketSection">
      <button class="cbtn mt-1" routerLink="/corporate/agreement">
        <i class="bi bi-book orange-dr-icon"></i>
        Terms & Conditions
      </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/businessDomain">
        <i class="fa fa-suitcase orange-dr-icon" aria-hidden="true"></i>Business Industry
      </button>
  </div>
    </div>
  </fieldset>

</div>

<div class="container mt-2 theme-fa-icon" *ngIf="id === 0 && showMarketSection">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">User & Role Master</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/rolesmaster">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Roles
        </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/permissions">
          <i class="fa fa-unlock-alt red-dr-icon" aria-hidden="true"></i>Permissions </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/module">
          <i class="fa fa-cubes purple-dr-icon" aria-hidden="true"></i>Modules
        </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/moduleitems">
          <i class="fa fa-cube blue-lt-icon" aria-hidden="true"></i>Module Items
        </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/subscription">
          <i class="bi bi-wallet-fill green-lt-icon" aria-hidden="true"></i>Client Subscription
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/add-role-resources">
          <i class="bi bi-people-fill orange-dr-icon " aria-hidden="true"></i>Roles & Resources</button>
      </div>
      <div class="col-xl-4  col-md-6">

      </div>
      <div class="col-xl-4  col-md-6">

      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" *ngIf="id === 0 && showMarketSection">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Master items for marGo</h5>
    </legend>

    <div class="row">
      <!--<div class="col-xl-4  col-md-6">
              <button class="cbtn mt-1" routerLink="/corporate/prospecttypemaster">
                <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>MC Prospect
              </button>
          </div>-->
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1 " routerLink="/corporate/trainingtypemaster">
          <i class="fa fa-graduation-cap orange-dr-icon" aria-hidden="true"></i>Training Type
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/eventtypemaster">
          <i class="fa fa-calendar-check-o red-dr-icon" aria-hidden="true"></i>Event Type
        </button>
      </div>

      <!-- <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1 " routerLink="/corporate/countrymaster">
          <i class="fa fa-flag-checkered blue-sky-icon" aria-hidden="true"></i>Country
        </button>
      </div>
      //hide tabs

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/currencymaster">
          <i class="fa fa-dollar green-dr-icon" aria-hidden="true"></i>Currency
        </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/industrymaster">
          <i class="fa fa-industry red-lt-icon" aria-hidden="true"></i>Industry
        </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/companytypemaster">
          <i class="fa fa-building purple-dr-icon" aria-hidden="true"></i>Company Type
        </button>
      </div>

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/loyalitytypemaster">
          <i class="fa fa-filter green-lt-icon" aria-hidden="true"></i>Loyalty Type
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
          <button class="cbtn mt-1" routerLink="/corporate/contacttype">
            <i class="fa fa-mobile-phone orange-dr-icon" aria-hidden="true"></i>Contact Type
          </button>
      </div>
    end here -->


      <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/importGeoCSV">
          <i class="fa fa-globe red-lt-icon" aria-hidden="true"></i>City CSV Import
        </button>
      </div>
      -->
    </div>
  </fieldset>
</div>


<div class="container mt-1 theme-fa-icon" *ngIf="showMarketSection">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold"> Help & Tips</h5>
    </legend>

    <div class="row">

      <div class="container mt-1 theme-fa-icon" *ngIf="showMarketSection">

          <div class="row">
            <div class="col-xl-4  col-md-6" *ngIf="id === 0">
              <button class="cbtn mt-1 " routerLink="/corporate/subjectlib">
                <i class="fa fa-book green-lt-icon" aria-hidden="true"></i>Subject Library
              </button>
            </div>

            <div class="col-xl-4  col-md-6" *ngIf="id === 0">
              <button class="cbtn mt-1" routerLink="/corporate/messagelib">
                <i class="fa fa-envelope-o orange-dr-icon" aria-hidden="true"></i>Message Library
              </button>
            </div>
            <div class="col-xl-4  col-md-6">
              <button class="cbtn mt-1" routerLink="/corporate/support-ticket">
                <i class="bi bi-card-heading purple-dr-icon" aria-hidden="true"></i>Support Ticket
              </button>
            </div>
          </div>
      </div>

    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" *ngIf="showMarketSection">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Contact Card</h5>
    </legend>

    <div class="row">



      <div class="container mt-1 theme-fa-icon" *ngIf="showMarketSection">


          <div class="row">
            <div class="col-xl-4  col-md-6">
              <button class="cbtn mt-1" routerLink="/corporate/attributes">
                <i class="bi bi-list-task blue-lt-icon" aria-hidden="true"></i>Attributes
              </button>
            </div>

            <div class="col-xl-4  col-md-6">
              <button class="cbtn mt-1" routerLink="/corporate/notes-list">
                <i class="bi bi-card-heading purple-dr-icon" aria-hidden="true"></i>Notes
              </button>
            </div>
          </div>

      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" *ngIf="isWallet">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">E-Wallet</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/e-wallet">
          <i class="bi bi-wallet2 orange-dr-icon" aria-hidden="true"></i>E-Wallet
        </button>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Reskinning</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" (click)="navigateReskinning('master')">
          <i class="bi bi-palette green-lt-icon" aria-hidden="true"></i>Branding
        </button>
      </div>

      <!-- <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" (click)="navigateReskinning('direct-seller')">
          <i class="bi bi-palette green-lt-icon" aria-hidden="true"></i>DS Branding
        </button>
      </div> -->

    </div>


  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Block IP Host</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/blockiphost">
          <i class="fa fa-server orange-dr-icon" aria-hidden="true"></i>Block IP Host
        </button>
      </div>
    </div>
  </fieldset>
</div>
<!--Resource Tabs-->
<div class="container mt-1 theme-fa-icon" *ngIf="id === 0 && showMarketSection">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Resource</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/res-category">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Resource Category
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/resource">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Resource
        </button>
      </div>
    </div>
  </fieldset>
</div>
