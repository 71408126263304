import { Component, OnInit } from '@angular/core';
import { PiiService } from 'src/app/services/pii/pii.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-pii-field',
  templateUrl: './pii-field.component.html',
  styleUrls: ['./pii-field.component.css']
})
export class PiiFieldComponent  extends BaseComponent implements OnInit {

  selectedAll: any;
  constructor(private piiservice: PiiService,
    private clientService:ClientService) {  super();}

  allSenTableFieldsobj;
  allsensitveTablesobj:any=[];
  allTableFieldsobj;
  showMessage = true;
  checkedTable;
  companyID;
  customerID;
  getCompanyobj;

  ngOnInit() {
    //this.companyID = localStorage.getItem('comapny_id');
    //this.gettingSensitiveTables();
    localStorage.setItem('comapny_id', "");
    var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
     this.customerID= customerInfo.admin_customer_id; 
     
     this.getCompany();
  }


  gettingSensitiveTables(id) {
    if(id == 0 || id == ''){this.allsensitveTablesobj = []; return;};
    this.ngxSpinnerService.show();
    let self = this;
   
    this.piiservice.gettingSensitiveTables(id).subscribe(data => {
      
      this.allsensitveTablesobj = data.data;
      this.companyID = id;
      localStorage.setItem('comapny_id',  this.companyID);
      
    //  console.log(this.allsensitveTablesobj);
     

    }, error => {
    //  console.log(error);
    });
    this.ngxSpinnerService.hide();
    
  }
  addNewData(){
    
  }

  gettingSenstiveTableFields(id) {
    // console.log("testing message");
    this.ngxSpinnerService.show();
    let self = this;
    
    this.piiservice.gettingSenstiveTableFields(id,this.companyID).subscribe(data => {
      this.ngxSpinnerService.hide();
      self.allSenTableFieldsobj = data.data;
      this.showMessage = false;
      this.checkedTable=id;
    //  console.log(self.allSenTableFieldsobj);  
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  gettingTableField(name) {
    let self = this;
    this.piiservice.gettingTableField(name,this.companyID).subscribe(data => {
      if(data.data) self.allTableFieldsobj = data.data;
      else self.allTableFieldsobj ="";
     // console.log(self.allTableFieldsobj);  
    }, error => {
     // console.log(error);
    });
  }

  deleteTableField(id) {
    
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.piiservice.deleteTableField(id,this.companyID).subscribe(data => {
            if (data.status) {
              this.gettingSenstiveTableFields(this.checkedTable);
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  getCompany() {
   
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  
}