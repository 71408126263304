<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Permissions</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>

      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>

  </div>
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1" routerLink="/corporate/addnewpermissions">
    <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0" style="width: 100%;">
    <tr>
      <!-- <th>Theme Id</th> -->
      <th (click)="chgord('x')" class="curPoint">Permission Name <i class=" fa fa-sort text-black"
          aria-hidden="true"></i></th>
      <th>Permission Description </th>
      
      
      <!-- <th>Created By</th> -->
      <th (click)="chgord('x')" class="curPoint"> Created On <i class=" fa fa-sort text-black"
          aria-hidden="true"></i></th>
      
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>

    <tr
      *ngFor="let obj of permissionObj | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">
      <!-- <td>{{obj.get_master_permission_fun.per_id}}</td> -->
      <td>{{obj.get_master_permission_fun.per_name}}</td>
      <td>{{obj.get_master_permission_fun.per_description}}</td>
      <!-- <td>{{obj.get_master_permission_fun.theme_screenshot}}</td> -->
      
      
      <!-- <td>{{obj.get_master_permission_fun.createdname}}</td> -->
      <td>{{obj.get_master_permission_fun.createdon | date:'mediumDate':'+0530' }}</td>
      
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
        <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editpermission(obj.get_master_permission_fun.per_id)"  placement="bottom" ngbTooltip="Edit"></i>
        <span class="fa fa-trash mr-1 cursor" *ngIf="isDelete" (click)="deletepermission(obj.get_master_permission_fun.per_id)" placement="bottom" ngbTooltip="Delete"></span>
        <mat-slide-toggle [checked]="obj.get_master_permission_fun.isactive" class="my-slider cursor" *ngIf="isEdit"
          (click)="updateStatus($event,obj.get_master_permission_fun.per_id)">
        </mat-slide-toggle>
      </div>
      </td>

    </tr>


</table>
<pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>