<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item" ><a routerLink="/corporate/attributes">Attributes</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Attributes</li>
    </ol>
  </nav>
</div>

<div class="container" >
  <form class="form-horizontal mt-1" [formGroup]="attrForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">
            <div *ngIf="companyID === 0">
              <label for="attrForm">Select Company Name <span style="color:red">*</span></label>
              <select formControlName="company" class="form-control formstyle"
                      [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                <option value="" selected>Select Company Name</option>
                <ng-container *ngFor="let userObj of getCompanyobj" >
                  <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
                  {{userObj.customer_company_name}}
                </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Company Name is required</div>
              </div>
            </div>

            <div>
              <label for="attrForm">Attribute Name <span style="color:red">*</span></label>
              <input type="text" appSpace maxlength="150" formControlName="attrname" class="form-control form-control-md"
                     placeholder="Provide Attribute Name" [ngClass]="{ 'is-invalid': submitted && f.attrname.errors }" required>
              <div *ngIf="submitted && f.attrname.errors" class="invalid-feedback">
                <div *ngIf="f.attrname.errors.required">Attribute Name is required</div>
              </div>
            </div>

            <div>
              <label for="attrForm">Attribute Description </label>
              <textarea appSpace formControlName="attrdesc" min="1" maxlength="499" class="form-control form-control-md"></textarea>
           <!--   <div *ngIf="submitted && f.attrdesc.errors" class="invalid-feedback">
                <div *ngIf="f.attrdesc.errors.required">Attribute Description is required</div>
              </div>-->
            </div>

          </div>

          <div class="card-footer text-center mt-1">
            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn"  (click)="addNewAttr()" >Submit</button>
            </div>
         <!--   <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
              <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateTheme()">Update</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
