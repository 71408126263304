import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DsuserService } from 'src/app/services/users/dsuser.service';

import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-teamassociation',
  templateUrl: './teamassociation.component.html',
  styleUrls: ['./teamassociation.component.css']
})
export class TeamassociationComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  public userObj: any = [];
  ord = 'asc';
  colname = "head";
  getCompanyobj: any;
  customerID: any;
  companyID: any = '';

  totalCount: number;
  page = 1;
  limit = 10;
  skip = 0;


  constructor(
    public dsregister: DsuserService,
    private clientService: ClientService
  ) { super(); }


  ngOnInit() {
    this.ngxSpinnerService.show();

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    if (this.customerID != 0) {
      this.companyID = this.customerID;
      this.getAssociatedTeam();
    } else this.getCompany();

    this.UserLogData()
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.dsregister.getAssociatedTeam(this.companyID, this.limit, this.skip, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.userObj = [] }
          else { this.userObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.userObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  setOrder(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getAssociatedTeam();
  }

  getAssociatedTeam() {
    if(this.companyID == ''){
      this.userObj = [];
      this.totalCount = 0;
      this.page = 1;
      return;}
    let searchTerm = this.searchText.nativeElement.value.trim();
    this.skip = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();

    this.dsregister.getAssociatedTeam(this.companyID, this.limit, this.skip, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.userObj = [] }
        else { this.userObj = data.data.rows }
        this.totalCount = data.data.total;
      } else {
        this.userObj = [];
        this.totalCount = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  UserLogData() {
    let UserLogs = {
      log_type: "direct seller User View",
      log_name: "direct seller User  View",
      log_description: "direct seller  User  View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(UserLogs).subscribe(data => {
      // console.log(data);
    }, error => {
      // console.log(error);         
    });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
    });
  }

  //Edit Client data
  editTeamAssociation(id, leadId) {
    this.router.navigate(['/corporate/addassociation'], { queryParams: { id: id, cid: this.companyID, leadId: leadId } });
  }

  deleteTeamAssociation(id) {
    let obj = {
      id: id,
      company: this.customerID
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.dsregister.deleteTeamAssociation(obj).subscribe(data => {
            if (data.status) {
              this.getAssociatedTeam();
              this.toastrService.success("Deleted Successfully");
            } else {
              this.toastrService.error("Something went wrong");
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.toastrService.error("Something went wrong");
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}