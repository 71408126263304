import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../../services/license/license.service';
import { AlertService } from '../../../_services/alert.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { ClientService } from '../../../services/clients/client.service';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-license-assign',
  templateUrl: './license-assign.component.html',
  styleUrls: ['./license-assign.component.css']
})
export class LicenseAssignComponent extends BaseComponent implements OnInit {

  getCompanyobj:any=[];
  selectedCompany;
  showForm;
  companyDS:any=[];
  allBundleList:any=[]
  selectedUsers:any=[];
  bundleAssignForm: FormGroup;
  submitted = false;
  cp = 1;
  ord = true;
  colname: "Name";
  text: any;

  constructor(
    private clientService: ClientService,
    private LS: LicenseService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { super(); }

  ngOnInit() {

    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
    });
  }
 
  get f() { return this.bundleAssignForm.controls; }
  loadBundleForm(id) {

    this.selectedCompany= id;
    this.showForm = true;
    this.ngxSpinnerService.show();


    this.bundleAssignForm = this.formBuilder.group({

      checkedUser: [''],

      bundleID: ['', Validators.required],
     
    });


    forkJoin(
      {
        companyDSData: this.LS.getDSList(id), 
        allBundleList: this.LS.getAllBundles(id,100), 

        
      }
    ).subscribe(data => {

    
      if (data.companyDSData.status) {        
        this.companyDS = data.companyDSData.data;
      }
      if (data.allBundleList.status) {        
        this.allBundleList = data.allBundleList.data;
      }
    
          // console.log(this.companyDS);

      

      this.ngxSpinnerService.hide();

    }), error => {
     // console.log(error);
      this.ngxSpinnerService.hide();

  }
  }

  selectUser(id) {

    var index = this.selectedUsers.indexOf(id);

    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    }
    else {
      this.selectedUsers.push(id);
    }


  }
  goSubmit() {

    



  

    this.submitted = true;
    if (this.bundleAssignForm.invalid) {
        return;
    }


    if( this.selectedUsers.length==0) {
      this.toastrService.error("Please select User");
      return;
    }




    let dataObject = {
      bundle_id: this.f.bundleID.value,     
      userid: this.selectedUsers,     
      company:this.selectedCompany,    
    }
    this.LS.assignBundle(dataObject).subscribe(data => {
      if(data.status) {

      this.toastrService.success("Bundle Assigned Successfully");
      this.router.navigateByUrl(`/corporate/license`);
      }else {

        this.toastrService.error(data.message);
      }
      
    }, error => {
    //  console.log(error);
    });



  }

  removeBundle(id) {

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to remove bundle from user ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {

          this.LS.removeBunbleFromUser(this.selectedCompany,id).subscribe(data => {
            if(data.status) {
      
              this.toastrService.success("License Bundle Removed Successfully");
              this.router.navigateByUrl(`/corporate/license`);
            }else {
      
              this.toastrService.error(data.message);
            }
            
          }, error => {
           // console.log(error);
          });



          // this.ngxSpinnerService.show();
         

        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));




  }

}
