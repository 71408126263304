<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Download CSV</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row" >    
  
   <div class="col-md-2 mt-2">
    <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
              [(ngModel)]="start_date"  >
              <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="col-md-2 mt-2">
     
              <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
              [(ngModel)]="end_date"  >
              <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker></div>
 
  <div class="col-md-4 mt-2">

     <!--<button class="btn btn-margo-action  formstyle gp-mr-5"  style="align-items: center;" (click)="donwloadCSV();">Download DS Login CSV</button>  -->


    <!-- <button class="btn btn-margo-action ml-0 formstyle gp-mr-5" (click)="resetReport()" style="align-items: center;">Reset</button>  -->
 
  </div>
</div>



