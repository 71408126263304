<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/contacttype">Contact Type</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Contact Type</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <form class="form-horizontal mt-1" [formGroup]="AddContact">
    <div class="rowTheme">
        <div class="themContain">
            <input value="0" type="hidden" formControlName='contact_type_parent_id'  class="form-control formstyle">
        <div class="mt-1">
            <label for="exampleForm2">Contact Type Name  <span class="required">*</span></label>
            <input type="text" class="form-control" appSpace formControlName='contact_type_name' class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': submitted && f.contact_type_name.errors }"   required maxlength="30" appSpace>
            <div *ngIf="submitted && f.contact_type_name.errors" class="invalid-feedback">
                <div *ngIf="f.contact_type_name.errors.required || f.contact_type_name.hasError('whitespace')">Contact Type Name is required</div>
                <div *ngIf="f.contact_type_name.errors.pattern">Contact Type Name is not a valid format</div>
                <div *ngIf="f.contact_type_name.errors?.maxlength">You can enter only 30 charachters</div>
       
        
    
            </div>
        </div>


        <div class="mt-1">
            <label for="exampleForm2">Contact Type Description</label>
            <input type="text" class="form-control"  formControlName='contact_type_description'
                class="form-control form-control-lg" >
        </div>




        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewContacttype()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateContacttype()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</form>
</div>
