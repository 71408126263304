<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
          <li class="breadcrumb-item"><a routerLink="/corporate/businessDomain">Business Domain</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{headers}} Business Domain</li>
      </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="domainForm">
      <div class="row">
          <div class="col-md-6">

              <div class="card mt-1">

                  <div class="card-body">
                    <div>
                        <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                        <select formControlName="company" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                            <option value="">Select Company</option>
                            <ng-container *ngFor="let userObj of getCompanyobj">
                                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </ng-container>
                        </select>
                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">
                                Company Name is required</div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <label for="exampleForm2">Domain Name <span style="color:red">*</span></label>
                        <input type="text" formControlName="domainName" class="form-control form-control-md"
                            [ngClass]="{ 'is-invalid': submitted && f.domainName.errors }" required appSpace>
                        <div *ngIf="submitted && f.domainName.errors" class="invalid-feedback">
                            <div *ngIf="f.domainName.errors.required">
                                Domain Name is required</div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <label for="exampleForm2">Domain Icon <span style="color:red">*</span></label>
                        <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                            <img src="{{domain_screenshot}}" alt="Margo" style="height: 50px;" (error)="setValue($event)">
                        </div>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio1"
                                    formControlName="firstActive" [value]="true" (change)='radioChange()'>
                                <label class="custom-control-label" for="customRadio1">Url</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio2"
                                    formControlName="firstActive" [value]="false" (change)='radioChange()'>
                                <label class="custom-control-label" for="customRadio2">Upload a file</label>
                            </div>
                        </div>
                        <div *ngIf="screenshot_type" class="mt-1">
                            <input type="text" formControlName="domain_image" class="form-control form-control-md mt-1"
                                placeholder="https://example.com/image(.jpg,.jpeg,.png,.gif)"
                              >

                        </div>
                        <div *ngIf="!screenshot_type" class="mt-1">
                            <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                (change)="processFile($event,imageInput)">
                            <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                        </div>
                    </div>
                    <div class="mt-2">
                      <label for="exampleForm2">Is Company</label>
                      <div>
                          <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" class="custom-control-input" id="isCompany1" name='is_company'
                                  formControlName="is_company" [value]="true">
                              <label class="custom-control-label" for="isCompany1">Yes</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" class="custom-control-input" id="isCompany2" name='is_company'
                                  formControlName="is_company" [value]="false">
                              <label class="custom-control-label" for="isCompany2">No</label>
                          </div>
                      </div>
                  </div>
                  <div class="mt-2">
                    <label for="exampleForm2">Is View</label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isView1" name='is_view'
                                formControlName="is_view" [value]="true">
                            <label class="custom-control-label" for="isView1">Yes</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isView2" name='is_view'
                                formControlName="is_view" [value]="false">
                            <label class="custom-control-label" for="isView2">No</label>
                        </div>
                    </div>
                </div>
                  </div>


                  <div class="card-footer text-center mt-1">
                      <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                          <button type="submit" class="custbtn" *ngIf="headers==='Add'"
                              (click)="addNewDomain()">Submit</button>
                      </div>
                      <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                          <button type="button" class="custbtn" *ngIf="headers==='Edit'"
                              (click)="updateDomain()">Update</button>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </form>
</div>
