import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MasterService } from "src/app/services/master/master.service";
import { ImportFilesDialogService } from "src/app/_utils/import-files-dialog/import-files-dialog.service";
import { OrderPipe } from "ngx-order-pipe";
import { BaseComponent } from "../../base.component";
import { ClientService } from "src/app/services/clients/client.service";
@Component({
  selector: "app-business-domain",
  templateUrl: "./business-domain.component.html",
  styleUrls: ["./business-domain.component.css"],
})
export class BusinessDomainComponent extends BaseComponent implements OnInit {
  public productObj: any = [];
  defaultPicture = "assets/custom/images/broken.png";
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = "asc";
  colname = "product_name";
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  disCompId: any = 0;
  @ViewChild("searchText", { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(private master: MasterService,
    private clientService: ClientService
    ) {
    super();
  }
  ngOnInit() {
    this.getCompany();

  }

  getBusinessDomain() {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getBusinessDomain(this.limit, this.skip,this.companyID).subscribe(
      (response) => {
        this.ngxSpinnerService.hide();
        if (response.status) {
          self.productObj = response.data.rows;
          self.totalCount = response.data.total;
        }
      },
      (error) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  editDomain(id) {
    this.router.navigate(["/corporate/addBusinessDomain"], {
      queryParams: { id: id ,companyId:this.companyID},
    });
  }

  deleteCatalog(event, id) {
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "ARE YOU SURE YOU WANT TO DELETE ?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          //obj.isdelete = !obj.isdelete;
          //obj.company = this.companyID;

          this.master.delDomain(id,this.companyID).subscribe(
            (data) => {
              if (data.status) {
                this.getBusinessDomain();
                // this.getProductCatalog(this.companyID)
                setTimeout(() => {
                  this.toastrService.success("Deleted Successfully");
                }, 1000);
              } else {
                this.toastrService.error(data.message);
              }
              this.ngxSpinnerService.hide();
            },
            (error) => {
              // console.log(error);
              this.ngxSpinnerService.hide();
            }
          );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.productObj.find((element) => {
      if (element.id === id) {
        return true;
      }
    });

    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.is_active = !obj.is_active;
          this.master
            .updateBusinessDomainStatus({
              id: obj.id,
              is_active: obj.is_active,
              company:this.companyID
            })
            .subscribe((data) => {
              this.toastrService.success(
                "Business Domain Status Changed Successfully "
              );
              this.ngxSpinnerService.hide();
            });
        } else {
          this.toastrService.error(" error : update catalogue");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }
  getCompanyId(id){
    this.companyID=id;
    this.getBusinessDomain()
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
}
