import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-add-poll-option',
  templateUrl: './add-poll-option.component.html',
  styleUrls: ['./add-poll-option.component.css']
})
export class AddPollOptionComponent extends BaseComponent implements OnInit {
  customer_id;
  addPollOptionForm: FormGroup;
  addPollOptionData: any = [];
  status: boolean = true;
  submitted = false;
  getCompanyobj: any;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService: MasterService
  ) { super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addPollOptionForm = this.formBuilder.group({
      option_name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    })
  }

  getCompany() {
    this.clientsService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  get f() { return this.addPollOptionForm.controls; }
  addNewPollOptionData() {
    this.submitted = true;
    if (this.addPollOptionForm.invalid) {
      return;
    }
    let requestData = {
      optionName: this.addPollOptionForm.value['option_name'],
    //  company: this.addPollOptionForm.value['selectedCompany'],
      //isActive: true,
    }

    this.ngxSpinnerService.show();
    this.masterService.addWebinarPollOptions(this.addPollOptionForm.value['selectedCompany'],requestData).subscribe(data => {
      console.log("🚀 ~ file: add-poll.component.ts:58 ~ AddPollComponent ~ this.masterService.addWebinarPoll ~ data:", data)
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Poll Option added successfully");
        this.router.navigateByUrl(`/corporate/poll-option`);
      } else {
        this.toastrService.error(data.message);
      }
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error.message);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
