import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent extends BaseComponent implements OnInit {
  id: any;
  companyObj: any;
  isWallet = false;
  showMarketSection = true;

  constructor(private master: MasterService) { super(); }

  ngOnInit() {
    let sid = JSON.parse(sessionStorage.getItem('userData'));
    this.id = sid.admin_customer_id;
    if (sid.role_id == 102) this.showMarketSection = false;

    if (this.id != 0) { this.getCompany() }
  }

  getCompany() {
    this.ngxSpinnerService.show();
    this.master.getCompanyInfo(this.id).subscribe(response => {
      if (response.status) {
        this.companyObj = response.data;
        this.isWallet = this.companyObj.wallet_status;
        this.ngxSpinnerService.hide();
      } else this.ngxSpinnerService.hide();
    }, err => {
      this.ngxSpinnerService.hide();
    });
  }

  navigateProductCatalogue(page){
    if(page=='master'){
      localStorage.setItem('page','master');
    }else{
      localStorage.setItem('page','direct-seller');
    }
    this.router.navigate(['/corporate/productcatalog']);
  }

  navigateReskinning(page){
    if(page=='master'){
      localStorage.setItem('page','master');
    }else{
      localStorage.setItem('page','direct-seller');
    }
    this.router.navigate(['/corporate/reskinning']);
  }
  navigateThemeAssociation(page){
    if(page=='master'){
      localStorage.setItem('page','master');
    }else{
      localStorage.setItem('page','direct-seller');
    }
    this.router.navigate(['/corporate/themeassociation']);
  }
}
