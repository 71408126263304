<ngx-spinner bdOpacity=0.9 bdColor="#333" size="large" color="#fff" type="ball-clip-rotate">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
         
          <li class="breadcrumb-item active" aria-current="page"> Role of resource</li>
      </ol>
  </nav>
</div>

<div class="container mt-1 ">  
      <div class="rowTheme">
          <div class="themContain ">
              <div class="col-md-8">
                  <label for="exampleForm2">Parent Module Item Name <span style="color:red">*</span></label>
                  <select  name="moduleid" class="form-control formstyle"  [(ngModel)]="moduleid">                

                    <option value="">Please select module name</option>  
                    <option *ngFor="let obj of moduleObj" value={{obj.get_master_module_item_fun.moduleitemid}}>

                          {{obj.get_master_module_item_fun.module_item_name}}
                      </option>
                  </select>                  
              </div>
              <div class="mt-2 col-md-8">
                <label for="exampleForm2">Permissions <span style="color:red">*</span></label>
                <select  name="permissonid" class="form-control formstyle"  [(ngModel)]="permissonid" (change)="getperids($event.target.value)">                

                  <option value="">Please select permission</option>  
                  <option *ngFor="let obj of permissiondata" value={{obj.get_master_permission_fun.per_id}}>

                        {{obj.get_master_permission_fun.per_name}}
                    </option>
                </select>                
            </div> 
            <div class="mt-2 col-md-8">                 
                  <label for="exampleForm2">Resources <span style="color:red">*</span></label>
                  <div class="row">
                    <div class="col-md-12">
                        <ng-multiselect-dropdown  class="formstyle"                  
                        [placeholder]="'select resource'"
                        [data]="resourcedata"
                        [(ngModel)]="resouceid"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)"
                      >
                      </ng-multiselect-dropdown>
                       </div>
                    <div class="col-sm-4"></div>
                  </div>           
                  <div class="row">
                    
                    <div class="col-12 mt-4"><div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                        <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addresourcepermission()" >Submit</button>
                    </div></div>
                  </div>           
            </div>              
           </div>
        
      </div>
      <div class="row">
        
     
</div>