import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { MasterService } from "src/app/services/master/master.service";

@Component({
  selector: "app-woo-commerce-update",
  templateUrl: "./woo-commerce-update.component.html",
  styleUrls: ["./woo-commerce-update.component.css"],
})
export class WooCommerceUpdateComponent extends BaseComponent implements OnInit {
  page: string = "Update";
  connectorId: number = 1;
  id: number;
  submitted=false;
  schemaObj: any = [];
  currentUser: any;
  packageCredData: any;
  companyID: any;
  constructor(public route: ActivatedRoute, private master:MasterService) {
    super()
  }

  ngOnInit(): void {
    let currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID=currentUser.admin_customer_id;
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    if (localStorage.getItem("connectorId")) {
      this.connectorId = parseInt(localStorage.getItem("connectorId"));
    }
    if (localStorage.getItem("formId")) {
      this.id = parseInt(localStorage.getItem("formId"));
    }

    this.route.queryParams.subscribe((params) => {
      this.page = params["path"];
    });
    if (this.page == "Update") this.bindFormData();
    this.getschemaList();
  }
  bindFormData() {
    this.master.getCredentialsById(this.companyID, this.currentUser.uid, this.id).subscribe((data) => {
      if (data.status) {
          this.packageCredData = data.data[0];
      }
    }, err => {
      console.log("error", err);
    })
  }
  async onEditPackageCred(form: NgForm) {
    this.submitted = true;
    let payload = {
      id: this.id,
      connector_id: this.connectorId,
      ...form.value,
    };
    this.ngxSpinnerService.show();
    this.master.updateCredentials(payload,this.companyID).subscribe(
      (response) => {
        this.ngxSpinnerService.hide();
        if (response.status) {
          this.toastrService.success("record update successfully");
          localStorage.setItem("moduleName", "PackageCredential");
          this.router.navigate([
            "/corporate/woocommerce",
          ]);
        } else {
          this.toastrService.error(response.message);
        }
      },
      (err) => {
        this.ngxSpinnerService.hide();
        console.log("error", err);
      }
    );
  }

  addForm(form: NgForm) {
    let payload = {
      connector_id: this.connectorId,
      ...form.value,
    };
    this.ngxSpinnerService.show();
    this.master.addCredentials(payload,this.companyID).subscribe(
      (res: any) => {
        this.ngxSpinnerService.hide();
        if (res.status == true) {
          this.toastrService.success("successfully added credential");
          localStorage.setItem("moduleName", "PackageCredential");
          this.router.navigate([
            "/corporate/woocommerce",
          ]);
        } else {
          this.toastrService.error(res.message);
        }
      },
      (error: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.error(error);
      }
    );
  }
  getschemaList() {
    this.ngxSpinnerService.show();
    this.master.getschemaList(this.companyID, this.connectorId).subscribe((response) => {
      this.ngxSpinnerService.hide();
      if (response.length > 0) {
        this.schemaObj = response;
      }
    }, error => {
      this.ngxSpinnerService.hide();
      console.log("error", error);
    });
  }
  onBack() {
    localStorage.setItem('moduleName','PackageCredential')
    this.router.navigate(['/corporate/woocommerce']);
  }
}
