import { Component, OnInit } from '@angular/core';
import { LogsService } from 'src/app/services/adminlogs/logs.service';

@Component({
  selector: 'app-client-headquater',
  templateUrl: './client-headquater.component.html',
  styleUrls: ['./client-headquater.component.css']
})
export class ClientHeadquaterComponent implements OnInit {

  constructor(private logs :LogsService) { } 
  ngOnInit() {
    this.ClientDetailsLogData()
  }
  ClientDetailsLogData() {
    let themeLogs = {
      log_type:" ClientDetails View" ,
      log_name:" ClientDetails View" ,
      log_description:" ClientDetails View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
}

}
