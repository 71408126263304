import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { MasterService } from "src/app/services/master/master.service";

@Component({
  selector: "app-woocommerce-cron-update",
  templateUrl: "./woocommerce-cron-update.component.html",
  styleUrls: ["./woocommerce-cron-update.component.css"],
})
export class WoocommerceCronUpdateComponent
  extends BaseComponent
  implements OnInit
{
  connectorId: number = 1;
  editCredentialForm: FormGroup;
  addCredentialForm: FormGroup;
  companyID: number;
  dsId: number;
  id: number;
  currentUser: any;
  submitted: boolean = false;
  page: string = "Update";
  constructor(
    public route: ActivatedRoute,
    public fb: FormBuilder,
    private master: MasterService
  ) {
    super();
  }

  ngOnInit(): void {
    let currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID=currentUser.admin_customer_id;
    this.route.queryParams.subscribe((params) => {
      this.page = params["path"];
    });
    if (localStorage.getItem("formId")) {
      this.id = parseInt(localStorage.getItem("formId"));
    }
    this.editCredentialForm = this.fb.group({
      name: [""],
      apiname: [""],
      crontime: [""],
      scheduletype: [""],
      query_field: [""],
    });

    this.addCredentialForm = this.fb.group({
      name: ["", [Validators.required]],
      cron_type: ["", [Validators.required]],
      cron_time: ["", [Validators.required]],
      time_zone: ["", [Validators.required]],
      cron_desc: ["", [Validators.required]],
    });
    this.bindFormData();
  }
  get f() { return this.editCredentialForm.controls; }
  get addForm() { return this.addCredentialForm.controls; }

  onBack() {
    localStorage.setItem('moduleName','CronSchedular')
    this.router.navigate(['/corporate/woocommerce']);
  }
  bindFormData() {
    this.master.getCronById(this.companyID,this.id).subscribe(
      (data) => {
        if (data.status) {
          this.editCredentialForm = this.fb.group({
            name: [data.data.name, [Validators.required]],
            apiname: [data.data.apiname, [Validators.required]],
            crontime: [data.data.crontime, [Validators.required]],
            scheduletype: [data.data.scheduletype, [Validators.required]],
            query_field: [data.data.query_field, [Validators.required]],
          });
        }
      },
      (err) => {
        console.log("error", err);
      }
    );
  }
  updateCredential() {
    this.submitted = true;
    if(this.editCredentialForm.invalid){
      return
    }
    let body = {
      id: this.id,
      company: this.companyID,
      connector_id:this.connectorId,
      name: this.f.name.value,
      apiname: this.f.apiname.value,
      crontime: this.f.crontime.value,
      scheduletype:this.f.scheduletype.value,
      query_field:this.f.query_field.value
    }
    this.ngxSpinnerService.show();
    this.master.updateCron(this.companyID,body).subscribe((response) => {
      this.ngxSpinnerService.hide();
      if (response.status) {
        localStorage.setItem('moduleName','CronSchedular')
        this.router.navigate(['/corporate/woocommerce'])
        this.toastrService.success("record update successfully");
      }
    }, err => {
      this.ngxSpinnerService.hide();
      console.log("error", err);
    })
  }
  navigatePage(){
    localStorage.setItem('moduleName','CronSchedular')
    this.router.navigate(['/corporate/woocommerce'])
  }
}
