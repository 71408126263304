import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-master-report',
  templateUrl: './master-report.component.html',
  styleUrls: ['./master-report.component.css']
})
export class MasterReportComponent  extends BaseComponent implements OnInit {

  constructor() { super() }
  projectName= environment.projectName;

  ngOnInit() {
  }

}
