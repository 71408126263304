import {BaseComponent} from '../base.component';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CampaignBuilderService} from '../../services/segmentation/campaign-builder.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-builder',
  templateUrl: './campaign-builder.component.html',
  styleUrls: ['./campaign-builder.component.css']
})
export class CampaignBuilderComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  currentUser;
  companyId;
  buCampaignObj:any=[];

  ord = 'desc';
  colname = 'createdon';
  totalNumber: number = 0;
  page = 1;
  limit = 10;
  skip = 0;

  constructor (private campaignBuilderService: CampaignBuilderService) {
    super();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.currentUser.admin_customer_id;
    this.getBUCampaign();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.campaignBuilderService.getBUCampaign(this.companyId, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.buCampaignObj = [] }
          else { this.buCampaignObj = res.data.rows }
          this.totalNumber = res.data.total;
        } else {
          this.buCampaignObj = [];
          this.totalNumber = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.buCampaignObj = [];
        this.totalNumber = 0;
        this.ngxSpinnerService.hide();
      });
  }

  //  ################  Get List of BU Campaign  #################  //

  // getBUCampaign() {

  //   this.ngxSpinnerService.show();
  //   this.campaignBuilderService.getBUCampaign(this.companyId,this.limit, this.offset).subscribe(data => {
  //     if (data.status) {
  //       this.buCampaignObj = data.data;
  //      // console.log('BU CAMPAIGN OBJECT', this.buCampaignObj);
  //     } else  {
  //       this.buCampaignObj = [];
  //     }
  //     this.ngxSpinnerService.hide();
  //   }, error => {
  //    // console.log(error);
  //     this.ngxSpinnerService.hide();
  //   });
  // }

  getBUCampaign() {
    this.skip = (this.page - 1) * this.limit;
    let searchTerm = this.searchText.nativeElement.value;
    this.ngxSpinnerService.show();

    this.campaignBuilderService.getBUCampaign(this.companyId, this.limit, this.skip, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.buCampaignObj = [] }
        else { this.buCampaignObj = data.data.rows }
        this.totalNumber = data.data.total;
      } else {
        this.buCampaignObj = [];
        this.totalNumber = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  setOrder(col) {
    this.colname = col;
    if (this.ord == 'asc') {
      this.ord = 'desc';
    } else {
      this.ord = 'asc';
    } this.getBUCampaign();
  }

  //   ###################  Delete Campaign ####################  //

  deleteCampaign(campid){
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete the selected campaign?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.campaignBuilderService.deleteCampaign(this.companyId, campid).subscribe(data => {
            if (data.status) {
              this.getBUCampaign();
              setTimeout(() => {
                this.toastrService.success('Campaign Deleted Successfully');
              }, 1000);
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
          //  console.log(error);
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  //   #################  Go To Campaign Edit Page  ####################  //

  editCampaign(campid){
    let URL = '/corporate/create-campaign-builder' + '?editCampId=' + campid;
   // console.log('SENT URL', URL);
    this.router.navigateByUrl(URL);
  }

  //   #################  Go to Audience Fetch List ####################  //

  goToAudienceList(audid){
    let URL = '/corporate/test-bu-built-audience' + '?campaign=yes&audid=' + audid;
    this.router.navigateByUrl(URL);
  }

}
