import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse,HttpClient } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json'
//   })
// };
@Injectable({
  providedIn: 'root'
})
export class AdminoneService {
  public baseUrl = 'http://ea35bd06.ngrok.io/api/auth/';
  public typeOfUser:String;

  constructor(private http: HttpClient) { }
  
  // getMenu() {
  //   return this.http.get<any>(this.baseUrl + "menu/1")
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }


  getMenu() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "menu/1", body)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError)
      );
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
