<div class="container">
    <div class="col-9">
        <nav aria-label="breadcrumb" class="breadCust">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="page"><a routerLink="/corporate/metadata-list">MetaData List</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add/Update MetaData DB BU Connections</li>
            </ol>
          </nav>
    </div>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="buForm">
      <div class="row">
        <div class="col-md-10 col-xl-7">
          <div class="card mt-1">
            <div class="card-body">
              <div >
                <label for="buForm">BU List <span class="required">*</span>
                </label>
                <select autocomplete="off" formControlName="buid" (change)="onBuSelected()"
                  class="form-control formstyle">
                  <option value="">
                    Select BU
                  </option>
                  <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                    {{ obj.customer_company_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="container">
    <h5>META DB Connection Details</h5>
    <form class="form-horizontal mt-1" [formGroup]="dbConnForm">
      <div class="row">
        <div class="col-md-10 col-xl-7">
          <div class="card mt-1">
            <div class="card-body">
                <div>
                    <label for="dbConnForm">Connection Type <span class="required">*</span>
                    </label>
                    <select autocomplete="off" formControlName="dbtype" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.dbtype.errors }">
                        <option value="">
                            Select DB Type
                        </option>
                        <option value="postgres">
                            POSTGRES
                        </option>
                        <option value="mssql">
                            MSSQL
                        </option>
                    </select>
                    <div *ngIf="submitted && f.dbtype.errors" class="invalid-feedback">
                        <div *ngIf="f.dbtype.errors.required">DB Type is required</div>
                    </div>
                </div>
                <div>
                    <label>Host</label>
                    <input type="text" formControlName="host" appSpace  maxlength="40" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.host.errors }" />
                    <div *ngIf="submitted && f.host.errors" class="invalid-feedback">
                        <div *ngIf="f.host.errors.required">Host is required</div>
                    </div>
                </div>
                <div>
                    <label>Username</label>
                    <input type="text" formControlName="username" appSpace  maxlength="40" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div>
                    <label>Password</label>
                    <input type="text" formControlName="password" appSpace  maxlength="40" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div>
                    <label>DB Name</label>
                    <input type="text" formControlName="dbname" appSpace  maxlength="40" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.dbname.errors }" />
                    <div *ngIf="submitted && f.dbname.errors" class="invalid-feedback">
                        <div *ngIf="f.dbname.errors.required">DB Name is required</div>
                    </div>
                </div>
                <div>
                    <label>Min Polling Time</label>
                    <input type="text" oninput="this.value=this.value.slice(0,this.maxLength)" (keydown)="allowNumbersOnly($event)"
                        onpaste="return false;" ondrop="return false;" formControlName="minpoll" class="form-control" maxlength="5"
                        pattern="[0-9]{0,5}" [ngClass]="{ 'is-invalid': submitted && f.minpoll.errors }" />
                    <div *ngIf="submitted && f.minpoll.errors" class="invalid-feedback">
                        <div *ngIf="f.minpoll.errors.required">Min Polling field is required</div>
                        <div *ngIf="f.minpoll.errors.pattern">
                            <p >Min Polling field should be Numeric</p>
                        </div>
                    </div>
                </div>
                <div>
                    <label>Max Polling Time</label>
                    <input type="text" oninput="this.value=this.value.slice(0,this.maxLength)" (keydown)="allowNumbersOnly($event)"
                        onpaste="return false;" ondrop="return false;" formControlName="maxpoll" class="form-control" maxlength="5"
                        pattern="[1-9][0-9]{0,4}" [ngClass]="{ 'is-invalid': submitted && f.maxpoll.errors }" />
                    <div *ngIf="submitted && f.maxpoll.errors" class="invalid-feedback">
                        <div *ngIf="f.maxpoll.errors.required">Max Polling field is required</div>
                        <div *ngIf="f.maxpoll.errors.pattern">
                            <p *ngIf="f.maxpoll.value === '0'">Max Polling field should be greater than 0</p>
                            <p *ngIf="f.maxpoll.value !== '0'">Max Polling field should not start with 0 or contain Special Characters
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <label>Idle Time</label>
                    <input type="text" oninput="this.value=this.value.slice(0,this.maxLength)" (keydown)="allowNumbersOnly($event)"
                        onpaste="return false;" ondrop="return false;" formControlName="idletime" class="form-control" maxlength="5"
                        pattern="[1-9][0-9]{0,4}" [ngClass]="{ 'is-invalid': submitted && f.idletime.errors }" />
                    <div *ngIf="submitted && f.idletime.errors" class="invalid-feedback">
                        <div *ngIf="f.idletime.errors.required">Idle Time field is required</div>
                        <div *ngIf="f.idletime.errors.pattern">
                            <p *ngIf="f.idletime.value === '0'">Idle Time field should be greater than 0</p>
                            <p *ngIf="f.idletime.value !== '0'">Idle Time field should not start with 0 or contain Special Characters
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <label for="dbConnForm">Encrypt <span class="required">*</span>
                    </label>
                    <select autocomplete="off" formControlName="encrypt" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.encrypt.errors }">
                        <option value="">
                            Select Encryption Status
                        </option>
                        <option value="true">
                            True
                        </option>
                        <option value="false">
                            False
                        </option>
                    </select>
                    <div *ngIf="submitted && f.encrypt.errors" class="invalid-feedback">
                        <div *ngIf="f.encrypt.errors.required">Encrypt Type is required</div>
                    </div>
                </div>
                <div>
                    <label for="dbConnForm">Trust Certificate <span class="required">*</span>
                    </label>
                    <select autocomplete="off" formControlName="t_cert" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.t_cert.errors }">
                        <option value="">
                            Select Certificate Status
                        </option>
                        <option value="true">
                            True
                        </option>
                        <option value="false">
                            False
                        </option>
                    </select>
                    <div *ngIf="submitted && f.t_cert.errors" class="invalid-feedback">
                        <div *ngIf="f.t_cert.errors.required">Trust Certificate Status is required</div>
                    </div>
                </div>
            </div>


            <div class="card-footer" >
                <div class="col-12"  style="justify-content: center">
                    <input
                      type="button"
                      value="SUBMIT"
                      *ngIf="isWrite"
                      class="custbtn red-lt-icon"
                      (click)=addDbConnMapping()/>    
                  </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>


