import { NgModule, Injector } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuard } from './_guards/role.guard';
import { AlertComponent } from './_directives/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { NgxPaginationModule } from "ngx-pagination";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgFlowchartModule } from '@joelwenzel/ng-flowchart';

import { OrderModule } from "ngx-order-pipe";
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxDataTableModule } from 'projects/ngx-data-table/src/public_api';
import { DatePipe } from '@angular/common';

import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { TokenInterceptorService } from './_interceptors/token.service';

// Routing
import { routing, routingComponents } from './app.routing';

// Components
import { AppComponent } from './app.component';

import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';

import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';

import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';

import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';



import { LoginComponent } from './login';

import { ChangelogComponent } from './changelog/changelog.component';

import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';

// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { GoogleChartsModule } from 'angular-google-charts';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { PartialsModule } from './content/partials/partials.module';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { DataApiService } from './_services/data.api';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { ForgotUserComponent } from './auth/forgot-user/forgot-user.component';
import { ForgotPasswrodComponent } from './auth/forgot-passwrod/forgot-passwrod.component';
import { CommonComponent } from './common/common.component';
import { ClientsComponent } from './secondadmin/clients/clients.component';
import { SecondadminComponent } from './secondadmin/secondadmin.component';
import { HomeComponent } from './secondadmin/home/home.component';
import { UsersComponent } from './secondadmin/usertypes/users/users.component';
import { RolesComponent } from './secondadmin/roles/roles.component';
import { MargoproductsComponent } from './secondadmin/margoproducts/margoproducts.component';
import { ReportsComponent } from './secondadmin/reports/reports.component';
import { NewusercontactComponent } from './secondadmin/usertypes/users/newusercontact/newusercontact.component';
import { RolesnewuserComponent } from './secondadmin/roles/rolesnewuser/rolesnewuser.component';
import { MargonewcontactComponent } from './secondadmin/margoproducts/margonewcontact/margonewcontact.component';
import { ReportsnewcntactComponent } from './secondadmin/reports/reportsnewcntact/reportsnewcntact.component';
import { ClientnewcontactComponent } from './secondadmin/clients/clientnewcontact/clientnewcontact.component';
import { MasterComponent } from './secondadmin/master/master.component';
import { ThememasterComponent } from './secondadmin/master/thememaster/thememaster.component';
import { JourneymasterComponent } from './secondadmin/master/journeymaster/journeymaster.component';
import { TrainingtypemasterComponent } from './secondadmin/master/trainingtypemaster/trainingtypemaster.component';
import { EventtypemasterComponent } from './secondadmin/master/eventtypemaster/eventtypemaster.component';
import { ActionmasterComponent } from './secondadmin/master/actionmaster/actionmaster.component';
import { MenumasterComponent } from './secondadmin/master/menumaster/menumaster.component';
import { CountrymasterComponent } from './secondadmin/master/countrymaster/countrymaster.component';
import { CurrencymasterComponent } from './secondadmin/master/currencymaster/currencymaster.component';
import { IndustrymasterComponent } from './secondadmin/master/industrymaster/industrymaster.component';
import { CompanytypemasterComponent } from './secondadmin/master/companytypemaster/companytypemaster.component';
import { ProfessionmasterComponent } from './secondadmin/master/professionmaster/professionmaster.component';
import { LoyalitytypemasterComponent } from './secondadmin/master/loyalitytypemaster/loyalitytypemaster.component';
import { ProspecttypemasterComponent } from './secondadmin/master/prospecttypemaster/prospecttypemaster.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { NewthemeComponent } from './secondadmin/master/thememaster/newtheme/newtheme.component';
import { AddnewjourneyComponent } from './secondadmin/master/journeymaster/addnewjourney/addnewjourney.component';
import { AddnewmenuComponent } from './secondadmin/master/menumaster/addnewmenu/addnewmenu.component';
import { AddnewcountryComponent } from './secondadmin/master/countrymaster/addnewcountry/addnewcountry.component';
import { AddnewcurrencyComponent } from './secondadmin/master/currencymaster/addnewcurrency/addnewcurrency.component';
import { AddnewprofessionComponent } from './secondadmin/master/professionmaster/addnewprofession/addnewprofession.component';
import { AddnewloyalityComponent } from './secondadmin/master/loyalitytypemaster/addnewloyality/addnewloyality.component';
import { AddnewprospectComponent } from './secondadmin/master/prospecttypemaster/addnewprospect/addnewprospect.component';
import { PiComponent } from './secondadmin/pi/pi.component';
import { AddnewactionComponent } from './secondadmin/master/actionmaster/addnewaction/addnewaction.component';
import { AddnewcompanyComponent } from './secondadmin/master/companytypemaster/addnewcompany/addnewcompany.component';
import { AddnewtrainingtypeComponent } from './secondadmin/master/trainingtypemaster/addnewtrainingtype/addnewtrainingtype.component';
import { AddneweventComponent } from './secondadmin/master/eventtypemaster/addnewevent/addnewevent.component';
import { AddnewindustryComponent } from './secondadmin/master/industrymaster/addnewindustry/addnewindustry.component';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationDialogComponent } from './_utils/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './_utils/confirmation-dialog/confirmation-dialog.service'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PermissionComponent } from './secondadmin/master/permission/permission.component';
import { AddnewpermissionsComponent } from './secondadmin/master/permission/addnewpermissions/addnewpermissions.component';

import { RoleMasterComponent } from './secondadmin/master/role-master/role-master.component';
import { AddnewroleMasterComponent } from './secondadmin/master/role-master/addnewrole-master/addnewrole-master.component';
import { ModuleMasterComponent } from './secondadmin/master/module-master/module-master.component';
import { AddnewmoduleComponent } from './secondadmin/master/module-master/addnewmodule/addnewmodule.component';
import { ModuleitemsComponent } from './secondadmin/master/moduleitems/moduleitems.component';
import { AddnewmoduleitemsComponent } from './secondadmin/master/moduleitems/addnewmoduleitems/addnewmoduleitems.component';
import { SetusernameComponent } from './auth/setusername/setusername.component';
import { ImportFilesDialogComponent } from './_utils/import-files-dialog/import-files-dialog.component';
import { ImportFilesDialogService } from './_utils/import-files-dialog/import-files-dialog.service';
import { TreeModule } from '@circlon/angular-tree-component';
import { ClienteditcontactComponent } from './secondadmin/clients/clienteditcontact/clienteditcontact.component';
// import { NgxDataTableModule } from 'projects/ngx-data-table/src/public_api';
import { ClientHomeComponent } from './secondadmin/clients/client-home/client-home.component';
import { ClientdetailsCardComponent } from './secondadmin/clients/clientdetails-card/clientdetails-card.component';
import { ClientdetailsListComponent } from './secondadmin/clients/clientdetails-list/clientdetails-list.component';
import { ClientdetailsListsearchComponent } from './secondadmin/clients/clientdetails-listsearch/clientdetails-listsearch.component';
import { ClientexpandedComponent } from './secondadmin/clients/clientexpanded/clientexpanded.component';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FacebookComponent } from './secondadmin/facebook/facebook.component';
import { PostmanagementComponent } from './secondadmin/facebook/postmanagement/postmanagement.component';
import { CampaignComponent } from './secondadmin/facebook/campaign/campaign.component';
import { CategoryComponent } from './secondadmin/facebook/category/category.component';
import { AddnewcampaignComponent } from './secondadmin/facebook/campaign/addnewcampaign/addnewcampaign.component';
import { AddnewcategoryComponent } from './secondadmin/facebook/category/addnewcategory/addnewcategory.component';
import { AddnewpostComponent } from './secondadmin/facebook/postmanagement/addnewpost/addnewpost.component';
import { MessageSnippetsComponent } from './secondadmin/facebook/message-snippets/message-snippets.component';
import { AddnewmessageComponent } from './secondadmin/facebook/message-snippets/addnewmessage/addnewmessage.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { EditmyprofileComponent } from './myprofile/editmyprofile/editmyprofile.component';
import { LogsComponent } from './logs/logs.component';
import { ViewLogsDialogComponent } from './_utils/view-logs-dialog/view-logs-dialog.component';
import { ViewLogsDialogService } from './_utils/view-logs-dialog/view-logs-dialog.service';
import { UsertypesComponent } from './secondadmin/usertypes/usertypes.component';
import { DirectsellerComponent } from './secondadmin/usertypes/directseller/directseller.component';
import { AddnewsellerComponent } from './secondadmin/usertypes/directseller/addnewseller/addnewseller.component';
import { MasterthemecategoryComponent } from './secondadmin/master/masterthemecategory/masterthemecategory.component';
import { NewthemecategoryComponent } from './secondadmin/master/masterthemecategory/newthemecategory/newthemecategory.component';
import { JourneytypeComponent } from './secondadmin/master/journeytype/journeytype.component';
import { NewjourneytypeComponent } from './secondadmin/master/journeytype/newjourneytype/newjourneytype.component';
import { ContacttypeComponent } from './secondadmin/master/contacttype/contacttype.component';
import { NewcontacttypeComponent } from './secondadmin/master/contacttype/newcontacttype/newcontacttype.component';
import { AssetComponent } from './secondadmin/master/asset/asset.component';
import { NewassetComponent } from './secondadmin/master/asset/newasset/newasset.component';
import { GenderComponent } from './secondadmin/master/gender/gender.component';
import { NewgenderComponent } from './secondadmin/master/gender/newgender/newgender.component';
import { AgerangeComponent } from './secondadmin/master/agerange/agerange.component';
import { NewagerangeComponent } from './secondadmin/master/agerange/newagerange/newagerange.component';
import { HelpsComponent } from './secondadmin/helps/helps.component';
import { TipsComponent } from './secondadmin/helps/tips/tips.component';
import { SupportComponent } from './secondadmin/helps/support/support.component';
import { ArticleDetailComponent } from './secondadmin/helps/tips/article-detail/article-detail.component';;
import { SafeHTMLtagsPipe } from './shared/pipes/safe-htmltags.pipe';
import { SubjectlibComponent } from './secondadmin/helps/subjectlib/subjectlib.component';
import { NewsubjectlibComponent } from './secondadmin/helps/subjectlib/newsubjectlib/newsubjectlib.component';
import { MessagelibComponent } from './secondadmin/helps/messagelib/messagelib.component';
import { NewmessagelibComponent } from './secondadmin/helps/messagelib/newmessagelib/newmessagelib.component';
import { ClientHeadquaterComponent } from './secondadmin/clients/client-headquater/client-headquater.component';
import { FbreportsComponent } from './secondadmin/facebook/fbreports/fbreports.component';
import { PiiTableComponent } from './secondadmin/pi/pii-table/pii-table.component';
import { PiiFieldComponent } from './secondadmin/pi/pii-field/pii-field.component';
import { PiiDataComponent } from './secondadmin/pi/pii-data/pii-data.component';
import { PiiDsarComponent } from './secondadmin/pi/pii-dsar/pii-dsar.component';
import { PiiReportComponent } from './secondadmin/pi/pii-report/pii-report.component';
import { SubscriptionComponent } from './secondadmin/master/subscription/subscription.component';
import { NewsubscriptionComponent } from './secondadmin/master/subscription/newsubscription/newsubscription.component';
import { ClientheadquaterdetailsListComponent } from './secondadmin/clients/clientheadquaterdetails-list/clientheadquaterdetails-list.component';
import { MasterReportComponent } from './secondadmin/pi/pii-report/master-report/master-report.component'
import { HqReportComponent } from './secondadmin/pi/pii-report/hq-report/hq-report.component';
import { BuReportComponent } from './secondadmin/pi/pii-report/bu-report/bu-report.component';
import { IndivisualDataComponent } from './secondadmin/pi/pii-report/indivisual-data/indivisual-data.component';
import { ThemeassociationComponent } from './secondadmin/master/thememaster/themeassociation/themeassociation.component';
import { PiiDataViewComponent } from './secondadmin/pi/pii-data-view/pii-data-view.component'
import { AppInjector } from './services/app-injector.service';

import { PiiFieldAddComponent } from './secondadmin/pi/pii-field-add/pii-field-add.component';
import { TeamassociationComponent } from './secondadmin/usertypes/teamassociation/teamassociation.component';
;
import { ContentbuilderComponent } from './secondadmin/contentbuilder/contentbuilder.component'
    ;

import { ContentbuildfieldComponent } from './secondadmin/contentbuilder/contentbuildfield/contentbuildfield.component'
    ;
import { ContBuildAssoiciationComponent } from './secondadmin/contentbuilder/cont-build-assoiciation/cont-build-assoiciation.component'
    ;
import { AddassociationComponent } from './secondadmin/usertypes/teamassociation/addassociation/addassociation.component'
    ;
import { CustomerContentbuilderComponent } from './secondadmin/contentbuilder/customer-contentbuilder/customer-contentbuilder.component'
    ;
import { ContbuildCustThemeListComponent } from './secondadmin/contentbuilder/contbuild-cust-theme-list/contbuild-cust-theme-list.component'
    ;
import { DsLogsComponent } from './secondadmin/usertypes/dslogs/dslogs.component';
import { EditContentBuilderDataComponent } from './secondadmin/contentbuilder/edit-content-builder-data/edit-content-builder-data.component'
    ;
import { SupportUserComponent } from './secondadmin/supportuser/supportuser.component';
import { SupportUserCreateRequestComponent } from './secondadmin/supportuser/supportuser-create-request/supportuser-create-request.component';
import { SupportUserListRequestComponent } from './secondadmin/supportuser/supportuser-request-list/supportuser-request-list.component';;
import { CbSectionFieldAssociationComponent } from './secondadmin/contentbuilder/cb-section-field-association/cb-section-field-association.component'
    ;
import { CbFieldAttributeAssociationComponent } from './secondadmin/contentbuilder/cb-field-attribute-association/cb-field-attribute-association.component'
    ;
import { AddCbSectionFieldAssociationComponent } from './secondadmin/contentbuilder/cb-section-field-association/add-cb-section-field-association/add-cb-section-field-association.component';
import { AddCbFieldAttributeAssociationComponent } from './secondadmin/contentbuilder/cb-field-attribute-association/add-cb-field-attribute-association/add-cb-field-attribute-association.component'
    ;
import { CbThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-theme-template-association.component'
    ;
import { CbAddThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-add-theme-template-association/cb-add-theme-template-association.component'
    ;
import { ViewImageComponent } from './_utils/view-image/view-image.component';
import { CbFormBuilderComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder.component'
import { ColorPickerModule } from 'ngx-color-picker';

import { GroupByPipe } from './shared/pipes/group-by.pipe';
import { GeolocationComponent } from './secondadmin/master/geolocation/geolocation.component';;
import { CbFormListComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-list/cb-form-list.component'
    ;
import { ReportDsComponent } from './secondadmin/reports/report-ds/report-ds.component'
    ;
import { ReportCustomerSubscriptionComponent } from './secondadmin/reports/report-customer-subscription/report-customer-subscription.component'
import { ReportMessageUtilizationComponent } from './secondadmin/reports/report-message-utilization/report-message-utilization.component';

;
import { ContactUsageComponent } from './secondadmin/reports/contact-usage/contact-usage.component'
    ;
import { ContactMarketActivityComponent } from './secondadmin/reports/contact-market-activity/contact-market-activity.component'
//import { DsLogsComponent } from './secondadmin/usertypes/dslogs/dslogs.component';;
import { BasicServicesSummaryComponent } from './secondadmin/reports/basic-services-summary/basic-services-summary.component'
    ;
import { PremiumServicesSummaryComponent } from './secondadmin/reports/premium-services-summary/premium-services-summary.component'
    ;
import { CustomerInvoiceComponent } from './secondadmin/reports/customer-invoice/customer-invoice.component'
    ;
import { OptInOptOutComponent } from './secondadmin/reports/opt-in-opt-out/opt-in-opt-out.component'
import { ReportEcomDetailComponent } from './secondadmin/reports/report-ecom-detail/report-ecom-detail.component';
import { ReportEcomSummaryComponent } from './secondadmin/reports/report-ecom-summary/report-ecom-summary.component';;
import { CsvDownloadComponent } from "./secondadmin/reports/csv-download/csv-download.component";

import { EinsteinDashboardComponent } from './components/einstein-dashboard/einstein-dashboard.component'
import { EinsteinDashboardReportsComponent } from './secondadmin/einstein-dashboard-reports/einstein-dashboard-reports';
import { RoleresourcesComponent } from './secondadmin/master/roleresources/roleresources.component'
import { AttributeComponent } from './secondadmin/master/attributemaster/attribute.component';
import { AddattributeComponent } from './secondadmin/master/attributemaster/addattribute/addattribute.component';


import { SpaceModule } from 'src/app/_directives/space.module';
import { NotesComponent } from './secondadmin/master/notesmaster/notes.component';
import { NoteslistComponent } from './secondadmin/master/notesmaster/noteslist/noteslist.component';
import { MySearchPipe } from './_pipes/mysearch.pipe';
import { ThrottleClickDirective } from './_directives/throttle-click.directive';
import { SegmentationHomeComponent } from './secondadmin/segmentation/segmentation-home.component';
import { ReplenishmentCrosssellComponent } from './secondadmin/replenishment-crosssell/replenishment-crosssell.component';
import { BuAudienceComponent } from './secondadmin/replenishment-crosssell/bu-audience/bu-audience.component';

import { MasterSegmentationComponent } from './secondadmin/segmentation/master-segmentation/master-segmentation.component';
import { AttributeGroupComponent } from './secondadmin/segmentation/master-segmentation/attribute-group/attribute-group.component';
import { NewAttributeGroupComponent } from './secondadmin/segmentation/master-segmentation/attribute-group/new-attribute-group/new-attribute-group.component';
import { AttributeTypeComponent } from './secondadmin/segmentation/master-segmentation/attribute-type/attribute-type.component';
import { NewAttributeTypeComponent } from './secondadmin/segmentation/master-segmentation/attribute-type/new-attribute-type/new-attribute-type.component';
import { SegmentationAttributeComponent } from './secondadmin/segmentation/master-segmentation/attribute-segmentation/segmentation-attribute.component';
import { NewSegmentationAttributeComponent } from './secondadmin/segmentation/master-segmentation/attribute-segmentation/new-attribute/new-segmentation-attribute.component';
import { EntitiesAttributeAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-attribute/entities-attribute-association.component';
import { AddEntitiesAttributeAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-attribute/add-entities-attribute-association/add-entities-attribute-association.component';
import { EntitiesAudienceAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-audience/entities-audience-association.component';
import { AddEntitiesAudienceAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-audience/add-entities-audience-association/add-entities-audience-association.component';
import { AttributeOperatorAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/attribute-operator/attribute-operator-association.component';
import { AddAttributeOperatorAssociationComponent } from './secondadmin/segmentation/master-segmentation/association-segmentation/attribute-operator/add-attributes-operator-association/add-attribute-operator-association.component';
import { BuSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-segmentation.component';
import { SelectbuSegmentationComponent } from './secondadmin/segmentation/selectbu-segmentation/selectbu-segmentation.component';
import { AddBuAudienceSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/add-bu-audience-segmentation.component';
import { BuAudienceSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/bu-audience-segmentation/bu-audience-segmentation.component';
import { NewBuAudienceSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/bu-audience-segmentation/new-bu-audience-segmentation/new-bu-audience-segmentation.component';
import { BuEntitiesSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-entities-segmentation/bu-entities-segmentation.component';
import { AddBuEntitiesSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-entities-segmentation/add-bu-entities-segmentation/add-bu-entities-segmentation.component';
import { BuAttributesSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-attributes-segmentation/bu-attributes-segmentation.component';
import { AddBuAttributesSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-attributes-segmentation/add-bu-attributes-segmentation/add-bu-attributes-segmentation.component';
import { UpdateAudienceBuSegmentation } from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-audience-update/update-audience-bu-segmentation';
import { UpdateEntitiesBuSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-entities-update/update-entities-bu-segmentation.component';
import { UpdateAttributeBuSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-attribute-update/update-attribute-bu-segmentation.component';
import { UpdateOperatorsBuSegmentationComponent } from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-operator-update/update-operators-bu-segmentation.component';;
import { CampaignBuilderComponent } from './secondadmin/campaign-builder/campaign-builder.component';
import { CreateCampaignBuilderComponent } from './secondadmin/campaign-builder/create-campaign-builder/create-campaign-builder.component';
import { AudienceCampaignBuilderComponent } from './secondadmin/campaign-builder/audience-campaign-builder/audience-campaign-builder.component';
import { ProductCampaignBuilderComponent } from './secondadmin/campaign-builder/product-campaign-builder/product-campaign-builder.component';
import { ThemeCampaignBuilderComponent } from './secondadmin/campaign-builder/theme-campaign-builder/theme-campaign-builder.component';
import { MessageCampaignBuilderComponent } from './secondadmin/campaign-builder/message-campaign-builder/message-campaign-builder.component';
import { ScheduleCampaignBuilderComponent } from './secondadmin/campaign-builder/schedule-campaign-builder/schedule-campaign-builder.component'
import { ViewCampaignBuilderComponent } from './secondadmin/campaign-builder/view-campaign-builder/view-campaign-builder.component'
import { ViewBuAudienceComponent } from './secondadmin/audience-builder/view-bu-audience/view-bu-audience.component';
import { TestBuAudienceComponent } from './secondadmin/audience-builder/test-bu-audience/test-bu-audience.component';
import { ScheduleBuAudienceComponent } from './secondadmin/audience-builder/schedule-bu-audience/schedule-bu-audience.component';
import { McInformationComponent } from './secondadmin/mc-connector/mc-information/mc-information.component';
import { McDataExtensionMapping } from './secondadmin/mc-connector/mc-data-extension-mapping/mc-data-extension-mapping';
import { McDataExtensionFieldsComponent } from './secondadmin/mc-connector/mc-data-extension-fields/mc-data-extension-fields.component';
import { McSchedulerComponent } from './secondadmin/mc-connector/mc-scheduler/mc-scheduler.component';
import { McSchedulerStatsComponent } from './secondadmin/mc-connector/mc-scheduler-stats/mc-scheduler-stats.component';;
import { EWalletComponent } from './secondadmin/usertypes/e-wallet/e-wallet.component';
import { SpecificEwalletTransferComponent } from './secondadmin/usertypes/e-wallet/specific-ewallet-transfer/specific-ewallet-transfer.component'
    ;
import { ShopLiveComponent } from './secondadmin/shop-live/shop-live.component';
import { NewCategoryComponent } from './secondadmin/shop-live/shoplive-category/new-category/new-category.component';;
import { UpdateCategoryComponent } from './secondadmin/shop-live/shoplive-category/update-category/update-category.component'
    ;
import { ShoplivePostmanagementComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-postmanagement.component';
import { ShopliveNewpostComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-newpost/shoplive-newpost.component';
import { ShopliveEditpostComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-editpost/shoplive-editpost.component';;
import { ShopliveCategoryComponent } from './secondadmin/shop-live/shoplive-category/shoplive-category.component'
import { MetaDataListComponent } from './secondadmin/metadata/metadatalist/metaDataList.component';
import { AddMetaDataComponent } from './secondadmin/metadata/addmetadata/addMetaData.component';

import { ReskiningmasterComponent } from './secondadmin/master/reskiningmaster/reskiningmaster.component';
import { NewreskinningComponent } from './secondadmin/master/reskiningmaster/newreskinning/newreskinning.component';
import { MetaDBConnectionComponent } from './secondadmin/metadata/metadbconnection/metadbconnection.component';;
import { ExtCompanySellerBuComponent } from './secondadmin/ext-company-seller-bu/ext-company-seller-bu.component'
import { MetaClientTableRelationComponent } from './secondadmin/metadata/metaclienttablerelation/metaclienttablerelation.component';
import { ProductcatalogmasterComponent } from './secondadmin/master/productcatalogmaster/productcatalogmaster.component';
import { NewproductcatalogComponent } from './secondadmin/master/productcatalogmaster/newproductcatalog/newproductcatalog.component';
import { CrosssellproductcatalogComponent } from './secondadmin/master/productcatalogmaster/crosssellproductcatalog/crosssellproductcatalog.component';

import { CbCloneThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-clone-theme-template-association/cb-clone-theme-template-association.component'
    ;

import { CbFormBuilderCloneComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder-clone/cb-form-builder-clone.component';;
import { DsApprovalListComponent } from './secondadmin/supportuser/ds-approval-list/ds-approval-list.component'
    ;
import { DsreportComponent } from './secondadmin/usertypes/dsreport/dsreport.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CbFormBuilderProductCloneComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder-product-clone/cb-form-builder-product-clone.component'


import { BlockIpHostComponent } from './secondadmin/master/block-ip-host/block-ip-host.component';
import { NewblockiphostComponent } from './secondadmin/master/block-ip-host/newblockiphost/newblockiphost.component';
import { CrosssellListComponent } from './secondadmin/replenishment-crosssell/cross-sell/cross-sell.component';
import { ReplenishmentListComponent } from './secondadmin/replenishment-crosssell/replenishment/replenishment.component';
import { CustomerCountListComponent } from './secondadmin/replenishment-crosssell/customer-count-listing/customer-countlist.component';
import { SettingComponent } from './secondadmin/replenishment-crosssell/setting/setting.component';
import { NewSettingComponent } from './secondadmin/replenishment-crosssell/setting/newsetting/newsetting.component';
import { HostDsComponent } from './secondadmin/usertypes/host-ds/host-ds.component';
import { NgSelectModule } from '@ng-select/ng-select';;
import { AgreementComponent } from './secondadmin/master/agreement/agreement.component'

import { AngularEditorModule } from '@kolkov/angular-editor';
import { NewThemeassociationComponent } from './secondadmin/master/thememaster/themeassociation/new-themeassociation/new-themeassociation.component';
import { NewBusinessDomainComponent } from './secondadmin/master/business-domain/new-business-domain/new-business-domain.component';
import { BusinessDomainComponent } from './secondadmin/master/business-domain/business-domain.component';

import { AddnewtemplateComponent } from './secondadmin/sms/templatemanagement/addnewtemplate/addnewtemplate.component';
import { TemplatemanagementComponent } from './secondadmin/sms/templatemanagement/templatemanagement.component';
import { SmsComponent } from './secondadmin/sms/sms.component';
import { SmscategoryComponent } from './secondadmin/sms/category/smscategory.component';
import { AddnewsmscategoryComponent } from './secondadmin/sms/category/addnewsmscategory/addnewsmscategory.component';
import { SnippetsComponent } from './secondadmin/sms/message-snippets/snippets.component';
import { AddnewsnippetsComponent } from './secondadmin/sms/message-snippets/addnewmessage/addnewmessage.component';;
import { ResourceCategoryComponent } from './secondadmin/master/resource-category/resource-category.component'
    ;
import { AddRescategoryComponent } from './secondadmin/master/resource-category/add-rescategory/add-rescategory.component'
    ;
import { UpdateRescategoryComponent } from './secondadmin/master/resource-category/update-rescategory/update-rescategory.component'
    ;
import { ResourceImageComponent } from './secondadmin/master/resource-image/resource-image.component'
    ;
import { AddResourceImageComponent } from './secondadmin/master/resource-image/add-resource-image/add-resource-image.component'
import { ImageCropperModule } from 'ngx-image-cropper';;
import { UpdateResourceImageComponent } from './secondadmin/master/resource-image/update-resource-image/update-resource-image.component'
    ;
import { FaqCategoryComponent } from './secondadmin/webinar/faq-category/faq-category.component'
    ;
import { AddFaqCategoryComponent } from './secondadmin/webinar/faq-category/add-faq-category/add-faq-category.component';
import { UpdateFaqCategoryComponent } from './secondadmin/webinar/faq-category/update-faq-category/update-faq-category.component'
    ;
import { WebinarModuleComponent } from './secondadmin/webinar/webinar-module/webinar-module.component'
    ;
import { FaqLibraryComponent } from './secondadmin/webinar/faq-library/faq-library.component'
    ;
import { AddFaqLibraryComponent } from './secondadmin/webinar/faq-library/add-faq-library/add-faq-library.component'
    ;
import { UpdateFaqLibraryComponent } from './secondadmin/webinar/faq-library/update-faq-library/update-faq-library.component'
import { WebCatListComponent } from './secondadmin/webinar/webinar-category/web-cat-list/web-cat-list.component';
import { WebCatEditComponent } from './secondadmin/webinar/webinar-category/web-cat-edit/web-cat-edit.component';
import { WebCatAddComponent } from './secondadmin/webinar/webinar-category/web-cat-add/web-cat-add.component';;
import { WebTagListComponent } from './secondadmin/webinar/webinar-tag/web-tag-list/web-tag-list.component';
import { WebTagAddComponent } from './secondadmin/webinar/webinar-tag/web-tag-add/web-tag-add.component';
import { WebTagEditComponent } from './secondadmin/webinar/webinar-tag/web-tag-edit/web-tag-edit.component';;
import { PollComponent } from './secondadmin/webinar/poll/poll.component'
    ;
import { AddPollComponent } from './secondadmin/webinar/poll/add-poll/add-poll.component'
    ;
import { PollOptionsComponent } from './secondadmin/webinar/poll-options/poll-options.component'
    ;
import { AddPollOptionComponent } from './secondadmin/webinar/poll-options/add-poll-option/add-poll-option.component';;
import { UpdatePollComponent } from './secondadmin/webinar/poll/update-poll/update-poll.component'
;
import { UpdatePollOptionComponent } from './secondadmin/webinar/poll-options/update-poll-option/update-poll-option.component'
;
import { TemplatesComponent } from './secondadmin/webinar/templates/templates.component'
;
import { AddTemplatesComponent } from './secondadmin/webinar/templates/add-templates/add-templates.component'
;
import { SubscriptionModuleComponent } from './secondadmin/subscriptionmodule/subscription-module/subscription-module.component'
;
import { AddSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/add-subscription/add-subscription.component'
;
import { ListSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/list-subscription/list-subscription.component';
import { EditSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/edit-subscription/edit-subscription.component';
import { SubscriptionLimitComponent } from './secondadmin/subscriptionmodule/subscription-limit/subscription-limit.component'
;
import { AddSubsLimitComponent } from './secondadmin/subscriptionmodule/add-subs-limit/add-subs-limit.component'
;
import { EditSubsLimitComponent } from './secondadmin/subscriptionmodule/edit-subs-limit/edit-subs-limit.component'
import { CreateMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/create-media-sub/create-media-sub.component';;
import { ListMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/list-media-sub/list-media-sub.component'
;
import { EditMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/edit-media-sub/edit-media-sub.component'
;
import { SenderProfileModuleComponent } from './secondadmin/sender-profile/sender-profile-module/sender-profile-module.component'
;
import { SenderProfileListComponent } from './secondadmin/sender-profile/sender-profile-list/sender-profile-list.component'
;
import { EditSenderProfileComponent } from './secondadmin/sender-profile/edit-sender-profile/edit-sender-profile.component'
;
import { ViewSenderProfileComponent } from './secondadmin/sender-profile/view-sender-profile/view-sender-profile.component';;
import { EventListComponent } from './secondadmin/events/event-list/event-list.component'
;
import { EventAddComponent } from './secondadmin/events/event-add/event-add.component'
;
import { ThemeListComponent } from './secondadmin/events/theme-list/theme-list.component'
;
import { EventPreviewComponent } from './secondadmin/events/event-preview/event-preview.component'
;
import { EventViewComponent } from './secondadmin/events/event-view/event-view.component'
import { WooCommerceListComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woo-commerce-list/woo-commerce-list.component';
import { WooCommerceUpdateComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woo-commerce-update/woo-commerce-update.component';;
import { WoocommerceCronUpdateComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woocommerce-cron-update/woocommerce-cron-update.component'
;
import { EventInviteeComponent } from './secondadmin/events/event-invitee/event-invitee.component'
;
import { ContactListComponent } from './secondadmin/master/contact-list/contact-list.component';
import { ContactViewComponent } from './secondadmin/master/contact-list/contact-view/contact-view.component'
;
import { ListReskinningComponent } from './secondadmin/master/reskiningmaster/list-reskinning/list-reskinning.component';
import { SupportuserLogsComponent } from './secondadmin/supportuser/supportuser-logs/supportuser-logs.component'
;
import { JourneySpanComponent } from './secondadmin/master/journey-span/journey-span.component'
;
import { AddJourneySpanComponent } from './secondadmin/master/journey-span/add-journey-span/add-journey-span.component'
;
import { ReadinessComponent } from './secondadmin/readiness/readiness/readiness.component'
;
import { ActivityListComponent } from './secondadmin/readiness/readiness-activity/activity-list/activity-list.component'
;
import { ActivityAddComponent } from './secondadmin/readiness/readiness-activity/activity-add/activity-add.component'
;
import { ActivityUpdateComponent } from './secondadmin/readiness/readiness-activity/activity-update/activity-update.component'
;
import { ResourceListComponent } from './secondadmin/readiness/readiness-reasource/resource-list/resource-list.component'
;
import { ResourceAddComponent } from './secondadmin/readiness/readiness-reasource/resource-add/resource-add.component';;
import { ReadinessEditComponent } from './secondadmin/readiness/readiness-reasource/readiness-edit/readiness-edit.component'
;
import { SupportTicketComponent } from './secondadmin/master/support-ticket/support-ticket.component';

import { SupportTicketViewComponent } from './secondadmin/master/support-ticket/support-ticket-view/support-ticket-view.component'
;
import { CreateJourneyFlowComponent } from './secondadmin/schedule-journey/journey-flow/create-journey-flow/create-journey-flow.component'
;
import { CustomStepComponent } from './secondadmin/schedule-journey/journey-flow/custom-step/custom-step.component'
;
import { RouteStepComponent } from './secondadmin/schedule-journey/journey-flow/custom-step/route-step/route-step.component'
;
import { NestedFlowComponent } from './secondadmin/schedule-journey/journey-flow/nested-flow/nested-flow.component'





const config = new AuthServiceConfig([

    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('246222385503381')
    }
]);
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
export function provideConfig() {
    return config;
}



@NgModule({
    imports: [
        NgSelectModule,
        SpaceModule,
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        ColorPickerModule,
        AngularEditorModule,

        NgxDataTableModule,
        AngularEditorModule,
        routing,

        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
        NgxPaginationModule,
        OrderModule,
        DeviceDetectorModule.forRoot(),
        ToastrModule.forRoot(),
        LoadingBarRouterModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        Ng2SearchPipeModule,
        MatSlideToggleModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        GoogleChartsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        TreeModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        }),
        NgxDataTableModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        ImageCropperModule,
        NgFlowchartModule
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        routingComponents,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent,
        HorizontalnavComponent,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        ForgotUserComponent,
        ForgotPasswrodComponent,
        CommonComponent,
        ClientsComponent,
        SecondadminComponent,
        HomeComponent,
        UsersComponent,
        RolesComponent,
        MargoproductsComponent,
        ReportsComponent,
        NewusercontactComponent,
        RolesnewuserComponent,
        MargonewcontactComponent,
        ReportsnewcntactComponent,
        ClientnewcontactComponent,
        MasterComponent,
        ThememasterComponent,
        JourneymasterComponent,
        TrainingtypemasterComponent,
        EventtypemasterComponent,
        ActionmasterComponent,
        MenumasterComponent,
        CountrymasterComponent,
        CurrencymasterComponent,
        IndustrymasterComponent,
        CompanytypemasterComponent,
        ProfessionmasterComponent,
        LoyalitytypemasterComponent,
        ProspecttypemasterComponent,
        ResetpasswordComponent,
        NewthemeComponent,
        AddnewjourneyComponent,
        AddnewmenuComponent,
        AddnewcountryComponent,
        AddnewcurrencyComponent,
        AddnewprofessionComponent,
        AddnewloyalityComponent,
        AddnewprospectComponent,
        PiComponent,
        AddnewcompanyComponent,
        AddnewactionComponent,
        AddnewtrainingtypeComponent,
        AddneweventComponent,
        AddnewindustryComponent,
        ConfirmationDialogComponent,
        SetusernameComponent,
        PermissionComponent,
        AddnewpermissionsComponent,
        RoleMasterComponent,
        AddnewroleMasterComponent,
        ModuleMasterComponent,
        AddnewmoduleComponent,
        ModuleitemsComponent,
        AddnewmoduleitemsComponent,
        ImportFilesDialogComponent,
        ClienteditcontactComponent,
        ClientHomeComponent,
        ClientdetailsCardComponent,
        ClientdetailsListComponent,
        ClientdetailsListsearchComponent,
        ClientexpandedComponent,
        FacebookComponent,
        CategoryComponent,
        CampaignComponent,
        PostmanagementComponent,
        AddnewcampaignComponent,
        AddnewcategoryComponent,
        AddnewpostComponent,
        MessageSnippetsComponent,
        AddnewmessageComponent,
        MyprofileComponent,
        EditmyprofileComponent,
        LogsComponent,
        ViewLogsDialogComponent,
        UsertypesComponent,
        DirectsellerComponent,
        AddnewsellerComponent,
        MasterthemecategoryComponent,
        NewthemecategoryComponent,
        JourneytypeComponent,
        NewjourneytypeComponent,
        ContacttypeComponent,
        NewcontacttypeComponent,
        AssetComponent,
        NewassetComponent,
        GenderComponent,
        NewgenderComponent,
        AgerangeComponent,
        NewagerangeComponent,
        HelpsComponent,
        SupportComponent,
        TipsComponent,
        ArticleDetailComponent,
        SafeHTMLtagsPipe,
        GroupByPipe,
        SubjectlibComponent,
        NewsubjectlibComponent,
        MessagelibComponent,
        NewmessagelibComponent,
        ClientHeadquaterComponent,
        FbreportsComponent,
        PiiTableComponent,
        PiiFieldComponent,
        PiiDataComponent,
        PiiDsarComponent,
        PiiReportComponent,
        PiiDataViewComponent,
        SubscriptionComponent,
        NewsubscriptionComponent,
        ClientheadquaterdetailsListComponent,
        MasterReportComponent,
        HqReportComponent,
        BuReportComponent,
        IndivisualDataComponent,
        ThemeassociationComponent,
        PiiDataViewComponent,
        PiiFieldAddComponent,
        TeamassociationComponent,
        ContentbuilderComponent,
        ContentbuildfieldComponent,
        ContBuildAssoiciationComponent,
        AddassociationComponent,
        CustomerContentbuilderComponent,
        ContbuildCustThemeListComponent,
        EditContentBuilderDataComponent,
        SupportUserComponent,
        SupportUserCreateRequestComponent,
        SupportUserListRequestComponent,
        CbSectionFieldAssociationComponent,
        CbFieldAttributeAssociationComponent,
        AddCbSectionFieldAssociationComponent,
        AddCbFieldAttributeAssociationComponent,
        CbThemeTemplateAssociationComponent,
        CbAddThemeTemplateAssociationComponent,
        CbCloneThemeTemplateAssociationComponent,
        CbFormBuilderCloneComponent,
        ViewImageComponent,
        GeolocationComponent,
        CbFormBuilderComponent,
        CbFormListComponent,
        ReportDsComponent,
        ReportCustomerSubscriptionComponent,
        ReportMessageUtilizationComponent,
        ContactMarketActivityComponent,
        ContactUsageComponent,
        DsLogsComponent,
        BasicServicesSummaryComponent,
        PremiumServicesSummaryComponent,
        CustomerInvoiceComponent,
        OptInOptOutComponent,
        ReportEcomDetailComponent,
        ReportEcomSummaryComponent,
        RoleresourcesComponent,
        EinsteinDashboardComponent,
        EinsteinDashboardReportsComponent,
        RoleresourcesComponent,
        AttributeComponent,
        AddattributeComponent,
        NotesComponent,
        NoteslistComponent,
        MySearchPipe,
        ThrottleClickDirective,
        SegmentationHomeComponent,
        ReplenishmentCrosssellComponent,
        BuAudienceComponent,
        MasterSegmentationComponent,
        AttributeGroupComponent,
        NewAttributeGroupComponent,
        AttributeTypeComponent,
        NewAttributeTypeComponent,
        SegmentationAttributeComponent,
        NewSegmentationAttributeComponent,
        EntitiesAttributeAssociationComponent,
        AddEntitiesAttributeAssociationComponent,
        EntitiesAudienceAssociationComponent,
        AddEntitiesAudienceAssociationComponent,
        AttributeOperatorAssociationComponent,
        AddAttributeOperatorAssociationComponent,
        BuSegmentationComponent,
        SelectbuSegmentationComponent,
        AddBuAudienceSegmentationComponent,
        BuAudienceSegmentationComponent,
        NewBuAudienceSegmentationComponent,
        BuEntitiesSegmentationComponent,
        AddBuEntitiesSegmentationComponent,
        BuAttributesSegmentationComponent,
        AddBuAttributesSegmentationComponent,
        UpdateAudienceBuSegmentation,
        UpdateEntitiesBuSegmentationComponent,
        UpdateAttributeBuSegmentationComponent,
        UpdateOperatorsBuSegmentationComponent,
        CampaignBuilderComponent,
        CreateCampaignBuilderComponent,
        AudienceCampaignBuilderComponent,
        ProductCampaignBuilderComponent,
        ThemeCampaignBuilderComponent,
        MessageCampaignBuilderComponent,
        ScheduleCampaignBuilderComponent,
        ViewCampaignBuilderComponent,
        ViewBuAudienceComponent,
        TestBuAudienceComponent,
        ScheduleBuAudienceComponent,
        McInformationComponent,
        McDataExtensionMapping,
        McDataExtensionFieldsComponent,
        McSchedulerComponent,
        McSchedulerStatsComponent,
        EWalletComponent,
        SpecificEwalletTransferComponent,
        ShopLiveComponent, NewCategoryComponent,
        UpdateCategoryComponent,
        ShoplivePostmanagementComponent,
        ShopliveNewpostComponent,
        ShopliveEditpostComponent,
        ShopliveCategoryComponent,
        MetaDataListComponent,
        AddMetaDataComponent,
        ReskiningmasterComponent,
        NewreskinningComponent,
        ExtCompanySellerBuComponent,
        MetaClientTableRelationComponent,
        MetaDBConnectionComponent,
        ProductcatalogmasterComponent,
        NewproductcatalogComponent,

        CrosssellproductcatalogComponent,
        DsApprovalListComponent,
        CbFormBuilderProductCloneComponent,
        DsreportComponent,
        BlockIpHostComponent,
        NewblockiphostComponent,

        CsvDownloadComponent,
        CrosssellListComponent,
        ReplenishmentListComponent,
        CustomerCountListComponent,
        SettingComponent,
        NewSettingComponent,
        HostDsComponent,
        AgreementComponent,
        NewThemeassociationComponent,
        BusinessDomainComponent,
        NewBusinessDomainComponent,
        AddnewtemplateComponent,
        TemplatemanagementComponent,
        SmsComponent,
        SmscategoryComponent,
        AddnewsmscategoryComponent,
        SnippetsComponent,
        AddnewsnippetsComponent
        ,
        ResourceCategoryComponent
        ,
        AddRescategoryComponent
        ,
        UpdateRescategoryComponent
        ,
        ResourceImageComponent,
        AddResourceImageComponent,
        UpdateResourceImageComponent,
        FaqCategoryComponent,
        AddFaqCategoryComponent,
        UpdateFaqCategoryComponent,
        WebinarModuleComponent,
        FaqLibraryComponent,
        AddFaqLibraryComponent,
        UpdateFaqLibraryComponent,
        WebCatListComponent,
        WebCatEditComponent,
        WebCatAddComponent,
        WebTagListComponent,
        WebTagAddComponent,
        WebTagEditComponent,
        PollComponent ,
        AddPollComponent,
        PollOptionsComponent,
        AddPollOptionComponent
,
        UpdatePollComponent
,
        UpdatePollOptionComponent ,
        TemplatesComponent ,
        AddTemplatesComponent ,
        SubscriptionModuleComponent ,
        AddSubscriptionComponent,
        ListSubscriptionComponent,
        EditSubscriptionComponent,
        SubscriptionLimitComponent,
        AddSubsLimitComponent,
        EditSubsLimitComponent,
        CreateMediaSubComponent
,
        ListMediaSubComponent
,
        EditMediaSubComponent ,
        SenderProfileModuleComponent ,
        SenderProfileListComponent ,
        EditSenderProfileComponent ,
        ViewSenderProfileComponent ,
        EventListComponent ,
        EventAddComponent,
        ThemeListComponent,
        EventPreviewComponent
,
        EventViewComponent,
        WooCommerceListComponent ,
        WooCommerceUpdateComponent,
        WoocommerceCronUpdateComponent
,
        EventInviteeComponent ,
        ContactListComponent ,
        ContactViewComponent ,
        ListReskinningComponent ,
        SupportuserLogsComponent,
        JourneySpanComponent,
        AddJourneySpanComponent
,
        ReadinessComponent
,
        ActivityListComponent ,
        ActivityAddComponent ,
        ActivityUpdateComponent ,
        ResourceListComponent ,
        ResourceAddComponent
,
        ReadinessEditComponent
,
        SupportTicketComponent
,
        SupportTicketViewComponent
,
        CreateJourneyFlowComponent
,
        CustomStepComponent
,
        RouteStepComponent
,
        NestedFlowComponent        
    ],

    providers: [
        DatePipe,
        AuthGuard,
        RoleGuard,
        AlertService,
        NavbarService,
        DataApiService,

        AuthService,
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        NgbCarouselConfig,
        NgbModalConfig,
        ConfirmationDialogService,
        ImportFilesDialogService,
        ViewLogsDialogService
    ],
    entryComponents: [
        BlockTemplateComponent,
        ConfirmationDialogComponent,
        ImportFilesDialogComponent,
        ViewLogsDialogComponent,
        ViewImageComponent
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})

export class AppModule {
    constructor(private injector: Injector) {
        AppInjector.setInjector(this.injector);
    }
}
