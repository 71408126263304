import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "src/app/services/clients/client.service";
import { CbService } from "src/app/services/contentbuilder/contentbuilder.service";
import { BaseComponent } from "../../base.component";
import { ScheduleJourneyService } from "src/app/services/schedulejourney/schedule-journey.service";





@Component({
    selector: 'add-schedule-journey',
    templateUrl: './add-schedule-journey.component.html',
    styleUrls: ['./add-schedule-journey.component.css']
})
export class AddScheduleJourneyComponent extends BaseComponent implements OnInit {

    companyID = '';
    addJourneyForm: FormGroup;
    submitted = false;
    themeObj:any;
    getCompanyobj: any;
    journeyTypeObj: any;
    limit = 99999;
    offset = 0;
    journeyList = [];
    minDate = new Date();
    stTime = { hour: 0, minute: 0 };
    constructor(private clientService: ClientService,
                private formBuilder: FormBuilder,
                private cb: CbService, private scheduleJourneyService: ScheduleJourneyService) {
                    super();
    }

    ngOnInit(): void {
        this.addJourneyForm = this.formBuilder.group({
            company: [this.companyID, Validators.required],
            journey_type_id: ['', Validators.required],
            journey_name: ['', Validators.required],
            scheduleType: ['true', Validators.required],
            interval: [5, [Validators.required, Validators.min(1)]],
            interval_type: ['', Validators.required],
            theme_id:['',Validators.required]
          });
        this.getCompany();
    }

    get f() { return this.addJourneyForm.controls; }

    addJourney() {
      this.submitted = true;
      if (this.addJourneyForm.invalid) {
        return;
      }
      
      this.ngxSpinnerService.show();

      let obj = {
        "master_journey_id": parseInt(this.f.journey_type_id.value, 10),
        "cb_journey_id": parseInt(this.f.journey_name.value, 10),
        "schedule_type": this.f.scheduleType.value === 'true' ? true : false,
        "schedule_interval_type": this.f.interval_type.value,
        "schedule_interval": this.f.interval.value,
        "theme_id":parseInt(this.f.theme_id.value,10)
      }
      
      this.scheduleJourneyService.addJourneySchedule(obj, this.companyID).subscribe(data => {
        if (data.status) {
          this.toastrService.success('Schedule Added Succesfully');
          this.ngxSpinnerService.hide();
          this.router.navigateByUrl('/corporate/schedule-journey-list');
        }
        else{
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.log(error);
        this.toastrService.error('Something went wrong !!');
          this.ngxSpinnerService.hide();
      });
    }

    getCompany() {
        let self = this;
        this.clientService.getBUCompanies().subscribe(data => {
          self.getCompanyobj = data.data;
          console.log(self.getCompanyobj);
        }, error => {
          console.log(error);
        });
      }

      getCompanyJourney(id) {
        this.journeyList = [];
        this.journeyTypeObj = [];
        if(id) {
  
            let self = this;
            this.ngxSpinnerService.show();  
            this.f.journey_type_id.setValue("");
            this.f.journey_name.setValue("");
            this.cb.getCBJourneyType(id).subscribe(data => {
              if( data.data) {
              self.journeyTypeObj = data.data;
              this.ngxSpinnerService.hide();
              }
              else {
                this.f.journey_type_id.setValue("");
                this.f.journey_name.setValue("");
                 self.journeyTypeObj = []; 
                 this.ngxSpinnerService.hide(); }
            }, error => {
              console.log(error);
              this.ngxSpinnerService.hide();
            });
          }
          else {
            this.f.journey_type_id.setValue("");
            this.f.journey_name.setValue("");
            this.journeyTypeObj=[];
            this.ngxSpinnerService.hide();
          }
      }
      
      getCompanyJourneyList() {
        this.f.journey_name.setValue("");
        this.journeyList = [];
        if (!this.f.journey_type_id.value) return;
        this.ngxSpinnerService.show();
        this.cb.getCompanyJourney(this.companyID, this.limit, this.offset, 'journey_name', 'asc', '', this.f.journey_type_id.value).subscribe(data => {
          console.log('J DATA', data);
          if (data.status && data.data.rows) {
            this.journeyList = data.data.rows;
            this.ngxSpinnerService.hide();
          } else {
            this.journeyList = [];
            this.ngxSpinnerService.hide();
          }
        }, error => {
          this.journeyList = [];
            console.log(error);
          this.ngxSpinnerService.hide();
        });
      }
      getCompanyJourneyThemeList(){
        this.themeObj=[];
        if (!this.f.journey_type_id.value) return;
        this.ngxSpinnerService.show();
        this.clientService.getThemeByCompanyJourney(this.companyID,this.f.journey_type_id.value).subscribe(data => {
          if (data.status && data.data.rows) {
            this.themeObj = data.data.rows;
            this.ngxSpinnerService.hide();
          } else {
            this.themeObj = [];
            this.ngxSpinnerService.hide();
          }
        }, error => {
          this.themeObj = [];
            console.log(error);
          this.ngxSpinnerService.hide();
        });
      }
}