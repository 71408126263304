import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseserviceService {
  public baseUrl1;
  public segBaseUrl;
  public sfDomainURL;
  constructor() {

   this.baseUrl1 = environment.apiUrl;
   this.segBaseUrl = environment.segmentationApiUrl;
   this.sfDomainURL = environment.SFDomainURL;
  }
  public httpHeadersOptions() {
    const token = localStorage.getItem('accesToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return httpOptions;
  }

}
