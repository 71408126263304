<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/agerange">Age Range</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Age Range</li>
        </ol>
    </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1 mt-md-2">
    <form class="form-horizontal mt-1" [formGroup]="AddRange">
    <div class="rowTheme">
        <div class="themContain">

            <div>
                <label for="exampleForm2">Age Range <span class="required">*</span> </label>
                <input type="text" appSpace formControlName='agerange' class="form-control form-control-lg"
                [ngClass]="{ 'is-invalid': submitted && f.agerange.errors }" required>
                <div *ngIf="submitted && f.agerange.errors" class="invalid-feedback">
                    <div *ngIf="f.agerange.errors.required">Age range is required</div>
                  <div *ngIf="f.agerange.errors.pattern"> Provide valid Age Range  (e.g. 11-99)</div>
                </div>
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Age Range Description</label>
                <input type="text" class="form-control" formControlName='agerange_desc' class="form-control form-control-lg"
                    >
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Age Range Icon</label>
                <input type="text" class="form-control" formControlName='agerange_icon' class="form-control form-control-lg"
                    >
            </div>

            <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'" >
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewAgerange()">Submit</button>
            </div>
            <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
                <button type="button" class="custbtn mt-2"  *ngIf="isEdit" (click)="updateAgerange()">Update</button>
            </div>
        </div>
    </div>
</form>
</div>
