import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-basic-services-summary',
  templateUrl: './basic-services-summary.component.html',
  styleUrls: ['./basic-services-summary.component.css']
})
export class BasicServicesSummaryComponent   implements OnInit {  
    companyID=0;
    companyId;
    pendingrecord:number=0;
    currentUser;
    company_status:boolean=false;
    cpdata={customer_id:null};
    getCompanyobj;
    customerID;
    obj;
    reportData;
    p: number = 1;
    total: number;    
    start_date=null;
    end_date=null;
    status='select';
    clicked=false;
    maxdate = new Date();
    tot_campaign_prospect_shop=0;
    tot_campaign_prospect_host=0;
    tot_campaign_prospect_join=0;
    tot_campaign_consumer_shop=0;
    tot_campaign_consumer_host=0;
    tot_campaign_consumer_join=0;
    tot_campaign_encouragement_new= 0;
    tot_campaign_training_new=0;
    tot_campaign_event_inperson=0;
    tot_campaign_event_virtual_new=0;
    tot_campaign_event_inperson_virtual=0;
    showResult:boolean=true;
    isHeadquater;

    constructor(
      private report:ReportService,
      public toastrService: ToastrService,
      private spinner : NgxSpinnerService,
      private clientService : ClientService
    ) { }
  
    ngOnInit() {       
      this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
      this.companyID= this.currentUser.admin_customer_id;
     
      this.showResult=false;

     this.isHeadquater = localStorage.getItem("customer_isheadquarter");

      console.log("ssss,,,,.",this.isHeadquater)
      if(this.companyID==0 || this.isHeadquater ==="true"){
      
        console.log("asdddddd........");
        this.getCompany();
        this.company_status=true;
        this.isHeadquater = true;
        //this.getTotalCampaign(3);
       // this.select_campaign_pros_shop(3,this.start_date,this.end_date);
        //this.select_campaign_pros_host(3,this.start_date,this.end_date);
       // this.select_campaign_pros_join(3,this.start_date,this.end_date);
        //this.select_campaign_cust_shop(3,this.start_date,this.end_date);
        //this.select_campaign_cust_host(3,this.start_date,this.end_date);
        //this.select_campaign_cust_join(3,this.start_date,this.end_date);
        //this.tot_compaign_event(3,this.start_date,this.end_date);
        //this.tot_compaign_event_inperson(3,this.start_date,this.end_date);
        //this.tot_campaign_event_virtual(3,this.start_date,this.end_date);
        //this.tot_campaign_event_imperson_virtual(3,this.start_date,this.end_date);
      //  this.tot_campaign_training(3,this.start_date,this.end_date);
        //this.tot_campaign_training_encouregement(3,this.start_date,this.end_date);
      }else{
        this.showResult=true;
        this.company_status=false;
        this.isHeadquater = false;
        console.log("ddddddddddddddd........");      
        this.gettingReports(1,this.companyID);
        this.companyId = this.companyID
      }
    
    }

    select_ds_host_active(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_ds_host_active(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_ds_host_inactive(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_ds_host_inactive(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_all_campaign(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_all_campaign(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_all_prospect(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_all_prospect(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_customer_campaign(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_customer_campaign(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_pros_shop(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_pros_shop(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_prospect_shop= data.data;
        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_pros_host(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_pros_host(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_prospect_host= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_pros_join(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_pros_join(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_prospect_join= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_cust_shop(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_cust_shop(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_consumer_shop= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_cust_host(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_cust_host(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_consumer_host= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    select_campaign_cust_join(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.select_campaign_cust_join(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_consumer_join= data.data;
          }
        else
        {
          
          this.total= 0;
        }
      });
    }

    tot_compaign_event(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.tot_compaign_event(body).subscribe((data)=>{
        if(data.status)
        { 
          this.total= data.data; 
         }
        else
        {          
          this.total= 0;
        }
      });
    }
    tot_compaign_event_inperson(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.tot_compaign_event_inperson(body).subscribe((data)=>{
        if(data.status)
        { 
          if(data.data===0) this.tot_campaign_event_inperson= 0;
          else this.tot_campaign_event_inperson= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    tot_campaign_event_virtual(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.tot_campaign_event_virtual(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_event_virtual_new= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
   
    
    tot_campaign_event_imperson_virtual(id:any,st:any,et:any){
      var body={customer_id:id,date1:st,date2:et};
      this.report.tot_campaign_event_imperson_virtual(body).subscribe((data)=>{
        if(data.status)
        { 
          this.tot_campaign_event_inperson_virtual= data.data;        }
        else
        {
          
          this.total= 0;
        }
      });
    }
    tot_campaign_training(id,st,et){
      var body={customer_id:id,date1:st,date2:et};
      this.report.tot_campaign_training(body).subscribe((data)=>{
        if(data.status)
        { 

          if(data.data==0) this.tot_campaign_training_new= 0; 
          if(data.data!=0) this.tot_campaign_training_new= data.data;
         }
        else
        {
          
          this.total= 0;
        }
      });
    }
    
    tot_campaign_training_encouregement(id,st,et){
     var body={customer_id:id,date1:st,date2:et};
      this.report.tot_campaign_training_encouregement(body).subscribe((data)=>{
        if(data.status)
        {   if(data.data==0) this.tot_campaign_encouragement_new =0; 
            else this.tot_campaign_encouragement_new =data.data;
         }
        else
        {
          
          this.total= 0;
        }
      });
    }
    getCompany() {    
      let self = this;
      this.clientService.getBUCompanies().subscribe(data => {
      
      
          self.getCompanyobj = data.data;
      


      }, error => {
       // console.log(error);
      });
    }
    getTotalCampaign(id:any){
     // alert(id);
      this.spinner.show();  
      this.report.getTotalCampaign(id).subscribe((data)=>{
        if(data.status) { 
          this.total= data.data;        
        }
        else{
          this.total= 0;
        }      
       this.spinner.hide();
      }); 
    }

    searchResult() {
      if(! this.companyId) {
        this.toastrService.error("Please select company");
        return false;
      }
      console.log("head.,",this.isHeadquater,typeof this.start_date)

      if(this.isHeadquater==false && this.start_date == null  && this.end_date == null ) {
        this.toastrService.error("Please select start date and end date");
        return false;
      }

      if(this.start_date>this.end_date){
        setTimeout(() => {
          this.toastrService.error("Please select from date less than to date");
        }, 1000);
        return false;
      }

      this.gettingReports(1,this.companyId);
      

    }

    gettingReports(page,id) {

      this.companyId  = id;

     console.log("company..",id);
     if(this.companyId==0) {
      this.showResult=false;
      this.toastrService.error("Please select company");
      return false;
    }
    
     
      this.companyID =  id;
      const parmas = {
        customer_id: this.companyID,
        page: page,
        start_date: this.start_date,
        end_date: this.end_date,         
        csv : false
      }; 
      
     
        this.showResult=true;
        this.select_ds_host_active(this.companyID,this.start_date,this.end_date);
        this.select_ds_host_inactive(this.companyID,this.start_date,this.end_date);
        this.select_all_campaign(this.companyID,this.start_date,this.end_date);
        this.select_all_prospect(this.companyID,this.start_date,this.end_date);
        this.select_customer_campaign(this.companyID,this.start_date,this.end_date);
        this.select_campaign_pros_shop(this.companyID,this.start_date,this.end_date);
        this.select_campaign_pros_host(this.companyID,this.start_date,this.end_date);
        this.select_campaign_pros_join(this.companyID,this.start_date,this.end_date);
        this.select_campaign_cust_shop(this.companyID,this.start_date,this.end_date);
        this.select_campaign_cust_host(this.companyID,this.start_date,this.end_date);
        this.select_campaign_cust_join(this.companyID,this.start_date,this.end_date);
        this.tot_compaign_event(this.companyID,this.start_date,this.end_date);
        this.tot_compaign_event_inperson(this.companyID,this.start_date,this.end_date);
        this.tot_campaign_training(this.companyID,this.start_date,this.end_date);
        this.tot_campaign_training_encouregement(this.companyID,this.start_date,this.end_date);
      
    }

    exportReportCsv() {
      this.spinner.show();
      const parmas = {
        customer_id: this.companyID,
        page: this.p,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
        csv : true
      };
      this.report.getDsSubscriptionList(parmas).subscribe(dataFile => {
       // console.log(">>>>>", dataFile);
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
          this.spinner.hide();
        }
        this.spinner.hide();
      }, err => {
        this.toastrService.error(err.message);
        this.spinner.hide();
      });
    }
    resetReport(){
      this.start_date=null;
      this.end_date =null;
      
      this.gettingReports(1, this.companyID)
    
    }
  }