<ngx-spinner bdOpacity=0.9 bdColor="#333" size="large" color="#fff" type="ball-clip-rotate">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/module">Modules</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{header}} Module</li>
        </ol>
    </nav>
</div>

<div class="container mt-1">
    <form class="form-horizontal mt-1" [formGroup]="moduleForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
        <div class="card">
            <div class="card-body">
                <div>
                    <label for="exampleForm2">Parent Module Name <span style="color:red">*</span></label>
                    <select formControlName="moduleParentid" class="form-control formstyle">
                        <option value="0">Root</option>
                        <option *ngFor="let userObj of moduleObj1" value={{userObj.moduleid}}>
                            {{userObj.module_name}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.moduleParentid.errors" class="invalid-feedback">
                        <div *ngIf="f.moduleParentid.errors.required">Parent Module Name is required</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Module Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="moduleName"
                        class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.moduleName.errors }" required maxlength="30" appSpace>
                    <div *ngIf="submitted && f.moduleName.errors" class="invalid-feedback">
                        <div *ngIf="f.moduleName.errors.required || f.moduleName.hasError('whitespace')">Module Name is required</div>
                        <div *ngIf="f.moduleName.errors.pattern">Module Name is not a valid format</div>
                        <div *ngIf="f.moduleName.errors?.maxlength">You can enter only 30 charachters</div>
                
                    </div>
                </div>

                <div class="mt-1">
                    <label for="exampleForm2"> Module Description</label>
                    <input type="text" id="exampleForm2" formControlName="moduleDescription"
                        class="form-control form-control-md" min="1" maxlength="499">
                </div>
                <label class="mt-1">IsActive</label>
                <div class="mt-1">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isactive"
                            [value]="true">
                        <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isactive"
                            [value]="false">
                        <label class="custom-control-label" for="customRadio2">In Active</label>
                    </div>
                </div>
                
            </div>
            <div class="card-footer">
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn" *ngIf="isWrite" (click)="addModule()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateModule()">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
    </form>
</div>