import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { BaseComponent } from '../../base.component';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent extends BaseComponent implements OnInit {
  eventList: any[];
  userData: any;
  companyId: any;
  limit =10;
  skip = 0;
  totalNumber=0;
  page=1;
  eventTypeFilter:any='';
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  rsvpObj: any;
  modalRef: any;
  @ViewChild('inviteeModal', {static:false}) inviteeModal:ElementRef;
  constructor(
    private shopLiveService : ShopLiveService,
    private modalService : NgbModal
  ) {
    super();
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
  }

  ngOnInit(): void {
    this.getEventList(this.page);
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.shopLiveService.getEvents(this.companyId, this.limit, this.skip,this.searchText.nativeElement.value,this.eventTypeFilter))
      )
      .subscribe(res => {
        if (res.status) {
          this.eventList = res.data.rows;
          this.totalNumber = res.total;
        } else {
          this.eventList = [];
          this.totalNumber = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.eventList = [];
        this.totalNumber = 0;
        this.ngxSpinnerService.hide();
      });
  }
  getEventList(event) {
    this.page = event;
    this.skip = (this.page - 1) * this.limit;
    let searchTerm = this.searchText.nativeElement.value;
    this.eventList = [];
    this.ngxSpinnerService.show();
    this.shopLiveService.getEvents(this.companyId,this.limit,this.skip,searchTerm,this.eventTypeFilter).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      if(res.status){
        this.eventList = res.data.rows;
        this.totalNumber = res.data.total;
        this.eventList.forEach((ele)=>{
          // Input string
         let input_str = ele.event_time_zone;
          // Split the string into words
          let words: string[] = input_str.split(" ");
        //  Get the first character of each word and join them into a new string
        ele.event_time_zone_abbr = words.map(word => word.charAt(0)).join("");
        })
      }
      else{
        this.eventList = [];
        this.totalNumber = 0;
      }
    },
    error=>{
      this.ngxSpinnerService.hide();
      this.eventList = [];
      this.totalNumber = 0;

    }
    )
  }
  checkEventType(id: any) {
    if (id == 127) {
      return 'In-Person';
    } else if (id == 128) {
      return 'Virtual';
    } else if (id == 129) {
      return 'In-Person & Virtual';
    } else {
      return '';
    }
  }
  onClickEdit(eventData:any){

    sessionStorage.setItem("eventdata", JSON.stringify(eventData));
    sessionStorage.setItem("eventAction",'Update');
    this.router.navigateByUrl("/corporate/add-event");
  }
  onClickAdd(){
    sessionStorage.removeItem('eventdata');
    sessionStorage.setItem("eventAction",'Add new');
    this.router.navigateByUrl("/corporate/add-event");
  }
  onClickView(eventData:any){
    sessionStorage.setItem("eventdata", JSON.stringify(eventData));
    sessionStorage.setItem("eventAction",'view');
    this.router.navigateByUrl("/corporate/event-view");
  }
  deleteComfirm(eventID:any){
    // this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to delete this event?', 'Yes', 'No')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.ngxSpinnerService.show();
    //       this.shopLiveService.deleteEvent(eventID,this.companyId).subscribe((res:any)=>{
    //         this.ngxSpinnerService.hide();
    //         if(res.status){
    //           this.toastrService.success('Record deleted !')
    //           this.getEventList(1);
    //         }
    //         else{
    //           this.toastrService.error(res?.message)
    //         }
    //       })
    //     }
    //   }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }
  openInviteeModal(id:any,count:any){
      if(count>0){
        sessionStorage.setItem('eventId',id);
        sessionStorage.setItem('eventinviteecount',count);
        this.router.navigateByUrl('/corporate/event-invitee');
      }
    }
    updateStatus(eventid:any,isactive:any){
      this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.shopLiveService.updateEventStatus(this.companyId,eventid,!isactive).subscribe((res:any)=>{
            this.ngxSpinnerService.hide();
            if(res.status){
              this.getEventList(1);
            }
            else{
              this.toastrService.error(res?.message)
            }
          })
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } 
}
