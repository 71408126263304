import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../services/users/register.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {

  image_awskey;
  userData:any;
  constructor(
    public registerService: RegisterService,
    public master: MasterService
  ) { }

  status = false;
  userProfileDetails;
  ngOnInit() {
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.registerService.getUserProfileDetails().subscribe(data => {
      if (data.status) {
        this.status = true;
        this.userProfileDetails = data.data;
       // console.log(this.userProfileDetails);

      }
    }, error => {

    });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.image_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : 'master_images/profile.png';
      } else {
        event.target.src = 'master_images/profile.png';
      }
    } else {
      event.target.src = 'assets/custom/images/profile.png';
    }
    // event.target.src = 'assets/custom/images/profile.png';
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

}
