<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/content-builder-template">Content Builder Template</a></li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="!id">Add Content Builder Template</li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="id">Update Content Builder Template</li>
        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="templateForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
    
              <div class="card mt-1">
    
                <div class="card-body">
                <div class="form-group">
                    <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                    <select formControlName="company" *ngIf="headers == 'Add'"  class="form-control formstyle"
                     (change)="getCompanyTemplate($event.target.value,'name','asc')"
                        [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                       
                        <option value="">Select Company Name</option>
                         <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                            {{userObj.customer_company_name}}
                        </option>
                    </select>
                    <select formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                   
                    <option value="">Select Company Name</option>
                     <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                </select>
                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                        <div *ngIf="f.company.errors.required">Company Name is required</div>
                    </div>
                </div>

                <!-- template dropdown start -->
                <div class="form-group">
                    <label for="exampleForm2">Marketing Cloud Template</label>
                    <select formControlName="templateid" *ngIf="headers == 'Add'"  class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.templateid.errors }" 
                        (change)="changeMkValue($event.target.value)">
                       
                        <option value = ''>Clone Template</option>
                         <option *ngFor="let userObj of getTemplateobj" value={{userObj.templateid}} >
                            {{userObj.name}}
                        </option>
                    </select>
                    <select formControlName="templateid" *ngIf="headers == 'Edit'"  class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.templateid.errors }" 
                    (change)="changeMkValue($event.target.value)">
                   
                    <option value = ''>Clone Template</option>
                     <option *ngFor="let userObj of getTemplateobj" value={{userObj.templateid}}>
                        {{userObj.name}}
                    </option>
                </select>
                    <div *ngIf="submitted && f.templateid.errors" class="invalid-feedback">
                        <div *ngIf="f.templateid.errors.required">template Name is required</div>
                    </div>
                </div>
                <!-- template dropdown end -->
                <div class="form-group">
                    <label for="exampleForm2">Template Name <span style="color:red">*</span></label>
                    <input type="text" formControlName="name" min="1" maxlength="499" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required appSpace>
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Template Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleForm2">Template Description</label>
                    <textarea  class="form-control text-area" rows="5" min="1" maxlength="499" formControlName="description" appSpace></textarea>
            
                    
                </div>

                <!-- ContentBlockName input start  -->
                <div class="form-group">
                    <label for="exampleForm2">ContentBlockName</label>
                    <input type="text" formControlName="ContentBlockName" class="form-control form-control-md">                    
                </div>
                <!-- ContentBlockName input end  -->

                <div class="form-group">
                    <label for="exampleForm2">Template Preview</label>
                    <textarea  class="form-control text-area"  style="min-height: 150px;" rows="10" formControlName="template_preview"></textarea>
                   
                </div>

                <div class="form-group">
                    <label for="exampleForm2">Template Screenshot <span style="color:red">*</span></label>
                   
                    <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                        <img src="{{them_awskey}}" alt="Screenshot" style="width:200px;" (error)="setValue($event)">
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio1"
                                formControlName="firstActive" [value]="true" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio1">Url</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2"
                                formControlName="firstActive" [value]="false" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio2">Upload a file</label>
                        </div>
                        
                    </div>
                    <div *ngIf="screenshot_type" class="mt-1">
                        <input type="text" formControlName="screenshot" class="form-control form-control-md mt-1"
                            placeholder="https://example.com/image(.jpg,.jpeg,.png)" required
                            [ngClass]="{ 'is-invalid': submitted && f.screenshot.errors }">
                        <div *ngIf="submitted && f.screenshot.errors" class="invalid-feedback">
                            <div *ngIf="f.screenshot.errors.required">Template screenshot is required</div>
                            <div *ngIf="f.screenshot.errors.validUrl && !f.screenshot.errors.required">Invalid url</div>
                        </div>
                    </div>
                    <div *ngIf="!screenshot_type" class="mt-1">
                        <input #imageInput type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                            <div *ngIf="submitted && !them_awskey" style="color:red">
                                Template screenshot is required
                            </div>
                    </div>
                </div>
                
            </div>

            <div class="card-footer text-center">
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn" *ngIf="isWrite" (click)="addTemplate()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateTemplate()">Update</button>
                </div>
              </div>
        </div>
    </div>
</div>
    </form>
</div>