import {BaseComponent} from '../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../services/segmentation/segmentation.service';
import {BUSegmentationService} from '../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-add-bu-audience-segmentation',
  templateUrl: './add-bu-audience-segmentation.component.html',
  styleUrls: ['./add-bu-audience-segmentation.component.css']
})
export class AddBuAudienceSegmentationComponent extends BaseComponent implements OnInit {

  buselected: any;
  buid: any;
  show = false;
  activeAudienceList = [];
  buAudList = [];
  buAudListIDS = [];
  constructor(private segmentService: BUSegmentationService) {
    super();
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
      this.checkAudienceData();
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {

    this.getActiveAudienceList();
  }

  //  ##############  Get List for Active Audience ###############  //

  getActiveAudienceList(){
    this.ngxSpinnerService.show();
    this.segmentService.getActiveAudienceList().subscribe(data => {
      if (data.status) {
        this.activeAudienceList = data.data;
        this.show = true;
      } else {
        this.show = false;
        this.activeAudienceList = [];
      }
      this.ngxSpinnerService.hide();

    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Select Audience from Checkbox  ####################  //

  selectAudience(event, id){
    if (event.target.checked) {
      this.buAudListIDS.push(id);
    } else {
      this.buAudListIDS.splice(this.buAudListIDS.indexOf(id), 1);
    }
  }

  //  ############# Add Audience for BU  ##############################  //

  addAudiences() {
    if (this.buAudListIDS.length === 0) {
      this.toastrService.error('Please select at least one Audience');
      return;
    }
    this.ngxSpinnerService.show();

    let obj = {
      company: this.buid,
      audience_id: this.buAudListIDS
    };
    this.segmentService.addAudienceforBU(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Audience added successfully for BU');
        this.router.navigateByUrl('/corporate/bu-audience');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############  Check for Audience Data for Selected BU  ##############  //

  checkAudienceData(){
    this.segmentService.checkAudienceDataForBU(this.buid).subscribe(data => {
      if (data.status) {
        this.router.navigateByUrl('/corporate/bu-audience');
      } else {

      }

    }, err2 => {
      this.toastrService.error(err2);
      this.router.navigateByUrl('/corporate/bu-audience');
    });
  }
}
