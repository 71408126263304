import { AppConstants } from 'src/app/_helpers/app.constants';
import { switchMap, debounceTime } from 'rxjs/operators';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../secondadmin/base.component';
import { ClientService } from '../../../../services/clients/client.service';
import { DsuserService } from '../../../../services/users/dsuser.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master/master.service';
import { error } from 'console';


@Component({
  selector: 'app-specific-ewallet-transfer',
  templateUrl: './specific-ewallet-transfer.component.html',
  styleUrls: ['./specific-ewallet-transfer.component.css']
})
export class SpecificEwalletTransferComponent extends BaseComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  order: string = 'get_ds_user_login_fun.full_name';
  specificWalletForm: FormGroup;
  companyId = "select";
  cp = 1;
  status = 1;
  total: any;
  amount: any;
  companyObj: any;
  searchText: any='';
  customerID: any;
  customerInfo: any;
  isAllChecked: boolean;
  reverse: boolean = false;
  submitted: boolean = false;
  checkedList = [];
  userDetails: any = [];
  allCheckedList: any = [];
  masterAmount: any
  plan_type:any='';
  isSubmit=false;
  userData:any;
  dsAvailableSenderNumbers: any;
  newsenderNumber:any='';
  updateSmsDs: any;
  constructor(
    public dsUserService: DsuserService,
    private formBuilder: FormBuilder,
    private modal:NgbModal,
    private toastr:ToastrService,
    private master:MasterService
  ) {
    super();
    this.specificWalletForm = this.formBuilder.group({

      amount: ['', [Validators.required, Validators.pattern(AppConstants.regex.intFloatPattern)]],

    })
  }

  ngOnInit() {
    this.customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = this.customerInfo.admin_customer_id;
    //console.log('customerInfo', this.customerInfo);

    if (this.customerID != 0) {
      this.companyId = this.customerID;
      this.getCompanySellerNew(1);
    } else {
      this.getCompany();
    }
    this.UserLogData();
  }

  getCompany() {
    this.ngxSpinnerService.show();
    this.dsUserService.activeWalletCompany().subscribe(data => {
      if (data.status) {
        this.companyObj = data.data;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
      }
    }, err => {
      this.ngxSpinnerService.hide();
    })
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  // getCompanySeller(page) {
  //   this.cp = page;
  //   this.ngxSpinnerService.show();
  //   if (this.status !== 0) {
  //     this.dsUserService.getEWalletDsUser(this.companyId, this.cp).subscribe(data => {
  //       if (data.status) {
  //         this.userDetails = data.data;
  //         this.total = data.total;
  //       } else {
  //         this.userDetails = [];
  //         this.toastrService.error('No Result Available');
  //       }
  //       this.userDetails.forEach(element => {
  //         element.isSelected = false;
  //       });
  //       this.ngxSpinnerService.hide();
  //     }, error => {
  //       // console.log(error);
  //       this.ngxSpinnerService.hide();
  //     });
  //   }
  //   else {
  //     this.userDetails = [];
  //     this.ngxSpinnerService.hide();
  //   }
  // }

  UserLogData() {
    let UserLogs = {
      log_type: "direct seller User View",
      log_name: "direct seller User  View",
      log_description: "direct seller  User  View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(UserLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      //  console.log(error);
    });
  }

  // search(event) {
  //   if (this.searchText == "") {
  //     this.getCompanySeller(this.cp);
  //   } else {
  //     this.ngxSpinnerService.show();
  //     this.dsUserService.getEWalletUserSearch(this.companyId, this.searchText).subscribe(data => {
  //       if (data.data) {
  //         this.userDetails = data.data;
  //         this.total = data.total;
  //       } else {
  //         this.userDetails = [];
  //         //console.log('userDetails', this.userDetails);
  //         //this.toastrService.error("No Records")
  //       }
  //       this.ngxSpinnerService.hide();
  //     }, error => {
  //       // console.log(error);
  //       this.ngxSpinnerService.hide();
  //     });
  //   }
  // }

  onCheckboxChange(option, event) {
    console.log('all', option);
    if (event.target.checked) {
      if (option == "all") {
        this.userDetails.forEach(element => {
          element.isSelected = true;
          this.checkedList.push(element);
        })
      }
      else this.checkedList.push(option);
    } else {
      if (option == "all") {
        this.userDetails.forEach(element => {
          element.isSelected = false;
        })
        for (var i = 0; i < this.checkedList.length; i++) {
          this.checkedList.splice(i, 1);
        }
      } else {
        for (var i = 0; i < this.checkedList.length; i++) {
          if (this.checkedList[i].userid == option.userid) {
            this.checkedList.splice(i, 1);
          }
        }
        this.specificWalletForm.get('amount').clearValidators();
      }
    }
    console.log('this.checkedList', this.checkedList, this.checkedList.length);
  }

  dsLogsView(id) {
    this.router.navigate(['/corporate/dslogs'], { queryParams: { customer_id: this.companyId, ds_id: id } });
  }

  get f() { return this.specificWalletForm.get('amount'); }

  addAmount() {
    this.submitted = true;
    if (this.checkedList.length > 0) {
      this.masterAmount = [];
      for (let item of this.checkedList) {
        this.masterAmount.push(item.masteramount);
      }
    }
    if(this.f.value < 5) {
      this.toastrService.error('Minimum amount >= 5.00');
      return false;
    }
    if (this.specificWalletForm.invalid) {
      return;
    }
    if (this.allCheckedList && this.isAllChecked) {
      const obj = {
        company: this.companyId,
        amount: this.f.value,
      }
      this.dsUserService.bulkWalletTransfer(obj).subscribe(res => {
        if (res.status) {
          this.toastrService.success('Credit amount added successfully');
          this.allCheckedList = [];
          this.checkedList = [];
          this.isAllChecked = false;
          this.f.setValue('');
          this.submitted = false;
          this.getCompanySellerNew(1);
        } else {
          this.toastrService.error(res.message);
        }
      }, err => {
        this.toastrService.error('Something went wrong');
      });
    } else if (this.checkedList.length > 0) {
      let userId = [];
      for (let item of this.checkedList) {
        userId.push(item.userid);
      }
      const obj = {
        company: this.companyId,
        amount: this.f.value,
        dsUsers: userId
      }
      this.dsUserService.specificWalletTransfer(obj).subscribe(res => {
        if (res.status) {
          this.toastrService.success('Credit amount added successfully');
          this.checkedList = [];
          this.allCheckedList = [];
          this.isAllChecked = false;
          this.f.setValue('');
          this.submitted = false;
          this.getCompanySellerNew(1);
        } else {
          this.toastrService.error(res.message);
        }
      }, err => {
        this.toastrService.error('Something went wrong')
      });
    }
  }

  checkUncheckAll() {
    if (this.isAllChecked) {
      for (let i = 0; i < this.userDetails.length; i++) {
        this.userDetails[i].isSelected = this.isAllChecked;
      }
      this.getCheckedItemList();
    } else {
      this.checkedList = [];
      this.allCheckedList = [];
    }
  }

  isAllSelected(event) {
    this.isAllChecked = this.userDetails.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.allCheckedList = [];
    for (let i = 0; i < this.userDetails.length; i++) {
      if (this.userDetails[i].isSelected) {
        this.allCheckedList.push(this.userDetails[i]);
      }
    }
    //console.log('allCheckedList', this.allCheckedList, this.allCheckedList.length)
  }
  openSubModal(data:any,modal:any){
    this.userData=data;
    this.modal.open(modal,{
      ariaLabelledBy: 'modal-basic-title',
      backdrop:'static',
      windowClass:'modal-center',
    })
  }
  checkSubscriptionExpire(userData:any){
    let subscriptionExpDays=1;
    if(userData?.sub_id){
      let currentDate=new Date();
      let expiryDate=new Date(userData?.sub_end_date)
      let threeDaysSub=new Date(new Date(expiryDate).setDate(expiryDate.getDate() - Number(subscriptionExpDays)));
      if( currentDate >= threeDaysSub){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  renewPlanType(){
    this.isSubmit=false;
    if(!this.plan_type){
      this.isSubmit=true;
      return;
    }
    this.ngxSpinnerService.show();
    let body={"sub_id":this.userData?.sub_id,"ds_id":this.userData?.userid,"plan_type":this.plan_type}
    this.dsUserService.renewSubscription(this.companyId, body).subscribe(data => {
      if (data.status) {
        this.getCompanySellerNew(this.cp);
        this.modal.dismissAll();
        this.isSubmit=false;
        this.plan_type=''
        this.toastr.success('Subscription update successfully')
      } else {
        this.toastr.error(data.message)
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastr.error(error.error.message || error.message)
      this.ngxSpinnerService.hide();
    });
  }
  openSMSModal(modal: any) {
    this.modal.open(modal, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      windowClass: 'modal-center',
    })
  }
  getSenderNumber(dsId: any, modal: any) {
    this.updateSmsDs = dsId;
    this.ngxSpinnerService.show();
    this.master.getReskinningSenderNumber(this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        if (res.status) {
          this.dsAvailableSenderNumbers = res.data;
          this.openSMSModal(modal);
        }
      }
      ,
      error: (error: any) => {
        this.dsAvailableSenderNumbers = [];
        console.log(error.message);
        this.ngxSpinnerService.hide();
      }
    })
  }
  updateSmsNo() {
    let payload = {
      ds_id: this.updateSmsDs,
      ds_sender_number: this.newsenderNumber
    }
    this.ngxSpinnerService.show();
    this.newsenderNumber = '';
    this.dsUserService.updateSMSNoForDS(this.companyId, payload).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        if (res.status) {
          this.toastrService.success('Updated SMS Number Successfully.');
          this.getCompanySellerNew(this.cp);
          this.modal.dismissAll();
        }
        else {
          this.toastrService.error('SMS No. is already occupied.');
        }
      }
      ,
      error: (error: any) => {

        console.log(error.message);
        this.ngxSpinnerService.hide();
      }
    })
  }
  getCompanySellerNew(page) {
    this.cp = page;
    this.ngxSpinnerService.show();

    this.dsUserService.getEWalletDsUserNew(this.companyId, this.cp,this.searchText).subscribe(data => {
      if (data.status) {
        this.userDetails = data.data;
        this.total = data.total;
      } else {
        this.userDetails = [];
        this.toastrService.error('No Result Available');
      }
      this.userDetails.forEach(element => {
        element.isSelected = false;
      });
      this.ngxSpinnerService.hide();

    },
    
    error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
}
