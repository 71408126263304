export class AppConstants {
  public static MOBILE_RESPONSIVE_WIDTH = 992;
  public static MOBILE_RESPONSIVE_WIDTH_HORIZONTAL = 768;
  public static NAVIGATION_TYPE_COLLAPSIBLE = 'menu-collapsible';
  public static NAVIGATION_TYPE_ACCORDATION = 'menu-accordation';
  public static LAYOUT_STYLE_HORIZONTAL = 'horizontal';
  public static LAYOUT_STYLE_VERTICAL = 'vertical';

  public static fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  public static regex = {
    email: '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    name: '[a-zA-Z ]*',
    zipcode: '^[0-9]*$',
    number: '[0-9]*',
    alphaNumeric: '[a-zA-Z0-9 ]*',
    websiteURL:"(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
    languagePattern: '[a-zA-Z, ]*',
    floatPattern:'^[0-9]\\d*(\\.\\d{1,2})$',
    ageRangePattern: '^[0-9]{1,2}(-)([1-9][0-9]|[1][0-3][0-9])$',
    eWallet: /^[A-Za-z0-9' _-]*$/, 
    intFloatPattern: /^\d+(\.\d{1,})?$/,
    //intFloatPattern: /^\d*\.?\d{ 0, 2 }$/,
    mixPattern: '^[0-9]*(\.[0-9]{1,2})?$'
  }
}
