<div
    class="nested-flow-step"
    #canvasContent
>
    <span>{{ data.name }}</span>
    <div
        ngFlowchartCanvas
        [ngFlowchartCallbacks]="callbacks"
        class="nested-canvas-content"
    >

    </div>
</div>
