<div>
  <!-- <mat-form-field> -->
      <!-- <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" class="filter"> -->
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" matInput (keyup)="applyFilter($event.target.value)">
      </div>
    <!-- </mat-form-field> -->
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
    <ng-container [matColumnDef]="cols" *ngFor="let cols of displayedColumns; let i = index">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <!-- <span *ngIf="">{{cols}}</span> -->
        <span *ngIf=" i > 0 && cols !== 'description'">{{cols}}</span>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'col-value': element.details === undefined}">
        <!-- expand caret -->
        <span *ngIf="i === 1 && element.details">
          <span *ngIf="expandedElement && expandedElement[cols] === element[cols]; else plusIcon"
            class="expand-icon">&#9650;</span>
          <ng-template #plusIcon><span class="expand-icon">&#9660;</span></ng-template>
        </span>
        <span *ngIf="i > 0 && i < displayedColumns.length - 1 && cols !== 'description'">{{element[cols]}}</span>
        <span *ngIf="cols ==='actions'">
          <span class="fa fa-edit mr-1" (click)="actionModule($event,element,'edit')" placement="bottom" ngbTooltip="Edit"></span>
          <span class="fa fa-trash mr-1" (click)="actionModule($event,element,'delete')" aria-disabled="true" placement="bottom" ngbTooltip="Delete"></span>
          <span>
            <mat-slide-toggle class="my-slider mr-4" id="{{element['id']}}" [checked]="element['active']"
              (click)="actionModule($event,element,'active')">
            </mat-slide-toggle>
          </span>
          <!-- <span class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="{{element['id']}}" [checked]="element['active']"
              (click)="actionModule($event,element['id'],'active')">
            <label class="custom-control-label" for="{{element['id']}}"></label>
          </span> -->
        </span>
      </mat-cell>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let detail">
        <ngx-data-table [data]="detail.element.details" class="detail-table" (messageEvent)="receiveMessage($event)">
        </ngx-data-table>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement == row"
      (click)="toggleRow(row)">
    </mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
      [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>

  </mat-table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[10, 15, 20, 25, 30]">
  </mat-paginator>
</div>