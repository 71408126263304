<div class="container bdrStCustom">

  <div class="row">
    <div class="col">
      <div class="card box-shadow-1">
        <div class="card-body">
          <h5 class="font-weight-bold">Subscription List</h5>
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Attachment</th>
                <th>Date</th>
              </tr>
              <tr *ngFor="let obj of subscriptionUserObj">
                <td>{{obj.name}}</td>
                <td>{{obj.description}}</td>
                <!-- <td>{{obj.attachment}}</td> -->
                <td *ngIf="checkImg(obj.attachment)"><img [src]="obj.attachment" alt="Not Found"
                    (error)="setValue($event)" style="height:50px;"></td>
                <td *ngIf="!checkImg(obj.attachment)"><a routerLink="setDocValue(obj.attachment)"
                    target="_blank">{{obj.attachment}}</a></td>
                <td>{{obj.createdon | date:'medium' }}</td>
              </tr>
            </table>
          </div>
          <div class="mt-2 text-center difPagination" *ngIf="subsTotal > 10">
            <ngb-pagination [collectionSize]="subsTotal" [(page)]="subsPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getSubscriptionUser(subsPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card box-shadow-1">
        <div class="card-body">
          <h5 class="font-weight-bold">User List</h5>
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User name</th>
                <th>Role</th>
                <th width="150">Created On</th>
              </tr>
              <tr *ngFor="let obj of contactUserObj">

                <td>{{obj.admin_fname}} {{obj.admin_lname}}</td>
                <td>{{obj.admin_email}}</td>
                <td>{{obj.admin_username}}</td>
                <td>{{obj.role_name}}</td>
                <td>{{obj.createdon | date:'medium' }}</td>
              </tr>
            </table>
          </div>
          <div class="mt-2 text-center difPagination" *ngIf="userTotal > 10">
            <ngb-pagination [collectionSize]="userTotal" [(page)]="userPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getCompanyUser(userPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card box-shadow-1">
        <div class="card-body">
          <h5 class="font-weight-bold">Contact list</h5>
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>

              <tr *ngFor="let obj of contactObj">
                <td>{{obj.contact_fname}}</td>
                <td>{{obj.contact_lname}}</td>
                <td>{{obj.contact_email}}</td>
                <td>{{obj.contact_phone}}</td>
              </tr>
            </table>
          </div>

          <div class="mt-2 text-center difPagination" *ngIf="contactTotal > 10">
            <ngb-pagination [collectionSize]="contactTotal" [(page)]="contactPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getContacts(contactPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>