import {BaseComponent} from '../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {McConnectorService} from '../../../services/mcconnector/mc-connector.service';

@Component({
  selector: 'app-mc-data-extension-mapping',
  templateUrl: './mc-data-extension-mapping.html',
  styleUrls: ['./mc-data-extension-mapping.css']
})
// tslint:disable-next-line:component-class-suffix
export class McDataExtensionMapping extends BaseComponent implements OnInit {

  dataextensionform: FormGroup;
  submitted = false;
  connectionStatus: any;
  companyId: any;
  mcData: any;
  mcDataExtension = [];
  selectedAttributes = [];
  mcMappedData = [];
  constructor(private formBuilder: FormBuilder, private mcService: McConnectorService) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
  }

  ngOnInit() {

    this.checkMCInformationData();
    this.dataextensionform = this.formBuilder.group({
      dataExtension: ['', Validators.required]
    });
  }

  get f() { return this.dataextensionform.controls; }

  //  ##########  Check if MC Information is added for BU or not #############  //

  checkMCInformationData() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcInfoData(this.companyId, 10, 0).subscribe(data => {
      if (data.status && data.data.length > 0) {
        this.mcData = data.data[0];
        this.checkTokenUrl();
      } else {
        this.toastrService.error(data.message);
        this.router.navigateByUrl('/corporate/mc-information');
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##########  Check Connectivity with Current Data  ##   //

  checkTokenUrl() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcTokenURL(this.companyId).subscribe(data => {
      if (data.status) {
        this.connectionStatus = true;
       // this.getExtensionData();
        this.checkMcMappingData();
      } else {
        this.toastrService.error('Cannot connect with provided Credentials');
        this.connectionStatus = false;
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/mc-information');
        // this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/mc-information');
    });
  }

  //  #################  Check for Mapping Data ###############  //

  checkMcMappingData(){
    this.mcService.checkDataExtensionMapping(this.companyId).subscribe(data => {

      if (data.status) {
        this.mcMappedData = data.data;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        let URL = '/corporate/mc-dataextensionfields';

        this.router.navigate([URL]);

      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  // getExtensionData() {
  //   this.ngxSpinnerService.show();
  //   this.mcService.getDataExtensionMapping(this.companyId).subscribe(data => {
  //     console.log('DATA Extension Mapping', data);
  //     if (data.status) {
  //       this.mcDataExtension = data.data.dataExtension;
  //     } else {
  //       this.mcDataExtension = [];
  //       this.toastrService.error(data.message);
  //       // this.toastrService.error(data.message);
  //     }
  //     this.ngxSpinnerService.hide();
  //   }, error => {
  //     console.log(error);
  //     this.ngxSpinnerService.hide();
  //   });
  // }

  //  ##########  On Data Extension Selected from Dropdown  ##########  //

  // selectDataExtensionFromDropdown(id) {
  //   if (id === '' || id === null || id === undefined) {
  //     this.selectedAttributes = [];
  //     return;
  //   }
  //   let selExt = this.mcDataExtension.filter(element => element.id === id);
  //   this.selectedAttributes = selExt[0].attributes;
  // }

  //  ##########  Submit Data Extension ID/ Name #####################  //

  submit() {

    let URL = '/corporate/mc-dataextensionfields';

    this.router.navigate([URL]);

  }

  //   #########  Go to MC HOME ####################################  //

  goToMcHome() {
    let URL = '/corporate/mc-information';


    this.router.navigate([URL]);

  }
}
