<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/themeassociation">Theme Association</a></li>
      <li class="breadcrumb-item active" aria-current="page">Theme Association</li>
    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-10 col-xl-7">
      <div class="card mt-1">
        <div class="card-body">
          <div *ngIf="customerID=='0'">
            <label for="exampleForm2">Select Company Name</label>
            <select name="company" class="form-control formstyle" [(ngModel)]="companyId"
              (change)="getJourneyDropDown()">
              <option value="" selected>Select Company Name</option>
              <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                {{userObj.customer_company_name}}
              </option>
            </select>
          </div>
          <div>
            <label for="exampleForm2" class="font-weight-bold">Journey Id</label>
            <select [disabled]="showProduct && page=='direct-seller'" [(ngModel)]="JourneyId" class="form-control formstyle"
              (ngModelChange)="applySelectFilter($event); ">
              <option value="">--Select Journey--</option>
              <option *ngFor="let obj of journeyDropDownId" value={{obj.journey_id}}>
                {{obj.journey_name}} - {{obj.journey_description}}
              </option>
            </select>
          </div>
          <div *ngIf="page=='direct-seller'">
            <label for="exampleForm2" class="font-weight-bold">Business Domain Name</label>
            <select name="company" class="form-control formstyle" (change)="listByDomainName($event.target.value)">
              <option value="0" selected disabled>Select Business Domain</option>
              <ng-container *ngFor="let obj of domainData">
                <option value={{obj.id}}>
                  {{obj.domain_name}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="mt-1" [hidden]="!showProduct">
            <label for="exampleForm2" class="font-weight-bold">Product Id</label>
            <select [(ngModel)]="ProductId" class="form-control formstyle">
            
              <option value="0" selected>--Select Product--</option>
              <optgroup label="{{obj.groupLabel}}" *ngFor="let obj of shopProductList">
              <option *ngFor="let objele of obj.groupEle" value={{objele.product_catalog_id}}>
                {{objele.product_name +" - "+ objele.product_category}}
              </option>
              </optgroup>
              <!-- <option *ngFor="let obj of shopProductList" value={{obj.product_catalog_id}}>
                {{obj.product_name +" - "+ obj.product_category}}
              </option> -->
            </select>
          </div>

          <div class="mt-1">
            <label for="exampleForm2" class="font-weight-bold">Theme Id</label>
            <select [(ngModel)]="ThemeId" class="form-control formstyle">
              <option value="">--Select Theme--</option>
              <option *ngFor="let obj of themeDropDownId" value={{obj.theme_id}}>
                {{obj.theme_name}}
              </option>1
            </select>
          </div>
        </div>

        <div class="card-footer text-center mt-1">
          <div class="my_class text-center">
            <button type="submit" class="custbtn" *ngIf="isWrite" (click)="addnewThemeAssociation()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
