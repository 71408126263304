import {BaseComponent} from '../../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentationService} from '../../../../../../services/segmentation/segmentation.service';
import {BUSegmentationService} from '../../../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-new-bu-audience',
  templateUrl: './new-bu-audience-segmentation.component.html',
  styleUrls: ['./new-bu-audience-segmentation.component.css']
})
export class NewBuAudienceSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  header = 'Add';
  submitted = false;
  status = true;
  audForm: FormGroup;
  audId;
  aud;
  constructor(private segmentService: BUSegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.audId = params.audId;
      });
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.audForm = this.formBuilder.group({
      audname: ['', Validators.required],
      auddesc: ['']
    });
    if (this.audId) {
      this.header = 'Edit';
      this.ngxSpinnerService.show();
      this.segmentService.getBUAudienceById(this.buid, this.audId).subscribe(data => {
        if (data.status){
          this.aud = data.data[0];
          this.status = false;
          this.audForm.get('audname').patchValue(this.aud.audience_name);
          this.audForm.get('auddesc').patchValue(this.aud.audience_desc);
        } else {
          this.toastrService.error(data.message);
          this.router.navigateByUrl('/corporate/bu-audience');
        }
        this.ngxSpinnerService.hide();

      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/bu-audience');
      });
    }
  }

  get f() { return this.audForm.controls; }

  //  ##############  Add New Audience for BU  ###############  //

  addNewBUAudience(){
    this.submitted = true;
    if (this.audForm.invalid) {
      return;
    }
    let audnm = this.audForm.value.audname.trim();
    if (audnm === '' || audnm === null || audnm === undefined){
      this.toastrService.error('Please provide a valid Audience Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.buid,
      'audience_name': this.audForm.value.audname.trim(),
      'audience_desc': this.audForm.value.auddesc.trim(),
      'status': true
    };

    this.segmentService.addNewBUAudience(obj).subscribe(data => {
      if (data.status){
        this.toastrService.success('Audience added successfully');
        this.router.navigateByUrl('/corporate/bu-audience');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #############  Update Audience for BU    ##############  //

  updateBUAudience(){
    this.submitted = true;
    if (this.audForm.invalid) {
      return;
    }
    let audnm = this.audForm.value.audname.trim();
    if (audnm === '' || audnm === null || audnm === undefined){
      this.toastrService.error('Please provide a valid Audience Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.buid,
      'audience_name': this.audForm.value.audname.trim(),
      'audience_desc': this.audForm.value.auddesc.trim(),
      'status': true
    };

    this.segmentService.updateBUAudienceObject(obj, this.audId).subscribe(data => {
      if (data.status){
        this.toastrService.success('Audience updated successfully');
        this.router.navigateByUrl('/corporate/bu-audience');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }
}
