import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "../../base.component";
import { MasterService } from "src/app/services/master/master.service";
import { ClientService } from "src/app/services/clients/client.service";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";

@Component({
  selector: "app-contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.component.css"],
})
export class ContactListComponent extends BaseComponent implements OnInit {
  @ViewChild("searchText", { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany: any = "";
  currentUser;
  getCompanyobj: any = [];
  contactObj: any = [];
  resCusTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  constructor(
    private clientService: ClientService,
    private masterService: MasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    if (this.currentUser.admin_customer_id != "0") {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getcontactList();
    }
    if (this.currentUser.admin_customer_id == "0") this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
        this.ngxSpinnerService.hide();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getcontactList() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService
        .fetchContactList(
          this.selectedCompany,
          this.slCatLimit,
          this.slCatSkip,
          searchTerm
        )
        .subscribe(
          (data) => {
            this.ngxSpinnerService.hide();
            if (data.status) {
              if (data.data.rows == null) {
                this.contactObj = [];
              } else {
                this.contactObj = data.data.rows;
                console.log(this.contactObj);
              }
              this.resCusTotal = data.data.total;
            } else {
              this.contactObj = [];
              this.resCusTotal = 0;
            }
          },
          (error) => {
            console.log(error);
            this.ngxSpinnerService.hide();
          }
        );
    } else {
      this.contactObj = [];
      this.selectedCompany = "";
      this.resCusTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = "";
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap((data) =>
          this.masterService.fetchContactList(
            this.selectedCompany,
            this.slCatLimit,
            this.slCatSkip,
            this.searchText.nativeElement.value
          )
        )
      )
      .subscribe(
        (res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.contactObj = [];
            } else {
              this.contactObj = res.data.rows;
            }
            this.resCusTotal = res.data.total;
          } else {
            this.contactObj = [];
            this.resCusTotal = 0;
          }
          this.ngxSpinnerService.hide();
        },
        (error) => {
          this.contactObj = [];
          this.resCusTotal = 0;
          this.ngxSpinnerService.hide();
        }
      );
  }
  gotodetail(id:any) {

    this.router.navigate(['/corporate/contactus-view'], { queryParams: { companyID: this.selectedCompany, enq_id: id } });
  }
}
