<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/rolesmaster">Roles Master</a></li>
            <li class="breadcrumb-item active">{{header}} Role Master</li>
        </ol>
    </nav>
</div>


<div class="container mt-2">
    <form class="form-horizontal mt-1" [formGroup]="roleForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
        <div class="card">
            <div class="card-body">
                <div>
                    <label class="forcolor">Parent Role Name</label>
                    <select type="text" formControlName="roleParentId" class="form-control formstyle">
                        <option value="0">Root</option>
                        <option *ngFor="let obj of getRoleObj" value={{obj.role_id}}>
                            {{obj.role_name}}
                        </option>
                    </select>                 
                </div> 
                <div class="mt-1">
                    <label class="mt-1">Role Name <span style="color:red">*</span></label>
                    <input type="text" formControlName="roleName" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.roleName.errors }" required maxlength="30" appSpace>
                    <div *ngIf="submitted && f.roleName.errors" class="invalid-feedback" >
                        <div *ngIf="f.roleName.errors.required || f.roleName.hasError('whitespace')">Role Name is required</div>
                        <div *ngIf="f.roleName.errors.pattern">Role Name is not a valid format</div>
                        <div *ngIf="f.roleName.errors?.maxlength">You can enter only 30 charachters</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label class="mt-1">Role Description</label>
                    <input type="text" formControlName="roleDescription" min="1" maxlength="499" class="form-control form-control-md">
                </div>

                <label class="mt-1">Is Active</label>
                <div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isactive"
                            [value]="true">
                        <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isactive"
                            [value]="false">
                        <label class="custom-control-label" for="customRadio2">In Active</label>
                    </div>
                </div>

                
            </div>
            <div class="card-footer">
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn" *ngIf="isWrite" (click)="addRole()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateRole()">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
    </form>
</div>