<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Premium Services Summary</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row" >    
    
    <div class="col-xl-3 col-md-4 mt-2" *ngIf="isHeadquater">    
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="gettingReports(1,$event.target.value)">
        <option selected="selected" value=0>Select Company</option>
         <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} >
              {{userObj.customer_company_name}}
          </option>
      </select>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
      [(ngModel)]="start_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
      [(ngModel)]="end_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="col-xl-4 col-md-5 mt-2">
      <button class="btn btn-margo-action  formstyle mt-md-1 ml-0"  style="align-items: center;" (click)="gettingReports(1,companyID)">Search</button> 
    
      <button class="btn btn-margo-action ml-0 formstyle gp-mr-5" (click)="resetReport()" style="align-items: center;">Reset</button> 
      <!-- <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a> -->
    </div>
</div>

  
<div class="tblPrt" *ngIf="showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">    
      <tr>
        <th> Replenishment</th>
        <th> Cross-sell</th>
        <th> Campaigns </th>
        <th>Messages </th>
        <th> Messages(email, sms, mms) </th>
        <th> Messages Clicked  </th>
        <th> Total Amount </th>
        <th> Total Purchases </th>
        <th> Total Product purchased in dollars</th>
         </tr>
        <tr *ngIf="companyID != 0">
          <td>{{!! total_rep?total_rep:'0'}}</td>
          <td>{{!! total_crs?total_crs:'0'}}</td>
          <td>{{total_campaign?total_campaign:'0' }}</td>
          <td>{{!! total_msg?total_msg:'0'}}</td>
          <td>{{!! total_email?total_email:'0'}}</td>
          <td>{{!! total_clicked?total_clicked:'0'}}</td>
          <td>{{!! total_amount?total_amount:'0'}}</td>
          <td>{{!! total_purchase?total_purchase:'0'}}</td>
          <td>{{!! total_product_doller?total_product_doller:'0'}}</td>          
        </tr>
        <tr *ngIf="companyID == 0">
          <td colspan="9" style= "text-align: center">No results available</td>                            
        </tr>  
  </table>
</div>
<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <td colspan="5">No results available</td>                            
    </tr>  
  </table>
</div>

