<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/mb">Social SMS</a></li>

      <li class="breadcrumb-item"><a routerLink="/corporate/templatemanagement">SMS  Library</a></li>

      <li *ngIf="post_id" class="breadcrumb-item active">Edit Template SMS</li>
      <li *ngIf="!post_id" class="breadcrumb-item active">Add Template SMS</li>
    </ol>
  </nav>
</div>


<div class="container mt-1 mt-md-2">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id=='0'">
              <label for="exampleForm2">Company Name</label>

              <select formControlName="selectedCompany" *ngIf="post_id!= undefined" disabled="controlEnabled"
                class="form-control formstyle" (change)="getCategory($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <select formControlName="selectedCompany" *ngIf="post_id == undefined" class="form-control formstyle"
                (change)="getCategory($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">


            <div class="form-group">
              <label for="exampleForm2">Category Name <span class="required">*</span></label>
              <select formControlName="categoryid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.categoryid.errors }">
                <option value="">Select Category</option>
                <option *ngFor="let Obj of postCategoryObj" value={{Obj.category_id}}>
                  {{Obj.category_name}}
                </option>
              </select>
              <div *ngIf="submitted && f.categoryid.errors" class="invalid-feedback">
                <div *ngIf="f.categoryid.errors.required">Category Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Select Business Domain</label>
              <select formControlName="domain"  class="form-control formstyle">
              <option value='0'>Select Domain</option>
              <ng-container *ngFor="let userObj of domainData" >
                  <option *ngIf="userObj.is_active == true" value={{userObj.id}}>
           {{userObj.domain_name}}
              </option>
              </ng-container>
          </select>
          </div>
            <div class="form-group">
              <label for="exampleForm2">Message Type <span class="required">*</span></label>
              <select formControlName="mtype" class="form-control formstyle"
                  [ngClass]="{ 'is-invalid': submitted && f.mtype.errors }">
                  <option value="">Select Message Type</option>
                  <ng-container>
                    <option value="sms">SMS</option>
                    <option value="mms">MMS</option>
                    <option value="whatsapp">Whatsapp</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>

                  </ng-container>
              </select>
              <div *ngIf="submitted && f.mtype.errors" class="invalid-feedback">
                <div *ngIf="f.mtype.errors.required">Message Type is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
            <div class="form-group">
              <label for="exampleForm2">Post Name <span class="required">*</span></label>
              <input type="text" class="form-control" formControlName="name" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="100" appSpace>
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Post Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Description <span class="required">*</span> </label>

              <textarea maxlength="300" class="form-control text-area h-25" rows="5" id="description" formControlName="description"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }" name="description" appSpace></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required || f.description.hasError('whitespace')">Description is
                  required</div>
              </div>
            </div>


            <div class="form-group">
              <label for="sel1">Personalization Fields:</label>
              <select class="form-control" id="sel1" name="sellist1" (change)="selectedOption($event.target.value)">
                <option>Select Personalization</option>
                <option value="full name">Your full name</option>
                <option value="signature">Your signature</option>
                <option value="title">Your title</option>
                <option value="seller_firstname">First Name</option>
                <option value="seller_lastname">Last Name</option>
                <option value="business_name">Business Name</option>
                <option value="shopping_url">Personal shopping site url</option>
                <!-- <option value="product_link">Product Link</option> -->

              </select>
            </div>

            <div class="form-group" *ngIf="productData.length>0 || productURL">
              <label for="productName">Product Name: </label>
              <select class="form-control" id="productName" name="productName" formControlName="product_url"
                (change)="selectedProduct($event.target.value)">
                <option value="">Select Product</option>
                <option *ngFor="let product of productData" value={{product.product_link}}>
                  {{product.product_name}} - {{product.product_category}}
                </option>



              </select>
            </div>



            <div class="form-group">
              <label for="exampleForm2">URL</label>
              <input type="text" class="form-control" formControlName="url" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.url.errors }" maxlength="100">
              <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                <div *ngIf="f.url.errors.pattern">Please enter valid URL</div>
              </div>
            </div>

            <div class="form-group">

              <label for="exampleForm2">Post Image</label>


              <div *ngIf="!screenshot_type && imageSrc!=null ">
                <label for="exampleForm2">{{isImage ?'Image':'Video'}} </label>
                <div *ngIf="isImage" [ngClass]="status ? 'displaynone' :'displayblock' ">

                  <img src="{{imageSrc}}" alt="Image" style="height:150px;" (error)="setValue($event)">
                </div>
                <div *ngIf="!isImage" [ngClass]="status ? 'displaynone' :'displayblock' ">
                  <video width="100%" controls [src]="imageSrc" (error)="setValue($event)">
                  </video>
                </div>

              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio1" formControlName="firstActive"
                    [value]="0" (change)='radioChange()'>
                  <label class="custom-control-label" for="customRadio1">Image URL</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio2" formControlName="firstActive"
                    [value]="1" (change)='radioChange()'>
                  <label class="custom-control-label" for="customRadio2">Upload a file</label>
                </div>
              </div>
              <div *ngIf="screenshot_type" class="mt-1">

                <input type="text" formControlName="post_screenshot" class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png, .gif)">

              </div>
              <div *ngIf="!screenshot_type" class="mt-1">
                <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event,imageInput)">
                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
              </div>
            </div>


          </div>
          <div class="card-footer">
            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn" (click)="addNewPostData()">Submit</button>
            </div>
            <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
              <button type="button" class="custbtn" (click)="updatePost()">Update</button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>

</div>
