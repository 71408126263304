import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';


@Component({
  selector: 'app-segmenthome',
  templateUrl: './segmentation-home.component.html',
  styleUrls: ['./segmentation-home.component.css']
})
export class SegmentationHomeComponent extends BaseComponent implements OnInit{

  id: any;
  constructor() {
    super();
  }
  ngOnInit() {
    let sid = JSON.parse(localStorage.getItem('userData'));
    this.id = sid.admin_customer_id;
  }

  //  ############# Go to BU Selection or BU Segmentation Landing page #############  //

  goForBUSegmentation(){
    if (parseInt(this.id, 10)){
      if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')){
        this.router.navigateByUrl('/corporate/bu-segmentation');
      } else {
        this.router.navigateByUrl('/corporate/selectbu-segmentation');
      }

    } else {
      if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
        this.router.navigateByUrl('/corporate/bu-segmentation');
      } else {
        this.router.navigateByUrl('/corporate/selectbu-segmentation');
      }
    }

  }
}
