import { Component, OnInit, PipeTransform } from '@angular/core';
import { RegisterService } from '../../../services/users/register.service';
import { ToastrService } from 'ngx-toastr'
import { MasterService } from '../../../services/master/master.service';
import { ViewLogsDialogService } from '../../../_utils/view-logs-dialog/view-logs-dialog.service';
import { SupportService } from 'src/app/services/support/support.service'
import { ClientService } from 'src/app/services/clients/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logs',
  templateUrl: './dslogs.component.html',
  styleUrls: ['./dslogs.component.css']
})
export class DsLogsComponent implements OnInit {
  adminView = [];
  adminCrud = [];
  directSellerViewLog = [];
  directSellerCrudLog = [];
  loginLog = [];
  tempadminCrud = [];
  tempadminView = [];
  directSellerSupportCrudLog = [];
  getCompanyobj;

  table_name;
  company_id;
  company_name;
  supportuser_name;
  directSellerId;
  customerId;
  dsInformation;

  vieword = "desc";
  viewColname = "lid";
  viewPage = 1;
  viewLimit = 10;
  viewSkip = 0;
  totaldsviewlog : number = 0;

  curdord = "desc";
  curdColname = "lid";
  curdPage = 1;
  curdLimit = 10;
  curdSkip = 0;
  totaldscrudlog : number = 0;

  loginord = "desc";
  loginColname = "lid";
  loginPage = 1;
  loginLimit = 10;
  loginSkip = 0;
  total : number = 0;

  constructor(
    public registerService: RegisterService,
    private ViewLogsDialogService: ViewLogsDialogService,
    private clientService: ClientService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {
    this.getCompany();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.directSellerId = params.ds_id;
      this.customerId = params.customer_id;
    });

    var data = JSON.parse(sessionStorage.getItem("userData"));
    this.company_id = data.admin_customer_id;

    this.table_name = 'admin_view_logs_tbl';
    console.log("sssssssss......", this.directSellerId)

    if (this.directSellerId) {
      this.dsviewLogs();
      this.getDsInformation();
      this.dscrudLogs();
      this.loginLogsDs();
    }

  }

  refresh_logs() {
    let self = this;
    this.tempadminView = []
    //= this.adminView;

    // this.adminView.forEach(function (context) {

    //   // console.log(context);
    //   let temp = {
    //     createdon: context.get_admin_view_logs_fun.createdon,
    //     data: JSON.stringify(context.get_admin_view_logs_fun)
    //   };
    //   self.tempadminView.push(temp);
    // })

    // this.adminCrud.forEach(function (context) {
    //   // console.log(context);
    //   let temp = {
    //     createdon: context.get_admin_cud_logs_fun.createdon,
    //     data: JSON.stringify(context.get_admin_cud_logs_fun)
    //   };
    //   self.tempadminCrud.push(temp);
    // })

    //this.tempadminCrud = this.adminCrud;
  }

  logsShows(viewObj) {
    // console.log(viewObj);
    this.ViewLogsDialogService.confirm(viewObj, 'View', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        //   console.log(confirmed);
      }, (err) => {
        //  console.log(err);
      })
  }

  changeTable(name) {
    this.table_name = name;
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }


  loginLogsDs() {
    this.loginSkip = (this.loginPage - 1) * this.loginLimit;
    this.spinner.show();
    this.registerService.getDsLoginLogs(this.directSellerId, this.loginLimit, this.loginSkip, this.loginColname, this.loginord).subscribe(data => {
      this.loginLog = data.data.rows;
      this.total = data.data.total;
      this.spinner.hide();
    }, error => {
      //  console.log(error);
      this.spinner.hide();
    })
  }

  getDsInformation() {
    this.spinner.show();
    this.registerService.getDSInformationById(this.customerId, this.directSellerId).subscribe(data => {
      this.dsInformation = data.data;
      this.spinner.hide();
    }, error => {
      // console.log(error);
      this.spinner.hide();
    })
  }

  dsviewLogs() {
    this.viewSkip = (this.viewPage - 1) * this.viewLimit;
    this.spinner.show();
    this.registerService.getDirectSellerViewLogsById(this.customerId, this.directSellerId, this.viewLimit, this.viewSkip, this.viewColname, this.vieword).subscribe(data => {
      this.directSellerViewLog = data.data.rows
      this.totaldsviewlog = data.data.total;
      this.spinner.hide();
    }, error => {
      //  console.log(error);
      this.spinner.hide();
    })
  }

  dscrudLogs() {
    this.curdSkip = (this.curdPage - 1) * this.curdLimit;
    this.spinner.show();
    this.registerService.getCrudDsLogs(this.customerId, this.directSellerId, this.curdLimit, this.curdSkip, this.curdColname, this.curdord).subscribe(data => {
      // console.log('getCrudLogs', data);
      this.adminCrud = data.data.rows;
      this.totaldscrudlog = data.data.total;
      this.spinner.hide();
    }, error => {
      // console.log(error);
      this.spinner.hide();
    })
  }

  adminSorting(col) {
    if (this.table_name == 'admin_view_logs_tbl') {
      this.viewColname = col;
      if (this.vieword == "asc") {
        this.vieword = "desc";
      } else { this.vieword = "asc" }
      this.dsviewLogs();

    } else if (this.table_name == 'admin_cud_logs_tbl') {
      this.curdColname = col;
      if (this.curdord == "asc") {
        this.curdord = "desc";
      } else { this.curdord = "asc" }
      //this.dscrudLogs();

    } else if (this.table_name == 'admin_login_logs_tbl') {
      this.loginColname = col;
      if (this.loginord == "asc") {
        this.loginord = "desc";
      } else { this.loginord = "asc" }
      // this.loginLogsDs();
    }
  }
}


