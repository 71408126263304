<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/cb-section">Content Builder Section</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Content Builder Section</li>

        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="templateForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
    
              <div class="card mt-1">
    
                <div class="card-body">
                <div class="form-group">
                    <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                    <select formControlName="company" *ngIf="headers == 'Add'"   class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                        <option value="">Select Company Name</option>
                        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                            {{userObj.customer_company_name}}
                        </option>
                    </select>
                    <select formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled"class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <option value="">Select Company Name</option>
                    <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                </select>
                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                        <div *ngIf="f.company.errors.required">Company Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleForm2">Section Name <span style="color:red">*</span></label>
                    <input type="text" formControlName="section_name"  min="1" maxlength="199" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.section_name.errors }" required appSpace>
                    <div *ngIf="submitted && f.section_name.errors" class="invalid-feedback">
                        <div *ngIf="f.section_name.errors.required">Section Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleForm2">Section Description</label>
                    <textarea  class="form-control"  min="1" maxlength="199" formControlName="section_description" appSpace></textarea>
            
                    
                </div>

               
                
            </div>

            <div class="card-footer text-center">
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="submit" class="custbtn" *ngIf="isWrite" (click)="addSection()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateSection()">Update</button>
                </div>
              </div>
        </div>
    </div>
</div>
    </form>
</div>