<div class="container">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="breadCust">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/corporate/schedule-journey-list">Schedule Journey
                            List</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Add New Schedule Journey</li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="addJourneyForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">

                <div class="card mt-1">

                    <div class="card-body">
                        <div class="form-group">
                            <label for="exampleForm2">Select Company Name<span style="color:red">*</span></label>
                            <select formControlName="company"  class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }" [(ngModel)]="companyID"
                                (change)="getCompanyJourney($event.target.value)">
                                <option value="">------Select Company Name------</option>
                                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </select>
                            
                            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                <div *ngIf="f.company.errors.required">Company Name is required</div>
                            </div>
                        </div>

                         <div class="form-group">
                            <label for="exampleForm2">Journey Type <span style="color:red">*</span></label>

                            <select formControlName="journey_type_id" name="journey_type_id"
                                class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.journey_type_id.errors }"
                                (change)="getCompanyJourneyList();getCompanyJourneyThemeList();">
                                <option value="">------Select Journey Type------</option>
                                <option *ngFor="let item of journeyTypeObj" value={{item.journey_id}}>
                                    {{item.journey_name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.journey_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.journey_type_id.errors.required">Journey Type is required</div>
                            </div>
                        </div>
                         
                        <div class="form-group">
                            <label for="exampleForm2">Journey Name <span style="color:red">*</span></label>

                            <select formControlName="journey_name" name="journey_name"
                                class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.journey_name.errors }">
                                <option value="">------Select Journey------</option>
                                <option *ngFor="let item of journeyList" value={{item.journeyid}}>
                                    {{item.journey_name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.journey_name.errors" class="invalid-feedback">
                                <div *ngIf="f.journey_name.errors.required">Journey is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleForm2">Theme<span style="color:red">*</span></label>

                            <select formControlName="theme_id" name="theme_id"
                                class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.theme_id.errors }">
                                <option value="">------Select Theme------</option>
                                <option *ngFor="let item of themeObj" value={{item.theme_id}}>
                                    {{item?.theme_name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.theme_id.errors" class="invalid-feedback">
                                <div *ngIf="f.theme_id.errors.required">Theme is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleForm2">Schedule Type <span style="color:red">*</span></label>

                            <p [ngClass]="{ 'is-invalid': submitted && f.scheduleType.errors }">
                                <input type="radio" value="true" formControlName="scheduleType"> Automatic
                                <input type="radio" value="false" formControlName="scheduleType" class="ml-2"> Manual
                              </p>
                              <div *ngIf="submitted && f.scheduleType.errors" class="invalid-feedback">
                                <div *ngIf="f.scheduleType.errors.required">Schedule Type is required</div>
                              </div>
                        </div>
                        
                        <!-- <div class="form-group" *ngIf="addJourneyForm.value.scheduleType === 'false'">
                            <label for="exampleForm2">Schedule Details <span style="color:red">*</span></label>

                            <div class="col-md-3">
                                <label>Schedule Date <span style="color:red">*</span></label>
                              </div>
                              <div class="col-md-9">
                                <div class="row">
                    
                    
                              <div class="col-lg-4 col-md-4 col-12 col-sm-3 schedCal">
                    
                    
                                <input class="form-control" matInput [min]="minDate" [matDatepicker]="picker2" readonly
                                       formControlName="scheduleStartDate"
                                       [ngClass]="{ 'is-invalid': submitted && f.scheduleStartDate.errors }">
                                <mat-datepicker-toggle matSuffix [for]="picker2" class="cldrIcon"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <div *ngIf="submitted && f.scheduleStartDate.errors" class="invalid-feedback">
                                  <div *ngIf="f.scheduleStartDate.errors.required">Start Date is required</div>
                                </div>
                              </div>
                    
                              <div class="widhtFd-150 ng-star-inserted d-flex justify-content-center atwtimeclass" >
                                <p class="atWTime">at</p>
                              </div>
                    
                                  <div class="col-lg-6 col-12 col-sm-5 scheduleTm d-flex justify-content-center">
                    
                                    <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="stTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
                                  </div>
                    
                            </div>
                          </div>
                        </div> -->
                        
                        <div class="form-group" >
                            <label for="exampleForm2">Interval Details <span style="color:red">*</span></label>
                            <input type="number" formControlName="interval" min="1" maxlength="50000"
                                class="form-control form-control-md"
                                [ngClass]="{ 'is-invalid': submitted && f.interval.errors }" required appSpace>
                            <div *ngIf="submitted && f.interval.errors" class="invalid-feedback">
                                <div *ngIf="f.interval.errors.required">Interval  is required</div>
                                <div *ngIf="f.interval.errors.min">Minimum Interval value is 1</div>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label for="exampleForm2">Interval Type <span style="color:red">*</span></label>
                        
                            <select formControlName="interval_type" name="interval_type" class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.interval_type.errors }">
                                <option value="">------Select Interval Type------</option>
                                <option value="minute">Minutes</option>
                                <option value="hour">Hours</option>
                                <option value="day">Days</option>
                            </select>
                            <div *ngIf="submitted && f.interval_type.errors" class="invalid-feedback">
                                <div *ngIf="f.interval_type.errors.required">Interval Type is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer text-center">
                        <div class="my_class text-center" >
                            <button type="submit" class="custbtn"  (click)="addJourney()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>