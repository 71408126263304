<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<!-- <ngx-spinner  bdOpacity = 0.9
bdColor = "#333"
size = "large"
color = "#fff"
type = "ball-clip-rotate">
<p style="color: white" > Loading... </p>
</ngx-spinner> -->

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
    <li class="breadcrumb-item active">Module Items</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>

  </div>
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/addnewmoduleitems">
    <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;"> 
    <tr>
      <!-- <th>Theme Id</th> -->
      <th (click)="chgord('x')" class="curPoint">Module Item Name <i class=" fa fa-sort text-black"
          aria-hidden="true"></i></th>
      <th>Module Item Description </th>
      <th>Module Name</th>
      <th>Created By</th>
      <th (click)="chgord('x')" class="curPoint"> Created On <i class=" fa fa-sort text-black"
          aria-hidden="true"></i></th>
      
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>

    <tr *ngFor="let obj of moduleObj | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">
      <!-- <td>{{obj.get_master_module_item_fun.moduleitemid}}</td> -->
      <td>{{obj.get_master_module_item_fun.module_item_name}}</td>
      <td>{{obj.get_master_module_item_fun.module_item_description}}</td>
      <!-- <td>{{obj.get_master_module_item_fun.theme_screenshot}}</td> -->
      <td>{{obj.get_master_module_item_fun.modulename }}</td>
      <td>{{obj.get_master_module_item_fun.createdname}}</td>
      <td>{{obj.get_master_module_item_fun.createdon | date:'mediumDate' }}</td>
      
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
        <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editModuleItem(obj.get_master_module_item_fun.moduleitemid)"  placement="bottom" ngbTooltip="Edit"></i>
        <span class="fa fa-trash mr-1 cursor"
          (click)="deleteModuleItemData(obj.get_master_module_item_fun.moduleitemid)" placement="bottom" *ngIf="isDelete" ngbTooltip="Delete"></span>
        <mat-slide-toggle [checked]="obj.get_master_module_item_fun.isactive" class="my-slider cursor" *ngIf="isEdit"
          (click)="updateStatus($event,obj.get_master_module_item_fun.moduleitemid)">
        </mat-slide-toggle>
      </div>
      </td>
    </tr>


</table>
<pagination-controls class="my-pagination mt-2" (pageChange)="cp=$event"></pagination-controls>
</div>

</div>