<!-- Breadcrumb for application -->
<!-- <div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/helps">Helps & Tips</a></li>
      <li class="breadcrumb-item active" aria-current="page">Knowledge Base</li>
    </ol>
  </nav>
</div> -->
<!-- Master Container 1 -->
<div class="container mt-1">
  <div class="row" *ngIf = "showArticlesList">
    <div class="col-md-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mb-1 mt-1 mt-md-0">
            <h4 class="font-weight-bold">Search the Knowledge Base</h4>
          </div>
        </div>
      </div>
      <fieldset class="scheduler-border">
        <legend class="scheduler-border">
          <h5 class="font-weight-bold">Search</h5>
        </legend>
        <div class="card-content mt-1">
          <section class="srchMain">
            <div class="srcInpt">
              <input  type="text"  placeholder="Search Article..." [(ngModel)]="queryArticleString" (keyup)="SearchArticle()" autofocus /> 
            </div>       
          </section>
        </div>
      </fieldset>
      <fieldset class="scheduler-border pb-0 mt-1">
        <legend class="scheduler-border">
          <h5 class="font-weight-bold">{{resultHeaderText}}</h5>
        </legend>
        <div class="row mt-1 article-list-container">
          <article class="tipsArt" *ngFor="let article of knowledgeArticles" (click)="getKnowledgeArticleById($event, article.id || article.KnowledgeArticleId)">
            <h4 class="text-primary font-weight-bold"><a>{{article.title || article.Title}}</a></h4>
            <!-- <p style="margin-left: 25px;" innerHTML="{{article.summary==null ? 'Summary not available for the article' : article.summary }}"></p> -->
            <!-- <p [innerHtml]="article.summary==null ? 'Summary not available for the article' : article.summary | safeHTMLtags: 'html'"></p> -->
            <p>{{(article.summary || article.Summary)==null ? 'Summary not available for the article' : article.summary || article.Summary}}</p> 
            
          </article>
          <article *ngIf="articleFound" class="tipsArt">
            <p style="text-align: center;">No Result Available</p> 
          </article>
        </div>
      </fieldset>
    </div>
    <div class="col-md-7 mt-3">
      <fieldset class="scheduler-border">
        <legend class="scheduler-border">
          <h5 class="font-weight-bold">Recent Articles</h5>
        </legend>
        <div class="mt-1">
          <a *ngFor="let article of recentknowledgeArticles" class="artist-name" (click)="getKnowledgeArticleById($event, article.id)" >
            {{article.title}}<br /></a>
        </div>
        <!-- <div class="row mt-1" style="overflow-y: scroll; max-height: 400px;">
          <article-detail [articleDetail]=knowledgeArticleLayoutDetail></article-detail>
        </div> -->
      </fieldset>
    </div>
  </div>
  <div class="row" *ngIf = "showArticleDetail">
  <div class="col-md-10">
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">
        <h5 class="font-weight-bold">Article Detail</h5>
      </legend>
      <!-- <div class="card"> -->
        <!-- <div class="card-content"> -->
          <div class="card-header pb-0" style="background-color:inherit;">
            <div class="row">
              <div class="col-12">
                <!-- <h4 class="float-left">Edit My Profile</h4> -->
                <span class="backBtnPro float-right">
                  <a (click)="showArticleList()"><i class="fa fa-arrow-left"  aria-hidden="true"></i> Back</a>
                </span>
              </div>
            </div>
          </div>
        <!-- </div>
      </div> -->
      <div class="mt-1">
        <!-- <a href="#" *ngFor="let article of knowledgeArticles" class="artist-name" [ngClass]="{'active': selectedArticle == article.id}" (click)="getKnowledgeArticleById($event, article.id)" >
          {{article.title}}<br /></a> -->
      </div>
      <div class="article-detail-container">
        <article-detail [articleDetail]=knowledgeArticleLayoutDetail></article-detail>
      </div>
    </fieldset>
  </div>
  </div>
</div>