<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Client Subscription</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>


    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1" routerLink="/corporate/newsubscription">
      <!-- <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button> -->
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0" style="width: 100%;">


      <tr>
        <!-- <th>Theme Id</th> -->
        <th (click)="sorting('name')">Title <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th style="cursor: pointer;">Image </th>
        <th>Description </th>
        <th (click)="sorting('customer_company_name')">Company Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <!-- <th>Created By</th> -->
        <th (click)="sorting('createdon')" style="cursor: pointer;"> CreatedOn <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr
        *ngFor="let obj of subscriptionObj">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->

        <td>{{obj.name}}</td>
        <td> 
          <a *ngIf="obj.imageType == 1" href="{{obj.attachment}}">
            <img src="assets/images/doc_image.jpg" style="height: 50px" />
          </a>
          <img class="img-fluid" style="height: 50px" *ngIf="obj.imageType == 0"
            src="{{obj.attachment}}" (error)="setValue($event)">
        </td>
        <td>{{obj.description}}</td>
        <td>{{obj.customer_company_name}}</td>
        <!-- <td>{{obj.createdname}}</td> -->
        <td>{{obj.createdon | date:'mediumDate' }}</td>

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editSubscription(obj.subscription_id)" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteSubscription(obj.subscription_id)"
              placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.subscription_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <div *ngIf="subscriptionObj.length < 1">
      <p>No Result Available</p>
    </div>
    <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getSubscription((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>

  </div>
</div>