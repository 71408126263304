<div class="container">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
          <li class="breadcrumb-item">
            <a routerLink="/corporate/bu-segmentation">BU Segmentation Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            BU Audience Update
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-3">
      <div  style="justify-content: right">
        <input
          type="button"
          value="{{buselected}}"
          *ngIf="isWrite"
          class="custbtn red-lt-icon ml-0 mb-1"
          routerLink="/corporate/selectbu-segmentation"
        />

      </div>
    </div>

  </div>

</div>


<div class="container">
  <form class="form-horizontal mt-1">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">


            <div class="form-group" *ngIf="show">
              <label for="audList">Active Audience List</label>
              <div class="piInnerList scroller" id="audList">
                <ul class="filtMain">
                  <li *ngFor="let item of masterAudienceActiveList">
                    <div class="row">
                      <div class="col-9">
                        <label class="form-check-label">
                          {{item.audience_name}}
                        </label>
                      </div>

                      <div class="col-3">
                        <input class="pull-right"  [checked]="audienceIds.indexOf(item.audience_id)>=0"  type="checkbox"  (change)="selectAudience($event, item.audience_id)">
                      </div>

                    </div>

                  </li>
                </ul>
              </div>

            </div>
            <p *ngIf="!show">No Result Available</p>
          </div>
          <div class="card-footer text-center ">
            <div class="my_class text-center">
              <button type="submit" class="custbtn" *ngIf="isWrite" (click)="updateAudience()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
