import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { FbService } from 'src/app/services/fbservice/fb.service';
import { OrderPipe } from 'ngx-order-pipe';
import { MasterService } from 'src/app/services/master/master.service';

import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-postmanagement',
  templateUrl: './postmanagement.component.html',
  styleUrls: ['./postmanagement.component.css']
})
export class PostmanagementComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  today: number = Date.now();
  public postObj: any = [];
  getCompanyobj: any = [];
  currentUser;
  companyID = 0;
  selectedCompany: any = '';

  fbord = "asc";
  fbColname = "post_name";
  fbPage = 1;
  fbLimit = 10;
  fbSkip = 0;
  fbTotal: number = 0;
  domainData: any[]=[];
  domain_id: any=0;
  constructor(
    private fb: FbService,
    private master: MasterService,
    private clientService: ClientService,
  ) { super(); }

  ngOnInit() {
    localStorage.removeItem("edit_post_id");
    localStorage.removeItem("post_edit_company_id");
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;


      this.selectedCompany = this.companyID;
      this.getPostManagement();
      this.getBusinessDomain();
      }
    //this.getPostManagement();


    if (this.currentUser.admin_customer_id == '0') this.getCompany();
    this.PostManagementLogData();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.fbSkip = 0;
          this.fbPage = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data =>
          this.fb.getPostBUData(this.selectedCompany, this.fbLimit, this.fbSkip, this.fbColname, this.fbord, this.searchText.nativeElement.value, true,this.domain_id))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.postObj = [] }
          else { this.postObj = res.data.rows }
          this.fbTotal = res.data.total;
        } else {
          this.postObj = [];
          this.fbTotal = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.postObj = [];
        this.fbTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

  setOrder(value: any) {
    this.fbColname = value;
    if (this.fbord == 'asc') {
      this.fbord = 'desc';
    } else {
      this.fbord = 'asc';
    }
    this.getPostManagement()
  }

  PostManagementLogData() {
    let pmLogs = {
      log_type: "Post Management View",
      log_name: "Post Management View",
      log_description: "Post Management View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(pmLogs).subscribe(data => {
      // console.log(data);
    }, error => {
      //  console.log(error);
    });
  }


  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      }
      else {
        // event.target.src = '../../../assets/custom/images/broken.png';
        event.target.src = '../../../assets/images/icons/video-play.svg';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getPostManagement() {
    let searchTerm = this.searchText.nativeElement.value;
    this.fbSkip = (this.fbPage - 1) * this.fbLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();
      this.fb.getPostBUData(this.selectedCompany, this.fbLimit, this.fbSkip, this.fbColname, this.fbord, searchTerm, true,this.domain_id).subscribe(data => {
        if (data.status) {

          if (data.data.rows == null) { this.postObj = [] }
          else {
            this.postObj = data.data.rows;


          }
          this.fbTotal = data.data.total;
          this.ngxSpinnerService.hide();

        } else {
          this.postObj = [];
          this.fbTotal = 0;
        }
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    } else {
      this.postObj = [];
      this.fbTotal = 0;
      this.selectedCompany = "";
      this.ngxSpinnerService.hide();
    }
  }

  editPost(id) {
    localStorage.setItem("edit_post_id", id);
    localStorage.setItem("post_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/addnewpost']);
  }

  deletePost(id) {
    let obj = {
      id: id,
      company: this.selectedCompany
    }
    this.confirmationDialogService.confirm(CommonMessages.confirmationTitle, 'Are you sure you want to Delete?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.fb.delPost(obj).subscribe(data => {
            if (data.status) {
              this.getPostManagement();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
              this.ngxSpinnerService.hide();
            } else {
              this.ngxSpinnerService.hide();
            }
          }, error => {
            this.toastrService.error(error.message);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.postObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.post_id === id) {
        return true;
      }
    });
    obj = obj;
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' In Active' : ' Active'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.selectedCompany;
          this.fb.updatePost(obj).subscribe(data => {
            this.toastrService.success("Status Updated Successfully")
            this.getPostManagement();
          });
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      }).catch(() => {
        //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
      //  console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.selectedCompany).subscribe(data => {
      this.ngxSpinnerService.hide();
      self.domainData = data.data.rows;
    }, error => {
      this.domainData=[]
      this.ngxSpinnerService.hide();
    });
  }
  getCompanyThemes(id) {
    this.ngxSpinnerService.show();
    if (id != '' && id !== '0') {
      this.fbPage = 1;
      this.fbLimit = 10;
      this.fbSkip = 0;
      this.selectedCompany = id;
      this.domain_id=0
      this.getPostManagement();
      this.getBusinessDomain();
    } else {
      this.selectedCompany = id;
      this.domain_id=0;
      this.domainData=[];
      this.ngxSpinnerService.hide();
      this.getPostManagement();
    }
  }
  getDomainList(id:any){
      this.domain_id = id;
      this.fbPage = 1;
      this.fbLimit = 10;
      this.fbSkip = 0;
      this.getPostManagement();
  }
}
