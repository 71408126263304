import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
@Injectable({
  providedIn: 'root'
})
export class DsuserService {
  public baseUrl;
  public baseUrl1;
  public baseUrl2;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/ds/'
    this.baseUrl1 = baseService.baseUrl1 + 'api/ewallet/'
    this.baseUrl2 = baseService.baseUrl1 + 'api/'
  }
  // /api/ds/getCustomerUserData
  // /api/ds/getCustomerData



  getDsUser(id, limit, offset,colname, colname_val, searchText, status, bundlename, assigneddate) {
    return this.http.get<any>(this.baseUrl + `directSeller/${id}/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}&filter=&isactive=${status}&bundleName=${bundlename}&assignedDate=${assigneddate}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDsUserHost(id, limit, offset,colname, colname_val, searchText, status, bundlename, assigneddate,type) {
    return this.http.get<any>(this.baseUrl + `directSeller/${id}/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}&filter=&isactive=${status}&bundleName=${bundlename}&assignedDate=${assigneddate}&type=${type}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getHostindirectSeller(id, limit, offset,colname, colname_val, searchText, status,dsId) {
    return this.http.get<any>(this.baseUrl + `getHostindirectSeller/${id}/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}&filter=&isactive=${status}&createdby=${dsId}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getChatUsers(id) {
    return this.http.get<any>(this.baseUrl + `getChatUsers/${id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }




  getDsUserSearch(id, status, query,role_id) {
    return this.http.get<any>(this.baseUrl + `directSellersearch/${id}/${status}/${query}?role_id=${role_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDSByBundleName(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getDSByBundleName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDSByBundleDate(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getDSByBundleDate", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }




  deleteTeamAssociation(obj) {
    var body = obj;
    return this.http.post<any>(this.baseUrl + `deleteTeamAssociation`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getAssociatedTeam(id, limit, skip, searchText) {
    return this.http.get<any>(this.baseUrl + `getAssociatedTeam/${id}/${limit}/${skip}?search=${searchText}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getAssociatedTeamById(id) {
    return this.http.get<any>(this.baseUrl + `getassociateDsUser/${id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  createUser(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "directSeller", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getUserdropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "getCustomerUserData", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getCustomerdropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "getCustomerData", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getUserTypedropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "getCustomerData", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getTeamList(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `getCustomerCompanyList/${id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getTeamMemberList(cid, id, editMode) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `getCompanyTeamMemberList/${cid}/${id}/${editMode}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  associateDsUser(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "associateDsUser/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  updateDSUserAssoc(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "updateDSUserAssoc/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  sendActivationLink(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `genrateActivationLinkForDs`, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  specificWalletTransfer(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl1 + `dsWalletTransfer`, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  bulkWalletTransfer(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl1 + `bulkWalletTransfer`, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  activeWalletCompany() {
    return this.http.get<any>(this.baseUrl2 + `activewallet`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEWalletDsUser(id, page) {
    return this.http.get<any>(this.baseUrl1 + `getdsuserlisting/${id}/${page}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEWalletUserSearch(id, query) {
    return this.http.get<any>(this.baseUrl1+ `getdsusersearchlisting/${id}/${query}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getdsExternalInfo(company, dsid, from, to): Observable<any> {
    return this.http.get<any>(this.baseUrl+ `getDsExternalInfo/${company}/${dsid}?from=${from}&to=${to}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  updateDSUserStatus(company, dsid): Observable<any> {
    return this.http.put<any>(this.baseUrl + `updateDirectSellerStatus/${company}/${dsid}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  renewSubscription(company, body): Observable<any> {
    return this.http.put<any>(this.baseUrl2 + `ewallet/update-subscription/${company}`,body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  updateSMSNoForDS(company,value) {
    var body = value;
    return this.http.put<any>(this.baseUrl1 + `update-sender-number/${company}`, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getEWalletDsUserNew(id, page,searchInput:any) {
    return this.http.get<any>(this.baseUrl1 + `getdsuser-listing/${id}/${page}?search=${searchInput}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
// Update Ds email provider 
  updateEmailProviderForDs(body:any,company) {
    return this.http.put<any>(this.baseUrl + `update-directSeller/${company}`,body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
}
