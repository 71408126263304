<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/messagelib">Support Message Library</a></li>          
      <li class="breadcrumb-item active" aria-current="page">Add Message Library</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <form class="form-horizontal mt-1" [formGroup]="AddMessage">
    <div class="rowTheme">
        <div class="themContain">

            <div *ngIf="currentUserID==0">
                <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                <select formControlName="company" *ngIf="help_message_id == undefined"  class="form-control formstyle" [ngModel] ="company"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <option value="">Select Company</option>
                    <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                </select>
                <select formControlName="company" *ngIf="help_message_id != undefined" disabled="controlEnabled" class="form-control formstyle" [ngModel] ="company"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                <option value="">Select Company</option>
                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                    {{userObj.customer_company_name}}
                </option>
            </select>
                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Company Name is required</div>
                </div>
            </div>

            <div *ngIf="currentUserID>0">
              <input type="hidden"  formControlName="company" value="company">
            </div>
        <div>
            <label for="exampleForm2">Message Library Type <span style="color:red">*</span></label>
            <select  formControlName='help_type' class="form-control formstyle"  [ngClass]="{ 'is-invalid': submitted && f.help_type.errors }">
                <option value=""> Select Type</option>
                <option value="1">Support text</option>
                <option value="2">Quick text</option>
            </select>
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.help_type.errors.required">Message Library Type is required</div>
            </div>
        </div>
        <div class="mt-1">
            <label for="exampleForm2">Message Body <span class="required">*</span></label>
                <input type="text" class="form-control" formControlName='message_body' 
                class="form-control form-control-md" required
               [ngClass]="{ 'is-invalid': submitted && f.message_body.errors }" maxlength="255" appSpace>
           <div *ngIf="submitted && f.message_body.errors" class="invalid-feedback">
               <div *ngIf="f.message_body.errors.required || f.message_body.hasError('whitespace')">Message is required</div>
           </div>
        </div>
        
        <div class="my_class" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewMessagelib()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateMessagelib()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</form> 
</div>