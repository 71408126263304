import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-subjectlib',
  templateUrl: './subjectlib.component.html',
  styleUrls: ['./subjectlib.component.css']
})
export class SubjectlibComponent extends BaseComponent implements OnInit {

  public subjectlibObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "subject_name";
  checked = false;
  today: number = Date.now();
  currentUser;
  companyID;
  getCompanyobj;
  body;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  
  constructor(private master: MasterService, private clientService: ClientService,
    private ImportFilesDialogService: ImportFilesDialogService
  ) {
    super();
  }

  ngOnInit() {

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') { this.getSubjectlib(); this.companyID = this.currentUser.admin_customer_id; }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getSubjectlib();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getSubjectlib(this.body, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          this.subjectlibObj = res.data.rows;
          this.totalCount = res.data.total;
        } else {
          this.subjectlibObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  getSubjectlib() {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getSubjectlib(this.body, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      if (response.status) {
        this.subjectlibObj = response.data.rows;
        this.totalCount = response.data.total;
      } else {
         this.subjectlibObj = [];
         this.totalCount = 0;
       }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }
  
  changeSubjectLib(id = "") {
    this.body = {
      company: id || '',
      is_corporate: true
    }
    if (id) this.companyID = id;
    this.getSubjectlib();
  }

  editSubjectlib(id) {
    this.router.navigate(['/corporate/newsubjectlib'], { queryParams: { companyID: this.companyID, help_subject_id: id } });
  }
  deleteSubjectlib(id) {
    let obj = {
      id: id,
      company: this.companyID
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delSubjectlib(obj).subscribe(data => {
            if (data.status) {
              this.changeSubjectLib(this.companyID);
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateSubjectlibStatus(event, id) {
    let value = event.source.checked;
    let obj = this.subjectlibObj.find(element => {
      if (element.help_subject_id === id) {
        return true;
      }
    });
    obj = obj;
    obj.isactive = value;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', CommonMessages.activeInactiveConfirmation + "", 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let subjectlibObj = {
            isactive: obj.isactive,
            help_subject_id: id,
            company: this.companyID
          }
          this.master.updateSubjectlibStatus(subjectlibObj).subscribe(data => {
            if (data.status) {
              this.toastrService.success(CommonMessages.statusUpdated);
              this.ngxSpinnerService.hide();
            }
          }, error => {
            console.error(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => {
        event.source.checked = !event.source.checked;
        // console.log(CommonMessages.dialogDismissMessage)
      });
  }

  importFile() {
    let self = this;
    if (self.companyID != undefined) {
      self.ImportFilesDialogService.confirm1('Please Choose the File Path ', `../../../assets/margo-import-files/margo-help-subject-import.csv`, 'addingSubjectBulk', self.companyID)
        .then(data => {
          //  console.log(data);
          self.changeSubjectLib(self.companyID);
        })
        .catch(err => {
          console.error(err);
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
  exportFile() {
    if (this.companyID != undefined) {
      let tablename = {
        "tableName": "margo_help_subject_tbl",
        "cid": this.companyID,
        "is_corporate": true
      }
      this.master.exportBulk(tablename).subscribe(dataFile => {
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      // console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
}

