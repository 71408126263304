import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../../../../_helpers/app.constants';
import {element} from 'protractor';

@Component({
  selector: 'app-newsegmentation-attribute',
  templateUrl: './new-segmentation-attribute.component.html',
  styleUrls: ['./new-segmentation-attribute.component.css']
})
export class NewSegmentationAttributeComponent extends BaseComponent implements OnInit{

  header = 'Add';
  attrForm: FormGroup;
  attrGroup = [];
  attrType = [];
  tableList = [];
  fields = [];

  attrId;
  attr;
  status = true;
  submitted = false;
  constructor(private segmentService: SegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.attrId = params.attrId;
      });
    this.segmentService.getActiveAttributesGroup().subscribe(data => {
      if (data.status){
        this.attrGroup = data.data;
      } else {
        this.attrGroup = [];
      }
    }, error => {
      this.toastrService.error(error);
    });

    this.segmentService.getActiveAttributesTypes().subscribe(data => {
      if (data.status){
        this.attrType = data.data;
      } else {
        this.attrGroup = [];
      }
    }, error => {
      this.toastrService.error(error);
    });

    this.segmentService.getActiveTablesList().subscribe(data => {
      if (data.status){
        this.tableList = data.data;
        if (this.attrId) {
          this.patchAttributeValues();
        }
      } else {
        this.toastrService.error('Cannot Fetch Tables data');
        this.tableList = [];
        this.router.navigateByUrl('/corporate/segmentation-attribute');
      }
    }, error => {
      this.toastrService.error(error);
    });

  }

  ngOnInit() {
    this.attrForm = this.formBuilder.group({
      attrname: ['', Validators.required],
      attrdesc: [''],
      attrgroup: ['', Validators.required],
      attrtype: ['', Validators.required],
      fieldsize: ['', [Validators.required, Validators.pattern(AppConstants.regex.number)]],
      // fieldtypeoption: [true, Validators.required],
      fieldalias: ['', Validators.required],
      butablename: ['', Validators.required]
    });
  }

  get f() { return this.attrForm.controls; }

  //  ################# Patch Attribute Values for Editing ###   //

  patchAttributeValues(){
    this.header = 'Edit';
    this.ngxSpinnerService.show();
    this.segmentService.getSegmentAttributeById(this.attrId).subscribe(data => {
      if (data.status){
        this.attr = data.data[0];
        let tbl = this.tableList.filter(elem => elem.table_id === this.attr.table_id);
        if (tbl.length === 0){
          this.fields = [];
        } else {
          this.attrForm.get('butablename').patchValue(tbl[0].table_id);
          this.fields = tbl[0].fields_json;
          let fjson = this.fields.findIndex((elem) => elem.column_name === this.attr.field_alias);
          let finalJson = this.fields[fjson];
          this.attrForm.get('fieldalias').patchValue(fjson != -1 ? fjson : '');
          finalJson != undefined ? this.attrForm.get('attrtype').patchValue(finalJson.data_type):'';
          if (finalJson == undefined || finalJson.character_maximum_length === null){
            this.attrForm.get('fieldsize').patchValue(0);
          } else {
            this.attrForm.get('fieldsize').patchValue(finalJson.character_maximum_length);
          }
        }


        this.status = false;
        this.attrForm.get('attrname').patchValue(this.attr.field_name);
        this.attrForm.get('attrdesc').patchValue(this.attr.field_desc);
        this.attrForm.get('attrgroup').patchValue(this.attr.field_group);
        // this.attrForm.get('attrtype').patchValue(this.attr.field_type_id);
        // this.attrForm.get('fieldsize').patchValue(this.attr.field_size);
        // this.attrForm.get('fieldtypeoption').patchValue(this.attr.field_type_option);
        // this.attrForm.get('fieldalias').patchValue(this.attr.field_alias);
        // this.attrForm.get('butablename').patchValue(tbl[0].table_id);
      } else {
        this.router.navigateByUrl('/corporate/segmentation-attribute');
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #################  Add New Attribute ###################  //

  addNewAttribute(){
    this.submitted = true;
    if (this.attrForm.invalid) {
      return;
    }
    let attrnm = this.attrForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Name');
      return;
    }


    let fd = this.fields[this.attrForm.value.fieldalias];
    let tbl = this.tableList.filter(table => table.table_id === parseInt(this.attrForm.value.butablename, 10));

    this.ngxSpinnerService.show();
    let obj = {
      'field_name': this.attrForm.value.attrname.trim(),
      'field_desc': this.attrForm.value.attrdesc.trim(),
      'field_size': fd.character_maximum_length || 0,
      'field_type_option': true,
      'field_group': this.attrForm.value.attrgroup,
      'field_type': fd.data_type,
      'bu_table_name': tbl[0].table_alias,
      'field_alias': fd.column_name,
      'table_id': parseInt(this.attrForm.value.butablename, 10),
      'status': true
    };
    this.segmentService.addAttribute(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Attribute added successfully');
        this.router.navigateByUrl('/corporate/segmentation-attribute');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #################  Update/Edit Attribute  #################  //

  updateAttribute(){
    this.submitted = true;
    if (this.attrForm.invalid) {
      return;
    }
    let attrnm = this.attrForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Name');
      return;
    }

    let fd = this.fields[this.attrForm.value.fieldalias];
    let tbl = this.tableList.filter(table => table.table_id === parseInt(this.attrForm.value.butablename, 10));

    this.ngxSpinnerService.show();
    let obj = {
      'field_name': this.attrForm.value.attrname.trim(),
      'field_desc': this.attrForm.value.attrdesc.trim(),
      'field_size': fd.character_maximum_length || 0,
      'field_type_option': true,
      'field_group': this.attrForm.value.attrgroup,
      'field_type': fd.data_type,
      'bu_table_name': tbl[0].table_alias,
      'field_alias': fd.column_name,
      'table_id': parseInt(this.attrForm.value.butablename, 10),
      'status': true
    };
    this.segmentService.updateSegmentationAttribute(obj, this.attr.field_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Attribute updated successfully');
        this.router.navigateByUrl('/corporate/segmentation-attribute');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ######################  On Company select from Dropdown ##################  //

  selectBUTable(){
    this.fields = [];
    this.attrForm.get('fieldalias').patchValue('');
    this.attrForm.get('attrtype').patchValue('');
    this.attrForm.get('fieldsize').patchValue('');
    if (this.attrForm.value.butablename === '') {
      return;
    }
    let opt = this.tableList.filter(element => element.table_id === parseInt(this.attrForm.value.butablename, 10));
    if (opt.length > 0) {
      this.fields = opt[0].fields_json;
    }
  }

  //  ####################  On Field Alias select from Dropdown  #################  //

  onFieldAliasSelected(val) {
    let field = this.fields[this.attrForm.value.fieldalias];
    if (val === '') {
      this.attrForm.get('attrtype').patchValue('');
      this.attrForm.get('fieldsize').patchValue('');
    } else {
      this.attrForm.get('attrtype').patchValue(field.data_type);
      if (field.character_maximum_length === null  || field.character_maximum_length === undefined){
        this.attrForm.get('fieldsize').patchValue(0);
      } else {
        this.attrForm.get('fieldsize').patchValue(field.character_maximum_length);
      }
    }
  }

}
