<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/pi">PII & DSAR</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">PII Table</li>
    </ol>
  </nav>
</div>
<div class="container">
  
  <div class="row" *ngIf="customerID == 0">
    <div class="col-sm-6 mt-2">
      <div>
        <select
          name="company"
          class="form-control formstyle"
          (change)="gettingSensitiveTables($event.target.value)"
        >
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj" >
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
          <!-- <option
            *ngFor="let userObj of getCompanyobj"
            value="{{ userObj.customer_id }}"
          > -->
            {{ userObj.customer_company_name }}
          </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-sm-6 mt-2" *ngIf="companyID && customerID == 0">
      <input *ngIf="isWrite" type="button" value="Add New" class="custbtn ml-0 mt-sm-1 mt-0" routerLink="/corporate/pii-table"
      />
    </div>
  </div>
</div>
<div class="container mt-2 themeTblReso">

  <div class="tblPrt">
    <div class="mt-3" *ngIf="allTablesobj.length == 0 && companyID">
      No Sensitive Tables
    </div>

    <table
      class="mt-1 bdrStCustom"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      *ngIf="allTablesobj.length > 0 && customerID == 0"
    >
      <tr>
        <!-- <th>Theme Id</th> -->
        <th>Table Name</th>

        <th style="cursor: pointer">
          CreatedOn <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>

        <th width="80">Actions</th>
      </tr>

      <tr *ngFor="let obj of allTablesobj">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->

        <td>{{ obj.table_name }}</td>

        <td>{{ obj.createdon | date: "mediumDate" }}</td>

        <td>
          <div class="actBtn wit-80">
            <span
              class="fa fa-trash mr-1"
              *ngIf="isDelete"
              (click)="deleteTable(obj.pii_tbl_id)"
              placement="bottom"
              ngbTooltip="Delete"
            ></span>
          </div>
        </td>
      </tr>
    </table>

    <div class="tabCustom1" *ngIf="customerID > 0">
      <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder">
        <ngb-tabset style="width: 100%">
          <ngb-tab>
            <ng-template ngbTabContent>
              <ngb-accordion
                #acc="ngbAccordion"
                *ngFor="let obj of getTablesAction"
              >
                <ngb-panel title="{{ obj.title }}">
                  <ng-template ngbPanelContent>
                    <table class="table table-borderless table-responsive">
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th>Data Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of obj.data">
                          <td>{{ item.field_name }}</td>
                          <td>{{ item.pii_type }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </ul>
    </div>
  </div>
</div>
