<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }

  /* .my-slider /deep/ .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #009FAE 
}
.my-slider /deep/ .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #009FAE ;
} */
</style>


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/corporate/home">Admin</a></li>
    <li class="breadcrumb-item"><a routerLink="/corporate/fb">Facebook</a></li>
    <li class="breadcrumb-item active"><a routerLink="/corporate/campaign">Campaign</a></li>
  </ol>
</nav>
<!--&gt; &frasl; -->
<div class="row">
  <div class="col-8 col-lg-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>
  </div>
  <div class="col-4 col-lg-4" style="justify-content: right;">
    <input type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/addnewcampaign">
    <!-- <button class="cbtn" (click)="importFile()" placement="bottom" ngbTooltip="Import">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="cbtn" (click)="exportFile()" placement="bottom" ngbTooltip="Export">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button> -->
  </div>
</div>

<table class="table table-borderless table-responsive mt-2">
  <thead>
    <tr>
      <!-- <th> Company Type Id</th> -->
      <!-- <th> Company Type ParentId</th> -->
      <th (click)="chgord('x')" style="cursor: pointer;">
        Campaign Name
        <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>
        Campaign Description </th>
      <th>Screenshot</th>
      <th>Created By</th>
      <th (click)="chgord('x')" style="cursor: pointer;">Created On
        <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>Modified By</th>
      <th>Modified On</th>
      <th>IsActive</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>

    <tr
      *ngFor="let obj of campaignObj | filter:text | orderBy:colname:ord | paginate: { itemsPerPage: 10, currentPage: p }">
        <td  style="border-radius:18px 0px 0px 10px">{{obj.get_fb_campaign_fun.campaign_name}}</td>
        <td>{{obj.get_fb_campaign_fun.campaign_description}}</td>
        <td><img src="{{obj.get_fb_campaign_fun.campaign_screenshot}}" alt ="No Image" style="height:50px;"
          onerror="this.onerror=null;this.src='../../../assets/custom/images/broken.png';"></td>
        <td>{{obj.get_fb_campaign_fun.createdname}}</td>
        <td>{{obj.get_fb_campaign_fun.createdon | date:'medium'}}</td>
        <td>{{obj.get_fb_campaign_fun.modifiedname}}</td>
        <td >{{obj.get_fb_campaign_fun.modifiedon | date:'medium'}}</td>
        <td>{{obj.get_fb_campaign_fun.isactive}}</td>
        <td style="border-radius:0px 18px 10px 0px">
          <i class="fa fa-edit mr-4" (click)="editCampaign(obj.get_fb_campaign_fun.campaign_id)"  placement="bottom" ngbTooltip="Edit"></i>
        <i class="fa fa-trash mr-4" (click)="deleteCampaign(obj.get_fb_campaign_fun.campaign_id)" placement="bottom" ngbTooltip="Delete"></i>
       
        <mat-slide-toggle  class="my-slider mr-4"
      
            [checked]="obj.get_fb_campaign_fun.isactive" 
            (click)="updateStatus($event,obj.get_fb_campaign_fun.campaign_id)">
        </mat-slide-toggle>


      </td>
    </tr>
  </tbody>
</table>
<div style="text-align: center;" *ngIf="(campaignObj | filter:text).length === 0">
  <p>No Result Available</p>
</div>
<pagination-controls *ngIf="(campaignObj | filter:text).length !== 0" class="my-pagination" (pageChange)="p = $event"></pagination-controls>