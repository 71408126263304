import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
@Component({
  selector: 'app-reskiningmaster',
  templateUrl: './reskiningmaster.component.html',
  styleUrls: ['./reskiningmaster.component.css']
})
export class ReskiningmasterComponent extends BaseComponent implements OnInit {
  public themeObj: any = [];
  cp = 1;
  ord = true;
  colname: "Reskinning List";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  addbtnStatus = false;
  getDSobj: any;
  dsid: any;
  page: any = 'master';

  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe,
    private clientService: ClientService
  ) {
    super();
  }
  ngOnInit() {

    this.page = this.master.getCataloguePage();
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getTheme();

    this.themeLogData();
    this.getCompany();
  }
  order: string = 'get_master_theme.theme_name';
  reverse: boolean = false;


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  themeLogData() {
    let themeLogs = {
      log_type: "Reskinning Master View",
      log_name: "Reskinning Master View",
      log_description: "Reskinning Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });

  }
  getTheme() {
    let self = this;
    this.getReskinningDataByUserType('bu')
    // this.ngxSpinnerService.show();
    // this.master.getTheme(this.companyID).subscribe(data => {
    //   self.themeObj = data;
    //   this.ngxSpinnerService.hide();
    // }, error => {

    //   this.ngxSpinnerService.hide();
    // });
  }

  getCompanyThemes(id) {

    if (id != '') {
      this.addbtnStatus = true;
    } else {
      this.addbtnStatus = false;
    }

    localStorage.setItem('dsId', id);
    this.ngxSpinnerService.show();
    console.log("id", id);
    console.log("id", typeof (id));
    if (id) {

      this.dsid = id;
      let self = this;
      this.master.getCompanyReskinning(this.companyID, this.dsid).subscribe(data => {
        self.themeObj = data[0];
        this.ngxSpinnerService.hide();
      }, error => {
        this.ngxSpinnerService.hide();
      });
    } else {
      this.themeObj = [];
      this.ngxSpinnerService.hide();

    }
  }

  getDirectSellerList(id) {
    this.themeObj = [];
    this.companyID = id;
    let self = this;
    localStorage.setItem('companyId', id);
    if (this.page == 'master') {
      this.getReskinningDataByUserType('bu')
      // this.master.getCompanyReskinning(this.companyID, this.dsid).subscribe(data => {
      //   self.themeObj = data[0];
      //   this.ngxSpinnerService.hide();
      // }, error => {
      //   this.ngxSpinnerService.hide();
      // });

    } else {

      if (id != '') {
        this.addbtnStatus = true;
      } else {
        this.addbtnStatus = false;
      }

      this.ngxSpinnerService.show();
      if (id) {

        let self = this;
        this.clientService.getBUDSList(this.companyID, 100, 0).subscribe(data => {
          if (data.status) {
            self.getDSobj = data.data.rows;
          } else {
            self.getDSobj = [];
          }
          this.ngxSpinnerService.hide();

          console.log("self.getDSobj", self.getDSobj);
        }, error => {
        });
      } else {
        this.getDSobj = [];
        this.ngxSpinnerService.hide();

      }
    }

  }

  edit(id) {
    this.router.navigate(['/corporate/addnewreskinning'], { queryParams: { companyID: this.companyID, them_id: id } });
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.themeObj.find(element => {
      if (element.theme_id === id) {
        return true;
      }
    });



    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;

          this.master.UpdateTheme(obj).subscribe(data => {
            // this.getTheme();
            this.getCompanyThemes(this.companyID)
            this.toastrService.success("Theme Master Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }


      }).catch((err) => {

      });


  }







  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      console.log("--Get company Name list--", data.data);
    }, error => {
      //  console.log(error);
    });
  }

  getReskinningDataByUserType(userType:any){
    this.ngxSpinnerService.show();
    this.master.getReskinningByUserType(this.companyID, userType).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.status==true){
        this.themeObj = data.data?.rows;
        if(this.themeObj){
          this.router.navigate(['/corporate/addnewreskinning'], { queryParams: { companyID: this.companyID, them_id: this.themeObj.reskininig_id,user_type:this.currentUser.admin_customer_id==0?'master':'bu' } });
        }else{
          this.themeObj=undefined;
        }
      }
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }
}
