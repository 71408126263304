<div class="container ">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Term & Conditions</li>
    </ol>
  </nav>
</div>

<div class="container">
  <div class="card">

    <div class="card-body">
      <form [formGroup]="agreementForm">
        <div class="row">
          <div class="col-xl-6 col-md-8">
            <div class="row form-group">
              <label class="col-sm-4 col-form-label">Agreement Title<span class="required">*</span>:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control formstyle" formControlName="title" placeholder="Title"
                  [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                  <div *ngIf="f.title.errors.required">Title is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-8">
            <div class="row form-group">
              <label class="col-sm-4 col-form-label">Agreement Subtitle<span class="required">*</span>: </label>
              <div class="col-sm-8">
                <input type="text" placeholder="Subtitle" class="form-control formstyle" formControlName="subtitle"
                  [ngClass]="{ 'is-invalid': submitted && f.subtitle.errors }" />

                <div *ngIf="submitted && f.subtitle.errors" class="invalid-feedback">
                  <div *ngIf="f.subtitle.errors.required">Subtitle is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6 col-md-8">
            <div class="row form-group">
              <label class="col-sm-4 col-form-label">Date: </label>
              <div class="col-sm-8">
                <mat-form-field>
                  <input matInput [(ngModel)]="currentDate" [matDatepicker]="picker1" placeholder="Angular forms"
                    formControlName="selecteddate" placeholder="Select date">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12 col-md-8">
            <div class="row form-group">
              <label class="col-sm-12 col-form-label">Agreement Text<span class="required">*</span>: </label>
              <div class="col-sm-12">
                <angular-editor id="editor" class="editor"  formControlName="text" [placeholder]="'Enter text here...'"
                  [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.text.errors }">
                </angular-editor>

                <div *ngIf="submitted && f.text.errors" class="invalid-feedback">
                  <div *ngIf="f.text.errors.required">Text is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center card-footer">
          <button class="custbtn" (click)="updateContent()">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>