import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-newsubscription',
  templateUrl: './newsubscription.component.html',
  styleUrls: ['./newsubscription.component.css']
})
export class NewsubscriptionComponent extends BaseComponent implements OnInit {

  subscriptionname;
  description;
  attachment: String = "";
  IsActive;
  company;
  subscription_id;
  subscriptionObj;
  getCompanyobj
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey;
  status: boolean = true;
  screenshot_type = false;
  headers = "Add";
  attachmentFile="";
  submitted = false;
  AddSubscription: FormGroup;
  controlEnabled:boolean = true;
  imageType : any;
  constructor(
    private master: MasterService,
    public registerService: RegisterService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private upload:UploadService) {
      super();
    }

    ngOnInit() {
      this.getCompany();
      this.controlEnabled= true;
      let self = this;
      this.activatedRoute.queryParams
        .subscribe(params => {
          this.subscription_id = params.subscription_id;
        });
      if (this.subscription_id) {
        this.UpdateSubscriptionLogData()
      }
      else {
        this.addSubscriptionLogData()
      }
      //get item details using id
      if (this.subscription_id) {
        this.headers = "Edit"
        this.ngxSpinnerService.show();
        this.master.getSubscriptionById(this.subscription_id).subscribe(response => {
         // console.log(response);
          if (response.status) {
            this.status = false;
            self.subscriptionObj = response.data;
             console.log("response.data.attachment", response.data.attachment);

            if (response.data.attachment) {
              if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt|pdf)$/i.test(response.data.attachment) === true) {
                self.subscriptionObj.attachment = this.downloadFile(self.subscriptionObj.attachment);
                self.subscriptionObj.imageType = 1;
                this.imageType = 1;
              } else {
                self.subscriptionObj.imageType = 0;
                this.imageType = 0;
              }
            }
            console.log("--self.subscriptionObj--", self.subscriptionObj);
            self.dataBinding();
            this.ngxSpinnerService.hide();
          } else {

          }
        });
      }
      this.AddSubscription = this.formBuilder.group({
        subscriptionname: ['', [Validators.required,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30),this.noWhitespaceValidator]],
        description: [],
        attachment:[],
        company:['', Validators.required],
      });
    }

    get f() { return this.AddSubscription.controls; }

    addNewSubscription() {
      let self = this;
      this.submitted = true;
      if (this.AddSubscription.invalid) {
        return;
      }
      this.f.attachment.setValue(self.them_awskey);


        let subscriptionObj = {
          name: this.AddSubscription.value.subscriptionname,
          description: this.AddSubscription.value.description,
          attachment: this.AddSubscription.value.attachment,
          isactive: this.IsActive,
          company:this.AddSubscription.value.company

        }
      //  console.log(this.company)
        this.ngxSpinnerService.show();
        this.master.addingSubscription(subscriptionObj).subscribe(data => {
          if (data.status) {
            setTimeout(() => {
              this.toastrService.success("Added Successfully")
            }, 1000);
            this.router.navigateByUrl(`/corporate/subscription`);
          } else {
            this.toastrService.error(data.message);
          }
          this.ngxSpinnerService.hide();
        }, error => {
        //  console.log(error);
          this.ngxSpinnerService.hide();
        });
      }
    addSubscriptionLogData() {

      let themeLogs = {
        log_type: "Adding New Subscription",
        log_name: "Adding New Subscription",
        log_description: "Adding New Subscription",
        moduleid: 1,
        moduleitemid: 7,
        per_id: 14
      }
      this.logs.addNewLogs(themeLogs).subscribe(data => {
      }, error => {
      //  console.log(error);
      });

    }
    UpdateSubscriptionLogData() {

      let themeLogs = {
        log_type: "Updating Subscription Data",
        log_name: "Updating Subscription Data",
        log_description: "Updating Subscription Data",
        moduleid: 1,
        moduleitemid: 7,
        per_id: 14
      }
      this.logs.addNewLogs(themeLogs).subscribe(data => {
       // console.log(data);

      }, error => {
       // console.log(error);
      });

    }
    dataBinding() {

      this.attachmentFile = this.isUrlValid(this.subscriptionObj.attachment) ? this.subscriptionObj.attachment : this.subscriptionObj.attachment;
      // this.subscriptionname = this.subscriptionObj.name;
      // this.description = this.subscriptionObj.description;
      // this.attachment = this.subscriptionObj.attachment;
      // this.IsActive = this.subscriptionObj.isactive;
      // this.company = this.subscriptionObj.customer_id;
      // this.attachmentFile = this.subscriptionObj.attachment;
      this.AddSubscription = this.formBuilder.group({
        subscriptionname: [this.subscriptionObj.name, Validators.required],
        description: [this.subscriptionObj.description],
        attachment:[this.subscriptionObj.attachment],
        company:[this.subscriptionObj.customer_id,Validators.required],
        attachmentFile:[this.subscriptionObj.attachment]
      });this.IsActive=this.subscriptionObj.isactive;
   // console.log("imgUr",this.attachmentFile);
    }
    getCompany() {
      let self = this;
      this.registerService.getCompanyData().subscribe(data => {
        self.getCompanyobj = data.data;
      }, error => {
       // console.log(error);
      });
    }

    updateSubscription() {
      let self=this;
      this.submitted = true;
      if (this.AddSubscription.invalid) {
        return;
      }
      //console.log("this.AddSubscription.value", this.AddSubscription.value);
      let { name, description, attachment, company } = this.AddSubscription.value;

      //console.log("attachment", attachment);
      //return false;
      if(this.them_awskey){
        this.attachment =  this.them_awskey;
      }else{
        this.attachment = this.attachmentFile;
      }


        let subscriptionobj = {
          name: this.AddSubscription.value.subscriptionname,
          description: this.AddSubscription.value.description,
          attachment: this.attachment,
          isactive: this.IsActive,
          company:this.AddSubscription.value.company,
          subscription_id: this.subscription_id
        }

        // console.log("--subscriptionobj--", subscriptionobj);
        // return false;

        this.ngxSpinnerService.show();
        this.master.UpdateSubscription(subscriptionobj).subscribe(data => {
          if (data.status) {
            this.toastrService.success(" Updated Successfully")
            this.router.navigateByUrl(`/corporate/subscription`);
            this.ngxSpinnerService.hide();
          }
        }, error => {
          console.error(error);
          this.ngxSpinnerService.hide();
        });
      }
    setValue(event) {

      let self = this;
      let url = event.target.src;
      if (url.indexOf("master_images") > 0) {
        url = url.substring(url.indexOf("master_images"), url.length)
        if (!this.isUrlValid(url)) {
          let awsUrl = self.master.getUrlfile(url);
          this.attachmentFile=url;
          self.them_awskey = url;
       //   console.log(awsUrl);
          event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
        } else {
          this.attachmentFile="";
          event.target.src = '../../../assets/custom/images/broken.png';
        }
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
      event.target.onerror = null;
    }
    isUrlValid(userInput) {
      // let regexp = new RegExp("^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$");
      // let regexp = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");

      // return regexp.test(userInput);
      // return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(userInput);
      return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);

    }
    processFile(event: any, imageInput: any) {
      // console.log( /\.jpe$/i.test(file.files[0].name));
      // if ( /\.jpe$/i.test(file.files[0].name) === false ) { alert("not an image!"); }
      const file: File = imageInput.files[0];
      if (/\.(jpe?g|png|jpg||txt|pdf|doc)$/i.test(file.name) === false) {
        this.toastrService.error("Please select only (png, jpg or jpeg,or txt or pdf or doc) files");
       // console.log("error ", file);
      } else {
        const reader = new FileReader();
        reader.onload = (event1) => {
          this.toUploadFiles(file.name, reader.result);
        }
        reader.readAsDataURL(file);
      }
    }
    toUploadFiles(name,file) {
      this.ngxSpinnerService.show();
      let self = this;
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.key) {
              self.them_awskey = params.Key;
            }
          }else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    }
    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }

  downloadFile(event) {
    let url = event;
    url = url.substring(url.indexOf("master_images"), url.length);
    console.log('url', url)
    if (url) {
      return this.master.getUrlfile(url);
      // this.downloadfile = awsUrl;
      // console.log('awsUrl', awsUrl)
    }
  }

  }


