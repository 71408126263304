<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/resource">Resource Library</a></li>
      <li class="breadcrumb-item active" aria-current="page">Update Resource</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 mt-md-2">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal mt-1" [formGroup]="addCategoryForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">
              <label for="exampleForm2">Company Name <span class="required">*</span></label>
              <select formControlName="selectedCompany" *ngIf="category_id == undefined" class="form-control formstyle"
              [attr.disabled]="controlEnabled" (change)="getCategory($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">

            <div class="form-group">
              <label for="exampleForm2">Category Name <span class="required">*</span></label>
              <select formControlName="categoryid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.categoryid.errors }">
                <option value="">Select Category</option>
                <option *ngFor="let Obj of categoryObj" value={{Obj.cate_id}}>
                  {{Obj.category_name}}
                </option>
              </select>
              <div *ngIf="submitted && f.categoryid.errors" class="invalid-feedback">
                <div *ngIf="f.categoryid.errors.required">Category Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Name <span class="required">*</span></label>
              <input type="text" class="form-control" formControlName="name" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="50" appSpace>
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required</div>
              </div>
              <div *ngIf="f.name.errors?.maxlength">
                You reached to maxlength
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Description <span class="required">*</span> </label>
              <textarea class="form-control text-area h-25" rows="4" id="description" formControlName="description"
                maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" name="description"
                appSpace></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required || f.description.hasError('whitespace')">Description is
                  required</div>
                <div *ngIf="f.description.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>

            <!-- <div class="form-group">
              <label for="exampleForm2">URL</label>
              <input type="text" class="form-control" formControlName="url" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
              <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                <div *ngIf="f.url.errors.pattern">Please enter valid URL</div>
              </div>
            </div> -->

            <div class="form-group">
              <label for="exampleForm2">Image<span class="required">*</span></label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio2" checked>
                  <label class="custom-control-label" for="customRadio2">Upload a file</label>
                </div>
              </div>
              <div class='form-group' *ngIf="croppedImage">
                <img [src]="croppedImage" width='100px' height='auto'/>
               </div>
              <div  class="mt-1">

                <input #imageInput type="file"
                  accept=".png, .jpg, .jpeg"
                  (change)="fileChangeEvent($event,cropperImage)">
                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
              </div>
              <div class="image-feedback" *ngIf="submitted && !croppedImage">*Image is required</div>
            </div>
          </div>
          <div class="card-footer">
            <div class="my_class text-center">
              <button type="submit" class="custbtn" (click)="addNewPostData()">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #cropperImage let-modal>
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead w-100">
              <h4 class="modal-title mb-0 float-left">Image Editor</h4>
              <button type="button" class="close" aria-label="Close" (click)="closeModal();modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
          </div>
          <div class="modal-body">
              <div class="scroller1">
                  <div class="row ">
                    <image-cropper
                     [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="true" [aspectRatio]="400 / 191"
                      format="png"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded($event)"
                    ></image-cropper>

                  </div>
              </div>
          </div>
          <div class="d-block text-center modal-footer border-0">
              <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                  (click)="uploadImage();uploadOriginalImage();modal.dismiss('Cross click')">Save</button>
          </div>
      </div>
  </div>
</ng-template>
