<style>
    .my-pagination /deep/ .ngx-pagination {
      text-align: center;
  
    }
  </style>
  <div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
        <li class="breadcrumb-item active" aria-current="page">Theme Category</li>
      </ol>
    </nav>
  </div>
  
  
  <div class="container mt-2 themeTblReso">
    <div class="row">
      <div class="col-md-8">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                aria-hidden="true"></i></span>
          </div>
  
          <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
        </div>
  
      </div>
  
    
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn" routerLink="/corporate/newthemecategory" >
      <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
    </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
  
        <tr>
          <!-- <th>Theme Id</th> -->
          <th (click)="chgord('subc1')" class="curPoint" width="10%">Theme Category Name <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
          <th (click)="chgord('subc1')" class="curPoint" >Theme Category Description <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
          
          <!-- <th width="220">Isactive</th> -->
          <th width="20%">Created by</th>
          
          <!--<th width="200">ModifiedBy</th>-->
          <th width="20%">Created Date</th>
          <th style="text-align: center;" width="25%" *ngIf="isEdit || isDelete">Actions</th>
        </tr>
  
        <tr *ngFor="let obj of themeObj | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:5,currentPage:cp }">
          <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->
          <td>{{obj.theme_category_name}}</td>
          <td>{{obj.theme_description}}</td>
          
          <!-- <td>{{obj.get_master_theme.isactive }}</td> -->
          <td>{{obj.createdname}}</td>
          <td>{{obj.createdon | date:'medium' }}</td>
          <!--<td>{{obj.get_master_theme.modifiedname }}</td>
          <td>{{obj.get_master_theme.modifiedon |date}}</td>-->
  
          <td *ngIf="isEdit || isDelete">
            <div class="actBtn">
            <i class="curPoint fa fa-edit mr-1" *ngIf="isEdit" (click)="editThemeCategory(obj.theme_category_id)"></i>
            <span class="curPoint fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteThemeCategory(obj.theme_category_id)"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (change)="updatethemecategoryStatus($event,obj.theme_category_id)">
            </mat-slide-toggle>
          </div>
          </td>         
          </tr>
          
  
      </table>
      <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
    </div> 
    </div> 
   
      