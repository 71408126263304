import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';


@Component({
  selector: 'app-add-audience',
  templateUrl: './add-audience.component.html',
  styleUrls: ['./add-audience.component.css']
})


export class AddAudienceComponent extends BaseComponent implements OnInit {


  audience_id; 
  audienceObj;  
  audienceForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
 

  constructor(
  
    private formBuilder: FormBuilder,  
    public toaster: ToastrService,
    private segmentService: SegmentationService

  ) {
    super();
  }

  ngOnInit() {


    //this.ngxSpinnerService.show();
   
    let self = this;
   

    if(localStorage.getItem("deleteAudienceId")) this.audience_id = localStorage.getItem("deleteAudienceId")
    if (this.audience_id) {
      this.ngxSpinnerService.show();
      this.UpdateAudienceLogData()
     
     
      this.segmentService.getAudienceId(this.audience_id).subscribe(response => {
        
        if (response.status) {   
          this.actionStatus = false;  
          //console.log("data,..........", response.data[0])
          
          self.audienceObj = response.data[0];
          this.status =   self.audienceObj.isactive    
          self.dataBinding();

        } 
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addAudienceLogData()
    }
    this.audienceForm = this.formBuilder.group({
      audience_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      audience_desc: ['']
     
    });
  }
  get f() { return this.audienceForm.controls; }

 

  addAudienceLogData() {

    let audienceLogs = {
      log_type: "Adding New Audience",
      log_name: "Adding New Audience",
      log_description: "Adding New Audience",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(audienceLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });

  }
  
  UpdateAudienceLogData() {

    let audienceLogs = {
      log_type: "Updating Audience",
      log_name: "Updating Audience",
      log_description: "Updating Audience",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(audienceLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  addAudience() {
    this.submitted = true;
   
    if (this.audienceForm.invalid) {
      return;
    }

    let { audience_name, audience_desc} = this.audienceForm.value;


    this.ngxSpinnerService.show();
    
    let audienceBody = {
      audience_name: audience_name,
      audience_desc: audience_desc,
      status:true

    }

    this.segmentService.addingAudience(audienceBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/audience`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
 
  dataBinding() {
    
      this.audienceForm = this.formBuilder.group({
        audience_name: [this.audienceObj.audience_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        audience_desc: [this.audienceObj.audience_desc],       
       
       
      });
     
  }
  UpdateAudience() {
  
    this.submitted = true;
    if (this.audienceForm.invalid) {
      return;
    }
    let {audience_name, audience_desc} = this.audienceForm.value;

    let audienceBody = {
      audience_name: audience_name,
      audience_desc: audience_desc,
      status:this.status
    
    }

    this.ngxSpinnerService.show();
    this.segmentService.updateAudience(audienceBody,this.audience_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/audience`);
        
      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
