<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/eventtypemaster">Event Type</a></li>
            <li class="breadcrumb-item active">Add New Event</li>
        </ol>
    </nav>
</div>
<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="eventForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Event Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="eventName" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.eventName.errors }" required maxlength="30" appSpace>
                    <div *ngIf="submitted && f.eventName.errors" class="invalid-feedback">
                        <div *ngIf="f.eventName.errors.required || f.eventName.hasError('whitespace')">Event Name is required</div>
                        <div *ngIf="f.eventName.errors.pattern">Event Name is not a valid format</div>
                        <div *ngIf="f.eventName.errors?.maxlength">You can enter only 30 charachters</div>
                
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Event Description</label>
                    <input type="text" id="exampleForm2" formControlName="eventDescription"
                        class="form-control form-control-md" min="1" maxlength="99">
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewEvent()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateEventType()">Update</button>
                </div>
            </div>
        </div>
    </form>
</div>