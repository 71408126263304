<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/asset">Asset</a></li>          
      <li class="breadcrumb-item active" aria-current="page">Add Asset</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <form class="form-horizontal mt-1" [formGroup]="AddAsset">
<div class="rowTheme">
    <div class="themContain">
        <div> 
            <label for="exampleForm2" >Asset Name <span class="required">*</span> </label>
            <input type="text" class="form-control" formControlName='assests_name' class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': submitted && f.assests_name.errors }"   required>
            <div *ngIf="submitted && f.assests_name.errors" class="invalid-feedback">
                <div *ngIf="f.assests_name.errors.required">Asset Name is required</div>
            </div>
        </div>
        <div class="mt-1">
            <label for="exampleForm2">Asset Description</label>
            <input type="text" class="form-control" formControlName='assests_description' 
                class="form-control form-control-lg" >
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Asset Image</label>
          <input type="text" class="form-control" formControlName='asset_image' 
              class="form-control form-control-lg" >
        </div>


        
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewAsset()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateAsset()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
    </form>
</div>
