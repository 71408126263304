import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { CommonMessages, ClientMessages } from 'src/app/shared/messages/messages.enum';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-clientdetails-list',
  templateUrl: './clientdetails-list.component.html',
  styleUrls: ['./clientdetails-list.component.css']
})
export class ClientdetailsListComponent implements OnInit {
  client_id;
  clientDataSingle_g;
  contactObj;
  contactUserObj;
  subscriptionUserObj;
  cp=1;
  cp3 =1;

  subsLimit =10;
  subsSkip=0;
  subsPage= 1;
  subsTotal: number = 0;

  userLimit =10;
  userSkip = 0;
  userPage = 1;
  userTotal: number = 0;

  contactLimit = 10;
  contactSkip = 0;
  contactPage = 1;
  contactTotal: number = 0;

  constructor(
    private clientsService: ClientService,
    private master: MasterService,
  ) { }

  ngOnInit() {

    if(localStorage.getItem("updatedClientId")){
      this.client_id = localStorage.getItem("updatedClientId");
    }
    if (this.client_id) {
       this.getCompanyUser();
       this.getSubscriptionUser();
       this.getContacts();
   }
  }
  getCompanyUser() {
    this.userSkip = (this.userPage - 1) * this.userLimit;
    this.clientsService.getCompanyUserList(this.client_id, this.userLimit, this.userSkip).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.contactUserObj = [] }
        else { this.contactUserObj = data.data.rows };
        this.userTotal = data.data.total;
      }
    }, err => {
      console.error(err);
    })
  }

  getSubscriptionUser() {
    let self = this;
    this.subsSkip = (this.subsPage -1) * this.subsLimit;
    this.clientsService.getSubscriptionUserList(this.client_id,this.subsLimit, this.subsSkip).subscribe(data => {
       // console.log(self.subscriptionUserObj);
      if (data.status) {
        if (data.data.rows == null) { self.subscriptionUserObj = [] }
        else { self.subscriptionUserObj = data.data.rows;}
        self.subsTotal = data.data.total;
      }
    }, err => {
      console.error(err);
    })
  }

  getContacts() {
    let self = this;
    this.contactSkip = (this.contactPage -1) * this.contactLimit;
    this.clientsService.getCompanyContactList(this.client_id, this.contactLimit, this.contactSkip).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.contactObj = [] }
        else { self.contactObj = data.data.rows;}
        self.contactTotal = data.data.total;
      }
    }, error => {
      //console.log(error);
    });
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  setDocValue(val) {
    let self = this;
    let url = val;
    let docLink = '../../../assets/custom/images/broken.png';
    if (url.indexOf("master_images") > -1) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        docLink = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        docLink = '../../../assets/custom/images/broken.png';
      }
    } else {
      docLink = '../../../assets/custom/images/broken.png';
    }
    return docLink;
  }

  checkImg(url) {
    let op = (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    return op;
  }

}
