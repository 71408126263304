import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../../services/license/license.service';
import { AlertService } from '../../../_services/alert.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { ClientService } from '../../../services/clients/client.service';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-license-bundle',
  templateUrl: './license-bundle.component.html',
  styleUrls: ['./license-bundle.component.css']
})
export class LicenseBundleComponent extends BaseComponent implements OnInit {

  licenseObj: any = [];
  bundleForm: FormGroup;
  submitted = false;
  getCompanyobj: any = [];
  showForm: boolean = false;
  defaultTeamMember: number = 20000;
  defaultPrice = 0;
  maxdate;
  costPerCustomer: number;
  marketShop: boolean = false;
  marketEvent: boolean = false;
  ReadOnlyStyleGuideNotes: boolean = true;
  bundleCreationPage: boolean = true;
  invoiceCreationPage: boolean = false;
  companyInfo: any = [];
  todayDate;
  selectedLicesne: any = [];
  minDate;
  myDate = new Date();
  selectedCompany = 0;
  startDate;

  bundleName;
  bundleAccessCode;
  currentDate;
  bundleID;
  editcompanyID;
  bundleObj;
  licenseData;
  durationData: any = [];
  newduration: any = []

  constructor(
    private clientService: ClientService,
    private LS: LicenseService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { super(); }

  ngOnInit() {


    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();
    this.minDate = new Date(currentYear, currentMonth, currentDate);
    //this.startDate = new Date(currentYear, currentMonth, 15);



    this.todayDate = Date.now();

    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
    //  console.log(error);
    });


    if (localStorage.getItem("editbundleId")) {


      this.ngxSpinnerService.show();
      this.bundleID = localStorage.getItem("editbundleId");
      this.editcompanyID = localStorage.getItem("editcompanyID");



      this.LS.getbundleByID(this.editcompanyID, this.bundleID).subscribe(response => {
       // console.log(response);
        if (response.status) {

          this.bundleObj = response.data.bundle_data;
          this.costPerCustomer = this.bundleObj.cost_per_person;


          this.licenseData = response.data.license_data;
          this.licenseData.forEach(element => {
            this.selectedLicesne.push(element.toString())

          });
          //console.log("aaaa....",this.selectedLicesne)

          //this.selectedLicesne = this.licenseData;
          this.selectedCompany = this.editcompanyID;
          this.loadBundleForm(this.editcompanyID);
          this.dataBinding();
          this.ngxSpinnerService.hide();
        } else {
        }
      });
    }




  }

  dataBinding() {

    //console.log(this.licenseData.license_id)

    this.bundleForm = this.formBuilder.group({
      licensePeriod: [this.bundleObj.duration, Validators.required],
      start_date: [this.bundleObj.start_date, Validators.required],
      totalMember: [this.bundleObj.total_member, [Validators.required, Validators.max(5000000)]],
      bundlePrice: [this.bundleObj.bundle_price, [Validators.required, Validators.max(500000000000)]],

      bundleName: [this.bundleObj.bundle_name, Validators.required],
      licenseId: [''],
      suggestCost: [''],
      pricePerCustomer: [this.bundleObj.cost_per_person]
    });


  }

  checked(item) {

    if (!localStorage.getItem("editbundleId")) return false;

    if (localStorage.getItem("editbundleId")) {
      var selected = this.licenseData;
      if (selected.indexOf(1) != -1 && (item == 2 || item == 3)) {
        return true;
      }
      if (selected.indexOf(4) != -1 && (item == 5)) {
        return true;
      }
      if (selected.indexOf(item) != -1) {
        return true;
      }
    }
  }
  loadBundleForm(id) {

    this.selectedCompany = id;

    this.ngxSpinnerService.show();

    this.showForm = true;


    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();


    this.currentDate = currentMonth + '/' + currentDate + '/' + currentYear;


    this.bundleForm = this.formBuilder.group({
      licensePeriod: ['', Validators.required],
      start_date: [this.minDate, Validators.required],
      totalMember: ['', [Validators.required, Validators.max(5000000)]],
      bundlePrice: ['', [Validators.required, Validators.max(500000000000)]],
      bundleName: ['', Validators.required],
      licenseId: [],
      suggestCost: [''],
      pricePerCustomer: ['']
    });






    this.f.bundlePrice.setValue(this.defaultPrice);
    this.f.totalMember.setValue(this.defaultTeamMember);



    //this.f.start_date.setValue(this.currentDate); 





    forkJoin(
      {
        companyData: this.clientService.getClientById(id),
        licenseData: this.LS.gettingLicense(id),
        bundleData: this.LS.gettingBundleName(id),
        durationData: this.LS.gettingduration(id),

      }
    ).subscribe(data => {

      if (data.companyData.status) {
        this.companyInfo = data.companyData.data;
      }
      if (data.licenseData.status) {
        this.licenseObj = data.licenseData.data;
      }
      if (data.durationData.status) {

        this.durationData = data.durationData.data;
      }
      for (var key in this.durationData[0]) {
        if (this.durationData[0].hasOwnProperty(key)) {
          this.newduration.push({ "id": key, "value": this.durationData[0][key] })
        }
      }
      if (data.bundleData.status) {
        this.bundleName = data.bundleData.data[0].bundle_name;
        this.bundleAccessCode = data.bundleData.data[0].access_code;


      }

      // this.f.bundleName.setValue( this.bundleName);





      this.ngxSpinnerService.hide();

    }), error => {
     // console.log(error);
      this.ngxSpinnerService.hide();

    }
  }

  get f() { return this.bundleForm.controls; }

  contains(arr, x) {
    return arr.filter(function (elem) { return elem == x }).length > 0;
  }

  selectLicense(id) {

    var index = this.selectedLicesne.indexOf(id);
    if (index > -1) {
      this.selectedLicesne.splice(index, 1);
    }
    else {
      this.selectedLicesne.push(id);
    }

    if (this.selectedLicesne.includes("1")) {

      var index = this.selectedLicesne.indexOf("2");
      if (index !== -1) {
        this.selectedLicesne.splice(index, 1);
      }
      var index = this.selectedLicesne.indexOf("3");
      if (index !== -1) {
        this.selectedLicesne.splice(index, 1);
      }

    }
    if (this.selectedLicesne.includes("4")) {

      var index = this.selectedLicesne.indexOf("5");
      if (index !== -1) {
        this.selectedLicesne.splice(index, 1);
      }


    }


    ///this.f.licenseId=true;
    const marketFull = document.getElementById("licenseId_1") as HTMLInputElement;
    const socialFull = document.getElementById("licenseId_4") as HTMLInputElement;
    const market1 = document.getElementById("licenseId_2") as HTMLInputElement;
    const market2 = document.getElementById("licenseId_3") as HTMLInputElement;

    //var market1_cost = document.getElementById("suggestCost_2") as HTMLInputElement;
    //var market2_cost = document.getElementById("suggestCost_3") as HTMLInputElement;



    if (market1.checked) this.marketShop = true;
    if (market2.checked) this.marketEvent = true;


    if (id == 1) {
      if (marketFull.checked) {
        market1.checked = true;
        market2.checked = true;
        market1.disabled = true;
        market2.disabled = true;

        this.marketShop = false;
        this.marketEvent = false;
      }
      else {
        market1.checked = false;
        market2.checked = false;
        market1.disabled = false;
        market2.disabled = false;
      }
    }



    if (id == 4) {
      const social = document.getElementById("licenseId_5") as HTMLInputElement;

      if (socialFull.checked) {
        social.checked = true;
        social.disabled = true;

      }
      else {
        social.checked = false;
        social.disabled = false;

      }

    }
    var totalPrice = 0;
   // console.log("total check...", this.selectedLicesne.length, this.selectedLicesne)
    if (this.selectedLicesne.length > 0) {
      this.selectedLicesne.forEach(id => {

        this.licenseObj.forEach(data => {

          if (data.license_id == id) {

            totalPrice += (this.bundleForm.get('totalMember').value * data.suggest_cost);
          }
        });

      });
      this.defaultPrice = totalPrice;
      this.f.bundlePrice.setValue(this.defaultPrice);
      this.costPerCustomer = this.defaultPrice / this.bundleForm.get('totalMember').value;
      this.costPerCustomer.toFixed(2);
    } else {

      this.defaultPrice = 0;
      this.f.bundlePrice.setValue(0);
      this.costPerCustomer = 0;
      this.costPerCustomer.toFixed(2);


    }

    //console.log("total price....",totalPrice)



  }

  manualBundlePrice(price) {

    if (price > 5000000) return false;
    this.costPerCustomer = price / this.bundleForm.get('totalMember').value;

  }

  manualTeamBundlePrice(val) {

    if (val && val != 0) {

      var totalPrice = 0;
      this.selectedLicesne.forEach(id => {

        const licenseCheck = document.getElementById("licenseId_" + id) as HTMLInputElement;
        this.licenseObj.forEach(data => {
          if (data.license_id == id) {
            totalPrice += (this.bundleForm.get('totalMember').value * data.suggest_cost);
          }
        });

      });

     // console.log("total price....", totalPrice)

      this.defaultPrice = totalPrice;
      this.f.bundlePrice.setValue(this.defaultPrice);
      this.costPerCustomer = this.bundleForm.get('bundlePrice').value / val;
      this.costPerCustomer.toFixed(2);
    }
    else { this.f.bundlePrice.setValue(0); this.costPerCustomer = 0; }
  }
  goInvoice() {
    this.invoiceCreationPage = true;
    this.bundleCreationPage = false;
  }
  showCreationForm() {
    this.invoiceCreationPage = false;
    this.bundleCreationPage = true;

  }
  confirmInvoice() {


    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Create New bundle ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          // this.ngxSpinnerService.show();
          this.toastrService.success("License Bundle Created Successfully");
          this.bundleForm.reset();
          this.invoiceCreationPage = false;
          this.bundleCreationPage = true;

        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));




  }

  goSubmit() {



    var endDate;


    //var startDate =  new Date(this.f.start_date.value);

    //console.log(startDate);

    //if(this.f.licensePeriod.value && this.f.licensePeriod.value!=-1) endDate= new Date(startDate.setMonth(startDate.getMonth()+this.f.licensePeriod.value+1));

    //console.log(endDate);


    this.submitted = true;

   // console.log(this.bundleForm);

    if (this.bundleForm.invalid) {
      return;
    }

    if (this.selectedLicesne.length == 0) {
      this.toastrService.error("Please select License for Bundle");
      return;
    }


    let dataObject = {
      duration: this.f.licensePeriod.value,
      bundle_price: this.f.bundlePrice.value,
      start_date: this.datePipe.transform(this.f.start_date.value, 'yyyy/MM/ddTHH:mm:ss'),
      end_date: this.f.start_date.value,
      total_member: this.f.totalMember.value,
      licenses_ids: this.selectedLicesne,
      bundle_name: this.f.bundleName.value,
      access_code: this.bundleAccessCode,
      company: this.selectedCompany,
      cost_pp: this.costPerCustomer,
      bundle_id: (this.bundleID) ? this.bundleID : ''
    }
    // console.log("check date........",dataObject)

    if (this.bundleID) {
      this.ngxSpinnerService.show();


      this.LS.editbundle(dataObject).subscribe(data => {
        if (data.status) {

          localStorage.removeItem("editbundleId");
          localStorage.removeItem("editcompanyID");

          this.toastrService.success("Bundle updated Successfully");
          this.ngxSpinnerService.hide();
          this.router.navigateByUrl(`/corporate/license-bundle-listing`);


        } else {
          this.ngxSpinnerService.hide()

          this.toastrService.error(data.message);
        }

      }, error => {
       // console.log(error);
      });


    } else {
      var today = new Date().getTime();
      var reqDateVar = new Date(this.f.start_date.value).getTime();
      if ((this.datePipe.transform(this.minDate, 'MM-dd-yy') != this.datePipe.transform(this.f.start_date.value, 'MM-dd-yy'))) {
        if (reqDateVar < today) {
          this.toastrService.error("Please select today or future date");
          return;
        }
      }
      this.ngxSpinnerService.show();

      this.LS.addNewbundle(dataObject).subscribe(data => {
        if (data.status) {

          this.toastrService.success("Bundle Created Successfully");
          this.router.navigateByUrl(`/corporate/license-bundle-listing`);
          this.ngxSpinnerService.hide();

        } else {

          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
       // console.log(error);
      });
    }


  }
  goCancel() {
    if (this.bundleID) {
      localStorage.removeItem("editbundleId");
      localStorage.removeItem("editcompanyID");
    }


    this.router.navigateByUrl(`/corporate/license-bundle-listing`);

  }
}
