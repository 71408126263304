import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-shoplive-postmanagement',
  templateUrl: './shoplive-postmanagement.component.html',
  styleUrls: ['./shoplive-postmanagement.component.css']
})
export class ShoplivePostmanagementComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  getCompanyobj: any = [];
  companyID = 0;
  currentUser;
  selectedCompany: any = '';
  productObj: any = [];
  categoryId: any;
  categoryObj: any = [];

  slord = "desc";
  slColname = "createdon";
  slPage = 1;
  slLimit = 10;
  slSkip = 0;
  slTotal: number = 0;

  slCatPage = 1;
  slCatLimit = 1000;
  slCatSkip = 0;
  slCatTotal: number = 0;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;
  domain_id: any='';


  constructor(protected confirmationDialogService: ConfirmationDialogService,
    private clientService: ClientService,
    private shopLiveService: ShopLiveService,
    public orderPipe: OrderPipe,
    private master: MasterService) { super(); }

  ngOnInit() {

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getProductList();
      this.getCategoryData();
    }
    if (this.currentUser.admin_customer_id == '0') { this.getCompany() }
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slSkip = 0;
          this.slPage = 1;
        }),
        switchMap(data => this.shopLiveService.getProductList(this.selectedCompany, this.slLimit, this.slSkip, this.slColname, this.slord, this.searchText.nativeElement.value, '0'))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.productObj = [] }
          else { this.productObj = res.data.rows }
          this.slTotal = res.data.total;
        } else {
          this.productObj = [];
          this.slTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.productObj = [];
        this.slTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

  setOrder(value: string) {
    this.slColname = value
    if (this.slord == 'asc') {
      this.slord = 'desc'
    } else {
      this.slord = 'asc'
    } this.getProductList();
  }

  deleteProduct(id) {
    let obj = {
      isdelete: true
    }
    this.confirmationDialogService.confirm(CommonMessages.confirmationTitle, 'Are you sure you want to Delete?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.shopLiveService.updateProduct(id, this.selectedCompany, obj).subscribe(data => {
            if (data.status) {
              this.getProductList();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
              this.ngxSpinnerService.hide();
            } else {
              this.ngxSpinnerService.hide();
            }
          }, error => {
            this.toastrService.error(error.message);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      console.log('this.companyID', this.getCompanyobj.customer_id);
      this.ngxSpinnerService.hide();
      //this.getCategoryData(this.getCompanyobj.customer_id);
    }, error => {
      console.log(error);
    });
  }

  getProductList() {
    let categoryId = this.categoryId > 0 ? this.categoryId : 0;
    let searchTerm = this.searchText.nativeElement.value;
    this.slSkip = (this.slPage - 1) * this.slLimit;

    if (this.selectedCompany !== '') {
      this.ngxSpinnerService.show();

      this.shopLiveService.getProductList(this.selectedCompany, this.slLimit, this.slSkip, this.slColname, this.slord, searchTerm, categoryId,this.domain_id).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.productObj = [] }
          else { this.productObj = data.data.rows }
          this.slTotal = data.data.total;
          this.productObj.forEach(element => {
            if (element.sl_product_files) {
              if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(element.sl_product_files) === true) {
                // element.sl_product_files = this.downloadFile(element.sl_product_files);
                element.imageType = 1;
              } else if (/\.(mp4|mov)$/i.test(element.sl_product_files) === true) {
                element.imageType = 2;
              } else {
                element.imageType = 0;
              }
            }
          });
        } else {
          this.productObj = [];
          this.slTotal = 0;
        }
      }, error => {
      console.log(error);
    });
    this.ngxSpinnerService.hide();
  } else {
  this.productObj = [];
  this.selectedCompany = "";
  this.ngxSpinnerService.hide();
}
  }

setValue(event) {
  let self = this;
  let url = event.target.src;
  if (url.indexOf("master_images") > 0) {
    url = url.substring(url.indexOf("master_images"), url.length)
    if (!this.isUrlValid(url)) {
      let awsUrl = self.master.getUrlfile(url);
      event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
  } else {
    event.target.src = '../../../assets/custom/images/broken.png';
  }
  event.target.onerror = null;
}
isUrlValid(userInput) {
  return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
}

editPost(id) {
  localStorage.setItem("edit_sl_post_id", id);
  localStorage.setItem("sl_post_edit_company_id", this.selectedCompany);
  this.router.navigate(['/corporate/shoplive-editpost']);
}

updateStatus(event, id, status) {
  event.preventDefault();
  let obj = {
    status: (status == true) ? false : true,
  }
  console.log(status);
  this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.status) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')
    .then((confirmed) => {
      if (confirmed) {
        this.ngxSpinnerService.show();
        this.shopLiveService.updateProduct(id, this.selectedCompany, obj).subscribe(data => {
          if (data.status) {
            this.toastrService.success("Status Updated Successfully")
            this.getProductList();
          } else {
            this.toastrService.error(data.message);
          }
        });
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
      }
    }).catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
}

getCategoryData() {
  if (this.selectedCompany != '') {
    let status = true;
    this.ngxSpinnerService.show();


    this.shopLiveService.getCategoryList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.slCatColname,  this.slCatord, status, '').subscribe(data => {

      this.ngxSpinnerService.hide();

      if (data.status) {
        if (data.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = data.data.rows }
          this.slCatTotal = data.data.total;
      } else {
        this.categoryObj = [];
        this.slCatTotal = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  } else {
    this.categoryObj = [];
    this.slCatTotal = 0;
  }
}

getProByCategoryId(val) {
  this.categoryId = val;
  //this.getProductList(this.selectedCompany, this.categoryId);
  this.getProductList();
}
getBusinessDomain(){
  this.ngxSpinnerService.show();
  if(this.selectedCompany!=''){
    let self = this;
    this.master.getBusinessDomain(100,0,this.selectedCompany).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.data){
      self.domainData = data.data.rows;
      }else{
        this.domainData=[];
      }
    }, error => {
      this.domainData=[]
    });
  }else{
    this.domainData=[]
  }

  this.ngxSpinnerService.hide();

}
getListByDomainId(val){
  this.domain_id=val;
  this.getProductList();
}
}
