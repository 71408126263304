import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';


@Component({
  selector: 'app-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.css']
})


export class AddOperatorComponent extends BaseComponent implements OnInit {

  operator_id;
  operatorGroupObj;
  operator: any;
  operatorForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;

  operatorObj:any=[];
  constructor(

    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private segmentService: SegmentationService

  ) {
    super();
  }

  ngOnInit() {


    //this.ngxSpinnerService.show();

    let self = this;


    if(localStorage.getItem("deleteOperatorId")) this.operator_id = localStorage.getItem("deleteOperatorId")
    if (this.operator_id) {
      this.ngxSpinnerService.show();
      this.UpdateOperatorLogData()


      this.segmentService.getOperatorId(this.operator_id).subscribe(response => {

        if (response.status) {
          this.actionStatus = false;
          self.operator = response.data[0];
          this.status =   self.operator.isactive
          self.dataBinding();

        }
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addOperatorLogData()
    }
    this.operatorForm = this.formBuilder.group({
      operator_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      operator_alias: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],

      operator_desc: [''],
      operator_group_id:['',Validators.required]

    });

    this.getOperator();
  }
  get f() { return this.operatorForm.controls; }



  addOperatorLogData() {

    let operatorLogs = {
      log_type: "Adding New Operator",
      log_name: "Adding New Operator",
      log_description: "Adding New Operator",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(operatorLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });

  }

  UpdateOperatorLogData() {

    let operatorLogs = {
      log_type: "Updating Operator",
      log_name: "Updating Operator",
      log_description: "Updating Operator",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(operatorLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  addOperator() {
    this.submitted = true;

    if (this.operatorForm.invalid) {
      return;
    }

    let { operator_name, operator_desc,operator_alias,operator_group_id} = this.operatorForm.value;


    this.ngxSpinnerService.show();

    let operatorBody = {
      operator_name: operator_name,
      operator_desc: operator_desc,
      operator_alias: operator_alias,
      operator_group_id: operator_group_id,
      status:true

    }

    this.segmentService.addingOperator(operatorBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/operator`);
      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  dataBinding() {

      this.operatorForm = this.formBuilder.group({
        operator_name: [this.operator.operator_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        operator_alias: [this.operator.operator_alias,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        operator_desc: [this.operator.operator_desc],
        operator_group_id:[this.operator.operator_group_id,Validators.required]

      });

  }
  UpdateOperator() {

    this.submitted = true;
    if (this.operatorForm.invalid) {
      return;
    }
    let { operator_name, operator_desc,operator_alias,operator_group_id} = this.operatorForm.value;

    let operatorBody = {
      operator_name: operator_name,
      operator_desc: operator_desc,
      operator_alias: operator_alias,
      operator_group_id: operator_group_id,
      status:this.status

    }

    this.ngxSpinnerService.show();
    this.segmentService.updateOperator(operatorBody,this.operator_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/operator`);

      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();

    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

getOperator() {

  this.ngxSpinnerService.show();
  this.segmentService.getOperatorGroupList(9999,0, 'createdon', 'desc').subscribe(data => {
    if(data.status) {

    this.operatorObj = data.data.rows.filter(item=>item.isactive==true);

    }
    this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    this.ngxSpinnerService.hide();

  });
}
}

