
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/events">Manage Event</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/add-event">{{header}} Event</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/theme-list">Event theme</a></li>
            <li class="breadcrumb-item active" aria-current="page">Event preview</li>
        </ol>
    </nav>
</div>

<div class="container">
    <div class="row">
        <div class="col-12 col-xl-9 col-lg-10 mt-1">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mt-1">
                                <div class="col-md-12 font-weight-bold">Event Name :</div>
                                <div class="col-md-12">{{eventData?.event_name}}</div>
                            </div>
                            <div class="col-md-6 mt-1">
                                <div class="col-md-12 font-weight-bold">Category :</div>
                                <div class="col-md-12">{{campaign}}</div>
                            </div>
                            <div class="col-md-6 mt-1">
                                <div class="col-md-12 font-weight-bold">Host :</div>
                                <div class="col-md-12">{{contactData?.full_name}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.event_url_id">
                                <div class="col-md-12 font-weight-bold">Event Id :</div>
                                <div class="col-md-12">{{eventData?.event_url_id}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.event_url">
                                <div class="col-md-12 font-weight-bold">Event URL :</div>
                                <div class="col-md-12">{{eventData?.event_url}}</div>
                            </div>
                            <div class="col-md-6 mt-1">
                                <div class="col-md-12 font-weight-bold">Date / Time :</div>
                                <div class="col-md-12">  {{eventData.event_date | date}} 
                                    {{eventData.event_start_time }}  
                                    {{ eventData.event_time_zone_abbr }}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.parent_event_type!=128">
                                <div class="col-md-12 font-weight-bold">Address 1 :</div>
                                <div class="col-md-12">{{eventData?.event_address1}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.parent_event_type!=128">
                                <div class="col-md-12 font-weight-bold">Address 2 :</div>
                                <div class="col-md-12">{{eventData?.event_address2}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.parent_event_type!=128">
                                <div class="col-md-12 font-weight-bold">Country :</div>
                                <div class="col-md-12">{{country}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.parent_event_type!=128">
                                <div class="col-md-12 font-weight-bold">City, State, Zip :</div>
                                <div class="col-md-12">{{eventData?.event_city}}, {{eventData?.event_state}}, {{eventData?.event_zip}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.event_shopping_url">
                                <div class="col-md-12 font-weight-bold">Shopping URL :</div>
                                <div class="col-md-12">{{eventData?.event_shopping_url}}</div>
                            </div>
                            <div class="col-md-6 mt-1" *ngIf="eventData?.pws">
                                <div class="col-md-12 font-weight-bold">Host URL :</div>
                                <div class="col-md-12">{{eventData?.pws}}</div>
                            </div>
                            <div class="col-md-12 mt-1" *ngIf="eventData?.event_description">
                                <div class="col-md-12 font-weight-bold">Description :</div>
                                <div class="col-md-12">{{eventData?.event_description}}</div>
                            </div>
                            <div class='col-md-6 mt-1' *ngIf="selectedImage">
                                <div class="col-md-12 font-weight-bold">Image/Video :</div>
                                <div class="col-md-6">
                                    <img *ngIf="selectedImage.resource_type=='image'" [src]="selectedImage?.resource_preview_url" width='200px' height='auto'/>
                                    <video *ngIf="selectedImage.resource_type=='video'" height="auto" width="100%" [controls]='true'>
                                      <source [src]="selectedImage?.resource_preview_url" type="video/mp4">
                                      Your browser does not support the video.
                                    </video>
                                </div>

                            </div>
                            <div class="col-md-6 mt-1" *ngIf="selectedThemeData">
                                <div class="col-md-12 font-weight-bold">Theme :</div>
                                <div class="col-md-12">{{selectedThemeData?.theme_name}}</div>
                                <div class="col-md-12">
                                    <img src="{{selectedThemeData.theme_screenshot}}"
                                    (error)="setValue($event)" width="200px"
                                    title="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="form-group text-center mb-0">
                          <button type="button" (click)="ConfirmCampaign()" class="btn btn-round btn-margo-action">Confirm</button>
                          <button type="button" (click)="onCancel()" class="btn btn-round btn-margo-cancel">Cancel</button>
                        </div>
                      </div>  
                </div>
            </div>
        </div>

    </div>
</div>  
