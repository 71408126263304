import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-contact-usage',
  templateUrl: './contact-usage.component.html',
  styleUrls: ['./contact-usage.component.css']
})
export class ContactUsageComponent  implements OnInit {
  companyID=0;
  companyId;
  dateArr: string[];
  pendingrecord=0;
  currentUser;
  company_status:boolean=false;
  cpdata={customer_id:null};
  maxdate = new Date();
  getCompanyobj;  
  customerID;
  obj;
  reportData;
  p: number = 1;
  total: number;
  status="select";
  start_date;
  end_date;
  clicked=false;
  total_contacts:number=0;    
  Consumetotal;
  total_used_deleted:number=0;
  user_type='all';
  addData=0;
  showResult:boolean=true;
  isHeadquater;
  
  constructor(private report:ReportService,
    private clientService : ClientService,
    public toastrService: ToastrService,
    private datePipe: DatePipe,
    private spinner : NgxSpinnerService) { }
    
    ngOnInit() {  
      this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
     
      this.companyID= this.currentUser.admin_customer_id; 
      this.companyId= this.currentUser.admin_customer_id; 

      var my_date = new Date();
      this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());
  // alert(this.companyId);
    
   this.isHeadquater=localStorage.getItem("customer_isheadquarter");
  if(this.companyID==0 || this.isHeadquater ==="true"){
        this.getCompany(this.companyID);
        this.companyID = 0;
        this.showResult=false;
        this.isHeadquater = true;
        console.log("ddddd...", this.isHeadquater)
        //this.addrecords(3);
      //this.spinner.hide();
      }else{   
        this.showResult=true;   
        this.isHeadquater = false;     
        this.gettingReports(1,this.companyID);
        // setTimeout(()=>{   
        //   this.spinner.show(); 
        // }, 1000);
      //this.spinner.hide();
    }

 }
 
  getFirstLastDateOfMonth(month, year) {
    var my_date = new Date(year, month, 1);
    var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    let formatted_first_date = first_date.getFullYear() + "-" + (first_date.getMonth() + 1) + "-" + first_date.getDate();

    var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    let formatted_last_date = last_date.getFullYear() + "-" + (last_date.getMonth() + 1) + "-" + last_date.getDate();
  //  console.log(formatted_first_date, formatted_last_date);
    return ([formatted_first_date, formatted_last_date]);
  }

  gettingReports(page,id) {
    this.companyID = id;

    this.companyId =id;

    if(this.companyId==0) {
      this.toastrService.error("Please select company");
      return false;
    }

    const parmas = {
      customer_id: this.companyID,
      page: page,
      start_date: this.start_date,
      end_date: this.end_date,
      csv : false
    }; 
    if(this.start_date>this.end_date){
      setTimeout(() => {
        this.toastrService.error("Please select fromdate less than todate");
      }, 1000);
      return false;
    }
    this.addrecords(this.companyID);
  }

  addrecords(custid:any){
  // alert('hi2');
  // this.spinner.show();
    //this.datePipe.transform(this.start_date, 'yyyy-MM-dd')
    this.spinner.show();

    if(this.start_date) var startDate =this.datePipe.transform(this.start_date, 'yyyy-MM-dd');
    else var startDate ="undefined";

    if(this.end_date) var endDate =this.datePipe.transform(this.end_date, 'yyyy-MM-dd');
    else var endDate ="undefined";

    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        addRecords: this.report.addrecords(custid,startDate,endDate),
        deletedRecords:this.report.getMargoUsedcontacts(custid,startDate,endDate),
        begingingRecords:this.report.beginingrecords(custid)
        //attribute: this.cb.getCompanyFieldAttribute(id)
      }
    ).subscribe(data => {
      this.showResult=true; 
    // console.log(data, 'forkJoin')
      if(data.begingingRecords.datavalue == undefined){
        this.total_contacts= 0 ; 
      }else{
        this.total_contacts= parseInt(data.begingingRecords.datavalue);     
      }
        if(data.addRecords.data) this.addData = data.addRecords.data;
        else this.addData =0;

        this.clicked=true;      
        this.total_used_deleted= parseInt(data.deletedRecords.data);

        this.pendingrecord =  data.begingingRecords.datavalue;
        if(data.addRecords.data) this.pendingrecord += data.addRecords.data; 
            
        this.pendingrecord -= data.deletedRecords.data;

        this.spinner.hide();
    }), error => {
      //console.log(error);
      this.spinner.hide();
    }
  }

  getCompany(companyId:any) {    
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;

    }, error => {
     // console.log(error);
    });
  }

  resetReport(){
    //this.spinner.show();
    this.start_date = "";
    this.end_date = "";
    this.companyID = 0;
    var my_date = new Date();
    this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());
  // alert(this.companyId);
   // this.getCompany(this.companyId);

    this.gettingReports(1,this.companyID);
  }
}