import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';

import { BaseComponent } from '../base.component';
import { ClientService } from 'src/app/services/clients/client.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends BaseComponent implements OnInit {

  constructor(
    private clientService:ClientService
  ) {super(); }

  companyID;
  getCompanyobj;
  customerID;
  obj;
  marketReportShow=true;
  projectName=environment.projectName

  ngOnInit() {
    localStorage.setItem('comapny_id',  "");
     var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
     this.customerID= customerInfo.admin_customer_id; 

     if(customerInfo.role_id==102) this.marketReportShow=false;
     
  
  }

  

}
