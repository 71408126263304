<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/master-segmentation">Segmentation Master</a></li>
      <li class="breadcrumb-item active" aria-current="page">Entities Attribute Association List</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">

  <div class="row">
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Associate Entities & Attribute" *ngIf="isWrite" class="custbtn ml-0" routerLink="/corporate/add-entities-attribute-association">

    </div>
  </div>

  <div></div>

  <div class="row">
    <div class="col-md-4 mt-3 mb-3">
    <div>


      <select name="company" class="form-control formstyle" [(ngModel)]="eid" (change)="getAssociatedAttribute($event.target.value)">
        <option value="">All Selected</option>
        <option *ngFor="let userObj of entities" value={{userObj.entity_id}}>
          {{userObj.entity_name}}
        </option>
      </select>


   



  </div></div>


  <!-- <div class="col-md-7 mt-md-2 mt-0 mb-md-0 mb-3" style="justify-content: right;">
    <input type="button" value="Associate Entities & Attribute" *ngIf="isWrite" class="custbtn ml-0 float-md-right" routerLink="/corporate/add-entities-attribute-association">


  </div> -->


</div>


  <div></div>
<div class="card accordHeadMain" *ngIf="fullAssocList.length > 0">
  <h4 class="pl-3 pt-3">Entities - Attribute Association Full List</h4>
  <ngb-accordion
    activeIds="ngb-panel-0"
    #myaccordion="ngbAccordion"
  >
    <ngb-panel title="{{obj.entity_name}}" id="ngb-panel-0" *ngFor="let obj of fullAssocList">
      <ng-template ngbPanelTitle>
          <span>
            <i class="fa fa-edit mr-1 ml-2"
               *ngIf="isEdit"
               (click)="editAssociationById(obj.entity_id)"
               placement="bottom"
               ngbTooltip="Edit">
            </i>
          </span>
      </ng-template>
      <ng-template ngbPanelContent >
        <ul class="attrList">
          <li *ngFor="let attr of obj.attributeList" >
            {{attr.field_name}}
          </li>
        </ul>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>

  <div class="card" *ngIf="show">
    <div class="col-md-7">
      <div class="row mt-3 mb-3">
        <h5 class="mt-1 mb-1 ml-3">Associated Attributes
          <span style="cursor: pointer">
            <i class="fa fa-edit mr-1"
               *ngIf="isEdit"
               (click)="editAssociation()"
               placement="bottom"
               ngbTooltip="Edit">
            </i>
          </span>
        </h5>

      </div>

      <div class="piInnerList scroller">
        <ul class="filtMain">
          <li *ngFor="let item of attributeObj">

                {{item.field_name}}

          </li>
          <li *ngIf="attributeObj?.length === 0">No Result Available</li>
        </ul>
      </div>

    </div>
  </div>

</div>
