import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/services/clients/client.service';
@Component({
  selector: 'app-newmessagelib',
  templateUrl: './newmessagelib.component.html',
  styleUrls: ['./newmessagelib.component.css']
})
export class NewmessagelibComponent extends BaseComponent implements OnInit {

  help_type;
  message_body;
  IsActive;
  help_message_id;
  messagelibObj;
  status: boolean = true;
  AddMessage: FormGroup;
  submitted = false;
  currentUserID;
  getCompanyobj;
  company="";
  currentUser;
  controlEnabled:boolean=true;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private alertService: AlertService,) { 
      super();
    }

  ngOnInit() {
    let self = this;
    this.controlEnabled = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    //this.companyID = this.currentUser.admin_customer_id; 
    this.currentUserID = this.currentUser.admin_customer_id; 
    if(this.currentUserID==0) self.getCompany();

    this.activatedRoute.queryParams
      .subscribe(params => {
        if(params.help_message_id) {
        this.company = params.companyID;
        this.help_message_id = params.help_message_id;
        }
      });
    //get item details using id
    if (this.help_message_id) {
      this.ngxSpinnerService.show();

      let obj = {
        company: this.company,
        id:this.help_message_id
      }
      this.master.getMessagelibById(obj).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.messagelibObj = response.data;
        //  console.log(self.messagelibObj)
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }
      });
    }
    this.AddMessage = this.formBuilder.group({
      company: ['', Validators.required],
      message_body: ['', [Validators.required,this.noWhitespaceValidator]],
      help_type: ['',Validators.required]
    });
  }
  get f() { return this.AddMessage.controls; }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     // console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  addNewMessagelib() {
    this.submitted = true;
   // console.log(this.AddMessage);
    
    if (this.AddMessage.invalid) {
      return;
    }
    let messagelibObj = {
      name: this.AddMessage.value.help_type,
      description: this.AddMessage.value.message_body,
      isactive: this.IsActive,
      company: this.AddMessage.value.company,
      is_corporate:true

    }
    this.ngxSpinnerService.show();
    this.master.addingMessagelib(messagelibObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/messagelib`);
      } else{
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    // this.help_type = this.messagelibObj.help_type;
    // this.message_body = this.messagelibObj.message_body;
    // this.IsActive = this.messagelibObj.isactive;
    this.AddMessage = this.formBuilder.group({
      message_body: [this.messagelibObj.message_body, [Validators.required,this.noWhitespaceValidator]],
      help_type: [this.messagelibObj.help_type,Validators.required],
      company: [ this.company, Validators.required],
      
    });this.IsActive = this.messagelibObj.isactive;
  }
  updateMessagelib() {
    this.submitted = true;
  //  console.log(this.AddMessage);
     
    if (this.AddMessage.invalid) {
      return;
    }
      let messagelibObj = {
        help_type: this.AddMessage.value.help_type,
        message_body: this.AddMessage.value.message_body,
        isactive: this.IsActive,
        help_message_id: this.help_message_id,
        company: this.company,
      }
      this.ngxSpinnerService.show();
      this.master.UpdateMessagelib(messagelibObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/messagelib`);
          this.ngxSpinnerService.hide();
        }else{
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }
    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }
}

