<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Reskinning</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <!-- <div class="col-md-12">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

    </div> -->


    <!-- <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn" routerLink="/corporate/addnewreskinning">
    </div> -->
  </div>

  <div class="row">
    <div class="col-md-5 mt-2" *ngIf="this.currentUser.admin_customer_id==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getDirectSellerList($event.target.value)">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-5 mt-2" *ngIf="page=='direct-seller'">
      <div>
        <select name="dsid" class="form-control formstyle" (change)="getCompanyThemes($event.target.value)">
          <option value="">Select Direct Seller</option>
          <ng-container *ngFor="let userObj of getDSobj">
            <option  value={{userObj.user_ds_id}}>
                {{userObj.user_fname}} {{userObj.user_lname}}, {{userObj.user_email_primary}} - {{userObj.user_ds_id}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>





  </div>
</div>




<div class="tblPrt" *ngIf="themeObj!=undefined && themeObj!=''" class="col-md-12">

  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="background-color: #fff;">

    <tr>
      <td colspan="3" style="text-align: right;">
          <input type="button" value="Edit" *ngIf="isWrite" class="custbtn" (click)="edit(themeObj.reskininig_id)">
      </td>
    </tr>


    <tr>
      <td  colspan="3" class="main_heading"> Common  </td>
    </tr>

    <tr>
      <td class="srno"> 1. </td>
      <td class="heading"> Header Background Color </td>
      <td>
        <span [ngStyle]="{'background-color': themeObj.header_background_color}" class="colorbox">
          {{themeObj.header_background_color}}
        </span>
      </td>
    </tr>

    <tr>
      <td class="srno"> 2. </td>
      <td class="heading"> Header Right Side Icon Color </td>
      <td> <span [ngStyle]="{'background-color': themeObj.icon_color}" class="colorbox">
          {{themeObj.icon_color}}
        </span>
      </td>
    </tr>

    <tr>
      <td class="srno"> 3. </td>
      <td class="heading"> Logo </td>
      <td> <img src="{{themeObj.logo}}" style="width: 100px;"></td>
    </tr>

    <tr>
      <td class="srno"> 4. </td>
      <td class="heading"> Margo Logo Status</td>
      <td *ngIf="themeObj.logo_status==true"> Active </td>
      <td *ngIf="themeObj.logo_status!=true"> In-Active </td>
    </tr>

    <tr>
      <td class="srno"> 5. </td>
      <td class="heading"> Model Logo Status </td>
      <td *ngIf="themeObj.model_logo_flag==true"> Active </td>
      <td *ngIf="themeObj.model_logo_flag!=true"> In-Active </td>
    </tr>

    <tr>
      <td class="srno"> 6. </td>
      <td class="heading"> Model Icon </td>
      <td> <img src="{{themeObj.model_logo}}" style="width: 100px;"></td>
    </tr>


    <tr>
      <td class="srno"> 7. </td>
      <td class="heading"> Background Image </td>
      <td> <img src="{{themeObj.background_image}}" style="width: 100px;"></td>
    </tr>



    <tr>
      <td class="srno"> 8. </td>
      <td class="heading"> Profile Picture OutLine Color </td>
      <td>
        <span [ngStyle]="{'background-color': themeObj.picture_outline_color}" class="colorbox">
          {{themeObj.picture_outline_color}}
        </span>
      </td>
    </tr>



    <tr>
      <td class="srno"> 9. </td>
      <td class="heading"> Scroll bar Color </td>
      <td>
        <span [ngStyle]="{'background-color': themeObj.scroll_color}" class="colorbox">
          {{themeObj.scroll_color}}
        </span>
      </td>
    </tr>


    <tr>
      <td class="srno"> 10. </td>
      <td class="heading"> Event button on my event Page (Edit/Cancel/Delete)</td>
      <td *ngIf="themeObj.event_flag==true"> Active </td>
      <td *ngIf="themeObj.event_flag!=true"> In-Active </td>
    </tr>









    <tr>
      <td  colspan="3" class="main_heading"> Landing Page </td>
    </tr>

    <tr>
      <td class="srno"> 1. </td>
      <td class="heading"> Landing Page Text </td>
      <td>{{themeObj.ds_alias_name}}</td>
    </tr>

    <tr>
      <td class="srno"> 2. </td>
      <td class="heading"> Landing Page Text Color </td>
      <td>
        <span [ngStyle]="{'background-color': themeObj.dashboard_text}" class="colorbox">
          {{themeObj.dashboard_text}}
        </span>
      </td>
    </tr>

    <tr>
      <td class="srno"> 3. </td>
      <td class="heading">  Hide/Show option for Password and change profile picture
        (Profile Page) </td>
      <td *ngIf="themeObj.profile_obj==true"> Active </td>
      <td *ngIf="themeObj.profile_obj!=true"> In-Active </td>
    </tr>








    <tr>
      <td  colspan="3" class="main_heading"> Market </td>
    </tr>





    <tr>
      <td class="srno"> 1. </td>
      <td class="heading"> Contact heading text </td>
      <td> {{themeObj.contact_heading_text}}</td>
    </tr>

    <tr>
      <td class="srno"> 2. </td>
      <td class="heading"> Contact heading text color </td>
      <td><span [ngStyle]="{'background-color': themeObj.contact_heading_text_color}" class="colorbox">
        {{themeObj.contact_heading_text_color}}
      </span>
    </td>
    </tr>

    <tr>
      <td class="srno"> 3. </td>
      <td class="heading"> Team member heading text </td>
      <td> {{themeObj.team_heading_text}} </td>
    </tr>

    <tr>
      <td class="srno"> 4. </td>
      <td class="heading"> Team member heading text color </td>
      <td><span [ngStyle]="{'background-color': themeObj.team_heading_text_color}" class="colorbox">
        {{themeObj.team_heading_text_color}}
      </span>
    </td>
    </tr>

    <!-- <tr>
      <td class="srno"> 5. </td>
      <td class="heading"> Page content color </td>
      <td><span [ngStyle]="{'background-color': themeObj.page_content_font_color}" class="colorbox">
        {{themeObj.page_content_font_color}}
      </span>
    </td>
    </tr> -->

    <tr>
      <td class="srno"> 5. </td>
      <td class="heading"> Tab color </td>
      <td><span [ngStyle]="{'background-color': themeObj.tab_color}" class="colorbox">
        {{themeObj.tab_color}}
      </span>
    </td>
    </tr>

    <!-- <tr>
      <td class="srno"> 6. </td>
      <td class="heading"> Tab text color </td>
      <td><span [ngStyle]="{'background-color': themeObj.tab_text_color}" class="colorbox">
        {{themeObj.tab_text_color}}
      </span>
    </td>
    </tr> -->



    <tr>
      <td class="srno"> 6. </td>
      <td class="heading"> Button color </td>
      <td><span [ngStyle]="{'background-color': themeObj.button_color}" class="colorbox">
        {{themeObj.button_color}}
      </span>
    </td>
    </tr>

    <tr>
      <td class="srno"> 7. </td>
      <td class="heading"> Button text color </td>
      <td><span [ngStyle]="{'background-color': themeObj.button_text_color}" class="colorbox">
        {{themeObj.button_text_color}}
      </span>
    </td>
    </tr>





<!--
    <tr>
      <td class="srno"> 18. </td>
      <td class="heading"> Action </td>
      <td>
        <div class="actBtn">
          <i class="fa fa-edit mr-1" (click)="edit(themeObj.reskininig_id)" placement="bottom" ngbTooltip="Edit"></i>
        </div>
      </td>
    </tr> -->

  </table>
</div>

<div class="tblPrt" *ngIf="themeObj==undefined" class="col-md-12">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="background-color: #fff;">
    <tr>
      <td style="text-align: center;">
        <!-- No Result Available -->
        <input type="button" value="Add New" class="custbtn" routerLink="/corporate/addnewreskinning">
      </td>
    </tr>
  </table>
</div>
