import { Component, OnInit } from "@angular/core";

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { stat } from "fs";
import { ClientService } from "src/app/services/clients/client.service";
import { MetaDataService } from "src/app/services/metadata/metadata.service";
import { BaseComponent } from "../../base.component";



@Component({
    selector: 'app-add-metadata',
    templateUrl: './addMetaData.component.html',
    styleUrls: ['./addMetaData.component.css']
})
export class AddMetaDataComponent extends BaseComponent implements OnInit{


    cp =1;
    text = '';
    customerID: any;
    getCompanyobj: any;
    buForm: FormGroup;
    dataMappingForm: FormGroup;
    dynForm: FormGroup;
    metaForm: FormGroup;
    selectedBuId = '';
    selectedTable = '';
    selectedTableId = '';
    // selectedClientTable = '';
    buMappingData = [];
    buMappingClData = [];
    buTables = [];
    colMapData = [];
    metaMapData = [];
    submitted = false;
    fieldsType = [
        {id: 'VARCHAR', name: 'Text'},
        {id: 'INT', name: 'Number'},
        {id: 'NUMERIC', name: 'Float'},
        {id: 'timestamp', name: 'Timestamp'},
        {id: 'BOOLEAN', name: 'Boolean'}
    ]
    constructor( private metaService: MetaDataService, private formBuilder: FormBuilder, 
                 private clientService: ClientService) {
        super();
        let sid = JSON.parse(localStorage.getItem('userData'));
        this.customerID = sid.admin_customer_id;
    }

    ngOnInit() {

        this.ngxSpinnerService.show();
        this.buForm = this.formBuilder.group({
            buid: [''],
            selTable: [''],
            selClientTable: ['']
          });
        this.getCompanyList();
       // this.createForm();
        this.createMetaForm();
        this.createDynForm();
    }

    get f() { return this.dataMappingForm.controls; }
    get g() { return this.metaForm.controls; }
    get t() { return this.g.metaFields as FormArray; }
    get a() { return this.dynForm.controls; }
    get b() { return this.a.dfields as FormArray;}

    //  ##############  Create Dynamic Form ###########  //

    createDynForm() {
        this.dynForm = this.formBuilder.group({
            dfields: new FormArray([])
        });
    }

    //  ##############  META FORM #####################  //

    createMetaForm() {
        this.metaForm = this.formBuilder.group({
            numberOfFields: [0],
            metaFields: new FormArray([])
        });
    }


    // ##########  Get BU Company List  #####################  //

    getCompanyList() {
        this.clientService.getBUCompanies().subscribe(data => {
            if (data.status) {
              this.getCompanyobj = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.getCompanyobj = [];
            this.ngxSpinnerService.hide();
          });
    }

    //  ############  On BU Selected from Dropdown ###################  //

    onBuSelected() {
        this.ngxSpinnerService.show();
        this.selectedBuId = this.buForm.value.buid;
      //  this.getBuMappingColumns(this.selectedBuId);
        this.getBUTablesListing(this.selectedBuId);
    }

    //  ###########  Get BU Tables Listing ###########################  //

    getBUTablesListing(buId) {
        this.buTables = [];
        this.buForm.get('selTable').patchValue('');
       // this.buForm.get('selClientTable').patchValue('');
       this.selectedTableId = '';
        this.clearDynForm();
        this.clearMetaForm();
        this.buMappingData = [];
        this.buMappingClData = [];
        this.metaMapData = [];
        this.colMapData = [];
        this.selectedTable = '';
      //  this.selectedClientTable = '';
        if (buId === '' || buId === undefined || buId === null) {
            this.ngxSpinnerService.hide();
            return;
        }
        this.metaService.getBUTablesListing(buId).subscribe(data => {
            if (data.status) {
                this.buTables = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ###########  Select BU Table for Mapping #################  //

  onBuTableSelected() {
    this.ngxSpinnerService.show();
    this.selectedTable = this.buForm.value.selTable;
    let tb = this.buTables.filter(elem => elem.table_alias === this.selectedTable);
    if (tb && tb.length > 0) {
      this.selectedTableId = tb[0].table_id;
    }
  //  this.selectedClientTable = '';
  //  this.buForm.get('selClientTable').patchValue('');
    this.buMappingData = [];
    this.buMappingClData = [];
    this.metaMapData = [];
    this.colMapData = [];
    this.clearDynForm();
    this.clearMetaForm();
    if (this.selectedTable === '' || this.selectedTable === undefined || this.selectedTable === null) {
      this.selectedTableId = '';
      this.ngxSpinnerService.hide();
      return;
    }

    this.metaService.getBUMappingColumns(this.selectedBuId, this.selectedTable).subscribe(data => {
      if (data.status) {
        this.buMappingData = data.data;
        this.getClientTableColumnData();
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Fetch Columns from Clients Tables (Can be Multiple) for Meta Mapping  ########  //

  getClientTableColumnData() {

    this.metaService.getClientMappingColumns(this.selectedBuId, this.selectedTableId).subscribe(data => {
      if (data.status) {
        this.buMappingClData = data.data;
       // console.log('CLIENT TABLES FULL MAPPING COLUMNS', this.buMappingClData);
        this.getMetaColumnMapping();
        this.getColumnMapping();
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  /*  //  ############ Select Client Table from List to fetch Mapping ####   //

  onClientTableSelected() {
    this.ngxSpinnerService.show();
    this.selectedClientTable = this.buForm.value.selClientTable;
    this.submitted = false;
    this.buMappingClData = [];
    this.metaMapData = [];
    this.colMapData = [];
    this.clearDynForm();
    this.clearMetaForm();
    if (this.selectedClientTable === '' || this.selectedClientTable === undefined || this.selectedClientTable === null) {
      this.ngxSpinnerService.hide();
      return;
    }

    let tb = this.buTables.filter(elem => elem.table_alias === this.selectedTable);

    this.metaService.getClientMappingColumns(this.selectedBuId, tb[0].table_id).subscribe(data => {
      // console.log('MAP DATA', data);
      if (data.status) {
        this.buMappingClData = data.data;
        this.getMetaColumnMapping();
        this.getColumnMapping();
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }  */

    //  ############  Get Mapping for Table Columns     ################   //

    getColumnMapping() {
        this.metaService.getMappedColumnsForBuTable(this.selectedBuId, this.selectedTable).subscribe(data => {
            if (data.status) {
              this.colMapData = data.data;
            //  console.log('COL MAP', this.colMapData);
            } else {
              this.toastrService.error(data.message);
            }
            this.addDynFieldstoForm();
            
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ############  Get Mapping for Table Meta Columns  ###############  //

    getMetaColumnMapping() {
        this.metaService.getMappedMetaColumnsForBuTable(this.selectedBuId, this.selectedTable).subscribe(data => {
            if (data.status) {
              this.metaMapData = data.data;
              // console.log('MAP', this.metaMapData);
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ############  Create Dynamic fields for Mapping #################  //

    addDynFieldstoForm() {
        if (this.b.length > 0) {
            this.clearDynForm();        
        }

             //console.log('COL MAP DATA', this.colMapData);
            // console.log('BU MAP DATA', this.buMappingData);
           //  console.log('BU MAP CLIENT DATA', this.buMappingClData);
        for (let fd of this.buMappingData) {
          let mval:any = '';
          let obj = this.colMapData.filter(elem => elem.column_name  === fd.column_id);
          //console.log('First Filter', obj);
          if (obj.length === 1) {
            let obj2 = this.buMappingClData.filter(elem => elem.column_id === obj[0].map_with && elem.table_name === obj[0].map_with_tbl);
            if (obj2.length === 1) {
              let ob = obj2[0];
              mval = this.buMappingClData.indexOf(ob);
              //console.log('SEL OBJ', obj2);
              //console.log('IDX SEL', mval);
            }
          }
            this.b.push(this.formBuilder.group({
                fname: [{value: fd.column_id, disabled: true}, Validators.required],
                dmapField: [mval],
                fldnm: [fd.label_name]
            }));
        }
        this.ngxSpinnerService.hide();
    }

    //  ############  Clear Dynamic Mapping Form ###################  //

    clearDynForm() {
        if (this.submitted) !this.submitted;
        this.dynForm.reset();
        this.b.clear();
    }

    //  ############  Clear Meta Data Mapping Form ###################  //

    clearMetaForm() {
        if (this.submitted) !this.submitted;
        this.metaForm.reset();
        this.t.clear();
    }

    //  ############  ADD New Meta Data Field  #####################  //

    addNewMetaField() {
        let no = this.metaForm.value.numberOfFields + 1;
        this.metaForm.get('numberOfFields').patchValue(no);
        this.t.push(this.formBuilder.group({

            name: ['', [Validators.required, this.checkNum]],
            metafname: ['', [Validators.required, this.checkNum]],

            ftype: ['', Validators.required],
            fsize: [1, Validators.required],
            mapfield: ['', Validators.required],
            viewonds: [true, Validators.required]
        }));
    }

    //  ############  On Inputting Name field for Meta  ###########  //

    onNameInput(i) {
      if (this.t.controls[i]['controls'].metafname.value !== '') {
        this.t.controls[i]['controls'].metafname.patchValue('');
      }
    }

    //  ############  Check for Meta Field Name ###################  //

    checkForMetaField(i) {
      this.t.controls[i]['controls'].name.patchValue(this.t.controls[i]['controls'].name.value.trim());
      if (this.t.controls[i]['controls'].name.value === '') {
        this.toastrService.error('Please provide correct field Name');
        if (this.t.controls[i]['controls'].metafname.value !== '') {
          this.t.controls[i]['controls'].metafname.patchValue('');
        }
        return;
      }
      let obj = {
        "table_name": this.selectedTable,
        "column_name": this.t.controls[i]['controls'].name.value 
      }
      this.metaService.checkMetaField(obj, this.selectedBuId).subscribe(data => {
        if (data.status) {
          this.t.controls[i]['controls'].metafname.patchValue(data.data.meta_field_name);
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
      });
    }

    //   ###########  Remove Meta Data Field  #####################  //

    removeMeta(idx) {
        this.t.removeAt(idx);
    }

    //  ##########  Confirm Meta Add/Update  #################   //

    confirmMetaAddition() {
      this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Update Meta Data Mapping?', 'Yes', 'No')
        .then((confirmed) => {
          if (confirmed) {
            this.ngxSpinnerService.show();
            this.addMapping();
          }
        }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    //  ##########  Add Mapping For BU Table #################  //

    addMapping() {
      this.submitted = true;
      for (let i = 0; i < this.t.controls.length ; i++) {
        let nm = this.t.controls[i]['controls'].name.value.trim();
        let mfnm = this.t.controls[i]['controls'].metafname.value.trim();
        if (nm === '' || nm === undefined || nm === null ){
          this.t.controls[i]['controls'].name.patchValue('');
        }
        if (mfnm === '' || mfnm === undefined || mfnm === null ){
          this.t.controls[i]['controls'].metafname.patchValue('');
        }
      } 
      if (this.dynForm.invalid || this.metaForm.invalid) {
        this.toastrService.error('Please provide correct values for Mapping');
        this.ngxSpinnerService.hide();
        return;
      }

      for (let i = 0; i <= this.t.controls.length - 1; i++) {
        let val = this.t.controls[i]['controls'].name.value.trim();
        let mpdt = this.metaMapData.filter(elem => elem.form_name === val);
        if (mpdt.length > 0) {
          this.toastrService.error('Label name is already used for Meta Field');
          this.t.controls[i]['controls'].name.patchValue('');
          this.ngxSpinnerService.hide();
          return;
        }
      }


      let st = false;
      for (let i = 0; i < this.t.controls.length - 1; i++) {
       let val = this.t.controls[i]['controls'].metafname.value.trim();
       let j = i + 1;
       for (;j < this.t.controls.length; j++) {
        let val2 = this.t.controls[j]['controls'].metafname.value.trim();
        if (val === val2) {
          this.t.controls[j]['controls'].metafname.patchValue('');
          st = true;
          break;
        }
       }
        if (st === true) {
          this.toastrService.error('Field Name for Meta Columns must be Unique');
          this.ngxSpinnerService.hide();
          return;
        }
      }

      for (let i = 0; i < this.t.controls.length - 1; i++) {
        let val = this.t.controls[i]['controls'].name.value.trim();
        let j = i + 1;
        for (;j < this.t.controls.length; j++) {
         let val2 = this.t.controls[j]['controls'].name.value.trim();
         if (val === val2) {
           this.t.controls[j]['controls'].name.patchValue('');
           st = true;
           break;
         }
        }
         if (st === true) {
           this.toastrService.error('Label Name for Meta Columns must be Unique');
           this.ngxSpinnerService.hide();
           return;
         }
       }
        
        let finobj = {
            "mapping_for": this.selectedTable,
            "mapping": []
        }
        let finArr = [];
        for (let dt of this.t.controls) {
            let obj = {
                "mapid": 0,
                "table_id": this.selectedTableId,
                "form_name": dt.value.name,
                "column_name": dt.value.metafname,
                "map_with": this.buMappingClData[dt.value.mapfield].column_id,
                "field_type": dt.value.ftype,
                "field_size": dt.value.fsize,
                "map_with_tbl": this.buMappingClData[dt.value.mapfield].table_name,
                "is_flag": dt.value.viewonds,
                "ismeta": true
            };
            finArr.push(obj);
        }
        for (let mp of this.b.controls) {
         // console.log('MAP', mp.value.dmapField);
          let ob = this.colMapData.filter(elem => elem.column_name === mp['controls'].fname.value);
            if (mp.value.dmapField !== '' || ob.length > 0) {
              let obj: any = {};
              if (mp.value.dmapField === '') {
                 obj = {
                  "mapid": 0,
                  "table_id": this.selectedTableId,
                  "column_name": mp['controls'].fname.value,
                  "map_with": mp.value.dmapField,
                  "map_with_tbl": '',
                  "is_flag": true,
                  "ismeta": false
                }
              } else {
                obj = {
                  "mapid": 0,
                  "table_id": this.selectedTableId,
                  "column_name": mp['controls'].fname.value,
                  "map_with": this.buMappingClData[mp.value.dmapField].column_id,
                  "map_with_tbl": this.buMappingClData[mp.value.dmapField].table_name,
                  "is_flag": true,
                  "ismeta": false
                }
              }
                
                if (ob.length > 0) {
                  obj.mapid = ob[0].mapid;
                }
                finArr.push(obj);
            }
        }
        
        finobj.mapping = finArr;

        
        this.metaService.addMapping(finobj, this.selectedBuId).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Mapping Added Successfully');
              this.ngxSpinnerService.hide();
              this.router.navigateByUrl('/corporate/metadata-list');

            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
            
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ###############  Delete Meta Data Field ####################  //

    onMetaDataFieldDelete(mapid) {


      this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete this Meta Field?', 'Yes', 'No')
          .then((confirmed) => {
            if (confirmed) {
              this.ngxSpinnerService.show();
              this.metaService.deleteMetaField(this.selectedBuId, mapid).subscribe(data => {
                if (data.status) {
                  this.toastrService.success('Meta Field Removed Successfully');
                  this.metaMapData = [];
                  this.getMetaColumnMapping();
                } else  {
                  this.toastrService.error(data.message);
                  this.ngxSpinnerService.hide();
                }
                
              }, error => {
               // console.log(error);
                this.ngxSpinnerService.hide();
              });
            }
          }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      }

      //  ############  On Meta Field Display on DS field Toggle   #############  //

      updateStatus(event, mapid,status) {
        event.preventDefault();
        
        this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status for Display on DS Field?', 'YES', 'NO')
          .then((confirmed) => {
            if (confirmed) {
              this.ngxSpinnerService.show();
              this.metaService.updateDisplayOnDsStatus(this.selectedBuId, mapid, !status).subscribe(data => {

                if (data.status) {
                  this.toastrService.success("Status Changed Successfully");
                  this.metaMapData = [];
                  this.getMetaColumnMapping();
                } else {
                  this.toastrService.error(data.message);
                  this.ngxSpinnerService.hide();
                }
              }, error => {
                this.toastrService.error(error);
                this.ngxSpinnerService.hide();
              });
            } 
          }).catch((err) => {
           
           //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
          });
    
    
      }


      allowNumbersOnly(e) {
        var code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }


    /* Check Numbers only value Validator */

  checkNum(control: FormControl) {
    let isnum = /^\d+$/.test(control.value);
    if (isnum === true ) {
      return {
        numCheck: {
          val: false
        }
      }
    }
    return null;
  }


}