import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';


@Component({
  selector: 'app-replenishmentcrosssell',
  templateUrl: './replenishment-crosssell.component.html',
  styleUrls: ['./replenishment-crosssell.component.css']
})
export class ReplenishmentCrosssellComponent extends BaseComponent implements OnInit{

  id: any;
  constructor() {
    super();
  }
  ngOnInit() {
    let sid = JSON.parse(localStorage.getItem('userData'));
    this.id = sid.admin_customer_id;
  }

  //  ############# Go to BU Selection or BU Segmentation Landing page #############  //

  goForAudience(){
    this.router.navigateByUrl('/corporate/replenishment-crosssell-audience');
  }

  goForCrossSell() {
    this.router.navigateByUrl('/corporate/cross-sell-list');
  }

  goForReplenishment() {
    this.router.navigateByUrl('/corporate/replenishment-list');
  }

  goForSetting() {
    this.router.navigateByUrl('/corporate/cross-sell-and-replenishment-setting');
  }
}
