import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { CampaignBuilderService } from 'src/app/services/segmentation/campaign-builder.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent extends BaseComponent implements OnInit {
  public campaignSettingObj: any = [];
  dataCount;
  Status: boolean = false;
  cp = 1;
  ord = true;
  colname: "Campaign Setting List";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  campaignType: any = 1;
  addNewStatus = false;
  crosselDropDown = false;


  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe,
    private clientService: ClientService,
    private campaignBuilderService: CampaignBuilderService
  ) {
    super();
  }
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getCamapignSetting();

    this.themeLogData();
    this.getCompany();
  }
  order: string = 'get_master_theme.theme_name';
  reverse: boolean = false;


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  themeLogData() {
    let themeLogs = {
      log_type: "Campaign Setting View",
      log_name: "Campaign Setting View",
      log_description: "Campaign Setting View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });

  }
  getCamapignSetting() {
    let self = this;
    this.ngxSpinnerService.show();
    this.campaignBuilderService.getBUCampaignSetting(this.companyID, 10, 0).subscribe(data => {
      self.campaignSettingObj = data;
      this.ngxSpinnerService.hide();
    }, error => {

      this.ngxSpinnerService.hide();
    });
  }

  getCampaignSetting(id) {
    // if (id != '') {
    //   this.addbtnStatus = true;
    // } else {
    //   this.addbtnStatus = false;
    // }

    this.ngxSpinnerService.show();
    if (id) {
      this.crosselDropDown = true;
      this.companyID = id;
      let self = this;
      this.campaignBuilderService.getBUCampaignSetting(this.companyID, 10, 0).subscribe(data => {
        // self.themeObj = data;
        if (data.status) {
          self.campaignSettingObj = data.data[0];
          this.Status = data.status;
          this.addNewStatus = false;

          // console.log("data", data.data[0]);
          // console.log("self.campaignSettingObj", self.campaignSettingObj);
        } else {
          this.Status = false;
          self.campaignSettingObj = undefined;
          this.addNewStatus = true;

        }

        this.ngxSpinnerService.hide();
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    } else {
      this.campaignSettingObj = [];
      this.ngxSpinnerService.hide();

    }
  }

  getCampaignSettingByAudType(type) {
    this.ngxSpinnerService.show();
    this.campaignType = type;
    let self = this;
    this.campaignBuilderService.getBUCampaignSettingByType(this.companyID, type).subscribe(data => {
      if (data.status && data.data) {
        self.campaignSettingObj = data.data[0];
        this.Status = data.status;
        this.addNewStatus = false;
      } else {
        this.Status = false;
        self.campaignSettingObj = undefined;
        this.addNewStatus = true;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }


  edit(id) {
    this.router.navigate(['/corporate/addnewsetting'], { queryParams: { companyID: this.companyID, id: id } });
  }

  addNew(id) {
    this.router.navigate(['/corporate/addnewsetting'], { queryParams: { companyID: this.companyID, type: id } });
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.campaignSettingObj.find(element => {
      if (element.theme_id === id) {
        return true;
      }
    });



    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;

          this.master.UpdateTheme(obj).subscribe(data => {
            // this.getTheme();
            this.getCampaignSetting(this.companyID)
            this.toastrService.success("Theme Master Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }


      }).catch((err) => {

      });


  }







  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      // console.log("--Get company Name list--", data.data);
    }, error => {
      //  console.log(error);
    });
  }
}
