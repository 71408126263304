import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseserviceService} from '../baseservice.service';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AudienceBuilderService{

  public baseUrl;
  public baseUrl1;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.segBaseUrl + 'api/segmentation/';
    this.baseUrl1 = baseService.baseUrl1;
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  // tohandle the error

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');

  }

  csvdownload(body) {

    return this.http.post<any>(this.baseUrl1 + `api/pii/csvdownload`,body,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

     
  }


 //  ############  GET ALL BU Audience  ############  //

 getBUAudience(company,limit,offset) {
  const URL = this.baseUrl  + 'audiencebuilder/'+company+"/"+limit+"/"+offset;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
}

//  ###########  Add  BU Audience  ##############  //

addingBUAudience(obj,id){
const URL = this.baseUrl + 'audiencebuilder/'+id;
const body = JSON.stringify(obj);
return this.http.post<any>(URL, body, this.httpHeadersOptions());
}


//  ###########  Add  BU Target Audience  ##############  //

getTargetAudienceList(company){

const URL = this.baseUrl  + 'buaudience/getall/'+company;
return this.http.get<any>(URL,  this.httpHeadersOptions())
  .pipe(
    map(data => {
      return data;
    }),
    catchError(this.handleError));
}



//  ###########  Add  BU Target Audience  ##############  //

getBUEntitiesList(company,limit,offset,id){

const URL = this.baseUrl  + 'buentities/getall/'+company+"/"+limit+"/"+offset+"/"+id;
return this.http.get<any>(URL,  this.httpHeadersOptions())
.pipe(
  map(data => {
    return data;
  }),
  catchError(this.handleError));
}
//  ###########  GET ATTRIBUTES OF SELECTED ENTITIES  ##############  //

getBUEntityAttributes(company,val){

  const URL = this.baseUrl  + 'buentities/allfieldById/'+company+"/"+val;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
  .pipe(
    map(data => {
      return data;
    }),
    catchError(this.handleError));
  }

  //  ###########  GET operatores OF SELECTED attributes  ##############  //

  getBUAttributeOperator(company,val){

  const URL = this.baseUrl  + 'buentities/alloperatorById/'+company+"/"+val;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
  .pipe(
    map(data => {
      return data;
    }),
    catchError(this.handleError));
  }




//  ###############  Delete audience builder  ####################  //

deleteAudienceBuilder(company,id){
  const URL = this.baseUrl  + 'audiencebuilder/' +company+ '/' + id;
  return this.http.delete<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
}

deleteAllAudienceQuery(company,id){
  const URL = this.baseUrl  + 'audiencebuilder/deleteall/' +company+ '/' + id;
  return this.http.delete<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
}


deleteQueryAudienceBuilder(company,id){
  const URL = this.baseUrl  + 'audiencebuilder/query/' +company+ '/' + id;
  return this.http.delete<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
}



//  ###########  Add  BU Audience  ##############  //

addingAudienceQuery(obj){
  const URL = this.baseUrl + 'querybuilder/insert/';
  const body = JSON.stringify(obj);
  return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }



  
buildAudienceQuery(company,id){
  const URL = this.baseUrl  + 'querybuilder/buildquery/'+company+"/"+id;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
  .pipe(
    map(data => {
      return data;
    }),
    catchError(this.handleError));
  }


  //  ###########  Add  BU Target Audience  ##############  //


  //  ###########  Get BU Audience Data by AUDID  ##########  //

  getBUAudienceDataByID(company, id) {
    const URL = this.baseUrl  + 'audiencebuilder/' + company + '/' + id;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //   ###############  Get Audience List for Query Builder  ##############  //

  getTestedAudienceForQueryBuilder(companyId, audId) {
    const URL = this.baseUrl  + 'audiencebuilder/executeQueryAud/' + companyId + '/' + audId;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Get Timezones ####################  //

  getTimezones(){
    const URL = this.baseService.baseUrl1 + 'api/getTimeZones';
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Schedule Created Audience ##############  //

  createSchedule(obj){
    const URL = this.baseUrl + 'audienceBuilderSchedule';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

    //  ###########  Get BU Audience Data by AUDID  ##########  //

    getQueryBuilderView(company, id) {
      const URL = this.baseUrl  + 'querybuilder/getbyId/' + company + '/' + id;
      return this.http.get<any>(URL,  this.httpHeadersOptions())
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError));
    }

  //   ########## Get Single Aud Data for Display ###############  //

  getAUDDataByID(companyId, audId){
    const URL = this.baseUrl + 'audiencebuilder/fetch/one/' + companyId + '/' + audId;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Audience List ####################  //

  getAudList(obj){
    const URL = this.baseUrl + 'audiencebuilder/listAud';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }


  /*** Country state city API call  ***/
  getAllCountries() {
    return this.http.get<any>(this.baseUrl1 + "api/geolocation/getAllCountries", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getStatesOfCountry(countryCode: string) {
    return this.http.get<any>(this.baseUrl1 + "api/geolocation/getStatesOfCountry/" + countryCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCitiesOfState(countryCode: string, stateCode: string) {
    return this.http.get<any>(this.baseUrl1 + "api/geolocation/getCitiesOfState/" + countryCode + "/" + stateCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getStateByCode(countryCode: string, stateCode: string) {
    return this.http.get<any>(this.baseUrl1 + "api/geolocation/getStateByCode/" + countryCode + "/" + stateCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCityById(geonameId: any) {
    return this.http.get<any>(this.baseUrl1 + "api/geolocation/getCityById/" + geonameId, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }



  //  ###########  Add  BU Target Audience  ##############  //

getAudienceByID(company,id){
  const URL = this.baseUrl  + 'audiencebuilder/getById/'+company+'/'+id;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
  }


  updateBUAudience(obj,compmay,id){
    const URL = this.baseUrl + 'audiencebuilder/'+compmay+'/'+id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions());
    }



    //  ###############  Fetch BU Audience LIST (With MULE SOFT Status)   ############  //

     //  ########### Audience Builder Scheduler  ##############  //

getAudienceBuilderSchedule(company,id){
  const URL = this.baseUrl  + 'audienceBuilderSchedule/'+company+'/'+id;
  return this.http.get<any>(URL,  this.httpHeadersOptions())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError));
  }


  updateAudienceBuilderSchedule(obj,id){
    const URL = this.baseUrl + 'audienceBuilderSchedule/'+id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions());
    }


    getBUAudienceList(company,  limit, skip, colname:any='aud_name', colname_val:any='asc', searchText){
      const URL = this.baseUrl  + `audiencebuilder/listAud/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`;
      return this.http.get<any>(URL,  this.httpHeadersOptions())
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError));
    }


    getCloneAudience(companyId, id){
      const URL = this.baseUrl  + 'audiencebuilder/cloneme/' + companyId + '/' + id;
      return this.http.get<any>(URL,  this.httpHeadersOptions())
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError));
    }

    getReplenishmentAndCrossSellAudience(company,  limit, skip, colname, colname_val, searchText){
      const URL = this.baseUrl  + `audiencebuilder/listAudience/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`;
      return this.http.get<any>(URL,  this.httpHeadersOptions())
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError));
    }

    launchAudience(obj) {
      const URL = this.baseUrl  + `audiencebuilder/buildAiAudience`;
      return this.http.post<any>(URL, obj, this.httpHeadersOptions())
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError));
    }

    exportAudListCSV(obj){
      const URL = this.baseUrl + 'audiencebuilder/listAud/csv';
      const body = JSON.stringify(obj);
      return this.http.post<any>(URL, body, this.httpHeadersOptions());
    }

}
