<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/support-ticket">Support Tickets</a></li>
            <li class="breadcrumb-item active" aria-current="page">Support Ticket Detail</li>
        </ol>
    </nav>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="card mt-1">
                <div class="card-body">
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Title : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.title }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Description : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.task }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Oodo Id : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.oodo_id }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Synced : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.is_synced ? 'Yes' : 'No' }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Status : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.status }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">File : </label>
                        </div>
                        <div class="col-md-9">
                            <a [href]='ticketDetails?.filepath' target='_blank'>{{ticketDetails?.filename}}</a>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Creation Date : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails.creation_dt | date: 'medium' }}</p>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3">
                            <label for="">Last Updation Date : </label>
                        </div>
                        <div class="col-md-9">
                            <p>{{ ticketDetails?.updated_dt | date: 'medium' }}</p>
                        </div>
                    </div>
                    

                

                    
                </div>
            </div>
            <div class="card mt-1">
                <div class="card-body">
                    <h5 class="font-weight-bold mb-2">Comments</h5>
                    <div class="col-md-12 comment-body">
                        <ng-container *ngFor="let comment of ticketDetails?.comments" >
                            <div class="mt-2 " *ngIf="comment.comment">
                                <label for="exampleForm2">{{comment?.ds_id ? ticketDetails?.ds_username: 'marGo Support'}}</label>
                                <div class="comment-box">
                                    <p [innerHTML]="comment?.comment"></p>
                                    <span class="comment-date">{{
                                        dateFormat(comment?.creation_dt)
                                      }}</span>
                                </div>    
                            </div>
                        </ng-container>
                    
                            
                        
                    </div>
                </div>
            </div>
        </div>
    </div>