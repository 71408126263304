<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li  class="breadcrumb-item active" aria-current="page">
        Campaign Builder
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">

      <h5 class="font-weight-bold mt-2 mb-4">Campaign Builder</h5>

    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-6 col-xl-8">
          <input type="button" value="+ New Campaign" class="custbtn mr-1 ml-0 mb-2" style="min-width: auto;max-width: 150px;" routerLink="/corporate/create-campaign-builder">
        </div>
        <div class="col-md-6 col-xl-4 mt-2 mt-md-0 text-sm-right">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text cyan lighten-2" id="basic-text1">
                <i aria-hidden="true" class="fa fa-search text-white"></i>
              </span>
            </div>
            <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
          </div>
        </div>
      </div>
      <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <th (click)="setOrder('camp_name')" style="cursor: pointer">Campaign Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
            <th>Audience Name</th>
            <th>Contacts</th>
            <th>Scheduling Info.</th>
            <th>Timezone</th>
            <th>Journey</th>

            <th>Theme Name  </th>

            <th (click)="setOrder('createdon')" style="cursor: pointer">Created Date <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
            <th>Actions</th>
          </tr>

          <tr *ngFor="let obj of buCampaignObj">

            <td title="{{obj.camp_desc}}" [ngClass]="{'aud-desc-color ': obj.camp_desc}">{{obj.camp_name}}</td>
            <td title="{{obj.aud_desc}}" [ngClass]="{'aud-desc-color ': obj.aud_desc}">{{obj.aud_name}}</td>
            <td><a (click)="goToAudienceList(obj.audid)">{{obj.total_count}}</a></td>
            <td>

              <div>{{obj.schedule_start|date}}</div>
              <div>{{obj.schedule_start|date: 'shortTime'}}</div>

            </td>
            <td>{{obj.schedule_timezone}}</td>
            <td>{{obj.journey_name}}</td>
            <td><div style="width: 120px;">{{obj.theme_name}}</div></td>
            <td>{{obj.createdon |date}}</td>
            <td>
              <div class="actBtn">
                <i class="fa fa-edit mr-2 linkForce" (click)="editCampaign(obj.campid)" style="vertical-align:top;"></i>
                <span class="fa fa-trash mr-1 cursor" style="vertical-align:top;" placement="bottom" (click)="deleteCampaign(obj.campid)" ngbTooltip="Delete"></span>
              </div>
            </td>
          </tr>

        </table>
        <div *ngIf="totalNumber == 0" style="text-align: center">
          <p>No Result Available</p>
        </div>
        <div class="mt-2 text-center difPagination" *ngIf="totalNumber > 10">
          <ngb-pagination [collectionSize]="totalNumber" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getBUCampaign(page=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination> 
        </div>
      </div>
    </div>
  </div>
</div>
