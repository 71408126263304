<div class="container bdrStCustom">

  <div class="row">
    <div class="col">
      <div class="card box-shadow-3">
        <div class="card-body">
          <h5 class="font-weight-bold">Subscription List</h5>
          <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Attachment</th>
              <th>Date</th>
            </tr>
            <tr *ngFor="let obj of subscriptionUserObj">
              <td>{{obj.name}}</td>
              <td>{{obj.description}}</td>
              <td><a *ngIf="obj.imageType == 1" href="{{obj.attachment}}">
                  <img src="assets/images/doc_image.jpg" style="max-height:35px; min-width: 35px;" />
                </a>
                <img class="img-fluid" style="max-height: 35px; min-width: 35px;" *ngIf="obj.imageType == 0"
                  src="{{obj.attachment}}" (error)="setValue($event)">
              </td>
              <td>{{obj.createdon | date:'medium' }}</td>
            </tr>
          </table>
          <div class="mt-2 text-center difPagination" *ngIf="subsTotal > 10">
            <ngb-pagination [collectionSize]="subsTotal" [(page)]="subsPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getSubscriptionUser(subsPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card box-shadow-3">
        <div class="card-body">
          <h5 class="font-weight-bold">User List</h5>
          <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>User name</th>
              <th>Role</th>
              <th>Created On</th>
            </tr>

            <tr *ngFor="let obj of contactUserObj">
              <td>{{obj.admin_fname}} {{obj.admin_lname}}</td>
              <td>{{obj.admin_email}}</td>
              <td>{{obj.admin_username}}</td>
              <td>{{obj.role_name}}</td>
              <td>{{obj.createdon | date:'medium' }}</td>
            </tr>
          </table>
          <div class="mt-2 text-center difPagination" *ngIf="userTotal > 10">
            <ngb-pagination [collectionSize]="userTotal" [(page)]="userPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getCompanyUser(userTotal=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card box-shadow-3">
        <div class="card-body">
          <h5 class="font-weight-bold">BU List</h5>
          <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>

            <tr *ngFor="let obj of contactBUObj">

              <td data-title="Client_Name" style="text-decoration:underline;cursor: pointer;"
                (click)="clientView(obj.customer_id)">
                {{obj.customer_company_name?obj.customer_company_name:'N/A'}}
              </td>
              <td>{{obj.customer_address1}}</td>
              <td>{{obj.customer_contact_email}}</td>
              <td>{{obj.customer_phone}}</td>
            </tr>

          </table>
          <!-- <div class="mt-2 text-center difPagination" *ngIf="buTotal > 10">
            <ngb-pagination [collectionSize]="buTotal" [(page)]="buPage" [maxSize]="5" [rotate]="true" (pageChange)="getBUList(buPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>