import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';



@Component({
  selector: 'app-add-entities',
  templateUrl: './add-entities.component.html',
  styleUrls: ['./add-entities.component.css']
})


export class AddEntitiesComponent extends BaseComponent implements OnInit {


  entity_id; 
  entityObj;  
  entityForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
 

  constructor(
  
    private formBuilder: FormBuilder,  
    public toaster: ToastrService,
    private segmentService: SegmentationService

  ) {
    super();
  }

  ngOnInit() {


    //this.ngxSpinnerService.show();
   
    let self = this;
   

    if(localStorage.getItem("deleteEntityId")) this.entity_id = localStorage.getItem("deleteEntityId")
    if (this.entity_id) {
      this.ngxSpinnerService.show();
      this.UpdateEntityLogData()
     
     
      this.segmentService.getEntityId(this.entity_id).subscribe(response => {
        
        if (response.status) {   
          this.actionStatus = false;      
          self.entityObj = response.data[0];
          this.status =   self.entityObj.isactive    
          self.dataBinding();

        } 
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addEntityLogData()
    }
    this.entityForm = this.formBuilder.group({
      entity_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      entity_desc: ['']
     
    });
  }
  get f() { return this.entityForm.controls; }

 

  addEntityLogData() {

    let entityLogs = {
      log_type: "Adding New Entity",
      log_name: "Adding New Entity",
      log_description: "Adding New Entity",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(entityLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });

  }
  
  UpdateEntityLogData() {

    let entityLogs = {
      log_type: "Updating Entity",
      log_name: "Updating Entity",
      log_description: "Updating Entity",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(entityLogs).subscribe(data => {
     // console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  addEntity() {
    this.submitted = true;
   
    if (this.entityForm.invalid) {
      return;
    }

    let { entity_name, entity_desc} = this.entityForm.value;


    this.ngxSpinnerService.show();
    
    let entityBody = {
      entity_name: entity_name,
      entity_desc: entity_desc,
      status:true

    }

    this.segmentService.addingEntity(entityBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/entities`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
 
  dataBinding() {
    
      this.entityForm = this.formBuilder.group({
        entity_name: [this.entityObj.entity_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        entity_desc: [this.entityObj.entity_desc],       
       
       
      });
     
  }
  UpdateEntity() {
  
    this.submitted = true;
    if (this.entityForm.invalid) {
      return;
    }
    let { entity_name, entity_desc} = this.entityForm.value;

    let entityBody = {
      entity_name: entity_name,
      entity_desc: entity_desc,
      status:this.status
    
    }

    this.ngxSpinnerService.show();
    this.segmentService.updateEntity(entityBody,this.entity_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/entities`);
        
      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}

