import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-add-content-builder-section',
  templateUrl: './add-content-builder-section.component.html',
  styleUrls: ['./add-content-builder-section.component.css']
})
export class AddContentBuiderSectionComponent extends BaseComponent implements OnInit {
  name;
  description;
  screenshot: String;
  IsActive;
  id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey;
  them_company;
  getCompanyobj:any=[];
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;

  submitted = false;
  templateForm: FormGroup;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.controlEnabled = true;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = params.companyID;

      });
    if (this.id) {
      this.UpdateTemplateLogData()
    }
    else {
      this.addTemplateLogData()
    }
    //get item details using id
    if (this.id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.cb.getSectionById(this.companyID,this.id).subscribe(response => {
       
        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          this.ngxSpinnerService.hide();
            self.dataBinding();
        } else {
        }
      });
    }
    this.templateForm = this.formBuilder.group({
      company: ['', Validators.required],
      section_name: ['', Validators.required],
      section_description: [''],
     
    });
  }
  get f() { return this.templateForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }

  addSection() {
    this.submitted = true;
    //console.log(this.templateForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
    //console.log("themescreenshot -- ", this.templateForm);

    if (this.templateForm.invalid) {
      return;
    }
    let { company, section_name, section_description} = this.templateForm.value;
    let themeObj = {
      section_name: section_name,
      section_description: section_description,
      isactive: true,
      company: company
    }
   // console.log("themeObj", themeObj);
    this.ngxSpinnerService.show();
    this.cb.addingSection(themeObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-section`);
      } else { 
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  addTemplateLogData() {
    let themeLogs = {
      log_type: "Adding New Template",
      log_name: "Adding New Template",
      log_description: "Adding New Template",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  UpdateTemplateLogData() {
    let themeLogs = {
      log_type: "Updating Template Data",
      log_name: "Updating Template Data",
      log_description: "Updating Template Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
     // console.log(data);
    }, error => {
    //  console.log(error);
    });
  }
  dataBinding() {
//  console.log("data binidning...."+this.themObj.name)

      this.templateForm = this.formBuilder.group({
        company: [this.companyID.toString(), Validators.required],
        section_name: [this.themObj.section_name, Validators.required],
        section_description: [this.themObj.section_description],
       
      });
    
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  updateSection() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    let { company, section_name, section_description } = this.templateForm.value;
     let themobj = {
      section_name: section_name,
      section_description: section_description,     
      id: Number.parseInt(this.id, 10),
      company: company
    }
   // console.log('themobj', themobj);
    this.ngxSpinnerService.show();
    this.cb.UpdateSection(themobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/cb-section`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  
}