import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-addnewpermissions',
  templateUrl: './addnewpermissions.component.html',
  styleUrls: ['./addnewpermissions.component.css']
})
export class AddnewpermissionsComponent extends BaseComponent implements OnInit {
  permissionObj;
  // parentid;
  // name;
  // description;
  per_id;
  permissionObjId;
  // isactive = true;
  // menu_id;
  // meunObjId;
  status: boolean = true;
  getRoleObj1;
  header = "Add New";

  permissionForm: FormGroup;
  submitted = false;

  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder
  ) {
    super();
   }

  ngOnInit() {
    let self = this;
    self.getRoledropdown()
    self.activatedRoute.queryParams.subscribe(params => {
      self.per_id = params.per_id;
    });
    //get item details using id

    if (self.per_id) {
      this.header = "Edit"
      this.ngxSpinnerService.show();
      this.UpdatePermissionLogData()
      this.master.getPermissionById(this.per_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.permissionObjId = response.data;
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      });
    } else {
      this.addPermissionLogData()
    }
    this.permissionForm = this.formBuilder.group({
      permissionsName: ['', [Validators.required,Validators.pattern(AppConstants.regex.name),Validators.maxLength(20),this.noWhitespaceValidator]],
      permissionsDescription: [],
      permissionsParentId: [0],
      isactive: [true]
    });
  }
  get f() { return this.permissionForm.controls; }
  getpermission() {
    let self = this;
    this.master.getPermissions().subscribe(data => {
    //  console.log(data);
      self.permissionObj = data;
    }, error => {
    //  console.log(error);
    });
  }
  addPermissionLogData() {

    let PermissionLogs = {
      log_type: "Adding New Permission",
      log_name: "Adding New Permission",
      log_description: "Adding New Permission",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(PermissionLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  UpdatePermissionLogData() {

    let PermissionLogs = {
      log_type: "Updating Permission Data",
      log_name: "Updating Permission Data",
      log_description: "Updating Permission Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(PermissionLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  getRoledropdown() {
    let self = this;
    this.master.getRoledropdown().subscribe(data => {
      self.getRoleObj1 = data;
    //  console.log(self.getRoleObj1)
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
    // this.name = this.permissionObjId.per_name;
    // this.description = this.permissionObjId.per_description;
    // this.parentid = this.permissionObjId.per_parent_id;
    // this.isactive = this.permissionObjId.isactive;
    this.permissionForm = this.formBuilder.group({
      permissionsName: [this.permissionObjId.per_name, [Validators.required,Validators.pattern(AppConstants.regex.name),Validators.maxLength(20),this.noWhitespaceValidator]],
      permissionsDescription: [this.permissionObjId.per_description],
      permissionsParentId: [this.permissionObjId.per_parent_id],
      isactive: [this.permissionObjId.isactive]
    });
  }
  addNewPermission() {
    this.submitted = true;
    if (this.permissionForm.invalid) {
      return;
    }
   // console.log(this.permissionForm);

    let { 
      permissionsName, permissionsDescription, permissionsParentId, isactive
    } = this.permissionForm.value;

    let permissionBody = {
      parentid: permissionsParentId,
      permissionName: permissionsName,
      permissionDesc: permissionsDescription,
      isactive: isactive
    }
   // console.log(permissionBody)
    this.ngxSpinnerService.show();
    this.master.addingNewPermission(permissionBody).subscribe(data => {
     // console.log(data);
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl('/corporate/permissions');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  updatePermission() {
    this.submitted = true;
    if (this.permissionForm.invalid) {
      return;
    }
  //  console.log(this.permissionForm);
    let { 
      permissionsName, permissionsDescription, permissionsParentId, isactive 
    } = this.permissionForm.value;

    let permissionBody = {
      per_name: permissionsName,
      per_description: permissionsDescription,
      per_parent_id: permissionsParentId,
      isactive: isactive,
      per_id: Number.parseInt(this.per_id, 10)
    }
   // console.log("******", permissionBody);
    this.ngxSpinnerService.show();
    this.master.updatePermission(permissionBody).subscribe(data => {
     // console.log(data)
      if (data.status) {
        this.toastrService.success("Updated Successfully")
        this.router.navigateByUrl(`/corporate/permissions`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
