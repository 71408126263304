<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
          <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
          <li class="breadcrumb-item"><a routerLink="/corporate/cb-sec-field-association">Section Field Association</a></li>
          <li class="breadcrumb-item active" aria-current="page">Add Section Field</li>

      </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="fieldForm">
      <div class="row">
          <div class="col-md-10 col-xl-7">
  
            <div class="card mt-1">
  
              <div class="card-body">
              <div class="form-group">
                  <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                  <select formControlName="company" class="form-control formstyle"
                      [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)="getCompanySections($event.target.value)">
                      <option value="">Select Company Name</option>
                      <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                          {{userObj.customer_company_name}}
                      </option>
                  </select>
                  <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                      <div *ngIf="f.company.errors.required">Company Name is required</div>
                  </div>
              </div>
              <div class="form-group">
                  <label for="exampleForm2">Section Name <span style="color:red">*</span></label>
                  <select formControlName="sid" class="form-control formstyle"
                      [ngClass]="{ 'is-invalid': submitted && f.sid.errors }"  (change)="getSectionField($event.target.value)">
                      <option value="">Please Select Section</option>
                      <option *ngFor="let userObj of themeObj" value={{userObj.sectionid}}>
                          {{userObj.section_name}}
                      </option>
                  </select>
                  <div *ngIf="submitted && f.sid.errors" class="invalid-feedback">
                      <div *ngIf="f.sid.errors.required">Section Name is required</div>
                  </div>
              </div>
              
              <div class="form-group" *ngIf="fieldObj?.length > 0">
              <label for="exampleForm2">Field Name <span style="color:red">*</span></label>
              <div class="piInnerList scroller">
                  <ul class="filtMain">
                    <li *ngFor="let item of fieldObj">
                      <div class="row">
                        <div class="col-sm-9">
                          <label class="form-check-label">
                            {{item.field_name}}
                          </label>
                        </div>
                        
                        <div class="col-sm-3">
                          <input type="checkbox"  [checked]="selectedFields.indexOf(item.fieldid)>=0"  (change)="selectFields($event, item.fieldid)">
                       
                        </div>
                        
                      </div>
                    
                    </li>
                  </ul>
              </div>
              
          </div>
          </div>
          <div class="card-footer text-center ">
              <div class="my_class text-center">
                  <button type="submit" class="custbtn" (click)="addFields()">Submit</button>
              </div>
            </div>
      </div>
  </div>
</div>
  </form>
</div>