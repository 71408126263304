import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { OrderPipe } from 'ngx-order-pipe';
import { ClientService } from 'src/app/services/clients/client.service';
import { SupportService } from 'src/app/services/support/support.service';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-supportuser-create-request',
  templateUrl: './supportuser-create-request.component.html',
  styleUrls: ['./supportuser-create-request.component.css']
})
export class SupportUserCreateRequestComponent extends BaseComponent implements OnInit {


  public themeObj: any = [];

  getCompanyobj: any = [];
  currentUser;
  companyID;
  roleID;
  supportUser: any = [];
  supportId;
  sellerId;
  timeInterval;
  bu_id;
  case_id;
  supportUserCreateForm;
  submitted: boolean = false;

  limit = 100;
  skip = 0;

  isSupportUser = false;

  //***********************************/
  constructor(
    private formBuilder: FormBuilder,

    private orderPipe: OrderPipe,
    private cb: CbService,

    private clientService: ClientService,
    private supportService: SupportService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {
    super();

    this.getCompany();

  }

  ngOnInit() {
    // this.gettingContentBuilderFields(); 
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    // alert(this.currentUser+""+sessionStorage.getItem('userData'));
    this.companyID = this.currentUser.admin_customer_id;
    this.roleID = this.currentUser.role_id;
    //  this.addCBThemeField();
    //get support list

    if (this.roleID == 71) {
      this.supportUserCreateForm = this.formBuilder.group({
        supportuser: ['', Validators.required],
        company: ['', Validators.required],
        sellerId: ['', Validators.required],
        timeInterval: ['', Validators.required],
        case_id: ['', Validators.required],
      });
    }
    else {
      this.isSupportUser = true;
      this.supportUserCreateForm = this.formBuilder.group({
        company: ['', Validators.required],
        sellerId: ['', Validators.required]
        // timeInterval: ['', Validators.required],
        // case_id: ['', Validators.required],
      });
    }

    this.supportService.getSupportList().subscribe(data => {
      this.supportUser = data.data;
      //  alert(data);
      this.ngxSpinnerService.hide();
    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });


  }

  get f() { return this.supportUserCreateForm.controls; }

  createSupportRequest() {

    this.submitted = true;
    if (this.supportUserCreateForm.invalid) {
      return;
    }

    //let createdBy = (this.supportId == 'undefined') ? this.supportId: this.currentUser.admin_customer_id;
    let supportData = {}

    if(this.isSupportUser) {
      supportData = {
        supportUser: this.currentUser.uid,
        sellerUser: +this.f.sellerId.value,
        timeInterval: '',
        bu_id: this.f.company.value,
        case_id: ''
      }
    }else {
      supportData = {
        supportUser: this.f.supportuser.value,
        sellerUser: this.f.sellerId.value,
        timeInterval: this.f.timeInterval.value,
        bu_id: this.f.company.value,
        case_id: this.f.case_id.value,
      }
    }

    // console.log("supportData",supportData);
    this.spinner.show();
    this.supportService.createSupportRequest(supportData, this.isSupportUser).subscribe(response => {
      if (response.status) {
        if(this.isSupportUser) {
          this.getDsSession(response.data);
          this.spinner.hide();
          this.ngxSpinnerService.hide();
        }else {
          this.toastrService.success(response.message);
          this.spinner.hide();
          this.router.navigateByUrl('/corporate/supportuser-list-request');
          this.ngxSpinnerService.hide();
        }
      } else {
        this.toastrService.error(response.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });

  }

  getDsSession(data) {     
    window.open(`${environment.dsURL}/login/true/${data.token}/${data.customer_company_name}/${encodeURIComponent(data.customer_company_logo)}`, '_blank');

  } 



  themeLogData() {
    let themeLogs = {
      log_type: "Theme Master View",
      log_name: "Theme Master View",
      log_description: "Theme Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);

    }, error => {
      //  console.log(error);
    });

  }


  getCompanySellerList(id) {
    //event.preventDefault();
    this.companyID = id;
    if (id != "") {
      //  console.log("company themes:"+ id);
      let self = this;
      //  alert(id);
      this.ngxSpinnerService.show();
      this.clientService.getDirectSellerList(id).subscribe(data => {
        self.themeObj = data.data;
        //  alert(data);
        this.ngxSpinnerService.hide();
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  }



  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      if(this.isSupportUser) {
        self.supportUserCreateForm.patchValue({
          company: self.getCompanyobj[0].customer_id
        })
        self.getCompanySellerList(self.getCompanyobj[0].customer_id);
      }
    }, error => {
      // console.log(error);
    });
  }
}
