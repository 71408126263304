<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>

      <li class="breadcrumb-item">
        <a routerLink="/corporate/audience-builder"> Audience List</a>
      </li>


      <li class="breadcrumb-item active" aria-current="page">
        Schedule Audience
      </li>


    </ol>
  </nav>
</div>

<div class="container mt-2" >
  <h5 class="font-weight-bold mt-4 mb-4">Create and Schedule Audience</h5>

  <form class="form-horizontal mt-1" [formGroup]="scheduleForm">
    <div class="row">
      <div class="col-xl-10">

        <div class="card mt-1">

          <div class="card-body">

            <div class="row mb-2" *ngIf="audData">
              <div class="col-xl-3 col-md-5 col-sm-6 font-weight-bold">Audience Name :</div>
              <div class="col-xl-9 col-md-7 col-sm-6">{{audData.aud_name}}</div>
            </div>
            <div class="row mb-3" *ngIf="audData && audData.aud_desc">
              <div class="col-xl-3 col-md-5 col-sm-6 font-weight-bold">Audience Description :</div>
              <div class="col-xl-9 col-md-7 col-sm-6">{{audData.aud_desc}}</div>
            </div>

            <div class="row">

              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-md-3">
                    <h6 class="font-weight-bold" for="scheduleForm">Select Schedule Type <span style="color:red">*</span></h6>
                  </div>
                  <div class="col-md-9">
                    <p [ngClass]="{ 'is-invalid': submitted && f.scheduleType.errors }">
                      <input type="radio" value="true" formControlName="scheduleType"> Schedule
                      <input type="radio" value="false" formControlName="scheduleType" class="ml-2"> Run Once
                    </p>
                  </div>
                </div>


              </div>
              <div *ngIf="submitted && f.scheduleType.errors" class="invalid-feedback">
                <div *ngIf="f.scheduleType.errors.required">Schedule Type is required</div>
              </div>



            <div class="col-12" *ngIf="this.scheduleForm.value.scheduleType === 'true'">
              <div class="form-group row">
                <div class="col-md-3">

                  Schedule Date
                  <span class="redMand">*</span>
                </div>
                <div class="col-md-9">
                  <div class="row">

                    <div class="col-sm-4 col-6 schedCal mb-3 mb-sm-0">


                      <input class="form-control" matInput  [matDatepicker]="picker2" readonly

                             formControlName="scheduleStartDate" [max]="maxdate"
                             [ngClass]="{ 'is-invalid': submitted && f.scheduleStartDate.errors }">
                      <mat-datepicker-toggle matSuffix [for]="picker2" class="cldrIcon"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      <div *ngIf="submitted && f.scheduleStartDate.errors" class="invalid-feedback">
                        <div *ngIf="f.scheduleStartDate.errors.required">Start Date is required</div>
                      </div>
                    </div>
                    <div class="widhtFd-150" *ngIf="this.scheduleForm.value.scheduleType === 'true'">
                      <p class="atWTime">at</p>
                    </div>


                    <div class="col-sm-5 col-8 scheduleTm">
                      <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="stTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
                    </div>

                  </div>
                </div>


              </div>


            </div>

            <div class="col-12 " *ngIf="this.scheduleForm.value.scheduleType === 'true'">
              <div class="row">
                <div class="col-md-3">
                  Time Zone
                  <span class="redMand">*</span>
                </div>
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-9">


                  <select
                formControlName="tzone"
                autocomplete="off"
                class="form-control formstyle "
                [ngClass]="{ 'is-invalid': submitted && f.tzone.errors }">
                <option value="">Select Time Zone</option>
                <option *ngFor="let tz of timezones" value="{{tz.value}}">{{tz.value}}</option>
                <!-- <option *ngFor="let tz of timezones" value="{{tz.value}}">{{tz.text}}</option> -->
              </select>
              <div *ngIf="submitted && f.tzone.errors" class="invalid-feedback">
                <div *ngIf="f.tzone.errors.required">Time Zone is required</div>
              </div>
            </div>
          </div>
                </div>
              </div>


            </div>

            <div class="col-12 mt-3" *ngIf="this.scheduleForm.value.scheduleType === 'true'">
              <div class="row">
                <div class="col-md-3">
                  Frequency
                  <span class="redMand">*</span>
                </div>
                <div class="col-md-9">
                  <div class="row">
                    <div class="widhtFd-150 mt-2">
                      <p class="">Every</p>
                    </div>
                    <div class="col-3 col-sm-2">
                      <input type="text" (keyup)="ischeck()" (keydown)="isNumber($event)" class="form-control" name="frequency" formControlName="freq"
                             [ngClass]="{ 'is-invalid': submitted && f.freq.errors }" maxlength="3" />
                      <div *ngIf="submitted && f.freq.errors" class="invalid-feedback">
                        <div *ngIf="f.freq.errors.required"> This field is required</div>
                    </div>
                    </div>


                    <div class="col-md-4 col-sm-3  col-5 col-xl-3">

                      <select
                        formControlName="freqType"
                        autocomplete="off"
                        class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.freqType.errors }">
                        <option value="Day">Day</option>
                        <option value="Hour">Hour</option>
                        <option value="Minute">Minute</option>
                        <option value="Week">Week</option>
                      </select>
                      <div *ngIf="submitted && f.freqType.errors" class="invalid-feedback">
                        <div *ngIf="f.freqType.errors.required">This Field is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="col-12 mt-3" *ngIf="this.scheduleForm.value.scheduleType === 'true'">
              <div class="row">
                <div class="col-md-3">
                  End Schedule
                  <span class="redMand">*</span>
                </div>
                <div class="col-md-9">
                  <div class="row">

                    <div class="col-sm-4 col-6 schedCal mb-3 mb-sm-0">
                      <input class="form-control" matInput  [matDatepicker]="picker3" readonly
                             formControlName="scheduleEndDate" [max]="maxdate"
                             [ngClass]="{ 'is-invalid': submitted && f.scheduleEndDate.errors }">
                      <mat-datepicker-toggle matSuffix [for]="picker3" class="cldrIcon"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                      <div *ngIf="submitted && f.scheduleEndDate.errors" class="invalid-feedback">
                        <div *ngIf="f.scheduleEndDate.errors.required">End Date is required</div>
                      </div>
                    </div>
                    <div class="widhtFd-150">
                      <p class="atWTime">at</p>
                    </div>

                    <div class="col-sm-5 col-8 scheduleTm">
                      <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
                    </div>

                  </div>
                </div>
              </div>


            </div>
              <div class="col-12 mt-2" *ngIf="this.scheduleForm.value.scheduleType === 'false'">
                <p>*This Automation will run only once upon saving the audience.</p>
              </div>
          </div>
          </div>

          <div class="card-footer text-center mt-1" *ngIf="!statusEdit">

            <div class="my_class text-center" *ngIf="isWrite">


              <button *ngIf="isBack" type="button" class="custbtn" (click)="backQueryEditPage()">Back</button>

              
              <button type="submit" class="custbtn"  (click)="submitSchedule()" >Submit</button>
            </div>
          </div>
          <div class="card-footer text-center mt-1" *ngIf="statusEdit">

            <div class="my_class text-center" *ngIf="isEdit">

                <button type="submit" class="custbtn"  (click)="updateSchedule()" >Update Schedule</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </form>
</div>
