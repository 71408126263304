import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewmenu',
  templateUrl: './addnewmenu.component.html',
  styleUrls: ['./addnewmenu.component.css']
})
export class AddnewmenuComponent extends BaseComponent implements OnInit {
  parentid;
  type;
  name;
  description;
  icon;
  link;
  linktype;
  IsActive;
  menu_id;
  meunObjId;
  status: boolean = true;
  getMeunObj;
  menu_awskey;
  screenshot_type;
  moduleitemid = 0;
  getModuleItemObj = [];
  header = "Add New";

  menuForm: FormGroup;

  constructor(
    private master: MasterService,
    public registerService: RegisterService,
    private formBuilder: FormBuilder,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    this.ngxSpinnerService.show();
    this.UpdateMenuLogData();
    self.getMenuDropdown();
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.menu_id = params.menu_id;
      });
    //get item details using id
    if (self.menu_id) {
      this.header = "Edit"
      this.ngxSpinnerService.show();
      this.master.getMenuById(this.menu_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.meunObjId = response.data;
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {
        }
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addMenuLogData();
      this.ngxSpinnerService.hide();
    }

  }

  fetchModuleItems() {
    if (this.linktype == "internal") {
      if (this.getModuleItemObj.length == 0) {
        this.getModuleItemDropdown();
      }
      this.moduleitemid = 0;
    }
    else if (this.linktype == "external") {
      this.link = "";
    }
  }

  addMenuLogData() {

    let menuLogs = {
      log_type: "Adding New Menu",
      log_name: "Adding New Menu",
      log_description: "Adding New Menu",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(menuLogs).subscribe(data => {


    }, error => {

     // console.log(error);
    });

  }

  UpdateMenuLogData() {

    let menuLogs = {
      log_type: "Updating Menu Data",
      log_name: "Updating Menu Data",
      log_description: "Updating Menu Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(menuLogs).subscribe(data => {


    }, error => {

     // console.log(error);
    });

  }
  getMenuDropdown() {
    let self = this;

    this.registerService.getMenuDropdown().subscribe(data => {
      if (data.status) {
        self.getMeunObj = data.data;
      }

    }, error => {
    //  console.log(error);
    });
  }
  getModuleItemDropdown() {
    let self = this;
    this.registerService.getModuleItems().subscribe(data => {
      if (data.status) {
        self.getModuleItemObj = data.data;
      }

    }, error => {
     // console.log(error);
    });
  }
  addNewMenu() {
    this.icon = this.isUrlValid(this.icon) ? this.icon : this.menu_awskey ? this.menu_awskey : "";

    if (this.name && this.type && this.icon && this.linktype && this.type.trim() && this.name.trim() && this.linktype.trim()) {
      if (this.linktype == "internal" && this.moduleitemid == 0) {
        this.toastrService.error("Please Fill all the fields")
      }
      else if (this.linktype == "external" && this.link && this.link.trim()) {
        this.toastrService.error("Please Fill all the fields")
      }
      else {
        let obj = {
          parentid: this.parentid,
          type: this.type,
          name: this.name,
          description: this.description,
          icon: this.icon,
          link: this.link,
          link_type: this.linktype,
          module_item_id: this.moduleitemid
        }
        //console.log(obj);
        this.ngxSpinnerService.show();
        this.master.addingMenu(obj).subscribe(data => {
          if (data.status) {
            this.toastrService.success("Added Successfully")
            this.router.navigateByUrl(`/corporate/menumaster`);
          } else {
          }
          this.ngxSpinnerService.hide();
        }, error => {
         // console.log(error);
          this.ngxSpinnerService.hide();
        });
      }
    } else {
      this.toastrService.error("Please Fill all the fields")
    }

  }
  dataBinding() {
    this.name = this.meunObjId.menu_name;
    this.description = this.meunObjId.menu_description;
    this.icon = this.meunObjId.menu_icon;
    this.linktype = this.meunObjId.menu_link_type;
    this.IsActive = this.meunObjId.isactive;
    this.parentid = this.meunObjId.menu_parent_id;
    this.link = this.meunObjId.menu_link;
    this.type = this.meunObjId.menu_type;
  }
  updateMenu() {
    this.icon = this.isUrlValid(this.icon) ? this.icon : this.menu_awskey ? this.menu_awskey : "";
  //  console.log(this.icon);

    if (this.name && this.type && this.icon && this.linktype && this.type.trim() && this.name.trim() && this.linktype.trim()) {
      if (this.linktype == "internal" && this.moduleitemid == 0) {
        this.toastrService.error("Please Fill all the fields")
      }
      else if (this.linktype == "external" && this.link && this.link.trim()) {
        this.toastrService.error("Please Fill all the fields")
      }
      else {
        let menuobj = {
          menu_name: this.name,
          menu_description: this.description,
          menu_icon: this.icon,
          menu_parent_id: this.parentid,
          menu_link: this.link,
          menu_link_type: this.linktype,
          menu_type: this.type,
          isactive: this.IsActive,
          menuid: Number.parseInt(this.menu_id, 10)
        }
     //   console.log("update", menuobj);
        this.ngxSpinnerService.show();
        this.master.updateMenu(menuobj).subscribe(data => {
          if (data.status) {
            this.toastrService.success("Updated Successfully")
            this.router.navigateByUrl(`/corporate/menumaster`);
            this.ngxSpinnerService.hide();
          }
        }, error => {
          console.error(error);
          this.ngxSpinnerService.hide();
        });
      }
    } else {
      this.toastrService.error("Please Fill  fields")
    }

  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.menu_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  processFile(event: any, imageInput: any) {
    // const file: File = imageInput.files[0];
    // const reader = new FileReader();
    // this.toUploadFiles(file);
    // reader.readAsDataURL(file);
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.menu_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
}
