import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
@Component({
  selector: 'app-subscription-limit',
  templateUrl: './subscription-limit.component.html',
  styleUrls: ['./subscription-limit.component.css']
})
export class SubscriptionLimitComponent extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  getCompanyobj: any = [];
  limitObj: any = [];
  limitTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "desc";
  slCatColname = "id";
  constructor(
    private clientService: ClientService,
    private masterService:MasterService
  ) {
    super();
   }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getLimit();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }
  getLimit(){
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getSubscriptionLimitList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        // console.log(data);

        if (data.status) {
          if (data.data.rows == null) { this.limitObj = [] }
          else { this.limitObj = data.data.rows }
          this.limitTotal = data.data.total;
        } else {
          this.limitObj = [];
          this.limitTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.limitObj = [];
      this.selectedCompany = "";
      this.limitTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getLimit()
  }
  editPost(id:any){
    localStorage.setItem("edit_subscription_limit_id", id);
    localStorage.setItem("subscription_limit_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/editSubscriptionLimit']);
  }
  delLimit(event:any,id:any){
    event.preventDefault();
    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.deleteSubscriptionLimit(this.selectedCompany,id).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Subscription Limit Deleted Successfully");
              this.getLimit();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  updateStatus(event:any,id:any, status:any){
    event.preventDefault();
    let obj = {
      isactive: (status == true) ? false : true,
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.updateStatusSubscriptionLimit(this.selectedCompany,id,obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Updated Successfully");
              this.getLimit();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.masterService.getSubscriptionLimitList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value,this.slCatColname,this.slCatord))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.limitObj = [] }
          else { this.limitObj = res.data.rows }
          this.limitTotal = res.data.total;
        } else {
          this.limitObj = [];
          this.limitTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.limitObj = [];
        this.limitTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }
}
