import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private apiUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }
  uploadImage(fileName:any,filePath:any,img:any,bucket_type=1) {
    let path = filePath;
    fileName = fileName.replace(/[^a-zA-Z0-9.]/g, '');
    let body = {
      fileName: filePath!=='resource_video' && filePath!=='resource_images'? (new Date()).getTime()+"-"+fileName:fileName,
      filePath: path,
      image: img,
      bucket_type:bucket_type
    }
    let uri = this.apiUrl + 'api/uploadImage';
    return this.http.post<any>(uri, body);
  }
}
