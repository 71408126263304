<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>


<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/license">License</a></li>
    <li class="breadcrumb-item active">License Bundle Listing</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">

 
  <select class="form-control formstyle"
   (change)="loadBundle($event.target.value)" [(ngModel)]="selectedCompany">
   <option value="0">Please select company</option>
   <ng-container *ngFor="let userObj of getCompanyobj" >
    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
<!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
{{userObj.customer_company_name}}
</option>
</ng-container>
</select>


</div>
</div>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true" ></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text"
         [readonly]="bundleData == null || bundleData.length == 0">
    </div>

  </div>
<div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/license-bundle-create">
  <!--  <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
    -->
  </div>
 
</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0"> 
    <tr>
      <!-- <th>Theme Id</th> -->
      <th (click)="setOrder('bundle_name')" style="cursor: pointer;">Bundle Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th (click)="setOrder('start_date')" style="cursor: pointer;">Start Date <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>End Date </th>
     
      <th>Total Member</th>
      <th >Bundle Price</th>
      <th (click)="setOrder('created_at')" style="cursor: pointer;" >Created Date<i class=" fa fa-sort text-black" aria-hidden="true"></i></th>      
      <th *ngIf="isEdit || isDelete" width="80">Action</th>
    </tr>

    <tr *ngFor="let obj of bundleData | filter:text | orderBy: order:reverse:'case-insensitive' | paginate:{itemsPerPage:10,currentPage:cp }">
      <!-- <td>{{obj.get_master_module_item_fun.moduleitemid}}</td> -->
      <td>{{obj.bundle_name}}</td>
      <td>{{obj.start_date | date:'mediumDate'}}</td>
      <td>{{obj.end_date | date:'mediumDate'}}</td>
      <!-- <td>{{obj.get_master_module_item_fun.theme_screenshot}}</td> -->
     
      <td>{{obj.total_member}}</td>
      <td>{{obj.bundle_price }}</td>
      <td>{{obj.created_at | date:'mediumDate'}}</td>
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn wit-80">
      <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editBundle(obj.bundle_id)"  placement="bottom" ngbTooltip="Edit">

        </i>
      <!-- <span class="fa fa-trash mr-1 cursor"
        (click)="deleteBundle(obj.bundle_id)"placement="bottom" *ngIf="isDelete" ngbTooltip="Delete"></span>
       
        <mat-slide-toggle [checked]="obj.is_active" class="my-slider cursor" *ngIf="isEdit"
        (click)="updateBundleStatus(obj.bundle_id,obj.is_active)">
      </mat-slide-toggle>--> 
      </div>
      </td>
    </tr>


</table>
<pagination-controls *ngIf="bundleData == null || bundleData.length == 0" class="my-pagination mt-2" (pageChange)="cp=$event"></pagination-controls>
<div *ngIf="(bundleData | filter:text ).length === 0" style="text-align: center">
<p>No Result Available</p>
</div>
</div>

</div>