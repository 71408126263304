import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-addnewloyality',
  templateUrl: './addnewloyality.component.html',
  styleUrls: ['./addnewloyality.component.css']
})
export class AddnewloyalityComponent extends BaseComponent implements OnInit {

  // name;
  // description;
  // IsActive;
  loyality_id;
  loyalityObjId;
  header = "Add New";
  status: boolean = true;

  loyalityForm: FormGroup;
  submitted = false;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.loyality_id = params.loyality_id;
      });
    //get item details using id
    if (self.loyality_id) {
      this.ngxSpinnerService.show();
      this.UpdateLoyalityLogData();
      this.header = "Edit"
      this.master.getLoyalityById(this.loyality_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.loyalityObjId = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();
        } else {

        }
      });
    } else {
      this.addLoyalityLogData();
    }
    this.loyalityForm = this.formBuilder.group({
      loyalityName: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      loyalityDescription: [],
    });
  }
  get f() { return this.loyalityForm.controls; }
  addLoyalityLogData() {

    let LoyalityLogs = {
      log_type: "Adding New Loyality",
      log_name: "Adding New Loyality",
      log_description: "Adding New Loyality",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(LoyalityLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  UpdateLoyalityLogData() {

    let LoyalityLogs = {
      log_type: "Updating Loyality Data",
      log_name: "Updating Loyality Data",
      log_description: "Updating Loyality Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(LoyalityLogs).subscribe(data => {
   //   console.log(data);
    }, error => {
    //  console.log(error);
    });
  }

  addLoyality() {
    this.submitted = true;
    if (this.loyalityForm.invalid) {
      return;
    }

    let { loyalityName, loyalityDescription } = this.loyalityForm.value;
    let loyalityBody = {
      name: loyalityName,
      description: loyalityDescription
    }
    this.ngxSpinnerService.show();
    this.master.addingLoyality(loyalityBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Sucessfully")
        this.router.navigateByUrl(`/corporate/loyalitytypemaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    // this.name = this.loyalityObjId.loyalty_type_name;
    // this.description = this.loyalityObjId.loyalty_type_description;
    // this.IsActive = this.loyalityObjId.isactive;
    this.loyalityForm = this.formBuilder.group({
      loyalityName: [this.loyalityObjId.loyalty_type_name,  [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      loyalityDescription: [this.loyalityObjId.loyalty_type_description],
    });
  }
  updateLoyality() {
    this.submitted = true;
    if (this.loyalityForm.invalid) {
      return;
    }

    let { loyalityName, loyalityDescription } = this.loyalityForm.value;
    let loyalityBody = {
      loyalty_type_name: loyalityName,
      loyalty_type_description: loyalityDescription,
      isactive: this.loyalityObjId.isactive,
      loyalty_type_id: this.loyality_id
    }
    this.ngxSpinnerService.show();
    this.master.updateLoyality(loyalityBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully");
        this.router.navigateByUrl(`/corporate/loyalitytypemaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
