import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormBuilder, FormGroup, FormArray, Validators , ValidatorFn, AbstractControl, ValidationErrors,} from '@angular/forms';

import { environment } from 'src/environments/environment';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-customer-contentbuilder',
  templateUrl: './customer-contentbuilder.component.html',
  styleUrls: ['./customer-contentbuilder.component.css']
})

export class CustomerContentbuilderComponent extends BaseComponent implements OnInit {
  image_awskey1:any =[];
  fileObject;
  image_awskey:any;
  dynamicForm: FormGroup;
  submitted = false
  selectedcompany: any;
  selectedAll: any;
  allTableFieldsobj;
  checkedAll = false;
  marked = false;
  theCheckbox = false;
  show: boolean = false;
  fdstatus: boolean = false;
  checkedTable: any = [];
  checkedTheme: any = [];
  changeCheckFileSize = {};
  checkedDatatype = {};
  sensitveTablesobj: any = [];
  nonSensitiveTables: any = [];
  id: any;
  cbdata: any;
  //************** Company & Theme*********************/
  public themeObj: any = [];
  cp = 1;
  ord = true;
  colname: "Theme Name";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID;
  fields: any = [];
  field_size: any = [];
  allTablesobj;
  getCompanyfields;
  contentdataForm: any = FormGroup;
  BUuser;
filedata:any=[];
  //***********************************/
  constructor(
    private formBuilder: FormBuilder,
    private master: MasterService,
    private orderPipe: OrderPipe,
    private cb: CbService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private clientService: ClientService,
    private renderer: Renderer2,
    private upload:UploadService
  ) {
    super();
     this.getCompany();
  }

  ngOnInit() {

    this.dynamicForm = this.formBuilder.group({
      items: new FormArray([])
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    this.companyID = this.currentUser.admin_customer_id;
    this.BUuser = this.companyID;
    this.selectedcompany = this.companyID;
    // alert(this.companyID);
    if(this.BUuser == 0) this.getTheme();
    if (this.BUuser > 0) this.getSelectCompanyThemes(this.selectedcompany);
    this.themeLogData();
    this.getCompany();
    //this.addCBThemeField();


  }
  //Adding content//
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.items as FormArray; }

  delete() {
    let self = this;
   // self.image_awskey = '';
   // this.dynamicForm.controls.photo.setValue('');
   this.fileObject = null;
  }

  setValue(event) {
  //  alert(event);
    let self = this;
    let url = event.target.src;
  //  event.target.src
  //alert(url.indexOf("master_images") );
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      alert(url);
      if (!this.isUrlValid(url)) {
        this.ngxSpinnerService.show();
        let awsUrl = self.cb.getUrlfile(url);
        this.ngxSpinnerService.hide();
        self.image_awskey= url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  //****************************/
  /************************New***************************/
  changeCheckTheme(d) {
    this.show = !this.show;
    let abc = d;
    var index = this.checkedTheme.indexOf(abc);
    if (index > -1) {
      this.checkedTheme.splice(index, 1);
     // console.log(this.checkedTheme);
    }
    else {
      // this.show = !this.show;
      this.checkedTheme.push(abc)
     // console.log(this.checkedTheme);
    }
  }

  /***********************************************************/



   gettingThemeFields(c, d) {

    this.ngxSpinnerService.show();
    let self = this;


    var obj = { themeid: d, dbType:environment.awsKey, customerId: c };
    this.cb.gettingThemeFields(obj).subscribe(data => {

      this.dynamicForm = this.formBuilder.group({
        items: new FormArray([])
      });
      var formlength = data.data.length;
      for (var j = 0; j < formlength; j++) {
        if (data.data[j].cb_field_type === 'undefined') {
          data.data[j].cb_field_type = "text";
        }
        if (data.data[j].cb_field_type === 'file') {
              this.fileObject = data.data[j].cb_field_name;
              //this.image_awskey[data.data[j].cbtaid] = null;
              this.t.push(this.formBuilder.group({
                cbtaid: [data.data[j].cbtaid, Validators.required],
                cb_field_name: [data.data[j].cb_field_name, Validators.required]
              }));
        } else {
          this.t.push(this.formBuilder.group({
            cbtaid: [data.data[j].cbtaid, Validators.required],
            cb_field_name: [data.data[j].cb_field_name, Validators.required]
          }));
        }
      }
      self.getCompanyfields = data.data;
     // console.log(self.getCompanyfields);
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

 async processFile(event1,imageInput) {
    const file: File = imageInput.files[0];
   // alert(file);
    let filedata = [];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      this.fileObject = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
       this.image_awskey = reader.result;
      this.toUploadFiles(file.name,reader.result).then(data => {
          this.image_awskey = data;
          this.filedata[event1]=data;
        }).catch(error => {
          this.toastrService.error('Please try again later');
        });
      }

    }
  }

  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show()
    return new Promise((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file,2).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Key) {
              self.image_awskey = params.Key;
              resolve(self.image_awskey)
            }
          }else{
            reject(res.message)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(err)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }




  addthemecCntentData(cid) {
  for(var k=0;k<this.t.length;k++){
    var tt =this.t.controls[k].value.cbtaid;
    var keys= Object.keys(this.t.controls[k].value);
    if(this.filedata[tt]){
    this.t.controls[k].value.cb_field_name=this.filedata[tt];
    }
  }
    var objt = this.t.value;
    this.ngxSpinnerService.show();
   // console.log(JSON.stringify(objt));
    this.cb.addingFieldsContent(objt, cid).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success('data addedsuccessfully');
      }
    }, error => {
     // console.log(error);
    });
  }

  /************************************************************/
  changeCheckTables(a) {
    this.fdstatus = !this.fdstatus;
    //alert(this.fdstatus);
    let abc = a;
    var index = this.checkedTable.indexOf(abc);
    if (index > -1) {
      this.checkedTable.splice(index, 1);
    //  console.log(this.checkedTable);
    }
    else {
      this.checkedTable.push(abc);
     // console.log(JSON.stringify(this.checkedTable));
    }
  }

  changeCheckField(k1, v1) {
    let key = k1;
    let value = v1;
    this.changeCheckFileSize[key] = value;
  }

  changeCheckDataType(key2, value2) {
    let key = key2;
    let value = value2;
    this.checkedDatatype[key] = value;

  }

  /************************New***************************/
  gettingContentBuilderFields() {
    let self = this;
    this.cb.gettingContentBuilderFields().subscribe(data => {
      self.allTablesobj = data.data;
    //  console.log(data.data);
    }, error => {
     // console.log(error);
    });
  }

  themeLogData() {
    let themeLogs = {
      log_type: "Theme Master View",
      log_name: "Theme Master View",
      log_description: "Theme Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  getTheme() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getTheme(this.companyID).subscribe(data => {
      self.themeObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getCompanyThemes(id) {
    //event.preventDefault();
    this.companyID = id;
  //  console.log("company themes:" + id);
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCompanyTheme(id).subscribe(data => {
      self.themeObj = data.data.rows;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  getSelectCompanyThemes(id) {
    this.selectedcompany = id;
    var obj = { customerId: id, companyid: id };
   // console.log("company themes:" + id);
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getSelectCompanyThemes(obj).subscribe(data => {
      self.themeObj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  editTheme(id) {

    this.router.navigate(['/corporate/newtheme'], { queryParams: { companyID: this.companyID, them_id: id } });
  }

  getCompany() {

    let self = this;
    this.ngxSpinnerService.show();
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }






  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }

}

