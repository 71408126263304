import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseserviceService } from '../baseservice.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SegmentationService {

  public baseUrl;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.segBaseUrl + 'api/segmentation/';
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  // tohandle the error

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');

  }

  //  ###############  Get Attribute Group List  ####################  //

  getAttributeGroupList(limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}attributeGroup/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Add Attribute Group ############################  //

  addAttributeGroup(obj) {
    const URL = this.baseUrl + 'attributeGroup';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #############  Get Attribute By Group ID  #####################  //
  getAttributeById(id) {
    const URL = this.baseUrl + 'attributeGroup' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }
  //  ###########  Update Attribute Group Fields ####################  //
  updateAttributeGroupFields(obj, id) {
    const URL = this.baseUrl + 'attributeGroup' + '/' + id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }
  //  ##########  Delete Attribute Group ##########################  //
  deleteAttributeGroup(id) {
    const URL = this.baseUrl + 'attributeGroup' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ###############  Get Operator Group List  ####################  //

  getOperatorGroupList(limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}operatorGroup/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete Operator Group List  ####################  //

  deleteOperatorGroupList(id) {
    const URL = this.baseUrl + 'operatorGroup' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Update Operator Group List  ####################  //

  updateOperatorGroup(obj, id) {
    const URL = this.baseUrl + 'operatorGroup/' + id;
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  add Operator Group List  ####################  //

  addingOperatorGroup(obj) {
    const URL = this.baseUrl + 'operatorGroup/';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Operator Group by ID List  ####################  //

  getOperatorGroupId(id) {
    const URL = this.baseUrl + 'operatorGroup' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ###############  Get Operator List  ####################  //

  getOperatorList(limit, offset, colname: any = "createdon", colname_val: any = "desc", searchText: any = "", group:any = "") {
    return this.http.get<any>(`${this.baseUrl}operator/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}&filter=&groupName=${group}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete Operator List  ####################  //

  deleteOperatorList(id) {
    const URL = this.baseUrl + 'operator' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Update Operator Group List  ####################  //

  updateOperator(obj, id) {
    const URL = this.baseUrl + 'operator/' + id;
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  add Operator List  ####################  //

  addingOperator(obj) {
    const URL = this.baseUrl + 'operator/';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Get Attribute Type details by ID  ###############  //

  getAttributeTypeById(id) {
    const URL = this.baseUrl + 'attributeType' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Add Attribute Type #############################  //

  addAttributeType(obj) {
    const URL = this.baseUrl + 'attributeType';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ########  Get Attribute Type List  ###################  //

  getAttributeTypeList(limit, offset) {
    const URL = this.baseUrl + 'attributeType' + '/' + limit + '/' + offset;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############ Update Attribute Type Fields ###############  //

  updateAttributeTypeFields(obj, id) {
    const URL = this.baseUrl + 'attributeType' + '/' + id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete Attribute Type  #################  //

  deleteAttributeType(id) {
    const URL = this.baseUrl + 'attributeType' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Operator by ID List  ####################  //

  getOperatorId(id) {
    const URL = this.baseUrl + 'operator' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Entities   ####################  //

  getEntity(limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}entities/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete Entities  ####################  //

  deleteEntity(id) {
    const URL = this.baseUrl + 'entities' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Update Entities ####################  //

  updateEntity(obj, id) {
    const URL = this.baseUrl + 'entities/' + id;
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  add Entities ####################  //

  addingEntity(obj) {
    const URL = this.baseUrl + 'entities/';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Entities by ID List  ####################  //

  getEntityId(id) {
    const URL = this.baseUrl + 'entities' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get audience   ####################  //

  getAudience(limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}audience/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete audience  ####################  //

  deleteAudience(id) {
    const URL = this.baseUrl + 'audience' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Update audience ####################  //

  updateAudience(obj, id) {
    const URL = this.baseUrl + 'audience/' + id;
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  add audience ####################  //

  addingAudience(obj) {
    const URL = this.baseUrl + 'audience/';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get audience by ID List  ####################  //

  getAudienceId(id) {
    const URL = this.baseUrl + 'audience' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Get Segmentation Attributes  ####################  //

  getSegementAttributeList(limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}attribute/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ########### Get Active Attributes Groups  ##########  //

  getActiveAttributesGroup() {
    const URL = this.baseUrl + 'attributeGroup' + '/' + 'activeList';
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Get Active Attributes Types  ##########  //

  getActiveAttributesTypes() {
    const URL = this.baseUrl + 'attributeType' + '/' + 'activeList';
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #########  Add New Attribute for Segmentation  ##########  //

  addAttribute(obj) {
    const URL = this.baseUrl + 'attribute';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ########  Get Segmentation Attribute By ID  ###########  //

  getSegmentAttributeById(id) {
    const URL = this.baseUrl + 'attribute' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #######  Update Segmentation Attribute ##########  //

  updateSegmentationAttribute(obj, id) {
    const URL = this.baseUrl + 'attribute' + '/' + id;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #########  Change Segmentation Attribute Status #############  //

  updateSegmentationAttributeStatus(obj) {
    const URL = this.baseUrl + 'attribute' + '/' + 'updatedStatus';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Delete Segmentation Attribute  ################  //

  deleteSegmentationAttribute(id) {
    const URL = this.baseUrl + 'attribute' + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ############### Get Associated Attributes for Entities by ID  ################  //

  getAssociatedAttributesForEntities(id) {
    const URL = this.baseUrl + 'entitiesAttibuteRelation' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }
  //  ###############  Get Table   ####################  //

  getTable(company,limit, offset, colname: any = "", colname_val: any = "", searchText: any = "") {
    return this.http.get<any>(`${this.baseUrl}segmentTable/${company}/${limit}/${offset}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Delete Table  ####################  //

  deleteTable(company,id) {
    const URL = this.baseUrl + 'segmentTable'+ '/' + company + '/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Update Table ####################  //

  updateTable(obj) {
    const URL = this.baseUrl + 'segmentTable';
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }
  //  ###############  Update Table ####################  //

  updatedStatus(obj) {
    const URL = this.baseUrl + 'segmentTable/updatedStatus';
    return this.http.put<any>(URL, obj, this.httpHeadersOptions())

      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ############  Add Entity Attribute Association ################  //

  addEntityAttributeAssociation(obj) {
    const URL = this.baseUrl + 'entitiesAttibuteRelation';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());

  }

  //  ###############  add Table ####################  //

  addingTable(obj) {
    const URL = this.baseUrl + 'segmentTable/';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())

      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ###############  Get Table by ID List  ####################  //

  getTableId(id) {
    const URL = this.baseUrl + 'segmentTable' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ################  Get Full List for Entities and Attribute Association #################  //

  getAllEntityAttributeAssoc() {
    const URL = this.baseUrl + 'entitiesAttibuteRelation/all';
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Get Full List for Entities and Attribute Association  ############# //

  getAllEntityAudienceAssoc() {
    const URL = this.baseUrl + 'audienceEntitiesRelation/all';
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Associated Audience for Entities  #########################  //

  getAssociatedAudienceForEntities(id) {
    const URL = this.baseUrl + 'audienceEntitiesRelation' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ##############  Add Audience Entities Association  ###########################  //

  addEntityAudienceAssociation(obj) {
    const URL = this.baseUrl + 'audienceEntitiesRelation';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  //  ############  Full List for Attribute and Operator Association  ############  //

  getAllAttributeOperatorAssoc() {
    const URL = this.baseUrl + 'attibuteOperatorRelation/all';
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  Fetch Associated Operators for Attributes by ID  ##########  //

  getAssociatedOperatorsForAttributes(id) {
    const URL = this.baseUrl + 'attibuteOperatorRelation' + '/' + id;
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ###########  Add Attribute-Operator Association  ##############  //

  addAttributeOperatorAssociation(obj) {
    const URL = this.baseUrl + 'attibuteOperatorRelation';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  //  ########## Fetch Active Tables List ######################   //

  getActiveTablesList() {
    const URL = this.baseUrl + 'segmentTable/activeList';
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ########## Fetch Active Audience List from Master #####################  //

  getMasterActiveAudienceList() {
    const URL = this.baseUrl + 'audience/activeList';
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ###########  Get Data for Cross Sell- Replenishment Audience  ##############  //

  getCrossSellReplenishmentAudList(obj) {
    const URL = this.baseUrl + 'audiencebuilder/aiResult/report';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  getRepCrossSellCustomerList(obj) {
    const URL = this.baseUrl + 'audiencebuilder/aiContacts/report';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  getRepCrossSellDsList(obj) {
    const URL = this.baseUrl + 'audiencebuilder/aiDs/report';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  launchCrossSellRepCampaign(obj) {
    const URL = this.baseUrl + 'audiencebuilder/buildAiCampaign';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  getAllData(obj) {
    const URL = this.baseUrl + 'audiencebuilder/aiContacts/getAllDsId';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }


}
