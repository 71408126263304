<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>


<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item active">Company Type</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>
  </div>
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/addnewcompany">
    <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0">
    <tr>
      <!-- <th> Company Type Id</th> -->
      <!-- <th> Company Type ParentId</th> -->
      <th (click)="setOrder('company_type_name')" style="cursor: pointer;">
        Company Type Name
        <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>
        Company Type Description </th>
      <th>Company Type Parent Name</th>
      
      <th>Created By</th>
      <th (click)="setOrder('createdon')" style="cursor: pointer;">Created On
        <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>
  
    <tr
      *ngFor="let obj of companyTypeObj | filter:text | orderBy: order:reverse:'case-insensitive' | paginate: { itemsPerPage: 10, currentPage: p }">
      <td>{{obj.company_type_name}}</td>
      <td>{{obj.company_type_description}}</td>
      <td>{{obj.parentname?obj.parentname:'Root'}}</td>
      
      <td>{{obj.createdname}}</td>
      <td>{{obj.createdon | date:'medium'}}</td>
      
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
        <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editCompanyType(obj.company_type_id)" placement="bottom" ngbTooltip="Edit"></i>
        <i class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteCompanyType(obj.company_type_id)" placement="bottom" ngbTooltip="Delete"></i>
        <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
          (click)="updateStatus($event,obj.company_type_id)">
        </mat-slide-toggle>
      </div>
      </td>
    </tr>

</table>
<div *ngIf="(companyTypeObj | filter:text).length === 0" style="text-align: center;">
  <p>No Result Available</p>

<pagination-controls *ngIf="(companyTypeObj | filter:text).length !== 0" class="my-pagination" (pageChange)="p = $event"></pagination-controls>
</div>
</div>