<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Premium Campaign</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Replenishment Listing</li>
    </ol>
  </nav>
</div>

<!-- <div class="container" *ngIf="customerId === 0 || isHeadquarter"> -->
  <div class="container">
  <form class="form-horizontal mt-1" [formGroup]="buForm">
    <div class="row">
      <div class="col-xl-4 col-sm-6" >
        <div class="card2 mt- mb-2" *ngIf="customerId === 0">
          <div class="card-body2">
            <div>
             <select autocomplete="off" class="custom-select" formControlName="buid" (change)="onBuSelected()"
                class="form-control formstyle">
                <option value="">
                  Select BU
                </option>
                <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                  {{ obj.customer_company_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8 mb-12" *ngIf="selectedBuId">
        <form [formGroup]="form">


          <div class="rowTr">
            <div class="col-md-6-Td text-left">


              <div>
                <ul class="dayMonthM pull-right">


                  <li *ngIf="totalRecords >0">
                    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
                        <img class="customimg" src="../../../assets/custom/images/export.png">

                    </button>
                  </li>



                  <li style="width:150px;">

                    <!-- <ng-select [(ngModel)]="selectedDS" formControlName="dsid" (change)="filterByDs(selectedDS)">
                      <ng-option value="0">
                        Select all1
                      </ng-option>
                      <ng-option  *ngFor="let obj of getDSList" [value]="obj.dsuserid" >
                          {{ obj.dsname }}
                      </ng-option>
                    </ng-select> -->

                    <select autocomplete="off"  class="custom-select"
                      (change)="filterByDs($event.target.value)" formControlName="dsid">
                      <option value="0">
                        Select All

                      </option>
                      <option *ngFor="let obj of getDSList" value="{{obj.dsuserid}}">
                        {{ obj.dsname }}
                      </option>

                    </select>

                  </li>

                  <li>
                    <select autocomplete="off" class="custom-select" formControlName="campType" [(ngModel)]="campaignType"
                      (change)="selectedCampType($event.target.value)" style="width: 138px;">
                      <option [value]="0">
                        Pending
                      </option>
                      <option [value]="1">
                        DS Sent
                      </option>
                      <option [value]="2">
                        Corporate Sent
                      </option>
                    </select>
                  </li>

                  <li *ngIf="campaignType!='0'">
                    <a [class.activeMonth]="selectedIndex === 'day'" (click)="getFilterByTab('day')">Day</a>
                  </li>
                  <li>
                    <a [class.activeMonth]="selectedIndex === 'week'" (click)="getFilterByTab('week')">Week</a>
                  </li>
                  <li *ngIf="campaignType!='0'">
                    <a [class.activeMonth]="selectedIndex === 'month'" (click)="getFilterByTab('month')">Month</a>
                  </li>

                  <li>

                    <a [class.activeMonth]="selectedIndex === 'custom'" (click)="getFilterByTab('custom')">Custom</a>
                  </li>
                </ul>



              </div>
            </div>

            <div class="col-md-6-Td">
              <div class="dateTimeM calRgt pull-right ml-0" *ngIf="selectedIndex === 'custom'">


                <mat-form-field appearance="fill">
                  <!-- <label class="mat-form-label nonBld">Start Date:</label> -->
                  <input class="form-control" placeholder="Start Date:" matInput [max]="maxdate" [min]="mindate" [matDatepicker]="picker" readonly
                    formControlName="startdate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>



                <mat-form-field appearance="fill">
                  <!-- <label class="mat-form-label nonBld">End Date:</label> -->
                  <input class="form-control" placeholder="End Date:" matInput [max]="maxdate" [min]="mindate" [matDatepicker]="picker2" readonly
                    formControlName="enddate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>


                <div class="custbtn-wrap">

                  <button class="custbtn mr-0" type="submit" (click)="fetchRecords()">Submit</button>

                </div>

                <!-- <button mat-raised-button color="accent" type="submit">Search</button> -->



              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </form>
</div>

<div class="container" *ngIf="selectedBuId">

  <div class="row">
    <!-- <div class="col-xl-3 pr-0 mb-3">
      <h5 class="mainHeading_1">Replenishment List : </h5>
    </div> -->
    <div class="col-xl-12 pt-1 mb-1">
      <h6 class="mt-1" *ngIf="startDate && endDate">
        Dates Filtered by Journey Start Date between the selected date : {{startDate}} To {{endDate}}
      </h6>
    </div>



  </div>

  <div class="row">

    <div class="tblPrt col-12">
      <!-- <h6 class="mt-1" *ngIf="startDate && endDate">Dates Filtered: {{startDate}} to {{endDate}}</h6> -->

      <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">

        <tr>
          <th *ngIf="campaignType == '0' && dsID!=0">
            <span>
              <!-- <input type="checkbox"  [disabled]="list?.length == 0" [(ngModel)]="isAllChecked" name="list_name" (change)="checkUncheckAll()" /> -->
              <input type="checkbox" [disabled]="list?.length == 0" [checked]="isAllChecked"
                [indeterminate]="onSelectUnselect()" (change)="checkUncheckAll($event.target.checked)" />
            </span> Select All
          </th>
          <th>Product Image</th>
          <th>Product Name/Category</th>
          <th *ngIf="dsID==0">DS Count</th>
          <th>Theme Name</th>
          <th class="price">Point</th>
          <th>Customers</th>

        </tr>

        <tr *ngFor="let obj of list">

          <td *ngIf="campaignType == '0' && dsID!=0">
            <!-- <input type="checkbox" [checked]="isAllChecked"  (change)="onSelectUnselect(obj, $event)" value="{{obj.id}}"> -->

            <input [disabled]="obj.checkBoxDisable" type="checkbox" [(ngModel)]="obj.isSelected"
              (ngModelChange)="isAllSelected()" />

          </td>
          <td>
            <p *ngIf="obj.images" class="text-center"><img [src]="obj.images" style="height:70px;"></p>
            <p *ngIf="!obj.images" class="text-center"><img [src]="defaultPicture" style="height:70px;"></p>
          </td>
          <td>
            <p>{{obj.name}}/{{obj.product_category}}</p>

          </td>
          <td *ngIf="dsID==0"><a (click)="getDSCustomers(obj)">{{obj.ds_count}}</a></td>
          <td>{{obj.theme_name}}</td>
          <td>{{obj.price}}</td>
          <td><a (click)="getCustomers(obj)">{{obj.customer}}</a></td>
        </tr>


      </table>

      <div class="text-center" *ngIf="checkedList.length>0 && dsID!=0">
        <button type="submit" class="custbtn" (click)="launchCampaign()">Launch Campaign </button>
      </div>

      <div *ngIf="list.length == 0" style="text-align: center">
        <p>No Result Available</p>
      </div>
      <div class="mt-2 text-center difPagination" *ngIf="list?.length > 0">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [(pageSize)]="limit" [maxSize]="2"
          [rotate]="true" (pageChange)="getDataOnPageChange(page=$event)" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>

      </div>
    </div>


  </div>


</div>
