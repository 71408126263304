<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Webinar Module</li>
    </ol>
  </nav>
</div>
<!--FAQ category Tabs-->
<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Webinar</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/webinar-category">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Webinar Category
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/webinar-tag">
          <i class="bi bi-tags blue-lt-icon" aria-hidden="true"></i>Webinar Tag
        </button>
      </div>
      <!-- <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/faq">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Webinar Library
        </button>
      </div> -->
    </div>
  </fieldset>
</div>
<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">FAQ</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/faq-category">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>FAQ Category
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/faq-library">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>FAQ
        </button>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Poll</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/poll">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Poll 
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/poll-option">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Poll Options
        </button>
      </div>
    </div>
  </fieldset>
</div>
<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Templates</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/templates">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Templates 
        </button>
      </div>
      <!-- <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/poll-option">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Poll Options
        </button>
      </div> -->
    </div>
  </fieldset>
</div>