import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseserviceService} from '../baseservice.service';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class McConnectorService {

  public baseUrl;
  public baseUrl1;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.segBaseUrl + 'api/MC/';
    this.baseUrl1 = baseService.baseUrl1;
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  // tohandle the error

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  //  ##########  Get Timezones ####################  //

  getTimezones(){
    const URL = this.baseService.baseUrl1 + 'api/getTimeZones';
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Save MC Information####################  //

  addingMCInformation(obj){
    let body = JSON.stringify(obj);
    const URL = this.baseUrl + 'information';
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ###########  Check if MC Information is available for BU ###############  //

  checkMcInfoData(companyId, limit, offset){
    const URL = this.baseUrl + 'information/' + companyId + '/' + limit + '/' + offset;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Check connectivity with TOKEN URL  ############  //

  checkMcTokenURL(companyId) {
    const URL = this.baseUrl + 'information/checkToken/' + companyId;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Update MC Information Data #####################   //

  updateMCInformation(obj) {
    let body = JSON.stringify(obj);
    const URL = this.baseUrl + 'information';
    return this.http.put<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //   #############  Submit Schedule for MC Connector ################  //

  createMcSchedule(obj) {
    let body = JSON.stringify(obj);
    const URL = this.baseUrl + 'schedule';
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //   ###############  Fetch DC Extension Mapping  ##################  //

  getDataExtensionMapping(companyId){
    const URL = this.baseUrl + 'customdata/dataExtensionNames?company=' + companyId;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ######### Add MC Mapping Details  #################  //

  addMCDataExtension(obj) {
    let body = JSON.stringify(obj);
    const URL = this.baseUrl + 'mapping';
    return this.http.post<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###########  Fetch MC Scheduler Data #################  //

  getMcSchedulerInfo(companyId) {

    const URL = this.baseUrl + 'schedule' + '/' + companyId + '/' + 1 + '/' + 0;

    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //   #############  Check for MC Connector Data   ###################  //

  checkDataExtensionMapping(companyId) {
    const URL = this.baseUrl + 'mapping' + '/' + companyId;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Update MC Schedule #####################  //

  updateMcSchedule(obj) {
    let body = JSON.stringify(obj);
    const URL = this.baseUrl + 'schedule';
    return this.http.put<any>(URL, body,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##########  Fetch stats for Scheduler Runs  #################  //

  getSchedulerRunStats(companyId, limit, offset) {
    const URL = this.baseUrl + 'customdata/logs/' + companyId + '/' + limit + '/' + limit * offset;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ###############  Get Attributes by Customer Key ####################  //

  getDataExtensionAttributesbyCustomerKey(key, companyId) {
    const URL = this.baseUrl + 'customdata/dataExtensionColumnNames?company=' + companyId + '&key=' + key;
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

}
