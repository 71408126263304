<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/entities-audience-association-list">Entities Audience Association List</a></li>
      <li class="breadcrumb-item active" aria-current="page">New Entities Audience Association</li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="audienceForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">


            <div class="form-group">
              <label for="audienceForm">Audience Name <span style="color:red">*</span></label>
              <select formControlName="aid" class="form-control formstyle"
                      [ngClass]="{ 'is-invalid': submitted && f.aid.errors }"  (change)="getEntityAudience($event.target.value)">

                <option value="">Please Select Audience</option>
                <option *ngFor="let userObj of audienceList" value={{userObj.audience_id}}>
                  {{userObj.audience_name}}
                </option>
              </select>
              <div *ngIf="submitted && f.aid.errors" class="invalid-feedback">
                <div *ngIf="f.aid.errors.required">Audience is required</div>
              </div>
            </div>

            <div class="form-group" *ngIf="show">
              <label for="audienceForm">Entities List</label>
              <div class="piInnerList scroller">
                <ul class="filtMain">
                  <li *ngFor="let item of entities">
                    <div class="row">
                      <div class="col-sm-9">
                        <label class="form-check-label">
                          {{item.entity_name}}
                        </label>
                      </div>

                      <div class="col-sm-3">

                        <input  [checked]="checkForIdInList(item.entity_id)" [value]="item.entity_id" [name]="item.entity_name"  type="checkbox"  (change)="selectEntities($event, item.entity_id)">

                      </div>

                    </div>

                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="card-footer text-center ">
            <div class="my_class text-center">
              <button type="submit" class="custbtn" *ngIf="isWrite" (click)="addEntities()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
