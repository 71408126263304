import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import { CommonMessages, ClientMessages } from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { Location } from '@angular/common';
import { fromEvent } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  ord = 'asc';

  colname= 'customer_company_name';
  text: any = '';

  isAllowed: boolean;
  public companyObj: any = [];
  AppConstants: AppConstants;
  totalCount: number = 0;
  page = 1;
  limit = 10;
  skip = 0;

  constructor(
    private clients: ClientService,
    public location: Location
  ) {
    super();
  }

  ngOnInit() {
    localStorage.removeItem("updatedClientId");
    localStorage.removeItem("editClientId");

    this.ngxSpinnerService.show();
    this.getCompanyData();
    this.ClientLogData();
    this.checkSize();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),

        tap(()=> {

          this.skip = 0;
          this.page = 1;
        }),
        switchMap(data => this.clients.getCompanyClientList(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.companyObj = [] }
          else { this.companyObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.companyObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col) {
    this.colname = col

    if(this.ord == 'asc') {

      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getCompanyData()
  }


  checkSize() {
    if (window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH_HORIZONTAL) {
      this.isAllowed = true;
    } else {
      this.isAllowed = false;
    }
  }


  ClientLogData() {
    let themeLogs = {
      log_type: "Client View",
      log_name: "Client View",
      log_description: "Client View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });
  }


  //Fetch Client Data
  getCompanyData() {
    let searchTerm = this.searchText.nativeElement.value;
    let self = this;
    this.skip = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.clients.getCompanyClientList(this.limit, this.skip, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.companyObj = [] }
        else { self.companyObj = data.data.rows };
        self.totalCount = data.data.total;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }


  //Edit Client data
  editCompany(id) {


    localStorage.setItem("editClientId", id);
    this.router.navigate(['/corporate/editCompany'], { queryParams: { client_id: id } });
  }

  //Delete Client Data
  deleteCompany(id) {
    this.confirmationDialogService.confirm(CommonMessages.confirmationTitle, CommonMessages.deleteConfirmation, 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.clients.deleteClient(id).subscribe(data => {
            if (data.status) {
              this.getCompanyData();
              setTimeout(() => {
                this.toastrService.success(ClientMessages.ClientDeletedSuccessMessage);
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  clientView(id) {
    localStorage.setItem("updatedClientId", id);
    this.router.navigate(['/corporate/clienthome'], { queryParams: { client_id: id } });
  }
  clientHeadquaterView(id) {
    localStorage.setItem("updatedClientId", id);
    this.router.navigate(['/corporate/clientheadquaterhome'], { queryParams: { client_id: id } });
  }
  // Update Client Status
  updateClientStatus(event, id) {
    let value = event.source.checked;
    let obj: any = {};
    obj.isactive = value;
    obj.customer_id = id;
    this.confirmationDialogService.confirm('Please confirm', CommonMessages.activeInactiveConfirmation + " '" + (value ? "ACTIVE" : "INACTIVE") + "'?", 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          //this.clients.updateClient(obj).subscribe(data => {
          this.clients.updateClientStatus(obj).subscribe(data => {
            this.getCompanyData();
            this.toastrService.success(CommonMessages.statusUpdated);
          });
        }
      }).catch(() => {
        event.source.checked = !event.source.checked;
        //console.log(CommonMessages.dialogDismissMessage)
      });
  }
}
