import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseserviceService } from "../baseservice.service";



@Injectable({
    providedIn: 'root'
})
export class ScheduleJourneyService {

    public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + "api/"
  }

  addJourneySchedule(obj, cid) {
    var body = obj;
    return this.http.post<any>(this.baseUrl + "job/" + cid, body, this.httpHeadersOptions());
  }

  getJourneyListing(cid, id, limit, offset) {
    let URL = this.baseUrl + 'jobs/' + cid + '/' + id + '/' + limit + '/' + offset;
    return this.http.get(URL, this.httpHeadersOptions());
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

}