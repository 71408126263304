import { Injectable } from '@angular/core';
import { Router, CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (localStorage.getItem('currentUser')) {
        //     // logged in so return true
        //     return true;
        // }

        /* Below code added for ON-163 to check Session storage is valid or not */

        let currUser = JSON.parse(sessionStorage.getItem('userData'));
        if (!currUser) {
            sessionStorage.clear();
            localStorage.clear();
        }

        /* Code for ON-163 ends here  */

        if(localStorage.getItem("accesToken") && sessionStorage.getItem("userData")){
            // console.log('Token exist');
            
            // this.router.navigate(['business/help']);
           return true;
           
        }
        else{
            this.router.navigate(['/login'],{ queryParams: { returnUrl:  state.url}});
            return false;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
       
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.canActivate(route,state);
    }
}
