import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base.component';


@Component({
  selector: 'app-contact-market-activity',
  templateUrl: './contact-market-activity.component.html',
  styleUrls: ['./contact-market-activity.component.css']
})

export class ContactMarketActivityComponent extends BaseComponent implements OnInit {

  //companyID=62;
  companyID:any = 0;
  Search=null;
  companyId;
  pendingrecord:number=0;
  currentUser;
  company_status:boolean=false;
  cpdata={customer_id:null};
  getCompanyobj;
  customerID;
  obj;
  reportData:any=[];
  p: number = 1;
  total: number;
  status="select";
  start_date;
  end_date;
  clicked=false;
  total_contacts;    
  Consumetotal;
  total_used;

  user_type='all';
  showResult:boolean=true;
  projectName=environment.projectName
  
  report_status=180;
  isHeadquater;


  constructor(private report:ReportService,
    private clientService : ClientService,
    public toastrService: ToastrService,
    private spinner : NgxSpinnerService) {super() }

  ngOnInit() {    
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
  //  console.log(this.currentUser);
   this.isHeadquater=localStorage.getItem("customer_isheadquarter");
    
    this.companyID= this.currentUser.admin_customer_id;
    this.companyId= this.currentUser.admin_customer_id;
    
    if(this.companyID==0 || this.isHeadquater ==="true"){
      //this.getMarketActivity(3,180,'all',this.Search);
      this.getCompany(this.companyId);
      this.company_status=true;
      this.companyID=0;
      this.isHeadquater = true;
     
    }
    else  {
      this.isHeadquater = false;
      this.getMarketActivity(this.companyId,180,'all',this.Search);

    }
 }

  gettingReports(page) {
    const parmas = {
      customer_id: this.companyID,
      search:this.Search,
      page: page,    
      report_status: this.report_status,
      user_type: this.user_type      
    };

     //  this.getMarketActivity(this.companyID,this.start_date,this.end_date,this.user_type);
       this.getMarketActivity(this.companyID,this.report_status,this.user_type,this.Search);
  }

  getMarketActivity(companyID,report_status,user_type,cname){

    this.companyId =companyID;

    if(this.companyId==0) {
      this.reportData = [];
      this.toastrService.error("Please select company");
      return false;
    }
    
    this.ngxSpinnerService.show();
    this.report.getMarketActivity(companyID,report_status,user_type,cname).subscribe((data)=>{
      if(data.status)
      { 
        //this.showResult=false;
        this.clicked=true;      
        this.reportData= data.data;
      }else{
        //this.showResult=true;
        this.reportData = [];
      }  
     this.ngxSpinnerService.hide();
    });
  }

  getCompany(companyId:any) {    
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
   
        self.getCompanyobj = data.data;
    
     // this.selectComapanyName(companyId)
    }, error => {
    //  console.log(error);
    });

  }
 
}