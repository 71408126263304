<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">PII & GDPR</li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
  <legend class="scheduler-border"><h5 class="font-weight-bold">PII & GDPR Navigation</h5></legend>
  <fieldset class="scheduler-border">


    <div class="row" *ngIf="customerID>0">
      <!-- <div class="col-xl-4  col-md-6">
          <button class="cbtn mt-1" routerLink="/corporate/pii-data-view">
           <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png"> 
            <i class="fa fa-search red-dr-icon" aria-hidden="true"></i>View PII Dataset  
          </button>
      </div> -->
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/pii-individual-profile">
          <i class="bi bi-person-square orange-dr-icon" aria-hidden="true"></i>Individual Profiling
        </button>
      </div>
      <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/logs">
          <i class="fa fa-users blue-lt-icon" aria-hidden="true"></i>PII Logs 
        </button>
      </div> -->

    </div>
    
    <div class="row" *ngIf="customerID==0">
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/pii-data-view">
          <!-- <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">--> 
          <i class="bi bi-file-earmark-spreadsheet red-dr-icon" aria-hidden="true"></i>PII Tables Management  
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/pii-field">
        <i class="bi bi-gear-wide-connected green-lt-icon" aria-hidden="true"></i>PII Fields/Attributes Management 
      </button>
    </div>
    <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/logs">
          <i class="fa fa-users blue-lt-icon" aria-hidden="true"></i>PII Logs 
        </button>
    </div> -->
    <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/pii-dsar">
          <i class="fa fa-usd green-lt-icon" aria-hidden="true"></i>DSAR Management 
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/pii-report">
        <i class="fa fa-file-text purple-dr-icon" aria-hidden="true"></i>PII & DSAR Reports 
      </button>
    </div> -->
    
    </div>


  </fieldset>
</div>






