import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-cb-section-field-association',
  templateUrl: './cb-section-field-association.component.html',
  styleUrls: ['./cb-section-field-association.component.css']
})
export class CbSectionFieldAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  getCompanyobj;
  companyID=0;
  fieldObj:any=[];

  constructor(
    private clientService:ClientService,
    private cb: CbService,
  ) {
    super();
   }
  ngOnInit() {   
    this.getCompany();
  }
  

  getCompanySection(id){
    //event.preventDefault();
    if(id) {
    this.companyID =id;
   // console.log("company themes:"+ id);
    let self = this;
    this.ngxSpinnerService.show();
    let tempData = [] ;
    this.cb.getCompanySection(id, 9999, 0).subscribe(data => {
      this.ngxSpinnerService.hide();
        if(data.data) {
          tempData = data.data.rows;
          const tempData2 = tempData.filter(item => item.isactive);
          this.themeObj = tempData2;
          this.fieldObj = [];
        } else {
          this.themeObj = [];
          this.fieldObj = [];
        }
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
      this.themeObj = [];
      this.fieldObj = [];
    });
  }else {

    this.themeObj = [];
    this.fieldObj = [];
  } 
  }
  
  getSectionField(id){
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getAssociatedSectionField(this.companyID,id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if( data.data) {
        self.fieldObj = data.data;
      } else {
        this.fieldObj = [];
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.fieldObj = [];
    });

  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      if(data.data) {
        self.getCompanyobj = data.data;
       // console.log(self.getCompanyobj);
      } else {
        self.getCompanyobj = [];
      }
     
    }, error => {
     // console.log(error);
      self.getCompanyobj = [];
    });
  }
}
