<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness">Readiness Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness-resources-list">Readiness Resources</a></li>
      <li class="breadcrumb-item active" aria-current="page">Update Readiness Resources</li>

    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="addActivityForm" (ngSubmit)="onSubmit()">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">

              <label for="exampleForm2">Company Name <span class="required">*</span></label>

              <select formControlName="selectedCompany" class="form-control formstyle" (change)='getParentList()'
                *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
            <div class="form-group">
              <label for="activity_id">Select Activity <span class="required">*</span></label>
              <select name="company" formControlName='activity_id' class="form-control formstyle" required
                [ngClass]="{ 'is-invalid': submitted && f.activity_id.errors }">
                <option value="" disabled hidden selected>Select Activity</option>
                <ng-container *ngFor="let userObj of parentList">
                  <optgroup [label]="userObj.title">
                    <option [value]="sub.id" *ngFor="let sub of userObj.subcategory">{{sub.title}}</option>
                  </optgroup>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.activity_id.errors" class="invalid-feedback">
                <div *ngIf="f.activity_id.errors.required">Activity is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="title">Title <span class="required">*</span></label>
              <input type="text" class="form-control" name="title" formControlName="title"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="50" appSpace>
              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required || f.title.hasError('whitespace')">Title is required</div>
                <div *ngIf="f.title.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Description <span class="required">*</span></label>
              <textarea class="form-control text-area h-25" rows="5" maxlength="100" appSpace
                formControlName="description"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">Description is required</div>
                <div *ngIf="f.description.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="type">Type <span class="required">*</span></label>
              <select name="company" formControlName='type' class="form-control formstyle" (change)='typeChange()' required
                [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                <option value="" disabled hidden selected>Select Type</option>
                <option value='pdf'>PDF</option>
                <option value='image'>IMAGE</option>
                <option value='video'>VIDEO</option>
              </select>
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">Type is required</div>
              </div>
            </div>
            <div class="form-group" *ngIf="f.type.value==='image'">
              <div class="mt-1">
                <label for="exampleForm2">Upload Image<span style="color:red">*</span></label>
                <div *ngIf='theme_screenshot'>
                  <img src="{{theme_screenshot}}" alt="Margo" style="
            width: 200px" (error)="setValue($event)">
                </div>
                <div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio1" name='firstActive'
                      formControlName="firstActive" [value]="true" (change)='radioChange("image")'>
                    <label class="custom-control-label" for="customRadio1">Url</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio2" name='firstActive'
                      formControlName="firstActive" [value]="false" (change)='radioChange("image")'>
                    <label class="custom-control-label" for="customRadio2">Upload a file</label>
                  </div>
                </div>
                <div *ngIf="screenshot_type" class="mt-1">
                  <input type="text" formControlName="link" class="form-control form-control-md mt-1"
                    placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                    [ngClass]="{ 'is-invalid': submitted && f.link.errors }" maxlength="200">
                  <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                    <div *ngIf="f.link.errors.required">Image Url is required</div>
                  </div>
                </div>
                <div *ngIf="!screenshot_type" class="mt-1">
                  <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                    (change)="processFile($event,imageInput,'image')">
                  <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="f.type.value==='video'">
              <div class="mt-1">
                <label for="exampleForm2">Upload Video<span style="color:red">*</span></label>
                <div *ngIf='theme_screenshot2'>
                  <video width="320" height="240" controls src="{{ theme_screenshot2 }}"></video>
                </div>
                <div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio1" name='firstActive'
                      formControlName="firstActive" [value]="true" (change)='radioChange("video")'>
                    <label class="custom-control-label" for="customRadio1">Youtube Url</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio2" name='firstActive'
                      formControlName="firstActive" [value]="false" (change)='radioChange("video")'>
                    <label class="custom-control-label" for="customRadio2">Upload a Video</label>
                  </div>
                </div>
                <div *ngIf="screenshot_type2" class="mt-1">
                  <input type="text" formControlName="link" class="form-control form-control-md mt-1"
                    placeholder="https://www.youtube.com/watch?v=yourVideoID" [ngClass]="{
                    'is-invalid': submitted && f.link.errors
                  }" maxlength="200" />
                  <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                    <div *ngIf="f.link.errors.required">Video Url is required</div>
                  </div>
                  <div *ngIf='f.link.value && !isValidYouTubeUrl(f.link.value)' style="color: red; margin-left: 10px">
                    Please enter correct youtube url
                  </div>
                </div>
                <div *ngIf="!screenshot_type2" class="mt-1">
                  <input #imageInput type="file" accept=".mp4"
                    (change)="processFile($event, imageInput,'video')" />
                  <div style="color: red; margin-left: 10px">
                    Allowed file Types(.mp4)
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="f.type.value==='pdf'">
              <div class="mt-1">
                <label for="exampleForm2">Upload PDF<span style="color:red">*</span></label>
                <div *ngIf="f.link?.value && getFileExtension(f.link?.value) === 'pdf'" class='mb-2'>
                  <a [href]='f.link?.value' target='_blank'>
                    <img
                    src="/assets/custom/images/pdf.png"
                    alt="marGo"
                  />
                  </a>
                </div>
                <div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio1" name='firstActive'
                      formControlName="firstActive" [value]="true" (change)='radioChange("pdf")'>
                    <label class="custom-control-label" for="customRadio1">Url</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="customRadio2" name='firstActive'
                      formControlName="firstActive" [value]="false" (change)='radioChange("pdf")'>
                    <label class="custom-control-label" for="customRadio2">Upload a Pdf</label>
                  </div>
                </div>
                <div *ngIf="screenshot_type3" class="mt-1">
                  <input type="text" formControlName="link" class="form-control form-control-md mt-1"
                    placeholder="https://example.com/pdf(.pdf)" [ngClass]="{
                    'is-invalid': submitted && f.link.errors
                  }" maxlength="200" />
                  <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                    <div *ngIf="f.link.errors.required">PDF Url is required</div>
                  </div>
                </div>
                <div *ngIf="!screenshot_type3" class="mt-1">
                  <input #imageInput type="file" accept=".pdf"
                    (change)="processFile($event, imageInput,'pdf')" />
                  <div style="color: red; margin-left: 10px">
                    Allowed file Types(.pdf)
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="title">Application Link <span class="required">*</span></label>
              <input type="text" class="form-control" name="title" formControlName="application_link"
                [ngClass]="{ 'is-invalid': submitted && f.application_link.errors }" appSpace>
              <div *ngIf="submitted && f.application_link.errors" class="invalid-feedback">
                <div *ngIf="f.application_link.errors.required">Application link is required</div>
              </div>
            </div> -->
            <!-- <div class="form-group">
              <label for="weight">Weight <span class="required">*</span></label>
              <input type="number" class="form-control" name="weight" formControlName="weight"
                [ngClass]="{ 'is-invalid': submitted && f.weight.errors }">
              <div *ngIf="submitted && f.weight.errors" class="invalid-feedback">
                <div *ngIf="f.weight.errors.required">Weight is required</div>
              </div>
            </div> -->
            <div class="form-group">
              <label for="orderno">Order No. <span class="required">*</span></label>
              <input type="number" class="form-control" name="orderno" formControlName="orderno"
                [ngClass]="{ 'is-invalid': submitted && f.orderno.errors }">
              <div *ngIf="submitted && f.orderno.errors" class="invalid-feedback">
                <div *ngIf="f.orderno.errors.required">Order no. is required</div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <button class="btn btn-round btn-margo-action" type='submit'>Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
