import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';


@Injectable({
  providedIn: 'root'
})
export class PiiService {
 
  public baseUrls;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrls = baseService.baseUrl1 + "api/pii/"
   }

  gettingTables(id) {
    var body = {};
    return this.http.get<any>(this.baseUrls + `gettingTables/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 
  

  getProfileData(id) {
    var body = {};
    return this.http.get<any>(this.baseUrls + `getProfileData/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  getOptInLogsData(id,company) {
    var body = {};
    return this.http.get<any>(this.baseUrls + `getOptInLogsData/`+id+'/'+company,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  getWebLogsData(id,company,limit,offset) {
    var body = {};
    return this.http.get<any>(this.baseUrls + `getWebLogsData/`+id+'/'+company+"?limit="+limit+"&offset="+offset,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  getIndividualProfileDataByParentID(id) {
    var body = {};
    return this.http.get<any>(this.baseUrls + `getIndividualProfileDataByParentID/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  gettIndividualProfileData() {
    var body = {};
    return this.http.get<any>(this.baseUrls + `gettIndividualProfileData`,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }




  
  delProfileData(value) {
    var id = value;   
    return this.http.delete<any>(this.baseUrls + "delIndividualProfileData/" + id.id, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  gettingSensitiveTables(id) {
     return this.http.get<any>(this.baseUrls + `gettingSenstiveTables/${id}`,this.httpHeadersOptions() )
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 
    
  gettingTableField(name,id) {
  
    return this.http.get<any>(this.baseUrls + `gettingTableFields/${name}/${id}`, this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  } 


  delTable(id,company) {
   
   // console.log(id);
    return this.http.delete<any>(this.baseUrls + "deleteSenstiveTables/"+id+"/"+company, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  deleteTableField(id,company) {
   
    return this.http.delete<any>(this.baseUrls + "deleteTableField/"  + id+'/'+company, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  searchtIndividualProfileData(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "searchtIndividualProfileData", body, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  searchtIndividualProfile(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "searchtIndividualProfile", body, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }




  addNewData(value){    
    var body = value;
    return this.http.post<any>(this.baseUrls + "insertSenstiveTables/",body, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addsFeildData(value){    
    var body = value;
    return this.http.post<any>(this.baseUrls + "insertSenstiveTableFields/",body, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  
/*
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }*/
  
  gettingSenstiveTables(id){
    var body = {};
    return this.http.get<any>(this.baseUrls + `gettingSenstiveTables/${id}`, this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  gettingSenstiveTableFields(id,companyID){
    return this.http.get<any>(this.baseUrls + `gettingSenstiveTableFields/${id}/${companyID}`, this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getIndividualProfileDownload(body) {
    
    return this.http.post<any>(this.baseUrls + `getIndividualProfileDownload`,body,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }
  
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
    //tohandle the error
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
  
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
    };


 
}

