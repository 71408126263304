import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-contentbuilder-view',
  templateUrl: './contentbuilder-view.component.html',
  styleUrls: ['./contentbuilder-view.component.css']
})
export class ContentbuilderViewComponent extends BaseComponent implements OnInit {
  currentUser;
  companyId;
  constructor(
    private orderPipe: OrderPipe,
    private cb: CbService,
    private clientService:ClientService,

  ) { super(); }
  allTablesobj:any=[];
  getCompanyobj;
  ngOnInit() {
  // this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
  // this.companyId= this.currentUser.admin_customer_id; 
   this.getCompany();
    //this.gettingContentBuilderFields();

  }
  getCompany() {     
    this.ngxSpinnerService.show();
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
    });
  }
  editFields(company,field,type){

    this.router.navigate(['/corporate/add-content-builder-field'], { queryParams: { company: company,fieldName: field,fieldType:type } });
 


  }
  gettingContentBuilderFields(id) {

    this.companyId = id;
    
    let self = this;
    this.ngxSpinnerService.show();

    this.cb.gettingContentBuilderFields(id).subscribe(data => {
      
      self.allTablesobj = data.data;
    //  console.log(data.data);
      this.ngxSpinnerService.hide();
    

    }, error => {
      self.allTablesobj="";
    //  console.log(error);
    });
  }



}
