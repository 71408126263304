<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/productcatalog">Product Catalogue</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{headers}} cross-sell Items</li>
        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="row">
            <div class="col-md-10 col-xl-12">

                <div class="card mt-1">

                    <div class="card-body">
                        
                        <div>
                            <label for="exampleForm2">Product SKU</label>
                            <input type="text" readonly formControlName="productsku" class="form-control form-control-md"
                               >
                        </div>

                        <div>
                            <label for="exampleForm2">Cross-Sell Items-1</label>
                            <select class="form-control" formControlName="crosssell_1">
                                <option> ------Select Item------</option>
                                <ng-container *ngFor="let product of productList">
                                    <option  value={{product.product_id}}>
                                      {{product.product_name}} - {{product.product_category}}
                                    </option>
                                </ng-container>

                            </select>
                        </div>

                        <div>
                            <label for="exampleForm2">Cross-Sell Items-2</label>
                            <select class="form-control" formControlName="crosssell_2">
                                <option> ------Select Item------</option>
                                <ng-container *ngFor="let product of productList">
                                    <option  value={{product.product_id}}>
                                        {{product.product_name}} - {{product.product_category}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>


                        <div>
                            <label for="exampleForm2">Cross-Sell Items-3</label>
                            <select class="form-control" formControlName="crosssell_3">
                                <option> ------Select Item------</option>
                                <ng-container *ngFor="let product of productList">
                                    <option  value={{product.product_id}}>
                                        {{product.product_name}} - {{product.product_category}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>

                        <div>
                            <label for="exampleForm2">Cross-Sell Items-4</label>
                            <select class="form-control" formControlName="crosssell_4">
                                <option> ------Select Item------</option>
                                <ng-container *ngFor="let product of productList">
                                    <option  value={{product.product_id}}>
                                        {{product.product_name}} - {{product.product_category}}
                                    </option>
                                </ng-container>
                                

                            </select>
                        </div>

                        <div>
                            <label for="exampleForm2">Cross-Sell Items-5</label>
                            <select class="form-control" formControlName="crosssell_5">
                                <option> ------Select Item------</option>
                                <ng-container *ngFor="let product of productList">
                                    <option  value={{product.product_id}}>
                                        {{product.product_name}} - {{product.product_category}}
                                    </option>
                                </ng-container>
                                

                            </select>
                        </div>
                    </div>


                    <div class="card-footer text-center mt-1">
                        <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                            <button type="button" class="custbtn" *ngIf="isEdit"
                                (click)="updateProduct()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>