import { Component, OnInit } from "@angular/core";
import { RegisterService } from "../services/users/register.service";
import { ToastrService } from "ngx-toastr";
import { MasterService } from "../services/master/master.service";
import { ViewLogsDialogService } from "../_utils/view-logs-dialog/view-logs-dialog.service";
import { SupportService } from "src/app/services/support/support.service";
import { ClientService } from "src/app/services/clients/client.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.css"],
})
export class LogsComponent implements OnInit {
  directSellerViewLog = [];
  directSellerloginLog = [];
  directSellerCrudLog = [];
  directSellerSupportCrudLog = [];
  tempadminCrud = [];
  tempadminView = [];
  adminView = [];
  adminCrud = [];
  loginLog = [];
  caseObj: any = [];
  table_name;
  company_id: any = '';
  customerID;
  sellerObj: any;
  getCompanyobj;
  bu_id = "";
  case_id = "";
  supportUser: any;
  support_id = "";
  sellerId:any = "";
  company_name;
  supportuser_name;
  seller_name;
  search: boolean = false;
  uid;

  vieword = "desc";
  viewColname = "lid";
  viewTotalCount: number;
  viewPage = 1;
  viewLimit = 50;
  viewSkip = 0;
  viewText: any = '';

  curdord = "desc";
  curdColname = "lid";
  curdTotalCount: number;
  curdPage = 1;
  curdLimit = 50;
  curdSkip = 0;
  crudText: any = '';

  loginord = "desc";
  loginColname = "lid";
  loginTotalCount: number;
  loginPage = 1;
  loginLimit = 50;
  loginSkip = 0;
  loginText: any = '';

  sLogPage = 1;
  sLogLimit = 50;
  sLogSkip = 0;
  supportord = "desc";
  supportColname = "lid";
  supportLogTotalCount: number;

  dsVieword = "desc";
  dsViewColname = "lid";
  dsViewTotalCount: number;
  dsViewPage = 1;
  dsViewLimit = 50;
  dsViewSkip = 0;
  dsViewText: any = '';
  searchText = '';
  dsCurdord = "desc";
  dsCurdColname = "lid";
  dsCurdTotalCount: number;
  dsCurdPage = 1;
  dsCurdLimit = 50;
  dsCurdSkip = 0;
  dsCurdText: any = '';

  dsLoginLogord = "desc";
  dsLoginLogColname = "lid";
  dsLoginLogTotalCount: number;
  dsLoginLogPage = 1;
  dsLoginLogLimit = 50;
  dsLoginLogSkip = 0;
  dsLoginLogText: any = '';

  isHeadquater: any;

    //Web history Variable
    logsLimit = 15;
    logsSkip = 1;
    logsNoOfRecords = 0;
    logSort = "created_dt DESC";
    logsData: any = [];
    userData: any;

    regLogsLimit=50;
    regLogsSkip=0;
    regLoginLogTotalCount = 0;
    regLoginLogPage = 1;
    regLoginLogColname = "lid";
    regLoginLogord = "desc";
    regLoginLogText: any = '';
    regLogsData:any[]=[]
    registerDirectSellers:any[]=[];
    regDsId:any='';

    selectedCompany:any
    dsUserId:any=''
    dsList:any=[];

    DsLogsData:any[]=[];
    DslogsNoOfRecords = 0;
    DslogsLimit = 15;
    DslogsSkip = 1;
    selected: any = { startDate: '', endDate: '' };
    fromChartDate: any;
    toChartDate: any;
    ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };
    searchType='';
  constructor(
    public registerService: RegisterService,
    private master: MasterService,
    private toastrService: ToastrService,
    private ViewLogsDialogService: ViewLogsDialogService,
    private supportService: SupportService,
    private clientService: ClientService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) {
    // this.getCompany();
  }

  ngOnInit() {
    var data = JSON.parse(sessionStorage.getItem("userData"));
    this.isHeadquater = JSON.parse(localStorage.getItem("customer_isheadquarter"));
    this.customerID = data.admin_customer_id;
    this.uid = data.uid;
    this.table_name = this.company_id == 0 ? 'admin_view_logs_tbl' : 'ds_view_logs_tbl';
    this.getDsByCompanyId() ;
    this.getRegisteredDsByCompanyId()
    if (this.isHeadquater == true && this.customerID != 0) {
      this.getHQBUCompany();
    } else {
      this.company_id = this.customerID;
      if (this.company_id == 0) {
        this.getAdminViewLogs();
        this.getAdminCurdLogs();
        this.getAdminLoginLogs();
        this.getAllViewLogs();
      } else {
        this.getDsViewLogs();
        this.getDsCurdLogs();
        this.getDsLoginLogs();
        this.getCompanySupportList(0);
      }
    }

    // if (this.customerID == 0) {
    //   this.companyId = this.customerID;
    //   this.getData();
    // }
    // else { this.getCompany(); }
    // this.company_id = data.admin_customer_id;
    // this.uid = data.uid;
    // this.table_name = this.company_id == 0 ? 'admin_view_logs_tbl' : 'ds_view_logs_tbl';
    // this.getCompany();
    // if (this.isHeadquater !== true) {
    //   if (this.company_id == 0) {
    //     this.getAdminViewLogs();
    //     this.getAdminCurdLogs();
    //     this.getAdminLoginLogs();
    //   } else {
    //     this.getDsViewLogs();
    //     this.getDsCurdLogs();
    //     this.getDsLoginLogs();
    //     this.getCompanySupportList(0);
    //   }
    //   // if (this.company_id != 0) {
    //   //   this.getCompanySupportList(0);
    //   // }
    // }
  }

  changeCompany(company) {
    this.company_id = company;
    if (this.company_id != 0) {
      this.getDsViewLogs();
      this.getDsCurdLogs();
      this.getDsLoginLogs();
    }
  }

  getAdminViewLogs() {
    this.viewSkip = (this.viewPage - 1) * this.viewLimit;
    this.spinner.show();
    this.registerService.getViewLogs(this.viewLimit, this.viewSkip, this.viewColname, this.vieword, this.viewText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.adminView = [] }
        else { this.adminView = data.data.rows }
        this.viewTotalCount = data.data.total;
        console.log('viewTotalCount',this.viewTotalCount)
      } else { this.adminView = []; this.viewTotalCount = 0 }
      this.refresh_logs();
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }


  adminViewSearch(val) {
    this.viewText = val.trim();
    console.log("adminViewSearch-",this.company_id,"and-",this.table_name);
    if (this.company_id == 0) {
      if (this.table_name == "admin_view_logs_tbl") {
        this.spinner.show();
        this.registerService.getViewLogs(this.viewLimit, this.viewSkip, this.viewColname, this.vieword, this.viewText).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.viewSkip = 0;
            this.viewPage = 1;
          }),
        ).subscribe((res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.adminView = [];
            } else { this.adminView = res.data.rows } this.viewTotalCount = res.data.total;
          } else {
            this.adminView = [];
            this.viewTotalCount = 0;
          }
          this.spinner.hide();
        });
      }
    }
  }

  allViewSearch(val,searchType) {
    this.dsLoginLogText = val.trim();
    if(searchType == 'text'){this.dsText = ''}else{this.searchText = ''}
    this.dsLoginLogSkip = 0 ;
    console.log("allViewSearch-",this.company_id,"and-",this.table_name);
    if (this.company_id == 0) {
      if (this.table_name == "all_view_logs_tbl") {
        this.spinner.show();
        let viewColname = "createdon";
        this.viewSkip = 0;
        this.registerService.getAllLogs(this.viewLimit, this.viewSkip, viewColname, this.vieword, '').pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.viewSkip = 0;
            this.viewPage = 1;
          }),
        ).subscribe((res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.allView = [];
            } else { this.allView = res.data.rows } this.allTotal = res.data.total;
          } else {
            this.adminView = [];
            this.allTotal = 0;
          }
          this.spinner.hide();
        });
      }
    }
  }

  allView = [] ;
  allTotal = 0;
  getAllViewLogs() {
    this.viewSkip = (this.viewPage - 1) * this.viewLimit;
    this.spinner.show();
    let viewColname = "createdon";
    this.registerService.getAllLogs(this.viewLimit, this.viewSkip, viewColname, this.vieword, this.viewText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.adminView = [] }
        else { this.allView = data.data.rows }
        this.allTotal = data.data.total;
        console.log('viewTotalCount',this.allTotal)
      } else { this.allView = []; this.allTotal = 0 }
      this.refresh_logs();
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  getAdminCurdLogs() {
    this.curdSkip = (this.curdPage - 1) * this.curdLimit;
    this.spinner.show();
    this.registerService.getCrudLogs(this.curdLimit, this.curdSkip, this.curdColname, this.curdord, this.crudText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.adminCrud = [] }
        else { this.adminCrud = data.data.rows }
        this.curdTotalCount = data.data.total;
      } else { this.adminCrud = []; this.curdTotalCount = 0 }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }


  adminCurdSearch(val) {
    this.crudText = val.trim();
    if (this.company_id == 0) {
      if (this.table_name == "admin_cud_logs_tbl") {
        this.spinner.show();
        this.registerService.getCrudLogs(this.curdLimit, this.curdSkip, this.curdColname, this.curdord, this.crudText).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.curdSkip = 0;
            this.curdPage = 1;
          }),
        ).subscribe((res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.adminCrud = [];
            } else { this.adminCrud = res.data.rows } this.curdTotalCount = res.data.total;
          } else {
            this.adminCrud = [];
            this.curdTotalCount = 0;
          }
          this.spinner.hide();
        });
      }
    }
  }

  getAdminLoginLogs() {
    this.loginSkip = (this.loginPage - 1) * this.loginLimit;
    this.spinner.show();
    this.registerService.getLoginLogs(this.loginLimit, this.loginSkip, this.loginColname, this.loginord, this.loginText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.adminCrud = [] }
        else { this.loginLog = data.data.rows }
        this.loginTotalCount = data.data.total;
      } else { this.loginLog = []; this.loginTotalCount = 0 }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }


  adminLoginLogSearch(val) {
    this.loginText = val.trim();
    if (this.company_id == 0) {
      if (this.table_name == "admin_login_logs_tbl") {
        this.spinner.show();
        this.registerService.getLoginLogs(this.loginLimit, this.loginSkip, this.loginColname, this.loginord, this.loginText).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.loginSkip = 0;
            this.loginPage = 1;
          }),
        ).subscribe((res) => {
          if (res.status) {
            if (res.data.rows == null) {
              this.loginLog = [];
            } else { this.loginLog = res.data.rows } this.loginTotalCount = res.data.total;
          } else {
            this.loginLog = [];
            this.loginTotalCount = 0;
          }
          this.spinner.hide();
        });
      }
    }
  }

  getDsViewLogs() {
    this.dsViewSkip = (this.dsViewPage - 1) * this.dsViewLimit;
    this.spinner.show();
    this.registerService.getDsViewLogs(this.company_id, this.dsViewLimit, this.dsViewSkip, this.dsViewColname, this.dsVieword, this.dsViewText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.directSellerViewLog = [] }
        else {
          this.directSellerViewLog = data.data.rows;
          this.refresh_logs()
        }
        this.dsViewTotalCount = data.data.total;
      } else { this.directSellerViewLog = []; this.dsViewTotalCount = 0 }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  dsViewLogSearch(val,searchType) {
    let dd;
    if (val && val !== '') {
      dd = this.directSellers.filter(elem => elem?.userid === parseInt(val, 10));
      this.dsViewText = dd[0]?.contactname.trim();
    //  this.dsText = dd[0]?.contactname.trim();
    }
    // if (val === '') {
    //   this.dsViewText = '';
    //   this.dsText = '';
    // }
   // this.dsViewText = val.trim();
    // if(searchType == 'text'){this.dsText = ''}else{this.searchText = ''}

    this.dsViewSkip = 0 ;
    console.log("this.table_name ==", this.table_name);
    // if (this.table_name == "ds_view_logs_tbl") {

      console.log("this.table_name 2....", this.table_name);
      this.spinner.show();
      this.registerService.getNewDsViewLogs(this.company_id, this.dsViewLimit, this.dsViewSkip, this.dsViewColname, this.dsVieword, this.searchText, this.dsText
        ).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.dsViewSkip = 0;
          this.dsViewPage = 1;
        }),
      ).subscribe((res) => {
        if (res.status) {
          if (res.data.rows == null) {
            this.directSellerViewLog = [];
          } else { this.directSellerViewLog = res.data.rows } this.dsViewTotalCount = res.data.total;
        } else {
          this.directSellerViewLog = [];
          this.dsViewTotalCount = 0;
        }
        this.spinner.hide();
      });
    // }
  }

  getDsCurdLogs() {
    this.dsCurdSkip = (this.dsCurdPage - 1) * this.dsCurdLimit;
    this.spinner.show();
    this.registerService.getDsCrudLogs(this.company_id, this.dsCurdLimit, this.dsCurdSkip, this.dsViewColname, this.dsCurdord, this.dsCurdText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.directSellerCrudLog = [] }
        else {
          this.directSellerCrudLog = data.data.rows;
          this.refresh_logs()
        }
        this.dsCurdTotalCount = data.data.total;
      } else { this.directSellerCrudLog = []; this.dsCurdTotalCount = 0 }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  dsCurdLogSearch(val,searchType) {
    this.dsCurdText = val.trim();
    if(searchType == 'text'){this.dsText = ''}else{this.searchText = ''}
    this.dsCurdSkip = 0 ;
    if (this.table_name == "ds_curd_logs_tbl") {
      this.spinner.show();
      this.registerService.getDsCrudLogs(this.company_id, this.dsCurdLimit, this.dsCurdSkip, this.dsViewColname, this.dsCurdord, this.dsCurdText).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.dsCurdSkip = 0;
          this.dsCurdPage = 1;
        }),
      ).subscribe((res) => {
        if (res.status) {
          if (res.data.rows == null) {
            this.directSellerCrudLog = [];
          } else { this.directSellerCrudLog = res.data.rows } this.dsCurdTotalCount = res.data.total;
        } else {
          this.directSellerCrudLog = [];
          this.dsCurdTotalCount = 0;
        }
        this.spinner.hide();
      });
    }
  }

  getDsLoginLogs() {
    this.dsLoginLogSkip = (this.dsLoginLogPage - 1) * this.dsLoginLogLimit;
    this.spinner.show();
    this.registerService.getDsBuLoginLogs(this.company_id, this.dsLoginLogLimit, this.dsLoginLogSkip, this.dsLoginLogColname, this.dsLoginLogord, this.dsLoginLogText).subscribe((data) => {
      if (data.status) {
        if (data.data.rows == null) { this.directSellerloginLog = [] }
        else {
          this.directSellerloginLog = data.data.rows;
          this.refresh_logs()
        }
        this.dsLoginLogTotalCount = data.data.total;
      } else { this.directSellerloginLog = []; this.dsLoginLogTotalCount = 0 }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  dsLoginLogSearch(val,searchType) {
    this.dsLoginLogText = val.trim();
    if(searchType == 'text'){this.dsText = ''}else{this.searchText = ''}
    this.dsLoginLogSkip = 0 ;
    if (this.table_name == "ds_login_logs_tbl") {
      this.spinner.show();
      this.registerService.getDsBuLoginLogs(this.company_id, this.dsLoginLogLimit, this.dsLoginLogSkip, this.dsLoginLogColname, this.dsLoginLogord, this.dsLoginLogText).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.dsLoginLogSkip = 0;
          this.dsLoginLogPage = 1;
        }),
      ).subscribe((res) => {
        if (res.status) {
          if (res.data.rows == null) {
            this.directSellerloginLog = [];
          } else { this.directSellerloginLog = res.data.rows } this.dsLoginLogTotalCount = res.data.total;
        } else {
          this.directSellerloginLog = [];
          this.dsLoginLogTotalCount = 0;
        }
        this.spinner.hide();
      });
    }
  }

  adminSorting(col) {

    if (this.table_name == 'admin_view_logs_tbl') {
      this.viewColname = col;
      if (this.vieword == "asc") {
        this.vieword = "desc";
      } else { this.vieword = "asc" }
      this.getAdminViewLogs();

    } else if (this.table_name == 'admin_cud_logs_tbl') {
      this.curdColname = col;
      if (this.curdord == "asc") {
        this.curdord = "desc";
      } else { this.curdord = "asc" }
      this.getAdminCurdLogs();

    } else if (this.table_name == 'admin_login_logs_tbl') {
      this.loginColname = col;
      if (this.loginord == "asc") {
        this.loginord = "desc";
      } else { this.loginord = "asc" }
      this.getAdminLoginLogs();

    } else if (this.table_name == 'support_logs_tbl') {
      this.supportColname = col;
      if (this.supportord == "asc") {
        this.supportord = "desc";
      } else { this.supportord = "asc" }
      this.getLogBycaseId();

    } else if (this.table_name == 'ds_view_logs_tbl') {
      this.dsViewColname = col;
      if (this.dsVieword == "asc") {
        this.dsVieword = "desc";
      } else { this.dsVieword = "asc" }
      this.getDsViewLogs();

    } else if (this.table_name == 'ds_curd_logs_tbl') {
      this.dsCurdColname = col;
      if (this.dsCurdord == "asc") {
        this.dsCurdord = "desc";
      } else { this.dsCurdord = "asc" }
      this.getDsCurdLogs();

    } else if (this.table_name == 'ds_login_logs_tbl') {
      this.dsLoginLogColname = col;
      if (this.dsLoginLogord == "asc") {
        this.dsLoginLogord = "desc";
      } else { this.dsLoginLogord = "asc" }
      this.getDsLoginLogs();
    }else if (this.table_name == 'all_view_logs_tbl') {
      this.dsLoginLogColname = col;
      if (this.dsLoginLogord == "asc") {
        this.dsLoginLogord = "desc";
      } else { this.dsLoginLogord = "asc" }
      this.getAllViewLogs();
    }else if(this.table_name == 'registration_logs'){
      this.regLoginLogColname = col;
      if (this.regLoginLogord == "asc") {
        this.regLoginLogord = "desc";
      } else { this.regLoginLogord = "asc" }
      this.getRegistrationLogs();
    }
  }

  refresh_logs() {
    let self = this;
    this.tempadminView = [];
    //= this.adminView;

    // this.adminView.forEach(function (context) {

    //   // console.log(context);
    //   let temp = {
    //     createdon: context.get_admin_view_logs_fun.createdon,
    //     data: JSON.stringify(context.get_admin_view_logs_fun)
    //   };
    //   self.tempadminView.push(temp);
    // })

    // this.adminCrud.forEach(function (context) {
    //   // console.log(context);
    //   let temp = {
    //     createdon: context.get_admin_cud_logs_fun.createdon,
    //     data: JSON.stringify(context.get_admin_cud_logs_fun)
    //   };
    //   self.tempadminCrud.push(temp);
    // })

    //this.tempadminCrud = this.adminCrud;
  }

  logsShows(viewObj) {
    //  console.log(viewObj);
    this.ViewLogsDialogService.confirm(viewObj, "View", "Are you sure you want to change the status?", "YES", "NO").then(
      (confirmed) => {
        //    console.log(confirmed);
      },
      (err) => {
        //    console.log(err);
      }
    );
  }


  changeTable(name) {
    this.table_name = name;
    this.viewText = "";
    this.crudText = "";
    this.loginText = "";
    this.dsViewText = "";
     this.dsText = "";
    this.dsCurdText = "";
    this.dsLoginLogText = "";
    this.sellerId='';
    this.support_id='';
    this.searchText='';
    this.regDsId=''
    this.selectedCompany='';
    this.dsUserId='';
    this.dsList=[];
    this.searchType=''
    this.fromChartDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.toChartDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.selected.startDate = moment();
    this.selected.endDate = moment();
    if(this.table_name == 'support_logs_tbl') {
      this.getCompany();
    }else if(this.table_name == 'ds_view_logs_tbl'){
      this.dsViewLogSearch('','tabChange')
    }else if(this.table_name == 'ds_curd_logs_tbl'){
      this.dsCurdLogSearch('','tabChange')
    }else if(this.table_name == 'ds_login_logs_tbl'){
      this.dsLoginLogSearch('','tabChange')
    }else if(this.table_name == 'ds_login_logs_tbl'){
      this.dsLoginLogSearch('','tabChange')
    }else if(this.table_name == 'all_view_logs_tbl'){
      this.allViewSearch('','tabChange')
    }else if(this.table_name=='web_logs_tbl'){
      this.logsNoOfRecords=0;
      this.logsData=[];
      this.getCompany();
    }else if(this.table_name=='registration_logs'){
      this.registrationLogsData('','tabChange');
    }else if(this.table_name=='website_ds_logs'){
      this.DslogsNoOfRecords=0
      this.DsLogsData=[];
      if(this.customerID==0){
        this.getCompany();
      }else{
        this.selectedCompany=this.customerID;
        this.getDsListByDateFilter(0)
      }
    }
  }

  exportFile() {
    let tablename = {
      tableName: this.table_name,
    };
    this.master.exportBulk(tablename).subscribe(
      (dataFile) => {
        if (dataFile.status) {
          let selBox = document.createElement("a");
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      },
      (err) => {
        this.toastrService.error(err.message);
      }
    );
  }

  getCompanySupportSellerList(value) {
    this.sellerObj = [];
    this.sellerId ='';
    this.caseObj = [];
    this.case_id ='';
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    if(typeof value != 'string' || value.length == 0){
      this.caseObj = [];
      return;
    }
    this.sLogPage = 1;
    this.search = false;
    //  console.log("company themes:" + JSON.parse(this.bu_id).id);
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;
    let self = this;
    this.spinner.show();
    this.supportService
      .getCompanySupportSellerList(companyID, JSON.parse(value).id)
      .subscribe(
        (data) => {
          self.sellerObj = data.data;
          this.spinner.hide();
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
        }
      );
  }

  getHQBUCompany() {
    let self = this;
    this.registerService.getHQBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
        //  console.log(self.getCompanyobj);
      },
      (error) => {
        //  console.log(error);
      }
    );
  }


  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
      },
      (error) => {
        //  console.log(error);
      }
    );
  }

  /** populate salesforce case id for seller  */

  getCaseId(value) {
    if(typeof value != 'string' || value.length == 0){
      this.caseObj = [];
      return;
    }
    this.search = false;
    this.sLogPage = 1;
    this.caseObj = [];
    this.case_id = '';
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    if(typeof value != 'string' || value.length == 0){
      this.caseObj = [];
      return;
    }
    this.spinner.show();
    this.seller_name = JSON.parse(value).seller_name;
    this.supportService.getCaseId(JSON.parse(value).id).subscribe(
      (data) => {
        this.caseObj = data.data;
        this.spinner.hide();
      },
      (error) => {
        //   console.log(error);
        this.spinner.hide();
      }
    );
  }

  getLogBycaseId() {
    if (this.company_id == 0) {
      if (
        this.bu_id == "" ||
        this.support_id == "" ||
        this.sellerId == "" ||
        this.case_id == ""
      ) {
        this.toastrService.error("select required values");
        return false;
      }
    } else {
      if (this.support_id == "" || this.sellerId == "" || this.case_id == "") {
        this.toastrService.error("select required values");
        return false;
      }
    }

    this.sLogSkip = (this.sLogPage - 1) * this.sLogLimit;
    this.spinner.show();
    let companyID =JSON.parse(this.bu_id).id == undefined? this.company_id: JSON.parse(this.bu_id).id;
    if (this.bu_id) {
      this.company_name = JSON.parse(this.bu_id).company_name;
    }

    this.supportuser_name = JSON.parse(this.support_id).supportuser_name;
    this.registerService
      .getDirectSellerSupportCrudLogs(companyID, this.case_id,this.sLogLimit, this.sLogSkip, this.supportColname, this.supportord)
      .subscribe(
        (data) => {
           console.log('directSellerCrudLog', data);
          if (data.status) {
            if (data.data.rows == null) { this.directSellerSupportCrudLog = [] }
            else { this.directSellerSupportCrudLog = data.data.rows }
            this.supportLogTotalCount = data.data.total;
            if (this.supportLogTotalCount == 0) {
              this.search = true;
            }
          } else { this.directSellerSupportCrudLog = []; this.supportLogTotalCount = 0 }
          this.spinner.hide();
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  exportSupportFile() {
    this.spinner.show();
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;
    this.supportService
      .getDirectSellerSupportCrudLogsCsv(this.case_id, companyID, this.supportColname, this.supportord)
      .subscribe(
        (dataFile) => {
          // console.log(">>>>>", dataFile);
          if (dataFile.status) {
            let selBox = document.createElement("a");
            var att = document.createAttribute("href");
            att.value = dataFile.data.filename;
            var att1 = document.createAttribute("download");
            selBox.setAttributeNode(att);
            selBox.setAttributeNode(att1);
            document.body.appendChild(selBox);
            selBox.click();
            this.spinner.hide();
          }
        },
        (err) => {
          this.toastrService.error(err.message);
          this.spinner.hide();
        }
      );
  }

  getCompanySupportList(value) {
    this.search = false;
    this.supportUser = [];
    this.support_id ='';
    this.sellerObj = [];
    this.sellerId ='';
    this.caseObj = [];
    this.case_id ='';
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    this.bu_id = value;
    this.company_name = value.company_name;
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;
    let self = this;
    // alert(value);
    this.spinner.show();
    this.supportService.supportUserByCompany(companyID).subscribe(
      (data) => {
        self.supportUser = data.data;
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
      }
    );
  }

  directSellers : any[] = [] ;
  dsText = '' ;
  getDsByCompanyId() {
    console.log("getDsByCompanyId -->",this.customerID);
    if(this.customerID > 0){
      this.spinner.show();

      this.clientService.getDsByCompanyId(this.customerID,'contactname','asc').subscribe(data => {

        this.directSellers = data.data;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
    }else {this.directSellers = []}
  }


  beforeChange(item){
// console.log("beforeChange-",item);
   if(item.nextId != undefined && item.nextId !=null && typeof (item.nextId) == 'string' ){
    this.changeTable(item.nextId);
   }
  }
//Code for WEB HISTORY Start
_getLogsDetails(page: any) {
  if (!this.selectedCompany || !this.dsUserId) {
    this.toastrService.error("select required values");
    return false;
  }
  let body = {
    limit: this.logsLimit,
    skip: (this.logsSkip - 1) * this.logsLimit,
    dsId: this.dsUserId,
    sort:this.logSort,
    customer_id:this.selectedCompany,
    search:'',
  };
  this.spinner.show();
  this.registerService.getAllWebLogs(body).subscribe(
    (data) => {
      if (data.status) {
        this.logsData = data.data.rows;
        this.logsNoOfRecords = data.data.total;
      } else {
        this.logsData = [];
        this.logsNoOfRecords = 0;
      }
      this.spinner.hide();
    },
    (error) => {
      this.logsData = [];
      this.logsNoOfRecords = 0;
      console.log(error);
    }
  );
}
_limitPage(event: any) {
  this.logsLimit = event.target.value;
  this._getLogsDetails(0);
}
_resetLogs(){
  this.logsSkip = 1;
  this.logsLimit = 15;
  this._getLogsDetails(0);
}
customerSortByInvoice(typ:any) {
  if (typ === 'created_dt DESC') {
    this.logSort = 'created_dt ASC';
  }else if (typ === 'created_dt ASC') {
    this.logSort = 'created_dt DESC';
  }
  this._getLogsDetails(0);
}
registrationLogsData(val,searchType) {
  this.regLoginLogText = val.trim();
  if(searchType == 'text'){this.dsText = ''}else{this.searchText = ''}
  this.regLogsSkip = 0 ;
  if (this.table_name == "registration_logs") {
    this.spinner.show();
    this.registerService.getRegistrationLogs(this.company_id, this.regLogsLimit, this.regLogsSkip, this.regLoginLogColname, this.regLoginLogord, this.regLoginLogText,this.regDsId).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.regLogsSkip = 0;
        this.regLoginLogPage = 1;
      }),
    ).subscribe((res) => {
      if (res.status) {
        if (res.data.rows == null) {
          this.regLogsData = [];
        } else { this.regLogsData = res.data.rows } this.regLoginLogTotalCount = res.data.total;
      } else {
        this.regLogsData = [];
        this.regLoginLogTotalCount = 0;
      }
      this.spinner.hide();
    });
  }
}
getRegistrationLogs() {
  this.regLogsSkip = (this.regLoginLogPage - 1) * this.regLogsLimit;
  this.spinner.show();
  this.registerService.getRegistrationLogs(this.company_id, this.regLogsLimit, this.regLogsSkip, this.regLoginLogColname, this.regLoginLogord, this.regLoginLogText,this.regDsId).subscribe((data) => {
    if (data.status) {
      if (data.data.rows == null) { this.regLogsData = [] }
      else {
        this.regLogsData = data.data.rows;
        this.refresh_logs()
      }
      this.regLoginLogTotalCount = data.data.total;
    } else { this.regLogsData = []; this.regLoginLogTotalCount = 0 }
    this.spinner.hide();
  },
    (error) => {
      this.spinner.hide();
      // console.log(error);
    }
  );
}
getRegisteredDsByCompanyId() {
  if(this.customerID > 0){
    this.spinner.show();

    this.clientService.getRegDsByCompanyId(this.customerID).subscribe(data => {

      this.registerDirectSellers = data.data.rows;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }else {this.registerDirectSellers = []}
}
regDsFilter(){
  this.regLogsLimit=50;
  this.regLoginLogPage = 1;
  this.getRegistrationLogs();
}
getDsListByCompanyId(event:any) {
  if(this.customerID==0){
    let data=event.target.value?JSON.parse(event.target.value):'';
    if(data.id > 0){
      this.selectedCompany=data.id
      this.spinner.show();
      this.clientService.getDsByCompanyId(this.selectedCompany,'contactname','asc').subscribe(data => {
        this.dsList = data.data;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }else {this.dsList = []}
  }else{
      this.spinner.show();
      this.clientService.getDsByCompanyId(this.selectedCompany,'contactname','asc').subscribe(data => {
        this.dsList = data.data;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }
}
  getWebsiteDsLogsDetails(page: any) {
  if (!this.selectedCompany) {
    this.toastrService.error("select required values");
    return false;
  }else if(this.searchType && !this.searchText){
    this.toastrService.error("Search field is required");
    return false;
  }
  let body = {
    limit: this.DslogsLimit,
    skip: (this.DslogsSkip - 1) * this.DslogsLimit,
    ds_id: this.dsUserId,
    fromDate : this.fromChartDate,
    toDate : this.toChartDate,
    key:this.searchText || '',
    search:this.searchText?this.searchType:''
  };
  this.spinner.show();
  this.registerService.getDsWebLogs(body,this.selectedCompany).subscribe(
    (data) => {
      if (data.status) {
        this.DsLogsData = data.data.rows;
        this.DslogsNoOfRecords = data.data.total;
      } else {
        this.DsLogsData = [];
        this.DslogsNoOfRecords = 0;
      }
      this.spinner.hide();
    },
    (error) => {
      this.DsLogsData = [];
      this.DslogsNoOfRecords = 0;
    }
  );
}
getDsListByDateFilter(event:any) {
  let body={
    "fromDate" : this.fromChartDate,
    "toDate" : this.toChartDate
  }
  if(this.customerID==0){
    let data=event.target.value?JSON.parse(event.target.value):'';
    if(data.id > 0){
      this.selectedCompany=data.id
      this.spinner.show();
      this.clientService.getDsByDate(body,this.selectedCompany,'contactname','asc').subscribe(data => {
        this.dsList = data.data;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }else {this.dsList = []
      this.selectedCompany='';
    }
  }else{
      this.spinner.show();
      this.clientService.getDsByDate(body,this.selectedCompany,'contactname','asc').subscribe(data => {
        this.dsList = data.data;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }
}
//============Date Filter==============
onDateFilter(event: any) {
    let startDate = this.datepipe.transform(this.selected.startDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.selected.endDate, 'yyyy-MM-dd');
    // don't call api if end date is not selected
    if ((endDate == 'null') || (endDate == null) || (endDate == '') || (endDate == undefined)) {
      return false;
    }
    this.fromChartDate=startDate;
    this.toChartDate=endDate;
    if(this.selectedCompany){
      let body={
        "fromDate" : this.fromChartDate,
        "toDate" : this.toChartDate
      }
      this.clientService.getDsByDate(body,this.selectedCompany,'contactname','asc').subscribe(data => {
        this.dsList = data.data;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }
}
onTextSerach() {
    this.DsLogsData=[];
    this.DslogsNoOfRecords = 0;
    this.DslogsSkip = 1;
    this.spinner.show();
    let body = {
      limit: this.DslogsLimit,
      skip: (this.DslogsSkip - 1) * this.DslogsLimit,
      ds_id: this.dsUserId,
      fromDate : this.fromChartDate,
      toDate : this.toChartDate,
      key:this.searchText || '',
      search:this.searchText?this.searchType:''
    };
    this.registerService.getDsWebLogs(body,this.selectedCompany).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
      }),
    ).subscribe((data) => {
      if (data.status) {
        this.DsLogsData = data.data.rows;
        this.DslogsNoOfRecords = data.data.total;
      } else {
        this.DsLogsData = [];
        this.DslogsNoOfRecords = 0;
      }
      this.spinner.hide();
    },(error) => {
      this.DsLogsData = [];
      this.DslogsNoOfRecords = 0;
    });
  // }
}
}
