import { Component, OnInit } from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cb-form-list',
  templateUrl: './cb-form-list.component.html',
  styleUrls: ['./cb-form-list.component.css']
})
export class CbFormListComponent extends BaseComponent implements OnInit {

  themeObj = [];
  journeyObj = [];
  getCompanyobj = [];
  companyId = 0;
  themeId = '';
  journeyId = 'all';
  formData: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount;
  image: any;
  ord = true;
  formChildData;
  currentUser;
  companyCheck;

  order: string = 'asc';
  colname = 'd.journey_name';
  constructor(private cb: CbService,
    public master: MasterService,
    private modalService: NgbModal,
    public clientService: ClientService) {
    super()
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id) this.companyId = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getCompanyThemes(this.companyId);
    else this.getCompany();
    this.companyCheck = this.currentUser.admin_customer_id;
  }

   setOrder(col) {
    this.colname = col;
    if (this.order == 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    } this.formList();
  }

  getCompany() {
    this.themeObj = [];
    this.journeyObj = [];
    this.formData = [];
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
      self.getCompanyobj = [];
    });
  }

  getCompanyThemes(id) {
    this.journeyObj = [];
    this.formData = [];
    //event.preventDefault();
    this.companyId = id;
    if(this.companyId == 0){this.themeId = '';this.journeyId = 'all';this.formData = [];this.totalCount = 0;this.cp = 1;return;}
    // console.log("company themes:"+ id);
    let self = this;
    this.ngxSpinnerService.show();
    let tempData = [] ;
    this.master.getCompanyTheme(id, 9999, 0, 'theme_name', 'asc', '').subscribe(response => {
      tempData = response.data.rows;
      const tempData2 = tempData.filter(item => item.isactive)
      this.themeObj = tempData2;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  previewForm(modalName, data) {
    data.company = this.companyId
    data.previewHTML = data.template_preview || '';

    this.formChildData = data;
    let self = this;
    self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
      //  console.log("reason", reason);
    });

  }

  getCompanyJourneys() {
    this.journeyObj = [];
    this.formData = [];
    this.skip = 0;
    this.cp=1;
    this.journeyId = 'all'

    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getCompanyThemesJourneys(this.companyId, this.themeId).subscribe(response => {
      if (response.status) {
        this.formList();
        this.journeyObj = response.data;
      } else {
        this.journeyObj = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      self.journeyObj = [];
      this.ngxSpinnerService.hide();
    });
  }

  changeJourney() {
    this.cp = 1;
    this.formList()
  }




  formList() {
    if (this.companyId && this.themeId && this.journeyId) {
      this.ngxSpinnerService.show();
      this.skip = (this.cp - 1) * this.limit;


      this.cb.getForms(this.companyId, this.themeId, this.limit, this.skip, this.colname, this.order, '', this.journeyId == 'all' ? '' : this.journeyId).subscribe(response => {


        if (response.data.rows) {
          this.formData = response.data.rows;
          this.totalCount = response.data.total;
        } else {
          this.formData = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      })
    }
  }

  editTemplate(id, tempId, journeyId) {
    this.router.navigate(['/corporate/cb-form-builder'], { queryParams: { companyID: this.companyId, id: id, themeId: this.themeId, journeyId: journeyId, templateId: tempId } });
  }
  deleteTemplate(id) {

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.cb.deleteDynamicFormBuilder(this.companyId, id).subscribe(data => {
            if (data.status) {
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
                this.formList()
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  openImage(image) {
    let self = this
    if (image && image.indexOf("master_images") >= 0) {
      image = image.substring(image.indexOf("master_images"), image.length)
      if (!this.isUrlValid(image)) {
        let awsUrl = self.master.getUrlfile(image);
        this.image = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        this.image = '../../../assets/custom/images/broken.png';
      }
    } else {
      this.image = image || '../../../assets/custom/images/broken.png';
    }
    this.viewImageService.confirm('View Screenshot', this.image, 'Close')
      .then((confirmed) => {
        // console.log(confirmed)
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));




  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
}
