<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Premium Campaign</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Audience
      </li>

    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">

      <h5 class="font-weight-bold mt-2 mb-3">Audience List</h5>

    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div *ngIf="disCompId === 0">

            <select name="company" class="form-control formstyle" (change)="getAudience($event.target.value)">
              <option value=0>

                Select Company Name

              </option>
              <ng-container *ngFor="let userObj of getCompanyobj">
                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                  {{userObj.customer_company_name}}
                </option>
              </ng-container>
            </select>

          </div>


        </div>
      </div>


        <!-- <div class="col-md-2 col-xl-4">
        </div>

        <div class="col-md-2 col-xl-4">
        </div> -->
        <div class="row">
        <div class="col-md-6 col-xl-8" *ngIf="disCompId!= 0">
          <input type="button" value="+ New Audience" class="custbtn mr-1 ml-0 mb-2"
            style="min-width: auto;max-width: 150px;" (click)="addNew()">
        </div>


        <div class="col-md-6 mt-2 mt-md-0 text-sm-right">

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text cyan lighten-2" id="basic-text1">
                <i aria-hidden="true" class="fa fa-search text-white"></i>
              </span>
            </div>

            
            <input class="form-control" type="text" placeholder="Search by audience" aria-label="Search" #searchText
              [disabled]="companyId == 0">
          </div>
        </div>

      </div>


      </div>
      <div class="col-12">
      <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0" style="min-width: 900px;">
          <tr>
            <th (click)="setOrder('aud_name')" style="cursor: pointer">Audience Name <i class=" fa fa-sort text-black"
                aria-hidden="true"></i>
            </th>
            <th (click)="setOrder('aud_name')" style="cursor: pointer">
              Audience Type <i class=" fa fa-sort text-black" aria-hidden="true"></i>
            </th>
            <th>Contacts</th>
            <th (click)="setOrder('createdon')" style="cursor: pointer">Created Date <i class=" fa fa-sort text-black"
                aria-hidden="true"></i></th>
            <th>Scheduling Info.</th>
            <th>Timezone</th>

            <th>Frequency</th>

            <th>End Date</th>
            <th>Actions</th>
            <th>Launch</th>
          </tr>
          <tr *ngFor="let obj of buAudienceObj">

            <td title="{{obj.aud_desc}}" [ngClass]="{'aud-desc-color ': obj.aud_desc}"><a
                (click)="goEditAudience(obj.audid)">
                <div style="max-width: 130px;">{{obj.aud_name}}</div>
              </a></td>
            <td>{{obj.aud_type}}</td>

            <td>
              <a (click)="goToAudienceList(obj.audid)" *ngIf="obj.process_status === 1 || obj.process_status ===2">
                {{obj.total_count}}</a>
              <div *ngIf="obj.process_status === 0">Pending</div>
              <div *ngIf="obj.process_status === 3">Error</div>
            </td>
            <td>{{obj.createdon |date}}</td>
            <td>

              <div>{{obj.schedule_start|date}}</div>
              <div>{{obj.schedule_start|date: 'shortTime'}}</div>
            </td>
            <td>{{obj.schedule_timezone}}</td>
            <td>{{obj.schedule_interval}}</td>
            <td>
              <div>{{obj.schedule_end|date}}</div>
              <div>{{obj.schedule_end|date: 'shortTime'}}</div>

            </td>
            <td>
              <div class="actBtn">


                <i class="fa fa-info-circle mr-1 linkForce" (click)="previewdata(queryPreviewModel,obj.audid)"
                  placement="bottom" ngbTooltip="Preview"></i>


                <i class="fa fa-clone mr-1 linkForce" (click)="onClone(obj.audid)" placement="bottom"
                  ngbTooltip="Create Clone"></i>




                <span class="fa fa-edit mr-1 linkForce" (click)="edit(obj.audid)" placement="bottom"
                  ngbTooltip="Edit Schedule"></span>



                <span class="fa fa-trash mr-1 cursor" (click)="deleteAudienceBuilder(obj.audid)" *ngIf="isDelete"
                  placement="bottom" ngbTooltip="Delete"></span>
              </div>
            </td>
            <td>
              <input type="button" value="Launch" class="custbtn" (click)="launch(obj)" [disabled]="checkStatus(obj)" />
            </td>
          </tr>

        </table>

        <div *ngIf="totalNumber == 0" style="text-align: center">
          <p>No Result Available</p>
        </div>
        <div class="mt-2 text-center difPagination" *ngIf="totalNumber > 10">
          <ngb-pagination [collectionSize]="totalNumber" [(page)]="page" [maxSize]="2" [rotate]="true"
            (pageChange)="getBUAudience(page=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
    </div>
  </div>


<ng-template #queryPreviewModel let-modal role="dialog" style="z-index:999999;" data-keyboard="false"
  data-backdrop="static">

  <div class="modal-body">
    <h6 class="font-weight-bold mb-4">Audience Name: {{audienceName}}</h6>
    <h6 class="font-weight-bold mt-4 mb-4">Audience Type: {{audienceTargetName}}</h6>


    <div class="card-body" *ngIf="queryResult">


      <div class="w-100 mb-3 gryBg" *ngFor="let item of queryResult; let i=index">
        <div class="row">

          <div class="col-12">


            <div class="row">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-1 text-right" *ngIf="i>0">
                    <span class="audImgIcon"><img src="assets/custom/images/audioBuildImg.png"></span>
                    <span class="audImgTxt">...</span>
                  </div>
                  <div class="col-md-2" *ngIf="i>0">

                    {{item.query_connector}}

                  </div>
                  <div class="col-md-4">

                    {{item.entity_name}}


                  </div>
                </div>
              </div>

              <div class="col-md-3 mb-3">

                {{item.field_name}}

              </div>



              <div class="col-md-3 mb-3">

                {{item.operator_name}}

              </div>
              <div class="col-3 toast-message" *ngIf="showProductValue">
                {{item.query_value}}
              </div>




            </div>


            <div class="text-right crossRed signAbso" *ngIf="item.iserror">
              <i class="fa fa-close mr-1 cursor audDeleteBtn" placement="bottom"></i>
            </div>
            <div class="text-right checkGreen signAbso" *ngIf="!item.iserror">
              <i class="fa fa-check mr-1 cursor audDeleteBtn" placement="bottom"></i>
            </div>





          </div>

        </div>
      </div>



    </div>
    <div class="card-body" *ngIf="queryResult.length==0">No Result Available</div>


    <div class="col-12 text-center">

      <button class="btn btn-margo-action" (click)="closeModel()" style="align-items: center;">Close</button>

    </div>
  </div>


</ng-template>