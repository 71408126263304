import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { environment } from '../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';



@Injectable({
  providedIn: 'root'
})
export class CbService {
  public theme_cb_id;
  public baseUrls;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrls = baseService.baseUrl1 + "api/cb/"
  }


  getContentBuildItems(data) {
    return this.http.post<any>(this.baseUrls + "getContentBuildItems", data)
      .pipe();
  }


  gettingContentBuilderFields(id = 0) {

    return this.http.get<any>(this.baseUrls + `gettingContentBuilderFields/` + id)
      .pipe();
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  addingContentBuilderFields(value: any) {
    var body = value;
    // alert(value);
    return this.http.post<any>(this.baseUrls + "insertContentBuilderField/", body, this.httpHeadersOptions())
      .pipe();

  }

  updateContentBuilderFields(value: any) {
    var body = value;
    // alert(value);
    return this.http.post<any>(this.baseUrls + "updateContentBuilderField/", body, this.httpHeadersOptions())
      .pipe();

  }




  addNewThemeData(value: any) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "insertContentBuilderTheme/", body, this.httpHeadersOptions())
      .pipe();

  }
  //add extra new fields
  addingNewThemeData(value: any) {
    //  alert('hi3');
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingContentBuilderTheme/", body, this.httpHeadersOptions())
      .pipe();
  }
  //add extra new fields


  gettingThemeFields(data: any) {
    var body = data;
    return this.http.post<any>(this.baseUrls + "gettingThemeFields/", body, this.httpHeadersOptions())
      .pipe();


  }

  getCompanyTemplate(id: any = 0, limit: any = 100, skip: any = 0, colname: any = 'name', order: any = 'asc', searchText: any = '') {
    // var body = { company: id, type: type };
    //console.log('name');
    // console.log(name);
    // if (name != null) {
    //   body['name'] = name;
    // }
    return this.http.get<any>(this.baseUrls + `getCompanyTemplate/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getAssociatedTemplateSection(company, id) {
    var body = { company: company, id: id };
    return this.http.post<any>(this.baseUrls + "getAssociatedTemplateSection", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getSectionFields(company, id) {
    return this.http.get<any>(this.baseUrls + `getTemplateSectionFieldAttribute/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getTemplateView(company, id) {
    return this.http.get<any>(this.baseUrls + `getTemplatePreview/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getAssociatedSectionField(company, id) {
    var body = { company: company, sectionid: id };
    return this.http.post<any>(this.baseUrls + "getAssociatedSectionFields", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getAssociatedFieldAttribute(company, id) {
    var body = { company: company, fieldid: id };
    return this.http.post<any>(this.baseUrls + "getAssociatedFieldAttribute", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getCompanySection(id, limit, skip, colname: any = '', order: any = '', searchText: any = '') {
    let URL;
    if (order != '') {
      URL = this.baseUrls + `getCompanySection/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`
    } else {
      URL = this.baseUrls + `getCompanySection/${id}/${limit}/${skip}`;
    }
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getFieldProperty(id, limit, skip, colname: any = '', order: any = '', searchText: any = '') {
    let URL;
    if (order != '') {
      URL = this.baseUrls + `getFieldAttribute/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`
    } else {
      URL = this.baseUrls + `getFieldAttribute/${id}/${limit}/${skip}`;
    }
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );



  }


  getCompanyJourney(id, limit:any=9999, skip:any=0, colname: any = '', order: any = '', searchText: any = '', journeyType: any = '') {
    let URL;
    if (order != '') {
      URL = this.baseUrls + `getCompanyJourney/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&filter=&journeyTypeId=${journeyType}`
    } else {
      URL = this.baseUrls + `getCompanyJourney/${id}/${limit}/${skip}`;
    }

    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getCBJourneyType(id) {

    return this.http.get<any>(this.baseUrls + "getCBJourneyType/" + id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getJourneyByType(company, id, name = null) {
    let apiUrl = "";

    if (name != null) {
      apiUrl = this.baseUrls + "getJourneyByType/" + company + "/" + id + "/" + name;
    } else {
      apiUrl = this.baseUrls + "getJourneyByType/" + company + "/" + id;
    }

    return this.http.get<any>(apiUrl, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getTotalJourney(company, id) {
    //let apiUrl = "";
    //apiUrl = this.baseUrls + "getTotalJourney/"+company+"/"+id;

    return this.http.get<any>(this.baseUrls + "getTotalJourney/" + company + "/" + id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addThemeTemplate(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingThemeJourneyTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  cloneThemeTemplate(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "cloneThemeJourneyTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  cloneFormBuilder(value) {
    var body = value;
    console.log("body", body);
    return this.http.post<any>(this.baseUrls + "cloneFormBuilder", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }
  addingCBJourney(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingCBJourney", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }
  addTSAssociation(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addTSAssociation", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  deleteThemeTemplate(company, id) {
    return this.http.delete<any>(this.baseUrls + `deleteCBThemeJourneyTemplate/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  deleteDynamicFormBuilder(company, id) {
    return this.http.delete<any>(this.baseUrls + `deleteCBThemeTemplateFieldValue/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getThemeTemplateById(companyID, id) {
    return this.http.get<any>(this.baseUrls + `getCBThemeTemplate/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getThemeTemplateJourneyById(companyID, id) {
    return this.http.get<any>(this.baseUrls + `getCBThemeTemplateFieldValue/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getDefaultFormData(companyID, themeId, templateId, journeyId) {
    return this.http.get<any>(this.baseUrls + `getFormDefaultValue/${companyID}/${themeId}/${templateId}/${journeyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getThemeJourneyTemplateOrders(obj) {
    var body = obj;
    return this.http.post<any>(this.baseUrls + `getThemeJourneyTemplateOrders`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  updateCBJourney(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateCBJourney", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  updateCBSection(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateThemeJourneyTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  updateThemeTemplate(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateThemeJourneyTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }
  addingTemplate(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addingSection(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingCBSection", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addingFields(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addSFAssociation", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addingDynamicForm(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingCBThemeFieldValue", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  updateDynamicForm(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateThemeFieldValue", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addingAttribute(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingCBAttribute", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addFAAssociation(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addFAAssociation", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  // getCompanyThemesJourneys(id: any = 0, tid: any = 0, limit: any = 0, skip: any = 0, colname: any = 'journeyid', order: any = 'asc', searchText: any = '', journeyId: any = '') {
  //   return this.http.get<any>(this.baseUrls + `getCBThemeTemplateAllFieldValue/${id}/${tid}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&journeyId=${journeyId}`, this.httpHeadersOptions())
  //     .pipe(
  //       map(data => {
  //         if (!data.status) {
  //           if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
  //             console.error(data.message);
  //             data.message = "Something went wrong.Please try again."
  //           }
  //         }
  //         return data;
  //       }),
  //     );
  // }

  getCompanyThemesJourneys(id, tid) {
    var body = {company:id, id:tid};
    return this.http.post<any>(this.baseUrls + `getCBThemeJourney`, body,this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }

  getCompanyJourneyTemplates(id: any = 0, tid: any = 0, limit: any = 10, skip: any = 0, colname: any = '', order: any = '', searchText: any = '', journeyId: any = '') {
    let sort = colname != '' ? `${colname}:${order}`: '';
    return this.http.get<any>(this.baseUrls + `getCBThemeJourneyTemplate/${id}/${tid}/${limit}/${skip}?sort=${sort}&search=${searchText}&journeyId=${journeyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  getTemplateById(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getTemplateById/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getSectionById(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getSectionById/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }



  getJourneyById(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getJourneyById/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getTemplateHTMLPreview(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getTemplateHTMLPreview/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }



  getJourneyByTheme(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getJourneyByTheme/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }



  getForms(id: any = 0, tid: any = 0, limit: any = 10, skip: any = 0, colname: any = 'journeyid', order: any = 'asc', searchText: any = '', journeyId: any = '') {

    return this.http.get<any>(this.baseUrls + `getCBThemeTemplateAllFieldValue/${id}/${tid}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&journeyId=${journeyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  UpdateTemplate(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateTemplate", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  UpdateSection(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateCBSection", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  getCompanyField(id, limit, skip, colname: any = '', order: any = '', searchText: any = '') {
    let URL;
    if (order != '') {
      URL = this.baseUrls + `getCompanyField/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`
    } else {
      URL = this.baseUrls + `getCompanyField/${id}/${limit}/${skip}`;
    }
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getCompanyFieldAttribute(id) {
    return this.http.get<any>(this.baseUrls + `getFieldAttribute/${id}/${9999}/${0}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  addingField(value) {
    var body = value;
    return this.http.post<any>(this.baseUrls + "addingField", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }




  getFieldById(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getFieldById/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getCBAttributeById(companyID, id) {

    return this.http.get<any>(this.baseUrls + `getCBAttributeById/${companyID}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }




  getTemplateSection(id) {

    return this.http.get<any>(this.baseUrls + `getTemplateSection/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }





  UpdateField(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateField", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  UpdateAttribute(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateCBAttribute", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }



  getCompanyThemes(customerid: any) {
    var body = { customerId: customerid };
    // alert(customerid=""+body);
    return this.http.post<any>(this.baseUrls + "getCompanyThemes/", body).pipe();


  }

  getSelectCompanyThemes(body) {
    return this.http.post<any>(this.baseUrls + "getSelectCompanyThemes/", body).pipe();
  }
  getCBCompanyThemes(customerid: any) {
    var body = { customerid: customerid };
    // alert(customerid=""+body);
    return this.http.post<any>(this.baseUrls + "getCBCompanyThemes/", body).pipe();


  }

  gettingFieldsAttribute(cid: any, theme_cb_id: any) {
    var body = { theme_cb_id: theme_cb_id, customerId: cid };
    return this.http.post<any>(this.baseUrls + "gettingFieldsAttribute/", body).pipe();
  }


  updateCBfield(data: any) {
    var body = data;
    return this.http.post<any>(this.baseUrls + "updateCBfield/", body).pipe();
  }
  addingFieldsContent(data: any, cid) {
    var body = { data: data, customerId: cid };
    return this.http.post<any>(this.baseUrls + "addingFieldsContent/", body).pipe();

  }

  deletefield(company, id) {

    return this.http.delete<any>(this.baseUrls + `deletefield/${company}/${id}`).pipe();

  }

  deleteTemplateField(company, id) {

    return this.http.delete<any>(this.baseUrls + `deleteTemplateField/${company}/${id}`).pipe();

  }

  deleteSection(company, id) {

    return this.http.delete<any>(this.baseUrls + `deleteSection/${company}/${id}`).pipe();

  }
  deleteProperty(company, id) {

    return this.http.delete<any>(this.baseUrls + `deleteAttribute/${company}/${id}`).pipe();

  }

  deleteJourney(company, id) {

    return this.http.delete<any>(this.baseUrls + `deleteJourney/${company}/${id}`).pipe();

  }

  deleteTemplate(company, id) {
    return this.http.delete<any>(this.baseUrls + `deleteTemplate/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  updateTemplateStatus(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateTemplateStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }



  updateSectionStatus(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateSectionStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }


  updateJourneyStatus(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateJourneyStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  updateAttributeStatus(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateAttributeStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }







  updateFieldStatus(value) {
    var body = value;
    return this.http.put<any>(this.baseUrls + "updateFieldStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  addCBThemeFields(data) {
    var body = data;
    // console.log(data);
    return this.http.post<any>(this.baseUrls + "addCBThemeFields/", body, this.httpHeadersOptions()).pipe();

  }

  uploadfile(file, userKey, cb) {

    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.cbBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        //console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }

  getUrlfile(awsKey) {
    return environment.awsUrl+'/'+awsKey;
    // const bucket = new S3({
    //   accessKeyId: environment.accessKeyId,
    //   secretAccessKey: environment.secretAccessKey,
    //   region: environment.region
    // });
    // var params = { Bucket: environment.awsBucket, Key: awsKey };
    // var url = bucket.getSignedUrl('getObject', params);
    // console.log('The URL is', url);

    // return url;
  }

  //  ######  Get Clone Data ###################  //

  getCloneDatabyJourneyId(cid, jid) {
    const URL = this.baseUrls + 'getThemeByJourneyId' + '/' + cid + '/' + jid;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));


  }

  getPopulateData(cid, fftaid) {
    const URL = this.baseUrls + 'getDatatemplatetheme' + '/' + cid + '/' + fftaid;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  productCloneFormBuilder(value) {
    var body = value;
    console.log("body", body);
    return this.http.post<any>(this.baseUrls + "updateProductImgaeClone", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
      );
  }

  getMJourney(cid) {
    return this.http.get<any>(this.baseUrls + "getMasterJourneyType/" + cid, this.httpHeadersOptions())
  }

}

