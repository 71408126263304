<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/subscription">Subscription List</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{headers}} Subscription</li>
      
    </ol>
  </nav>
</div>

<div class="container mt-1">
  <form class="form-horizontal mt-1" [formGroup]="AddSubscription">
  <div class="rowTheme">
      <div class="themContain">
          <div class="mt-1">
              <label for="exampleForm2">Select Company Name <span class="required">*</span></label>
              <select type="text" formControlName='company'  *ngIf="headers == 'Add'"   class="form-control formstyle">
                <option value="" selected > --select company name-- </option>
                  <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                      {{userObj.customer_company_name}}
                  </option>
              </select>

              <select type="text" formControlName='company'  *ngIf="headers == 'Edit'" disabled="controlEnabled" class="form-control formstyle">
                <option value="" selected > --select company name-- </option>
                  <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                      {{userObj.customer_company_name}}
                  </option>
              </select>
              <div *ngIf="submitted && f.company.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.company.errors.required">Company name is required</div>
            </div>
          </div>
          
          <div class="mt-1">
              <label for="exampleForm2">Title<span class="required">*</span></label>
              <input type="text" class="form-control" formControlName='subscriptionname' class="form-control form-control-md"
              [ngClass]="{ 'is-invalid': submitted && f.subscriptionname.errors }"  required maxlength="30" appSpace>
              <div *ngIf="submitted && f.subscriptionname.errors" class="invalid-feedback">
                <div *ngIf="f.subscriptionname.errors.required || f.subscriptionname.hasError('whitespace')">Title is required</div>
                <div *ngIf="f.subscriptionname.errors.pattern">Title is not a valid format</div>
                <div *ngIf="f.subscriptionname.errors?.maxlength">You can enter only 30 charachters</div>
       
              </div>
          </div>
          
          <div class="mt-1">
              <label for="exampleForm2">Description</label>
              <input type="text" class="form-control"  formControlName='description'
                  class="form-control form-control-md" min="1" maxlength="499">
          </div>
          <div class="mt-1">
            <label for="exampleForm2">Attachment </label>

            <br>


            <a *ngIf="imageType == 1" href="{{attachmentFile}}">
              <img src="assets/images/doc_image.jpg" style="height: 80px" />
            </a>
            <img class="img-fluid" style="height: 80px" *ngIf="imageType == 0"
              src="{{attachmentFile}}" (error)="setValue($event)">


                   <!-- <span>
                    <img  src="{{attachmentFile}}"  
                    alt="Not Found" style="width:50px;" 
                    (error)="setValue($event)">
                  </span>  -->
                   <br>
                   
            <input #imageInput type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                        <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg,.txt,.doc,.pdf)</div>
                 
            
        </div>
          
          <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewSubscription()">Submit</button>
          </div>
          <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
              <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateSubscription()">Update</button>
          </div>
      </div>
  </div>
</form>
</div>