<nav
  class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-dark navbar-shadow">
  <div class="navbar-wrapper">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs"
            [routerLink]=""></a></li>
        <li class="nav-item"><a class="navbar-brand" [routerLink]="['/corporate/home']"><img class="brand-logo"
              alt="{{projectName}} logo" src="../../../../assets/images/logo/logo.png">
            <h3 class="brand-text">{{projectName}} Admin</h3>
          </a></li>
        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse justify-content-end" id="navbar-mobile">
        <ul class="nav navbar-nav">
          <li class="nav-item"><a class="nav-link mr-2 nav-link-label" [routerLink]="['/corporate/home']"><i
                class="ficon feather ft-arrow-left"></i></a></li>
          <li class="dropdown nav-item"><a class="nav-link mr-2 nav-link-label" [routerLink]=""
              data-toggle="dropdown"><i class="ficon feather ft-settings"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
