<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">User Management</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/dsuser">Direct Seller</a></li>
      <li class="breadcrumb-item active" aria-current="page">Direct Seller Details</li>
    </ol>
  </nav>
</div>
<div class="container">
  <div class="row">
    <div class="col">
        <h4 class="font-weight-bold ml-2 mb-1" *ngIf="dsInformation"> Name: <span> {{dsInformation.contactname}}</span></h4>
        <div class="card box-shadow-1">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <label>Email:</label>
                </div>
                <div class="col-12 col-sm-8 mb-1 mb-sm-0">
                  <p *ngIf="dsInformation" class="card-text">{{dsInformation.contact_email}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4 ">
                  <label>Address:</label>
                </div>
                <div class="col-12 col-sm-8 mb-1 mb-sm-0">
                  <p *ngIf="dsInformation" class="card-text"> {{dsInformation.user_address1	}}</p>
                  <p *ngIf="dsInformation" class="card-text"> {{dsInformation.user_city}}</p>
                  <p *ngIf="dsInformation" class="card-text"> {{dsInformation.user_state}}</p>
                  <p *ngIf="dsInformation" class="card-text"> {{dsInformation.user_country}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-4 ">
                  <label>Contact Number:</label>
                </div>
                <div class="col-12 col-sm-8 mb-1 mb-sm-0">
                  <p class="card-text" *ngIf="dsInformation">{{dsInformation.contact_phone}}</p>
                </div>
              </div>

            </div>
        </div>
    </div>
  </div>
</div>
<div class="card card-height">
  <div class="card-body">
    <div class="row">

      <div class="col-12 col-xl-12">
        <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder">
          <ngb-tabset style="width: 100%;">

            <ngb-tab>
              <ng-template ngbTabTitle>
                <p (click)="changeTable('admin_view_logs_tbl')">{{"View Logs"}}</p>
              </ng-template>
              <ng-template ngbTabContent>
                <div class="input-group">
                  <!-- <input class="form-control" type="text" placeholder="Search" aria-label="Search"
                    [(ngModel)]="viewText" (keypress)="onKeyPress($event)"> -->
                  {{adminView}}
                </div>
                <table class="table table-borderless table-responsive" *ngIf="directSellerViewLog?.length != 0">
                  <thead>
                    <tr>
                      <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i class=" fa fa-sort text-black"
                        aria-hidden="true"></i></th>
                      <th>Log Name</th>
                      <th>Log Description</th>
                      <th>User</th>
                      <th>IP Address</th>
                      <th>Date Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of directSellerViewLog">
                      <td>{{item.lid}}</td>
                      <td>{{item.log_name}}</td>
                      <td>{{item.log_description}}</td>
                      <td>{{item.createdname}}</td>
                      <td>{{item.ipaddress}}</td>
                      <td>{{item.createdon | date:'medium'}}</td>

                      <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-2 text-center difPagination" *ngIf="totaldsviewlog > 10">
                  <ngb-pagination [collectionSize]="totaldsviewlog" [(page)]="viewPage" [maxSize]="2" [rotate]="true"
                    (pageChange)="dsviewLogs(viewPage=$event)">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                  </ngb-pagination>
                </div>
                <p class="text-center mt-1" *ngIf="totaldsviewlog == 0">No Data Found</p>
              </ng-template>
              <ng-template ngbTabContent>
                <p>No Data Found</p>
              </ng-template>
            </ngb-tab>


            <ngb-tab>
              <ng-template ngbTabTitle>
                <p (click)="changeTable('admin_cud_logs_tbl')">{{"CRUD Logs"}}</p>
              </ng-template>
              <ng-template ngbTabContent>
                <div class="input-group">
                  <!-- <input class="form-control" type="text" placeholder="Search" aria-label="Search"
                    [(ngModel)]="crudText"> -->
                </div>
                <table class="table table-borderless table-responsive mt-2" *ngIf="adminCrud?.length != 0">
                  <thead>
                    <tr>
                      <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i class=" fa fa-sort text-black"
                        aria-hidden="true"></i></th>
                      <th>Log Name</th>
                      <th>Log Description</th>
                      <th>User</th>
                      <th>IP Address</th>
                      <th>Date Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of adminCrud">
                      <!-- <td>{{item.get_master_menu.menuid}}</td> -->
                      <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
                      <td>{{item.lid}}</td>
                      <td>{{item.log_name}}</td>
                      <td>{{item.log_description}}</td>
                      <td>{{item.createdname}}</td>
                      <td>{{item.ipaddress}}</td>
                      <td>{{item.createdon | date:'medium'}}</td>
                      <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                    </tr>
                  </tbody>
                </table>
            
                <div class="mt-2 text-center difPagination" *ngIf="totaldscrudlog > 10">
                  <ngb-pagination [collectionSize]="totaldscrudlog" [(page)]="curdPage" [maxSize]="2" [rotate]="true"
                    (pageChange)="dscrudLogs(curdPage=$event)">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                  </ngb-pagination>
                </div>
                <p class="text-center mt-1" *ngIf="totaldscrudlog == 0">No Data Found</p>
              </ng-template>
              <ng-template ngbTabContent>
                <p>No Data Found</p>
              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle>
                <p (click)="changeTable('admin_login_logs_tbl')">{{"Login Logs"}}</p>
              </ng-template>
              <ng-template ngbTabContent>
                <div class="input-group">
                  <!-- <input class="form-control" type="text" placeholder="Search" aria-label="Search"
                    [(ngModel)]="crudText"> -->
                </div>
                <table class="table table-borderless table-responsive mt-2" *ngIf="loginLog?.length != 0">
                  <thead>
                    <tr>
                      <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i class=" fa fa-sort text-black"
                        aria-hidden="true"></i></th>
                      <th>Log Name</th>
                      <th>Log Description</th>
                      <th>User</th>
                      <th>IP Address</th>
                      <th>Date Time</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of loginLog">
                      <!-- <td>{{item.get_master_menu.menuid}}</td> -->
                      <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
                      <td>{{item.lid}}</td>
                      <td>{{item.log_name}}</td>
                      <td>{{item.log_description}}</td>
                      <td>{{item.username}}</td>
                      <td>{{item.ipaddress}}</td>
                      <td>{{item.createdon | date:'medium'}}</td>

                      <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-2 text-center difPagination" *ngIf="total > 10">
                  <ngb-pagination [collectionSize]="total" [(page)]="loginPage" [maxSize]="2" [rotate]="true"
                    (pageChange)="loginLogsDs(loginPage=$event)">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                  </ngb-pagination>
                </div>
                <p class="text-center mt-1" *ngIf="total == 0">No Data Found</p>
              </ng-template>
              <ng-template ngbTabContent>
                <p>No Data Found</p>
              </ng-template>
            </ngb-tab>

          </ngb-tabset>
        </ul>
      </div>
    </div>
  </div>
</div>
