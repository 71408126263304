<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li  class="breadcrumb-item"><a routerLink="/corporate/events">Manage Events</a></li>
        <li  class="breadcrumb-item active" aria-current="page">Event Invitees</li>
      </ol>
    </nav>
  </div>
  
  <div class="container">
    <div class="row">
       <div class="col-md-10">
            <div class="row col-md-12 d-flex justify-content-between">
                <div class="col-md-8 col-sm-12 mb-md-0 mb-1" *ngIf="eventObj">
                    <p>Event : {{ eventObj[0]?.event_name }} </p>
                  </div>
                  <div class="col-md-4 col-sm-12 mb-md-0 mb-1" *ngIf="inviteeCount">
                   <p> Total Invitees : {{ inviteeCount }}</p>
                  </div>
        </div>
        
      </div>


      <div class="col-12">
        <div class="tblPrt">
          <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">
            <tr>
                <th>Directseller</th>
                <th>RSVP "Yes"</th>
                <th>RSVP "No"</th>
                <th>RSVP "May be"</th>
            </tr>
  
            <tr *ngFor="let obj of eventObj">
                <td>{{obj?.user_fname}} {{obj?.user_lname}}</td>
                <td>{{obj?.yes_count}}</td>
                <td>{{obj?.no_count}}</td>
                <td>{{obj?.maybe_count}}</td>
            </tr>
  
          </table>
        </div>
      </div>
      <div class="col-12 text-center mb-0">
        <button type="button" (click)="onBack()" class="btn btn-round btn-margo-action">Back</button>
      </div>
    </div>
  </div>
