<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/themecategorymaster">Theme Category</a></li>          
      <li class="breadcrumb-item active" aria-current="page">Add Theme Category</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <div class="rowTheme">
        <div class="themContain">
        <div>
            <label for="exampleForm2">Theme Category Name</label>
            <input type="text"  autocomplete="off" class="form-control" [(ngModel)]="themecategoryname" class="form-control form-control-lg"
                required>
                <!-- <div *ngIf="submitted && f.themename?.errors" class="invalid-feedback">
                    <div *ngIf="f.themename?.errors.required">Theme Name is required</div> -->
                <!-- </div> -->
        </div>
        <div class="mt-1">
            <label for="exampleForm2">Theme Category Description</label>
            <input type="text" autocomplete="off" class="form-control" [(ngModel)]="themedescription"
                class="form-control form-control-lg" required>
        </div>
        
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewThemeCategory()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateThemeCategory()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</div>