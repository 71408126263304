import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pii-data',
  templateUrl: './pii-data.component.html',
  styleUrls: ['./pii-data.component.css']
})
export class PiiDataComponent extends BaseComponent implements OnInit {

  constructor() { super(); }

  ngOnInit() {
  }

}
