<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Asset</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

    </div>

  
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn" routerLink="/corporate/newasset" >
    <button class="csvBtn ml-1" *ngIf="isImport" (click)="importFile()">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" *ngIf="isExport" (click)="exportFile()">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
  </div>
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" style="min-width:900px" cellpadding="0" cellspacing="0">

      <tr>
        <!-- <th>Theme Id</th> -->
        <th (click)="chgord('subc1')" class="curPoint" width="10%">Asset Name <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        <th (click)="chgord('subc1')" class="curPoint" >Asset Description <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        
        
        
        
        <th width="10%">Created by</th>
        
        <!--<th width="200">ModifiedBy</th>-->
        <th width="20%">Created Date</th>
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let obj of assetObj | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:5,currentPage:cp }">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->
        <td>{{obj.assests_name}}</td>
        <td>{{obj.assests_description}}</td>
        
        <!-- <td>{{obj.get_master_theme.isactive }}</td> -->
        <td>{{obj.createdname}}</td>
        <td>{{obj.createdon | date:'medium' }}</td>
        <!--<td>{{obj.get_master_theme.modifiedname }}</td>
        <td>{{obj.get_master_theme.modifiedon |date}}</td>-->

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="curPoint fa fa-edit mr-1" *ngIf="isEdit" (click)="editAsset(obj.assestsid)"></i>
            <span class="curPoint fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteAsset(obj.assestsid)"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (change)="updateAssetStatus($event,obj.assestsid)">
            </mat-slide-toggle>
          </div>
        </td>         
        </tr>
        

    </table>
    <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
  </div> 
  </div> 
 
    