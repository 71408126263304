import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notauthorised',
  templateUrl: './notauthorised.component.html',
  styles: []
})
export class NotauthorisedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
