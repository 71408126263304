import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DsuserService} from 'src/app/services/users/dsuser.service'
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { debounceTime } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-addassociation',
  templateUrl: './addassociation.component.html',
  styleUrls: ['./addassociation.component.css']
})
export class AddassociationComponent extends BaseComponent implements OnInit {
  
  public userObj: any = {};
  header = "Add New"
  dsRegisterForm: FormGroup;
  isactive = "true"  
  CustomerData;
  TeamData=[];
  teamHead="";
  teamMember="";
  TeamMemberData=[];
  prevTeamMem:any="";
  prevTeamLead:any="";
  cid="";
  customerId;
  dsid:any;
  editMode:any=false;
  compId: any;
  constructor(
    private duserregisterService: DsuserService,
    private formBuilder: FormBuilder,
    private clientService:ClientService,
    public ngxSpinnerService : NgxSpinnerService
  ) { super(); }

 
  ngOnInit() {

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerId = customerInfo.admin_customer_id;
    this.compId = customerInfo.admin_customer_id;

    this.activatedRoute.queryParams.pipe(debounceTime(500))
      .subscribe(params => {
        if (params != undefined && Object.keys(params).length>0) {
          this.header = "Edit";
          this.editMode = true;
          this.cid = params.cid;
          this.dsid = Number(params.id);

          
         // this.ngxSpinnerService.show();

          if(this.cid) 
          this.getTeamList(Number(this.cid));
          this.prevTeamLead=Number(params.leadId);
          if(params.leadId) this.getMemberList(Number(params.leadId));
          this.teamHead = params.leadId;
          this.teamMember = this.dsid;
          this.prevTeamMem = this.dsid;
        }else{
          this.cid = this.customerId;
          this.getTeamList(this.customerId);
        }
      });

    //get item details using id
    // if (this.cid) {
    //   this.header = "Edit"
    //   this.getAssociatedTeamById(this.cid);

    // }
    //this.ngxSpinnerService.show();
    if (this.customerId == 0) {
        this.getDsCustomerdropdown();
    }
      
    // if (this.customerId != 0 && !this.editMode) {


    //   console.log("once again");
    //   this.cid = this.customerId;
    //   this.getTeamList(this.customerId);
    // }


  }

  getDsCustomerdropdown() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.CustomerData = data.data;
      if(!this.editMode) this.ngxSpinnerService.hide();
    }), error => {
     // console.log(error);                                                             
      this.ngxSpinnerService.hide();
    };
  }

  associateDsUser() {
    var companyID;
    if(this.cid =="" || this.teamHead =="" ||
    this.teamMember == "") {
      if (this.customerId == 0)  this.toastrService.error("Please Select Company Name,Team Leader,Team Member");
      if (this.customerId >0)  this.toastrService.error("Please Select Team Leader and Team Member");
   
      return false;
    }
    this.ngxSpinnerService.show();
    if(this.cid) companyID =this.cid; 
    else companyID = this.customerId; 

    var obj ={     
      teamHead:this.teamHead,
      teamMember:this.teamMember,
      customer_id:companyID     
          }
        //  console.log(obj);

          this.duserregisterService.associateDsUser(obj).subscribe(data => {
            this.duserregisterService = data.data;
            
            if(data.status) {
              this.ngxSpinnerService.hide();
              this.toastrService.success( !this.editMode ? "Added Successfully" : "Updated Successfully");
              this.router.navigateByUrl("/corporate/teamassociation")
            }
          }, error => {
          //  console.log(error);
            this.ngxSpinnerService.hide();
          });

    
}
updateAssociateDsUser() {
  var companyID;
  if(this.cid =="" || this.teamHead =="" ||
  this.teamMember == "") {
    if (this.customerId == 0) this.toastrService.error("Please Select Company Name,Team Leader,Team Member");
    
    if (this.customerId >0) this.toastrService.error("Please Select Team Leader and Team Member");
   
    return false;
  }
  this.ngxSpinnerService.show();
  if(this.cid) companyID =this.cid; 
  else companyID = this.customerId; 

  var obj ={     
    teamHead:this.teamHead,
    teamMember:this.teamMember,
    customer_id:companyID,
    prevTeamMember:this.prevTeamMem,
    prevTeamLead:this.prevTeamLead     
        }
     //   console.log(obj);

        this.duserregisterService.updateDSUserAssoc(obj).subscribe(data => {
          this.duserregisterService = data.data;
          
          if(data.status) {
            this.ngxSpinnerService.hide();
            this.toastrService.success("Updated Successfully");
            this.router.navigateByUrl("/corporate/teamassociation")
          }
        }, error => {
         // console.log(error);
          this.ngxSpinnerService.hide();
        });

  
}

getTeamList(id){

  console.log("------getTeamList-------", id);
 // console.log("customer id ......"+id);
 //if(!this.editMode) this.ngxSpinnerService.show();
  //this.customerId = customerId;
  this.TeamData = [];
  this.TeamMemberData = [];
  if (id === '') {
    return;
  }
  let self = this;
  self.TeamData = [];
  self.TeamMemberData = [];
  if (id === '') {
    return;
  }
  // if(!this.editMode) self.teamHead=id;
  this.duserregisterService.getTeamList(id).subscribe(data => {
    if(data.data=="") 
    {this.toastrService.success("No Team Member in this company");   
    self.TeamData = data.data;
    }
    self.TeamData = data.data;
     this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    this.ngxSpinnerService.hide();
  });

}

getMemberList(id){
 // console.log("customer id "+id);
  this.ngxSpinnerService.show();
  let self = this;
  this.TeamMemberData = [];
  if (id === '') {
    this.ngxSpinnerService.hide();
    return;
  }
  var companyID
  if(this.customerId==0) companyID =this.cid; 
  else companyID = this.customerId; 


  this.duserregisterService.getTeamMemberList(companyID,id,this.editMode).subscribe(data => {
    if(data.data=="") this.toastrService.success("No Team Member for association ");   
    self.TeamMemberData = data.data;
    //self.teamMember=id;
    
    this.ngxSpinnerService.hide();
  }, error => {
  //  console.log(error);
    this.ngxSpinnerService.hide();
  });

}





}
