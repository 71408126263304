<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Customer Content Builder </li>
    </ol>
  </nav>
</div>

<div class="container mt-2">
  <div class="card">
    <div class="card-body" >
     <div *ngIf="BUuser==0">
      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">
            <label class="col-sm-4 col-form-label">Select Company:</label>
            <div class="col-sm-8">
              <select name="company" class="form-control formstyle" (change)="getSelectCompanyThemes($event.target.value)">
                <option value="">Select Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                  {{userObj.customer_company_name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Theme:</label>
            <div class="col-sm-8">
              <select name="themeid" class="form-control formstyle" (change)='gettingThemeFields(selectedcompany,$event.target.value)'>
                <option value="">Select Theme</option>
                <option *ngFor="let obj of themeObj" value={{obj.theme_id}}>
                  {{obj?.theme_name}}
                </option>
              </select>  
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row form-group">
            <label class="col-sm-4 col-form-label">Content Fields:</label>
            <div class="col-sm-12">
              <div  #myElement class="piInnerList scroller" *ngIf="!show && getCompanyfields">
             <form [formGroup]="dynamicForm"  (ngSubmit)="addthemecCntentData(selectedcompany)" >
                <div *ngFor="let item of t.controls; let i = index"  >                
                  <div class='row' [formGroup]="item"> 
                    <div style="float:left" width="50%">                      
                      <input  class="form-control" formControlName="cbtaid"  type='hidden' >  
                      <div class="media-body" *ngIf="getCompanyfields[i].cb_field_type==='file'">
                        <div class="proImgMain mb-1" for="fileInput">
                          <div class="fileUpl">
                            <i class="fa fa-camera"></i>                            
                            <input class="form-control flUpBtn" #imageInput   accept=".png, .jpg, .jpeg"  (change)="processFile(getCompanyfields[i].cbtaid,imageInput)" formControlName="cb_field_name"  type="{{getCompanyfields[i].cb_field_type}}">
                          </div>
                         <div  *ngIf="getCompanyfields[i].cb_field_type==='file' && getCompanyfields[i].cb_field_value!=''">
                            <img src="{{getCompanyfields[i].cb_field_value}}" alt="logo" (error)="setValue($event)">
                            <small class="redNote d-block">Allowed file Types(.png, .jpg, .jpeg)</small>
                            <button  class="btn btn-round btn-margo-action mt-1"  (click)="delete()">Delete</button>
                          </div>
                          <div  *ngIf="getCompanyfields[i].cb_field_type==='file' && getCompanyfields[i].cb_field_value==''">
                            <img src="{{ image_awskey }}" alt="image" (error)="setValue($event)">
                          </div>                           
                        </div>                        
                      </div><br>
                      <input *ngIf="getCompanyfields[i].cb_field_type==='undefined'"  class="form-control" formControlName="cb_field_name"  type="{{getCompanyfields[i].cb_field_type}}" value="{{getCompanyfields[i].cb_field_value}}">
                      <input *ngIf="getCompanyfields[i].cb_field_type==='text'"  class="form-control" formControlName="cb_field_name"  type="{{getCompanyfields[i].cb_field_type}}" value="{{getCompanyfields[i].cb_field_value}}">
                      <input *ngIf="!getCompanyfields[i].cb_field_type"  class="form-control" formControlName="cb_field_name"  type="text" value="{{getCompanyfields[i].cb_field_value}}" >
                    </div>  
                    <div></div>
                   </div>                  
                </div>
                <button >Add Content</button>
              </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>


      
      
    </div>

      <div class="text-center card-footer">
        <button class="custbtn" >
          Save & Preview
        </button>
      </div>

  </div>


</div>
