import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { DsuserService } from 'src/app/services/users/dsuser.service';

import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { LicenseService } from '../../../services/license/license.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { DatePipe } from '@angular/common';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-directseller',
  templateUrl: './directseller.component.html',
  styleUrls: ['./directseller.component.css']
})
export class DirectsellerComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  public userObj: any = [];
  getCompanyobj;
  customerID;
  companyId: any = '';
  status: any = 3;
  total;
  checkedList = [];
  allBundleList: any = [];
  usersForBundleAssignment: any = [];
  bundleSelectForm: FormGroup;
  assignBundleStatus: boolean = false;
  resendStatus: boolean = false;
  selectedBundleName = ""
  selectedBundleDate = "";
  assignDate: any = '';
  customerInfo: any;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  dsord = "desc";
 // dsColname = "full_name";
  dsColname = "createdon";
  dsPage = 1;
  dsLimit = 10;
  dsSkip = 0;
  totalNumber: number = 0;
  selectedCompany:any;
  muUpdateDs:any;
  @ViewChild('emailServiceModal', { static: true }) emailServiceModal: ElementRef;
  constructor(
    public dsregister: DsuserService,
    private clientService: ClientService,
    private authenticationService: AuthserviceService,
    private LS: LicenseService, private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private modalService:NgbModal
  ) {
    super();
  }


  ngOnInit() {

    this.ngxSpinnerService.show();

    this.customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = this.customerInfo.admin_customer_id;

    if (this.customerID != 0 && this.customerID != 2) {
      this.companyId = this.customerID;
      this.getCompanySeller('');
      this.getCompany()
    }
    else { this.getCompany() }

    this.UserLogData()
    this.bundleSelectForm = this.formBuilder.group({
      bundleid: ['', Validators.required]
    });

  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    let status = this.status == 1 ? 'true' : this.status == 2 ? 'false' : ''
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.dsSkip = 0;
          this.dsPage = 1;
          this.ngxSpinnerService.show();
          // console.log("in ngAfterViewInit02 getDsUser");
        }),
        switchMap(data => this.dsregister.getDsUser(this.companyId, this.dsLimit, this.dsSkip, this.dsColname, this.dsord, this.searchText.nativeElement.value, status, this.selectedBundleName, this.assignDate))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.userObj = [] }
          else {
            this.userObj = res.data.rows;
            this.total = res.data.total;
            // this.getAllBuldleList();
          }
          this.total = res.data.total;
        } else {
          this.userObj = [];
          //this.allBundleList = [];
          this.total = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  setOrder(col: string) {
    this.dsColname = col
    if (this.dsord == 'asc') {
      this.dsord = 'desc'
    } else {
      this.dsord = 'asc'
    }
    this.getCompanySeller('');
  }

  getCompanySeller(flag) {
    if(this.companyId == ''){
      this.userObj = [];
      this.dsPage = 1;
      this.total = 0;
      this.dsSkip = 0 ;
      return;
    }
    this.ngxSpinnerService.show();
    this.checkedList = [];

    if(this.assignDate != '') {
      this.assignDate = this.datePipe.transform(this.assignDate.toString(), 'yyyy-MM-dd');
    } else this.assignDate = "";

    let searchTerm = this.searchText.nativeElement.value.trim();
    this.dsSkip = (this.dsPage - 1) * this.dsLimit;
    if(this.customerID == 0){
      this.selectedCompany = this.getCompanyobj?.find((data:any)=>data.customer_id==this.companyId)
    }
    if (this.status !== 0) {
      //this.allBundleList = [];
      this.usersForBundleAssignment = [];
      let stats = this.status == 1 ? 'true' : this.status == 2 ? 'false' : '';
      if (this.companyId != '') {
        this.dsregister.getDsUser(this.companyId, this.dsLimit, this.dsSkip, this.dsColname, this.dsord, searchTerm, stats, this.selectedBundleName, this.assignDate).subscribe(data => {
          if (data.status) {
            if (data.data.rows == null) {
              this.userObj = []
            } else {
              this.userObj = data.data.rows;
              if(flag == 'compChange'){ this.getAllBuldleList();}
            }
            this.total = data.data.total;
          } else {
            this.userObj = [];
            //this.allBundleList = [];
            this.total = 0;
          }
          this.ngxSpinnerService.hide();
          this.userObj.forEach(element => {
            element.isSelected = false;
          });
          this.ngxSpinnerService.hide();
        }, error => {
          this.ngxSpinnerService.hide();
        });
      } else {
        this.userObj = [];
        //this.allBundleList = [];
        this.ngxSpinnerService.hide();
      }
    }
    else {
      this.userObj = [];
      this.ngxSpinnerService.hide();
    }
  }



  getAllBuldleList() {
    this.ngxSpinnerService.show();
    this.LS.getAllBundles(this.companyId, this.dsPage).subscribe((data2: any) => {
      if (data2.status) {
        this.allBundleList = data2.data;
      } else {this.allBundleList = []}
      this.ngxSpinnerService.hide();
    });
  }

  UserLogData() {
    let UserLogs = {
      log_type: "direct seller User View",
      log_name: "direct seller User  View",
      log_description: "direct seller  User  View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(UserLogs).subscribe(data => {
      // console.log(data);
    }, error => {
      //  console.log(error);
    });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.usersForBundleAssignment = [];
      this.ngxSpinnerService.hide();
      if(this.customerID != 0 && this.customerID != 2){
        this.selectedCompany = this.getCompanyobj?.find((data:any)=>data.customer_id==this.companyId)
      }
    }, error => {
      // console.log(error);
    });
  }

  dsLogsView(id) {
    this.router.navigate(['/corporate/dslogs'], { queryParams: { customer_id: this.companyId, ds_id: id } });
  }

  resetpassword(username) {
    this.ngxSpinnerService.show();

    this.authenticationService.checkForgotPasswordDs({ username: username }).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.hide();
        this.toastrService.success("E-mail Send Successfully For Reset  Password")
        //this.router.navigateByUrl('/login');
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error("Account is Not Existed");
      }
    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });



  }

  sendActivationLink(obj) {

    this.assignBundleStatus = false;
    this.resendStatus = true;
    //alert();
    this.ngxSpinnerService.show();
    this.dsregister.sendActivationLink({ data: obj }).subscribe((data) => {
      if (data.status) {
        // alert();
        this.ngxSpinnerService.hide();
        this.toastrService.success("E-mail Send Successfully For Account Activation")
        this.checkedList = [];
        //uncheck all checkboxes
        this.checkboxes.forEach((element) => {
          element.nativeElement.checked = false;
        });
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error("Account Not Exists");
      }

    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });


  }

  updateStatus(event, dsid, isactive) {
    event.preventDefault();
    let userId = JSON.parse(sessionStorage.getItem('userData'));
    const dataobj = {
      customer_id: this.companyId,
      status: !isactive,
      dsid: dsid,
      userId: userId.uid
    }
    let stat = 'INACTIVE';
    if (!isactive) {
      stat = 'ACTIVE';
    }
    // console.log(userId);
    // console.log("userId",userId.uid);
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.registerService.activeInactiveDs(dataobj).subscribe(data => {
            //   console.log(data)
            this.toastrService.success("Status Changed Successfully");
            this.getCompanySeller('');
          });
        }
      }).catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }


  onCheckboxChange(option, event) {


    this.assignBundleStatus = false;


    if (event.target.checked) {
      this.resendStatus = true;

      if (option == "all") {
        this.userObj.forEach(element => {
          element.isSelected = true;
          this.checkedList.push(element);
        })
      }
      else this.checkedList.push(option);
    } else {
      this.resendStatus = false;

      if (option == "all") {
        this.userObj.forEach(element => {
          element.isSelected = false;
        })

        for (var i = 0; i < this.checkedList.length; i++) {
          this.checkedList.splice(i, 1);
        }

      } else {

        for (var i = 0; i < this.checkedList.length; i++) {
          if (this.checkedList[i].userid == option.userid) {
            this.checkedList.splice(i, 1);
          }
        }
      }
    }
    // console.log(this.checkedList);
  }

  onUserSelectedForAssignment(event, obj) {
    this.assignBundleStatus = true;
    this.resendStatus = false;
    if (event) {
      this.usersForBundleAssignment.push(obj.userid);
    } else {
      this.usersForBundleAssignment = this.usersForBundleAssignment.filter(user => user !== obj.userid);
    }
  }

  //  ###################  Assign Bundle to Selected Users  ##################  //

  assignBundle() {
    if (this.usersForBundleAssignment.length === 0) {
      this.toastrService.error('Please Select At Least One User');
      return;
    }
    this.ngxSpinnerService.show();
    if (this.bundleSelectForm.value.bundleid === '' || this.bundleSelectForm.value.bundleid === null ||
      this.bundleSelectForm.value.bundleid === undefined) {
      this.toastrService.error('Please select a License Bundle to continue');
      this.ngxSpinnerService.hide();
      return;
    } else {
      let cid = null;

      let dataObject = {
        bundle_id: this.bundleSelectForm.value.bundleid,
        userid: this.usersForBundleAssignment,
        company: this.companyId,
      };

      this.LS.assignBundle(dataObject).subscribe(data => {
        // console.log(data);
        if (data.status) {

          this.toastrService.success('Bundle Assigned Successfully');
          this.bundleSelectForm.reset();
          this.allBundleList = [];
          this.bundleSelectForm.get('bundleid').patchValue('');
          this.userObj = [];
          //    this.status = 0;
          //    this.companyId = 'select';
          //    this.getCompany();

          this.getCompanySeller('');

        } else {

          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
        //  console.log(error);
        this.ngxSpinnerService.hide();
      });

    }

  }

  reset() {
    this.searchText.nativeElement.value = '';
    this.selectedBundleName = "";
    this.allBundleList = [];
    // this.status  = this.status == 1 ? 'true' : this.status == 2 ? 'false' : '';
    this.assignDate = '';
    this.getCompanySeller('');
  }

  dsreportView(id){
    this.router.navigate(['/corporate/ds-report'], { queryParams: { customer_id:this.companyId,ds_id: id } });
  }

  getBundleAssignedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    // console.log(event);
    if (event.value) {
      this.selectedBundleName = "";
      this.dsPage = 1;
      this.assignDate = this.datePipe.transform(event.value.toString(), 'yyyy-MM-dd');
      this.getCompanySeller('');
    }
  }
  openEmailSelectionModal(modal){
    this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      keyboard: false,
      // scrollable: true
    }).result.then((result) => {
    }, (reason) => {// console.log("reason", reason);
    });
  }

  updateEmailProviderForDs(value:any){
    let payload = {
      ds_id: this.muUpdateDs,
      mc_type: value
    }
    this.ngxSpinnerService.show();
    this.dsregister.updateEmailProviderForDs(payload,this.companyId).subscribe({
      next:(res:any)=>{
        this.modalService.dismissAll();
        this.ngxSpinnerService.hide();
        this.toastrService.success('Updated successfully !');
        this.getCompanySeller('compChange');
      },
      error:(error:any)=>{
        this.modalService.dismissAll();
        this.ngxSpinnerService.hide();
        this.toastrService.error('Something went wrong !');
      }
    })
  }
}
