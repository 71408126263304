import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report-ds',
  templateUrl: './report-ds.component.html',
  styleUrls: ['./report-ds.component.css']
})
export class ReportDsComponent extends BaseComponent implements OnInit {
  page=1;
  companyID=0;
  getCompanyobj;
  customerID;
  obj;
  start_date=null;
  end_date=null;
  reportData;
  p: number = 1;
  total: number;
  status=null;  
  clicked=false;
  maxdate = new Date();
  mindate = new Date();
  stDate: Date;
  showResult:boolean=true;
  isHeadquater;


  constructor(
    private report:ReportService,
    private spinner : NgxSpinnerService,
    public toastrService: ToastrService,
    private clientService : ClientService
  ) {super(); }

  ngOnInit() {
    let today = new Date();
    today.setFullYear(today.getFullYear() - 10);
    this.mindate = today;
    this.stDate = this.mindate;

    localStorage.setItem('comapny_id',  "");
    var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
    this.customerID= customerInfo.admin_customer_id;
    // console.log("this.customerID==",this.customerID)
    this.companyID = this.customerID;
    this.isHeadquater = localStorage.getItem("customer_isheadquarter");

    console.log("gggg..........", this.isHeadquater)

    if(this.companyID==0 || this.isHeadquater ==="true") {   this.isHeadquater = true; this.companyID =0; this.getCompany();}
    else   {  this.isHeadquater = false; this.showResult=true; this.gettingReports(1,this.companyID); }
      //this.getDsSubscriptionList(62);
  }

  getCompany() {   
    
    let self = this;
    this.spinner.show();
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     
      this.spinner.hide();
     // console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }

  gettingReports(page,id) {
   // console.log('this.status');
 //   console.log(this.status);
  //  console.log(typeof this.status);
    //if(this.start_date != '' && this.end_date && this.companyID != 0 && this.status != null){
      this.companyID =id;

      
    if(this.companyID==0) {
      this.toastrService.error("Please select company");
      this.reportData = [];
      this.total= 0;
      return false;
    }
    
    const parmas = {
        customer_id: this.companyID,
        page: page,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
        csv : false
      }; 
      //alert(parmas);
      this.p = page;
      this.spinner.show();
      this.report.getDsSubscriptionList(parmas).subscribe((data)=>{
        if(data.status)
        { 
          this.clicked=true;
          this.showResult=true;
          this.reportData = data.data;
          this.total= data.totalCount;
        }
        else
        {
          this.reportData = [];
          this.total= 0;
        }
      
      this.spinner.hide();
      }); 
    //} else {
    //  this.toastrService.error("Kindly select all fields.");
    //}
    
  }

  getDsSubscriptionList(companyID){
   // console.log("getDsSubscriptionList===",companyID)
    const parmas = {
      customer_id: companyID,
      page: this.page,
      start_date: this.start_date,
      end_date: this.end_date,
      status: this.status,
      csv : false
    }; 
    //alert(parmas);
    this.p = this.page;
    this.spinner.show();
    this.report.getDsSubscriptionList(parmas).subscribe((data)=>{
      if(data.status)
      { 
        this.clicked=true;
        this.reportData = data.data;
        this.total= data.totalCount;
      } else {
        this.reportData = [];
        this.total= 0;
      }
      this.spinner.hide();
    }); 
  }

  exportReportCsv() {
    this.spinner.show();
    if(this.companyID !=0){
      const parmas = {
        customer_id: this.companyID,
        page: this.p,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
        csv : true
      };
      this.report.getDsSubscriptionList(parmas).subscribe(dataFile => {
      // console.log(">>>>>", dataFile);
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
        this.spinner.hide();
      }, err => {
        this.toastrService.error(err.message);
        this.spinner.hide();
      });
    }else{
      this.toastrService.error('Please Select The Company!');
      this.spinner.hide();
    }
  }
  
  updateCalDate() {
    let d = new Date(this.start_date);
    d.setDate(d.getDate() + 1);
    this.stDate = d;
  //  console.log(this.stDate);
  }

  resetReport(page=1){
    this.start_date = null;
    this.end_date = "";
    this.status = null;
    if(this.companyID==0 || this.isHeadquater ==="true") this.companyID = 0;
    
     this.gettingReports(1,this.companyID);

  }



}
