<div class="app-content content">
  <div class="content-wrapper">

    <div class="container mt-5 mt-md-3">
      <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
        <div class="row">
          <div class="col-xl-10">
            <h4 class="mb-3 mainHeading_1">Edit My Profile</h4>
            <!-- <span class="backBtnPro float-right">
              <a [routerLink]="['/myprofile']" class="backBtnCom"><i class="fa fa-long-arrow-left"
                  aria-hidden="true"></i>
                Back</a>
            </span> -->
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-xl-10">
            <div class="card">
              <div class="card-body">
                <div class="row" *ngIf="userProfileDetails">
                  <div class="col-md-4 text-center">
                    <div class="media d-flex">
                      <div class="media-body">
                        <div class="proImgMain mb-1">
                          <div class="fileUpl">
                            <i class="fa fa-camera"></i>
                            <input #imageInput class="flUpBtn" type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                          </div>
                          <div class="imgProUp" for="fileInput">                         

                          <img src="{{image_awskey}}" alt="profile image" (error)="setValue($event)">
                        </div>

                        </div>

                        <!-- <img src="assets/custom/images/profile.png" >
                    <i class="fa fa-camera" style="position: fixed;z-index: 2;"></i> -->

                        
                        <small class="redNote d-block">Allowed file Types(.png, .jpg, .jpeg)</small>
                        <button *ngIf="image_awskey" class="btn btn-round btn-margo-action mt-3"
                          (click)="delete()">Delete</button>
                      </div>

                    </div>
                    <!-- <p class="mt-2"><a [routerLink]="['/directseller/mypayment/add']" class="card-link"><i class="fa fa-search blue-dr-icon" aria-hidden="true"></i> View marGo Agreement</a></p> -->
                  </div>

                  <div class="col-md-8 mt-1 mt-md-0">
                    <h4 class="font-weight-bold mb-3" *ngIf="userProfileDetails.admin_fname">
                      {{userProfileDetails.admin_fname + ' '+   userProfileDetails.admin_lname | titlecase}}</h4>

                    <div class="row mt-3"  *ngIf="userProfileDetails.company_name">
                      <div class="col-sm-4 font-weight-bold">Company :</div>
                      <div class="col-sm-8"><span class="text-uppercase"
                          *ngIf="userProfileDetails">{{userProfileDetails.company_name}}</span></div>
                    </div>

                    <div class="row mt-3" *ngIf="userProfileDetails?.admin_username">
                      <div class="col-sm-4 font-weight-bold">Username :</div>
                      <div class="col-sm-8"><span class="text-uppercase"
                          *ngIf="userProfileDetails">{{userProfileDetails.admin_username}}</span></div>
                    </div>

                    <div class="row mt-3" *ngIf="userProfileDetails?.admin_email">
                      <div class="col-sm-4 font-weight-bold">Email :</div>
                      <div class="col-sm-8">{{userProfileDetails?.admin_email}}</div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

        <h4 class="mb-3 font-weight-bold">Basic Information</h4>

        <div class="row">
          <div class="col-xl-10">
            <div class="card">
              <div class="card-body">

                <div class="row">

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Title <span class="required">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" formControlName="title" id="Title"
                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                      <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.hasError('required')"> Title is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Company name</label>
                      <input type="text" autocomplete="off" class="form-control" formControlName="company_name"
                        id="name" />

                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>First Name <span class="required">*</span></label>
                      <input type="text" autocomplete="off" required class="form-control" formControlName="admin_fname"
                        [ngClass]="{ 'is-invalid': submitted && f.admin_fname.errors }" id="user_fname" />
                      <div *ngIf="submitted && f.admin_fname.errors" class="invalid-feedback">
                        <div *ngIf="f.admin_fname.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" autocomplete="off" class="form-control" formControlName="admin_lname">
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Mobile Number <span class="required">*</span></label>
                      <div class="phFieldPre ng-star-inserted">+ <b>91</b></div>
                      <input type="text" class="form-control phField" name="user_mobile_phone" formControlName="mobile_phone"
                        [ngClass]="{ 'is-invalid': submitted && f.mobile_phone.errors }" />
                      <div *ngIf="submitted && f.mobile_phone.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile_phone.errors.required"> Mobile number is required</div>
                        <div *ngIf="f.mobile_phone.errors.pattern"> Only numbers are allowed</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Home Number</label>
                      <input type="text" class="form-control" name="user_home_phone" formControlName="home_phone" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="form-group row">
                      <div class="col-12">
                        <label>Birth Date:</label>
                        <input class="form-control" matInput [matDatepicker]="picker" readonly
                          formControlName="birthdate" [max]="maxdate"
                          [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <!-- <input type="date" class="form-control" [max]="maxdate" formControlName="birthdate"
                  [(ngModel)]="contactByIdData.contact_birthdate" [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }" > -->
                        <div *ngIf="submitted && f.birthdate.errors" class="invalid-feedback">
                          <div *ngIf="f.birthdate.hasError('date')">Your age should be greater than 16 years</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>


        <h4 class="mb-3 font-weight-bold">Address</h4>

        <div class="row">
          <div class="col-xl-10">

            <div class="card">
              <div class="card-body">

                <div class="row" *ngIf="userProfileDetails">

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Address 1 <span class="required">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" name="address1"
                        formControlName="address1" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" />
                      <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                        <div *ngIf="f.address1.hasError('required')">Address1 is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Address 2</label>
                      <input type="text" autocomplete="off" class="form-control" name="address2"
                        formControlName="address2" />

                      <!-- <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                  <div *ngIf="f.address2.errors.required">Address2 is required</div>
                </div> -->
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Country <span class="required">*</span></label>
                      <input formControlName="country" autocomplete="off" class="form-control " placeholder="country"
                        [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                      <!-- (change)="states($event.target.value)"
                  <option *ngFor="let Obj of countriesData" value={{Obj.id}}>
                    {{Obj.name}}
                  </option>
                </select> -->
                      <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                        <div *ngIf="f.country.hasError('required')">Country is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>State <span class="required">*</span></label>
                      <input formControlName="state" autocomplete="off" class="form-control "
                        [ngClass]="{ 'is-invalid': submitted && f.state.errors }" placeholder="State"
                        (change)="getCities($event.target.value)">
                      <!-- <option value="Select State"></option> -->
                      <!-- <option *ngFor="let Obj of stateData" value={{Obj.id}}>
                    {{Obj.name}}
                  </option> -->

                      <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                        <div *ngIf="f.state.hasError('required')">State is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>City <span class="required">*</span></label>
                      <input formControlName="city" autocomplete="off" class="form-control "
                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="City">
                      <!-- <option *ngFor="let Obj of cityData" value={{Obj.id}}>
                    {{Obj.name}}
                  </option>
                </select>-->
                      <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                        <div *ngIf="f.city.hasError('required')">City is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Zip Code <span class="required">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" name="pincode"
                        formControlName="zipcode" [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }" />
                      <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                        <div *ngIf="f.zipcode.hasError('required')">Zipcode is required</div>
                      </div>
                    </div>
                  </div>



                </div>

              </div>
            </div>

          </div>
        </div>

        <h4 class="mb-3 font-weight-bold">Social Links</h4>

        <div class="row">
          <div class="col-xl-10">
            <div class="card">
              <div class="card-body">

                <div class="row" *ngIf="userProfileDetails">

                  <div class="col-xl-4 col-md-6">
                    <div class="form-group">
                      <label>Facebook Link:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-facebook"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="facebookURL"
                          formControlName="facebook_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">


                    <div class="form-group">
                      <label>Twitter Link:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-twitter"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="twitterURL"
                          formControlName="twitter_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>Instagram Link:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-instagram"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="instagramURL"
                          formControlName="instagram_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>LinkedIn Link:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-linkedin"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="linkedInURL"
                          formControlName="linkedin_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>Instagram Biz:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-instagram"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="instagrambiz"
                          formControlName="instagram_biz_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>YouTube:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-youtube"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="youtubeURL"
                          formControlName="youtube_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>Google Biz:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-google"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="googleURL"
                          formControlName="google_my_biz_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>Sina Wiebo:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-Sina"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="Sina"
                          formControlName="sina_wiebo_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>WeChat:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-wechat"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="wechatURL"
                                (keydown)="isNumber($event)"
                          formControlName="we_chat_id" />
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-4 col-md-6">

                    <div class="form-group">
                      <label>Whatsapp:</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text input-group-margo input-group-whatsapp"></div>
                        </div>
                        <input rows="5" autocomplete="off" class="form-control" name="whatsappURL"
                                (keydown)="isNumber($event)"
                          formControlName="whatsapp_id" />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-xl-10">
            <div class="form-group text-center mt-1">
              <button type="submit" class="btn btn-round btn-margo-action" [disabled]="submitButton">Save Changes</button>
            </div>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>
