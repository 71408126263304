<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Basic Services Summary</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row" >    
  
    <div class="col-xl-3 col-md-4 mt-2" *ngIf="isHeadquater">    
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="gettingReports(1,$event.target.value);">
        <option selected="selected" value=0>Select Company</option>    
        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} >
            {{userObj.customer_company_name}}
        </option>
      </select>
  </div>
   <div class="col-xl-2 col-md-4 mt-2">
    <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
              [(ngModel)]="start_date"  >
              <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
     
              <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
              [(ngModel)]="end_date"  >
              <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker></div>
  <!-- <div class="col-md-2 mt-2">
    <select name="status" class="form-control formstyle"  [(ngModel)]="status">
      <option value="select">Select Status</option>
        <option value="true">Activated</option>
        <option value="false">Canceled</option>
    </select> 
  </div> -->
  <div class="col-xl-4 col-md-5 mt-2">
    <button class="btn btn-margo-action  formstyle mt-md-1 ml-0"  style="align-items: center;" (click)="searchResult();">Search</button> 

    <button class="btn btn-margo-action ml-0 formstyle gp-mr-5" (click)="resetReport()" style="align-items: center;">Reset</button> 
 
    <!-- <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a> -->
  </div>
</div>

  
<div class="tblPrt" *ngIf="showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">    
      <tr>
        <th> Prospect Shop</th>
        <th> Prospect Host</th>
        <th> Prospect Join </th>
        <th> Customer Shop </th>
        <th> Customer Host </th>
        <th> Customer Join </th>
     
        <th> Hosted Event In Person</th>
        <th> Hosted Event Virtual</th>
        <th> Hosted Event Combo</th>        
        </tr>
        <tr>
          <td>{{!!tot_campaign_prospect_shop?tot_campaign_prospect_shop:'0'}}</td>
          <td>{{!!tot_campaign_prospect_host?tot_campaign_prospect_host:'0'}}</td>
          <td>{{!!tot_campaign_prospect_join?tot_campaign_prospect_join:'0'}}</td>
          <td>{{!!tot_campaign_consumer_shop?tot_campaign_consumer_shop:'0'}}</td>
          <td>{{!!tot_campaign_consumer_host?tot_campaign_consumer_host:'0'}}</td>
          <td>{{!!tot_campaign_consumer_join?tot_campaign_consumer_join:'0'}}</td>
          
       

          <td>{{!!tot_campaign_event_inperson?tot_campaign_event_inperson:'0'}}</td>
          <td>{{!!tot_campaign_event_virtual_new?tot_campaign_event_virtual_new:'0'}}</td>
          <td>{{!!tot_campaign_event_inperson_virtual?tot_campaign_event_inperson_virtual:'0'}}</td>          
        </tr>
  </table>
</div>

<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <td colspan="5">No results available</td>                            
    </tr>  
  </table>
</div>

