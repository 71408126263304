<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>

      <li class="breadcrumb-item">
        <a routerLink="/corporate/audience-builder"> Audience List</a>
      </li>


      <li class="breadcrumb-item active" aria-current="page">
        View Audience
      </li>


    </ol>
  </nav>
</div>

<div class="container mt-2" *ngIf="audData">
  <h6 class="font-weight-bold mt-4 mb-4">Audience Name:  {{audData.aud_name}}</h6>
  <h6 class="font-weight-bold mt-4 mb-4">Audience Type:  {{audData.audience_name}}</h6>

  <div class="row">
      <div class="col-xl-12">


        <div class="card">
          <div class="card-body">
           

            <div class="w-100 mb-3 gryBg" *ngFor="let item of queryResult; let i=index">
              <div class="row">
                
                <div class="col-12">
                  
               
            <div class="row" >
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-1 text-right"  *ngIf="i>0">
                    <span class="audImgIcon"><img src="assets/custom/images/audioBuildImg.png"></span>
                    <span class="audImgTxt">...</span>
                  </div>
                  <div class="col-md-2" *ngIf="i>0">
                  
                    {{item.query_connector}}
                 
                  </div>
                  <div class="col-md-4">

                    {{item.entity_name}}
                   
                    
                  </div>
                </div>
              </div>
              
              <div class="col-md-3 mb-3">

                {{item.field_name}}
              
              </div>
              
                                    
            
              <div class="col-md-3 mb-3">

                {{item.operator_name}}
              
              </div>
              <div class="col-md-6" *ngIf="showProductValue">
                {{item.query_value}}
              </div>

                                    
            </div>
            <div class="text-right crossRed signAbso" *ngIf="item.iserror">
              <i class="fa fa-close mr-1 cursor audDeleteBtn"  placement="bottom" ></i>
            </div>
            <div class="text-right checkGreen signAbso" *ngIf="!item.iserror">
              <i class="fa fa-check mr-1 cursor audDeleteBtn"  placement="bottom" ></i>
            </div>
          </div>
          
        </div>
          </div>

         

          </div>
          <div class="card-footer">
            <div class="text-center my_class " *ngIf="isWrite" >
              <!--<button type="button" class="custbtn" >UPDATE</button>-->
              <!--<button type="button" class="custbtn" (click)="testAudience()">Test Audience</button>-->
              <button type="button" class="custbtn" (click)="backQueryEditPage()">Back</button>
              <button type="button" class="custbtn" (click)="goToScheduleAudience()">Schedule</button>
            </div>

          </div>
        </div>
      </div>
    </div>

</div>

<div class="container mt-2" *ngIf="!audData && show">
  <p>No Result Available</p>
</div>
