import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-cb-field-attribute-association',
  templateUrl: './cb-field-attribute-association.component.html',
  styleUrls: ['./cb-field-attribute-association.component.css']
})
export class CbFieldAttributeAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  getCompanyobj;
  companyID=0;
  attributeObj:any=[];

  constructor(
    private clientService:ClientService,
    private cb: CbService,
  ) {
    super();
   }
  ngOnInit() {   
    this.getCompany();
  }
  

  getCompanyFields(id){
    //event.preventDefault();
    this.companyID =id;
   // console.log("company themes:"+ id);
    let self = this;
    this.attributeObj = [];
    this.ngxSpinnerService.show();
    if(id) {

      let tempData = [] ;

    this.cb.getCompanyField(id, 9999, 0).subscribe(data => {
      this.ngxSpinnerService.hide();
        if(data.data) {
          tempData = data.data.rows;
          const tempData2 = tempData.filter(item => item.isactive);
          this.themeObj = tempData2;
        } else {
          this.themeObj = [];
        }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.themeObj = [];
    });
  }
  else {
    this.themeObj = [];
    this.ngxSpinnerService.hide();

  } 

  }
  
  getFieldAttribute(id){
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getAssociatedFieldAttribute(this.companyID,id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if( data.data) {
        self.attributeObj = data.data;
      } else {
        this.attributeObj = [];
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.attributeObj = [];
    });

  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      if(data.data) {
        self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
      } else {
        self.getCompanyobj = [];
      }
     
    }, error => {
     // console.log(error);
      self.getCompanyobj = [];
    });
  }
}
