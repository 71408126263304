import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-contentbuilder',
  templateUrl: './contentbuilder.component.html',
  styleUrls: ['./contentbuilder.component.css']
})
export class ContentbuilderComponent extends BaseComponent implements OnInit {

  constructor() { super(); }

  customerID=0;
  roleID;

  ngOnInit() {

    var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
    this.customerID= customerInfo.admin_customer_id; 
    this.roleID=customerInfo.role_id;

  }

}
