import { Injectable } from '@angular/core';
import { Router, CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RegisterService } from 'src/app/services/users/register.service';
@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate,CanActivateChild {

    constructor(private router: Router,private registerService: RegisterService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | boolean{

        return this.registerService.checkifpageauthorised(state.url.replace('/',''),this.router);
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.canActivate(route,state);
    }
}
