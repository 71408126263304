<div class="container bdrStCustom">
  <div class="row">
    <div class="col">
      <div class="card box-shadow-1">
        <div class="card-body">
          <h5 class="font-weight-bold">DS User list</h5>
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User name</th>
                <th width="150">Created On</th>
              </tr>
              <tr *ngFor="let obj of contactUserObj">
                <td>{{obj.full_name}}</td>
                <td>{{obj.email}}</td>
                <td>{{obj.username}}</td>
                <td>{{obj.createdon | date:'medium' }}</td>
              </tr>

            </table>
          </div>
          <div class="mt-2 text-center difPagination" *ngIf="clientTotal > 10">
            <ngb-pagination [collectionSize]="clientTotal" [(page)]="clientPage" [maxSize]="2" [rotate]="true"
              (pageChange)="getCompanySeller(clientPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>