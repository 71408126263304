import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-addnewmoduleitems',
  templateUrl: './addnewmoduleitems.component.html',
  styleUrls: ['./addnewmoduleitems.component.css']
})
export class AddnewmoduleitemsComponent extends BaseComponent implements OnInit {
  getModuleObj: any;
  // itemsname;
  // parentid;
  // name;
  // description;
  moduleitemid;
  moduleItemsObjId;
  moduleitemroutelink;
  // isactive = true;
  // moduleid = 0;
  getModuleItemsobj;
  // moduleparentname = 0;
  header = "Add New"
  // menu_id;
  // meunObjId;
  status: boolean = true;
  selectedid;
  selectedname;

  trainingtypeForm: FormGroup;
  submitted = false;
  constructor(
    private master: MasterService,
    public register: RegisterService,
    private formBuilder: FormBuilder
  ) {
    super();
   }

  ngOnInit() {
    this.getModule();
    this.getItemsOfModule();
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.moduleitemid = params.moduleitemid;
      });
    //get item details using id
    if (self.moduleitemid) {
      this.UpdateModuleItemsLogData();
      this.ngxSpinnerService.show();
      this.header = "Edit"
      this.master.getModuleItemsById(this.moduleitemid).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.moduleItemsObjId = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();
        } else {
        }
      });
    } else {
      this.addModuleItemsLogData();
    }
    this.trainingtypeForm = this.formBuilder.group({
      itemsName: ['',  [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      itemsDescription: [],
      itemsModuleId: ['', Validators.required],
      itemsModuleitemrouteLink: [],
      isactive: [true],
    });
  }
  get f() { return this.trainingtypeForm.controls; }
  addModuleItemsLogData() {
    let ModuleItemsLogs = {
      log_type: "Adding New ModuleItems",
      log_name: "Adding New ModuleItems",
      log_description: "Adding New ModuleItems",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ModuleItemsLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  UpdateModuleItemsLogData() {
    let ModuleItemsLogs = {
      log_type: "Updating ModuleItems Data",
      log_name: "Updating ModuleItems Data",
      log_description: "Updating ModuleItems Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ModuleItemsLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  getModule() {
    let self = this;
    this.register.getModuleDataChild().subscribe(data => {
      var getModuleObj = data.data;
    //  console.log(getModuleObj);

      var obj = [];

      getModuleObj.forEach(element => {
        element = element.get_master_module_parent_id_fun;
        var each = {
          id: element.moduleid,
          name: element.module_name,
          children: []
        };
        //console.log(element);
        if (element.level == 0) {

          for (var i = 0; i < getModuleObj.length; i++) {

            if (getModuleObj[i].get_master_module_parent_id_fun.level == 1 &&
              getModuleObj[i].get_master_module_parent_id_fun.role_parent == element.moduleid) {

              var child = {
                id: getModuleObj[i].get_master_module_parent_id_fun.moduleid,
                name: getModuleObj[i].get_master_module_parent_id_fun.module_name
              }
              //element.children = element.children == undefined ? [] : element.children;
              each.children.push(child);

            }
          }

          obj.push(each);
        }
        // console.log(obj);
        self.getModuleObj = obj;
      });
    });
  }
  getItemsOfModule() {
    let self = this;
    this.register.getModuleItems().subscribe(data => {
      self.getModuleItemsobj = data.data;
    }, error => {
     // console.log(error);
    });
  }
  addModuleItems() {
    this.submitted = true;
   // console.log(this.trainingtypeForm);
    if (this.trainingtypeForm.invalid) {
      return;
    }
    //console.log(this.trainingtypeForm);

    let { itemsName, itemsDescription, itemsModuleId, itemsModuleitemrouteLink, isactive } = this.trainingtypeForm.value;
    let itemsObj = {
      // parentId: itemsParentId,
      moduleItemName: itemsName,
      moduleItemDesc: itemsDescription,
      moduleId: itemsModuleId,
      moduleItemRouteLink: itemsModuleitemrouteLink,
      isactive: isactive
    }
  //  console.log(itemsObj)
    this.ngxSpinnerService.show();
    this.master.addingModuleItems(itemsObj).subscribe(data => {
     // console.log(data);
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl('/corporate/moduleitems');
        this.getItemsOfModule()
        this.ngxSpinnerService.hide();
      }else{
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    })
  }
  dataBinding() {

    // this.itemsname = this.moduleItemsObjId.module_item_name;
    // this.description = this.moduleItemsObjId.module_item_description;
    // this.parentid = this.moduleItemsObjId.module_item_parent_id;
    // this.isactive = this.moduleItemsObjId.isactive;
    // this.moduleid = this.moduleItemsObjId.moduleid;
    // this.moduleitemroutelink = this.moduleItemsObjId.module_item_route;

    this.trainingtypeForm = this.formBuilder.group({
      itemsName: [this.moduleItemsObjId.module_item_name,  [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      itemsDescription: [this.moduleItemsObjId.module_item_description],
      itemsModuleId: [this.moduleItemsObjId.moduleid, Validators.required],
      itemsModuleitemrouteLink: [this.moduleItemsObjId.module_item_route],
      isactive: [this.moduleItemsObjId.isactive],
    });
  }
  updateModuleItems() {
    this.submitted = true;
    if (this.trainingtypeForm.invalid) {
      return;
    }
   // console.log(this.trainingtypeForm);

    let { itemsName, itemsDescription, itemsModuleId, itemsModuleitemrouteLink, isactive } = this.trainingtypeForm.value;
    let roleObj = {
      module_item_name: itemsName,
      module_item_description: itemsDescription,
      // module_item_parent_id: itemsParentId,
      moduleItemRouteLink: itemsModuleitemrouteLink,
      isactive: isactive,
      moduleid: itemsModuleId,
      moduleitemid: Number.parseInt(this.moduleitemid, 10)
    }
   // console.log("******", roleObj);
    this.ngxSpinnerService.show();
    this.master.updateModuleItems(roleObj).subscribe(data => {
     // console.log(data)
      if (data.status) {
        this.toastrService.success("Updated Successfully")
      //  console.log(data);
        this.router.navigateByUrl(`/corporate/moduleitems`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
