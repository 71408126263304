import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/secondadmin/base.component';

@Component({
  selector: 'app-indivisual-data',
  templateUrl: './indivisual-data.component.html',
  styleUrls: ['./indivisual-data.component.css']
})
export class IndivisualDataComponent extends BaseComponent implements OnInit {

  constructor() { super() }

  ngOnInit() {
  }

}
