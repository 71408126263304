<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Readiness Module</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon pb-3">
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">Readiness Module</h5>
  </legend>
  <fieldset class="scheduler-border">
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/readiness-activity-list">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Readiness Activity
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/readiness-resources-list">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>
          Readiness Resources
        </button>
      </div>
    </div>
  </fieldset>
</div>
