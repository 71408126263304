import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-addnewrole-master',
  templateUrl: './addnewrole-master.component.html',
  styleUrls: ['./addnewrole-master.component.css']
})
export class AddnewroleMasterComponent extends BaseComponent implements OnInit {
  // parentid;
  // prolename;
  // name;
  // description;
  role_id;
  roleObjId;
  // isactive = true;
  // menu_id;
  // meunObjId;
  parentrole = 0;
  getRoleObj1;
  getRoleObj = [];
  status: boolean = true;
  selectedid;
  selectedname;
  header = "Add New"

  roleForm: FormGroup;
  submitted = false;
  constructor(
    private master: MasterService,
    private register: RegisterService,
    private formBuilder: FormBuilder
  ) { 
    super();
  }

  ngOnInit() {
    this.getRole(0);
    //this.getRoledropdown()
    let self = this;
    // self.parentid = 0;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.role_id = params.role_id;
      });

    if (self.role_id) {
      this.UpdateRole_MasterLogData()
    }
    else {
      this.addRole_MasterLogData()
    }
    this.roleForm = this.formBuilder.group({
      roleName: ['', [Validators.required,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30),this.noWhitespaceValidator]],
      roleDescription: '',
      roleParentId:[0],
      isactive: [true]
    });
  }
  get f() { return this.roleForm.controls; }

  addRole_MasterLogData() {
    let rmLogs = {
      log_type: "Adding New Role_Master",
      log_name: "Adding New Role_Master",
      log_description: "Adding New Role_Master",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(rmLogs).subscribe(data => {
    }, error => {
     // console.log(error);
    });
  }

  UpdateRole_MasterLogData() {
    let rmLogs = {
      log_type: "Updating Role_Master Data",
      log_name: "Updating Role_Master Data",
      log_description: "Updating Role_Master Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(rmLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  getRole(roleId) {
    let self = this;
    this.register.getRoleData2(roleId).subscribe(data => {
      self.getRoleObj = data.data;
      //get item details using id
      if (self.role_id) {
        this.header = "Edit"
        this.master.getRoleById(this.role_id).subscribe(response => {
          if (response.status) {
            this.status = false;
            self.roleObjId = response.data;
            self.dataBinding();
          } else {}
        });
      }
    }, error => {
     // console.log(error);
    });
  }
  getRoledropdown() {
    let self = this;
    this.master.getRoledropdown().subscribe(data => {
      self.getRoleObj1 = data;
    //  console.log(self.getRoleObj1)
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
    // this.name = this.roleObjId.role_name;
    // this.description = this.roleObjId.role_description;
    // this.parentid = this.roleObjId.role_parent_id;
    // this.isactive = this.roleObjId.isactive;

    this.roleForm = this.formBuilder.group({
      roleName: [this.roleObjId.role_name, [Validators.required,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30),this.noWhitespaceValidator]],
      roleDescription: [this.roleObjId.role_description],
      roleParentId: [this.roleObjId.role_parent_id],
      isactive: [this.roleObjId.isactive]
    });
  }
  addRole() {
    this.submitted = true;
    if (this.roleForm.invalid) {
      return;
    }
    let { roleName, roleDescription, roleParentId, isactive } = this.roleForm.value;
    let roleBody = {
      parentId: roleParentId,
      roleName: roleName,
      roleDesc: roleDescription,
      isactive: isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingRole(roleBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl('/corporate/rolesmaster');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     console.log(error);
     this.ngxSpinnerService.hide();
    });
  }
  updateRole() {
    this.submitted = true;
    if (this.roleForm.invalid) {
      return;
    }
    let { roleName, roleDescription, roleParentId, isactive } = this.roleForm.value;
    let roleBody = {
      role_name: roleName,
      role_description: roleDescription,
      role_parent_id: roleParentId,
      isactive: isactive,
      role_id: Number.parseInt(this.role_id, 10)
    }
    this.ngxSpinnerService.show();
    this.master.updateRole(roleBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully")        
        this.router.navigateByUrl(`/corporate/rolesmaster`);
      }else{
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
