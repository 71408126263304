<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
    margin-top: 2em;
  }

  .ngx-pagination {
    padding-left: 0;
  }
</style>
<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4 mt-2" *ngIf="isHeadquater == true && customerID !=0 ">
      <select name="company" class="form-control formstyle" [(ngModel)]="company_id"
        (change)="changeCompany($event.target.value)">
        <option value="">Select Company</option>
        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
          {{userObj.customer_company_name}}
        </option>
      </select>
    </div>
  </div>

  <div class="card mt-2 card-height">
    <div class="card-body">
      <div class="row">

        <div class="col-12 col-xl-12">
          <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder">
            <ngb-tabset style="width: 100%;" (tabChange)="beforeChange($event)">

              <ngb-tab *ngIf="customerID==0" id="admin_view_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('admin_view_logs_tbl')">{{"Admin View"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="input-group">
                    <input class="form-control ml-3 mb-3" value="" type="text" placeholder="Search" aria-label="Search"
                      [(ngModel)]="viewText" (keyup)="adminViewSearch($event.target.value)">
                  </div>
                  <table class="table table-borderless table-responsive" *ngIf="adminView?.length != 0">
                    <thead>
                      <tr>
                        <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of adminView ">
                        <td>{{item.lid}}</td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.log_description}}</td>
                        <td>{{item.createdname}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon  | date:'MMM d, y, h:mm a'}}</td>
                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="viewTotalCount > 10">
                    <ngb-pagination [collectionSize]="viewTotalCount" [(page)]="viewPage" [pageSize]="50" [maxSize]="2" [rotate]="true"
                      (pageChange)="getAdminViewLogs(viewPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="viewTotalCount == 0">No Result Available</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID==0" id="admin_cud_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('admin_cud_logs_tbl')">{{"Admin CRUD"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="input-group">
                    <input class="form-control ml-3 mb-3" type="text" placeholder="Search" aria-label="Search"
                      [(ngModel)]="crudText" (keyup)="adminCurdSearch($event.target.value)">
                  </div>
                  <table class="table table-borderless table-responsive mt-2" *ngIf="adminCrud?.length != 0">
                    <thead>
                      <tr>
                        <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of adminCrud">
                        <td>{{item.lid}}</td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.log_description}}</td>
                        <td>{{item.createdname}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>

                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="curdTotalCount > 10">


                    <ngb-pagination [collectionSize]="curdTotalCount" [(page)]="curdPage" [pageSize]="50" [maxSize]="2" [rotate]="true"
                      (pageChange)="getAdminCurdLogs(curdPage=$event)" >


                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="curdTotalCount == 0">No Result Available</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID!=0" id="ds_view_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('ds_view_logs_tbl')">{{"Direct Seller View"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="row">
                  <div class="col-md-6 mb-md-0 mb-1">
                    <div class="input-group" style="margin: 7px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text cyan lighten-2">
                          <i aria-hidden="true" class="fa fa-search text-white"></i>
                        </span>
                      </div>
                      <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search"
                        [(ngModel)]="searchText" (keyup)="dsViewLogSearch($event.target.value,'text')">
                    </div>
                  </div>

                  <div class="col-md-6 mb-md-0 mb-1">
                    <select name="ds" [(ngModel)]="dsText" class="form-control formstyle srchWith" (change)="dsViewLogSearch($event.target.value,'DS')" >
                    <option value = '' >Select DS</option>

                      <option *ngFor="let data of directSellers" value={{data.userid}}>
                        {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}

                      </option>
                  </select>
                  </div>
                </div>
                  <div class="col-12 tblPrt">
                  <table class="table table-borderless table-responsive" *ngIf="directSellerViewLog?.length != 0">
                    <thead>
                      <tr>
                        <!-- <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <!-- <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of directSellerViewLog">
                        <!-- <td>{{item.lid}}</td> -->

                        <td><a title="{{item.log_description}}" class="titleStyle">{{item.log_name}}</a></td>

                        <!-- <td>{{item.log_description}}</td> -->
                        <td>{{item.createdname}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>


                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-2 text-center difPagination" *ngIf="dsViewTotalCount > 10">


                    <ngb-pagination [collectionSize]="dsViewTotalCount" [(page)]="dsViewPage" [pageSize]="50" [maxSize]="2"


                      [rotate]="true" (pageChange)="getDsViewLogs(dsViewPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="dsViewTotalCount == 0">No Result Available</p>
                </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID!=0" id="ds_curd_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('ds_curd_logs_tbl')">{{"Direct Seller Crud"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="row">
                    <div class="col-md-6 mb-md-0 mb-1">
                      <div class="input-group" style="margin: 7px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text cyan lighten-2">
                          <i aria-hidden="true" class="fa fa-search text-white"></i>
                        </span>
                      </div>
                      <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search"
                        [(ngModel)]="searchText" (keyup)="dsCurdLogSearch($event.target.value,'text')">
                    </div>
                  </div>


                  <div class="col-md-6 mb-md-0 mb-1">
                    <select name="ds" [(ngModel)]="dsText" class="form-control formstyle" (change)="dsCurdLogSearch($event.target.value,'DS')" >
                    <option value = '' >Select DS</option>
                      <option *ngFor="let data of directSellers" value={{data.contactname}}>
                        {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}
                      </option>
                  </select>
                  </div>
                </div>
                <div class="col-12 tblPrt">
                  <table class="table table-borderless table-responsive mt-2" *ngIf="directSellerCrudLog?.length != 0">
                    <thead>
                      <tr>
                        <!-- <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <!-- <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of directSellerCrudLog">
                        <!-- <td>{{item.get_master_menu.menuid}}</td> -->
                        <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
                        <!-- <td>{{item.lid}}</td> -->
                        <td><a title="{{item.log_description}}" class="titleStyle">{{item.log_name}}</a></td>
                        <!-- <td>{{item.log_description}}</td> -->
                        <td>{{item.createdname}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>


                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-2 text-center difPagination" *ngIf="dsCurdTotalCount > 10">
                    <ngb-pagination [collectionSize]="dsCurdTotalCount" [(page)]="dsCurdPage" [pageSize]="50" [maxSize]="2"
                      [rotate]="true" (pageChange)="getDsCurdLogs(dsCurdPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="dsCurdTotalCount == 0">No Result Available</p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID==0" id="admin_login_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('admin_login_logs_tbl')">{{"Login Logs"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="col-md-6 mt-3">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text cyan lighten-2">
                          <i aria-hidden="true" class="fa fa-search text-white"></i>
                        </span>
                      </div>
                      <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search"
                        [(ngModel)]="loginText" (keyup)="adminLoginLogSearch($event.target.value)">
                    </div>
                  </div>
                  <table class="table table-borderless table-responsive mt-2" *ngIf="loginLog?.length != 0">
                    <thead>
                      <tr>
                        <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('username')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of loginLog">
                        <!-- <td>{{item.get_master_menu.menuid}}</td> -->
                        <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
                        <td>{{item.lid}}</td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.log_description}}</td>
                        <td>{{item.username}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>

                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-2 text-center difPagination" *ngIf="loginTotalCount > 10">
                    <ngb-pagination [collectionSize]="loginTotalCount" [(page)]="loginPage" [pageSize]="50" [maxSize]="2"
                      [rotate]="true" (pageChange)="getAdminLoginLogs(loginPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="loginTotalCount == 0">No Result Available</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID!=0" id="ds_login_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('ds_login_logs_tbl')">{{"Login Logs"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="row">
                    <div class="col-md-6 mb-md-0 mb-1">
                      <div class="input-group" style="margin: 7px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text cyan lighten-2">
                          <i aria-hidden="true" class="fa fa-search text-white"></i>
                        </span>
                      </div>
                      <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search"
                        [(ngModel)]="searchText" (keyup)="dsLoginLogSearch($event.target.value,'text')">
                    </div>
                  </div>

                  <div class="col-md-6 mb-md-0 mb-1">
                    <select name="ds" [(ngModel)]="dsText" class="form-control formstyle" (change)="dsLoginLogSearch($event.target.value,'DS')" >
                    <option value = '' >Select DS</option>
                      <option *ngFor="let data of directSellers" value={{data.username}}>
                        {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}
                      </option>
                  </select>
                  </div>
                </div>
                  <div class="col-12 tblPrt">
                  <table class="table table-borderless table-responsive mt-2" *ngIf="directSellerloginLog?.length != 0">
                    <thead>
                      <tr>
                        <!-- <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <!-- <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of directSellerloginLog">
                        <!-- <td>{{item.lid}}</td> -->
                        <td><a title="{{item.log_description}}" class="titleStyle">{{item.log_name}}</a></td>
                        <!-- <td>{{item.log_description}}</td> -->
                        <td>{{item.username}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>
                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="dsLoginLogTotalCount > 10">
                    <ngb-pagination [collectionSize]="dsLoginLogTotalCount" [(page)]="dsLoginLogPage" [pageSize]="50" [maxSize]="2"
                      [rotate]="true" (pageChange)="getDsLoginLogs(dsLoginLogPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="dsLoginLogTotalCount == 0">No Result Available</p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID==0" id="support_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('support_logs_tbl')">{{"Support Log"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div>
                    <div class="row">



                      <div class="col-sm-2 mt-3 ml-3 mb-3">
                        <span class="logs-label">Company Name <span style="color:red">*</span></span>
                        <select name="company" id="company" class="form-control formstyle"
                          (change)="getCompanySupportList($event.target.value)" [(ngModel)]="bu_id">
                          <option value="">Select Company</option>
                          <option *ngFor="let userObj of getCompanyobj"
                            value='{"id":"{{userObj.customer_id}}","company_name":"{{userObj.customer_company_name}}"}'>
                            {{userObj.customer_company_name}}
                          </option>
                        </select>
                      </div>

                      <div class="col-sm-2 mt-3 ml-3 mb-3">
                        <span class="logs-label"> Support User <span style="color:red">*</span></span>
                        <select name="company" class="form-control formstyle"
                          (change)="getCompanySupportSellerList($event.target.value)" [(ngModel)]="support_id">
                          <option value="">Select Support User</option>
                          <option *ngFor="let userObj of supportUser"
                            value='{"id":"{{userObj.uid}}","supportuser_name":"{{userObj.full_name}}"}'>
                            {{userObj.full_name}}
                          </option>
                        </select>
                      </div>


                      <div class="col-sm-2 mt-3 ml-3 mb-3">
                        <span class="logs-label"> Direct Seller <span style="color:red">*</span></span>
                        <select name="sellerId" class="form-control formstyle" [(ngModel)]="sellerId"
                          (change)="getCaseId($event.target.value)">
                          <option value="">Select Direct Seller</option>
                          <option *ngFor="let obj of sellerObj"
                            value='{"id":"{{obj.userid}}","seller_name":"{{obj.full_name}}"}'>
                            {{obj.full_name}}
                          </option>
                        </select>
                      </div>





                      <div class="col-sm-2 mt-3 ml-3 mb-3">
                        <span class="logs-label"> Case Id <span style="color:red">*</span></span>
                        <select name="case_id" class="form-control formstyle" [(ngModel)]="case_id">
                          <option value="">Select Case Id</option>
                          <option *ngFor="let obj of caseObj" value={{obj.salesforce_case_id}}>
                            {{obj.salesforce_case_id}}
                          </option>
                        </select>
                      </div>

                      <div class="col-sm-2 mt-5">
                        <button class="btn btn-margo-action ml-0 mt-0 form-control formstyle" (click)="getLogBycaseId()"
                          style="align-items: center;">Search</button>
                      </div>

                    </div>

                  </div>
                  <div class="container" *ngIf="directSellerSupportCrudLog?.length != 0">
                    <div>
                      <span style="float: right;
                  margin-right: 15px;
                  padding: 8px;
                  border-radius: 7px;
                "> <a (click)="exportSupportFile()"> <img class="customimg"
                            src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
                        </a></span>
                    </div>
                    <nav aria-label="breadcrumb" class="breadCust">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/corporate/logs">{{company_name}}</a></li>
                        <li class="breadcrumb-item"><a routerLink="/corporate/logs">{{supportuser_name}}</a></li>
                        <li class="breadcrumb-item"><a routerLink="/corporate/logs">{{seller_name}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{case_id}}</li>
                      </ol>
                    </nav>
                  </div>
                  <table class="table table-borderless table-responsive"
                    *ngIf="directSellerSupportCrudLog?.length != 0">
                    <thead>
                      <tr>
                        <th (click)="adminSorting('lid')" style="cursor:pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of directSellerSupportCrudLog">
                        <td>{{item.lid}}</td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.log_description}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>

                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="supportLogTotalCount > 10">


                    <ngb-pagination [collectionSize]="supportLogTotalCount" [(page)]="sLogPage" [pageSize]="50" [maxSize]="2"


                      [rotate]="true" (pageChange)="getLogBycaseId(sLogPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <div *ngIf="supportLogTotalCount== 0" style="margin-left: 355px;">
                    <p><b>No Log created Yet....</b></p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="customerID==0" id="all_view_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('all_view_logs_tbl')">{{"All View"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="input-group">
                    <!-- <input class="form-control ml-3 mb-3" value="" type="text" placeholder="Search" aria-label="Search"
                      [(ngModel)]="viewText" (keyup)="allViewSearch($event.target.value,'text')"> -->
                  </div>
                  <table class="table table-borderless table-responsive" *ngIf="adminView?.length != 0">
                    <thead>
                      <tr>
                        <th >Log Id </th>
                        <th>Log Description </th>
                        <th >User </th>
                        <th >Error </th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Log Name </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of allView ">
                        <td>{{item.id}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.error ? 'Yes':'No'}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>
                        <td>{{item.route}}</td>
                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="allTotal > 10">
                    <ngb-pagination [collectionSize]="viewTotalCount" [(page)]="viewPage" [pageSize]="50" [maxSize]="2" [rotate]="true"
                      (pageChange)="getAllViewLogs(viewPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="allTotal == 0">No Result Available</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>
               <!-- Website Logs Start -->
               <ngb-tab *ngIf="customerID==0" id="web_logs_tbl">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('web_logs_tbl')">{{"Website Log"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div>
                    <div class="row">
                      <div class="col-sm-3 mt-3 ml-3 mb-3">
                        <span class="logs-label">Company Name <span style="color:red">*</span></span>
                        <select name="company" id="company" class="form-control formstyle"
                          (change)="getDsListByCompanyId($event)">
                          <option value="">Select Company</option>
                          <option *ngFor="let userObj of getCompanyobj"
                            value='{"id":"{{userObj.customer_id}}","company_name":"{{userObj.customer_company_name}}"}'>
                            {{userObj.customer_company_name}}
                          </option>
                        </select>
                      </div>

                      <!-- <div class="col-sm-3 mt-3 ml-3 mb-3">
                        <span class="logs-label"> Support User <span style="color:red">*</span></span>
                        <select name="company" class="form-control formstyle"
                          (change)="getCompanySupportSellerList($event.target.value)" [(ngModel)]="support_id">
                          <option value="">Select Support User</option>
                          <option *ngFor="let userObj of supportUser"
                            value='{"id":"{{userObj.uid}}","supportuser_name":"{{userObj.full_name}}"}'>
                            {{userObj.full_name}}
                          </option>
                        </select>
                      </div> -->


                      <!-- <div class="col-sm-3 mt-3 ml-3 mb-3">
                        <span class="logs-label"> Direct Seller <span style="color:red">*</span></span>
                        <select name="sellerId" class="form-control formstyle" [(ngModel)]="sellerId">
                          <option value="">Select Direct Seller</option>
                          <option *ngFor="let obj of sellerObj"
                            value='{"id":"{{obj.userid}}","seller_name":"{{obj.full_name}}"}'>
                            {{obj.full_name}}
                          </option>
                        </select>
                      </div> -->
                      <div class="col-sm-3 mt-3 ml-3 mb-3">
                        <span class="logs-label">Direct Seller <span style="color:red">*</span></span>
                        <select name="ds" [(ngModel)]="dsUserId" class="form-control formstyle">
                        <option value = '' >Select DS</option>
                          <option *ngFor="let data of dsList" value={{data.userid}}>
                            {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}
                          </option>
                      </select>
                      </div>
                      <div class="col-sm-2 mt-5">
                        <button class="btn btn-margo-action ml-0 mt-0 form-control formstyle" (click)="_getLogsDetails(0)"
                          style="align-items: center;">Search</button>
                      </div>
                    </div>
                  </div>
                  <table class="table table-borderless table-responsive"
                    *ngIf="logsData?.length != 0">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th class="text-nowrap">Created Date
                          <span *ngIf="logSort === 'created_dt DESC'" (click)="customerSortByInvoice('created_dt DESC')"><i
                                  class="fa fa-caret-down pointer"></i></span>
                              <span *ngIf="logSort === 'created_dt ASC'" (click)="customerSortByInvoice('created_dt ASC')"><i
                                  class="fa fa-caret-up pointer"></i></span>
                        </th>
                        <th>Username/Email</th>
                        <th>URL Type</th>
                        <th>Log Type</th>
                        <th>Log Name</th>
                        <th>Event Type</th>
                        <th>Event Name</th>
                        <th>Event Detail</th>
                        <th>IP Address</th>
                        <th>Access Url</th>
                        <th>Operating System</th>
                        <th>Browser Info</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of logsData;let i=index">
                        <td>{{i+1+(this.logsSkip - 1) * this.logsLimit}}</td>
                        <td>{{item.created_dt | date : 'd MMM, y, h:mm a'}}</td>
                        <td>{{item?.contact_fname?item.contact_fname + item.contact_lname:''}} <br> {{item?.contact_email?item.contact_email:''}}</td>
                        <td>{{item.log_action | uppercase}} </td>
                        <td>{{item.log_type | uppercase}}</td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.event_action}}</td>
                        <td>{{item.event_name}}</td>
                        <td>{{item.event_desc}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.accessed_url}}</td>
                        <td>{{item.machine_info}}</td>
                        <td>{{item.browser_info}}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="logsNoOfRecords > 10">


                    <ngb-pagination [collectionSize]="logsNoOfRecords" [(page)]="logsSkip" [pageSize]="50" [maxSize]="2"


                      [rotate]="true" (pageChange)="_getLogsDetails(logsSkip=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <div *ngIf="logsNoOfRecords== 0" style="margin-left: 355px;">
                    <p><b>No Log created Yet....</b></p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>
              <!-- website Logs End  -->
              <!--Registration Logs Start-->
              <ngb-tab *ngIf="customerID!=0" id="registration_logs">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('registration_logs')">{{"Registration Logs"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="row">
                    <div class="col-md-6 mb-md-0 mb-1">
                      <div class="input-group" style="margin: 7px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text cyan lighten-2">
                          <i aria-hidden="true" class="fa fa-search text-white"></i>
                        </span>
                      </div>
                      <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search"
                        [(ngModel)]="searchText" (keyup)="registrationLogsData($event.target.value,'text')">
                    </div>
                  </div>
                  <div class="col-md-6 mb-md-0 mb-1">
                    <select name="ds" [(ngModel)]="regDsId" class="form-control formstyle" (change)="regDsFilter()" >
                    <option value = '' >Select DS</option>
                      <option *ngFor="let data of registerDirectSellers" value={{data.uid}}>
                        {{data?.fullname}}
                      </option>
                  </select>
                  </div>
                </div>
                <div class="col-12 tblPrt">
                  <table class="table table-borderless table-responsive mt-2" *ngIf="regLogsData?.length != 0">
                    <thead>
                      <tr>
                        <!-- <th (click)="adminSorting('lid')" style="cursor: pointer">Log Id <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
                        <!-- <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->
                        <th (click)="adminSorting('createdname')" style="cursor: pointer">User <i
                            class=" fa fa-sort text-black" aria-hidden="true"></i>
                        </th>
                        <th>IP Address</th>
                        <th>Date Time</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of regLogsData">
                        <!-- <td>{{item.get_master_menu.menuid}}</td> -->
                        <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
                        <!-- <td>{{item.lid}}</td> -->
                        <td><a title="{{item.log_description}}" class="titleStyle">{{item.log_name}}</a></td>
                        <!-- <td>{{item.log_description}}</td> -->
                        <td>{{item.createdname}}</td>
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>


                        <td><span class="fa fa-eye ml-1" (click)="logsShows(item)"></span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-2 text-center difPagination" *ngIf="regLoginLogTotalCount > 10">
                    <ngb-pagination [collectionSize]="regLoginLogTotalCount" [(page)]="regLoginLogPage" [pageSize]="50" [maxSize]="2"
                      [rotate]="true" (pageChange)="getDsCurdLogs(regLoginLogPage=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <p class="text-center mt-1" *ngIf="regLoginLogTotalCount == 0">No Result Available</p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>
              <!--Registration Logs End-->
              <!--Frontend Ds Logs Start-->
              <ngb-tab id="website_ds_logs">
                <ng-template ngbTabTitle>
                  <p (click)="changeTable('website_ds_logs')">{{"Website DS Log"}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <div>
                    <div class="row">
                      <div class='col-sm-3 mt-3 ml-3 mb-3'>
                        <span class="logs-label">Select Date</span>
                            <input class="form-control icon d-input" type="text" ngxDaterangepickerMd [locale]="{
                              applyLabel: 'Okay',
                              format: 'YYYY-MM-DD',
                              displayFormat: 'MM/DD/YYYY',
                              firstDay: 1
                            }" [(ngModel)]="selected" [showCustomRangeLabel]="true" [ranges]="ranges"
                                [linkedCalendars]="true" [showClearButton]="false" [showCancel]="true"
                                [alwaysShowCalendars]="false" [keepCalendarOpeningWithRange]="false"
                                (datesUpdated)="onDateFilter($event)" placeholder="Start date - End date"
                                [timePicker]='false' />
                      </div>
                      <div class="col-sm-3 mt-3 ml-3 mb-3" *ngIf="customerID==0">
                        <span class="logs-label">Company Name <span style="color:red">*</span></span>
                        <select name="company" id="company" class="form-control formstyle"
                          (change)="getDsListByDateFilter($event)">
                          <option value="">Select Company</option>
                          <option *ngFor="let userObj of getCompanyobj"
                            value='{"id":"{{userObj.customer_id}}","company_name":"{{userObj.customer_company_name}}"}'>
                            {{userObj.customer_company_name}}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-3 mt-3 ml-3 mb-3">
                        <span class="logs-label">Direct Seller</span>
                        <select name="ds" [(ngModel)]="dsUserId" class="form-control formstyle" (change)='DslogsSkip=1'>
                        <option value = '' >Select DS</option>
                          <option *ngFor="let data of dsList" value={{data.userid}}>
                            {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}
                          </option>
                      </select>
                      </div>
                      <div class="col-sm-2 mt-5">
                        <button class="btn btn-margo-action ml-0 mt-0 form-control formstyle" (click)="getWebsiteDsLogsDetails(0)"
                          style="align-items: center;">Search</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 mt-1 ml-3 mb-2" *ngIf='selectedCompany'>
                        <span class="logs-label">Search Type</span>
                        <select name="search_with" class="form-control formstyle" [(ngModel)]='searchType'>
                          <option value='' >Select Type</option>
                          <option value='Name'>Name</option>
                          <option value='Email'>Email</option>
                          <option value='Log_Name'>Log Name</option>
                          <option value='Access_Url'>Access Url</option>
                          <option value='Subscription'>Subscription</option>
                          <option value='Ip_Address'>IP Address</option>
                      </select>
                      </div>
                      <div class="col-md-4 mt-1 ml-3 mb-2" [ngClass]="{'d-none':!searchType}">
                        <span class="logs-label"></span>
                        <div class="input-group" style="margin: 7px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text cyan lighten-2">
                            <i aria-hidden="true" class="fa fa-search text-white"></i>
                          </span>
                        </div>
                        <input class="form-control mt-0 srchWith" type="text" placeholder="Search" aria-label="Search" (keyup)="onTextSerach()" [(ngModel)]='searchText'>
                      </div>
                    </div>
                    </div>
                  </div>
                  <table class="table table-borderless table-responsive"
                    *ngIf="DsLogsData?.length != 0">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th class="text-nowrap">Created Date</th>
                        <th>User Detail</th>
                        <th>Log Name</th>
                        <th>Access Url</th>
                        <!-- <th>Subscription</th> -->
                        <th>IP Address</th>
                        <th>Operating System</th>
                        <th>Browser Info</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of DsLogsData;let i=index">
                        <td>{{i+1+(this.logsSkip - 1) * this.logsLimit}}</td>
                        <td>{{item.created_dt | date : 'd MMM, y, h:mm a'}}</td>
                        <td>
                          Name: {{item?.full_name || ''}} <br>
                          Email: {{item.email || ''}} <br>
                          Username: {{item?.username || ''}}<br>
                          Subscription: {{item?.title || ''}}<br>
                          Plan Type: {{item?.subscription_type || ''}}
                        </td>
                        <td>{{item.log_name}}</td>
                        <td>{{item.accessed_url}}</td>
                        <!-- <td>
                          Name: {{item?.title || ''}} <br>
                          Plan Type: {{item?.subscription_type || ''}}
                        </td> -->
                        <td>{{item.ipaddress}}</td>
                        <td>{{item.machine_info}}</td>
                        <td>{{item.browser_info}}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 text-center difPagination" *ngIf="DslogsNoOfRecords > 10">


                    <ngb-pagination [collectionSize]="DslogsNoOfRecords" [(page)]="DslogsSkip" [pageSize]="50" [maxSize]="2"


                      [rotate]="true" (pageChange)="getWebsiteDsLogsDetails(DslogsSkip=$event)">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                  <div *ngIf="DslogsNoOfRecords== 0" style="margin-left: 355px;">
                    <p><b>No Log created Yet....</b></p>
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <p>No Result Available</p>
                </ng-template>
              </ngb-tab>
              <!--Frontend Ds Logs End-->
            </ngb-tabset>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
