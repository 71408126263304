<div class="container mt-2">
  <h5 class="font-weight-bold mt-4 mb-4">Data Extension Mapping</h5>
  <form  class="form-horizontal mt-1" [formGroup]="dataextensionfieldform">
    <div class="row">
      <div class="col-md-10 col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="mt-1">

              <div class="row">
                <div class="col-3">
                  <label for="extension" class="position-relative pr-2">Extension ID/ Name <span style="color: red">*</span></label>
                </div>
                <div class="col-6">
                  <select formControlName="dataExtension" autocomplete="off" class="form-control formstyle "
                          (change)="selectDataExtensionFromDropdown($event.target.value, false, '')" [ngClass]="{ 'is-invalid': submitted && f.dataExtension.errors }">
                    <option value=""><-- Select Data Extension --></option>
                    <option *ngFor="let dt of mcDataExtension" value="{{dt.CustomerKey}}">{{dt.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.dataExtension.errors" class="invalid-feedback">
                    <div *ngIf="f.dataExtension.errors.required">Data Extension is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-3">
                  <label  class="position-relative pr-2">First Name <span style="color: red">*</span></label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_fname" autocomplete="off" class="form-control formstyle "
                           [ngClass]="{ 'is-invalid': submitted && f.contact_fname.errors }">
                    <option value=""><-- Select First Name --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_fname.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_fname.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>

<!--              <div class="row mt-2">-->
<!--                <div class="col-3">-->
<!--                  <label  class="position-relative pr-2">Middle Name <span style="color: red">*</span></label>-->
<!--                </div>-->
<!--                <div class="col-6">-->
<!--                  <select formControlName="contact_mname" autocomplete="off" class="form-control formstyle "-->
<!--                          [ngClass]="{ 'is-invalid': submitted && f.contact_mname.errors }">-->
<!--                    <option value=""><&#45;&#45; Select Middle Name &ndash;&gt;</option>-->
<!--                    <option *ngFor="let attr of selectedAttributes" value="{{attr.id}}">{{attr.name}}</option>-->
<!--                  </select>-->
<!--                  <div *ngIf="submitted && f.contact_mname.errors" class="invalid-feedback">-->
<!--                    <div *ngIf="f.contact_mname.errors.required">Middle Name is required</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Last Name <span style="color: red">*</span></label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_lname" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_lname.errors }">
                    <option value=""><-- Select Last Name --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_lname.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_lname.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Phone/ Mobile </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_phone" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_phone.errors }">
                    <option value=""><-- Select Phone --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_phone.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone.errors.required">Phone No. is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Other Phone/ Mobile </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_phone_other" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_phone_other.errors }">
                    <option value=""><-- Select Other Phone --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_phone_other.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone_other.errors.required">Other Phone is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Phone Type</label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_phone_type" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_phone_type.errors }">
                    <option value=""><-- Select Phone Type--></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_phone_type.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone_type.errors.required">Contact Phone is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Email <span style="color: red">*</span></label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_email" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_email.errors }">
                    <option value=""><-- Select Email Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_email.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_email.errors.required">Email is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Email 1 </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_email1" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_email1.errors }">
                    <option value=""><-- Select Email 1 Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_email1.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_email1.errors.required">Email 1 is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Email 2 </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_email2" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_email2.errors }">
                    <option value=""><-- Select Email 2 Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_email2.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_email2.errors.required">Email 2 is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Birthdate </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_birthdate" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_birthdate.errors }">
                    <option value=""><-- Select Birthdate Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_birthdate.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_birthdate.errors.required">Birthdate is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Age Range </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_age_range" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_age_range.errors }">
                    <option value=""><-- Select Age Range Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_age_range.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_age_range.errors.required">Age Range is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Sex </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_sex" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_sex.errors }">
                    <option value=""><-- Select Sex Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_sex.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_sex.errors.required">Contact Sex is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Loyalty Type </label>
                </div>
                <div class="col-6">
                  <select formControlName="loyalty_type" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.loyalty_type.errors }">
                    <option value=""><-- Loyalty Type Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.loyalty_type.errors" class="invalid-feedback">
                    <div *ngIf="f.loyalty_type.errors.required">Loyalty Type is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Address </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_address" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_address.errors }">
                    <option value=""><--Select Contact Address Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_address.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_address.errors.required">Contact Address is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Contact Address 2 </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_address_2" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_address_2.errors }">
                    <option value=""><--Select Contact Address 2 Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_address_2.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_address_2.errors.required">Contact Address 2 is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Country </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_country" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_country.errors }">
                    <option value=""><-- Select Country Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_country.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_country.errors.required">Country is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label class="position-relative pr-2">State </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_state" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_state.errors }">
                    <option value=""><-- Select State Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_state.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_state.errors.required">State is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">City </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_city" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_city.errors }">
                    <option value=""><-- Select City Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_city.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_city.errors.required">City is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Zipcode </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_zip" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_zip.errors }">
                    <option value=""><-- Select Zipcode Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_zip.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_zip.errors.required">Zip is required</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <label  class="position-relative pr-2">Region </label>
                </div>
                <div class="col-6">
                  <select formControlName="contact_region" autocomplete="off" class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.contact_region.errors }">
                    <option value=""><-- Select Region Field --></option>
                    <option *ngFor="let attr of selectedAttributes" value="{{attr.ObjectID}}">{{attr.Name}}</option>
                  </select>
                  <div *ngIf="submitted && f.contact_region.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_region.errors.required">Region is required</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="card-footer text-center mt-1" >
            <div class="my_class text-center" *ngIf="isWrite" >
              <button type="submit" class="custbtn"  (click)="submit()" >Submit</button>
              <button type="submit" class="custbtn"  (click)="cancelUpdateMapping()" *ngIf="mcDataExtension.length > 0">Close</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
