import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import csc from 'country-state-city';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-newusercontact',
  templateUrl: './newusercontact.component.html',
  styleUrls: ['./newusercontact.component.css']
})
export class NewusercontactComponent extends BaseComponent implements OnInit {

  getTypeUserobj;
  getCountryobj;
  getCompanyobj;
  getRoleObj;
  uid;
  isactive
  selectedid;
  selectedname;
  select_role_name;
  header = "Add New"
  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }
  status: boolean = true;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  userProfileDetails;

  userObjId;
  summaries;
  countriesData;
  stateData;
  cityData;
  ngOnInit() {
    this.ngxSpinnerService.show();
    this.countriesData = csc.getAllCountries();
    this.getUserProfileDetails();
    this.getTypeOfUsers();
    this.getCountry();
    this.getCompany();
    //this.getRole();
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.uid = params.uid;
      });

    //get item details using id
    if (self.uid) {
      this.header = "Edit"
      this.status = false;
      this.registerService.getUserById(this.uid).subscribe(response => {
        if (response.status) {

          self.userObjId = response.data.get_admin_user_details_by_id;

          self.dataBinding();
        } else {
        }
      }, error => {
       // console.log(error);
      });


    }
    if (self.uid) {
      this.UpdateUserLogData()
    } else {
      this.addUserLogData()
    }
    this.registerForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: [],
      pemail: ['', [Validators.required, Validators.email]],
      semail: ['', Validators.email],
      hphone: [],
      mphone: ['', [Validators.required, Validators.minLength(12)]],
      // birthdate: ['', Validators.required],
      // userrole: ['', Validators.required],
      // title: ['', Validators.required],
      // address1: ['', Validators.required],
      // address2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],

      zip: ['', [Validators.required, Validators.pattern(AppConstants.regex.number),
        Validators.minLength(4), Validators.maxLength(10)]],

      region: [],
      country: ['', Validators.required],
      // fb: [],
      // twitter: [],
      // linkedin: [],
      // instagram: [],
      // ibiz: [],
      // youtube: [],
      // gbiz: [],
      // sinawiebo: [],
      // wechat: [],
      // whatsapp: [],
      // marital: ['', Validators.required],
      company: ['', Validators.required],
      //usertype: [1],
      roleid: ['', Validators.required]
    });
  }

  formChange() {
    if (this.userProfileDetails.company_name == 'margo') {
      this.registerForm.get('company').setValidators([Validators.required]);
    } else {
      this.registerForm.get('company').clearValidators();
    }
    this.registerForm.get('company').updateValueAndValidity();
  }





  states(id) {
    // console.log("State",csc.getStatesOfCountry(id));
    this.f.state.setValue('');
    this.f.city.setValue('');
    this.stateData = [];
    this.cityData = [];
    if (id === '') {
      this.stateData = csc.getStatesOfCountry(id);
    } else {
      let ct = this.countriesData.filter(elem => elem.sortname === id);
    this.stateData = csc.getStatesOfCountry(ct[0].id);
    }
  }

  getCities(id) {
    // console.log("cities",csc.getCitiesOfState(id));
    this.f.city.setValue('');
    this.cityData = [];
    if (id === '') {
      this.cityData = csc.getCitiesOfState(id);
    } else {
      let st = this.stateData.filter(elem => elem.name === id);
    this.cityData = csc.getCitiesOfState(st[0].id);
    }
  }

  addUserLogData() {

    let UserLogs = {
      log_type: "Adding New User",
      log_name: "Adding New User",
      log_description: "Adding New User",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(UserLogs).subscribe(data => {
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  UpdateUserLogData() {

    let UserLogs = {
      log_type: "Updating User Data",
      log_name: "Updating User Data",
      log_description: "Updating User Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }

    this.logs.addNewLogs(UserLogs).subscribe(data => {
      this.ngxSpinnerService.hide();

    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  getUserProfileDetails() {
    let self = this;
    self.registerService.getUserProfileDetails().subscribe(data => {
      if (data.status) {
        //self.status = true;
        self.userProfileDetails = data.data;
        this.getRole();
        this.formChange();
      }
    }, error => {

    });
  }

  addNewUser() {
    let self = this, company_id = 0;
    this.submitted = true;

    if (self.userProfileDetails.admin_customer_id === 0) {
      company_id = this.f.company.value;
    } else {
      company_id = self.userProfileDetails.admin_customer_id;
    }
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    let addUser = {
      fname: this.f.fname.value,
      lname: this.f.lname.value || "",
      //username: this.f.pemail.value,
      email: this.f.pemail.value,
      email_primary: this.f.pemail.value,
      email_secondary: this.f.semail.value || "",
      home_phone: this.f.hphone.value || "",
      mobile_phone: this.f.mphone.value,
      // birthdate: this.f.birthdate.value,
      // marid: this.f.marital.value,
      // title: this.f.title.value,
      // sexid: this.f.sex.value,
      // address1: this.f.address1.value,
      // address2: this.f.address2.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zipcode: this.f.zip.value || "",
      region: this.f.region.value || "",
      country: this.f.country.value,
      // facebook_id: this.f.fb.value,
      // twitter_id: this.f.twitter.value,
      // linkedin_id: this.f.linkedin.value,
      // instagram_id: this.f.instagram.value,
      // instagram_biz_id: this.f.ibiz.value,
      // youtube_id: this.f.youtube.value,
      // google_my_biz_id: this.f.gbiz.value,
      // sina_wiebo_id: this.f.sinawiebo.value,
      // we_chat_id: this.f.wechat.value,
      // whatsapp_id: this.f.whatsapp.value,
      roleid: this.f.roleid.value,
      company: company_id, // this.f.company.value,
      typeid: 1 //this.f.usertype.value,
    }
    //console.log(addUser);
    this.ngxSpinnerService.show();
    this.registerService.createUser(addUser).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Please Verify Your E-mail To Activate Your Account");
        this.router.navigateByUrl(`/corporate/users`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  dataBinding() {
    let self = this;
  //  console.log('My Data', this.userObjId);
    this.states(this.userObjId.country);
    this.getCities(this.userObjId.state);
    this.selectedname = this.userObjId.role_name;
    self.registerForm = self.formBuilder.group({
      fname: [this.userObjId.admin_fname, Validators.required],
      lname: [this.userObjId.admin_lname],
      pemail: [this.userObjId.email_primary, [Validators.required, Validators.email]],
      semail: [this.userObjId.email_secondary, Validators.email],
      hphone: [this.userObjId.home_phone],
      mphone: [this.userObjId.mobile_phone, [Validators.required, Validators.minLength(12)]],
      // birthdate: ['', Validators.required],
      // userrole: [this.userObjId.role_id, Validators.required],
      // title: ['', Validators.required],
      // address1: ['', Validators.required],
      // address2: ['', Validators.required],
      city: [this.userObjId.city, Validators.required],
      state: [this.userObjId.state, Validators.required],
      zip: [this.userObjId.zipcode, [Validators.required, Validators.pattern(AppConstants.regex.number),
        Validators.minLength(5), Validators.maxLength(12)]],
      region: [this.userObjId.region],
      country: [this.userObjId.country, Validators.required],
      // fb: [],
      // twitter: [],
      // linkedin: [],
      // instagram: [],
      // ibiz: [],
      // youtube: [],
      // gbiz: [],
      // sinawiebo: [],
      // wechat: [],
      // whatsapp: [],
      // marital: ['', Validators.required],
      company: [this.userObjId.customer_id, Validators.required],
      // usertype: [this.userObjId.admin_type_id, Validators.required],
      roleid: [this.userObjId.role_id, Validators.required]
    });
  }

  updateUserDetails() {
    let self = this, company_id = 0;
    this.submitted = true;

    if (self.userProfileDetails.customer_id === 0) {
      company_id = this.f.company.value;
    } else {
      company_id = self.userProfileDetails.customer_id;
    }
  //  console.log(this.registerForm);

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // this.submitted = true;
   // console.log(this.registerForm.invalid);

    // stop here if form is invalid
    //  if (this.registerForm.invalid) {
    //   return;
    // }

    let userObject = {
      fname: this.f.fname.value,
      lname: this.f.lname.value,
      email: this.f.pemail.value,
      email_primary: this.f.pemail.value,
      email_secondary: this.f.semail.value || "",
      home_phone: this.f.hphone.value || "",
      mobile_phone: this.f.mphone.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zipcode: this.f.zip.value || "",
      region: this.f.region.value || "",
      country: this.f.country.value,
      customerid: this.f.company.value,
      roleid: this.f.roleid.value,
      typeid:1, // this.f.usertype.value,
      id: Number.parseInt(this.uid, 10),
      isactive: this.userObjId.isactive
    };
   // console.log(userObject);
    this.ngxSpinnerService.show();
    this.registerService.updateUser(userObject).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully");
        this.router.navigateByUrl(`/corporate/users`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  getTypeOfUsers() {
    let self = this;
    this.registerService.getType().subscribe(data => {
      self.getTypeUserobj = data.data;
    }, error => {
     // console.log(error);
    });
  }

  getCountry() {
    let self = this;
    this.registerService.getCountryData().subscribe(data => {
      self.getCountryobj = data.data;
    }, error => {
     // console.log(error);
    });
  }

  getCompany() {
    let self = this;
    this.registerService.getCompanyData().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
    });
  }
  // getRole() {
  //   let self = this;
  //   this.registerService.getRoleData().subscribe(data => {
  //     self.getRoleObj = data.data;
  //     console.log(self.getRoleObj)
  //   }, error => {
  //     console.log(error);
  //   });
  // }
  onEvent(event) {
    this.selectedid = event.node.data.id;
    this.selectedname = event.node.data.name;
   // console.log(this.selectedid, this.selectedname)
  }

  getRole() {
    let self = this;
    this.registerService.getRoleData().subscribe(data => {
      var getRoleObj = data.data;
      console.log('ROLE OBJ', getRoleObj);
      var obj = [];
      let superobj = {
        id: 0,
        name: "Role",
        children: []
      }
      for (let index = 0; index < getRoleObj.length; index++) {
        const element = getRoleObj[index];
        if (element.get_master_role_parent_id_fun.level === 0 &&
          element.get_master_role_parent_id_fun.role_parent === 0) {

          var child = {
            id: element.get_master_role_parent_id_fun.role_id,
            name: element.get_master_role_parent_id_fun.role_name
          }
          //element.children = element.children == undefined ? [] : element.children;
          superobj.children.push(child);
        }
      }
      obj.push(superobj);
      getRoleObj.forEach(element => {
        element = element.get_master_role_parent_id_fun;
        var each = {
          id: element.role_id,
          name: element.role_name,
          children: []
        };
        if (element.level == 0) {
          for (var i = 0; i < getRoleObj.length; i++) {
            if (getRoleObj[i].get_master_role_parent_id_fun.level == 1 &&
              getRoleObj[i].get_master_role_parent_id_fun.role_parent == element.role_id) {

              var child = {
                id: getRoleObj[i].get_master_role_parent_id_fun.role_id,
                name: getRoleObj[i].get_master_role_parent_id_fun.role_name
              }
              //element.children = element.children == undefined ? [] : element.children;
              each.children.push(child);
            }
          }
          obj.push(each);
        }
      //  console.log(obj);
      });

      obj.shift();
      if (this.userProfileDetails.role_id === 86) {
        obj = obj.filter(elem => elem.name === "Client HQ");
        let chd = obj[0].children;
        let chfn = chd.filter (elem => elem.name !== 'Superadmin');
        obj[0].children = chfn;
      }
      if (this.userProfileDetails.role_id === 78) {
        obj.filter(elem => elem.name === "Client BU");
        obj = obj.filter(elem => elem.name === "Client BU");
        let chd = obj[0].children;
        let chfn = chd.filter (elem => elem.name !== 'Superadmin');
        obj[0].children = chfn;
      }
      self.getRoleObj = obj;
    });
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }
}




