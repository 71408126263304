import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.css']
})
export class CustomerInvoiceComponent  implements OnInit {
  companyID=0;
  dateArr: string[];
  companyId;
  pendingrecord:number=0;
  currentUser;
  company_status:boolean=false;
  cpdata={customer_id:null};
  getCompanyobj;
  customerID;
  DsData;
  obj;
  reportData;
  p: number = 1;
  total: number;
  status="select";
  start_date;
  end_date;
  clicked=false;
  total_contacts;    
  Consumetotal;
  total_used;
  user_type=true;
  report_status=60;
  total_ds=0;
  total_ds_active=0;
  total_ds_inactive=0;
  tot_ds_add=0; 
  tot_ds_active=0;

  maxdate = new Date();
  showResult:boolean=true;
  isHeadquater;


  constructor(private report:ReportService,
    private clientService : ClientService,
    public toastrService: ToastrService,
    private spinner : NgxSpinnerService) { }

  ngOnInit() {    
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;  
    this.companyID= this.currentUser.admin_customer_id;  
      
    var my_date = new Date();
    this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());
 
    this.isHeadquater=localStorage.getItem("customer_isheadquarter");
    if(this.companyID==0 || this.isHeadquater ==="true"){
      this.getCompany(this.companyID);
      this.companyID = 0;
      this.showResult=false;
      this.isHeadquater = true;
     // console.log("ddddd...", this.isHeadquater)
      //this.addrecords(3);
    //this.spinner.hide();
    }else{   
      this.showResult=true;   
      this.isHeadquater = false;     
      this.gettingReports(1,this.companyID);
  
  }

  
 }

  getFirstLastDateOfMonth(month, year) {
    var my_date = new Date(year, month, 1);
    var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    let formatted_first_date = first_date.getFullYear() + "-" + (first_date.getMonth() + 1) + "-" + first_date.getDate();

    var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    let formatted_last_date = last_date.getFullYear() + "-" + (last_date.getMonth() + 1) + "-" + last_date.getDate();
  //  console.log(formatted_first_date, formatted_last_date);
    return ([formatted_first_date, formatted_last_date]);
  }

 DS_list(id,start_date,end_date){
  var body={customer_id:id,date1:start_date,date2:end_date};
  this.report.DS_list(body).subscribe((data)=>{
    // console.log(data);
    if(data.status)
    { 
      this.clicked=true;      
      this.DsData= data.data;
    }  
  this.spinner.hide();
  });
}

tot_ds_list(id,start_date,end_date){
  var body={customer_id:id,date1:start_date,date2:end_date};
 this.report.select_ds_total(body).subscribe((data)=>{
  if(data.status)
  { 
    this.clicked=true;      
    this.total_ds= data.data;
  }  
 this.spinner.hide();
});}

tot_ds_active_list(id,start_date,end_date){
  var body={customer_id:id,date1:start_date,date2:end_date};
  this.report.select_ds_total_active(body).subscribe((data)=>{
  if(data.status)
  { 
    this.clicked=true;      
    this.total_ds_active= data.data;
  }  
 this.spinner.hide();
});}

tot_ds_inactive_list(id,start_date,end_date){
  var body={customer_id:id,date1:start_date,date2:end_date};
 this.report.select_ds_total_inactive(body).subscribe((data)=>{
  if(data.status)
  { 
    this.clicked=true;      
    this.total_ds_inactive= data.data;
  }  
 this.spinner.hide();
});}
   

  gettingReports(page,id) {

    this.companyID =id;
    if(this.companyID==0) {
      this.toastrService.error("Please select company");
      return false;
    }
    const parmas = {
      customer_id: this.companyID,
      page: page,    
      start_date:this.start_date,
      end_date:this.end_date,
      user_type: this.user_type      
    };
    //var dt1 =split()
    if(this.start_date>this.end_date){
    setTimeout(() => {
      this.toastrService.error("Please select fromdate less than todate");
    }, 1000);
    return false;
    }
    
      this.showResult=true;
      this.DS_list(this.companyID,this.start_date,this.end_date);
      this.tot_ds_list(this.companyID,this.start_date,this.end_date);
      this.tot_ds_active_list(this.companyID,this.start_date,this.end_date);
      this.tot_ds_inactive_list(this.companyID,this.start_date,this.end_date);
    
  }
  
  getCompany(companyId:any) {    
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;

    }, error => {
    // console.log(error);
    });
  }

  resetReport(){
    this.start_date=null;
    this.end_date =null;
    var my_date = new Date();
    this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());

    this.companyID =0;
    this.showResult=false;
   // this.getCompany(this.companyId);
   // this.reportData(1,this.companyID);
  }
}