import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-countrymaster',
  templateUrl: './countrymaster.component.html',
  styleUrls: ['./countrymaster.component.css']
})
export class CountrymasterComponent extends BaseComponent implements OnInit {
  p = 1;
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();
  public countryObj: any = [];
  constructor(
    private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
   
    ) { 
      super();
    }
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
    this.getCountry()
    this.CountryLogData()
  }
  CountryLogData() {
    let countryLogs = {
      log_type:"Country Master View" ,
      log_name:"Country Master View" ,
      log_description:"Country Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(countryLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
      //console.log(error);
    });
  
}
  getCountry() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCountry().subscribe(data => {
      self.countryObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  deleteCountry(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delCountry(obj).subscribe(data => {
            if (data.status) {
              this.getCountry();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
            //console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  editCountry(id) {
    this.router.navigate(['/corporate/addnewcountry'], { queryParams: { country_id: id } });
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.countryObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.get_master_country.country_id === id) {
        return true;
      }
    });
    obj = obj.get_master_country;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.updateCountry(obj).subscribe(data => {
            this.getCountry()
            this.toastrService.success("Country Master Status Changed Successfully");
            this.ngxSpinnerService.hide();
          });
        }
        // else {
        //   this.master.updateCountry(obj).subscribe(data => {
        //     this.getCountry()
        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //   });
        // }
      }).catch(() => {
        //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    this.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-country-import.csv`, 'addingCountryBulk')
      .then(data => {
       // console.log(data);
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_country_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    })
  }
}