import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-support-ticket-view',
  templateUrl: './support-ticket-view.component.html',
  styleUrls: ['./support-ticket-view.component.css']
})
export class SupportTicketViewComponent extends BaseComponent implements OnInit {

  supportTicketId:any=0;
  companyId=0;
  dsId=0;
  ticketDetails:any = [];

  constructor(
    private masterService:MasterService
  ) { 
    super();
  }

  ngOnInit(): void {
  this.activatedRoute.queryParams.subscribe((param:any)=>{
    this.companyId = param.companyid;
    this.dsId = param.dsid;
    this.supportTicketId = param?.ticketid;
  });
  this.getSupportTicketData();

  }
  getSupportTicketData(){
    this.ngxSpinnerService.show();
    this.masterService.getSupportTicketDetails(this.companyId,this.dsId,this.supportTicketId).subscribe({
      next:(res:any)=>{
        this.ticketDetails = res.data.rows[0];      
        this.ngxSpinnerService.hide();  
      },
      error:(error:any)=>{
        console.error(error);
        this.ngxSpinnerService.hide();  
      }
    })
  }
  dateFormat(date) {
    if (date) {
      return moment(date).fromNow();
    }
  }
}
