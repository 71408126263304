

<style type="text/css">
  .error-main{
  background-color: #fff;
  box-shadow: 0px 10px 10px -10px #5D6572;
}
.error-main h1{
  font-weight: bold;
  color: #444444;
  font-size: 100px;
  text-shadow: 2px 4px 5px #6E6E6E;
}
.error-main h6{
  color: #42494F;
}
.error-main p{
  color: #9897A0;
  font-size: 14px; 
}  
@media only screen and (max-width: 640px) {
    .error-main h1 {
    font-size: 50px;}
}
</style>
<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center text-center">
      <div class="col-lg-6 col-sm-8 offset-lg-4 col-9 p-3 error-main">
        <div class="row">
          <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
  
            <div class="error-details">
              <p>Oops... looks like you're not authorised to view this page!</p>
            </div>
            <div class="error-actions-btn">
              <div class="login-right-head">
                  <a routerLink="/corporate/home"><i class="la la-arrow-left"></i> Please take me to the homepage</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



