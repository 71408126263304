<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/sender-profile">Sender Profile</a></li>
      <li class="breadcrumb-item active" aria-current="page">Sender Profile List</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getProfileList()" [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText />
      </div>
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Directseller Name</th>
        <th>Email</th>
        <th>Username</th>
        <th>Sender Email</th>
        <th>Domain</th>
        <th>Status</th>
        <th>Actions</th>

      </tr>
      <tr *ngFor="let obj of profileObj">
        <td>{{ obj?.user_fname }} {{ obj?.user_lname }}</td>
        <td>{{ obj?.user_email_primary }}</td>
        <td>{{ obj?.username }}</td>
        <td>{{ obj?.senderemail }}</td>
        <td>{{ obj?.emaildomain }}</td>
        <td>{{ obj?.status | titlecase }}</td>
        <td>
          <div class="actBtn">
            <i (click)="gotoview(obj.id)" class="fa fa-eye mr-2 linkForce"></i>
            <i *ngIf='isEdit' (click)="gotoedit(obj.id)" class="fa fa-edit mr-1 linkForce"></i>
           
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="profileTotal == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="profileTotal > 10">
      <ngb-pagination [collectionSize]="profileTotal" [(page)]="slCatPage" [maxSize]="2" [rotate]="true"
        (pageChange)="getProfileList(slCatPage=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>