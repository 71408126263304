import { Component, OnInit } from "@angular/core";
import { ClientService } from "src/app/services/clients/client.service";
import { BaseComponent } from "../base.component";
import { CbService } from "src/app/services/contentbuilder/contentbuilder.service";
import { ScheduleJourneyService } from "src/app/services/schedulejourney/schedule-journey.service";



@Component({
    selector: 'app-schedule-journey-list',
    templateUrl: './schedule-journey-list.component.html',
    styleUrls: ['./schedule-journey-list.component.css']
})
export class ScheduleJourneyListComponent extends BaseComponent implements OnInit {


    companyID = 0;
    getCompanyobj: any;
    journeyTypeObj = [];
    journeyList = [];
    limit = 20;
    offset = 0;
    totalCount = 0;
    page = 1;
    jid = '';
    constructor(private clientService: ClientService, private cb: CbService,
                private scheduleJourneyService: ScheduleJourneyService) {
        super();
    }

    ngOnInit(): void {
        this.getCompany();
    }

    getCompany() {
        let self = this;
        this.clientService.getBUCompanies().subscribe(data => {
          self.getCompanyobj = data.data;
          console.log(self.getCompanyobj);
        }, error => {
          console.log(error);
        });
      }

    onCompanyChange(id) {
        this.journeyTypeObj = [];
        this.journeyList = [];
        this.totalCount = 0;
        this.offset = 0;
        this.page = 1;
        if(id) {
            let self = this;
            this.ngxSpinnerService.show();  
            this.cb.getCBJourneyType(id).subscribe(data => {
              if( data.data) {
              self.journeyTypeObj = data.data;
              this.ngxSpinnerService.hide();
              }
              else {
                 self.journeyTypeObj = []; 
                 this.ngxSpinnerService.hide(); }
            }, error => {
              console.log(error);
              this.ngxSpinnerService.hide();
            });
          }
          else {
            this.journeyTypeObj=[];
            this.ngxSpinnerService.hide();
          }
    }

  onJourneySelect(id) {
    this.journeyList = [];
    this.totalCount = 0;
    this.offset = 0;
    this.page = 1;
    if (!id) return;
    this.getJourneyData(id);
  }

  getJourneyData(id) {
    this.ngxSpinnerService.show();
    this.scheduleJourneyService.getJourneyListing(this.companyID, id, this.limit, this.limit*this.offset).subscribe((data: any) => {
      if (data.status && data?.data?.rows) {
        this.journeyList = data.data.rows;
        console.log('JOURDATA', this.journeyList);
        this.totalCount = data.data.total;
        this.ngxSpinnerService.hide();
      }
      else {

        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getPageChange(p) {
   this.page = p;
   this.offset = this.page - 1;
   this.getJourneyData(this.jid);
  }
}