<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/content-builder-field-property">Content Builder Attribute</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Content Builder Attribute</li>

        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="templateForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
    
              <div class="card mt-1">
    
                <div class="card-body">
                <div class="form-group">
                    <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                    <select formControlName="company"  *ngIf="headers == 'Add'" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                        <option value="">Select Company Name</option>
                        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                            {{userObj.customer_company_name}}
                        </option>
                    </select>

                    <select formControlName="company" class="form-control formstyle" *ngIf="headers == 'Edit'" disabled="controlEnabled"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <option value="">Select Company Name</option>
                    <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                </select>
                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                        <div *ngIf="f.company.errors.required">Company Name is required</div>
                    </div>
                </div>
              


                <div class="form-group">
                    <label for="exampleForm2">Name <span style="color:red">*</span></label>
                    <input type="text" min="1" maxlength="199" formControlName="attribute_name" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.attribute_name.errors }" required appSpace>
                    <div *ngIf="submitted && f.attribute_name.errors" class="invalid-feedback">
                        <div *ngIf="f.attribute_name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleForm2">Description</label>

                    <textarea  class="form-control" min="1" maxlength="199" formControlName="attribute_description" class="form-control" appSpace> </textarea>
                   
                </div>
                <div class="form-group">
                    <label for="exampleForm2">Select type <span style="color:red">*</span></label>
                    <select formControlName="attribute_type" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.attribute_type.errors }" (change)="displayItemList($event.target.value)">
                        <option value="">Select Attribute Type</option>
                        <option  value="text">Text</option>
                        <option  value="textarea">Textarea</option>
                        <option  value="image">Image</option>
                        <option  value="color">Color</option>
                        <option  value="dropdown">Dropdown</option>
                       
                    </select>
                    <div *ngIf="submitted && f.attribute_type.errors" class="invalid-feedback">
                        <div *ngIf="f.attribute_type.errors.required">Type is required</div>
                    </div>
                </div>

                <div class="form-group" *ngIf="itemListDisplay">
                    <label for="exampleForm2">Item List</label>

                    <textarea  class="form-control" formControlName="listitem" style="min-height: 100px;"></textarea>
                    <label>Please enter item with comma separate</label>
                </div>

                <div class="form-group">
                    <label for="exampleForm2">Is Field Required?</label>
                  
                <div class="custom-control custom-radio mb-1">
                    <input type="radio"  [value]="true"  name="isrequire" formControlName="isrequire"   class="custom-control-input bg-default"
                     id="opt-default">
                    <label class="custom-control-label" 
                      for="opt-default">Yes</label>
                  </div>
                  <div class="custom-control custom-radio mb-1">
                    <input type="radio"  [value]="false" name="isrequire" formControlName="isrequire" class="custom-control-input bg-primary"
                     id="opt-primary">
                    <label class="custom-control-label" 
                      for="opt-primary">No</label>
                  </div>
               </div>
                
            </div>

            <div class="card-footer text-center">
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="submit" class="custbtn" *ngIf="isWrite" (click)="addAttribute()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateAttribute()">Update</button>
                </div>
              </div>
        </div>
    </div>
</div>
    </form>
</div>