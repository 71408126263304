import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { CampaignBuilderService } from 'src/app/services/segmentation/campaign-builder.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-newsetting',
  templateUrl: './newsetting.component.html',
  styleUrls: ['./newsetting.component.css']
})
export class NewSettingComponent extends BaseComponent implements OnInit {
  IsActive;
  campaignSettingId;
  campaignSettingObj;
  getCompanyobj;
  headers = "Add";
  companyID;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: FormGroup;
  status: boolean = true;
  campaignType: any;
  timeSlots = [
    {time: '01:00'},{time: '01:30'},{time: '02:00'},{time: '02:30'},{time: '03:00'},{time: '03:30'},{time: '04:00'},{time: '04:30'},{time: '05:00'},{time: '05:30'},{time: '06:00'},{time: '06:30'},{time: '07:00'},{time: '07:30'},{time: '08:00'},{time: '08:30'},{time: '09:00'},{time: '09:30'},{time: '10:00'},{time: '10:30'},
    {time: '11:00'},{time: '11:30'},{time: '12:00'},{time: '12:30'},{time: '13:00'},{time: '13:30'},{time: '14:00'},{time: '14:30'},{time: '15:00'},{time: '15:30'},{time: '16:00'},{time: '16:30'},{time: '17:00'},{time: '17:30'},{time: '18:00'},{time: '18:30'},{time: '19:00'},{time: '19:30'},{time: '20:00'},{time: '20:30'},
    {time: '21:00'},{time: '21:30'},{time: '22:00'},{time: '22:30'},{time: '23:00'},{time: '23:30'},{time: '00:00'},{time: '00:30'}
    
  ];
  themeObj = [];
  constructor(
    private master: MasterService,
    private campaignBuilderService: CampaignBuilderService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.campaignSettingId = params.id;
        this.companyID = params.companyID;
        this.campaignType = params.type;
      });

    // get theme list
    this.getCompanyThemes(this.companyID);


    if (this.campaignSettingId) {
      this.UpdateCampaignSettingLogData()
    }
    else {
      this.addCampaignSettingLogData()
    }


    if (this.campaignSettingId) {
      this.headers = "Edit";
      
      this.campaignBuilderService.getBUCampaignSettingById(this.companyID, this.campaignSettingId).subscribe(response => {

        // console.log("response", response);
       
        if (response.status) {
          this.status = false;
          self.campaignSettingObj = response.data[0];
          console.log("--self.themObj--", self.campaignSettingObj);
          self.dataBinding();
        } else {
        }
        this.ngxSpinnerService.hide();
      });
    }

    // console.log("this.campaignType", this.campaignType);
    this.themeForm = this.formBuilder.group({
      company: [this.companyID, Validators.required],
      marketshowId: [],
      campaign_type: [this.campaignType, Validators.required],
      theme: [''],
      day: [''],
      time: [''],
      automationCampaign: [true],
      automationAudience: [true],
      status: [true],
    });



  }
  get f() { return this.themeForm.controls; }

  addNew() {
    this.submitted = true;

    if (this.themeForm.invalid) {
      console.log("invalid form");
      return;
    }

    let { company, marketshowId, campaign_type, theme, day, time, automationCampaign, automationAudience } = this.themeForm.value;

    let Obj = {
      company: company,
      marketshowId: marketshowId,
      campaign_type: campaign_type,
      theme: theme,
      day: day,
      time: time,
      automationCampaign: automationCampaign,
      automationAudience: automationAudience,
    }


    this.ngxSpinnerService.show();
    this.campaignBuilderService.addingBUCampaignSetting(Obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Setting Added Successfully")
        this.router.navigateByUrl(`/corporate/cross-sell-and-replenishment-setting`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  addCampaignSettingLogData() {
    let themeLogs = {
      log_type: "Adding New Campaign setting",
      log_name: "Adding New Campaign setting",
      log_description: "Adding New Campaign setting",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    }, error => {
    });
  }

  UpdateCampaignSettingLogData() {
    let themeLogs = {
      log_type: "Updating Campaign Setting Data",
      log_name: "Updating Campaign Setting Data",
      log_description: "Updating Campaign Setting Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 1
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      // console.log(error);
    });
  }


  dataBinding() {
    this.themeForm = this.formBuilder.group({
      company: [this.companyID, Validators.required],
      campaign_type: [this.campaignSettingObj.campaign_type],
      marketshowId: [this.campaignSettingObj.marketshow_id],
      theme: [this.campaignSettingObj.theme],
      day: [this.campaignSettingObj.day],
      time: [this.campaignSettingObj.time],
      automationCampaign: [this.campaignSettingObj.automation_campaign],
      automationAudience: [this.campaignSettingObj.automation_audience],
      status:[this.campaignSettingObj.isactive],
    });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
  update() {
    this.submitted = true;
    let { company, marketshowId, campaign_type, theme, day, time, automationCampaign, automationAudience, status } = this.themeForm.value;

    let Obj = {
      id: this.campaignSettingId,
      company: company,
      campaign_type: campaign_type,
      marketshowId: marketshowId,
      theme: theme,
      day: day,
      time: time,
      automationCampaign: automationCampaign,
      automationAudience: automationAudience,
      status:status,
    }

    // return false;
    // console.log("--update obj--", Obj);
    this.ngxSpinnerService.show();
    this.campaignBuilderService.updateBUCampaignSetting(Obj).subscribe(data => {
      if (data.status) {
        console.log("update success", data);

        this.router.navigateByUrl(`/corporate/cross-sell-and-replenishment-setting`);
        this.ngxSpinnerService.hide();
        this.toastrService.success("Record update Successfully")
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  getCompanyThemes(id){
    let self = this;
    this.master.getCompanyTheme(id).subscribe(data => {
      self.themeObj = data.data.rows;
      console.log("--themeObj--", self.themeObj);
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
    
  }

  cancelBtb() {
    this.router.navigateByUrl(`/corporate/cross-sell-and-replenishment-setting`);
  }



}
