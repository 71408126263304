import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { SegmentationService } from '../../../../services/segmentation/segmentation.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-bu-table',
  templateUrl: './bu-table.component.html',
  styleUrls: ['./bu-table.component.css']
})


export class BuTableComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  tableObj: any = [];
  totalCount: number = 0;
  ord = 'desc';
  colname = "createdon";
  limit = 10;
  offset = 0;
  page = 1;

  today: number = Date.now();

  constructor(private segmentService: SegmentationService,private clientService: ClientService) {
    super();
  }

  ngOnInit() {
    localStorage.removeItem("deleteTableId")
    this.getCompany();
    this.getTable('init');
    this.logdata();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.segmentService.getTable(this.company,this.limit, this.offset, this.colname, this.ord, this.searchText.nativeElement.value))
      ).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.tableObj = [] }
          else { this.tableObj = data.data.rows }
          this.totalCount = data.data.total;
        } else {
          this.tableObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  logdata() {
    let themeLogs = {
      log_type: "table Segementation View",
      log_name: "table Segementation View",
      log_description: "table Segementation View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      //  console.log(error);
    });
  }

  getTable(flag) {
    if(flag == 'compChange' || flag == 'delete' ){this.page = 1;this.searchText.nativeElement.value = ''};
    let searchTerm = this.searchText.nativeElement.value;
    this.offset = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.segmentService.getTable(this.company,this.limit, this.offset, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.tableObj = [] }
        else { this.tableObj = data.data.rows }
        this.totalCount = data.data.total;
      } else {
        this.tableObj = [];
        this.totalCount = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();

    });
  }

  sorting(col) {
    this.colname = col;
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getTable('sort');
  }

  deleteTable(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.segmentService.deleteTable(this.company,id).subscribe(data => {
            if (data.status) {
              this.getTable('delete');
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            }
            else {
              this.toastrService.error(data.message)
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  editTable(id) {
    localStorage.setItem("deleteTableId", id);
    this.router.navigate(['/corporate/add-segmentation-table']);
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.tableObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.table_id === id) {
        return true;
      }
    });

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' In Active?' : ' Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          obj.status = !obj.isactive;
          obj.id = obj.table_id

          this.segmentService.updatedStatus(obj).subscribe(data => {
            if (data.status) {
              this.getTable('status');
              this.toastrService.success("Status Changed Successfully");
            }
            else {
              this.toastrService.error(data.message)
            }

          });
        }
        // else {
        //   this.master.updateJourney(obj).subscribe(data => {
        //     this.getJourney();
        //   });
        // }
      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  company = 0;
  getCompanyobj =[] ;
  getCompany() {
    this.ngxSpinnerService.show();
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
       console.log(error);
       this.ngxSpinnerService.hide();
    });
  }

}

