import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, Subject, of, combineLatest } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  socket: any;
  public baseUrls;
  notification = new Subject<any>();
  apiPath = environment.chatURI;

  subject = new Subject<boolean>();
  subject$: any;
  rooms = new Subject<any>();
  noticeBoard = new Subject<any>();
  channels = new Subject<any>();
  joinRoom = new Subject<any>();
  messageIn = new Subject<any>();
  messageOut = new Subject<any>();
  moreMessages = new Subject<any>();
  status = new Subject<any>();
  messageNotification = new Subject<any>();
  disconnect = new Subject<any>();
  sessionMessageCount = new Subject<number>();
  socketCount = new Subject<any>();
  fileOut = new Subject<any>();

  deleteMessge=new Subject<any>();
  updateMessge=new Subject<any>();

  countSocket = 0;
  checkSocket = new BehaviorSubject<any>("Disconnected");

  apiUrl = environment.chatURI; // "http://localhost:4000";


  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrls = baseService.baseUrl1;
  }

  init() {

    this.socket = io.connect(environment.chatURI, {
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax' : 5000,
      'reconnectionAttempts': 100,
    });
    




    this.socket.on('connect', () => {
      this.socket
        .emit('authenticate', { token: localStorage.getItem('accesToken') }) // send the jwt
        .on('authenticated', () => {
          this.subscribe();
          this.subject.next(true);
          this.checkSocket.next("socket initialized");
        })
        .on('unauthorized', (msg) => {
          this.subject.next(false);
        });
    });
    this.socket.on('disconnect', () => {
     // console.log('************disconnect event fired*****************');
      // this.socket.close();
      /* this.disconnect.next();*/

    });
    this.socket.on('reconnect', function() {

     // console.log('reconnect fired!');
    });
    return this.subject;
  }

  subscribe() {
    /*
    this.socket.on('list-rooms',(data)=>{
      if(data && data.output && data.output.data)
      this.rooms.next(data.output.data);
    });
    */
    this.socket.on('file-out', (data) => {
     // console.log('**************file out++++++++++', data);
      if (data && data.output && data.output.data) {
        this.fileOut.next(data.output.data);
      }
    });

    this.socket.on('list-noticeboard', (data) => {
    //  console.log('notice board...............');

      if (data && data.output && data.output.data) {
        this.noticeBoard.next(data.output.data);
      }


      /*

        if(data && data.output && data.output.data)
        {
          var noticeBoard = data.output.data.filter(function (result) {
            return result.room_type === 3;
          });
        }
        */
      // this.channels.next(channels);

    });

    this.socket.on('message-in', (data) => {
      if (data && data.output && data.output.data) {
        this.messageIn.next(data.output.data);
      }
    });
    this.socket.on('join-room', (data) => {  
      if (data && data.output && data.output.data) {
        this.joinRoom.next(data.output.data);
      }
    });
    this.socket.on('message-out', (data) => {

      if (data && data.output && data.output.data) {
        this.messageOut.next(data.output.data);
        this.messageNotification.next(data.output.data);
      }

    });
    this.socket.on('more-messages', (data) => {
      if (data && data.output && data.output.data) {
        this.moreMessages.next(data.output.data);
      }
    });
    this.socket.on('status', (data) => {
      if (data && data.output && data.output.data) {        
        this.status.next(data.output.data);
      }        
    });     


    this.socket.on('get-notification', (data) => {  
      if (data && data.output && data.output.data) {   
        this.notification.next({type: 1, notification: data.output.data.notification});
      }   
    });   
    this.socket.on('increment-notification', (data) => {
      if (data && data.output && data.output.data) {
        this.notification.next({type: 2, notification: data.output.data.notification});
      }
    });
    this.socket.on('get-socket', (data) => {
      if (data && data.output && data.output.data) {
        this.countSocket = data.output.data.count;
        this.socketCount.next(this.countSocket);
      }

    });

    this.socket.on('delete-message',(data)=>{

      if(data && data.output && data.output.data)
        this.deleteMessge.next(data.output.data);
  })
  this.socket.on('update-message',(data)=>{
    if(data && data.output && data.output.data)
      this.updateMessge.next(data.output.data);
})



  }

  sendFileOut(data) {
   // console.log('*******fileOut called*********', data);
    this.socket.emit('file-out', data);
  }


  resetUnReadCount(roomId) {
    this.socket.emit('reset-unread-count', {room_id: roomId});
  }

  getMessagesByApi(room_id, limit, offset, flag) {
    return this.http.get<any>( this.apiPath + 'api/get-messages', {
      params: {
        room_id: room_id,
        limit: limit,
        offset: offset,
        flag: flag

      }});
  }

  setSessionMessageCount(value) {
    this.sessionMessageCount.next(value);
  }


  join(data) {
    this.socket.emit('join-room', data);
  }
  getSignedUrl(filename, file) {
    // console.log("signed URL is FOUND",this.apiPath + 'api/signed-url?filename=' + filename)
    return this.http.get(this.apiPath + 'api/signed-url?filename=' + filename);
  }
  uploadImageToS3(url, file) {
  //  console.log('url:::: ', url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/octet-stream');
    return this.http.request('PUT', url, {body: file, headers: headers});
  }
  getReadSignedURL(filename) {
    return this.http.get(this.apiPath + 'api/read-signed-url?filename=' + filename);
  }


  sendMessage(data) {
   // console.log('*******sendMEssage called', data);
    this.socket.emit('message', data);
  }
  resetJoinRoom() {
    this.socket.emit('reset-join-room');
  }
  getNotification() {

   // console.log("sssss...",this.socket)

    if(this.socket) this.socket.emit('get-notification');
  }


  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  // tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getMessages(room_id) {
  //  console.log(room_id);

    return this.http.get<any>(this.apiUrl + 'api/message/' + room_id)

      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf('Error acquiring client') >= 0 ||
              data.message.indexOf('Error executing query') >= 0)) {
              console.error(data.message);
              data.message = 'Something went wrong.Please try again.';
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getChannels(obj) {
    this.socket.emit('list-channels', obj);

  }

  getNoticeBoard(obj) {
    this.socket.emit('list-noticeboard', obj);

  }

  getNoticeBoardRooms(id) {

    return this.http.get<any>(this.apiUrl + 'api/noticeBoardRooms/' + id, this.baseService.httpHeadersOptions())

      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf('Error acquiring client') >= 0 ||
              data.message.indexOf('Error executing query') >= 0)) {
              console.error(data.message);
              data.message = 'Something went wrong.Please try again.';
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  addMessage(value) {

    const body = value;
    return this.http.post<any>(this.apiUrl + 'api/message/', body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf('Error acquiring client') >= 0 ||
              data.message.indexOf('Error executing query') >= 0)) {

              console.error(data.message);
              data.message = 'Something went wrong.Please try again.';
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  deleteRoomMessage(message_id,room_id,index,date) {
    var obj = {
      "message_id": message_id,
      "socketId":this.socket.id,
      "room_id":room_id,
      "keyIndex":index,
      "date":date
    }
    //console.log(obj);

    //console.log('socket.....',this.socket.id);

    return this.http.post<any>(this.apiPath + "api/deleteMessage",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  editMessage(message_id,content,room_id) {

    var obj = {
      "message_id": message_id,
      "content": content,
      "socketId":this.socket.id,
      "room_id":room_id
    }

    return this.http.post<any>(this.apiPath + "api/editMessage",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getTimezones(){
    const URL = this.baseService.baseUrl1 + 'api/getTimeZones';
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  unsubscribe() {
    this.socket.removeAllListeners();
    this.socket.disconnect();
    this.socket = undefined;
  }


  close() {
    try {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket.close();
    } catch (e) {
     // console.log('**************close*************', e);
    }
  }


}
