<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/mb">Social SMS</a></li>
      <li class="breadcrumb-item active" aria-current="page">MB Message Snippets</li>
    </ol>
  </nav>
</div>

<div class="container themeTblReso">
  <div class="row">

    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getMessageData()" [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <!-- *ngIf="userObj.dbstatus == true"  -->
            <option value={{userObj.customer_id}}>
              <!-- <option
            *ngFor="let userObj of getCompanyobj"
            value="{{ userObj.customer_id }}"
          > -->
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText
        [disabled]="selectedCompany == ''">
      </div>

    </div>


    <div class="col-md-4 mt-2 mt-md-0" style="justify-content: right;">
      <input *ngIf="isWrite" type="button" value="Add New" class="custbtn mt-md-1 ml-0 ml-0" routerLink="/corporate/addnewsnippets">
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom min-wit-700" width="100%" cellpadding="0" cellspacing="0">


      <tr>
        <!-- <th> Company Type Id</th> -->
        <!-- <th> Company Type ParentId</th> -->
        <th (click)="setOrder('msg_type')" style="cursor: pointer;">
          Snippet Name
          <i class=" fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Snippet Text</th>

        <th (click)="setOrder('createdon')" style="cursor: pointer;">Created On
          <i class=" fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Action</th>

        <!-- <th>Actions</th> -->
      </tr>


      <tr *ngFor="let obj of messageSnippetObj">
        <td>{{obj.msg_type}}</td>
        <td style="word-wrap: break-word !important; white-space: pre-wrap !important;">{{obj.msg_description}}</td>
        <td>{{obj.createdon | date:'mediumDate'}}</td>
        <td>
          <div class="actBtn">
            <i *ngIf="isEdit" class="fa fa-edit mr-1" (click)="editMessage(obj.snippet_id)" placement="bottom"
              ngbTooltip="Edit"></i>
            <span *ngIf="isEdit" class="fa fa-trash mr-1" (click)="deleteMessage(obj.snippet_id)" placement="bottom"
              ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.snippet_id,obj.isactive)">
            </mat-slide-toggle>
          </div>
        </td>

      </tr>

    </table>
    <div *ngIf="total== 0" style="text-align: center;">
      <p>No Result Available</p>
    </div>
    <!-- <pagination-controls *ngIf="(messageSnippetObj | filter:text).length !== 0" class="my-pagination"
      (pageChange)="p = $event"></pagination-controls> -->
    <div class="mt-2 text-center difPagination" *ngIf="total > 10">
      <ngb-pagination [collectionSize]="total" [(page)]="page" [maxSize]="2" [rotate]="true"
        (pageChange)="getMessageData(page=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>