import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseserviceService} from '../baseservice.service';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CampaignBuilderService {

  public baseUrl;
  public dsUrl;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.segBaseUrl + 'api/segmentation/';
    this.dsUrl = baseService.baseUrl1;
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  // tohandle the error

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  //  ############  GET ALL BU Campaign  ############  //

  getBUCampaign(company,  limit, skip, colname, colname_val, searchText) {
    const URL = this.baseUrl + `campaignBuilderBu/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############  GET segement Journey List  ############  //

  getSegementJourney(company) {
    const URL = this.baseUrl + 'campaignBuilderBu/masterJourney/' + company;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }


  //  ###########  Add  BU Audience  ##############  //

  addingBUCampaign(obj, id) {
    const URL = this.baseUrl + 'campaignBuilderBu/';
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  //   ########## Get Themes List  #################  //

  getThemes(prodId, jid) {
    const URL = this.dsUrl + 'api/theme/getThemeAssosciationBycidpid/' + prodId + '/' + jid + '/30/0';
    return this.http.get<any>(URL, this.httpHeadersOptions());
  }

  //  ###########  Update Campaign ################  //

  updateCampaign(obj){
    const URL = this.baseUrl + 'campaignBuilderBu';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions());
  }

  //  ###########  Get Campaign Data by ID  ##########  //

  getBUCampaignById(companyId, campId){
    const URL = this.baseUrl + 'campaignBuilderBu/' + companyId + '/' + campId;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #########  Delete Campaign ########################  //

  deleteCampaign(companyId, campid){
    const URL = this.baseUrl + 'campaignBuilderBu/' + companyId + '/' + campid;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //   ############  Update 'Name', 'Description', 'Journey' for Campaign ##########  //

  updateCampaignBasicDetails(obj) {
    const URL = this.baseUrl + 'campaignBuilderBu/update';
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions());
  }

  getBUCampaignSetting(company,  limit, skip) {
   const URL = this.baseUrl + `audiencebuilder/campaignSetting/${company}/${limit}/${skip}`;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  addingBUCampaignSetting(obj) {
    let company = 62;
    // console.log("obj", obj);
    const URL = this.baseUrl + `audiencebuilder/campaignSetting/add/${company}`;
    const body = JSON.stringify(obj);
    return this.http.post<any>(URL, body, this.httpHeadersOptions());
  }

  updateBUCampaignSetting(obj) {
    console.log("obj", obj);
    let company = obj.company;
    let id = obj.id;
    console.log("obj", obj);
    const URL = this.baseUrl + `audiencebuilder/campaignSetting/update/${company}/${id}`;
    const body = JSON.stringify(obj);
    return this.http.put<any>(URL, body, this.httpHeadersOptions());
  }

  getBUCampaignSettingById(companyId, campaignId){
    const URL = this.baseUrl + 'audiencebuilder/campaignSetting/view/' + companyId + '/' + campaignId;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  getBUCampaignSettingByType(companyId, type){
    const URL = this.baseUrl + 'audiencebuilder/campaignSetting/type/' + companyId + '/' + type;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

}
