<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page"> Customer Subscription Report</li>
    </ol>
  </nav>
  <div >
    <span style="float: right;
  margin-right: 15px;
  padding: 8px;
  border-radius: 7px;
  "> <!--<a (click)="exportReportCsv()"> <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -100px;"> </a>--></span>
  </div>
</div>

<!-- <div class="container mt-1 themeTblReso">
  <div class="row" *ngIf="customerID==0"><div class="col-md-8 mt-2">
    <div>    
      <select name="company" class="form-control formstyle"  (change)="gettingReports($event.target.value)">
        <option value="">------Select Company Name------</option>
          <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
      </select>
  </div>
</div>
 -->
<div class="container mt-1 themeTblReso">
  <div class="row w-100" >
    <div class="col-md-3 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
      [(ngModel)]="start_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="col-md-3 mt-2">     
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
      [(ngModel)]="end_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="col-md-5 mt-2 ">   
      <button class="custbtn ml-0 mt-md-1" (click)="gettingReports(1)" style="align-items: center;">Search</button>
      <button class="custbtn ml-0 mt-md-1" (click)="resetReport()" style="align-items: center;">Reset</button> 
    </div>

    <!-- <div class="col-md-2 mt-2 ">
      <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a>
    </div> -->
  </div>

  <div class="row">
    <div class="col-12">
      <div class="tblPrt">
    
        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">    
          <tr>
            <th>Company Name  <i class=" fa fa-sort" aria-hidden="true"></i></th>
            <th>{{projectName}} Subscription Type</th>
            <th>Contract Status</th>
            <th>Contract Term</th>
            <th>Contract Start Date</th>
            <th>Base Service Start Date</th>
            <th>Premium Service Start Date</th>
            <th>Monthly Price/DS</th>
            <th>Months to renewal</th>
            <th>Wallet load Amount</th>
            </tr>
            <tr *ngFor="let report of reportData | paginate:{itemsPerPage:10,currentPage:p}">
              <td>{{report.customer_company_name}}</td>
              <td>Basic</td>
              <td>{{report.isactive?"Active":"In-active"}}</td>
              <td>{{report.contract_in_years}} years</td>
              <td>{{report.start_date |date}}</td>
              <td>{{report.start_date |date}}</td>
              <td>{{report.premium_service_start_date |date}}</td>
              <td>$0</td>
              <td>{{report.remainingMonth}} months</td>
              <td>$0</td>
            </tr>      
      </table>  
    </div>
    </div>
  </div>

  <div><pagination-controls class="my-pagination" (pageChange)="p = $event"></pagination-controls></div>
</div>