import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-opt-in-opt-out',
  templateUrl: './opt-in-opt-out.component.html',
  styleUrls: ['./opt-in-opt-out.component.css']
})
export class OptInOptOutComponent  implements OnInit {
  companyID=0;
  companyId;
  pendingrecord:number=0;
  currentUser;
  company_status:boolean=false;
  cpdata={customer_id:null};
  getCompanyobj;
  customerID;
  obj;
  reportData:any=[];
  p: number = 1;
  total: number;
  status="select";
  start_date;
  end_date;
  clicked=false;
  total_contacts;    
  Consumetotal;
  total_used;
  user_type=true;
  showResult:boolean = true;
  isHeadquater;

  report_status=180;

  totalCount: number = 0;
  page = 1;
  limit = 100;
  pageSize = 100;
  skip = 0;


  search = 0 ;

  
  constructor(private report:ReportService,
    private clientService : ClientService,
    public toastrService: ToastrService,
    private spinner : NgxSpinnerService) { }

  ngOnInit() {    
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;
    this.companyID= this.currentUser.admin_customer_id;
   // this.getCompany(this.companyId);

    this.isHeadquater=localStorage.getItem("customer_isheadquarter");
    if(this.companyID==0 || this.isHeadquater ==="true"){
      this.getCompany(this.companyID);
      this.companyID = 0;
      this.showResult=false;
      this.isHeadquater = true;
     // console.log("ddddd...", this.isHeadquater)
      //this.addrecords(3);
    //this.spinner.hide();
    }else{   
      this.showResult=true;   
      this.isHeadquater = false;     
      this.gettingReports(1,this.companyID,'onInit');

  }
  }

  gettingReports(page,id,flag) {
    if(flag == "timeChange"){this.reportData = [];this.totalCount = 0;this.page = 1;this.skip = 0;};
    this.companyId =id;
    this.search = page == 0? 1: 0; 
    if(this.companyId==0) {
      this.showResult=false;
      this.toastrService.error("Please select company");
      return false;
    }
    const parmas = {
      customer_id: id,
      page: page,    
      report_status: this.report_status,
      user_type: this.user_type        
    };
    this.getOPTinoutDS(id,this.report_status,this.user_type,page);
  }
  
  // getOPTinoutDS(id,report_status,user_type){
  //   this.spinner.show();
  //   this.report.getOPTinoutDS(id,report_status,user_type).subscribe((data)=>{
  //     if(data.status)
  //     { 
  //       this.showResult=true;
  //       this.clicked=true;   
  //       //this.showResult =true;   
  //       this.reportData= data.data;
  //     } else { 
  //       this.reportData=[];  
  //       //this.showResult = false ;
  //     }
  //     this.spinner.hide();
  //   });
  // }


  getOPTinoutDS(id,report_status,user_type,page){
    this.spinner.show();

    this.skip = this.search == 0 ? (this.page - 1) * this.limit : 0;
    this.search = 0;
    this.report.getOPTinoutDS(id,report_status,user_type,this.limit, this.skip).subscribe((data)=>{
      if(data.status)
      { 
        this.showResult=true;
        this.clicked=true;   
        //this.showResult =true;   
        this.reportData= data.data.rows;
        this.totalCount = data.data.total;
      } else { 
        this.reportData=[];  
        //this.showResult = false ;
      }
      this.spinner.hide();
    });
  }
   
  getCompany(companyId:any) {    
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //this.selectComapanyName()
    }, error => {
    // console.log(error);
    });
  }
 
}