import { Component, OnInit } from '@angular/core';
import { PiiService } from 'src/app/services/pii/pii.service';

import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pii-individual-profile',
  templateUrl: './pii-individual-profile.component.html',
  styleUrls: ['./pii-individual-profile.component.css']
})
export class PiiIndividualProfileComponent extends BaseComponent implements OnInit {

  constructor(
    private pii: PiiService,

  ) { super(); }

  cp = 1;
  ord = true;
  colname: "Created On";
  allProfileDataObj: any = [];
  fname="";
  lname="";
  email="";
  phone="";
  address="";
  city="";
  state="";

  page = 1;
  limit = 10;
  offset = 0;
  dataList: any = [];
  totalCount: number;
  profileID;
  showProfile:boolean=false;
  searchResult:boolean=false;
  isDataEmpty:boolean=false;

  ngOnInit() {
    //this.searchtIndividualProfileData();
  }

  searchtIndividualProfileData() {
  this.isDataEmpty=false;
  this.ngxSpinnerService.show();

    this.offset =0;
    this.page =1;
    this.offset = (this.page -1) * this.limit;
    this.allProfileDataObj = [];
    //  console.log("testing...")

      if (this.fname == "" && this.lname == "" && this.email == "" && this.phone == "" && this.address == "" && this.city == "" && this.state == "") {
        this.ngxSpinnerService.hide();
        this.toastrService.error("Please enter keyword");
        this.allProfileDataObj = [];
      } else {
        this.dataList = [];
        var params = {
          fname: this.fname || "",
          lname: this.lname || "",
          email: this.email || "",
          phone: this.phone || "",
          address: this.address || "",
          city: this.city || "",
          state: this.state || "",
          limit:this.limit,
          offset:this.offset
        }
        this.pii.searchtIndividualProfile(params).subscribe(data => {

          if(data.status)  {

            window.scrollTo(0, 1000);
             this.totalCount = data.data.total;
             this.allProfileDataObj = data.data.result;
             console.log("ssss",this.totalCount);
             this.ngxSpinnerService.hide();

          }

          else    {
            this.isDataEmpty=true;
            this.ngxSpinnerService.hide();
            this.allProfileDataObj = [];
            this.totalCount = 0;
          // this.toastrService.error("No Result Available");


        }

        }, error => {
        //  console.log(error);
        });
      }

  }
  searchtIndividualProfileDataPaging() {

    this.isDataEmpty=false;
   this.offset = (this.page -1) * this.limit;
  //  else  { this.offset = 0; window.scrollTo({ top: 500, behavior: 'smooth' }); }
    console.log("aaaaaaaaaaa", this.offset)
    this.allProfileDataObj = [];
  //  console.log("testing...")

    if (this.fname == "" && this.lname == "" && this.email == "" && this.phone == "" && this.address == "" && this.city == "" && this.state == "") {
      this.toastrService.error("Please enter keyword");
      this.allProfileDataObj = [];
    } else {
      this.dataList = [];
      var params = {
        fname: this.fname || "",
        lname: this.lname || "",
        email: this.email || "",
        phone: this.phone || "",
        address: this.address || "",
        city: this.city || "",
        state: this.state || "",
        limit:this.limit,
        offset:this.offset
      }
      this.pii.searchtIndividualProfile(params).subscribe(data => {

        if(data.status)  {


           this.totalCount = data.data.total;
           this.allProfileDataObj = data.data.result;
           console.log("ssss",this.totalCount);


        }
        else    {  this.allProfileDataObj = [];
          this.isDataEmpty=true;
          this.totalCount = 0;
        // this.toastrService.error("No Result Available");
      }

      }, error => {
      //  console.log(error);
      });
    }

  }


  ResetFormData() {

    this.fname ="";
    this.lname ="";
    this.email ="";
    this.phone ="";
    this.address ="";
    this.city ="";
    this.state ="";
    this.allProfileDataObj = "";
    this.isDataEmpty=false
    this.totalCount = 0;
  }

  viewProfile(id) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log("hi testing......" +id)
        this.showProfile =true;
        this.profileID = id;
        // sessionStorage.setItem("individualProfilingID",id);
        // this.router.navigateByUrl('corporate/pii-report-bu');
   }

   backToSearchPage(id) {
    this.showProfile =false;
   }


}
