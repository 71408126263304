import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { FbService } from 'src/app/services/fbservice/fb.service';
@Component({
  selector: 'app-fbreports',
  templateUrl: './fbreports.component.html',
  styleUrls: ['./fbreports.component.css']
})
export class FbreportsComponent implements OnInit {
  buPostObjCount = 0;
  dsPostObjCount = 0;
  buHqPostObjCount = 0;
  totalePostObjCount = 0;
  postCampaignObjCount = 0;
  schedulerPostObjCount = 0;
  constructor(
    private fb: FbService,
    private router: Router,
    private logs: LogsService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getPostCount();
  }

  getPostCount() {
    let self = this;
    this.fb.getPostManagementData(true).subscribe(data => {
      self.buPostObjCount = data.data.length;
      this.totalePostObjCount += data.data.length;
    //  console.log(self.buPostObjCount);
    }, error => {
     // console.log(error);
    });
    this.fb.getPostManagementData(false).subscribe(data => {
      self.dsPostObjCount = data.data.length;
      this.totalePostObjCount += data.data.length;
    //  console.log(self.dsPostObjCount);
    }, error => {
     // console.log(error);
    });
    this.fb.postCampaignBycompanyId().subscribe(data => {
      self.postCampaignObjCount = data.data.length;
    //  console.log('postCampaignObjCount', self.postCampaignObjCount);
    }, error => {
     // console.log(error);
    });
    this.fb.fbSchedulerByCompanyId().subscribe(data => {
      self.schedulerPostObjCount = data.data.length;
     // console.log('postCampaignObjCount', self.schedulerPostObjCount);
    }, error => {
    //  console.log(error);
    });
  }
}
