import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import {forkJoin} from 'rxjs';
@Component({
  selector: 'app-add-cb-section-field-association',
  templateUrl: './add-cb-section-field-association.component.html',
  styleUrls: ['./add-cb-section-field-association.component.css']
})
export class AddCbSectionFieldAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  public fieldObj: any = [];
  submitted = false;
  fieldForm: FormGroup;
  fieldid: any = [];
  getCompanyobj: any = [];
  companyID;
  fieldObj1=[];
  selectedFields = [];
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
  }

  ngOnInit() {
    this.getCompany();
  
    this.fieldForm = this.formBuilder.group({
      company: ['', Validators.required],
     
      sid: ['',[Validators.required]]
    });
  }

  get f() { return this.fieldForm.controls; }

  selectFields(e, id) {
    if(e.target.checked) {
      //this.fieldid.push(id)
      this.selectedFields.push(id);

    } else if(!e.target.checked) {
      this.selectedFields.splice(this.selectedFields.indexOf(id), 1);
    }
  }

  addFields() {
    this.submitted = true;
    //console.log(this.templateForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
    //console.log("themescreenshot -- ", this.templateForm);
    
    if (this.fieldForm.invalid) {
      return;
    }

    if(this.selectedFields.length>0) this.selectedFields = this.selectedFields.filter((el, i, a) => i === a.indexOf(el));

 
    const data = {
      fieldid: this.selectedFields,
      sectionid: this.f.sid.value,
      company: this.f.company.value
    }
    this.ngxSpinnerService.show();
    this.cb.addingFields(data).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-sec-field-association`);
      } else { 
        this.toastrService.error(data.message)
        this.ngxSpinnerService.hide();
      }
    }, error => {
    //  console.log(error);
      this.toastrService.error('Something went wrong')
    });
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    //  console.log(this.getCompanyobj);
    }, error => {
      //console.log(error);
    });
  }

  getCompanySections(id){

    if(id) {

    this.companyID = id;
    //event.preventDefault();
    this.ngxSpinnerService.show();
    this.fieldForm.controls.sid.setValue("");
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        //field: this.cb.getCompanyField(id),
        section: this.cb.getCompanySection(id, 9999, 0)
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      //this.fieldObj = data.field.data;
      this.themeObj = data.section.data.rows;
      this.fieldObj=[];
    //  console.log(data, 'forkJoin')
    }), error => {
      //console.log(error);
     
    }
    }
    else {
      this.fieldObj=[];
      this.themeObj =[];

    }
  }

  getSectionField(id){

    if(id) {
    let self = this;

    this.ngxSpinnerService.show();   
    this.selectedFields = [];
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        field: this.cb.getCompanyField(this.companyID, 9999, 0),
        checkedFields: this.cb.getAssociatedSectionField(this.companyID,id)
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      this.fieldObj = data.field.data.rows;
      this.fieldObj1 = data.checkedFields.data;

   if(self.fieldObj1){
      self.fieldObj1.forEach(element => {
        this.selectedFields.push(element.fieldid);
      });  
   }
    //  console.log(data, 'forkJoin')
    }), error => {
     // console.log(error);
     
    } 
    }
    else {

      this.fieldObj =[];
    }
  }

}
