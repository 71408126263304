<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
        <li class="breadcrumb-item active">Training Type</li>
      </ol>
    </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>

      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>

  </div>
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/addnewtraining">
    <button class="csvBtn" *ngIf="isImport" (click)="importFile()">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" *ngIf="isExport" (click)="exportFile()">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>

<div class="tblPrt">

  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Training Type Id</th> -->
        <th (click)="chgord('subc1')" class="curPoint">Training Type Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Training Type Description </th>
        
        <!-- <th>Created By</th> -->
        <th   class="curPoint">Created On<i class=" fa fa-sort text-black"
          aria-hidden="true" (click)="chgord('subc1')"></i></th>
        
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>
  

      <tr
        *ngFor="let obj of trainingObj  | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">

        <!-- <td>{{obj.get_master_training_type.training_type_id}}</td>  -->
        <!-- <td>{{obj.get_master_training_type.training_type_id}}</td> -->
        <td>{{obj.get_master_training_type.training_type_name}}</td>
        <td>{{obj.get_master_training_type.training_type_description}}</td>
        
        <!-- <td>{{obj.get_master_training_type.createdname}}</td> -->
        <td>{{obj.get_master_training_type.createdon |date:'mediumDate'}}</td>
        
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
          <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editTrainingType(obj.get_master_training_type.training_type_id)"  placement="bottom" ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1 cursor" *ngIf="isDelete" (click)="deleteTrainingType(obj.get_master_training_type.training_type_id)" placement="bottom" ngbTooltip="Delete"></span>
          <mat-slide-toggle [checked]="obj.get_master_training_type.isactive" class="my-slider cursor" *ngIf="isEdit"
            (click)="updateStatus($event,obj.get_master_training_type.training_type_id)">
          </mat-slide-toggle>
        </div>
        </td>

      </tr>



  </table>
  <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
</div>
</div>