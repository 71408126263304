import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { SegmentationService } from "src/app/services/segmentation/segmentation.service";
import { BaseComponent } from "../../base.component";
import { MasterService } from 'src/app/services/master/master.service';

@Component({
    selector: 'app-customercountlist',
    templateUrl: './customer-countlist.component.html',
    styleUrls: ['./customer-countlist.component.css']
})
export class CustomerCountListComponent extends BaseComponent implements OnInit {

    companyId: any;
    audTyp: any;
    prodId: any;
    from: any;
    to: any;
    navtype: any;
    dtyp: any;
    limit = 10;
    skip = 0;
    page = 1;
    customerList = [];
    totalRecords = 0;
    queryContactField: FormControl = new FormControl();
    stext = '';
    dspage = 1;
    dsList = [];
    dsTotalRecords = 0;
    isshop = false;
    selIdx: any;
    pg: any;
    launched:any;






    contactStartDate: any;
    contactEndDate: any;
    dsid = 0;
    constructor(protected activeRoute: ActivatedRoute, private segmentationService: SegmentationService,
        private master: MasterService) {


        super();
    }

    ngOnInit(): void {
        this.activeRoute.queryParams
            .subscribe(params => {
                this.companyId = params.company;
                this.audTyp = params.audType;
                this.prodId = params.prodId;
                this.from = params.from;
                this.to = params.to;
                this.navtype = params.navtype;
                this.dtyp = params.dtyp;
                this.isshop = params.isshop;
                this.selIdx = params.indx;
                this.pg = params.page;
                this.contactStartDate = params.contactStartDate;
                this.dsid = params.dsid;
                this.launched = params.launchedFlag;

            });
        if (!this.companyId || !this.audTyp || !this.prodId || !this.from || !this.to || !this.navtype || !this.dtyp || !this.isshop) {
            this.router.navigateByUrl('/corporate/replenishment-and-cross-sell');
        } else {
            if (this.dtyp === 'ds') {
                this.ngxSpinnerService.show();
                this.getDsData();
            } else {
                this.queryContactField.valueChanges
                    .pipe(
                        map((query) => query),
                        debounceTime(1000),
                        distinctUntilChanged()
                    )
                    .subscribe(query => {
                        this.ngxSpinnerService.show();
                        this.skip = 0;
                        this.page = 1;
                        this.customerList = [];
                        this.totalRecords = 0;
                        this.getCustomerData();
                    });
            }
        }
    }

    getDsData() {
        let obj = {
            "company": this.companyId,
            "productId": this.prodId,
            "from": this.from,
            "to": this.to,
            "audType": this.audTyp,
            "limit": this.limit,
            "offset": this.limit * this.skip,
            "isshop": this.isshop,
            "launchedFlag": this.launched,
            "contactStartDate": this.contactStartDate,
        }
        this.segmentationService.getRepCrossSellDsList(obj).subscribe(data => {
            if (data.status) {
                this.dsList = data.data.rows;
                this.dsTotalRecords = data.data.total;
                this.ngxSpinnerService.hide();
            } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
            }
        }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
            this.goBack();
        });
    }

    getCustomerData() {
        let obj = {
            "company": this.companyId,
            "productId": this.prodId,
            "from": this.from,
            "to": this.to,
            "audType": this.audTyp,
            "limit": this.limit,
            "offset": this.limit * this.skip,
            "search": this.stext,
            "isshop": this.isshop,
            "launchedFlag": this.launched,
            "contactStartDate": this.contactStartDate,
            "dsid": this.dsid,
        }
        
        this.segmentationService.getRepCrossSellCustomerList(obj).subscribe(data => {
            if (data.status) {
                this.customerList = data.data.rows;
                this.totalRecords = data.data.total;
                this.ngxSpinnerService.hide();
            } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
            }
        }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
            this.goBack();
        });
    }

    getDataOnPageChange(p) {
        this.ngxSpinnerService.show();
        this.page = p;
        this.skip = this.page - 1;
        this.customerList = [];

        this.getCustomerData();
    }

    getDsDataOnPageChange(p) {
        this.ngxSpinnerService.show();
        this.dspage = p;
        this.skip = this.dspage - 1;
        this.dsList = [];

        this.getDsData();
    }

    goBack() {
        let shrtUrl = '?comp=' + this.companyId + '&from=' + this.from + '&to=' + this.to +
            '&shop=' + this.isshop + '&indx=' + this.selIdx + '&pg=' + this.pg + '&launchedFlag=' + this.launched;
        if (this.navtype === 'rep') {
            this.router.navigateByUrl('/corporate/replenishment-list' + shrtUrl);
        }
        if (this.navtype === 'cross') {
            this.router.navigateByUrl('/corporate/cross-sell-list' + shrtUrl);
        }
    }


    exportFile() {
        this.ngxSpinnerService.show();
        let filename = '';
        if(this.audTyp==1){
            filename = 'crosssell-campaign';
        }else{
            filename = 'replenishment-campaign';
        }
        let data = {

            "tableName": filename + this.from + '-' + this.to,
            "cid": this.companyId,
            "from": this.from,
            "to": this.to,
            "audType": this.audTyp,
            "isshop": this.isshop,
            "dsid": this.dsid,
            "productId": this.prodId,
            "contactStartDate": this.contactStartDate,
            "launchedFlag": this.launched,
        }





        this.master.exportCustomData(data).subscribe(dataFile => {
            // console.log("--dataFile--", dataFile);
            if (dataFile.status) {
                let selBox = document.createElement('a');
                var att = document.createAttribute("href");
                att.value = dataFile.file;
                var att1 = document.createAttribute("download");
                selBox.setAttributeNode(att);
                selBox.setAttributeNode(att1);
                document.body.appendChild(selBox);
                selBox.click();
            }
            this.ngxSpinnerService.hide();
        }, err => {
            this.toastrService.error(err.message);
        });
    }

}

