<!-- <h3><a routerLink="/corporate/campaign">&lt;Back</a> </h3>-->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/mb">Social SMS</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/smscategory">Category Management</a>
      </li>
      <li class="breadcrumb-item active">Add / Update Category</li>
    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="profileForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">
              <label for="exampleForm2">Company Name</label>
              
                <select  formControlName="selectedCompany" class="form-control formstyle" *ngIf="category_id!= undefined"  disabled="controlEnabled"  [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }" >
                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj" >
                    <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                  <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                  </ng-container>
                </select>

                <select  formControlName="selectedCompany" class="form-control formstyle"   *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }" >
                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj" >
                    <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                  <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                  </ng-container>
                </select>
                <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                  <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
                </div>
            </div> 
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
           

            <div class="form-group">
              <label for="name">Category Name <span class="required">*</span></label>
              <input
                type="text"
                class="form-control"
                name="name"
                formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                maxlength="50" appSpace
              >
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="name">Category Description</label>
              <textarea              
              class="form-control text-area h-25" rows="5"
                formControlName="description"
              ></textarea>
             
            </div>
    
            <!-- <div>
            <label for="exampleForm2"> Name</label>
            <input type="text" class="form-control" [(ngModel)]="name" class="form-control form-control-md">
          </div>
          <div class="mt-1">
            <label for="exampleForm2"> Description</label>
            <input type="text" class="form-control" [(ngModel)]="description" class="form-control form-control-md">
          </div> -->
            <!-- <div>
          <label for="exampleForm2"> ScreenShot</label>
          <input type="text" class="form-control" [(ngModel)]="screenshot" class="form-control form-control-md">
        </div> -->
            <!-- <div class="mt-1">
            <label for="exampleForm2">Screenshot</label>
            <div [ngClass]="status ? 'displaynone' :'displayblock' ">
              <img src="{{screenshot}}" alt="No Image" style="width:300px;height:250px;">
            </div>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="customRadio1" [(ngModel)]="screenshot_type"
                  name="active" [value]="true">
                <label class="custom-control-label" for="customRadio1">Url</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="customRadio2" name="active"
                  [(ngModel)]="screenshot_type" [value]="false">
                <label class="custom-control-label" for="customRadio2">Upload a file</label>
              </div>
            </div>
            <div *ngIf="screenshot_type" class="mt-1">
              <input type="text" id="exampleForm1" [(ngModel)]="screenshot" class="form-control form-control-md">
            </div>
            <div *ngIf="!screenshot_type" class="mt-1">
              <input #imageInput type="file" accept=".png, .jpg, .jpeg" (change)="processFile($event,imageInput)">
            </div>
          </div> -->
            <!-- <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <button type="submit" class="custbtn mt-2" (click)="addNewCategoryData()">Submit</button>
          </div>
          <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'">
            <button type="button" class="custbtn mt-2" (click)="updateCategory()">Update</button>
          </div> -->
          </div>
          <div class="card-footer">
            <div
              [ngClass]="status ? 'displayblock' : 'displaynone'"
              class="text-center"
            >
              <button
                class="btn btn-round btn-margo-action"
                (click)="addNewCategoryData()"
              >
                Save
              </button>
            </div>
            <div
              [ngClass]="status ? 'displaynone' : 'displayblock'"
              class="text-center"
            >
              <button
                class="btn btn-round btn-margo-action"
                (click)="updateCategory()"
              >
              Update
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
