
<!-- page 2 starts -->

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
     
      <li class="breadcrumb-item active">
         Audience List
      </li>


      <li class="breadcrumb-item active" aria-current="page">
        Clone Audience
      </li>
      
      
    </ol>
  </nav>
</div>






<div class="container" >
  <div class="row">
    <div class="col-12">
      <h5 class="font-weight-bold mb-4">This Audience Contains All Users Where</h5>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
          
            <div class="row">
            <div class="col-12 geogMain">
              <div class="container" >

                <div class="row">
                  <div class="col-12">
                    <button type="button" value="" class="custbtn ml-0 mb-3 mt-2" style="min-width: auto;max-width: 150px;" (click)="addCondition()"> + Add Condition</button>
                   
                  </div>
                  <div class="col-12">
                    <div class="card">
              
                      <div class="card-body" *ngIf="queryResult">
                         
              
                        <div class="w-100 mb-3 gryBg" *ngFor="let item of queryResult; let i=index">
                          <div class="row">
                            
                            <div class="col-12">
                              
                           
                        <div class="row" >
                          <div class="col-12 mb-3">
                            <div class="row">
                              <div class="col-1 text-right"  *ngIf="i>0">
                                <span class="audImgIcon"><img src="assets/custom/images/audioBuildImg.png"></span>
                                <span class="audImgTxt">...</span>
                              </div>
                              <div class="col-md-2" *ngIf="i>0">
                              
                                {{item.query_connector}}
                             
                              </div>
                              <div class="col-md-4">
              
                                {{item.entity_name}}
                               
                                
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-md-3 mb-3">
              
                            {{item.field_name}}
                          
                          </div>
                          
                                                
                        
                          <div class="col-md-3 mb-3">
              
                            {{item.operator_name}}
                          
                          </div>
                          <div class="col-3" *ngIf="showProductValue" >
                            {{item.query_value}}
                          </div>
                                         
                          <div class="col-3" >
              
                             <button type="submit" class="custbtn ml-0" (click)="deleteAudienceBuilderQuery(item.audqueryid)"  >Delete</button>
              
                           
                        
                        
                                  </div>
                        
                                                
                        </div>
              
              
                        <div class="text-right crossRed signAbso" *ngIf="item.iserror">
                          <i class="fa fa-close mr-1 cursor audDeleteBtn"  placement="bottom" ></i>
                        </div>
                        <div class="text-right checkGreen signAbso" *ngIf="!item.iserror">
                          <i class="fa fa-check mr-1 cursor audDeleteBtn"  placement="bottom" ></i>
                        </div>
              
                       
              
              
              
                      </div>
                      
                    </div>
                      </div>
              
                     
              
                      </div>
              
              
              
              
              
              
                      <form [formGroup]="conditionForm" (ngSubmit)="onSubmit()">
                        
                      <div class="card-body">
                        <div class="form-group">                       
              
                        
                          <div class="row">
                          <div class="col-12 geogMain">
                            <div class="form-group">
                              
              
                                <div class="w-100" formArrayName="audienceConditions">
                                  <div class="w-100 mb-3 gryBg" *ngFor="let item of audienceConditions().controls; let i=index" [formGroupName]="i">
                                    <div class="row">
                                      <div class="col-1 text-right"  *ngIf="i>0">
                                        <span class="audImgIcon"><img src="assets/custom/images/audioBuildImg.png"></span>
                                        <span class="audImgTxt">...</span>
                                      </div>
                                      <div class="col-10">
              
                                  <div class="row" >
                                    <div class="col-12 mb-3">
                                      <div class="row">

                                        <div class="col-md-2">
                                        
                                          <Select type="text" class="form-control formstyle" formControlName="open_close" ngDefaultControl>
                                            <option value="" selected>
                                              <--Select-->
                                            </option>
                                            <option value="1">(</option>
                                            <option value="0">)</option>
                                          </Select>
                                        </div>

                                        <div  *ngIf="((queryResult.length+i)>0)" class="col-md-2">

                                          <input appSpace value="{{(queryResult.length+1)+i}}" type="hidden" formControlName="data_order" class="form-control formstyle mb-1" maxlength="50"
                                        ngDefaultControl style="width: 50px;"/>
              
              
                                        <select  class="form-control formstyle"   formControlName="conditionAndOr" ngDefaultControl>
                                          <option  value="OR" selected>OR</option>
                                          <option  value="AND">AND</option>
                                        </select>
                                        </div>
                                        <div class="col-md-4">
                                          <select class="form-control formstyle" formControlName="entity_id" (change)="entityAttributes($event.target.value,i)" ngDefaultControl  required  >
                                            <option value="">Select Entities</option>
                                            <option *ngFor="let obj of buEntitiesObj[i]" value="{{obj.entity_id}}">{{obj.entity_name}}</option>
              
                                          </select>
                                          <span class="required">*</span>

              
                                        </div>
                                      </div>
                                    </div>
              
                                    <div class="col-md-3 mb-3">
                                      <Select type="text" class="form-control formstyle" formControlName="field_id" (change)="attributeOperators($event.target.value,i)" ngDefaultControl  required >
                                        <option  value="">Select Attribute</option>
                                        <option *ngFor="let obj of buEntitieAttributesObj[i]" value="{{obj.field_id}}">{{obj.field_name}}</option>
                                      </Select>
                                      <span class="required">*</span>

                                    </div>
              
              
              
                                    <div class="col-md-3 mb-3">
                                      <select class="form-control formstyle" formControlName="operator_id" ngDefaultControl required  >
                                        <option value="">Select Operator</option>
                                        <option *ngFor="let obj of buAttributeOperatorObj[i]" value="{{obj.operator_id}}">{{obj.operator_name}}</option>
              
                                      </select>
                                      <span class="required">*</span>

              
                                    </div>
                                    <div class="col-3" *ngIf="dataLabelName[i]=='inputvalue'" >
                                      <span class="required">*</span>
                                      <input  appSpace type="text" formControlName="data_value"  class="form-control formstyle mb-1" maxlength="50"
                                        ngDefaultControl />
                                    </div>
              
                                    <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='agreedToHost'">
              
                                      <div class="custom-control custom-radio float-left">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked' + i}}"  value="true"  formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked' + i}}">Yes</label>
                                      </div>
                                      <div class="custom-control custom-radio float-left ml-3">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked1' + i}}"  value="false"  formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked1' + i}}">No</label>
              
                                      </div>
                                      <span class="required">*</span>
              
                                    </div>
              
              
                                    <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='agreedToJoin'">
              
                                      <div class="custom-control custom-radio float-left">
                                        <input type="radio" class="custom-control-input" id="defaultChecked9"  value="true"  formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="defaultChecked9">Yes</label>
                                      </div>
                                      <div class="custom-control custom-radio float-left ml-3">
                                        <input type="radio" class="custom-control-input" id="defaultChecked10" value="false"  formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="defaultChecked10">No</label>
              
                                      </div>
              
                                      <span class="required">*</span>
                                    </div>
              
              
                                    <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='gender'">
                                      <div class="custom-control custom-radio float-left">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked3' + i}}" value="2" formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked3' + i}}">Female</label>
                                      </div>
                                      <div class="custom-control custom-radio float-left ml-3">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked4' + i}}"   value="1"  formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked4' + i}}">Male</label>
              
                                      </div>
              
                                      <span class="required">*</span>
              
                                    </div>
              
              
              
                                    <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='optINSMS'">
                                      <div class="custom-control custom-radio float-left">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked5' + i}}" value="true" formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked5' + i}}">Yes</label>
                                      </div>
                                      <div class="custom-control custom-radio float-left ml-3">
                                        <input type="radio" class="custom-control-input" id="{{ 'defaultChecked6' + i}}"  value="false"   formControlName="data_value" ngDefaultControl>
                                        <label class="custom-control-label" for="{{ 'defaultChecked6' + i}}">No</label>
              
                                      </div>
                                      <span class="required">*</span>
                                   </div>
              
              
                                   <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='optINEmail'">
                                    <div class="custom-control custom-radio float-left">
                                      <input type="radio" class="custom-control-input" id="{{ 'defaultChecked7' + i}}" value="true"  formControlName="data_value" ngDefaultControl>
                                      <label class="custom-control-label" for="{{ 'defaultChecked7' + i}}">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio float-left ml-3">
                                      <input type="radio" class="custom-control-input" id="{{ 'defaultChecked8' + i}}"  value="false"  formControlName="data_value" ngDefaultControl>
                                      <label class="custom-control-label" for="{{ 'defaultChecked8' + i}}">No</label>
              
                                    </div>
                                    <span class="required">*</span>
                                 </div>

                                 <div class="col-md-3  mb-3" *ngIf="dataLabelName[i]=='open'">

                                  <div class="custom-control custom-radio float-left">
                                    <input type="radio" class="custom-control-input" id="{{ 'defaultChecked9' + i}}" value="1"  formControlName="data_value" ngDefaultControl>
                                    <label class="custom-control-label" for="{{ 'defaultChecked9' + i}}">Yes</label>
                                  </div>
                                  <div class="custom-control custom-radio float-left ml-3">
                                    <input type="radio" class="custom-control-input" id="{{ 'defaultChecked10' + i}}"  value="0"  formControlName="data_value" ngDefaultControl>
                                    <label class="custom-control-label" for="{{ 'defaultChecked10' + i}}">No</label>
                                  </div>
                                  <span class="required">*</span>
                               </div>
              
                                    <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='wishlist'">
              
                                        <select  class="form-control formstyle" formControlName="data_value" ngDefaultControl  >
                                          <option  value="">Select Product</option>
                                          <option *ngFor="let obj of companyProductDataObj[i]" value="{{obj.product_catalog_id}}">{{obj.product_name}} - {{obj.product_category}}</option>
              
                                        </select>
                                        <span class="required">*</span>
                                      </div>
              
                                      <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='Attribute'">
              
                                        <select  class="form-control formstyle" formControlName="data_value"  ngDefaultControl  >
                                          <option  value="">Select Attribute</option>
                                          <option *ngFor="let obj of companyAttributeDataObj[i]" value="{{obj.attribute_id}}">{{obj.attribute_name}}</option>
              
                                        </select>
                                        <span class="required">*</span>
                                      </div>
              
                                      <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='inputdate'">
                                        <span class="required requiredNew">*</span>
              
                                        <mat-form-field>
                                          <input matInput [matDatepicker]="picker" placeholder="Choose a date"  formControlName="data_value" readonly>
                                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                          <mat-datepicker #picker></mat-datepicker>
              
                                        </mat-form-field>
              
                                      </div>
                                      <div class="col-3 mb-3" *ngIf="dataLabelName[i]=='Journey'">
              
                                        <select  class="form-control formstyle" formControlName="data_value" ngDefaultControl  >
                                          <option  value="">Select Journey</option>
                                          <option value="67">JOIN</option>
                                          <option value="68">HOST</option>
                                          <option value="70">SHOP</option>
              
                                        </select>
                                        <span class="required">*</span>
                                      </div>
              
              
                                      <div class="col-md-3 mb-3" *ngIf="dataLabelName[i]=='Country' && (!displayState[i] && !displayCity[i])">
                        
                                        <Select  type="text" class="form-control formstyle" formControlName="data_value"  (change)="showState($event.target.value,i)" ngDefaultControl  >
                                          <option  value="">Select Country</option>
                                          <option *ngFor="let obj of getdataValueObj[i]" value="{{obj.id}}">{{obj.name}}</option>
              
                                        </Select>
                                        <span class="required">*</span>
                                      </div>
              
                                     
                                      <div class="col-md-3 mb-3" *ngIf="dataLabelName[i]=='Country' && (displayState[i] || displayCity[i])">
                                      
                                        <Select  type="text" class="form-control formstyle" formControlName="dataValueCountry"  (change)="showState($event.target.value,i)" ngDefaultControl  >
                                          <option  value="">Select Country</option>
                                          <option *ngFor="let obj of getdataValueObj[i]" value="{{obj.id}}">{{obj.name}}</option>
              
                                        </Select>
                                        <span class="required">*</span>
                                      </div>
              
                                      <div class="col-md-3 mb-3" *ngIf="dataLabelName[i]=='Country' && displayState[i] && !displayCity[i]">
                              
              
                                        <Select   type="text" class="form-control formstyle" formControlName="data_value"  (change)="showCity($event.target.value,i)" ngDefaultControl  >
                                          <option  value="">Select State</option>
                                          <option *ngFor="let obj of stateData[i]" value="{{obj.id}}">{{obj.name}}</option>
                                        </Select>
                                        <span class="required">*</span>
                                      </div>
              
              
                                      <div class="col-md-3 mb-3" *ngIf="dataLabelName[i]=='Country' &&  displayCity[i]">
                              
              
                                        <Select   type="text" class="form-control formstyle" formControlName="dataValueState"  (change)="showCity($event.target.value,i)" ngDefaultControl  >
                                          <option  value="">Select State</option>
                                          <option *ngFor="let obj of stateData[i]" value="{{obj.id}}">{{obj.name}}</option>
                                        </Select>
                                        <span class="required">*</span>
                                      </div>
              
                                      <div class="col-12" *ngIf="dataLabelName[i]=='Country' && displayCity[i]">
              
                                        <div class="row">
              
              
                                          <div class="col-3 mb-3" *ngIf="displayCity[i]">
              
                                           
                                            <Select  type="text" class="form-control formstyle" formControlName="data_value"  ngDefaultControl  >
                                              <option  value="">Select City</option>
                                              <option *ngFor="let obj of cityData[i]" value="{{obj.id}}">{{obj.name}}</option>
              
                                            </Select>
                                            <span class="required">*</span>
                                          </div>
                                        
                                        </div>
              
                                        </div>
              
                                        
              
              
                                  </div>
                                </div>
                                <div class="text-right">
                                  <span class="fa fa-times2 mr-1 cursor audDeleteBtn" (click)="removeCondition(i)" placement="bottom" ngbTooltip="Delete">X</span>
                                </div>
                              </div>
                                </div>
                                </div>
              
              
              
              
              
              
                              </div>
                            </div>
                        </div>
              
                        </div>
              
              
                  </div>
              
              
              
              
              
              
                  <div class="card-footer text-center">
                    <button type="button" class="custbtn" (click)="backQueryEditPage()">Back</button>

                    <button type="submit" class="custbtn ml-0" >Submit</button>

                  </div>
                </form>
              
              
              
              
              
              
              
                </div>
              </div>
              </div>
              </div>
              </div>  
          </div>
            
          </div>

          
    </div>
  </div>
</div>
</div>
</div>  







<!-- page 4 ends -->
