import { Component, OnInit } from '@angular/core';
import { PiiService } from 'src/app/services/pii/pii.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pii-field-add',
  templateUrl: './pii-field-add.component.html',
  styleUrls: ['./pii-field-add.component.css']
})
export class PiiFieldAddComponent extends BaseComponent implements OnInit {
  selectedAll: any;

  constructor(private piiservice: PiiService) { super() }

  sensitveTablesobj: any = [];
  sensitveTableFieldsobj: any = [];
  checkedColumn: any = [];
  selectedTable = "";
  nonSensitiveTableField: any = [];
  companyID;
  showMessage = true;

  ngOnInit() {
    this.companyID = localStorage.getItem('comapny_id');
    this.gettingSensitiveTables(this.companyID);
  }

  gettingSensitiveTables(id) {
    let self = this;
    this.piiservice.gettingSensitiveTables(id).subscribe(data => {
      this.sensitveTablesobj = data.data;
    }, error => {
      // console.log(error);
    });
  }

  gettingTableField(name, id) {

    this.gettingSenstiveTableFields(id);

    let self = this;
    this.piiservice.gettingTableField(name, this.companyID).subscribe(data => {
      this.nonSensitiveTableField = [];
      if (data.data) {
        for (var i = 0; i < data.data.length; i++) {
          if (this.sensitveTableFieldsobj.indexOf(data.data[i]['column_name']) == -1) {
            this.nonSensitiveTableField.push({
              "name": data.data[i]['column_name'],
              "type": data.data[i]['data_type'],
              "tableID": id
            });
          }
        }
      }
      // console.log('nonSensitiveTableField', this.nonSensitiveTableField);
      this.selectedTable = id;
    }, error => {
      // console.log(error);
    });
  }

  changeCheckTables(name, fieldType) {

    let ab = name + '~' + fieldType;
    var index = this.checkedColumn.indexOf(ab);
    if (index > -1) {
      this.checkedColumn.splice(index, 1);
    }
    else {
      this.checkedColumn.push(ab)
    }
  }

  addNewData() {
    if (this.checkedColumn.length > 0) {
      var obj = {
        selectedTableFields: this.checkedColumn,
        dbType: 1,
        userId: 1,
        tableID: this.selectedTable,
        company: this.companyID
      }
      this.piiservice.addsFeildData(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success("Record Inserted successfully");
          this.router.navigateByUrl(`/corporate/pii-field`);

        } else {
          this.toastrService.error(data.message)
        }
      }, error => {
        this.toastrService.error(error.message)
      });
    } else {
      this.toastrService.error("Please select at least one field.")
    }
  }

  gettingSenstiveTableFields(id) {
    let self = this;
    this.piiservice.gettingSenstiveTableFields(id, this.companyID).subscribe(data => {
      if (data.data) {
        for (var i = 0; i < data.data.length; i++) {
          this.sensitveTableFieldsobj.push(data.data[i]['field_name'] + '~' + data.data[i]['pii_type']);
        }
      }
      this.showMessage = false;
    }, error => {
      console.log(error);
    });
  }
}