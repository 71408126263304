import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClientService } from "src/app/services/clients/client.service";
import { MetaDataService } from "src/app/services/metadata/metadata.service";
import { BaseComponent } from "../../base.component";


@Component({
    selector: 'app-metadatalist',
    templateUrl: './metaDataList.component.html',
    styleUrls: ['./metaDataList.component.css']
})
export class MetaDataListComponent extends BaseComponent implements OnInit{

    customerID: any;
    buForm: FormGroup;
    getCompanyobj = [];
    buTables = [];
    selectedBuId = '';
    selectedTable = '';
    colMapData = [];
    metaMapData = [];
    text = '';
    constructor(private metaService: MetaDataService, private formBuilder: FormBuilder,
        private clientService: ClientService) {
        super();
        let sid = JSON.parse(localStorage.getItem('userData'));
        this.customerID = sid.admin_customer_id;
    }

    ngOnInit() {
        this.ngxSpinnerService.show();
        this.buForm = this.formBuilder.group({
            buid: [''],
            selTable: ['']
          });
        this.getCompanyList();
    }

    // ##########  Get BU Company List  #####################  //

    getCompanyList() {
        this.clientService.getBUCompanies().subscribe(data => {
            if (data.status) {
              this.getCompanyobj = data.data;
              if (this.customerID > 0) {
                  this.buForm.get('buid').patchValue(this.customerID);
                  this.selectedBuId = this.customerID;
                  this.getBUTablesListing(this.selectedBuId);
              }
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.getCompanyobj = [];
            this.ngxSpinnerService.hide();
          });
    }

    /*  ON BU SELECTED FROM DROPDOWN  */

    onBuSelected() {
        this.ngxSpinnerService.show();
        this.selectedBuId = this.buForm.value.buid;
        this.getBUTablesListing(this.selectedBuId);
    }

    //  ###########  Get BU Tables Listing ###########################  //

    getBUTablesListing(buId) {
        this.buTables = [];
        this.colMapData = [];
        this.metaMapData = [];
        this.buForm.get('selTable').patchValue('');
        this.selectedTable = '';
        
        if (buId === '' || buId === undefined || buId === null) {
            this.ngxSpinnerService.hide();
            return;
        }
        this.metaService.getBUTablesListing(buId).subscribe(data => {
            if (data.status) {
                this.buTables = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ###########  Get BU Data columns for Mapping #################  //

    onBuTableSelected() {
        this.ngxSpinnerService.show();
        this.selectedTable = this.buForm.value.selTable;
        this.metaMapData = [];
        this.colMapData = [];
        if (this.selectedTable === '' || this.selectedTable === undefined || this.selectedTable === null) {   
            this.ngxSpinnerService.hide();
            return;
        }
        
        this.getColumnMapping();
        this.getMetaColumnMapping();
    }


    //  ############  Get Mapping for Table      ################   //

    getColumnMapping() {
        this.metaService.getMappedColumnsForBuTable(this.selectedBuId, this.selectedTable).subscribe(data => {
            if (data.status) {
              this.colMapData = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ############  Get Mapping for Table Meta Columns  ###############  //

    getMetaColumnMapping() {
        this.metaService.getMappedMetaColumnsForBuTable(this.selectedBuId, this.selectedTable).subscribe(data => {
            if (data.status) {
              this.metaMapData = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }


}