import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-master-segmentation',
  templateUrl: './master-segmentation.component.html',
  styleUrls: ['master-segmentation.component.css']
})
export class MasterSegmentationComponent implements OnInit{

  id: any;
  constructor() {
  }
  ngOnInit() {
    let sid = JSON.parse(sessionStorage.getItem('userData'));
    this.id = sid.admin_customer_id;
  }
}
