import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.css']
})
export class ContactViewComponent extends BaseComponent implements OnInit {
  companyId:any;
  enqId:any;
  enqData: any;
  constructor(
    private route:ActivatedRoute,
    private masterService: MasterService
  ) {
    super();
    this.route.queryParams.subscribe(query=>{
      this.companyId=query.companyID;
      this.enqId=query.enq_id
    })
   }

  ngOnInit(): void {
    this.getEnquiryDetail();
  }
  getEnquiryDetail(){
    this.ngxSpinnerService.show();
    this.masterService.fetchContactEnquiryById(this.companyId,this.enqId).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide();
        if(res.status){
          this.enqData = res.data.rows[0];
        }
        else{
          this.router.navigateByUrl('/corporate/contactus-list')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/contactus-list')
      }
    })
  }
}
