import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-loyalitytypemaster',
  templateUrl: './loyalitytypemaster.component.html',
  styleUrls: ['./loyalitytypemaster.component.css']
})
export class LoyalitytypemasterComponent extends BaseComponent implements OnInit {
  cp = 1;
  ord = false;
  colname: "loyalty_type_name";
  text;
  today: number = Date.now();
  public loyalityObj: any = [];
  constructor(
    private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
  ) {
    super();
   }

  ngOnInit() {
    this.getLoyalityData()
    this.LoyalityTypeLogData()
  }
  LoyalityTypeLogData() {
    let LoyalityTypeLogs = {
      log_type:"LoyalityType Master View" ,
      log_name:"LoyalityType Master View" ,
      log_description:"LoyalityType Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(LoyalityTypeLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
}
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  getLoyalityData() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getLoyality().subscribe(data => {
      self.loyalityObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  deleteLoyality(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delLoyality(obj).subscribe(data => {
            if (data.status) {
              this.getLoyalityData();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully")
              }, 1000)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
          //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  editLoyality(id) {
    this.router.navigate(['/corporate/addnewloyality'], { queryParams: { loyality_id: id } });
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.loyalityObj.find(element => {
      //console.log(element.get_master_company_type.company_type_id)
      if (element.loyalty_type_id === id) {
        return true;
      }
    });
    //obj = obj.get_master_loyality_type
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.updateLoyality(obj).subscribe(data => {
            this.toastrService.success("Status Changed Successfully");
            this.getLoyalityData();
            this.ngxSpinnerService.hide();
          });
        }
        // else {
        //   this.master.updateLoyality(obj).subscribe(data => {
        //     // console.log(data)

        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //     this.getLoyalityData();
        //   });
        // }
      }).catch(() => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-loyalty-type-import.csv`, 'addingLoyalityTypeBulk')
      .then(data => {
     //   console.log(data);
        self.getLoyalityData();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_loyality_type_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
