import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.css']
})
export class PollComponent  extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  getCompanyobj: any = [];
  pollsData: any = [];
  resCatTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "title";
  constructor(private clientService: ClientService,
    private masterService:MasterService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getPolls();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getPolls()
  }
  getPolls() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getWebinarPollList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.pollsData = [] }
          else { this.pollsData = data.data.rows }
          this.resCatTotal = data.data.total;
        } else {
          this.pollsData = [];
          this.resCatTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.pollsData = [];
      this.selectedCompany = "";
      this.resCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }

  delePoll(ev,id) {
    this.confirmationDialogService
      .confirm(
        CommonMessages.confirmationTitle,
        "Are you sure you want to Delete?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .deletePoll(this.selectedCompany,id)
            .subscribe(
              (data) => {
                if (data.status) {
                  this.getPolls();
                  setTimeout(() => {
                    this.toastrService.success("Deleted Successfully");
                  }, 1000);
                  this.ngxSpinnerService.hide();
                } else {
                  this.ngxSpinnerService.hide();
                }
              },
              (error) => {
                this.toastrService.error(error.message);
              }
            );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
  updateStatus(event, id, poll) {
    event.preventDefault();
    let obj = {
      isactive: poll.is_active == true ? false : true,
      id: id,
      title: poll.title,
      description: poll.description
    };
    poll.isActive = poll.is_active == true ? false : true,
    poll.isCorporate = poll.is_corporate;
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status To" +
          (obj.isactive ? " Active?" : " In Active?"),
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .updatePollStatus(this.selectedCompany,id,poll)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Updated Successfully");
                this.getPolls();
              } else {
                this.toastrService.error(data.message);
              }
            });
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      })
      .catch(() => {
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        );
      });
  }
  editPoll(id:any){
    console.log("🚀 ~ file: poll.component.ts:165 ~ PollComponent ~ editPoll ~ id:", id)
    localStorage.setItem("edit_poll_id", id);
    localStorage.setItem("poll_edit_company_id", this.selectedCompany);
    this.router.navigate(['corporate/update-poll']);
  } 

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.masterService.getWebinarPollList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value,this.slCatColname,this.slCatord))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.pollsData = [] }
          else { this.pollsData = res.data.rows }
          this.resCatTotal = res.data.total;
        } else {
          this.pollsData = [];
          this.resCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.pollsData = [];
        this.resCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

}
