import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { environment } from '../../../../../environments/environment';
import { MetaDataService } from 'src/app/services/metadata/metadata.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-newproductcatalog',
  templateUrl: './newproductcatalog.component.html',
  styleUrls: ['./newproductcatalog.component.css']
})
export class NewproductcatalogComponent extends BaseComponent implements OnInit {
  productname;
  defaultImage = 'assedefault_product.JPG';
  theme_description;
  screenshot: String;
  IsActive;
  product_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey = '';
  them_awskey2 = '';
  them_awskey3 = '';
  them_awskey4 = '';
  them_awskey5 = '';
  them_company;
  getCompanyobj;
  getDSobj;
  theme_screenshot;
  theme_screenshot2;
  theme_screenshot3;
  theme_screenshot4;
  productImage1;
  productImage2;
  productImage3;
  productImage4;
  productImage5;
  status: boolean = true;
  screenshot_type = true;
  screenshot_type2 = true;
  screenshot_type3 = true;
  screenshot_type4 = true;
  screenshot_type5 = true;


  myFiles: string[] = [];

  headers = "Add";
  companyID;
  correctImage: boolean = true;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: FormGroup;
  myNewFiles: string[] = [];
  productSku = '';
  metaVisibility: boolean = false;
  metafields = [];
  metaForm: FormGroup;
  userProfileDetails: any;
  uid: any;
  page: any = 'master';


  productCataloguePath = environment.product_catalogue_path;
  business_domain_id: any;
  // productCataloguePath = 'Products/dev';
  domainData: any;


  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private metaService: MetaDataService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {

    this.page = this.master.getCataloguePage();

    let self = this;
    self.getCompany();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.product_id = params.id;
        this.companyID = params.companyID;

      });
    if (this.product_id) {
      this.UpdateProductCatalogLogData()
    }
    else {
      this.addProductCatalogLogData()
    }
    //get item details using id
    if (this.product_id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit";
      this.master.getProductCatalogById(this.companyID, this.product_id).subscribe(response => {
        //console.log('response111', response.data[0]);
        this.ngxSpinnerService.hide();
        if (response.status) {
          this.status = false;
          // console.log("product data",response.data);
          // console.log("product data",response.data.rows[0] );
          self.themObj = response.data.rows[0];
          this.business_domain_id=self.themObj.business_domain_id;
          console.log("self.themObj", self.themObj);
          self.dataBinding();
        } else {
        }
      });
    }
    this.themeForm = this.formBuilder.group({
      company: ['', Validators.required],
      dsid: [''],
      productname: ['', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]],
      productdescription: ['', [Validators.maxLength(2000)]],
      productsku: ['', [Validators.required]],
      productcategory: ['', [Validators.required]],
      productprice: ['', [Validators.required, Validators.min(1)]],
      productlink: ['', [Validators.required, this.ValidateUrl]],
      firstActive: [true],
      secondActive: [true],
      thirdActive: [true],
      fourthActive: [true],
      productPicture: [''],
      no_of_days_cross: [''],
      no_of_days_replenishment: [''],
      website_product_id:[''],
      productPicture2: [''],
      productPicture3: [''],
      productPicture4: [''],
      business_domain_id:['',[Validators.required]],
    });

    this.metaForm = this.formBuilder.group({
      metaFields: new FormArray([])
    });

    //let companyId = 62;
    if (this.product_id) {
      this.metaService.getMetaFieldsForUpdate(this.companyID, 3, this.product_id || 0).subscribe(data => {

        if (data.status) {
          this.metaVisibility = true;
          this.metafields = data.data;
          this.createMetaForm();
        }
        else {
          this.metaVisibility = false;
          this.metafields = [];
        }
      }, error => {
        this.metaVisibility = false;
        this.metafields = [];
        console.log(error);
      });
    }
    if(this.companyID){
      this.getBusinessDomain();
    }
  }


  get g() { return this.metaForm.controls; }
  get t() { return this.g.metaFields as FormArray; }

  //  ##############  META FORM #####################  //

  createMetaForm() {
    if (this.metafields.length > 0) {
      for (let mt of this.metafields) {
        let vl = mt.field_value;
        if (vl === null || vl === undefined) {
          vl = '';
        }
        if (mt.field_type === 'INT') {
          this.t.push(this.formBuilder.group({
            name: [mt.field_name],
            metafname: [vl, [Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(mt.field_size)]],
            lname: [mt.label_name],
            ftyp: 'Integer'
          }));
        } else {

          if (mt.field_type === 'NUMERIC') {
            this.t.push(this.formBuilder.group({
              name: [mt.field_name],
              metafname: [vl, [Validators.pattern(/^[0-9]\d*[.][0-9]\d*$/), Validators.maxLength(mt.field_size)]],
              lname: [mt.label_name],
              ftyp: 'Float'
            }));
          } else {
            if (mt.field_type === 'BOOLEAN') {
              let v = '';
              if (vl === true) {
                v = 'true';
              }
              if (vl === false) {
                v = 'false';
              }
              this.t.push(this.formBuilder.group({
                name: [mt.field_name],
                metafname: [v, this.checkBool],
                lname: [mt.label_name],
                ftyp: 'Boolean'
              }));
            } else {
              if (mt.field_type === 'timestamp') {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, [Validators.pattern(/^[1-9]([0-9]{3,3})[-][0-1][0-9]-[0-3][0-9][T][0-2][0-9][:][0-5][0-9][:][0-5][0-9][.]([0-9]{3,3})([Z])$/), Validators.maxLength(mt.field_size)]],
                  lname: [mt.label_name],
                  ftyp: 'Tmstamp'
                }));
              } else {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, Validators.maxLength(mt.field_size)],
                  lname: [mt.label_name],
                  ftyp: 'String'
                }));
              }

            }
          }
        }
      }
    }
  }

  /* Check for Boolean Validator Input */

  checkBool(control: FormControl) {
    let val = control.value;
    if (val === 'true' || val === 'false' || val === '') {
      return null;
    }
    return {
      boolCheck: {
        val: false
      }
    }


  }

  get f() { return this.themeForm.controls; }
  radioChange(type) {
    if (type == 'image1') {
      this.screenshot_type = !this.screenshot_type;
    }
    else if (type == 'image2') {
      this.screenshot_type2 = !this.screenshot_type2;
    }
    else if (type == 'image3') {
      this.screenshot_type3 = !this.screenshot_type3;
    }
    else if (type == 'image4') {
      this.screenshot_type4 = !this.screenshot_type4;
    }
    // else if (type == 'image5') {
    //   this.screenshot_type5 = !this.screenshot_type5;
    // }

  }

  addNewProduct() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { company, dsid, productPicture,productPicture2,productPicture3,productPicture4, productname, productdescription, productsku, productcategory, productprice, productlink, no_of_days_cross, no_of_days_replenishment,website_product_id,business_domain_id,firstActive, secondActive, thirdActive, fourthActive} = this.themeForm.value;

    productPicture = productPicture ? productPicture : this.them_awskey;

     productPicture2 = productPicture2 ? productPicture2 : this.them_awskey2 || '';
     productPicture3 = productPicture3 ? productPicture3 : this.them_awskey3 || '';
     productPicture4 = productPicture4 ? productPicture4 : this.them_awskey4 || '';
    //  productPicture5 = productPicture5 ? productPicture5 : this.them_awskey5 || '';

    let catalogObj = {
      name: productname,
      description: productdescription,
      sku: productsku,
      category: productcategory,
      price: productprice,
      link: productlink,
      isactive: true,
      company: company,
      image: productPicture,
      product_image2: productPicture2,
      product_image3: productPicture3,
      product_image4: productPicture4,
      // productPicture5: productPicture,
      no_of_days_cross: no_of_days_cross,
      no_of_days_replenishment: no_of_days_replenishment,
      ds_id: dsid,
      business_domain_id:Number(business_domain_id),
      website_product_id:website_product_id,
      is_image:firstActive==true?0:1,
      is_image2:secondActive==true?0:1,
      is_image3:thirdActive==true?0:1,
      is_image4:fourthActive==true?0:1,
    }

    // console.log("--catalogObj add object --", catalogObj);
    // return false;


    this.ngxSpinnerService.show();
    this.master.addingCatalog(catalogObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/productcatalog`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  addProductCatalogLogData() {
    let themeLogs = {
      log_type: "Adding New Product Catalog",
      log_name: "Adding New Product Catalog",
      log_description: "Adding New Product Catalog",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      // console.log(error);
    });
  }
  UpdateProductCatalogLogData() {
    let themeLogs = {
      log_type: "Updating Product Catalog Data",
      log_name: "Updating Product Catalog Data",
      log_description: "Updating Product Catalog Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      // console.log(error);
    });
  }
  dataBinding() {


    console.log(this.themObj);
    if (this.isUrlValid(this.themObj.product_image)) {
      this.theme_screenshot = this.isUrlValid(this.themObj.product_image) ? this.themObj.product_image : this.themObj.product_image;
    }
    if (this.isUrlValid(this.themObj.product_image2)) {
      this.theme_screenshot2 = this.isUrlValid(this.themObj.product_image2) ? this.themObj.product_image2 : this.themObj.product_image2;
    }
    if (this.isUrlValid(this.themObj.product_image3)) {
      this.theme_screenshot3 = this.isUrlValid(this.themObj.product_image3) ? this.themObj.product_image3 : this.themObj.product_image3;
    }
    if (this.isUrlValid(this.themObj.product_image4)) {
      this.theme_screenshot4 = this.isUrlValid(this.themObj.product_image4) ? this.themObj.product_image4 : this.themObj.product_image4;
    }
    /*
    if (this.isUrlValid(this.themObj.product_fancy_image_hd)) {
      this.productImage2 = this.isUrlValid(this.themObj.product_fancy_image_hd) ? this.themObj.product_fancy_image_hd : this.themObj.product_fancy_image_hd;
    }

    if (this.isUrlValid(this.themObj.product_fancy_image_sd)) {
      this.productImage3 = this.isUrlValid(this.themObj.product_fancy_image_sd) ? this.themObj.product_fancy_image_sd : this.themObj.product_fancy_image_sd;
    }

    if (this.isUrlValid(this.themObj.product_banner_image_sd)) {
      this.productImage4 = this.isUrlValid(this.themObj.product_banner_image_sd) ? this.themObj.product_banner_image_sd : this.themObj.product_banner_image_sd;
    }

    if (this.isUrlValid(this.themObj.product_image_thumb)) {
      this.productImage5 = this.isUrlValid(this.themObj.product_image_thumb) ? this.themObj.product_image_thumb : this.themObj.product_image_thumb;
    }
    */

    // this.status = true;
    //let setvalue=false;
    //console.log('isUrlValid', this.isUrlValid(this.themObj.theme_screenshot))


    // if (this.isUrlValid(this.themObj.productPicture)) {
    //   this.themeForm = this.formBuilder.group({
    //     company: [this.themObj.company_id.toString(), Validators.required],
    //     themename: [this.themObj.theme_name, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
    //     themedescription: [this.themObj.theme_description],
    //     firstActive: [true],
    //     themescreenshot: [this.themObj.theme_screenshot]
    //   });
    // } else {
    //   this.themeForm = this.formBuilder.group({
    //     company: [this.themObj.company_id.toString(), Validators.required],
    //     themename: [this.themObj.theme_name, Validators.required],
    //     themedescription: [''],this.themObj.theme_name, Validators.required
    //     firstActive: [false],
    //     themescreenshot: ['']
    //   });
    //   this.them_awskey = this.themObj.theme_screenshot;
    //   this.screenshot_type = false;
    // }

    // console.log("prodct object", this.themObj);
    // console.log("product", this.themObj.product_name);

    console.log("-this.themObj.ds_id-", this.themObj.ds_id);
    // get selected DS on edit
    this.getDSLIST(this.themObj.customer_id);

    this.themeForm = this.formBuilder.group({
      company: [this.themObj.customer_id],
      dsid: [this.themObj.ds_id],
      productname: [this.themObj.product_name],
      productdescription: [this.themObj.product_description],
      productsku: [this.themObj.product_sku, [Validators.required]],
      productcategory: [this.themObj.product_category, [Validators.required]],
      productprice: [this.themObj.product_price, [Validators.required, Validators.min(1)]],
      productlink: [this.themObj.product_link, [Validators.required, this.ValidateUrl]],
      no_of_days_cross: [this.themObj.number_of_days_crosssell, [Validators.min(0)]],
      no_of_days_replenishment: [this.themObj.number_of_days_replenishment, [Validators.min(0)]],
      firstActive: [this.themObj.is_image==0?true:false],
      secondActive: [this.themObj.is_image2==0?true:false],
      thirdActive: [this.themObj.is_image3==0?true:false],
      fourthActive: [this.themObj.is_image4==0?true:false],
      productPicture: [this.themObj.product_image],
      productPicture2: [this.themObj.product_image2],
      productPicture3: [this.themObj.product_image3],
      productPicture4: [this.themObj.product_image4],
      // productPicture5: [this.themObj.product_image],
      website_product_id:[this.themObj.website_product_id],
      business_domain_id:[String(this.themObj.business_domain_id),[Validators.required]]
    });
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      // console.log(error);
    });
  }


  getDSLIST(id) {
    this.companyID = id;
    if (this.page == 'direct-seller') {
      if (id != 0) {
        this.getDirectSeller();
      }
    }
  }


  getDirectSeller() {
    let self = this;
    this.ngxSpinnerService.show();
    //let company;
    let limit = 100;
    let skip = 0;
    this.clientService.getBUDSList(this.companyID, limit, skip).subscribe(data => {
      if (data.status) {
        self.getDSobj = data.data.rows;
      } else {
        self.getDSobj = [];
      }
      this.ngxSpinnerService.hide();
      //console.log("self.getDSobj", self.getDSobj);
    }, error => {
      //  console.log(error);
    });
  }


  updateProduct() {
    this.submitted = true;
    // console.log("--update object--", this.themeForm);
    // console.log("--update object--", this.themeForm.invalid);

    if (this.themeForm.invalid || !this.correctImage) {
      return;
    }

    let { company, dsid, productPicture,productPicture2,productPicture3,productPicture4, productname, productdescription, productsku, productcategory, productprice, productlink, no_of_days_cross, no_of_days_replenishment ,website_product_id,business_domain_id, firstActive, secondActive, thirdActive, fourthActive} = this.themeForm.value;




    // let productImg = '';
    // let productImg2 = '';
    // let productImg3 = '';
    // let productImg4 = '';
    // let productImg5 = '';

   if(this.screenshot_type && productPicture!=''){
     productPicture = this.themeForm.value.productPicture;
   }
   else{
    if (this.isUrlValid(this.them_awskey)) {
      productPicture = this.them_awskey;

    } else {
      productPicture = this.themObj.product_image;
    }
   }

    productPicture = productPicture ? productPicture : this.them_awskey;
    productPicture2 = productPicture2 ? productPicture2 : this.them_awskey2 || '';
    productPicture3 = productPicture3 ? productPicture3 : this.them_awskey3 || '';
    productPicture4 = productPicture4 ? productPicture4 : this.them_awskey4 || '';
    // productPicture5 = productPicture5 ? productPicture5 : this.them_awskey5 || '';

    let catalogObj = {
      product_catalog_id: this.product_id,
      company: company,
      ds_id: dsid,
      name: productname,
      description: productdescription,
      sku: productsku,
      category: productcategory,
      price: productprice,
      link: productlink,
      isactive: true,
      image: productPicture,
      product_image2: productPicture2,
      product_image3: productPicture3,
      product_image4: productPicture4,
      // productPicture5: productPicture,
      no_of_days_cross: no_of_days_cross,
      no_of_days_replenishment: no_of_days_replenishment,
      business_domain_id:business_domain_id,
      website_product_id:website_product_id,
      is_image:firstActive==true?0:1,
      is_image2:secondActive==true?0:1,
      is_image3:thirdActive==true?0:1,
      is_image4:fourthActive==true?0:1,
    }

    // console.log("---CatalogObj---", catalogObj);
    // return false;

    this.ngxSpinnerService.show();
    this.master.UpdateCatalog(catalogObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/productcatalog`);
        this.ngxSpinnerService.hide();


      } else {


        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message)
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    if (control.value != "") {
      return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
    }
  }


  processFile(event: any, imageInput: any, productImageType) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      this.correctImage = false;
      return false;
    } else {
      let productSku;
      let newFileName;
      if (this.themeForm.value.productsku != '') {
        productSku = this.themeForm.value.productsku;
        newFileName = productSku + '.' + file.name.split('.').pop();

      }

      else {
        this.toastrService.error("Before that please enter product sku");
        return;
      }

      // return false;
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(newFileName, reader.result, productImageType);
      }
      reader.readAsDataURL(file);
      this.correctImage = true;
    }
  }

  toUploadFiles(newFileName, file, productImageType) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(newFileName,this.productCataloguePath,file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            if(productImageType=='image1'){
              self.them_awskey = params.Location;
              this.theme_screenshot=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image2'){
              self.them_awskey2 = params.Location;
              this.theme_screenshot2=`${self.them_awskey2}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image3'){
              self.them_awskey3 = params.Location;
              this.theme_screenshot3=`${self.them_awskey3}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image4'){
              self.them_awskey4 = params.Location;
              this.theme_screenshot4=`${self.them_awskey4}?timestamp=${new Date().getTime()}`;
            }
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  processProductFile(event: any, imageInput: any,imageType) {
    const file: File = imageInput.files[0];
    let old_fileName = file.name;
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      this.correctImage = false;
      return false;
    } else {

      let productSku;
      if ((this.themObj) && (this.headers == 'Edit')) {
        productSku = this.themObj.product_sku;
      }

      this.myFiles.push(productSku);
      let body = {
        company: this.companyID,
        product: this.myFiles,
        product_path: this.productCataloguePath,

        page: 'edit',
        old_fileName: old_fileName,

        product_id: this.product_id,

      }


      this.master.renameProductImage(body).subscribe(data => {
        if (data.status) {
          let product_name = data.result[0].product_name;
          const reader = new FileReader();
          reader.onload = (event1) => {
            this.toUploadProductFiles(product_name,reader.result,imageType);
          }
          reader.readAsDataURL(file);
          this.correctImage = true;
        }

      });
    }
  }

  toUploadProductFiles(product_name,file,productImageType) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(product_name,this.productCataloguePath,file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            if(productImageType=='image1'){
              self.them_awskey = params.Location;
              this.theme_screenshot=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image2'){
              self.them_awskey2 = params.Location;
              this.theme_screenshot2=`${self.them_awskey2}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image3'){
              self.them_awskey3 = params.Location;
              this.theme_screenshot3=`${self.them_awskey3}?timestamp=${new Date().getTime()}`;
            }else if(productImageType=='image4'){
              self.them_awskey4 = params.Location;
              this.theme_screenshot4=`${self.them_awskey4}?timestamp=${new Date().getTime()}`;
            }
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  setThemeForm(key) {
    this.them_awskey = key;
  }


  updateMetaFields() {
    if (this.metaForm.invalid) {
      this.toastrService.error('Please provide correct Meta Field Values');
      return;
    }
    this.ngxSpinnerService.show();
    let fnarr = [];
    for (let fd of this.t.controls) {
      let fn = fd['controls'].name.value;
      let mfnm = fd['controls'].metafname.value;
      if (fd['controls'].metafname.value !== null || fd['controls'].metafname.value !== undefined) {
        if (typeof mfnm === 'string') {
          mfnm = mfnm.trim();
        }

      }
      let obj = {
        field_name: fn,
        field_value: mfnm,
        isAdmin: true,
      }
      fnarr.push(obj);
    }
    this.ngxSpinnerService.hide();

    this.metaService.updateProfileMetaFields(this.companyID, 3, this.product_id, fnarr).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Meta Fields Updated Successfully');
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl(`/corporate/productcatalog`);
        //this.router.navigateByUrl(this.inputdata.navurl);
      }
      else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.error('Error while saving Meta Fields');
      this.ngxSpinnerService.hide();
      console.log(error);
    });
  }
  getDsDomain(event){
    let domain=this.getDSobj.find(domain=>domain.user_ds_id==event.target.value);
    this.business_domain_id=domain.business_domain_id;
  }
  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.companyID).subscribe(data => {
      self.domainData = data.data.rows;
      //console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
}
