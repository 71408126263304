import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-add-bu-entity',
  templateUrl: './add-bu-entities-segmentation.component.html',
  styleUrls: ['./add-bu-entities-segmentation.component.css']
})
export class AddBuEntitiesSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  entId: any;
  ent: any;
  header = 'Add';
  status = true;
  submitted = false;
  entForm: FormGroup;

  constructor(private segmentService: BUSegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.entId = params.entId;
      });
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.entForm = this.formBuilder.group({
      entname: ['', Validators.required],
      entdesc: ['']
    });
    if (this.entId) {
      this.header = 'Edit';
      this.segmentService.getBUEntityById(this.buid, this.entId).subscribe(data => {
        if (data.status){
          this.ent = data.data[0];
          this.status = false;
          this.entForm.get('entname').patchValue(this.ent.entity_name);
          this.entForm.get('entdesc').patchValue(this.ent.entity_desc);
        } else {
          this.router.navigateByUrl('/corporate/bu-entities');
        }
        this.ngxSpinnerService.hide();

      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/bu-entities');
      });
    }
  }

  get f() { return this.entForm.controls; }

  //  ##############  Add New BU Entity ###################  //

  addNewBUEntity(){
    this.submitted = true;
    if (this.entForm.invalid) {
      return;
    }
    let entnm = this.entForm.value.entname.trim();
    if (entnm === '' || entnm === null || entnm === undefined){
      this.toastrService.error('Please provide a valid Entity Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.buid,
      'entity_name': this.entForm.value.entname.trim(),
      'entity_desc': this.entForm.value.entdesc.trim(),
      'status': true
    };

    this.segmentService.addNewBUEntity(obj).subscribe(data => {
      if (data.status){
        this.toastrService.success('Entity added successfully');
        this.router.navigateByUrl('/corporate/bu-entities');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Update BU Entity  ##############  //

  updateBUEntity(){
    this.submitted = true;
    if (this.entForm.invalid) {
      return;
    }
    let entnm = this.entForm.value.entname.trim();
    if (entnm === '' || entnm === null || entnm === undefined){
      this.toastrService.error('Please provide a valid Entity Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.buid,
      'entity_name': this.entForm.value.entname.trim(),
      'entity_desc': this.entForm.value.entdesc.trim(),
      'status': true
    };

    this.segmentService.updateBUEntity(obj, this.entId).subscribe(data => {
      if (data.status){
        this.toastrService.success('Entity updated successfully');
        this.router.navigateByUrl('/corporate/bu-entities');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

}
