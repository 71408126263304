import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";
import { CommonMessages } from "src/app/shared/messages/messages.enum";

@Component({
  selector: "app-resource-list",
  templateUrl: "./resource-list.component.html",
  styleUrls: ["./resource-list.component.css"],
})
export class ResourceListComponent extends BaseComponent implements OnInit {
  @ViewChild("searchText", { static: true }) searchText: ElementRef;
  currentUser: any;
  companyID = 0;
  selectedCompany: any = "";
  getCompanyobj: any = [];
  readinessData: any = [];
  readTotal: number = 0;
  readPage = 1;
  readLimit = 10;
  readSkip = 0;
  constructor(
    private clientService: ClientService,
    private masterService: MasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    if (this.currentUser.admin_customer_id != "0") {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getReadinessData();
    }
    if (this.currentUser.admin_customer_id == "0") this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
        this.ngxSpinnerService.hide();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getReadinessData() {
    let searchTerm = this.searchText.nativeElement.value;
    this.readSkip = (this.readPage - 1) * this.readLimit;
    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();
      let body = {
        limit: this.readLimit,
        offset: this.readSkip,
        key: searchTerm || "",
      };
      this.masterService
        .getReadinessResourcesList(this.selectedCompany, body)
        .subscribe(
          (data) => {
            this.ngxSpinnerService.hide();
            if (data.status) {
              if (data.data.rows == null) {
                this.readinessData = [];
                this.readTotal = 0;
              } else {
                this.readinessData = data.data.rows;
                this.readTotal = data.data.total;
              }
            } else {
              this.readinessData = [];
              this.readTotal = 0;
            }
          },
          (error) => {
            this.ngxSpinnerService.hide();
          }
        );
    } else {
      this.readinessData = [];
      this.selectedCompany = "";
      this.readTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  delCategory(event, id) {
    event.preventDefault();
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "ARE YOU SURE YOU WANT TO DELETE ?",
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .delReadinessResources(this.selectedCompany, id)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Deleted Successfully");
                this.getReadinessData();
              } else {
                this.toastrService.error(data.message);
              }
              this.ngxSpinnerService.hide();
            });
        }
      })
      .catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = {
      isactive: status == true ? false : true,
      id: id,
    };
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status To" +
          (obj.isactive ? " Active?" : " In Active?"),
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService
            .updateResourcesStatus(this.selectedCompany, obj)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Updated Successfully");
                this.getReadinessData();
              } else {
                this.toastrService.error(data.message);
              }
              this.ngxSpinnerService.hide();
            });
        }
      })
      .catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  editPost(id: any) {
    localStorage.setItem("readiness_id", id);
    localStorage.setItem("readiness_company_id", this.selectedCompany);
    this.router.navigate(["/corporate/readiness-resources-edit"]);
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = "";
    fromEvent(this.searchText.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.readSkip = 0;
          this.readPage = 1;
        }),
        switchMap((data) => {
          let body = {
            limit: this.readLimit,
            offset: this.readSkip,
            key: this.searchText?.nativeElement.value || "",
          };
          this.ngxSpinnerService.show();
          return this.masterService.getReadinessResourcesList(
            this.selectedCompany,
            body
          );
        })
      )
      .subscribe(
        (data) => {
          if (data.status) {
            if (data.data.rows == null) {
              this.readinessData = [];
              this.readTotal = 0;
            } else {
              this.readinessData = data.data.rows;
              this.readTotal = data.data.total;
            }
          } else {
            this.readinessData = [];
            this.readTotal = 0;
          }
          this.ngxSpinnerService.hide();
        },
        (error) => {
          this.readinessData = [];
          this.selectedCompany = "";
          this.readTotal = 0;
          this.ngxSpinnerService.hide();
        }
      );
  }
}
