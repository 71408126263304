import { Component, OnInit, PipeTransform } from '@angular/core';
import { ReportService } from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientService } from 'src/app/services/clients/client.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-report-ecom-detail',
  templateUrl: './report-ecom-detail.component.html',
  styleUrls: ['./report-ecom-detail.component.css']
})
export class ReportEcomDetailComponent implements OnInit {
  reportData: any[] = [];
  companyID: any = 0;
  companyList: any = [];
  limit: number = 15;
  page: number = 1;
  totalPage: number = 0;
  totalRecords: number = 0;
  currentUser: any;
  showResult: boolean = false;
  start_date;
  end_date;
  customerID;
  isHeadquater

  constructor(private report: ReportService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private clientService: ClientService) { }

  ngOnInit() {
    this.spinner.show();

    // this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    //this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    this.companyID = this.customerID;
    this.isHeadquater = localStorage.getItem("customer_isheadquarter");

    if (this.companyID == 0 || this.isHeadquater === "true") {
      this.isHeadquater = true;
      this.getAllBU();
      this.companyID = 0;

    }
    else {
      this.isHeadquater = false;
      this.fetchCustomerData(this.page, this.companyID);
      this.showResult = true;
    }


  }

  getAllBU() {
    this.spinner.show();
    this.clientService.getBUCompanies().subscribe(data => {
      this.companyList = data.data;

      this.spinner.hide();
      // console.log(this.companyList);
    }, error => {
      this.spinner.hide();
      // console.log(error);
    });
  }

  fetchCustomerData(page = 1, id) {


    this.page = 1;
    this.changePage(this.page, id)

  }

  changePage(page, id) {

    if (id != 0) {
      this.page = page;
      this.spinner.show();
      this.report.geteCommDetailReport(this.companyID, this.limit, (this.page - 1) * this.limit).subscribe(data => {
        if (data.data) {
          this.showResult = true;
          this.reportData = data.data;
          this.totalRecords = data.total_count;
          let totalPage = data.total_count;
          this.totalPage = Math.floor(data.total_count / this.limit) + (data.total_count % this.limit == 0 ? 0 : 1);
          this.spinner.hide();
        } else { this.showResult = false; this.spinner.hide(); }
        //  console.log(this.companyList);
      }, error => {
        this.spinner.hide();
        // console.log(error);
      });

    }
    else {
      this.showResult = false;

    }
  }

}
