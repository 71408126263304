<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness">Readiness Module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Readiness Resources</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getReadinessData()"
          [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText />
      </div>
    </div>

    <div class="col-md-4 mt-md-0 mt-2" style="justify-content: right">
      <input type="button" value="Add New" class="custbtn mt-md-1 mt-1 ml-0"
        routerLink="/corporate/readiness-resources-add" />
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Image</th>
        <th>Category</th>
        <th>Sub Category</th>
        <th>Title</th>
        <th>Description</th>
        <th>
          Created On
        </th>
        <th>Actions</th>
      </tr>
      <ng-container *ngFor="let category of readinessData">
        <tr>
          <td></td>
          <td>{{category?.title}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{category?.createdon | date: "mediumDate"}}</td>
          <td></td>
        </tr>
        <ng-container *ngFor="let sub of category?.subcategory">
          <tr>
            <td></td>
            <td></td>
            <td>{{sub?.title}}</td>
            <td></td>
            <td></td>
            <td>{{sub?.createdon | date: "mediumDate"}}</td>
            <td></td>
          </tr>
          <ng-container *ngFor="let res of sub?.resources">
            <tr *ngIf='res'>
              <td>
                <img *ngIf="res?.type=='image'" [src]="res?.link" style="height:40px; max-width:50px">
                <a *ngIf="res?.type=='pdf'" target='_blank' [href]='res?.link'>
                  <img src="/assets/custom/images/pdf.png" alt="marGo" style="height:40px; max-width:50px" />
                </a>
              </td>
              <td></td>
              <td></td>
              <td>{{res?.title}}</td>
              <td>{{res?.description}}</td>
              <td>{{res?.createdon | date: "mediumDate"}}</td>
              <td>
                <div class="actBtn">
                  <i *ngIf="isWrite" class="fa fa-edit mr-1 linkForce" (click)="editPost(res.id)"></i>
                  <i *ngIf="isWrite" class="fa fa-trash mr-1 linkForce" (click)="delCategory($event, res?.id)"></i>
                  <mat-slide-toggle *ngIf="isWrite" class="my-slider" [checked]="res?.status"
                    (click)="updateStatus($event, res?.id, res?.status)">
                  </mat-slide-toggle>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </table>
    <div *ngIf="readTotal == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="readTotal > 10">
      <ngb-pagination [collectionSize]="readTotal" [(page)]="readPage" [maxSize]="2" [rotate]="true"
        (pageChange)="getReadinessData(readPage=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
