<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness">Readiness Module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Readiness Activity</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getReadinessData()"
          [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText />
      </div>
    </div>

    <div class="col-md-4 mt-md-0 mt-2" style="justify-content: right">
      <input type="button" value="Add New" class="custbtn mt-md-1 mt-1 ml-0"
        routerLink="/corporate/readiness-activity-add" />
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Categroy
          <!-- <i class="fa fa-sort text-black" aria-hidden="true"></i> -->
        </th>
        <th>Sub Category</th>
        <th>Description</th>
        <th class='text-right'>Weight</th>
        <th>
          Created On
        </th>
        <th>Actions</th>
      </tr>
      <ng-container *ngFor="let category of readinessData">
        <tr>
          <td>{{category?.title}}</td>
          <td></td>
          <td>{{category?.description}}</td>
          <td class='text-right'>{{category?.weight}}</td>
          <td>{{category?.createdon | date: "mediumDate"}}</td>
          <td>
            <div class="actBtn">
              <i *ngIf="isWrite" class="fa fa-edit mr-1 linkForce" (click)="editPost(category.id)"></i>
              <i *ngIf="isWrite" class="fa fa-trash mr-1 linkForce" (click)="delCategory($event, category.id)"></i>
              <mat-slide-toggle *ngIf="isWrite" class="my-slider" [checked]="category.status"
                (click)="updateStatus($event, category.id, category.status)">
              </mat-slide-toggle>
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let sub of category?.subcategory">
          <tr>
            <td></td>
            <td>{{sub?.title}}</td>
            <td>{{sub?.description}}</td>
            <td class='text-right'>{{sub?.weight}}</td>
            <td>{{sub?.createdon | date: "mediumDate"}}</td>
            <td>
              <div class="actBtn">
                <i *ngIf="isWrite" class="fa fa-edit mr-1 linkForce" (click)="editPost(sub.id)"></i>
                <i *ngIf="isWrite" class="fa fa-trash mr-1 linkForce" (click)="delCategory($event, sub.id)"></i>
                <mat-slide-toggle *ngIf="isWrite" class="my-slider" [checked]="sub.status"
                  (click)="updateStatus($event, sub.id, sub.status)">
                </mat-slide-toggle>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
    <div *ngIf="readTotal == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="readTotal > 10">
      <ngb-pagination [collectionSize]="readTotal" [(page)]="readPage" [maxSize]="2" [rotate]="true"
        (pageChange)="getReadinessData(readPage=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
