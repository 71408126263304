<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Import Geo Location CSV File</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
      <form (submit)="uploadFile()" enctype="multipart/form-data">
        <div class="input-group">
          <div class="input-group-prepend">
            <!-- <span class="input-group-text cyan lighten-2" id="csvFileAddon01">Upload</span> -->
            <button type="submit" class="input-group-text white" id="csvFileAddon01">Upload</button>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="csvFile" name="csvFile" aria-describedby="csvFileAddon01" (change) = "handleFileInput($event.target.files)">
            <label class="custom-file-label" for="csvFile">{{csvFileName}}</label>
          </div>
        </div>
        
      </form>
    </div>
  </div>
</div>
