import { Component, OnInit } from '@angular/core';

import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';


@Component({
  selector: 'app-add-operator-group',
  templateUrl: './add-operator-group.component.html',
  styleUrls: ['./add-operator-group.component.css']
})


export class AddOperatorGroupComponent extends BaseComponent implements OnInit {


  operator_group_id; 
  operatorGroupObj;  
  operatorGroupForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
 

  constructor(
  
    private formBuilder: FormBuilder,  
    public toaster: ToastrService,
    private segmentService: SegmentationService

  ) {
    super();
  }

  ngOnInit() {


    //this.ngxSpinnerService.show();
   
    let self = this;
   

    if(localStorage.getItem("deleteOperatorId")) this.operator_group_id = localStorage.getItem("deleteOperatorId")
    if (this.operator_group_id) {
      this.ngxSpinnerService.show();
      this.UpdateOperatorGroupLogData()
     
     
      this.segmentService.getOperatorGroupId(this.operator_group_id).subscribe(response => {
        
        if (response.status) {   
          this.actionStatus = false;      
          self.operatorGroupObj = response.data[0];
          this.status =   self.operatorGroupObj.isactive    
          self.dataBinding();

        } 
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addOperatorGroupLogData()
    }
    this.operatorGroupForm = this.formBuilder.group({
      group_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      group_desc: ['']
     
    });
  }
  get f() { return this.operatorGroupForm.controls; }

 

  addOperatorGroupLogData() {

    let operatorGroupLogs = {
      log_type: "Adding New Operator Group",
      log_name: "Adding New Operator Group",
      log_description: "Adding New Operator Group",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(operatorGroupLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
   //   console.log(error);
    });

  }
  
  UpdateOperatorGroupLogData() {

    let operatorGroupLogs = {
      log_type: "Updating Operator Group",
      log_name: "Updating Operator Group",
      log_description: "Updating Operator Group",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(operatorGroupLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  addOperatorGroup() {
    this.submitted = true;
   
    if (this.operatorGroupForm.invalid) {
      return;
    }

    let { group_name, group_desc} = this.operatorGroupForm.value;


    this.ngxSpinnerService.show();
    
    let operatorGroupBody = {
      group_name: group_name,
      group_desc: group_desc,
      status:true

    }

    this.segmentService.addingOperatorGroup(operatorGroupBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/operator-group`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
 
  dataBinding() {
    
      this.operatorGroupForm = this.formBuilder.group({
        group_name: [this.operatorGroupObj.group_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        group_desc: [this.operatorGroupObj.group_desc],       
       
       
      });
     
  }
  UpdateOperatorGroup() {
  
    this.submitted = true;
    if (this.operatorGroupForm.invalid) {
      return;
    }
    let { group_name, group_desc} = this.operatorGroupForm.value;

    let operatorGroupBody = {
      group_name: group_name,
      group_desc: group_desc,
      status:this.status
    
    }

    this.ngxSpinnerService.show();
    this.segmentService.updateOperatorGroup(operatorGroupBody,this.operator_group_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/operator-group`);
        
      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
