import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportService } from 'src/app/services/support/support.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-ds-approval-list',
  templateUrl: './ds-approval-list.component.html',
  styleUrls: ['./ds-approval-list.component.css']
})
export class DsApprovalListComponent extends BaseComponent implements OnInit {
  DSApprovalList: any = [];

  isFetching = false;


  constructor(private supportService: SupportService) { 
    super();
  }

  ngOnInit() {
    this.fetchDSApprovalList();
  }

  async fetchDSApprovalList() {
    this.ngxSpinnerService.show();
    try {
      this.isFetching = true;
      const response = await this.supportService.getDSApprovalList().toPromise();
      this.ngxSpinnerService.hide();
      this.isFetching = false;
      this.DSApprovalList = response.data;
    } catch (error) {
      this.isFetching = false;
      this.ngxSpinnerService.hide();
    }
  }

  async giveAccess(status, item) {
    this.ngxSpinnerService.show();
    const request = { "supportId": item.support_id, "accessValue": status }
    try {
      const response = await this.supportService.allowDenyAccess(request).toPromise();
      this.ngxSpinnerService.hide();
      if(response.message == 'Allowed') {
        this.toastrService.success('Access Allowed!');
      }else {
        this.toastrService.success('Access Denied!');
      }
      this.fetchDSApprovalList();
    } catch(error) {
      this.ngxSpinnerService.hide();
    }
  }

}
