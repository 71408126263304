<div class="container mt-2">
  <h5 class="font-weight-bold mt-4 mb-4">Scheduler</h5>

  <div class="row mt-3 " *ngIf="status && schStatus">
    <div class="col-12">
      <div class="tblPrt ">
        <table class="mt-1 bdrStCustom ml-6" width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <th >Schedule Start <i class="fa"></i></th>
            <th >Count</th>
            <th >Timezone</th>
            <th >Schedule End</th>
            <th >Interval Type</th>
            <th >Interval Value</th>
            <th >View</th>
          </tr>
          <tr *ngFor="let obj of mCschedulerData">
            <td >
              <div>{{obj.schedule_start|date}}</div>
              <div>{{obj.schedule_start|date: 'shortTime'}}</div>
            </td>
            <td>{{obj.total_add_count}}</td>
            <td >{{obj.schedule_timezone}}</td>
            <td>
              <div>{{obj.schedule_end|date}}</div>
              <div>{{obj.schedule_end|date: 'shortTime'}}</div>
            </td>
            <td>{{obj.schedule_interval_type}}</td>
            <td>{{obj.schedule_interval_value}}</td>
            <td><a (click)="goToSchedulerStats()">View Scheduler Status</a></td>
          </tr>

        </table>
      </div>
    </div>
    <div class="my_class text-center " *ngIf="isWrite" >
      <button type="submit" class="custbtn"  (click)="viewEditSchedule()" >Update Schedule</button>
      <button type="submit" class="custbtn"  (click)="goToMcInformation()" >Home</button>
      <button type="button" class="custbtn"  (click)="goToMcDataMapping()">Mapping</button>

    </div>
  </div>

  <form class="form-horizontal mt-1 ml-2" [formGroup]="mcschedulerform" *ngIf="status && !schStatus">
    <div class="row">
      <div class="col-xl-10">

        <div class="card mt-1">

          <div class="card-body">

            <div class="row">

              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-md-3">
                    <h6 class="font-weight-bold" for="mcschedulerform">Select Schedule Type <span style="color:red">*</span></h6>
                  </div>
                  <div class="col-md-9">
                    <p [ngClass]="{ 'is-invalid': submitted && f.scheduleType.errors }">
                      <input type="radio" value="true" formControlName="scheduleType"> Refresh
                      <input type="radio" value="false" formControlName="scheduleType" class="ml-2"> Run Once
                    </p>
                  </div>
                </div>


              </div>
              <div *ngIf="submitted && f.scheduleType.errors" class="invalid-feedback">
                <div *ngIf="f.scheduleType.errors.required">Schedule Type is required</div>
              </div>



              <div class="col-12" *ngIf="this.mcschedulerform.value.scheduleType === 'true'">
                <div class="form-group row">
                  <div class="col-md-3">

                    Refresh Date
                    <span class="redMand">*</span>
                  </div>
                  <div class="col-md-9">
                    <div class="row">

                      <div class="col-4 col-sm-3 schedCal">

                        <input class="form-control" matInput [min]="minStartDate" [matDatepicker]="picker2" readonly
                               formControlName="scheduleStartDate" [max]="maxdate"
                               [ngClass]="{ 'is-invalid': submitted && f.scheduleStartDate.errors }">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="cldrIcon"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <div *ngIf="submitted && f.scheduleStartDate.errors" class="invalid-feedback">
                          <div *ngIf="f.scheduleStartDate.errors.required">Start Date is required</div>
                          <div *ngIf="f.scheduleStartDate.value.getTime() < minStartDate.getTime()">Start Date should be greater than current Date</div>
                        </div>
                      </div>
                      <div class="widhtFd-150" *ngIf="this.mcschedulerform.value.scheduleType === 'true'">
                        <p class="atWTime">at</p>
                      </div>


                      <div class="col-6 col-sm-5 scheduleTm">
                        <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="stTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
                      </div>

                    </div>
                  </div>


                </div>


              </div>

              <div class="col-12 " *ngIf="this.mcschedulerform.value.scheduleType === 'true'">
                <div class="row">
                  <div class="col-md-3">
                    Time Zone
                    <span class="redMand">*</span>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-9">


                        <select
                          formControlName="tzone"
                          autocomplete="off"
                          class="form-control formstyle "
                          [ngClass]="{ 'is-invalid': submitted && f.tzone.errors }">
                          <option value="">Select Time Zone</option>
                          <option *ngFor="let tz of timezones" value="{{tz.value}}">{{tz.text}}</option>
                        </select>
                        <div *ngIf="submitted && f.tzone.errors" class="invalid-feedback">
                          <div *ngIf="f.tzone.errors.required">Time Zone is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div class="col-12 mt-3" *ngIf="this.mcschedulerform.value.scheduleType === 'true'">
                <div class="row">
                  <div class="col-md-3">
                    Frequency
                    <span class="redMand">*</span>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="widhtFd-150 mt-2">
                        <p class="">Every</p>
                      </div>
                      <div class="col-2">
                        <input type="text" (keydown)="isNumber($event)" class="form-control" name="frequency" formControlName="freq"
                               [ngClass]="{ 'is-invalid': submitted && f.freq.errors }" />
                        <div *ngIf="submitted && f.freq.errors" class="invalid-feedback">
                          <div *ngIf="f.freq.errors.required"> This field is required</div>
                        </div>
                      </div>


                      <div class="col-md-3 col-4 col-xl-3">

                        <select
                          formControlName="freqType"
                          autocomplete="off"
                          class="form-control formstyle"
                          [ngClass]="{ 'is-invalid': submitted && f.freqType.errors }">
                          <option value="Day">Day</option>
                          <option value="Hour">Hour</option>
                          <option value="Minute">Minute</option>
                          <option value="Week">Week</option>
                        </select>
                        <div *ngIf="submitted && f.freqType.errors" class="invalid-feedback">
                          <div *ngIf="f.freqType.errors.required">This Field is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div class="col-12 mt-3" *ngIf="this.mcschedulerform.value.scheduleType === 'true'">
                <div class="row">
                  <div class="col-md-3">
                    End Schedule
                    <span class="redMand">*</span>
                  </div>
                  <div class="col-md-9">
                    <div class="row">

                      <div class="col-4 col-sm-3 schedCal">
                        <input class="form-control" matInput [min]="minEndDate" [matDatepicker]="picker3" readonly
                               formControlName="scheduleEndDate" [max]="maxdate"
                               [ngClass]="{ 'is-invalid': submitted && f.scheduleEndDate.errors }">
                        <mat-datepicker-toggle matSuffix [for]="picker3" class="cldrIcon"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <div *ngIf="submitted && f.scheduleEndDate.errors" class="invalid-feedback">
                          <div *ngIf="f.scheduleEndDate.errors.required">End Date is required</div>
                          <div *ngIf="f.scheduleEndDate.value.getTime() < minEndDate.getTime()">End Date should be greater than current Date</div>
                        </div>
                      </div>
                      <div class="widhtFd-150">
                        <p class="atWTime">at</p>
                      </div>

                      <div class="col-6 col-sm-5 scheduleTm">
                        <ngb-timepicker [readonlyInputs]="'readOnly'"  [meridian]="true" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" [minuteStep]="5"></ngb-timepicker>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
              <div class="col-12 mt-2" *ngIf="this.mcschedulerform.value.scheduleType === 'false'">
                <p>*This Automation will run only once upon saving.</p>
              </div>
            </div>
          </div>

          <div class="card-footer text-center mt-1" >
            <div class="my_class text-center" *ngIf="isWrite" >
              <button type="submit" class="custbtn"  (click)="submitSchedule()" *ngIf="mCschedulerData.length === 0">Submit</button>
              <button type="submit" class="custbtn"  (click)="updateSchedule()" *ngIf="mCschedulerData.length > 0">Update</button>
              <button type="submit" class="custbtn"  (click)="cancelUpdateSchedule()" *ngIf="mCschedulerData.length > 0">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>

