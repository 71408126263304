import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
import { DsuserService } from 'src/app/services/users/dsuser.service';

@Component({
  selector: 'app-add-journey-span',
  templateUrl: './add-journey-span.component.html',
  styleUrls: ['./add-journey-span.component.css']
})
export class AddJourneySpanComponent implements OnInit {

  journeySpanForm: FormGroup
  companyId: any='';
  journeyId: any='';
  dsId: any='';
  spanTime: any='';
  screenShot: any='';

  header = 'Add';
  submitted: boolean = false;
  getCompanyobj: any[] = []; // Stores all company dropdown list
  journeyObj: any[] = []; // Stores all journeies for selected company
  dsUserObj: any[] = []; // Store All ds user for selected company
  screenshotPreview: string;
  imgpreview: any;
  journeySpanId:any;


  constructor(
    private fb: FormBuilder, private ngxSpinnerService: NgxSpinnerService, private clientService: ClientService,
    private master: MasterService, private dsUserService: DsuserService, private upload: UploadService,
    private toastrService: ToastrService, private router: Router,private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
 
    this.activatedRoute.queryParams
    .subscribe(params => {
      this.journeySpanId = params.journey_span_id;
      this.companyId = params.company;
    });
    if(this.journeySpanId){
      this.header='Edit';
      this.getJourneySpanData();
      this.onSelectCompany(this.companyId);
    }

    this.initiateJourneySpanForm();
    this.getCompany();
  }
  getCompany() {
    let self = this;
    this.ngxSpinnerService.show();
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;   
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  onSelectCompany(id) {
    this.companyId = id;
    let self = this;
    self.journeyObj = [];
    this.ngxSpinnerService.show();
    this.master.getJourneyAll(id,1000,0,'journey_name','asc','').subscribe(res => {
      if(res.data){
      this.journeyObj = res.data?.rows.filter(item=>item.journey_parent_id == 0 );
      this.getCompanyDs();
      }else{
        this.journeyObj=[];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.journeyObj=[];
      this.ngxSpinnerService.hide();
    });

  }
  getCompanyDs() {
    this.ngxSpinnerService.show();
    this.clientService.getDsByCompanyId(this.companyId,'contactname','asc').subscribe(data => {
      this.dsUserObj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }

  initiateJourneySpanForm() {
    this.journeySpanForm = this.fb.group({
      companyId: ['', Validators.required],
      journeyId: ['', Validators.required],
      dsId: ['', Validators.required],
      spanTime: ['', Validators.required],
      screenShot: ['']
    })
  }
  resetForm() {
    this.companyId = '',
      this.journeyId = '',
      this.dsId = '',
      this.spanTime = '',
      this.screenShot = ''
  }
  get f() { return this.journeySpanForm.controls; }

  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      // this.correctImage = false;
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
      // this.correctImage = true;
    }
  }

  toUploadFiles(newFileName: any, file: any) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(newFileName, 'master_images', file).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide()
        if (res.status) {
          let params = res.data;
          if (params.Key) {
            self.screenShot = params.Key;
            // self.imgpreview = params.Location;
          }
        } else {
          this.toastrService.error('Something went wrong !')
        }
      },
      error: (err: any) => {
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.screenShot = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  getJourneySpanData() {
    this.ngxSpinnerService.show();
    this.master.getJourneySpan(this.journeySpanId,this.companyId).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide();
        this.journeyId = res.data.journey_id;
        this.dsId = res.data.ds_id;
        this.spanTime = res.data.no_of_days;
        this.screenShot = res.data.journey_screen_shot;
        // this.imgpreview = res.data.journey_screen_shot;
      },
      error:(error:any)=>{
        this.ngxSpinnerService.hide();
      }
    })
  }

  addJourneySpan() {
    this.submitted = true;
    if (this.journeySpanForm.invalid) {
      console.log(this.journeySpanForm.controls);
      return;
    }
    let payload = {
      "ds_id": this.dsId,
      "journey_id": this.journeyId,
      "no_of_days": this.spanTime,
      "journey_screen_shot": this.screenShot
    }
    this.ngxSpinnerService.show();
    this.master.addJourneySpan(payload, this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.success('New journey span added successfully !');
        this.router.navigateByUrl('/corporate/journey-span');
      },
      error: (error: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.error('Journey Span for this journey or directseller already exist !');
      }
    })
  }
  updateJourneySpan() {
    this.submitted = true;
    if (this.journeySpanForm.invalid) {
      console.log(this.journeySpanForm.controls);
      return;
    }
    let payload = {
      "id": this.journeySpanId,
      "ds_id": this.dsId,
      "journey_id": this.journeyId,
      "no_of_days": this.spanTime,
      "journey_screen_shot": this.screenShot
    }
    this.ngxSpinnerService.show();
    this.master.updateJourneySpan(payload, this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.success('Updated successfully !');
        this.router.navigateByUrl('/corporate/journey-span');
      },
      error: (error: any) => {
        this.ngxSpinnerService.hide();
        this.toastrService.error('Journey Span for this journey or directseller already exist !');
      }
    })
  }
}
