<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/operator">Operator</a>
      </li>
      <li *ngIf="!operator_id" class="breadcrumb-item active" aria-current="page">
        Add New Operator
      </li>
      <li *ngIf="operator_id" class="breadcrumb-item active" aria-current="page">
        Edit Operator
      </li>
    </ol>
  </nav>
</div>
<div class="container mt-2">
  <form class="form-horizontal mt-1" [formGroup]="operatorForm">
      <div class="row">
          <div class="col-md-10 col-xl-7">

          
          <div class="card">
              <div class="card-body">

                <div class="mt-1">
                  <label for="exampleForm2">Operator Group <span style="color:red">*</span></label>
                  <select formControlName="operator_group_id" class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.operator_group_id.errors }">
                      <option value="">Select Operator Group</option>
                      <option *ngFor="let userObj of operatorObj" value={{userObj.operator_group_id}}>
                          {{userObj.group_name}}
                      </option>
                  </select>

                  <div *ngIf="submitted && f.operator_group_id.errors" class="invalid-feedback">
                    <div *ngIf="f.operator_group_id.errors.required"> Operator Group is required</div>
                
              
                </div>
              </div>

           

              <div class="mt-1">
                  <label for="exampleForm2"> Name  <span style="color:red">*</span></label>
                  <input type="text" id="exampleForm2" formControlName="operator_name"
                      class="form-control form-control-md" required
                      [ngClass]="{ 'is-invalid': submitted && f.operator_name.errors }" maxlength="80" appSpace >
                  <div *ngIf="submitted && f.operator_name.errors" class="invalid-feedback">
                      <div *ngIf="f.operator_name.errors.required || f.operator_name.hasError('whitespace')"> Name is required</div>
                       <div *ngIf="f.operator_name.errors?.maxlength">You can enter only 80 charachters</div>
              
                
                  </div>
              </div>



              <div class="mt-1">
                  <label for="exampleForm2"> Description</label>
                  <input type="text"  formControlName="operator_desc"
                      class="form-control form-control-md">
              </div>


              <div class="mt-1">
                <label for="exampleForm2"> Operator Alias  <span style="color:red">*</span></label>
                <input type="text" id="exampleForm2" formControlName="operator_alias"
                    class="form-control form-control-md" required
                    [ngClass]="{ 'is-invalid': submitted && f.operator_alias.errors }" maxlength="80" appSpace >
                <div *ngIf="submitted && f.operator_alias.errors" class="invalid-feedback">
                    <div *ngIf="f.operator_alias.errors.required || f.operator_alias.hasError('whitespace')"> Operator Alias is required</div>
                     <div *ngIf="f.operator_alias.errors?.maxlength">You can enter only 80 charachters</div>
            
              
                </div>
            </div>
              

              
          </div>
          <div class="card-footer">
              <div class="text-center my_class " *ngIf="isWrite" [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
                  <button type="button" class="custbtn" (click)="addOperator()">Submit</button>
              </div>
              <div class="text-center my_class " *ngIf="isEdit" [ngClass]="actionStatus ? 'displaynone' :'displayblock'  ">
                  <button type="button" class="custbtn" (click)="UpdateOperator()">Update</button>
              </div>
          </div>
          </div>
      </div>
      </div>
  </form>
</div>