<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<!-- Breadcrumb Section Start -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">Users</a></li>
      <li class="breadcrumb-item active" aria-current="page">Team Association</li>
    </ol>
  </nav>
</div>

<!-- Breadcrumb Section End -->
<!-- Search & Navigation Section Start -->
<div class="container themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Enter Team Leader / Team Member Name" aria-label="Search"  
        [disabled]="companyID == '' " #searchText>
      </div>
    </div>
    <div class="col-md-4 mt-md-0 mt-2" style="justify-content: right;">
      <input type="button" class="btn btn-margo-action mt-md-1 ml-0" value="Associate New Team Member" *ngIf="isWrite"
        routerLink="/corporate/addassociation">
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2" *ngIf="customerID==0">
      <div>
        <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="getAssociatedTeam()">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <!-- Search & Navigation Section Start -->
  <!-- Result Listing & Paging Section Start -->

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Team Leader - Upline </th>
        <th>Team Member - Downline </th>
        <th>Action</th>
      </tr>
      <tr
        *ngFor="let obj of userObj">
        <td>{{obj.head}}</td>
        <td>{{obj.name}} </td>
        <td>
          <i class="fa fa-edit mr-1 linkForce" *ngIf="isEdit" ngbTooltip="Edit"
            (click)="editTeamAssociation(obj.id,obj.head_id)"></i>&nbsp;
        </td>
      </tr>
    </table>

    <div *ngIf="userObj.length === 0" style="text-align: center;">
      <p>No Result Available</p>
    </div>

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
        (pageChange)="getAssociatedTeam(page = $event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>

  </div>