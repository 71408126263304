import { Component, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';
import { RegisterService } from 'src/app/services/users/register.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';
import csc from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { MasterService } from 'src/app/services/master/master.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-editmyprofile',
  templateUrl: './editmyprofile.component.html',
  styleUrls: ['./editmyprofile.component.css'],
  providers: [DatePipe]
})
export class EditmyprofileComponent extends BaseComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  public userProfileDetails;
  status = false;
  profileForm: FormGroup;
  submitted = false;
  isactive = true;
  url;
  submitButton: boolean = false;
  image_awskey;
  countriesData: any;
  stateData: any;
  cityData: any;
  birthdate:any;
  fileObject;
  maxdate;
  timezones;
  ngxSpinnerService: any;

  constructor(
    private formBuilder: FormBuilder,
    public datePipe: DatePipe,
    public registerService: RegisterService,
    public toastrService: ToastrService,
    public router: Router,
    public master:MasterService,
    public spinner: NgxSpinnerService,
    private upload:UploadService
  ) {

      super();
  }

  ngOnInit() {
    this.createForm();
    this.countriesData = csc.getAllCountries();
    this.spinner.show();
    this.registerService.getUserProfileDetails().subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.profileForm.patchValue(data.data);
        this.userProfileDetails = data.data;
        if(this.userProfileDetails) {
          this.dataBinding();
        }
       // console.log(this.userProfileDetails);
      }
    })

    let self = this;
    var year=new Date().getFullYear()-16;
    this.maxdate =this.datePipe.transform(new Date(new Date(new Date().setFullYear(year)).setHours(0,0,0,0)),'yyyy-MM-dd');
    this.profileForm.controls['birthdate'].valueChanges.subscribe(value => {
    if(value) {
        this.profileForm.controls['birthdate'].setValidators(this.dateValidator())
      } else {
        this.profileForm.controls['birthdate'].clearValidators();
      }
      //this.profileForm.updateValueAndValidity();
    })
  }

  get f() {
    // console.log(this.profileForm.controls);
    return this.profileForm.controls;
  }



/* Create profile form */

  createForm() {
    this.profileForm = this.formBuilder.group({
      uid: [],
      upid: [],
      admin_fname:['', Validators.required],
      admin_lname: [''],
      username:[],
      company_name: [],
      title: ['', Validators.required],
      address1: ['', Validators.required],
      email_primary: [],
      mobile_phone : ['', [Validators.required, Validators.pattern(AppConstants.regex.number)]],
      home_phone : [],
      photo:[],
      // password:[],
      address2: [],
      birthdate:[''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipcode: ['', Validators.required],
      country: ['', Validators.required],
      facebook_id: [],
      twitter_id: [],
      instagram_biz_id: [],
      linkedin_id: [],
      youtube_id: [],
      google_my_biz_id: [],
      instagram_id: [],
      we_chat_id: [],
      sina_wiebo_id: [],
      whatsapp_id:[]
      // image:[]
    });

  }

  dataBinding() {
    let self = this;
    this.userProfileDetails.user_birthdate=this.datePipe.transform(this.userProfileDetails.user_birthdate,'yyyy-MM-dd');
   // console.log(this.userProfileDetails)
    this.profileForm.patchValue(this.userProfileDetails)
    if (!this.isUrlValid(this.userProfileDetails.photo)) {
      this.image_awskey = this.userProfileDetails.photo;
    } else {
      this.image_awskey = '../../../assets/custom/images/profile.png';
    }

  }

  /* Date validation for atleast 16 years */

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateStr = control.value;

      if(dateStr)
      {
      var year=new Date().getFullYear()-16;
      const now =new Date(new Date(new Date().setFullYear(year)).setHours(0,0,0,0));
      const invalidObj = { 'date': true };
      const date = new Date(new Date(dateStr).setHours(0,0,0,0));
      if(date>now)
      {
        return invalidObj;
      }
      else
      {
        return null;
      }
      }
      return null;
    };
  }

  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      this.fileObject = file;
      const reader = new FileReader();
      // this.toUploadFiles(file);
      reader.readAsDataURL(file);
      reader.onload = (e) => { // called once readAsDataURL is completed
        this.image_awskey = reader.result;

      }
    }
  }

  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    return new Promise((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          if(res.status){
            let params=res.data;
            if (params.key) {
              self.image_awskey = params.Key;
              self.ngxSpinnerService.show();
              resolve(self.image_awskey);
            }
          }
          else{
            reject(res.message)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(err)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }


  async updateProfile() {
   // console.log(this.profileForm.value);
    let self = this;
    this.submitted = true;

    if (this.profileForm.invalid) {
      return;
    }

    if(this.fileObject) {
     try {
        await this.toUploadFiles(this.fileObject.name,this.image_awskey).then(data => {
         // console.log(data)
          self.image_awskey = data;

          this.profileForm.controls.photo.setValue(self.image_awskey);
         // this.subService.sendMessage(self.image_awskey);
        }).catch(error => {
          this.toastrService.error('Please try again later');
        });
      } catch(error){
        this.toastrService.error('Please try again later');
        return;
      };

    } else if(this.image_awskey=='') {
      this.profileForm.controls.photo.setValue('');
     // this.subService.sendMessage(self.image_awskey);
    }

   this.f.birthdate.setValue(this.datePipe.transform(this.f.birthdate.value,'yyyy-MM-dd'));
   // console.log(this.profileForm.value);
    this.submitButton = true;
    this.spinner.show();

    let { admin_fname,admin_lname,company_name,title,address1,email_primary,mobile_phone,home_phone
      ,address2,birthdate,city,state,zipcode,country, facebook_id,twitter_id,instagram_biz_id,
      linkedin_id,youtube_id,google_my_biz_id,instagram_id,we_chat_id,sina_wiebo_id,whatsapp_id } = this.profileForm.value;






    let userObj = {
      fname: admin_fname,
      lname: admin_lname,
      company_name: company_name,
      title: title,
      address1:address1,
      email_primary:email_primary,
      mobile_phone:mobile_phone,
      home_phone:home_phone,
      address2:address2,
      birthdate:birthdate,
      city:city,
      state:state,
      zipcode:zipcode,
      country:country,
       facebook_id: facebook_id,
       twitter_id:twitter_id,
       instagram_biz_id:instagram_biz_id,
      linkedin_id:linkedin_id,
      youtube_id:youtube_id,
      google_my_biz_id:google_my_biz_id,
      instagram_id:instagram_id,
      we_chat_id:we_chat_id,
      sina_wiebo_id:sina_wiebo_id,
      whatsapp_id:whatsapp_id,
      photo:this.image_awskey,
      id: this.userProfileDetails.uid,
      isactive: this.userProfileDetails.userstatus
    }

    this.registerService.updateUser(userObj).subscribe(result => {
      this.spinner.hide();
    //  console.log(result);
    localStorage.setItem("UserData", JSON.stringify(userObj));
      this.submitButton = false;
      if (result.status) {
        this.toastrService.success('Profile Updated');
        setTimeout(() => {
          this.router.navigateByUrl("/myprofile");
        }, 1000);
        //this.ngxSpinnerService.hide();
      }
    })
  }

  delete() {
    let self = this;
    self.image_awskey = '';
    this.profileForm.controls.photo.setValue('');
    this.fileObject = null;
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.image_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/profile.png';
      } else {
        event.target.src = '../../../assets/custom/images/profile.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/profile.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }

  states(id) {
    // console.log("State",csc.getStatesOfCountry(id));
    this.stateData = csc.getStatesOfCountry(id)
  }

  getCities(id) {
    // console.log("cities",csc.getCitiesOfState(id));
    this.cityData = csc.getCitiesOfState(id)
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }

}
