import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent extends BaseComponent implements OnInit, AfterViewInit {

  public subscriptionObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "name";
  checked = false;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  companyID;
  currentUser;
  getCompanyobj;

  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private clientService: ClientService
  ) {
    super();
  }
  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }
  ngOnInit() {
    this.getSubscription();
    this.SubscriptionLogData();
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getSubscription();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getSubscription(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.subscriptionObj = [] }
          else { this.subscriptionObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.subscriptionObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  SubscriptionLogData() {
    let themeLogs = {
      log_type: "subscription View",
      log_name: "subscription View",
      log_description: "subscription View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);

    }, error => {
      //  console.log(error);
    });

  }
  getSubscription() {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getSubscription(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      self.subscriptionObj = response.data.rows;
      self.totalCount = response.data.total;
      self.subscriptionObj.forEach(element => {
        if (element.attachment) {
          if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt|pdf)$/i.test(element?.attachment.split("?")[0]) === true) {
            element.attachment = this.downloadFile(element.attachment);
            element.imageType = 1;
          } else {
            element.imageType = 0;
          }
        }
      });
      this.ngxSpinnerService.hide();
    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  editSubscription(id) {
    this.router.navigate(['/corporate/newsubscription'], { queryParams: { subscription_id: id } });
  }
  deleteSubscription(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delSubscription(obj).subscribe(data => {
            if (data.status) {
              this.getSubscription();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.subscriptionObj.find(element => {
      if (element.subscription_id === id) {
        return true;
      }
    });
    obj = obj;
    // obj.isactive = value;
    // console.log(obj)
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.UpdateSubscription(obj).subscribe(data => {
            this.getSubscription();
            this.toastrService.success("Subscription Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }
      }).catch((err) => {
        // console.log(err)
        // event.source.checked = !event.source.checked;
        // this.checked = obj.isactive;
        //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-theme-import.csv`, 'addingSubscriptionBulk')
      .then(data => {
        //  console.log(data);
        self.getSubscription();
      })
      .catch(err => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_subscription_tbl",
    }
    //console.log('tablename', tablename)
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }

  downloadFile(event) {
    let url = event;
    url = url.substring(url.indexOf("master_images"), url.length);
    console.log('url', url)
    if (url) {
      return this.master.getUrlfile(url);
      // this.downloadfile = awsUrl;
      // console.log('awsUrl', awsUrl)
    }
  }
}
