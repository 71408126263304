import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-roleresources',
  templateUrl: './roleresources.component.html',
  styleUrls: ['./roleresources.component.css']
})
export class RoleresourcesComponent extends BaseComponent implements OnInit {
  public perdata:any=[];
  public permissiondata:any=  [];
  public permissionObj: any = [];
  public storedata:any = [];
  removedata:any=[];
  resourcedata:any=[];
  resouceid:any=[];

  
  addedData:any=[];
  deletedData:any=[];
  
  status:any;
  moduleid:any=[];


  permissonid:any=[];
  public excludedata:any=[];
  public includedata:any=[];
  public rid:any=[];
  public totalrid:any=[];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'key',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 100,
    allowSearchFilter: true
  };
   selectedItems = [];
  dt1:any=[];
  dt2:any=[];
  public moduleObj: any = [];
  cp = 1;
  ord = true;
  colname: "Name";
  text;
  dsValue
  dSData:any;
  selectData:any;
  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService
    ) 
    { 
      super();
    }

  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getpermission(); 
    this.getModuleItemsData();
    this.getResources()
  }

  

  ModuleItemsLogData() {
    let ModuleItemsLogs = {
      log_type:"ModuleItems Master View" ,
      log_name:"ModuleItems Master View" ,
      log_description:"ModuleItems Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(ModuleItemsLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
     // console.log(error);
    });

}
getpermission() {
  let self = this;
  var a = []; var b = [];
  this.master.getPermissions().subscribe(data => {
    this.permissiondata=data;
   // console.log('this.permissiondata');
   // console.log(this.permissiondata);

    // self.permissionObj = JSON.parse(JSON.stringify(data));
  }, error => {
   // console.log(error);
  });
}
getModuleItemsData() {
  let self = this;
  this.master.getModuleItems().subscribe(data => {
  //  console.log(data);
    self.moduleObj = data;
    this.ngxSpinnerService.hide();
  }, error => {
  //  console.log(error);
    this.ngxSpinnerService.hide();
  });
}

getperids(val){
  this.rid =[];
  this.resouceid=[];
  
  var dturl='/'+this.moduleid+'/'+val;
  this.master.getperids(dturl).subscribe(data => {
   // console.log(data);


    

    this.resouceid = data.map(function (val, index) {
      var name =data[index].resourcename.toUpperCase()+"-"+data[index].privilegename;
      return { 'key': data[index].resourceid, 'value':name  };
  });

 // this.resouceid=Array.from(new Set(this.resouceid));
 var dataarray = this.resouceid.reduce((accumalator, current) => {
    if(!accumalator.some(item => item.key === current.key && item.value === current.value)) {
      accumalator.push(current);
    }
    return accumalator;
},[]);
this.resouceid=dataarray;

  for(var j=0;j<data.length;j++){
      this.rid.push(data[j].resourceid);
  } 
  if(this.rid.length>0){
  this.selectData = { 'key': this.rid, 'value':dataarray[0].value };
    //this.onItemSelect(this.rid);
     this.toastrService.success('Previous permission added in resource');

    }
 }, error => {
  // console.log(error);
   this.ngxSpinnerService.hide();    
 });
}
 intersection(first, second)
{
	var s = new Set(second);
	return first.filter(item => s.has(item));
};



onItemSelect(item: any) {
 // this.ngxSpinnerService.show();
// console.log("resouce",this.rid); 
 // if(this.perdata.length=0)this.perdata=this.rid; 
  this.perdata.push(item.key);
 // alert(this.perdata);


 this.addedData.push(item.key);


      }
      onSelectAll(items:any){
      //  this.perdata=this.totalrid;
      for(var j=0;j<items.length;j++){
        this.totalrid.push(items[j].key);

        this.addedData.push(items[j].key);
    }
        this.perdata =this.totalrid;
        

    }
       

  
      onItemDeSelect(item:any){       
       this.perdata=this.perdata.filter(this.onlyUnique);
      // console.log("pushdata",this.perdata);
        this.perdata.pop(item.key);

        this.deletedData.push(item.key);
      //  console.log("removedata",this.perdata);


      }
          
      onDeSelectAll(event){
        console.log("onDeSelectAll",event);
        this.perdata = [];
      }

      getIntersect(arr1, arr2) {
        var temp = [];
        for(var i = 0; i < arr1.length; i++){
            for(var k = 0; k < arr2.length; k++){
                  if(arr1[i].type == arr2[k].type){
                    temp.push( arr1[i]);
                    break;
                }
            }
        }
        return temp;
      }  

getResources() {
 // this.ngxSpinnerService.show();
  let self = this;
// console.log(this.resouceid);
  this.master.getResources().subscribe(data => {
  //  console.log(data);
     this.resourcedata = data.map(function (val, index) {
        var name =data[index].resourcename.toUpperCase()+"-"+data[index].privilegename;
      return { 'key': data[index].id,'value':name  };
    })  
    
    let rdata = this.resourcedata;
    this.resourcedata = Array.from(new Set(rdata));    
    this.ngxSpinnerService.hide();
  }, error => {
  //  console.log(error);
    this.ngxSpinnerService.hide();
    
    
  });
}
importFile() {
  let self = this;
  self.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-moduleitem-import.csv`, 'addingModuleItemsBulk')
    .then(data => {
    //  console.log(data);
      self.getModuleItemsData();
    })
    .catch(err => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    })
}
exportFile() {
  let tablename = {
    "tableName": "master_module_item_tbl"
  }
  this.master.exportBulk(tablename).subscribe(dataFile => {
    if (dataFile.status) {
      let selBox = document.createElement('a');
      var att = document.createAttribute("href");
      att.value = dataFile.file;
      var att1 = document.createAttribute("download");
      selBox.setAttributeNode(att);
      selBox.setAttributeNode(att1);
      document.body.appendChild(selBox);
      selBox.click();
    }
  }, err => {
    this.toastrService.error(err.message);
  });
}
 onlyUnique(value, index, self) { 
  return self.indexOf(value) === index;
}
addresourcepermission(){
 // alert("perdata="+this.perdata);

 
   this.dt1 =[];
   this.dt2=[];
  var data1=this.perdata.filter( this.onlyUnique );
     var data2= this.rid.filter( this.onlyUnique );
    
  if(data1.length>0){
    this.dt1= data2.filter(item => data1.indexOf(item) < 0);
  }else{
    this.dt1= data2;
  }//difference
 
  if(data1.length>0){ this.dt2 = this.getIntersect(data1,data2);}
  else{ this.dt2=[]; }//common
  if(this.dt2.length>0){ this.dt2=data1;}

  if((this.moduleid!=""&&this.permissonid!="" && this.resouceid.length !=0)&&(this.perdata.length>0 || this.dt1.length>0||this.dt2.length>0 || this.dt2.length>0 )){
    var updatebody={
      moduleitemid:this.moduleid,
      permissionid:this.permissonid,

      exclude:this.dt1,
      include:this.dt2,

      isactive:true,
     
    };
     var body={moduleitemid:this.moduleid,
      permissionid:this.permissonid,
      resources:this.perdata,
      isactive:true,
    };
  
   this.ngxSpinnerService.show();
   if(this.rid.length==0){
     
    this.master.addresourcepermission(body).subscribe(data => {
      // console.log(data);
     // this.ngxSpinnerService.show();
      if(data.status){
      // this.toastrService.success('Permission added successfully');
       this.moduleid="";
       this.permissonid="";
       this.resouceid=null;
       this.rid=null;
       this.addedData=[];
       this.deletedData=[];
       this.perdata=[];

       this.toastrService.success('Permission added sucessfully');
      }else{
        this.toastrService.error(data.message);
      }

       this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
     this.toastrService.error(error);
      this.ngxSpinnerService.hide();    
    });
  }else{
    this.master.updateresourcepermission(updatebody).subscribe(data => {
    //  this.ngxSpinnerService.show();
    //  console.log(data);
    if(data.status){
    this.toastrService.success('Permission updated successfully');
    this.resouceid=null;
    this.rid=null;
    this.moduleid="";
    this.addedData=[];
    this.deletedData=[];
    this.permissonid="";
    this.perdata=[];
      }else{
        this.toastrService.error(data.message);
      }
    this.ngxSpinnerService.hide();
  }, error => { 
    this.toastrService.error(error);
  // console.log(error);
   this.ngxSpinnerService.hide();    
  });}
  }else{
    this.toastrService.error('Please select data in module,permission and resources');
}
 }
}
