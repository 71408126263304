import { Component, OnInit } from '@angular/core';
import { MbService } from 'src/app/services/fbservice/mb.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-addnewmessage',
  templateUrl: './addnewmessage.component.html',
  styleUrls: ['./addnewmessage.component.css']
})
export class AddnewsnippetsComponent implements OnInit {
  post_id; msgVal
  name;
  Desc;
  isactive = true;
  typeofsnippet;
  status: boolean = true;
  selectedid: any;
  selectedObj: any;
  profileForm: FormGroup;
  submitted = false;
  msg_id;
  snippetObj;
  currentUser;
  companyID;
  customer_id;
  getCompanyobj: any = [];
  selectedCompany;
  controlEnabled: boolean = false;
  constructor(
    private mb: MbService,
    private toastrService: ToastrService,
    private router: Router,
    private ar: ActivatedRoute,
    private formBuilder: FormBuilder,
    private clientsService: ClientService,

  ) { }

  ngOnInit() {
    this.controlEnabled = false;
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();



    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID = this.currentUser.admin_customer_id;

    let self = this;
    // console.log();
    if (localStorage.getItem("edit_snippet_id")) {


      this.msg_id = localStorage.getItem("edit_snippet_id");

      //this.customer_id =  localStorage.getItem("post_edit_company_id");
      this.selectedCompany = localStorage.getItem("snippet_edit_company_id");
      // this.selectedCompany.disable();
      this.mb.getMessageById(this.selectedCompany, this.msg_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.snippetObj = response.data;
          // console.log(self.snippetObj);
          self.dataBinding();
        } else {

        }
      });
    }
    this.profileForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespaceValidator]],
      desc: ['', [Validators.required, this.noWhitespaceValidator]],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }
  get f() { return this.profileForm.controls; }
  addNewMessageData() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    let nm = this.profileForm.value.name.trim();
    if (nm === null || nm === undefined || nm === '') {
      this.toastrService.error('Please provide a valid Snippet Name');
      return;
    }
    let dsc = this.profileForm.value.desc.trim();
    if (dsc === null || dsc === undefined || dsc === '') {
      this.toastrService.error('Please provide a valid Snippet Description');
      return;
    }
    this.profileForm.value.name = this.profileForm.value.name.trim();
    this.profileForm.value.desc = this.profileForm.value.desc.trim();
    let { name, desc, typeofsnippet, selectedCompany } = this.profileForm.value;
    let obj = {
      type: name,
      description: desc,
      isactive: true,
      msgcategory: 0,
      iscorporate: true,
      company: selectedCompany
    }
    //console.log(obj);
    // this.fbservice.addMessageSnippets(obj).subscribe(data => {
    //   this.router.navigate(['/directseller/social/customfbpost']);
    // })
    // if (this.type && this.type.trim() && this.description) {
    // var obj = {
    //   type: this.type,
    //   description: this.description,
    //   isactive: this.isactive,
    //   iscorporate: true
    // }
    this.mb.addNewmsg(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/smssnippets`);
      } else {
        this.toastrService.error(data.message);
      }
    }, error => {
      this.toastrService.error(error.message);
    });
    // } else {
    //   this.toastrService.error("Please Fill all the fields")
    // }
  }

  dataBinding() {
    // this.name = this.categoryObj.category_name;
    // this.description = this.categoryObj.category_description;
    // this.screenshot = this.categoryObj.category_screenshot;
    // this.isactive = this.categoryObj.isactive;


    this.profileForm = this.formBuilder.group({
      name: [this.snippetObj.msg_type, [Validators.required, this.noWhitespaceValidator]],
      desc: [this.snippetObj.msg_description, [Validators.required, this.noWhitespaceValidator]],
      selectedCompany: [this.selectedCompany, Validators.required]
    });

  }

  updateMessage() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    let { name, desc, typeofsnippet, selectedCompany } = this.profileForm.value;
    let obj = {
      id: this.msg_id,
      type: name,
      description: desc,
      msgcategory: 0,
      iscorporate: true,
      company: selectedCompany
    }

    //  console.log(obj, "####");
    this.mb.updateSnippet(obj).subscribe(data => {
      this.toastrService.success("Snippet Updated successfully");
      this.router.navigateByUrl(`/corporate/smssnippets`);
    }, error => {
      console.error(error);
    });
  }

  getCompany() {

    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
