import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-newblockiphost',
  templateUrl: './newblockiphost.component.html',
  styleUrls: ['./newblockiphost.component.css']
})
export class NewblockiphostComponent extends BaseComponent implements OnInit {
  blockIPHostForm: FormGroup;
  submitted = false;
  isEdit = false;
  hostId;
  blockIPHost;
  currentUser;

  constructor(private master: MasterService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
      super()
     }

  ngOnInit() {
    this.blockIPHostForm = this.formBuilder.group({
      host: ['', [Validators.required,Validators.maxLength(30),this.noWhitespaceValidator]],
      type: ['', Validators.required],
      isactive: [true]
    });
    this.currentUser = JSON.parse(localStorage.getItem('userData'));

    this.route.queryParams.subscribe(res => {
      this.hostId = res.hostid;
      if(this.hostId) {
       this.dataBinding();
      }
    })
  }

  addblockIPHost() {
    if(!this.blockIPHostForm.valid) {
      this.submitted = true;
      return
    }
    this.ngxSpinnerService.show();
    let body = {
      host: this.blockIPHostForm.value.host,
      type: this.blockIPHostForm.value.type,
      isactive: this.blockIPHostForm.value.isactive,
      createdby: this.currentUser.uid
    }
    this.master.addBlacklistedhost(body).subscribe(response => {
      this.ngxSpinnerService.hide();
      this.toastrService.success('Block IP Host Added!');
      window.history.back();
    },error => {
      this.ngxSpinnerService.hide();
    })
  }

  updateblockIPHost() {
    if(!this.blockIPHostForm.valid) {
      this.submitted = true;
      return
    }
    this.ngxSpinnerService.show();
    let body = {
      host: this.blockIPHostForm.value.host,
      type: this.blockIPHostForm.value.type,
      isactive: this.blockIPHostForm.value.isactive,
      modifiedby: this.currentUser.uid
    }
    this.master.updateBlacklistedhost(this.hostId, body).subscribe(response => {
      this.ngxSpinnerService.hide();
      this.toastrService.success('Block IP Host Updated!');
      window.history.back();
    },error => {
      this.ngxSpinnerService.hide();
    })
  }

  dataBinding() {
    this.ngxSpinnerService.show();
    this.master.getBlacklistedhost(1000, 0, 'createdon', 'asc', '', '').subscribe(res => {
      this.ngxSpinnerService.hide();
      this.isWrite = false;
      this.isEdit = true;
      this.blockIPHost = res.data.rows.find(item => item.hostid == this.hostId);
      this.blockIPHostForm.patchValue({
        host: this.blockIPHost.host,
        type: this.blockIPHost.host_type,
        isactive: this.blockIPHost.isactive
      })
    })
  }

  get f() { return this.blockIPHostForm.controls; }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
