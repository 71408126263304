<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/clients">Clients / Customers</a></li>
            <li class="breadcrumb-item  active">Add / Update Client DB Detail</li>
        </ol>
    </nav>
</div>
<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="updateclientdbForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Select Customer Name <span style="color:red">*</span></label>
                    <select formControlName="customerid" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.customerid.errors }"  (change)="getClientDBDetail($event.target.value)">
                        <option value=''>--Select Customer Name--</option>
                        <option *ngFor="let customerObj of getCompanyobj" value={{customerObj.customer_id}}>
                            {{customerObj.customer_company_name}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.customerid.errors" class="invalid-feedback">
                        <div *ngIf="f.customerid.errors.required">Company Name is required</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Customer DB Host  <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="customerdbhost"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.customerdbhost.errors }">
                    <div *ngIf="submitted && f.customerdbhost.errors" class="invalid-feedback">
                        <div *ngIf="f.customerdbhost.errors.required">Customer DB Host is required</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Customer DB Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="customerdbname"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.customerdbname.errors }">
                        <div *ngIf="submitted && f.customerdbname.errors" class="invalid-feedback">
                            <div *ngIf="f.customerdbname.errors.required">Customer DB Name is required</div>
                        </div>
                </div>               
                <div class="mt-1">
                    <label for="exampleForm2">Customer DB User Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="customerdbusername"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.customerdbusername.errors }">
                        <div *ngIf="submitted && f.customerdbusername.errors" class="invalid-feedback">
                            <div *ngIf="f.customerdbusername.errors.required">Customer DB User Name is required</div>
                        </div>
                </div>               
                <div class="mt-1">
                    <label for="exampleForm2">Customer DB Password <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="customerdbpassword"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.customerdbpassword.errors }">
                        <div *ngIf="submitted && f.customerdbpassword.errors" class="invalid-feedback">
                            <div *ngIf="f.customerdbpassword.errors.required">Customer DB Password is required</div>
                        </div>
                </div>                
                <div class="mt-1">
                    <label for="exampleForm2">Customer DB Port  <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="customerdbport"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.customerdbport.errors }">
                    <div *ngIf="submitted && f.customerdbport.errors" class="invalid-feedback">
                        <div *ngIf="f.customerdbport.errors.required">Customer DB Port is required</div>
                    </div>
                </div>
                <div class="mt-1" *ngIf="isEdit">
                    <label class="mt-1">IsActive</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isactive"
                            [value]="true">
                        <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isactive"
                            [value]="false">
                        <label class="custom-control-label" for="customRadio2">Inactive</label>
                    </div>
                </div>
                <div class="text-center my_class">
                    <button type="button" class="custbtn mt-2" (click)="UpdateClientDBDetails()">Save</button>
                    <button type="button" class="custbtn mt-2" (click)="location?.back()">Cancel</button>  
                </div>
            </div>
        </div>
    </form>
</div>