<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Content Builder</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon">
  <legend class="scheduler-border"><h5 class="font-weight-bold">Content Builder Navigation</h5></legend>
  <fieldset class="scheduler-border">

    <div class="row">
    <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/contentbuilder-view">
          <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">
          <i class="fa fa-search red-dr-icon" aria-hidden="true"></i>Content Builder Master
        </button>
    </div> -->


    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/cb-section">
        <i class="bi bi-layout-sidebar blue-lt-icon" aria-hidden="true"></i>Section
      </button>
    </div>



    
    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">

      <button class="cbtn mt-1" routerLink="/corporate/cb-journey">
        <i class="fa fa-truck green-lt-icon" aria-hidden="true"></i>Journey
      </button>
    </div>


    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/content-builder-template">
        <i class="fa fa-newspaper-o red-dr-icon" aria-hidden="true"></i>Template
      </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/content-builder-field">
        <i class="bi bi-ui-radios-grid orange-dr-icon" aria-hidden="true"></i>Fields
      </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/content-builder-field-property">
        <i class="fa fa-i-cursor purple-dr-icon" aria-hidden="true"></i>Attributes
      </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/cb-ts-association">
        <i class="bi bi-file-earmark blue-lt-icon" aria-hidden="true"></i>Template Section Association
      </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/cb-sec-field-association">
        <i class="bi bi-ui-checks-grid red-dr-icon" aria-hidden="true"></i>Section Field Association
      </button>
    </div>

    <div class="col-xl-4  col-md-6" *ngIf="roleID==71">
      <button class="cbtn mt-1" routerLink="/corporate/cb-field-att-association">
        <i class="bi bi-input-cursor-text orange-dr-icon" aria-hidden="true"></i>Field Attribute Association
      </button>
    </div>

    <div class="col-xl-4  col-md-6" >
      <button class="cbtn mt-1" routerLink="/corporate/cb-theme-template-association">
        <i class="bi bi-palette2 purple-dr-icon" aria-hidden="true"></i>Theme Template Association
      </button>
    </div>

    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/cb-form-builder-list">
        <i class="bi bi-palette green-lt-icon" aria-hidden="true"></i>Theme Builder
      </button>
    </div>

    <!-- <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/content-builder-associate">
        <i class="fa fa-user-times orange-dr-icon" aria-hidden="true"></i>Content Builder Association
      </button>
    </div> -->
    <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/content-builder-customer">
          <i class="fa fa-users blue-lt-icon" aria-hidden="true"></i>Content Builder Data Management
        </button>
    </div> -->
    <!-- <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/content-builder-customer-list">
          <i class="fa fa-usd green-lt-icon" aria-hidden="true"></i>Customer Content Builder List
        </button>
    </div> -->
   <!-- <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/pii-report">
        <i class="fa fa-file-text purple-dr-icon" aria-hidden="true"></i>PII & DSAR Reports
      </button>
    </div> -->

    </div>
  </fieldset>
</div>






