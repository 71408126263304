<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <div class="row">
    <div class="col-9">
      <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
          <li class="breadcrumb-item">
            <a routerLink="/corporate/bu-segmentation">BU Segmentation Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Attributes List for {{buselected}}
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-3">
      <div  style="justify-content: right">
        <input
          type="button"
          value="{{buselected}}"
          *ngIf="isWrite"
          class="custbtn red-lt-icon"
          routerLink="/corporate/selectbu-segmentation"
        />

      </div>
    </div>

  </div>

</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                                                                            aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search by Attribute Name" aria-label="Search" [(ngModel)]="text">
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn" *ngIf="isWrite" routerLink="/corporate/add-bu-attributes">

    </div>
  </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
      <tr>

        <th (click)="chgord('attributes_name')" class="cursor">Name <i class=" fa fa-sort text-black"
                                                                     aria-hidden="true"></i></th>
        <th>Description</th>

        <th>Field Alias</th>
        <th>Field Size</th>
        <th>Field Type Option</th>
        <th (click)="chgord('createdon')" class="cursor">
          Created On <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th>Actions</th>

      </tr>
      <tr
        *ngFor="let obj of attributes | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">

        <td>{{obj.field_name}}</td>
        <td>{{obj.field_desc}}</td>
        <td>{{obj.field_alias}}</td>
        <td>{{obj.field_size}}</td>
        <td>{{obj.field_type_option}}</td>
        <td>{{obj.createdon |date}}</td>


        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1 cursor" (click)="editAttribute(obj.field_id)"  *ngIf="isEdit"
               placement="bottom" ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1 cursor" (click)="deleteAttribute(obj.field_id)" *ngIf="isDelete"
                  placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle [checked]="obj.isactive" class="my-slider cursor"  *ngIf="isEdit"
                              (click)="updateStatus($event, obj)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <div
      *ngIf="(attributes | filter: text).length === 0"
      style="text-align: center"
    >
      <p>No Result Available</p>
    </div>
    <div
      *ngIf="(attributes | filter: text).length !== 0"
      style="text-align: center"
    >
      <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
    </div>
  </div>
</div>

