<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .seg-table thead th {
    border: 0;
    padding: 8px !important;
    font-size: 15px;
  }
  .seg-table .table-light > td, .seg-table .table-light > th {
    background-color: #f0f0f0;
    border-bottom: 2px solid #fff;
  }
</style>
<div class="container">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
          <li class="breadcrumb-item">
            <a routerLink="/corporate/segmentation">Segmentation Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            BU Segmentation Home
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-12 text-right">
        <input type="button" value="{{buselected}}" *ngIf="isWrite" class="custbtn red-lt-icon" routerLink="/corporate/selectbu-segmentation"
        />
    </div>

  </div>

</div>
<div class="container mt-2">
<div class="row">
  <div class="col-7">
    <h5 class="ml-1 mt-2">Audience List for {{buselected}}</h5>
  </div>

  <div class="col-5 mb-3 text-right">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn red-lt-icon" (click)="editAudience()"
    />
  </div>
</div>
</div>

<div class="ml-1 col-12 mb-3">

  <ngb-accordion [closeOthers]="true" activeIds="" #accordion >
    <ngb-panel   *ngFor="let aud of audBUList; let i = index" id="{{i}}">
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between segAcrd" >
          <h5  class="m-2">{{aud.audience_name}}
            <span style="cursor: pointer">
            <i class="fa fa-edit ml-1"
               *ngIf="isEdit"
               (click)="editAudience()"
               placement="bottom">
            </i>
          </span>
          </h5>

          <div class="segArdBtn" *ngIf="!isOpen(i)">
            <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-2"
                    (click)="getEntityforBUAudience(aud.aud_id)">
              <i  class="fa fa-plus-circle mr-1 ml-10 " *ngIf="isEdit" placement="bottom"></i>
            </button>
          </div>
          <div class="segArdBtn" *ngIf="isOpen(i)">
            <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-2">
              <i  class="fa fa-minus-circle mr-1 ml-10 " *ngIf="isEdit" placement="bottom"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p *ngIf="entBUList.length === 0 && entshow">No Associated Entities Available for selected Audience</p>
        <h6 *ngIf="entBUList.length > 0" class="ml-3 mb-3 mt-2 font-weight-bold">Associated Entities
          <span style="cursor: pointer">
                    <i class="fa fa-edit ml-1" *ngIf="isEdit" (click)="editEntities(aud.aud_id, aud.audience_id)" placement="bottom">
                    </i>
                  </span>
        </h6>
        <ngb-accordion [closeOthers]="true" #accordion2 activeIds="" >
          <ngb-panel   *ngFor="let ent of entBUList; let x = index" id="{{x}}">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between segAcrd segAcrdSecnd" >
                <h6  class="m-2 ">{{ent.entity_name}}</h6>
                <div class="segArdBtn" *ngIf="!is2Open(x)">
                  <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-2"
                          (click)="getAttributeandOperatorforBUEntity(ent.entity_id)"
                  >
                    <i  class="fa fa-plus-circle mr-1 ml-10 " *ngIf="isEdit" placement="bottom"></i>
                  </button>
                </div>
                <div class="segArdBtn" *ngIf="is2Open(x)">
                  <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-2">
                    <i  class="fa fa-minus-circle mr-1 ml-10 " *ngIf="isEdit" placement="bottom"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p *ngIf="attrAndOperators.length === 0">No Result Available</p>
              <div class="tablScrlSeg" *ngIf="attrAndOperators.length > 0">
                <table width="100%" border="0" class="table seg-table border-0">
                  <thead>
                  <tr>
                    <th width="33%">Attribute
                      <span style="cursor: pointer" >
                    <i class="fa fa-edit " *ngIf="isEdit" (click)="editAttributes(ent)" placement="bottom">
                    </i>
                  </span>
                    </th>
                    <th>Operator</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr class="table-light" *ngFor="let attr of attrAndOperators">
                    <td >{{attr.field_name}}
                      <span style="cursor: pointer" >
                    <i class="fa fa-edit " *ngIf="isEdit" (click)="editOperators(ent,  attr.mfield_id)" placement="bottom">
                    </i>
                  </span>
                    </td>
                    <td>
                      <span *ngFor="let opr of attr.operator" class="segOptr">{{opr.operator_name}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

</div>


            <!--<div class="container mt-2 theme-fa-icon" *ngIf="show">-->
<!--  <fieldset class="scheduler-border">-->
<!--    <legend class="scheduler-border"><h5 class="font-weight-bold">{{buselected}} Audience</h5></legend>-->
<!--    <div class="row">-->

<!--      <div class="col-xl-4  col-md-6">-->
<!--        <button class="cbtn mt-1" routerLink="/corporate/bu-audience">-->
<!--          <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>Audience-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="col-xl-4  col-md-6">-->

<!--        <button class="cbtn mt-1" routerLink="/corporate/bu-entities">-->
<!--          <i class="fa fa-unlock-alt red-dr-icon" aria-hidden="true"></i>Entities</button>-->
<!--      </div>-->
<!--      <div class="col-xl-4  col-md-6">-->

<!--        <button class="cbtn mt-1" routerLink="/corporate/bu-attributes">-->
<!--          <i class="fa fa-list-alt blue-sky-icon" aria-hidden="true"></i>Attributes-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="col-xl-4  col-md-6">-->

<!--        <button class="cbtn mt-1" >-->
<!--          <i class="fa fa fa-file-text-o green-dr-icon" aria-hidden="true"></i>Operators-->
<!--        </button>-->
<!--      </div>-->

<!--    </div>-->
<!--  </fieldset>-->
<!--</div>-->
