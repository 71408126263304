import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-thememaster',
  templateUrl: './thememaster.component.html',
  styleUrls: ['./thememaster.component.css']
})
export class ThememasterComponent extends BaseComponent implements OnInit, AfterViewInit {
  public themeObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "theme_name";
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  disCompId: any;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  domainData: any=[];
  domain_id: any;
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe,
    private clientService: ClientService
  ) {
    super();
  }
  ngOnInit() {
    // this.getBusinessDomain();
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.disCompId = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getThemeData();
    this.themeLogData();
    this.getCompany();
  }


  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getCompanyTheme(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value,'all',this.domain_id))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.themeObj = [] }
          else { this.themeObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.themeObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getThemeData();
  }

  themeLogData() {
    let themeLogs = {
      log_type: "Theme Master View",
      log_name: "Theme Master View",
      log_description: "Theme Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });

  }

  getThemeData() {
    this.ngxSpinnerService.show();
    // console.log("company themes:"+ id);
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.master.getCompanyTheme(this.companyID,this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value,'all',this.domain_id).subscribe(response => {
      this.ngxSpinnerService.hide();
      self.themeObj = response.data.rows;
      this.totalCount = response.data.total;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getCompanyThemes(id) {
    //event.preventDefault();


    this.ngxSpinnerService.show();
    if (id != '' && id !== '0') {
      this.companyID = id;
      this.cp = 1;
      this.getThemeData();
      this.getBusinessDomain();
    } else {
      this.themeObj = [];
      this.totalCount = 0;
      this.cp = 1;
      this.companyID = id;
      this.domain_id = '';
      this.searchText.nativeElement.value = '';
      this.ngxSpinnerService.hide();
    }
  }

  editTheme(id) {

    this.router.navigate(['/corporate/newtheme'], { queryParams: { companyID: this.companyID, them_id: id } });
  }
  deleteTheme(id) {
    let obj = {
      id: id,

    }
    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delTheme(this.companyID, id).subscribe(data => {
            if (data.status) {
              this.getCompanyThemes(this.companyID)
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.themeObj.find(element => {
      if (element.theme_id === id) {
        return true;
      }
    });
    //obj = obj.get_master_theme;
    // obj.isactive = value;
    //  console.log(obj.isactive);


    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;

          this.master.UpdateTheme(obj).subscribe(data => {
            // this.getTheme();
            this.getCompanyThemes(this.companyID)
            this.toastrService.success("Theme Master Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }


      }).catch((err) => {
        // console.log(err)
        // event.source.checked = !event.source.checked;
        // this.checked = obj.isactive;
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });


  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }
  importFile() {
    let self = this;
    if (self.companyID != 0) {
      let tablename = {
        "tableName": "master_themes_tbl",
        "cid": this.companyID
      }
      self.ImportFilesDialogService.confirm1('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-theme-import.csv`, 'addingThemeBulk', this.companyID)
        .then(data => {
          //  console.log(data);
          self.getThemeData();
        })
        .catch(err => {
          //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        })
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
  exportFile() {
    if (this.companyID != 0) {
      let tablename = {
        "tableName": "master_themes_tbl",
        "cid": this.companyID
      }
      this.master.exportBulk(tablename).subscribe(dataFile => {
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
    } else {
      this.toastrService.error('Please Select The Company!');
    }

  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }

  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.companyID).subscribe(data => {
      this.ngxSpinnerService.hide();
      self.domainData = data.data.rows;
    }, error => {
      this.domainData=[]
      this.ngxSpinnerService.hide();
    });
  }
  checkDomain(id){
    if(id!=0 && id!=undefined && id!=null){
      let test=this.domainData.find((res:any)=>{
        return res.id==id
    })
    return test?.domain_name!=undefined && test?.domain_name!=null?test.domain_name:''
    }else{
      return ''
    }
  }
  getDomainList(id:any){
      //event.preventDefault();
      this.ngxSpinnerService.show();
      if (id) {
        this.domain_id = id;
        this.getThemeData();
      } else {
        this.themeObj = [];
        this.totalCount = 0;
        this.cp = 1;
        this.ngxSpinnerService.hide();
    }
  }
}
