<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/shop-live">Shop Live</a></li>
      <li class="breadcrumb-item active" aria-current="page">Category</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getCategory()" [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText/>
      </div>
    </div>

    <div class="col-md-4 mt-md-0 mt-2" style="justify-content: right">
      <input type="button" value="Add New" class="custbtn mt-md-1 mt-1 ml-0" routerLink="/corporate/shoplive-addcategory" />
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th (click)="setOrder('sl_cat_name')" style="cursor: pointer">Category Name <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Category Description</th>
        <th (click)="setOrder('createdon')" style="cursor: pointer">
          Created On <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Actions</th>
      </tr>
      <tr *ngFor="let category of categoryObj">
        <td>{{category?.sl_cat_name}}</td>
        <td>{{category?.sl_cat_desc}}</td>
        <td>{{category?.createdon | date: "mediumDate"}}</td>
        <td>
          <div class="actBtn">
            <i *ngIf="isWrite" class="fa fa-edit mr-1 linkForce" (click)="editPost(category.sl_cat_id)"></i>
            <i *ngIf="isWrite" class="fa fa-trash mr-1 linkForce"
              (click)="delCategory(delete, category.sl_cat_id, category.sl_cat_name)"></i>
            <mat-slide-toggle *ngIf="isWrite" class="my-slider" [checked]="category.status"
              (click)="updateStatus($event, category.sl_cat_id, category.status)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="slCatTotal == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="slCatTotal > 10">
      <ngb-pagination [collectionSize]="slCatTotal" [(page)]="slCatPage" [maxSize]="2" [rotate]="true" (pageChange)="getCategory(slCatPage=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>

    <ng-template #delete let-modal role="dialog" style="z-index: 999999" data-keyboard="false" data-backdrop="static">
      <div class="modal-header dialHead text-center" *ngIf="postflag1 || postflag2">
        <img src="assets/custom/images/margoLogoIcon.png" />
      </div>

      <div class="card-body" *ngIf="postflag1">
        <form class="form-horizontal" [formGroup]="updateForm">
          <h5 class="text-center update-subheading">
            Category `{{ catname }}` is already linked with
            <div *ngIf="datacount[0] > 0 ">
              {{ datacount[0] }} posts message.
            </div>
            <div class="mt-2">
              Please select and update the new category before deleting the
              category for these items.</div>
          </h5>
          <div class="form-group">

            <input type="hidden" formControlName="company">

            <input type="hidden" class="form-control" name="oldcatid" value="{{ oldcatid }}" formControlName="oldcatid"
              [(ngModel)]="oldcatid" />
          </div>
          <div class="form-group">
            <select class="form-control mt-1" id="sel1" name="categoryId" formControlName="categoryId"
              [ngClass]="{ 'is-invalid': submitted && fud.categoryId.errors }">
              <option value="">Select Category</option>
              <option *ngFor="let item of categorynewdata" value="{{ item.sl_cat_id }}">
                {{ item.sl_cat_name }}
              </option>
            </select>
            <div *ngIf="submitted && fud.categoryId.errors" class="invalid-feedback">
              <div *ngIf="fud.categoryId.errors.required">Name is required</div>
            </div>
          </div>

          <div class="modal-footer text-center justify-content-center pb-3" style="border: 0">
            <button type="button" class="btn vl custbtn text-uppercase" (click)="updatepostincategory(datacount)">
              Update
            </button>
            <button type="button" class="btn vl custbtn text-uppercase" (click)="cancelCat()">
              Cancel
            </button>
          </div>
        </form>
      </div>

      <div class="" *ngIf="postflag2">
        <form class="form-horizontal" [formGroup]="updateForm">
          <h4 class="modal-title mt-3 text-center">PLEASE CONFIRM</h4>
          <h5 class="text-center mt-1 update-subheading text-uppercase">ARE YOU SURE YOU WANT TO DELETE THE CATEGORY?
          </h5>
          <div class="form-group" style='margin-bottom: .15rem;'>
            <input type="hidden" class="form-control" name="oldcatid" formControlName="oldcatid" value="{{ oldcatid }}"
              [(ngModel)]="oldcatid" [ngClass]="{ 'is-invalid': submitted && fud.oldcatid.errors }" />
            <div *ngIf="submitted && fud.oldcatid.errors" class="invalid-feedback">
              <div *ngIf="fud.oldcatid.errors.required"></div>
            </div>
            <div class="form-group" style='margin-bottom: .15rem;'>

              <input type="hidden" formControlName="company">

              <input type="hidden" class="form-control" name="categoryId" formControlName="categoryId"
                value="{{ oldcatid }}" [(ngModel)]="categoryId"
                [ngClass]="{ 'is-invalid': submitted && fud.categoryId.errors }" />
              <div *ngIf="submitted && fud.categoryId.errors" class="invalid-feedback">
                <div *ngIf="fud.categoryId.errors.required"></div>
              </div>
            </div>
          </div>

          <div class="modal-footer text-center justify-content-center pb-3" style="border: 0">
            <button type="button" class="btn vl custbtn text-uppercase" (click)="delCategoryUserData()">
              YES
            </button>
            <button type="button" class="btn vl custbtn text-uppercase" (click)="cancelCat()">
              NO
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>