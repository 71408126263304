import { BaseComponent } from '../../base.component';
import { Component, OnInit } from '@angular/core';
import { AudienceBuilderService } from '../../../services/segmentation/audience-builder.service';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import csc from 'country-state-city';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from '../../../services/master/master.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-view-bu-audience',
  templateUrl: './view-bu-audience.component.html',
  styleUrls: ['./view-bu-audience.component.css']
})
export class ViewBuAudienceComponent extends BaseComponent implements OnInit {

  audId: any;
  companyId: any;
  audData: any;
  show = false;
  buEntitiesObj: any = [];

  buEntitieAttributesObj: any = []
  buAttributeOperatorObj: any = []
  conditionForm: FormGroup;

  showAndOr: boolean = false;
  showDataValue: any = [];
  getdataValueObj: any = []
  stateData: any = []
  cityData: any = []
  addedAudienceId;
  targetAudieceId;

  dataLabelName = [];
  companyProductObj;
  companyAttributeObj = [];

  companyProductDataObj = [];
  companyAttributeDataObj = [];
  displayState = [];
  displayCity = [];
  queryResult = [];

  editStatus;
  showProductValue:boolean=true;


  constructor(private audienceBuilderService: AudienceBuilderService, private fb: FormBuilder, private clientsService: ClientService,
    private master: MasterService,
    public datePipe: DatePipe,) {
    super();


    this.activatedRoute.queryParams
      .subscribe(params => {
        this.audId = params.audid;
        this.editStatus = params.edit;
      });


    //this.audId = 534;
    if (!this.audId) {
      this.router.navigateByUrl('/corporate/audience-builder');
    }
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);

  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.audienceBuilderService.getQueryBuilderView(this.companyId, this.audId).subscribe(data => {
      if (data.status) {

        this.audData = data.data[0];


        // this.queryResult=[];

        data.data.forEach(element => {

          element.querydata.forEach(element1 => {

           // console.log("queries....", element1)

            if(element1.field_name.includes('Product')) {             

              this.showProductValue=false;
              this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {

                let productList =  data.data.rows.filter(item=>item.product_catalog_id==element1.query_value);
                 element1.query_value=productList[0].product_name;
                 this.showProductValue=true;
                // this.ngxSpinnerService.hide();

              }, error => {
               // console.log(error);
              });





             }
             if(element1.field_name=="Attribute")
             {
             // this.ngxSpinnerService.show();
             this.showProductValue=false;
              this.master.getFullAttributes(this.companyId, 9999).subscribe(data => {
                this.companyAttributeDataObj= data.data.rows;
                let attributeList = this.companyAttributeDataObj.filter(item=>item.attribute_id==element1.query_value);
                element1.query_value=attributeList[0].attribute_name;
                this.showProductValue=true;
               // this.ngxSpinnerService.hide();
              }, error => {
               // console.log(error);

              });




             }
             if(element1.field_name=="Country")
             {
              let countryData = csc.getAllCountries().filter(item => item.sortname == element1.query_value);
              element1.query_value=countryData[0].name;
             }
             if(element1.field_name=="Type of Campaign")
             {
               if(element1.query_value==67)  element1.query_value="JOIN";
               else if(element1.query_value==68)  element1.query_value="HOST";
               else element1.query_value="SHOP";
             }
             if(element1.field_name=="Sex")
             {
              element1.query_value=(element1.query_value==2?"Female":"Male");
             }

             if (element1.query_value == "true" || element1.query_value == 1) {
              element1.query_value = "Yes"
            }
            if (element1.query_value == "false" || element1.query_value == 0)  {
              element1.query_value = "No"
            }

             if(element1.field_name && (element1.field_name.includes('Date'))) {
              element1.query_value= this.datePipe.transform(element1.query_value, 'MM-dd-yyyy');

             }

            this.queryResult.push(element1)

           // console.log("aaaa...",this.queryResult)

          });



        });

       // console.log("queries....", this.queryResult)

        // if(this.queryData1)  this.patchDataValue();
        this.ngxSpinnerService.hide();


      } else {
        this.show = true;
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
     // console.log(error);
      this.show = true;
      this.ngxSpinnerService.hide();
    });


  }

  //  #################  Test Audience Link Click from Page  ##################  //

  testAudience() {
    let URL = '/corporate/test-bu-built-audience' + '?audid=' + this.audId;
    this.router.navigateByUrl(URL);
  }

  //  ############# Click on Schedule Button to got to Schedule Audience Page ######  //

  goToScheduleAudience() {

    let URL;

 if(this.editStatus)
 { URL = '/corporate/schedule-bu-built-audience' + '?edit=true&audid=' + this.audId; }
 else
 { URL = '/corporate/schedule-bu-built-audience' + '?audid=' + this.audId;}
    
    

    this.router.navigateByUrl(URL);
  }




productList() {
  this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {
    this.companyProductDataObj = data.data;
    this.ngxSpinnerService.hide();

  }, error => {
   // console.log(error);
  });


}

attributesList() {

  this.master.getAttributes(this.companyId).subscribe(data => {
    this.companyAttributeDataObj= data;
    this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    this.ngxSpinnerService.hide();
  });

}

backQueryEditPage() {

 localStorage.setItem("editAudienceID", this.audId);
  localStorage.setItem("cloneAudience",'1');
  this.router.navigate(['/corporate/clone-audience-builder']);



}






}
