import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-addnewtrainingtype',
  templateUrl: './addnewtrainingtype.component.html',
  styleUrls: ['./addnewtrainingtype.component.css']
})
export class AddnewtrainingtypeComponent extends BaseComponent implements OnInit {

  constructor(public master: MasterService,
    private formBuilder: FormBuilder
  ) { 
    super();
  }
  tid;
  trainingObjId;
  status: boolean = true;
  header = "Add New"
  
  trainingtypeForm: FormGroup;
  submitted = false;
  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.tid = params.trainingtype_id;
      });
    if (this.tid) {
      this.ngxSpinnerService.show();
      this.UpdateTrainingLogData()
      this.header = "Edit"
      this.master.getTrainingId(this.tid).subscribe(response => {
      //  console.log(response);
        if (response.status) {
          this.status = false;
          self.trainingObjId = response.data;
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }
      });
    } else {
      this.addTrainingLogData()
    }

    this.trainingtypeForm = this.formBuilder.group({
      name: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      description: ['']
    });
  }
  get f() { return this.trainingtypeForm.controls; }

  addTrainingLogData() {

    let ttLogs = {
      log_type: "Adding New Training Type",
      log_name: "Adding New Training Type",
      log_description: "Adding New Training Type",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ttLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  UpdateTrainingLogData() {

    let ttLogs = {
      log_type: "Updating Training Type Data",
      log_name: "Updating Training Type Data",
      log_description: "Updating Training Type Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ttLogs).subscribe(data => {
     // console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  dataBinding() {

    // this.isactive = this.trainingObjId.isactive;
    this.trainingtypeForm = this.formBuilder.group({
      name: [this.trainingObjId.training_type_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      description: [this.trainingObjId.training_type_description],
    });
  }
  UpdateTraining() {
    this.submitted = true;
    if (this.trainingtypeForm.invalid) {
      return;
    }

    let { name, description } = this.trainingtypeForm.value;

    let Trainingobj = {
      training_type_name: name.trim(),
      training_type_description: description,
      isactive: this.trainingObjId.isactive,
      training_type_id: Number.parseInt(this.tid, 10)
    }
    this.ngxSpinnerService.show();
    this.master.UpdateTraining(Trainingobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/trainingtypemaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  addTrainingTypeMaster() {
    this.submitted = true;
    if (this.trainingtypeForm.invalid) {
      return;
    }
  //  console.log(this.trainingtypeForm);

    let { name, description } = this.trainingtypeForm.value;
    let trainingTypeObj = {
      name: name.trim(),
      description: description
    }
    this.ngxSpinnerService.show();
    this.master.addingTrainingType(trainingTypeObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Added Successfully")
        this.router.navigateByUrl(`/corporate/trainingtypemaster`);
        this.ngxSpinnerService.hide();
      }else{
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.error(error.message);
      this.ngxSpinnerService.hide();
    })
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}