<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/loyalitytypemaster">Loyalty Type</a></li>
            <li class="breadcrumb-item active">Add New Loyalty Type</li>
        </ol>
    </nav>
</div>
<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="loyalityForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2" >Loyality Name <span class="required">*</span> </label>
                    <input type="text" appSpace formControlName='loyalityName' class="form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.loyalityName.errors }" required  maxlength="30" appSpace>
                        <div *ngIf="submitted && f.loyalityName.errors" class="invalid-feedback">
                            <div *ngIf="f.loyalityName.errors.required || f.loyalityName.hasError('whitespace')">Loyality Name is required</div>
                            <div *ngIf="f.loyalityName.errors.pattern">Loyality Name is not a valid format</div>
                            <div *ngIf="f.loyalityName.errors?.maxlength">You can enter only 30 charachters</div>
                   
        
                        </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Loyality Description</label>
                    <input type="text" formControlName='loyalityDescription' id="exampleForm2"
                        class="form-control form-control-lg">
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <!-- <div class="col-12 col-md-3"> -->
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addLoyality()">Submit</button>
                    <!-- </div> -->
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <!-- <div class="col-12 col-md-3"> -->
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateLoyality()">Update</button>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </form>
</div>
