<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
      <li class="breadcrumb-item active" aria-current="page">PII Table</li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">PII & DSAR Tables in Master DB</h5>
    </legend>
    <div class="row">
      <div class="col-md-7">
        <h5 class="mt-1 mb-1">Select Table for PII Data Filter</h5>

        

        <div class="piInnerList scroller">
        <ul class="filtMain">
          <li *ngFor="let item of nonSensitiveTables">
            <div class="row">
              <div class="col-sm-9">
                <label class="form-check-label" (change)='changeCheckTables(item)'>
                  <input class="form-check-input"  type="checkbox" id="table{{item}}" name="tables">
                  {{item}}
                </label>
              </div>
             
              <div class="col-sm-3">
                <span (click)="gettingTableFields(item)" class="blue-lt-txt cursor-pointer">View fields</span>
              </div>
            
            </div>
          
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <button type="button" class="custbtn mt-2" (click)="addNewData()">Add</button>
        </div>
      </div>
        
      </div>      
      <div class="col-md-5 mt-1 mt-md-0">
        <h5 class="mt-1 mb-1">Table Detail</h5>
        <div class="piInnerList scroller">
          <ul class="filtMain tablDetailLight">
            <li *ngFor="let item of allTableFieldsobj">{{item.column_name}} - {{item.data_type}} </li>
          </ul>
        </div>
    
      </div>
     



    </div>

