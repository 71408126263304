import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-list-reskinning',
  templateUrl: './list-reskinning.component.html',
  styleUrls: ['./list-reskinning.component.css']
})
export class ListReskinningComponent extends BaseComponent implements OnInit{
  totalCount
  currentUser: any;
  getCompanyobj: any;
  companyID: any;
  reskinningObj: any[];
  themeObj: any;
  loading:boolean=false;
  constructor(
    private clientService:ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    // if (this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0'){
      this.getrskList(this.currentUser.admin_customer_id)
    } 
    this.getCompany();
  
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      //  console.log(error);
    });
  }

  getrskList(id:any){
    this.companyID = id;
    localStorage.setItem('companyId', id);
    this.ngxSpinnerService.show();
    this.loading = true;
    this.masterService.fetchReskinningByCompany(this.companyID).subscribe({
      next:(res:any)=>{
        this.loading = false;
        this.ngxSpinnerService.hide();
        this.reskinningObj = res;
        this.totalCount = this.reskinningObj.length;
      },
      error:(error:any)=>{
        this.ngxSpinnerService.hide();
        this.reskinningObj = [];
        this.totalCount = 0;
        this.loading = false;
      }
    })
  }
  getReskinningDataByUserType(id:any){
   localStorage.setItem('companyId',this.companyID);
   localStorage.setItem('rskAction','Edit');
   this.router.navigate(['/corporate/addnewreskinning'], { queryParams: { companyID: this.companyID, them_id: id } });
  }
  navigateToAdd(){
    localStorage.setItem('rskAction','Add');
    this.router.navigate(['/corporate/addnewreskinning']);
  }
}
