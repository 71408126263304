import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-trainingtypemaster',
  templateUrl: './trainingtypemaster.component.html',
  styleUrls: ['./trainingtypemaster.component.css']
})
export class TrainingtypemasterComponent extends BaseComponent implements OnInit {
  today: number = Date.now();
  public trainingObj: any = [];
  name;
  description;
  isactive;
  createdby;
  cp = 1;
  ord = true;
  colname: "Name";
  text;

  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    
  ) {
    super();
  }


  ngOnInit() {
    this.getTrainingType();
    this.TrainingLogData();
  }

  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  TrainingLogData() {
    let ttLogs = {
      log_type:"Training Type Master View" ,
      log_name:"Training Type Master View" ,
      log_description:"Training Type Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(ttLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
    //  console.log(error);
    });
  
}
  getTrainingType() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getTraining().subscribe(data => {
      self.trainingObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  editTrainingType(id) {
    this.router.navigate(['/corporate/addnewtraining'], { queryParams: { trainingtype_id: id } })
  }
  deleteTrainingType(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delTrainingType(obj).subscribe(data => {
            if (data.status) {
              this.getTrainingType();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 500)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
          //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.trainingObj.find(element => {
      if (element.get_master_training_type.training_type_id === id) {
        return true;
      }
    });
    obj = obj.get_master_training_type;
   // console.log("**",obj);
    
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive
        //  console.log(obj);
          this.master.UpdateTraining(obj).subscribe(data => {
            this.getTrainingType();
            this.toastrService.success("Training Type Master Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        }
        // else {
        //   this.master.updateJourney(obj).subscribe(data => {
        //     this.getTrainingType();
        //   });
        // }
      }).catch((err) => {
      //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-trainin_type-import.csv`, 'addingTrainingBulk')
      .then(data => {
      //  console.log(data);
        self.getTrainingType();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_training_type"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
