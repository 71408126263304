

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Einstein Dashboard</li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
    <fieldset class="scheduler-border">
        <legend class="scheduler-border"><h5 class="font-weight-bold">Einstein Report Dashboard</h5></legend>
<div class="row">

  <div class="col-xl-4  col-md-6">

    <button class="cbtn mt-1" (click)="openreport(1)" >
      <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Service Summary Report 
    </button>
  </div>
  <div class="col-xl-4  col-md-6">

    <button class="cbtn mt-1" (click)="openreport(2)">
      <i class="fa fa-graduation-cap orange-dr-icon" aria-hidden="true"></i>E-Commerce Report 
    </button>
  </div>
  <div class="col-xl-4  col-md-6">

    <button class="cbtn mt-1" (click)="openreport(3)">
      <i class="fa fa-unlock-alt red-dr-icon" aria-hidden="true"></i>Subscription Report</button>
  </div>

  <div class="col-xl-4  col-md-6">

    <button class="cbtn mt-1" (click)="openreport(4)">
      <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>Marketing Report</button>
  </div>
  <!--<div class="col-xl-4  col-md-6">

    <button class="cbtn mt-1" (click)="openreport(5)">
      <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>Mobile Device</button>
  </div>
-->
</div>
</fieldset>
</div>


