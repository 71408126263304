<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/webinar">Webinar module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/faq-library">FAQ Library</a></li>
      <li class="breadcrumb-item active" aria-current="page">Update FAQs</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 mt-md-2">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal mt-1" [formGroup]="addCategoryForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">
              <label for="exampleForm2">Company Name <span class="required">*</span></label>
              <select formControlName="selectedCompany" *ngIf="category_id == undefined" class="form-control formstyle"
              [attr.disabled]="controlEnabled" (change)="getCategory($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">

              <div class="form-group">
                <label for="exampleForm2">FAQ Type 
                  <span class="required">*</span>
                </label>
                  <select formControlName="faqType" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.faqType.errors }">
                    <option value="">Select Faq Type --</option>
                    <option  value="Webinar"> Webinar </option>
                    <option  value="Contact"> Contact </option>
                  </select>
                
                  <div *ngIf="submitted && f.faqType.errors" class="invalid-feedback">
                    <div *ngIf="f.categoryid.errors.required">Faq is required</div>
                  </div>
                </div>



            <div class="form-group">
              <label for="exampleForm2">Category Name <span class="required">*</span></label>
              <select formControlName="categoryid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.categoryid.errors }">
                <option value="">Select Category</option>
                <option *ngFor="let Obj of categoryObj" value={{Obj.id}}>
                  {{Obj.name}}
                </option>
              </select>
              <div *ngIf="submitted && f.categoryid.errors" class="invalid-feedback">
                <div *ngIf="f.categoryid.errors.required">Category Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Question <span class="required">*</span></label>
              <input type="text" class="form-control" formControlName="question" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.question.errors }" appSpace>
              <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                <div *ngIf="f.question.errors.required || f.question.hasError('whitespace')">Question is required</div>
              </div>
            
            </div>
            <div class="form-group">
              <label for="exampleForm2">Answer <span class="required">*</span> </label>
              <textarea class="form-control text-area h-25" rows="4" id="answer" formControlName="answer"
                 [ngClass]="{ 'is-invalid': submitted && f.answer.errors }" name="answer"
                appSpace></textarea>
              <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
                <div *ngIf="f.answer.errors.required || f.answer.hasError('whitespace')">Answer is
                  required</div>
               
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="my_class text-center">
              <button type="submit" class="custbtn" (click)="addNewPostData()">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
