<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/currencymaster">Currency</a></li>
            <li class="breadcrumb-item active">Add New Currency</li>
        </ol>
    </nav>
</div>


<div class="container mt-1 mt-md-2">
    <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Currency Name <span style="color:red">*</span></label>
                    <input type="text" formControlName="currencyName" id="exampleForm2"
                        class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.currencyName.errors }" maxlength="20" appSpace>
                    <div *ngIf="submitted && f.currencyName.errors" class="invalid-feedback">
                        <div *ngIf="f.currencyName.errors.required || f.currencyName.hasError('whitespace')">Currency Name is required</div>
                        <div *ngIf="f.currencyName.errors.pattern">Currency Name is not a valid format</div>
                        <div *ngIf="f.currencyName.errors?.maxlength">You can enter only 20 charachters</div>
               
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Currency Symbol <span style="color:red">*</span></label>
                    <input type="text" formControlName="currencySymbol" id="exampleForm2"
                        class="form-control form-control-md" [ngClass]="{ 'is-invalid': submitted && f.currencySymbol.errors }" maxlength="10" appSpace>

                        <div *ngIf="submitted && f.currencySymbol.errors" class="invalid-feedback">
                            <div *ngIf="f.currencySymbol.errors.required || f.currencySymbol.hasError('whitespace')">Currency Symbol is required</div>
                            <div *ngIf="f.currencySymbol.errors?.maxlength">You can enter only 10 charachters</div>
                   
                        </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Currency Description</label>
                    <input type="text" formControlName="currencyDescription" id="exampleForm2"
                        class="form-control form-control-md">
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Currency Image</label>
                    <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                        <img src="{{currency_image}}" alt="Currency Image" style="height:150px;" (error)="setValue($event)">
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio1"
                                formControlName="firstActive" [value]="true" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio1">Url</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2"
                                formControlName="firstActive" [value]="false" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio2">Upload a file</label>
                        </div>
                    </div>
                    <div *ngIf="screenshot_type" class="mt-1">
                        <input type="text" id="exampleForm1" formControlName="currencyImage"
                            placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                            class="form-control form-control-md">
                    </div>
                    <div *ngIf="!screenshot_type" class="mt-1">
                        <!-- <input type="file" id="exampleForm2" formControlName="themescreenshot"> -->
                        <input #imageInput type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                        <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                    </div>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addCurrency()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' : 'displayblock'  ">
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="UpdateCurrency()">Update</button>
                </div>
            </div>
        </div>
    </form>
</div>