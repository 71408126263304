import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ClientMessages } from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { AppConstants } from 'src/app/_helpers/app.constants';
import csc from 'country-state-city';
import { DatePipe } from '@angular/common';
import { of } from 'rxjs';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-clientnewcontact',
  templateUrl: './clientnewcontact.component.html',
  styleUrls: ['./clientnewcontact.component.css']
})
export class ClientnewcontactComponent extends BaseComponent implements OnInit {

  getTypeUserobj;
  getCountryobj;
  getCompanyobj;
  getRoleObj;
  uid;
  isactive;
  countriesData;
  stateData;
  cityData;
  billingStateData;
  billingCityData;

  currencyObj: any = []

  constructor(private clientService: ClientService, private master: MasterService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe, private upload:UploadService) {
    super();
  }

  status: boolean = true;
  loading = false;
  submitted = false;
  returnUrl: string;
  userObjId;
  summaries;
  clientNewForm: FormGroup;

  existingClients: any = [];
  clientID: any;
  isheadquarter: boolean = true;
  isEWallet: boolean = false;

  logoAWSkey;
  ngOnInit() {
    let self = this;

    this.countriesData = csc.getAllCountries();
    this.getCountry();
    this.getCurrenctData();

    this.clientNewForm = this.formBuilder.group({

      cname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator, Validators.maxLength(50)]],
      fname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lname: ['', [Validators.pattern(AppConstants.regex.name)]],

      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lgname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      dispName: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      addr1: ['', Validators.required],
      addr2: [''],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      // zip: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(6)]],
      zip: ['', [Validators.required, Validators.pattern(AppConstants.regex.number), Validators.maxLength(6)]],
      taxid: ['', [Validators.required, this.noWhitespaceValidator]],
      website: ['', [Validators.required, Validators.pattern(AppConstants.regex.websiteURL)]],
      mphone: ['', [Validators.minLength(10), Validators.pattern(AppConstants.regex.number)]],
      lang: ['', Validators.pattern(AppConstants.regex.languagePattern)],
      curr: [''],
      revenue: ['', Validators.pattern(AppConstants.regex.mixPattern)],
      startDate: ['', Validators.required],
      noEmp: ['', Validators.pattern(AppConstants.regex.number)],
      billAddr1: ['', Validators.required],
      billAddr2: [''],
      billCountry: ['', [Validators.required]],
      billState: ['', [Validators.required]],
      billCity: ['', [Validators.required]],
      bZip: ['', [Validators.required, Validators.maxLength(6), Validators.pattern(AppConstants.regex.number)]],
      noCountry: ['', Validators.pattern(AppConstants.regex.number)],
      noDS: ['', Validators.pattern(AppConstants.regex.number)],
      contractYears: ['', [Validators.required, Validators.pattern(AppConstants.regex.number)]],
      headquarter: ['', Validators.required],
      clientID: [''],
      monthly_amount: [''],
      wallet_name: [''],
      wallet_status: ['']
    });
    this.clientNewForm.get('wallet_status').valueChanges.subscribe(data => {
      this.changeValidators()
    })
    this.ngxSpinnerService.show();
    this.clientList();
  }

  // Submit Client Data
  submitClientDetails() {
    this.submitted = true;
    let client = this.f.headquarter.value ? 0 : this.f.clientID.value;
    if (this.isheadquarter == false) {
      if (client == "" || client == undefined) {
        this.toastrService.error('Please select the Headquarter');
        return false;
      }
    }

    let monthly_amount = this.f.monthly_amount.value;

    if(this.isEWallet && !this.isheadquarter && monthly_amount >= 1000) {

      this.toastrService.error(`Amount should be less than $1000`);

      return false;
    }

    // stop here if form is invalid

    if (this.clientNewForm.invalid) {
      return;
    }

    var d = new Date();
    let addClient = {
      cname: this.f.cname.value,
      clogo: this.logoAWSkey,
      fname: this.f.fname.value,
      lname: this.f.lname.value,
      email: this.f.email.value,
      title: this.f.title.value,
      lgname: this.f.lgname.value,
      dispName: this.f.dispName.value,
      addr1: this.f.addr1.value,
      addr2: this.f.addr2.value,
      country: this.f.country.value,
      state: this.f.state.value,
      city: this.f.city.value,
      zip: Number.parseInt(this.f.zip.value),
      taxid: this.f.taxid.value,
      website: this.f.website.value,
      mphone: this.f.mphone.value,
      lang: this.f.lang.value,
      curr: this.f.curr.value,

      revenue: this.f.revenue.value != "" ? Number.parseInt(this.f.revenue.value) : 0,
      startDate: this.f.startDate.value != "" ? this.datePipe.transform(this.f.startDate.value, 'yyyy-MM-dd') : d.getFullYear() + '-' + d.getMonth() + '-' + d.getDay(),
      noEmp: this.f.noEmp.value != "" ? Number.parseInt(this.f.noEmp.value) : 0,

      billAddr1: this.f.billAddr1.value,
      billAddr2: this.f.billAddr2.value,
      billCountry: this.f.billCountry.value,
      billCity: this.f.billCity.value,
      billState: this.f.billState.value,

      bZip: this.f.bZip.value != "" ? Number.parseInt(this.f.bZip.value) : 0,
      noCountry: this.f.noCountry.value != "" ? Number.parseInt(this.f.noCountry.value) : 0,
      noDS: this.f.noDS.value != "" ? Number.parseInt(this.f.noDS.value) : 0,
      contractYears: this.f.contractYears.value != "" ? Number.parseInt(this.f.contractYears.value) : 0,
      headquarter: this.f.headquarter.value,
      clientID: this.f.headquarter.value ? 0 : this.f.clientID.value,
      monthly_amount: this.f.monthly_amount.value != "" ? this.f.monthly_amount.value: 0,
      wallet_name: this.f.wallet_name.value,
      wallet_status: this.f.wallet_status.value

    }
    console.log('addClient', addClient);
    this.clientService.addingCompany(addClient).subscribe(data => {
      if (data.status) {
        this.toastrService.success(ClientMessages.ClientAddedSuccessMessage);
        this.router.navigateByUrl(`/corporate/clients`);
      } else {
        this.toastrService.error(data.message);
      }
    }, error => {

    });
  }

  get f() { return this.clientNewForm.controls; }
  ValidateUrl(control: AbstractControl) {

    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? of(null) : of({ validUrl: true });
  }

  // Get Existing Client List
  clientList() {
    this.clientService.headquartersList().subscribe(response => {
      if (response.status) {
        // console.log(response.data);

        this.existingClients = response.data;
      }
      this.ngxSpinnerService.hide();
    }, error => {

      this.ngxSpinnerService.hide();
    });
  }

  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");

    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.logoAWSkey = params.Key;
          }
        }
        else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  getCountry() {
    let self = this;
    this.registerService.getCountryData().subscribe(data => {
      self.getCountryobj = data.data;
    }, error => {

    });
  }
  states(id) {
    // console.log("State",csc.getStatesOfCountry(id));
    this.stateData = csc.getStatesOfCountry(id)
  }
  billingstates(id) {
    // console.log("State",csc.getStatesOfCountry(id));
    this.billingStateData = csc.getStatesOfCountry(id)
  }

  getCities(id) {
    // console.log("cities",csc.getCitiesOfState(id));
    this.cityData = csc.getCitiesOfState(id)
  }
  getBillingCities(id) {
    // console.log("cities",csc.getCitiesOfState(id));
    this.billingCityData = csc.getCitiesOfState(id)
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };

  }
  getCurrenctData() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCurrency().subscribe(data => {
      self.currencyObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }


  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }


  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.logoAWSkey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  changeValidators() {
    //console.log('',this.clientNewForm.get("wallet_status").value);

    if (this.clientNewForm.get("wallet_status").value == true) {
      this.clientNewForm.get('monthly_amount').setValidators([Validators.required, Validators.pattern(AppConstants.regex.intFloatPattern)]);

      this.clientNewForm.get('wallet_name').setValidators([Validators.required, Validators.maxLength(25), Validators.pattern(AppConstants.regex.eWallet)]);
    } else {
      this.clientNewForm.get('wallet_name').clearValidators();
      this.clientNewForm.get('monthly_amount').clearValidators();
    }
    this.clientNewForm.get('monthly_amount').updateValueAndValidity();
    this.clientNewForm.get('wallet_name').updateValueAndValidity();
  }
}


