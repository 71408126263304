import { Component, OnInit } from '@angular/core';
import { RegisterService } from 'src/app/services/users/register.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { RolesService } from 'src/app/services/roles/roles.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { columnGroupWidths } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { MasterService } from 'src/app/services/master/master.service';
@Component({
  selector: 'app-rolesnewuser',
  templateUrl: './rolesnewuser.component.html',
  styleUrls: ['./rolesnewuser.component.css']
})
export class RolesnewuserComponent implements OnInit {
  getRoleObj;
  getAllModuleData;
  getModuleObj:any = [];
  parentModule:any = [];
  finalModuleArr: any = [];
  getcheckroleobj;
  roleForm: FormGroup;
  roledata;
  module;
  moduledataObj;
  getPermissionObj;
  checkvalue;
  roleObj;
  checkedRole:any=[];
  checkedpermissionString;
  checkedModuleItems:any= [] ;
  checkedPermissions:any=[];
  moduleid;
  role_id;

  showAfterValue:boolean = false;
  
  public addNewObj:any = {
  }
  constructor(
    public register:RegisterService,private fb: FormBuilder,
    public roles:RolesService,private logs :LogsService,
    private master: MasterService,
    public toaster:ToastrService,public router :Router) { 
  }
  RoleLogData() {
    let RoleLogs = {
      log_type:"Adding New Role" ,
      log_name:"Adding New Role" ,
      log_description:"Adding New Role" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(RoleLogs).subscribe(data => {
      //console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
}
  musicPreferences2 = [
   
  ]
  appendingCheckbox(value) {
  // console.log(value)
   let self = this;
   this.module = ""
   this.moduledataObj = []
   this.register.getRoleData2(value).subscribe(data => {
     self.musicPreferences2 = data.data;
   //  console.log(self.musicPreferences2)
   }, error => {
    // console.log(error);
   });
  }
  appendingCheckboxForModule(value) {
    let self = this;
   this.moduleid= value;
   let checkedRole = 0
    if(this.checkedRole[0]!=undefined){
      checkedRole = this.checkedRole[0]
    }
    this.register.getModuleData2(value,checkedRole).subscribe(data => {
      this.moduledataObj = data.data;
    //  console.log(self.module)
    }, error => {
    //  console.log(error);
    });
   }
  changeCheckForRole(a) {
    let abc = a;
    /* var index = this.checkedRole.indexOf(abc); 
    if (index > -1) { 
      this.checkedRole.splice(index, 1);
      console.log(this.checkedRole);
     }
     else{
       this.checkedRole.push(abc)
       console.log(this.checkedRole);
     } */
     this.checkedRole = [abc]
    // console.log(this.checkedRole);
     this.module = ""
     this.moduledataObj = []
     
  }
  changeCheckForModule(a){
     let abc = a;
    var index = this.checkedModuleItems.indexOf(abc); 
    if (index > -1) { 
      this.checkedModuleItems.splice(index, 1);
    //  console.log(this.checkedModuleItems);
     }
     else{
       this.checkedModuleItems.push(abc)
     //  console.log(this.checkedModuleItems);
     }
    
  }
  changeCheckForPermissions(a){
    
  //  console.log(a);
   // console.log(this.getPermissionObj);
    
    let abc = a;
      
       var index = this.checkedPermissions.indexOf(abc); 
       if (index > -1) { 
         this.checkedPermissions.splice(index, 1);
        //   console.log(this.checkedPermissions);
         this.checkedpermissionString=this.checkedPermissions.toString()
        }
        else{
          this.checkedPermissions.push(abc)
          // console.log(this.checkedPermissions);
         this.checkedpermissionString=this.checkedPermissions.toString()
       //  console.log(this.checkedpermissionString);
         
        }
       
  }
  getRoleData(roleId){
    let self = this;
    this.register.getRoleData2(roleId).subscribe(data => {
      self.getRoleObj = data.data;
    //  console.log(self.getRoleObj)
    }, error => {
    //  console.log(error);
    });
  }
  ngOnInit() {
    this.getRoleData(0);
    this.getModule();
    this.getPermissions()
  }
  // getModule() {
  //   let self = this;
  //   this.register.getModuleData().subscribe(data => {
  //     self.getModuleObj = data.data;
  //    // console.log(self.getModuleObj)
  //   }, error => {
  //    // console.log(error);
  //   });
  // }
  getModule() {
    let self = this;
    // let body = {
    //  // "pid": 0,
    //   "limit": 250,
    //   "skip": 0
    // }
    this.master.getAllModules(250, 0).subscribe(response => {     
      self.getAllModuleData = response.data.rows;
      self.getAllModuleData.forEach((ele, index)=> {
        if(ele.active == true){
          self.getModuleObj.push(ele);
        }
      });

      // return all data with active status with true value
      let getModuleActiveArr:any [];
      getModuleActiveArr = self.getAllModuleData.filter((activeEle)=> {
        return (activeEle.active == true);
      });
      // return all data with parent role i.e. root
      let parentModuleArr: any [];
      parentModuleArr = getModuleActiveArr.filter((parentEle) => {
        return (parentEle.parentid == 0);
      });
      //create data with parent title and data which return child role in group of same parent role
      parentModuleArr.forEach(element => {
        let tempObj = {
          title: element.name,
          id: element.id,
          data: getModuleActiveArr.filter((ele) =>{ 
            return(ele.parentid == element.id)
          })
        }
        self.finalModuleArr.push(tempObj)
      });
      console.log( self.finalModuleArr);
    }, error => {
      console.log(error);
    });
  }

  getPermissions() {
    let self = this;
    this.register.getPermissionData().subscribe(data => {
      self.getPermissionObj = data.data;
      // this.showAfterValue = true;
      // self.getPermissionObj.forEach(element => {
      //   element.checkedvalue = true;
      // });
     // console.log(self.getPermissionObj)
    }, error => {
     // console.log(error);
    });
  }
  addNewRole(){

    if(this.checkedRole.length==0){
      this.toaster.error("Please select Role")
    }else if(this.module==''){
      this.toaster.error("Please select Module")
    }else if(this.checkedModuleItems.length==0){
      this.toaster.error("Please select Sub Module")
    }else if(this.checkedPermissions.length==0){
      this.toaster.error("Please select Permissions")
    }else{
      var obj ={
        roleId:this.checkedRole,
        moduleId:this.moduleid,
        moduleItemId:this.checkedModuleItems,
        permissonList:this.checkedpermissionString,
        isActive:true
      }
     // console.log(obj)
  
      this.roles.addNewRoleData(obj).subscribe(data => {
        this.roleObj = data.data;
        // console.log(this.roleObj)
        if(data.status) {
          this.toaster.success("Added Successfully")
          this.router.navigateByUrl("/corporate/roles")
        }
      }, error => {
      //  console.log(error);
      });
    }
  }

}




