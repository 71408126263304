<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/supportuser">Support</a></li>
      <li class="breadcrumb-item active" aria-current="page">Request List</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-sm-6">

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" value="" type="text" placeholder="Search Seller name" aria-label="Search" #searchText>
      </div>
    </div>
   <div class="col-12">
     
    </div>
  </div>
  <div class="tblPrt">

    <!-- <div class="mt-3" *ngIf="allTablesobj.length==0">No Sensitive Tables</div> -->
    
    <!-- <div style="text-align: center;" *ngIf="allTablesobj && allTablesobj.length === 0"> -->
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" >
  
  
        <tr>
          <!-- <th>Theme Id</th> -->
          <th (click)="sorting('seller_name')" style="cursor: pointer">Seller Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>          
          <th (click)="sorting('customer_company_name')"  style="cursor: pointer">Company Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
          <th>Case Id</th>

          <!-- <th>Interval</th>
          <th (click)="sorting('created_by')"  style="cursor: pointer">Support User <i class=" fa fa-sort text-black" aria-hidden="true"></i></th> -->

          <th (click)="sorting('createdon')"  style="cursor: pointer">Date <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
          <th *ngIf="currentUser.role_id == 71" class="text-center">Action</th>
          
        </tr>
       
        <tr *ngFor="let obj of requestList">
          <td>{{obj.seller_name}}</td>
          <td>{{obj.customer_company_name}}</td>
          <td>{{obj.salesforce_case_id}}</td>

       

          <td><div style="min-width: 100px;display: inline-block;">{{obj.createdon | date:'medium'}}</div></td>
          <td *ngIf="obj.access_status==0 && currentUser.role_id == 71" class="text-center">   <button *ngIf="isWrite" class="custbtn min-wit-200"   (click)="getDsSession(obj.seller_id,obj.support_user_id,obj.salesforce_case_id,obj.time_interval)" [disabled]="(obj.access_status==0 || obj.access_status == 2)?true:false">Requested</button></td>
          <td *ngIf="obj.access_status==1 && currentUser.role_id == 71" class="text-center">   <button *ngIf="isWrite"  class="custbtn min-wit-200"   (click)="getDsSession(obj.seller_id,obj.support_user_id,obj.salesforce_case_id,obj.time_interval)" [disabled]="(obj.access_status==0 || obj.access_status == 2 || obj.disabled)?true:false">Request Granted</button></td>
          <td *ngIf="obj.access_status==2 && currentUser.role_id == 71" class="text-center">   <button *ngIf="isWrite" class="custbtn min-wit-200"   (click)="getDsSession(obj.seller_id,obj.support_user_id,obj.salesforce_case_id,obj.time_interval)" [disabled]="(obj.access_status==0 || obj.access_status == 2)?true:false">Request Denied</button></td>
        </tr>
       
      </table>

      <p *ngIf="!isFetching && requestList.length == 0">No Result Available</p>

      <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getRequestList(page=$event)" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination> 
      </div>
      
    <!-- </div> -->
    
  </div>