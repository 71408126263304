<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{projectName}} Products</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-8">
  <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white" aria-hidden="true"></i></span>
      </div>
      
      <input class="form-control" type="text" placeholder="Search" aria-label="Search">
    </div>
      <!-- <i class="fa fa-remove text-white" aria-hidden="true"></i> -->
    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input  *ngIf="isWrite" type="button" value="Add New" class="custbtn" [routerLink]="['/corporate/margonewuser']">
      
    </div>
  </div> 

  
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Product Name <i class=" fa fa-sort" aria-hidden="true"></i></th>
        <th>Create Date</th>
        <th>Create By <i class=" fa fa-sort" aria-hidden="true"></i> </th>
        <th>Is active</th>
      </tr>
      <tr>
        <td>Twitter</td>
        <td>January 31,2020</td>
        <td>System</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Replenishment</td>
        <td>February 28,2020</td>
        <td>Chris H</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Cross-sell</td>
        <td>February 28,2020</td>
        <td>System</td>
        <td>No</td>
      </tr>
      <tr>
        <td>LinkedIN</td>
        <td>February 28,2020</td>
        <td>Chris H</td>
        <td>yes</td>
      </tr>
      

  </table>
</div>  
