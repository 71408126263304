import {BaseComponent} from '../../base.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AudienceBuilderService} from '../../../services/segmentation/audience-builder.service';
import { Console } from 'console';


@Component({
  selector: 'app-schedule-bu-audience',
  templateUrl: './schedule-bu-audience.component.html',
  styleUrls: ['./schedule-bu-audience.component.css']
})
export class ScheduleBuAudienceComponent extends BaseComponent implements OnInit, OnDestroy{

  scheduleForm: FormGroup;
  submitted: boolean;
  status = true;
  maxdate: any;
  timezones = [];
  audId: any;
  audData: any;
  companyId: any;
  minStartDate = new Date();
  minEndDate = new Date();
  stTime = { hour: 0, minute: 0 };
  endTime = { hour: 0, minute: 0 };
  subscription: any;
  isBack:boolean=true;

  schedulerData: any=[];

  statusEdit:any;
  audType = 'segmentation';

  constructor(private formBuilder: FormBuilder,
     private audienceBuilderService: AudienceBuilderService) {

    super();
    this.submitted = false;
    let uData = JSON.parse(localStorage.getItem('userData'));

    // this.companyId = parseInt(uData.admin_customer_id, 10);
    if (localStorage.getItem('companyId')) {
      this.companyId = localStorage.getItem('companyId');
    } else {
      this.companyId = parseInt(uData.admin_customer_id, 10);
    }
    
    this.subscription = this.activatedRoute.queryParams
      .subscribe(params => {
        this.audId = params.audid;
        this.statusEdit = params.edit;


      });
    if (!this.audId) {
      this.router.navigateByUrl('/corporate/audience-builder');
    } else {
      this.fetchData();
    }
  }

  ngOnInit() {

    this.scheduleForm = this.formBuilder.group({
      scheduleType: ['true', Validators.required],
      scheduleStartDate: ['', Validators.required],
      tzone: ['', Validators.required],
      freq: [1, Validators.required],
      freqType: ['Day', Validators.required],
      endSchedNum: [1, Validators.required],
      scheduleEndDate: ['', Validators.required]
    });




  }

  //  ################  Fetch Data ########################  /

  fetchData(){
    this.audienceBuilderService.getAUDDataByID(this.companyId, this.audId).subscribe(data => {
      if (data.status) {

       // console.log("query 1........",data.data)
        this.audData = data.data[0];
        this.audType = data.data[0].aud_type;
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
    this.audienceBuilderService.getTimezones().subscribe(data => {
      if (data.status) {
        this.timezones = data.data;

        if( this.statusEdit) {
          this.editFetchData();

         }


      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }


  editFetchData(){

    this.audienceBuilderService.getAudienceBuilderSchedule(this.companyId, this.audId).subscribe(data => {
      if (data.status) {
        this.status=false;
        this.schedulerData = data.data[0];
        this.databinding();
      }
      else {
        this.statusEdit = "";
        this.status=true;
        this.schedulerData = [];
        this.isBack =false;


      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  databinding() {


    let currentTime = new Date();

    let startDate;
    let endDate;

    if(this.schedulerData.schedule_start) {

      startDate = new Date(this.schedulerData.schedule_start);
      if(currentTime.getTime() < startDate.getTime())  this.minStartDate =currentTime;
      else this.minStartDate = startDate;

    }


    if(this.schedulerData.schedule_end) {

      endDate = new Date(this.schedulerData.schedule_end);
      if(currentTime.getTime() < endDate.getTime())  this.minEndDate =currentTime;
      else this.minEndDate = endDate;



    }



   // if(this.schedulerData.schedule_end)  this.minEndDate = new Date(this.schedulerData.schedule_end);

   // console.log("result data.......",this.minStartDate)

    let tz;
  //  if( this.schedulerData.schedule_timezone) {
  //   tz = this.timezones.filter( tzo => tzo.utc[0] === this.schedulerData.schedule_timezone);
  //  }

   if(this.schedulerData.schedule_timezone && this.schedulerData.schedule_abbr) {
    tz = this.timezones.filter( tzo => tzo.utc[0] === this.schedulerData.schedule_timezone && tzo.abbr === this.schedulerData.schedule_abbr);
   } else {
    if (this.schedulerData.schedule_timezone) {
      tz = this.timezones.filter( tzo => tzo.utc[0] === this.schedulerData.schedule_timezone);
    }
   }

    let schdeuletype
    if(this.schedulerData.schedule_type)
       {
           schdeuletype =  'true';
       }
    else {  schdeuletype =  'false'; }

    this.scheduleForm = this.formBuilder.group({
      scheduleType: [schdeuletype, Validators.required],
      scheduleStartDate: [(this.schedulerData.schedule_start.split('Z')[0]), Validators.required],
      tzone: [(tz?tz[0].value:''), Validators.required],
      freq: [this.schedulerData.schedule_interval_value, Validators.required],
      freqType: [this.schedulerData.schedule_interval_type, Validators.required],
      endSchedNum: [1, Validators.required],
      scheduleEndDate: [(this.schedulerData.schedule_end.split('Z')[0]), Validators.required]
    });
    let startTimeHour = new Date(this.schedulerData.schedule_start.split('Z')[0]).getHours();
    let startTimeMins = new Date(this.schedulerData.schedule_start.split('Z')[0]).getMinutes();

    let endimeHour = new Date(this.schedulerData.schedule_end.split('Z')[0]).getHours();
    let endTimeMins = new Date(this.schedulerData.schedule_end.split('Z')[0]).getMinutes();

  //  console.log("start time...",startTimeHour);


    this.stTime = {
       'hour' :startTimeHour,
       'minute':startTimeMins

    }
    this.endTime = {
      'hour' :endimeHour,
      'minute':endTimeMins

   }



  }



  ischeck() {
    if(this.f.freq.value==0) {


      this.toastrService.error("Can't set frequency 0")
      this.f.freq.setValue("");

    }

  }

  isNumber(evt) {



    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }

  get f() { return this.scheduleForm.controls; }

  //  ############  Add Schedule for Audience #################  //

  submitSchedule(){
    this.submitted = true;
    let self = this;
    if (this.scheduleForm.value.scheduleType === 'false') {

      let obj = {
        audbid: this.audId,
        company: this.companyId,
        schedule_type: this.scheduleForm.value.scheduleType,
        schedule_start: '',
        schedule_timezone: 'Asia/Kolkata',
        abbr: 'IST',
        schedule_interval_value: '',
        schedule_interval_type: '',
        schedule_end: '',
        status: true,
      };

         this.audienceBuilderService.createSchedule(obj).subscribe(data => {
          if (data.status) {

            if( (self.audType=='cross-sell') || (self.audType=='replenishment')) {
              this.router.navigateByUrl('/corporate/replenishment-crosssell-audience');
            }else{
              this.router.navigateByUrl('/corporate/audience-builder');
            }

            this.toastrService.success('Schedule created Successfully');
            this.ngxSpinnerService.hide();
            //this.router.navigateByUrl('/corporate/audience-builder');
          } else {
            this.toastrService.error(data.message);
            this.ngxSpinnerService.hide();
          }

        }, error => {
        //  console.log(error);
          this.ngxSpinnerService.hide();
        });

    } else {

      if (this.scheduleForm.invalid) {
        return;
      }

      if (this.scheduleForm.value.freqType === 'Minute') {
        if (this.scheduleForm.value.freq < 5) {
          this.toastrService.error('Please select a schedule frequency greater than 5 minutes');
          return;
        }
      }

      let tmpDt = new Date(this.scheduleForm.value.scheduleStartDate);
      let tmpEndDt = new Date(this.scheduleForm.value.scheduleEndDate);

      let tz = this.timezones.filter( tzo => tzo.value === this.scheduleForm.value.tzone );
      let utc = tz[0].utc[0];

      tmpDt.setHours(this.stTime.hour, this.stTime.minute, 0);
      tmpEndDt.setHours(this.endTime.hour, this.endTime.minute, 0);
      let chkTm = 5 * 60 * 1000;

      let newsysdate = new Date().toLocaleString("en-US", { timeZone: utc });
      let sysdate = new Date(newsysdate);

    
      if (tmpDt.getTime() - sysdate.getTime()   < chkTm) {
        this.toastrService.error('Please select a Start Date and Time greater than Current Date and Time');
        return;
      }

      if (tmpEndDt.getTime() - sysdate.getTime()   < chkTm) {
       this.toastrService.error('Please select a End Date and Time greater than Current Date and Time');
        return;
      }

      if (tmpEndDt.getTime() - tmpDt.getTime()   < chkTm) {
        this.toastrService.error('Please select a End Date and Time greater than selected Start Date and Time');
        return;
      }

      let checkStartTime;
      if (this.scheduleForm.value.freqType === 'Day') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 24 * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Hour') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Minute') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Week') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 7 * 24 * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }
      }

     

    this.ngxSpinnerService.show();


    let tzOffset = new Date().getTimezoneOffset();
    let t1 = new Date(new Date(tmpDt).getTime() - tzOffset*60*1000).toISOString();
    let t2 = new Date(new Date(tmpEndDt).getTime() - tzOffset*60*1000).toISOString();
    
      let obj = {
        audbid: this.audId,
        company: this.companyId,
        schedule_type: this.scheduleForm.value.scheduleType,
        // schedule_start: tmpDt.toISOString(),
        schedule_start: t1,
        schedule_timezone: utc,
        abbr: tz[0].abbr,
        schedule_interval_value: this.scheduleForm.value.freq,
        schedule_interval_type: this.scheduleForm.value.freqType,
        // schedule_end: tmpEndDt.toISOString(),
        schedule_end: t2,
        status: true
      };


          this.audienceBuilderService.createSchedule(obj).subscribe(data => {
          if (data.status) {
            this.toastrService.success('Schedule created Successfully');
            this.ngxSpinnerService.hide();
            this.router.navigateByUrl('/corporate/audience-builder');
          } else {
            this.toastrService.error(data.message);
            this.ngxSpinnerService.hide();
          }

        }, error => {
        //  console.log(error);
          this.ngxSpinnerService.hide();
        });



    }
  }


  //  ############  Update Schedule for Audience #############  //

  updateSchedule(){
    this.submitted = true;
    const self = this;
    if (this.scheduleForm.value.scheduleType === 'false') {

      let obj = {
        audbid: this.audId,
        company: this.companyId,
        schedule_type: this.scheduleForm.value.scheduleType,
        schedule_start: '',
        schedule_timezone: 'Asia/Kolkata',
        abbr: 'IST',
        schedule_interval_value: '',
        schedule_interval_type: '',
        schedule_end: '',
        status: true,
      };


         this.audienceBuilderService.updateAudienceBuilderSchedule(obj,this.schedulerData.audbsid).subscribe(data => {
          if (data.status) {
            this.toastrService.success('Schedule Updated Successfully');
            this.ngxSpinnerService.hide();
            if( (self.audType=='cross-sell') || (self.audType=='replenishment')) {
              this.router.navigateByUrl('/corporate/replenishment-crosssell-audience');
            }else{
              this.router.navigateByUrl('/corporate/audience-builder');
            }
            
          } else {
            this.toastrService.error(data.message);
            this.ngxSpinnerService.hide();
          }

        }, error => {
        //  console.log(error);
          this.ngxSpinnerService.hide();
        });
    } else {

      if (this.scheduleForm.invalid) {
        return;
      }

      if (this.scheduleForm.value.freqType === 'Minute') {
        if (this.scheduleForm.value.freq < 5) {
          this.toastrService.error('Please select a schedule frequency greater than 5 minutes');
          return;
        }
      }

      let tmpDt = new Date(this.scheduleForm.value.scheduleStartDate);
      let tmpEndDt = new Date(this.scheduleForm.value.scheduleEndDate);

      let tz = this.timezones.filter( tzo => tzo.value === this.scheduleForm.value.tzone );

      let utc = tz[0].utc[0];

      //tmpDt = new Date().toLocaleString("en-US", { timeZone: utc});

      tmpDt.setHours(this.stTime.hour, this.stTime.minute, 0);
      tmpEndDt.setHours(this.endTime.hour, this.endTime.minute, 0);
      let chkTm = 5 * 60 * 1000;



      let newsysdate = new Date().toLocaleString("en-US", { timeZone: utc });
      let sysdate = new Date(newsysdate);




      //let currentTime =new Date();
        //  currentTime = currentTime.toLocaleString("en-US", { timeZone: utc})


   
        if (tmpDt.getTime() - sysdate.getTime()   < chkTm) {
        this.toastrService.error('Please select a Start Date and Time greater than current Date and Time');
        return;
      }

      if (tmpEndDt.getTime() - sysdate.getTime()   < chkTm) {
        this.toastrService.error('Please select a End Date and Time greater than Current Date and  Time');
        return;
      }

      if (tmpEndDt.getTime() - tmpDt.getTime()   < chkTm) {
        this.toastrService.error('Please select a End Date and Time greater than Selected Start Date and Time');
        return;
      }


      let checkStartTime;
      if (this.scheduleForm.value.freqType === 'Day') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 24 * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Hour') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Minute') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }

      } else if (this.scheduleForm.value.freqType === 'Week') {
        checkStartTime =  tmpDt.getTime() + (this.scheduleForm.value.freq * 7 * 24 * 60 * 60 * 1000);
        if (checkStartTime >= tmpEndDt.getTime()) {
          this.toastrService.error('End Time Should Be Greater Than Start time. Also Please Check That End Time Is According To Frequency');
          return;
        }
      }

     

      this.ngxSpinnerService.show();

      let tzOffset = new Date().getTimezoneOffset();
      let t1 = new Date(new Date(tmpDt).getTime() - tzOffset * 60 * 1000).toISOString();
      let t2 = new Date(new Date(tmpEndDt).getTime() - tzOffset * 60 * 1000).toISOString();

      let obj = {
        audbid: this.audId,
        company: this.companyId,
        schedule_type: this.scheduleForm.value.scheduleType,
        // schedule_start: tmpDt.toISOString(),
        schedule_start: t1,
        schedule_timezone: utc,
        abbr: tz[0].abbr,
        schedule_interval_value: this.scheduleForm.value.freq,
        schedule_interval_type: this.scheduleForm.value.freqType,
        // schedule_end: tmpEndDt.toISOString(),
        schedule_end: t2,
        status: true
      };




        this.audienceBuilderService.updateAudienceBuilderSchedule(obj,this.schedulerData.audbsid).subscribe(data => {
          if (data.status) {
            this.toastrService.success('Schedule Updated Successfully');
            this.ngxSpinnerService.hide();
            this.router.navigateByUrl('/corporate/audience-builder');
          } else {
            this.toastrService.error(data.message);
            this.ngxSpinnerService.hide();
          }

        }, error => {
         // console.log(error);
          this.ngxSpinnerService.hide();
        });





    }
  }

  //  ############  On Destroy  #############################  //

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backQueryEditPage() {

    localStorage.setItem("editAudienceID", this.audId);
    localStorage.setItem("cloneAudience",'1');
    this.router.navigate(['/corporate/clone-audience-builder']);


  }
}