import { Component, OnInit } from '@angular/core';
import { FbService } from 'src/app/services/fbservice/fb.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/services/master/master.service';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewcampaign',
  templateUrl: './addnewcampaign.component.html',
  styleUrls: ['./addnewcampaign.component.css']
})
export class AddnewcampaignComponent implements OnInit {
  name;
  description;
  screenshot;
  isactive =true;
  status: boolean = true;
  campaign_id;
  campaignObj;
  header = "Add New"
  fileData;
  fileUploaded;
  screenshot_type
  constructor(private fb:FbService,
    private logs :LogsService,
    private toastrService:ToastrService,
    private router:Router,private ar: ActivatedRoute,private master: MasterService, private ngxSpinnerService:NgxSpinnerService, private upload:UploadService) { }

  ngOnInit() {
    let self = this;
    this.ar.queryParams
      .subscribe(params => {
        self.campaign_id = params.campaign_id;
      });
    //get item details using id
    if (self.campaign_id) {
      this.header = "Edit"
      this.fb.getCampaignById(this.campaign_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.campaignObj = response.data;
        //  console.log(self.campaignObj);

          self.dataBinding();
        } else {

        }
      });
    }
    if (self.campaign_id) {
      this.UpdateCampaignLogData()
    }else {
      this.addCampaignLogData()
    }
  }
  addCampaignLogData() {

    let CampaignLogs = {
      log_type: "Adding New Campaign",
      log_name: "Adding New Campaign",
      log_description: "Adding New Campaign",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CampaignLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  UpdateCampaignLogData() {

    let CampaignLogs = {
      log_type: "Updating Campaign Data",
      log_name: "Updating Campaign Data",
      log_description: "Updating Campaign Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CampaignLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  dataBinding() {
    this.name = this.campaignObj.campaign_name;
    this.description = this.campaignObj.campaign_description;
    this.screenshot = this.campaignObj.campaign_screenshot;
    this.isactive = this.campaignObj.isactive;
  }
  updateCampaignData() {
    if( this.name && this.name.trim() && this.screenshot){
      let campobj = {
        campaign_name: this.name,
        campaign_description: this.description,
        campaign_screenshot: this.screenshot,
        isactive: this.isactive,
        campaign_id: Number.parseInt(this.campaign_id, 10)
      }
     // console.log(campobj,"####");

      this.fb.updateCampaign(campobj).subscribe(data => {
        if(data.status){
          this.toastrService.success("Record Updated successfully");
          this.router.navigateByUrl(`/corporate/campaign`);
        }

      }, error => {
        console.error(error);
      });
    }else{
      this.toastrService.error("Please Fill  fields")
    }
  }
  addNewCampaignData() {
    if ( this.name && this.name.trim() && this.isUrlValid(this.screenshot.trim())) {
      var obj = {
        name: this.name,
        description: this.description,
        screenshot: this.screenshot,
        isactive:this.isactive
      }
      this.fb.addNewCampaign(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success("Record Inserted successfully");
          this.router.navigateByUrl(`/corporate/campaign`);
        } else {
          this.toastrService.error(data.message)
        }
      }, error => {
        this.toastrService.error(error.message)
      });
    } else {
      this.toastrService.error("Please Fill all the fields")
    }
  }
  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
}
  processFile(event: any, imageInput: any) {
    // const file: File = imageInput.files[0];
    // console.log(file);
    // const reader = new FileReader();
    // this.fileData = file;
    // this.fileUploaded = true;
    // this.toUploadFiles(file);
    // reader.readAsDataURL(file);
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let vm = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            vm.screenshot = params.Location;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

}
