<div class="container">
    <div class="row">
    <div class="col-12">
        <nav aria-label="breadcrumb" class="breadCust">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="page"><a routerLink="/corporate/metadata-list">MetaData List</a></li>
              <li class="breadcrumb-item active" aria-current="page">Update MetaData</li>
            </ol>
          </nav>
    </div>
</div>
</div>


<div class="container">
<form class="form-horizontal mt-1" [formGroup]="buForm">
    <div class="row">
        <div class="col-md-10 col-xl-7">
            <div class="card mt-1 mb-3">
                <div class="card-body">
                    <div *ngIf="customerID === 0">
                        <label for="buForm">BU List <span class="required">*</span>
                        </label>
                        <select autocomplete="off" formControlName="buid" (change)="onBuSelected()"
                            class="form-control formstyle">
                            <option value="">
                                Select BU
                            </option>
                            <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                                {{ obj.customer_company_name }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="buTables.length > 0" class="mt-2">
                        <label for="buForm">BU Tables List <span class="required">*</span>
                        </label>
                        <select autocomplete="off" formControlName="selTable" (change)="onBuTableSelected()"
                            class="form-control formstyle">
                            <option value="">
                                Select BU Table
                            </option>
                            <option *ngFor="let obj of buTables" value="{{obj.table_alias}}">
                                {{ obj.table_name }}
                            </option>
                        </select>
                    </div>
                    <!-- <div *ngIf="buTables.length > 0 && selectedTable !== ''">
                        <label for="buForm">Client Tables List <span class="required">*</span>
                        </label>
                        <select autocomplete="off" formControlName="selClientTable" (change)="onClientTableSelected()"
                            class="form-control formstyle">
                            <option value="">
                                <--- Select Client Table--->
                          <!--  </option>
                            <option value="bi_Reps">Repository</option>
                            <option value="bi_Customers">Customers</option>
                            <option value="bi_CustomerNotes">Customer Notes</option>
                            <option value="bi_InventoryCategory">Inventory Category</option>
                            <option value="bi_InventoryPrices">Inventory Prices</option>
                            <option value="bi_InventoryCollectionTypes">Inventory Collection Types</option>
                            <option value="bi_Orders">Orders</option>
                            <option value="bi_OrderDetails">Order Details</option>
                            <option value="bi_OnlineOrders">Online Orders</option>
                            <option value="bi_OnlineOrderDetails">Online Order Details</option>
                            <option value="bi_Party">Party</option>
                            <option value="cs_bi_Party">CS Party</option>
                            <option value="bi_PartyGuests">Party Guests</option>
                        </select>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</form>
</div>

<div class="container mt-2" *ngIf="selectedBuId && selectedTable && buMappingData.length > 0 && buMappingClData.length > 0">
<div class="row">
    <div class="col-12">
        <h5 class="font-weight-bold mt-2 mb-2 mr-2 float-left">Meta Fields</h5>
        <div  style="justify-content: right">
            <input type="button" value="Add New Metadata Field" *ngIf="isWrite" class="custbtn red-lt-icon" (click)=addNewMetaField()/>    
          </div>
    </div>
    
   
</div>

<form class="form-horizontal mt-2" [formGroup]="metaForm" *ngIf="t.controls.length > 0">
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="mt-1">


                        <div *ngFor="let meta of t.controls; let i = index" class="list-group list-group-flush min-wit-700">
                            <div class="list-group-item" >
                                <h5 class="card-title">
                                    Meta Field {{i + 1}}
                                    <a>
                                        <span class="fa fa-trash mr-1 cursor" (click)="removeMeta(i)"
                                           *ngIf="isDelete" placement="bottom" ngbTooltip="Delete">
                                        </span>
                                    </a>
                                </h5>
                                <div [formGroup]="meta" class="form-row">
                                    <div class="form-group col-2">
                                        <label>Field Name</label>
                                        <!-- <input type="text" formControlName="name" appSpace (keydown)="onNameInput(i)" maxlength="40" class="form-control" [ngClass]="{ 'is-invalid': submitted && meta.controls.name.errors }" /> -->
                                        <input type="text" formControlName="name" appSpace   maxlength="40" class="form-control" [ngClass]="{ 'is-invalid': submitted && meta.controls.name.errors }" 
                                        pattern="([a-zA-Z0-9_]+)"/>
                                        <div *ngIf="submitted && meta.controls.name.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.name.errors.required">Name is required</div>

                                            <div *ngIf="meta.controls.name.errors.numCheck">
                                                <p >Only Numbers are not allowed to create Field Names </p>
                                            </div>
                                            <div *ngIf="meta.controls.name.errors.pattern">
                                                <p >Spatial Character not allowed </p>
                                            </div>
                                        </div>
                                        

                                    </div>
                                    <div class="form-group col-2">
                                        <label>Meta Field Name</label>
                                        <!-- <a class="ml-1" ><span class="fa fa-edit mr-1 cursor" (click)="checkForMetaField(i)"
                                        *ngIf="isWrite" placement="bottom" ngbTooltip="Add">
                                        </span></a> -->
                                        <input type="text" formControlName="metafname" appSpace maxlength="40" pattern="([a-zA-Z][a-zA-Z0-9_]+)" class="form-control" [ngClass]="{ 'is-invalid': submitted && meta.controls.metafname.errors }" />
                                        <!-- <input type="text" formControlName="metafname" readonly class="form-control" [ngClass]="{ 'is-invalid': submitted && meta.controls.metafname.errors }" /> -->
                                        <div *ngIf="submitted && meta.controls.metafname.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.metafname.errors.required">Meta Field Name is required</div>
                                            <div *ngIf="meta.controls.metafname.errors.pattern">
                                                <p >Field Name should contain Numbers, Characters & Underscore( "_" ) Only </p>
                                                <p >Field Name should start with Charcters (a-z, A-Z) Only </p>
                                            </div>

                                            <div *ngIf="meta.controls.metafname.errors.numCheck">
                                                <p >Only Numbers are not allowed to create Column Names </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label>Field Type</label>
                                        <select formControlName="ftype" autocomplete="off" class="form-control formstyle "
                                            [ngClass]="{ 'is-invalid': submitted && meta.controls.ftype.errors }">
                                            <option value=''>
                                                Field Type
                                            </option>
                                            <option *ngFor="let ft of fieldsType" value="{{ft.id}}">{{ft.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && meta.controls.ftype.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.ftype.errors.required">Field Type is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label>Meta Field Size</label>
                                        <input type="text" oninput="this.value=this.value.slice(0,this.maxLength)"
                                        (keydown)="allowNumbersOnly($event)" onpaste="return false;" ondrop="return false;"
                                        formControlName="fsize" class="form-control" maxlength="5" pattern="[1-9][0-9]{0,4}" [ngClass]="{ 'is-invalid': submitted && meta.controls.fsize.errors }" />
                                        <div *ngIf="submitted && meta.controls.fsize.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.fsize.errors.required">Meta Field Size is required</div>
                                            <div *ngIf="meta.controls.fsize.errors.pattern">
                                                <p *ngIf="meta.controls.fsize.value === '0'">Field Size should be greater than 0</p>
                                                <p *ngIf="meta.controls.fsize.value !== '0'">Field Size should not start with 0 or contain Special Characters</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label>Mapping Field</label>
                                        <select formControlName="mapfield" autocomplete="off" class="form-control formstyle "
                                            [ngClass]="{ 'is-invalid': submitted && meta.controls.mapfield.errors }">
                                            <option value=''>
                                                Mapping Field
                                            </option>
                                            <option *ngFor="let dt of buMappingClData;let i = index" value="{{i}}">{{dt.column_name}} - {{dt.table_name}}</option>
                                        </select>
                                        <div *ngIf="submitted && meta.controls.mapfield.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.mapfield.errors.required">Mapping Field is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label>Display on DS</label>
                                        <select formControlName="viewonds" autocomplete="off" class="form-control formstyle "
                                            [ngClass]="{ 'is-invalid': submitted && meta.controls.viewonds.errors }">
                                            <option  value="true">Yes</option>
                                            <option  value="false">No</option>
                                        </select>
                                        <div *ngIf="submitted && meta.controls.viewonds.errors" class="invalid-feedback">
                                            <div *ngIf="meta.controls.viewonds.viewonds.required">Field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>


<div class="tblPrt" *ngIf="metaMapData && metaMapData.length > 0">
    <table class="mt-1 bdrStCustom mb-0 min-wit-700" width="100%" cellpadding="0" cellspacing="0" >
      <tr>

        <th >Name </th>
        <th>Column Name</th>
        <th>Field Type</th>
        <th>Field Size</th>
        <th>Map With</th>
        <th>Display On DS</th>
        <th width="80">Actions</th>

      </tr>
      <tr
        *ngFor="let obj of metaMapData">
        <td>{{obj.form_name}}</td>
        <td>{{obj.column_name}}</td>
        <td>
            <span *ngIf="obj.bu_field_type === 'VARCHAR'">Text</span>
            <span *ngIf="obj.bu_field_type === 'INT'">Number</span>
            <span *ngIf="obj.bu_field_type === 'NUMERIC'">Float</span>
            <span *ngIf="obj.bu_field_type === 'timestamp'">Timestamp</span>
            <span *ngIf="obj.bu_field_type === 'BOOLEAN'">Boolean</span>
        </td>
        <td>{{obj.bu_field_size}}</td>
        <td>{{obj.map_with}}</td>
        <td>
            <span *ngIf="obj.is_flag">YES</span>
            <span *ngIf="!obj.is_flag">NO</span>
            <mat-slide-toggle [checked]="obj.is_flag" class="my-slider cursor ml-1"  *ngIf="isEdit"
             (click)="updateStatus($event,obj.mapid,obj.is_flag)">
            </mat-slide-toggle>
        </td>
        <td>
          <div class="actBtn wit-80">
            <!-- <i class="fa fa-edit mr-1 cursor"   *ngIf="isEdit"
              placement="bottom" ngbTooltip="Edit"></i> -->
            <span class="fa fa-trash mr-1 cursor" (click)="onMetaDataFieldDelete(obj.mapid)" *ngIf="isDelete"
              placement="bottom" ngbTooltip="Delete"></span>
          </div>
        </td>
      </tr>

    </table>
    <div
    *ngIf="(metaMapData | filter: text).length === 0"
    style="text-align: center"
  >
    <p>No Result Available</p>
  </div>
  <div
  *ngIf="(metaMapData | filter: text).length !== 0"
    style="text-align: center"
  >
    <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
  </div>
  </div>

<h5 class="font-weight-bold mt-4 mb-4">Mapping</h5>


<form class="form-horizontal mt-1 " [formGroup]="dynForm">
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="mt-1">

                        <div *ngFor="let fd of b.controls; let i = index" class="list-group list-group-flush">
                            <div class="list-group-item" >
                                <div [formGroup]="fd" class="form-row">
                                    <div class="form-group col-sm-3 col-6">
                                        <label>{{fd.controls.fldnm.value}}</label>
                                        <input type="text" formControlName="fname" class="form-control" [ngClass]="{ 'is-invalid': submitted && fd.controls.fname.errors }" />
                                        <div *ngIf="submitted && fd.controls.fname.errors" class="invalid-feedback">
                                            <div *ngIf="fd.controls.fname.errors.required">Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-3 col-6">
                                        <label>Mapping Field</label>
                                        <select formControlName="dmapField" autocomplete="off" class="form-control formstyle "
                                            [ngClass]="{ 'is-invalid': submitted && fd.controls.dmapField.errors }">
                                            <option value=''>
                                                Mapping Field
                                            </option>
                                            <option *ngFor="let dt of buMappingClData;let i=index" value="{{i}}">{{buMappingClData[i].column_name}} - {{buMappingClData[i].table_name}}</option>
                                        </select>
                                        <div *ngIf="submitted && fd.controls.dmapField.errors" class="invalid-feedback">
                                            <div *ngIf="fd.controls.dmapField.errors.required">Mapping Field is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="card-footer" >
                    <div class="col-12 text-center"  style="justify-content: center">
                        <input
                          type="button"
                          value="SUBMIT"
                          *ngIf="isWrite"
                          class="custbtn red-lt-icon"
                          (click)=confirmMetaAddition()/>    
                      </div>
                </div>
            </div>
        </div>
    </div>
</form>



</div>
