<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/subjectlib">Support Subject Library</a></li>          
      <li class="breadcrumb-item active" aria-current="page">Add Subject Library</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <form class="form-horizontal mt-1" [formGroup]="AddSubject">
    <div class="rowTheme">
        <div class="themContain">

            <div *ngIf="currentUserID==0">
                <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                <select formControlName="company" *ngIf="help_subject_id == undefined" class="form-control formstyle" [ngModel] ="company"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <option value="">Select Company</option>
                    <ng-container *ngFor="let userObj of getCompanyobj" >
                        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                    <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
                        {{userObj.customer_company_name}}
                    </option>
                    </ng-container>
                </select>
                <select formControlName="company" *ngIf="help_subject_id != undefined" disabled="controlEnabled" class="form-control formstyle" [ngModel] ="company"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                <option value="">Select Company</option>
                <ng-container *ngFor="let userObj of getCompanyobj" >
                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
                    {{userObj.customer_company_name}}
                </option>
                </ng-container>
                 </select>
                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Company Name is required</div>
                </div>
            </div>

            <div *ngIf="currentUserID>0">
              <input type="hidden"  formControlName="company" value="company">
            </div>



            <!--
        <div>
            <label for="exampleForm2">Subject Library Type</label>
            <select  formControlName='help_type' class="form-control formstyle">
                <option value="undefined"  disabled > --select Type-- </option>
                <option value="1" selected>Support text</option>
                
            </select>
        </div>
        -->
        <div class="mt-1">
            <label for="exampleForm2">Subject Name <span class="required">*</span></label>
            <input type="text" class="form-control" formControlName='subject_name' 
                 class="form-control form-control-md" required min="1" 
                [ngClass]="{ 'is-invalid': submitted && f.subject_name.errors }" maxlength="100" appSpace>
            <div *ngIf="submitted && f.subject_name.errors" class="invalid-feedback">
                <div *ngIf="f.subject_name.errors.required || f.subject_name.hasError('whitespace')">Subject Name is required</div>
                 <div *ngIf="f.subject_name.errors.maxlength">You can enter only 100 charachters</div>
       
            </div>
        </div>
        
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewSubjectlib()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateSubjectlib()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</form>
</div>