import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { CommonMessages, ClientMessages } from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-messagelib',
  templateUrl: './messagelib.component.html',
  styleUrls: ['./messagelib.component.css']
})
export class MessagelibComponent extends BaseComponent implements OnInit, AfterViewInit {
  public messagelibObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "message_body";
  checked = false;
  currentUser;
  companyID;
  getCompanyobj;
  body;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private clientService: ClientService,
  ) {
    super();
  }
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') { this.getMessagelib(); this.companyID = this.currentUser.admin_customer_id; }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getMessagelib(this.body, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.messagelibObj = [] }
          else { this.messagelibObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.messagelibObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getMessagelib();
  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      // console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }
  getMessagelib() {
    if(this.body.company == '' || this.body.company == 0){return;}
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getMessagelib(this.body, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      self.messagelibObj = response.data.rows;
      self.totalCount = response.data.total;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }

  changeMessageLib(id = "") {
    this.body = {
      company: id || '',
      is_corporate: true
    }
    if (id != '') {this.companyID = id;
    this.getMessagelib();}else {
      this.messagelibObj = [] ;
      this.totalCount = 0;
      this.cp = 1 ;
    }
  }
  
  editMessagelib(id) {
    this.router.navigate(['/corporate/newmessagelib'], { queryParams: { companyID: this.companyID, help_message_id: id } });
  }

  deleteMessagelib(id) {
    let obj = {
      id: id,
      company: this.companyID
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delMessagelib(obj).subscribe(data => {
            if (data.status) {
              this.changeMessageLib(this.companyID);
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateMessagelibStatus(event, id) {
    let value = event.source.checked;
    let obj = this.messagelibObj.find(element => {
      if (element.help_message_id === id) {
        return true;
      }
    });
    obj = obj;
    obj.isactive = value;
    this.confirmationDialogService.confirm('Please confirm', CommonMessages.activeInactiveConfirmation + "", 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let messagelibObj = {
            isactive: obj.isactive,
            help_message_id: id,
            company: this.companyID
          }
          this.master.updateMessagelibStatus(messagelibObj).subscribe(data => {
            if (data.status) {
              this.toastrService.success(CommonMessages.statusUpdated);
              this.ngxSpinnerService.hide();
            }
          }, error => {
            console.error(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => {
        event.source.checked = !event.source.checked;
        //  console.log(CommonMessages.dialogDismissMessage)
      });
  }

  clickEvent() { }

  importFile() {
    let self = this;
    console.log('company', self.companyID);
    if (self.companyID != undefined) {
      self.ImportFilesDialogService.confirm1('Please Choose the File Path ', `../../../assets/margo-import-files/margo-message-library-import.csv`, 'addingMessageBulk', self.companyID)
        .then(data => {
          // console.log(data);
          self.changeMessageLib(self.companyID);
          this.ngxSpinnerService.hide();
        })
        .catch(err => {
          console.error(err);
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }

  exportFile() {
    if (this.companyID != undefined) {
      let tablename = {
        "cid": this.companyID,
        "tableName": "margo_help_message_tbl",
        "is_corporate": true
      }
      this.master.exportBulk(tablename).subscribe(dataFile => {
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
}
