import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-journeymaster',
  templateUrl: './journeymaster.component.html',
  styleUrls: ['./journeymaster.component.css']
})
export class JourneymasterComponent extends BaseComponent implements OnInit {
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "journey_name";
  getCompanyobj;
  public journeyObj: any = [];
  today: number = Date.now();
  companyID = 0;
  currentUser;
  checkCompany;
  companyid
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  constructor(
    private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private clientService: ClientService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID = this.currentUser.admin_customer_id;
    this.companyid = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') {
      this.getJourneyData();
      this.JourneyLogData();
    }

    this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getJourneyAll(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.journeyObj = [] }
          else { this.journeyObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.journeyObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getJourneyData();
  }

  JourneyLogData() {
    let themeLogs = {
      log_type: "Journey Master View",
      log_name: "Journey Master View",
      log_description: "Journey Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);

    }, error => {
      // console.log(error);
    });

  }

  getJourneyData() {
    if(this.companyID == 0 ){return};
    this.ngxSpinnerService.show();
    // console.log("company themes:"+ id);
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.master.getJourneyAll(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      self.journeyObj = response.data.rows;
      this.totalCount = response.data.total;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getCompanyJourney(id) {

    // this.ngxSpinnerService.show();
    //event.preventDefault();
    if (id != 0) {
      this.companyID = id;
      this.getJourneyData();
    } else { this.journeyObj = [];this.totalCount = 0;this.cp = 1; this.ngxSpinnerService.hide(); }
  }

  deleteJourney(id) {
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'ARE YOU SURE YOU WANT TO DELETE ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delJourney(id, this.companyID).subscribe(data => {
            if (data.status) {
              this.getCompanyJourney(this.companyID);
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  editJourney(id) {
    this.router.navigate(['/corporate/addnewjourney'], { queryParams: { journey_id: id, company: this.companyID } });
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.journeyObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.journey_id === id) {
        return true;
      }
    });
    //obj = obj.get_master_journey;

    // console.log("*********", obj)

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status? ', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;
          this.master.updateJourney(obj).subscribe(data => {
            this.getCompanyJourney(this.companyID);
            this.toastrService.success("Journey Master Status Changed Successfully");
          });
        }
        // else {
        //   this.master.updateJourney(obj).subscribe(data => {
        //     this.getJourney();
        //   });
        // }
      }).catch(() => {
        //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  importFile() {
    let self = this;
    if (self.companyID != undefined) {
      let tablename = {
        "tableName": "master_themes_tbl",
        "cid": this.companyID
      }
      this.ImportFilesDialogService.confirm1('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-journey-import.csv`, 'addingJourneyBulk', self.companyID)
        .then(data => {
          //  console.log(data);
          self.getJourneyData();
        })
        .catch(err => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        })
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
  exportFile() {
    if (this.companyID != undefined) {
      let tablename = {
        "tableName": "master_journey_tbl",
        'cid': this.companyID
      }
      //  return console.log('export', tablename);
      this.master.exportBulk(tablename).subscribe(dataFile => {
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      // console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
}
