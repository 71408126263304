import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, Subject, of, combineLatest } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';


@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  public baseUrls;
  public baseUrls1;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {

    this.baseUrls = baseService.baseUrl1 + "api/license/"
    this.baseUrls1 = baseService.baseUrl1 + "api/ds/"
   }

   gettingLicense(id) {    
    return this.http.get<any>(this.baseUrls + `getLicenselisting/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 


  gettingBundleName(id) {    
    return this.http.get<any>(this.baseUrls + `randomcode/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 
  gettingduration(id) {    
    return this.http.get<any>(this.baseUrls + `getDuration/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 

  
  getDSList(id) {    
    return this.http.get<any>(this.baseUrls + `getDSList/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  } 


  getAllBundles(id,page) {    
    return this.http.get<any>(this.baseUrls + `allbundles/`+id+`/`+page,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  getCountryCode() {    
    return this.http.get<any>(this.baseUrls1 + `getCountryCode`,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  getbundleByID(company,id) {    
    return this.http.get<any>(this.baseUrls + `getbundlebyid/`+company+`/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }



  allassignedUsers(id) {    
    return this.http.get<any>(this.baseUrls + `allassignedUsers/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }



  removeBunbleFromUser(company,id) {    
    return this.http.get<any>(this.baseUrls + `removeassignbundle/`+company+`/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }


  removeBunble(company,id) {    
    return this.http.get<any>(this.baseUrls + `removebunble/`+company+`/`+id,this.httpHeadersOptions() )
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
     
  }

  

  addNewbundle(data){
    
    return this.http.post<any>(this.baseUrls + "savebundle", data, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  editbundle(data){
    
    return this.http.post<any>(this.baseUrls + "updateBundle", data, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateBundleStatus(data){
    
    return this.http.post<any>(this.baseUrls + "actionbundle", data, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  

  assignBundle(data){
    
    return this.http.post<any>(this.baseUrls + "assignbundle", data, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
    //tohandle the error
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
  
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
    };
}
