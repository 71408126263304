
<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>


<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Segmentation BU Tables
      </li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn mt-md-1 mt-2 ml-0" *ngIf="isWrite" routerLink="/corporate/add-segmentation-table">
     
    </div>
  </div>

  <div class="row"><div class="col-md-6 mt-2">
      <select name="company" class="form-control formstyle"  [(ngModel)]="company"
       (change)="getTable('compChange')">
        <option value=0>Select Company Name</option>
        <ng-container *ngFor="let userObj of getCompanyobj" >
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
        </ng-container>
      </select>
  </div>
</div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom mn-wit-700" width="100%" cellpadding="0" cellspacing="0" >
      <tr>
        
        <th (click)="sorting('table_name')" class="curPoint">Table Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Table Description</th>
        <th>Table Alias</th>
      
        <th (click)="sorting('createdon')" class="curPoint">
          Created On <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th>Actions</th>

      </tr>
      <tr
        *ngFor="let obj of tableObj">
        
        <td>{{obj.table_name}}</td>
        <td>{{obj.table_desc}}</td>
        <td>{{obj.table_alias}}</td>
        <td>{{obj.createdon |date}}</td>


        <td>
          <div class="actBtn">
            <!-- <i class="fa fa-edit mr-1 cursor" (click)="editTable(obj.table_id)"  *ngIf="isEdit"
              placement="bottom" ngbTooltip="Edit"></i> -->
            <span class="fa fa-trash mr-1 cursor" (click)="deleteTable(obj.table_id)" *ngIf="isDelete"
              placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle [checked]="obj.isactive" class="my-slider cursor"  *ngIf="isEdit"
              (click)="updateStatus($event,obj.table_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="totalCount == 0" style="text-align: center">
    <p>No Result Available</p>
  </div>
  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
      (pageChange)="getTable(page = $event)" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
  </div>