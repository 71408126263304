import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import csc from 'country-state-city';
import { BaseComponent } from '../../base.component';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css']
})
export class EventViewComponent extends BaseComponent implements OnInit {
  eventData: any;
  selectedThemeData:any;
  header:any='';
  country:any='';
  campaign: string;
  userData: any;
  companyId: any;
  eventDetails:any;
  constructor(
    private master : MasterService,
    private shopLiveService: ShopLiveService
  ) {
    super();
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
  }

  ngOnInit(): void {
    this.header = sessionStorage.getItem('eventAction');
    this.eventData = JSON.parse(sessionStorage.getItem("eventdata"));
    if(this.eventData){
      this.getEventDetails(this.eventData?.event_id)
    }
  }
  getEventDetails(eventId:any){
    this.ngxSpinnerService.show();
    this.eventDetails = [];
    this.shopLiveService.getEventById(this.companyId,eventId).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      if(res.status){
        this.eventDetails = res.data;
        this.getCountryById(this.eventDetails.event_country);
       if(this.eventDetails && this.eventDetails?.event_type_id == 127 ){
        this.campaign = 'In-Person';
       }else if(this.eventDetails && this.eventDetails?.event_type_id== 128){
        this.campaign = 'Virtual';
       }else if(this.eventDetails && this.eventDetails?.event_type_id== 129){
        this.campaign = 'In-Person & Virtual';
       }
      }

    },
    (error:any)=>{
      this.eventDetails = [];
      this.ngxSpinnerService.hide();
    }
    )
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  getCountryById(countryId: string) {
    let cObj = csc.getCountryByCode(countryId)
   this.country = cObj?.name
}
onBack(){
  this.location.back();
}

// getThemeById(id:any) {
//   let self = this;
//   this.ngxSpinnerService.show();
//   this.master.getThemeById(id,this.companyId).subscribe(data => {
//     self.selectedThemeData = data;
//     this.ngxSpinnerService.hide();
//   }, error => {
//    // console.log(error);
//     this.ngxSpinnerService.hide();
//   });
// }
}
