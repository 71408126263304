<style>
    .my-pagination /deep/ .pagination {
        text-align: center;

    }
</style>
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
            <li class="breadcrumb-item active" aria-current="page">Reskinning</li>
        </ol>
    </nav>
</div>
<div class="container mt-2 themeTblReso">
    <div class="row">
        <div class="col-md-5 mt-2" *ngIf="this.currentUser.admin_customer_id==0">
            <div>
              <select name="company" class="form-control formstyle" (change)="getrskList($event.target.value)">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let userObj of getCompanyobj">
                  <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                    {{userObj.customer_company_name}}
                  </option>
                </ng-container>
              </select>
            </div>
        </div>
        <div class="col-md-2 mt-2" (click)="navigateToAdd()">
          <button class="btn btn-margo-action">Add new</button>
      </div>
    </div>
</div>
<div class="container">
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
            <tr>
                <th>Domain</th>
                <th>Created On</th>
                <th>Active</th>
                <th *ngIf="isEdit || isDelete">Actions</th>
            </tr>

            <tr *ngFor="let obj of reskinningObj">
              <td>{{obj?.domain_name ? obj.domain_name : 'BU' }}</td>
              <td>{{obj?.createdon | date:'mediumDate' }}</td>
              <td>{{obj.isactive? 'YES':'NO' }}</td>
              <td *ngIf="isEdit || isDelete">
                <div class="actBtn">
                  <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="getReskinningDataByUserType(obj.reskininig_id)" placement="bottom"
                    ngbTooltip="Edit"></i>
                  <!-- <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteTheme(obj.theme_id)" placement="bottom"
                            ngbTooltip="Delete"></span>
                          <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
                            (click)="updateStatus($event,obj.theme_id)">
                          </mat-slide-toggle> -->
                </div>
              </td>
            </tr>

        </table>
        <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->

        <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
            <!-- <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getThemeData((cp=$event))" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination> -->
        </div>

        <div style="text-align: center;" *ngIf="totalCount == 0">
            <p>No Result Available</p>
        </div>


    </div>
</div>