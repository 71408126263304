import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  getCompanyobj: any = [];
  templatesData: any = [];
  resCatTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "name";
  constructor(private clientService: ClientService,
    private masterService:MasterService) {
    super();
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getTemplates();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getTemplates()
  }
  getTemplates(){
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getTemplateList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.templatesData = [] }
          else { this.templatesData = data.data.rows }
          this.resCatTotal = data.data.total;
        } else {
          this.templatesData = [];
          this.resCatTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.templatesData = [];
      this.selectedCompany = "";
      this.resCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  deleteTemplate(ev,id) {
    this.confirmationDialogService
      .confirm(
        CommonMessages.confirmationTitle,
        "Are you sure you want to Delete?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let requestData = {
            "company": this.selectedCompany,
            "id":id,
            "isdelete":true
        }
          this.masterService
            .deleteTemplate(requestData)
            .subscribe(
              (data) => {
                if (data.status) {
                  this.getTemplates();
                  setTimeout(() => {
                    this.toastrService.success("Deleted Successfully");
                  }, 1000);
                  this.ngxSpinnerService.hide();
                } else {
                  this.ngxSpinnerService.hide();
                }
              },
              (error) => {
                this.toastrService.error(error.message);
              }
            );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  updateStatus(event, id, obj){
    event.preventDefault();
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status To" +
          (!obj.is_active ? " Active?" : " In Active?"),
        "Ok",
        "Cancel"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let requestData = {
            "company":this.selectedCompany,
            "id":id,
            "isactive":obj.is_active == true ? false : true
        }
          this.masterService
            .updateTemplateStatus(requestData)
            .subscribe((data) => {
              if (data.status) {
                this.toastrService.success("Status Updated Successfully");
                this.getTemplates();
              } else {
                this.toastrService.error(data.message);
              }
            });
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }
      })
      .catch(() => {
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        );
      });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.masterService.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  editPoll(templateData){
    console.log("🚀 ~ file: poll.component.ts:165 ~ PollComponent ~ editPoll ~ id:", templateData)
    templateData.company = this.selectedCompany;
    localStorage.setItem("templateData", JSON.stringify(templateData));
    this.router.navigate(['corporate/add-templates']);
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),
        switchMap(data => this.masterService.getTemplateList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value,this.slCatColname,this.slCatord))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.templatesData = [] }
          else { this.templatesData = res.data.rows }
          this.resCatTotal = res.data.total;
        } else {
          this.templatesData = [];
          this.resCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.templatesData = [];
        this.resCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

}
