import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pii-dsar',
  templateUrl: './pii-dsar.component.html',
  styleUrls: ['./pii-dsar.component.css']
})
export class PiiDsarComponent  extends BaseComponent implements OnInit {

  constructor() { super(); }

  ngOnInit() {
  }

}
