import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { ClientService } from 'src/app/services/clients/client.service';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-themeassociation',
  templateUrl: './themeassociation.component.html',
  styleUrls: ['./themeassociation.component.css']
})
export class ThemeassociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  public ThemeAssociationObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "journey_name";
  checked = false;

  getCompanyobj;
  companyID = 0;
  currentUser: any;
  disCompId: any = 0;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  page: any = 'master';
  domainData: any=[];
  domainId: any='';
  journeyDrpData: any=[];
  journeyFilt: any='';

  constructor(private master: MasterService,private clientsService: ClientService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe) {
      super();
    }

  ngOnInit() {
    this.page = this.master.getCataloguePage();
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.disCompId = this.currentUser.admin_customer_id;
    let self = this;
    self.getCompany();
    //this.themeAssociationList(15);
    this.themeLogData()
  }

  ngAfterViewInit() {
    // this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
          console.log(this.searchText.nativeElement.value)
        }),
        switchMap(data => this.clientsService.getThemeAssociation(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.ThemeAssociationObj = [] }
          else { this.ThemeAssociationObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.ThemeAssociationObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getThemeAssoicationData();
  }

  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }

  themeLogData() {
    let themeLogs = {
      log_type: "Theme Association View",
      log_name: "Theme Association View",
      log_description: "Theme Association View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }

  getThemeAssoicationData() {
    this.ngxSpinnerService.show();
    // console.log("company themes:"+ id);
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.clientsService.getThemeAssociation(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value,'all',this.domainId,this.journeyFilt).subscribe(response => {
      self.ThemeAssociationObj = response.data.rows;
      this.totalCount = response.data.total;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.totalCount =0;
      this.ngxSpinnerService.hide();
    });
  }

  themeAssociationList(id){
    let self = this;
    // this.text = '';
    this.domainId = '';
    this.journeyFilt='';
    this.domainData = [];
    this.journeyDrpData= [];
    this.cp = 1;
    this.ngxSpinnerService.show();

    if(id!=0){
      this.companyID = id;
      console.log('LINE138',this.page);
      if(this.page=='direct-seller'){

      
        this.getBusinessDomain()
      }else{
        
        this.getJourneyList();
        this.getThemeAssoicationData();
      }
      // this.ngxSpinnerService.hide();
    }else{
      self.ThemeAssociationObj = []
      this.totalCount = 0;
      this.cp = 1;
      this.ngxSpinnerService.hide();
    }

  }

   getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = [{"customer_id":0,"customer_company_name":"<-- Select Company -->"}]
      self.getCompanyobj = self.getCompanyobj.concat(data.data)
      if (this.disCompId > 0) {
        this.companyID = this.disCompId;
        this.themeAssociationList(this.disCompId);
      } else this.disCompId = 0;
    }, error => {
     // console.log(error);
    });
  }

  updateStatus(event, obj) {
    event.preventDefault();

    let stat = 'INACTIVE';
    let st = !obj.isactive;
    if (!st) {
      stat = 'ACTIVE';
    }
     this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          let finObj = {
            isactive: !obj.isactive,
            jtr_id: obj.jtr_id,
            company: this.companyID
          }
          this.ngxSpinnerService.show();
          this.clientsService.updateThemeAssociationStatus(finObj).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Status Changed Successfully');
              // this.status = 1;
              this.getThemeAssoicationData();
            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          },
            error => this.ngxSpinnerService.hide());
        }

      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  getBusinessDomain(){
      let self = this;
      this.ngxSpinnerService.show();
      this.master.getBusinessDomain(100,0,this.companyID).subscribe(data => {
        if(data.data){
          self.domainData = data.data.rows;
        }else{
          self.domainData=[];
        }
        self.ngxSpinnerService.hide();
      }, error => {
        console.log("errorrrr");
        this.domainData=[];
        self.ngxSpinnerService.hide();
      });
  }
  getListByDomainId(id:any){
    this.domainId=id;
    this.getThemeAssoicationData()
  }
  getJourneyList(){
    this.ngxSpinnerService.show();
    this.master.getJourneyAll(this.companyID,1000,0,'journey_name','asc','').subscribe(res => {
      if(res.data){
      this.journeyDrpData = res.data?.rows.filter(item=>item.journey_parent_id == 0 );
      }else{
        this.journeyDrpData=[];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log("errorrrr");
      this.journeyDrpData=[];
      this.ngxSpinnerService.hide();
    });
}
getListForJourney(journey:any){
  this.journeyFilt = journey;
  this.getThemeAssoicationData();
}
}
