import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base.component';
import {MasterService} from '../../../../services/master/master.service';
import {ClientService} from '../../../../services/clients/client.service';
import {AlertService} from '../../../../_services/alert.service';


@Component({
  selector: 'app-noteslist',
  templateUrl: './noteslist.component.html',
  styleUrls: ['noteslist.component.css']
})
export class NoteslistComponent extends BaseComponent implements OnInit{

  shwSearch: boolean = false;
  cp = 1;
  ord = true;
  text;
  getCompanyobj;
  currentUser;
  companyID = 0;
  companyNoteConf: any = [];
  selectedCompany: any = null;
  companyName = "";
  selectedCompanyName;

  constructor(private master: MasterService, private clientService: ClientService,
              private alertService: AlertService) {
    super();
  }
  ngOnInit() {
       this.getCompany();
    this.companyNoteConf = [];
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
   // this.companyID = this.currentUser.admin_customer_id;

    //this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
   console.log
    if (this.currentUser.admin_customer_id != '0') {  this.companyID = this.currentUser.admin_customer_id; this.getCompanyNotes(this.companyID); }
   
  }

  order: string = 'get_master_theme.theme_name';
  reverse: boolean = false;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  //  ###############  Get Notes Configuration for Company    ###############  //

  getCompanyNotes(data){
    let compamyId = data;
    
    if(compamyId === ''){
      this.selectedCompany = null;
      return;
    }
    //console.log("val===========",val.value,val.name,val.options[val.options.selectedIndex].text)
    
    //this.companyName = data.options[data.options.selectedIndex].text




    this.ngxSpinnerService.show();
    this.master.getCompanyNotesDetails(compamyId).subscribe((data: any) => {
      if (data.status) {
        this.selectedCompany = data.data[0];
      } else {
        this.selectedCompany = null;
      }
      this.ngxSpinnerService.hide();
    });
  }

  //  ###############  Get Companies List ####################  //

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
   
     

    }, error => {
     // console.log(error);
    });
  }

  //   ############## Update Notes Status   ################  //
  updateNoteStatus(event){
    event.preventDefault();
    const obj = {
      lengthNum: this.selectedCompany.note_length,
      company: this.selectedCompany.company_id,
      isactive: !this.selectedCompany.isactive
    };

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the Notes Status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.updateCompanyNoteDetails(obj).subscribe(data => {
            if (data.status) {
              this.master.getCompanyNotesDetails(this.selectedCompany.company_id).subscribe((data2: any) => {
                this.selectedCompany = null;
                if (data.status) {
                  this.selectedCompany = data2.data[0];
                } else {
                  this.selectedCompany = null;
                }
                this.ngxSpinnerService.hide();
              });
              setTimeout(() => {
                this.toastrService.success('Notes Updated Successfully');
              }, 1000);
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

}
