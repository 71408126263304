import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewjourney',
  templateUrl: './addnewjourney.component.html',
  styleUrls: ['./addnewjourney.component.css']
})
export class AddnewjourneyComponent extends BaseComponent implements OnInit {
  // Journeyname;
  // Description;
  // Icon;
  // ParentId = 0;
  IsActive;
  journey_id;
  journeyObjId;
  journeyObj;
  journey_awskey = '';
  status: boolean = true;
  screenshot_type = true;
  header = "Add New";
  getCompanyobj;
  companyID;
  company;

  journeyForm: FormGroup;
  submitted = false;
  journey_icon;
  controlEnabled:boolean=true;
  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    public toaster: ToastrService,
    private upload:UploadService

  ) {
    super();
  }

  ngOnInit() {

this.controlEnabled = true;
    this.ngxSpinnerService.show();
    this.getCompany();
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.journey_id = params.journey_id;
        self.companyID = params.company;
      });
    //get item details using id

   // console.log("Journey id:" + self.journey_id);
    if (self.journey_id) {
      this.ngxSpinnerService.show();

      this.UpdateJourneyLogData()

      this.header = "Edit"
      this.master.getJourneyId(this.companyID, this.journey_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.journeyObjId = response.data;
          this.getCompanyJourney(this.companyID);
          self.dataBinding();

        } else {

        }
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addJourneyLogData()
    }
    this.journeyForm = this.formBuilder.group({
      company: ['', Validators.required],
      journeyName: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(30)]],
      journeyDescription: [''],
      firstActive: [true],
      journeyIcon: [''],
      journeyParentId: [0],
      issegment:[false],
      isproduct:[false],

      is_oneday:[false],
      marketshowid_mms:['', [Validators.required]],
      marketshowid_email:['', [Validators.required]],

      contact_type:[1],
      total_journey:[1],
      url:['',[Validators.required,this.noWhitespaceValidator,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    });
  }
  get f() { return this.journeyForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }

  addJourneyLogData() {

    let journeyLogs = {
      log_type: "Adding New Journey",
      log_name: "Adding New Journey",
      log_description: "Adding New Journey",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(journeyLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });

  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }
  UpdateJourneyLogData() {

    let journeyLogs = {
      log_type: "Updating Journey Data",
      log_name: "Updating Journey Data",
      log_description: "Updating Journey Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(journeyLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  addJourney() {
    this.submitted = true;
   // console.log(this.journeyForm);

    if (this.journeyForm.invalid) {
      return;
    }


    let { journeyName, journeyDescription, journeyIcon, journeyParentId,issegment ,isproduct , is_oneday, marketshowid_mms, marketshowid_email, contact_type,url,total_journey} = this.journeyForm.value;




    if (this.f.firstActive.value) {

     // console.log("sssss......")

      if (this.f.journeyIcon.value != '') {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.journeyIcon.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }



      if (this.f.journeyIcon.value == '') {
        this.toaster.error("Enter Screen Shot URL");
        return;
      }

    } else {


      if (this.journey_awskey == '') {
        this.toaster.error("Upload Theme Image");
        return;
      }


    }
    this.ngxSpinnerService.show();

    journeyIcon = this.isUrlValid(journeyIcon) ? journeyIcon : this.journey_awskey ? this.journey_awskey : ""
    let journeyBody = {
      name: journeyName,
      description: journeyDescription,
      icon: journeyIcon,
      parentid: journeyParentId,
      company: this.companyID,
      issegment:issegment,
      isproduct:isproduct,

      is_oneday:is_oneday,
      marketshowid_mms:marketshowid_mms,
      marketshowid_email:marketshowid_email,

      contact_type:contact_type,
      url:url,
      total_journey:total_journey

    }

    this.master.addingJourney(journeyBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigate(['/corporate/journeymaster'], { queryParams: { company: this.companyID } });
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  getJourney() {
    let self = this;
    this.master.getJourney(self.companyID).subscribe(data => {
      self.journeyObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    this.journey_icon = this.journeyObjId.journey_icon;
    if (this.isUrlValid(this.journeyObjId.journey_icon)) {
      this.journeyForm = this.formBuilder.group({
        journeyName: [this.journeyObjId.journey_name, [Validators.required,this.noWhitespaceValidator,Validators.maxLength(30)]],
        journeyDescription: [this.journeyObjId.journey_description],
        firstActive: [true],
        journeyIcon: [this.journeyObjId.journey_icon],
        journeyParentId: [this.journeyObjId.journey_parent_id],
        company: [this.companyID],
        issegment:[this.journeyObjId.issegment,Validators.required],
        isproduct:[this.journeyObjId.isproduct,Validators.required],

        is_oneday:[this.journeyObjId.is_oneday],
        marketshowid_mms:[this.journeyObjId.marketshowid_mms, Validators.required],
        marketshowid_email:[this.journeyObjId.marketshowid_email, Validators.required],


        url:[this.journeyObjId.url,[Validators.required,this.noWhitespaceValidator,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        total_journey:[this.journeyObjId.total_journey,Validators.required],
        contact_type:[this.journeyObjId.contact_type]

      });
    } else {
      this.journeyForm = this.formBuilder.group({
        journeyName: [this.journeyObjId.journey_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(30)]],
        journeyDescription: [this.journeyObjId.journey_description],
        firstActive: [false],
        journeyIcon: [],
        journeyParentId: [this.journeyObjId.journey_parent_id],
        company: [this.companyID],
        issegment:[this.journeyObjId.issegment,Validators.required],
        isproduct:[this.journeyObjId.isproduct,Validators.required],

        is_oneday:[this.journeyObjId.is_oneday],
        marketshowid_mms:[this.journeyObjId.marketshowid_mms, Validators.required],
        marketshowid_email:[this.journeyObjId.marketshowid_email, Validators.required],

        url:[this.journeyObjId.url,[Validators.required,this.noWhitespaceValidator,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        total_journey:[this.journeyObjId.total_journey,Validators.required],
        contact_type:[this.journeyObjId.contact_type]
      });
      this.journey_awskey = this.journeyObjId.journey_icon;
      this.screenshot_type = false;
    }
    this.IsActive = this.journeyObjId.isactive;
    this.journey_id = this.journeyObjId.journey_id;
  }
  UpdateJourney() {
   // console.log("UpdateJourney===")
    this.submitted = true;
    if (this.journeyForm.invalid) {
      return;
    }

    let { journeyName, journeyDescription, journeyIcon, journeyParentId,issegment, isproduct , is_oneday, marketshowid_mms,marketshowid_email, contact_type,url,total_journey} = this.journeyForm.value;


    if (this.f.firstActive.value) {

     // console.log("sssss......")

      if (this.f.journeyIcon.value != '') {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.journeyIcon.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }



      if (this.f.journeyIcon.value == '') {
        this.toaster.error("Enter Screenshot URL");
        return;
      }

    } else {


      if (this.journey_awskey == '') {
        this.toaster.error("Upload Theme Image");
        return;
      }


    }

    journeyIcon = journeyIcon ? journeyIcon :  this.journey_awskey;
    let journeyBody = {
      journey_name: journeyName,
      journey_description: journeyDescription,
      journey_icon: journeyIcon,
      journey_parent_id: journeyParentId,
      isactive: this.IsActive,
      journey_id: this.journey_id,
      company: this.companyID,
      issegment:issegment,
      isproduct:isproduct,

      is_oneday:is_oneday,
      marketshowid_mms:marketshowid_mms,
      marketshowid_email:marketshowid_email,

      contact_type:contact_type,
      url:url,
      total_journey:total_journey
    }

  //  console.log("ssss",journeyBody)

    this.ngxSpinnerService.show();
    this.master.updateJourney(journeyBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigate(['/corporate/journeymaster'], { queryParams: { company: this.companyID } });
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.journey_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
     // console.log("error ", file);
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            self.journey_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  getCompany() {


    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {

      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });
  }
  getCompanyJourney(id) {
    //event.preventDefault();
    this.companyID = id;
   // console.log("company Journey:" + id);
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCompanyJourney(id).subscribe(data => {
      self.journeyObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
counter(i: number) {
  return new Array(i);


}
}
