import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pii-report',
  templateUrl: './pii-report.component.html',
  styleUrls: ['./pii-report.component.css']
})
export class PiiReportComponent  extends BaseComponent implements OnInit {

  constructor() {  super()}

  ngOnInit() {
  }

}
