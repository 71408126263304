<style>
    .my-pagination /deep/ .ngx-pagination {
      text-align: center;
    }
    .cldrIcon {
      position: absolute;
  top: 0px;
  right: -20px;
  color: #777 !important;
    }
  </style>  
  <div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
        <li class="breadcrumb-item active" aria-current="page">Contact Inventory</li>
      </ol>
    </nav>
  </div>
  
    
    <div class="container mt-1 themeTblReso"> 
      <div class="row">
       
        <div class="col-md-4 mt-2" *ngIf="isHeadquater">
          <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="fetchCustomerData($event.target.value)">
            <option selected="selected" value=0>Select Company</option>
            <ng-container *ngFor="let userObj of companyList" >
              <option *ngIf="userObj.dbstatus == true" [value]="userObj.customer_id">
            <!-- <option *ngFor="let userObj of companyList" [value]="userObj.customer_id"> -->
              {{userObj.customer_company_name}}
            </option>
              </ng-container>
          </select>
        </div>
        <div class="col-md-4 mt-2">
          <select name="month" class="form-control formstyle" [(ngModel)]="selectedMonth" (change)="fetchCustomerData(companyID)">
            <option *ngFor="let ele of monthsArr" [value]="ele">{{ele}}</option>
          </select>
        </div>
        <!-- <div  class="col-md-4 mt-2">
          <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a>
        </div> -->
      </div>   
      
    
      <div class="tblPrt mt-3" *ngIf="showResult">   
        <div *ngIf="selectedMonth">Report Month: {{selectedMonth}}</div>
        
         <div *ngIf="monthlyOpeningBalance!=0">
          <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
          <tr>
            <th>Report Item Name</th>
            <th>Item Value</th>
            <th>Price</th>
          </tr>
          <tr>
            <td>Monthly Begining Balance</td>
            <td>{{!!monthlyOpeningBalance?monthlyOpeningBalance:'0'}}</td>
            <td></td>
          </tr>
          <tr>
            <td>Amount Purchase During Month</td>
            <td><div>{{!!buyUsed?buyUsed:'0'}}</div></td>
            <td>{{!!buyPrice?'$'.buyPrice:'0'}}</td>
          </tr>
          <tr>
            <td>Contacts Used During Month</td>
            <td>{{!!systemUsed?systemUsed:'0'}}</td>
            <td></td>
          </tr>
          <tr>
            <td>Contacts Deleted During Month </td>
            <td>{{ !!dataUsed?dataUsed:'0' }}</td>
            <td></td>
          </tr>
          <tr>
            <td>Monthly Ending Balance </td>
            <!--td>{{!!closingBalance?closingBalance:'0'}}</td><td></td-->
            <td>{{(!!monthlyOpeningBalance?+monthlyOpeningBalance:'0') - (!!systemUsed?+systemUsed:'0')}}</td><td></td>

          </tr>
    
        </table>
      </div>
      <div *ngIf="monthlyOpeningBalance==0" class="tblPrt">
        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
          <tr>
            <th>Report Item Name</th>
            <th>Item Value</th><th>Price</th>
          </tr>
          <tr><td colspan="3" style="text-align: center">No results available</td></tr>
        </table>
       </div>
      </div>
      <div class="tblPrt" *ngIf="!showResult">
        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
            <tr>
              <td colspan="5" style="text-align: center">No results available</td>                            
            </tr>  
      </table>
      </div>
    </div>
  
  