<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Cross-sell &
                    Replenishment</a>
            </li>
            <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Setting</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{headers}}
            </li>
        </ol>
    </nav>
</div>



<form [formGroup]="themeForm">
    <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading">Setting</h5>
        <div class="card">
            <div class="card-body pt-2">
                <div class="row">

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2"> Select Company Name
                            <span style="color:red">*</span>
                        </label>
                        <select (change)="getCompanyThemes($event.target.value)" formControlName="company"
                            *ngIf="headers == 'Add'" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                            <option value="">Select Company</option>
                            <ng-container *ngFor="let userObj of getCompanyobj">
                                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </ng-container>
                        </select>

                        <select formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled"
                            class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                            <option value="">Select Company</option>
                            <ng-container *ngFor="let userObj of getCompanyobj">
                                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </ng-container>
                        </select>


                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">Company Name is required</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Campaign Type
                        </label>
                        <!-- <input class="form-control" formControlName="campaign_type"/> -->

                        <select class="form-control" formControlName="campaign_type" disabled="controlEnabled">
                            <option value="1">Cross-Sell</option>
                            <option value="2">Replenishment</option>
                        </select>
                    </div>





                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2"> Market Show Id
                            <span style="color:red">*</span>

                        </label>
                        <input class="form-control" formControlName="marketshowId" />
                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Theme Id
                            <span style="color:red">*</span>
                        </label>
                        <!-- <input class="form-control" formControlName="theme" /> -->

                        <select name="company" formControlName="theme"  class="form-control">
                        <option value="">--Select Theme Name--</option>
                              <option *ngFor="let userObj of themeObj" value={{userObj.theme_id}}>
                                  {{userObj.theme_name}}
                              </option>
                          </select>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Day
                            <span style="color:red">*</span>

                        </label>

                        <select class="form-control" formControlName="day">
                            <option value="monday">Monday</option>
                            <option value="tuesday">Tuesday</option>
                            <option value="wednesday">Wednesday</option>
                            <option value="thirsday">Thirsday</option>
                            <option value="friday">Friday</option>
                            <option value="saturday">Saturday</option>
                            <option value="sunday">Sunday</option>
                        </select>
                        <!-- <input  maxlength="10" formControlName="day" class="form-control"
                         /> -->
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Time
                            <span style="color:red">*</span>
                        </label>
                        <!-- <input  formControlName="time" class="form-control"
                         /> -->

                        <select class="form-control" formControlName="time">
                            <ng-container *ngFor="let timeObj of timeSlots">
                                <option value="{{timeObj.time}}">
                                    {{timeObj.time}}
                                </option>
                            </ng-container>
                        </select>

                    </div>




                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Automation Campaign
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg3"
                                    formControlName="automationCampaign" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="customRadiolg3">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg4"
                                    formControlName="automationCampaign" [value]="false">
                                <label class="custom-control-label" for="customRadiolg4">In Active</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Automation Audience
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolgg3"
                                    formControlName="automationAudience" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="customRadiolgg3">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolgg4"
                                    formControlName="automationAudience" [value]="false">
                                <label class="custom-control-label" for="customRadiolgg4">In Active</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Status
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="statusradio1"
                                    formControlName="status" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="statusradio1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="statusradio2"
                                    formControlName="status" [value]="false">
                                <label class="custom-control-label" for="statusradio2">In Active</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-10 col-xl-12 mb-0">
                        <div class="card-footer text-center mt-1">
                            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                                <button type="submit" class="custbtn mb-2" *ngIf="isWrite" (click)="addNew()">Submit</button>
                                <button type="submit" class="custbtn mb-2" *ngIf="isWrite"
                                    (click)="cancelBtb()">Cancel</button>
                            </div>
                            <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                                <button type="button" class="custbtn mb-2" *ngIf="isEdit" (click)="update()">Update</button>

                                <button type="submit" class="custbtn mb-2" *ngIf="isEdit"
                                    (click)="cancelBtb()">Cancel</button>
                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </div>
    </div>


</form>