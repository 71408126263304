<div class="scroller">
  <div class="wholePop filtMain">
    <div class="modal-header dialHead text-center d-block mb-1">
      <!-- <h4 class="modal-title">{{ title }}</h4> -->
      <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button> -->
      <img src="assets/custom/images/margoLogoIcon.png" />
    </div>
    <hr />

    <div class="modal-body">
      <h4 class="modal-title">{{ title }}</h4>
      <span class="text-uppercase">{{ message }}</span>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn vl custbtn text-uppercase" (click)="accept()">
        {{ btnOkText }}
      </button>
      <button type="button" class="btn vl custbtn text-uppercase" (click)="decline()">
        {{ btnCancelText }}
      </button>
    </div>
  
  </div>

</div>

