import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../../../services/license/license.service';
import { AlertService } from '../../../../_services/alert.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../../base.component';
import { ClientService } from '../../../../services/clients/client.service';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-license-assign-listing',
  templateUrl: './license-assign-listing.component.html',
  styleUrls: ['./license-assign-listing.component.css']
})
export class LicenseAssignListingComponent extends BaseComponent implements OnInit {

  cp = 1;
  ord = true;
  colname: "Name";
  text;
  getCompanyobj: any = [];
  bundleData: any = [];
  selectedCompany=0;

  constructor(private clientService: ClientService,
    private LS: LicenseService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { super(); }

  ngOnInit() {

    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
    });
  }

  loadBundle(id) {

    if(id>0) {


      this.selectedCompany = id;
      this.ngxSpinnerService.show();

    forkJoin(
      {
        bundleData: this.LS.allassignedUsers(id),

      }
    ).subscribe(data => {

      if (data.bundleData.status) {
        this.bundleData = data.bundleData.data;
      }
      this.ngxSpinnerService.hide();

    }), error => {
     // console.log(error);
      this.ngxSpinnerService.hide();

    }
    }else {
      this.bundleData= [];
    }

  }

  removeBundle(id) {

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to remove bundle from user ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.LS.removeBunbleFromUser(this.selectedCompany,id).subscribe(data => {
            if(data.status) {

              this.loadBundle(this.selectedCompany);
      
              this.toastrService.success("Assign Bundle Removed Successfully");
              //this.router.navigateByUrl(`/corporate/license-assign-listing`);
            }else {
      
              this.toastrService.error(data.message);
            }
            
          }, error => {
          //  console.log(error);
          });
          // this.ngxSpinnerService.show();        

        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));




  }

}
