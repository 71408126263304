<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Admin</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/fb">Facebook</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        FB Post Library
      </li>
    </ol>
  </nav>
</div>


<div class="container mt- themeTblReso">
  <div class="row">
    <div class="col-md-3" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getCompanyThemes($event.target.value)"
          [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              <!-- <option
          *ngFor="let userObj of getCompanyobj"
          value="{{ userObj.customer_id }}"
        > -->
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-3" *ngIf='selectedCompany!=0'>
      <div>

        <select name="company" class="form-control formstyle" (change)="getDomainList($event.target.value)" >
          <option value="0">Business Domain (All)</option>
          <ng-container *ngFor="let userObj of domainData" >
            <option *ngIf="userObj.is_active == true" value={{userObj.id}}>
                {{userObj.domain_name}}
            </option>
          </ng-container>
        </select>

    </div>

    </div>
    <div class="col-md-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText
        [disabled]="selectedCompany == ''"/>
      </div>
    </div>

    <div class="col-md-3" style="justify-content: right">
      <input *ngIf="isWrite" type="button" value="Add New" class="custbtn mt-md-1 ml-0" routerLink="/corporate/addnewpost" />
    </div>
  </div>

  <div class="row">
    <div class="col tblPrt">
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th (click)="setOrder('post_name')" style="cursor: pointer">
              Post Name
              <i class="fa fa-sort text-black" aria-hidden="true"></i>
            </th>
            <th>Post Image </th>
            <th>Domain</th>
            <!-- <th>BU Admin</th>
          <th>Created By</th> -->
            <th (click)="setOrder('createdon')" style="cursor: pointer">
              Created On<i class="fa fa-sort text-black" aria-hidden="true"></i>
            </th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let obj of postObj">
            <td>{{ obj.post_name }}</td>
            <td>

              <!-- <video  src="" poster="assets/images/icons/video-play.svg"
              style="max-height: 40px"></video> -->

              <img [src]="obj.post_images" alt="Not Found"
              (error)="setValue($event)" style="height: 50px" />
            </td>
            <td>{{obj?.domain_name}}</td>
            <td>{{ obj.createdon | date: "mediumDate" }}</td>
            <td>
              <div class="actBtn">
                <i class="fa fa-edit mr-1" (click)="editPost(obj.post_id)" placement="bottom" ngbTooltip="Edit"
                  *ngIf="isEdit"></i>
                <i *ngIf="isEdit" class="fa fa-trash mr-1" (click)="deletePost(obj.post_id)" placement="bottom"
                  ngbTooltip="Delete"></i>

                <mat-slide-toggle *ngIf="isEdit" class="my-slider" [checked]="obj.isactive"
                  (click)="updateStatus($event, obj.post_id)">
                </mat-slide-toggle>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="fbTotal == 0" style="text-align: center">
        <p>No Result Available</p>
      </div>

      <div class="mt-2 text-center difPagination" *ngIf="fbTotal > 10">
        <ngb-pagination [collectionSize]="fbTotal" [(page)]="fbPage" [maxSize]="2" [rotate]="true"
          (pageChange)="getPostManagement(fbPage=$event)" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
