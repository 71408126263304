import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { environment } from '../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable, of } from 'rxjs';

let httpParams = new HttpParams();

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public baseUrl;
  public baseUrl2;
  public baseUrl_j;
  public baseUrl_n;
  public baseUrl_t;
  public baseUrl_q;
  public baseUrl_a;
  public baseUrl_rs;
  public baseUrl_pc;
  public baseUrl_res;
  public baseUrl_faq;
  public baseUrl_poll;
  public baseUrl_sub;
  public baseUrl_woo;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/master/';
    this.baseUrl_j = baseService.baseUrl1 + 'api/journey/';
    this.baseUrl_n = baseService.baseUrl1 + 'api/note/';
    this.baseUrl_t = baseService.baseUrl1 + 'api/theme/';
    this.baseUrl_q = baseService.baseUrl1 + 'api/qtm/';
    this.baseUrl_a = baseService.baseUrl1 + 'api/attr/';
    this.baseUrl2 = baseService.baseUrl1;
    this.baseUrl_rs = baseService.baseUrl1 + 'api/reskinning/';
    this.baseUrl_pc = baseService.baseUrl1 + 'api/';
    this.baseUrl_res = baseService.baseUrl1 + 'api/resource/';
    this.baseUrl_faq = baseService.baseUrl1 + 'api/webinar/'
    this.baseUrl_poll = baseService.baseUrl1 + 'api/poll/'
    this.baseUrl_sub = baseService.baseUrl1 + 'api/subscription/';
    this.baseUrl_woo = baseService.baseUrl1 + 'api/woocommerce/'
  }

  uploadfile(file, userKey, cb, type = 1) {

    const bucket = new S3({
      accessKeyId: (type == 1) ? environment.accessKeyId : environment.cbAccessKeyId,
      secretAccessKey: (type == 1) ? environment.secretAccessKey : environment.cbSecretAccessKey,
      region: (type == 1) ? environment.region : environment.cbRegion
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: (type == 1) ? environment.awsBucket : environment.cbBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        // console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }
  uploadBase64Image(img, userKey, filename, cb) {
    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region
    });

    const base64Data = new Buffer(img.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // Getting the file type, ie: jpeg, png or gif
    const type = img.split(';')[0].split('/')[1];
    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + filename,
      Body: base64Data,
      ACL: 'public-read',
      ContentEncoding: 'base64', // required
      ContentType: `image/${type}` // required. Notice the back ticks
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        cb(err, null);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }





  uploadfileProductCatalog(newFileName, file, userKey, cb, type = 1) {
    const bucket = new S3({
      accessKeyId: (type == 1) ? environment.accessKeyId : environment.cbAccessKeyId,
      secretAccessKey: (type == 1) ? environment.secretAccessKey : environment.cbSecretAccessKey,
      region: (type == 1) ? environment.region : environment.cbRegion
    });

    const params = {
      Bucket: (type == 1) ? environment.awsBucket : environment.cbBucket,
      Key: userKey + '/' + newFileName,
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        // console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }



  getUrlfile(awsKey) {
    return environment.awsUrl+'/'+awsKey;;
    // const bucket = new S3({
    //   accessKeyId: environment.accessKeyId,
    //   secretAccessKey: environment.secretAccessKey,
    //   region: environment.region
    // });

    // var params = { Bucket: environment.awsBucket, Key: awsKey };
    // var url = bucket.getSignedUrl('getObject', params);

    // return url;
  }

  //theme apis
  addingTheme(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_t + "addingTheme", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getTheme(comapny) {
    var body = {};
    return this.http.post<any>(this.baseUrl_t + "getTheme/" + comapny + "/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCompanyTheme(id, limit: any = 9999, skip: any = 0, colname: any = "theme_name", order: any = "asc", searchText: any = "", type = "all", business_domain_id?: any) {
    var body = { company_id: id, type: type };
    return this.http.post<any>(this.baseUrl_t + `getCompanyTheme/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&business_domain_id=${business_domain_id}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getSelectCompanyTheme(id) {
    var body = {};
    return this.http.post<any>(this.baseUrl_t + `getCompanyTheme/${id}/9999/0`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getThemeById(companyID, theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_t + `getThemeById/${companyID}/${theme_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateTheme(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_t + "UpdateTheme", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delTheme(company, id) {
    return this.http.delete<any>(this.baseUrl_t + `deleteTheme/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  //journey apis
  addingJourney(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_j + "addingJourney", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getJourney(comapny) {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + "getJourney/" + comapny + "/100/0", body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getJourneyAll(id, limit: any = 0, skip: any = 0, colname: any = "", order: any = "", searchText: any = "", type = "all") {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + `getJourneyAll/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCompanyJourney(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + `getJourney/${id}/100/0`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneyId(company_id, journey_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + `getJourneyById/${company_id}/${journey_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getLoyalityById(loyality_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getLoyalityTypeById/${loyality_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateJourney(value) {
    var body = value;
    // console.log(body);
    return this.http.put<any>(this.baseUrl_j + `updateJourney`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delJourney(id, company_id) {

    return this.http.delete<any>(this.baseUrl_j + `deleteJourney/${company_id}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //adding training type
  addingTrainingType(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingTtraining", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getTraining() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getTraining/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delTrainingType(value) {
    var id = value;
    //  console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteTtraining/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Event Type
  addNewEvent(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingEvent", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delEventType(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteEvent/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEventType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getEvent/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCountryById(country_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getCountryById/${country_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Country Service
  addingCountry(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCountry", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCountry(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "updateCountry", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingMenu(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingMenu", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingCurrency(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCurrency", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //Adding Training Type
  addingProfession(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingProfession", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingLoyality(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingLoyalityType", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCountry() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCountry/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getMenuById(menu_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getMenuById/${menu_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateMenu(munuBody) {
    var body = munuBody;
    return this.http.put<any>(this.baseUrl + "updateMenu", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addingProspect(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingProspect", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));

  }
  getMenu() {
    var body = {};
    // return this.http.get<any>(this.baseUrl + "getMenuById/1",  this.httpHeadersOptions())
    return this.http.post<any>(this.baseUrl + "getMenu/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));

  }
  getCurrency() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCurrency/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCurrencyById(currency_id) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getCurrency/${currency_id}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCurrency(currency_body) {
    var body = currency_body;
    return this.http.put<any>(this.baseUrl + "updateCurrency", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getTrainingType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getTraining", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getTrainingTypeById(trainingType_id) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getTraining/${trainingType_id}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateTrainingType() {
    var body = {};
    return this.http.put<any>(this.baseUrl + "updateTtraining", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getProfession() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getProfession/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getLoyality() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getLoyalityType/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getProspect() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getProspect/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getProfessionById(profession_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getProfessionById/${profession_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateProfession(professionBody) {
    var body = professionBody;
    return this.http.put<any>(this.baseUrl + "updateProfession", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getIndustry() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getIndustry/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCompanyType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCompany/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingCompany(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCompany", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));

  }
  addNewIndustry(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingIndustry", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delMenu(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteMenu/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));

  }
  delCountry(value) {
    var id = value;
    //  console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteCountry/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateLoyality(loyality_body) {
    var body = loyality_body;
    return this.http.put<any>(this.baseUrl + "updateLoyalityType", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delCurrency(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteCurrency/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));

  }
  delIndustry(value) {
    var id = value;
    //  console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteIndustry/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delCompanyType(value) {
    var id = value;
    //console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteCompany/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getProspectById(prospect_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getProspectById/${prospect_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateProspect(prospect_body) {
    var body = prospect_body;
    return this.http.put<any>(this.baseUrl + `updateProspect`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delProfession(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteProfession/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delProspect(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteProspect/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delLoyality(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteLoyalityType/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEventTypeById(event_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getEventById/${event_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateEventType(event_body) {
    var body = event_body;
    return this.http.put<any>(this.baseUrl + "updateEvent", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getTrainingId(training_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getTtrainingById/${training_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateTraining(training_body) {
    var body = training_body;
    return this.http.put<any>(this.baseUrl + "updateTraining", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getIndustryId(industry_id) {
    var body = industry_id;
    return this.http.get<any>(this.baseUrl + `getIndustryById/${industry_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateIndustry(industry_body) {
    var body = industry_body;
    return this.http.put<any>(this.baseUrl + "updateIndustry", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCompany(company_body) {
    var body = company_body;
    return this.http.put<any>(this.baseUrl + "updateCompany", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCompanyById(company_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getCompanyById/${company_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCurrencyId(currency_id) {
    var body = currency_id;
    return this.http.get<any>(this.baseUrl + `getCurrencyById/${currency_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateCurrency(industry_body) {
    var body = industry_body;
    return this.http.put<any>(this.baseUrl + "updateCurrency", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getPermissions() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getPermissions/100/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delpermission(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deletePermissions/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingNewPermission(value) {
    // console.log(value);

    var body = value;
    return this.http.post<any>(this.baseUrl + "addingPermissions", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getPermissionById(per_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getPermissionsById/${per_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updatePermission(permission_body) {
    var body = permission_body;
    return this.http.put<any>(this.baseUrl + "updatePermissions", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getRole(value) {
    var body = value || {};
    return this.http.post<any>(this.baseUrl + "getRole", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  // getRoledropdown() {
  //   var body = {};
  //   return this.http.post<any>(this.baseUrl + "getMasterRole", body, this.httpHeadersOptions())
  //  .pipe(
  // map(data=>{
  //   if(!data.status)
  //   {
  //     if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
  //     {
  //       console.error(data.message);
  //       data.message="Something went wrong.Please try again."
  //     }
  //   }
  //   return data;
  // }),
  // catchError(this.handleError));
  // }
  delRole(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteRole/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingRole(value) {
    //console.log(value);

    var body = value;
    return this.http.post<any>(this.baseUrl + "addingRole", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getRoleById(role_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getRoleById/${role_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateRole(permission_body) {
    var body = permission_body;
    return this.http.put<any>(this.baseUrl + "updateRole", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getModule(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getModule", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAllModulesOLD(limit: any = 0, skip: any = 0, colname: any = 'name', order: any = 'asc', searchText: any = '') {
    return this.http.post<any>(this.baseUrl + `getAllModules/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getAllModules(limit: any = 0, skip: any = 0, colname: any = 'name', order: any = 'asc', searchText: any = '') {
    return this.http.post<any>(this.baseUrl + `getAllParentModules/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getChildModules(limit: any = 0, skip: any = 0, colname: any = 'name', order: any = 'asc', searchText: any = '', id: any) {
    return this.http.post<any>(this.baseUrl + `getAllChildModules/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&parentId=${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delModule(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteModule/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingModule(value) {
    // console.log(value);

    var body = value;
    return this.http.post<any>(this.baseUrl + "addingModule", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getModuleById(module_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getModuleById/${module_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateModule(Module_body) {
    var body = Module_body;
    return this.http.put<any>(this.baseUrl + "updateModule", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  importBulk(url, value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + url, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  exportBulk(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getBulkData", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getModuleItems() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getModuleItems/500/0", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delModuleItems(value) {
    var id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteModuleItems/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingModuleItems(value) {
    // console.log(value);

    var body = value;
    return this.http.post<any>(this.baseUrl + "addingModuleItems", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getModuleItemsById(moduleitems_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getModuleItemsById/${moduleitems_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateModuleItems(moduleitems_body) {
    var body = moduleitems_body;
    return this.http.put<any>(this.baseUrl + "updateModuleItems", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getRoledropdown() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getMasterRole", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAllRoles(limit, skip, colname, order, searchText) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getAllRoles/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getModuledropdown() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getMasterModule", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }



  //Theme Category  apis
  addingThemeCategory(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_t + "addingThemeCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getThemeCategory() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getThemeCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getThemeCategoryById(theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_t + `getThemeCategoryById/${theme_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateThemeCategory(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_t + "UpdateThemeCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateThemeCategoryStatus(themobj: { isactive: any; theme_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl_t + "updateThemeCategoryStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delThemeCategory(value) {
    let id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl_t + "deleteThemeCategory/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Contact Type  apis
  addingContacttype(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingContacttype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getContacttype() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getContacttype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getContacttypeById(contact_type_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getContacttypeById/${contact_type_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateContacttype(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateContacttype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateContacttypeStatus(contacttypeobj: { isactive: any; contact_type_id: any; }) {
    var body = contacttypeobj;
    return this.http.put<any>(this.baseUrl + "updateContacttypeStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delContacttype(value) {
    let id = value;
    //  console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteContacttype/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Journey Type  apis
  addingJourneytype(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_j + "addingJourneytype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneytype() {
    var body = {};
    return this.http.post<any>(this.baseUrl_j + "getJourneytype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneytypeById(journey_type_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + `getJourneytypeById/${journey_type_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateJourneytype(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_j + "UpdateJourneytype", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateJourneytypeStatus(themobj: { isactive: any; journey_type_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl_j + "updateJourneytypeStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delJourneytype(value) {
    let id = value;
    //console.log(id);
    return this.http.delete<any>(this.baseUrl_j + "deleteJourneytype/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //asset  apis
  addingAsset(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingAsset", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAsset() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getAsset", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAssetById(assestsid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getAssetById/${assestsid}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateAsset(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateAsset", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateAssetStatus(themobj: { isactive: any; journey_type_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl + "updateAssetStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delAsset(value) {
    let id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteAsset/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Gender  apis
  addingGender(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingGender", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getGender() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getGender", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getGenderById(sexid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getGenderById/${sexid}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateGender(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateGender", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateGenderStatus(genderobj: { isactive: any; sexid: any; }) {
    var body = genderobj;
    return this.http.put<any>(this.baseUrl + "updateGenderStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delGender(value) {
    let id = value;
    //  console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteGender/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Age Range  apis
  addingAgerange(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingAgerange", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAgerange() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getAgerange", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getAgerangeById(marid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getAgerangeById/${marid}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateAgerange(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateAgerange", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateAgerangeStatus(agerangeobj: { isactive: any; marid: any; }) {
    var body = agerangeobj;
    return this.http.put<any>(this.baseUrl + "updateAgerangeStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delAgerange(value) {
    let id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteAgerange/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Message Library  apis
  addingMessagelib(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_q + "addingMessagelib", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getMessagelib(body, limit, skip, colname, order, searchText) {
    //ar body = {};
    return this.http.post<any>(this.baseUrl_q + `getMessagelib/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getMessagelibById(body) {

    return this.http.post<any>(this.baseUrl_q + `getMessagelibById`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateMessagelib(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_q + "UpdateMessagelib", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateMessagelibStatus(messagelibobj: { isactive: any; help_message_id: any; }) {
    var body = messagelibobj;
    return this.http.put<any>(this.baseUrl_q + "updateMessagelibStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delMessagelib(value) {

    return this.http.delete<any>(this.baseUrl_q + "deleteCompanyMessagelib/" + value.id + "/" + value.company, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Subject Library  apis
  addingSubjectlib(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_q + "addingSubjectlib", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubjectlib(body, limit, skip, colname, order, searchText) {
    return this.http.post<any>(this.baseUrl_q + `getSubjectlib/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubjectlibById(body) {
    // var body = {};
    return this.http.post<any>(this.baseUrl_q + `getSubjectlibById`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateSubjectlib(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_q + "UpdateSubjectlib", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateSubjectlibStatus(subjectlibobj: { isactive: any; help_subject_id: any; }) {
    var body = subjectlibobj;
    return this.http.put<any>(this.baseUrl_q + "updateSubjectlibStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delSubjectlib(value) {

    return this.http.delete<any>(this.baseUrl_q + "deleteCompanySubjectlib/" + value.id + "/" + value.company, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //Subscription  apis
  addingSubscription(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingSubscription", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubscription(limit, skip, colname, order, searchText) {
    // var body = {};
    return this.http.post<any>(this.baseUrl + `subscription/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubscriptionById(subscription_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getSubscriptionById/${subscription_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateSubscription(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateSubscription", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateSubscriptionStatus(subscriptionobj: { isactive: any; subscription_id: any; }) {
    var body = subscriptionobj;
    return this.http.put<any>(this.baseUrl + "updateSubscriptionStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delSubscription(value) {
    let id = value;
    // console.log(id);
    return this.http.delete<any>(this.baseUrl + "deleteSubscription/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  uploadGeoCSVFile(value: any) {
    let headOpt = this.httpHeadersOptions();
    headOpt.headers = headOpt.headers.set('enctype', 'multipart/form-data');
    // console.log(this.baseService.baseUrl1 + "geolocation/importCitiesCSV");

    const formData: FormData = new FormData();
    formData.append('csvFile', value, value.name);

    return this.http.post<any>(this.baseService.baseUrl1 + "geolocation/importCitiesCSV", formData, headOpt)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');

  }
  getResources() {
    // var body = value;
    return this.http.get<any>(this.baseUrl2 + `api/resourceprivilege`, this.httpHeadersOptions())
      .pipe(
        map(data => {

          return data;
        }),
        catchError(this.handleError));
  }
  addresourcepermission(body) {
    //  var body = {};
    return this.http.post<any>(this.baseUrl2 + `api/resourcepagepermissions`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error") >= 0 || data.message.indexOf("wrong") >= 0 || data.message.indexOf("query execution") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateresourcepermission(body) {
    //  var body = {};
    return this.http.put<any>(this.baseUrl2 + `api/resourcepagepermissions`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getperids(dataurl) {
    return this.http.get<any>(this.baseUrl2 + `api/resourcepagepermissions` + dataurl, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ################  Get All attribute Data  #####################  //
  getAttributes(cid: any = 0, limit: any = 10, skip: any = 0, colname: any = 'attribute_name', order: any = 'asc', searchText: any = 0) {
    return this.http.get<any>(this.baseUrl_a + `getAttributes/${cid}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ################  Get Full attribute Data with 9999 limit  #####################  //
  getFullAttributes(cid: any = 0, limit, skip: any = 0, colname: any = 'attribute_name', order: any = 'asc', searchText: any = '') {
    return this.http.get<any>(this.baseUrl_a + `getAttributes/${cid}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##############  Add New Attribute ########   //
  addAttribute(body) {

    return this.http.post<any>(this.baseUrl_a + 'addingAttribute', body, this.httpHeadersOptions())

      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  #############  Delete Attribute ##############  //

  delAttr(cid, id) {

    const URL = this.baseUrl_a + 'deleteAttribute' + '/' + cid + '/' + id;

    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ############ Update Attribute Status  ##################  //
  updAttr(body) {

    const URL = this.baseUrl_a + 'updateStatus';

    return this.http.put<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##################  Get Notes Details For Company ###################  //

  getCompanyNotesDetails(cid) {
    const URL = this.baseUrl_n + 'getContactNote' + '/' + cid;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##################  Update Company Notes Details  ##################  //

  updateCompanyNoteDetails(obj) {
    const body = JSON.stringify(obj);
    const URL = this.baseUrl_n + 'addUpdateContactNote';
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
  }

  getCompanyInfo(company_id) {
    return this.http.get<any>(this.baseUrl + `getCompanyInfo/${company_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //  ##################  Get Company Reskinning  ##################  //
  getCompanyReskinning(id, dsid, type = "all") {

    var body = {
      company_id: id,
      type: type,
      dsid: dsid
    };
    return this.http.post<any>(this.baseUrl_rs + `getCompanyReskinning/${id}/100/0`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getReskinningById(companyID, theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_rs + `getReskinningById/${companyID}/${theme_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReskinningByUserType(companyId=0,userType:any) {
    return this.http.get<any>(this.baseUrl + `reskinning/${companyId}/${userType}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //add resckining apis
  addingReskinning(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_rs + "addingReskinning", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  UpdateReskinning(value) {
    var body = value;
    console.log("Body", body);

    return this.http.put<any>(this.baseUrl_rs + "UpdateReskinning", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }




  //  ##################  Get Product Catalog list  ##################  //
  getProductCatalog(id, dsId, limit, skip, searchText, colname, order) {
    return this.http.get<any>(this.baseUrl_pc + `getProductList/${id}?dsId=${dsId}&limit=${limit}&skip=${skip}&sort=${colname}&order=${order}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getProductCatalogById(id, productId) {

    // var body = {company_id:id, type:type};
    return this.http.get<any>(this.baseUrl_pc + `getProductList/${id}/${productId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  //add product catalog
  addingCatalog(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_pc + "productCatelogue", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //update product catalog
  UpdateCatalog(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl_pc + "productCatelogue", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //update product catalog status
  UpdateCatalogStatus(value, id, productId) {
    var body = value;
    return this.http.put<any>(this.baseUrl_pc + `updatproductCateloguestatus/${id}/${productId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delCatalog(company, id) {
    return this.http.delete<any>(this.baseUrl_t + `deleteTheme/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  renameProductImage(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl_pc + `updateProductPicture`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getBlacklistedhost(limit, skip, colname, order, searchText, filter) {
    // var body = value;
    return this.http.get<any>(this.baseUrl_pc + `blacklisted-host/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&type=${filter}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addBlacklistedhost(body) {
    // var body = value;
    return this.http.post<any>(this.baseUrl_pc + `blacklisted-host`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateBlacklistedhost(id, body) {
    // var body = value;
    return this.http.put<any>(this.baseUrl_pc + `blacklisted-host/${id}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  deleteBlacklistedhost(id) {
    // var body = value;
    return this.http.delete<any>(this.baseUrl_pc + `blacklisted-host/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //get cross-sell items
  getCrossSellItems(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_pc + "getCrossSellProductList", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //update cross-sell items
  UpdateCrossSellItems(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl_pc + "updateCrossSellItem", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  exportCustomData(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "GetCrossSellReplenishmentBulkData", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getTermConditions() {
    return this.http.get<any>(this.baseUrl + `agreement`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateTermConditions(body) {
    return this.http.put<any>(this.baseUrl + `agreement`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //  ################## Business Domain API  ##################  //
  getBusinessDomain(limit, skip, companyId) {
    return this.http.post<any>(this.baseUrl + `getBusinessDomain/${companyId}/${limit}/${skip}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getBusinessDomainById(id, companyId) {
    return this.http.get<any>(this.baseUrl + `getBusinessDomainById/${companyId}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addingBusinessDomain(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingBusinessDomain", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  UpdateDomain(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "updateBusinessDomain", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delDomain(id, companyId) {
    return this.http.delete<any>(this.baseUrl + `deleteBusinessDomain/${companyId}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //update product catalog status
  updateBusinessDomainStatus(body) {
    return this.http.put<any>(this.baseUrl + `updateBusinessDomainStatus`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCataloguePage() {
    let page = 'master';
    if (localStorage.getItem('page')) {
      page = localStorage.getItem('page');
    }
    return page;
  }
  //------------------Resource category api-----------------------------
  getReosurceCategoryList(companyId: any, limit: any, skip: any, searchText: any) {
    return this.http.get<any>(this.baseUrl_res + `category-list/${companyId}/${limit}/${skip}?search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  createReosurceCategory(body: any) {
    return this.http.post<any>(this.baseUrl_res + `add-category`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getResCategoryById(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_res + `categorylist/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateResCategory(body: any) {
    var body = body;
    return this.http.put<any>(this.baseUrl_res + "update-category", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateResCategoryStatus(body: any) {
    return this.http.put<any>(this.baseUrl_res + `update-category-status`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //------------------Resource Image api Start-----------------------------
  getReosurceList(companyId: any, limit: any, skip: any, searchText: any, categorytId: any = '') {
    return this.http.get<any>(this.baseUrl_res + `image-list/${companyId}/${limit}/${skip}?search=${searchText}&filter=${categorytId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateResImageStatus(body: any) {
    return this.http.put<any>(this.baseUrl_res + `update-resource-status`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addReosurceImage(body: any) {
    return this.http.post<any>(this.baseUrl_res + `add-image`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReosurceListById(companyId: any, res_id: any) {
    return this.http.get<any>(this.baseUrl_res + `resource-list/${companyId}/${res_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateReosurceImage(body: any) {
    return this.http.put<any>(this.baseUrl_res + `update-resource`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //------------------Resource Image api End-----------------------------
  //------------------FAQ category API-----------------------------
  getFaqCategoryList(companyId: any, limit: any, skip: any, searchText: any, sort?: any, order?: any) {
    return this.http.get<any>(this.baseUrl_faq + `getFaqCategory/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateFaqCategoryStatus(body: any) {
    return this.http.put<any>(this.baseUrl_faq + `updateFaqCategoryStatus`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  createFaqCategory(body: any) {
    return this.http.post<any>(this.baseUrl_faq + `AddingFaqCategory`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getFaqCategoryById(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_faq + `getFaqCategoryById/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateFaqCategory(body: any) {
    var body = body;
    return this.http.put<any>(this.baseUrl_faq + "updateFaqCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteFaqCategory(companyId:any, catId:any) {
    return this.http.delete<any>(this.baseUrl_faq + `deleteFaqCategory/${companyId}/${catId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //-------------------------FAQ API------------------------------------
  getFAQList(companyId: any, limit: any, skip: any, searchText: any, categorytId: any = '', sort?: any, order?: any) {
    return this.http.get<any>(this.baseUrl_faq + `getFaq/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  addFAQ(body: any) {
    return this.http.post<any>(this.baseUrl_faq + `AddingFaq`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getFaqById(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_faq + `getFaqById/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getFaqByCategory(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_faq + `getFaqByCategory/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getFaqByFaqType( company: any,faqType:any= null) {
    return this.http.get<any>(this.baseUrl_faq + `getFaqByFaqtype/${company}/${faqType}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  updateFAQ(body: any) {
    return this.http.put<any>(this.baseUrl_faq + `updateFaq`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateFAQStatus(body: any) {
    return this.http.put<any>(this.baseUrl_faq + `updateFaqStatus`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteFAQ(body: any) {
    return this.http.post<any>(this.baseUrl_faq + `deleteFaq`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //------------------Webinar Category api-----------------------------
  getWebinarCategoryList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
    return this.http.get<any>(this.baseUrl_faq + `getCategory/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateWebinarCategoryStatus(body: any) {
    return this.http.put<any>(this.baseUrl_faq + `updateCategoryStatus`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addWebinarCategory(body: any) {
    return this.http.post<any>(this.baseUrl_faq + `AddingCategory`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getWebinarCategoryById(companyId: any, web_id: any) {
    return this.http.get<any>(this.baseUrl_faq + `getCategoryById/${companyId}/${web_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateWebinarCategory(body: any) {
    return this.http.put<any>(this.baseUrl_faq + `updateCategory`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteWebinarCategory(companyId: any, web_id: any) {
    return this.http.delete<any>(this.baseUrl_faq + `deleteCategory/${companyId}/${web_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
    //------------------Webinar Tag api-----------------------------
    getWebinarTagList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
      return this.http.get<any>(this.baseUrl_faq + `getWebinarTag/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    updateWebinarTagStatus(body: any) {
      return this.http.put<any>(this.baseUrl_faq + `updateWebinarTagStatus`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    addWebinarTag(body: any) {
      return this.http.post<any>(this.baseUrl_faq + `AddingWebinarTag`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getWebinarTagById(companyId: any, web_id: any) {
      return this.http.get<any>(this.baseUrl_faq + `getWebinarTagById/${companyId}/${web_id}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    updateWebinarTag(body: any) {
      return this.http.put<any>(this.baseUrl_faq + `updateWebinarTag`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    delWebinarTag(body) {
      return this.http.post<any>(this.baseUrl_faq + `deleteWebinarTag`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

     //------------------Webinar poll-----------------------------

     getWebinarPollList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
      return this.http.get<any>(this.baseUrl_poll + `${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    addWebinarPoll(companyId:any,body: any) {
      return this.http.post<any>(this.baseUrl_poll + `${companyId}`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    deletePoll(companyId: any,id:any) {
      return this.http.delete<any>(this.baseUrl_poll+ `${companyId}/ ${id}`, this.httpHeadersOptions())
        .pipe(
          map((data:any) => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    updatePollStatus(companyId: any,id:any,body: any) {
      return this.http.put<any>(this.baseUrl_poll + `${companyId}/${id}`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getPollById(id: any, company: any) {
      return this.http.get<any>(this.baseUrl_poll + `${company}/${id}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getWebinarAllPollOptionList(company:any) {
      return this.http.get<any>(this.baseUrl_pc + `poll-option/${company}/100/0`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    getWebinarActivePollOptionList(company:any) {
      return this.http.get<any>(this.baseUrl_pc + `getPollOption/${company}/100/0`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }




    addWebinarPollOptions(companyId:any,body: any){
      return this.http.post<any>(this.baseUrl_pc+ `poll-option/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
    }

    getWebinarPollOptionList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
      return this.http.get<any>(this.baseUrl_pc + `poll-option/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    deletePollOption(companyId: any,id:any) {
      return this.http.delete<any>(this.baseUrl_pc+ `poll-option/${companyId}/ ${id}`, this.httpHeadersOptions())
        .pipe(
          map((data:any) => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getOptionById(id: any, company: any) {
      return this.http.get<any>(this.baseUrl_pc + `poll-option/${company}/${id}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }


    updatePollOption(companyId: any,id:any,body: any) {
      return this.http.put<any>(this.baseUrl_pc + `poll-option/${companyId}/${id}`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    updatePollOptionStatus(companyId: any,body: any) {
      return this.http.put<any>(this.baseUrl_pc + `poll-option-status/${companyId}`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }


     //------------------Webinar Templates-----------------------------
    getTemplateList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
      return this.http.get<any>(this.baseUrl_faq + `getWebinarTemplate/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getWebinarSection(company:any) {
      return this.http.get<any>(this.baseUrl_faq + `getWebinarSection/${company}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    addTemplates(body: any) {
      return this.http.post<any>(this.baseUrl_faq + `AddingWebinarTemplate`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    updateTemplates(body: any) {
      return this.http.put<any>(this.baseUrl_faq + `updateWebinarTemplate`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    deleteTemplate(body:any) {
      return this.http.post<any>(this.baseUrl_faq + `deleteWebinarTemplate`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
    }
    updateTemplateStatus(body:any) {
      return this.http.put<any>(this.baseUrl_faq + `updateWebinarTemplateStatus`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
    }
    getWebinarTemplateSection(company:any,tempId:any,limit: any, skip: any) {
      return this.http.get<any>(this.baseUrl_faq + `getWebinarAndSectionRel/${company}/${tempId}/${limit}/${skip}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    // ---------------Subscription APIs-------------------------
    addSubscriptionCategory(body: any,companyId:any) {
      return this.http.post<any>(this.baseUrl_sub + `subscription/${companyId}`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getSubscriptionList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
      return this.http.get<any>(this.baseUrl_sub + `subscription/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

    updateSubscriptionDataStatus(companyId: any,subId:any,body:any) {
      return this.http.put<any>(this.baseUrl_sub + `subscriptionStatus/${companyId}/${subId}`, body,this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    deleteSubscriptionCategory(companyId: any, sub_id: any) {
      return this.http.delete<any>(this.baseUrl_sub + `subscription/${companyId}/${sub_id}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    getSubscriptionDataById(companyId: any, web_id: any) {
      return this.http.get<any>(this.baseUrl_sub + `subscription/${companyId}/${web_id}`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    updateSubscriptionData(companyId: any,subId:any,body:any) {
      return this.http.put<any>(this.baseUrl_sub + `subscription/${companyId}/${subId}`, body,this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }

      // Subscription Limit required APIs //

      getSubscriptionListDropdown(companyId: any, limit: any, skip: any) {
        return this.http.get<any>(this.baseUrl_pc + `subscription/subscription/${companyId}/${limit}/${skip}`, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      getSubscriptionLimitList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
        return this.http.get<any>(this.baseUrl_pc + `subscription/subscription-limit/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      createSubscriptionLimit(companyId: any,body:any) {
        return this.http.post<any>(this.baseUrl_pc + `subscription/subscription-limit/${companyId}`,body, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      getSubscriptionLimitById(companyId: any,id:any) {
        return this.http.get<any>(this.baseUrl_pc + `subscription/subscription-limit/${companyId}/${id}`, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      updateStatusSubscriptionLimit(companyId: any,id:any,body:any) {
        return this.http.put<any>(this.baseUrl_pc + `subscription/subscription-limit-status/${companyId}/${id}`,body, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      deleteSubscriptionLimit(companyId: any,id:any) {
        return this.http.delete<any>(this.baseUrl_pc + `subscription/subscription-limit/${companyId}/${id}`,this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }

      updateSubscriptionLimit(companyId: any,id:any,body:any) {
        return this.http.put<any>(this.baseUrl_pc + `subscription/subscription-limit/${companyId}/${id}`,body,this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
      }
  //-----------Subscription Media API's--------------------
  addSubscriptionMedia(body: any,companyId:any) {
    return this.http.post<any>(this.baseUrl_sub + `subscription-media/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubscriptionMediaList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
    return this.http.get<any>(this.baseUrl_sub + `subscription-media/${companyId}/${limit}/${skip}?search=${searchText}&sort=${sort}:${order}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateSubscriptionMediaStatus(companyId: any,subId:any,body:any) {
    return this.http.put<any>(this.baseUrl_sub + `subscription-media-status/${companyId}/${subId}`, body,this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteSubscriptionMedia(companyId: any, sub_id: any) {
    return this.http.delete<any>(this.baseUrl_sub + `subscription-media/${companyId}/${sub_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateSubscriptionMedia(companyId: any,subId:any,body:any) {
    return this.http.put<any>(this.baseUrl_sub + `subscription-media/${companyId}/${subId}`, body,this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSubscriptionMediaById(companyId: any, sub_id: any) {
    return this.http.get<any>(this.baseUrl_sub + `subscription-media/${companyId}/${sub_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSenderProfileList(companyId: any, limit: any, skip: any, searchText?: any, sort?: any, order?: any) {
    return this.http.get<any>(this.baseUrl_pc + `sender/${companyId}/${limit}/${skip}?search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  viewSenderProfile(companyId: any,id:any) {
    return this.http.get<any>(this.baseUrl_pc + `sender/${companyId}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  viewSenderProfileLog(companyId: any,id:any) {
    return this.http.get<any>(this.baseUrl_pc + `sender/${companyId}/${id}/50/0`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateSenderProfile(companyId: any,id:any,body:any) {
    return this.http.put<any>(this.baseUrl_pc + `sender/${companyId}/${id}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //------------------WooCommerce API---------------
  getCredentialPackageList(companyId: any, adminId: any, connectorId:any,limit:any,skip:any) {
    return this.http.get<any>(this.baseUrl_woo + `list/${companyId}/${adminId}/${connectorId}/${limit}/${skip}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addCredentials(body: any,companyId:any) {
    return this.http.post<any>(this.baseUrl_woo + `credential-add/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCredentials(body: any,companyId:any) {
    return this.http.put<any>(this.baseUrl_woo + `update/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getschemaList(companyId: any,connectorId:any) {
    return this.http.get<any>(this.baseUrl_woo + `connector/schema-list/${companyId}/${connectorId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCredentialsById(companyId: any,adminId:any,credentialId:any) {
    return this.http.get<any>(this.baseUrl_woo + `listById/${companyId}/${adminId}/${credentialId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCroneLogsList(companyId: any,adminId:any,connectorId:any) {
    return this.http.get<any>(this.baseUrl_woo + `log-list/${companyId}/${adminId}/${connectorId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deletePackageCredential(companyId: any,connectorId:any) {
    return this.http.delete<any>(this.baseUrl_woo + `dell/${connectorId}/${companyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCroneList(companyId: any,adminId:any,limit:any,skip:any,body:any){
    return this.http.post<any>(this.baseUrl_woo + `list/${companyId}/${adminId}/${limit}/${skip}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getMapFiels(companyId:any,body:any){
    return this.http.post<any>(this.baseUrl_woo + `get-map-fields/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCronStatus(companyId:any,body:any){
    return this.http.put<any>(this.baseUrl_woo + `crone-update-status/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCronById(companyId:any,croneId:any){
    return this.http.get<any>(this.baseUrl_woo + `listById/${companyId}/${croneId}`, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  updateCron(companyId:any,body:any){
    return this.http.put<any>(this.baseUrl_woo + `crone-update/${companyId}`, body, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  getProductFromWoocommerce(companyId:any,body:any){
    return this.http.post<any>(this.baseUrl_woo + `product-list/${companyId}`, body, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  fetchContactList(companyId,limit, skip, searchText) {
    return this.http.get<any>(this.baseUrl2 + `api/contact/list/${companyId}/${limit}/${skip}?search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  fetchContactEnquiryById(companyId,id) {
    return this.http.get<any>(this.baseUrl2 + `api/contact/listById/${companyId}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  fetchReskinningByCompany(companyId) {
    return this.http.get<any>(this.baseUrl2 + `api/reskinning/getDomainReskinning/${companyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReskinningSenderNumber(companyId) {
    return this.http.get<any>(this.baseUrl_rs + `getPhoneNumbers/${companyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addJourneySpan(body,company) {
    return this.http.post<any>(this.baseUrl_j + `adding-journey-span/${company}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateJourneySpan(body,company) {
    return this.http.put<any>(this.baseUrl_j + `update-journey-span/${company}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneySpan(id,company) {
    return this.http.get<any>(this.baseUrl_j + `getSpanJourneyById/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneySpanAll(company, limit: any = 0, skip: any = 0,journey_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl_j + `getSpanJourney/${company}/${limit}/${skip}?journeyId=${journey_id}`, body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteJourneySpan(id,company) {
    return this.http.delete<any>(this.baseUrl_j + `journeySpan/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReadinessActivityList(companyId:any,body:any){
    return this.http.post<any>(this.baseUrl_pc + `readiness/activity/allSubCat/${companyId}`, body, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  getReadinessParentList(company:any){
    return this.http.get<any>(this.baseUrl_pc + `readiness/activity/allParent/${company}`)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addReadinessActivity(company:any,body:any){
    return this.http.post<any>(this.baseUrl_pc + `readiness/activity/create/${company}`,body)
      .pipe(
        // map(data => {
        //   if (!data.status) {
        //     if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
        //       console.error(data.message);
        //       data.message = "Something went wrong.Please try again."
        //     }
        //   }
        //   return data;
        // }),
        // catchError(this.handleError)
      );
  }
  updateActivityStatus(company:any,body: any) {
    return this.http.put<any>(this.baseUrl_pc + `readiness/activity/update-status/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReadinessActivityById(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_pc + `readiness/activity/withiId/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateReadinessActivity(company:any,body:any){
    return this.http.put<any>(this.baseUrl_pc + `readiness/activity/update/${company}`,body)
      .pipe(
        // map(data => {
        //   if (!data.status) {
        //     if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
        //       console.error(data.message);
        //       data.message = "Something went wrong.Please try again."
        //     }
        //   }
        //   return data;
        // }),
        // catchError(this.handleError)
      );
  }
  delReadinessActivity(company, id) {
    return this.http.delete<any>(this.baseUrl_pc + `readiness/activity/dell/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReadinessResourcesList(companyId:any,body:any){
    return this.http.post<any>(this.baseUrl_pc + `readiness/resources/allSubCat/${companyId}`, body, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  addReadinessResources(company:any,body:any){
    return this.http.post<any>(this.baseUrl_pc + `readiness/resources/create/${company}`,body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateResourcesStatus(company:any,body: any) {
    return this.http.put<any>(this.baseUrl_pc + `readiness/resources/update-status/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delReadinessResources(company, id) {
    return this.http.delete<any>(this.baseUrl_pc + `readiness/resources/del/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getReadinessResourcesById(id: any, company: any) {
    return this.http.get<any>(this.baseUrl_pc + `readiness/resources/withiId/${company}/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateResourcesActivity(company:any,body:any){
    return this.http.put<any>(this.baseUrl_pc + `readiness/resources/update/${company}`,body)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSupportTicketList(id=0,payload={}) {
    return this.http.post<any>(this.baseUrl_pc + `ticket/list/${id}`,payload)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getDsListSupportTicket(id=0) {
    return this.http.get<any>(this.baseUrl_pc + `ticket/ds-list/${id}`)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getSupportTicketDetails(cid,dsid,id) {
    return this.http.get<any>(this.baseUrl_pc + `ticket/details/${cid}/${dsid}/${id}`)
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
}
