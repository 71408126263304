<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
      <li class="breadcrumb-item active" aria-current="page">PII DSAR </li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
      <legend class="scheduler-border"><h5 class="font-weight-bold">DSAR in Master DB</h5></legend>
<div class="row">
  <div class="col-md-12">
    <p>&nbsp;</p>
    <p>This section will display all Those DSAR based Data in  Master Database which had PII based data</p>
  </div>
</div>
</fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
      <legend class="scheduler-border"><h5 class="font-weight-bold">DSAR Data in BU Headquater DB</h5></legend>
<div class="row">
  <div class="col-md-12">
    <p>&nbsp;</p>
    <p>This section will display all Those DSAR based Data in BU Headquater DB Database which had PII based data</p>
  </div>
</div>
</fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
      <legend class="scheduler-border"><h5 class="font-weight-bold">DSAR Data in BU DB</h5></legend>
<div class="row">
  <div class="col-md-12">
    <p>&nbsp;</p>
    <p>This section will display all Those DSAR based Data in BU DB Database which had PII based data</p>
  </div>
</div>
</fieldset>
</div>
