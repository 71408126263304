<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>


<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Operator List
      </li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>
    <div class="col-md-4">
      <!-- (change)="filterOperator($event.target.value)" -->
      <select class="form-control formstyle" [(ngModel)]="operGroup" (change)="getOperator()">
        <option value="">Select Operator Group</option>
        <option *ngFor="let userObj of operatorGroupObj" value={{userObj.group_name}}>
          {{userObj.group_name}}
        </option>
      </select>
    </div>

    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn mt-md-1 ml-0" *ngIf="isWrite" routerLink="/corporate/add-operator">
    </div>
  </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
      <tr>

        <th (click)="sorting('operator_name')" class="curPoint">Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Description</th>
        <th>Operator Group</th>

        <th (click)="sorting('createdon')" class="curPoint">
          Created On <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th>Actions</th>

      </tr>
      <tr *ngFor="let obj of operatorObj">

        <td>{{obj.operator_name}}</td>
        <td>{{obj.operator_desc}}</td>
        <td>{{obj.group_name}}</td>
        <td>{{obj.createdon | date}}</td>


        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1 cursor" (click)="editOperator(obj.operator_id)" *ngIf="isEdit" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1 cursor" (click)="deleteOperator(obj.operator_id)" *ngIf="isDelete"
              placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle [checked]="obj.isactive" class="my-slider cursor" *ngIf="isEdit"
              (click)="updateStatus($event,obj.operator_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>

    <div *ngIf="totalCount == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
        (pageChange)="getOperator(page = $event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>