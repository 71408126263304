import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';


@Component({
  selector: 'app-newthemecategory',
  templateUrl: './newthemecategory.component.html',
  styleUrls: ['./newthemecategory.component.css']
})
export class NewthemecategoryComponent extends BaseComponent implements OnInit {
  themecategoryname;
  themedescription;
  IsActive;
  them_id;
  themObj;
  status: boolean = true;

  constructor(private master: MasterService,
    private alertService: AlertService,) {
      super();
     }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.them_id = params.them_id;
      });
    //get item details using id
    if (this.them_id) {
      this.ngxSpinnerService.show();
      this.master.getThemeCategoryById(this.them_id).subscribe(response => {
      //  console.log(response);
        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          this.ngxSpinnerService.hide();
        //  console.log(self.themObj)
          self.dataBinding();
        } else {

        }
      });
    }
  }

  addNewThemeCategory() {
    if( this.themecategoryname &&  this.themecategoryname.trim()){
    let themeObj = {
      name: this.themecategoryname,
      description: this.themedescription,
      isactive: this.IsActive
    }
    this.ngxSpinnerService.show();
    this.master.addingThemeCategory(themeObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/themecategorymaster`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }else{
    this.toastrService.error("Please Fill all the fields")
  }
  }
  dataBinding() {
    this.themecategoryname = this.themObj.theme_category_name;
    this.themedescription = this.themObj.theme_description;
    this.IsActive = this.themObj.isactive;
  }
  updateThemeCategory() {
    if( this.themecategoryname && this.themecategoryname.trim()){
      let themobj = {
        theme_category_name: this.themecategoryname,
        theme_description: this.themedescription,
        isactive: this.IsActive,
        theme_id: this.them_id
      }
      this.ngxSpinnerService.show();
      this.master.UpdateThemeCategory(themobj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/themecategorymaster`);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }else{
      this.toastrService.error("Please Fill name & screenshot fields")
    }
  }
  
}

