import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import {forkJoin} from 'rxjs';
@Component({
  selector: 'app-add-cb-template-section',
  templateUrl: './add-cb-template-section-association.component.html',
  styleUrls: ['./add-cb-template-section-association.component.css']
})
export class AddCBTSAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  submitted = false;
  templateForm: FormGroup;
  sectionObj:any=[];
  sectionid: any = [];
  getCompanyobj: any = [];
  image;
  companyID;
  selectedSections:any = [];
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCompany();

    this.templateForm = this.formBuilder.group({
      company: ['', Validators.required],
      tid: ['', Validators.required]
    });
  }

  get f() { return this.templateForm.controls; }

  selectSections(e, id) {
    if(e.target.checked) {
      this.selectedSections.push(id)
    } else if(!e.target.checked) {
      this.selectedSections.splice(this.selectedSections.indexOf(id), 1);
    }
  }

  addTemplate() {
    this.submitted = true;
    //console.log(this.templateForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
    //console.log("themescreenshot -- ", this.templateForm);

    if (this.templateForm.invalid) {
      return;
    }

    if(this.selectedSections.length>0) this.selectedSections = this.selectedSections.filter((el, i, a) => i === a.indexOf(el));


    const data = {
      section_id: this.selectedSections,
      templateid: this.f.tid.value,
      company: this.f.company.value
    }
    this.ngxSpinnerService.show();
    this.cb.addTSAssociation(data).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-ts-association`);
      } else {
        this.toastrService.error(data.message)
        this.ngxSpinnerService.hide();
      }
    }, error => {
   //   console.log(error);
      this.toastrService.error('Something went wrong')
    });
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
     // console.log(this.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }

  getCompanyTemplate(id){
    //event.preventDefault();
    if(id) {

    this.companyID = id;
    this.ngxSpinnerService.show();
    this.f.tid.setValue("");
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        template: this.cb.getCompanyTemplate(id),
        //section: this.cb.getCompanySection(id)
      }
    ).subscribe(data => {

      if(data.template.data) {

      this.themeObj = data.template.data.rows;




      this.sectionObj = [];
      this.image ="";
      this.ngxSpinnerService.hide();
      }
     else { this.themeObj =[];  this.ngxSpinnerService.hide(); }
      //this.sectionObj = data.section.data;
    //  console.log(data, 'forkJoin')
    }), error => {
    //  console.log(error);

    }
    }else {
      //this.ngxSpinnerService.hide();
      this.sectionObj = [];
      this.themeObj = [];
      this.image =""

    }
  }

  getTemplateSection(id){
    if(id) {
    let self = this
    this.ngxSpinnerService.show();
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        templatePreview: this.cb.getTemplateView(this.companyID,id),
        selectedSections: this.cb.getAssociatedTemplateSection(this.companyID, id),
        section: this.cb.getCompanySection(this.companyID, 9999, 0, 'section_name', 'asc')
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.templatePreview) this.getTemplatePreview(data.templatePreview);
      if(data.section.data){
        self.sectionObj = data.section.data.rows;
      }else {
        this.sectionObj = [];
      }



      if(data.selectedSections.data) {
        this.selectedSections = data.selectedSections.data.map(value => value.sectionid);
      } else {
        this.selectedSections = [];
      }

      console.log(this.selectedSections);
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
      this.sectionObj = [];
    });
  }
  else {
    //this.ngxSpinnerService.hide();
    this.sectionObj = [];
    this.image =""

  }

  }

  getTemplatePreview(data) {
    let self = this
    this.ngxSpinnerService.show();
      let url = data.data.screenshot
      if (url && url.indexOf("master_images") >= 0) {
        url = url.substring(url.indexOf("master_images"), url.length)
        if (!this.isUrlValid(url)) {
          let awsUrl = self.master.getUrlfile(url);
          this.image = self.isUrlValid(awsUrl) ? awsUrl : '';
          this.ngxSpinnerService.hide();
        } else {
          this.image = '';
          this.ngxSpinnerService.hide();
        }
      } else {
          this.image = '';
          this.ngxSpinnerService.hide();
      }
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
}
