import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { FbService } from 'src/app/services/fbservice/fb.service';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { OrderPipe } from 'ngx-order-pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  public categoryObj: any = [];
  today: number = Date.now();
  reverse: boolean = true;
  updateForm: FormGroup;
  postflag1: boolean = false;
  postflag2: boolean = false;
  categoryname: any;
  datacount: any;
  oldcatid;
  categorynewdata;
  catname;
  submitted = false;

  getCompanyobj: any = [];
  currentUser;
  companyID = 0;
  selectedCompany: any = '';
  categoryId;

  fbCatord = "asc";
  fbCatColname = "category_name";
  fbCatPage = 1;
  fbCatLimit = 10;
  fbCatSkip = 0;
  fbCatTotal: number = 0;

  constructor(private fb: FbService,
    public router: Router,
    public logs: LogsService,
    public toastrService: ToastrService,
    public confirmationDialogService: ConfirmationDialogService,
    public ImportFilesDialogService: ImportFilesDialogService,
    public orderPipe: OrderPipe,
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private clientService: ClientService,
  ) { super(); }

  ngOnInit() {
    localStorage.removeItem("edit_category_id");
    localStorage.removeItem("category_edit_company_id");
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getCategory();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
    this.CategoryLogData();

    this.updateForm = this.formBuilder.group({
      oldcatid: ['', Validators.required],
      categoryId: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.fbCatSkip = 0;
          this.fbCatPage = 1;
        }),
        switchMap(data => this.fb.getCategoryData(this.selectedCompany, this.fbCatLimit, this.fbCatSkip, this.fbCatColname, this.fbCatord, true, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = res.data.rows }
          this.fbCatTotal = res.data.total;
        } else {
          this.categoryObj = [];
          this.fbCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.categoryObj = [];
        this.fbCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

  get fud() { return this.updateForm.controls; }

  setOrder(value: string) {
    this.fbCatColname = value
    if (this.fbCatord == 'asc') {
      this.fbCatord = 'desc'
    } else {
      this.fbCatord = 'asc'
    } this.getCategory()
  }

  CategoryLogData() {
    let CategoryLogs = {
      log_type: "Category View",
      log_name: "Category View",
      log_description: "Category View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CategoryLogs).subscribe(data => {
      // console.log(data);
    }, error => {
      // console.log(error);
    });
  }

  editCategory(id) {
    localStorage.setItem("edit_category_id", id);
    localStorage.setItem("category_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/addnewcategory']);
  }

  getCategory() {
    let searchTerm = this.searchText.nativeElement.value;
    this.fbCatSkip = (this.fbCatPage - 1) * this.fbCatLimit;
    this.ngxSpinnerService.show();
    if (this.selectedCompany !== "") {
      this.fb.getCategoryData(this.selectedCompany, this.fbCatLimit, this.fbCatSkip, this.fbCatColname, this.fbCatord, true, searchTerm).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = data.data.rows }
          this.fbCatTotal = data.data.total;
        } else {
          this.categoryObj = [];
          this.fbCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.ngxSpinnerService.hide();
        // console.log(error);
      });
    } else {
      this.categoryObj = [];
      this.selectedCompany = "";
      this.fbCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }

  cancelCat() {
    //this.postflag1=false;
    //this.postflag2=false;
    this.modalService.dismissAll();
  }

  delCategoryUserData() {
    let self = this;
    this.updateForm = this.formBuilder.group({
      oldcatid: [this.oldcatid, Validators.required],
      categoryId: ['', Validators.required],
      company: [this.selectedCompany]
    });
    var cat = this.updateForm.value;
    this.ngxSpinnerService.show();
    this.fb.delCategoryUserData(cat).subscribe(data => {
      self.ngxSpinnerService.hide();
      if (data.status) {
        this.postflag1 = false;
        this.postflag2 = false;

        this.modalService.dismissAll();
        self.categoryObj = [];
        this.getCategory();
        this.toastrService.success("Successfully Deleted");
      } else {
        this.toastrService.error();
      }
    });
  }

  cancelEvent(catid, cname) {

    let self = this;
    this.postflag1 = false;
    this.postflag2 = false;
    this.oldcatid = catid;
    var catdata = { catid: catid, company: this.selectedCompany };
    this.updateForm = this.formBuilder.group({
      oldcatid: [this.oldcatid, Validators.required],
      categoryId: ['', Validators.required],
      company: [this.selectedCompany]
    });
    //  alert(catdata);
    this.catname = cname;
    this.ngxSpinnerService.show();
    this.fb.cancelCat(catdata).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.datacount = data.data;
        //  alert(this.datacount[0]);         
        if (this.datacount[0] > 0 || this.datacount[1] > 0) {
          this.postflag1 = true;
          this.postflag2 = false;
          this.fb.getUnusedCategory(catdata).subscribe(data => {
            this.categorynewdata = data.data;
            //  console.log(this.categorynewdata);
          });
          // console.log(this.datacount);
          //self.modalService.open(modalName,this.datacount);
        } else {

          this.postflag1 = false;
          this.postflag2 = true;
          //self.modalService.open(modalName);
        }

      } else {
        this.postflag1 = false;
        this.postflag2 = true;
        //  console.log(this.datacount);
        // self.modalService.open(modalName);
      }
    });

  }

  updatepostincategory(data: any) {
    if (this.updateForm.invalid) {
      this.submitted = true;
      return;
    }
    var catformdata = this.updateForm.value;
    //  alert(catformdata);
    catformdata.company = this.selectedCompany
    this.fb.updatepostincategory(catformdata).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Successfully Deleted");
        this.modalService.dismissAll();
        this.getCategory();
        // self.modalService.close();
      }
    });

  }

  deleteCategory(name, id, catname) {
    let obj = {
      id: id
    }

    this.cancelEvent(id, catname)
    this.modalService.open(name, { size: <any>'sm' });

  }


  updateStatus(event, id, status) {

    event.preventDefault();

    let obj = {
      id: id,
      isactive: (status == true) ? false : true,
      company: this.selectedCompany
    }

    var catdata = { catid: id, company: this.selectedCompany };


    this.fb.cancelCat(catdata).subscribe(data => {
      if (data.status) {
        let dt = data.data;
        //  alert(this.datacount[0]);         
        if (dt[0] > 0 || dt[1] > 0) {


          this.toastrService.error('Category is already linked to FB posts/snippets.');

        } else {

          this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')

            .then((confirmed) => {
              if (confirmed) {
                this.fb.updateCategoryStatus(obj).subscribe(data => {
                  this.toastrService.success("Status Updated Successfully");
                  this.getCategory();
                });
              } else {

              }
            }).catch(() => {
              //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
            });

        }

      } else {

        this.toastrService.error(data.message);
      }
    }, error => {
      console.log(error);
    });

  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
      // console.log(self.getCompanyobj);
    }, error => {
      //   console.log(error);
    });
  }

}