import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-newgender',
  templateUrl: './newgender.component.html',
  styleUrls: ['./newgender.component.css']
})
export class NewgenderComponent extends BaseComponent implements OnInit {


  sex_name;
  sex_desc;
  sex_icon;
  isactive;
  sexid;
  gender_type_id;
  genderObj;
  status: boolean = true;
  submitted = false;
  AddGender: FormGroup;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    ) {
      super();
     }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.sexid = params.sexid
      });
    //get item details using id
    if (self.sexid) {
      this.ngxSpinnerService.show();
      this.master.getGenderById(self.sexid).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.genderObj = response.data;
         // console.log(self.genderObj)
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }

      });
    }
    this.AddGender = this.formBuilder.group({
      sex_name: ['', Validators.required],
      sex_desc: [],
      sex_icon:[],
    });
  }
  get f() { return this.AddGender.controls; }
  addNewGender() {
    this.submitted = true;
    if (this.AddGender.invalid) {
      return;
    }

    let nm = this.AddGender.value.sex_name.trim();
    if (nm === '' || nm === null || nm === undefined) {
      this.toastrService.error('Please provide valid Gender');
      return;
    }
    let genderObj = {
      name: this.AddGender.value.sex_name,
      description: this.AddGender.value.sex_desc,
      sex_icon: this.AddGender.value.sex_icon,
      isactive: this.isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingGender(genderObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/gender`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
    dataBinding() {

    // this.sex_name = this.genderObj.sex_name;
    // this.sex_desc = this.genderObj.sex_desc;
    // this.sex_icon = this.genderObj.sex_icon;
    // this.isactive = this.genderObj.isactive;
    this.AddGender = this.formBuilder.group({
      sex_name: [this.genderObj.sex_name, Validators.required],
      sex_desc: [this.genderObj.sex_desc],
      sex_icon:[this.genderObj.sex_icon]
    });this.isactive = this.genderObj.isactive;
  }
  updateGender() {
    this.submitted = true;
    if (this.AddGender.invalid) {
      return;
    }

    let nm = this.AddGender.value.sex_name.trim();
    if (nm === '' || nm === null || nm === undefined) {
      this.toastrService.error('Please provide valid Gender');
      return;
    }
    let genderObj = {
      sex_name: this.AddGender.value.sex_name,
      sex_desc: this.AddGender.value.sex_desc,
      sex_icon: this.AddGender.value.sex_icon,
      isactive: this.isactive,
      sexid: this.sexid
    }
      this.ngxSpinnerService.show();
      this.master.UpdateGender(genderObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully");;
          this.router.navigateByUrl(`/corporate/gender`);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }

}


