import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/clients/client.service';


@Component({
  selector: 'app-clientdetails-listsearch',
  templateUrl: './clientdetails-listsearch.component.html',
  styleUrls: ['./clientdetails-listsearch.component.css']
})
export class ClientdetailsListsearchComponent implements OnInit {
  contactUserObj;
  client_id;
  clientLimit = 10;
  clientSkip = 0;
  clientPage = 1;
  clientTotal: number = 0;

  constructor(
    private clientsService: ClientService,
  ) { }

  ngOnInit() {
    if(localStorage.getItem("updatedClientId"))  this.client_id = localStorage.getItem("updatedClientId")
    
     if (this.client_id) {
      // console.log(self.client_id);
       this.getCompanySeller();
     }
  }

  
  getCompanySeller() {
    let self = this;
    this.clientSkip = (this.clientPage - 1) * this.clientLimit;
    this.clientsService.getCompanySellerList(this.client_id, this.clientLimit, this.clientSkip).subscribe(data => {
      if (data.status) {
        // if (data.data.rows == null) { self.contactUserObj = [] }
        self.contactUserObj = data.data;
        self.clientTotal = data.total;
      }
     
    }, err => {
      console.error(err);
    })
  }

}
