import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClientService } from "src/app/services/clients/client.service";
import { MetaDataService } from "src/app/services/metadata/metadata.service";
import { BaseComponent } from "../../base.component";




@Component({
    selector: 'app-metaclienttablerelation',
    templateUrl: './metaclienttablerelation.component.html',
    styleUrls: ['./metaclienttablerelation.component.css']
})
export class MetaClientTableRelationComponent extends BaseComponent implements OnInit{

    customerID: any;
    getCompanyobj = [];
    buForm: FormGroup;
    selectedBuId = '';
    selectedTable = '';
    selectedTableId = '';
    buTables = [];
    clientTables = [];
    client_bu_tables_rel = [];
    clientTableIds = [];

    constructor(private metaService: MetaDataService, private formBuilder: FormBuilder,
        private clientService: ClientService) {
        super();
        let sid = JSON.parse(localStorage.getItem('userData'));
        this.customerID = sid.admin_customer_id;
    }

    ngOnInit(): void {
        this.ngxSpinnerService.show();
        this.getCompanyList();
        this.buForm = this.formBuilder.group({
            buid: [''],
            selTable: ['']
          });
    }

    // ##########  Get BU Company List  #####################  //

    getCompanyList() {
        this.clientService.getBUCompanies().subscribe(data => {
            if (data.status) {
              this.getCompanyobj = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.getCompanyobj = [];
            this.ngxSpinnerService.hide();
          });
    }

    //  ############  On BU Selected from Dropdown ###################  //

    onBuSelected() {
        this.ngxSpinnerService.show();
        this.selectedBuId = this.buForm.value.buid;
        this.getBUTablesListing(this.selectedBuId);
    }

    //  ###########  Get BU Tables Listing ###########################  //

    getBUTablesListing(buId) {
        this.buTables = [];
        this.clientTables = [];
        this.client_bu_tables_rel = [];
        this.buForm.get('selTable').patchValue('');
        this.selectedTableId = '';
        this.selectedTable = '';
        this.clientTableIds = [];
        if (buId === '' || buId === undefined || buId === null) {
            this.ngxSpinnerService.hide();
            return;
        }
        this.metaService.getBUTablesListing(buId).subscribe(data => {
            if (data.status) {
                this.buTables = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }

    //  ###########  Select BU Table for Mapping #################  //

  onBuTableSelected() {
      this.ngxSpinnerService.show();
      this.clientTables = [];
      this.client_bu_tables_rel = [];
      this.clientTableIds = [];
    this.selectedTable = this.buForm.value.selTable;
    let tb = this.buTables.filter(elem => elem.table_alias === this.selectedTable);
    if (tb && tb.length > 0) {
      this.selectedTableId = tb[0].table_id;
    }
    if (this.selectedTable === '' || this.selectedTable === undefined || this.selectedTable === null) {
        this.selectedTableId = '';
        this.ngxSpinnerService.hide();
        return;
      }
      this.getMetaClientTable();
  }

  //  ##########  Get List for Client Meta Tables ################  //

  getMetaClientTable() {
    this.metaService.getMetaClientTables(this.selectedBuId).subscribe(data => {
        if (data.status) {
            this.clientTables = data.data;
            this.getClientBuTableMapping();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
      });
  }

  //  #########  Get Mapping for Client-BU Table  ############  //
  
  getClientBuTableMapping() {
    this.clientTableIds = [];
    this.client_bu_tables_rel = [];
    this.metaService.getClientBuTableMapping(this.selectedBuId, this.selectedTableId).subscribe(data => {
        
        if (data.status) {
            
            this.client_bu_tables_rel = data.data;
            
            for (let item of this.client_bu_tables_rel) {
                this.clientTableIds.push(item.client_tbl_name);
            }
            
        } 
        this.ngxSpinnerService.hide();
      }, error => {
        this.clientTables = [];
        this.client_bu_tables_rel = [];
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
      });
  }

  //  ###################  On Check Uncheck Relation Checkbox  ###   //

  checkUncheckTable(event, id) {
    if (event.target.checked) {
        this.clientTableIds.push(id);
      } else {
        this.clientTableIds.splice(this.clientTableIds.indexOf(id), 1);
      }
  }


  //  ###################  Update Relation #######################  //

  updateRelation() {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Update Meta Data Table Relation Mapping?', 'Yes', 'No')
    .then((confirmed) => {
      if (confirmed) {
        this.ngxSpinnerService.show();
        this.addMapping();
      }
    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
    }

    // ##### Add Mapping on Confirmation  ###################  //

    addMapping() {
        let obj = {
            'table_id': this.selectedTableId,
            'table_name': this.clientTableIds
          };
          this.metaService.createClientBuTableRelation(obj, this.selectedBuId).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Relation updated Successfully');
              this.ngxSpinnerService.hide();
              this.router.navigateByUrl('/corporate/metadata-list');
      
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
          });
    }
  

}