import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-entities-audience-association',
  templateUrl: './entities-audience-association.component.html',
  styleUrls: ['./entities-audience-association.component.css']
})
export class EntitiesAudienceAssociationComponent extends BaseComponent implements OnInit{
  fullAssocList = [];
  entitiesObj = [];
  auid = '';
  audList = [];
  entities = [];
  limit = 9999;
  offset = 0;
  show = false;
  constructor (private segmentService: SegmentationService) {
    super();
  }
  ngOnInit() {
    this.getEntities();
    this.getList();
    this.getAllEntityAudienceAssociationList();
  }

  //  ################  Get Full List of Entities and Attribute Association  ######  //

  getAllEntityAudienceAssociationList() {
    this.segmentService.getAllEntityAudienceAssoc().subscribe(data => {

      if (data.status) {
        this.fullAssocList = data.data;
       // console.log('FULL ASSOCIATION LIST', this.fullAssocList);
      } else  {
        this.fullAssocList = [];
      }
    }, error => {
     // console.log(error);
    });
  }

  //  ################  Get List of Entities  #################  //

  getEntities() {

    this.ngxSpinnerService.show();
    this.segmentService.getEntity(this.limit, this.offset, 'createdon', 'desc').subscribe(data => {

      if (data.status) {
        this.entities = data.data.rows;
      } else  {
        this.entities = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###############  Get Attributes Group List  #######  //

  getList(){
    this.segmentService.getAudience(this.limit, this.offset, 'audience_name', 'asc').subscribe(data => {
      if (data.status){
        this.audList = data.data.rows;
      } else {
        this.audList = [];
      }
    }, error => {
      this.toastrService.error(error);
    });
  }

  //  ##############  Fetch Associated Attributes with Entity  ###########  //

  getAssociatedEntities(id){
    this.entitiesObj = [];
    this.show = false;
    this.fullAssocList = [];
    if (id === '') {
      this.getAllEntityAudienceAssociationList();
      return;
    }

    this.ngxSpinnerService.show();
    this.segmentService.getAssociatedAudienceForEntities(id).subscribe(data => {
      if (data.status) {
        let arr = data.data;
        if (arr.length > 0){
          for (let obj of data.data){
            let val = this.entities.filter(elem => elem.entity_id === obj.entity_id);
            this.entitiesObj.push(val[0]);
          }
        }
      } else  {
        this.toastrService.error(data.message);
      }
      this.show = true;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Edit Association  ###################  //


  editAssociation(){
    let URL = '/corporate/add-entities-audience-association' ;


    this.router.navigate([URL], { queryParams: {'assocId': this.auid}});

  }

  //  ############# Edit Association (Selecting Audience ID from Full List ########  //

  editAssociationById(id){
    let URL = '/corporate/add-entities-audience-association';


    this.router.navigate([URL],  { queryParams: {'assocId': id}});

  }
}

