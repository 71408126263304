<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
      <li class="breadcrumb-item active" aria-current="page">PII Field</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon">

  <fieldset class="scheduler-border">

    <legend class="scheduler-border">
      <h5 class="font-weight-bold">PII & DSAR Table Fields in Master DB</h5>
    </legend>
    <div class="row">
      <div class="col-md-7">

        <h5 class="mt-1 mb-1">Select Table for PII Data Filter</h5>

        <div class="piInnerList scroller">
          <ul class="filtMain">
            <li *ngFor="let item of sensitveTablesobj">
              <div class="row">
                <div class="col-sm-9">
                  <label class="form-check-label">
                    {{item.table_name}}
                  </label>
                </div>
                <div class="col-sm-3">
                  <span (click)="gettingTableField(item.table_name,item.pii_tbl_id)"
                    class="blue-lt-txt cursor-pointer">View fields</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="nonSensitiveTableField.length == 0 && !showMessage" class="col-md-5 mt-1 mt-md-0">
        <h5 class="mt-1 mb-1">No Non Senstive Fields</h5>
      </div>

      <div class="col-md-5 mt-1 mt-md-0" *ngIf="nonSensitiveTableField && nonSensitiveTableField.length>0">
        <h5 class="mt-1 mb-1">Table Fields</h5>
        <div class="piInnerList scroller">
          <ul class="filtMain tablDetailLight">
            <li *ngFor="let item of nonSensitiveTableField">{{item.name}} - {{item.type}}
              <label class="form-check-label" (change)='changeCheckTables(item.name,item.type)'>
                <input type="checkbox" id="Column{{item.tableID}}" name="Column">
              </label>
            </li>
          </ul>
        </div>
        <div>
          <button type="button" class="custbtn mt-2" (click)="addNewData()">Add</button>
        </div>
      </div>
    </div>
  </fieldset>
</div>