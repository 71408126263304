import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';

import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';

import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent  extends BaseComponent implements OnInit {
  public permissionObj: any = [];
  cp = 1;
  ord = true;
  colname: "Theme Name";
  text;
  today: number = Date.now();
  constructor(private master: MasterService,
    
    private ImportFilesDialogService: ImportFilesDialogService
 
  ) { super(); }
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  PermissionLogData() {
    let PermissionLogs = {
      log_type:"Permission Master View" ,
      log_name:"Permission Master View" ,
      log_description:"Permission Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(PermissionLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
}
  ngOnInit() {
    this.getpermission();
    this.PermissionLogData()
  }
  getpermission() {
    let self = this;
    this.master.getPermissions().subscribe(data => {
     // console.log(data);
      self.permissionObj = data;
    }, error => {
    //  console.log(error);
    });
  }
  editpermission(id) {
   // console.log();
    // this.router.navigateByUrl(`/corporate/addnewjourney`,["fragment",]);
    this.router.navigate(['/corporate/addnewpermissions'], { queryParams: { per_id: id } });
  }
  deletepermission(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'ARE YOU SURE YOU WANT To DELETE ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.master.delpermission(obj).subscribe(data => {
          //  console.log(data);
            if (data.status) {
              this.toastrService.success("Deleted Successfully")
              this.getpermission();

            } else {
            }
          }, error => {
           // console.log(error);
            // this.alertService.error(error);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.permissionObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.get_master_permission_fun.per_id === id) {
        return true;
      }
    });
    obj = obj.get_master_permission_fun;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          obj.isactive = !obj.isactive;
          this.master.updatePermission(obj).subscribe(data => {
            this.getpermission()
            this.toastrService.success("Permission Status Changed Successfully");
          });
        }
        // else {
        //   this.master.updatePermission(obj).subscribe(data => {
        //     console.log(data)
        //     this.getpermission()
        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //   });
        // }
      }).catch(() => {
      //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    this.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-permission-import.csv`, 'addingPermissionsBulk')
      .then(data => {
       // console.log(data);
        self.getpermission();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_permission_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}