import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-newtheme',
  templateUrl: './newtheme.component.html',
  styleUrls: ['./newtheme.component.css']
})
export class NewthemeComponent extends BaseComponent implements OnInit {
  theme_name;
  theme_description;
  screenshot: String;
  IsActive;
  them_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey='';
  them_company;
  getCompanyobj;
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;
  correctImage:boolean=true;
  controlEnabled:boolean = true;
  submitted = false;
  themeForm: FormGroup;
  display_event_address:true;
  domainData: any;
  company_id: any;
  journeyDropDown: any;

  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster:ToastrService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.them_id = params.them_id;
        this.companyID = params.companyID;
        this.company_id=this.companyID;
      });
    if (this.them_id) {
      this.UpdateThemeLogData()
    }
    else {
      this.addThemeLogData()
    }
    //get item details using id
    if (this.them_id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.master.getThemeById(this.companyID,this.them_id).subscribe(response => {
        //console.log(response);
        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          this.ngxSpinnerService.hide();
          //console.log(self.themObj)
          self.dataBinding();
        } else {
        }
      });
    }
    if(this.company_id){
      this.getBusinessDomain();
      this.getJourneyDropDown();
    }
    this.themeForm = this.formBuilder.group({
      company: ['', Validators.required],
      themename: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
      themedescription: [''],
      firstActive: [true],
      //themescreenshot: ['', [Validators.required, this.ValidateUrl]]
      themescreenshot: [''],
      display_event_address:[true],
      business_domain_id:[''],
      theme_journey_id:['']
    });
  }
  get f() { return this.themeForm.controls; }
  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.company_id).subscribe(data => {
      self.domainData = data.data.rows;
      //console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  addNewTheme() {
    this.submitted = true;
   // console.log(this.themeForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
   // console.log("themescreenshot -- ", this.themeForm);



    if (this.themeForm.invalid) {
      return;
    }


    let { company, themename, themedescription, themescreenshot,display_event_address,business_domain_id,theme_journey_id } = this.themeForm.value;

    if(this.f.firstActive.value) {

      if(this.f.themescreenshot.value!='') {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.themescreenshot.value);
        //console.log("ssss",pattern)

        if(!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
              }
        }



      if(this.f.themescreenshot.value=='') {
        this.toaster.error("Enter Screenshot URL");
        return;
      }

    }else {


      if(this.them_awskey=='') {
        this.toaster.error("Upload Theme Image");
        return;
      }


    }


    themescreenshot = themescreenshot ? themescreenshot : this.them_awskey;
    let themeObj = {
      name: themename,
      description: themedescription,
      screenshot: themescreenshot,
      isactive: true,
      company: company,
      display_event_address:display_event_address,
      business_domain_id:business_domain_id,
      theme_journey_id:theme_journey_id
    }
  //  console.log("themeObj", themeObj);
    this.ngxSpinnerService.show();
    this.master.addingTheme(themeObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/thememaster`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  addThemeLogData() {
    let themeLogs = {
      log_type: "Adding New Theme",
      log_name: "Adding New Theme",
      log_description: "Adding New Theme",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  UpdateThemeLogData() {
    let themeLogs = {
      log_type: "Updating Theme Data",
      log_name: "Updating Theme Data",
      log_description: "Updating Theme Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
    this.theme_screenshot = this.isUrlValid(this.themObj.theme_screenshot) ? this.themObj.theme_screenshot : this.themObj.theme_screenshot;
    // this.status = true;
    //let setvalue=false;
    //console.log('isUrlValid', this.isUrlValid(this.themObj.theme_screenshot))


    if (this.isUrlValid(this.themObj.theme_screenshot)) {
      this.themeForm = this.formBuilder.group({
        company: [this.themObj.company_id.toString(), Validators.required],
        themename: [this.themObj.theme_name, [Validators.required, Validators.maxLength(50),this.noWhitespaceValidator]],
        themedescription: [this.themObj.theme_description],
        firstActive: [true],
        themescreenshot: [this.themObj.theme_screenshot],
        display_event_address: [this.themObj.display_event_address],
        business_domain_id:[this.themObj.theme_category_id],
        theme_journey_id:[this.themObj.theme_journey_id]
      });
    } else {
      this.themeForm = this.formBuilder.group({
        company: [this.themObj.company_id.toString(), Validators.required],
        themename: [this.themObj.theme_name, Validators.required],
        themedescription: [this.themObj.theme_description],
        firstActive: [false],
        themescreenshot: [''],
        display_event_address: [this.themObj.display_event_address],
        business_domain_id:[this.themObj.theme_category_id],
        theme_journey_id:[this.themObj.theme_journey_id]
      });
      this.them_awskey = this.themObj.theme_screenshot;
      this.screenshot_type = false;
    }
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  updateTheme() {
    this.submitted = true;
    if (this.themeForm.invalid ||  !this.correctImage) {
      return;
    }
    let { company, themename, themedescription, themescreenshot,display_event_address, business_domain_id,theme_journey_id } = this.themeForm.value;

    if(this.f.firstActive.value) {

      if(this.f.themescreenshot.value!='') {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.themescreenshot.value);
        //console.log("ssss",pattern)

        if(!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
              }
        }

      if(this.f.themescreenshot.value=='') {
        this.toaster.error("Enter Screen Shot URL");
        return;
      }

    }else {
      if(this.them_awskey=='') {
        this.toaster.error("Upload Theme Image");
        return;
      }


    }

    themescreenshot = this.isUrlValid(themescreenshot) ? themescreenshot : this.them_awskey ? this.them_awskey : ""
    let themobj = {
      theme_name: themename,
      theme_description: themedescription,
      theme_screenshot: themescreenshot,
      isactive: this.themObj.isactive,
      theme_id: Number.parseInt(this.them_id, 10),
      company: company,
      display_event_address:display_event_address,
      business_domain_id:business_domain_id,
      theme_journey_id:theme_journey_id
    }
   // console.log('themobj', themobj);
    this.ngxSpinnerService.show();
    this.master.UpdateTheme(themobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/thememaster`);
        this.ngxSpinnerService.hide();
      }else {this.ngxSpinnerService.hide();}
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    if(control.value!=""){
      return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
    }
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      this.correctImage=false;
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
      this.correctImage=true;
    }
  }
  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.setThemeForm(params.Key);
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

setThemeForm(key){
  this.them_awskey = key;
  this.themeForm = this.formBuilder.group({
    company: [this.themeForm.value.company, Validators.required],
    themename: [this.themeForm.value.themename, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
    themedescription: [this.themeForm.value.themedescription],
    firstActive: [this.themeForm.value.firstActive],
    themescreenshot: [''],
    display_event_address:[true],
    business_domain_id:[this.themeForm.value.business_domain_id],
    theme_journey_id:[this.themeForm.value.theme_journey_id]
  });
  this.ngxSpinnerService.hide();
}
getCompanyID(id){
  this.company_id=id;
  if (!id) {
    this.domainData = [];
    return;
  }
  this.getBusinessDomain();
  this.getJourneyDropDown();
}
getJourneyDropDown() {
  this.master.getCompanyJourney(this.company_id).subscribe(data => {
    this.journeyDropDown = data;
  }, error => {
    console.log(error);
  });
}
}
