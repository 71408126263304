
import { Component, OnInit, } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AudienceBuilderService } from '../../../services/segmentation/audience-builder.service';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-edit-audience-builder',
  templateUrl: './edit-audience-builder.component.html',
  styleUrls: ['./edit-audience-builder.component.css']
})
export class EditAudienceBuilderComponent extends BaseComponent implements OnInit {


  audienceForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
  audienceAddForm: boolean = true;
  currentUser;
  companyId;
  audId;
  AudienceData;
  subscription: any;
  page: any;

  constructor(

    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private audienceBuilderService: AudienceBuilderService,
    private fb: FormBuilder,
    protected activeRoute: ActivatedRoute,

  ) {
    super();


  }


  ngOnInit() {

    this.audId = localStorage.getItem("editAudienceID");
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.currentUser.admin_customer_id;



      

    if (this.audId) {
      this.getAudienceById();
      console.log("--Edit Audience--");
    }
    this.audienceForm = this.formBuilder.group({
      audience_name: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(80)]],
      audience_desc: [''],
      audienceType: ['segmentation', Validators.required]
    });


    this.activeRoute.queryParams
    .subscribe(params => {
      this.page = params.page;
      if(this.page=='premium_campaign'){
          this.audienceForm.get('audienceType').patchValue('cross-sell');
      }
    });

  }

  get f() { return this.audienceForm.controls; }

  getAudienceById() {

    this.audienceBuilderService.getAUDDataByID(this.companyId, this.audId).subscribe(data => {
      if (data.status && data.data) {

        this.AudienceData = data.data[0];
        this.audienceForm.get('audience_name').patchValue(this.AudienceData.aud_name);
        this.audienceForm.get('audience_desc').patchValue(this.AudienceData.aud_desc);
        this.audienceForm.get('audienceType').patchValue(this.AudienceData.aud_type);
        // if (!this.AudienceData.aud_type) {
        //   this.audienceForm.get('audienceType').patchValue('segmentation');
        // }
        
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);

      this.ngxSpinnerService.hide();
    });

  }

  addBUAudience() {
    this.submitted = true;

    if (this.audienceForm.invalid) {
      return;
    }

    let { audience_name, audience_desc, audienceType } = this.audienceForm.value;




    this.ngxSpinnerService.show();

    let audienceBody = {
      aud_name: audience_name.trim(),
      aud_desc: audience_desc.trim(),
      audienceType: audienceType,
      status: true

    }

    this.audienceBuilderService.addingBUAudience(audienceBody, this.companyId).subscribe(data => {
      if (data.status && data.data.audid) {
        console.log("ssss bbbbb....", data.data.audid)


        if (data.data.audid) {
          localStorage.setItem("editAudienceID", data.data.audid);
          this.toastrService.success("Audience Added Successfully");
          this.router.navigateByUrl('/corporate/audience-builder-create');
        }

        // this.loadTargetAudienceList();

      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }





  updateBUAudience() {
    this.submitted = true;

    if (this.audienceForm.invalid) {
      if (!this.audienceForm.value.audienceType) {
        this.toastrService.error('Please select Audience Type');
      }
      return;
    }

    let { audience_name, audience_desc, audienceType } = this.audienceForm.value;


    this.ngxSpinnerService.show();

    let audienceBody = {
      aud_name: audience_name.trim(),
      aud_desc: audience_desc.trim(),
      audienceType: audienceType,
      status: true

    }


    this.audienceBuilderService.updateBUAudience(audienceBody, this.companyId, this.audId).subscribe(data => {
      if (data.status) {

        this.toastrService.success("Audience updated Successfully");
        let URL;


        localStorage.setItem("editAudienceID", this.audId);

        if (localStorage.getItem("BackStepAudience")) {
          URL = '/corporate/audience-builder-create';
        }
        else if (localStorage.getItem("cloneAudience"))
          URL = '/corporate/clone-audience-builder';
        else {
          URL = '/corporate/audience-builder';

        }


        this.router.navigateByUrl(URL);

      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  loadTargetAudienceList() {

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }



}
