<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-0 p-0">
            <div class="text-center col-12">
              <img class="col-sm-12" src="../../assets/custom/images/margologo250.png" alt="branding logo">
            </div>
            <form class="form-horizontal mt-1" [formGroup]="forgotForm">
              <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                <input type="text" formControlName="username" class="form-control formstyle"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="username"
                  placeholder="Enter Your Username">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback ml-1">
                  <div *ngIf="f.username.errors.required">Username is Required</div>
                  <div *ngIf="f.username.errors.pattern">Special Characters are not allowed</div>
                  <div *ngIf="f.username.errors.minlength">Username must be 6 characters</div>
                </div>
                {{message}}
              </div>
              <div class="" [ngClass]="status ? 'displayblock' : 'displaynone'"
                style="border: 1px solid green;border-radius:10px;padding: 10px;">
                <span [innerHTML]='username_verify' style="font-weight: 900;"></span>
                <!-- text-center -->
              </div>
              <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                <button type="submit" class="custbtn mt-2" (click)="submitUserName()"> Submit </button>
              </div>
              <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                <button type="button" class="custbtn mt-2" (click)="verifyUserName()"> Verify </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>