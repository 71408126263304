import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-role-master',
  templateUrl: './role-master.component.html',
  styleUrls: ['./role-master.component.css']
})
export class RoleMasterComponent extends BaseComponent implements OnInit, AfterViewInit {

  public roleObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "role_name";
  data: any[] = [];
  moduleStatus = false;
  today: number = Date.now();
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  constructor(private master: MasterService,
    public ImportFilesDialogService: ImportFilesDialogService,
  ) {
    super();
   }
  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }
  ngOnInit() {
       
    this.getRoleData();
    this.RoleLogData()
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getRoleData();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getAllRoles(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.roleObj = [] }
          else { this.roleObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.roleObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  RoleLogData() {
    let RoleLogs = {
      log_type:"Role Master View" ,
      log_name:"Role Master View" ,
      log_description:"Role Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(RoleLogs).subscribe(data => {
    //  console.log(data);
      
    }, error => {
     // console.log(error);
    });
  
}
  receiveMessage(event) {
   // console.log("role", event);
    if (event._id && event.name === "delete") {
     // console.log("delete", event);
      this.toastrService.error("You dont't have permission to delete this Role.");
    } else if (event._id && event.name === "edit") {
      // console.log("edit", event);
      this.editRole(event._id);
    } else if (event._id && event.name === "active") {
     // console.log("active", event)
      this.activeStatus(event._id, event.row_obj);
    }
  }
  getRoleData() {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    // let body = {
    //   "pid": 0,
    //   "limit": 50,
    //   "skip": 0
    // }
    this.ngxSpinnerService.show(); 
    this.master.getAllRoles(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      var rolwnewdata = [];
      // var parentdata=roleData.filter( d=>  d.role_parent_id===0 );
      //       if (parentdata.length > 0) {
      //         parentdata.forEach(function (row, callback) {                   
      //           rolwnewdata.push(row);
      //                   var childdata=roleData.filter( d=>  d.role_parent_id===row.role_id );
      //                   if (childdata.length > 0) {
      //                     childdata.forEach(function (childrow, callback) {  
      //                       rolwnewdata.push(childrow);
      //                   });
      //                   }
      //           });
      //       }
      // self.data = rolwnewdata;
      self.roleObj = response.data.rows;
      self.totalCount = response.data.total;
      if (rolwnewdata.length) {
        self.moduleStatus = true;
      }
      this.ngxSpinnerService.hide(); 
    }, error => {
      this.ngxSpinnerService.hide(); 
     // console.log(error);
    });
  }

  editRole(id) {
    this.router.navigate(['/corporate/addnewrolemaster'], { queryParams: { role_id: id } });
  }

  deleteRoleData(id) {

    //Logic to delete the item

    let obj =
    {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show(); 
          this.master.delRole(obj).subscribe(data => {

           // console.log(data);

            if (data.status) {

              this.getRoleData();
              this.toastrService.success("Deleted Successfully")
            } else {
              this.ngxSpinnerService.hide(); 
            }
          }, error => {
            this.ngxSpinnerService.hide(); 
           // console.log(error);
            // this.alertService.error(error);

          });
        }
      }).catch(() =>{
        this.ngxSpinnerService.hide(); 
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });

  }

  activeStatus(row_id, row_obj) {
    //let { active, description, id, name, parentid } = row_obj;
    let obj = row_obj;
   // console.log("*********", obj)
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show(); 
          obj.isactive = ! obj.isactive;
          this.master.updateRole(obj).subscribe(data => {
          //  console.log(data)
            this.getRoleData()
            this.toastrService.success("Role Master Status Changed Successfully");
          });
        }
         else {
          this.ngxSpinnerService.hide(); 
        //   this.master.updateRole(obj).subscribe(data => {
        //     console.log(data)
        //     this.getRoleData()
        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //   });
         }
      }).catch(() => {
        this.ngxSpinnerService.hide(); 
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  updateStatus(event, id) {
   //event.preventDefault();
    let obj = this.roleObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.role_id === id) {
        return true;
      }
    });
    this.activeStatus(id, obj);
  }

  importFile() {
    let self = this;
    this.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-role-import.csv`, 'addingRoleBulk')
      .then(data => {
       // console.log(data);
        self.getRoleData();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_role_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}