<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item active">Journey Master</li>
    </ol>
  </nav>
</div>

<div class="container themeTblReso">

  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText 
          [disabled]="companyID == 0">
      </div>

    </div>
    <div class="col-md-4 mt-3 mt-md-0" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn ml-0 mt-md-1" *ngIf="isWrite" routerLink="/corporate/addnewjourney">
      <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="companyid == 0">
  <div class="row">
  <div class="col-md-6 mt-2">
      <select name="company" class="form-control formstyle" (change)="getCompanyJourney($event.target.value)">
        <option value = 0>Select Company Name</option>
        <ng-container *ngFor="let userObj of getCompanyobj">
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
            {{userObj.customer_company_name}}
          </option>
        </ng-container>
      </select>

  </div>
</div>
</div>

<div class="container mt-2 themeTblReso">
  

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
      <tr>
        <!-- <th>Journey Id</th> -->
        <th>Journey Icon</th>
        <th (click)="sorting('journey_name')" class="curPoint">Journey Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Journey Description</th>

        <!-- <th>Journey Parent Id</th> -->

        <!-- <th>Created By</th> -->
        <th (click)="sorting('createdon')" class="curPoint">
          Created On <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th>Actions</th>

      </tr>
      <tr
        *ngFor="let obj of journeyObj">
        <td><img src="{{obj.journey_icon}}" alt="Not Found" (error)="setValue($event)"
            style="height:50px; max-width:50px;"></td>
        <td>{{obj.journey_name}}</td>
        <td>{{obj.journey_description}}</td>
        <!-- <td>{{obj.createdname}}</td> -->
        <td>{{obj.createdon |date:'mediumDate'}}</td>


        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1 cursor" (click)="editJourney(obj.journey_id)" *ngIf="isEdit" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1 cursor" (click)="deleteJourney(obj.journey_id)" *ngIf="isDelete"
              placement="bottom" ngbTooltip="Delete"></span>

            <mat-slide-toggle [checked]="obj.isactive" class="my-slider cursor" *ngIf="isEdit"
              (click)="updateStatus($event,obj.journey_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>

    <div style="text-align: center;" *ngIf="totalCount === 0">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getJourneyData((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>
    <!-- <pagination-controls *ngIf="(journeyObj | filter:text).length != 0" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->



  </div>