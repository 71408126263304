import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../../../base.component';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';


@Component({
  selector: 'app-new-attributegroup',
  templateUrl: './new-attribute-group.component.html',
  styleUrls: ['./new-attribute-group.component.css']
})
export class NewAttributeGroupComponent extends BaseComponent implements OnInit{

  header = 'Add';
  attrGroupForm: FormGroup;
  attrId;
  attr;
  status = true;
  submitted = false;
  constructor(private segmentService: SegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.attrId = params.attrId;
      });
  }

  ngOnInit() {
    this.attrGroupForm = this.formBuilder.group({
      attrname: ['', Validators.required],
      attrdesc: ['']
    });
    if (this.attrId) {
      this.header = 'Edit';
      this.ngxSpinnerService.show();
      this.segmentService.getAttributeById(this.attrId).subscribe(data => {
      //  console.log('DATA', data);
        if (data.status){
          this.attr = data.data[0];
          this.status = false;
          this.attrGroupForm.get('attrname').patchValue(this.attr.group_name);
          this.attrGroupForm.get('attrdesc').patchValue(this.attr.group_desc);
        } else {
          this.router.navigateByUrl('/corporate/attribute-group');
        }
        this.ngxSpinnerService.hide();

      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
      });
    }
  }

  get f() { return this.attrGroupForm.controls; }

  //   ###############  Add New Attribute Group  ####################  //

  addNewAttrGroup(){
    this.submitted = true;
    if (this.attrGroupForm.invalid) {
      return;
    }
    let attrnm = this.attrGroupForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Group Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'groupname': this.attrGroupForm.value.attrname.trim(),
      'groupdesc': this.attrGroupForm.value.attrdesc.trim(),
      'status': true
    };

    this.segmentService.addAttributeGroup(obj).subscribe(data => {
      if (data.status){
        this.toastrService.success('Attribute Group added successfully');
        this.router.navigateByUrl('/corporate/attribute-group');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });

  }

  //   ##################  Update Attribute Group ####################  //

  updateAttributeGroup(){
    this.submitted = true;
    if (this.attrGroupForm.invalid) {
      return;
    }
    let attrnm = this.attrGroupForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Group Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'groupname': this.attrGroupForm.value.attrname.trim(),
      'groupdesc': this.attrGroupForm.value.attrdesc.trim(),
      'status': this.attr.isactive
    };

    this.segmentService.updateAttributeGroupFields(obj, this.attr.field_group_id).subscribe(data => {
      if (data.status){
        this.toastrService.success('Attribute Group updated successfully');
        this.router.navigateByUrl('/corporate/attribute-group');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

}
