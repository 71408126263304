<div class="container">
    <div class="row">
    <div class="col-12">
        <nav aria-label="breadcrumb" class="breadCust">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="page"><a routerLink="/corporate/metadata-list">MetaData List</a></li>
              <li class="breadcrumb-item active" aria-current="page">MetaData Client Table Relation</li>
            </ol>
          </nav>
    </div>
</div>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="buForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">
                <div class="card mt-1">
                    <div class="card-body">
                        <div *ngIf="customerID === 0">
                            <label for="buForm">BU List <span class="required">*</span>
                            </label>
                            <select autocomplete="off" formControlName="buid" (change)="onBuSelected()"
                                class="form-control formstyle">
                                <option value="">
                                    Select BU
                                </option>
                                <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                                    {{ obj.customer_company_name }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="buTables.length > 0" class="mt-2">
                            <label for="buForm">BU Tables List <span class="required">*</span>
                            </label>
                            <select autocomplete="off" formControlName="selTable" (change)="onBuTableSelected()"
                                class="form-control formstyle">
                                <option value="">
                                    Select BU Table
                                </option>
                                <option *ngFor="let obj of buTables" value="{{obj.table_alias}}">
                                    {{ obj.table_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="container" *ngIf="selectedBuId && selectedTableId && clientTables.length > 0">
    <form class="form-horizontal mt-1">
        <div class="row">
            <div class="col-md-10 col-xl-7">

                <div class="card mt-1">

                    <div class="card-body">


                        <div class="form-group">
                            <label for="clList">Client Tables List</label>
                            <div class="piInnerList scroller" id="clList">
                                <ul class="filtMain">
                                    <li *ngFor="let item of clientTables">
                                        <div class="row">
                                            <div class="col-sm-9">
                                                <label class="form-check-label">
                                                    {{item.table_name}}
                                                </label>
                                            </div>

                                            <div class="col-sm-3">
                                                <input [checked]="clientTableIds.indexOf(item.table_id)>=0"
                                                    type="checkbox"
                                                    (change)="checkUncheckTable($event, item.table_id)">
                                            </div>

                                        </div>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer text-center ">
                        <div class="my_class text-center">
                            <button type="submit" class="custbtn" *ngIf="isWrite"
                                (click)="updateRelation()">SAVE</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-xl-5">
                <div class="card mt-1">

                    <div class="card-body">

                        <div class="form-group">
                            <label for="clList">Selected Tables For Mapping</label>
                            <div class="piInnerList scroller" id="clList">
                                <ul class="filtMain" *ngIf="clientTableIds && clientTableIds.length > 0">
                                    <li *ngFor="let item of clientTableIds; let i = index">
                                        <div class="row">
                                            <div class="col-sm-9">
                                                <label class="form-check-label">
                                                    {{item}}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <p *ngIf="clientTableIds.length === 0">No Table Selected for Mapping</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-1">

                    <div class="card-body">

                        <div class="form-group">
                            <label for="clList">Previously Mapped Tables</label>
                            <div class="piInnerList scroller" id="clList">
                                <ul class="filtMain" *ngIf="client_bu_tables_rel && client_bu_tables_rel.length > 0">
                                    <li *ngFor="let item of client_bu_tables_rel; let i = index">
                                        <div class="row">
                                            <div class="col-sm-9">
                                                <label class="form-check-label">
                                                    {{item.client_tbl_name}}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <p *ngIf="client_bu_tables_rel.length === 0">No Table Mapped</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>