import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
import { Router, CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-customer-subscription',
  templateUrl: './report-customer-subscription.component.html',
  styleUrls: ['./report-customer-subscription.component.css']
})
export class ReportCustomerSubscriptionComponent implements OnInit {
  companyID=62;
  maxdate = new Date();
  dateArr: string[];
  companyId;
  pendingrecord:number=0;
  currentUser;
  company_status:boolean=false;
  cpdata={customer_id:null}; 
  reportData;
  p: number = 1;
  total: number;
  status="select";
  start_date=null;
  end_date=null;
  clicked=false;
  user_type;
  getCompanyobj;
  customerID;
  DsData;   
  obj;
  page=1;
  projectName = environment.projectName
  constructor(private report:ReportService,
    private spinner : NgxSpinnerService,
    private clientService : ClientService,
    public toastrService: ToastrService,
    private router: Router
) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;  
     
    const parmas = {      
      page: this.page,    
      start_date:this.start_date,
      end_date:this.end_date,
      user_type: this.user_type,    
      csv : false
    };
    this.spinner.show();
    this.customersubscriptionlist(parmas);
  }
  
  customersubscriptionlist(body){
   // console.log(body);
    this.report.getCustomerSubscriptionList(body).subscribe((data)=>{
      if(data.message == undefined){
        this.reportData = data.data;
        this.spinner.hide();
      }else{
       // this.toastrService.success(data.message);
        this.reportData = [];
        this.spinner.hide();
      }
     });
  }

  exportReportCsv() {
    this.spinner.show();
    const parmas = {     
      csv : true
    };
    this.report.getCustomerSubscriptionList(parmas).subscribe(dataFile => {
     // console.log(">>>>>", dataFile);
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
        this.spinner.hide();
      }
      this.spinner.hide();
    }, err => {
      this.toastrService.error(err.message);
      this.spinner.hide();
    });
  }
 
  getCompany(companyId:any) {    
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
     if(companyId){
      // let cpdata=0;
      for(var i=0;i<data.data.length;i++){
        if(data.data[i].customer_id==companyId){
         this.cpdata=data.data[i];
        }else{
          self.getCompanyobj = "";
        }
      }}else{
        self.getCompanyobj = data.data;
      }
    }, error => {
     // console.log(error);
    });
  }
  
  gettingReports(page) {
    const parmas = {
      page: page,    
      start_date:this.start_date,
      end_date:this.end_date,
      user_type: this.user_type      
    };    
    if(this.start_date>this.end_date){
    setTimeout(() => {
      this.toastrService.error("Please select fromdate less than todate");
    }, 1000);
    return false;
    }
    this.customersubscriptionlist(parmas); 
  }

  resetReport(){
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;  
     
    const parmas = {      
      page: this.page,    
      start_date:null,
      end_date:null,
      user_type: this.user_type,    
      csv : false
    };
    this.spinner.show();
    this.customersubscriptionlist(parmas);
    this.start_date = "";
    this.end_date= "";
  }
}
