<div class="scroller">
  <div class="wholePop filtMain" *ngIf="message!='update_bulk_product_images'">
    <div class="modal-header d-block dialHead">
      <img src="assets/custom/images/margoLogoIcon.png">
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4 class="modal-title">{{ title }} </h4>
      <!-- {{ message }} -->
      You can download the template <a style="color: red;" href="{{message}}" download>HERE.</a>
      <div class="uploaded" [style.display]="display">
        <i class="far fa-file-pdf"></i>
        <div class="file">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
              [ngClass]="status ? 'bg-success' :'bg-danger'" [style.width]="width">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-2">
      <div class="row">
        <div class="col">
          <div class="upload-btn-wrapper">
            <button class="custbtn">Upload a file</button>
            <input #imageInput type="file" accept=".csv" (change)="processFile($event,imageInput)" class="">
            <i class="fa fa-upload"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="wholePop filtMain" *ngIf="message=='update_bulk_product_images'">
    <div class="modal-header d-block dialHead">
      <img src="assets/custom/images/margoLogoIcon.png">
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4 class="modal-title">{{ title }} </h4>
      <span> Note: Before browse please make sure product name should be match with sku number. </span>

      <div class="uploaded" [style.display]="display">
        <i class="far fa-file-pdf"></i>
        <div class="file">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
              [ngClass]="status ? 'bg-success' :'bg-danger'" [style.width]="width">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-4">
      <div class="row">
        <div class="col">
          <div class="upload-btn-wrapper">
            <button class="custbtn">Upload a file</button>
            <input #imageInput type="file" accept=".csv" (change)="processFile($event,imageInput)" class="">
            <i class="fa fa-upload"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="wholePop filtMain" *ngIf="message=='update_bulk_product_images'">
    <div class="modal-header d-block dialHead">
      <img src="assets/custom/images/margoLogoIcon.png">
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4 class="modal-title">{{ title }} </h4>
      <span> Note: Before browse please make sure product name should be match with sku number. </span>

      <div class="uploaded" [style.display]="display">
        <i class="far fa-file-pdf"></i>
        <div class="file">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
              [ngClass]="status ? 'bg-success' :'bg-danger'" [style.width]="width">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-4">
      <div class="row">
        <div class="col">
          <div class="upload-btn-wrapper">
            <button class="custbtn">Upload Multiple Product</button>
            <input #imageInput type="file" multiple accept="" (click)="imageInput.value = null" (change)="processFiles($event,imageInput)" class="">
            <i class="fa fa-upload"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
