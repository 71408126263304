import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../base.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { MasterService } from 'src/app/services/master/master.service';


@Component({
  selector: 'app-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.css']
})
export class ThemeListComponent extends BaseComponent implements OnInit {
  queryThemeField: FormControl = new FormControl();
  themeData: any = [];
  contact_id;
  contactByIdData;
  query_contact_id;
  selectThemeData;
  theme_id;
  customerID;
  contacttypeid;
  contacttypename;
  contacttypetext;
  type;
  product_id;
  themename;
  theme;

  eventtype;

  topImageAspectRatio_0 = 0.41929824561;
  heightImageAspectRatio_0 = 0.7;
  latestThemeData: any = [];
  finalArray: any = [];
  product_thumb_image;
  // rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  // rskmodellogo = this.ReskinningService.rskModelLogo;
  // userProfileDetails: any;
  eventMeta: any;
  themeLimit = 18;
  themeSkip = 0;
  themeNoOfRecords = 0;
  searchText: any = '';
  isIndependenceDs: boolean = false;
  dataLoad:boolean=false;
  dsBalance:any;
  domainList=[];
  @HostListener('window:popstate', ['$event'])
  selectedDomain='';
  isDisableDomain=false;
  themePreview:any=``;
  userData: any;
  companyId: any;
  header='Add new'
  eventdata: any;
  onPopState(event) {
    // sessionStorage.removeItem('themes_list_data');
    this.modalService.dismissAll();

  }
  constructor(
    private modalService: NgbModal,
    private master: MasterService,
    // public adminone: AdminoneService,
    // private marketFlowService: MarketFlowService,
    // private metaService: MetaService,
    // private ReskinningService: ReskinningService,
    // private contactSegmentService: ContactSegmentService,
    // private clientService:ClientService,
    private sanitized: DomSanitizer,
    // private registerService : RegisterService,
    private shopLiveService : ShopLiveService
  ) {
    super();
    // var name = "Theme List Page View by " + this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    // this.addLogData("Theme List Page View", name);
    // this.isDisableDomain=this.router.url.includes('market/prospect/shop/theme')

    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
  }

  ngOnInit() {
    // this.ngxSpinnerService.show();
    let self = this;

    this.isIndependenceDs = this.userData.is_independent_ds
    this.header = sessionStorage.getItem('eventAction');
    if(this.header=='Update'){
      this.eventdata = JSON.parse(sessionStorage.getItem('eventdata') || "");

    }
    // if (!(sessionStorage.getItem("contactid") && sessionStorage.getItem("market")
    //   && sessionStorage.getItem("contactdata"))) {
    //   let currentURL = this.router.url.replace(/\//g, "_");
    //   let storageData = JSON.parse(localStorage.getItem('HISTORY-SESSIONS'));
    //   sessionStorage.setItem("contactid", storageData[currentURL]['contactid']);
    //   sessionStorage.setItem("contacttypeid", storageData[currentURL]['contacttypeid']);
    //   sessionStorage.setItem("contactdata", storageData[currentURL]['contactdata']);
    //   sessionStorage.setItem("market", storageData[currentURL]['market']);
    //   sessionStorage.setItem("type", storageData[currentURL]['type']);

    //   if (storageData[currentURL]['product_data'])
    //     sessionStorage.setItem("product_data", storageData[currentURL]['product_data']);

    //   if (storageData[currentURL]['parentjourney'])
    //     sessionStorage.setItem("parentjourney", storageData[currentURL]['parentjourney']);

    //   if (storageData[currentURL]['themes_list_data'])
    //     sessionStorage.setItem("themes_list_data", storageData[currentURL]['themes_list_data']);

    //   if (storageData[currentURL]['cohostdata'])
    //     sessionStorage.setItem("cohostdata", storageData[currentURL]['cohostdata']);

    //   if (storageData[currentURL]['eventdata'])
    //     sessionStorage.setItem("eventdata", storageData[currentURL]['eventdata']);

    // }

    // this.type = JSON.parse(sessionStorage.getItem("type"));
    // var data = JSON.parse(sessionStorage.getItem("market"));
    // this.contacttypeid = data.id;
    // this.contacttypename = data.name;
    // this.contacttypetext = data.text;
    // self.contact_id = sessionStorage.getItem("contactid");
    // this.contactByIdData = JSON.parse(sessionStorage.getItem("contactdata"));
    // if (sessionStorage.getItem("product_data")) {
    //   this.product_thumb_image = JSON.parse(sessionStorage.getItem('product_data')).product_image;
    //   this.product_id = sessionStorage.getItem("product_data") ? JSON.parse(sessionStorage.getItem("product_data")).product_catalog_id : 0;
    // }
    // else {
      this.product_id = 0;
    // }
    // var parentjourney = JSON.parse(sessionStorage.getItem("parentjourney"));
    // if (parentjourney != null) {
    //   this.eventtype = parentjourney.eventtype;
    // }
    // if (sessionStorage.getItem('themes_list_data')) {
    //   self.theme_id = JSON.parse(sessionStorage.getItem('themes_list_data')).theme_id;
    // }
    this.getLatestThemeList('','');
    // this.getThemeList('','');
    // this.eventMeta = JSON.parse(sessionStorage.getItem('metaEventData'));

    // this.searchTheme();
    // this.getPointBalance();
    // this.getDomains();
  }

  searchTheme() {
    this.queryThemeField.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.themeSkip = 0;
        this.theme_id = '';
        this.themeData = [];
        this.latestThemeData = [];
        this.ngxSpinnerService.show();
      })).subscribe(query => {
        let val = query.trim();
        this.searchText = val;
        this.getThemeList(this.searchText,this.selectedDomain);
        this.getLatestThemeList(this.searchText,this.selectedDomain);
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
        this.dataLoad = true;
      });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  showConfirm(modalName, modalShow) {

    let self = this;

    if (this.latestThemeData.length > 0) {
      let result = this.themeData;
      this.finalArray = this.themeData.concat(this.latestThemeData);
    }
    else { this.finalArray = this.themeData; }


    this.selectThemeData = this.finalArray.find(element => {
      if (element.theme_id === this.theme_id) {
        return true;
      }
    });

    if (modalShow) {

      if( parseInt(this.dsBalance) < this.selectThemeData?.totalPoints){
        // this.clientService.openBalancePoput(this.dsBalance);
        return;
      }
      self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.sendtopreviewpage(true);
      }, (reason) => {

        if (reason && reason != 0) {
          this.saveCampaign(modalShow);
        }
      });
    } else {
      self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.sendtopreviewpage(false);

      }, (reason) => {
        if (reason == 'no') {
          sessionStorage.removeItem("message");
        }
        if (reason && reason != 0) {
          sessionStorage.setItem('themes_list_data', JSON.stringify(self.selectThemeData));
          self.router.navigate(['/directseller/market/' + self.contacttypename + '/' + this.type.name + '/confirmCampaign'])
        }
      });
    }
  }
  sendtopreviewpage(isSave = true) {
    this.selectThemeData.isSave = isSave;
    sessionStorage.setItem('themes_list_data', JSON.stringify(this.selectThemeData));

    // this.router.navigate([this.basedsurl + 'market/' + this.contacttypename + '/' + this.type.name + '/personalMessage']);
  }
  saveCampaign(confirmBox) {
    this.ngxSpinnerService.show();
    let self = this;
    var data = JSON.parse(sessionStorage.getItem("market"));
    // this.marketFlowService.contactByIdData = this.contactByIdData;
    let marketorder = {
      contact_id: self.contact_id,
      contact_type_id: data.id,
      product_id: this.product_id,
      journey_id: this.type.id,
      theme_id: self.theme_id,
      message: "",
      // company_name: this.loginUserData.customer_company_name
    }
    // this.marketFlowService.MarkertOrder = marketorder;
    var cohost;
    if (sessionStorage.getItem("cohostdata")) {
      cohost = JSON.parse(sessionStorage.getItem("cohostdata"));
    }
    let eventHostCohostRel = {
      contact_id: this.contact_id,
      event_id: 0,
      host_flag: 1,
      isActive: true,
      cohost: cohost
    }
    // this.marketFlowService.EventHostCohostRelData = eventHostCohostRel;
    var objEvent = JSON.parse(sessionStorage.getItem("eventdata"));
    if (objEvent) {
      objEvent.event_type_id = this.type.id;
      objEvent.theme_id = this.theme_id;
      objEvent.hostid = this.contact_id;
      // this.marketFlowService.EventData = objEvent;
      // this.marketFlowService.createevent().subscribe(result => {

      //   var data;
      //   if (!Array.isArray(result)) {
      //     data = result;
      //   }
      //   else {
      //     data = result[1];
      //   }
      //   if (data.status) {
      //     /*  Adding Invitees in Bulk to newly created Event */
      //     let inv = JSON.parse(sessionStorage.getItem('bulkInvitees'));
      //     if (inv && inv?.length > 0) {
      //       this.addBulkInvitees(inv, this.contactByIdData.contact_id);
      //     }
      //     this.saveMetaFields();
      //     sessionStorage.clear();
      //     //self.showConfirm(confirmBox, true);
      //     self.modalService.open(confirmBox, { ariaLabelledBy: 'modal-basic-title' });
      //     this.ngxSpinnerService.hide();
      //   } else {
      //     this.ngxSpinnerService.hide();
      //     if (data.message) {
      //       this.toastrService.error(data.message);
      //     }
      //   }

      // }, error => {
      //   this.ngxSpinnerService.hide();
      //   console.log(error);
      // });

    }
    else {
      // this.marketFlowService.createmarketorder().subscribe(data => {
      //   if (data.status) {
      //     sessionStorage.clear();
      //     self.modalService.open(confirmBox, {
      //       ariaLabelledBy: 'modal-basic-title',
      //       backdrop: 'static',
      //       keyboard: false,
      //     });
      //     this.ngxSpinnerService.hide();
      //   } else {
      //     this.ngxSpinnerService.hide();
      //   }

      // });
    }
  }

  getThemeList(searchQuery,domain) {
    let offset = this.themeSkip * this.themeLimit;
    this.ngxSpinnerService.show();
    // this.master.getThemes(this.product_id, this.type.id, this.themeLimit, offset, 'createdon', 'desc', searchQuery,7,domain).subscribe(data => {
    //   this.themeNoOfRecords = 0;
    //   if (data.status) {
    //     if (data.data.rows == null) { this.themeData = [...this.themeData] }
    //     else { this.themeData = [...this.themeData, ...data.data.rows] };

    //     this.themeData = this.themeData.map(element => {
    //       var totalPoints=0;
    //       element.total_joruney.forEach(item =>{
    //         totalPoints += item.total * item.point;

    //       })
    //       //console.log("xxxxxxx",totalPoints);
    //       element.totalPoints = totalPoints;
    //       return element;

    //     });
    //     //console.log("ddddd",this.themeData)


    //     this.themeNoOfRecords = data.data.total;

    //   } else {
    //     //this.themeData = [];
    //     // this.themeNoOfRecords = 0;
    //   }
    //   this.ngxSpinnerService.hide();
    // }, error => {
    //   console.log(error);
    //   this.ngxSpinnerService.hide();
    // });
  }

  getLatestThemeList(searchQuery,domain) {
    this.ngxSpinnerService.show();
    this.shopLiveService.getThemeAssosciationBycidpid(this.companyId,this.product_id,126, 18,0).subscribe(data => {
      if (data.status) {
        this.latestThemeData = data.data;
        if(this.eventdata && this.header=='Update'){
          this.selectTheme(this.eventdata?.event_theme_id);
        }
      } else {
        this.latestThemeData = [];
      }

      // this.latestThemeData = this.latestThemeData.map(element => {
      //   var totalPoints=0;
      //   element.total_joruney.forEach(item =>{
      //     totalPoints += item.total * item.point;

      //   })
      //   //console.log("xxxxxxx",totalPoints);
      //   element.totalPoints = totalPoints;
      //   return element;

      // });
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  showThemes(modalName, selectedTheme) {

    let self = this;

    self.query_contact_id = self.contact_id;
    this.theme_id = selectedTheme
    this.themename = selectedTheme.theme_name;
    this.theme = selectedTheme.theme_screenshot;
    self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

      //   sessionStorage.setItem('themes_list_data', JSON.stringify(this.selectThemeData));
      // this.router.navigate(['/directseller/market/' + this.contacttypename + '/' + this.type.name + '/personalMessage']);
    }, (reason) => {
      console.log("reason", reason);
    });
  }


  getLibraryData(modelName) {

    this.ngxSpinnerService.show();
    this.modalService.open(modelName, { ariaLabelledBy: 'modal-basic-title', windowClass: 'theme-list-model' });
    this.ngxSpinnerService.hide();

  }
  getLibraryDataNew(modelName: any, item: any) {
    this.themePreview = ``;
    let payload = {
      theme_id: item?.theme_id,
      journey_id: item?.theme_journey_id,
      product_id: this.product_id,
      contact_id: this.contact_id
    }
    this.ngxSpinnerService.show();
    // this.master.previewThemeTemplate(payload).subscribe({
    //   next: (res: any) => {
    //     this.themePreview = this.sanitized.bypassSecurityTrustHtml(res?.data);
    //     this.modalService.open(modelName, {size:'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'theme-list-model' });
    //     this.ngxSpinnerService.hide();
    //   },
    //   error: (err: any) => {
    //     this.ngxSpinnerService.hide();
    //     this.toastrService.error('Something went wrong!')
    //   }
    // })
  }
  onImageLoad(evt, overlapImage: any, type) {
    console.log("overlapImage", overlapImage);
    if (overlapImage) {
      console.log("Enter inside", overlapImage);
      let element = evt.srcElement;
      let topImageAspectRatio_0 = 0.41929824561;
      let heightImageAspectRatio_0 = 0.61578947368;
      if (type == 0) {
        overlapImage.style.width = element.clientWidth + 'px';
        overlapImage.style.height = Math.round((heightImageAspectRatio_0) * (element.clientWidth)) + 'px';
        overlapImage.style.top = Math.round(topImageAspectRatio_0 * (element.clientWidth - 10)) + 'px';
        overlapImage.style.left = (element.offsetLeft - 1) + 'px';
      } else {
        overlapImage.style.width = element.clientWidth + 'px';
        overlapImage.style.height = ((.3447 * element.clientHeight) + 1) + 'px';
        overlapImage.style.top = (.164 * element.clientHeight) + 'px';
        overlapImage.style.left = 0 + 'px';
      }
      overlapImage.style.opacity = 1;
    }



  }

  //  ###############  Select Theme on Click ##########  //

  selectTheme(id) {
    this.theme_id = id;
  }

  //   ############### Save Meta Fields for Events ##########  //

  saveMetaFields() {
    if (!this.eventMeta) {
      return;
    }
    // this.metaService.updateProfileMetaFields(this.loginUserData.user_customer_id, 4, this.marketFlowService.EventHostCohostRelData.event_id, this.eventMeta).subscribe(data => {
    //   if (data.status) {

    //   }
    //   else {
    //   }
    // }, error => {

    //   console.log(error);
    // });
  }

  /*  Add Bulk Invitees To Event */

  addBulkInvitees(arr, id) {
    let invt = [];
        for (let cn of arr) {
            let v = {
                "invitee_name": cn.con?.contact_fname + " " + cn.con?.contact_lname,
                "invitee_email": cn.con?.contact_email,
                "contact_id": cn.con?.contact_id
            }
            invt.push(v);
        }
        let udata = JSON.parse(localStorage.getItem("UserData"));
        // let body =
        // {
        //     "eventData": {
        //         "event_id": this.marketFlowService.EventHostCohostRelData.event_id,
        //         "ds_id": udata.user_ds_id,
        //         "host_id": id,
        //         "customer_company_logo": udata.customer_company_logo
        //     },
        //     "inviteeData": invt
        // }
        // this.contactSegmentService.addInviteesToEvent(body).subscribe(result => {
        //     this.ngxSpinnerService.hide();
        //     if (result.status) {

        //     } else {
        //         this.toastrService.error('Cannot add Invitees at the moment');
        //     }

        // }, error => {
        //     console.log(error);
        // });
  }

  onThemeListScrolled() {
    if (this.themeNoOfRecords < this.themeLimit) {
      return;
    } else {
      this.themeSkip++;
    }
    this.getThemeList(this.searchText,this.selectedDomain);
  }

  //   ###############  On Destroy   ###################  //

  ngOnDestroy() {
    this.modalService.dismissAll();
  }
  getPointBalance() {
    // this.clientService.getDsBalance().subscribe({
    //   next: (res: any) => {
    //     if (res?.status) {
    //       this.dsBalance = Number(res?.data?.totalFunds);
    //     }
    //   },
    //   error: (err: any) => {
    //     console.log(err)
    //   }
    // })
  }
  getDomains(){
    // this.registerService.getUserDomains().subscribe(
    //   (data) => {
    //     if (data.status) {
    //       this.domainList=data.data;
    //     } else {
    //       this.domainList=[]
    //     }
    //   },
    //   (error) => {
    //     this.domainList=[]
    //     console.error(error);
    //   }
    // )
  }

  domainChange(event:any){
    this.themeData = [];
    this.latestThemeData = [];

    this.getLatestThemeList(this.searchText, this.selectedDomain);
    this.getThemeList(this.searchText, this.selectedDomain);
  }
  navigateToPreview(){

    let self = this;

    if (this.latestThemeData.length > 0) {
      let result = this.themeData;
      this.finalArray = this.themeData.concat(this.latestThemeData);
    }
    else { this.finalArray = this.themeData; }


    this.selectThemeData = this.finalArray.find(element => {
      if (element.theme_id === this.theme_id) {
        return true;
      }
    });
       sessionStorage.setItem('themes_list_data', JSON.stringify(this.selectThemeData));
       this.router.navigateByUrl('/corporate/event-preview')
  }
  onCancel(){
    sessionStorage.removeItem('eventdata');
    sessionStorage.removeItem('contactdata');
    sessionStorage.removeItem('themes_list_data');
    this.router.navigateByUrl('/corporate/events');
  }
}
