<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Form Builder</li>
    </ol>
  </nav>
  <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mb-1" routerLink="/corporate/cb-form-builder">
  <input type="button" value="Clone" *ngIf="isWrite" class="custbtn ml-0 mb-1" routerLink="/corporate/clone-cb-form-builder">
  <!-- <input type="button" value="Hero Image Update" *ngIf="isWrite" class="custbtn ml-0 mb-1" routerLink="/corporate/product-clone-cb-form-builder"> -->

<div class="row mb-1">
  <div class="col-md-4 mt-2" *ngIf="companyCheck==0">
    <div>
      <select name="company" class="form-control formstyle"  (change)="getCompanyThemes($event.target.value)">
        <option value=0>--Select Company Name--</option>
          <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
      </select>
    </div>
  </div>

  <div class="col-md-4 mt-2">
    <div>
    
      <select name="company" class="form-control formstyle" [(ngModel)]="themeId"  (change)="getCompanyJourneys()">
        <option value="">Select Theme Name</option>
          <option *ngFor="let userObj of themeObj" value={{userObj.theme_id}}>
              {{userObj.theme_name}}
          </option>
      </select>
    </div>
  </div>

  <div class="col-md-4 mt-2">
    <div>
      <select name="company" class="form-control formstyle" [(ngModel)]="journeyId" (change)="changeJourney()">
        <option value="all">Select Journey Name</option>
        <option value="all">All Journey</option>
          <option *ngFor="let userObj of journeyObj" value={{userObj.journeyid}}>
              {{userObj.journey_name}}
          </option>
      </select>
    </div>
  </div>

</div>

<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
          <tr>
      <!-- <th>Theme Id</th> -->
      <th>Theme Image</th>
      <th style="cursor: pointer;" (click)="setOrder('d.journey_name')">Journey name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th style="cursor: pointer" (click)="setOrder('c.name')">Template name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>      
      
      <th style="cursor: pointer;  width: 140px;" (click)="setOrder('a.createdon')">Created on <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th style="cursor: pointer; width: 140px">Modified on <i aria-hidden="true"></i></th>

      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>
    <!-- (click)="openImage(obj?.theme_screenshot)" -->
    <tr
      *ngFor="let obj of formData">
      <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->
      <td ><img src="{{obj?.theme_screenshot}}" alt="Not Found" (error)="setValue($event)"
          style="height:50px;"></td>
      <td>{{obj?.journey_name}}</td>
      <td>{{obj?.template_name}}</td>       
     
      <td>{{obj?.createdon | date:'mediumDate' }}</td>
      <td>{{obj?.modifiedon | date:'mediumDate' }}</td>

      <td >
        <div class="actBtn">

          <i class="fa fa-info-circle mr-1 linkForce" (click)="previewForm(formPreviewModel,obj)" placement="bottom"
          ngbTooltip="Preview"></i>
          <i *ngIf="isEdit || isDelete" class="fa fa-edit mr-1 linkForce" (click)="editTemplate(obj.ttfaid, obj.templateid,obj.journeyid)" placement="bottom"
            ngbTooltip="Edit"></i>
          <i  *ngIf="isEdit || isDelete" class="fa fa-trash mr-1 linkForce" (click)="deleteTemplate(obj.ttfaid)" placement="bottom"
            ngbTooltip="Delete"></i>
         
        </div>
      </td>
    </tr>

  </table>
  <div style="text-align: center;" *ngIf="formData?.length === 0">
    <p>No Result Available</p>
  </div>

  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="formList(cp=$event)" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination> 
  </div>

  
</div>
<ng-template  #formPreviewModel let-modal role="dialog" style="z-index:999999;" data-keyboard="false" data-backdrop="static">
 
 <div class="modal-body"> 
  <!-- <img src="{{formChildData}}" alt="Not Found" (error)="setValue($event)"> -->
  <app-cb-form-preview [childFormData]="formChildData" ></app-cb-form-preview>
</div>


</ng-template>