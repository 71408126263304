import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audience-campaign-builder',
  templateUrl: './audience-campaign-builder.component.html',
  styleUrls: ['./audience-campaign-builder.component.css']
})
export class AudienceCampaignBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
