import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.css']
})
export class AddSubscriptionComponent extends BaseComponent implements OnInit {

  customer_id;
  addSubscriptionForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    this.addSubscriptionForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      slug_title: ['',[Validators.maxLength(50),Validators.required]],
      subscription_type: ['monthly', Validators.required],
      allocate_point:['',Validators.required],
      price:['',Validators.required],
      gst_rate:['',Validators.required],
      half_yearly_discount:[''],
      yearly_discount:[''],
      orderno:[''],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],
      self_referral_point:['',Validators.required],
      ds_referral_point:['',Validators.required]
    });
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f() { return this.addSubscriptionForm.controls; }
  addNewSubscriptionData(){
    this.submitted = true;
    if (this.addSubscriptionForm.invalid) {
      return;
    }
    let { selectedCompany } = this.addSubscriptionForm.value;
    this.masterService.addSubscriptionCategory(this.addSubscriptionForm.value,selectedCompany).subscribe(data=>{
      if (data.status) {
        this.toastrService.success("Subscription added successfully");
        this.router.navigateByUrl(`/corporate/subscription-list`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.toastrService.error(error.message);
    });
  }

}
