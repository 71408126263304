<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Content Builder Template Section</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">


  <div class="row">
    <div class="col-md-4">
   
    
      <select name="company" class="form-control formstyle"  (change)="getCompanyTemplate($event.target.value)">
        <option value="">Company Name</option>
          <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
      </select>
      
 

  </div>
    <div class="col-md-4">
   
    
      <select name="company" class="form-control formstyle"  (change)="getTemplateSection($event.target.value)">
        <option value="">Template Name</option>
          <option *ngFor="let userObj of themeObj" value={{userObj.templateid}}>
              {{userObj.name}}
          </option>
      </select>
      
  

  </div>
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Associate New Section" *ngIf="isWrite" class="custbtn ml-0 float-md-right mt-md-1 mt-0" routerLink="/corporate/add-cb-ts-association">
         <!--  <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
          <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
        </button>
        <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
          <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
        </button>--> 
      </div>
</div>



  <div class="row">
    <div class="col-md-7">
      <h5 class="mt-2 mb-2">Associated Sections</h5>     
      <div class="piInnerList scroller">
      <ul class="filtMain">
        <li *ngFor="let item of sectionObj">
          <div class="row">
            <div class="col-sm-9">
            
                {{item.section_name}}
             
            </div>
          </div>
        
        </li>
        <li *ngIf="sectionObj?.length == 0" class="text-sm-center">No Result Available</li>
      </ul>
    </div>
     
    </div>
    <div class="col-md-5 mt-5" *ngIf="image"> 
      <img style="width:170px" src="{{image}}">
    </div>  
  </div>
