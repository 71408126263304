<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/cb-ts-association">Template Section Association</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Template Sections</li>

        </ol>
    </nav>
</div>

<div class="container">
    <div class="row">

    <div class="col-md-7">
    <form class="form-horizontal mt-1" [formGroup]="templateForm">
        <div class="card mt-1">
    
            <div class="card-body">
            <div class="form-group">
                <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                <select formControlName="company" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)="getCompanyTemplate($event.target.value)">
                    <option value="">Select Company Name</option>
                    <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                </select>
                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Company Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleForm2">Template Name <span style="color:red">*</span></label>
                <select formControlName="tid" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.tid.errors }" (change)="getTemplateSection($event.target.value)">
                    <option value="">Select Template</option>                    
                    <option *ngFor="let userObj of themeObj" value={{userObj.templateid}}>
                        {{userObj.name}}
                    </option>
                </select>
                <div *ngIf="submitted && f.tid.errors" class="invalid-feedback">
                    <div *ngIf="f.tid.errors.required">Template Name is required</div>
                </div>
            </div>
            <!-- <div class="mt-1">
                <label for="exampleForm2">Section Name <span style="color:red">*</span></label>
                <select formControlName="sid" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.sid.errors }">
                    <option *ngFor="let userObj of sectionObj" value={{userObj.sectionid}}>
                        <input type="checkbox" (change)="selectSections($event, userObj.sectionid)">
                        {{userObj.section_name}}
                    </option>
                </select>
                <div *ngIf="submitted && f.sid.errors" class="invalid-feedback">
                    <div *ngIf="f.sid.errors.required">Section Name is required</div>
                </div>
            </div> -->
            <div class="form-group" *ngIf="sectionObj?.length > 0">
            <label for="exampleForm2">Section Name <span style="color:red">*</span></label>
            <div class="piInnerList scroller">
                <ul class="filtMain">
                  <li *ngFor="let item of sectionObj">
                    <div class="row">
                      <div class="col-sm-9">
                        <label class="form-check-label">
                          {{item.section_name}}
                        </label>
                      </div>
                      
                      <div class="col-sm-3">
                        <input type="checkbox"  [checked]="selectedSections.indexOf(item.sectionid)>=0"  (change)="selectSections($event, item.sectionid)">
                      </div>
                      
                    </div>
                  
                  </li>
                </ul>
            </div>
            
        </div>
        </div>
        <div class="card-footer text-center ">
            <div class="my_class text-center">
                <button type="submit" class="custbtn" (click)="addTemplate()">Submit</button>
            </div>
          </div>
    </div>
    </form>
</div>
    <div class="col-md-5 mt-5" *ngIf="image"> 
        <img style="width:250px" src="{{image}}">
      </div> 
      </div> 
</div>