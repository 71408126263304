import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
let httpParams = new HttpParams();

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  public baseUrl;


  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/auth/';
  }
  checkLogin2() {
    return this.http.get<any>(this.baseUrl + "get-events-list")
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  checkLogin(value, mcConnection) {
    var body = value;
    let URL = this.baseUrl + 'login';
    if (mcConnection) {
      URL += '?mc=1';
    }
    return this.http.post<any>(URL, body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError)
      );
  }
  getUserDetails() {
    return this.http.get<any>(this.baseService.baseUrl1 + "ds/user/getUserDetails", this.baseService.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  checkForgotPassword(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "forgotpassword", body, httpOptions)
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  resetPasswordService(value, body) {
    // var body = value;
    return this.http.post<any>(`${this.baseUrl}resetPassword/${value}`, body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError)
      );
  }

  forgotUserNames(value) {
    let body = value;
    return this.http.post<any>(`${this.baseUrl}forgotUserName`, body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  setUserNameService(value, token) {
    let body = value;
    return this.http.post<any>(`${this.baseUrl}resetUserName/${token}`, body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  verifyUserName(value, token) {
    return this.http.post<any>(`${this.baseService.baseUrl1}api/user/checkUserNameExists?token=${token}`, value)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
logout()
{
  localStorage.removeItem("accesToken");
  localStorage.removeItem('currentUser');
  sessionStorage.removeItem('userData');
  localStorage.removeItem('bucompanyid');
  localStorage.removeItem('bucompanyname');
}

/** reset paswword for ds user */
checkForgotPasswordDs(value) {
  var body = value;
  return this.http.post<any>(this.baseUrl + "ds/forgotpassword", body, httpOptions)
     .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  catchError(this.handleError));
}
verifyToken(token) {
  return this.http.get<any>(`${this.baseService.baseUrl1}api/auth/render/setPassword/${token}`)
  .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  catchError(this.handleError));
}
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}

