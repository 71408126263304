<div class="scroller">
  <div class="wholePop filtMain">
    <div class="modal-header">
      <!-- <h4 class="modal-title">{{ title }}</h4> -->
       <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button> 
    </div>
    <div class="modal-body">
      <h4 class="modal-title">{{title}}</h4>
      <div> </div>
      <img style="width:170px" src="{{message}}">
    </div>
    <hr>
    <div class="modal-footer">
      <!-- <button type="button" class="btn vl custbtn" (click)="decline()">{{ btnCancelText }}</button> -->
    </div>
  
  </div>

</div>
