import { Component, Inject, OnInit, Renderer2, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../../_services/navbar.service';
import { ThemeSettingsService } from '../../settings/theme-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { MenuSettingsService } from '../../settings/menu-settings.service';
import { isArray } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import { RegisterService } from '../../../services/users/register.service';
import {ChatService} from 'src/app/services/chat/chat.service';

@Component({

  selector: 'app-verticalnav',
  templateUrl: './verticalnav.component.html',
  styleUrls: ['./verticalnav.component.css'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: '1',
      })),
      state('hide', style({
        opacity: '0',
        height: '*',
      })),
      transition('show => hide', animate('200ms ease-in-out')),
      transition('hide => show', animate('200ms ease-in-out'))
    ])
  ]
})
export class VerticalnavComponent implements OnInit {
  child: any;
  insideTm: any;
  outsideTm: any;
  private _themeSettingsConfig: any;
  public _menuSettingsConfig: any;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  updateNotification$;
  disconnect$;
  public tempSessionCount=0;

  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    private _registerService:RegisterService,
    private _renderer: Renderer2,
    private router: Router,
    private adminone: AdminoneService,
    public chatService:ChatService,
    private ngZone: NgZone) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();


  }
  public menu: any = [];
  ngOnInit() {

    this.chatService.getNotification();
    this.updateNotification$ = this.chatService.notification.subscribe((value) => {

      if(value.type===1){
      //  console.log('**************updateNotification if****',value);
        this.tempSessionCount = value.notification>0?value.notification:0;
      }else{
      //  console.log('**************updateNotification if****',value);
        this.tempSessionCount += 1;
      }

    })
    this.disconnect$=this.chatService.disconnect.subscribe(()=>{
       this.unsubscribe();
    })

    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });

    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {

        //if (localStorage.getItem('typeofuser') === "mukesh.k@aditatechnologies.com") {
          this.menu = [{
            title: 'Home',
            icon: 'bi bi-house-fill',
            // icon: 'assets/custom/images/homeNav.png',
            page: '/corporate/home',
            color: '#94CC00',
            // badge: { type: 'badge-danger', value: '1.0' }
          },
          {
            title: 'Clients',
            icon: 'bi bi-people-fill',
            // icon: 'assets/custom/images/2.png',
            page: '/corporate/clients',
            color: '#006DA7',
          },
          {
            title: 'Users',
            icon: 'bi bi-person-badge',
            // icon: 'assets/custom/images/3.png',
            page: '/corporate/usertypes',
            color: '#8C4684',
            // isExternalLink: true
          },
          {
            title: 'Roles',
            icon: 'bi bi-briefcase-fill',
            // icon: 'assets/custom/images/roles_v.png',
            page: '/corporate/roles',
            color: '#DE003B',
            // isExternalLink: true,
          },
          {
            title: 'Audience Builder',
            icon: 'fa fa-users',
            // icon: 'assets/custom/images/audienceBuilImg.png',
            page: '/corporate/audience-builder',
            color: '#006DA7',
            // isExternalLink: true,
          },
          {
            title: 'Campaign Builder',
            icon: 'bi bi-megaphone-fill',
            // icon: 'assets/custom/images/1.png',
            page: '/corporate/campaign-builder',
            color: '#8C4684',
            // isExternalLink: true,
          },
          {
            title: 'Premium Campaign',
            icon: 'bi bi-card-checklist',
            // icon: 'assets/custom/images/1 – 10.png',
            page: '/corporate/replenishment-and-cross-sell',
            color: '#DE003B',
            // isExternalLink: true,
          },

          {
            title: 'Master Module',
            icon: 'fa fa-puzzle-piece',
            // icon: 'assets/custom/images/MasterModulesImg.png',
            page: '/corporate/master',
            color: '#F7A00E',
            // isExternalLink: true,
          },
          {
            title: 'Readiness Module',
            icon: 'fa fa-puzzle-piece',
            // icon: 'assets/custom/images/MasterModulesImg.png',
            page: '/corporate/readiness',
            color: '#F7A00E',
            // isExternalLink: true,
          },
          {
            title: 'Content Builder',
            icon: 'bi bi-grid-1x2-fill',
            // icon: 'assets/custom/images/contentBuilderImg.png',
            page: '/corporate/contentbuilder',
            color: '#F7A00E',
            // isExternalLink: true,
          },
          {
            title: 'Reports',
            icon: 'bi bi-card-checklist',
            // icon: 'assets/custom/images/6.png',
            page: '/corporate/reports',
            color: '#94CC00',
            // isExternalLink: true,
          },
          {
            title: 'PII & DSAR Module',
            icon: 'fa fa-cubes',
            // icon: 'assets/custom/images/PiiContentImg.png',
            page: '/corporate/pi',
            color: '#006DA7',
            // isExternalLink: true,
          },

          {
            title: 'Logs',
            icon: 'bi bi-journal-bookmark-fill',
            // icon: 'assets/custom/images/logsImg.png',
            page: '/corporate/logs',
            color: '#8C4684',
            // isExternalLink: true,
          },

          {
            title: 'Support',
            icon: 'fa fa-handshake-o',
            // icon: 'assets/custom/images/1-9.jpg',
            page: '/corporate/supportuser',
            color: '#DE003B',
            // isExternalLink: true,
          },


          {
            title: 'Messenger',
            icon: 'fa fa-comments',
            // icon: 'assets/custom/images/remastermodules/6_1.jpg',
            page: '/corporate/messenger',
            color: '#8DCE17',
            // isExternalLink: true,
          },
          {
            title: 'Manage Event',
            icon: 'fa fa-user',
            // icon: 'assets/custom/images/Icon.jpg',
            page: '/corporate/events',
            color: '#DE003B',
            // isExternalLink: true,
          },
          {
            title: 'Webinar Module',
            icon: 'fa fa-camera',
            // icon: 'assets/custom/images/Icon.jpg',
            page: '/corporate/webinar',
            color: '#DE003B',
            // isExternalLink: true,
          },
          {
            title: 'Subscription',
            icon: 'fa fa-camera',
            // icon: 'assets/custom/images/Icon.jpg',
            page: '/corporate/subscriptionModule',
            color: '#DE003B',
            // isExternalLink: true,
          },
          {
            title: 'Meta Data',
            icon: 'bi bi-file-earmark-bar-graph',
            page: '/corporate/metadata-list',
            // isExternalLink: true,
          },
          {
            title: 'Facebook Module',
            icon: 'fa fa-facebook-square',
            // icon: 'assets/custom/images/remastermodules/fb.jpg',
            page: '/corporate/fb',
            color: '#2C66A5',

            // isExternalLink: true,
          },

          {
            title: 'Sender Profile',
            icon: 'fa fa-user',
            // icon: 'assets/custom/images/Icon.jpg',
            page: '/corporate/sender-profile',
            color: '#DE003B',
            // isExternalLink: true,
          },



          {
            title: 'Segmentation',
            icon: 'bi bi-diagram-2-fill',
            // icon: 'assets/custom/images/1 – 10.png',
            page: '/corporate/segmentation',
            color: '#DE003B',
            // isExternalLink: true,
          },


          {
            title: 'Social Assets',
            icon: 'fa  fa-commenting-o',
            // icon: 'assets/custom/images/remastermodules/fb.jpg',
            page: '/corporate/mb',
            color: '#2C66A5',

            // isExternalLink: true,
          },
          {
            title: 'Shop Live',
            icon: 'bi bi-people-fill',
            // icon: 'assets/custom/images/1-9.jpg',
            page: '/corporate/shop-live',
            color: '#DE003B',

            // isExternalLink: true,
          },

          {
            title: 'Schedule Journey',
            icon: 'fa fa-rocket',
            page: '/corporate/schedule-journey-list',
            color: '#DE003B',
            // isExternalLink: true,
          },


          {
            title: 'Master Module',
            icon: 'fa fa-puzzle-piece',
            // icon: 'assets/custom/images/Icon.jpg',
            page: '/corporate/master',
            color: '#DE003B',
            // isExternalLink: true,
          },

          {
            title: 'Readiness Module',
            icon: 'fa fa-puzzle-piece',
            // icon: 'assets/custom/images/MasterModulesImg.png',
            page: '/corporate/readiness',
            color: '#F7A00E',
            // isExternalLink: true,
          },


          {
            title: 'MC',
            icon: 'assets/custom/images/1–19.png',
            page: '/corporate/mc-information',
            // isExternalLink: true,
          },
          {
            title: 'License',
            icon: 'fa fa-id-card-o',
            // icon: 'assets/custom/images/license_v.png',
            page: '/corporate/license',
            color: '#94CC00',
            // isExternalLink: true,
          },
          // {
          //   title: ' Products',
          //   icon: 'assets/custom/images/5.png',
          //   page: '/corporate/margoproducts',
          //   // isExternalLink: true,
          // },

         /* {
            title: 'Einstein Dashboard',
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT0M7tu3hyLOVbq3Pd0pwd1HOmTpEmqMMhDYg&usqp=CAU',
            page: '/corporate/einstein-dashboard',
            // isExternalLink: true,
          },
         */







          // {
          //   title: 'Community',
          //   icon: 'fa fa-comments',
          //   // icon: 'assets/custom/images/remastermodules/6_1.jpg',
          //   page: '/corporate/messenger',
          //   color: '#8DCE17',
          //   // isExternalLink: true,
          // },
          {
            title: 'Ext Co. Seller BU',
            icon: 'fa fa-industry',
            // icon: 'assets/custom/images/remastermodules/6_1.jpg',
            page: '/corporate/ext-company-seller-bu',
            color: '#DE003B',
            // isExternalLink: true,
          }
          ];

          console.log("--before filter--", this.menu);
        this.menu=  this._registerService.filterdata(this.menu,this._registerService.RoleActionsDataByUser.value);

        console.log("Role actions", this._registerService.RoleActionsDataByUser.value);
        console.log("--after filter--", this.menu);

      //  console.log("ssss",this.menu)
        this._menuSettingsConfig = {
          horizontal_menu: {
          },
          vertical_menu: {
            items: this.menu
          }
        };
      });
    // TODO Patch to reset menu after login
    this.resetMainMenu();
    this.setActiveRouteInNavbar();

  }

  resetMainMenu() {
    const nodes = this.document.getElementById('main-menu-navigation').childNodes;
    for (let i = 0; i < nodes.length; i++) {
      this.resetCollapseMenu(nodes[i]);
    }
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['hover'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['isOpen'] = false;
      this.resetSubmenuItems(this._menuSettingsConfig.vertical_menu.items[i]);
    }
  }

  resetCollapseMenu(element) {
    if (element.classList && element.classList.contains('has-sub') && element.classList.contains('open')) {
      element.classList.remove('hover');
      element.classList.remove('menu-collapsed-open');
    }
  }

  resetSubmenuItems(parentItem) {
    if (parentItem['submenu'] &&
      parentItem['submenu']['items'] &&
      parentItem['submenu']['items'].length > 0) {
      parentItem['isOpen'] = false;
      for (let j = 0; j < parentItem['submenu']['items'].length; j++) {
        parentItem['submenu']['items'][j]['isSelected'] = false;
        this.resetSubmenuItems(parentItem['submenu']['items'][j]);
      }
    }
  }

  refreshView() {
    const mainMenuElement = document.getElementsByClassName('main-menu');
    if (mainMenuElement && mainMenuElement.length > 0) {
      if (this._themeSettingsConfig.colorTheme === 'semi-light' || this._themeSettingsConfig.colorTheme === 'light') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-dark');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-light');
      } else if (this._themeSettingsConfig.colorTheme === 'semi-dark' || this._themeSettingsConfig.colorTheme === 'dark') {
        this._renderer.addClass(mainMenuElement.item(0), 'menu-dark');
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-light');
      }
      if (this._themeSettingsConfig.layout.pattern === 'static') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-fixed');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-static');
      } else if (this._themeSettingsConfig.layout.pattern === 'fixed') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-static');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-fixed');
      }
    }
  }

  setActiveRouteInNavbar() {
    console.log("set active menu", this._menuSettingsConfig.vertical_menu.items.length);
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      if (!this._menuSettingsConfig.vertical_menu.items[i].submenu &&
        this._menuSettingsConfig.vertical_menu.items[i].page === this.router.url) {
        this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
        break;
      } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu) {
        // Level 1 menu
        for (let j = 0; j < this._menuSettingsConfig.vertical_menu.items[i].submenu.items.length; j++) {
          if (!this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu &&
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].page === this.router.url) {
            this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
            this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;
            break;
          } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu) {
            // Level 2 menu
            for (let k = 0; k < this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items.length; k++) {
              if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k].page === this.router.url) {
                this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;

                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].isOpen = true;
              }
            }
          }
        }
      }
    }
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu['isOpen'] = false;
        menu['isActive'] = false;
        menu['hover'] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu['isOpen'] = false;
          menu['isActive'] = false;
          menu['hover'] = false;
          menu.submenu.items[j]['isOpen'] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu &&
        menu.page === this.router.url) {
        menu['isOpen'] = value;
        menu['isActive'] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu['isOpen'] = value;
            menu['isActive'] = value;
            menu.submenu.items[j]['isOpen'] = value;
            menu.submenu.items[j]['isActive'] = value;
            break;
          }
        }
      }
    }
  }

  callFunction(event, child, isSubmenuOfSubmenu) {
    const methodName = event.methodName;
    if (this[methodName]) {
      // method exists on the component
      const param = event.methodParam;
      if (!isArray(param)) {
        this[methodName](param); // call it
      } else {
        this[methodName](param[0], param[1]); // call it
      }
    }
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    child['isSelected'] = true;
  }

  setTheme(theme) {
    this._themeSettingsService.config = {
      colorTheme: theme, // semi-light, semi-dark
    };
  }

  setLayout(layout) {
    this._themeSettingsService.config = {
      layout: {
        pattern: layout
      }
    };
  }

  fixComponent(component, value) {
    if (component === 'header') {
      this._themeSettingsService.config = {
        header: value
      };
    } else if (component === 'footer') {
      this._themeSettingsService.config = {
        footer: value
      };
    } else {
      this._themeSettingsService.config = {
        header: value,
        footer: value
      };
    }
  }

  /**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');

    // check if the left aside menu is fixed
    if (!navBar.classList.contains('expanded')) {
      this._renderer.addClass(navBar, 'expanded');
      this._renderer.addClass(mainMenu, 'expanded');
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');
    if (navBar && navBar.classList.contains('expanded')) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, 'expanded');
          this._renderer.removeClass(mainMenu, 'expanded');
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  resetOtherActiveMenu(selectedChild, isSubmenuOfSubmenu) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['hover'] = false;
      this.handleSubmenuItems(this._menuSettingsConfig.vertical_menu.items[i], selectedChild, isSubmenuOfSubmenu);
    }
  }

  handleSubmenuItems(parentItem, selectedChild, isSubmenuOfSubmenu) {
    if (selectedChild['title'] === 'Horizontal') {
      localStorage.setItem('currentLayoutStyle', AppConstants.LAYOUT_STYLE_HORIZONTAL);
      window.location.reload();
    } else if (selectedChild['title'] === 'Vertical') {
      localStorage.setItem('currentLayoutStyle', AppConstants.LAYOUT_STYLE_VERTICAL);
      window.location.reload();
    } else if (parentItem['submenu'] &&
      parentItem['submenu']['items'] &&
      parentItem['submenu']['items'].length > 0) {
      if (parentItem.title !== selectedChild.title && parentItem['isOpen'] === true && !isSubmenuOfSubmenu &&
        this._themeSettingsConfig.navigation === AppConstants.NAVIGATION_TYPE_COLLAPSIBLE) {
        parentItem['isOpen'] = false;
      }
      for (let j = 0; j < parentItem['submenu']['items'].length; j++) {
        if (selectedChild.page !== 'null') {
          parentItem['submenu']['items'][j]['isSelected'] = false;
        }
        this.handleSubmenuItems(parentItem['submenu']['items'][j], selectedChild, isSubmenuOfSubmenu);
      }
    } else if (parentItem.title !== selectedChild.title && !selectedChild.submenu
      && this._themeSettingsConfig.navigation === AppConstants.NAVIGATION_TYPE_COLLAPSIBLE
      && parentItem['isOpen'] === true) {
      parentItem['isOpen'] = false;
    }
  }

  toggleMenu(event, child, isSubmenuOfSubmenu) {

    const toggle = document.getElementById('sidenav-overlay');
    const target = event.target || event.srcElement || event.currentTarget;
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    if (child['isSelected'] === true) {
      child['isSelected'] = false;
    } else {
      child['isSelected'] = true;
    }

    if (child['hover'] === true) {
      child['hover'] = false;
    } else {
      child['hover'] = true;
    }

    if (child['isOpen'] === true) {
      child['isOpen'] = false;
    } else {
      child['isOpen'] = true;
    }

    if (this.router.url !== '') {
      this._renderer.addClass(toggle, 'd-none');
      this._renderer.removeClass(toggle, 'd-block');
    }
  }
  ngOnDestroy(): void {

   // console.log("sssss")

    this.unsubscribe();
  }
  unsubscribe(){
    this.updateNotification$.unsubscribe();
    this.disconnect$.unsubscribe();
  }

}
