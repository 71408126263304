import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { CommonMessages, ClientMessages } from 'src/app/shared/messages/messages.enum';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-clientexpanded',
  templateUrl: './clientexpanded.component.html',
  styleUrls: ['./clientexpanded.component.css']
})
export class ClientexpandedComponent extends BaseComponent implements OnInit {
  customerDropDownId;
  journeyDropDownId;
  themeDropDownId;

  companyObj;
  ThemeAssociationObj;
  ThemeObj;
  journeyObj;
  productObj;
  companyProductObj;
  companyEventTypeObj;
  companyJourneyAssetObj;

  client_id;
  clientDataSingle_g;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;
  public isCollapsed7 = true;
  public isCollapsed8 = true;


  CustomerId;
  JourneyId = "";
  ThemeId = "";
  isactive = true;


  CustomerThemeId;
  Themeid;
  name;
  ProductId: any = 0;


  CustomerJourneyId;
  Journeyid;
  journeyname;
  companyId;

  themeLimit = 10;
  themeSkip = 0;
  themePage = 1;
  themeTotal: number = 0;

  assocLimit = 10;
  assocSkip = 0;
  assocPage = 1;
  assocTotal: number = 0;

  journeyLimit = 10;
  journeySkip = 0;
  journeyPage = 1;
  journeyTotal: number = 0;

  productLimit = 10;
  productSkip = 0;
  productPage = 1;
  productTotal: number = 0;

  shopProductList: any = 0;
  showProduct:boolean = false;
  constructor(
    private clientsService: ClientService,
    private master: MasterService,
  ) { super(); }

  ngOnInit() {
    let self = this;

    self.journeyList();
    self.getCustomerDropDown();

    // self.getCompanyData()

    if (localStorage.getItem("updatedClientId")) this.client_id = localStorage.getItem("updatedClientId")

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.companyId = customerInfo.uid;

    this.clientsService.getClientById
    if (self.client_id) {

      self.getThemeDropDown(self.client_id);
      self.getJourneyDropDown(self.client_id);
      self.themeList();
      self.getClientById(self.client_id);
      //self.themeClientList(self.client_id);
      self.themeAssociationList();
      self.companyProductList();
      self.companyEventTypeList(self.client_id);
      self.companyJourneyAssetList(self.client_id);
      self.onShopSelectProductList();
    }
  }

  getClientById(client_id_local) {
    this.clientsService.getClientById(client_id_local).subscribe(clientDataSingle => {
      if (clientDataSingle.status) {
        this.clientDataSingle_g = clientDataSingle.data;
      } else {
      }
    }, err => {
      console.error(err);
    })
  }

  getCustomerDropDown() {
    let self = this;
    this.clientsService.getCustomerId().subscribe(data => {
      self.customerDropDownId = data.data;
      // console.log(self.customerDropDownId);
    }, error => {
      //  console.log(error);
    });
  }

  getJourneyDropDown(id) {
    let self = this;
    this.master.getCompanyJourney(id).subscribe(data => {
      self.journeyDropDownId = data;
      console.log('self.journeyDropDownId', self.journeyDropDownId);
    }, error => {
      //  console.log(error);
    });
  }

  getThemeDropDown(id) {
    let self = this;
    this.master.getCompanyTheme(id).subscribe(data => {
      self.themeDropDownId = data.data.rows;
       console.log('self.themeDropDownId', self.themeDropDownId);
    }, error => {
      //  console.log(error);
    });
  }

  themeList() {
    this.ngxSpinnerService.show();
    this.themeSkip = (this.themePage - 1) * this.themeLimit;
    this.master.getCompanyTheme(this.client_id, this.themeLimit, this.themeSkip, 'theme_name', 'asc').subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.ThemeObj = [] }
        else { this.ThemeObj = data.data.rows };
        this.themeTotal = data.data.total;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
    });
  }

  themeClientList(client_id) {
    let self = this;
    this.clientsService.getThemeListClient(client_id).subscribe(data => {
      // self.ThemeObj = data.data;
      //  console.log(self.ThemeObj);

    }, error => {
      //  console.log(error);
    });
  }

  themeAssociationList() {
    let self = this;
    this.ngxSpinnerService.show();
    self.assocSkip = (this.assocPage - 1) * this.assocLimit;
    self.clientsService.getThemeAssociation(this.client_id, this.assocLimit, this.assocSkip, 'journey_name', 'asc').subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.ThemeAssociationObj = [] }
        else { self.ThemeAssociationObj = data.data.rows; }
        self.assocTotal = data.data.total;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
    });
  }


  journeyList() {
    let self = this;
    this.ngxSpinnerService.show();
    self.clientsService.getJourneyList().subscribe(data => {
      if (data.status) {
        self.journeyObj = data.data;
      } else {
        self.journeyObj = [] 
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
    });
  }

  addnewThemeAssociation() {
    if (this.JourneyId == "" || this.ThemeId == "") {
      this.toastrService.error("Please select journey  and theme");
      return true;
    }
    // if (this.JourneyId == "70" && this.ProductId == 0) {
    //   this.toastrService.error("Please select product name")
    // }
    if (this.showProduct && this.ProductId == 0) {
      this.toastrService.error("Please select product name")
    }
    else {
      let themeassobj = {
        customerId: this.client_id,
        journeyId: this.JourneyId,
        themeId: this.ThemeId,
        isActive: this.isactive,
        productid: this.ProductId,
        company: this.client_id,
      }
      // console.log(themeassobj);

      this.clientsService.addingNewThemeAssociation(themeassobj).subscribe(data => {
        //  console.log(data);
        if (data.status) {
          this.themeAssociationList();
          this.toastrService.success("Added Successfully")
          this.ThemeId = "";
          this.JourneyId = "";
          this.ProductId = 0;
        } else {
          this.toastrService.error(data.message);
        }
      }, error => {
        // console.log(error);
      });
    }
  }

  addnewTheme() {
    let themeobj = {
      customerId: this.CustomerThemeId,
      themeId: this.Themeid,
      name: this.name,
      isActive: this.isactive
    }
    this.ngxSpinnerService.show();
    this.clientsService.addingNewTheme(themeobj).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.show();
        this.toastrService.success("Added successfully");
        this.themeList()
      }else {
        this.ngxSpinnerService.show();
      }

    }, error => {
      // console.log(error);
    });
  }

  addingNewJourney() {
    let Journeyobj = {
      customerId: this.CustomerJourneyId,
      journeyId: this.Journeyid,
      name: this.journeyname,
      isActive: this.isactive
    }
    // console.log(Journeyobj);

    this.clientsService.addingNewJourney(Journeyobj).subscribe(data => {
      // console.log(data);
      if (data.status) {
        this.toastrService.success("Added successfully")
        this.journeyList()
      }

    }, error => {
      //  console.log(error);
    });
  }


  companyProductList() {
    let self = this;
    this.productSkip = (this.productPage - 1) * this.productLimit;
    this.ngxSpinnerService.show();
    this.clientsService.getCompanyProductList(this.client_id, this.productLimit, this.productSkip).subscribe(data => {
      //self.companyProductObj = data.data;
      this.ngxSpinnerService.hide();
      if (data.status) {
        if (data.data.rows == null) { self.companyProductObj = [] }
        else { self.companyProductObj = data.data.rows; }
        self.productTotal = data.data.total;
      }

    }, error => {
      //console.log(error);
    });
  }

  onShopSelectProductList() {
    let self = this;
    this.ngxSpinnerService.show();
    this.clientsService.shopJourneyCompanyProductList(this.client_id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        self.shopProductList = data.data;
      }
    }, error => {
    });
  }
  
  companyEventTypeList(id) {
    let self = this;
    this.clientsService.getCompanyEventTypeList(id).subscribe(data => {
      self.companyEventTypeObj = data.data;
      //  console.log(self.journeyObj);
    }, error => {
      //  console.log(error);
    });
  }

  companyJourneyAssetList(id) {
    this.journeySkip = (this.journeyPage - 1) * this.journeyLimit;
    this.ngxSpinnerService.show();
    this.clientsService.getcompanyJourneyAssetList(id, this.journeyLimit, this.journeySkip).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        if (data.data.rows == null) { this.companyJourneyAssetObj = [] }
        else { this.companyJourneyAssetObj = data.data.rows; }
        this.journeyTotal = data.data.total;
      }
    }, error => {
      // console.log(error);
    });
  }

  applySelectFilter(event) {
    // if (event.value != 70) {
    //   this.ProductId = 0;
    // }
    let journeyEle = this.journeyDropDownId.find((ele: any) => {
      return  ele.journey_id == this.JourneyId;
    })
    
    if (journeyEle && journeyEle.isproduct == true) {
      this.showProduct = true;
    } 
    else{
      this.ProductId = 0;
      this.showProduct = false;
    }
  }
}