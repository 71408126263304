<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/events">Manage Event</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/add-event">{{header}} event</a></li>
        <li class="breadcrumb-item active" aria-current="page">Event Theme</li>
      </ol>
  </nav>
</div>

<div class="container-fluid mt-1 mt-md-0 theme-list-wraper">
    <div class="row">
      <div class="col-sm-12 col-md-12">
      <h4 class="font-weight-bold mainHeading_1" *ngIf="contactByIdData">Please select a theme for
          {{contactByIdData.contactname | titlecase}}</h4>
      </div>
     </div>
     <!-- <div class="row">
        <div class="col-md-6">
          <div class="searchDropNw tablCl flex-fill px-1" >
            <select autocomplete="off" class="custom-select border-trns">
              <option selected value="0">Select All</option>
              <option value="2">Customer</option>
              <option value="3">Prospect</option>
              <option value="4">Customer/Host</option>
              <option value="5">Prospect/Host</option>
            </select>
  
          </div>
        </div>
        <div class=" col-md-6 searchComRg">
          <section class="srchMain">
            <div class="srcInpt searchIcon">
              <input type="text" class="form-control ng-pristine ng-valid ng-touched" placeholder="Search"
                [formControl]="queryThemeField">
            </div>
          </section>
  
      </div>
    </div>  -->
    <div class="row mt-1">
      <div class="col-md-7">
        <div class="searchHeader">
  
          <div class="row" >
            <div class="searchDropNw tablCl flex-fill px-1" *ngIf="!isDisableDomain">
              <select autocomplete="off" class="custom-select border-trns"
              (change)="domainChange($event)" [(ngModel)]="selectedDomain" >
                <option selected value=''>All Business Domains</option>
                <option [value]="domain?.id" *ngFor="let domain of domainList">{{domain?.domain_name}}</option>
              </select>
  
            </div>
            <div class="searchComRg tablCl flex-fill px-1">
              <section class="srchMain">
                <div class="srcInpt searchIcon">
                  <input type="text" class="form-control ng-pristine ng-valid ng-touched" placeholder="Search"
                    [formControl]="queryThemeField">
                </div>
              </section>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  
    <div class="row">
      <div class="col-12 mb-3" *ngIf="latestThemeData &&  latestThemeData.length>0">
        <h5 class="mt-1 subTitleHead_3">Added new this week</h5>
        <div class="row">
          <div class="dashTblMbl">
            <div class="top col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let item of latestThemeData">
              <div class="card mb-1" [ngClass]="theme_id === item.theme_id ? 'selected' : ''">
                <div class="card-content text-center" aria-expanded="true" (click)="selectTheme(item.theme_id)">
                  <div class="card-body themeImgHt p-1">
                    <div class="imgThemeCentr themeImglarge">
  
                      <!-- <span *ngIf="product_thumb_image && isIndependenceDs===false">
                        <img src="{{item.theme_screenshot}}"
                          (load)="onImageLoad($event,overlapImage,0)" (error)="setValue($event)"
                          title="Click here to preview the theme" />
                      </span>
                      <span *ngIf="product_thumb_image && isIndependenceDs">
                        <img src="{{item.theme_screenshot}}"
                          (error)="setValue($event)"
                          title="Click here to preview the theme" />
                      </span>
                      <span *ngIf="!product_thumb_image">
                        <img src="{{item.theme_screenshot}}" (error)="setValue($event)"
                          title="Click here to preview the theme" />
                      </span> -->
                      <span>
                        <img src="{{item.theme_screenshot}}"
                          (error)="setValue($event)"
                          title="" />
                      </span>
  
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center pb-1 pt-1">
                  {{item.theme_name}}
                 <br>
                  <!-- <a class="" (click)="getLibraryData(libraryModalPoint)">Total Points: {{item.totalPoints}}
                  </a> -->
                  <a class="mt-1" (click)="getLibraryData(libraryModal)">
                    Quick View
                  </a>
                </div>
              </div>
  
              <ng-template #libraryModal let-modal>
                <div class="scroller">
                  <div class="wholePop filtMain">
                    <div class="modal-header dialHead w-100">
                      <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                      <h4 class="modal-title">{{item.theme_name}}</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body ">
                      <div class="card mb-2 mb-md-0">
                        <div class="card-header scrlCont" id="container">
                          <div class="imgThemeCentr">
  
                            <!-- <span *ngIf="product_thumb_image">
                              <img class="w-100" src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </span> -->
  
                            <span *ngIf="product_thumb_image && isIndependenceDs===false">
                              <img src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImage,0)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                                <img  class="overlap-image" src="{{product_thumb_image}}" #overlapImage (error)="setValue($event)">
                            </span>
                            <span *ngIf="product_thumb_image && isIndependenceDs">
                              <img src="{{item.theme_screenshot}}"
                                 (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </span>
  
                            <span *ngIf="!product_thumb_image">
                              <img class="w-100" src="{{item.theme_screenshot}}"
                              (error)="setValue($event)" title="Click here to preview the theme" />
                            </span>
                            <!-- <img class="overlap-image" src="{{product_thumb_image}}" #overlapImagemodal
                              (error)="setValue($event)"> -->
  
  
  
  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
  
              <ng-template #libraryModalPoint let-modal>
                <div class="scroller">
                  <div class="wholePop filtMain">
                    <div class="modal-header dialHead w-100 d-block">
                      <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title">{{item.theme_name}}</h4>
  
                    </div>
                    <div class="modal-body">
                      <div class="card mb-2 mb-md-0 position-relative">
                        <div class="card-header scrlCont" id="container">
  
                            <!-- <span *ngIf="product_thumb_image">
                              <img class="w-100" src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </span> -->
  
  
                            <div class="card-body scroller">
                              <div class="dashTblMbl filtMain">
                                <table class="contact_table" cellpadding="0" cellspacing="0" border="0"
                                width="100%" class="table-striped">
                                  <tr>
                                    <th>Type</th>
                                    <th>Total</th>
                                    <th>Point</th>
  
                                    </tr>
                                  <tr *ngFor="let itemPoint of item.total_joruney">
                                    <td> {{itemPoint.name}} </td>
                                    <td> {{itemPoint.total}}</td>
                                    <td> {{itemPoint.point}}</td>
  
                                  </tr>
  
                                </table>
  
                              </div>
                            </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
                            <!-- <img class="overlap-image" src="{{product_thumb_image}}" #overlapImagemodal
                              (error)="setValue($event)"> -->
  
  
                        </div>
  
                        <!-- <div class="card-header scrlCont" id="container">
                  <img src="{{item.theme_screenshot}}" (error)="setValue($event)" width="100%"/>
                </div> -->
  
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
  
              <!-- <div class="text-center">
              <label class="custRadio">
                <input type="radio" id="customRadio1" [(ngModel)]="theme_id" name="selectedTheme"
                  [checked]="theme_id === item.theme_id" [value]="item.theme_id">
                <span class="radiomark"></span>
              </label>
  
            </div> -->
            </div>
          </div>
  
        </div>
      </div>
      <div class="col-12" *ngIf="themeData &&  themeData.length>0">
        <h5 class="mt-1 subTitleHead_3">All</h5>
        <div class="row">
          <div class="dashTblMbl" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onThemeListScrolled()" [scrollWindow]="true">
            <div class="top col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let item of themeData">
              <div class="card mb-1" [ngClass]="theme_id === item.theme_id ? 'selected' : ''">
                <div class="card-content text-center position-relative" aria-expanded="true"
                  (click)="selectTheme(item.theme_id)">
                  <div class="card-body themeImgHt p-1 emailtheme">
                    <div class="imgThemeCentr themeImglarge">
                      <div class="theme-preview">
                        <span (click)="getLibraryDataNew(libraryModalNew,item)" class="preview-btn">Preview Email Template</span>
                      </div>
                      <span *ngIf="product_thumb_image && isIndependenceDs===false">
                        <img src="{{item.theme_screenshot}}"
                          (load)="onImageLoad($event,overlapImage,0)" (error)="setValue($event)"
                          title="Click here to preview the theme" />
                          <!-- <img  class="overlap-image" src="{{product_thumb_image}}" #overlapImage (error)="setValue($event)"> -->
                      </span>
                      <span *ngIf="product_thumb_image && isIndependenceDs">
                        <img src="{{item.theme_screenshot}}"
                           (error)="setValue($event)"
                          title="Click here to preview the theme" />
                      </span>
  
                      <!-- <span *ngIf="product_thumb_image">
                        <img src="{{item.theme_screenshot}}"
                          (load)="onImageLoad($event,overlapImage,0)" (error)="setValue($event)" title="" />
                      </span> -->
  
                      <span *ngIf="!product_thumb_image">
                        <img src="{{item.theme_screenshot}}" (error)="setValue($event)"
                          title="" />
                      </span>
  
                    <!-- <img class="overlap-image" src="{{product_thumb_image}}" #overlapImage (error)="setValue($event)"> -->
  
                    </div>
                  </div>
  
                </div>
                <div class="card-footer text-center pb-1 pt-1">
                  <div class="">
  
                     {{item.theme_name}} <br>
                     <!-- <a class="" (click)="getLibraryData(libraryModalPoint)">Total Points: {{item.totalPoints}}
                    </a> -->
                  </div>
                  <div class="">
                    <a class="" (click)="getLibraryDataNew(libraryModalNew,item)">
                      Quick View
                    </a>
                  </div>
                </div>
              </div>
  
              <ng-template #libraryModal let-modal>
                <div class="scroller">
                  <div class="wholePop filtMain">
                    <div class="modal-header dialHead w-100 d-block">
                      <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title">{{item.theme_name}}</h4>
  
                    </div>
                    <div class="modal-body">
                      <div class="card mb-2 mb-md-0 position-relative">
                        <div class="card-header scrlCont" id="container">
                          <div class="imgThemeCentr imgAlign">
                            <!-- <span *ngIf="product_thumb_image">
                              <img class="w-100" src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </span> -->
  
                            <div class="text-center" *ngIf="product_thumb_image && isIndependenceDs===false">
                              <img class="theme-img" src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImage,0)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                                <img  class="overlap-image" src="{{product_thumb_image}}" #overlapImage (error)="setValue($event)">
                            </div>
                            <div class="text-center" *ngIf="product_thumb_image && isIndependenceDs">
                              <img class="theme-img" src="{{item.theme_screenshot}}"
                                 (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </div>
  
                            <div class="text-center" *ngIf="!product_thumb_image">
                              <img class="theme-img" class="w-100" src="{{item.theme_screenshot}}"
                                (error)="setValue($event)" title="Click here to preview the theme" />
                            </div>
  
                            <!-- <img class="overlap-image" src="{{product_thumb_image}}" #overlapImagemodal
                              (error)="setValue($event)"> -->
  
                            </div>
                        </div>
  
                        <!-- <div class="card-header scrlCont" id="container">
                  <img src="{{item.theme_screenshot}}" (error)="setValue($event)" width="100%"/>
                </div> -->
  
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>            
  
              <ng-template #libraryModalPoint let-modal>
                <div class="scroller">
                  <div class="wholePop filtMain">
                    <div class="modal-header dialHead w-100 d-block">
                      <!-- <img src="assets/custom/images/margoLogoIcon.png"/> -->
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title">{{item.theme_name}}</h4>
  
                    </div>
                    <div class="modal-body">
                      <div class="card mb-2 mb-md-0 position-relative">
                        <div class="card-header scrlCont" id="container">
  
                            <!-- <span *ngIf="product_thumb_image">
                              <img class="w-100" src="{{item.theme_screenshot}}"
                                (load)="onImageLoad($event,overlapImagemodal,1)" (error)="setValue($event)"
                                title="Click here to preview the theme" />
                            </span> -->
  
  
                            <div class="card-body scroller">
                              <div class="dashTblMbl filtMain">
                                <table class="contact_table" cellpadding="0" cellspacing="0" border="0"
                                width="100%" class="table-striped">
                                  <tr>
                                    <th>Type</th>
                                    <th>Total</th>
                                    <th>Point</th>
  
                                    </tr>
                                  <tr *ngFor="let itemPoint of item.total_joruney">
                                    <td> {{itemPoint.name}} </td>
                                    <td> {{itemPoint.total}}</td>
                                    <td> {{itemPoint.point}}</td>
  
                                  </tr>
  
                                </table>
  
                              </div>
                            </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
                            <!-- <img class="overlap-image" src="{{product_thumb_image}}" #overlapImagemodal
                              (error)="setValue($event)"> -->
  
  
                        </div>
  
                        <!-- <div class="card-header scrlCont" id="container">
                  <img src="{{item.theme_screenshot}}" (error)="setValue($event)" width="100%"/>
                </div> -->
  
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
  
  
              <ng-template #libraryModalNew let-modal>
                <div class="scroller">
                  <div class="wholePop filtMain">
                    <div class="modal-header dialHead w-100 d-block">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title">{{item.theme_name}}</h4>
              
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 col-sm-12">
                          <div class="msg-content" [innerHtml]="themePreview">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container mt-2 mb-2" *ngIf="theme_id">
        <div class="row totalPriceFix">
          <div class="col-12 text-center">
            <button class="btn btn-margo-action" (click)="navigateToPreview()">PREVIEW</button>
            <button type="button" (click)="onCancel()" class="btn btn-round btn-margo-cancel">Cancel</button>
            <!-- <button class="btn btn-margo-action" (click)="showConfirm(confirmBox,null)">PREVIEW</button> -->
            <!-- <button class="btn btn-margo-action" (click)="showConfirm(confirmBoxditals,success)">1-CLICK SEND</button> -->
          </div>
        </div>
      </div>
  
  
      <ng-template #confirmBoxditals let-modal>
        <div class="modal-header dialHead w-100 pb-1 d-block text-center">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />
          <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
  
          <h5 class="text-uppercase text-center">WOULD YOU LIKE TO ADD A QUICK PERSONAL MESSAGE?</h5>
        </div>
        <div class="modal-footer d-block text-center border-0">
          <button type="button" class="btn btn-margo-action btn-round" (click)="modal.close('Cross click')">YES</button>
          <button type="button" class="btn btn-margo-cancel btn-round" (click)="modal.dismiss('no')">NO</button>
        </div>
      </ng-template>
      <ng-template #confirmBox let-modal>
  
        <div class="modal-header dialHead w-100 pb-1 text-center d-block">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />
          <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
        <div class="modal-body ">
          <h5 class="text-center">WOULD YOU LIKE TO ADD A QUICK PERSONAL MESSAGE?</h5>
        </div>
        <div class="modal-footer text-center d-block border-0">
          <button type="button" class="btn btn-margo-action btn-round" (click)="modal.close('Cross click')">YES</button>
          <button type="button" class="btn btn-margo-cancel btn-round" (click)="modal.dismiss('no')">NO</button>
  
        </div>
      </ng-template>
  
      <ng-template #success let-modal>
        <div class="scroller">
          <div class="wholePop filtMain">
            <div class="modal-header dialHead w-100">
              <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
              <!-- <h4 class="modal-title">Quick View</h4> -->
              <button type="button" class="close" aria-label="Close" routerLink="/directseller/market"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
  
            <div class="modal-body text-center">
              <ng-container *ngIf="contacttypename==='teammember'">
                <h5 *ngIf="contactByIdData && eventtype==='training'" class="text-uppercase text-center">TRAINING CAMPAIGN
                  SENT TO {{contactByIdData.contactname | uppercase}} </h5>
                <h5 *ngIf="contactByIdData && type.name==='encouragement'" class="text-uppercase text-center">
                  ENCOURAGEMENT CAMPAIGN SENT TO {{contactByIdData.contactname | uppercase}} </h5>
              </ng-container>
              <ng-container *ngIf="contacttypename==='host'">
                <h5 *ngIf="contacttypename==='host'" class="text-center text-uppercase">Campaign launched to your host
                </h5>
              </ng-container>
              <ng-container *ngIf="contacttypename==='prospect' || contacttypename==='customer' ">
                <h5 *ngIf="contactByIdData && type.name==='shop'" class="text-uppercase text-center">CAMPAIGN LAUNCHED TO
                  {{contactByIdData.contactname | uppercase}}</h5>
                <h5 *ngIf="contactByIdData && type.name==='host'" class="text-uppercase text-center">CAMPAIGN LAUNCHED TO
                  {{contactByIdData.contactname | uppercase}}</h5>
                <h5 *ngIf="contactByIdData && type.name==='join'" class="text-uppercase text-center">CAMPAIGN LAUNCHED TO
                  {{contactByIdData.contactname | uppercase}}</h5>
              </ng-container>
            </div>
            <div class="modal-footer text-center d-block border-0">
              <!-- <button type="button" class="btn btn-margo-cancel btn-round" (click)="modal.dismiss('Cross click')">No</button> -->
              <button type="button" class="btn btn-margo-action btn-round" routerLink="/directseller/market"
                (click)="modal.dismiss('Cross click')">OK</button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="text-center mt-1" *ngIf="themeData.length==0 && latestThemeData.length==0">
      <div class="col-12 text-center ">
      No Records Available
      </div>
    </div>
  </div>
  