<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Theme Template Association</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search." aria-label="Search"
          [formControl]="queryContactField"  [readonly]="templateObj == null || templateObj.length == 0">
      </div>

    </div>


    <div class="col-md-6 mt-2 mt-md-0" style="justify-content: right;">
      <input type="button" value="Add New" class="custbtn mt-md-1 ml-0" routerLink="/corporate/add-cb-theme-template-association">
      <input type="button" value="Clone" class="custbtn" routerLink="/corporate/clone-cb-theme-template-association">
      <!-- <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button> -->
      <!-- <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>-->
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2" *ngIf="companyCheck==0">
      <div>

        <select name="company" class="form-control formstyle" (change)="getCompanyThemes($event.target.value)">
          <option value="">Select Company Name</option>
          <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
            {{userObj.customer_company_name}}
          </option>
        </select>

      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2">
      <div>

        <select name="company" class="form-control formstyle" [(ngModel)]="themeId" (change)="getCompanyJourneys()">
          <option value="">Select Theme Name</option>
          <option *ngFor="let userObj of themeObj" value={{userObj.theme_id}}>
            {{userObj.theme_name}}
          </option>
        </select>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2">
      <div>

        <select name="company" class="form-control formstyle" [(ngModel)]="journeyId" (change)="changeJourney()">
          <option value="all">All Journey</option>
          <option *ngFor="let userObj of journeyObj" value={{userObj.journeyid}}>
            {{userObj.journey_name}}
          </option>
        </select>

      </div>

    </div>
  </div>


  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Theme Id</th> -->
        <th>Screenshot</th>
        <th style="cursor: pointer;" (click)="setOrder('a.name')">Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th style="cursor: pointer;" (click)="setOrder('c.journey_name')">Journey <i class=" fa fa-sort text-black"
            aria-hidden="true"></i> </th>

        <th style="cursor: pointer; width: 130px;"> CreatedOn <i aria-hidden="true"></i></th>
        <!-- <th style="cursor: pointer; width: 130px"> ModifiedOn <i
            aria-hidden="true"></i></th>   -->

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let obj of templateObj">

        <td (click)="openImage(obj?.screenshot)"><img [src]="obj.screenshot" alt="Not Found" (error)="setValue($event)"
            style="height:50px;"></td>
        <td>{{obj.name}}</td>
        <td>{{obj.journey_name}}</td>


        <td>{{obj.createddate | date:'mediumDate' }}</td>
        <!-- <td>{{obj.modifiedon | date:'mediumDate' }}</td> -->



        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1" (click)="editTemplate(obj.ttaid)" placement="bottom" ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1" (click)="deleteTemplate(obj.ttaid)" placement="bottom"
              ngbTooltip="Delete"></span>

          </div>
        </td>
      </tr>

    </table>
    <div style="text-align: center;" *ngIf="templateObj.length === 0">
      <p>No Result Available</p>
    </div>
    <!-- <pagination-controls *ngIf="(templateObj | filter:text).length > 10" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
        (pageChange)="getCompanyTemplates(cp=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>