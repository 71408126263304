<!-- <h3><a routerLink="/corporate/clients">&lt;Back</a></h3> -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/clients">Clients / Customers</a>
      </li>
      <li class="breadcrumb-item active">Add New Client</li>
    </ol>
  </nav>
</div>
<form class="form-horizontal" [formGroup]="clientNewForm" (ngSubmit)="submitClientDetails()">
  <div class="container mt-2">
    <h5 class="mb-1 font-weight-bold">Personal Information</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Client Name <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="cname" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.cname?.errors }" id="cname" placeholder=" Client Name"
              maxlength="50" appSpace />
            <div *ngIf="submitted && f.cname?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.cname?.errors.required || f.cname.hasError('whitespace')
                ">
                Client Name is required
              </div>
              <div *ngIf="f.cname.errors.pattern">
                Client Name is not a valid format
              </div>
              <div *ngIf="f.cname.errors?.maxlength">
                You reached to maxlength
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Company Logo</label>
            <br>
            <img src="{{logoAWSkey}}" alt="logo" (error)="setValue($event)" height="50px">

            <input #imageInput type="file" accept=".png, .jpg, .jpeg" (change)="processFile($event, imageInput)"
              class="inputEllipses" />
            <div style="color: red;">
              Allowed file Types(.png, .jpg, .jpeg)
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Email <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="email" class="form-control formstyle mb-1"
              placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email?.errors }" maxlength="80" appSpace />
            <div *ngIf="submitted && f.email?.errors" class="invalid-feedback">
              <div *ngIf="f.email?.errors.required">Email is required</div>
              <div *ngIf="f.email?.errors.email">Invalid Email Format</div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Title <span class="required">*</span></label>
            <select formControlName="title" autocomplete="off" class="form-control formstyle" placeholder="title"
              [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
              <option value="">Select Title</option>
              <option value="Mr">Mr</option>
              <option value="Miss">Miss</option>
              <option value="Mrs">Mrs</option>
            </select>
            <!-- <input
              type="text"
              autocomplete="off"
              formControlName="title"
              class="form-control formstyle mb-1"
              placeholder="Title"
              [ngClass]="{ 'is-invalid': submitted && f.title?.errors }"
              maxlength="20"
              appSpace
            /> -->
            <div *ngIf="submitted && f.title?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.title?.errors.required || f.title.hasError('whitespace')
                ">
                Title is required
              </div>
              <div *ngIf="f.title.errors.pattern">
                Title is not a valid format
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">First Name <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="fname" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.fname?.errors }" id="fname" placeholder=" First Name"
              maxlength="50" appSpace />
            <div *ngIf="submitted && f.fname?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.fname?.errors.required || f.fname.hasError('whitespace')
                ">
                First Name is required
              </div>
              <div *ngIf="f.fname.errors.pattern">
                First Name is not a valid format
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Last Name</label>
            <input type="text" autocomplete="off" formControlName="lname" class="form-control formstyle" id="lname"
              placeholder=" Last Name" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.lname?.errors }" maxlength="50" appSpace />
            <div *ngIf="submitted && f.lname?.errors" class="invalid-feedback">
              <div *ngIf="f.lname.errors.pattern">
                Last Name is not a valid format
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Legal Name <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="lgname" class="form-control formstyle mb-1"
              placeholder="Legal Name" [ngClass]="{ 'is-invalid': submitted && f.lgname?.errors }" maxlength="50"
              appSpace />
            <div *ngIf="submitted && f.lgname?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.lgname?.errors.required || f.lgname.hasError('whitespace')
                ">
                Legal Name is required
              </div>
              <div *ngIf="f.lgname.errors.pattern">
                Legal Name is not a valid format
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Display Name <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="dispName" class="form-control formstyle mb-1"
              placeholder="Display Name" [ngClass]="{ 'is-invalid': submitted && f.dispName?.errors }" maxlength="50"
              appSpace />
            <div *ngIf="submitted && f.dispName?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.dispName?.errors.required ||
                  f.dispName.hasError('whitespace')
                ">
                Display Name is required
              </div>
              <div *ngIf="f.dispName.errors.pattern">
                Display Name is not a valid format
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="container mt-2">
    <h5 class="mb-1 font-weight-bold">Address</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Address 1 <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="addr1" class="form-control formstyle mb-1"
              placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.addr1?.errors }" maxlength="500"
              appSpace />
            <div *ngIf="submitted && f.addr1?.errors" class="invalid-feedback">
              <div *ngIf="f.addr1?.errors.required">Address 1 is required</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Address 2</label>
            <input type="text" autocomplete="off" formControlName="addr2" class="form-control formstyle mb-1"
              placeholder="Address 2" maxlength="500" appSpace />
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Country <span class="required">*</span>
            </label>
            <select formControlName="country" autocomplete="off" class="form-control formstyle" placeholder="country"
              [ngClass]="{ 'is-invalid': submitted && f.country.errors }" (change)="states($event.target.value)">
              <option value="">Select Country</option>
              <option *ngFor="let Obj of countriesData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
              <div *ngIf="f.country.errors.required">Country is required</div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">State <span class="required">*</span></label>

            <select formControlName="state" class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.state.errors }" placeholder="State"
              (change)="getCities($event.target.value)">
              <option value="">Select State</option>
              <!-- <option value="Select State"></option> -->
              <option *ngFor="let Obj of stateData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>

            <div *ngIf="submitted && f.state?.errors" class="invalid-feedback">
              <div *ngIf="f.state?.errors.required">State is required</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">City <span class="required">*</span></label>
            <select formControlName="city" class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="City">
              <option value="">Select City</option>
              <option *ngFor="let Obj of cityData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.city?.errors" class="invalid-feedback">
              <div *ngIf="f.city?.errors.required">City is required</div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Zip <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="zip" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.zip?.errors }" placeholder="Zip" maxlength="10" appSpace />
            <div *ngIf="submitted && f.zip?.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.pattern">Zip is only numeric</div>
              <div *ngIf="f.zip.errors?.maxlength">
                You reached to maxlength
              </div>
              <div *ngIf="f.zip.errors.required">Zip is required</div>
              <!-- <div *ngIf="f.zip?.errors.minLength">Zip must be of 6 digits</div>
            <div *ngIf="f.zip?.errors.maxLength">Zip must be of 6 digits</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-2">
    <h5 class="mb-1 font-weight-bold">Billing Address</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing Address 1 <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="billAddr1" class="form-control formstyle mb-1"
              placeholder="Billing Address 1" [ngClass]="{ 'is-invalid': submitted && f.billAddr1?.errors }"
              maxlength="500" appSpace />
            <div *ngIf="submitted && f.billAddr1?.errors" class="invalid-feedback">
              <div *ngIf="f.billAddr1?.errors.required">
                Billing Address 1 is required
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing Address 2</label>
            <input type="text" autocomplete="off" formControlName="billAddr2" class="form-control formstyle mb-1"
              placeholder="Billing Address 2" maxlength="500" appSpace />
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing Country <span class="required">*</span></label>

            <select formControlName="billCountry" autocomplete="off" class="form-control formstyle"
              placeholder="country" [ngClass]="{ 'is-invalid': submitted && f.billCountry.errors }"
              (change)="billingstates($event.target.value)">
              <option value="">Select Billing Country</option>
              <option *ngFor="let Obj of countriesData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>

            <div *ngIf="submitted && f.billCountry?.errors" class="invalid-feedback">
              <div *ngIf="f.billCountry?.errors.required">
                Billing Country is required
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing State <span class="required">*</span></label>
            <select formControlName="billState" class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.billState.errors }" placeholder="State"
              (change)="getBillingCities($event.target.value)">
              <option value="">Select Billing State</option>
              <!-- <option value="Select State"></option> -->
              <option *ngFor="let Obj of billingStateData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>

            <div *ngIf="submitted && f.billState?.errors" class="invalid-feedback">
              <div *ngIf="f.billState?.errors.required">
                Billing State is required
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing City <span class="required">*</span></label>
            <select formControlName="billCity" class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.billCity.errors }" placeholder="City">
              <option value="">Select Billing City</option>
              <option *ngFor="let Obj of billingCityData" value="{{ Obj.id }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.billCity?.errors" class="invalid-feedback">
              <div *ngIf="f.billCity?.errors.required">
                Billing City is required
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Billing Zip <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="bZip" class="form-control formstyle mb-1"
              placeholder="Billing Zip" [ngClass]="{ 'is-invalid': submitted && f.bZip?.errors }" maxlength="10"
              appSpace />
            <div *ngIf="submitted && f.bZip?.errors" class="invalid-feedback">
              <!-- <div *ngIf="f.bZip?.errors.minLength">Zip must be of 6 digits</div>
                    <div *ngIf="f.bZip?.errors.maxLength">Zip must be of 6 digits</div> -->
              <div *ngIf="f.bZip.errors.pattern">Zip is only numeric</div>
              <div *ngIf="f.bZip.errors?.maxlength">
                You reached to maxlength
              </div>
              <div *ngIf="f.bZip.errors.required">Billing Zip is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-2">
    <h5 class="mb-1 font-weight-bold">Others</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Tax ID <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="taxid" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.taxid?.errors }" placeholder="Tax ID" appSpace required
              maxlength="50" />
            <div *ngIf="submitted && f.taxid?.errors" class="invalid-feedback">
              <div *ngIf="
                  f.taxid?.errors.required || f.taxid.hasError('whitespace')
                ">
                Tax ID is required
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Website <span class="required">*</span></label>
            <input type="text" autocomplete="off" formControlName="website" class="form-control formstyle mb-1"
              placeholder="Website" [ngClass]="{ 'is-invalid': submitted && f.website.errors }" required
              maxlength="100" />
            <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
              <div *ngIf="f.website.errors.required">Website is required</div>
              <div *ngIf="f.website.errors.pattern">Please enter valid URL</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Phone</label>
            <input type="text" minlength="10" autocomplete="off" formControlName="mphone"
              class="form-control formstyle mb-1" [ngClass]="{ 'is-invalid': submitted && f.mphone.errors }"
              placeholder="Phone" maxlength="20" />
            <div *ngIf="submitted && f.mphone.errors" class="invalid-feedback">
              <div *ngIf="f.mphone.errors.minlength">
                Phone must be of 10 digits
              </div>
              <div *ngIf="f.mphone.errors.pattern">
                Number is not in valid format
              </div>
              <!--<div *ngIf="f.mphone?.errors.maxLength">Phone must be of 10 digits</div> -->
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Language</label>
            <input type="text" autocomplete="off" formControlName="lang" class="form-control formstyle mb-1"
              [ngClass]="{ 'is-invalid': submitted && f.lang.errors }" placeholder="Language" maxlength="50" />
            <div *ngIf="submitted && f.lang.errors" class="invalid-feedback">
              <div *ngIf="f.lang.errors.pattern">
                please enter valid Language
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Currency</label>
            <select formControlName="curr" class="form-control formstyle" placeholder="City">
              <option value="">Select Currency</option>
              <option *ngFor="let Obj of currencyObj" value="{{ Obj.get_master_currency.currency_symbol }}">
                {{ Obj.get_master_currency.currency_name }} ({{
                Obj.get_master_currency.currency_symbol
                }})
              </option>
            </select>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Revenue</label>
            <input appSpace type="text" formControlName="revenue" class="form-control formstyle mb-1"
              placeholder="Revenue" [ngClass]="{ 'is-invalid': submitted && f.revenue.errors }" maxlength="50" />
            <div *ngIf="submitted && f.revenue.errors" class="invalid-feedback">
              <div *ngIf="f.revenue.errors.pattern">Enter numeric value</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3 slectD">
            <label for="startDate" class="col-form-label">Start Date <span class="required">*</span></label>

            <!-- [min]="minDate"-->
            <input class="form-control formstyle mb-1 max-w-1501" placeholder="mm/dd/yy" matInput
              [matDatepicker]="picker1" readonly formControlName="startDate" autocomplete="off"
              [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />

            <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
              <div *ngIf="f.startDate.errors.required">Select Start Date</div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">No. of Employees</label>
            <input appSpace type="text" formControlName="noEmp" class="form-control formstyle mb-1"
              placeholder="No of Employees" [ngClass]="{ 'is-invalid': submitted && f.noEmp.errors }" maxlength="10" />

            <div *ngIf="submitted && f.noEmp.errors" class="invalid-feedback">
              <div *ngIf="f.noEmp.errors.pattern">Enter numeric value</div>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">No. of Countries</label>
            <input appSpace type="text" formControlName="noCountry" class="form-control formstyle mb-1"
              placeholder="No of Countries" [ngClass]="{ 'is-invalid': submitted && f.noCountry.errors }"
              maxlength="3" />
            <div *ngIf="submitted && f.noCountry.errors" class="invalid-feedback">
              <div *ngIf="f.noCountry.errors.pattern">Enter numeric value</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">No. of DS</label>
            <input appSpace type="text" formControlName="noDS" class="form-control formstyle mb-1"
              placeholder="No of DS" [ngClass]="{ 'is-invalid': submitted && f.noDS.errors }" maxlength="10" />
            <div *ngIf="submitted && f.noDS.errors" class="invalid-feedback">
              <div *ngIf="f.noDS.errors.pattern">Enter numeric value</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 pb-3">
            <label for="exampleForm2">Contract in Years <span class="required">*</span></label>
            <input appSpace type="text" formControlName="contractYears" class="form-control formstyle mb-1"
              placeholder="Contract in Years" [ngClass]="{ 'is-invalid': submitted && f.contractYears.errors }" required
              maxlength="5" />

            <div *ngIf="submitted && f.contractYears.errors" class="invalid-feedback">
              <div *ngIf="f.contractYears.errors.pattern">
                Enter numeric value
              </div>
              <div *ngIf="f.contractYears.errors.required">Contract Years is Required</div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
                <label class="mr-1">Is Headquarter </label>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="headquarterYes" [(ngModel)]="isheadquarter"
                    formControlName="headquarter" [value]="true" />
                  <label class="custom-control-label" for="headquarterYes">Yes</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="headquarterNo" formControlName="headquarter"
                    [(ngModel)]="isheadquarter" [value]="false" />
                  <label class="custom-control-label" for="headquarterNo">No</label>
                </div>
              </div>
              <div class="col-md-4 mt-1 mt-md-0">
                <div [hidden]="isheadquarter">
                  <label for="exampleForm2">Headquarter</label><span class="required">*</span>
                  <select formControlName="clientID" class="formstyle form-control">
                    <option value="">Select Headquarter</option>
                    <option *ngFor="let clientObj of existingClients" value="{{ clientObj.customer_id }}">
                      {{ clientObj.customer_company_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngIf="!isheadquarter">
            <div class="row">
              <div class="col-md-4">
                <label class="mr-1">Do you want E-Wallet? </label>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="isEWalletYes" [(ngModel)]="isEWallet"
                    formControlName="wallet_status" [value]="true" />
                  <label class="custom-control-label" for="isEWalletYes">Yes</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="isEWalletNo" formControlName="wallet_status"
                    [(ngModel)]="isEWallet" [value]="false" />
                  <label class="custom-control-label" for="isEWalletNo">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-4 col-sm-6" *ngIf="isEWallet && !isheadquarter">
                <label for="exampleForm2">DS E-Wallet Amount</label><span class="required">*</span>
                <input appSpace type="text" formControlName="monthly_amount" required
                  class="form-control formstyle mb-1" placeholder="$100"
                  [ngClass]="{ 'is-invalid': submitted && f.monthly_amount.errors }" />
                <div *ngIf="submitted && f.monthly_amount.errors" class="invalid-feedback">
                  <div *ngIf="f.monthly_amount.errors.required">DS E-Wallet Amount is required.</div>
                  <div *ngIf="f.monthly_amount.errors?.pattern"> Only numeric value allow</div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6" *ngIf="isEWallet && !isheadquarter">
                <label for="exampleForm2">E-Wallet Name</label><span class="required">*</span>
                <input appSpace type="text" formControlName="wallet_name" class="form-control formstyle mb-1"
                  placeholder="Wallet Name" required maxlength="25"
                  [ngClass]="{ 'is-invalid': submitted && f.wallet_name.errors }" />
                <div *ngIf="submitted && f.wallet_name?.errors" class="invalid-feedback">
                  <div *ngIf="f.wallet_name.errors.required">
                    E-Wallet name is required.
                  </div>
                  <div *ngIf="f.wallet_name.errors?.maxlength">
                    You reached to maxlength
                  </div>
                  <div *ngIf="f.wallet_name.errors.pattern">
                    Wallet Name is not a valid format
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-4">
      <div class="my_class text-center">
        <button type="button" class="custbtn mt-1" *ngIf="isWrite" appThrottleClick [throttleTime]="15000"
          (throttledClick)="submitClientDetails()">
          Submit
        </button>
      </div>
    </div>
  </div>
</form>