<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/productcatalog">Product Catalog</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{headers}} Catalog</li>
        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="row">
            <div class="col-md-10 col-xl-12">

                <div class="card mt-1">

                    <div class="card-body">
                        <div>
                            <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                            <select formControlName="company"  class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                                (change)="getDSLIST($event.target.value);getBusinessDomain()">
                                <option value="">Select Company</option>
                                <ng-container *ngFor="let userObj of getCompanyobj">
                                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                        {{userObj.customer_company_name}}
                                    </option>
                                </ng-container>
                            </select>

                            <!-- <select [attr.disabled]="true" formControlName="company" *ngIf="headers == 'Edit'"
                                class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                                <option value="">Select Company</option>
                                <ng-container *ngFor="let userObj of getCompanyobj">
                                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                        {{userObj.customer_company_name}}
                                    </option>
                                </ng-container>
                            </select> -->


                            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                <div *ngIf="f.company.errors.required">Company Name is required</div>
                            </div>
                        </div>

                        <div *ngIf="page=='direct-seller'">
                            <label for="exampleForm2">Select Direct Seller <span style="color:red">*</span></label>
                            <select formControlName="dsid" class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.dsid.errors }" (change)='getDsDomain($event)'>
                                <option value="">Select Direct Seller</option>
                                <ng-container *ngFor="let userObj of getDSobj">
                                    <option value={{userObj.user_ds_id}}>
                                        {{userObj.user_fname}}
                                    </option>
                                </ng-container>
                            </select>
                            <div *ngIf="submitted && f.dsid.errors" class="invalid-feedback">
                                <div *ngIf="f.dsid.errors.required">Direct Seller is required</div>
                            </div>
                        </div>
                        <div>
                          <label for="exampleForm2">Select Business Domain Name<span style="color:red">*</span></label>
                          <select formControlName="business_domain_id"  class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.business_domain_id.errors }">
                          <option value=''>Select Domain</option>
                          <ng-container *ngFor="let userObj of domainData" >
                              <option *ngIf="userObj.is_active == true" value={{userObj.id}}>
                       {{userObj.domain_name}}
                          </option>
                          </ng-container>
                      </select>
                      <div *ngIf="submitted && f.business_domain_id.errors" class="invalid-feedback">
                        <div *ngIf="f.business_domain_id.errors.required">Business domain is required</div>
                    </div>
                      </div>
                        <div>
                            <label for="exampleForm2">Product Name <span style="color:red">*</span></label>
                            <input type="text" formControlName="productname" class="form-control form-control-md"
                                [ngClass]="{ 'is-invalid': submitted && f.productname.errors }" required maxlength="150"
                                appSpace>
                            <div *ngIf="submitted && f.productname.errors" class="invalid-feedback">
                                <div *ngIf="f.productname.errors.required || f.productname.hasError('whitespace')">
                                    Product Name is required</div>

                                <div *ngIf="f.productname.errors?.maxlength">You can enter only 150 characters</div>


                            </div>
                        </div>
                        <div class="mt-1">
                            <label for="exampleForm2">Product Description
                                <!-- <span style="color:red">*</span> -->
                            </label>

                            <textarea rows="3" formControlName="productdescription"
                                class="form-control form-control-md textarea_height" maxlength="1990"
                                [ngClass]="{ 'is-invalid': submitted && f.productdescription.errors }"></textarea>

                            <!-- <input type="text" formControlName="productdescription" min="1"
                                class="form-control form-control-md" maxlength="100"
                                [ngClass]="{ 'is-invalid': submitted && f.productdescription.errors }"> -->

                            <!-- <div *ngIf="submitted && f.productdescription.errors" class="invalid-feedback">
                                <div
                                    *ngIf="f.productdescription.errors.required || f.productdescription.hasError('whitespace')">
                                    Product Description is required</div>

                                <div *ngIf="f.productdescription.errors?.maxlength">You can enter only 2000 characters
                                </div>
                            </div> -->
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product SKU
                                <span style="color:red">*</span>
                            </label>
                            <input type="text" formControlName="productsku" class="form-control form-control-md"
                                maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.productsku.errors }">
                            <div *ngIf="submitted && f.productsku.errors" class="invalid-feedback">
                                <div *ngIf="f.productsku.errors.required">
                                    Product Sku is required</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Category <span style="color:red">*</span></label>
                            <input type="text" formControlName="productcategory" class="form-control form-control-md"
                                maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.productcategory.errors }">

                            <div *ngIf="submitted && f.productcategory.errors" class="invalid-feedback">
                                <div *ngIf="f.productcategory.errors.required">
                                    Product category is required</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Price <span style="color:red">*</span></label>
                            <input type="number" formControlName="productprice" class="form-control form-control-md"
                                maxlength="10" [ngClass]="{ 'is-invalid': submitted && f.productprice.errors }">

                            <div *ngIf="submitted && f.productprice.errors" class="invalid-feedback">
                                <div *ngIf="f.productprice.errors.required">
                                    Product Price is required</div>

                                <div *ngIf="f.productprice.errors?.min">Price should be greater then 0</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Link <span style="color:red">*</span></label>
                            <input type="text" formControlName="productlink" class="form-control form-control-md"
                                maxlength="150" [ngClass]="{ 'is-invalid': submitted && f.productlink.errors }">

                            <div *ngIf="submitted && f.productlink.errors" class="invalid-feedback">
                                <div *ngIf="f.productlink.errors.required">
                                    Product Link is required</div>
                            </div>

                            <div *ngIf="submitted && f.productlink.hasError('validUrl')" class="invalid-feedback">
                                <div *ngIf="f.productlink.hasError('validUrl')">
                                    Please enter valid url</div>
                            </div>

                        </div>
                        <!--=================Product Image 1============-->
                        <div class="mt-1">
                            <label for="exampleForm2">Product Image<span style="color:red">*</span></label>
                            <div *ngIf='theme_screenshot'>
                                <img src="{{theme_screenshot}}" alt="Margo" style="
                        width: 200px" (error)="setValue($event)">
                            </div>
                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio1" name='firstActive'
                                        formControlName="firstActive" [value]="true" (change)='radioChange("image1")'>
                                    <label class="custom-control-label" for="customRadio1">Url</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio2" name='firstActive'
                                        formControlName="firstActive" [value]="false" (change)='radioChange("image1")'>
                                    <label class="custom-control-label" for="customRadio2">Upload a file</label>
                                </div>
                            </div>
                            <div *ngIf="screenshot_type" class="mt-1">
                                <input type="text" formControlName="productPicture"
                                    class="form-control form-control-md mt-1"
                                    placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                                    [ngClass]="{ 'is-invalid': submitted && f.productPicture.errors }" maxlength="200">
                                <div *ngIf="submitted && f.productPicture.errors" class="invalid-feedback">
                                    <div *ngIf="f.productPicture.errors.required">Product Url is required</div>
                                </div>

                                <!-- <div *ngIf="submitted && f.productPicture.hasError('validUrl')"
                                    class="invalid-feedback">
                                    <div *ngIf="f.productPicture.hasError('validUrl')">
                                        Please enter valid url</div>
                                </div> -->

                            </div>
                            <div *ngIf="!screenshot_type && headers=='Add'" class="mt-1"
                                [ngClass]="status ? 'displayblock' : 'displaynone'">
                                <input multiple #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                    (change)="processFile($event,imageInput,'image1')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                            </div>

                            <div *ngIf="!screenshot_type && headers=='Edit'" class="mt-1">
                                <input multiple #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                    (change)="processProductFile($event,imageInput,'image1')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                            </div>

                        </div>
                        <!--=================Product Image 2============-->
                        <div class="mt-1">
                          <label for="exampleForm2">Product Image 2</label>
                          <div *ngIf="theme_screenshot2">
                              <img src="{{theme_screenshot2}}" alt="Margo" style="
                      width: 200px" (error)="setValue($event)">
                          </div>
                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image2' type="radio" class="custom-control-input" id="customRadio3"
                                       [value]="true" (change)='radioChange("image2")' formControlName='secondActive' name='secondActive'>
                                  <label class="custom-control-label" for="customRadio3">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image2' type="radio" class="custom-control-input" id="customRadio4"
                                     [value]="false" (change)='radioChange("image2")' formControlName='secondActive' name='secondActive'>
                                  <label class="custom-control-label" for="customRadio4">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type2" class="mt-1">
                              <input type="text" formControlName="productPicture2"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                                  [ngClass]="{ 'is-invalid': submitted && f.productPicture2.errors }" maxlength="200">
                          </div>
                          <div *ngIf="!screenshot_type2 && headers=='Add'" class="mt-1"
                              [ngClass]="status ? 'displayblock' : 'displaynone'">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processFile($event,imageInput,'image2')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                          <div *ngIf="!screenshot_type2 && headers=='Edit'" class="mt-1">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processProductFile($event,imageInput,'image2')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                      </div>
                        <!--=================Product Image 3============-->
                        <div class="mt-1">
                          <label for="exampleForm2">Product Image 3</label>
                          <div *ngIf="theme_screenshot3">
                              <img src="{{theme_screenshot3}}" alt="Margo" style="
                      width: 200px" (error)="setValue($event)">
                          </div>
                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image3' type="radio" class="custom-control-input" id="customRadio5"
                                       [value]="true" (change)='radioChange("image3")' formControlName='thirdActive' name='thirdActive'>
                                  <label class="custom-control-label" for="customRadio5">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image3' type="radio" class="custom-control-input" id="customRadio6"
                                     [value]="false" (change)='radioChange("image3")' formControlName='thirdActive' name='thirdActive'>
                                  <label class="custom-control-label" for="customRadio6">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type3" class="mt-1">
                              <input type="text" formControlName="productPicture3"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                                  [ngClass]="{ 'is-invalid': submitted && f.productPicture3.errors }" maxlength="200">
                          </div>
                          <div *ngIf="!screenshot_type3 && headers=='Add'" class="mt-1"
                              [ngClass]="status ? 'displayblock' : 'displaynone'">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processFile($event,imageInput,'image3')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                          <div *ngIf="!screenshot_type3 && headers=='Edit'" class="mt-1">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processProductFile($event,imageInput,'image3')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                      </div>
                        <!--=================Product Image 4============-->
                        <div class="mt-1">
                          <label for="exampleForm2">Product Image 4</label>
                          <div *ngIf="theme_screenshot4">
                              <img src="{{theme_screenshot4}}" alt="Margo" style="
                      width: 200px" (error)="setValue($event)">
                          </div>
                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image4' type="radio" class="custom-control-input" id="customRadio7"
                                       [value]="true" (change)='radioChange("image4")' formControlName='fourthActive' name='fourthActive'>
                                  <label class="custom-control-label" for="customRadio7">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input name='image4' type="radio" class="custom-control-input" id="customRadio8"
                                     [value]="false" (change)='radioChange("image4")' formControlName='fourthActive' name='fourthActive'>
                                  <label class="custom-control-label" for="customRadio8">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type4" class="mt-1">
                              <input type="text" formControlName="productPicture4"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                                  [ngClass]="{ 'is-invalid': submitted && f.productPicture4.errors }" maxlength="200">
                          </div>
                          <div *ngIf="!screenshot_type4 && headers=='Add'" class="mt-1"
                              [ngClass]="status ? 'displayblock' : 'displaynone'">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processFile($event,imageInput,'image4')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                          <div *ngIf="!screenshot_type4 && headers=='Edit'" class="mt-1">
                              <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                                  (change)="processProductFile($event,imageInput,'image4')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                          </div>

                      </div>
                        <div class="mt-1" *ngIf="page!='direct-seller'">
                            <label for="exampleForm2">No of days for Cross-sell</label>
                            <input type="number" formControlName="no_of_days_cross" class="form-control form-control-md"
                                [ngClass]="{ 'is-invalid': submitted && f.no_of_days_cross.errors }">

                            <div *ngIf="submitted && f.no_of_days_cross.errors" class="invalid-feedback">
                                <div *ngIf="f.no_of_days_cross.errors?.min">Please enter valid number</div>
                            </div>


                        </div>

                        <div class="mt-1" *ngIf="page!='direct-seller'">
                            <label for="exampleForm2">No of days for replenishment </label>
                            <input type="number" formControlName="no_of_days_replenishment"
                                class="form-control form-control-md"
                                [ngClass]="{ 'is-invalid': submitted && f.no_of_days_replenishment.errors }">

                            <div *ngIf="submitted && f.no_of_days_replenishment.errors" class="invalid-feedback">
                                <div *ngIf="f.no_of_days_replenishment.errors?.min">Please enter valid number</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">External Website Product ID </label>
                            <input type="number" formControlName="website_product_id"
                                class="form-control form-control-md">


                        </div>


                        <!-- <div class="mt-1">
                            <label for="exampleForm2">Product Fancy Image</label>
                            <div [ngClass]="productImage2 ? 'displayblock' :'displaynone' ">
                                <img src="{{productImage2}}" alt="Margo" style="
                        width: 200px;" (error)="setValue($event)">
                            </div>
                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio3"
                                        formControlName="firstActive2" [value]="true" (change)='radioChange("image2")'>
                                    <label class="custom-control-label" for="customRadio3">Url</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio4"
                                        formControlName="firstActive2" [value]="false" (change)='radioChange("image2")'>
                                    <label class="custom-control-label" for="customRadio4">Upload a file</label>
                                </div>
                            </div>
                            <div *ngIf="screenshot_type2" class="mt-1">
                                <input type="text" formControlName="productPicture2"
                                    class="form-control form-control-md mt-1"
                                    placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                            </div>
                            <div *ngIf="!screenshot_type2" class="mt-1">
                                <input #imageInput2 type="file" accept=".png, .jpg, .jpeg"
                                    (change)="processFile($event,imageInput2, 'image2')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Fancy Image-2</label>
                            <div [ngClass]="productImage3 ? 'displayblock' :'displaynone' ">
                                <img src="{{productImage3}}" alt="Margo" style="width: 200px;"
                                    (error)="setValue($event)">
                            </div>

                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio5"
                                        formControlName="firstActive3" [value]="true" (change)='radioChange("image3")'>
                                    <label class="custom-control-label" for="customRadio5">Url</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio6"
                                        formControlName="firstActive3" [value]="false" (change)='radioChange("image3")'>
                                    <label class="custom-control-label" for="customRadio6">Upload a file</label>
                                </div>
                            </div>
                            <div *ngIf="screenshot_type3" class="mt-1">
                                <input type="text" formControlName="productPicture3"
                                    class="form-control form-control-md mt-1"
                                    placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                            </div>
                            <div *ngIf="!screenshot_type3" class="mt-1">
                                <input #imageInput3 type="file" accept=".png, .jpg, .jpeg"
                                    (change)="processFile($event,imageInput3, 'image3')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Banner Image</label>
                            <div [ngClass]="productImage4 ? 'displayblock' :'displaynone' ">
                                <img src="{{productImage4}}" alt="Margo" style="
                        width: 200px;" (error)="setValue($event)">
                            </div>

                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio7"
                                        formControlName="firstActive4" [value]="true" (change)='radioChange("image4")'>
                                    <label class="custom-control-label" for="customRadio7">Url</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio8"
                                        formControlName="firstActive4" [value]="false" (change)='radioChange("image4")'>
                                    <label class="custom-control-label" for="customRadio8">Upload a file</label>
                                </div>
                            </div>
                            <div *ngIf="screenshot_type4" class="mt-1">
                                <input type="text" formControlName="productPicture4"
                                    class="form-control form-control-md mt-1"
                                    placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                            </div>
                            <div *ngIf="!screenshot_type4" class="mt-1">
                                <input #imageInput4 type="file" accept=".png, .jpg, .jpeg"
                                    (change)="processFile($event,imageInput4, 'image4')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Product Thumb Image</label>
                            <div [ngClass]="productImage5 ? 'displayblock' :'displaynone' ">
                                <img src="{{productImage5}}" alt="Margo" style="
                        width: 200px;" (error)="setValue($event)">
                            </div>

                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio9"
                                        formControlName="firstActive5" [value]="true" (change)='radioChange("image5")'>
                                    <label class="custom-control-label" for="customRadio9">Url</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="customRadio10"
                                        formControlName="firstActive5" [value]="false" (change)='radioChange("image5")'>
                                    <label class="custom-control-label" for="customRadio10">Upload a file</label>
                                </div>
                            </div>
                            <div *ngIf="screenshot_type5" class="mt-1">
                                <input type="text" formControlName="productPicture5"
                                    class="form-control form-control-md mt-1"
                                    placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                            </div>
                            <div *ngIf="!screenshot_type5" class="mt-1">
                                <input #imageInput5 type="file" accept=".png, .jpg, .jpeg"
                                    (change)="processFile($event,imageInput5, 'image5')">
                                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                            </div>
                        </div> -->



                    </div>


                    <div class="card-footer text-center mt-1">
                        <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                            <button type="submit" class="custbtn" *ngIf="isWrite"
                                (click)="addNewProduct()">Submit</button>
                        </div>
                        <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                            <button type="button" class="custbtn" *ngIf="isEdit"
                                (click)="updateProduct()">Update</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>
    <form class="form-horizontal mt-1" [formGroup]="metaForm" *ngIf="metaVisibility">
        <div class="crad">
            <h5 class="mb-1 font-weight-bold subTitleHead_3">
                Meta Fields
            </h5>

            <div class="card-body" style="background: #fff;">
                <div class="row">
                    <div class="col-md-6 col-sm-6" *ngFor="let fd of t.controls; let i = index">
                        <div class="form-group" [formGroup]="fd">
                            <label>{{fd.controls.lname.value}}</label>
                            <input type="text" formControlName="metafname" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fd.controls.metafname.errors }" />

                            <div *ngIf="fd.controls.metafname.errors">
                                <div *ngIf="fd.controls.metafname.errors.maxlength">
                                    <span style="color: red;">
                                        <small> *Maximum allowed length reached for the field</small>
                                    </span>
                                </div>
                                <div
                                    *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Integer'">
                                    <span style="color: red;">
                                        <small> *Only Integers 0-9 are Allowed</small>
                                    </span>
                                </div>
                                <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Float'">
                                    <span style="color: red;">
                                        <small> *Only Floating values are Allowed e.g: 123.32</small>
                                    </span>
                                </div>
                                <div
                                    *ngIf="fd.controls.metafname.errors.boolCheck && fd.controls.ftyp.value === 'Boolean'">
                                    <span style="color: red;">
                                        <small> *Only true or false values are Allowed</small>
                                    </span>
                                </div>
                                <div
                                    *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Tmstamp'">
                                    <span style="color: red;">
                                        <small> *Timestamp values should be like 2020-11-15T21:11:11.000Z</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div class="form-group text-center">
                <button class="btn btn-round btn-margo-action" (click)="updateMetaFields()">Save Meta Fields</button>
            </div>
        </div>
    </form>
</div>
