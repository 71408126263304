import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HelpandtipsService } from 'src/app/services/helpandtips/helpandtips.service';
import {HelpAndTipsMessages} from 'src/app/shared/messages/messages.enum';
import {Case} from 'src/app/shared/modelClasses/modelClasses';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  subject: string = "";
  description: string = "";
  status: boolean = true;
  library: any = [];
  caseDetail: Case;
  libraryType:string="Library";
  projectName = environment.projectName;
  constructor(private helpandtipsService: HelpandtipsService,
    private toastrService: ToastrService,
    private modalService: NgbModal, ) {

  }

  ngOnInit() {
  }

  //Create new Case
  submitCase() {
    if (this.subject != "") {
      this.status = true;
      // this.caseDetail.subject=this.subject;
      // this.caseDetail.Description=this.description;
      // this.caseDetail.SuppliedEmail="Vishal.d@aditatechnologies.com";
      // this.caseDetail.SuppliedName="Vishal Dhawan";
      this.caseDetail = {
        subject: this.subject,
        Origin: "Web",
        Status: "New",
        Description: this.description,
        SuppliedEmail: "Vishal.d@aditatechnologies.com",
        SuppliedName: "Vishal Dhawan",
        Case_Originated_App__c:"marGo-Admin"
      }
      this.helpandtipsService.submitCase(this.caseDetail).subscribe(resStatus => {
       // console.log(resStatus);
        if (resStatus.success)
          this.toastrService.success(HelpAndTipsMessages.caseLogged);
        this.subject = "";
        this.description = "";
      }, error => {
      //  console.log(error);
      });

    }
    else {
      this.toastrService.error("Please Enter Subject.")
    }
  }

  getLibraryData(modelName, libraryType) {
    this.libraryType = libraryType;
    this.library = [];
    if (libraryType == "Subject") {
      this.helpandtipsService.getSubjectlib().subscribe(data => {
        this.library = data;
      //  console.log(data);
      }, error => {
      //  console.log(error);
      });
    }
    else {
      this.helpandtipsService.getMessagelib().subscribe(data => {
        this.library = data;
      //  console.log(data);
      }, error => {
      //  console.log(error);
      });
    }
    this.modalService.open(modelName, { ariaLabelledBy: 'modal-basic-title' });
  }

  selectSubjectMessage(selectedLibraryItem,libraryType) {
   // console.log(selectedLibraryItem);
    libraryType=="Subject"?this.subject=selectedLibraryItem:this.description=selectedLibraryItem;
    this.modalService.dismissAll();
  }

}
