import { Component, OnInit } from '@angular/core';
import { PiiService } from 'src/app/services/pii/pii.service';
import { BaseComponent } from '../../base.component';


@Component({
  selector: 'app-pii-table',
  templateUrl: './pii-table.component.html',
  styleUrls: ['./pii-table.component.css']
})
export class PiiTableComponent extends BaseComponent implements OnInit {
  selectedAll: any;
  constructor(private piiservice: PiiService) { super() }


  allTableFieldsobj;
  checkedAll = false;
  marked = false;
  theCheckbox = false;
  checkedTable: any = [];
  sensitveTablesobj: any = [];
  nonSensitiveTables: any = [];
  companyID;

  ngOnInit() {

    this.companyID = localStorage.getItem('comapny_id');
    this.gettingSensitiveTables(this.companyID);
    this.gettingTables(this.companyID);
  }

  gettingTables(id) {
    let self = this;
    this.ngxSpinnerService.show();
    this.piiservice.gettingTables(id).subscribe(data => {

      for (var i = 0; i < data.data.length; i++) {

        if (this.sensitveTablesobj.indexOf(data.data[i]['table_name']) == -1) {
          this.nonSensitiveTables.push(data.data[i]['table_name']);
        }
      }

      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });

  }

  gettingTableFields(name) {
    // console.log("testing message");
    let self = this;
    this.piiservice.gettingTableField(name, this.companyID).subscribe(data => {
      self.allTableFieldsobj = data.data;
      //console.log(self.allTableFieldsobj);  
    }, error => {
    //  console.log(error);
    });
  }


  checkAll(e) {
    this.marked = e.target.checked;
  }

  checkIfAllSelected() {
    this.selectedAll = this.allTableFieldsobj.every(function (item: any) {
      return item.selected == true;
    })
  }

  changeCheckTables(a) {
    let abc = a;
    var index = this.checkedTable.indexOf(abc);
    if (index > -1) {
      this.checkedTable.splice(index, 1);
     // console.log(this.checkedTable);
    }
    else {
      this.checkedTable.push(abc)
    //  console.log(this.checkedTable);
    }


  }

  addNewData() {
    this.ngxSpinnerService.show();
    if (this.checkedTable.length > 0) {

      var obj = {
        selectedTables: this.checkedTable,
        dbType: 'margo',
        customerId: 1,
        company: this.companyID
      }


      this.piiservice.addNewData(obj).subscribe(data => {
        if (data.status) {
          this.ngxSpinnerService.hide();
          this.toastrService.success("Record Inserted successfully");
          this.router.navigateByUrl(`/corporate/pii-data-view`);
          //this.router.navigateByUrl(``);
        } else {
          this.ngxSpinnerService.hide();
          this.toastrService.error(data.message)
        }
      }, error => {
        this.ngxSpinnerService.hide();
        this.toastrService.error(error.message)
      });
    } else {
      this.ngxSpinnerService.hide();
      this.toastrService.error("Please select at least one table.")
    }
  }

  gettingSensitiveTables(id) {
    let self = this;
    this.piiservice.gettingSensitiveTables(id).subscribe(data => {
      for (var i = 0; i < data.data.length; i++) {
        this.sensitveTablesobj.push(data.data[i]['table_name']);

      }


    }, error => {
     // console.log(error);
    });

  //  console.log(this.checkedTable);
  }
}