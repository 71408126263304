import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../base.component";
import { SupportService } from "src/app/services/support/support.service";
@Component({
  selector: "app-supportuser-logs",
  templateUrl: "./supportuser-logs.component.html",
  styleUrls: ["./supportuser-logs.component.css"],
})
export class SupportuserLogsComponent extends BaseComponent implements OnInit {
  currentUser: any;
  isFetching = false;
  bu_id = "";
  getCompanyobj;
  support_id = "";
  supportUser: any;
  company_id: any;
  sellerId: any = "";
  sellerObj: any;
  case_id = "";
  caseObj: any = [];
  directSellerSupportCrudLog = [];
  sLogPage = 1;
  sLogLimit = 20;
  sLogSkip = 0;
  supportord = "desc";
  supportColname = "lid";
  supportLogTotalCount: number;
  constructor(private supportService: SupportService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    this.company_id = this.currentUser.admin_customer_id;
    if(this.company_id==0){
      this.getHQBUCompany();
    }else{
      this.getCompanySupportList(this.company_id)
    }
  }
  getCompanySupportList(value) {
    this.supportUser = [];
    this.support_id = "";
    this.sellerObj = [];
    this.sellerId = "";
    this.caseObj = [];
    this.case_id = "";
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    this.bu_id = value;
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;
    let self = this;
    // alert(value);
    this.ngxSpinnerService.show();
    this.supportService.supportUserByCompany(companyID).subscribe(
      (data) => {
        self.supportUser = data.data;
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
  getHQBUCompany() {
    let self = this;
    this.registerService.getHQBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
      },
      (error) => {}
    );
  }
  getCompanySupportSellerList(value) {
    this.sellerObj = [];
    this.sellerId = "";
    this.caseObj = [];
    this.case_id = "";
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    if (typeof value != "string" || value.length == 0) {
      this.caseObj = [];
      return;
    }
    this.sLogPage = 1;
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;
    let self = this;
    this.ngxSpinnerService.show();
    this.supportService
      .getCompanySupportSellerList(companyID, JSON.parse(value).id)
      .subscribe(
        (data) => {
          self.sellerObj = data.data;
          this.ngxSpinnerService.hide();
        },
        (error) => {
          this.ngxSpinnerService.hide();
        }
      );
  }
  getCaseId(value) {
    if (typeof value != "string" || value.length == 0) {
      this.caseObj = [];
      return;
    }
    this.sLogPage = 1;
    this.caseObj = [];
    this.case_id = "";
    this.directSellerSupportCrudLog = [];
    this.supportLogTotalCount = 0;
    if (typeof value != "string" || value.length == 0) {
      this.caseObj = [];
      return;
    }
    this.ngxSpinnerService.show();
    this.supportService.getCaseId(JSON.parse(value).id).subscribe(
      (data) => {
        this.caseObj = data.data;
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
  getLogBycaseId() {
    if (this.company_id == 0) {
      if (
        this.bu_id == "" ||
        this.support_id == "" ||
        this.sellerId == "" ||
        this.case_id == ""
      ) {
        this.toastrService.error("select required values");
        return false;
      }
    } else {
      if (this.support_id == "" || this.sellerId == "" || this.case_id == "") {
        this.toastrService.error("select required values");
        return false;
      }
    }

    this.sLogSkip = (this.sLogPage - 1) * this.sLogLimit;
    this.ngxSpinnerService.show();
    let companyID =
      JSON.parse(this.bu_id).id == undefined
        ? this.company_id
        : JSON.parse(this.bu_id).id;

    this.registerService
      .getDirectSellerSupportCrudLogs(
        companyID,
        this.case_id,
        this.sLogLimit,
        this.sLogSkip,
        this.supportColname,
        this.supportord
      )
      .subscribe(
        (data) => {
          if (data.status) {
            if (data.data.rows == null) {
              this.directSellerSupportCrudLog = [];
            } else {
              this.directSellerSupportCrudLog = data.data.rows;
            }
            this.supportLogTotalCount = data.data.total;
          } else {
            this.directSellerSupportCrudLog = [];
            this.supportLogTotalCount = 0;
          }
          this.ngxSpinnerService.hide();
        },
        (error) => {
        }
      );
  }
  adminSorting(col) {
    this.supportColname = col;
    if (this.supportord == "asc") {
      this.supportord = "desc";
    } else {
      this.supportord = "asc";
    }
    this.getLogBycaseId();
  }
}
