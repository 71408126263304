import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-update-bu-operator',
  templateUrl: './update-operators-bu-segmentation.component.html',
  styleUrls: ['./update-operators-bu-segmentation.component.css']
})
export class UpdateOperatorsBuSegmentationComponent extends BaseComponent implements OnInit{

  buselected = '';
  buid: any;
  entid: any;
  bumfid: any;
  operList = [];
  buOperList = [];
  operIds = [];
  fieldId: any;
  show = false;
  constructor(private segmentService: BUSegmentationService, private masterSegmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.entid = params.entid;
        this.bumfid = params.bumfid;
      });
  }

  ngOnInit() {
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }

    if (this.entid && this.bumfid) {
      this.ngxSpinnerService.show();
      this.getMasterOperatorsById();
    } else {
      this.router.navigateByUrl('/corporate/bu-segmentation');
    }

  }

  //  ###################  Fetch Master Operators List bu ATtribute ID ##########  //

  getMasterOperatorsById(){
    this.masterSegmentService.getAssociatedOperatorsForAttributes(this.bumfid).subscribe(data => {
      if (data.status) {
        this.operList = data.data;
        this.getAttributePlusOperData();
      } else {
        this.ngxSpinnerService.hide();
        this.operList = [];
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //   ################  Fetch Attribute and Operator data by Entity ID  #############  //

  getAttributePlusOperData() {
    this.show = false;
    this.segmentService.getAttributeandOperatorforBUEntity(this.buid, this.entid).subscribe(data => {
      if (data.status) {
        let op = data.data;
        let dt = op.filter(element => element.mfield_id === parseInt(this.bumfid, 10));
        this.buOperList = dt[0].operator;
        if (this.buOperList.length > 0){
          this.buOperList.forEach(element => {
            this.operIds.push(element.moperator_id);
          });
        }
        this.show = true;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###############  On Operator Select from checkbox ###############  //

  selectOperator(event , id){
    let elem = this.operList.filter(element => element.operator_id === id);
    let i = elem[0].operator_id;
    if (event.target.checked) {
      this.operIds.push(i);
    } else {
      this.operIds.splice(this.operIds.indexOf(i), 1);
    }
  }

  //  #################  Update Operator for BU ###################  //

  updateOperator(){
    if (this.operIds.length === 0) {
      this.toastrService.error('Please select at least one Operator');
      return;
    }
    let obj = {
      'company': this.buid,
      'field_id': parseInt(this.bumfid, 10),
      'operator_id': this.operIds
    };
    this.ngxSpinnerService.show();
    this.segmentService.updateOperatorsForBU(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Operators updated Successfully');
        this.router.navigateByUrl('/corporate/bu-segmentation');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }
}
