import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {McConnectorService} from '../../../services/mcconnector/mc-connector.service';


@Component({
  selector: 'app-mcinformation',
  templateUrl: './mc-information.component.html',
  styleUrls: ['./mc-information.component.css']
})
export class McInformationComponent extends BaseComponent implements OnInit {

  mcinformationform: FormGroup;
  submitted = false;
  companyId: any;

  mcInfoStatus = false;
  mcData: any;
  connectionStatus: any;
  editStatus = false;
  finalStatus = false;

  constructor(private formBuilder: FormBuilder, private mcService: McConnectorService) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
  }
  ngOnInit() {

    this.checkMCInformationData();
    this.createMcForm();
  }

  //  ################  Create MC Information Form  ##################  //

  createMcForm() {

    this.mcinformationform = this.formBuilder.group({
      serviceURL: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(80)]],
      accountId: ['', [Validators.required, Validators.maxLength(80)]],
      clientId: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(80)]],
      clientSecret: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(80)]],
      tokenUrl: [''],
      version: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(80)]],
      grantType: [''],
      scopes: [''],
      readTimeout: [''],
      maxIdleTimeout: ['']

    });

  }

  get f() { return this.mcinformationform.controls; }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }

    //  ##########  Check if MC Information is added for BU or not #############  //

  checkMCInformationData() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcInfoData(this.companyId, 10, 0).subscribe(data => {
      if (data.status) {
        if (data.data.length > 0) {
          this.mcData = data.data[0];
         // console.log('MC DATA', this.mcData);
          this.mcInfoStatus = true;
          this.checkTokenUrl();
        }
      } else {
        // this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.finalStatus = true;
      this.ngxSpinnerService.hide();
    });
  }

    //  ##########  Check Connectivity with Current Data  ##   //

  checkTokenUrl() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcTokenURL(this.companyId).subscribe(data => {
      if (data.status) {
        this.connectionStatus = true;
      } else {
        this.connectionStatus = false;
        // this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.finalStatus = true;
      this.ngxSpinnerService.hide();
    });
  }

    //  ##########  Submit MC Information  #################  //

  submitMCInformation(){
    this.submitted = true;
    if (this.mcinformationform.invalid) {
      return;
    }

    let srvUrl = this.mcinformationform.value.serviceURL.trim();
    if (srvUrl === undefined || srvUrl === null) {
      this.toastrService.error('Please provide correct Service URL');
      return;
    }

    let accid = this.mcinformationform.value.accountId.trim();
    if (accid === undefined || accid === null) {
      this.toastrService.error('Please provide correct  Account ID details');
      return;
    }

    let clid = this.mcinformationform.value.clientId.trim();
    if (clid === undefined || clid === null) {
      this.toastrService.error('Please provide correct Client ID');
      return;
    }

    let cskt = this.mcinformationform.value.clientSecret.trim();
    if (cskt === undefined || cskt === null) {
      this.toastrService.error('Please provide correct Client Secret');
      return;
    }

    let tkurl = this.mcinformationform.value.tokenUrl.trim();
    if (tkurl === undefined || tkurl === null) {
      this.toastrService.error('Please provide correct Token URL value');
      return;
    }

    let vr = this.mcinformationform.value.version.trim();
    if (vr === undefined || vr === null) {
      this.toastrService.error('Please provide correct Version value');
      return;
    }

    let gtyp = this.mcinformationform.value.grantType.trim();
    if (gtyp === undefined || gtyp === null) {
      this.toastrService.error('Please provide correct Grant Type value');
      return;
    }

    let sckp = this.mcinformationform.value.scopes.trim();
    if (sckp === undefined || sckp === null) {
      this.toastrService.error('Please provide correct Scopes value');
      return;
    }

    this.ngxSpinnerService.show();
    let obj = {
      company: this.companyId,
      url: this.mcinformationform.value.serviceURL.trim(),
      account_id: this.mcinformationform.value.accountId.trim(),
      version: this.mcinformationform.value.version.trim(),
      grant_type: this.mcinformationform.value.grantType.trim(),
      client_id: this.mcinformationform.value.clientId.trim(),
      client_secret: this.mcinformationform.value.clientSecret.trim(),
      timeout_interval: this.mcinformationform.value.readTimeout,
      max_idle_timeout: this.mcinformationform.value.maxIdleTimeout,
      scopes: this.mcinformationform.value.scopes.trim(),
      status: true
    };

    this.mcService.addingMCInformation(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Information Saved Successfully');
        this.submitted = false;
        this.createMcForm();

        this.editStatus = false;
        this.mcInfoStatus = false;
        this.checkMCInformationData();


      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  //  ##############  Update MC Information ##################################  //

  updateMCInformation() {
    this.submitted = true;
    if (this.mcinformationform.invalid) {
      return;

    }

    let srvUrl = this.mcinformationform.value.serviceURL.trim();
    if (srvUrl === undefined || srvUrl === null) {
      this.toastrService.error('Please provide correct Service URL');
      return;
    }

    // let accid = this.mcinformationform.value.accountId.trim();
    // if (accid === undefined || accid === null) {
    //   this.toastrService.error('Please provide correct  Account ID details');
    //   return;
    // }

    let clid = this.mcinformationform.value.clientId.trim();
    if (clid === undefined || clid === null) {
      this.toastrService.error('Please provide correct Client ID');
      return;
    }

    let cskt = this.mcinformationform.value.clientSecret.trim();
    if (cskt === undefined || cskt === null) {
      this.toastrService.error('Please provide correct Client Secret');
      return;
    }

    let tkurl = this.mcinformationform.value.tokenUrl.trim();
    if (tkurl === undefined || tkurl === null) {
      this.toastrService.error('Please provide correct Token URL value');
      return;
    }

    let vr = this.mcinformationform.value.version.trim();
    if (vr === undefined || vr === null) {
      this.toastrService.error('Please provide correct Version value');
      return;
    }

    let gtyp = this.mcinformationform.value.grantType.trim();
    if (gtyp === undefined || gtyp === null) {
      this.toastrService.error('Please provide correct Grant Type value');
      return;
    }

    let sckp = this.mcinformationform.value.scopes.trim();
    if (sckp === undefined || sckp === null) {
      this.toastrService.error('Please provide correct Scopes value');
      return;
    }

    this.ngxSpinnerService.show();
    let obj = {
      mcid: this.mcData.mcid,
      company: this.companyId,
      url: this.mcinformationform.value.serviceURL.trim(),
      account_id: this.mcinformationform.value.accountId,
      version: this.mcinformationform.value.version.trim(),
      grant_type: this.mcinformationform.value.grantType.trim(),
      client_id: this.mcinformationform.value.clientId.trim(),
      client_secret: this.mcinformationform.value.clientSecret.trim(),
      timeout_interval: this.mcinformationform.value.readTimeout,
      max_idle_timeout: this.mcinformationform.value.maxIdleTimeout,
      scopes: this.mcinformationform.value.scopes.trim(),
      status: this.mcData.isactive
    };

    this.mcService.updateMCInformation(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Information Updated Successfully');
        this.submitted = false;
        this.editStatus = false;
        this.createMcForm();
        this.checkMCInformationData();
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }


  goToMcDataMapping() {
    this.router.navigateByUrl('/corporate/mc-dataextension');
  }

  //  ##############  Open Form view to update MC Connection DATA ############  //

  goToUpdateFormView() {

    if (this.mcData.url) {
      this.mcinformationform.get('serviceURL').patchValue(this.mcData.url);
    }
    if (this.mcData.account_id) {
      this.mcinformationform.get('accountId').patchValue(this.mcData.account_id);
    }
    if (this.mcData.client_id) {
      this.mcinformationform.get('clientId').patchValue(this.mcData.client_id);
    }
    if (this.mcData.client_secret) {
      this.mcinformationform.get('clientSecret').patchValue(this.mcData.client_secret);
    }
    if (this.mcData.token_url) {
      this.mcinformationform.get('tokenUrl').patchValue(this.mcData.token_url);
    }
    if (this.mcData.version) {
      this.mcinformationform.get('version').patchValue(this.mcData.version);
    }
    if (this.mcData.grant_type) {
      this.mcinformationform.get('grantType').patchValue(this.mcData.grant_type);
    }
    if (this.mcData.scopes) {
      this.mcinformationform.get('scopes').patchValue(this.mcData.scopes);
    }
    if (this.mcData.timeout_interval) {
      this.mcinformationform.get('readTimeout').patchValue(this.mcData.timeout_interval);
    }
    if (this.mcData.max_idle_timeout) {
      this.mcinformationform.get('maxIdleTimeout').patchValue(this.mcData.max_idle_timeout);
    }
    this.editStatus = true;
  }

  //  ##############  Cancel Update for MC Info  #############################  //

  cancelMcInfoUpdate() {
    this.editStatus = false;
    this.createMcForm();
    if (this.submitted) {
      this.submitted = false;
    }
  }

  //  ############  Check Connection Status for Enabling/Disabling Schedule/Mapping ######## //

  checkConnStatus() {
    if (this.connectionStatus) {
      return false;
    } else {
      return true;
    }
  }

  //   ###########  Go TO MC Schedule Form Page  ###############  //

  goToMcSchedule() {
    this.router.navigateByUrl('/corporate/mc-scheduler');

  }
}
