<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }

  .more-padding-on-right {
    padding-left: 50px !important;
  }
</style>
<!-- <ngx-spinner  bdOpacity = 0.9
bdColor = "#333"
size = "large"
color = "#fff"
type = "ball-clip-rotate">
<p style="color: white" > Loading... </p>
</ngx-spinner> -->

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item active">Module</li>

    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1 cursor"
        routerLink="/corporate/addnewmodule">
      <button class="csvBtn cursor" *ngIf="isImport" (click)="importFile()">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn cursor" *ngIf="isExport" (click)="exportFile()">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>


  <!-- <div class="row">
  <div class="col-12 mb-1 col-xl-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>
  </div>
  <div class="col-12 col-xl-4" style="justify-content: right;">
    <input type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/addnewmodule">
    <button class="cbtn" (click)="importFile()" placement="bottom" ngbTooltip="Import">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="cbtn" (click)="exportFile()" placement="bottom" ngbTooltip="Export">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div> 
</div> 
<div *ngIf="moduleStatus" class="mt-1 ngxDataTableClass" style="white-space: nowrap;">
  <ngx-data-table [data]="data" style="white-space: nowrap;" (messageEvent)="receiveMessage($event)"></ngx-data-table>
</div>
<div class="row mt-1">
  <div class="col-8 col-xl-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>
  </div>
  <div class="col-12 col-xl-12" style="justify-content: right;">
    <input type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/addnewrolemaster">
    <button class="cbtn" (click)="importFile()" placement="bottom" ngbTooltip="Import">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="cbtn" (click)="exportFile()" placement="bottom" ngbTooltip="Export">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button> 
  </div>
</div>-->

  <div class="tblPrt">
    <!-- <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0"> 
  
    <tr>
      <th (click)="sorting('name')" style="cursor:pointer">Module Name <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th (click)="sorting('description')" style="cursor:pointer">Module Description <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>Parent Module Name</th>
      <th>CreatedBy</th>
      <th style="width: 130px;">CreatedOn</th>
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>
 
    
      <tr *ngFor="let obj of moduleObj">
        <td *ngIf="obj.parentid!==0" class ="more-padding-on-right">{{obj.name}}</td>
        <td *ngIf="obj.parentid==0"><i class="fa fa-arrow-right mr-1"></i>{{obj.name}}</td>
        <td>{{obj.description}}</td>
        <td>{{obj.parentmodule }}</td>
        <td>{{obj.createdname}}</td>
        <td>{{obj.createdon | date:'mediumDate' }}</td>
       
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editModule(obj.id)"  placement="bottom" ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1 cursor"
              (click)="deleteModule(obj.id)" *ngIf="isEdit" placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle [checked]="obj.active" class="my-slider cursor" *ngIf="isEdit"
              (click)="updateStatus($event,obj.id)">
            </mat-slide-toggle>
          </div>
        </td>        
      </tr>
  </table> -->


    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th style="cursor:pointer">Module Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th style="cursor:pointer">Module Description <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Parent Module Name</th>
        <th>CreatedBy</th>
        <th style="width: 130px;">CreatedOn</th>
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tbody *ngFor="let obj of moduleObj">
        <tr *ngIf="obj.parentid==0">
          <td *ngIf="obj.childcount==0" class="more-padding-on-right">{{obj.name}} </td>
          <td style="cursor: pointer;" *ngIf="obj.childcount!=0" (click)="getChildModuleData(obj.id)">
            <i class="fa fa-arrow-right mr-1"></i>
            {{obj.name}}
          </td>
          <td>{{obj.description}} </td>
          <td>{{obj.parentmodule }}</td>
          <td>{{obj.createdname}}</td>
          <td>{{obj.createdon | date:'mediumDate' }}</td>

          <td *ngIf="isEdit || isDelete">
            <div class="actBtn">
              <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editModule(obj.id)" placement="bottom"
                ngbTooltip="Edit"></i>
              <span class="fa fa-trash mr-1 cursor" (click)="deleteModule(obj.id)" *ngIf="isEdit" placement="bottom"
                ngbTooltip="Delete"></span>
              <mat-slide-toggle [checked]="obj.active" class="my-slider cursor" *ngIf="isEdit"
                (click)="updateStatus($event,obj.id)">
              </mat-slide-toggle>
            </div>
          </td>
        </tr>
        <tr *ngFor="let obj2 of moduleChiledObj">
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid" class="more-padding-on-right">{{obj2.name}}</td>
         
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid">{{obj2.description}}</td>
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid">{{obj2.parentmodule }}</td>
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid">{{obj2.createdname}}</td>
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid">{{obj2.createdon | date:'mediumDate' }}</td>
          <td *ngIf="obj2.parentid!=0 && obj.id==obj2.parentid"></td>
        </tr>
      </tbody>

    </table>

    <!-- <pagination-controls class="my-pagination mt-2" (pageChange)="cp=$event"></pagination-controls>  -->
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
        (pageChange)="getModuleData((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>