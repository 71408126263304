<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
      <li class="breadcrumb-item active" aria-current="page">PII & DSAR Reports</li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">PII & DSAR Reports</h5></legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p>This section will display all Those PII & DSAR Reports</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4  col-md-6">
          <button class="cbtn mt-1" routerLink="/corporate/pii-report-master">
            <!-- <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">--> 
            <i class="fa fa-search red-dr-icon" aria-hidden="true"></i>Master Report  
          </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/pii-report-hq">
          <i class="fa fa-search orange-dr-icon" aria-hidden="true"></i>BU HQ Report 
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
          <button class="cbtn mt-1" routerLink="/corporate/pii-report-bu">
            <i class="fa fa-search blue-lt-icon" aria-hidden="true"></i>BU Reports 
          </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/pii-individual-profile">
          <i class="fa fa-search green-lt-icon" aria-hidden="true"></i>Individual Profile
        </button>
    </div>
    </div>
      
  </fieldset>
</div>


