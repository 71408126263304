import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';


@Component({
  selector: 'app-add-poll',
  templateUrl: './add-poll.component.html',
  styleUrls: ['./add-poll.component.css']
})
export class AddPollComponent extends BaseComponent implements OnInit {
  customer_id;
  addPollForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  seletedOption:any=[];
  webinarPollOptions: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addPollForm = this.formBuilder.group({
      name: ['', [Validators.required,  this.noWhitespaceValidator]],
      description: [''],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],
      options:[[],Validators.required]
    })

    this.addPollForm.get("selectedCompany").valueChanges.subscribe(selectedValue => {
      console.log('address changed')
      console.log(selectedValue);
      this.getWebinarPollOptions(selectedValue)
    })
  }

  getWebinarPollOptions(id:any){
    this.masterService.getWebinarActivePollOptionList(id).subscribe((data) => {
      if (data.status) {
        this.webinarPollOptions = data.data.rows;
      } else {
        this.webinarPollOptions = [];
      }
    });
  }
  getCompany() {
    this.clientsService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  get f() { return this.addPollForm.controls; }

  addNewPollData(){
    this.submitted = true;
    if (this.addPollForm.invalid) {
      return;
    }
    let { name, description, selectedCompany,options } = this.addPollForm.value;
    let obj = {
      title: name,
      description: description,
      isactive: true,
      company: selectedCompany,
      options:options
    }
    this.ngxSpinnerService.show();
    this.masterService.addWebinarPoll(selectedCompany,obj).subscribe(data=>{
      console.log("🚀 ~ file: add-poll.component.ts:58 ~ AddPollComponent ~ this.masterService.addWebinarPoll ~ data:", data)
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Poll added successfully");
        this.router.navigateByUrl(`/corporate/poll`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error.message);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  onChange(id:number,checked:boolean){    
    if(checked){
      this.seletedOption.push(id)
      this.addPollForm.controls.options.setValue(this.seletedOption)
    }
    else{
      this.seletedOption = this.seletedOption.filter((ele:any)=>
      { return ele!=id }
      )
      this.addPollForm.controls.options.setValue(this.seletedOption)
    }
}
}
