<!-- Breadcrumb for application -->
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/pii-report">PII & DSAR Reports</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{projectName}} Master Report</li>
      </ol>
    </nav>
</div>
<!-- Container for application -->
<div class="container mt-2 theme-fa-icon">
    <fieldset class="scheduler-border">
      <legend class="scheduler-border"><h5 class="font-weight-bold">{{projectName}} Master Report</h5></legend>
      <div class="row">
        <div class="col-md-12">
          <p class="mt-1">Under this section we will implement functinality to search any admin user and its related all activity which are related to PII data.</p>
        </div>
      </div>
    </fieldset>
  </div>
  <div class="container mt-2 themeTblReso">
    <div class="row">
        <div class="col-md-8">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                aria-hidden="true"></i></span>
          </div>
          <input class="form-control" type="text" placeholder="Search by user email" aria-label="Search">
        </div>
        </div>
        <div class="col-md-4" style="justify-content: right;">
            <input type="button" value="Search" class="custbtn" routerLink="/corporate/newagerange">
        </div>
    </div>
    <h4 class="mt-1">Individual under Admin Panel</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th width="10%">Username</th>
          <th>Full Name</th>
          <th width="10%">Email</th>
          <th width="10%">Phone</th>
          <th width="10%">Role</th>
          <th width="10%">Created by</th>
          <th width="20%">Last online time</th>
        </tr>
        <tr>
            <td colspan="8">No Result Available</td>
        </tr>
        </table>
    </div> 
</div> 
  