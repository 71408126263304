<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>

      <li class="breadcrumb-item" *ngIf="campaign=='no'">
        <a routerLink="/corporate/audience-builder"> Audience List</a>
      </li>

      <li class="breadcrumb-item" *ngIf="campaign=='yes'">
        <a routerLink="/corporate/campaign-builder"> Campaign Builder</a>
      </li>


      <li class="breadcrumb-item active" aria-current="page">
        Tested Audience Count
      </li>


    </ol>
  </nav>
</div>

<div class="container mt-2">
  <h6 class="font-weight-bold mt-4 mb-4" *ngIf="audData">Audience Name: {{audData.aud_name}}</h6>
  <h6 class="font-weight-bold mt-4 mb-4" *ngIf="audData">Audience Type: {{audData.audience_type}}</h6>

  <div class="row mt-2">
    <div class="col-md-6 col-xl-4 mt-2 mb-2 mt-md-0 text-sm-right">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1">
            <i aria-hidden="true" class="fa fa-search text-white"></i>
          </span>
        </div>
        <input class="form-control" type="text" [(ngModel)]="search" placeholder="Search"
          [formControl]="queryContactField" autofocus />
      </div>
    </div>

    <div class="col-md-6 col-xl-4 mt-2 mb-2 mt-md-0">
      <button class="csvBtn ng-star-inserted" (click)="donwloadCSV()">
        <img class="customimg" src="../../../../assets/custom/images/export.png"
          style="height: 45px; margin-top: -8px;"></button>
    </div>
  </div>

</div>

<div class="row mt-2">
  <div class="col-md-12 col-xl-12">


    <div class="card">
      <div class="card-body">

        <div class="w-100" *ngIf="audData">
          <h6 class="font-weight-bold  float-right">Total Count: {{totalItems}}</h6>
        </div>


        <div class="tblPrt mt-4 w-100" *ngIf="audData && !show">
          <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width: 900px;">
            <tr>

              <th>Full Name </th>
              <th>Email</th>
              <th>Contact</th>
              <th>User Fullname</th>
              <th>DS</th>
              <th>BU Id</th>

            </tr>
            <tr *ngFor="let obj of audData.result">
              <td>{{obj.contact_fname}} {{obj.contact_lname}}</td>

              <td>{{obj.contact_email}}</td>
              <td style="white-space: nowrap;">{{obj.contact_phone}}</td>
              <td>{{obj.user_fname}} {{obj.user_lname}}</td>
              <td>{{obj.username}}</td>
              <td>{{obj.bu_customer_id}}</td>
            </tr>

          </table>

        </div>
        <div class="container mt-2" *ngIf="audData && audData?.result && audData?.result.length === 0">
          <p>No Result Available</p>
        </div>


        <div class="mt-2 text-center difPagination" *ngIf="audData && audData?.result && audData?.result.length > 0  && totalItems > 10">


          <ngb-pagination [collectionSize]="totalItems" [pageSize]="10" [(page)]="cp" [maxSize]="2" [rotate]="true"

            (pageChange)="fetchData(cp=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div>

      </div>
      <div class="card-footer">
        <div class="text-center my_class " *ngIf="isWrite">
          <!--            <button type="button" class="custbtn" >UPDATE</button>-->
          <!--            <button type="button" class="custbtn" (click)="goToViewAudience()">BACK</button>-->
          <!--            <button type="button" class="custbtn" (click)="goToScheduleAudience()">SCHEDULE</button>-->
        </div>

      </div>
    </div>
  </div>
</div>