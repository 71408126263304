import {BaseComponent} from '../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {McConnectorService} from '../../../services/mcconnector/mc-connector.service';

@Component({
  selector: 'app-mc-scheduler',
  templateUrl: './mc-scheduler.component.html',
  styleUrls: ['./mc-scheduler.component.css']
})
export class McSchedulerComponent extends BaseComponent implements OnInit{

  mcschedulerform: FormGroup;
  submitted = false;
  minStartDate = new Date();
  minEndDate = new Date();
  stTime = { hour: 0, minute: 0 };
  endTime = { hour: 0, minute: 0 };
  maxdate: any;
  timezones = [];
  connectionStatus: any;
  companyId: any;
  mcData: any;

  mCschedulerData = [];
  status = false;
  schStatus = false;

  constructor(private formBuilder: FormBuilder, private mcService: McConnectorService) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
    this.minStartDate.setHours(0,0,0);
    this.minEndDate.setHours(0,0,0);
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getTimezones();
    this.checkMCInformationData();
    this.createScheduleForm();

  }

  //  ##########  Create Schedule Form  ######################################  //

  createScheduleForm() {
    this.mcschedulerform = this.formBuilder.group({
      scheduleType: ['true', Validators.required],
      scheduleStartDate: ['', Validators.required],
      tzone: ['', Validators.required],
      freq: [1, Validators.required],
      freqType: ['Day', Validators.required],
      endSchedNum: [1, Validators.required],
      scheduleEndDate: ['', Validators.required]
    });
  }

  //  ##########  Check if MC Information is added for BU or not #############  //

  checkMCInformationData() {
    this.ngxSpinnerService.show();
    this.mcService.checkMcInfoData(this.companyId, 10, 0).subscribe(data => {
      if (data.status && data.data.length > 0) {
        this.mcData = data.data[0];
        this.checkTokenUrl();
      } else {
         this.toastrService.error(data.message);
         this.router.navigateByUrl('/corporate/mc-information');
        this.ngxSpinnerService.hide();
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##########  Check Connectivity with Current Data  ##   //

  checkTokenUrl() {
    this.mcService.checkMcTokenURL(this.companyId).subscribe(data => {
      if (data.status) {
        this.connectionStatus = true;
        this.fetchSchedulerInfo();
      } else {
        this.toastrService.error('Cannot connect with provided Credentials');
        this.connectionStatus = false;
        this.router.navigateByUrl('/corporate/mc-information');
        this.ngxSpinnerService.hide();
        // this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/mc-information');
    });
  }

  //  #########  Fetch Scheduler Information  ################  //

  fetchSchedulerInfo() {
    this.mcService.getMcSchedulerInfo(this.companyId).subscribe(data => {

      if (data.status) {
        this.mCschedulerData = data.data;
        this.schStatus = true;
      } else {

        // this.toastrService.error(data.message);
      }
      this.status = true;

      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }

  get f() { return this.mcschedulerform.controls; }

  //  ##################  Get Timezones      #######################  //

  getTimezones() {
    this.mcService.getTimezones().subscribe(data => {
      if (data.status) {
        this.timezones = data.data;
      } else {
        this.toastrService.error(data.message);
      }
     // this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    //  this.ngxSpinnerService.hide();
    });
  }

  //  ##################  Submit MC Schedule #######################  //

  submitSchedule() {
    this.submitted = true;
    if (this.mcschedulerform.value.scheduleType === 'false') {

      let obj = {
        mcid: this.mcData.mcid,
        company: this.companyId,
        schedule_type: this.mcschedulerform.value.scheduleType,
        schedule_start: '',
        schedule_timezone: '',
        schedule_interval_value: '',
        schedule_interval_type: '',
        schedule_end: '',
        status: true,
      };


      this.mcService.createMcSchedule(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success('Schedule created Successfully');
          this.submitted = false;
          this.createScheduleForm();
          this.fetchSchedulerInfo();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
        //console.log(error);
        this.ngxSpinnerService.hide();
      });

    } else {

      if (this.mcschedulerform.invalid) {
        return;
      }

      if (this.mcschedulerform.value.freqType === 'Minute') {
        if (this.mcschedulerform.value.freq < 5) {
          this.toastrService.error('Please select a frequency greater than 5 minutes');
          return;
        }
      }

      let tmpDt = new Date(this.mcschedulerform.value.scheduleStartDate);

      tmpDt.setHours(this.stTime.hour, this.stTime.minute, 0);


      let chkTm = 5 * 60 * 1000;

      if (tmpDt.getTime() - new Date().getTime() < chkTm){
        this.toastrService.error('Please select a Start Time greater than current Time');
        return;
      }

      let tmpEndDt = new Date(this.mcschedulerform.value.scheduleEndDate);

      tmpEndDt.setHours(this.endTime.hour, this.endTime.minute, 0);
      if ( tmpEndDt.getTime() - new Date().getTime() < chkTm){
        this.toastrService.error('Please select an End Time greater than current Time');
        return;
      }

      if ( tmpEndDt.getTime() - tmpDt.getTime() < chkTm) {
        this.toastrService.error('End Time should be greater than Start Time');
        return;
      }

      this.ngxSpinnerService.show();

      let tz = this.timezones.filter( tzo => tzo.value === this.mcschedulerform.value.tzone );

      let utc = tz[0].utc[0];

      let obj = {
        mcid: this.mcData.mcid,
        company: this.companyId,
        schedule_type: this.mcschedulerform.value.scheduleType,
        schedule_start: tmpDt.toISOString(),
        schedule_timezone: utc,
        schedule_interval_value: this.mcschedulerform.value.freq,
        schedule_interval_type: this.mcschedulerform.value.freqType,
        schedule_end: tmpEndDt.toISOString(),
        status: true
      };


      this.mcService.createMcSchedule(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success('Schedule created Successfully');
          this.submitted = false;
          this.createScheduleForm();
          this.fetchSchedulerInfo();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  }

  //  ##################  Edit/Update view for Scheduler  #################  //

  viewEditSchedule() {
    this.schStatus = false;

    if (this.mCschedulerData[0].schedule_type === false) {
      this.mcschedulerform.get('scheduleType').patchValue('false');
    } else {
      this.mcschedulerform.get('scheduleType').patchValue('true');
      let sdt = new Date(this.mCschedulerData[0].schedule_start);
      let stHr = sdt.getHours();
      let stMin = sdt.getMinutes();
      this.stTime = {
        'hour' : stHr,
        'minute':stMin
      };
      sdt.setHours(0, 0, 0);
      this.mcschedulerform.get('scheduleStartDate').patchValue(sdt);
      let endt = new Date(this.mCschedulerData[0].schedule_end);
      let endHr = endt.getHours();
      let endMin = endt.getMinutes();
      this.endTime = {
        'hour' : endHr,
        'minute': endMin
      };
      endt.setHours(0, 0, 0);
      this.mcschedulerform.get('scheduleEndDate').patchValue(endt);
      this.mcschedulerform.get('freq').patchValue(this.mCschedulerData[0].schedule_interval_value);
      this.mcschedulerform.get('freqType').patchValue(this.mCschedulerData[0].schedule_interval_type);
      let stat = false;
      var  i = 0;
      for (let tz of this.timezones) {
        if (stat) {
          break;
        }
        for (let tc of tz.utc) {
          if (tc === this.mCschedulerData[0].schedule_timezone) {
            stat = true;
            this.mcschedulerform.get('tzone').patchValue(tz.value);
            break;
          } else {
            i++;
          }
        }
      }
    }
  }

  //  ################# Cancel Update Schedule ###########################  //

  cancelUpdateSchedule() {
    this.submitted = false;
    this.createScheduleForm();
    this.stTime = {
      'hour' : 0,
      'minute': 0
    };
    this.endTime = {
      'hour' : 0,
      'minute': 0
    };
    this.schStatus = true;
  }

  //  ################# Update MC Scheduler  ############################  //

  updateSchedule() {
    this.submitted = true;
    if (this.mcschedulerform.value.scheduleType === 'false') {

      let obj = {
        mcid: this.mCschedulerData[0].mcid,
        mcsid: this.mCschedulerData[0].mcsid,
        company: this.companyId,
        schedule_type: this.mcschedulerform.value.scheduleType,
        schedule_start: '',
        schedule_timezone: '',
        schedule_interval_value: '',
        schedule_interval_type: '',
        schedule_end: '',
        status: true,
      };


      this.mcService.updateMcSchedule(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success('Schedule Updated Successfully');
          this.submitted = false;
          this.createScheduleForm();
          this.fetchSchedulerInfo();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
      //  console.log(error);
        this.ngxSpinnerService.hide();
      });

    } else {

      if (this.mcschedulerform.invalid) {
        return;
      }

      if (this.mcschedulerform.value.freqType === 'Minute') {
        if (this.mcschedulerform.value.freq < 5) {
          this.toastrService.error('Please select a frequency greater than 5 minutes');
          return;
        }
      }



      let tmpDt = new Date(this.mcschedulerform.value.scheduleStartDate);

      tmpDt.setHours(this.stTime.hour, this.stTime.minute, 0);


      let chkTm = 5 * 60 * 1000;

      if (tmpDt.getTime() - new Date().getTime() < chkTm){
        this.toastrService.error('Please select a Start Time greater than current Time');
        return;
      }

      let tmpEndDt = new Date(this.mcschedulerform.value.scheduleEndDate);

      tmpEndDt.setHours(this.endTime.hour, this.endTime.minute, 0);
      if ( tmpEndDt.getTime() - new Date().getTime() < chkTm){
        this.toastrService.error('Please select an End Time greater than current Time');
        return;
      }

      if ( tmpEndDt.getTime() - tmpDt.getTime() < chkTm) {
        this.toastrService.error('End Time should be greater than Start Time');
        return;
      }

      this.ngxSpinnerService.show();

      let tz = this.timezones.filter( tzo => tzo.value === this.mcschedulerform.value.tzone );

      let utc = tz[0].utc[0];

      let obj = {
        mcid: this.mCschedulerData[0].mcid,
        mcsid: this.mCschedulerData[0].mcsid,
        company: this.companyId,
        schedule_type: this.mcschedulerform.value.scheduleType,
        schedule_start: tmpDt.toISOString(),
        schedule_timezone: utc,
        schedule_interval_value: this.mcschedulerform.value.freq,
        schedule_interval_type: this.mcschedulerform.value.freqType,
        schedule_end: tmpEndDt.toISOString(),
        status: true
      };


      this.mcService.updateMcSchedule(obj).subscribe(data => {
        if (data.status) {
          this.toastrService.success('Schedule Updated Successfully');
          this.submitted = false;
          this.createScheduleForm();
          this.fetchSchedulerInfo();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }

      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  }

  //   ##################  Go to scheduler stats details view ################  //

  goToSchedulerStats() {
    this.router.navigateByUrl('/corporate/mc-scheduler-stats');
  }

  //  #################  Go to MC Schedule page view  ####################  //

  goToMcInformation() {
    this.router.navigateByUrl('/corporate/mc-information');
  }

  //  #################  Go to MC Mapping View ###########################  //

  goToMcDataMapping() {
    this.router.navigateByUrl('/corporate/mc-dataextension');
  }
}
