import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { ClientService } from 'src/app/services/clients/client.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { BaseComponent } from '../../base.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
;

@Component({
  selector: 'app-shoplive-category',
  templateUrl: './shoplive-category.component.html',
  styleUrls: ['./shoplive-category.component.css']
})
export class ShopliveCategoryComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  getCompanyobj: any = [];
  categoryObj: any = [];

  order: string = 'createdon';
  reverse: boolean = true;

  selectedCompany : any = '';
  currentUser;
  companyID = 0;

  updateForm: FormGroup;
  postflag1: boolean = false;
  postflag2: boolean = false;
  datacount: any;
  oldcatid;
  categorynewdata;
  catname;
  submitted = false;
  categoryId;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatTotal: number = 0;

  constructor(protected confirmationDialogService: ConfirmationDialogService,
    private clientService: ClientService,
    private shopLiveService: ShopLiveService,
    public orderPipe: OrderPipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal) { super(); }

  ngOnInit() {
    localStorage.removeItem("edit_category_id");
    localStorage.removeItem("category_edit_company_id");
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getCategory();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();

    this.categoryLogData();
    this.updateForm = this.formBuilder.group({
      oldcatid: ['', Validators.required],
      categoryId: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.shopLiveService.getCategoryList(this.selectedCompany, this.slCatLimit, this.slCatSkip, this.slCatColname, this.slCatord, '', this.searchText.nativeElement.value))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = res.data.rows }
          this.slCatTotal = res.data.total;
        } else {
          this.categoryObj = [];
          this.slCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.categoryObj = [];
        this.slCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

  get fud() { return this.updateForm.controls; }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  getCategory() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.shopLiveService.getCategoryList(this.selectedCompany, this.slCatLimit, this.slCatSkip, this.slCatColname, this.slCatord, '', searchTerm).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = data.data.rows }
          this.slCatTotal = data.data.total;
        } else {
          this.categoryObj = [];
          this.slCatTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.categoryObj = [];
      this.selectedCompany = "";
      this.slCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }

  categoryLogData() {
    let CategoryLogs = {
      log_type: "Category View",
      log_name: "Category View",
      log_description: "Category View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CategoryLogs).subscribe(data => {
      // console.log(data);
    }, error => {
      // console.log(error);
    });
  }


  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getCategory()
  }

  editPost(id) {
    localStorage.setItem("edit_category_id", id);
    localStorage.setItem("category_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/shoplive-editcategory']);
  }

  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = {
      status: (status == true) ? false : true,
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.status) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.shopLiveService.updateCategoryStatus(id, this.selectedCompany, obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Updated Successfully");
              this.getCategory();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }

  cancelCat() {
    this.modalService.dismissAll();
  }

  delCategoryUserData() {
    let catId = this.updateForm.get('oldcatid').value;
    this.ngxSpinnerService.show();
    this.shopLiveService.delCategory(catId, this.selectedCompany).subscribe(data => {
      if (data.status) {
        this.postflag1 = false;
        this.postflag2 = false;
        this.toastrService.success('Category Deleted Successfully');
        this.modalService.dismissAll();
        this.getCategory();
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  cancelEvent(catid, cname) {
    this.postflag1 = false;
    this.postflag2 = false;
    this.oldcatid = catid;
    var catdata = { sl_cat_id: catid, company: this.selectedCompany };
    this.updateForm = this.formBuilder.group({
      oldcatid: [this.oldcatid, Validators.required],
      categoryId: ['', Validators.required],
      company: [this.selectedCompany]
    });
    this.catname = cname;
    this.ngxSpinnerService.show();
    this.shopLiveService.cancelCat(catdata).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.datacount = data.data;
        console.log('this.datacount', this.datacount)
        if (this.datacount[0] > 0 || this.datacount[1] > 0) {
          this.postflag1 = true;
          this.postflag2 = false;
          this.shopLiveService.getUnusedCategory(catdata).subscribe(data => {
            this.categorynewdata = data.data;
          });
        } else {
          this.postflag1 = false;
          this.postflag2 = true;
        }
      } else {
        this.postflag1 = false;
        this.postflag2 = true;
      }
    });
  }

  updatepostincategory(data: any) {
    if (this.updateForm.invalid) {
      this.submitted = true;
      return;
    }
    let catdata = {
      sl_old_cat_id: this.oldcatid,
      sl_cat_id: this.updateForm.get('categoryId').value,
      company: this.selectedCompany
    };
    this.ngxSpinnerService.show();
    this.shopLiveService.updatepostincategory(catdata).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Successfully Deleted");
        this.modalService.dismissAll();
        this.getCategory();
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, err => { console.log(err) });
  }

  delCategory(name, id, catname) {
    this.cancelEvent(id, catname)
    this.modalService.open(name, { size: <any>'sm' });
  }
}
