import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientService } from 'src/app/services/clients/client.service';
@Component({
  selector: 'app-report-message-utilization',
  templateUrl: './report-message-utilization.component.html',
  styleUrls: ['./report-message-utilization.component.css']
})
export class ReportMessageUtilizationComponent implements OnInit {
  reportData: any[] = [];
  monthsArr: any[] = [];
  selectedMonth: string = '';
  companyID = 0;
  companyList: any = [];
  monthlyOpeningBalance: number = 0;
  dataUsed: number = 0;
  dateArr: string[];
  currentMonth: boolean = true;
  totalMsgConsumedByAllCustomer: number = 0;
  closingBalance: number = 0;
  monthNames:string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentUser: any;
  showResult:boolean=false;
  isHeadquater;
  customerID

  constructor(private report: ReportService, 
    private spinner: NgxSpinnerService,
    private clientService:ClientService) { }

  ngOnInit() {
    this.spinner.show();
    var my_date = new Date();   
    this.getMonthsArray();
    this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());
    this.selectedMonth = this.monthNames[my_date.getMonth()] + " : " + my_date.getFullYear();
   
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
    this.customerID= customerInfo.admin_customer_id;
    // console.log("this.customerID==",this.customerID)
    this.companyID = this.customerID;
   
    this.isHeadquater = localStorage.getItem("customer_isheadquarter");
    
    if(this.companyID==0 || this.isHeadquater ==="true")  
    {
      this.isHeadquater = true;
      this.getAllBU();
      this.companyID =0;
  
    }
    else {
      this.isHeadquater = false;
      this.getMessageBalance(this.dateArr[0], this.dateArr[1] );
      this.closingBalance = this.monthlyOpeningBalance - this.totalMsgConsumedByAllCustomer;
      this.showResult=true;
    }
  }

  fetchCustomerData() {
    if( this.companyID!=0 && this.selectedMonth!='no') {
      this.showResult=true;
    // console.log(this.selectedMonth);
      var my_date = new Date();
      var monArr = this.selectedMonth.split(' : ');
    //  console.log(this.monthNames, monArr, this.monthNames.indexOf(monArr[0]), parseInt(monArr[1]));
      if(my_date.getMonth() == this.monthNames.indexOf(monArr[0].trim()) && (my_date.getFullYear() == parseInt(monArr[1].trim())))
        this.currentMonth = true;
      else
        this.currentMonth = false;

      this.dateArr = this.getFirstLastDateOfMonth(this.monthNames.indexOf(monArr[0]), parseInt(monArr[1]));

      this.getMessageBalance(this.dateArr[0], this.dateArr[1] );

      if(this.currentMonth) {
        this.getMsgConsumption(this.dateArr[0], this.dateArr[1])
        this.getMsgConsumptionOfAllCustomers(this.dateArr[0], this.dateArr[1])
      } else {
        this.getHistoricalDataMonthly(this.dateArr[0], this.dateArr[1] );
        this.getHistoricalDataMonthlyOfAllCustomer(this.dateArr[0], this.dateArr[1] );
      }
    } 
    else this.showResult=false;
  }

  getAllBU() {
    this.spinner.show();
    this.clientService.getBUCompanies().subscribe(data => {
      this.companyList = data.data;
     
      this.spinner.hide();
     // console.log(this.companyList);
    }, error => {
      this.spinner.hide();
     // console.log(error);
    });
  }

  getHistoricalDataMonthly(startDate, endDate) {
    this.spinner.show();
    this.report.getHistoricalMsgConsumption(startDate, endDate, this.companyID).subscribe((data) => {
      this.dataUsed = data.data;
      this.spinner.hide();
    });
  }

  getHistoricalDataMonthlyOfAllCustomer(startDate, endDate) {
    this.spinner.show();
    this.totalMsgConsumedByAllCustomer = 0;
    this.report.getHistoricalMsgConsumedByAll(startDate, endDate).subscribe((data) => {
      if(data.status) {}
        this.totalMsgConsumedByAllCustomer = data.data != null ? data.data: 0;
        this.closingBalance = this.monthlyOpeningBalance - this.totalMsgConsumedByAllCustomer;
      this.spinner.hide();
    });
  }

  getMessageBalance(startDate, endDate) {
    this.spinner.show();
    this.report.getMessageBalance(startDate, endDate).subscribe((data) => {
      this.monthlyOpeningBalance = data.data;
      this.closingBalance = this.monthlyOpeningBalance - this.totalMsgConsumedByAllCustomer;
      this.spinner.hide();
    });
  }

  getFirstLastDateOfMonth(month, year) {
    var my_date = new Date(year, month, 1);
    var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    let formatted_first_date = first_date.getFullYear() + "-" + (first_date.getMonth() + 1) + "-" + first_date.getDate();

    var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    let formatted_last_date = last_date.getFullYear() + "-" + (last_date.getMonth() + 1) + "-" + last_date.getDate();
  //  console.log(formatted_first_date, formatted_last_date);
    return ([formatted_first_date, formatted_last_date]);
  }

  getMsgConsumption(startDate, endDate, custId = this.companyID) {
    this.spinner.show();
    this.report.getMsgConsumption(startDate, endDate, custId).subscribe((data) => {
      this.dataUsed = data.data;
      this.spinner.hide();
    });
  }

  getMsgConsumptionOfAllCustomers(startDate, endDate) {
    this.spinner.show();
    this.totalMsgConsumedByAllCustomer = 0;
    this.companyList.forEach(element => {
      this.report.getMsgConsumption(startDate, endDate, element.customer_id).subscribe((data) => {
        if(data.status)
          this.totalMsgConsumedByAllCustomer = this.totalMsgConsumedByAllCustomer+data.data;
        this.spinner.hide();
      });
    });
    
  }

  getMonthsArray() {
    var today = new Date();
    var d: any;
    var month: string;
    var currentYear = today.getFullYear();
    if(today.getMonth() < 11)
      var lastyear = currentYear - 1;

      //console.log(today.getMonth() );
      var currentMonth = today.getMonth()+1;

    var tempArr = [];
    for(var i = 11; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = this.monthNames[d.getMonth()];
      if(i<currentMonth)
       tempArr.push(month + " : " + currentYear)      
      else tempArr.push(month + " : " + lastyear)
    }
  //  console.log("MonthsArr");
   // console.log(tempArr);
    this.monthsArr = tempArr;
  }
}