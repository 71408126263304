<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">E-commerce Summary Report</li>
    </ol>
  </nav>
  <!-- <div>
    <span class="imageWrapper"> <img class="customimg" src="../../../assets/custom/images/export.png"></span>
  </div> -->
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">

    <div class="col-md-3 mt-2"  *ngIf="isHeadquater">
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="fetchCustomerData($event.target.value)">
        <option selected="selected" value=0>Select Company</option>
        <option *ngFor="let userObj of companyList" [value]="userObj.customer_id">{{userObj.customer_company_name}}
        </option>
      </select>
    </div>
    <!-- <div class="col-md-9 mt-2">
      <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png" style="height: 45px;"> </a>
    </div> -->
    <!-- <div class="col-md-4 mt-2">
      <select name="month" class="form-control formstyle" [(ngModel)]="selectedMonth" (change)="fetchCustomerData()">
        <option>Select Month</option>
        <option *ngFor="let ele of monthsArr" [value]="ele">{{ele}}</option>
      </select>
    </div> -->
    <!-- <div class="col-sm-2">
      <span style="margin-left: 30px;"></span>
      <button class="btn btn-margo-action ml-0 form-control formstyle" (click)="generateReport()" style="align-items: center;">Search</button>
    </div>-->
  </div>


<div class="tblPrt" *ngIf="showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="reportData.length > 0">
    <tr>
      <th>Number of Campaigns</th>
      <th>Message Clicked</th>
      <th>Attributed No. of Purchased</th>
      <th>Total Attributed Purchased Amount</th>
      <th>Total Purchased Amount</th>
    </tr>
      <tr *ngFor="let itm of reportData">
        <td>{{itm.total_campaign}}</td>
        <td>{{itm.message_by==null?'0':itm.message_by}}</td>
        <td>{{itm.total_quantity == null?'0':itm.total_quantity}}</td>
        <td>${{itm.attr_no_purchased == null?'0':itm.attr_no_purchased}}</td>
        <td>${{itm.total_amt == null?'0':itm.total_amt}}</td>
      </tr>
  </table>

  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="reportData.length == 0">
    <tr>
      <th>Number of Campaigns</th>
      <th>Message Clicked</th>
      <th>Attributed No. of Purchased</th>
      <th>Total Attributed Purchased Amount</th>
      <th>Total Purchased Amount</th>
    </tr>
  </table>
</div>

<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td colspan="5">No results available</td>                            
      </tr>  
  </table>
</div>
</div>