<p style="text-align: right;"> <input type="button" value="Back" class="custbtn" routerLink="/corporate/home" >
</p>

<p>


  

 <app-einstein-dashboard 
  [token]="token" 
  [appName]="lightningAppName" 
  [url]="SFDomainURL" 
  [componentName]="lightningComponentName"
  [DashboardId]="dashboardId"
  [BuName]="BuName"
  ></app-einstein-dashboard>
  
</p>

  <p></p>

 
