import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';

import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgotUserComponent } from './auth/forgot-user/forgot-user.component';
import { ForgotPasswrodComponent } from './auth/forgot-passwrod/forgot-passwrod.component';
import { RoleGuard } from './_guards/role.guard';
import { SecondadminComponent } from './secondadmin/secondadmin.component';
import { HomeComponent } from './secondadmin/home/home.component';
import { UsersComponent } from './secondadmin/usertypes/users/users.component';
import { RolesComponent } from './secondadmin/roles/roles.component';
import { MargoproductsComponent } from './secondadmin/margoproducts/margoproducts.component';
import { ReportsComponent } from './secondadmin/reports/reports.component';
import { NewusercontactComponent } from './secondadmin/usertypes/users/newusercontact/newusercontact.component';
import { RolesnewuserComponent } from './secondadmin/roles/rolesnewuser/rolesnewuser.component';
import { MargonewcontactComponent } from './secondadmin/margoproducts/margonewcontact/margonewcontact.component';
import { ReportsnewcntactComponent } from './secondadmin/reports/reportsnewcntact/reportsnewcntact.component';
import { ClientnewcontactComponent } from './secondadmin/clients/clientnewcontact/clientnewcontact.component';
import { HostDsComponent } from "./secondadmin/usertypes/host-ds/host-ds.component";
import { MasterComponent } from './secondadmin/master/master.component';
import { ThememasterComponent } from './secondadmin/master/thememaster/thememaster.component';
import { JourneymasterComponent } from './secondadmin/master/journeymaster/journeymaster.component';
import { TrainingtypemasterComponent } from './secondadmin/master/trainingtypemaster/trainingtypemaster.component';
import { EventtypemasterComponent } from './secondadmin/master/eventtypemaster/eventtypemaster.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { ActionmasterComponent } from './secondadmin/master/actionmaster/actionmaster.component';
import { MenumasterComponent } from './secondadmin/master/menumaster/menumaster.component';
import { CountrymasterComponent } from './secondadmin/master/countrymaster/countrymaster.component';
import { CurrencymasterComponent } from './secondadmin/master/currencymaster/currencymaster.component';
import { IndustrymasterComponent } from './secondadmin/master/industrymaster/industrymaster.component';
import { CompanytypemasterComponent } from './secondadmin/master/companytypemaster/companytypemaster.component';
import { ProfessionmasterComponent } from './secondadmin/master/professionmaster/professionmaster.component';
import { LoyalitytypemasterComponent } from './secondadmin/master/loyalitytypemaster/loyalitytypemaster.component';
import { ProspecttypemasterComponent } from './secondadmin/master/prospecttypemaster/prospecttypemaster.component';
import { NewthemeComponent } from './secondadmin/master/thememaster/newtheme/newtheme.component';
import { AddnewjourneyComponent } from './secondadmin/master/journeymaster/addnewjourney/addnewjourney.component';
import { AddnewmenuComponent } from './secondadmin/master/menumaster/addnewmenu/addnewmenu.component';
import { AddnewcountryComponent } from './secondadmin/master/countrymaster/addnewcountry/addnewcountry.component';
import { AddnewcurrencyComponent } from './secondadmin/master/currencymaster/addnewcurrency/addnewcurrency.component';
import { AddnewprofessionComponent } from './secondadmin/master/professionmaster/addnewprofession/addnewprofession.component';
import { AddnewloyalityComponent } from './secondadmin/master/loyalitytypemaster/addnewloyality/addnewloyality.component';
import { AddnewprospectComponent } from './secondadmin/master/prospecttypemaster/addnewprospect/addnewprospect.component';
import { PiComponent } from './secondadmin/pi/pi.component';
import { AddnewcompanyComponent } from './secondadmin/master/companytypemaster/addnewcompany/addnewcompany.component';
import { AddnewtrainingtypeComponent } from './secondadmin/master/trainingtypemaster/addnewtrainingtype/addnewtrainingtype.component';
import { AddneweventComponent } from './secondadmin/master/eventtypemaster/addnewevent/addnewevent.component';
import { AddnewindustryComponent } from './secondadmin/master/industrymaster/addnewindustry/addnewindustry.component';
import { PermissionComponent } from './secondadmin/master/permission/permission.component';
import { AddnewpermissionsComponent } from './secondadmin/master/permission/addnewpermissions/addnewpermissions.component';
import { RoleMasterComponent } from './secondadmin/master/role-master/role-master.component';
import { AddnewroleMasterComponent } from './secondadmin/master/role-master/addnewrole-master/addnewrole-master.component';
import { ModuleMasterComponent } from './secondadmin/master/module-master/module-master.component';
import { AddnewmoduleComponent } from './secondadmin/master/module-master/addnewmodule/addnewmodule.component';
import { ModuleitemsComponent } from './secondadmin/master/moduleitems/moduleitems.component';
import { AddnewmoduleitemsComponent } from './secondadmin/master/moduleitems/addnewmoduleitems/addnewmoduleitems.component';
import { SetusernameComponent } from './auth/setusername/setusername.component';
import { ClienteditcontactComponent } from './secondadmin/clients/clienteditcontact/clienteditcontact.component'
import { ClientHomeComponent } from './secondadmin/clients/client-home/client-home.component';
import { ClientHeadquaterComponent } from './secondadmin/clients/client-headquater/client-headquater.component';
import { ClientsComponent } from './secondadmin/clients/clients.component';
import { FacebookComponent } from './secondadmin/facebook/facebook.component';
import { PostmanagementComponent } from './secondadmin/facebook/postmanagement/postmanagement.component';
import { CampaignComponent } from './secondadmin/facebook/campaign/campaign.component';
import { CategoryComponent } from './secondadmin/facebook/category/category.component';
import { AddnewcampaignComponent } from './secondadmin/facebook/campaign/addnewcampaign/addnewcampaign.component';
import { AddnewcategoryComponent } from './secondadmin/facebook/category/addnewcategory/addnewcategory.component';
import { AddnewpostComponent } from './secondadmin/facebook/postmanagement/addnewpost/addnewpost.component';
import { MessageSnippetsComponent } from './secondadmin/facebook/message-snippets/message-snippets.component';
// import { AddnewrolesComponent } from './secondadmin/master/roles/addnewroles/addnewroles.component';
import { AddnewmessageComponent } from './secondadmin/facebook/message-snippets/addnewmessage/addnewmessage.component'
import { MyprofileComponent } from './myprofile/myprofile.component';
import { EditmyprofileComponent } from './myprofile/editmyprofile/editmyprofile.component';
import { LogsComponent } from './logs/logs.component';
import { UsertypesComponent } from './secondadmin/usertypes/usertypes.component';
import { DirectsellerComponent } from './secondadmin/usertypes/directseller/directseller.component';
import { AddnewsellerComponent } from './secondadmin/usertypes/directseller/addnewseller/addnewseller.component';
import { MasterthemecategoryComponent } from './secondadmin/master/masterthemecategory/masterthemecategory.component';
import { NewthemecategoryComponent } from './secondadmin/master/masterthemecategory/newthemecategory/newthemecategory.component';
import { JourneytypeComponent } from './secondadmin/master/journeytype/journeytype.component';
import { NewjourneytypeComponent } from './secondadmin/master/journeytype/newjourneytype/newjourneytype.component';
import { AssetComponent } from './secondadmin/master/asset/asset.component';
import { NewassetComponent } from './secondadmin/master/asset/newasset/newasset.component'
import { ContacttypeComponent } from './secondadmin/master/contacttype/contacttype.component';
import { NewcontacttypeComponent } from './secondadmin/master/contacttype/newcontacttype/newcontacttype.component';
import { GenderComponent } from './secondadmin/master/gender/gender.component';
import { NewgenderComponent } from './secondadmin/master/gender/newgender/newgender.component';
import { AgerangeComponent } from './secondadmin/master/agerange/agerange.component';
import { NewagerangeComponent } from './secondadmin/master/agerange/newagerange/newagerange.component'
import { HelpsComponent } from './secondadmin/helps/helps.component';
import { TipsComponent } from './secondadmin/helps/tips/tips.component';
import { SupportComponent } from './secondadmin/helps/support/support.component';
import { SubjectlibComponent } from './secondadmin/helps/subjectlib/subjectlib.component';
import { NewsubjectlibComponent } from './secondadmin/helps/subjectlib/newsubjectlib/newsubjectlib.component';
import { MessagelibComponent } from './secondadmin/helps/messagelib/messagelib.component';
import { NewmessagelibComponent } from './secondadmin/helps/messagelib/newmessagelib/newmessagelib.component';
import { FbreportsComponent } from './secondadmin/facebook/fbreports/fbreports.component';
import { PiiTableComponent } from './secondadmin/pi/pii-table/pii-table.component';
import { PiiFieldComponent } from './secondadmin/pi/pii-field/pii-field.component';
import { PiiDataComponent } from './secondadmin/pi/pii-data/pii-data.component';
import { PiiDataViewComponent } from './secondadmin/pi/pii-data-view/pii-data-view.component';
import { PiiDsarComponent } from './secondadmin/pi/pii-dsar/pii-dsar.component';
import { PiiReportComponent } from './secondadmin/pi/pii-report/pii-report.component';
import { NotauthorisedComponent } from './notauthorised/notauthorised.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SubscriptionComponent } from './secondadmin/master/subscription/subscription.component';
import { NewsubscriptionComponent } from './secondadmin/master/subscription/newsubscription/newsubscription.component';
import { MasterReportComponent } from './secondadmin/pi/pii-report/master-report/master-report.component';
import { HqReportComponent } from './secondadmin/pi/pii-report/hq-report/hq-report.component';
import { BuReportComponent } from './secondadmin/pi/pii-report/bu-report/bu-report.component';
import { IndivisualDataComponent } from './secondadmin/pi/pii-report/indivisual-data/indivisual-data.component';
import { ThemeassociationComponent } from './secondadmin/master/thememaster/themeassociation/themeassociation.component'
import { PiiFieldAddComponent } from './secondadmin/pi/pii-field-add/pii-field-add.component';
import { TeamassociationComponent } from './secondadmin/usertypes/teamassociation/teamassociation.component';
import { AddassociationComponent } from './secondadmin/usertypes/teamassociation/addassociation/addassociation.component';
import { PiiIndividualProfileComponent } from './secondadmin/pi/pii-individual-profile/pii-individual-profile.component';
import { ContentbuilderComponent } from './secondadmin/contentbuilder/contentbuilder.component';
import { ContentbuilderViewComponent } from './secondadmin/contentbuilder/contentbuilder-view/contentbuilder-view.component';
import { ContentbuildfieldComponent } from './secondadmin/contentbuilder/contentbuildfield/contentbuildfield.component';
import { ContBuildAssoiciationComponent  } from './secondadmin/contentbuilder/cont-build-assoiciation/cont-build-assoiciation.component';
import { CustomerContentbuilderComponent } from './secondadmin/contentbuilder/customer-contentbuilder/customer-contentbuilder.component';
import {ContbuildCustThemeListComponent } from './secondadmin/contentbuilder/contbuild-cust-theme-list/contbuild-cust-theme-list.component';
import {ContentTemplateComponent } from './secondadmin/contentbuilder/content-builder-template/content-template.component';
import { EditContentBuilderDataComponent } from './secondadmin/contentbuilder/edit-content-builder-data/edit-content-builder-data.component';
import { SupportUserComponent } from './secondadmin/supportuser/supportuser.component';
import { SupportUserCreateRequestComponent } from './secondadmin/supportuser/supportuser-create-request/supportuser-create-request.component';
import { SupportUserListRequestComponent } from './secondadmin/supportuser/supportuser-request-list/supportuser-request-list.component';
import { AddContentTemplateComponent } from './secondadmin/contentbuilder/content-builder-template/add-content-template/add-content-template.component';
import { ContentBuilderFieldComponent } from './secondadmin/contentbuilder/content-builder-field/content-builder-field.component';
import { AddContentBuilderFieldsComponent } from './secondadmin/contentbuilder/content-builder-field/add-content-builder-fields/add-content-builder-fields.component';
import { ContentBuilderFieldPropertyComponent } from './secondadmin/contentbuilder/content-builder-field-property/content-builder-field-property.component';
import { AddContentBuilderFieldPropertyComponent } from './secondadmin/contentbuilder/content-builder-field-property/add-content-builder-field-property/add-content-builder-field-property.component';
import { ContentBuilderJourneyComponent } from './secondadmin/contentbuilder/content-builder-journey/content-builder-journey.component';
import { AddContentBuiderJourneyComponent } from './secondadmin/contentbuilder/content-builder-journey/add-content-builder-journey/add-content-builder-journey.component';
import { ContentBuilderSectionComponent } from './secondadmin/contentbuilder/content-builder-section/content-builder-section.component';
import { AddContentBuiderSectionComponent } from './secondadmin/contentbuilder/content-builder-section/add-content-builder-section/add-content-builder-section.component';
import { CBTSAssociationComponent } from './secondadmin/contentbuilder/cb-template-section-association/cb-template-section-association.component';
import { AddCBTSAssociationComponent } from './secondadmin/contentbuilder/cb-template-section-association/add-cb-template-section-association/add-cb-template-section-association.component'
import { CbSectionFieldAssociationComponent } from './secondadmin/contentbuilder/cb-section-field-association/cb-section-field-association.component';
import { CbFieldAttributeAssociationComponent } from './secondadmin/contentbuilder/cb-field-attribute-association/cb-field-attribute-association.component';
import { AddCbSectionFieldAssociationComponent } from './secondadmin/contentbuilder/cb-section-field-association/add-cb-section-field-association/add-cb-section-field-association.component';
import { AddCbFieldAttributeAssociationComponent } from './secondadmin/contentbuilder/cb-field-attribute-association/add-cb-field-attribute-association/add-cb-field-attribute-association.component';
import { CbAddThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-add-theme-template-association/cb-add-theme-template-association.component';
import { CbThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-theme-template-association.component';
import { CbFormBuilderComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder.component';
import { GeolocationComponent } from './secondadmin/master/geolocation/geolocation.component';
import { CbFormListComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-list/cb-form-list.component';

import { CbFormPreviewComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-preview/cb-form-preview.component';
import { UpdateClientsDBDetailsComponent } from './secondadmin/clients/update-clients-db-details/updateclientsdbdetails.component';


import { ReportDsComponent } from './secondadmin/reports/report-ds/report-ds.component';
import { ReportCustomerSubscriptionComponent } from './secondadmin/reports/report-customer-subscription/report-customer-subscription.component';
import { ReportMessageUtilizationComponent } from './secondadmin/reports/report-message-utilization/report-message-utilization.component';


import { ContactUsageComponent } from './secondadmin/reports/contact-usage/contact-usage.component';
import { ContactMarketActivityComponent } from './secondadmin/reports/contact-market-activity/contact-market-activity.component';
import { DsLogsComponent } from './secondadmin/usertypes/dslogs/dslogs.component';
import { BasicServicesSummaryComponent } from './secondadmin/reports/basic-services-summary/basic-services-summary.component';
import { CustomerInvoiceComponent } from './secondadmin/reports/customer-invoice/customer-invoice.component';
import { OptInOptOutComponent } from './secondadmin/reports/opt-in-opt-out/opt-in-opt-out.component';
import { CsvDownloadComponent } from "./secondadmin/reports/csv-download/csv-download.component";
import { PremiumServicesSummaryComponent } from './secondadmin/reports/premium-services-summary/premium-services-summary.component';
import { ReportEcomDetailComponent } from './secondadmin/reports/report-ecom-detail/report-ecom-detail.component';
import { ReportEcomSummaryComponent } from './secondadmin/reports/report-ecom-summary/report-ecom-summary.component';

import {RoleresourcesComponent} from './secondadmin/master/roleresources/roleresources.component';

import { EinsteinReportsService } from 'src/app/services/einstein/einstein-reports.service';

import { EinsteinDashboardReportsComponent } from './secondadmin/einstein-dashboard-reports/einstein-dashboard-reports';
import { EinsteinReportsComponent } from './secondadmin/einstein-dashboard-reports/einstein-reports/einstein-reports.component'

import {AttributeComponent} from './secondadmin/master/attributemaster/attribute.component';
import {AddattributeComponent} from './secondadmin/master/attributemaster/addattribute/addattribute.component';
import { NotesComponent } from './secondadmin/master/notesmaster/notes.component';
import {NoteslistComponent} from './secondadmin/master/notesmaster/noteslist/noteslist.component';

import {LicenseBundleComponent} from './secondadmin/license/license-bundle/license-bundle.component';
import {LicenseComponent} from './secondadmin/license/license.component';
import { LicenseAssignComponent } from './secondadmin/license/license-assign/license-assign.component';


import { LicenseBundleListingComponent } from './secondadmin/license/license-bundle/license-bundle-listing/license-bundle-listing.component'
;
import { LicenseAssignListingComponent } from './secondadmin/license/license-assign/license-assign-listing/license-assign-listing.component'
import {SegmentationHomeComponent} from './secondadmin/segmentation/segmentation-home.component';
import {ReplenishmentCrosssellComponent} from './secondadmin/replenishment-crosssell/replenishment-crosssell.component';
import {BuAudienceComponent} from './secondadmin/replenishment-crosssell/bu-audience/bu-audience.component';
import {MasterSegmentationComponent} from './secondadmin/segmentation/master-segmentation/master-segmentation.component';
import {AttributeGroupComponent} from './secondadmin/segmentation/master-segmentation/attribute-group/attribute-group.component';
import {NewAttributeGroupComponent} from './secondadmin/segmentation/master-segmentation/attribute-group/new-attribute-group/new-attribute-group.component';
import {AttributeTypeComponent} from './secondadmin/segmentation/master-segmentation/attribute-type/attribute-type.component';
import {NewAttributeTypeComponent} from './secondadmin/segmentation/master-segmentation/attribute-type/new-attribute-type/new-attribute-type.component';
import {OperatorGroupComponent} from './secondadmin/segmentation/master-segmentation/operator-group/operator-group.component';
import {AddOperatorGroupComponent} from './secondadmin/segmentation/master-segmentation/operator-group/add-operator-group/add-operator-group.component';
import { OperatorComponent } from './secondadmin/segmentation/master-segmentation/operator/operator.component';
import { AddOperatorComponent } from './secondadmin/segmentation/master-segmentation/operator/add-operator/add-operator.component';
import { EntitiesComponent } from './secondadmin/segmentation/master-segmentation/entities/entities.component'
;
import { AudienceComponent } from './secondadmin/segmentation/master-segmentation/audience/audience.component'
import { AddAudienceComponent } from './secondadmin/segmentation/master-segmentation/audience/add-audience/add-audience.component';;
import { AddEntitiesComponent } from './secondadmin/segmentation/master-segmentation/entities/add-entities/add-entities.component'
import {SegmentationAttributeComponent} from './secondadmin/segmentation/master-segmentation/attribute-segmentation/segmentation-attribute.component';
import {NewSegmentationAttributeComponent} from './secondadmin/segmentation/master-segmentation/attribute-segmentation/new-attribute/new-segmentation-attribute.component';
import {EntitiesAttributeAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-attribute/entities-attribute-association.component';
import {AddEntitiesAttributeAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-attribute/add-entities-attribute-association/add-entities-attribute-association.component';
import { BuTableComponent } from './secondadmin/segmentation/master-segmentation/bu-table/bu-table.component';
import { AddBuTableComponent } from './secondadmin/segmentation/master-segmentation/bu-table/add-bu-table/add-bu-table.component';
import {EntitiesAudienceAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-audience/entities-audience-association.component';
import {AddEntitiesAudienceAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/entities-audience/add-entities-audience-association/add-entities-audience-association.component';
import {AttributeOperatorAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/attribute-operator/attribute-operator-association.component';
import {AddAttributeOperatorAssociationComponent} from './secondadmin/segmentation/master-segmentation/association-segmentation/attribute-operator/add-attributes-operator-association/add-attribute-operator-association.component';
import {BuSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-segmentation.component';
import {SelectbuSegmentationComponent} from './secondadmin/segmentation/selectbu-segmentation/selectbu-segmentation.component';
import { AudienceBuilderComponent } from './secondadmin/audience-builder/audience-builder.component'
;
import { CreateAudienceBuilderComponent } from './secondadmin/audience-builder/create-audience-builder/create-audience-builder.component'

import {AddBuAudienceSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/add-bu-audience-segmentation.component';
import {BuAudienceSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/bu-audience-segmentation/bu-audience-segmentation.component';
import {NewBuAudienceSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/add-bu-audience-segmentation/bu-audience-segmentation/new-bu-audience-segmentation/new-bu-audience-segmentation.component';
import {BuEntitiesSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-entities-segmentation/bu-entities-segmentation.component';
import {AddBuEntitiesSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-entities-segmentation/add-bu-entities-segmentation/add-bu-entities-segmentation.component';
import {BuAttributesSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-attributes-segmentation/bu-attributes-segmentation.component';
import {AddBuAttributesSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-attributes-segmentation/add-bu-attributes-segmentation/add-bu-attributes-segmentation.component';
import {UpdateAudienceBuSegmentation} from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-audience-update/update-audience-bu-segmentation';
import {UpdateEntitiesBuSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-entities-update/update-entities-bu-segmentation.component';
import {UpdateAttributeBuSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-attribute-update/update-attribute-bu-segmentation.component';
import {UpdateOperatorsBuSegmentationComponent} from './secondadmin/segmentation/bu-segmentation/bu-segmentation-data-edit/bu-segmentation-operator-update/update-operators-bu-segmentation.component';
import {CampaignBuilderComponent} from './secondadmin/campaign-builder/campaign-builder.component';
import {CreateCampaignBuilderComponent} from './secondadmin/campaign-builder/create-campaign-builder/create-campaign-builder.component';
import {AudienceCampaignBuilderComponent} from './secondadmin/campaign-builder/audience-campaign-builder/audience-campaign-builder.component'
import {ProductCampaignBuilderComponent} from './secondadmin/campaign-builder/product-campaign-builder/product-campaign-builder.component'
import {ThemeCampaignBuilderComponent} from './secondadmin/campaign-builder/theme-campaign-builder/theme-campaign-builder.component'
import { MessageCampaignBuilderComponent } from './secondadmin/campaign-builder/message-campaign-builder/message-campaign-builder.component'
import { ScheduleCampaignBuilderComponent } from './secondadmin/campaign-builder/schedule-campaign-builder/schedule-campaign-builder.component'
import { ViewCampaignBuilderComponent } from './secondadmin/campaign-builder/view-campaign-builder/view-campaign-builder.component'
import {ViewBuAudienceComponent} from './secondadmin/audience-builder/view-bu-audience/view-bu-audience.component';
import {TestBuAudienceComponent} from './secondadmin/audience-builder/test-bu-audience/test-bu-audience.component';
import {ScheduleBuAudienceComponent} from './secondadmin/audience-builder/schedule-bu-audience/schedule-bu-audience.component';
import { EditAudienceBuilderComponent } from './secondadmin/audience-builder/edit-audience-builder/edit-audience-builder.component';
import {McInformationComponent} from './secondadmin/mc-connector/mc-information/mc-information.component';
import { McDataExtensionMapping } from './secondadmin/mc-connector/mc-data-extension-mapping/mc-data-extension-mapping';
import {McDataExtensionFieldsComponent} from './secondadmin/mc-connector/mc-data-extension-fields/mc-data-extension-fields.component';
import {McSchedulerComponent} from './secondadmin/mc-connector/mc-scheduler/mc-scheduler.component';
import { CloneAudienceBuilderComponent } from './secondadmin/audience-builder/clone-audience-builder/clone-audience-builder.component'
import {McSchedulerStatsComponent} from './secondadmin/mc-connector/mc-scheduler-stats/mc-scheduler-stats.component';
import { EWalletComponent } from './secondadmin/usertypes/e-wallet/e-wallet.component';
import { ShopLiveComponent } from './secondadmin/shop-live/shop-live.component';
import { NewCategoryComponent } from './secondadmin/shop-live/shoplive-category/new-category/new-category.component';
import { UpdateCategoryComponent } from './secondadmin/shop-live/shoplive-category/update-category/update-category.component';
import { ShoplivePostmanagementComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-postmanagement.component';
import { ShopliveNewpostComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-newpost/shoplive-newpost.component';
import { ShopliveEditpostComponent } from './secondadmin/shop-live/shoplive-postmanagement/shoplive-editpost/shoplive-editpost.component';
import { ShopliveCategoryComponent } from './secondadmin/shop-live/shoplive-category/shoplive-category.component';
import { MetaDataListComponent } from './secondadmin/metadata/metadatalist/metaDataList.component';
import { AddMetaDataComponent } from './secondadmin/metadata/addmetadata/addMetaData.component';

import { ReskiningmasterComponent } from './secondadmin/master/reskiningmaster/reskiningmaster.component';
import { NewreskinningComponent } from './secondadmin/master/reskiningmaster/newreskinning/newreskinning.component';
import { MetaDBConnectionComponent } from './secondadmin/metadata/metadbconnection/metadbconnection.component';
import { ExtCompanySellerBuComponent } from './secondadmin/ext-company-seller-bu/ext-company-seller-bu.component';
import { MetaClientTableRelationComponent } from './secondadmin/metadata/metaclienttablerelation/metaclienttablerelation.component';
import { ProductcatalogmasterComponent } from './secondadmin/master/productcatalogmaster/productcatalogmaster.component';
import { NewproductcatalogComponent } from './secondadmin/master/productcatalogmaster/newproductcatalog/newproductcatalog.component';
import { CrosssellproductcatalogComponent } from './secondadmin/master/productcatalogmaster/crosssellproductcatalog/crosssellproductcatalog.component';
import { CbCloneThemeTemplateAssociationComponent } from './secondadmin/contentbuilder/cb-theme-template-association/cb-clone-theme-template-association/cb-clone-theme-template-association.component';
import { CbFormBuilderCloneComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder-clone/cb-form-builder-clone.component';
import { DsApprovalListComponent } from './secondadmin/supportuser/ds-approval-list/ds-approval-list.component';

import { DsreportComponent } from './secondadmin/usertypes/dsreport/dsreport.component';
import { BlockIpHostComponent } from './secondadmin/master/block-ip-host/block-ip-host.component';
import { NewblockiphostComponent } from './secondadmin/master/block-ip-host/newblockiphost/newblockiphost.component';
import { CbFormBuilderProductCloneComponent } from './secondadmin/contentbuilder/cb-form-builder/cb-form-builder-product-clone/cb-form-builder-product-clone.component';
import { CrosssellListComponent } from './secondadmin/replenishment-crosssell/cross-sell/cross-sell.component';
import { ReplenishmentListComponent } from './secondadmin/replenishment-crosssell/replenishment/replenishment.component';
import { CustomerCountListComponent } from './secondadmin/replenishment-crosssell/customer-count-listing/customer-countlist.component';
import { SettingComponent } from './secondadmin/replenishment-crosssell/setting/setting.component';
import { NewSettingComponent } from './secondadmin/replenishment-crosssell/setting/newsetting/newsetting.component';
import { AgreementComponent } from './secondadmin/master/agreement/agreement.component';
import { NewThemeassociationComponent } from './secondadmin/master/thememaster/themeassociation/new-themeassociation/new-themeassociation.component';
import { NewBusinessDomainComponent } from './secondadmin/master/business-domain/new-business-domain/new-business-domain.component';
import { BusinessDomainComponent } from './secondadmin/master/business-domain/business-domain.component';
import { SmsComponent } from './secondadmin/sms/sms.component';
import { TemplatemanagementComponent } from './secondadmin/sms/templatemanagement/templatemanagement.component';
import { AddnewtemplateComponent } from './secondadmin/sms/templatemanagement/addnewtemplate/addnewtemplate.component';
import { SmscategoryComponent } from './secondadmin/sms/category/smscategory.component';
import { AddnewsmscategoryComponent } from './secondadmin/sms/category/addnewsmscategory/addnewsmscategory.component';
import { SnippetsComponent } from './secondadmin/sms/message-snippets/snippets.component';
import { AddnewsnippetsComponent } from './secondadmin/sms/message-snippets/addnewmessage/addnewmessage.component';
import { ResourceCategoryComponent } from './secondadmin/master/resource-category/resource-category.component';
import { AddRescategoryComponent } from './secondadmin/master/resource-category/add-rescategory/add-rescategory.component';
import { UpdateRescategoryComponent } from './secondadmin/master/resource-category/update-rescategory/update-rescategory.component';
import { ResourceImageComponent } from './secondadmin/master/resource-image/resource-image.component';
import { AddResourceImageComponent } from './secondadmin/master/resource-image/add-resource-image/add-resource-image.component';
import { UpdateResourceImageComponent } from './secondadmin/master/resource-image/update-resource-image/update-resource-image.component';
import { FaqCategoryComponent } from './secondadmin/webinar/faq-category/faq-category.component';
import { AddFaqCategoryComponent } from './secondadmin/webinar/faq-category/add-faq-category/add-faq-category.component';
import { UpdateFaqCategoryComponent } from './secondadmin/webinar/faq-category/update-faq-category/update-faq-category.component';
import { WebinarModuleComponent } from './secondadmin/webinar/webinar-module/webinar-module.component';
import { FaqLibraryComponent } from './secondadmin/webinar/faq-library/faq-library.component';
import { AddFaqLibraryComponent } from './secondadmin/webinar/faq-library/add-faq-library/add-faq-library.component';
import { UpdateFaqLibraryComponent } from './secondadmin/webinar/faq-library/update-faq-library/update-faq-library.component';
import { WebCatListComponent } from './secondadmin/webinar/webinar-category/web-cat-list/web-cat-list.component';
import { WebCatAddComponent } from './secondadmin/webinar/webinar-category/web-cat-add/web-cat-add.component';
import { WebCatEditComponent } from './secondadmin/webinar/webinar-category/web-cat-edit/web-cat-edit.component';
import { WebTagListComponent } from './secondadmin/webinar/webinar-tag/web-tag-list/web-tag-list.component';
import { WebTagAddComponent } from './secondadmin/webinar/webinar-tag/web-tag-add/web-tag-add.component';
import { WebTagEditComponent } from './secondadmin/webinar/webinar-tag/web-tag-edit/web-tag-edit.component';
import { PollComponent } from './secondadmin/webinar/poll/poll.component';
import { AddPollComponent } from './secondadmin/webinar/poll/add-poll/add-poll.component';
import { PollOptionsComponent } from './secondadmin/webinar/poll-options/poll-options.component';
import { AddPollOptionComponent } from './secondadmin/webinar/poll-options/add-poll-option/add-poll-option.component';
import { UpdatePollOptionComponent } from './secondadmin/webinar/poll-options/update-poll-option/update-poll-option.component';
import { ScheduleJourneyListComponent } from './secondadmin/schedule-journey/schedule-journey-list.component';
import { AddScheduleJourneyComponent } from './secondadmin/schedule-journey/add-schedule-journey/add-schedule-journey.component';
import { UpdatePollComponent } from './secondadmin/webinar/poll/update-poll/update-poll.component';
import { TemplatesComponent } from './secondadmin/webinar/templates/templates.component';
import { AddTemplatesComponent } from './secondadmin/webinar/templates/add-templates/add-templates.component';
import { SubscriptionModuleComponent } from './secondadmin/subscriptionmodule/subscription-module/subscription-module.component';
import { AddSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/add-subscription/add-subscription.component';
import { ListSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/list-subscription/list-subscription.component';
import { EditSubscriptionComponent } from './secondadmin/subscriptionmodule/subscription-crud/edit-subscription/edit-subscription.component';
import { SubscriptionLimitComponent } from './secondadmin/subscriptionmodule/subscription-limit/subscription-limit.component';
import { AddSubsLimitComponent } from './secondadmin/subscriptionmodule/add-subs-limit/add-subs-limit.component';
import { EditSubsLimitComponent } from './secondadmin/subscriptionmodule/edit-subs-limit/edit-subs-limit.component';
import { CreateMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/create-media-sub/create-media-sub.component';
import { ListMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/list-media-sub/list-media-sub.component';
import { EditMediaSubComponent } from './secondadmin/subscriptionmodule/subscription-media/edit-media-sub/edit-media-sub.component';
import { SenderProfileModuleComponent } from './secondadmin/sender-profile/sender-profile-module/sender-profile-module.component';
import { SenderProfileListComponent } from './secondadmin/sender-profile/sender-profile-list/sender-profile-list.component';
import { EditSenderProfileComponent } from './secondadmin/sender-profile/edit-sender-profile/edit-sender-profile.component';
import { ViewSenderProfileComponent } from './secondadmin/sender-profile/view-sender-profile/view-sender-profile.component';
import { EventListComponent } from './secondadmin/events/event-list/event-list.component';
import { EventAddComponent } from './secondadmin/events/event-add/event-add.component';
import { ThemeListComponent } from './secondadmin/events/theme-list/theme-list.component';
import { EventPreviewComponent } from './secondadmin/events/event-preview/event-preview.component';
import { EventViewComponent } from './secondadmin/events/event-view/event-view.component';
import { WooCommerceListComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woo-commerce-list/woo-commerce-list.component';
import { WooCommerceUpdateComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woo-commerce-update/woo-commerce-update.component';
import { WoocommerceCronUpdateComponent } from './secondadmin/master/productcatalogmaster/wooCommerce/woocommerce-cron-update/woocommerce-cron-update.component';
import { EventInviteeComponent } from './secondadmin/events/event-invitee/event-invitee.component';
import { ContactListComponent } from './secondadmin/master/contact-list/contact-list.component';
import { ContactViewComponent } from './secondadmin/master/contact-list/contact-view/contact-view.component';
import { ListReskinningComponent } from './secondadmin/master/reskiningmaster/list-reskinning/list-reskinning.component';
import { SupportuserLogsComponent } from './secondadmin/supportuser/supportuser-logs/supportuser-logs.component';
import { JourneySpanComponent } from './secondadmin/master/journey-span/journey-span.component';
import { AddJourneySpanComponent } from './secondadmin/master/journey-span/add-journey-span/add-journey-span.component';
import { ReadinessComponent } from './secondadmin/readiness/readiness/readiness.component';
import { ActivityListComponent } from './secondadmin/readiness/readiness-activity/activity-list/activity-list.component';
import { ActivityAddComponent } from './secondadmin/readiness/readiness-activity/activity-add/activity-add.component';
import { ActivityUpdateComponent } from './secondadmin/readiness/readiness-activity/activity-update/activity-update.component';
import { ResourceListComponent } from './secondadmin/readiness/readiness-reasource/resource-list/resource-list.component';
import { ResourceAddComponent } from './secondadmin/readiness/readiness-reasource/resource-add/resource-add.component';
import { ReadinessEditComponent } from './secondadmin/readiness/readiness-reasource/readiness-edit/readiness-edit.component';
import { SupportTicketComponent } from './secondadmin/master/support-ticket/support-ticket.component';
import { SupportTicketViewComponent } from './secondadmin/master/support-ticket/support-ticket-view/support-ticket-view.component';
import { CreateJourneyFlowComponent } from './secondadmin/schedule-journey/journey-flow/create-journey-flow/create-journey-flow.component';


const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [

      { path: 'login', component: LoginComponent, },
      { path: 'forgot-user', component: ForgotUserComponent },
      { path: 'setusername/:token', component: SetusernameComponent },
      { path: 'forgot-pwd', component: ForgotPasswrodComponent },
      { path: "resetpwd/:token", component: ResetpasswordComponent },
      { path: '', component: LoginComponent }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'logout', component: LoginComponent,
        //  canActivate: [AuthGuard]
      },
      { path: 'myprofile', component: MyprofileComponent, canActivate: [AuthGuard] },
      { path: 'myprofile/editprofile', component: EditmyprofileComponent, canActivate: [AuthGuard] },

      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'pagenotfound', component: PageNotFoundComponent, canActivate: [AuthGuard] },
      { path: 'notauthorised', component: NotauthorisedComponent },


      {
        path: "corporate", component: SecondadminComponent, canActivate: [RoleGuard],canActivateChild:[RoleGuard, AuthGuard],
        children: [
          { path: "home", component: HomeComponent },
          { path: "clients", component: ClientsComponent },
          //  children:[
          { path: "clientnew", component: ClientnewcontactComponent },
          //  ]
          // },
          { path: "messenger", loadChildren: () => import('./secondadmin/chat/chat.module').then(m => m.ChatModule), canActivate: [AuthGuard], data: { preload: true, delay:true } },
          { path: "users", component: UsersComponent },
          { path: 'newuser', component: NewusercontactComponent },
          { path: "roles", component: RolesComponent },
          { path: 'rolenewuser', component: RolesnewuserComponent },
          { path: "margonewuser", component: MargonewcontactComponent },
          { path: "margoproducts", component: MargoproductsComponent },
          { path: "master", component: MasterComponent },
          { path: "thememaster", component: ThememasterComponent },
          { path: "blockiphost", component: BlockIpHostComponent},
          { path: "newblockiphost", component: NewblockiphostComponent},
          { path: "reskinning", component: ListReskinningComponent },
          { path: "addnewreskinning", component: NewreskinningComponent },

          { path: "productcatalog", component: ProductcatalogmasterComponent },
          { path: "newproductcatalog", component: NewproductcatalogComponent },
          { path: "crosssellproductcatalogue", component: CrosssellproductcatalogComponent },

          { path: "newtheme", component: NewthemeComponent },
          { path: "journeymaster", component: JourneymasterComponent, canActivate: [AuthGuard] },
          { path: "addnewjourney", component: AddnewjourneyComponent },
          { path: "trainingtypemaster", component: TrainingtypemasterComponent },
          { path: "addnewtraining", component: AddnewtrainingtypeComponent },
          { path: "eventtypemaster", component: EventtypemasterComponent },
          { path: "addnewevent", component: AddneweventComponent },
          { path: "actionmaster", component: ActionmasterComponent },
          { path: "menumaster", component: MenumasterComponent },
          { path: "addnewmenu", component: AddnewmenuComponent },
          { path: "countrymaster", component: CountrymasterComponent },
          { path: "addnewcountry", component: AddnewcountryComponent },
          { path: "currencymaster", component: CurrencymasterComponent },
          { path: "addnewcurrency", component: AddnewcurrencyComponent },
          { path: "industrymaster", component: IndustrymasterComponent },
          { path: "addnewindustry", component: AddnewindustryComponent },
          { path: "companytypemaster", component: CompanytypemasterComponent },
          { path: "addnewcompany", component: AddnewcompanyComponent },
          { path: "professionmaster", component: ProfessionmasterComponent },
          { path: "addnewprofession", component: AddnewprofessionComponent },
          { path: "loyalitytypemaster", component: LoyalitytypemasterComponent },
          { path: "addnewloyality", component: AddnewloyalityComponent },
          { path: "prospecttypemaster", component: ProspecttypemasterComponent },
          { path: "addnewprospect", component: AddnewprospectComponent },
          { path: "pi", component: PiComponent },
          { path: "permissions", component: PermissionComponent },
          { path: "addnewpermissions", component: AddnewpermissionsComponent },
          // {path:"roles",component:RolesnewuserComponent},
          // {path:"addnewroles",component:AddnewrolesComponent},
          { path: "rolesmaster", component: RoleMasterComponent },
          { path: "addnewrolemaster", component: AddnewroleMasterComponent },
          { path: "module", component: ModuleMasterComponent },
          { path: "addnewmodule", component: AddnewmoduleComponent },
          { path: "moduleitems", component: ModuleitemsComponent },
          { path: "addnewmoduleitems", component: AddnewmoduleitemsComponent },
          { path: "editCompany", component: ClienteditcontactComponent },
          { path: "clienthome", component: ClientHomeComponent },
          { path: "updateclientdbdetails", component: UpdateClientsDBDetailsComponent },
          { path: "clientheadquaterhome", component: ClientHeadquaterComponent },
          { path: "fb", component: FacebookComponent },
          { path: "postmanagement", component: PostmanagementComponent },
          { path: "addnewpost", component: AddnewpostComponent },
          { path: "campaign", component: CampaignComponent },
          { path: "addnewcampaign", component: AddnewcampaignComponent },
          { path: "category", component: CategoryComponent },
          { path: "addnewcategory", component: AddnewcategoryComponent },
          { path: "msgsnippets", component: MessageSnippetsComponent },
          { path: "addnewmsg", component: AddnewmessageComponent },
          { path: "logs", component:  LogsComponent},
          { path: "usertypes",component:UsertypesComponent},
          { path: "dsuser",component:DirectsellerComponent},
          { path: "adddsuser",component :AddnewsellerComponent},
          { path: "themecategorymaster", component: MasterthemecategoryComponent },
          { path: "newthemecategory", component: NewthemecategoryComponent },
          { path: "journeytype",component:JourneytypeComponent},
          { path: "newjourneytype",component:NewjourneytypeComponent},
          { path: "asset",component:AssetComponent},
          { path: "newasset",component:NewassetComponent},
          { path: "contacttype",component:ContacttypeComponent},
          { path: "newcontacttype",component:NewcontacttypeComponent},
          { path: "gender",component:GenderComponent},
          { path: "newgender",component:NewgenderComponent},
          { path: "agerange",component:AgerangeComponent},
          { path: "newagerange",component:NewagerangeComponent},
          { path:"helps",component:HelpsComponent},
          { path:"tips",component:TipsComponent},
          { path:"support",component:SupportComponent},
          { path:"subjectlib",component:SubjectlibComponent},
          { path:"newsubjectlib",component:NewsubjectlibComponent},
          { path:"messagelib",component:MessagelibComponent},
          { path:"newmessagelib",component:NewmessagelibComponent},
          { path:"fbreports",component:FbreportsComponent},
          { path:"pii-table",component:PiiTableComponent},
          { path:"pii-individual-profile",component:PiiIndividualProfileComponent},

          { path:"pii-field",component:PiiFieldComponent},
          { path:"pii-data",component:PiiDataComponent},
          { path:"pii-data-view",component:PiiDataViewComponent},
          {path:"pii-field-add", component:PiiFieldAddComponent},
          { path:"pii-dsar",component:PiiDsarComponent},
          { path:"pii-report",component:PiiReportComponent},
          { path:"subscription",component:SubscriptionComponent},
          { path:"newsubscription",component:NewsubscriptionComponent},
          { path:"pii-report-master",component:MasterReportComponent},
          { path:"pii-report-hq",component:HqReportComponent},
          { path:"pii-report-bu",component:BuReportComponent},
          { path:"pii-indivisual-data",component:IndivisualDataComponent},
          { path:"themeassociation",component:ThemeassociationComponent},
          { path:"teamassociation",component:TeamassociationComponent},
          { path:"hostds",component:HostDsComponent},
          {path:"addassociation",component:AddassociationComponent},
          { path:"contentbuilder",component:ContentbuilderComponent},
          { path:"contentbuilder-view",component:ContentbuilderViewComponent},
         // { path:"add-content-builder-field",component:ContentbuildfieldComponent},
         { path:"add-content-builder-field",component:AddContentBuilderFieldsComponent},
         { path:"add-content-builder-field-property",component:AddContentBuilderFieldPropertyComponent},
         { path:"add-cb-journey",component:AddContentBuiderJourneyComponent},
         { path:"add-cb-section",component: AddContentBuiderSectionComponent},
         { path:"add-cb-ts-association",component: AddCBTSAssociationComponent},
         { path:"cb-ts-association",component: CBTSAssociationComponent},
         { path:"einstein-reports",component: EinsteinReportsComponent , resolve:{SFtoken:EinsteinReportsService}},




         { path:"add-cb-sec-field-association",component: AddCbSectionFieldAssociationComponent},
         { path:"cb-sec-field-association",component: CbSectionFieldAssociationComponent},
         { path:"add-cb-field-att-association",component: AddCbFieldAttributeAssociationComponent},
         { path:"cb-field-att-association",component: CbFieldAttributeAssociationComponent},

         { path:"add-cb-theme-template-association",component: CbAddThemeTemplateAssociationComponent},
         { path:"cb-theme-template-association",component: CbThemeTemplateAssociationComponent},
         { path:"update-cb-theme-template-association",component: CbAddThemeTemplateAssociationComponent},

         { path:"clone-cb-theme-template-association",component: CbCloneThemeTemplateAssociationComponent},
         { path:"clone-cb-form-builder",component: CbFormBuilderCloneComponent},



         { path:"cb-form-builder",component: CbFormBuilderComponent},
         { path:"cb-form-builder-list",component: CbFormListComponent},

         { path:"content-builder-field",component:ContentBuilderFieldComponent},
         { path:"content-builder-field-property",component:ContentBuilderFieldPropertyComponent},
         { path:"cb-journey",component:ContentBuilderJourneyComponent},
         { path:"cb-section",component:ContentBuilderSectionComponent},
         { path:"cb-form-preview",component:CbFormPreviewComponent},


         { path:"content-builder-associate",component:ContBuildAssoiciationComponent},
          { path:"content-builder-customer",component:CustomerContentbuilderComponent},
          { path:"content-builder-customer-list",component:ContbuildCustThemeListComponent},
          { path:"content-builder-template",component:ContentTemplateComponent},
          { path:"add-content-builder-template",component:AddContentTemplateComponent},
          { path:"edit-contentbuilder-data",component:EditContentBuilderDataComponent},
          { path:"supportuser",component:SupportUserComponent},
          { path:"supportuser-create-request",component:SupportUserCreateRequestComponent},
          { path:"supportuser-list-request",component:SupportUserListRequestComponent},
          { path:"supportuser-logs",component:SupportuserLogsComponent},
          { path:"ds-approval-list",component:DsApprovalListComponent},
          { path: "importGeoCSV", component: GeolocationComponent },

          { path: "reports", component: ReportsComponent },
          { path: "report-contact-inventory", component: ReportsnewcntactComponent },
          { path: "report-ds", component: ReportDsComponent },
          { path: "report-csr", component: ReportCustomerSubscriptionComponent},
          { path: "report-msg-utilized", component: ReportMessageUtilizationComponent},
          { path: "report-ecom-detail", component: ReportEcomDetailComponent},
          { path: "report-ecom-summary", component: ReportEcomSummaryComponent},

          { path: "report-contact-usage", component: ContactUsageComponent},
          { path: "report-contact-market-activity", component: ContactMarketActivityComponent},
          { path: "dslogs", component: DsLogsComponent},
          { path:"report-basic-services-summary",component:BasicServicesSummaryComponent},
          { path:"report-opt-in-opt-out",component:OptInOptOutComponent},
          { path:"report-csvDownload",component:CsvDownloadComponent},
          { path:"report-customer-invoice",component:CustomerInvoiceComponent},
          { path:"report-premium-services-summary",component:PremiumServicesSummaryComponent},
          { path: "dslogs", component: DsLogsComponent},
          { path: "einstein-dashboard", component: EinsteinDashboardReportsComponent},
          { path:"add-role-resources",component:RoleresourcesComponent},
          { path: 'attributes', component: AttributeComponent},
          { path: 'addattributes', component: AddattributeComponent},

          { path: 'license', component: LicenseComponent},

          { path: 'license-bundle-create', component: LicenseBundleComponent},
          { path: 'license-bundle-assign', component: LicenseAssignComponent},


          { path: 'notes', component: NotesComponent},
          { path: 'notes-list', component: NoteslistComponent},

          { path: 'license-bundle-listing', component: LicenseBundleListingComponent},
          { path: 'license-assign-listing', component: LicenseAssignListingComponent},
          { path: 'notes', component: NotesComponent},
          { path: 'segmentation', component: SegmentationHomeComponent},
          { path: 'replenishment-and-cross-sell', component: ReplenishmentCrosssellComponent},
          { path: 'replenishment-crosssell-audience', component: BuAudienceComponent},
          { path: 'master-segmentation', component: MasterSegmentationComponent},
          { path: 'attribute-group', component: AttributeGroupComponent},
          { path: 'new-attribute-group', component: NewAttributeGroupComponent},
          { path: 'attribute-type', component: AttributeTypeComponent},
          {path: 'new-attribute-type', component: NewAttributeTypeComponent},
          { path: 'operator-group', component: OperatorGroupComponent},
          { path: 'add-operator-group', component: AddOperatorGroupComponent},

          { path: 'operator', component: OperatorComponent},
          { path: 'add-operator', component: AddOperatorComponent},

          { path: 'entities', component: EntitiesComponent},
          { path: 'add-entities', component: AddEntitiesComponent},

          { path: 'audience', component: AudienceComponent},
          { path: 'add-audience', component: AddAudienceComponent},
          { path: 'segmentation-attribute', component: SegmentationAttributeComponent},
          { path: 'newsegmentationattribute', component: NewSegmentationAttributeComponent},

          { path: 'entities-attribute-association-list', component: EntitiesAttributeAssociationComponent},
          {path: 'add-entities-attribute-association', component: AddEntitiesAttributeAssociationComponent},

          { path: 'segmentation-table', component: BuTableComponent},
          { path: 'add-segmentation-table', component: AddBuTableComponent},
          { path: 'entities-audience-association-list', component: EntitiesAudienceAssociationComponent},
          { path: 'add-entities-audience-association', component: AddEntitiesAudienceAssociationComponent},
          { path: 'attribute-operator-association-list', component: AttributeOperatorAssociationComponent},
          { path: 'add-attribute-operator-association', component: AddAttributeOperatorAssociationComponent},
          { path: 'bu-segmentation', component: BuSegmentationComponent},
          { path: 'selectbu-segmentation', component: SelectbuSegmentationComponent},
          { path: 'add-bu-audience', component: AddBuAudienceSegmentationComponent},
          { path: 'bu-audience', component: BuAudienceSegmentationComponent},
          { path: 'new-bu-audience', component: NewBuAudienceSegmentationComponent},

          { path: 'audience-builder', component: AudienceBuilderComponent},
          { path: 'audience-builder-create', component: CreateAudienceBuilderComponent},
          { path: 'add-bu-audience', component: AddBuAudienceSegmentationComponent},
          { path: 'bu-entities', component: BuEntitiesSegmentationComponent},
          { path: 'add-bu-entities', component: AddBuEntitiesSegmentationComponent},
          { path: 'bu-attributes', component: BuAttributesSegmentationComponent},
          { path: 'add-bu-attributes', component: AddBuAttributesSegmentationComponent},
          { path: 'update-bu-audience', component: UpdateAudienceBuSegmentation},
          { path: 'update-bu-entities', component: UpdateEntitiesBuSegmentationComponent},
          { path: 'update-bu-attribute', component: UpdateAttributeBuSegmentationComponent},
          { path: 'update-bu-operator', component: UpdateOperatorsBuSegmentationComponent},
          { path: 'campaign-builder', component: CampaignBuilderComponent},
          { path: 'create-campaign-builder', component: CreateCampaignBuilderComponent},
          { path: 'audience-campaign-builder', component: AudienceCampaignBuilderComponent},
          { path: 'product-campaign-builder', component: ProductCampaignBuilderComponent},
          { path: 'theme-campaign-builder', component: ThemeCampaignBuilderComponent},
          { path: 'message-campaign-builder', component: MessageCampaignBuilderComponent},
          { path: 'schedule-campaign-builder', component: ScheduleCampaignBuilderComponent},
          { path: 'view-campaign-builder', component: ViewCampaignBuilderComponent},
          { path: 'view-bu-built-audience', component: ViewBuAudienceComponent},
          { path: 'test-bu-built-audience', component: TestBuAudienceComponent},
          { path: 'schedule-bu-built-audience', component: ScheduleBuAudienceComponent},
          { path: 'manage-audience-builder', component: EditAudienceBuilderComponent},
          { path: 'mc-information', component: McInformationComponent},
          { path: 'mc-dataextension', component: McDataExtensionMapping},
          { path: 'mc-dataextensionfields', component: McDataExtensionFieldsComponent},
          { path: 'mc-scheduler', component: McSchedulerComponent},
          { path: 'mc-scheduler-stats', component: McSchedulerStatsComponent},
          { path: 'clone-audience-builder', component: CloneAudienceBuilderComponent},

          { path: 'e-wallet', component: EWalletComponent},
          { path: 'shop-live', component: ShopLiveComponent},
          { path: 'shoplive-category', component: ShopliveCategoryComponent},
          { path: 'shoplive-addcategory', component: NewCategoryComponent},
          { path: 'shoplive-editcategory', component: UpdateCategoryComponent},
          { path: 'shoplive-postmanage', component: ShoplivePostmanagementComponent},
          { path: 'shoplive-addpost', component: ShopliveNewpostComponent},
          { path: 'shoplive-editpost', component: ShopliveEditpostComponent},
          { path: 'metadata-list', component: MetaDataListComponent},
          { path: 'add-metadata', component: AddMetaDataComponent},
          { path: 'ext-company-seller-bu', component: ExtCompanySellerBuComponent},
          { path: 'meta-client-table', component: MetaClientTableRelationComponent},
          { path: 'ds-report', component:DsreportComponent},
          { path: 'product-clone-cb-form-builder',component: CbFormBuilderProductCloneComponent},
          { path: 'cross-sell-list',component: CrosssellListComponent},
          { path: 'replenishment-list',component: ReplenishmentListComponent},
          { path: 'customercountlist',component: CustomerCountListComponent},
          { path: 'cross-sell-and-replenishment-setting',component: SettingComponent},
          { path: 'cross-sell-and-replenishment-setting',component: NewSettingComponent},
          { path: 'addnewsetting',component: NewSettingComponent},
          { path: 'agreement',component: AgreementComponent},
          { path: 'newthemeassociation',component: NewThemeassociationComponent},
          { path: 'businessDomain', component:BusinessDomainComponent},
          { path: 'addBusinessDomain', component:NewBusinessDomainComponent},
          { path: "mb", component: SmsComponent },
          { path: "templatemanagement", component: TemplatemanagementComponent },
          { path: "addnewtemplate", component: AddnewtemplateComponent },
          { path: "smscategory", component: SmscategoryComponent },
          { path: "addnewsmscategory", component: AddnewsmscategoryComponent },
          { path: "smssnippets", component: SnippetsComponent },
          { path: "addnewsnippets", component: AddnewsnippetsComponent },
          { path: "res-category", component: ResourceCategoryComponent },
          { path: "add-res-category", component: AddRescategoryComponent },
          { path: "update-res-category", component: UpdateRescategoryComponent },
          { path: "resource", component: ResourceImageComponent },
          { path: "add-resource", component: AddResourceImageComponent },
          { path: "update-resource", component: UpdateResourceImageComponent },
          { path: "faq-category", component: FaqCategoryComponent },
          { path: "add-faq-category", component: AddFaqCategoryComponent },
          { path:'update-faq-category', component:UpdateFaqCategoryComponent},
          { path:'webinar', component:WebinarModuleComponent},
          { path:'faq-library', component:FaqLibraryComponent},
          { path:'add-faq-library', component:AddFaqLibraryComponent},
          { path:'update-faq-library', component:UpdateFaqLibraryComponent},
          { path: "webinar-category", component: WebCatListComponent },
          { path: "add-webinar-category", component: WebCatAddComponent },
          { path: "update-webinar-category", component: WebCatEditComponent },
          { path: "webinar-tag", component: WebTagListComponent },
          { path: "add-webinar-tag", component: WebTagAddComponent },
          { path: "update-webinar-tag", component: WebTagEditComponent },
          { path: "poll", component: PollComponent },
          { path: "add-poll", component: AddPollComponent },
          { path: "poll-option", component: PollOptionsComponent },
          { path: "add-poll-option", component: AddPollOptionComponent },
          { path: "update-poll", component: UpdatePollComponent },
          { path: "update-poll-option", component: UpdatePollOptionComponent },
          { path: "schedule-journey-list", component: ScheduleJourneyListComponent },
          { path: "add-schedule-journey-list", component: AddScheduleJourneyComponent },
          { path: "templates", component: TemplatesComponent },
          { path: "add-templates", component: AddTemplatesComponent },
          { path: "subscriptionModule", component: SubscriptionModuleComponent },
          { path: "add-subscription", component: AddSubscriptionComponent },
          { path: "subscription-list", component: ListSubscriptionComponent },
          { path: "edit-subscription", component: EditSubscriptionComponent },
          { path: "subscriptionLimit", component: SubscriptionLimitComponent },
          { path: "addSubscriptionLimit", component: AddSubsLimitComponent },
          { path: "editSubscriptionLimit", component: EditSubsLimitComponent },
          { path: "create-media-subscription", component: CreateMediaSubComponent },
          { path: "subscription-media-list", component: ListMediaSubComponent },
          { path:"edit-subscription-media", component:EditMediaSubComponent},
          { path:"sender-profile", component:SenderProfileModuleComponent},
          { path:"sender-profile-list", component:SenderProfileListComponent},
          { path:"sender-profile-update", component:EditSenderProfileComponent},
          { path:"sender-profile-view", component:ViewSenderProfileComponent},
          { path:"events", component:EventListComponent},
          { path:"add-event", component:EventAddComponent},
          { path:"theme-list", component:ThemeListComponent},
          { path:"event-preview", component:EventPreviewComponent},
          { path:"event-view", component:EventViewComponent},
          { path:"event-invitee", component:EventInviteeComponent},
          { path:'woocommerce',component:WooCommerceListComponent},
          { path:'woocommerce/update',component:WooCommerceUpdateComponent},
          { path:'woocommerce/cron-update',component:WoocommerceCronUpdateComponent},
          { path:'contactus-list',component:ContactListComponent},
          { path:'contactus-view',component:ContactViewComponent},
          { path:'journey-span',component:JourneySpanComponent},
          { path:'add-journey-span',component:AddJourneySpanComponent},
          { path:'readiness',component:ReadinessComponent},
          { path:'readiness-activity-list',component:ActivityListComponent},
          { path:'readiness-activity-add',component:ActivityAddComponent},
          { path:'readiness-activity-edit',component:ActivityUpdateComponent},
          { path:'readiness-resources-list',component:ResourceListComponent},
          { path:'readiness-resources-add',component:ResourceAddComponent},
          { path:'readiness-resources-edit',component:ReadinessEditComponent},
          { path:'support-ticket',component:SupportTicketComponent},
          { path:'support-ticket-view',component:SupportTicketViewComponent},
          { path:'create-journey', component: CreateJourneyFlowComponent}
        ]
      },
    ],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'pagenotfound' },


];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
export const routingComponents = [PageNotFoundComponent, NotauthorisedComponent, TeamassociationComponent,
  PiiIndividualProfileComponent, ContentbuilderViewComponent, ContBuildAssoiciationComponent,
  AddassociationComponent,ContentTemplateComponent,AddContentTemplateComponent,
  ContentBuilderFieldPropertyComponent,AddContentBuilderFieldPropertyComponent,
  AddContentBuilderFieldsComponent,ContentBuilderFieldComponent,AddContentBuiderJourneyComponent,
  AddContentBuiderSectionComponent,ContentBuilderJourneyComponent,ContentBuilderSectionComponent,
  AddCBTSAssociationComponent,CBTSAssociationComponent,CbFormPreviewComponent,UpdateClientsDBDetailsComponent,
  EinsteinReportsComponent,RoleresourcesComponent,LicenseComponent,LicenseBundleComponent,
  LicenseAssignComponent, LicenseBundleListingComponent,
  LicenseAssignListingComponent, OperatorGroupComponent,
  AddOperatorGroupComponent, AttributeGroupComponent, NewAttributeGroupComponent,
  OperatorComponent, AddOperatorComponent,EntitiesComponent,AudienceComponent ,
  AddAudienceComponent,AddEntitiesComponent,  BuTableComponent, AddBuTableComponent,

  AudienceBuilderComponent,CreateAudienceBuilderComponent ,AddBuAudienceSegmentationComponent,EditAudienceBuilderComponent,CloneAudienceBuilderComponent,
  ExtCompanySellerBuComponent, ScheduleJourneyListComponent, AddScheduleJourneyComponent
];
