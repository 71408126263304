<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">Users</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/contactus-list">Contact Enquiry List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Contact Enquiry Detail</li>

        </ol>
    </nav>
</div>

<div class="container">
        <div class="row">
            <div class="col-md-12 col-xl-12">
                <div class="card mt-1">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Name</label>
                                    <input type="text" value='{{enqData?.contact_fname}} {{enqData?.contact_lname}}' class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Email</label>
                                    <input type="text" value='{{enqData?.contact_email}}' class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Phone</label>
                                    <input type="text" value='{{enqData?.contact_phone}}' class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Created On</label>
                                    <input type="text" value='{{enqData?.createdon | date: "medium"}}' class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mt-1">
                                    <label for="exampleForm2">Message</label>
                                    <textarea type="text"  class="form-control text-area-expand" rows="7"  value='{{enqData?.message}}' 
                                         readonly></textarea>
                                </div>
                            </div>
                        </div>


                 
                </div>
            </div>
        </div>
</div>