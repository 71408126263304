import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BaseserviceService } from "../baseservice.service";


@Injectable({
    providedIn: 'root'
})
export class MetaDataService {

    public baseUrl;

    private httpHeadersOptions() {
        return this.baseService.httpHeadersOptions();
      }


      private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
    
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status},` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
    
      }

    constructor(private http: HttpClient, public baseService: BaseserviceService) {
        this.baseUrl = baseService.baseUrl1 + 'api/meta/';

    }

    getBUMappingColumns(buId, selTable) {
    
    const URL = this.baseUrl  + 'getcolumns/' + selTable + '/' + buId; 
    return this.http.get<any>(URL,  this.httpHeadersOptions())
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError));
    }

    getClientMappingColumns(buId, selTable) {
    
        const URL = this.baseUrl  + 'dbcolumns/' + buId + '/' + selTable ;
        return this.http.get<any>(URL,  this.httpHeadersOptions())
          .pipe(
            map(data => {
              return data;
            }),
            catchError(this.handleError));
        }

    getBUTablesListing(buId) {
        const URL = this.baseUrl + 'gettables/' + buId;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    /*  Add Meta Data Mapping for BU */

    addMapping(obj, buId) {
        const body = JSON.stringify(obj);
        const URL = this.baseUrl + 'addmapping/' + buId;
        return this.http.post<any>(URL, body, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  Get Mapping List For BU Columns //

    getMappedColumnsForBuTable(buId, cid) {
        const URL = this.baseUrl + 'formlisting/' + cid + '/' + buId;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  Get Meta Mapping List For BU Columns //

    getMappedMetaColumnsForBuTable(buId, cid) {
        const URL = this.baseUrl + 'metaformlisting/' + cid + '/' + buId;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  Check Meta Field Value //

    checkMetaField(obj, cid) {
        let body = JSON.stringify(obj);
        const URL = this.baseUrl + 'checkmetafieldname/' + cid ;
        return this.http.post<any>(URL, body, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  Delete Meta Field //

    deleteMetaField(buId, mapid) {
        const URL = this.baseUrl + 'deletemeta/' + buId + '/' + mapid;
        return this.http.delete<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  Get Client Tables listing for Meta Relation Mapping  ##########  //

    getMetaClientTables(cid) {
        const URL = this.baseUrl + 'dbtables/' + cid ;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    // ########  Get Client BU Mapping List  ##########################  //

    getClientBuTableMapping(cid, tblid) {
        const URL = this.baseUrl + 'mapclient/' + cid + '/' + tblid ;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    // ###########  Update Client - BU Meta Table Relation  #################  //

    createClientBuTableRelation(obj, cid) {
        let body = JSON.stringify(obj);
        const URL = this.baseUrl + 'mapclient/' + cid ;
        return this.http.post<any>(URL, body, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    //  ##########  Change Display on DS Status for Meta field ###################  //

    updateDisplayOnDsStatus(cid, mapid, status) {
        const URL = this.baseUrl + 'isflag/' + cid + '/' + mapid + '/' + status ;
        return this.http.get<any>(URL, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError));
    }

    getMetaFieldsForUpdate(cid, typ, contact_id) {

        return this.http.get<any>(this.baseUrl + 'metafields/' + cid + '/' + typ + '/' + contact_id, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    if (!data.status) {
                        if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                            console.error(data.message);
                            data.message = "Something went wrong.Please try again."
                        }
                    }
                    return data;
                }),
                catchError(this.handleError));
    }

    updateProfileMetaFields(cid, typ, contact_id, obj) {
        let url = this.baseUrl + 'addupdatemetafields/' + cid + '/' + typ + '/' + contact_id;
        let body = JSON.stringify(obj);

        console.log("--body--", body);

        return this.http.post<any>(url, body, this.httpHeadersOptions())
            .pipe(
                map(data => {
                    if (!data.status) {
                        if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                            console.error(data.message);
                            data.message = "Something went wrong.Please try again."
                        }
                    }
                    return data;
                }),
                catchError(this.handleError));
    }

}