<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/users">Admin User List</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ header }} User
      </li>
    </ol>
  </nav>
</div>

<div class="container mt-1">
  <form
    class="form-horizontal mt-1"
    [formGroup]="registerForm"
    (ngSubmit)="addNewUser()"
  >
    <h4 class="mb-1 font-weight-bold">Personal Information</h4>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2"
              >First Name <span class="required">*</span>
            </label>
            <input
              type="text"
              autocomplete="off"
              formControlName="fname"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
              id="fname"
              placeholder="Enter your First Name"
            />
            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
              <div *ngIf="f.fname.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2">Last Name</label>
            <input
              type="text"
              autocomplete="off"
              formControlName="lname"
              class="form-control formstyle"
              placeholder="Enter your Last Name"
            />
            <!-- [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"  required -->
            <!-- <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                        <div *ngIf="f.lname.errors.required">List Name is required</div>
                    </div> -->
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2"
              >Primary Email <span class="required">*</span>
            </label>
            <input
              type="text"
              autocomplete="off"
              formControlName="pemail"
              class="form-control formstyle"
              placeholder="Primary Email"
              [ngClass]="{ 'is-invalid': submitted && f.pemail.errors }"
            />
            <div *ngIf="submitted && f.pemail.errors" class="invalid-feedback">
              <div *ngIf="f.pemail.errors.required">
                Primary Email is required
              </div>
              <div *ngIf="f.pemail.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2">Secondary Email</label>
            <input
              type="text"
              autocomplete="off"
              formControlName="semail"
              class="form-control formstyle"
              placeholder="Secondary Email"
              [ngClass]="{ 'is-invalid': submitted && f.semail.errors }"
            />
            <div *ngIf="submitted && f.semail.errors" class="invalid-feedback">
              <div *ngIf="f.semail.errors.email">
                Secondary Email must be a valid email address
              </div>
            </div>
            <!-- [ngClass]="{ 'is-invalid': submitted && f.semail.errors }"
                        required
                    <div *ngIf="submitted && f.semail.errors" class="invalid-feedback">
                        <div *ngIf="f.semail.errors.required">Secondary Email is required</div>
                    </div> -->
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2">
              Mobile Phone <span class="required">*</span>
            </label>
            <input
              type="text"
              autocomplete="off"
              formControlName="mphone"
              (keydown)="isNumber($event)"
              maxlength="12"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.mphone.errors }"
              placeholder="Mobile Phone"
            />
            <div *ngIf="submitted && f.mphone.errors" class="invalid-feedback">
              <div *ngIf="f.mphone.errors.required">
                Mobile Phone is required
              </div>
              <div *ngIf="f.mphone.errors.minlength">
                Mobile must be 12 digits
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2">Home Phone</label>
            <input
              type="text"
              autocomplete="off"
              formControlName="hphone"
              (keydown)="isNumber($event)"
              maxlength="12"
              class="form-control formstyle"
              placeholder="Home Phone"
            />
            <!-- [ngClass]="{ 'is-invalid': submitted && f.hphone.errors }" required
                    <div *ngIf="submitted && f.hphone.errors" class="invalid-feedback">
                        <div *ngIf="f.hphone.errors.required">Home Phone is required</div>
                        <div *ngIf="f.hphone.errors.minlength">Number must be 10 characters</div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>

    <h4 class="mb-1 font-weight-bold mt-1">Address</h4>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-4 col-md-6 pb-3">
            <!-- <div>
                        <label for="exampleForm2">Title</label>
                        <input type="text" formControlName="title" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Title" required>
                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                            <div *ngIf="f.title.errors.required">Title is required</div>
                        </div>
                    </div> -->
            <!-- <div>
                        <label for="exampleForm2">Address 1</label>
                        <input type="text" formControlName="address1" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" placeholder="Address 1" required>
                        <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                            <div *ngIf="f.address1.errors.required">Address 1 is required</div>
                        </div>
                    </div>
                    <div>
                        <label for="exampleForm2">Address 2</label>
                        <input type="text" formControlName="address2" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" placeholder="Address 2" required>
                        <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                            <div *ngIf="f.address2.errors.required">Address 2 is required</div>
                        </div>
                    </div> -->

            <label for="exampleForm2"
              >Country <span class="required">*</span>
            </label>
            <select
              formControlName="country"
              autocomplete="off"
              class="form-control formstyle"
              placeholder="country"
              [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
              (change)="states($event.target.value)"
            >
              <option value="">Select Country</option>
              <option *ngFor="let Obj of countriesData" value="{{ Obj.sortname }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
              <div *ngIf="f.country.errors.required">Country is required</div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2"
              >State <span class="required">*</span></label
            >
            <select
              formControlName="state"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
              placeholder="State"
              (change)="getCities($event.target.value)"
            >
              <option value="">Select State</option>
              <option *ngFor="let Obj of stateData" value="{{ Obj.name }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
              <div *ngIf="f.state.errors.required">State is required</div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2"
              >City <span class="required">*</span>
            </label>
            <select
              formControlName="city"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
              placeholder="City"
            >
              <option value="">Select City</option>
              <option *ngFor="let Obj of cityData" value="{{ Obj.name }}">
                {{ Obj.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
              <div *ngIf="f.city.errors.required">City is required</div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2"
              >Zip <span class="required">*</span></label
            >
            <input
              type="text"
              autocomplete="off"
              formControlName="zip"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.zip.errors }"
              placeholder="Zip"
            />
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.minlength">
                Zip must be at least 4 characters
              </div>
            </div>
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.maxlength">
                Max. limit reached
              </div>
            </div>
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.required">Zip is required</div>
            </div>
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.pattern">Only Numbers Allowed</div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2">Region</label>
            <input
              type="text"
              formControlName="region"
              class="form-control formstyle"
              placeholder="Region"
            />
          </div>
        </div>
      </div>
    </div>

    <h4 class="mb-1 font-weight-bold mt-1">Others</h4>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-4 col-md-6 pb-3">
            <label for="exampleForm2 "
              >User Role <span class="required">*</span></label
            >
            <select
              formControlName="roleid"
              autocomplete="off"
              class="form-control formstyle"
              placeholder="co"
              [ngClass]="{ 'is-invalid': submitted && f.roleid.errors }"
            >
              <!-- <option value="0">Role</option> -->
              <!-- <option *ngFor="let Obj of getCountryobj" value={{Obj.country_id}}>
                            {{Obj.country_name}}
                        </option> -->
              <option value="" selected>Select User Role</option>
              <optgroup
                *ngFor="let group of getRoleObj"
                label="{{ group.name }}"
              >
                <option *ngFor="let item of group.children" [value]="item.id">
                  {{ item.name }}
                </option>
              </optgroup>
            </select>
            <div *ngIf="submitted && f.roleid.errors" class="invalid-feedback">
              <div *ngIf="f.roleid.errors.required">User Role is required</div>
            </div>
          </div>

          <div
            *ngIf="
              userProfileDetails && userProfileDetails.company_name === 'margo'
            "
            class="col-xl-4 col-md-6 pb-3"
          >
            <label for="exampleForm2"
              >Company <span class="required">*</span>
            </label>
            <div
              *ngIf="
                userProfileDetails &&
                userProfileDetails.company_name !== 'margo'
              "
              style="padding: 10px"
            >
              <samp
                [innerHTML]="userProfileDetails.company_name"
                style="color: #3b4781; font-weight: 900"
              ></samp>
            </div>
            <div
              *ngIf="
                userProfileDetails &&
                userProfileDetails.company_name === 'margo'
              "
            >
              <select
                formControlName="company"
                autocomplete="off"
                class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
              >
              <option value="" selected>Select Company</option>
                <option
                  *ngFor="let Obj of getCompanyobj"
                  value="{{ Obj.customer_id }}"
                >
                  {{ Obj.customer_company_name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.company.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.company.errors.required">
                  company Name is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my_class text-center">
      <div
        class="my_class text-center"
        *ngIf="isWrite"
        [ngClass]="status ? 'displayblock' : 'displaynone'"
      >
        <!-- <div class="col-12 col-md-3"> -->
        <button type="button" class="custbtn mt-2" (click)="addNewUser()">
          Submit
        </button>
        <!-- </div> -->
      </div>
      <div
        class="my_class text-center"
        *ngIf="isEdit"
        [ngClass]="status ? 'displaynone' : 'displayblock'"
      >
        <!-- <div class="col-12 col-md-3"> -->
        <button
          type="button"
          class="custbtn mt-2"
          (click)="updateUserDetails()"
        >
          Update
        </button>
        <!-- </div> -->
      </div>
    </div>
  </form>
</div>
