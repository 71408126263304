<div class="container mt-2">
  <div class="row mt-4 mb-4 ">
    <div class="col-4">
      <h5 class="font-weight-bold ">MC Scheduler Stats</h5>
    </div>
    <div class="col-8">
      <button type="button" class="custbtn  float-right" (click)="goToMcSchedule()">MC Schedule</button>
    </div>
  </div>
  <div class="row">

    <div class="card">
      <div class="card-body">


        <div class="tblPrt mt-4 w-100" *ngIf="status && scheduleStats.length !== 0">
          <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0"   >
            <tr>

              <th >Add Count </th>
<!--              <th>Update Count</th>-->
              <th>Start</th>
              <th>End</th>
              <th>Log Status</th>
              <th>Log Message</th>

            </tr>
            <tr
              *ngFor="let obj of scheduleStats| paginate:{itemsPerPage:limit,currentPage:cp,totalItems:totalItems }">

              <td>{{obj.count_add}}</td>
<!--              <td>{{obj.count_update}}</td>-->
              <td>{{obj.new_log_start}}
<!--                <div>{{obj.log_start|date}}</div>-->
<!--                <div>{{obj.log_start|date: 'shortTime'}}</div>-->
              </td>
              <td>{{obj.new_log_end}}
<!--                <div>{{obj.log_end|date}}</div>-->
<!--                <div>{{obj.log_end|date: 'shortTime'}}</div>-->
              </td>
              <td>{{obj.log_status}}</td>
              <td>{{obj.log_message}}</td>

            </tr>

          </table>

        </div>
        <div class="container mt-2" *ngIf="status && scheduleStats.length === 0">
          <p>No Result Available</p>
        </div>

        <div class="text-center" *ngIf="status && scheduleStats.length !== 0">
          <pagination-controls  class="my-pagination" (pageChange)="getPageData($event)"></pagination-controls>
        </div>

      </div>
    </div>

  </div>
</div>
