import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { ReplaySubject, Subject, of } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {DatePipe, formatDate } from '@angular/common';
import csc from 'country-state-city';
import { nanoid } from 'nanoid';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { MatSelect } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AudienceBuilderService } from 'src/app/services/segmentation/audience-builder.service';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { MasterService } from 'src/app/services/master/master.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.css']
})
export class EventAddComponent extends BaseComponent implements OnInit {
  // rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  // rskmodellogo = this.ReskinningService.rskModelLogo;
  header = 'Add New'
  public userProfileDetails;
  status = false;
  minDate;
  time = { hour: 19, minute: 0 };
  EventForm: FormGroup;
  clickDate;
  event_name;
  event_date;
  event_start_time;
  event_hour = '';
  event_min = '';
  event_ampm = '';
  event_time_zone = '';
  event_details;
  event_address1;
  event_address2;
  event_city;
  event_state;
  event_zip;
  event_country = '';
  event_region;
  event_url;
  eventObj;
  contacttypeid;
  contacttypename;
  parentjourney;
  event_id;
  shopping_url;
  submitted = false;
  host_url;
  countriesData;
  stateData;
  cityData;
  hours;
  mins: string[] = [];
  timezones = [];
  event_url_id;
  country;
  selectedtimezone;
  utc;
  mapURL: any;//="../../../../assets/images/addressNotFound.jpg";
  companyId = 0;
  userData;
  metaVisibility: boolean = false;
  metafields = [];
  metaForm: FormGroup;contactList: any;
;
  event_type: any = false;
  manyToMany: boolean = false;
  //image variable
  imageList: any;
  imageCategoryList: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageSubmitted = false;
  isimageUpdate: boolean = false;
  selelctedImage: any;
  imageForm: FormGroup;;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  public timezoneFilterCtrl: FormControl = new FormControl();
  public filteredTimezones: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  selectedCountryObj: any = { "phonecode": "1", "id": "US", "name": "United States" };
  //variable for description length
  desShowLengthEvent = 0;
  desAllowedLengthEvent = 500;
  eventDescription = '';
  //Category Variables
  showList: boolean = true;
  add: boolean = false;
  edit: boolean = false;
  categForm: FormGroup;;
  categFormEdit: FormGroup;
  showLength = 0;
  allowedLength = 50;
  deleteCategory: boolean = false;
  submittedCat = false;
  selectedId;
  selectedObj;
  oldcatid: any;
  desShowLength = 0;
  desAllowedLength = 100;
  selectedImgCat: any = '';
  skip: number = 0;
  mediaType: any;
  videoUrl: any = '';
  selectedContact:any;
  eventDetails:any;
  constructor(
    private formBuilder: FormBuilder,
    private master: MasterService,
    private datePipe: DatePipe,
    public modalService: NgbModal,
    private shopLiveService : ShopLiveService,
   private audienceBuilderService :AudienceBuilderService,
   private upload:UploadService
  ) {
    super();
    this.minDate = new Date();
    this.baseService.changeValue(false);
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
  }

  ngOnInit() {
    this.header = sessionStorage.getItem('eventAction');
    this.timezoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTimeZones();
      });
    this.metaForm = this.formBuilder.group({
      metaFields: new FormArray([])
    });
    this._createImageForm()
    this.metaDetails();
    this.hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    for (var i = 0; i < 60; i = i + 5) {
      var val = i.toString().length === 1 ? "0" + i : i.toString();
      this.mins.push(val);
    }

    // if (!(sessionStorage.getItem("contactid") && sessionStorage.getItem("market")
    //   && sessionStorage.getItem("contactdata"))) {
    //   let currentURL = this.router.url.replace(/\//g, "_");
    //   let storageData = JSON.parse(localStorage.getItem('HISTORY-SESSIONS'));
    //   sessionStorage.setItem("contactid", storageData[currentURL]['contactid']);
    //   sessionStorage.setItem("contacttypeid", storageData[currentURL]['contacttypeid']);
    //   sessionStorage.setItem("contactdata", storageData[currentURL]['contactdata']);
    //   sessionStorage.setItem("market", storageData[currentURL]['market']);
    //   sessionStorage.setItem("RelatedSourcePage", storageData[currentURL]['RelatedSourcePage']);

    //   if (sessionStorage.getItem("parentjourney"))
    //     sessionStorage.setItem("parentjourney", storageData[currentURL]['parentjourney']);

    //   if (sessionStorage.getItem("eventdata"))
    //     sessionStorage.setItem("eventdata", storageData[currentURL]['eventdata']);
    // }

    // this.parentjourney = JSON.parse(sessionStorage.getItem("parentjourney"));

      this.EventForm = this.formBuilder.group({
        valEventName: ['', Validators.required],
        valEventDate: ['', Validators.required, this.dateValidator()],
        time: ['', Validators.required],
        valHours: [''],
        valMins: [''],
        valAMPM: [''],
        valEventTimeZone: ['', Validators.required],
        valAddress1: [''],
        valcountry: [''],
        valEventCity: [''],
        valstate: [''],
        valZipCode: [''],
        valEventID: [''],
        valEventURL:[''],
        valEvent_URL:[''],
        valEvent_Type: [false],
        parent_event_type:[''],
        contact:['',Validators.required]
      });
      // this.countriesData = csc.getAllCountries();
      this.getAllCountries();
      this.getCustomerList();



    this.EventForm.get('time').valueChanges
      .subscribe(value => {
        this.EventForm.get('valEventDate').updateValueAndValidity();
      });
    var data = JSON.parse(sessionStorage.getItem("market"));
    this.ngxSpinnerService.show();
    this.contacttypeid = data?.id;
    this.contacttypename = data?.name;
    var eventdata = JSON.parse(sessionStorage.getItem("eventdata"));

    this.audienceBuilderService.getTimezones().subscribe(data => {
      if (data.status) {
        this.filteredTimezones.next(data.data.slice());
        this.timezones = data.data;

        if (!eventdata) {
          // this.event_time_zone = this.loginUserData.user_time_zone;
          // var filterdata = this.timezones.filter(data => data.abbr === this.event_time_zone);
          // if (filterdata.length > 0) {
          //   this.event_time_zone = filterdata[0].value;
          // }
        }
        this.EventForm.get('valEventTimeZone').valueChanges
          .subscribe(value => {
            this.setUtc(value);
            let newsysdate = new Date().toLocaleString("en-US", { timeZone: this.utc });
            this.minDate = new Date(newsysdate);
            this.EventForm.get('valEventDate').updateValueAndValidity();
          });
          if(eventdata){
            this.getEventDetails(eventdata?.event_id);
          }
          else{
            this.ngxSpinnerService.hide();
          }
      }
    }, error => {
      this.ngxSpinnerService.hide();
    })

  }

  zipCodeCountryValidator(): any {
    return (control: AbstractControl): ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }

      if (this.event_country === 'AU') {
        if (value.length >= 4) {
          return null
        } else {
          return { zipcodeminlength: 4, ziperror: true }
        }
      } else {
        if (value.length >= 2) {
          return null
        } else {
          return { zipcodeminlength: 2, ziperror: true }
        }
      }

    }

  }

  convertTime12to24 = time12h => {
    const [time, modifier] = time12h.split("  ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  viewMoreRequests(modalName) {
    let self = this;
    self.modalService.open(modalName).result.then((result) => {
    }, (reason) => {

    });
  }

  metaDetails() {
    // this.metaService.getMetaFieldsForUpdate(this.companyId, 4, 0).subscribe(data => {

    //   // console.log("metaDetails", data);
    //   if (data.length > 0) {
    //     this.metaVisibility = true;
    //     this.metafields = data.data;
    //     this.createMetaForm();
    //   }
    //   else {
    //     this.metaVisibility = false;
    //     this.metafields = [];
    //   }
    // }, error => {
    //   this.metaVisibility = false;
    //   this.metafields = [];
    //   console.log(error);
    // });

    // console.log('ssss', this.metaVisibility)
  }

  get g() { return this.metaForm.controls; }
  get t() { return this.g.metaFields as FormArray; }

  //  ##############  META FORM #####################  //

  createMetaForm() {
    let mtdt = JSON.parse(sessionStorage.getItem('metaEventData'));
    if (this.metafields?.length > 0) {
      for (let mt of this.metafields) {

        let vl = null;
        if (mtdt && mtdt.length > 0) {
          let ftobj = mtdt.filter(elem => elem.field_name === mt.field_name);
          if (ftobj?.length === 1) {
            vl = ftobj[0].field_value;
          }
        }

        if (vl === null || vl === undefined) {
          vl = '';
        }

        if (mt.field_type === 'INT') {
          this.t.push(this.formBuilder.group({
            name: [mt.field_name],
            metafname: [vl, [Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(mt.field_size)]],
            lname: [mt.label_name],
            ftyp: 'Integer'
          }));
        } else {

          if (mt.field_type === 'NUMERIC') {
            this.t.push(this.formBuilder.group({
              name: [mt.field_name],

              metafname: [vl, [Validators.pattern(/^[0-9]\d*[.][0-9]\d*$/), Validators.maxLength(mt.field_size)]],

              lname: [mt.label_name],
              ftyp: 'Float'
            }));
          } else {
            if (mt.field_type === 'BOOLEAN') {
              this.t.push(this.formBuilder.group({
                name: [mt.field_name],
                metafname: [vl, this.checkBool],
                lname: [mt.label_name],
                ftyp: 'Boolean'
              }));
            } else {
              if (mt.field_type === 'timestamp') {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, [Validators.pattern(/^[1-9]([0-9]{3,3})[-][0-1][0-9]-[0-3][0-9][T][0-2][0-9][:][0-5][0-9][:][0-5][0-9][.]([0-9]{3,3})([Z])$/), Validators.maxLength(mt.field_size)]],
                  lname: [mt.label_name],
                  ftyp: 'Tmstamp'
                }));
              } else {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, Validators.maxLength(mt.field_size)],
                  lname: [mt.label_name],
                  ftyp: 'String'
                }));
              }
            }
          }
        }

      }
    }
  }

  /* Check Boolean value Validator */

  checkBool(control: FormControl) {
    let val = control.value;
    if (val === 'true' || val === 'false' || val === '') {
      return null;
    }
    return {
      boolCheck: {
        val: false
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  private filterTimeZones() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    if (!search) {
      this.filteredTimezones.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the timezone
    this.filteredTimezones.next(
      this.timezones.filter(timezone => timezone.text.toLowerCase().indexOf(search) > -1)
    );
  }

  drawMap() {
    var eventdataSession = JSON.parse(sessionStorage.getItem("eventdata"));
    if (eventdataSession) {
      var eventData = {
        event_address1: eventdataSession.event_address1,
        event_address2: eventdataSession.event_address2,
        event_country: eventdataSession.event_country,
        event_city: eventdataSession.event_city,
      }
    }
    else {
      var eventData = {
        event_address1: this.event_address1,
        event_address2: this.event_address2,
        event_country: this.event_country ? this.f.valcountry.value.name : '',
        event_city: this.event_city ? this.event_city.name : '',
      }
    }

    let self = this;
    // self.master.getMapViewFromAddress(eventData).subscribe(data => {
    //   //console.log(data)
    //   this.mapURL = data.mapURL
    // })
  }

  get f() { return this.EventForm.controls; }
  setUtc(event_time_zone:any) {
    this.selectedtimezone = this.timezones.filter(timezone => timezone.value === event_time_zone);

    if (this.selectedtimezone[0]?.utc?.length > 1) {
      this.utc = this.selectedtimezone[0]?.utc[1];
    }
    else {
      this.utc = this.selectedtimezone[0]?.utc[0];
    }
  }
  dateValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      const dateStr = control.value;
      this.setUtc(this.event_time_zone);


      let newsysdate = new Date().toLocaleString("en-US", { timeZone: this.utc });
      const now = (new Date(newsysdate));
      now.setSeconds(0, 0);
      const invalidObj = { 'date': true };
      const date = new Date(new Date(dateStr).setHours(this.EventForm.get('time').value.hour, this.EventForm.get('time').value.minute, 0, 0));

      if (date < now) {
        return of(invalidObj);
      }
      return of(null);
    };
  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? of(null) : of({ validUrl: true });
  }

  states(event:any) {
    let id = event.target.value;
    this.country = this.event_country;
    this.EventForm.get('valZipCode').patchValue('');
    // this.stateData = this.getStatesOfCountry(this.event_country);
    this.event_state = '';
    this.event_city = '';
    this.cityData = [];
    this.stateData = csc.getStatesOfCountry(id);

  }

  getCities() {
    this.cityData = csc.getCitiesOfState(this.event_state);
    this.event_city = '';
    if (this.cityData && this.cityData.length > 0) {
      this.event_city = '';
    }
    else {
      this.cityData = [this.event_state];
    }
  }

  OnFormSubmit() {
    this.submitted = true;

    if (this.EventForm.invalid) {
    console.log(this.EventForm);
      return;
    }
    if (this.metaForm.invalid) {
      this.toastrService.error('Please provide correct Meta Field values');
      return;
    }
    if(this.desShowLengthEvent >this.desAllowedLengthEvent){
      this.toastrService.error('Description maximum word limit voilated');
      return;
    }
    this.addEvent();
  }

  goBack() {
    sessionStorage.removeItem("parentjourney");
    this.location.back();
  }

  addEvent() {
    if (this.time.hour >= 12) {
      this.event_ampm = 'pm'
    } else {
      this.event_ampm = 'am'
    }
    if (this.event_url_id) {
      this.event_url_id = this.event_url_id.trim();
    }

    if (this.manyToMany == false) {
      this.event_type = false;
    } else {
      this.event_type = true;
    }

    if (this.event_url_id === undefined) {
      this.event_url_id = nanoid(6);
    }
    let selectedContact =  this.contactList.filter((e:any)=>{
      return e.contact_id == parseInt(this.f.contact.value)
  })
    var obj = {
      event_name: this.event_name,
      event_date: this.datePipe.transform(this.event_date, 'yyyy-MM-dd'),
      event_start_time: (this.time.hour % 12 ? (this.time.hour % 12 < 10 ? "0" + this.time.hour % 12 : this.time.hour % 12) : 12) + ':' + (this.time.minute < 10 ? "0" + this.time.minute : this.time.minute) + ' ' + this.event_ampm,
      event_time_zone: this.event_time_zone,
      event_details: this.event_details,
      event_address1: this.event_address1,
      event_address2: this.event_address2,
      event_city: this.event_city ? this.event_city : '',
      event_state: this.event_state ? this.event_state : '',
      event_zip: this.event_zip,
      event_country: this.event_country ? this.event_country : '',
      event_region: this.event_region,
      event_url: this.event_url,
      isActive: true,
      pws: this.host_url,
      event_url_id: this.event_url_id,

      event_shopping_url: this.shopping_url,
      event_time_zone_abbr: "",
      // customer_company_logo: this.loginUserData.customer_company_logo,
      event_type: this.event_type,
      res_id: this.selelctedImage ? this.selelctedImage?.res_id : '',
      res_rel_id:this.eventDetails?.res_rel_id,
      source_type: 'event',
      event_description: this.eventDescription || '',
      parent_event_type: parseInt(this.parentjourney),
      contact: parseInt(this.f.contact.value),
      event_id:this.eventDetails?.event_id,
      event_theme_id: this.eventDetails?.event_theme_id,
      resource_type:this.selelctedImage? this.selelctedImage?.resource_type : '',
      resource_preview_url:this.selelctedImage? this.selelctedImage?.resource_preview_url : '',
    }
    var filteredTimeZone = this.timezones.filter(data => data.value === this.event_time_zone);
    if (filteredTimeZone && filteredTimeZone.length > 0) {
      obj.event_time_zone_abbr = filteredTimeZone[0].abbr;
    }


    if(this.header=='Add new'){
      this.ngxSpinnerService.show();
      this.shopLiveService.checkEventIdExists({ event_url_id: this.event_url_id },this.companyId).subscribe((result: any) => {
        if (result.status) {
          // sessionStorage.setItem("eventdata", JSON.stringify(obj));
          // // var mainlurl = this.basedsurl + "market/" + this.contacttypename + "/subtype";
          // var mainlurl = `/corporate/theme-list`;
          // this.router.navigateByUrl(mainlurl);
          sessionStorage.setItem("contactdata", JSON.stringify(selectedContact[0]));
          sessionStorage.setItem("eventdata", JSON.stringify(obj));
          this.router.navigateByUrl(`/corporate/theme-list`);
        }
        else {
          this.toastrService.error(result.message);
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.ngxSpinnerService.hide();
        console.log(error);
      });
    }
    else if(this.header=='Update'){
      // console.log(obj);

      sessionStorage.setItem("contactdata", JSON.stringify(selectedContact[0]));
      sessionStorage.setItem("eventdata", JSON.stringify(obj));
      this.router.navigateByUrl(`/corporate/theme-list`);
    }

    let fnarr = [];
    for (let fd of this.t.controls) {
      let fn = fd['controls'].name.value;
      let mfnm = fd['controls'].metafname.value;
      if (fd['controls'].metafname.value !== null && fd['controls'].metafname.value !== undefined) {
        if (typeof mfnm === 'string') {
          mfnm = mfnm.trim();
        }
      }
      let obj2 = {
        field_name: fn,
        field_value: mfnm,
        label_value: fd['controls'].lname.value
      }
      fnarr.push(obj2);
      sessionStorage.setItem("metaEventData", JSON.stringify(fnarr));
    }
  }

  getCitiesOfState(state:any, countryId: any) {
    this.audienceBuilderService.getCitiesOfState(countryId.trim(), state.trim()).subscribe((objArr: any[]) => {
      this.cityData = objArr;
    });
  }

  getCountryById(countryId: string) {
    // this.master.getCountryByCode(countryId).subscribe((objArr: any) => {
    //   this.selectedCountryObj = objArr[0];
    // });
  }

  getStatesOfCountry(event: any) {
    // if (!isNaN(countryId)) {
    //   // if number
    //   countryId = this.getCountryISOfromNPMPack(countryId);
    // }
    this.audienceBuilderService.getStatesOfCountry(event).subscribe(objArr => {
      this.stateData = objArr;
    });
  }



  getAllCountries() {
    this.audienceBuilderService.getAllCountries().subscribe(objArr => {
      this.countriesData = objArr;
    });
  }

  // ######## Paste event on Event ID field  #############  //

  onPaste(e) {
    return false;
  }


  //  ################  Check Event Name Length  ######################  //

  checkEventNameLength(e) {
    if (this.EventForm.value.valEventName === undefined) {
      return;
    }
    if (this.event_name.length >= 500) {
      this.toastrService.error('You have maximum allowed character limit(500) for Event Name');
    }
  }

  getslEventUrl() {
    this.ngxSpinnerService.show();
    this.shopLiveService.getShopLiveEventUrl(this.companyId).subscribe(data => {
      if (data.status) {
        this.event_url = data.data;
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    })
  }

  getEventDate(date) {
    let dateObj = new Date(date)
    dateObj.setMinutes((dateObj.getMinutes() + dateObj.getTimezoneOffset()))
    return dateObj;
  }
  //  ################  resource image code  ######################  //
  viewResourceImageModal(modalName) {
    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      // scrollable: true
    }).result.then((result) => {
    }, (reason) => {// console.log("reason", reason);
    });
    this._getResourceCategory('')
  }
  getResourceImageList(action:any) {
    this.ngxSpinnerService.show();
    this.master.getReosurceList(this.companyId,this.skip, 0, '',this.selectedImgCat).subscribe(result => {
      this.ngxSpinnerService.hide();
      if (result.status) {
        if(action == 'scroll'){
          this.imageList.push(...result.data.rows);
        }else{
          this.imageList = result.data.rows;
        }

      }
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
      this.imageList = null;
    });
  }
  _getResourceCategory(imageFilter?: any) {
    this.imageCategoryList = null;
    this.ngxSpinnerService.show();
    this.master.getReosurceCategoryList(this.companyId, 100, 0, imageFilter).subscribe(result => {
      this.ngxSpinnerService.hide();
      if (result.status) {
        this.imageCategoryList = result.data.rows;
      }
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
      this.imageCategoryList = null;
    });
  }
  _createImageForm() {
    this.imageForm = this.formBuilder.group({
      resource_type: [''],
      resource_title: ['', Validators.required],
      resource_desc: [''],
      resource_url: ['', Validators.required],
      resource_preview_url: ['', Validators.required],
      cate_id: ['', Validators.required],
    })
  }
  get res() { return this.imageForm.controls; };
  uploadImage() {
    let path = 'resource_images';
    let imageExtension = this.imageChangedEvent.target.files[0].name.split('.').pop();
    let currentDateNewFormat = formatDate(new Date(), 'MMddYYYYHHMMSSSS', 'en_US');
    // Random 3 digits
    let min = Math.ceil(100);
    let max = Math.floor(999);
    let randomThreeDigit = Math.floor(Math.random() * (max - min + 1) + min);
    let imageName = 'resource_cropped' + currentDateNewFormat + randomThreeDigit.toString() + '.' + imageExtension;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(imageName,path,this.croppedImage).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Location) {
            this.imageForm.get("resource_preview_url").setValue(params.Location);
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  uploadOriginalImage() {
    let imageBase64: any;
    // this.ngxSpinnerService.show();
    let path = 'resource_images';
    let imageExtension = this.imageChangedEvent.target.files[0].name.split('.').pop();
    let currentDateNewFormat = formatDate(new Date(), 'MMddYYYYHHMMSSSS', 'en_US');
    // Random 3 digits
    let min = Math.ceil(100);
    let max = Math.floor(999);
    let randomThreeDigit = Math.floor(Math.random() * (max - min + 1) + min);
    let imageName = 'resource_original' + currentDateNewFormat + randomThreeDigit.toString() + '.' + imageExtension;;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageChangedEvent.target.files[0]);
    reader.onload = () => {
      imageBase64 = reader.result;
      this.ngxSpinnerService.show()
      this.upload.uploadImage(imageName,path,imageBase64).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Location) {
              this.imageForm.get("resource_url").setValue(params.Location);
            }
          }else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    }
  }
  fileChangeEvent(event: any, modal: any): void {
    let file = event.target.files[0];
    let regexImage = new RegExp("^image/");
    if (regexImage.test(file.type) === false) {
      this.toastrService.error("Please select a valid image format");
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          let width = img.width;
          if (width < 800) {
            this.toastrService.error('Image width must be aleast 800px');
            event.target.value = null;
            return;
          }
          else {
            this.imageChangedEvent = event;
            // this.ngxSpinnerService.show();
            this.modalService
              .open(modal, {
                ariaLabelledBy: "modal-basic-title",
                size: "sm",
                backdrop: "static",
                keyboard: false,
              })
              .result.then(
                (result) => { },
                (reason) => { }
              );
          }
        };
      };
      reader.readAsDataURL(file);
    }
  }
  videoUpload(event: any) {
    let file = event.target.files[0];
    let regexImage = new RegExp("^video/");
    if (regexImage.test(file.type) === false) {
      this.toastrService.error("Please select a valid video format");
      return;
    }
    if (file.size > 50 * 1024 * 1024) {
      this.toastrService.error('Maximum video size must be 50mb');
      return;
    }
    //convert file to base64
    this.uploadOriginalVideo(file)
  }
  uploadOriginalVideo(file: any) {
    this.videoUrl = ''
    let videoBase64: any;
    // this.ngxSpinnerService.show();
    let path = 'resource_video';
    let videoExtension = file.name.split('.').pop();
    let currentDateNewFormat = formatDate(new Date(), 'MMddYYYYHHMMSSSS', 'en_US');
    // Random 3 digits
    let min = Math.ceil(100);
    let max = Math.floor(999);
    let randomThreeDigit = Math.floor(Math.random() * (max - min + 1) + min);
    let imageName = 'resource_original' + currentDateNewFormat + randomThreeDigit.toString() + '.' + videoExtension;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      videoBase64 = reader.result;
      this.ngxSpinnerService.show()
      this.upload.uploadImage(imageName,path,videoBase64).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Location) {
              this.videoUrl = params.Location
              this.imageForm.get("resource_url").setValue(params.Location);
              this.imageForm.get("resource_preview_url").setValue(params.Location);
            }
          }else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: any) {
    this.ngxSpinnerService.hide();
  }
  saveImageData() {
    this.imageSubmitted = true;
    if (this.imageForm.invalid) {
      return;
    }
    this.imageForm.get("resource_type").setValue(this.mediaType);
    this.ngxSpinnerService.show();
    this.master.addReosurceImage(this.imageForm.value).subscribe(result => {
      this.ngxSpinnerService.hide();
      this.croppedImage = null;
      this.videoUrl = null;
      this.imageSubmitted = false;
      this.imageChangedEvent = null;
      this.imageForm.reset();
      this.isimageUpdate = false
      this.getResourceImageList('change')

    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
      this.imageSubmitted = false;
    });
  }
  backToImageList() {
    this.isimageUpdate = false;
    this.imageForm.reset();
    this.imageSubmitted = false;
    this.imageChangedEvent = null;
    this.croppedImage = null
  }
  //Added description length
  countDesLength(e:any) {
    // let stat = 0;
    // if (this.desShowLengthEvent === this.desAllowedLengthEvent - 1) {
    //   stat = 1;
    // }
    // this.desShowLengthEvent = e.target.value.length;
    // if (this.desShowLengthEvent === this.desAllowedLengthEvent && stat) {
    //   this.toastrService.error('You have reached maximum character limit allowed');
    // }
    let stat = 0;
    stat =this.eventDescription.trim().split(/\s+/).length;
    this.desShowLengthEvent = stat;
    if(this.eventDescription==''){this.desShowLengthEvent=0}
    if (this.desShowLengthEvent >= this.desAllowedLengthEvent && stat) {
      this.toastrService.error('You have reached maximum word limit allowed');
    }


    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25)+"px";
    console.log(e.target.style.height);
  }

  // #################### Add resource category code ##########################
  viewCategory(modalName) {
    let self = this;
    self.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title', backdrop: 'static',
      keyboard: false
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  closeCategory(modalName) {
    modalName.close();
    this._getResourceCategory('');
  }
  _createCategoryForm() {
    this.categForm = this.formBuilder.group({
      catid: ['', Validators.required],
      catname: ['', [Validators.required, this.noWhitespaceValidator]],
      catdescription: ['']
    });
    this.categFormEdit = this.formBuilder.group({
      catid: ['', Validators.required],
      catname: ['', [Validators.required, this.noWhitespaceValidator]],
      catdescription: ['']
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  countLength(e: any) {
    let stat = 0;
    if (this.showLength === this.allowedLength - 1) {
      stat = 1;
    }
    this.showLength = e.target.value.length;
    if (this.showLength === this.allowedLength && stat) {
      this.toastrService.error('You have reached maximum character limit allowed');
    }
  }
  cancelCategory() {
    this.showList = true;
    this.add = false;
    this.edit = false;
    this.deleteCategory = false;
  }
  addCategory() {
    this.showList = false;
    this.add = true;
    this.edit = false;
    this.showLength = 0;
    this.desShowLength = 0;
    this.categForm = this.formBuilder.group({
      catname: ['', [Validators.required, this.noWhitespaceValidator]],
      catdescription: ['']
    });
  }
  get catf() { return this.categForm.controls; }
  get fed() { return this.categFormEdit.controls; }
  saveCategory() {
    this.submittedCat = true;
    if (this.categForm.invalid) {
      return;
    }
    let { catname, catdescription } = this.categForm.value;

    let obj = {
      category_name: catname,
      category_desc: catdescription,
      status: true,
      company: this.companyId
    }
    var self = this;
    this.ngxSpinnerService.show();
    this.master.createReosurceCategory(obj).subscribe(data => {
      if (data.status) {
        this.add = false;
        this.edit = false;
        this.showLength = 0;
        this.desShowLength = 0;
        this._getResourceCategory('');
        this.toastrService.success("Successfully added");
        this.showList = true;
        this.deleteCategory = false;
        this.submittedCat = false
        this.ngxSpinnerService.hide();
      } else {
        self.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    });
  }
  editCategory(id, selectObj) {
    this.showLength = 0;
    this.desShowLength = 0
    this.showList = false;
    this.add = false;
    this.edit = true;
    this.selectedId = id;
    this.selectedObj = selectObj;

    this.categFormEdit = this.formBuilder.group({
      catid: [this.selectedId, Validators.required],
      catname: [selectObj.category_name, [Validators.required, this.noWhitespaceValidator]],
      catdescription: [selectObj.category_desc, [this.noWhitespaceValidator]]
    });
    this.showLength = this.categFormEdit.value.catname?.length;
    this.desShowLength = this.categFormEdit.value.catdescription?.length;
  }
  updateCat() {
    let { catname, catdescription } = this.categFormEdit.value;
    this.submittedCat = true;
    if (!catname) {
      this.categFormEdit = this.formBuilder.group({
        catid: [this.selectedId, Validators.required],
        catname: ['', [Validators.required, this.noWhitespaceValidator]],
        catdescription: ['']
      });
    } else {
      let obj = {
        cate_id:  Number.parseInt(this.selectedId,10),
        category_name: catname,
        category_desc: catdescription,
        company: this.companyId
      }
      this.ngxSpinnerService.show();
      this.master.updateResCategory(obj).subscribe(data => {
        if (data.status) {
          this.add = false;
          this.edit = false;
          this.showLength = 0;
          this.desShowLength = 0;
          this._getResourceCategory('');
          this.toastrService.success("Successfully Updated");
          this.showList = true;
          this.deleteCategory = false;
          this.submittedCat = true;
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
      })
    }
  }
  cancelEvent(catid, cname) {
    this.showList = false;
    this.add = false;
    this.edit = false;
    this.deleteCategory = true;
    this.submittedCat = false;
    this.oldcatid = catid;
  }
  delCategoryUserData() {
    let self = this;
    let catdata = { is_delete: true, cate_id: this.oldcatid, company : this.companyId }
    this.ngxSpinnerService.show();
    this.master.updateResCategoryStatus(catdata).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Successfully deleted");
        this.showList = true;
        this.deleteCategory = false;
        this.oldcatid = null;
        this._getResourceCategory('');
      } else {
        this.toastrService.error();
        self.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    });
  }
  //Added description length
  countDesLengthCat(e) {
    let stat = 0;
    if (this.desShowLength === this.desAllowedLength - 1) {
      stat = 1;
    }
    this.desShowLength = e.target.value.length;
    if (this.desShowLength === this.desAllowedLength && stat) {
      this.toastrService.error('You have reached maximum character limit allowed');
    }
  }
  // Filter Image resource on Category
  imgResourceOnCat(action: any) {
    // if (action == 'scroll') {
    //   this.skip += 16;
    //   if (this.selectedImgCat !== '') {
    //     // this.contactSegmentService.getResourceImageByCategory(this.selectedImgCat, 16, this.skip).subscribe(result => {
    //     //   if (result.status) {
    //     //     this.imageList.push(...result.data.rows);
    //     //   }
    //     // }, error => {
    //     //   console.log(error);
    //     // });
    //   }
    //   else {
    //     // this.contactSegmentService.getResourceImageList(16, this.skip, '').subscribe(result => {
    //     //   if (result.status) {
    //     //     this.imageList.push(...result.data.rows);
    //     //   }
    //     // }, error => {
    //     //   console.log(error);
    //     // });
    //   }
    // }
    // if (action == 'change') {
    //   this.skip = 0;
    //   if (this.selectedImgCat !== '') {
    //     // this.ngxSpinnerService.show();
    //     // this.contactSegmentService.getResourceImageByCategory(this.selectedImgCat, 16, 0).subscribe(result => {
    //     //   this.ngxSpinnerService.hide();
    //     //   if (result.status) {
    //     //     this.imageList = result.data.rows;
    //     //   } else {
    //     //     this.imageList = null;
    //     //   }
    //     // }, error => {
    //     //   console.log(error);
    //     //   this.ngxSpinnerService.hide();
    //     //   this.imageList = null;
    //     // });
    //     this.getResourceImageList();
    //   }
    //   else {
    //     // this.imageList = null;
    //     this.getResourceImageList();
    //   }
    // }

    if(action == 'scroll'){
      this.skip += 16;
      this.getResourceImageList('scroll');
    }
    else if(action == 'change'){
      this.skip = 0;
      this.getResourceImageList('change');
    }

  }
  getCustomerList(){
    this.shopLiveService.getCustomerListdrp(this.companyId).subscribe((res:any)=>{
      if(res.status){
        this.contactList = res.data;
      }
      else{
        this.contactList = [];
        this.toastrService.error('Something went wrong !')
      }
    }
    )
  }
  addEventFormControl(event){
    let type = parseInt(event);
    if(type == 128){
      this.EventForm.addControl('valEvent_URL',new FormControl(['',Validators.required,this.ValidateUrl]));
      if(this.EventForm.get('valEventURL')){this.EventForm.get('valEventURL').setErrors(null);}
      this.EventForm.get('valAddress1').setErrors(null);
      this.EventForm.get('valcountry').setErrors(null);
      this.EventForm.get('valEventCity').setErrors(null);
      this.EventForm.get('valstate').setErrors(null);
      this.EventForm.get('valZipCode').setErrors(null);
    }
    else if(type== 129){
      this.EventForm.addControl('valEventURL',new FormControl(['',Validators.required,this.ValidateUrl]));
      if(this.EventForm.get('valEvent_URL'))
      {this.EventForm.get('valEvent_URL').setErrors(null);}
    }
    else {
      if (this.EventForm.get('valEventURL'))
        this.EventForm.get('valEventURL').setErrors(null);
      if (this.EventForm.get('valEvent_URL'))
        this.EventForm.get('valEvent_URL').setErrors(null);
    }
    if(type != 128)
    {
      this.EventForm.get('valAddress1').setValidators([Validators.required]);
      this.EventForm.get('valcountry').setValidators([Validators.required]);
      this.EventForm.get('valEventCity').setValidators(Validators.required);
      this.EventForm.get('valstate').setValidators(Validators.required);
      this.EventForm.get('valZipCode').setValidators([Validators.required, Validators.pattern(AppConstants.regex.zipcode), this.zipCodeCountryValidator(), Validators.maxLength(10)]);
    }
    this.EventForm.updateValueAndValidity();

  }
  onCancel(){
    sessionStorage.removeItem('eventdata');
    sessionStorage.removeItem('contactdata');
    sessionStorage.removeItem('themes_list_data');
    sessionStorage.removeItem('eventAction');
    this.router.navigateByUrl('/corporate/events');
  }
  getEventDetails(eventId:any){
    this.ngxSpinnerService.show();
    this.eventDetails = [];
    this.shopLiveService.getEventById(this.companyId,eventId).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      if(res.status){
        this.eventDetails = res.data;
        this.getCountryById(this.eventDetails.event_country);
        if (this.eventDetails) {

          this.event_name = this.eventDetails.event_name;
          this.event_date = this.eventDetails.event_date;
          // this.event_date = this.getEventDate(this.event_date);
          var timearr = this.eventDetails.event_start_time.split(':');
          if (timearr[0].startsWith('0')) {
            let hour = timearr[0].split('0')[1];

            this.time.hour = timearr[1].split(' ')[1] === 'pm' ? parseInt(hour) + 12 : parseInt(hour) % 12;
          }
          else {
            this.time.hour = timearr[1].split(' ')[1] === 'pm' ? parseInt(timearr[0]) + 12 : parseInt(timearr[0]) % 12;
          }
          timearr = timearr[1].split(' ');
          if (timearr[0].startsWith('0')) {
            let minute = timearr[0].split('0')[1];

            this.time.minute = minute ? parseInt(minute) : 0;

          }
          else {
            this.time.minute = parseInt(timearr[0].trim());
          }



          var timearr2 = this.eventDetails.event_start_time.split(':');
          let hr = timearr2[0];

          if (hr == '00') {
            hr = 9;
          }

          if (hr.length == 1) {
            hr = '0' + hr;
          }

          this.parentjourney = this.eventDetails.event_type_id;
          this.event_country = this.eventDetails.event_country;
          let minutes = timearr2[1].substr(0, 2);
          let ampm = timearr2[1].substr(2,3);


          let finalTime = hr + ':' + minutes + ' ' + ampm;
          var convertedTime = this.convertTime12to24(finalTime);
          var timearr3 = convertedTime.split(':');
          let convertedHour = timearr3[0];
          let convertedMinute = timearr3[1];
          this.time = { hour: +convertedHour, minute: +convertedMinute };
          this.event_ampm = timearr[1];
          this.event_details = this.eventDetails?.event_details;
          this.event_address1 = this.eventDetails?.event_address1;
          this.event_address2 = this.eventDetails?.event_address2;
          this.event_city = this.eventDetails?.event_city;
          this.event_state = this.eventDetails?.event_state;
          this.event_zip = this.eventDetails?.event_zip;
          this.manyToMany = this.eventDetails?.shop_live_many;
          this.event_region = this.eventDetails?.event_region;
          this.event_url = this.eventDetails?.event_url;
          this.host_url = this.eventDetails?.event_host_pws;
          this.event_url_id = this.eventDetails?.event_url_id;
          this.shopping_url = this.eventDetails?.event_shopping_url;
          this.selectedContact = this.eventDetails?.event_primary_host_id;
          this.event_time_zone  = this.eventDetails?.event_time_zone;
          this.eventDescription = this.eventDetails?.event_description;
          let obj = {
            resource_type:this.eventDetails?.resource_type,
            resource_preview_url:this.eventDetails?.resource_preview_url,
            res_id:this.eventDetails?.res_id
          }
          this.selelctedImage = obj;
          if (this.parentjourney) {
            this.addEventFormControl(this.parentjourney);
          }
          if (this.event_country) {

            this.stateData = this.getStatesOfCountry(this.event_country);
            if (this.event_state) {
              this.cityData = this.getCitiesOfState(this.event_state, this.event_country);

              this.event_city = this.eventDetails.event_city;
            } else {
              this.cityData = [];
            }
          } else {
            this.stateData = [];
            this.cityData = [];
          }

          this.drawMap();
        }

      }
      else {
        this.time.hour = 19;
        this.time.minute = 0;
        this.mapURL = "../../../../assets/images/addressNotFound.jpg";
      }

    },
    (error:any)=>{
      this.eventDetails = [];
      this.ngxSpinnerService.hide();
    }
    )
  }
  getTypeName(){
    if(this.parentjourney==127){
      return "In-Person"
    }else if(this.parentjourney==128){
      return "Virtual"
    }
    else if(this.parentjourney==129){
      return "In-Person & Virtual"
    }
    else{
      return ''
    }
  }
}

