import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-add-content-builder-journey',
  templateUrl: './add-content-builder-journey.component.html',
  styleUrls: ['./add-content-builder-journey.component.css']
})
export class AddContentBuiderJourneyComponent extends BaseComponent implements OnInit {
  name;
  description;
  screenshot: String;
  IsActive;
  id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey;
  them_company;
  getCompanyobj:any=[];
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;
  journeyTypeObj = [];
  masterjourney = []; 

  submitted = false;
  templateForm: FormGroup;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.controlEnabled = true;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = params.companyID;

      });

      this.templateForm = this.formBuilder.group({
        company: ['', Validators.required],
        journey_name: ['', Validators.required],
        journey_description: [''],     
        journey_type_id: ['', Validators.required],
        master_journey_type_id: ['', Validators.required]
      });

    if (this.id) {
      this.getCompanyJourney(this.companyID);
      this.UpdateTemplateLogData()
    }
    else {
      this.addTemplateLogData()
    }
    //get item details using id
    if (this.id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.cb.getJourneyById(this.companyID,this.id).subscribe(response => {
       
        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          self.dataBinding();
          
           
        } else {
          this.ngxSpinnerService.hide();
        }
      });
    }
    
  }
  get f() { return this.templateForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }

  addJourney() {
    this.submitted = true;
    //console.log(this.templateForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
    //console.log("themescreenshot -- ", this.templateForm);

    if (this.templateForm.invalid) {
      return;
    }
    let { company, journey_name, journey_description, journey_type_id } = this.templateForm.value;
      let themeObj = {
      journey_name: journey_name,
      journey_description: journey_description,
      journey_type_id: journey_type_id,     
      company: company,
      master_journey_type_id: this.templateForm.value.master_journey_type_id
    }
    // console.log("themeObj", themeObj);
    this.ngxSpinnerService.show();
    this.cb.addingCBJourney(themeObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-journey`);
      } else { 
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
    // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  getCompanyJourney(id){

    if(id) {
  
    let self = this;
    this.ngxSpinnerService.show();
    this.getMasterJourneyType(id);
    this.f.journey_type_id.setValue("");
    this.cb.getCBJourneyType(id).subscribe(data => {
      if( data.data) {
      self.journeyTypeObj = data.data;
      this.ngxSpinnerService.hide();
      }
      else {
         self.journeyTypeObj = [];
         this.masterjourney = []; 
         this.ngxSpinnerService.hide(); }
    }, error => {
      console.log(error);
      this.masterjourney = []; 
      this.ngxSpinnerService.hide();
    });
  }
  else {
    this.masterjourney = []; 
    this.journeyTypeObj=[];
    this.ngxSpinnerService.hide();

  }

  }

  getMasterJourneyType(id) {
    this.cb.getMJourney(id).subscribe(data => {
      if( data.status && data.data) {
      this.masterjourney = data.data;
      }
      else {
         this.masterjourney = []; 
        }
    }, error => {
      this.masterjourney = []; 
      console.log(error);
    }); 
  }

  addTemplateLogData() {
    let themeLogs = {
      log_type: "Adding New Template",
      log_name: "Adding New Template",
      log_description: "Adding New Template",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
    //  console.log(error);
    });
  }
  UpdateTemplateLogData() {
    let themeLogs = {
      log_type: "Updating Template Data",
      log_name: "Updating Template Data",
      log_description: "Updating Template Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
 
    let mj = this.themObj.master_journey_type_id ? this.themObj.master_journey_type_id: '';
  this.templateForm = this.formBuilder.group({
    company: [this.companyID, Validators.required],
    journey_name: [this.themObj.journey_name, Validators.required],
    journey_description: [this.themObj.journey_description],
    journey_type_id: [this.themObj.journey_type_id],
    master_journey_type_id: [mj, Validators.required],
  });
  this.ngxSpinnerService.hide();
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     // console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  updateJourney() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    let { company, journey_name, journey_description, journey_type_id } = this.templateForm.value;
    let themobj = {
      journey_name: journey_name,
      journey_description: journey_description,
      journey_type_id: journey_type_id,    
      id: Number.parseInt(this.id, 10),
      company: company,
      master_journey_type_id: this.templateForm.value.master_journey_type_id
    }
   // console.log('themobj', themobj);
    this.ngxSpinnerService.show();
    this.cb.updateCBJourney(themobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/cb-journey`);
        this.ngxSpinnerService.hide();
      } else { 
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
 
}