<div class="container">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="breadCust">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">MetaData List</li>
                </ol>
              </nav>
        </div>
        <div class="col-md-8" >
            <div  style="justify-content: right " class="float-left" *ngIf="customerID === 0">
              <input type="button" value="Add/Edit Metadata" *ngIf="isWrite" class="custbtn red-lt-icon ml-0" routerLink="/corporate/add-metadata"/>    
            </div>
          
            <div  style="justify-content: right" *ngIf="customerID === 0">
              <input type="button" value="Update Client Table Relation" *ngIf="isWrite" class="custbtn red-lt-icon ml-0 " routerLink="/corporate/meta-client-table"/>    
            </div>
          </div>      
    </div>
  </div>

  <div class="container">
    <form class="form-horizontal mt-1" [formGroup]="buForm">
        <div class="row">
            <div class="col-md-10 col-xl-7 mt-1">
                <div class="card mt-1 mb-3">
                    <div class="card-body">
                        <div *ngIf="customerID === 0">
                            <label for="buForm">BU List <span class="required">*</span>
                            </label>
                            <select autocomplete="off" formControlName="buid" (change)="onBuSelected()"
                                class="form-control formstyle">
                                <option value="">
                                    Select BU
                                </option>
                                <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                                    {{ obj.customer_company_name }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="buTables.length > 0" class="mt-2">
                            <label for="buForm">BU Tables List <span class="required">*</span>
                            </label>
                            <select autocomplete="off" formControlName="selTable" (change)="onBuTableSelected()"
                                class="form-control formstyle">
                                <option value="">
                                    Select BU Table
                                </option>
                                <option *ngFor="let obj of buTables" value="{{obj.table_alias}}">
                                    {{ obj.table_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="container " *ngIf="this.selectedTable">
  <h5>Meta Fields Mapping</h5>
  <div class="tblPrt" >
    <table class="mt-1 bdrStCustom mb-0" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
      <tr>
  
        <th>Name </th>
        <th>Column Name</th>
        <th>Field Type</th>
        <th>Field Size</th>
        <th>Map With</th>
        <th>Display on DS</th>

      </tr>
      <tr *ngFor="let obj of metaMapData ">
        <td>{{obj.form_name}}</td>
        <td>{{obj.column_name}}</td>
        <td>
          <!-- {{obj.bu_field_type}} -->
          <span *ngIf="obj.bu_field_type === 'VARCHAR'">Text</span>
            <span *ngIf="obj.bu_field_type === 'INT'">Number</span>
            <span *ngIf="obj.bu_field_type === 'NUMERIC'">Float</span>
            <span *ngIf="obj.bu_field_type === 'timestamp'">Timestamp</span>
            <span *ngIf="obj.bu_field_type === 'BOOLEAN'">Boolean</span>
        </td>
        <td>{{obj.bu_field_size}}</td>
        <td>{{obj.map_with}}</td>
        <td>
          <span *ngIf="obj.is_flag">Yes</span>
          <span *ngIf="!obj.is_flag">No</span>
        </td>
      </tr>
  
    </table>
    <div *ngIf="(metaMapData | filter: text).length === 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div *ngIf="(metaMapData | filter: text).length !== 0" style="text-align: center">
      <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
    </div>
  </div>
</div>

<div class="container mt-4" *ngIf="this.selectedTable">
  <h5>Fields Mapping</h5>
  <div class="tblPrt" >
    <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0" style="min-width:400px;max-width: 100%;">
      <tr>
  
        <th>Name </th>
        <th>Mapped Column Name</th>
  
      </tr>
      <tr *ngFor="let obj of colMapData ">
        <td>{{obj.column_name}}</td>
        <td>{{obj.map_with}}</td>
      </tr>
  
    </table>
    <div *ngIf="(colMapData | filter: text).length === 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div *ngIf="(colMapData | filter: text).length !== 0" style="text-align: center">
      <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
    </div>
  </div>
</div>