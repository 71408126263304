import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cb-add-theme-template-association',
  templateUrl: './cb-add-theme-template-association.component.html',
  styleUrls: ['./cb-add-theme-template-association.component.css']
})
export class CbAddThemeTemplateAssociationComponent extends BaseComponent implements OnInit {
  id;
  getCompanyobj;
  headers = "Add";
  companyID = 0;
  journeyId;
  themeObj = [];
  journeyObj = [];
  templateObj = [];
  submitted = false;
  addForm: FormGroup;
  journeyTypeObj = [];
  //jorneyType:any=[];
  journey_type_id;
  
  addedOrder= [];
  currentUser;
  companyCheck;
  journeyOrderArray = Array;
  totalJourney=5;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = (params.companyID)?params.companyID:this.companyID;
      });
  }

  ngOnInit() {
    let self = this;
    this.controlEnabled = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if(this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;     
    if(this.currentUser.admin_customer_id!='0' && !this.id ) {
      this.getCompanyThemes(this.companyID);
    }
    else this.getCompany();
    this.companyCheck =  this.currentUser.admin_customer_id;
  
    //get item details using id
    if (this.id && this.companyID) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.cb.getThemeTemplateById(this.companyID,this.id).subscribe(response => {
        
        if (response.status) {
            self.dataBinding(response.data[0]);
            //this.ngxSpinnerService.hide();
        } 
        else {this.ngxSpinnerService.hide();}
      });
    }
    this.addForm = this.formBuilder.group({
      company: [(this.companyCheck>0)?this.companyCheck:'', Validators.required],
      themeid: ['', Validators.required],
      journeyid: ['', Validators.required],
      templateid: ['', Validators.required],
      journey_type_id:['',Validators.required],
      journey_order:[''],
      ttaid:['']
    });
  }
  get f() { return this.addForm.controls; }

  dataBinding(data) {

    this.addForm.controls.company.setValue(this.companyID)
    this.getCompanyThemes(this.companyID);
    this.getCompanyJourneys(data.themeid)
    this.addForm.patchValue(data);
  
    

  }

  checkThemeID(){
    this.f.journeyid.setValue("");
    this.f.templateid.setValue("");
    this.f.journey_type_id.setValue("");
    this.f.journey_order.setValue("");   
  }

  getCompanyThemes(id){
    //event.preventDefault();

    
    if(id) {
    this.companyID = id;
   // console.log("company themes:"+ id);
    let self = this;
    
    

    this.ngxSpinnerService.show();
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        template: this.cb.getCompanyTemplate(id),
        theme: this.master.getCompanyTheme(id),
        //journey: this.cb.getCompanyJourney(id),
        journeyType: this.cb.getCBJourneyType(id)
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      this.templateObj = data.template.data.rows;
      this.themeObj = data.theme.data.rows;
      //this.journeyObj = data.journey.data;
      this.journeyTypeObj = data.journeyType.data;
       this.ngxSpinnerService.hide();
      // console.log(data, 'forkJoin')
    }), error => {
    //  console.log(error);
     
    }
    }
    else {
      this.f.themeid.setValue("");
      this.f.journeyid.setValue("");
      this.f.templateid.setValue("");
      this.f.journey_type_id.setValue("");
      this.f.journey_order.setValue("");
      this.journeyTypeObj = [];
      this.templateObj = [];
      this.themeObj = [];
      this.journeyObj = [];
    }
  }

  getJourneyByType(id){

    if(id) {
      this.f.journeyid.setValue("");
      let self = this;
      this.ngxSpinnerService.show();  
      var themeId;
      if(this.addForm.get("themeid").value) themeId =this.addForm.get("themeid").value;
      
      forkJoin(
        {
          journeyObj: this.cb.getJourneyByType(this.companyID,id),
          totalJourney: this.cb.getTotalJourney(this.companyID,id),
        }
      ).subscribe(data => {
        this.ngxSpinnerService.hide();
        this.journeyObj = data.journeyObj.data;
        this.totalJourney = data.totalJourney.data.total_journey;
        
      
        this.ngxSpinnerService.hide();
      //  console.log(data, 'forkJoin')
      }), error => {
      //  console.log(error);
      
      }
  }
  else { this.journeyObj =[];
  }

  }

 

  getCompanyJourneys(id) {
     //event.preventDefault();
     this.journeyId = id;
   //  console.log("company themes:"+ id);
     let self = this;
     this.ngxSpinnerService.show();
     this.cb.getCompanyJourney(this.companyID, 9999, 0).subscribe(data => {
      this.ngxSpinnerService.hide();
       if(data.data) {
        self.journeyObj = data.data.rows;
       }
     }, error => {
      // console.log(error);
       self.journeyObj = [];
       this.ngxSpinnerService.hide();
     });
  }

  getJourneyByTheme(id) {
   
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getJourneyByTheme(this.companyID,id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.data) {
        self.journeyTypeObj = data.data;
      } else {
        self.journeyTypeObj = [];
      }
    }, error => {
    //  console.log(error);
      self.templateObj = [];
      this.ngxSpinnerService.hide();
    });
  }

  addTemplate() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    this.addForm.removeControl('ttaid');
   // console.log(this.addForm.value)
    this.ngxSpinnerService.show();
    this.cb.addThemeTemplate(this.addForm.value).subscribe(data => {
      this.ngxSpinnerService.hide();
    //  console.log(data);
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/cb-theme-template-association`);
      } else {
       
        this.toastrService.error("The journey order already set")
      }
    }, error => {
     // console.log(error);
    });
  }

  updateTemplate() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    this.ngxSpinnerService.show();
    this.cb.updateThemeTemplate(this.addForm.value).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/cb-theme-template-association`);
      } else {
        this.toastrService.error(data.message)
      }
    }, error => {
      console.error(error);
    });
  }
  
  
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }

  getorder(id) {

    let self = this;
    var obj = {
    company:  this.companyID,
    themeid: this.f.themeid.value,
    journeyid: this.f.journeyid.value,
    templateid:id
    }
    this.cb.getThemeJourneyTemplateOrders(obj).subscribe(data => {
      self.addedOrder = data.data;
    //  console.log(self.addedOrder);
    }, error => {
     // console.log(error);
    });



  }
  
  
}
