import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.css']
})
export class SupportTicketComponent  extends BaseComponent implements OnInit {
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "";
  getCompanyobj;
  public supportTicketObj: any = [];
  today: number = Date.now();
  companyID = 0;
  currentUser;
  checkCompany;
  dsFilter=0;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  dsDropdown =[];
  selectedCompanyId = 0;

  constructor(
    private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private clientService: ClientService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID = this.currentUser.admin_customer_id;
    this.selectedCompanyId = this.companyID;
    if (this.currentUser.admin_customer_id != '0') {
      this.getSupportTicketList();
      this.CreateLog();
    }
    this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getSupportTicketList(this.selectedCompanyId,
          {
            limit: this.limit,
            offset: this.skip,
            ds_id: this.dsFilter,
            key: this.searchText.nativeElement.value
          }))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.supportTicketObj = [] }
          else { this.supportTicketObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.supportTicketObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getSupportTicketList();
  }

  CreateLog() {
    let logPayload = {
      log_type: "Support Ticket",
      log_name: "Support Ticket List",
      log_description: "Support Ticket Master View",
      moduleid: 227,
      moduleitemid: 412,
      per_id: 0
    }
    this.logs.addNewLogs(logPayload).subscribe(data => {
      //  console.log(data);

    }, error => {
      // console.log(error);
    });

  }

  getSupportTicketList() {    
    if(this.selectedCompanyId == 0 ){return};
    this.ngxSpinnerService.show();
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.master.getSupportTicketList(this.selectedCompanyId,{
      limit: this.limit,
      offset: this.skip,
      ds_id: this.dsFilter,
      key: this.searchText.nativeElement.value
    }).subscribe(response => {
      self.supportTicketObj = response.data?.rows;
      this.totalCount = response.data?.total;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }

  getSupportTicketOnFilt(dsId) {
    // if (dsId != 0) {
      this.dsFilter = dsId
      this.getSupportTicketList();
    // } 
    // else{
    //   this.dsFilter=0;
    //   this.supportTicketObj = [];
    //   this.totalCount = 0;
    //   this.cp = 1;
    //   this.ngxSpinnerService.hide(); }
  }

  deleteJourney(id) {
    // this.confirmationDialogService.confirm('PLEASE CONFIRM', 'ARE YOU SURE YOU WANT TO DELETE ?', 'YES', 'NO')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.ngxSpinnerService.show();
    //       this.master.delJourney(id, this.selectedCompanyId).subscribe(data => {
    //         if (data.status) {
    //           this.getSupportTicketOnFilt(this.dsFilter);
    //           setTimeout(() => {
    //             this.toastrService.success("Deleted Successfully");
    //           }, 1000)
    //         } else {
    //         }
    //         this.ngxSpinnerService.hide();
    //       }, error => {
    //         // console.log(error);
    //         this.ngxSpinnerService.hide();
    //       });
    //     }
    //   }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  editJourney(id) {
    this.router.navigate(['/corporate/addnewjourney'], { queryParams: { journey_id: id, company: this.selectedCompanyId } });
  }
  updateStatus(event, id) {
    // event.preventDefault();
    // let obj = this.supportTicketObj.find(element => {
    //   if (element.journey_id === id) {
    //     return true;
    //   }
    // });
    // this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status? ', 'YES', 'NO')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       obj.isactive = !obj.isactive;
    //       obj.company = this.selectedCompanyId;
    //       this.master.updateJourney(obj).subscribe(data => {
    //         this.getSupportTicketOnFilt(this.dsFilter);
    //         this.toastrService.success("Journey Master Status Changed Successfully");
    //       });
    //     }

    //   }).catch(() => {
    //   });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  importFile() {
    // let self = this;
    // if (self.selectedCompanyId != undefined) {
    //   let tablename = {
    //     "tableName": "master_themes_tbl",
    //     "cid": this.selectedCompanyId
    //   }
    //   this.ImportFilesDialogService.confirm1('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-journey-import.csv`, 'addingJourneyBulk', self.selectedCompanyId)
    //     .then(data => {
    //       //  console.log(data);
    //       self.getSupportTicketList();
    //     })
    //     .catch(err => {
    //     })
    // } else {
    //   this.toastrService.error('Please Select The Company!');
    // }
  }
  exportFile() {
    // if (this.selectedCompanyId != undefined) {
    //   let tablename = {
    //     "tableName": "master_journey_tbl",
    //     'cid': this.selectedCompanyId
    //   }
    //   this.master.exportBulk(tablename).subscribe(dataFile => {
    //     if (dataFile.status) {
    //       let selBox = document.createElement('a');
    //       var att = document.createAttribute("href");
    //       att.value = dataFile.file;
    //       var att1 = document.createAttribute("download");
    //       selBox.setAttributeNode(att);
    //       selBox.setAttributeNode(att1);
    //       document.body.appendChild(selBox);
    //       selBox.click();
    //     }
    //   }, err => {
    //     this.toastrService.error(err.message);
    //   });
    // } else {
    //   this.toastrService.error('Please Select The Company!');
    // }
  }
  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
    });
  }
  getDsDropdown(id) {
    this.selectedCompanyId = id;
    this.getSupportTicketOnFilt('');
    if (id == 0) {
      this.dsFilter = 0;
      this.dsDropdown = [];
      return;
    }
    this.ngxSpinnerService.show();
    this.master.getDsListSupportTicket(id).subscribe({
      next: (res: any) => {
        if (res.data) {
          this.dsDropdown = res.data.rows;
        }
        this.ngxSpinnerService.hide();
      },
      error: (error: any) => {
        console.error(error);
        this.ngxSpinnerService.hide();
      }

    })
  }
  viewTicket(dsid:any,id:any){
    this.router.navigate([`/corporate/support-ticket-view`],{queryParams: { 'companyid':this.selectedCompanyId,'dsid':dsid,'ticketid': id ,}});
  }
  
}
