<div class="container">


  <div class="row" *ngIf="roleId!=248">


    <div class="col-xl-4 col-md-6" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body green-lt-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(1)"><i class="fa fa-shopping-basket dashIconFa"
              aria-hidden="true"></i> Service Summary Report </h5>

        </div>

      </div>

    </div>

    <div class="col-xl-8 col-md-6" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body blue-lt-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(2)"><i class="fa fa-assistive-listening-systems dashIconFa"
              aria-hidden="true"></i> E-Commerce Report </h5>

        </div>

      </div>

    </div>



    <div class="col-xl-8 col-sm-6" style="cursor: pointer;" *ngIf="marketerHideSection">

      <div class="card text-sm-center">

        <div class="card-body purple-dr-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(3)"><i class="fa fa-filter dashIconFa" aria-hidden="true"></i>

            Subscription Report</h5>

        </div>

      </div>

    </div>

    <div class="col-xl-4 col-sm-6" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body red-dr-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(4)"><i class="fa fa-dollar dashIconFa" aria-hidden="true"></i>

            Marketing Report</h5>

        </div>

      </div>

    </div>



    <div class="col-xl-6 col-sm-6 ng-star-inserted" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body blue-sky-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(5)"><i class="fa fa-sliders dashIconFa" aria-hidden="true"></i> Campaign Segment Report</h5>

        </div>

      </div>

    </div>

 


    <!-- <div class="col-xl-4 col-sm-6" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body blue-sky-icon brd-radius">

          <h5 class="mb-0 text-white" (click)="openreport(5)"><i class="fa fa-sliders dashIconFa" aria-hidden="true"></i> Mobile Device</h5>

        </div>

      </div>

    </div>

    <div class="col-xl-4 col-sm-6" style="cursor: pointer;">

      <div class="card text-sm-center">

        <div class="card-body orange-dr-icon brd-radius">

          <h5 class="mb-0 text-white"><i class="fa fa-cog dashIconFa" aria-hidden="true"></i> Basic Service Activities</h5>

        </div>

      </div>

    </div>

   <div class="col-xl-4 col-sm-6">

      <div class="card text-sm-center">

        <div class="card-body green-dr-icon brd-radius">

          <h5 class="mb-0 text-white"><i class="fa fa-mixcloud dashIconFa" aria-hidden="true"></i> Marketing Cloud Usage</h5>

        </div>

      </div>

    </div>

 

    <div class="col-xl-4 col-sm-6">

      <div class="card text-sm-center">

        <div class="card-body orange-dr-icon brd-radius">

          <h5 class="mb-0 text-white"><i class="fa fa-cog dashIconFa" aria-hidden="true"></i> Basic Service Activities</h5>

        </div>

      </div>

    </div>

    <div class="col-xl-8 col-sm-6">

      <div class="card text-sm-center">

        <div class="card-body purple-dr-icon brd-radius">

          <h5 class="mb-0 text-white"><i class="fa fa-sun-o dashIconFa" aria-hidden="true"></i> Premium Service Activities Campaign/Social Studios</h5>

        </div>

      </div>

    </div>

-->

  </div>
  


  <div *ngIf="roleId==248">
    <h2 class="helpLandingPg">How can marGo help today?</h2>
  </div>

</div>

 



<!--

<div class="container" *ngIf="!customerISheadquarter">

  <div class="card">

    <div class="card-header">

      <div class="row">

        <div class="col-12 text-center">

          <h4 class="mb-0">Account Summary</h4>

        </div>

      </div>

    </div>

    <div class="card-body">

 

      <div class="row accntSummaryHead">

        <div class="col-md-6 col-xl-3">

          <h5 class="">Active Users:</h5>

          <ul>

            <li>Current Month – {{reportAllProspectUserobj}}</li>

            <li class="clearfix">Prior Month – 51,200</li>

            <li *ngIf="reportAllDSUserobj">Total Direct Seller – {{reportAllDSUserobj.totcount}}</li>

            <li *ngIf="reportAllAdminUserobj">Total Admin – {{reportAllAdminUserobj.totcount}}</li>

          </ul>

        </div>

        <div class="col-md-6 col-xl-3">

          <h5 class="mt-1 mt-md-0">Impact:</h5>

          <ul>

            <li>Current Month – $200/Seller</li>

            <li class="clearfix">Prior Month – $192/Seller</li>

          </ul>

        </div>

        <div class="col-md-6 col-xl-6">

          <h5 class="mt-1 mt-md-0">New contacts added:</h5>

          <ul>

            <li>Prospects <span class="green-lt-txt">– <i class="fa fa-long-arrow-up"

                    aria-hidden="true"></i> {{reportAllProspectUserobj}}</span></li>

            <li>Team Members <span class="green-lt-txt">– <i class="fa fa-long-arrow-up"

                    aria-hidden="true"></i> {{reportAllContactObj}}</span></li>

            <li class="clearfix">Hosts <span class="green-lt-txt">– <i class="fa fa-long-arrow-up"

                    aria-hidden="true"></i> {{reportAllHostUserobj}}</span></li>

 

            <li class="clearfix">Customer <span class="green-lt-txt">– <i class="fa fa-long-arrow-up"

                    aria-hidden="true"></i> {{reportAllCustomerUserobj}}</span></li>

          </ul>

        </div>

      </div>

 

      <div class="row pieChartMain">

        <div class="col-md-6 col-xl-3 text-center overflow-hidden">

          <div class="pieTitle">

            <span class="pieTitleMain">Prospect Campaigns</span>

          </div>

          <span *ngIf="reportAllProspectCampaignsobj?.length==0">No Records</span>

          <span *ngIf="reportAllProspectCampaignsobj?.length>0">

            <div class="pieActTxt">

              Toggle: <div class="switchTog"><span (click)="showProspectTotal('total')" class="togLink">#</span><span

                (click)="showProspectTotal('percentage')" class="togLink">%</span>

                </div>

            </div>

 

            <span *ngIf="showProspect=='percentage'" class="d-inline-block chartMainH">

              <google-chart #chart [title]="title" [type]="type" [data]="prospectCampaigns" [columnNames]="columnNames"

                [options]="options" [width]="width" [height]="height" style="display: inline-block;">

              </google-chart>

            </span>

 

            <span *ngIf="showProspect=='total'" class="hashMain">

              <ul class="hashList" *ngFor="let item of reportAllProspectCampaignsobj">

                <li class="hashHeadL">{{item.journey_name}} :</li>

                <li>{{item.total_product}}</li>

              </ul>

            </span>

          </span>

 

        </div>

 

        <div class="col-md-6 col-xl-3 text-center overflow-hidden">

          <div class="pieTitle">

            <span class="pieTitleMain">Customer Campaigns</span>

          </div>

          <span *ngIf="reportAllCustomerCampaignsobj?.length==0">No Records</span>

          <span *ngIf="reportAllCustomerCampaignsobj?.length>0">

            <div class="pieActTxt">

              Toggle: <div class="switchTog"><span (click)="showCustomerTotal('total')" class="togLink">#</span><span

                (click)="showCustomerTotal('percentage')" class="togLink">%</span></div>

            </div>

            <span *ngIf="showCustomer=='percentage'" class="d-inline-block chartMainH">

              <google-chart #chart [title]="title" [type]="type" [data]="customerCampaigns" [columnNames]="columnNames"

                [options]="options" [width]="width" [height]="height" style="display: inline-block;">

              </google-chart>

 

            </span>

            <span *ngIf="showCustomer=='total'" class="hashMain">

              <ul class="hashList" *ngFor="let item of reportAllCustomerCampaignsobj">

                <li class="hashHeadL">{{item.journey_name}} :</li>

 

                <li>{{item.total_product}}</li>

              </ul>

            </span>

 

          </span>

 

        </div>

 

        <div class="col-md-6 col-xl-3 text-center overflow-hidden">

          <div class="pieTitle">

            <span class="pieTitleMain">Events/Parties</span>

          </div>

          <span *ngIf="reportAllEventCampaignsobj?.length==0">No Records</span>

          <span *ngIf="reportAllEventCampaignsobj?.length>0">

            <div class="pieActTxt">

              Toggle: <div class="switchTog"><span (click)="showEventTotal('total')" class="togLink">#</span><span

                (click)="showEventTotal('percentage')" class="togLink">%</span></div>

            </div>

            <span *ngIf="showEvent=='percentage'" class="d-inline-block chartMainH">

 

              <google-chart #chart [title]="title" [type]="type" [data]="eventsCampaigns" [columnNames]="columnNames"

                [options]="options" [width]="width" [height]="height" style="display: inline-block;">

              </google-chart>

 

            </span>

            <span *ngIf="showEvent=='total'" class="hashMain">

              <ul class="hashList" *ngFor="let item of reportAllEventCampaignsobj">

                <li class="hashHeadL">{{item.journey_name}} :</li>

 

                <li>{{item.total_product}}</li>

              </ul>

            </span>

 

          </span>

 

        </div>

 

        <div class="col-md-6 col-xl-3 text-center overflow-hidden">

          <div class="pieTitle">

            <span class="pieTitleMain">Team Engagement </span>

          </div>

          <span *ngIf="reportAllTeamCampaignsobj?.length==0">No Records</span>

          <span *ngIf="reportAllTeamCampaignsobj?.length>0">

            <div class="pieActTxt">

              Toggle: <div class="switchTog"><span (click)="showTeamTotal('total')" class="togLink">#</span><span

                (click)="showTeamTotal('percentage')" class="togLink">%</span></div>

            </div>

            <span *ngIf="showTeam=='percentage'" class="d-inline-block chartMainH">

              <google-chart #chart [title]="title" [type]="type" [data]="teamCampaigns" [columnNames]="columnNames"

                [options]="options" [width]="width" [height]="height" style="display: inline-block;">

              </google-chart>

            </span>

            <span *ngIf="showTeam=='total'" class="hashMain">

              <ul class="hashList" *ngFor="let item of reportAllTeamCampaignsobj">

                <li class="hashHeadL">{{item.journey_name}} :</li>

 

                <li>{{item.total_product}}</li>

              </ul>

            </span>

 

          </span>

 

        </div>

 

      </div>

 

      <div class="row pt-1">

        <div class="col-md-6 col-xl-4 text-center">

          <div class="pieTitle">

            <span class="pieTitleMain">Replenishment Campaigns</span>

 

          </div>

          <div>Total: {{reportAllReplenishmentsobj}}</div>

 

          <div class="chartSec">

            <img src="../../../assets/custom/images/graph_1.png">

          </div>

        </div>

 

        <div class="col-md-6 col-xl-4 text-center">

          <div class="pieTitle">

            <span class="pieTitleMain">Cross-sell Campaigns</span>

 

          </div>

          <div>Total: {{reportAllCrossSellobj}}</div>

 

          <div class="chartSec">

            <img src="../../../assets/custom/images/graph_2.png">

          </div>

        </div>

 

        <div class="col-md-6 col-xl-4 text-center">

          <div class="pieTitle">

            <span class="pieTitleMain">Social Media</span>

          </div>

 

          <div class="chartSec">

            <img src="../../../assets/custom/images/graph_3.png">

          </div>

        </div>

 

      </div>

 

     

    </div>

  </div>

</div>-->