<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/subscriptionModule">Subscription</a></li>
            <li class="breadcrumb-item" disabled><a routerLink="/corporate/subscriptionLimit">Subscription Limit</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Subscription Limit</li>
  
        </ol>
    </nav>
  </div>
  
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <form class="form-horizontal" [formGroup]="addLimitForm">
          <div class="card">
            <div class="card-body">
  
              <div class="form-group" *ngIf="customer_id===0">
  
                <label for="exampleForm2">Company Name <span class="required">*</span></label>
  
                <select formControlName="selectedCompany" (change)="subscriptionList()" [(ngModel)]="selectedCompany" class="form-control formstyle ssss"
                  *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj">
                    <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                      {{Obj.customer_company_name}}
                    </option>
                  </ng-container>
                </select>
  
                <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                  <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
                </div>
              </div>
  
              <!-- <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }"> -->
  
                <div class="form-group">
  
                    <label for="exampleForm2">Subscription <span class="required">*</span></label>
      
                    <select formControlName="sub_id" class="form-control formstyle ssss"
                       [ngClass]="{ 'is-invalid': submitted && f.sub_id.errors }">
                      <option value="">Select Subscription</option>
                      <ng-container *ngFor="let Obj of subsObj">
                        <option  value={{Obj.id}}>
                          {{Obj.title}}
                        </option>
                      </ng-container>
                    </select>
      
                    <div *ngIf="submitted && f.sub_id.errors" class="invalid-feedback">
                      <div *ngIf="f.sub_id.errors.required">Subscription is required</div>
                    </div>
                  </div>

              <div class="form-group">
                <label for="name">Component Name <span class="required">*</span></label>
                <input type="text" class="form-control" name="component_name" formControlName="component_name"
                  [ngClass]="{ 'is-invalid': submitted && f.component_name.errors }" maxlength="50" appSpace>
                <div *ngIf="submitted && f.component_name.errors" class="invalid-feedback">
                  <div *ngIf="f.component_name.errors.required || f.component_name.hasError('whitespace')">Component Name is required</div>
                  <div *ngIf="f.component_name.errors?.maxlength">
                    You reached to maxlength
                  </div>
                </div>
  
              </div>
              <div class="form-group">
                <label for="name">Component Limit </label>
                <input type="text" class="form-control" name="component_limit" formControlName="component_limit"
                   maxlength="50" appSpace>
                <!-- <div *ngIf="submitted && f.component_limit.errors" class="invalid-feedback">
                  <div *ngIf="f.component_limit.errors.required || f.component_limit.hasError('whitespace')">Component Limit is required</div>
                  <div *ngIf="f.component_limit.errors?.maxlength">
                    You reached to maxlength
                  </div>
                </div> -->
  
              </div>
              <div class="form-group">
                <label for="name">Time Limit </label>
                <input type="text" class="form-control" name="time_limit" formControlName="time_limit"
                  maxlength="50" appSpace>
                <!-- <div *ngIf="submitted && f.time_limit.errors" class="invalid-feedback">
                  <div *ngIf="f.time_limit.errors.required || f.time_limit.hasError('whitespace')">Time Limit is required</div>
                  <div *ngIf="f.time_limit.errors?.maxlength">
                    You reached to maxlength
                  </div>
                </div> -->
  
              </div>
              <div class="form-group">
                <label for="name">Order No </label>
                <input type="text" class="form-control" name="orderno" formControlName="orderno"
                   maxlength="50" appSpace>
                <!-- <div *ngIf="submitted && f.orderno.errors" class="invalid-feedback">
                  <div *ngIf="f.orderno.errors.required || f.orderno.hasError('whitespace')">Order No is required</div>
                  <div *ngIf="f.orderno.errors?.maxlength">
                    You reached to maxlength
                  </div>
                </div> -->
  
              </div>
            </div>
            <div class="card-footer">
              <div class="text-center">
                <button class="btn btn-round btn-margo-action" (click)="addNewCategoryData()">Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  