<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/pi">PII & DSAR</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">PII Field</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon">
  
    <h5 class="font-weight-bold">PII & DSAR Table Fields in Master DB</h5>
 
  
    <div class="row" *ngIf="customerID == 0">
      <div class="col-md-6 mt-2">
        <div>
          <select
            name="company"
            class="form-control formstyle"
            (change)="gettingSensitiveTables($event.target.value)"
          >
            <option value="">Select Company Name</option>
            <ng-container *ngFor="let userObj of getCompanyobj" >
              <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
            <!-- <option
              *ngFor="let userObj of getCompanyobj"
              value="{{ userObj.customer_id }}"
            > -->
              {{ userObj.customer_company_name }}
            </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-6 mt-2" *ngIf="companyID">
        <button *ngIf="isWrite" type="button" class="custbtn mt-md-1 mt-0 ml-0" routerLink="/corporate/pii-field-add">
          Add
        </button>
      </div>
    </div>
    <div class="row">
      <div class="mt-3 ml-5" *ngIf="allsensitveTablesobj.length == 0 && companyID" >
        No Sensitive Tables
      </div>
    </div>
    <div class="row" *ngIf="companyID">
      
      <div class="col-md-6 mt-3">
        <h5 class="mt-1 mb-1"  *ngIf="allsensitveTablesobj == null || allsensitveTablesobj.length == 0">Select Table for PII Data Filter</h5>

        <div class="piInnerList scroller">
          <ul class="filtMain">
            <li *ngFor="let item of allsensitveTablesobj">
              <div class="row">
                <div class="col-sm-9">
                  <label class="form-check-label">
                    {{ item.table_name }}
                  </label>
                </div>

                <div class="col-sm-3">
                  <span
                    (click)="gettingSenstiveTableFields(item.pii_tbl_id)"
                    class="blue-lt-txt cursor-pointer"
                    >View fields</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="col-md-5 mt-1 mt-md-0"
        *ngIf="!allSenTableFieldsobj && !showMessage"
      >
        <h5 class="mt-1 mb-1">No Senstive Fields</h5>
      </div>

      <div
        class="col-md-5 mt-1 mt-md-0"
        *ngIf="allSenTableFieldsobj && allSenTableFieldsobj.length > 0"
      >
        <h5 class="mt-1 mb-1">Table Fields</h5>
        <div class="piInnerList scroller">
          <ul class="filtMain tablDetailLight">
            <li *ngFor="let item of allSenTableFieldsobj">
              {{ item.field_name }}
              <span
                class="fa fa-trash mr-1 ml-5"
                *ngIf="isDelete"
                (click)="deleteTableField(item.pii_inventory_record_id)"
                placement="bottom"
                ngbTooltip="Delete"
              ></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
 
</div>


