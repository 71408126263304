import { BaseComponent } from '../../base.component';
import { Component, OnInit } from '@angular/core';
import { AudienceBuilderService } from '../../../services/segmentation/audience-builder.service';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import csc from 'country-state-city';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from '../../../services/master/master.service';


@Component({
  selector: 'app-clone-audience-builder',
  templateUrl: './clone-audience-builder.component.html',
  styleUrls: ['./clone-audience-builder.component.css']
})
export class CloneAudienceBuilderComponent extends BaseComponent implements OnInit {

  audId: any;
  companyId: any;
  audData: any;
  show = false;
  audienceForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
  targetAudience: boolean = false;
  targetEntities: boolean = false;
  audienceAddForm: boolean = true;
  currentUser;


  buTargetAudienceObj: any = []
  buEntitiesObj: any = []
  buEntitieAttributesObj: any = []
  buAttributeOperatorObj: any = []

  conditionForm: FormGroup;

  showAndOr: boolean = false;
  showDataValue: any = [];
  getdataValueObj: any = []
  stateData: any = []
  cityData: any = []
  addedAudienceId;
  targetAudieceId;

  dataLabelName = [];
  companyProductObj;
  companyAttributeObj = [];

  companyProductDataObj = [];
  companyAttributeDataObj = [];
  companyAttributeDataObj1 = [];
  displayState = [];
  displayCity = [];
  queryData1: any;;
  queryResult = [];
  selectedCountry=[];
  
  selectedBUAud: any;
  showProductValue:boolean=true;

  constructor(private audienceBuilderService: AudienceBuilderService, private fb: FormBuilder, private clientsService: ClientService,
    private master: MasterService,
    public datePipe: DatePipe,) {
    super();



    this.audId = localStorage.getItem("editAudienceID");




    this.targetAudieceId  = localStorage.getItem("audienceTarget");
    this.addedAudienceId = localStorage.getItem("editAudienceID");









    if (!this.audId) {
      this.router.navigateByUrl('/corporate/audience-builder');
    }
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);

  }

  ngOnInit() {


    this.ngxSpinnerService.show();

    this.conditionForm = this.fb.group({

      audienceConditions: this.fb.array([]),

    });
    this.goConditionStep();

    //this.checkCondition();

  }

  get f() { return this.conditionForm.controls; }

  //  #################  Test Audience Link Click from Page  ##################  //

  testAudience() {
    let URL = '/corporate/test-bu-built-audience' + '?audid=' + this.audId;
    this.router.navigateByUrl(URL);
  }

  //  ############# Click on Schedule Button to got to Schedule Audience Page ######  //

  goToScheduleAudience() {
    let URL = '/corporate/schedule-bu-built-audience' + '?audid=' + this.audId;
    this.router.navigateByUrl(URL);
  }

  getEntities() {
    this.audienceBuilderService.getBUEntitiesList(this.companyId, 999, 0, this.targetAudieceId).subscribe(data => {

      if (data.status) {

        this.buEntitiesObj = data.data;
      } else {
        this.buEntitiesObj = [];
      }

    }, error => {
    //  console.log(error);

    });
   

  }

  onSubmit() {


    console.log("fffffff.....",this.addedAudienceId);


    if(this.queryResult.length==0 && this.conditionForm.value.audienceConditions.length == 0 ) {
      this.toastrService.error("There must be a query to create Audience");
      return false;      

    }

    

    if (this.conditionForm.value.audienceConditions.length == 0) {

    

      this.audienceBuilderService.buildAudienceQuery( this.companyId,this.addedAudienceId).subscribe(data => {

        if (data.status) {

          this.toastrService.success("Audience Builder Query Updated Successfully");
          //let URL = '/corporate/schedule-bu-built-audience' + '?audid=' + this.addedAudienceId;
          let URL = '/corporate/view-bu-built-audience' + '?audid=' + this.addedAudienceId;
          this.router.navigateByUrl(URL);
  
          
        } else {
          this.toastrService.error(data.message)
        }
  
      }, error => {
       // console.log(error);
  
      });




     
    }
    else {

      

      if (this.conditionForm.invalid) {
        this.toastrService.error("Select all required fields");
        return;
      }
      let isOp;
      this.conditionForm.value.audienceConditions.forEach(element => {
        let val = element.open_close;
        if (val === '1') {
          if (isOp === true) {
            this.toastrService.error('Opening braces should be followed by a closing braces only');
            return;
          } else {
            isOp = true;
          }
        }
        if (val === '0') {
          if (isOp === false) {
            this.toastrService.error('Closing braces should only be succeded by Opening braces');
            return;
          } else isOp = false;
        }
      });
      if (isOp === true) {
        this.toastrService.error('Opened braces should be closed by Closing braces');
        return;
      }
      this.ngxSpinnerService.show();
      let obj = {
        "audid": this.addedAudienceId,
        "aud_id": localStorage.getItem("audienceTarget"),
        "company": this.companyId,
        "query_data": this.conditionForm.value
      }

      var k = 0;
      this.conditionForm.value.audienceConditions.forEach(element => {

       
        let fieldaData = this.buEntitieAttributesObj[k].filter(item => item.field_id == element.field_id);
        if (fieldaData && fieldaData[0].table_id) element.table_id = fieldaData[0].table_id;
        if (typeof element.data_value === 'string') element.data_value = element.data_value.replace(/'/g, "\\'");

        
        k++;
        element.data_order = (this.queryResult.length)+k;
      });

     // console.log("query..........",obj);
     
      this.audienceBuilderService.addingAudienceQuery(obj).subscribe(data => {
        if (data.status) {

          this.toastrService.success("Audience Builder Query Updated Successfully");
          let URL = '/corporate/view-bu-built-audience' + '?audid=' + this.addedAudienceId;
          this.router.navigateByUrl(URL);

        } else {
          this.toastrService.error(data.message)
        }
        this.ngxSpinnerService.hide();
      }, error => {
        //console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
  }

  showState(id, index) {
  
    this.selectedCountry[index] = id;
    this.cityData[index] = [];
    this.stateData[index] = [];
    if (id === '' || id === null || id === undefined) {
      return;
    }

    this.audienceBuilderService.getStatesOfCountry(id).subscribe(data => {
      if (data) {
        this.stateData[index] = data
      } 
    }, error => {
     // console.log(error);
     
    })

  }


  showCity(id, index) {  
    this.cityData[index] = [];
    if (id === '' || id === null || id === undefined) {
      return;
    }
    this.audienceBuilderService.getCitiesOfState(this.selectedCountry[index], id).subscribe(data => {
      if (data) {
        this.cityData[index] = data
      } 
    }, error => {
     // console.log(error);
      
    })

  }


  entityAttributes(val, index) {

    this.buAttributeOperatorObj[index] = [];
    this.dataLabelName[index] = [];
    this.audienceConditions().controls[index].get("data_value").patchValue("");
   // this.audienceConditions().controls[index].get("dataValueState").patchValue("");
    //this.audienceConditions().controls[index].get("dataValueCountry").patchValue("");


    this.ngxSpinnerService.show();

    if (val) {

      this.audienceBuilderService.getBUEntityAttributes(this.companyId, val).subscribe(data => {

        if (data.status) {


          this.buEntitieAttributesObj[index] = data.data[0].attributeList;
        //  console.log('ATTRS', index, this.buEntitieAttributesObj);
          // this.targetAudience=true;
        } else {
          this.toastrService.error(data.message)
        }
        this.ngxSpinnerService.hide();
      }, error => {
      //  console.log(error);
        this.ngxSpinnerService.hide();
      });
    }
    else {
      this.ngxSpinnerService.hide();
      this.buEntitieAttributesObj[index] = [];

    }
  }

  attributeOperators(val, index) {


    this.ngxSpinnerService.show();
    this.audienceConditions().controls[index].get("data_value").patchValue("");


    this.audienceConditions().controls[index].get("dataValueState").patchValue("");
    this.audienceConditions().controls[index].get("dataValueCountry").patchValue("");



    if (val) {
      this.showDataValue[index] = true;

      let selectedAttribute = this.buEntitieAttributesObj[index].filter(item => item.field_id == val);

     // console.log("sssss...", selectedAttribute[0].field_name);


      if (selectedAttribute && (selectedAttribute[0].field_name.includes('Country') || selectedAttribute[0].field_name.includes('State') || selectedAttribute[0].field_name.includes('City'))) {

        this.dataLabelName[index] = "Country";

        this.audienceBuilderService.getAllCountries().subscribe(data => {


          if (data) {
            this.getdataValueObj[index] = data
          } 
        }, error => {
        //  console.log(error);
          //this.ngxSpinnerService.hide();
        })

        if (selectedAttribute[0].field_name.includes('Country')) { this.displayState[index] = false; this.displayCity[index] = false; }
        if (selectedAttribute[0].field_name.includes('State')) { this.displayState[index] = true; this.displayCity[index] = false; }
        if (selectedAttribute[0].field_name.includes('City')) { this.displayCity[index] = true; this.displayState[index] = true; }
      //  console.log("selected attribute....", this.conditionForm.get("dataValue"));

      }

      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Product'))) {

        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "wishlist";

        this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {


          this.companyProductDataObj[index] = data.data.rows;

        //  console.log(data.data);
          this.ngxSpinnerService.hide();

        }, error => {
        //  console.log(error);
        });


      }
      else if (selectedAttribute && selectedAttribute[0].field_name == 'Attribute') {

        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "Attribute";
        this.master.getFullAttributes(this.companyId, 9999).subscribe(data => {
          this.companyAttributeDataObj[index] = data.data.rows;
          // this.shwSearch = true;
          this.ngxSpinnerService.hide();
        }, error => {
        //  console.log(error);
          this.ngxSpinnerService.hide();
        });
      }

      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Type '))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "Journey";
      }

      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('OPT IN SMS'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "optINSMS";
      }
      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('OPT IN Email'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "optINEmail";
      }

      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Agreed to Host'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "agreedToHost";
      }
      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Agreed to Join'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "agreedToJoin";
      }
      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Sex'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "gender";


      }
      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Open'))) {
        this.ngxSpinnerService.show();
        this.dataLabelName[index] = "open";


      }


      else if (selectedAttribute && (selectedAttribute[0].field_name.includes('Date'))) {

        this.dataLabelName[index] = "inputdate";

      }
      else {

        this.dataLabelName[index] = "inputvalue";

      }
      

      this.audienceBuilderService.getBUAttributeOperator(this.companyId, val).subscribe(data => {

        if (data.status) {

          this.buAttributeOperatorObj[index] = data.data[0].operatorList;
          // this.targetAudience=true;
        } else {
          this.toastrService.error(data.message)
        }
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
    } else {
      this.ngxSpinnerService.hide();
      this.buAttributeOperatorObj[index] = [];
      this.dataLabelName[index] = [];
      this.audienceConditions().controls[index].get("data_value").patchValue("");

    }



  }

  goConditionStep() {

    //console.log("aaaaa..........",this.audId)
    this.ngxSpinnerService.show();

    this.audId  = localStorage.getItem("editAudienceID");


    this.queryResult = [];
    this.audienceBuilderService.getQueryBuilderView(this.companyId, this.audId).subscribe(data => {
      if (data.status && data.data) {   
        console.log("sssss...",data.data[0].aud_id)
        
      if(data.data[0].aud_id!=undefined)   localStorage.setItem("audienceTarget",  data.data[0].aud_id);

       // this.targetAudieceId = data.data[0].aud_id;
        data.data.forEach(element => {

          if(element.querydata.length > 0) {

          element.querydata.forEach(element1 => {

            if(element1.field_name.includes('Product')) { 

              this.showProductValue= false;

              this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {

                let productList = data.data.rows.filter(item => item.product_catalog_id == element1.query_value);
                element1.query_value = productList[0].product_name;
                this.showProductValue=true;

                // this.ngxSpinnerService.hide();

              }, error => {
              //  console.log(error);
              });





            }
            if (element1.field_name == "Attribute") {
              // this.ngxSpinnerService.show();
              this.showProductValue=false;

             

              // this.master.getAttributes(this.companyId).subscribe(data => {
                this.master.getFullAttributes(this.companyId, 9999).subscribe(data => {

                console.log(" 1234....",data)
                this.companyAttributeDataObj1 = data.data.rows;
                let attributeList = this.companyAttributeDataObj1.filter(item => item.attribute_id == element1.query_value);
                element1.query_value = attributeList[0].attribute_name;
                this.showProductValue=true;
                // this.ngxSpinnerService.hide();
              }, error => {
              //  console.log(error);

              });




            }
            if (element1.field_name == "Country") {
              let countryData = csc.getAllCountries().filter(item => item.sortname == element1.query_value);
              element1.query_value = countryData[0].name;
            }
            if (element1.field_name == "Type of Campaign") {
              if (element1.query_value == 67) element1.query_value = "JOIN";
              else if (element1.query_value == 68) element1.query_value = "HOST";
              else element1.query_value = "SHOP";
            }
            if (element1.field_name == "Sex") {
              element1.query_value = (element1.query_value == 2 ? "Female" : "Male");
            }

            if (element1.query_value == "true" || element1.query_value == 1) {
              element1.query_value = "Yes"
            }
            if (element1.query_value == "false" || element1.query_value == 0)  {
              element1.query_value = "No"
            }

            if (element1.field_name && (element1.field_name.includes('Date'))) {
              element1.query_value = this.datePipe.transform(element1.query_value, 'MM-dd-yyyy');

            }

            this.queryResult.push(element1)



          });

        }



        });


      //  console.log("new queries....", this.queryResult)

        // if(this.queryData1)  this.patchDataValue();
        this.ngxSpinnerService.hide();


      } else {
        this.show = true;
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
     // console.log(error);
      this.show = true;
      this.ngxSpinnerService.hide();
    });


    //this.checkCondition();

  }

  audienceConditions(): FormArray {

    return this.conditionForm.get("audienceConditions") as FormArray
  }

  checkCondition() {

    let id = localStorage.getItem("audienceTarget");
    this.audienceBuilderService.getBUEntitiesList(this.companyId, 999, 0, id).subscribe(data => {

      if (data.status) {

        let obj = this.buTargetAudienceObj.filter(elem => elem.aud_id === parseInt(id, 10));
        this.selectedBUAud = obj[0];


        this.audienceAddForm = false;
        this.targetAudience = false;
        this.targetEntities = true;


        this.buEntitiesObj.push(data.data);

       // console.log("ccccc....", this.buEntitiesObj)


      } else {
        this.buEntitiesObj = [];

      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  deleteAudienceBuilderQuery(id) {

   // console.log("sssss....")

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.audienceBuilderService.deleteQueryAudienceBuilder(this.companyId, id).subscribe(data => {
            if (data.status) {
              this.goConditionStep();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            }
            else {
              this.toastrService.error(data.message)
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



  }

  newCondition(): FormGroup {
    return this.fb.group({
      open_close: "",
      entity_id: ["", Validators.required],
      field_id: ["", Validators.required],
      operator_id: ["", Validators.required],
      data_value: ["", Validators.required],
      conditionAndOr: "AND",

      dataValueState: "",
      dataValueCountry: "",
      data_order: "",
      dataValueProduct: ''
    })
  }

  addCondition() {

    if ((this.audienceConditions().length + this.queryResult.length) >= 10) {


      this.toastrService.error("Can't add more than 10 Conditions in one Audience");

      return;
    }

    this.showAndOr = true;
    this.audienceConditions().push(this.newCondition());
    this.checkCondition();


  }
  removeCondition(i:number) {
    console.log("dddddd. 1..",this.companyAttributeDataObj)

    this.buEntitiesObj.splice(i,1);
    this.buEntitieAttributesObj.splice(i,1);
    this.buAttributeOperatorObj.splice(i,1);
    if(this.companyAttributeDataObj) this.companyAttributeDataObj.splice(i,1);
    if(this.companyProductDataObj) this.companyProductDataObj.splice(i,1);
    if(this.getdataValueObj) this.getdataValueObj.splice(i,1);
    if(this.stateData) this.stateData.splice(i,1);
    if(this.cityData) this.cityData.splice(i,1);
    this.audienceConditions().removeAt(i);

    console.log("dddddd. 2..",this.companyAttributeDataObj)
  }
  backQueryEditPage() {
      //  localStorage.setItem("audienceTarget",this.targetAudieceId);  
        //this.toastrService.success("Audience Added Successfully");
        this.router.navigateByUrl('/corporate/audience-builder-create');

  }   

}
