<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/prospecttypemaster">Prospect type Master</a></li>
            <li class="breadcrumb-item active">{{header}} Prospect</li>
        </ol>
    </nav>
</div>

<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Prospect Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="prospectName"
                        [ngClass]="{ 'is-invalid': submitted && f.prospectName.errors }" required
                        class="form-control form-control-lg">
                    <div *ngIf="submitted && f.prospectName.errors" class="invalid-feedback">
                        <div *ngIf="f.prospectName.errors.required">Prospect Name is required</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Prospect Description</label>
                    <input type="text" id="exampleForm2" formControlName="prospectDescription"
                        class="form-control form-control-lg">
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Prospect Icon</label>
                    <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                        <img src="{{prospect_icon}}" alt="Prospect Icon" style="height:150px;" (error)="setValue($event)">
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio1" formControlName="firstActive"
                                [value]="true" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio1">Url</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2" formControlName="firstActive"
                                [value]="false" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio2">Upload a file</label>
                        </div>
                    </div>
                    <div *ngIf="screenshot_type" class="mt-1">
                        <input type="text" id="exampleForm1" formControlName="prospectIcon"
                            placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                            class="form-control form-control-md">
                    </div>
                    <div *ngIf="!screenshot_type" class="mt-1">
                        <input #imageInput type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                        <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                    </div>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addProspect()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateProspect()">Update</button>
                </div>
            </div>
        </div>
    </form>
</div>