import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { MasterService } from 'src/app/services/master/master.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cb-ts-assocation',
  templateUrl: './cb-template-section-association.component.html',
  styleUrls: ['./cb-template-section-association.component.css']
})
export class CBTSAssociationComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  getCompanyobj;
  companyID = 0;
  sectionObj: any = [];
  image;

  constructor(
    private clientService: ClientService,
    private cb: CbService,
    public master: MasterService
  ) {
    super();
  }
  ngOnInit() {
    this.getCompany();
  }


  getCompanyTemplate(id) {
    //event.preventDefault();
    this.companyID = id;
    //  console.log("company themes:"+ id);
    let self = this;
    this.ngxSpinnerService.show();
    this.sectionObj = [];
    this.image = "";
    if (id) {

      let tempData = [] ;
      this.cb.getCompanyTemplate(id, 1000, 0, 'name', 'asc', '').subscribe(response => {
        this.ngxSpinnerService.hide();
        if (response.data) {
          tempData = response.data.rows;
          const tempData2 = tempData.filter(item => item.isactive)
          this.themeObj = tempData2;
        } else {
          this.themeObj = [];
        }
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
        this.themeObj = [];
      });
    } else {
      this.ngxSpinnerService.hide();
      this.themeObj = [];

    }
  }

  getTemplateSection(id) {
    let self = this
    this.ngxSpinnerService.show();
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        templatePreview: this.cb.getTemplateView(this.companyID, id),
        sections: this.cb.getAssociatedTemplateSection(this.companyID, id),
      }
    ).subscribe(data => {
      this.ngxSpinnerService.hide();
      this.getTemplatePreview(data.templatePreview)
      if (data.sections.data) {
        self.sectionObj = data.sections.data;
      } else {
        this.sectionObj = [];
      }
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
      this.sectionObj = [];
    });

  }

  getTemplatePreview(data) {
    let self = this
    this.ngxSpinnerService.show();
    let url = data.data.screenshot
    if (url && url.indexOf("master_images") >= 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        this.image = self.isUrlValid(awsUrl) ? awsUrl : '';
        this.ngxSpinnerService.hide();
      } else {
        this.image = '';
        this.ngxSpinnerService.hide();
      }
    } else {
      this.image = '';
      this.ngxSpinnerService.hide();
    }
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      if (data.data) {
        self.getCompanyobj = data.data;
        // console.log(self.getCompanyobj);
      } else {
        self.getCompanyobj = [];
      }

    }, error => {
      //  console.log(error);
      self.getCompanyobj = [];
    });
  }
}
