<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Contact Usage</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row" >
    
    <div class="col-xl-3 col-md-4 mt-2" *ngIf="isHeadquater">    
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="gettingReports(1,$event.target.value)">
        <option selected="selected" value=0>Select Company</option>
        <ng-container *ngFor="let userObj of getCompanyobj" >
         
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>      
         <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} > -->
              {{userObj.customer_company_name}}
          </option>
          </ng-container>
        </select>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
                [(ngModel)]="start_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">     
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
            [(ngModel)]="end_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    
    <div  class="col-xl-5 col-md-6 mt-2">
      <button class="custbtn ml-0 mt-md-1" (click)="gettingReports(1,companyID)" style="align-items: center;">Search</button> 

      <button class="custbtn ml-0 mt-md-1" (click)="resetReport()" style="align-items: center;">Reset</button> 
  
      <!-- <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a> -->
    </div>
  </div>

  
<div class="tblPrt" *ngIf="showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">     
  <tr>                     
    <th>Beginning Total Contacts</th>
    <th>Added Contacts (SFMC)</th>
    <th>Deleted Contacts (SFMC)</th>
    <th>Ending Total Contacts (SFMC)</th>
    </tr>
    <tr *ngIf="companyID != 0">
      <td>{{total_contacts}}</td>
      <td>{{addData}}</td>
      <td>{{!!total_used_deleted?total_used_deleted:0}}</td>
      <td>{{!!pendingrecord?pendingrecord:0}}</td>
    </tr>  
    <tr *ngIf="companyID == 0">
      <td colspan="4" style= "text-align: center">No results available</td>                            
    </tr>  
  </table> 
</div>
<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <td colspan="5" style= "text-align: center">No results available</td>                            
    </tr>  
</table>
</div>