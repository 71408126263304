import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/services/clients/client.service';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-newsubjectlib',
  templateUrl: './newsubjectlib.component.html',
  styleUrls: ['./newsubjectlib.component.css']
})
export class NewsubjectlibComponent extends BaseComponent implements OnInit {
  help_type;
  subject_name;
  IsActive;
  help_subject_id;
  subjectlibObj;
  status: boolean = true;
  AddSubject: FormGroup;
  submitted = false;
  getCompanyobj;
  company='';
  currentUser;
  currentUserID;
  controlEnabled:boolean=true;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private alertService: AlertService) {
      super();
     }

  ngOnInit() {
    let self = this;
    this.controlEnabled = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    //this.companyID = this.currentUser.admin_customer_id; 
    this.currentUserID = this.currentUser.admin_customer_id; 

   // console.log("company:"+this.currentUserID)


   if(this.currentUserID==0) self.getCompany();
   
    
   this.activatedRoute.queryParams
   .subscribe(params => {
     if(params.help_subject_id) {
     this.help_subject_id = params.help_subject_id;
     this.company = params.companyID;
     }
   });

    //get item details using id
    if (this.help_subject_id) {

      let obj = {
        company: this.company,
        help_subject_id:this.help_subject_id
      }

      this.ngxSpinnerService.show();
      this.master.getSubjectlibById(obj).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.subjectlibObj = response.data;
         // console.log(self.subjectlibObj)
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }
      });
    }
    this.AddSubject = this.formBuilder.group({
      subject_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(100)]],
      company: [this.company, Validators.required],
      help_type: [1]
    });

  }
 get f() { return this.AddSubject.controls; }
  addNewSubjectlib() {
    this.submitted = true;
   // console.log(this.AddSubject);
    
    if (this.AddSubject.invalid) {
      return;
    }
    let subjectlibObj = {
     
      subject_name: this.AddSubject.value.subject_name,    
      company: this.AddSubject.value.company,
      is_corporate:true


      
    }
    this.ngxSpinnerService.show();
    this.master.addingSubjectlib(subjectlibObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/subjectlib`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
    // this.help_type = this.subjectlibObj.help_type;
    // this.subject_name = this.subjectlibObj.subject_name;
    // this.IsActive = this.subjectlibObj.isactive;
    this.AddSubject = this.formBuilder.group({
      subject_name: [this.subjectlibObj.subject_name, [Validators.required,this.noWhitespaceValidator,Validators.maxLength(100)]],
      help_type: [this.subjectlibObj.help_type],
      company: [this.company,Validators.required],
      
    });this.IsActive = this.subjectlibObj.isactive;
  }
  updateSubjectlib() {
    this.submitted = true;
   // console.log(this.AddSubject);
    
    if (this.AddSubject.invalid) {
      return;
    }
      let subjectlibObj = {
        help_type: this.AddSubject.value.help_type,
        subject_name: this.AddSubject.value.subject_name,
        isactive: this.IsActive,
        help_subject_id: this.help_subject_id,
        company: this.company,
      }
      this.ngxSpinnerService.show();
      this.master.UpdateSubjectlib(subjectlibObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/subjectlib`);
          this.ngxSpinnerService.hide();
        }
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }

    getCompany() {
      let self = this;
      this.clientService.getBUCompanies().subscribe(data => {
        self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
      }, error => {
       // console.log(error);
      });
    }

    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }

  
}

