<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/sender-profile">Sender Profile</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/sender-profile-list">Sender Profile List</a></li>
            <li class="breadcrumb-item active" aria-current="page">DS Sender Profile</li>

        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="senderProfileForm">
        <div class="row">
            <div class="col-md-12 col-xl-12">
                <div class="card mt-1">

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="exampleForm2">Company Name</label>
                                <select formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled"
                                    class="form-control formstyle"
                                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                                    <option value="">Select Company</option>
                                    <ng-container *ngFor="let userObj of getCompanyobj">
                                        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                            {{userObj.customer_company_name}}
                                        </option>
                                    </ng-container>
                                </select>


                                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                    <div *ngIf="f.company.errors.required">Company Name is required</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="exampleForm2">Sender Name</label>
                                <input type="text" formControlName="sendername" class="form-control form-control-md"
                                    readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="exampleForm2">Sender Email</label>
                                <input type="text" formControlName="senderemail" class="form-control form-control-md"
                                    [ngClass]="{ 'is-invalid': submitted && f.senderemail.errors }" readonly>
                                <div *ngIf="submitted && f.senderemail.errors" class="invalid-feedback">
                                    <div *ngIf="f.senderemail.errors.required">Sender Email is required</div>
                                    <div *ngIf="f.senderemail.errors?.pattern">Please enter valid email address</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="exampleForm2">Email Domain</label>
                                <input type="text" formControlName="emaildomain" class="form-control form-control-md"
                                    [ngClass]="{ 'is-invalid': submitted && f.emaildomain.errors }" readonly>
                                <div *ngIf="submitted && f.emaildomain.errors" class="invalid-feedback">
                                    <div *ngIf="f.emaildomain.errors.required">Email domain is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="exampleForm2">Status<span style="color:red">*</span></label>
                                <select name="" id="" class="form-control form-control-md" formControlName="status"
                                    [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <option value="" disabled>select status</option>
                                    <option value="pending">Pending</option>
                                    <option value="inprogress">In Progress</option>
                                    <option value="completed">Completed</option>
                                    <option value="rejected">Rejected</option>
    
                                </select>
                                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">Status is required</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="exampleForm2">Comment<span *ngIf="f.status.value=='rejected'"
                                        style="color:red">*</span></label>
                                <textarea class="form-control text-area-expand" rows="3" min="1" maxlength="200"
                                    formControlName="comment" [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                                    [required]="f.status.value=='rejected'"></textarea>
                                <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                                    <div *ngIf="f.comment.errors.required">Comment is required</div>
                                </div>
                            </div>     
                        </div>


                        <div class="mt-4 d-flex flex-row justify-content-between">
                            <h5 for="exampleForm2"><b>DNS Record</b></h5><a class="mx-link" href="javascript:void(0)"
                                (click)="addNewMxRecord()">Add DNS Record</a>
                        </div>
                        <ng-container formArrayName="maxrecord">
                            <div class="mt-4" *ngFor="let mx of item.controls;let i = index" [formGroupName]="i">
                                <label>DNS Record {{i+1}}</label>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label for="exampleForm2">DNS Type</label>
                                        <select formControlName="mx_record_type" name="mx_record_type"
                                            class="form-control form-control-md">
                                            <option value="" selected>Select DNS type</option>
                                            <option value="MX">MX</option>
                                            <option value="TXT">TXT</option>
                                            <option value="CNAME">CNAME</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="exampleForm2">DNS Priority</label>
                                        <input type="text" formControlName="mx_record_priority" name="mx_record_priority"
                                         placeholder="0"   class="form-control form-control-md">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="exampleForm2">DNS Key</label>
                                        <input type="text" formControlName="mx_record_key" name="mx_record_key"
                                            class="form-control form-control-md">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="exampleForm2">DNS Value</label>
                                        <input type="text" formControlName="mx_record_value" name="mx_record_value"
                                            class="form-control form-control-md">
                                    </div>

                                    <div class="col-md-2">
                                        <label for="exampleForm2">DNS Status</label>
                                        <select formControlName="mx_status" id="" name="mx_status"
                                            class="form-control form-control-md">
                                            <option value="" selected>select status</option>
                                            <option value="pending">Pending</option>
                                            <option value="inprogress">In Progress</option>
                                            <option value="completed">Completed</option>
                                            <option value="rejected">Rejected</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer text-center mt-1">
                        <div class="my_class text-center">
                            <button type="button" class="custbtn" (click)="updateSenderProfile()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>