import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-update-bu-attribute',
  templateUrl: './update-attribute-bu-segmentation.component.html',
  styleUrls: ['./update-attribute-bu-segmentation.component.css']
})
export class UpdateAttributeBuSegmentationComponent extends BaseComponent implements OnInit{

  buselected = '';
  buid: any;
  entid: any;
  buentid: any;
  attList = [];
  buAttList = [];
  attIds = [];
  show = false;

  constructor(private segmentService: BUSegmentationService, private masterSegmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.entid = params.entid;
        this.buentid = params.buentid;
      });
  }

  ngOnInit() {
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
    if (this.entid && this.buentid) {
      this.ngxSpinnerService.show();
      this.getMasterAttributesById();
    } else {
      this.router.navigateByUrl('/corporate/bu-segmentation');
    }
  }

  //  ##############  Fetch Master Attributes List #####################  //

  getMasterAttributesById(){
    this.masterSegmentService.getAssociatedAttributesForEntities(this.buentid).subscribe(data => {
      if (data.status) {
        this.attList = data.data;
        this.getAttributeforBUEntity();
      } else {
        this.ngxSpinnerService.hide();
        this.attList = [];
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ################  Fetch Attributes for selected BU Entity ###################  //

  getAttributeforBUEntity(){
    this.buAttList = [];
    this.segmentService.getAttributeandOperatorforBUEntity(this.buid, this.entid).subscribe(data => {
      if (data.status) {
        this.buAttList = data.data;
        if (this.buAttList.length > 0){
          this.buAttList.forEach(element => {
            this.attIds.push(element.mfield_id);
          });
        }
        this.show = true;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.buAttList = [];
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
      this.show = true;
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###################  ON BU Attribute checked  ######################  //

  selectAttribute(event , id) {
    let elem = this.attList.filter(element => element.field_id === id);
    let i = elem[0].field_id;
    if (event.target.checked) {
      this.attIds.push(i);
    } else {
      this.attIds.splice(this.attIds.indexOf(i), 1);
    }
  }

  //  ###############  Update Attribute ########################  //

  updateAttribute(){
    if (this.attIds.length === 0) {
      this.toastrService.error('Please select at least one Attribute');
      return;
    }
    let obj = {
      'company': this.buid,
      'entity_id': this.buentid,
      'field_id': this.attIds
    };
    this.ngxSpinnerService.show();
    this.segmentService.updateAttributesForBU(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Attributes updated Successfully');
        this.router.navigateByUrl('/corporate/bu-segmentation');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }
}
