import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {ClientService} from '../../../services/clients/client.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-buselect-segmentation',
  templateUrl: './selectbu-segmentation.component.html',
  styleUrls: ['./selectbu-segmentation.component.css']
})
export class SelectbuSegmentationComponent extends BaseComponent implements OnInit{

  getCompanyobj = [];
  buForm: FormGroup;
  customerID;
  constructor(private formBuilder: FormBuilder, private clientService: ClientService) {
    super();
  }
  ngOnInit() {


    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;


    this.ngxSpinnerService.show();
    this.buForm = this.formBuilder.group({
      buid: ['']
    });

    if(this.customerID>0) {
      this.buForm.get("buid").patchValue(this.customerID);
      //this.buForm.value.buid = this.customerID

    }
    this.clientService.getBUCompanies().subscribe(data => {
      if (data.status) {
        this.getCompanyobj = data.data;
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.getCompanyobj = [];
    });
  }

  //  ##########  Set details for BU on dropdown select  ##############  //

  setCompanyDetailsforSegmentation(){
    if (this.buForm.value.buid === ''){
      this.toastrService.error('Please select a BU Company');
      return;
    } else {
      let cp = this.getCompanyobj.filter(elem => elem.customer_id === parseInt(this.buForm.value.buid, 10));
      localStorage.setItem('bucompanyid', cp[0].customer_id);
      localStorage.setItem('bucompanyname', cp[0].customer_company_name);
      this.router.navigateByUrl('/corporate/bu-segmentation');
    }
  }
}
