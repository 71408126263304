
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { BaseComponent } from 'src/app/secondadmin/base.component';
import { PiiService } from 'src/app/services/pii/pii.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { RegisterService } from '../../../../services/users/register.service';

import { int } from 'aws-sdk/clients/datapipeline';

@Component({
  selector: 'app-bu-report',
  templateUrl: './bu-report.component.html',
  styleUrls: ['./bu-report.component.css']
})
export class BuReportComponent extends BaseComponent implements OnInit {

  constructor(

    private pii: PiiService,

    private client: ClientService,
    public registerService: RegisterService,
  ) { super() }
  @Input() profileID;
  @Output() messageToEmit = new EventEmitter();

  cp = 1;
  allProfileDataObj: any = [];
  ord = false;
  colname = "Created On";
  contact_id;
  customerID;

  contactObj: any = [];
  dSObj: any = [];
  optInObj: any = [];
  webLogsObj: any = [];
  profileObj: any = [];

  showProfile;

  pageWebLog = 1;
  limitWebLog = 10;
  offsetWebLog = 0;
  totalWebLog;


  csvWebID;


  ngOnInit() {


    var individualID = this.profileID;

    this.getIndividualProfileData(individualID);

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    this.getProfileData(individualID);

  }

  getIndividualProfileData(id) {

    this.pii.getIndividualProfileDataByParentID(id).subscribe(data => {
      this.allProfileDataObj = data.data;
    }, error => {
      // console.log(error);
    });
  }


  backToSearchPage() {
    this.showProfile = 1;
    this.messageToEmit.emit(this.contactObj);
  }



  getContactListById(id, company) {


    this.client.getContactListById(id, company).subscribe(data => {
      this.contactObj = data.data;
    }, error => {
      //  console.log(error);

    });
  }

  getDSInfo(id) {


    this.registerService.getDSInformationById(this.customerID, id).subscribe(data => {
      this.dSObj = data.data;
    }, error => {
      //  console.log(error);
    });
  }


  getCompanyInfo(id, company) {

    this.client.getContactListById(id, company).subscribe(data => {
      this.contactObj = data.data;
    }, error => {
      //  console.log(error);
    });
  }


  getOptInLogsData(id, company) {

    this.pii.getOptInLogsData(id, company).subscribe(data => {
      this.optInObj = data.data;
    }, error => {
      //  console.log(error);
    });
  }
  getWebLogsData(id, company) {

    this.offsetWebLog = (this.pageWebLog - 1) * this.limitWebLog;
    this.pii.getWebLogsData(id, company, this.limitWebLog, this.offsetWebLog).subscribe(data => {
      if (data.status) {
        this.totalWebLog = data.data.total;
        this.webLogsObj = data.data.rows;
      }
    }, error => {

      //  console.log(error);


    });
  }

  getProfileData(id) {
    this.ngxSpinnerService.show();
    this.pii.getProfileData(id).subscribe(data => {
      this.profileObj = data.data[0];
      this.contact_id = data.data[0].contact_id;

      //   console.log("aaaaaaaaaaaaaa",this.allProfileDataObj)
      if (data.data[0].source_id == 1) {
        this.csvWebID = this.contact_id;

        this.getContactListById(this.contact_id, this.customerID);
        this.getOptInLogsData(this.contact_id, this.customerID);

      }
      else if (data.data[0].source_id == 2) {
        this.csvWebID = data.data[0].user_ds_id;
        this.getWebLogsData(data.data[0].user_ds_id, this.customerID);


        this.getDSInfo(data.data[0].user_ds_id);
      }

      this.ngxSpinnerService.hide();

    }, error => {
      //  console.log(error);
    });








  }


  donwloadCSV() {


    this.ngxSpinnerService.show();

    let body = {
      "id": this.profileID,
      "company": this.customerID,
      "optwebid": this.csvWebID

    };


    this.pii.getIndividualProfileDownload(body).subscribe(data => {
      if (data.status) {

        window.URL.revokeObjectURL(data.file);

        var hiddenElement = document.createElement('a');
        hiddenElement.href = encodeURI(data.file);
        hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = '';
        hiddenElement.click();

        this.toastrService.success("CSV downloaded Sucessfully");
        // this.ngxSpinnerService.hide();
      } else {

        this.toastrService.error(data.message);

      }


      this.ngxSpinnerService.hide();

    }, error => {
      //  console.log(error);

    });




  }


    // donwloadCSV(){


    //   this.ngxSpinnerService.show();

    //   let body= {
    //     "id":this.profileID,
    //     "company":this.customerID,
    //     "optwebid":this.csvWebID

    //     };


    //   this.pii.getIndividualProfileDownload(body).subscribe(data => {
    //     if(data.status) {

    //       window.URL.revokeObjectURL(data.file);

    //       var hiddenElement = document.createElement('a');
    // hiddenElement.href =  encodeURI(data.file);
    // hiddenElement.target = '_blank';

    // //provide the name for the CSV file to be downloaded
    // hiddenElement.download = '';
    // hiddenElement.click();

    //       this.toastrService.success("CSV downloaded Sucessfully");
    //      // this.ngxSpinnerService.hide();
    //     }else {

    //       this.toastrService.error(data.message);

    //     }


    //      this.ngxSpinnerService.hide();

    //   }, error => {
    //   //  console.log(error);
    //   });




    // }


    // }






}
