import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";

@Component({
  selector: "app-activity-add",
  templateUrl: "./activity-add.component.html",
  styleUrls: ["./activity-add.component.css"],
})
export class ActivityAddComponent extends BaseComponent implements OnInit {
  addActivityForm: FormGroup;
  customer_id;
  getCompanyobj: any = [];
  submitted = false;
  parentList: any[] = [];
  constructor(
    private clientsService: ClientService,
    private formBuilder: FormBuilder,
    private masterService: MasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.customer_id = JSON.parse(
      sessionStorage.getItem("userData")
    ).admin_customer_id;
    if (this.customer_id == 0) {
      this.getCompany();
    } else {
      this.parentList = [];
      this.masterService
        .getReadinessParentList(this.customer_id)
        .subscribe((res: any) => {
          this.parentList = res.data || [];
        });
    }
    this.createForm();
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  get f() {
    return this.addActivityForm.controls;
  }
  createForm() {
    this.addActivityForm = this.formBuilder.group({
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
      parent_id: ["", Validators.required],
      title: [
        "",
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      description: ["", [Validators.maxLength(100), Validators.required]],
      weight: ["", Validators.required],
      orderno: ["", Validators.required],
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  getParentList() {
    this.parentList = [];
    let companyId = this.f.selectedCompany.value;
    this.masterService
      .getReadinessParentList(companyId)
      .subscribe((res: any) => {
        this.parentList = res.data || [];
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.addActivityForm.invalid) {
      return;
    }
    this.ngxSpinnerService.show();
    this.masterService
      .addReadinessActivity(this.f.selectedCompany.value, {
        ...this.addActivityForm.value,
        company: this.f.selectedCompany.value,
      })
      .subscribe(
        (data) => {
          this.ngxSpinnerService.hide();
          if (data.status) {
            this.toastrService.success("Activity added successfully");
            this.router.navigateByUrl(`/corporate/readiness-activity-list`);
          } else {
            this.toastrService.error(data.message);
          }
        },
        (error) => {
          this.ngxSpinnerService.hide();
          this.toastrService.error(
            error?.error?.message || error?.message || error
          );
        }
      );
  }
}
