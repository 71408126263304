import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-addnewevent',
  templateUrl: './addnewevent.component.html',
  styleUrls: ['./addnewevent.component.css']
})
export class AddneweventComponent extends BaseComponent implements OnInit {

  constructor(public master: MasterService,
    private formBuilder: FormBuilder
  ) {
    super();
   }

  // name;
  // description;
  // isactive;
  event_id;
  eventObj;
  header = "Add New"
  status: boolean = true;

  eventForm: FormGroup;
  submitted = false;

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.event_id = params.event_id;
      });
    //get item details using id
    if (self.event_id) {
      this.ngxSpinnerService.show();
      this.UpdateEventLogData()
      this.header = "Edit"
      this.master.getEventTypeById(this.event_id).subscribe(response => {
        //console.log(response);
        if (response.status) {
          this.status = false;
          self.eventObj = response.data;
         // console.log(self.eventObj)
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }
      });
    } else {
      this.addEventTypeLogData()
    }
    this.eventForm = this.formBuilder.group({
      eventName: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      eventDescription: ['']
    });
  }
  get f() { return this.eventForm.controls; }
  addEventTypeLogData() {

    let eventLogs = {
      log_type: "Adding New Event Type",
      log_name: "Adding New Event Type",
      log_description: "Adding New Event Type",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(eventLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
    //  console.log(error);
    });
  }
  UpdateEventLogData() {
    let eventLogs = {
      log_type: "Updating Event Type Data",
      log_name: "Updating Event Type Data",
      log_description: "Updating Event Type Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(eventLogs).subscribe(data => {
     // console.log(data);

    }, error => {
      console.log(error);
    });

  }
  addNewEvent() {
    this.submitted = true;
    if (this.eventForm.invalid) {
      return;
    }

   // console.log(this.eventForm);

    let { eventName, eventDescription } = this.eventForm.value;
    let eventBody = {
      name: eventName,
      description: eventDescription
    }
    this.ngxSpinnerService.show();
    this.master.addNewEvent(eventBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully");
        this.router.navigateByUrl(`/corporate/eventtypemaster`);
        this.ngxSpinnerService.hide();
      }else{
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.error(error.message);
      this.ngxSpinnerService.hide();
    })
  }
  updateEventType() {
    this.submitted = true;
    if (this.eventForm.invalid) {
      return;
    }

    let { eventName, eventDescription } = this.eventForm.value;
    let eventBody = {
      event_type_name: eventName,
      event_type_description: eventDescription,
      isactive: this.eventObj.isactive,
      event_type_id: this.event_id
    }
    this.ngxSpinnerService.show();
    this.master.updateEventType(eventBody).subscribe(data => {
      this.toastrService.success("Update Successfully");
      this.router.navigateByUrl(`/corporate/eventtypemaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.toastrService.error(error.message);
      this.ngxSpinnerService.hide();
    });
  }
  dataBinding() {
       this.eventForm = this.formBuilder.group({
      eventName: [this.eventObj.event_type_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      eventDescription: [this.eventObj.event_type_description],
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
  
}
