<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Content Builder Association</li>
    </ol>
  </nav>
</div>

<div class="container mt-2">

  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Company:</label>
            <div class="col-sm-8">
              <select name="company" class="form-control formstyle" (change)="getCompanyTheme($event.target.value)">
                <option value="">Select Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} >
                  {{userObj.customer_company_name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Theme:</label>
            <div class="col-sm-8">
              <select name="themeid" class="form-control formstyle" (change)='changeCheckTheme($event.target.value)'>
                <option value="">---Select Theme---</option>
                <option *ngFor="let obj of themeObj" value={{obj.theme_id}}>
                  {{obj.theme_name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Column:</label>
            <div class="col-sm-8">
              <div class="piInnerList scroller" *ngIf="!show">
                <ul class="filtMain">
                  <li *ngFor="let item of allTablesobj" class="assoLi">

                    <label class="form-check-label" (change)='changeCheckTables(item.column_name);'>
                      <input class="form-check-input1" type="checkbox" id="table{{item.column_name}}" name="tables">
                      {{item.column_name}}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-md-6">
          <div class="piInnerList1 scroller" *ngIf="!show">
            <div class="filtMain">
              <div class="form-group"  *ngFor="let item of checkedTable">
                <div class="row m-1 mt-md-0">
                  <div class="col-sm-4">{{item}}</div>
                <div class="col-sm-4">
                  <select id="field_type{{item}}" class="form-control formstyle" (change)='changeCheckDataType(item,$event.target.value)'>
                    <option value="text" [selected]="item.data_type=='text'">Text</option>
                    <option value="integer" [selected]="item.data_type =='integer'">Integer</option>
                    <option value="file" [selected]="item.data_type =='file'">File</option>    
                  </select>
                </div>
                 <div *ngIf="input_data[item]!=''" class="col-sm-4">
                    <input type="{{input_data[item]}}" class="form-control" id="field_size" (change)='changeCheckField(item,$event.target.value)' placeholder="field size" [formControl]="field_size" name="field_size" value="100">
                 </div>
                 <div *ngIf="input_data[item]==''" class="col-sm-4">
                  <input type="text" class="form-control" id="field_size" (change)='changeCheckField(item,$event.target.value)' placeholder="field size" [formControl]="field_size" name="field_size" value="100">

                </div>

              </div>
            </div>
            </div>
          </div>
        </div>
      </div>


      
      
    </div>

      <div class="text-center card-footer">
        <button class="custbtn" (click)="addCBThemeField()">
          Create template
        </button>
      </div>

  </div>


</div>