<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/contentbuilder">Content Builder</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/cb-form-builder-list">Form Builder</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Product Clone Form Builder
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="addForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">
        <div class="card mt-1">
          <div class="card-body">
            <div class="form-group" *ngIf="companyCheck == 0">
              <label for="exampleForm2">Select Company Name <span style="color: red">*</span></label>

              <select formControlName="company" *ngIf="headers == 'Add'" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                (change)="getCompanyThemes($event.target.value)">
                <option value="">Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value="{{ userObj.customer_id }}">
                  {{ userObj.customer_company_name }}
                </option>
              </select>


              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">
                  Company Name is required
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="companyCheck > 0">
              <input type="hidden" formControlName="company" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
            </div>
            <div class="form-group">
              <label for="exampleForm2">From Theme Name <span style="color: red">*</span></label>
              <select formControlName="themeid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.themeid.errors }">
                <option value="">Theme Name</option>
                <option *ngFor="let userObj of themeObj" value="{{ userObj.theme_id }}">
                  {{ userObj.theme_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.themeid.errors" class="invalid-feedback">
                <div *ngIf="f.themeid.errors.required">
                  Theme Name is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Product Image<span class="required">*</span></label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio2" [value]="0" formControlName="file">
                  <label class="custom-control-label" for="customRadio2">Upload a file</label>
                </div>
              </div>
              <input #imageInput type="file" accept=".png, .jpg, .jpeg," (change)="processFile($event,imageInput)">
              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Is Pre Host</label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="prehost1" formControlName="prehost" [value]="1">
                  <label class="custom-control-label" for="prehost1">YES</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="prehost2" formControlName="prehost" [value]="0">
                  <label class="custom-control-label" for="prehost2">NO</label>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Is Pre Guest</label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="preguest1" formControlName="preguest"
                    [value]="1">
                  <label class="custom-control-label" for="preguest1">YES</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="preguest2" formControlName="preguest"
                    [value]="0">
                  <label class="custom-control-label" for="preguest2">NO</label>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Is Post Host</label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="posthost1" formControlName="posthost"
                    [value]="1">
                  <label class="custom-control-label" for="posthost1">YES</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="posthost2" formControlName="posthost"
                    [value]="0">
                  <label class="custom-control-label" for="posthost2">NO</label>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Is Post Guest</label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="postguest1" formControlName="postguest"
                    [value]="1">
                  <label class="custom-control-label" for="postguest1">YES</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="postguest2" formControlName="postguest"
                    [value]="0">
                  <label class="custom-control-label" for="postguest2">NO</label>
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-center">
            <div class="my_class text-center">
              <button type="button" class="custbtn" (click)="addProductClone()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>