<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/journeytype">Journey Type</a></li>          
      <li class="breadcrumb-item active" aria-current="page">Add Journey Type</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1">
    <div class="rowTheme">
        <div class="themContain">
      <div> 
      <label class="forcolor">Parent Journey Type</label>
      <select type="text" [(ngModel)]="journey_type_parent_id" class="form-control formstyle">
      <option value="0" selected>Root</option>
      <!--<option *ngFor="let Obj of getRoleObj1" value={{Obj.role_id}}>
          {{Obj.role_name}}
      </option>-->
      </select>
    </div>
        <div class="mt-1">
            <label for="exampleForm2">Journey Type Name</label>
            <input type="text" class="form-control" [(ngModel)]="journey_type_name" class="form-control form-control-lg"
                required>
                <!-- <div *ngIf="submitted && f.themename?.errors" class="invalid-feedback">
                    <div *ngIf="f.themename?.errors.required">Theme Name is required</div> -->
                <!-- </div> -->
        </div>
        <div class="mt-1">
            <label for="exampleForm2">Journey Type Description</label>
            <input type="text" class="form-control" [(ngModel)]="journey__type_description"
                class="form-control form-control-lg" required>
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Journey Type Icon</label>
          <input type="text" class="form-control" [(ngModel)]="journey_type_icon"
              class="form-control form-control-lg" required>
        </div>


        
        <div class="my_class text-center mt-2" *ngIf="isWrite"  [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" (click)="addNewJourneytype()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" *ngIf="isEdit"  [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" (click)="updateJourneytype()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</div>