import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "src/app/services/clients/client.service";
import { MetaDataService } from "src/app/services/metadata/metadata.service";
import { BaseComponent } from "../../base.component";

@Component({
    selector: 'app-meta-db-connection',
    templateUrl: './metadbconnection.component.html',
    styleUrls: ['./metadbconnection.component.css']
})
export class MetaDBConnectionComponent extends BaseComponent implements OnInit{

    customerID: any;
    buForm: FormGroup;
    getCompanyobj = [];
    selectedBuId = '';
    submitted = false;
    dbConnForm: FormGroup;
    
    constructor(private metaService: MetaDataService, private formBuilder: FormBuilder,
        private clientService: ClientService) {
        super();
        let sid = JSON.parse(localStorage.getItem('userData'));
        this.customerID = sid.admin_customer_id;
    }

    ngOnInit() {
        this.ngxSpinnerService.show();
        this.buForm = this.formBuilder.group({
            buid: ['']
          });
          this.dbConnForm = this.formBuilder.group({
            dbtype: ['', Validators.required],
            host: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            dbname: ['', Validators.required],
            minpoll: ['', Validators.required],
            maxpoll: ['', Validators.required],
            idletime: ['', Validators.required],
            encrypt: ['', Validators.required],
            t_cert: ['', Validators.required]
          });

        this.getCompanyList();
    }

    get f() { return this.dbConnForm.controls; }

    // ##########  Get BU Company List  #####################  //

    getCompanyList() {
        this.clientService.getBUCompanies().subscribe(data => {
            if (data.status) {
              this.getCompanyobj = data.data;
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            this.toastrService.error(error);
            this.getCompanyobj = [];
            this.ngxSpinnerService.hide();
          });
    }

    /*  ON BU SELECTED FROM DROPDOWN  */

    onBuSelected() {
        // this.ngxSpinnerService.show();
        
    }

    allowNumbersOnly(e) {
        var code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    addDbConnMapping() {
        this.ngxSpinnerService.show();
        this.submitted = true;
        if (this.dbConnForm.invalid) {
            this.toastrService.error('Please provide correct values for Mapping');
            this.ngxSpinnerService.hide();
            return;
        }
    }
}