<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a  routerLink="/corporate/gender">Gender</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Gender</li>
    </ol>
  </nav>
</div>

<!-- <h3><a routerLink="/corporate/thememaster">&lt;Back</a>
</h3> -->
<div class="container mt-1 mt-md-2">
    <form class="form-horizontal mt-1" [formGroup]="AddGender">
    <div class="rowTheme">
        <div class="themContain">

        <div>
            <label for="exampleForm2" >Gender Name <span class="required">*</span> </label>
            <input type="text" appSpace formControlName='sex_name' class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': submitted && f.sex_name.errors }"  required>
                <div *ngIf="submitted && f.sex_name.errors" class="invalid-feedback">
                    <div *ngIf="f.sex_name.errors.required">Gender Name is required</div>
                </div>
        </div>

        <div class="mt-1">
            <label for="exampleForm2">Gender Description</label>
            <input type="text" class="form-control" formControlName='sex_desc'
                class="form-control form-control-lg">
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Gender Icon</label>
          <input type="text" class="form-control"  formControlName='sex_icon'
              class="form-control form-control-lg">
        </div>



        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewGender()">Submit</button>
            <!-- </div> -->
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock' ">
            <!-- <div class="col-12 col-md-3"> -->
                <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateGender()">Update</button>
            <!-- </div> -->
        </div>
    </div>
</div>
</form>
</div>
