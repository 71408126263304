import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-update-poll-option',
  templateUrl: './update-poll-option.component.html',
  styleUrls: ['./update-poll-option.component.css']
})
export class UpdatePollOptionComponent extends BaseComponent implements OnInit {
  customer_id;
  addPollOptionForm: FormGroup;
  addPollOptionData:any;
  controlEnabled: boolean = true;
  status: boolean = true;
  submitted = false;
  getCompanyobj: any = [];
  screenshot;
  pollOption_id;
  pollObj;
  screenshot_type;
  selectedCompany;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService: MasterService
  ) { super(); }

  ngOnInit() {
    this.controlEnabled = true;
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    if (localStorage.getItem("edit_poll_option_id")) {
      this.pollOption_id = localStorage.getItem("edit_poll_option_id");
      this.selectedCompany = localStorage.getItem("poll_option_edit_company_id");

      this.masterService.getOptionById(this.pollOption_id, this.selectedCompany).subscribe(response => {
        if (response.status) {
          this.status = false;
          this.addPollOptionData = response.data;
          this.dataBinding();
        }
      });
    }

    this.addPollOptionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      // description: ['', Validators.maxLength(100)],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }

  get f() { return this.addPollOptionForm.controls; }

  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  dataBinding() {
    this.addPollOptionForm = this.formBuilder.group({
      name: [this.addPollOptionData.option_name, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      selectedCompany: [this.selectedCompany, Validators.required]
    });
  }
  getCompany() {
    this.clientsService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  updatePollOption(){
    this.submitted = true;
    if (this.addPollOptionForm.invalid) {
      return;
    }
    let { name, description, selectedCompany } = this.addPollOptionForm.value;
    let updationObj = {
      option_name: name,
      isActive : true,
    }
    this.ngxSpinnerService.show();
    this.masterService.updatePollOption(this.selectedCompany, this.pollOption_id, updationObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Poll Option Updated successfully");
        this.router.navigateByUrl(`/corporate/poll-option`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
    });
  }
}
