<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Segmentation</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 theme-fa-icon" >
  <fieldset class="scheduler-border">
<!--    <legend class="scheduler-border"><h5 class="font-weight-bold">Marketing Cloud Master</h5></legend>-->

    <div class="row">

      <div class="col-xl-4  col-md-6" *ngIf="id === 0">
        <button class="cbtn mt-1" routerLink="/corporate/master-segmentation">
          <i class="bi bi-pie-chart green-lt-icon" aria-hidden="true"></i>Master Segmentation
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" (click)="goForBUSegmentation()">
          <i class="fa fa-pie-chart red-dr-icon" aria-hidden="true"></i>BU Segmentation
        </button>
      </div>

    </div>

  </fieldset>
</div>
