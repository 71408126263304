import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-update-poll',
  templateUrl: './update-poll.component.html',
  styleUrls: ['./update-poll.component.css']
})
export class UpdatePollComponent extends BaseComponent implements OnInit {

  updatePollForm: FormGroup;
  controlEnabled: boolean = true;
  status: boolean = true;
  submitted = false;
  getCompanyobj: any = [];
  screenshot;
  poll_id;
  pollObj;
  screenshot_type;
  customer_id;
  selectedCompany;
  webinarPollOptions: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService: MasterService
  ) { super(); }

  ngOnInit() {
    this.controlEnabled = true;
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    if (localStorage.getItem("edit_poll_id")) {
      this.poll_id = localStorage.getItem("edit_poll_id");
      this.selectedCompany = localStorage.getItem("poll_edit_company_id");
      this.getWebinarPollOptions(this.selectedCompany)
      this.masterService.getPollById(this.poll_id, this.selectedCompany).subscribe(response => {
        if (response.status) {
          this.status = false;
          this.pollObj = response.data;
          this.dataBinding();
        }
      });
    }

    this.updatePollForm = this.formBuilder.group({
      name: ['', [Validators.required,  this.noWhitespaceValidator]],
      description: [''],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],
      options: [[]]
    });
  }

  get f() { return this.updatePollForm.controls; }

  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  dataBinding() {
    this.updatePollForm = this.formBuilder.group({
      name: [this.pollObj.title, [Validators.required, this.noWhitespaceValidator]],
      description: [this.pollObj.description],
      selectedCompany: [this.selectedCompany, Validators.required],
      options: [this.pollObj.options]
    });
  }
  getCompany() {
    this.clientsService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  getWebinarPollOptions(id: any) {
    this.masterService.getWebinarAllPollOptionList(id).subscribe((data) => {
      if (data.status) {
        this.webinarPollOptions = data.data.rows;
      } else {
        this.webinarPollOptions = [];
      }
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  updatePoll() {
    this.submitted = true;
    if (this.updatePollForm.invalid) {
      return;
    }
    let { name, description, selectedCompany, options } = this.updatePollForm.value;
    let updationObj = {
      title: name,
      description: description,
      options: options,
      isActive:this.pollObj.is_active,
      isCorporate:this.pollObj.is_corporate,
    }
    this.ngxSpinnerService.show();
    this.masterService.updatePollStatus(this.selectedCompany, this.pollObj.id, updationObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Poll Updated successfully");
        this.router.navigateByUrl(`/corporate/poll`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
    });
  }
  checkedfunction(a: any) {
    if (this.updatePollForm.controls.options.value) {
      let checkArray = [];
      checkArray = this.updatePollForm.controls.options.value;
      return checkArray.some((ele) =>
        ele == a);
    }
  }
  onChange(id: number, checked: boolean) {
    if (checked) {
      this.pollObj.options.push(id)
      this.updatePollForm.controls.options.setValue(this.pollObj.options)
    }
    else {
      this.pollObj.options = this.pollObj.options.filter((ele: any) => { return ele != id }
      )
      this.updatePollForm.controls.options.setValue(this.pollObj.options)
    }
  }
}
