<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">Users</a></li>
      <li class="breadcrumb-item active" aria-current="page">Direct Seller</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6 mb-md-0 mb-1" *ngIf="customerID==0">
          <select name="company" class="form-control formstyle" [(ngModel)]="companyId" (change)="getCompanySeller('compChange')">
            <option value="">Select Company Name</option>
              <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                  {{userObj.customer_company_name}}
              </option>
          </select>
        </div>
        <div class="col-md-6 mb-md-0 mb-1" >
          <select name="status" class="form-control formstyle"  (change)="getCompanySeller('status')" [(ngModel)]="status"
          [disabled]="companyId == '' ">
            <option value="3">All User</option>
            <option value="1">Active User</option>
            <option value="2">Inactive User</option>
          </select>
        </div>
      </div>
  </div>
<div class="col-md-6">
  <div class="row">
    <!-- *ngIf="userObj.length !== 0" -->
    <div class="col-md-8 mb-md-0 mb-1">
      <div class="input-group" >
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search"
        [disabled]="companyId == '' " #searchText>
      </div>

    </div>


  <div class="col-md-4 mb-md-0 mb-1 mt-md-0 mt-2" style="justify-content: right;" *ngIf='selectedCompany?.is_bu'>
    <input type="button" value="Add New" class="custbtn mt-md-1 ml-0" *ngIf="isWrite" routerLink="/corporate/adddsuser" >

  </div>
</div>
</div>

  </div>

  <div class="row" >


    <div class=" col-md-4 mt-3" *ngIf="assignBundleStatus && usersForBundleAssignment.length > 0  && customerID == 0 ">
    <!--  <label for="staticEmail" class="col-md-2 col-form-label">Bundle Name</label> -->
      <form class="form-horizontal mt-1" [formGroup]="bundleSelectForm" >
        <select class="form-control formstyle ellipsis-cs" style="max-width: 125px;" id="inlineFormCustomSelect"
        [disabled]="companyId == '' "  formControlName="bundleid">

          <option value="" selected>Select bundle</option>
          <option *ngFor="let items of allBundleList" value="{{items.bundle_id}}">{{items.bundle_name}}</option>

        </select>
        <button class="custbtn ml-2" (click)="assignBundle()" [disabled]="companyId == '' ">Assign Bundle</button>
      </form>


    </div>

  <div class="pl-3 mt-3 pt-2" *ngIf="resendStatus && (status==2 || status==3)">
    <button  type="button" class="custbtn" (click)="sendActivationLink(userObj)"
    data-sidebar="menu-light" id="light-menu" *ngIf="resendStatus && checkedList.length== 0 && status==2">Resend All
    </button>
    <button  type="button" class="custbtn" (click)="sendActivationLink(checkedList)"
    data-sidebar="menu-light" id="light-menu" *ngIf="resendStatus && checkedList.length > 0">Resend Checked
    </button>
  </div>

  <div class="col-12 mt-2" *ngIf="allBundleList.length > 0">
    <div class="row mt-md-3" >
      <div class="col-md-3">
        <select class="form-control formstyle ellipsis-cs" (change)="getCompanySeller('bundleFilter')" [(ngModel)]="selectedBundleName"  [disabled]="companyId == ''">

    <option value="" selected>Filter By Bundle Name</option>
    <option *ngFor="let items of allBundleList" value="{{items.bundle_name}}" title="{{items.bundle_name}}">{{items.bundle_name}}</option>

    </select></div>
    <div class="col-md-3 mt-md-0 mt-2">
      <!-- <input class="form-control formstyle mb-1" placeholder="Bundle Assigned Date" matInput [matDatepicker]="picker" readonly
     (dateInput)="addEvent('input', $event)" [(ngModel)]="assignDate">
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker  ></mat-datepicker> -->
  <input class="form-control formstyle mb-1" placeholder="Bundle Assigned Date" matInput [matDatepicker]="picker" readonly
     (dateInput)="getBundleAssignedDate('input', $event)" [(ngModel)]="assignDate">
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker  ></mat-datepicker>

    </div>
    <div class="col-md-3"><button class="custbtn mt-md-1 ml-0 mt-2" (click)="reset()" >Reset</button></div>
    </div>
  </div>

</div>


<div class="tblPrt mt-2 overflow-auto">


  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="userObj.length !== 0">



      <tr>

        <th> <span *ngIf="status==1 && customerID === 0">Select</span></th>


        <th><span (click)="setOrder('full_name')" style="cursor: pointer;">Name / UserName / Email <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></span></th>
            <!-- <th>UserName</th>
            <th (click)="setOrder('email')" style="cursor:pointer">Email <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th> -->
            <!-- <th>Bundle Name</th>
            <th>Bundle Assinged Date</th> -->
            <th>Bundle Name / Assigned Date</th>
            <th>Business Domain</th>
            <th>Is Independent DS</th>
            <th>Email Service</th>
            <th>Subscription</th>
            <th>Email Verify</th>
            <th>Board By</th>
            <th>Is Active</th>

        <th style="cursor: pointer;"><span (click)="setOrder('createdon')">Created On <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></span></th>


        <th><span *ngIf="customerInfo.role_id != 100">Actions</span></th>
        <th>Reports</th>
      </tr>
      <tr *ngFor="let obj of userObj">

        <td >
          <span *ngIf="status==1 && obj.isactive && obj.password && customerID === 0">
          <input type="checkbox" (change)="onUserSelectedForAssignment($event.target.checked, obj)" [checked]="obj.isSelected"  #checkboxes></span>

        <span *ngIf="obj.isactive==false ||  obj.password==null || obj.password=='' || customerID!=0">  <input type="checkbox" (change)="onCheckboxChange(obj,$event)" value="{{obj.userid}}"  #checkboxes *ngIf="!obj.isactive || !obj.password"></span>

        </td>








        <td><span (click)="dsLogsView(obj.userid)" style="text-decoration:underline;cursor: pointer;">
          {{obj.full_name}}<br/>
          {{obj.username}}</span><br/>
          <span class="ellipsis" title="{{obj.email}}">{{obj.email}}</span>

        </td>

          <!-- <td>{{obj.username}}</td>
        <td>{{obj.email}}</td> -->

        <td>
          <span>
          {{obj.bundle_name}}<br/>
          {{obj.bundle_assigned_date | date:"MMM dd, YYYY 'at' hh:mm a"}}
        </span>

        </td>
        <!-- <td><span style="word-break: break-word;">{{obj.bundle_name}}</span></td> -->
<!--        <td style="word-break: break-word;">{{obj.bundle_assigned_date | date:'medium'}}</td>-->
        <!-- <td>
          <p class="mb-0">{{obj.bundle_assigned_date | date:'mediumDate'}}</p>
          <p>{{obj.bundle_assigned_date | date:'mediumTime'}}</p>
        </td> -->
        <td>{{ obj?.domain_name }}</td>
        <td class="text-center">
          {{(obj.is_independent_ds!=undefined) ? (obj.is_independent_ds?'Yes':'No'):''}}
        <!-- <br/>
          <span *ngIf="obj.is_independent_ds && obj.domain_name">
            ( {{obj.domain_name }} )
          </span> -->

        </td>
        <td> 
          <a *ngIf="obj.is_independent_ds ; else noActionMu" (click)="muUpdateDs=obj?.userid;openEmailSelectionModal(emailServiceModal)" class="text-uppercase">{{obj?.mc_type}}</a>
          <ng-template #noActionMu class="text-uppercase">{{obj?.mc_type}}</ng-template>
        </td>

        <td><span>
          {{obj.subscriptionname}}<br/>
          {{obj.sub_end_date | date:"MMM dd, YYYY 'at' hh:mm a"}}
        </span></td>
        <td>{{ obj?.is_email_verify_upper}}</td>
        <td>

       </td>
        <td>{{(obj.isactive) ? "Active" : "Inactive"}}</td>

        <td>
          <p class="mb-0">{{obj.createdon | date:"MMM dd, YYYY 'at' hh:mm a"}}</p>
          <!-- <p>{{obj.createdon | date:'mediumTime'}}</p> -->
<!--          {{obj.createdon | date:'medium'}}-->
        </td>
        <td style="width: 200px;">
          <span *ngIf="customerInfo.role_id != 100">
          <a *ngIf="obj.isactive && obj.password"  (click)="resetpassword(obj.username)" style="white-space: nowrap;">Reset Password</a>
          <mat-slide-toggle *ngIf="obj.isactive && obj.password" [checked]="obj.isactive"  class="my-slider ml-1"
            (click)="updateStatus($event, obj.userid,obj.isactive)">
            </mat-slide-toggle>
          <p *ngIf="!obj.isactive || !obj.password" ><a   (click)="sendActivationLink([obj])">Resend Link</a></p>
        </span>
        </td>
        <td class="viewReport"> <span (click)="dsreportView(obj.userid)"> View </span> </td>


        <!-- <td>
          <i class="fa fa-edit mr-1" (click)="editUser(obj.get_ds_user_login_fun.uid)"></i>
          <i class="fa fa-trash mr-1" (click)="deleteUser(obj.get_ds_user_login_fun.uid)"></i>
          <mat-slide-toggle [checked]="obj.get_ds_user_login_fun.isactive" class="my-slider"
            (click)="updateStatus($event,obj.get_ds_user_login_fun.uid)">
          </mat-slide-toggle>
        </td> -->
      </tr>

  </table>
  <div *ngIf="total == 0" style="text-align: center;margin-top: 70px;">
    <p>No Result Available</p>
  </div>
  <!-- <div class="text-center" *ngIf="selectedBundleName=='' && userObj.length !== 0">
    <pagination-controls  class="my-pagination" (pageChange)="getCompanySeller($event, companyId)"></pagination-controls>
  </div>
  <div class="text-center" *ngIf="selectedBundleName!='' && userObj.length !== 0">
    <pagination-controls  class="my-pagination" (pageChange)="searchByBundleNamePaging(selectedBundleName,$event)"></pagination-controls>
  </div> -->
  <div class="mt-2 text-center difPagination" *ngIf="total > 10">
    <ngb-pagination [collectionSize]="total" [(page)]="dsPage" [maxSize]="2" [rotate]="true"
      (pageChange)="getCompanySeller(dsPage=$event)">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div>
</div>

<!-- <div class="container" *ngIf="usersForBundleAssignment.length !== 0">
  <div class="footerBtnLsn">
    <div class="form-group row">
      <button class="mainBtnBle" (click)="assignBundle()">Assign Bundle</button>
    </div>
  </div>
</div> -->
<ng-template #emailServiceModal let-modal>
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead w-100">
              <h4 class="modal-title mb-0 float-left">Update email service provider</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                  <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
          </div>
          <div class="modal-body">
              <div class="scroller1">
                  <div class="col-12 row ">
                    <div class="pb-3">
                      <label for="emailSelect" >Please select email service provider</label>
                      <select id="emailSelect" class="form-control formstyle" #emailServiceProviderValue>
                        <option value="" selected disabled>select</option>
                        <option value="mu">MU</option>
                        <option value="sfmc">SFMC</option>
                        <option value="ses">SES</option>
                      </select>
    
                  
    
                    </div>
                  </div>
              </div>
          </div>
          <div class="d-block text-center modal-footer border-0">
              <button type="button" class="btn btn-margo-action btn-round text-uppercase"
                  (click)="updateEmailProviderForDs(emailServiceProviderValue.value)">Update</button>
                  <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                  (click)="modal.dismiss('Cross click')">Cancel</button>   
          </div>
      </div>
  </div>
</ng-template>