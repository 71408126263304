import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-margonewcontact',
  templateUrl: './margonewcontact.component.html',
  styleUrls: ['./margonewcontact.component.css']
})
export class MargonewcontactComponent implements OnInit {

  constructor() { }
  projectName=environment.projectName

  ngOnInit() {
  }

}
