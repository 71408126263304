import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-add-faq-category',
  templateUrl: './add-faq-category.component.html',
  styleUrls: ['./add-faq-category.component.css']
})
export class AddFaqCategoryComponent extends BaseComponent implements OnInit {

  customer_id;
  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', Validators.maxLength(100)],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f() { return this.addCategoryForm.controls; }
  addNewCategoryData(){
    this.submitted = true;
    if (this.addCategoryForm.invalid) {
      return;
    }
    let { name, description, selectedCompany } = this.addCategoryForm.value;
    let obj = {
      name: name,
      description: description,
      isactive: true,
      company: selectedCompany
    }
    this.ngxSpinnerService.show();
    this.masterService.createFaqCategory(obj).subscribe(data=>{
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Category added successfully");
        this.router.navigateByUrl(`/corporate/faq-category`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error.message);
    });
  }
}
