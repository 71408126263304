import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';

@Component({
  selector: 'app-addnewcompany',
  templateUrl: './addnewcompany.component.html',
  styleUrls: ['./addnewcompany.component.css']
})

export class AddnewcompanyComponent extends BaseComponent implements OnInit {
  // name;
  // description;
  // parentid = "0";
  // isactive;
  company_id;
  companyObj;
  status: boolean = true;
  companyTypeObj
  header = "Add New";

  companyForm: FormGroup;
  submitted = false;
  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder
  ) { 
    super();
  }

  ngOnInit() {
    this.getCompanyType()
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.company_id = params.company_id;
      });
    //get item details using id
    if (self.company_id) {
      this.ngxSpinnerService.show();
      this.UpdateCompanyLogData();
      this.header = "Edit"
      this.master.getCompanyById(this.company_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.companyObj = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();
        } else {

        }
      });
    } else {
      this.addCompanyLogData()
    }
    this.companyForm = this.formBuilder.group({
      companyName: ['',  [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      companyDescription: [],
      companyParentId: [0],
    });
  }
  get f() { return this.companyForm.controls; }
  addCompanyLogData() {

    let CompanyLogs = {
      log_type: "Adding New Company",
      log_name: "Adding New Company",
      log_description: "Adding New Company",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CompanyLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  UpdateCompanyLogData() {

    let CompanyLogs = {
      log_type: "Updating Company Data",
      log_name: "Updating Company Data",
      log_description: "Updating Company Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CompanyLogs).subscribe(data => {
     // console.log(data);

    }, error => {
      console.log(error);
    });

  }
  getCompanyType() {
    let self = this;
    this.master.getCompanyType().subscribe(data => {
      self.companyTypeObj = data;
    }, error => {
      console.log(error);
    });
  }

  dataBinding() {
    // this.name = this.companyObj.company_type_name;
    // this.description = this.companyObj.company_type_description;
    // this.parentid = this.companyObj.company_parent_id;
    // this.isactive = this.companyObj.isactive;
    this.companyForm = this.formBuilder.group({
      companyName: [this.companyObj.company_type_name,  [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      companyDescription: [this.companyObj.company_type_description],
      companyParentId: [this.companyObj.company_parent_id],
    });
  }

  addNewCompany() {
    this.submitted = true;
    // console.log(this.companyForm);
    if (this.companyForm.invalid) {
      return;
    }
  //  console.log(this.companyForm);

    let { companyName, companyDescription, companyParentId } = this.companyForm.value;
    var companyBody = {
      name: companyName,
      description: companyDescription,
      parentid: companyParentId
    }
    this.ngxSpinnerService.show();
    this.master.addingCompany(companyBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/companytypemaster`); 
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error.message)
      this.ngxSpinnerService.hide();
    });
  }
  updateCompany() {
    this.submitted = true;
    if (this.companyForm.invalid) {
      return;
    }
    //console.log(this.companyForm);
    let { companyName, companyDescription, companyParentId } = this.companyForm.value;
    let companyBody = {
      company_type_name: companyName,
      company_type_description: companyDescription,
      company_parent_id: companyParentId,
      isactive: this.companyObj.isactive,
      company_type_id: Number.parseInt(this.company_id, 10)
    }
    this.ngxSpinnerService.show();
    this.master.updateCompany(companyBody).subscribe(data => {
      this.toastrService.success("Record Updated successfully");
      this.router.navigateByUrl(`/corporate/companytypemaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
