<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/campaign-builder"> Campaign Builder</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{header}} Campaign
      </li>
    </ol>
  </nav>
</div>

<div class="container mt-2">
  <h5 class="font-weight-bold mt-4 mb-4">{{header}} Campaign</h5>
  <form *ngIf="campaignAddForm"  class="form-horizontal mt-1" [formGroup]="campaignForm">
    <div class="row">
      <div class="col-md-7 col-xl-7">
        <div class="card">
          <div class="card-body">
            <div class="mt-1">
              <label for="exampleForm2" class="position-relative pr-2">Campaign Name <span
                  style="color: red">*</span></label>
              <input type="text" id="exampleForm2" formControlName="camp_name" class="form-control form-control-md"
                required [ngClass]="{ 'is-invalid': submitted && f.camp_name.errors }" maxlength="80" appSpace
                maxlength="80">
              <div *ngIf="submitted && f.camp_name.errors" class="invalid-feedback">
                <div *ngIf="f.camp_name.errors.required || f.camp_name.hasError('whitespace')"> Name is required</div>
                <div *ngIf="f.camp_name.errors?.maxlength">You can enter only 80 charachters</div>
              </div>
            </div>
            <div class="mt-1">
              <label for="exampleForm2"> Campaign Description</label>
              <input appSpace maxlength="400" type="text" formControlName="camp_desc"
                class="form-control form-control-md">
            </div>
            <div class="mt-1">
              <label for="journey_id"> Campaign Journey Type <span style="color: red">*</span></label>
              <select id="journey_id" [attr.disabled]="journeyStatus ? 'disabled' : null" class="form-control formstyle"
                formControlName="journey_id" (change)="getJourneyType($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.journey_id.errors }">
                <option value="">Select Campaign Journey</option>
                <option *ngFor="let obj of journeyObj" value="{{obj.journey_id}}">{{obj.journey_name}}</option>

              </select>
              <div *ngIf="submitted && f.journey_id.errors" class="invalid-feedback">
                <div *ngIf="f.journey_id.errors.required"> Campaign Journey is required</div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Email Signature</label>
              <div>
                  <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" id="issms1"
                           [value]="0"   formControlName="mail_signature">
                      <label class="custom-control-label" for="issms1">By Direct Seller</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" id="issms2"
                           [value]="1"  formControlName="mail_signature">
                      <label class="custom-control-label" for="issms2">By BU Corporate</label>
                  </div>
              </div>
          </div>

          <div class="mt-1">
            <label for="exampleForm2">Is Tested</label>
            <div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="istest1"
                         [value]= true   formControlName="is_tested">
                    <label class="custom-control-label" for="istest1">Yes</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="istest2"
                         [value]= false  formControlName="is_tested">
                    <label class="custom-control-label" for="istest2">No</label>
                </div>
            </div>
        </div>

          </div>
          <div class="card-footer">
            <div class="text-center my_class " *ngIf="!editStatus"
              [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
              <button type="button" class="custbtn" (click)="addBUCampaign()">Submit</button>
            </div>
            <div class="text-center my_class " *ngIf="editStatus"
              [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
              <button type="button" class="custbtn" (click)="updateBUCampaign()">Update</button>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-5 col-xl-5" *ngIf="showJourneyType">
        <div class="card">
          <div class="card-body">
            <ul  class="campRightUl">
              <li>Welcome Email Message</li>
              <li>Thanks Email Message</li>
            </ul>

            <!-- <ul *ngIf="journeyTypeObj" class="campRightUl">
              <li *ngFor="let obj of journeyTypeObj">{{obj.journey_name}}</li>
            </ul> -->
            <!-- <div *ngIf="journeyTypeObj.length==0">No Result Available</div> -->
          </div>
        </div>
      </div>

    </div>
  </form>
</div>

<!-- Audience Load-->

<div class="container" *ngIf="targetAudience">
  <div class="row ">
    <div class="col-12">

      <div class="row">

        <div class="col-8 ">
          <h5 class="font-weight-bold1 ">Select Target Audience</h5>
        </div>

        <div class="col-4  text-sm-right">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text cyan lighten-2" id="basic-text1">
                <i aria-hidden="true" class="fa fa-search text-white"></i>
              </span>
            </div>
            <input class="form-control" type="text" placeholder="Search" aria-label="Search" [formControl]="querySearchField">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <th> Select Audience</th>
            <th>Audience Name </th>
            <th>Total Contacts</th>
            <th>Create Date</th>
            <th>Scheduling Information</th>
            <th>Frequency</th>
            <th>End Date</th>
          </tr>
          <tr
            *ngFor="let obj of buTargetAudienceObj">
            <td>
              <div>
                <label class="radInnBxLab">
                  <input type="radio" name="keys_on_hand" [title]=" obj.total_count > 10 ? 'We are allowing only 10 or less contacts' :''" [disabled]="getAudCount(obj)" [value]="obj.audid"
                    [(ngModel)]="selectedAud">
                </label>
              </div>
            </td>
            <td title="{{obj.aud_desc}}" [ngClass]="{'aud-desc-color ': obj.aud_desc}">{{obj.aud_name}}</td>
            <td> {{obj.total_count}}</td>
            <td>{{obj.createdon |date}}</td>
            <td>
              <div>{{obj.schedule_start|date}}</div>
              <div>{{obj.schedule_start|date: 'shortTime'}}</div>
            </td>
            <td>{{obj.schedule_interval}}</td>
            <td>
              <div>{{obj.schedule_end|date}}</div>
              <div>{{obj.schedule_end|date: 'shortTime'}}</div>

            </td>
          </tr>
        </table>
       
        <div class="mt-2 text-center difPagination" *ngIf="buTargetAudiencetotal > 10">
          <ngb-pagination [collectionSize]="buTargetAudiencetotal" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="loadTargetAudienceList(page=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination> 
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="text-center my_class " [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
        <button type="button" class="custbtn" (click)="goBackToCampaignFormView()" *ngIf="editStatus">Back</button>
        <button type="button" class="custbtn" (click)="chkAudForCampaign()">Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="col-12" *ngIf="buTargetAudiencetotal == 0 && targetAudience" style="text-align: center">
  <p>No Result Available</p>
</div>