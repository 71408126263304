import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../../../services/license/license.service';
import { AlertService } from '../../../../_services/alert.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../../base.component';
import { ClientService } from '../../../../services/clients/client.service';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-license-bundle-listing',
  templateUrl: './license-bundle-listing.component.html',
  styleUrls: ['./license-bundle-listing.component.css']
})
export class LicenseBundleListingComponent extends BaseComponent implements OnInit {

  cp = 1;
  ord = true;
  colname: "Bundle Name";
  text;
  getCompanyobj: any = [];
  bundleData: any = [];
  selectedCompany=0;
  order: string = '';
  reverse: boolean = true;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
  constructor(private clientService: ClientService,
    private LS: LicenseService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { super(); }

  ngOnInit() {

    if (localStorage.getItem("editbundleId")) {

    localStorage.removeItem("editbundleId");
    localStorage.removeItem("editcompanyID"); 
    }  

    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
    });

 



  }
  loadBundle(id) {

   if(id>0) {

    this.selectedCompany = id;
    this.ngxSpinnerService.show();
    forkJoin(
      {

        bundleData: this.LS.getAllBundles(id, 1000),


      }
    ).subscribe(data => {

      if (data.bundleData.status) {
        this.bundleData = data.bundleData.data;
      }
      this.ngxSpinnerService.hide();

    }), error => {
     // console.log(error);
      this.ngxSpinnerService.hide();

    }
   }else {
    this.bundleData =[];

   }

  }

  editBundle (id){
    
    localStorage.setItem("editbundleId",id);
    localStorage.setItem("editcompanyID",this.selectedCompany.toString());

    this.router.navigate(['/corporate/license-bundle-create']);
  }
  updateBundleStatus(id,status) {

    let obj = {
      company:this.selectedCompany,
      bundle_id:id,
      action:(status)?0:1,
      action_for:'is_active'
    } 

    this.LS.updateBundleStatus(obj).subscribe(data => {
      if(data.status) {
        this.toastrService.success("Bundle Status updated Successfully");
        this.loadBundle(this.selectedCompany);       
      }else {
            this.toastrService.error(data.message);
      }
      
    }, error => {
     // console.log(error);
    });


  }




  deleteBundle(id) {
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to remove bundle?', 'Ok', 'Cancel')
    .then((confirmed) => {
      if (confirmed) {

        this.LS.removeBunble(this.selectedCompany,id).subscribe(data => {
          if(data.status) {
    
            this.toastrService.success("Bundle Removed Successfully");
            this.router.navigateByUrl(`/corporate/license-bundle-listing`);
          }else {
                this.toastrService.error(data.message);
          }
          
        }, error => {
        //  console.log(error);
        });



        // this.ngxSpinnerService.show();
       

      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));






  }


}
