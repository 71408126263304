import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-margoproducts',
  templateUrl: './margoproducts.component.html',
  styleUrls: ['./margoproducts.component.css']
})
export class MargoproductsComponent extends BaseComponent implements OnInit {

  constructor() { super(); }

  projectName = environment.projectName;

  ngOnInit() {
  }

}
