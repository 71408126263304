<!-- Breadcrumb for application -->
<!-- <div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Helps & Tips</li>
    </ol>
  </nav>
</div> -->
<!-- Master Container 1 -->
<div class="container mt-1">

  <div class="row">

    <div class="col-md-6">
      <h5 class="font-weight-bold">Helps & Tips</h5>
        <div class="card">
          <div class="card-body">                
            <div class="row theme-fa-icon">
              <div class="col-md-12">
                  <button class="cbtn mt-1" routerLink="/corporate/tips">
                    <i class="fa fa-graduation-cap orange-dr-icon" aria-hidden="true"></i>Knowledge Base 
                  </button>
              

               <!--<button class="cbtn mt-3" routerLink="/corporate/support">
                  <i class="fa fa-support blue-lt-icon" aria-hidden="true"></i>Contact Support 
                </button>--> 

              </div>  
            </div>
          </div>
        </div>

    </div>

    <div class="col-md-6 mrg-border-left">
      <h5 class="font-weight-bold">Tips for You</h5>
      <div class="card">
        <div class="card-body">  
          <div class="row mt-1">
            <div class="col-md-12">
              <p>When the direct seller selects Help & Tips from the menu, the direct seller will have the opportunity to look for help using the {{projectName}} Knowledge Base or contacting the {{projectName}} support team.
                If the Seller selects the Knowledge Base, they will be redirected to the knowledge base homepage where they can search for help
                </p>
            </div>            
          </div>
        </div>
      </div>

    </div>

  </div>  

</div>

