<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Content Builder Field</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText
           [disabled]="companyID == 0">
      </div>

    </div>


    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0"
        routerLink="/corporate/add-content-builder-field">
      <!--<button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>-->
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2">
      <div>

        <select name="company" class="form-control formstyle" (change)="companyChange($event.target.value)">

          <option value= 0>Select Company Name</option>

          <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
            {{userObj.customer_company_name}}
          </option>
        </select>

      </div>

    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Theme Id</th> -->

        <th (click)="setOrder('field_name')" style="cursor: pointer;">Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Type </th>

        <th (click)="setOrder('createdon')" style="cursor: pointer;"> CreatedOn <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th (click)="setOrder('modifiedon')" style="cursor: pointer;"> ModifiedOn <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let obj of themeObj">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->

        <td>{{obj.field_name}}</td>
        <td>{{obj.field_type}}</td>

        <td>{{obj.createdon | date:'mediumDate' }}</td>
        <td>{{obj.modifiedon | date:'mediumDate' }}</td>

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editField(obj.fieldid)" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteField(obj.fieldid)" placement="bottom"
              ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.fieldid,obj.isactive)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
        (pageChange)="getCompanyField(page = $event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>