<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Attributes</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white" aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

      <select name="company" class="form-control formstyle"  (change)="fetchCompanyAttributes($event.target.value)" *ngIf="companyID === 0">
        <option value="">Select Company Name</option>
        <ng-container *ngFor="let userObj of getCompanyobj" >
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
        <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
          {{userObj.customer_company_name}}
        </option>
        </ng-container>
      </select>

    </div>


    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1" routerLink="/corporate/addattributes">
     <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Theme Id</th> -->

        <th (click)="sorting('attribute_name')" style="cursor: pointer;">Attribute Name <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Attribute Description </th>
        <th (click)="sorting('created_at')" style="cursor: pointer;"> CreatedOn <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let obj of attrList">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->

        <td>{{obj.attribute_name}}</td>
        <td style="white-space: pre-wrap;">{{obj.attribute_description}}</td>
        <td>{{obj.created_at | date:'mediumDate' }}</td>

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">

            <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteAttr(obj.attribute_id)" placement="bottom"
                  ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
                              (click)="updateAttrStatus($event, obj)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getCompanyAttributes((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>
  </div>
</div>
