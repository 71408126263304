import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report/report.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientService } from 'src/app/services/clients/client.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-report-ecom-summary',
  templateUrl: './report-ecom-summary.component.html',
  styleUrls: ['./report-ecom-summary.component.css']
})
export class ReportEcomSummaryComponent implements OnInit {
  reportData: any[] = [];
  companyID:any = 0;
  companyList: any = [];
  currentUser: any;
  showResult:boolean=true;
  isHeadquater;
  
  constructor(private report: ReportService, 
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private clientService:ClientService) { }

  ngOnInit() {
    this.spinner.show();
  //  this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
    this.companyID= customerInfo.admin_customer_id;
    // console.log("this.customerID==",this.customerID)
    //this.companyID = this.customerID;
    this.isHeadquater = localStorage.getItem("customer_isheadquarter");
    if(this.companyID==0 || this.isHeadquater ==="true") {   this.isHeadquater = true; this.companyID =0; this.getAllBU();}
    else   {  this.isHeadquater = false; this.showResult=true;  this.fetchCustomerData(this.companyID); }



   
  }

  getAllBU() {
    this.spinner.show();
    this.clientService.getBUCompanies().subscribe(data => {
    this.companyList = data.data;    
    this.spinner.hide();
    //  console.log(this.companyList);
    }, error => {
      this.spinner.hide();
     // console.log(error);
    });
  }

  fetchCustomerData(id) {
    console.log("ssss.......",id)
    
    if(id !=0 ){
      this.showResult = true;
      this.report.geteCommSummaryReport(id).subscribe(data => {
        if(data.status)
          this.reportData = data.data;
        else
          this.reportData = [];

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
       // console.log(error);
      });
    }
    else this.showResult=false;
  }

  


 
}
