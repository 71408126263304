import {BaseComponent} from '../../../base.component';
import {Component, OnInit} from '@angular/core';
import {SegmentationService} from '../../../../services/segmentation/segmentation.service';
import {BUSegmentationService} from '../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-bu-entities-segmentation',
  templateUrl: './bu-entities-segmentation.component.html',
  styleUrls: ['./bu-entities-segmentation.component.css']
})
export class BuEntitiesSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  entities = [];
  cp = 1;
  ord = true;
  colname: 'entities_name';
  text = '';
  limit = 9999;
  offset = 0;

  constructor(private segmentService: BUSegmentationService) {
    super();
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.getBUEntities();
  }

  //  #############  Get Entities List for BU  ###################  //

  getBUEntities(){
    this.ngxSpinnerService.show();
    this.segmentService.getBUEntitiesListing(this.buid, this.limit, this.offset).subscribe(data => {

      if (data.status) {
        this.entities = data.data;
      } else  {
        this.entities = [];
      }
      this.ngxSpinnerService.hide();
    }, error2 => {
     // console.log(error2);
      this.ngxSpinnerService.hide();
    });
  }

  chgord(col) {
    this.ord = !this.ord;
    this.colname = col;
  }

  //  ##########  Update Status for Entity  ###############  //

  updateStatus(event, obj){
    event.preventDefault();
    let stat = 'Active';
    if (obj.isactive) {
      stat = 'InActive';
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status to ' + stat + '?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {

          let ob = {
            'company': this.buid,
            'id': obj.entity_id,
            'status': !obj.isactive
          };

          this.segmentService.updateBUEntityStatus(ob).subscribe(data => {
            if (data.status) {
              this.text = '';
              this.cp = 1;
              this.getBUEntities();
              this.toastrService.success('Status Changed Successfully');
            } else {
              this.toastrService.error(data.message);
            }

          }, error2 => {
            this.toastrService.error(error2);
           // console.log(error2);
            this.ngxSpinnerService.hide();

          });
        }
      }).catch(() => {
    //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ##############  Delete Entity for BU ###################  //

  deleteEntity(id){
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to the delete the selected Entity?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.segmentService.deleteBUEntity(id, this.buid).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Entity Deleted Successfully');
              this.cp = 1;
              this.text = '';
              this.getBUEntities();
            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          }, error2 => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ################## Edit BU Entity #################  //

  editEntity(id){
    let URL = '/corporate/add-bu-entities' ;


    this.router.navigate([URL], { queryParams: {'entId': id} });

  }

}
