import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base.component';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';


@Component({
  selector: 'app-bu-audience-segmentation-list',
  templateUrl: './bu-audience-segmentation.component.html',
  styleUrls: ['./bu-audience-segmentation.component.css']
})
export class BuAudienceSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  cp = 1;
  ord = true;
  colname: 'audience_name';

  text = '';
  limit = 9999;
  offset = 0;

  audienceObj: any = [];
  constructor(private segmentService: BUSegmentationService) {
    super();
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.getBUAudienceList();
  }

  //  ##########  Get Audience List for Selected BU ################ //

  getBUAudienceList(){
    this.ngxSpinnerService.show();
    this.segmentService.getBUAudienceList(this.limit, this.offset, this.buid).subscribe(data => {

      if (data.status) {
        this.audienceObj = data.data;
      } else  {
        this.audienceObj = [];
      }
      this.ngxSpinnerService.hide();
    }, error2 => {
    //  console.log(error2);
      this.ngxSpinnerService.hide();

    });
  }

  chgord(col) {
    this.ord = !this.ord;
    this.colname = col;
  }

  //  ##############  Update Status for Audience ###################  //

  updateStatus(event, obj) {
    event.preventDefault();
    let stat = 'Active';
    if (obj.isactive) {
      stat = 'InActive';
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status to ' + stat + '?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {

          let ob = {
            'company': this.buid,
            'id': obj.aud_id,
            'status': !obj.isactive
          };

          this.segmentService.updateBUAudience(ob).subscribe(data => {
            if (data.status) {
              this.text = '';
              this.cp = 1;
              this.getBUAudienceList();
              this.toastrService.success('Status Changed Successfully');
            } else {
              this.toastrService.error(data.message);
            }

          }, error2 => {
            this.toastrService.error(error2);
           // console.log(error2);
            this.ngxSpinnerService.hide();

          });
        }
      }).catch(() => {
    //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ##############  Delete Audience for BU  ####################  //

  deleteAudience(auid) {

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to the delete the selected Audience?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.segmentService.deleteBUAudience(auid, this.buid).subscribe(data => {
              if (data.status) {
                this.toastrService.success('Audience Deleted Successfully');
                this.cp = 1;
                this.text = '';
                this.getBUAudienceList();
              } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
              }
            }, error2 => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  #################  Edit BU Audience #######################  //

  editAudience(auid){
    let URL = '/corporate/new-bu-audience' ;


    this.router.navigate([URL], { queryParams: {'audId': auid} });

  }
}
