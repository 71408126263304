import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cb-clone-theme-template-association',
  templateUrl: './cb-clone-theme-template-association.component.html',
  styleUrls: ['./cb-clone-theme-template-association.component.css']
})
export class CbCloneThemeTemplateAssociationComponent extends BaseComponent implements OnInit {
  id;
  getCompanyobj;
  headers = "Add";
  companyID = 0;
  journeyId;
  themeObj = [];
  journeyObj = [];
  templateObj = [];
  submitted = false;
  addForm: FormGroup;
  journeyTypeObj = [];
  //jorneyType:any=[];
  journey_type_id;
  
  addedOrder= [];
  currentUser;
  companyCheck;
  journeyOrderArray = Array;
  totalJourney=5;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    this.controlEnabled = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if(this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;     
    if(this.currentUser.admin_customer_id!='0') this.getCompanyThemes(this.companyID);
    else this.getCompany();
    this.companyCheck =  this.currentUser.admin_customer_id;

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = (params.companyID)?params.companyID:this.companyID;
      });
  
    //get item details using id
    // if (this.id && this.companyID) {
    //   this.ngxSpinnerService.show();
    //   this.headers = "Edit"
    //   this.cb.getThemeTemplateById(this.companyID,this.id).subscribe(response => {
        
    //     if (response.status) {
    //         self.dataBinding(response.data[0]);
    //     } 
    //     else {this.ngxSpinnerService.hide();}
    //   });
    // }
    this.addForm = this.formBuilder.group({
      company: [(this.companyCheck>0)?this.companyCheck:'', Validators.required],
      themeid: ['', Validators.required],
      to_themeid: ['', Validators.required],
      ttaid:['']
    });
  }
  get f() { return this.addForm.controls; }

  // dataBinding(data) {

  //   this.addForm.controls.company.setValue(this.companyID)
  //   this.getCompanyThemes(this.companyID);
  //   this.getCompanyJourneys(data.themeid)
  //   this.addForm.patchValue(data);
  
    

  // }

  // checkThemeID(){
  //   this.f.journeyid.setValue("");
  //   this.f.templateid.setValue("");
  //   this.f.journey_type_id.setValue("");
  //   this.f.journey_order.setValue("");   
  // }

  getCompanyThemes(id){
    if(id) {
    this.companyID = id;
    let self = this;
    this.ngxSpinnerService.show();
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
       // template: this.cb.getCompanyTemplate(id,2),
        theme: this.master.getCompanyTheme(id,9999,  0, "theme_name","asc","", ""),
        //journey: this.cb.getCompanyJourney(id),
     //   journeyType: this.cb.getCBJourneyType(id)
      }
    ).subscribe(data => {
      if(data.theme.status) {
        //  this.ngxSpinnerService.hide();
         // this.templateObj = data.template.data;
           this.themeObj = data.theme.data.rows;
          //this.journeyObj = data.journey.data;
        //  this.journeyTypeObj = data.journeyType.data;
           this.ngxSpinnerService.hide();
          }
          else { this.ngxSpinnerService.hide(); this.themeObj = [];  }
    }), error => {
    //  console.log(error);
     
    }
    }
    else {
      
    }
  }

  
  addTemplate() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    this.addForm.removeControl('ttaid');
   // console.log(this.addForm.value)
    this.ngxSpinnerService.show();
    this.cb.cloneThemeTemplate(this.addForm.value).subscribe(data => {
      this.ngxSpinnerService.hide();
    //  console.log(data);
      if (data.status) {
        this.toastrService.success(data.message);
        this.router.navigateByUrl(`/corporate/cb-theme-template-association`);
      } else {
        this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
    });
  }

  
  
  
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }

  getorder(id) {

    let self = this;
    var obj = {
    company:  this.companyID,
    themeid: this.f.themeid.value,
    journeyid: this.f.journeyid.value,
    templateid:id
    }
    this.cb.getThemeJourneyTemplateOrders(obj).subscribe(data => {
      self.addedOrder = data.data;
    //  console.log(self.addedOrder);
    }, error => {
     // console.log(error);
    });



  }
  
  
}
