import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-addnewprospect',
  templateUrl: './addnewprospect.component.html',
  styleUrls: ['./addnewprospect.component.css']
})
export class AddnewprospectComponent extends BaseComponent implements OnInit {
  // name;
  // Description;
  // Icon;
  // IsActive;
  prospectObjId;
  prospect_id;
  status: boolean = true;
  screenshot_type = true;
  prospect_awskey;
  prospect_icon;
  header = "Add New";

  submitted = false;
  themeForm: FormGroup;

  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.prospect_id = params.prospect_id;
      });
    //get item details using id
    if (self.prospect_id) {
      this.ngxSpinnerService.show();
      this.UpdateProspectLogData();
      this.header = "Edit";
      this.master.getProspectById(this.prospect_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.prospectObjId = response.data;
          self.dataBinding();
    this.ngxSpinnerService.hide();
        } else {
        }
      });
    } else {
      this.addProspectLogData();
    }
    this.themeForm = this.formBuilder.group({
      prospectName: ['', Validators.required],
      prospectDescription: [],
      firstActive: [true],
      prospectIcon: []
    });
  }
  get f() { return this.themeForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  addProspectLogData() {

    let ProspectLogs = {
      log_type: "Adding New Prospect",
      log_name: "Adding New Prospect",
      log_description: "Adding New Prospect",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ProspectLogs).subscribe(data => {
      //console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  UpdateProspectLogData() {

    let ProspectLogs = {
      log_type: "Updating Prospect Data",
      log_name: "Updating Prospect Data",
      log_description: "Updating Prospect Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ProspectLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  dataBinding() {
    // this.name = this.prospectObjId.pname;
    // this.Description = this.prospectObjId.pdescription;
    // this.Icon = this.prospectObjId.picon;
    // this.IsActive = this.prospectObjId.isactive;
    this.prospect_icon = this.prospectObjId.picon;
    if (this.isUrlValid(this.prospectObjId.picon)) {
      this.themeForm = this.formBuilder.group({
        prospectName: [this.prospectObjId.pname, Validators.required],
        prospectDescription: [this.prospectObjId.pdescription],
        firstActive: [true],
        prospectIcon: [this.prospectObjId.picon]
      });
    } else {
      this.themeForm = this.formBuilder.group({
        prospectName: [this.prospectObjId.pname, Validators.required],
        prospectDescription: [this.prospectObjId.pdescription],
        firstActive: [false],
        prospectIcon: []
      });
      this.prospect_awskey = this.prospectObjId.picon;
      this.screenshot_type = false;
    }
  }
  addProspect() {
    this.submitted = true;

    if (this.themeForm.invalid) {
      return;
    }
    let { prospectName, prospectDescription, prospectIcon } = this.themeForm.value;
    prospectIcon = this.isUrlValid(prospectIcon) ? prospectIcon : this.prospect_awskey ? this.prospect_awskey : "";
    let prospectObj = {
      name: prospectName,
      description: prospectDescription,
      icon: prospectIcon
    }
    this.ngxSpinnerService.show();
    this.master.addingProspect(prospectObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Sucessfully")
        this.router.navigateByUrl(`/corporate/prospecttypemaster`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  updateProspect() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { prospectName, prospectDescription, prospectIcon } = this.themeForm.value;
    prospectIcon = this.isUrlValid(prospectIcon) ? prospectIcon : this.prospect_awskey ? this.prospect_awskey : "";
    let prospectObj = {
      pname: prospectName,
      pdescription: prospectDescription,
      picon: prospectIcon,
      isactive: this.prospectObjId.isactive,
      pid: Number.parseInt(this.prospect_id, 10)
    }
    this.ngxSpinnerService.show();
    this.master.updateProspect(prospectObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Updated Successfully")
        this.router.navigateByUrl(`/corporate/prospecttypemaster`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.prospect_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.prospect_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
}
