<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .more-padding-on-right {
  padding-left: 50px!important;
  }
</style>
<!-- <ngx-spinner  bdOpacity = 0.3
bdColor = "#333"
size = "large"
color = "#fff"
type = "ball-clip-rotate">
<p style="color: white" > Loading... </p>
</ngx-spinner> -->
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
        <li class="breadcrumb-item active">Roles</li>
      </ol>
    </nav>
  </div>
  <div class="container mt-2 themeTblReso">
    <div class="row">
      <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn cursor ml-0 mt-md-1" routerLink="/corporate/addnewrolemaster">
    <button class="csvBtn cursor" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn cursor" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>


<!-- <div class="row">
  <div class="col-8 col-xl-8"> -->
<!-- <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div> -->
<!-- </div>
  <div class="col-4 col-xl-4" style="justify-content: right;">
    <input type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/addnewrolemaster">
    <button class="cbtn" (click)="importFile()" placement="bottom" ngbTooltip="Import">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="cbtn" (click)="exportFile()" placement="bottom" ngbTooltip="Export">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div>
<div *ngIf="moduleStatus" class="mt-1 ngxDataTableClass" style="white-space: nowrap;">
  <ngx-data-table [data]="data" style="white-space: nowrap;" (messageEvent)="receiveMessage($event)"></ngx-data-table>
</div>
<div class="row mt-1">
  <div class="col-8 col-xl-8">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
    </div>
  </div>
   <div class="col-12 col-xl-12" style="justify-content: right;">
    <input type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/addnewrolemaster">
    <button class="cbtn" (click)="importFile()" placement="bottom" ngbTooltip="Import">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="cbtn" (click)="exportFile()" placement="bottom" ngbTooltip="Export">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
</div> -->
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="min-width:990px;">
    <tr>
      <th (click)="sorting('role_name')" style="cursor:pointer">Role Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th (click)="sorting('role_description')" style="cursor:pointer">Role Description <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>Parent Role Name</th>
      <!-- <th>CreatedBy</th> -->
      <th> CreatedOn </th>
      <!--  <th>ModifiedBy</th>
      <th>ModifiedOn</th> -->
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>
    <tr *ngFor="let obj of roleObj">
      <td *ngIf="obj.role_parent_id!==0" class ="more-padding-on-right">{{obj.role_name}}</td>
      <td *ngIf="obj.role_parent_id==0"><i class="fa fa-arrow-right mr-2"></i>{{obj.role_name}}</td>
      <td>{{obj.role_description}}</td>
        <td>{{obj.parentrolename }}</td>
      <!-- <td>{{obj.createdname}}</td> -->
      <td>{{obj.createdon | date :'mediumDate' }}</td>
      <!--  <td>{{obj.modifiedname }}</td>
      <td>{{obj.modifiedon |date}}</td> -->

      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
          <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editRole(obj.role_id)"  placement="bottom" ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteRoleData(obj.role_id)" placement="bottom" ngbTooltip="Delete"></span>
          <mat-slide-toggle
            [checked]="obj.isactive" class="my-slider" *ngIf="isEdit"
            (change)="updateStatus($event,obj.role_id)">
        </mat-slide-toggle>
      </div>
    </td>
  </tr>
    </table>
    <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getRoleData((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>

  </div>
</div>
