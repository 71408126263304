import { Component, OnInit, Input, Output,PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { KnowledgeArticleLayoutDetail } from 'src/app/shared/modelClasses/modelClasses';

@Component({
  selector: 'article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit, PipeTransform {
  @Input() articleDetail: KnowledgeArticleLayoutDetail;
  constructor(private sanitized: DomSanitizer) {
    // this.articleDetail1 = this.articleDetail;
  }

  ngOnInit() {
    // this.articleDetail1 = this.articleDetail;
  }
  //Bypass HTML Security
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

}
