<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Facebook</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 theme-fa-icon">
  <legend class="scheduler-border"><h5 class="font-weight-bold">Facebook Sections</h5></legend>
  <fieldset class="scheduler-border">
    
    <div class="row">
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/postmanagement">
          <!-- <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">--> 
          <i class="bi bi-pencil-square red-dr-icon" aria-hidden="true"></i>FB Master Post Library 
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/category">
        <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>FB Category 
      </button>
    </div>
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/msgsnippets">
          <i class="fa fa-envelope-o blue-lt-icon" aria-hidden="true"></i>FB Message Snippets 
        </button>
    </div>
    
    
    </div>
  </fieldset>
</div>


<!-- <div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Facebook Reports</h5></legend>
    
    <div class="row">
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/fbreports">
          <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png">
          <i class="fa fa-file-text-o purple-dr-icon" aria-hidden="true"></i>FB Reports 
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/fbreports">
        <i class="fa fa-file-text-o green-lt-icon" aria-hidden="true"></i>FB Report BU Headquater
      </button>
    </div>
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/fbreports">
          <i class="fa fa-file-text-o blue-fb-icon" aria-hidden="true"></i>FB Reports BU
        </button>
    </div>
    
    
    </div>
  </fieldset>
</div> -->







