import {BaseComponent} from '../../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentationService} from '../../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-add-entities-attributes-association',
  templateUrl: './add-entities-attribute-association.component.html',
  styleUrls: ['./add-entities-attribute-association.component.css']
})
export class AddEntitiesAttributeAssociationComponent extends BaseComponent implements OnInit{

  attributeObj = [];
  attributeid = [];
  entities = [];
  attrList = [];
  attributeForm: FormGroup;
  submitted = false;
  show = false;
  limit = 9999;
  offset = 0;
  assocId;
  constructor(private formBuilder: FormBuilder, private segmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.assocId = params.assocId;
      });
  }

  ngOnInit() {

    this.getEntities();
    this.attributeForm = this.formBuilder.group({
      eid: ['', Validators.required],
      aid: ['']
    });
  }

  get f() { return this.attributeForm.controls; }

  //  ################ Get Values of Association for Edit/Update  ###  //

  getEditAssociation(){
    this.attributeForm = this.formBuilder.group({
      eid: [this.assocId, Validators.required],
      aid: ['']
    });
    this.ngxSpinnerService.hide();
    this.getEntityAttribute(this.assocId);
  }

  //  ################  Get List of Entities  #################  //

  getEntities() {

    this.ngxSpinnerService.show();
    this.segmentService.getEntity(this.limit, this.offset,  'createdon', 'desc').subscribe(data => {

      if (data.status) {
        this.ngxSpinnerService.hide();
        this.entities = data.data.rows;
        this.getList();
      } else  {
        this.ngxSpinnerService.hide();
        this.entities = [];
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ###############  Get Attributes List  #######  //

  getList(){
    this.segmentService.getSegementAttributeList(this.limit, this.offset,  'createdon', 'desc').subscribe(data => {
      if (data.status){
        this.attrList = data.data.rows;
        if (this.assocId){
          this.getEditAssociation();
        } else {
          this.ngxSpinnerService.hide();
        }
      } else {
        this.ngxSpinnerService.hide();
        this.attrList = [];
      }
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }


  //  ##############  Fetch Attributes for Entities       ################  //

  getEntityAttribute(val) {
    this.attributeObj = [];
    this.attributeid = [];
    this.show = false;
    if (val === '') {
      return;
    }

    this.ngxSpinnerService.show();
    this.segmentService.getAssociatedAttributesForEntities(val).subscribe(data => {

      if (data.status) {

        this.show = true;
        this.attributeObj = data.data;
        if (this.attributeObj.length > 0) {
          this.attributeObj.forEach(element => {
            this.attributeid.push(element.field_id);
          });
        }
      } else  {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  //  ##############  Add Entities-Attribute Association  ################  //

  addAttribute() {
    this.submitted = true;
    if (this.attributeForm.invalid) {
      return;
    }
    if (this.attributeid.length === 0) {
      this.toastrService.error('Please select at least one Attribute');
      return;
    }
    let obj = {
      'field_id': this.attributeid,
      'entity_id': this.attributeForm.value.eid
    };

    this.segmentService.addEntityAttributeAssociation(obj).subscribe(data => {
      this.ngxSpinnerService.show();
      if (data.status) {
        this.toastrService.success('Association Updated Successfully');
        this.router.navigateByUrl('/corporate/entities-attribute-association-list');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  On Attribute Selection ############################  //

  selectAttributes(event, id) {

    if (event.target.checked) {
      this.attributeid.push(id);
    } else {
      this.attributeid.splice(this.attributeid.indexOf(id), 1);
    }
  }

  checkForIdInList(id) {
    if (this.attributeid.indexOf(parseInt(id, 10)) >= 0) {
      return true;
    }
    return false;
  }

}
