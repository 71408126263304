import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { MasterService } from 'src/app/services/master/master.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-cb-form-builder-product-clone',
  templateUrl: './cb-form-builder-product-clone.component.html',
  styleUrls: ['./cb-form-builder-product-clone.component.css']
})
export class CbFormBuilderProductCloneComponent extends BaseComponent implements OnInit {
  id;
  getCompanyobj;
  headers = "Add";
  companyID = 0;
  themeObj = [];

  submitted = false;
  addForm: FormGroup;

  addedOrder = [];
  currentUser;
  companyCheck;
  productImgURL;

  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private cb: CbService,
    private upload:UploadService

  ) { super(); }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0') this.getCompanyThemes(this.companyID);
    else this.getCompany();
    this.companyCheck = this.currentUser.admin_customer_id;

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = (params.companyID) ? params.companyID : this.companyID;
      });

    this.addForm = this.formBuilder.group({
      company: [(this.companyCheck > 0) ? this.companyCheck : '', Validators.required],
      themeid: ['', Validators.required],
      file: [0, Validators.required],
      prehost: [0],
      preguest: [0],
      posthost: [0],
      postguest: [0],
    });
  }
  get f() { return this.addForm.controls; }

  getCompanyThemes(id) {
    if (id) {
      this.companyID = id;
      this.ngxSpinnerService.show();
      forkJoin(
        {
          theme: this.master.getCompanyTheme(id, 9999, 0, "theme_name", "asc", "", ""),
        }
      ).subscribe(data => {

        if (data.theme.status) {
          this.themeObj = data.theme.data.rows;
          this.ngxSpinnerService.hide();
        }
        else { this.ngxSpinnerService.hide(); this.themeObj = []; }
      }), error => { }
    }
    else {
      this.f.themeid.setValue("");
      this.themeObj = [];
    }
  }


  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      // console.log(error);
    });
  }

  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    return new Promise<any>((resolve, reject) => {
      this.upload.uploadImage(name,"images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Location) {
              self.productImgURL = params.Location;
              resolve(self.productImgURL);
            }
          }else{
            reject();
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject();
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }


  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  addProductClone() {
    this.submitted = true;
    console.log('productImgURL', this.productImgURL)
    if (this.productImgURL == '' || this.productImgURL == undefined) {
      this.toastrService.error('Please upload product image.');
      return false;
    }
    if (this.addForm.invalid) {
      return;
    }
    let obj = {
      'company': this.companyID,
      'themeid': this.f.themeid.value.trim(),
      'preguest': this.f.preguest.value,
      'prehost': this.f.prehost.value,
      'postguest': this.f.postguest.value,
      'posthost': this.f.posthost.value,
      'productimage': this.productImgURL
    }
    console.log('OBJ', obj);
    this.ngxSpinnerService.show();
    this.cb.productCloneFormBuilder(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(data.message);
        this.router.navigateByUrl(`/corporate/cb-form-builder-list`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
    });
  }
}
