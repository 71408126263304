import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgFlowchart, NgFlowchartCanvasDirective, NgFlowchartStepRegistry } from '@joelwenzel/ng-flowchart';
import { CustomStepComponent } from '../custom-step/custom-step.component';
import { NestedFlowComponent } from '../nested-flow/nested-flow.component';
import { RouteStepComponent } from '../custom-step/route-step/route-step.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-create-journey-flow',
  templateUrl: './create-journey-flow.component.html',
  styleUrls: ['./create-journey-flow.component.css']
})
export class CreateJourneyFlowComponent implements OnInit {

  title = 'workspace';

  callbacks: NgFlowchart.Callbacks = {};
  options: NgFlowchart.Options = {
    stepGap: 40,
    rootPosition: 'TOP_CENTER'
  }

  @ViewChild('normalStep')
  normalStepTemplate: TemplateRef<any>;

  sampleJson = `{
    "root": {
        "id": "s1725872090021",
        "type": "router",
        "data": {
            "name": "Initial Node"
        },
        "children": []
    }
}`;


  items = [
    {
      name: 'Logger',
      type: 'log',
      data: {
        name: 'Log',
        icon: { name: 'log-icon', color: 'blue' },
        config: {
          message: null,
          severity: null
        }
      }
    }
  ]

  customOps = [
    {
      paletteName: 'Drag to Start',
      step: {
        template: CustomStepComponent,
        type: 'router',
        data: {
          name: 'Root'
        }
      }
    },
    // {
    //   paletteName: 'Nested Flow',
    //   step: {
    //     template: NestedFlowComponent,
    //     type: 'nested-flow',
    //     data: {
    //       name: 'Nested Flow'
    //     }
    //   }

    // }
  ]



  @ViewChild(NgFlowchartCanvasDirective)
  canvas: NgFlowchartCanvasDirective;

  disabled = false;
  companyID=0;
  jid=0;
  themeId=0;
  journeyTypeObj=[];
  getCompanyobj=[];
  themeObj=[];
  journeyList: any[];

  constructor(private stepRegistry: NgFlowchartStepRegistry,
    private clientService: ClientService,private ngxSpinnerService:NgxSpinnerService,  private cb: CbService)
    {

    this.callbacks.onDropError = this.onDropError;
    this.callbacks.onMoveError = this.onMoveError;
  }
  ngOnInit(): void {
    this.getCompany();
  }

  ngAfterViewInit() {
    // this.stepRegistry.registerStep('rest-get', this.normalStepTemplate);
    this.stepRegistry.registerStep('log', this.normalStepTemplate);
    this.stepRegistry.registerStep('router', CustomStepComponent);
    this.stepRegistry.registerStep('nested-flow', NestedFlowComponent);
    this.stepRegistry.registerStep('route-step', RouteStepComponent);
    this.onUploadJson();
  }

  onDropError(error: NgFlowchart.DropError) {
    console.log(error);
  }

  onMoveError(error: NgFlowchart.MoveError) {
    console.log(error);
  }

  showUpload() {
    this.canvas.getFlow().upload(this.sampleJson);
  }

  showFlowData() {

    let json = this.canvas.getFlow().toJSON(4);

    var x = window.open();
    x.document.open();
    x.document.write('<html><head><title>Flowchart Json</title></head><body><pre>' + json + '</pre></body></html>');
    x.document.close();

  }

  clearData() {
    this.canvas.getFlow().clear();
  }

  onGapChanged(event) {

    this.options = {
      ...this.options,
      stepGap: parseInt(event.target.value)
    };
  }

  onSequentialChange(event) {
    this.options = {
      ...this.options,
      isSequential: event.target.checked
    }
  }

  onDelete(id) {
    this.canvas.getFlow().getStep(id).destroy(true);
    console.log('onDelete called');

  }
  onUploadJson() {
    this.canvas.getFlow().upload(this.sampleJson)
  }
  onCompanyChange(id) {
    this.journeyTypeObj = [];
    this.journeyList = [];
    if(id) {
        let self = this;
        this.ngxSpinnerService.show();  
        this.cb.getCBJourneyType(id).subscribe(data => {
          if( data.data) {
          self.journeyTypeObj = data.data;
          this.ngxSpinnerService.hide();
          }
          else {
             self.journeyTypeObj = []; 
             this.ngxSpinnerService.hide(); }
        }, error => {
          console.log(error);
          this.ngxSpinnerService.hide();
        });
      }
      else {
        this.journeyTypeObj=[];
        this.ngxSpinnerService.hide();
      }
}
getCompanyJourneyThemeList(jid:any){
  this.themeObj=[];
  this.ngxSpinnerService.show();
  this.clientService.getThemeByCompanyJourney(this.companyID,jid).subscribe(data => {
    if (data.status && data.data.rows) {
      this.themeObj = data.data.rows;
      this.ngxSpinnerService.hide();
    } else {
      this.themeObj = [];
      this.ngxSpinnerService.hide();
    }
  }, error => {
    this.themeObj = [];
      console.log(error);
    this.ngxSpinnerService.hide();
  });
}
  getCompany() {
        let self = this;
        this.clientService.getBUCompanies().subscribe(data => {
          self.getCompanyobj = data.data;
          console.log(self.getCompanyobj);
        }, error => {
          console.log(error);
        });
      }
}
