<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/roles">Roles</a></li>
      <li class="breadcrumb-item active">New Role Assignment</li>
    </ol>
  </nav>
</div> 
<div class="container">
  <div class="row">
    <div class="col-md-10 col-xl-7">
      <div class="card mt-1">
        <div class="card-body">
          <label>Role Name <span class="required">*</span></label>
          <select type="text" class="form-control formstyle" [(ngModel)]="roledata" required
            (change)="appendingCheckbox(roledata)">
            <option *ngFor="let Obj of getRoleObj" value={{Obj.role_id}}> {{Obj.role_name}}
            </option>
          </select>
          <label class="mt-1" *ngIf="musicPreferences2.length>0">Child Role List <span class="required">*</span></label>
          <div class="d-inline-block">
            <div *ngFor='let item of musicPreferences2' class="float-left mt-1">
              <li class="custom-control custom-checkbox mr-1">
                <label class="form-check-label" (change)='changeCheckForRole(item.role_id)'>
                  <input class="form-check-input" type="radio" id="tag{{item.role_id}}" name="tagOptions">
                  {{item.role_name}}
                </label>
              </li>
            </div>
          </div>
          <label class="mt-1">Module Name  <span class="required">*</span></label>
          <!-- <select type="text" class="form-control formstyle mt-1" [(ngModel)]="module" required
            (change)="appendingCheckboxForModule(module)">
            <option *ngFor="let obj of getModuleObj" value={{obj.id}}>
              {{obj.name}}
            </option>
          </select> -->
          <select type="text" class="form-control formstyle mt-1" [(ngModel)]="module" required
            (change)="appendingCheckboxForModule(module)">
            <option value="" selected disabled>--Select--</option>
            <ng-container *ngFor="let group of finalModuleArr">
              <option *ngIf="group.data.length==0" [value]="group.id">{{group.title}}</option>
              <optgroup *ngIf="group.data.length > 0" label="{{group.title}}">
                <option *ngFor="let item of group.data" value={{item.id}}>{{item.name}}</option>
              </optgroup>
            </ng-container>
          </select>
          <label class="mt-1">Sub Modules <span class="required">*</span></label>
          <div class="d-inline-block">
            <div *ngFor='let item of moduledataObj' class="float-left mt-1">
              <div class="custom-control custom-checkbox mr-1">
                <label class="form-check-label" (change)='changeCheckForModule(item.moduleitemid)'>
                <input class="form-check-input" type="checkbox" id="tag{{item.moduleitemid}}" name="tagOptions" [disabled]="item.status">
                {{item.module_item_name}}
                </label>
              </div>
            </div>  
          </div>    
          <!-- <div *ngIf='showAfterValue'> -->
          <label class="mt-1">Permissions <span class="required">*</span></label>
          <div class="d-inline-block">
            <div *ngFor='let item of getPermissionObj'  class="float-left mt-1">
              <div class="custom-control custom-checkbox mr-1">
                <label class="form-check-label" (change)='changeCheckForPermissions(item.per_id)' style="white-space: nowrap;">
                  <input class="form-check-input" type="checkbox"
                  [(ngModel)]='item.checkedvalue' id="tag{{item.per_id}}" name="tagOptions">
                  {{item.per_name}}
                </label>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="card-footer text-center">
          <button class="custbtn" appThrottleClick [throttleTime]="3000"
          (throttledClick)="addNewRole()">submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
                     
      