import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
@Component({
  selector: 'app-addnewmodule',
  templateUrl: './addnewmodule.component.html',
  styleUrls: ['./addnewmodule.component.css']
})
export class AddnewmoduleComponent extends BaseComponent implements OnInit {
  moduleObj1;
  // parentid;
  // name;
  // description;
  moduleid;
  moduleObjId;
  // isactive = true;
  // menu_id;
  // meunObjId;
  status: boolean = true;
  header = "Add New"

  moduleForm: FormGroup;
  submitted = false;
  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder
  ) {
    super();
   }

  ngOnInit() {
    this.getModuledropdown();
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.moduleid = params.moduleid;
      });
    //get item details using id
    if (self.moduleid) {
      this.UpdateModuleLogData();
      this.header = "Edit"
      this.ngxSpinnerService.show();
      this.master.getModuleById(this.moduleid).subscribe(response => {
      
        if (response.status) {
          this.status = false;
          self.moduleObjId = response.data;
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {
        }
        
      });
    } else {
      this.addModuleLogData();
    }
    this.moduleForm = this.formBuilder.group({
      moduleName: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      moduleDescription: [],
      moduleParentid: [0, Validators.required],
      isactive: [true]
    });
  }
  get f() { return this.moduleForm.controls; }
  addModuleLogData() {

    let ModuleLogs = {
      log_type: "Adding New Module",
      log_name: "Adding New Module",
      log_description: "Adding New Module",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ModuleLogs).subscribe(data => {
     // console.log(data);
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    //  console.log(error);
    });

  }
  UpdateModuleLogData() {

    let ModuleLogs = {
      log_type: "Updating Module Data",
      log_name: "Updating Module Data",
      log_description: "Updating Module Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ModuleLogs).subscribe(data => {
    //  console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  addModule() {
    this.submitted = true;
    
    if (this.moduleForm.invalid) {
      return;
    }
  //  console.log(this.moduleForm);

    let { moduleName, moduleDescription, moduleParentid, isactive } = this.moduleForm.value;
    let moduleBody = {
      parentId: moduleParentid,
      moduleName: moduleName,
      moduleDesc: moduleDescription,
      isactive: isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingModule(moduleBody).subscribe(data => {
     
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl('/corporate/module');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    //  console.log(error);
    });

  }
  dataBinding() {
    // this.name = this.moduleObjId.module_name;
    // this.description = this.moduleObjId.module_description;
    // this.parentid = this.moduleObjId.module_parent_id;
    // this.isactive = this.moduleObjId.isactive;
    this.moduleForm = this.formBuilder.group({
      moduleName: [this.moduleObjId.module_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(30)]],
      moduleDescription: [this.moduleObjId.module_description],
      moduleParentid: [this.moduleObjId.module_parent_id, Validators.required],
      isactive: [this.moduleObjId.isactive],
    });

  }
  getModuledropdown() {
    let self = this;
    let body = {
      "pid": 0,
      "limit": 10,
      "skip": 0
    }
    this.master.getModuledropdown().subscribe(data => {
      self.moduleObj1 = data;
     
    }, error => {
     // console.log(error);
    
      // this.alertService.error(error);
    });
  }
  updateModule() {
    this.submitted = true;
    if (this.moduleForm.invalid) {
      return;
    }
   

    let { moduleName, moduleDescription, moduleParentid, isactive } = this.moduleForm.value;
    let moduleBody = {
      module_name: moduleName,
      module_description: moduleDescription,
      module_parent_id: moduleParentid,
      isactive: isactive,
      moduleid: Number.parseInt(this.moduleid, 10)
    }
   // console.log("******", moduleBody);
    this.ngxSpinnerService.show();
    this.master.updateModule(moduleBody).subscribe(data => {
      
      if (data.status) {
        this.toastrService.success("Updated Successfully")
        
        this.router.navigateByUrl(`/corporate/module`);
        this.ngxSpinnerService.hide();
      }
      else {
        this.toastrService.error(data.message)

      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
