import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-update-bu-entities-segmentation',
  templateUrl: './update-entities-bu-segmentation.component.html',
  styleUrls: ['./update-entities-bu-segmentation.component.css']
})
export class UpdateEntitiesBuSegmentationComponent extends BaseComponent implements OnInit{

  audid: any;
  adid: any;
  buselected = '';
  buid: any;
  entList = [];
  entIds = [];
  entBUList = [];
  show = false;
  constructor(private segmentService: BUSegmentationService, private masterSegmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.adid = params.auid;
        this.audid = params.audid;
      });
  }
  ngOnInit() {
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
    if (this.adid && this.audid) {
      this.ngxSpinnerService.show();
      this.getMasterEntitiesById();
    } else {
      this.router.navigateByUrl('/corporate/bu-segmentation');
    }
  }

  //  #################  Get Entities list by Audience ID (Master) #############  //

  getMasterEntitiesById(){
    this.masterSegmentService.getAssociatedAudienceForEntities(this.audid).subscribe(data => {
      if (data.status) {
        this.entList = data.data;
        this.getEntityforBUAudience(this.adid);
      } else {
        this.ngxSpinnerService.hide();
        this.entList = [];
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ####################  Fetch BU Entities List  ####################  //

  getEntityforBUAudience(auid){
    this.entBUList = [];
    this.segmentService.getEntitiesListforBUbyAudienceID(this.buid, auid).subscribe(data => {
      if (data.status) {
        this.entBUList = data.data;
        if (this.entBUList.length > 0) {
          this.entBUList.forEach(element => {
            this.entIds.push(element.bu_entity_id);
          });
        }
        this.show = true;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.entBUList = [];
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############  Check Entities from List ##################  //

  selectEntity(event, id){
    let elem = this.entList.filter(element => element.entity_id === id);
    let i = elem[0].entity_id;
    if (event.target.checked) {
      this.entIds.push(i);
    } else {
      this.entIds.splice(this.entIds.indexOf(i), 1);
    }
  }

  //  ###################  Update Entities for BU ##########################  //

  updateEntity(){
    if (this.entIds.length === 0) {
      this.toastrService.error('Please select at least one Entity');
      return;
    }
    let obj = {
      'audience_id': this.audid,
      'company': this.buid,
      'entity_id': this.entIds
    };
    this.ngxSpinnerService.show();
    this.segmentService.updateEntitiesForBU(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Entities updated Successfully');
        this.router.navigateByUrl('/corporate/bu-segmentation');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }
}
