<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/countrymaster">Country</a></li>
            <li class="breadcrumb-item active">Add New Country</li>
        </ol>
    </nav>
</div>
<div class="container mt-1 mt-md-2">
    <form class="form-horizontal mt-1" [formGroup]="countryForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <div class="container mt-1 mt-md-2">
                        <label for="exampleForm2">Country Name <span style="color:red">*</span></label>
                        <input type="text" id="exampleForm2" formControlName="countryName"
                            class="form-control form-control-md"
                            [ngClass]="{ 'is-invalid': submitted && f.countryName.errors }" required>
                        <div *ngIf="submitted && f.countryName.errors" class="invalid-feedback">
                            <div *ngIf="f.countryName.errors.required">Country Name is required</div>
                        </div>
                    </div>
                    <div class="mt-1">
                        <label for="exampleForm2">Country Code</label>
                        <input type="text" id="exampleForm2" formControlName="countryCode"
                            class="form-control form-control-md">
                    </div>
                    <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                        <button type="submit" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewCountry()">Submit</button>
                    </div>
                    <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                        <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updateCountry()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>