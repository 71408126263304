import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import {CommonMessages} from 'src/app/shared/messages/messages.enum';
import { FbService } from 'src/app/services/fbservice/fb.service';
@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {

  p = 1
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();
  public campaignObj: any = [];
  constructor(private fb: FbService,
    private router: Router,
    private logs :LogsService,
    private toastrService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private ImportFilesDialogService: ImportFilesDialogService
  ) { }
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
    this.getCampaignData();
    this.CampaignLogData()
  }
  CampaignLogData() {
    let CampaignLogs = {
      log_type:"Campaign View" ,
      log_name:"Campaign View" ,
      log_description:"Campaign View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(CampaignLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
    //  console.log(error);
    });
  
}
  getCampaignData() {
    let self = this;
    this.fb.getCampaign().subscribe(data => {
    //  console.log(data);
      
      self.campaignObj = data.data;
    }, error => {
     // console.log(error);
    });
  }

  editCampaign(id) {    
    this.router.navigate(['/corporate/addnewcampaign'], { queryParams: { campaign_id: id } });
  }

  deleteCampaign(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm(CommonMessages.confirmationTitle, CommonMessages.activeInactiveConfirmation, 'Delete', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.fb.delCampaign(obj).subscribe(data => {
            if (data.status) {
              this.getCampaignData();
                this.toastrService.success("Deleted Successfully");
            } else {
            }
          }, error => {
            this.toastrService.error(error.message);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  updateStatus(event, id) {
    event.preventDefault();
    // let value = event.source.checked;
    let obj = this.campaignObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.get_fb_campaign_fun.campaign_id === id) {
        return true;
      }
    });
    obj = obj.get_fb_campaign_fun;

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          obj.isactive = !obj.isactive;
          this.fb.updateCampaign(obj).subscribe(data => {
            this.toastrService.success("Status Updated Successfully")
            this.getCampaignData();
          });
        }
        else {

        }
      }).catch(() => {
        //console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  
}
