<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>

  <div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
        <li class="breadcrumb-item active">Currency</li>
      </ol>
    </nav>
</div> 

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
          aria-hidden="true"></i></span>
    </div>
    <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
  </div>
</div>
<div class="col-md-4" style="justify-content: right;">
  <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/addnewcurrency">
  <button class="csvBtn" (click)="importFile()" *ngIf="isImport">
    <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
  </button>
  <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
    <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
  </button>
</div>
</div>

<div class="tblPrt">

<table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Currency Id</th> -->
        <th>Currency Image</th>
        <th (click)="chgord('subc1')" style="cursor: pointer;">Currency Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Currency Symbol</th>
        <th>Currency Description</th>
        
        <th>Created By</th>
        <th (click)="chgord('subc1')" style="cursor: pointer;">Created On <i class=" fa fa-sort text-black"
            aria-hidden="true"></i> </th>
        
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>
    
      <tr
        *ngFor="let obj of currencyObj | filter:text | orderBy:colname:ord | paginate: { itemsPerPage: 10, currentPage: p }">
        <!-- <td>{{obj.get_master_currency.currency_id}}</td> -->
        <td><img [src]="obj.get_master_currency.currency_image" alt="Not Found" (error)="setValue($event)"  style="height:50px;"></td>
        <td>{{obj.get_master_currency.currency_name}}</td>
        <td>{{obj.get_master_currency.currency_symbol}}</td>
        <td>{{obj.get_master_currency.currency_description}}</td>
      
        <!-- <td>{{obj.get_master_currency.currency_image}}</td> -->
        
        <td>{{obj.get_master_currency.createdname}}</td>
        <td>{{obj.get_master_currency.createdon |date:'medium'}}</td>
        
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
          <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editCurrency(obj.get_master_currency.currency_id)" placement="bottom" ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteCurrency(obj.get_master_currency.currency_id)" placement="bottom" ngbTooltip="Delete"></span>
          <mat-slide-toggle class="my-slider" [checked]="obj.get_master_currency.isactive" *ngIf="isEdit"
            (click)="updateStatus($event,obj.get_master_currency.currency_id)">
          </mat-slide-toggle>
        </div>
        </td>
      </tr>

  </table>
  <pagination-controls class="my-pagination" (pageChange)="p = $event"></pagination-controls>

</div>