import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MasterService} from '../../../services/master/master.service';
import {OrderPipe} from 'ngx-order-pipe';
import {ClientService} from '../../../services/clients/client.service';
import {BaseComponent} from '../../base.component';
import {ImportFilesDialogService} from '../../../_utils/import-files-dialog/import-files-dialog.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';


@Component({
    selector: 'app-attributes',
    templateUrl: './attribute.component.html',
    styleUrls: ['./attribute.component.css']
})
export class AttributeComponent extends BaseComponent implements OnInit {

  public themeObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "attribute_name";
  checked = false;
  getCompanyobj;
  currentUser;
  companyID=0;
  attrList: any = [];
  BUID = 0;
  shwSearch: boolean = false;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(private master: MasterService,
              private ImportFilesDialogService: ImportFilesDialogService,
              private orderPipe: OrderPipe,
              private clientService: ClientService
  ) {
    super();
  }
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    if (this.currentUser.admin_customer_id != '0') {  
      this.companyID = this.currentUser.admin_customer_id; 
      this.BUID = this.currentUser.admin_customer_id;
      this.getCompanyAttributes(); 
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getAttributes(this.BUID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.attrList = [] }
          else { this.attrList = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.attrList = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getCompanyAttributes();
  }


  //  ###############  Get Companies List ####################  //

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
    //  console.log(error);
    });
  }

  //  ############ Get Attributes on Dropdown select ##############  //

  getCompanyAttributes() {
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getAttributes(this.BUID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(res => {
      if (res.status) {
        if (res.data.rows == null) { this.attrList = [] }
        else { this.attrList = res.data.rows }
        this.totalCount = res.data.total;
      } else {
        this.attrList = [];
        this.totalCount = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  fetchCompanyAttributes(val) {
    if(val != ''){
      this.BUID = val;
      this.getCompanyAttributes();
    }else{
      this.attrList = [] ;
      this.totalCount = 0 ;
      this.cp = 1;
    }
  }

  //  ######################  Delete Attribute ############################  //

  deleteAttr(id) {

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete the Attribute ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let cid = 0;
          if (this.companyID === 0) {
            cid = this.BUID;
          } else {
            cid = this.companyID;
          }
          this.master.delAttr(cid, id).subscribe(data => {
            if (data.status) {
              this.getCompanyAttributes();
              setTimeout(() => {
                this.toastrService.success('Attribute Deleted Successfully');
              }, 1000);
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  //  #####################  Update Attribute Status  ####################  //

  updateAttrStatus(event, attr) {
    event.preventDefault();
    let cid = 0;
    if (this.companyID === 0) {
      cid = this.BUID;
    } else {
      cid = this.companyID;
    }
    let obj = {
      id: attr.attribute_id,
      status: !attr.isactive,
      company: cid,
      customerid: this.companyID
    };

    let body = JSON.stringify(obj);

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the Attribute Status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.updAttr(body).subscribe(data => {
            if (data.status) {
              this.getCompanyAttributes();
              setTimeout(() => {
                this.toastrService.success('Attribute Updated Successfully');
              }, 1000);
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
          //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  exportFile() {
   let company = this.BUID;
   if (company !== 0) {
      let tablename = {
        "tableName": "customer_attributes",
        "cid": company
      }
      //return console.log('tablename', tablename);
      this.master.exportBulk(tablename).subscribe(dataFile => {
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
   } else {
     this.toastrService.error('Please Select The Company!');
   }
  }

  importFile() {
    let self = this;
    let company = this.BUID;
    let cid = 0;
    if (this.companyID === 0) {
      cid = this.BUID;
    } else {
      cid = this.companyID;
    }
    if (company !==0) {
      self.ImportFilesDialogService.confirm1('Please Choose the File Path ', `../../../assets/margo-import-files/margo-customer-attribute-import.csv`, 'addingAttributeBulk', company)
        .then(data => {
          if(data) {
            this.getCompanyAttributes();
          }
        })
        .catch(err => {
          console.error(err);
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }
}



