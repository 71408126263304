<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <!--<li class="breadcrumb-item" ><a routerLink="/corporate/notes">Add Notes</a></li>-->
      <li class="breadcrumb-item active" aria-current="page">Notes List</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group" *ngIf="shwSearch">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                                                                            aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

      <select *ngIf="companyID === 0" name="company" class="form-control formstyle"  (change)="getCompanyNotes($event.target.value)">
        <option value="">Select Company Name</option>
        <ng-container *ngFor="let userObj of getCompanyobj" >
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
        <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
          {{userObj.customer_company_name}}
        </option>
        </ng-container>
      </select>

    </div>




    <div class="col-md-4" style="justify-content: right;" *ngIf="selectedCompany==null">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/notes">

    </div>
  </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Theme Id</th> -->
       
        <th >Notes Length</th>
        <th> Date and Time </th>
        <!--<th >Time</th>
        <th>Is Active</th>-->

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngIf="selectedCompany">
        <!-- <td>{{obj.get_master_theme.theme_id}}?companyID={{selectedCompany.company_id}}</td> -->
   
        <td>{{selectedCompany.note_length}}</td>
        <td >{{selectedCompany.created_at | date:'mediumDate' }}</td>
        <!--<td>{{selectedCompany.created_at |date: 'shortDate' | date:'shortTime' }}</td>
        <td>{{selectedCompany.isactive}}</td>-->

        <td *ngIf="isEdit || isDelete">
          <div >
            <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" routerLink="/corporate/notes" [queryParams]="{ companyID: selectedCompany.company_id}"  placement="bottom" ngbTooltip="Edit"></i>
            <mat-slide-toggle class="my-slider" [checked]="selectedCompany.isactive" *ngIf="isEdit"
                              (click)="updateNoteStatus($event)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <div style="text-align: center;" *ngIf="!selectedCompany">
      <p>No Result Available</p>
    </div>
    <pagination-controls *ngIf="(companyNoteConf | filter:text).length !== 0" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls>
  </div>
</div>
