import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';

@Component({
  selector: 'app-event-invitee',
  templateUrl: './event-invitee.component.html',
  styleUrls: ['./event-invitee.component.css']
})
export class EventInviteeComponent extends BaseComponent implements OnInit {
  eventId:any;
  companyId: any;
  userData: any;
  eventObj: any;
  inviteeCount:any;
  constructor(
    private shopliveService: ShopLiveService
  ) { 
    super();
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.userData.admin_customer_id;
   }

  ngOnInit(): void {
    this.eventId = sessionStorage.getItem('eventId');
    this.inviteeCount = sessionStorage.getItem('eventinviteecount');
    this.getInvitee();
  }
  getInvitee(){
    this.ngxSpinnerService.show();
    this.shopliveService.getEventInviteeDetail(this.companyId,this.eventId).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      if(res.status){
        this.eventObj =  res.data.rows;
      }
      else{
        this.eventObj = [];
      }
    })
  }
  onBack(){
    this.location.back();
  }
}
