<div class="container">
    <div class="row">
        <div class="col-3 mb-2">
            <h2 class="mainHeading_1">Overview</h2>
        </div>

        <div class="col-9 mb-2 text-right">
            <form [formGroup]="form">
                <ul class="dayMonthM">
                    <li>
                        <a [class.activeMonth]="selectedIndex === 'day'" (click)="getFilterByTab('day')">Day</a>
                    </li>
                    <li>
                        <a [class.activeMonth]="selectedIndex === 'week'" (click)="getFilterByTab('week')">Weeks</a>
                    </li>
                    <li>
                        <a [class.activeMonth]="selectedIndex === 'month'" (click)="getFilterByTab('month')">Months</a>
                    </li>
                </ul>

                <div class="dateTimeM calRgt">
                    <input type="text" ngxDaterangepickerMd formControlName="selected" class="form-control"
                        placeholder="Choose date"   [locale]="{applyLabel: 'ok', format: 'YYYY-MM-DD'}" [showDropdowns]="true" (change)="getFilterByCalendar()"/>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Contact(internal)</span>
                            <span class="ovValue">{{ dsReportList.contact_ds_tbl }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/dollarIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Contact(external)</span>

                            <span class="ovValue">{{
                                dsReportList.external_contact_tbl
                                }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/userIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Contact(data warehouse)</span>
                            <span class="ovValue">{{
                                dsReportList.data_warehose_contact
                                }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/reloadIcon.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Payment(internal)</span>

                            <span class="ovValue">${{ dsReportList.purchase_data_tbl }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/dollarIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Payment(external)</span>

                            <span class="ovValue">${{ dsReportList.external_purchase_data_tbl }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"

                                        src="assets/custom/images/DashboardImages/userIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Payment(data warehouse)</span>
                            <span class="ovValue">${{
                                dsReportList.data_warehose_payment
                                }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/reloadIcon.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Abandoned Cart(internal)</span>
                            <span class="ovValue">{{ dsReportList.abandoned_cart_data_tbl }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/dollarIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Abandoned Cart(external)</span>

                            <span class="ovValue">{{
                                dsReportList.external_abandoned_cart_data_tbl
                                }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/userIconImg.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="overBxInn">
                        <div class="ovBxInnTxt">
                            <span class="ovBxTitle">Abandoned Cart(data warehouse)</span>
                            <span class="ovValue">{{
                                dsReportList.data_warehouse_abandoned_cart
                                }}</span>
                        </div>
                        <div class="ovBxInnIcon">
                            <div class="righArrwIcon">
                                <a>
                                    <img class="iconRightOver"
                                        src="assets/custom/images/DashboardImages/reloadIcon.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-2">
                <div class="overBxInn">
                    <div class="ovBxInnTxt">
                        <p class="dsr-heading">Complete Profile Information</p>

                        <div class="dsr-info">
                            <p *ngIf="dsReportList.userdata?.role_id == '76'">
                                <b>User Type:</b> Direct Seller
                            </p>
                            <p *ngIf="dsReportList.userdata?.role_id == '77'">
                                <b>User Type:</b> Host
                            </p>
                            <p><b>First Name:</b> {{ dsReportList.userdata?.user_fname }}</p>
                            <p><b>Last Name:</b> {{ dsReportList.userdata?.user_lname }}</p>
                            <p>
                                <b>Email ID:</b> {{ dsReportList.userdata?.user_email_primary }}
                            </p>
                            <p><b>Is Independent DS:</b> {{ dsReportList.userdata?.is_independent_ds == true ? 'YES' : 'NO'}}</p>
                            <p><b>Address:</b> {{ dsReportList.userdata?.address }}</p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-8 mb-4" *ngIf="dsReportList.userdata?.isactive == false">
                <button class="btn btn-dsr-active" type="button" (click)="updateDSUser()">
                    Active
                </button>
            </div>
        </div>
    </div>
</div>
