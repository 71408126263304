import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {CampaignBuilderService} from '../../../services/segmentation/campaign-builder.service';
import {AudienceBuilderService} from '../../../services/segmentation/audience-builder.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../services/clients/client.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MasterService} from '../../../services/master/master.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-schedule-campaign-builder',
  templateUrl: './schedule-campaign-builder.component.html',
  styleUrls: ['./schedule-campaign-builder.component.css']
})
export class ScheduleCampaignBuilderComponent extends BaseComponent implements OnInit, OnDestroy {
  audId: any;
  jid: any;
  campId: any;
  isprod: boolean;
  companyId: any;
  audData: any;
  journeyObj = [];
  selectedJourney: any;
  buCampaignObj = [];
  campaignObj: any;
  limit = 12;
  offset = 0;
  prodList = [];
  selectedProdId = 0;
  selectedProduct: any;
  product_thumb_image = '';
  themeData = [];
  panel = 0;
  theme_id: any;
  selectedTheme: any;
  timezones = [];
  scheduleForm: FormGroup;
  minDate = new Date();
  submitted = false;
  selectedUTC: any;
  selectedUTCtext: any;
  productForModal: any;
  stTime = { hour: 0, minute: 0 };
  subscription: any;
  text = '';
  cp = 1;
  totProds = 0;
  fullProdList = [];
  queryField: FormControl = new FormControl();
  constructor(private campaignBuilderService: CampaignBuilderService,
              private audienceBuilderService: AudienceBuilderService,
              private formBuilder: FormBuilder,
              private clientsService: ClientService,
              private master: MasterService,
              private modalService: NgbModal) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
    this.subscription = this.activatedRoute.queryParams
      .subscribe(params => {
        this.audId = parseInt(params.audid, 10);
        this.jid = parseInt(params.jid, 10);
        this.campId = parseInt(params.campId, 10);
        if (params.isprod === 'true'){
          this.isprod = true;

          //   Get Full Products List  //
                    // this.clientsService.getCompanyProductList(this.companyId).subscribe(data => {
                    //   if (data.status) {
                    //     this.fullProdList = data.data;
                    //   } else {
                    //   this.fullProdList = [];
                    //   this.toastrService.error(data.message);
                    //   this.ngxSpinnerService.hide();
                    //   this.router.navigateByUrl('/corporate/campaign-builder');
                    //   }
                    // }, error => {
                    //  // console.log(error);
                    //  this.toastrService.error(error);
                    //  this.ngxSpinnerService.hide();
                    //  this.router.navigateByUrl('/corporate/campaign-builder');
                    // });
        } else {
          this.isprod = false;
        }

      });

    if (!this.audId || !this.jid || !this.campId){
      this.router.navigateByUrl('/corporate/campaign-builder');
    } else {
      this.fetchData();
    }
  }

  ngOnInit() {

    this.scheduleForm = this.formBuilder.group({
      scheduleType: ['true', Validators.required],
      scheduleStartDate: ['', Validators.required],
      tzone: ['', Validators.required],
      schltm: ['1', Validators.required],
      ampm: ['AM', Validators.required]
    });

    this.queryField.valueChanges
      .pipe(
        map((query) => query),
        debounceTime(1500),
        distinctUntilChanged()
      )
      .subscribe(query => {
        this.ngxSpinnerService.show();
        this.cp  = 1;
        this.prodList = [];
        this.totProds = 0;
        this.offset = 0;
        this.getProductList();
      });

  }

  //  #############  Fetch Data ######################  //

  fetchData(){

    this.ngxSpinnerService.show();
    this.audienceBuilderService.getAUDDataByID(this.companyId, this.audId).subscribe(data => {
      if (data.status) {
        this.audData = data.data[0];
      //  console.log('AUD', this.audData);
        this.getJourneyList();
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
      }

    }, error => {
     // console.log(error);
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }

  get f() { return this.scheduleForm.controls; }

  getJourneyList() {

    this.campaignBuilderService.getSegementJourney(this.companyId).subscribe(data => {

      if (data.status) {

        this.journeyObj = data.data;
        let jrny = this.journeyObj.filter(elem => elem.journey_id === this.jid);
        this.selectedJourney = jrny[0];
        this.getCampaignData();
      } else  {
        this.journeyObj = [];
        this.ngxSpinnerService.hide();
      }
    }, error => {
    //  console.log(error);
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });

  }

  getCampaignData(){
    this.campaignBuilderService.getBUCampaignById(this.companyId, this.campId).subscribe(data => {
      if (data.status) {

        this.campaignObj = data.data;
      //  console.log('CAMP DATA', this.campaignObj);
        if (this.isprod === true){
          this.getProductList();
        } else {
          this.selectedProdId = 0;
          if (this.campaignObj && this.campaignObj.theme_id) {
            this.theme_id = this.campaignObj.theme_id;
          }
          this.getThemeData();
        }

      } else  {
        this.campaignObj = [];
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //   ############### Get Company Product List ##################  //

  getProductList() {

    this.clientsService.getProductListByserach(this.companyId, this.limit, this.offset, this.text).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.hide();
        this.prodList = data.data;
        this.totProds = data.total;
      //  console.log('CAMP OBJ', this.campaignObj);
        if (this.campaignObj && this.campaignObj.product_id) {
          this.selectedProdId = this.campaignObj.product_id;
        }
        this.panel = 1;
      } else {
       this.prodList = [];
       this.totProds = 0;
       this.toastrService.error('No Products Available');
       this.ngxSpinnerService.hide();
      }
    }, error => {
    //  console.log(error);
    this.prodList = [];
       this.totProds = 0;
    this.toastrService.error(error);
    this.ngxSpinnerService.hide();
    });
  }

  //   #################  On Page Change ###################  //

  getProds(page) {
    this.cp = page;
    this.offset = this.cp - 1;
    this.ngxSpinnerService.show();
    this.getProductList();
  }

  //   #################  Get Theme Data  ##################  //

  getThemeData(){
    this.campaignBuilderService.getThemes(this.selectedProdId, this.jid).subscribe(data => {

      if (data.status) {
        this.themeData = data.data;

        this.panel = 2;
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ################ Select Product and got to Panel 2  ###############  //

  // selectProduct(prod){
  //   this.selectedProduct = prod;
  //   this.selectedProdId = this.selectedProduct.product_id;
  //   this.theme_id = '';
  //   this.product_thumb_image = this.selectedProduct.product_image_thumb;
  //   this.getThemeData();
  // }

  //  ################  Go to Edit Campaign Form   ########################   //

  goBackToEditCampaign() {
    let URL = '/corporate/create-campaign-builder' + '?editCampId=' + this.campId + '&backStatus=' + true;
    this.router.navigateByUrl(URL);
  }


  //  ################  Go to Panel 2 from 1       ########################   //

  goToPanel2From1() {
    if (this.selectedProdId === 0 || this.selectedProdId === null || this.selectedProdId === undefined) {
      this.toastrService.error('Please select a Product from List');
      return;
    }
    let selProd = this.prodList.filter(element => element.product_id === this.selectedProdId);
    this.selectedProduct = selProd[0];
    this.product_thumb_image = this.selectedProduct.product_image ? this.selectedProduct.product_image :'';
    this.theme_id = '';
    this.ngxSpinnerService.show();
    this.getThemeData();
  }

  //  ################  Go to Panel 1 from Panel 2 ########################  //

  goToPanel1From2(){
    this.theme_id = '';
    this.selectedProduct = {};
    this.panel = 1;
  }

  //  ################  Go to Panel 3(Schedule Campaign Panel from Theme List Panel ##########  //

  goToPanel3From2(){

    if (this.theme_id) {

      // Check if Timezomes are loaded or not //
      if (this.timezones.length === 0) {
        this.ngxSpinnerService.show();
        this.audienceBuilderService.getTimezones().subscribe(data => {
          if (data.status) {
            this.timezones = data.data;
            this.panel = 3;
            let thm = this.themeData.filter(elem => elem.theme_id === parseInt(this.theme_id, 10));
            this.selectedTheme = thm[0];
          } else {
            this.toastrService.error(data.message);
          }
          this.ngxSpinnerService.hide();
        }, error => {
         // console.log(error);
          this.toastrService.error(error);
          this.ngxSpinnerService.hide();
        });
      } else {
        let thm = this.themeData.filter(elem => elem.theme_id === parseInt(this.theme_id, 10));
        this.selectedTheme = thm[0];
        this.panel = 3;
      }

    } else {
      this.toastrService.error('Please select a Theme');
    }
  }

  //  ##############  Go to Panel 2 back from 3 ##############  //

  goToPanel2From3(){
    this.panel = 2;
  }

  //  ##############  Got to Panel 4 from 3 #################  //

  gotToPanel4From3(){
    this.submitted = true;
    if (this.scheduleForm.value.scheduleType === 'false'){
      this.panel = 4;
    } else {
      if (this.scheduleForm.invalid) {
        return;
      }
      let tmpDt = this.scheduleForm.value.scheduleStartDate;

      tmpDt.setHours(this.stTime.hour, this.stTime.minute, 0);
      let chkTm = 5 * 60 * 1000;

      let tz = this.timezones.filter( tzo => tzo.value === this.scheduleForm.value.tzone );
      this.selectedUTC = tz[0].utc[0];

      let newsysdate = new Date().toLocaleString("en-US", { timeZone: this.selectedUTC });
      let sysdate = new Date(newsysdate);




      if (tmpDt.getTime() - sysdate.getTime()   < chkTm){
        this.toastrService.error('Please select a Start Time greater than current Time');
        return;
      }


      this.selectedUTCtext = tz[0].text;
      this.panel = 4;
    }
  }

  //  ##############  Go to panel 3 from Panel 4  ##########  //
  gotToPanel3From4() {
   // this.scheduleForm.value.scheduleStartDate.setHours(0, 0, 0);
    this.panel = 3;
  }

  //  #############  Save Campaign ########################  //

  saveCampaign(){
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.companyId,
      'campid': this.campId,
      'audid': this.audId,
      'tot_schedule_count': this.campaignObj.tot_schedule_count,
      'product_id': this.selectedProdId,
      'theme_id': parseInt(this.theme_id, 10),
      'contact_type_id': '',
      'schedule_type': false,
      'schedule_timezone': '',
      'schedule_start': '',
      'start_time': '',
      'is_tested': this.campaignObj.is_tested
    };
    if (this.scheduleForm.value.scheduleType === 'true'){
      obj.schedule_type = true;
      obj.schedule_timezone = this.selectedUTC;
      obj.schedule_start = this.scheduleForm.value.scheduleStartDate.toISOString();
      obj.start_time = this.scheduleForm.value.scheduleStartDate.toISOString();
    }

    this.campaignBuilderService.updateCampaign(obj).subscribe(data => {
      if (data.status) {

        this.toastrService.success('Campaign Updated Successfully');
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/campaign-builder');

      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
      }

    }, error => {
    //  console.log(error);
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #############  Show Product in Modal  ###############  //

  showProductInModal(modalName, selectedProduct) {

    let self = this;
    this.productForModal = selectedProduct;

    self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

      // sessionStorage.setItem('product_data', JSON.stringify(self.productByIdData));
      // self.router.navigate(['/directseller/market/' + this.contacttypename + '/' + this.type.name + '/theme'])
    }, (reason) => {
     // console.log('reason', reason);
    });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }


  onImageLoad(evt, overlapImage, type){

    let element=evt.srcElement;
    let topImageAspectRatio_0=0.41929824561;
    let heightImageAspectRatio_0=0.61578947368;
    if(type==0){
     // console.log('type===0','height',Math.round((heightImageAspectRatio_0)*(element.clientWidth-10)),'top',Math.round(topImageAspectRatio_0*(element.clientWidth-10)));
      overlapImage.style.width=element.clientWidth+'px';
      overlapImage.style.height=Math.round((heightImageAspectRatio_0)*(element.clientWidth))+'px';
      overlapImage.style.top=Math.round(topImageAspectRatio_0*(element.clientWidth-10))+'px';
     // overlapImage.style.left=(element.offsetLeft-1)+'px';
    }else{
    //  console.log('modal.',element.clientHeight);
      overlapImage.style.width=element.clientWidth+'px';
      overlapImage.style.height=((.3447*element.clientHeight)+1)+'px';
      overlapImage.style.top=(.164*element.clientHeight)+'px';
      overlapImage.style.left=0+'px';
    }
    overlapImage.style.opacity=1;
  }

  getLibraryData(modelName) {

    this.ngxSpinnerService.show();
    this.modalService.open(modelName, { ariaLabelledBy: 'modal-basic-title' });
    this.ngxSpinnerService.hide();

  }

  //   ###########  On Destroy #######################  //

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
