<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">  Roles</li>
    </ol>
  </nav>
  <div class="row mt-2" *ngIf="!isUpdatePermission">
    <!-- <div class="col-8 col-lg-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1">
            <i class="fa fa-search text-white" aria-hidden="true"></i>
          </span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search">
      </div>
    </div> -->
    <div class="col-4 col-lg-4" style="justify-content: right;">
      <input  *ngIf="isEdit" style="margin-top: 3px;" type="button" value="Add New" class="custbtn mr-1" routerLink="/corporate/rolenewuser">
      <input style="margin-top: 3px;" *ngIf="aclStatus && isEdit" type="button" value="Update Acl Object" class="custbtn mr-1" (click)="updateAcl()">
    </div>
  </div>
  <div class="row" *ngIf="!isUpdatePermission">
    <div class="col-12">
      <div class="tabCustom1">
        <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder">
          <ngb-tabset style="width: 100%;">
            <ngb-tab *ngFor="let Obj of getRoleObj">
               <!--Obj.role_name - DS, BU, Margo etc..(main or parent role)-->
              <ng-template ngbTabTitle >
                <p (click)="changeSuit(Obj.role_id)">{{Obj.role_name}}</p>
              </ng-template>
              <ng-template ngbTabContent >
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngFor="let obj of getRoleAction; let roleindex=index;">
                   <!--obj.title - admin, analyst etc..(child role)-->
                  <ngb-panel title="{{obj.title}}" *ngIf="showTabRole">
                    <ng-template ngbPanelContent>
                      <table class="table table-borderless table-responsive" *ngIf="obj.data.length !== 0">
                        <thead>
                          <tr>
                            <th>Module Name</th>
                            <th>Module Item Name</th>
                            <th value="13"> Read </th>
                            <th value="14"> Edit </th>
                            <th value="15">  Write </th>
                            <th value="16">Delete</th>
                            <th value="17">View All</th>
                            <th value="18">Modify All</th>
                            <th value="26"> Import </th>
                            <th value="27"> Export </th>
                            <th> Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of obj.data; let i =  index">
                            <td>{{item.get_admin_role_action_fun.modulename}} {{Obj.role_name}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.moduleitemname}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("13") ? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("14")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("15")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("16")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("17")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("18")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("26")? "Y" :"N"}}
                            </td>
                            <td>{{item.get_admin_role_action_fun.permission_list.includes("27")? "Y" :"N"}}
                            </td>
                            <td> 
                              <div class="actBtn">
                              <i class="fa fa-edit mr-1" *ngIf="isEdit" placement="bottom" (click)="editpermision(item.get_admin_role_action_fun,Obj,obj,roleindex,i)"
                              ngbTooltip="Edit"></i>
                              <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deteteRoleAction(item.get_admin_role_action_fun,roleindex,i)" placement="bottom"
                              ngbTooltip="Delete"></span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div *ngIf='obj.data.length == 0' style="text-align: center;font-weight: 600;">
                        No Result Available</div>
                    </ng-template>
                    <div *ngIf="obj.data.length === 0" style="text-align: center;font-weight: 600;">
                      No Result Available
                    </div>
                  </ngb-panel>
                </ngb-accordion>
              </ng-template>
              <ng-template ngbTabContent>
                <p>No Result Available</p>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </ul>
      </div>
    </div>
  </div>
  <div class="mt-md-2" *ngIf="isUpdatePermission">
    <div class="row">
      <div class="rowTheme col-12">
        <div class="themContain"> 
          <div class="row">
            <div class="col-sm-6 col-md-3"><label class="font-weight-bold">Role Name:</label></div>
            <div class="col-sm-6 col-md-3"><label>{{objRoleActionData.rolename}}</label></div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-3"><label class="font-weight-bold">Child Role Name:</label></div>
            <div class="col-sm-6 col-md-3"><label>{{objRoleActionData.childrolename}}</label></div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-3"><label class="font-weight-bold">Module Name:</label></div>
            <div class="col-sm-6 col-md-3"><label>{{objRoleActionData.modulename}}</label></div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-3"><label class="font-weight-bold">Module Item Name:</label></div>
            <div class="col-sm-6 col-md-3"><label>{{objRoleActionData.moduleitemname}}</label> </div>
          </div>
          <div class="row">
            <div class="col-sm-4"><label class="mt-1 font-weight-bold mb-0">Permissions:</label></div>
          </div>   
          <div class="mt-1 w-100 d-inline-block">
            <div *ngFor='let item of getPermissionObj' class="mr-2 float-left mb-1">
              <div class="custom-control custom-checkbox">
                <label class="form-check-label" (change)='changeCheckForPermissions(item.per_id)' style="white-space: nowrap;">
                  <input class="form-check-input" type="checkbox" [checked]="objRoleActionData.permissionlist.indexOf(item.per_id.toString())>=0"
                  id="tag{{item.per_id}}" name="tagOptions"> {{item.per_name}}
                </label>
              </div>
            </div>
          </div> 
          <!-- </div> -->
          <button class="mt-2 custbtn" (click)="isUpdatePermission=false">Cancel</button>
          <button class="mt-2 custbtn" (click)="updateRoleAction()">Update</button>
        </div>            
      </div>
    </div>
  </div>
</div>