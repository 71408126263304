<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/thememaster">Theme Master</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{headers}} Theme</li>

        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="themeForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">

              <div class="card mt-1">

                <div class="card-body">
                <div>
                    <label for="exampleForm2">Select Company Name <span style="color:red">*</span></label>
                    <select formControlName="company" *ngIf="headers == 'Add'"  class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)='getCompanyID($event.target.value)'>
                    <option value="">Select Company</option>
                    <ng-container *ngFor="let userObj of getCompanyobj" >
                        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                 {{userObj.customer_company_name}}
                    </option>
                    </ng-container>
                </select>

                <select formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                <option value="">Select Company</option>
                <ng-container *ngFor="let userObj of getCompanyobj" >
                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
             {{userObj.customer_company_name}}
                </option>
                </ng-container>
            </select>


                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                        <div *ngIf="f.company.errors.required">Company Name is required</div>
                    </div>
                </div>
                <div>
                    <label for="exampleForm2">Theme Name <span style="color:red">*</span></label>
                    <input type="text" formControlName="themename" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.themename.errors }" required  maxlength="50" appSpace>
                    <div *ngIf="submitted && f.themename.errors" class="invalid-feedback">
                        <div *ngIf="f.themename.errors.required || f.themename.hasError('whitespace')">Theme Name is required</div>

                        <div *ngIf="f.themename.errors?.maxlength">You can enter only 50 charachters</div>


                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Theme Description</label>

                    <input type="text" formControlName="themedescription" min="1" maxlength="499" class="form-control form-control-md" maxlength="100"  appSpace>


                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Theme Screenshot <span style="color:red">*</span></label>
                    <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                        <img src="{{theme_screenshot}}" alt="Margo" style="
                        width: 99%;" (error)="setValue($event)">
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio1"
                                formControlName="firstActive" [value]="true" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio1">Url</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2"
                                formControlName="firstActive" [value]="false" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio2">Upload a file</label>
                        </div>
                    </div>
                    <div *ngIf="screenshot_type" class="mt-1">
                        <input type="text" formControlName="themescreenshot" class="form-control form-control-md mt-1"
                            placeholder="https://example.com/image(.jpg,.jpeg,.png,.gif)"
                          >

                    </div>
                    <div *ngIf="!screenshot_type" class="mt-1">
                        <input #imageInput type="file" accept=".png, .jpg, .jpeg, .gif"
                            (change)="processFile($event,imageInput)">
                        <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                    </div>
                </div>

                <div class="mt-1">
                    <label for="exampleForm2">Display Event Address</label>

                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="display_event_address1"
                                formControlName="display_event_address" [value]="true" >
                            <label class="custom-control-label" for="display_event_address1">Yes</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="display_event_address2"
                                formControlName="display_event_address" [value]="false">
                            <label class="custom-control-label" for="display_event_address2">No</label>
                        </div>
                    </div>
                    <div>
                      <label for="exampleForm2">Select Business Domain Name</label>
                      <select formControlName="business_domain_id"  class="form-control formstyle">
                      <option value=''>Select Domain</option>
                      <ng-container *ngFor="let userObj of domainData" >
                          <option *ngIf="userObj.is_active == true" value={{userObj.id}}>
                   {{userObj.domain_name}}
                      </option>
                      </ng-container>
                  </select>
                  </div>
                  <div *ngIf="themeForm.controls['business_domain_id'].value!=''">
                    <label for="exampleForm2">Theme Journey</label>
                    <select formControlName="theme_journey_id"  class="form-control formstyle">
                    <option value=''>Select Theme Journey</option>
                    <option *ngFor="let obj of journeyDropDown" value={{obj.journey_id}}>
                        {{obj.journey_name}} - {{obj.journey_description}}
                      </option>
                </select>
                </div>

                </div>

            </div>


            <div class="card-footer text-center mt-1" >
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="submit" class="custbtn" *ngIf="isWrite" (click)="addNewTheme()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateTheme()">Update</button>
                </div>
              </div>
        </div>
    </div>
</div>
    </form>
</div>
