import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-eventtypemaster',
  templateUrl: './eventtypemaster.component.html',
  styleUrls: ['./eventtypemaster.component.css']
})
export class EventtypemasterComponent extends BaseComponent implements OnInit {
  public eventTypeObj: any = [];

  cp = 1;
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();
  constructor(
    private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
  ) { 
    super();
  }

  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }

  ngOnInit() {
    this.getEvent();
    this.EventLogData()
  }
  EventLogData() {
    let etLogs = {
      log_type:"Event Type Master View" ,
      log_name:"Event Type Master View" ,
      log_description:"Event Type Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(etLogs).subscribe(data => {
     // console.log(data);
      
    }, error => {
    //  console.log(error);
    });
  
}
  getEvent() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getEventType().subscribe(data => {
      self.eventTypeObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  deleteEventType(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delEventType(obj).subscribe(data => {
            if (data.status) {
              this.getEvent();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  editEventType(id) {
    this.router.navigate(['/corporate/addnewevent'], { queryParams: { event_id: id } });
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.eventTypeObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.get_master_event_type.event_type_id === id) {
        return true;
      }
    });
    obj = obj.get_master_event_type;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.updateEventType(obj).subscribe(data => {
            this.getEvent();
            this.toastrService.success("Event Type Master Status Changed Successfully");
            this.ngxSpinnerService.hide();
          });
        }
        // else {
        //   this.master.updateEventType(obj).subscribe(data => {
        //     this.getEvent();
        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //   });
        // }
      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-event-type-import.csv`, 'addingEventBulk')
      .then(data => {
      //  console.log(data);
        self.getEvent();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_event_type"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
