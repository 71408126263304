<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/segmentation-attribute">Attributes List</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ header }} Attributes
      </li>
    </ol>
  </nav>
</div>


<div class="container" >
  <form class="form-horizontal mt-1" [formGroup]="attrForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">

            <div>
              <label for="attrForm"
              >BU Table Name <span class="required">*</span>
              </label>
              <select
                formControlName="butablename"
                autocomplete="off"
                class="form-control formstyle"
                placeholder="BU Table"
                [ngClass]="{ 'is-invalid': submitted && f.butablename.errors }"
                (change)="selectBUTable()"
              >
                <option value=""><---- Select Table ----></option>
                <option *ngFor="let Obj of tableList" value="{{ Obj.table_id}}">
                  {{ Obj.table_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.butablename.errors" class="invalid-feedback">
                <div *ngIf="f.butablename.errors.required">Table is required</div>
              </div>
            </div>

            <div>
              <label for="attrForm"
              >Field Alias <span class="required">*</span>
              </label>
              <select
                formControlName="fieldalias"
                autocomplete="off"
                class="form-control formstyle"
                placeholder="Field Alias"
                (change)="onFieldAliasSelected($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted && f.fieldalias.errors }"
              >
                <option value=""><--- Select Field Alias ---></option>
                <option *ngFor="let Obj of fields;let i = index"  value="{{i}}">
                  {{ Obj.column_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.fieldalias.errors" class="invalid-feedback">
                <div *ngIf="f.fieldalias.errors.required">Field Alias is required</div>
              </div>

            </div>

            <div>
              <label for="attrForm">Attribute Name <span style="color:red">*</span></label>
              <input type="text" appSpace maxlength="80" formControlName="attrname" class="form-control form-control-md"
                     placeholder="Provide Attribute Name" [ngClass]="{ 'is-invalid': submitted && f.attrname.errors }" required>
              <div *ngIf="submitted && f.attrname.errors" class="invalid-feedback">
                <div *ngIf="f.attrname.errors.required">Attribute Name is required</div>
              </div>
            </div>

            <div>
              <label for="attrForm">Attribute List Description </label>
              <textarea appSpace maxlength="255" placeholder="Provide Short Description for Attribute" formControlName="attrdesc" class="form-control form-control-md"></textarea>
            </div>

            <div>
              <label for="attrForm"
              >Attribute Group <span class="required">*</span>
              </label>
              <select
                formControlName="attrgroup"
                autocomplete="off"
                class="form-control formstyle"
                placeholder="Attribute Group"
                [ngClass]="{ 'is-invalid': submitted && f.attrgroup.errors }"
              >
                <option value="">Select Group</option>
                <option *ngFor="let Obj of attrGroup" value="{{ Obj.field_group_id }}">
                  {{ Obj.group_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.attrgroup.errors" class="invalid-feedback">
                <div *ngIf="f.attrgroup.errors.required">Attribute Group is required</div>
              </div>
            </div>
            <div>

              <label for="attrForm">Attribute Type <span style="color:red">*</span></label>
              <input type="text" appSpace  formControlName="attrtype" class="form-control form-control-md"
                     placeholder="Provide Attribute Type" readonly
                     [ngClass]="{ 'is-invalid': submitted && f.attrtype.errors }" required>
              <div *ngIf="submitted && f.attrtype.errors" class="invalid-feedback">
                <div *ngIf="f.attrtype.errors.required">Attribute Type is required</div>
              </div>

            </div>

            <div>
              <label for="attrForm">Field Size <span style="color:red">*</span></label>
              <input type="text" appSpace maxlength="4" formControlName="fieldsize" class="form-control form-control-md"
                     placeholder="Provide Field Size" readonly
                     [ngClass]="{ 'is-invalid': submitted && f.fieldsize.errors }" required>
              <div *ngIf="submitted && f.fieldsize.errors" class="invalid-feedback">
                <div *ngIf="f.fieldsize.errors.required">Field Size is required</div>
                <div *ngIf="f.fieldsize.errors.pattern">Field Size should have Numeric Values only</div>
              </div>
            </div>

<!--            <div>-->
<!--              <label for="attrForm"-->
<!--              >Field Type Option <span class="required">*</span>-->
<!--              </label>-->
<!--              <select-->
<!--                formControlName="fieldtypeoption"-->
<!--                autocomplete="off"-->
<!--                class="form-control formstyle"-->
<!--                placeholder=""-->
<!--                readonly-->
<!--                [ngClass]="{ 'is-invalid': submitted && f.fieldtypeoption.errors }"-->
<!--              >-->
<!--                <option disabled value="true">True</option>-->
<!--&lt;!&ndash;                <option disabled value="false">False</option>&ndash;&gt;-->
<!--              </select>-->
<!--              <div *ngIf="submitted && f.fieldtypeoption.errors" class="invalid-feedback">-->
<!--                <div *ngIf="f.fieldtypeoption.errors.required">Field Option is required</div>-->
<!--              </div>-->
<!--            </div>-->

          </div>

          <div class="card-footer text-center mt-1" *ngIf="status">
            <div class="my_class text-center" *ngIf="isWrite" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn"  (click)="addNewAttribute()" >Submit</button>
            </div>
          </div>
          <div class="card-footer text-center mt-1" *ngIf="!status">
            <div class="my_class text-center" *ngIf="isEdit" [ngClass]="status ? 'displaynone' : 'displayblock'">
              <button type="submit" class="custbtn"  (click)="updateAttribute()" >Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
