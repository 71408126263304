<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 theme-fa-icon" *ngIf="stat">
  <div class="row">
    <div class="col-xl-4 col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/users">
        <i class="fa fa-tachometer green-lt-icon" aria-hidden="true"></i>Admin
      </button>
    </div>
    <div class="col-xl-4 col-md-6 mt-md-0 mt-2" *ngIf="userProfileDetails.role_id !== 86">
      <button class="cbtn mt-1" routerLink="/corporate/dsuser">
        <i class="fa fa-user-o red-lt-icon" aria-hidden="true"></i>Direct seller
      </button>
    </div>
    <div class="col-xl-4 col-md-6 mt-xl-0 mt-2" *ngIf="companyId!=2">
      <button class="cbtn mt-1" routerLink="/corporate/teamassociation">
        <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Team Association
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4 col-md-6 mt-md-0 mt-2" *ngIf="userProfileDetails.role_id !== 86">
      <button class="cbtn mt-1" routerLink="/corporate/hostds">
        <i class="fa fa-user-secret blue-lt-icon" aria-hidden="true"></i>Host
      </button>
    </div>
    <div class="col-xl-4 col-md-6 mt-md-0 mt-2">
      <button class="cbtn mt-1" routerLink="/corporate/contactus-list">
        <i class="fa fa-envelope-o orange-dr-icon" aria-hidden="true"></i>Contact-us Enquiry
      </button>
    </div>
    <div class="col-xl-4 col-md-6 mt-xl-0 mt-2" >
    </div>
  </div>

</div>