import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ignoreElements } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AppConstants} from '../../../../_helpers/app.constants';
@Component({
  selector: 'app-newagerange',
  templateUrl: './newagerange.component.html',
  styleUrls: ['./newagerange.component.css']
})
export class NewagerangeComponent extends BaseComponent  implements OnInit {

  agerange;
  agerange_desc;
  agerange_icon;
  isactive;
  marid;
  agerangeObj;
  status: boolean = true;
  submitted = false;
  AddRange: FormGroup;
  constructor(private master: MasterService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
    ) {
      super();
     }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.marid = params.marid;
      });
    //get item details using id
    if (this.marid) {
      this.ngxSpinnerService.show();
      this.master.getAgerangeById(this.marid).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.agerangeObj = response.data;
         // console.log(self.agerangeObj)
          self.dataBinding();
        } else {

        }
        this.ngxSpinnerService.hide();
      });
    }
    this.AddRange = this.formBuilder.group({
      agerange: ['', [Validators.required, Validators.pattern(AppConstants.regex.ageRangePattern)]],
      agerange_icon: [],
      agerange_desc:[],
    });
  }
  get f() { return this.AddRange.controls; }
  addNewAgerange() {
    this.submitted = true;
    if (this.AddRange.invalid) {
      return;
    }
    let agerangeObj = {
      name: this.AddRange.value.agerange,
      description: this.AddRange.value.agerange_desc,
      agerange_icon: this.AddRange.value.agerange_icon,
      isactive: this.isactive
    }
    this.ngxSpinnerService.show();
    this.master.addingAgerange(agerangeObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/agerange`);
      } else {
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
    // this.agerange = this.agerangeObj.agerange;
    // this.agerange_desc = this.agerangeObj.agerange_desc;
    // this.agerange_icon = this.agerangeObj.agerange_icon;
    // this.isactive = this.agerangeObj.isactive;
    this.AddRange = this.formBuilder.group({
      agerange: [this.agerangeObj.agerange, [Validators.required, Validators.pattern(AppConstants.regex.ageRangePattern)]],
      agerange_desc: [this.agerangeObj.agerange_desc],
      agerange_icon:[this.agerangeObj.agerange_icon]
    });this.isactive = this.agerangeObj.isactive;
  }
  updateAgerange() {
    this.submitted = true;
    if (this.AddRange.invalid) {
      return;
    }
      let agerangeObj = {
        agerange: this.AddRange.value.agerange,
        agerange_desc: this.AddRange.value.agerange_desc,
        agerange_icon: this.AddRange.value.agerange_icon,
        isactive: this.isactive,
        marid: this.marid
      }
      this.ngxSpinnerService.show();
      this.master.UpdateAgerange(agerangeObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/agerange`);
        }
        this.ngxSpinnerService.hide();
      }, error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      });
    }

}


