import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-attribute-type',
  templateUrl: './new-attribute-type.component.html',
  styleUrls: ['./new-attribute-type.component.css']
})
export class NewAttributeTypeComponent extends BaseComponent implements OnInit{

  header = 'Add';
  submitted = false;
  status = true;
  attrTypeForm: FormGroup;
  attrId;
  attr;

  constructor(private segmentService: SegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.attrId = params.attrId;
      });
  }

  ngOnInit() {
    this.attrTypeForm = this.formBuilder.group({
      attrname: ['', Validators.required],
      attrdesc: ['']
    });
    if (this.attrId) {
      this.header = 'Edit';
      this.ngxSpinnerService.show();
      this.segmentService.getAttributeTypeById(this.attrId).subscribe(data => {
        if (data.status){
          this.attr = data.data[0];
          this.status = false;
          this.attrTypeForm.get('attrname').patchValue(this.attr.field_type_name);
          this.attrTypeForm.get('attrdesc').patchValue(this.attr.field_type_desc);
        } else {
          this.router.navigateByUrl('/corporate/attribute-type');
        }
        this.ngxSpinnerService.hide();

      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
      });
    }
  }

  get f() { return this.attrTypeForm.controls; }

  //  ###################  Add new Attribute Type #####################  //

  addNewAttrType(){
    this.submitted = true;
    if (this.attrTypeForm.invalid) {
      return;
    }
    let attrnm = this.attrTypeForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Type Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'field_type_name': this.attrTypeForm.value.attrname.trim(),
      'field_type_desc': this.attrTypeForm.value.attrdesc.trim(),
      'status': true
    };

    this.segmentService.addAttributeType(obj).subscribe(data => {
      if (data.status){
        this.toastrService.success('Attribute Type added successfully');
        this.router.navigateByUrl('/corporate/attribute-type');
      } else {
       // console.log(data);
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ################### Update Attribute Type  #####################  //

  updateAttributeType(){
    this.submitted = true;
    if (this.attrTypeForm.invalid) {
      return;
    }
    let attrnm = this.attrTypeForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Type Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'field_type_name': this.attrTypeForm.value.attrname.trim(),
      'field_type_desc': this.attrTypeForm.value.attrdesc.trim(),
      'status': this.attr.isactive
    };

    this.segmentService.updateAttributeTypeFields(obj, this.attr.field_type_id).subscribe(data => {
      if (data.status){
        this.toastrService.success('Attribute Type Updated Successfully');
        this.router.navigateByUrl('/corporate/attribute-type');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

}
