<style>
    .my-pagination /deep/ .ngx-pagination {
        text-align: center;
    }
</style>


<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/moduleitems">Module Items</a></li>
            <li class="breadcrumb-item active">{{header}} Module Items</li>
        </ol>
    </nav>
</div>



<div class="container mt-1 mt-md-2">
    <form class="form-horizontal mt-1" [formGroup]="trainingtypeForm">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div>
                    <label for="exampleForm2">Module Name <span class="required">*</span> </label>
                    <select  type="text" formControlName="itemsModuleId" class="form-control formstyle">
                        <option value="" selected disabled>--Select--</option>
                        <ng-container *ngFor="let group of getModuleObj">                           
                            <option *ngIf="group.children.length==0" [value]="group.id">{{group.name}}</option>
                            <optgroup *ngIf="group.children.length>0" label="{{group.name}}">
                                <option *ngFor="let item of group.children" [value]="item.id">{{item.name}}</option>
                            </optgroup>
                        </ng-container>
                    </select>
                    <div *ngIf="submitted && f.itemsModuleId.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.itemsModuleId.errors.required">Module Name is required</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Module Items Name <span class="required">*</span> </label>
                    <input type="text" id="exampleForm2" formControlName="itemsName"
                        class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.itemsName.errors }" required maxlength="30" appSpace>
                    <div *ngIf="submitted && f.itemsName.errors" class="invalid-feedback">
                        <div *ngIf="f.itemsName.errors.required || f.itemsName.hasError('whitespace')">Module Items Name is required</div>
                        <div *ngIf="f.itemsName.errors.pattern">>Module Items Name is not a valid format</div>
                        <div *ngIf="f.itemsName.errors?.maxlength">You can enter only 30 charachters</div>
                
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2"> Module Items Description</label>
                    <input type="text" id="exampleForm2" formControlName="itemsDescription"
                        class="form-control form-control-md" min="1" maxlength="499">
                </div>
                <div class="mt-1">
                    <label for="exampleForm2"> Module Item Route Link</label>
                    <input type="text" id="exampleForm2" formControlName="itemsModuleitemrouteLink"
                        class="form-control form-control-md">
                </div>

                <div class="mt-1">
                    <label class="mt-1">IsActive</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isactive"
                            [value]="true">
                        <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isactive"
                            [value]="false">
                        <label class="custom-control-label" for="customRadio2">Inactive</label>
                    </div>
                </div>

                
                    </div>
                    <div class="card-footer">
                        <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn" *ngIf="isWrite" (click)="addModuleItems()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateModuleItems()">Update</button>
                </div>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>