import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ClientMessages } from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { Location } from '@angular/common';
import { AppConstants } from 'src/app/_helpers/app.constants';
import csc from 'country-state-city';
import { DatePipe } from '@angular/common';
import { UploadService } from 'src/app/services/uploadfile/upload.service';


@Component({
  selector: 'app-clienteditcontact',
  templateUrl: './clienteditcontact.component.html',
  styleUrls: ['./clienteditcontact.component.css']
})
export class ClienteditcontactComponent extends BaseComponent implements OnInit {
  cid
  details
  submitted = false;
  status: boolean = true;
  existingClients: any = [];
  clientID: any;
  isheadquarter: boolean = true;
  logoAWSkey;
  companyLogo;
  date;
  countriesData: any = [];
  stateData: any = [];
  cityData: any = [];
  billingStateData: any = [];
  billingCityData: any = [];
  getCountryobj;
  currencyObj: any = []
  isEWallet: boolean = true;
  constructor(
    private clientService: ClientService, private master: MasterService,
    public location: Location,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private upload:UploadService) {
    super();
  }
  editForm: FormGroup;
  ngOnInit() {
    let clientData = this;

    clientData.cid = localStorage.getItem("editClientId");

    this.countriesData = csc.getAllCountries();
    this.getCountry();
    this.getCurrenctData();

    //get Client details using id
    if (clientData.cid) {
      this.ngxSpinnerService.show();
      this.clientService.getClientById(this.cid).subscribe(response => {
        if (response.status) {
          clientData.details = response.data;
          clientData.dataBinding();
        }
        this.ngxSpinnerService.hide();
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    }

    // Get Existing Client List
    this.clientService.headquartersList().subscribe(response => {
      if (response.status) {
        // console.log(response.data);
        this.existingClients = response.data;
      }
    }, error => {
      // console.log(error);
    });

    this.editForm = this.formBuilder.group({
      cname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      fname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lname: ['', [Validators.pattern(AppConstants.regex.name)]],
      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lgname: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      dispName: ['', [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      addr1: ['', Validators.required],
      addr2: [''],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      // zip: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(6)]],
      zip: ['', [Validators.required, Validators.pattern(AppConstants.regex.number), Validators.maxLength(5)]],
      taxid: ['', [Validators.required, this.noWhitespaceValidator]],
      website: ['', [Validators.required, Validators.pattern(AppConstants.regex.websiteURL)]],
      mphone: ['', [Validators.minLength(12), Validators.pattern(AppConstants.regex.number)]],
      lang: ['', Validators.pattern(AppConstants.regex.languagePattern)],
      curr: [''],
      revenue: ['', Validators.pattern(AppConstants.regex.mixPattern)],
      startDate: ['', Validators.required],
      noEmp: ['', Validators.pattern(AppConstants.regex.number)],
      billAddr1: ['', Validators.required],
      billAddr2: [''],
      billCountry: ['', [Validators.required]],
      billState: ['', [Validators.required]],
      billCity: ['', [Validators.required]],
      bZip: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(AppConstants.regex.number)]],
      noCountry: ['', Validators.pattern(AppConstants.regex.number)],
      noDS: ['', Validators.pattern(AppConstants.regex.number)],
      contractYears: ['', [Validators.required, Validators.pattern(AppConstants.regex.number)]],
      headquarter: [true, Validators.required],
      clientID: [''],

      monthly_amount: [''],
      wallet_name: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(AppConstants.regex.eWallet)]],
      wallet_status: ['']

    });
    this.editForm.get('wallet_status').valueChanges.subscribe(data => {
      this.changeValidators()
   })
  }


  // convenience getter for easy access to form fields
  get f() { return this.editForm.controls; }


  dataBinding() {
    let clientData = this;
    // console.log(this.details)
    this.isheadquarter = this.details.customer_isheadquarter;
    this.isEWallet = this.details.wallet_status;
    this.date = this.details.start_date.split('T');

    this.states(this.details.customer_country);
    this.getCities(this.details.customer_state);
    this.billingstates(this.details.customer_billing_country);
    this.getBillingCities(this.details.customer_billing_state);

    this.editForm = this.formBuilder.group({
      cname: [this.details.customer_company_name, [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      fname: [this.details.customer_contact_fname, [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lname: [this.details.customer_contact_lname, [Validators.pattern(AppConstants.regex.name)]],
      email: [this.details.customer_contact_email, [Validators.required, Validators.email]],
      title: [this.details.customer_title, [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      lgname: [this.details.customer_legal_name, [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      dispName: [this.details.customer_display_name, [Validators.required, Validators.pattern(AppConstants.regex.name), this.noWhitespaceValidator]],
      addr1: [this.details.customer_address1, Validators.required],
      addr2: [this.details.customer_address2],
      country: [this.details.customer_country, Validators.required],
      city: [this.details.customer_city, Validators.required],
      state: [this.details.customer_state ? this.details.customer_state : '', Validators.required],
      zip: [this.details.customer_zip, [Validators.required, Validators.pattern(AppConstants.regex.number), Validators.maxLength(5)]],
      taxid: [this.details.customer_tax_id],
      website: [this.details.customer_website, [Validators.required, Validators.pattern(AppConstants.regex.websiteURL)]],
      mphone: [this.details.customer_phone, [Validators.minLength(12), Validators.pattern(AppConstants.regex.number)]],
      lang: [this.details.customer_language, [Validators.pattern(AppConstants.regex.languagePattern)]],
      curr: [this.details.customer_currency],
      revenue: [this.details.customer_revenue, Validators.pattern(AppConstants.regex.floatPattern)],
      startDate: [this.date[0], Validators.required],
      noEmp: [this.details.customer_no_of_employes, Validators.pattern(AppConstants.regex.number)],
      billAddr1: [this.details.customer_billing_address1, Validators.required],
      billAddr2: [this.details.customer_billing_address2],
      billCountry: [this.details.customer_billing_country, Validators.required],
      billState: [this.details.customer_billing_state ? this.details.customer_billing_state : '', Validators.required],
      billCity: [this.details.customer_billing_city, Validators.required],
      bZip: [this.details.customer_billing_zip, [Validators.required, Validators.pattern(AppConstants.regex.number), Validators.maxLength(5)]],
      noCountry: [this.details.customer_no_of_countries, Validators.pattern(AppConstants.regex.number)],
      noDS: [this.details.customer_no_of_ds, Validators.pattern(AppConstants.regex.number)],
      contractYears: [this.details.contract_in_years, [Validators.required, Validators.pattern(AppConstants.regex.number)]],
      headquarter: [(this.details.customer_isheadquarter) ? this.details.customer_isheadquarter : false, Validators.required],
      clientID: [this.details.customer_parentid],
      monthly_amount: [parseFloat(this.details.monthly_amount).toFixed(2)],
      wallet_name: [this.details.wallet_name],
      wallet_status: [(this.details.wallet_status) ? this.details.wallet_status : false]

    });

    this.companyLogo = this.details.customer_company_logo;

    this.editForm.get('wallet_status').valueChanges.subscribe(data => {
      this.changeValidators()
   })

  }

  //Update Client Details
  updateClientDetails() {
    let client = this.f.headquarter.value ? 0 : this.f.clientID.value;
    if (this.isheadquarter == false) {
      if (client == "" || client == undefined) {
        this.toastrService.error('Please select the Headquarter');
        return false;
      }
    }
    let monthly_amount = this.f.monthly_amount.value;

    if(this.isEWallet && !this.isheadquarter && monthly_amount >= 1000) {

      this.toastrService.error(`Amount should be less than $1000`);

      return false;
    }
    this.submitted = true;
    // console.log("sssss....",this.f)
    if (this.editForm.invalid) {
      return
    }


    if (this.logoAWSkey) this.companyLogo = this.logoAWSkey;

    let clientObject = {
      cname: this.f.cname.value.trim(),
      clogo: this.companyLogo,
      fname: this.f.fname.value.trim(),
      lname: this.f.lname.value,
      email: this.f.email.value.trim(),
      title: this.f.title.value.trim(),
      lgname: this.f.lgname.value,
      dispName: this.f.dispName.value,
      addr1: this.f.addr1.value,
      addr2: this.f.addr2.value,
      country: this.f.country.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zip: this.f.zip.value,
      taxid: this.f.taxid.value,
      website: this.f.website.value,
      mphone: this.f.mphone.value,
      lang: this.f.lang.value,
      curr: this.f.curr.value,
      revenue: this.f.revenue.value,
      startDate: this.f.startDate.value,
      noEmp: this.f.noEmp.value,
      billAddr1: this.f.billAddr1.value,
      billAddr2: this.f.billAddr2.value,
      billCountry: this.f.billCountry.value,
      billCity: this.f.billCity.value,
      billState: this.f.billState.value,
      bZip: this.f.bZip.value,
      noCountry: this.f.noCountry.value,
      noDS: this.f.noDS.value,
      contractYears: this.f.contractYears.value,
      id: Number.parseInt(this.cid, 10),
      isactive: this.details.isactive,
      headquarter: this.f.headquarter.value,
      clientID: this.f.headquarter.value ? 0 : this.f.clientID.value,
      monthly_amount: this.f.monthly_amount.value,
      wallet_name: this.f.wallet_name.value,
      wallet_status: this.f.wallet_status.value
    }
    console.log('Edit Client', clientObject);
    this.ngxSpinnerService.show();
    this.clientService.updateClient(clientObject).subscribe(data => {

      if (data.status) {
        this.toastrService.success(ClientMessages.ClientUpdatedSuccessMessage);
        this.router.navigateByUrl(`/corporate/clients`);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.logoAWSkey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }
  processFile(event: any, imageInput: any) {


    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
      // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        if (this.toUploadFiles(file.name,reader.result)) this.ngxSpinnerService.hide();;
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    return new Promise((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.key) {
              self.logoAWSkey = params.Key;
              self.ngxSpinnerService.hide();
              resolve(self.logoAWSkey);
            }
          }
          else{
            reject(res.message)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(err)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }


  getCountry() {
    let self = this;
    this.registerService.getCountryData().subscribe(data => {
      self.getCountryobj = data.data;
    }, error => {
      // console.log(error);
    });
  }
  states(id) {
    this.f.state.setValue('');
    // console.log("State",csc.getStatesOfCountry(id));
    this.stateData = csc.getStatesOfCountry(id)
  }
  billingstates(id) {
    this.f.billState.setValue('');
    // console.log("State",csc.getStatesOfCountry(id));
    this.billingStateData = csc.getStatesOfCountry(id)
  }

  getCities(id) {
    this.f.city.setValue('');
    // console.log("cities",csc.getCitiesOfState(id));
    this.cityData = csc.getCitiesOfState(id)
  }
  getBillingCities(id) {
    this.f.billCity.setValue('');
    // console.log("cities",csc.getCitiesOfState(id));
    this.billingCityData = csc.getCitiesOfState(id)
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  getCurrenctData() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCurrency().subscribe(data => {
      self.currencyObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  changeValidators() {
    //console.log('', this.editForm.get("wallet_status").value);
    if (this.editForm.get("wallet_status").value == true) {

      this.editForm.get('monthly_amount').setValidators([Validators.required, Validators.pattern(AppConstants.regex.intFloatPattern)]);

      this.editForm.get('wallet_name').setValidators([Validators.required, Validators.maxLength(25), Validators.pattern(AppConstants.regex.eWallet)]);
    } else {
      this.editForm.get('wallet_name').clearValidators();
      this.editForm.get('monthly_amount').clearValidators();
    }
    this.editForm.get('monthly_amount').updateValueAndValidity();
    this.editForm.get('wallet_name').updateValueAndValidity();
  }

}
