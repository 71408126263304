<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/master-segmentation">Segmentation Master</a></li>
      <li class="breadcrumb-item active" aria-current="page"> Attribute Operator Association List</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">


  <div class="row">
    <div class="col-md-5 mb-3">
    

      <select name="company" class="form-control formstyle" [(ngModel)]="attrid" (change)="getAssociatedOperators($event.target.value)">
        <option value="">All Selected</option>
        <option *ngFor="let userObj of attributes" value={{userObj.field_id}}>
          {{userObj.field_name}}
        </option>
      </select>

    

  </div>
  <div class="col-md-7 mt-md-2 mt-0 mb-md-0 mb-3" style="justify-content: right;">
    <input type="button" value="Associate Attribute & Operator" *ngIf="isWrite" class="custbtn ml-0 float-md-right" routerLink="/corporate/add-attribute-operator-association">

  </div>

</div>

 
  <div class="card accordHeadMain" *ngIf="fullAssocList.length > 0">
    <h4 class="pl-3 pt-3">Attribute - Operator Association Full List</h4>
    <ngb-accordion
      activeIds="ngb-panel-0"
      #myaccordion="ngbAccordion"
    >
      <ngb-panel title="{{obj.field_name}}" id="ngb-panel-0" *ngFor="let obj of fullAssocList">
        <ng-template ngbPanelTitle>
          <span>
            <i class="fa fa-edit mr-1 ml-2"
               *ngIf="isEdit"
               (click)="editAssociationById(obj.field_id)"
               placement="bottom"
               ngbTooltip="Edit">
            </i>
          </span>
        </ng-template>
        <ng-template ngbPanelContent >
          <ul class="attrList">
            <li *ngFor="let attr of obj.operatorList" >
              {{attr.operator_name}}
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div class="card" *ngIf="show">
    <div class="col-md-7">
      <div class="row mt-3 mb-3">
        <h5 class="mt-1 mb-1 ml-3">Associated Operators
          <span style="cursor: pointer">
            <i class="fa fa-edit mr-1"
               *ngIf="isEdit"
               (click)="editAssociation()"
               placement="bottom"
               ngbTooltip="Edit">
            </i>
          </span>
        </h5>

      </div>

      <div class="piInnerList scroller">
        <ul class="filtMain">
          <li *ngFor="let item of operatorsObj">

            {{item.operator_name}}

          </li>
          <li *ngIf="operatorsObj?.length === 0">No Result Available</li>
        </ul>
      </div>

    </div>
  </div>

</div>
