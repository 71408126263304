<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Attribute Type List
      </li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">

  <div class="col-md-12">
    <div class="row">
      <div class="col-md-5 mb-md-0 mb-1">
        <div class="input-group" >
          <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                                                                            aria-hidden="true"></i></span>
          </div>

          <input class="form-control" type="text" placeholder="Search by Type Name" aria-label="Search" [(ngModel)]="text" >
        </div>

      </div>
      <div class="col-md-4" style="justify-content: right">
        <input
          type="button"
          value="Add New"
          *ngIf="isWrite"
          class="custbtn"
          routerLink="/corporate/new-attribute-type"
        />

      </div>
    </div>
  </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th
          (click)="setOrder('field_type_name')"
          style="cursor: pointer"
        >
          Attribute Type Name <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th> Attribute Type Description </th>
        <th>Actions</th>
      </tr>

      <tr
        *ngFor="let obj of attrTypeList | myfilter: text: 'field_type_name'
            | orderBy: order:reverse:'case-insensitive'
            | paginate: { itemsPerPage: 10, currentPage: p }">
        <td>{{ obj.field_type_name }}</td>
        <td>{{ obj.field_type_desc }}</td>
        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1 curPoint"
               *ngIf="isEdit"
               (click)="editAttributeType(obj.field_type_id)"
               placement="bottom"
               ngbTooltip="Edit">
            </i>
            <i class="fa fa-trash mr-1
            curPoint" *ngIf="isDelete"
               (click)="deleteAttributeType(obj.field_type_id)">
            </i>
            <mat-slide-toggle
              [checked]="obj.isactive"
              *ngIf="isEdit"
              class="my-slider"
              (click)="updateAttributeTypeStatus($event, obj)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
    <div
      *ngIf="(attrTypeList | filter: text).length === 0"
      style="text-align: center"
    >
      <p>No Result Available</p>
    </div>
    <div class="text-center" *ngIf="(attrTypeList | filter: text).length !== 0">
      <pagination-controls
        class="my-pagination"
        (pageChange)="p = $event"
      ></pagination-controls>
    </div>
  </div>

</div>
