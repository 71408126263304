<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">External Company Seller BU</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getTablesData($event.target.value)">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div>
        <select name="company" class="form-control formstyle" (change)="onTableChange($event.target.value)">
          <option value="0">Select Table</option>
          <ng-container *ngFor="let obj of tablesList">
            <option value={{obj.table_name}}>
              {{obj.table_name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-3">

        <select name="company" class="form-control formstyle" [(ngModel)]="selectedFieldname" (change)="onFieldNameChange($event.target.value)">
          <option value="">Select Fieldname</option>
          <ng-container *ngFor="let obj of tableFieldList">
            <option value={{obj?.column_name}}>
              {{obj?.column_name}}
            </option>
          </ng-container>
        </select>

    </div>
  <div class="col-md-4 mt-2" *ngIf="selectedFieldname !== '' && selectedFieldname !==null && selectedFieldname !== undefined">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="searchText" (keyup)="buListByFieldNameSearch($event)"/>
    </div>
  </div>
    <div class="col-md-4 mt-2" *ngIf="buDataList?.length > 0">

      <label>Total Records : <span>{{totalCount}}</span></label>
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="buDataList?.length > 0">
      <tr>
        <th *ngFor="let item of headerkyes | keyvalue">{{item.value}}</th>
      </tr>
      <tr *ngFor="let list of dataList">
        <td *ngFor="let val of list | keyvalue">
          {{val.value}}
        </td>
      </tr>
    </table>
    <div class="mt-3 text-center" *ngIf="buDataList?.length == 0">
      <p>No Result Available</p>
    </div>
    <div class="mt-3 text-center difPagination" *ngIf="buDataList?.length !== 0">

      <!-- <ngb-pagination [collectionSize]="totalCount" (pageChange)="getBUListbyTablename()" [(page)]="page" [maxSize]="2"
        [boundaryLinks]="true">
      </ngb-pagination> -->

      <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getBUListbyTablename()" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>

</div>

