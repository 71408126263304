import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';

@Component({
  selector: 'app-web-tag-list',
  templateUrl: './web-tag-list.component.html',
  styleUrls: ['./web-tag-list.component.css']
})
export class WebTagListComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  getCompanyobj: any = [];
  tagObj: any = [];
  webTagTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "name";
  constructor(private clientService: ClientService,
    private masterService:MasterService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
  
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getTags();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  getTags() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getWebinarTagList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        // console.log(data);

        if (data.status) {
          if (data.data.rows == null) { this.tagObj = [] }
          else { this.tagObj = data.data.rows }
          this.webTagTotal = data.data.total;
        } else {
          this.tagObj = [];
          this.webTagTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.tagObj = [];
      this.selectedCompany = "";
      this.webTagTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getTags()
  }
  editPost(id:any) {
    localStorage.setItem("edit_webinar_tag_id", id);
    localStorage.setItem("_webinar_tag_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/update-webinar-tag']);
  }
  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = {
      isactive: (status == true) ? false : true,
      company:this.selectedCompany,
      id:id
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.updateWebinarTagStatus(obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Updated Successfully");
              this.getTags();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  delTag(event, id) {
    event.preventDefault();
    let obj = {
      id:id,
      isdelete: true,
      company:this.selectedCompany

    }
    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.delWebinarTag(obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Deleted Successfully");
              this.getTags();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.masterService.getWebinarTagList(this.selectedCompany,this.slCatLimit, this.slCatSkip,this.searchText.nativeElement.value,this.slCatColname,this.slCatord))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.tagObj = [] }
          else { this.tagObj = res.data.rows }
          this.webTagTotal = res.data.total;
        } else {
          this.tagObj = [];
          this.webTagTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.tagObj = [];
        this.webTagTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }
}
