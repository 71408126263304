import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-update-faq-library',
  templateUrl: './update-faq-library.component.html',
  styleUrls: ['./update-faq-library.component.css']
})
export class UpdateFaqLibraryComponent extends BaseComponent implements OnInit {

  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  customer_id;
  submitted: boolean = false;
  categoryObj;
  selectedCompany;
  screenshot_type = true;
  them_awskey;
  category_id;
  imgURL = [];
  videoURLs = [];
  fileURLs = [];
  product_files: any[];
  postUrl = [];
  screenshotUrl = [];
  fileUploadErrMsg: any;
  slCatPage = 1;
  slCatLimit = 100;
  slCatSkip = 0;
  slCatTotal: number = 0;
  status: boolean = true;
  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;
  imageChangedEvent: any;
  croppedImage: any;
  controlEnabled: boolean = true;
  post_id: string;
  constructor(
    private clientService: ClientService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.addCategoryForm = this.formBuilder.group({
      categoryid: ["", Validators.required],
      faqType:["", Validators.required],
      question: [
        "",
        [
          Validators.required,
          this.noWhitespaceValidator,
        ],
      ],
      answer: [
        "",
        [
          Validators.required,          
          this.noWhitespaceValidator,
        ],
      ],
      status:[],
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
    });
  }

  ngOnInit() {
    this.customer_id = JSON.parse(sessionStorage.getItem("userData")).admin_customer_id;
    this.controlEnabled = true;
    if (this.customer_id == 0) this.getCompany();
    if (this.customer_id != 0) {
      this.getCategoryData(this.customer_id);
    }
    let self = this;
    if (localStorage.getItem("edit_resource_id")) {
      this.post_id = localStorage.getItem("edit_resource_id");
      this.selectedCompany = localStorage.getItem("resource_edit_company_id");
      this.getCategory(this.selectedCompany);
      this.ngxSpinnerService.show();
      this.masterService.getFaqById(this.post_id,this.selectedCompany).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.dataBinding(response?.data);

        }
      });
    }
  }
  dataBinding(data:any) {
    this.croppedImage=data?.resource_preview_url || null;
    this.addCategoryForm = this.formBuilder.group({
      categoryid: [data.category_id, Validators.required],
      faqType: [data.faq_type, Validators.required],
      answer: [data.answer, [Validators.required, this.noWhitespaceValidator]],
      question: [data.question, [Validators.required, this.noWhitespaceValidator]],
      isactive:[data.is_active],
      selectedCompany: [this.selectedCompany, Validators.required],
    });
  }
  get f() {
    return this.addCategoryForm.controls;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCategory(company) {
    if (company) {
      this.getCategoryData(company);
      this.selectedCompany = company;
    }
  }
  getCategoryData(company) {
    if (company) {
      this.ngxSpinnerService.show();
      let status = true;

      this.masterService
        .getFaqCategoryList(company, this.slCatLimit, this.slCatSkip, "","name","asc")
        .subscribe(
          (data) => {
            if (data.status) {
              if (data.data.rows == null) {
                this.categoryObj = [];
              } else {
                this.categoryObj = data.data.rows;
              }
              this.slCatTotal = data.data.total;
            } else {
              this.categoryObj = [];
              this.slCatTotal = 0;
            }
            this.ngxSpinnerService.hide();
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.categoryObj = [];
      this.ngxSpinnerService.hide();
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  addNewPostData() {
    this.submitted = true;
    let { categoryid, question, answer,isactive,faqType } =
      this.addCategoryForm.value;
    if (this.addCategoryForm.invalid) {
      return;
    }

    let obj = {
      id:this.post_id,
      category_id: categoryid,
      question: question,
      answer: answer,
      isactive:isactive,
      company:this.selectedCompany,
      faqType:faqType
    };
    this.ngxSpinnerService.show();

    this.masterService.updateFAQ(obj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success("Record Updated successfully");
          this.router.navigateByUrl(`/corporate/faq-library`);
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.toastrService.error(error.message);
      }
    );
  }
}
