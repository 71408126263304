<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/contentbuilder">Content Builder</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/cb-theme-template-association">Theme Template Association</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="!id">
        Add Theme Template Association
      </li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="id">
        Update Theme Template Association
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="addForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">
        <div class="card mt-1">
          <div class="card-body">
            <div class="form-group" *ngIf="companyCheck == 0">
              <label for="exampleForm2">Select Company Name <span style="color: red">*</span></label>

              <select formControlName="company" *ngIf="headers == 'Add'" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                (change)="getCompanyThemes($event.target.value)">
                <option value="">Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value="{{ userObj.customer_id }}">
                  {{ userObj.customer_company_name }}
                </option>
              </select>

              <select formControlName="company" *ngIf="headers == 'Edit'" [attr.disabled]="controlEnabled"
                class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                (change)="getCompanyThemes($event.target.value)">
                <option value="">Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value="{{ userObj.customer_id }}">
                  {{ userObj.customer_company_name }}
                </option>
              </select>

              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">
                  Company Name is required
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="companyCheck > 0">
              <input type="hidden" formControlName="company" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
            </div>
            <div class="form-group">
              <label for="exampleForm2">Theme Name <span style="color: red">*</span></label>
              <select formControlName="themeid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.themeid.errors }" (change)="checkThemeID()">
                <option value="">Theme Name</option>
                <option *ngFor="let userObj of themeObj" value="{{ userObj.theme_id }}">
                  {{ userObj.theme_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.themeid.errors" class="invalid-feedback">
                <div *ngIf="f.themeid.errors.required">
                  Theme Name is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Journey Type <span style="color: red">*</span></label>
              <select formControlName="journey_type_id" name="journey_type_id" class="form-control formstyle"
                (change)="getJourneyByType($event.target.value)" [ngClass]="{
                  'is-invalid': submitted && f.journey_type_id.errors
                }">
                <option value="">------Select Journey Type------</option>
                <option *ngFor="let item of journeyTypeObj" value="{{ item.journey_id }}">
                  {{ item.journey_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.journey_type_id.errors" class="invalid-feedback">
                <div *ngIf="f.journey_type_id.errors.required">
                  Journey Type is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Journey Name <span style="color: red">*</span></label>
              <select formControlName="journeyid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.journeyid.errors }">
                <option value="">Journey Name</option>
                <option *ngFor="let userObj of journeyObj" value="{{ userObj.journeyid }}">
                  {{ userObj.journey_name }}
                </option>
              </select>
              <div *ngIf="submitted && f.journeyid.errors" class="invalid-feedback">
                <div *ngIf="f.journeyid.errors.required">
                  Journey Name is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Template Name <span style="color: red">*</span></label>
              <select formControlName="templateid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.templateid.errors }" (change)="getorder($event.target.value)">
                <option value="">Template Name</option>
                <option *ngFor="let userObj of templateObj" value="{{ userObj.templateid }}">
                  {{ userObj.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.templateid.errors" class="invalid-feedback">
                <div *ngIf="f.templateid.errors.required">
                  Template Name is required
                </div>
              </div>
            </div>
 <input type="hidden" formControlName="journey_order" value="1">
            <!-- <div class="form-group">
              <label for="exampleForm2">Journey Order <span style="color: red">*</span></label>
              <select formControlName="journey_order" class="form-control formstyle" [ngClass]="{
                  'is-invalid': submitted && f.journey_order.errors
                }">
                <option value="">Journey Order</option>
                <option *ngFor="
                    let item of journeyOrderArray(totalJourney);
                    let i = index
                  " value="{{ i + 1 }}">
                  {{ i + 1 }}
                </option>
              </select>
              <div *ngIf="submitted && f.journey_order.errors" class="invalid-feedback">
                <div *ngIf="f.journey_order.errors.required">
                  Journey Order is required
                </div>
              </div>
            </div> -->

          </div>

          <div class="card-footer text-center">
            <div class="my_class text-center">
              <button type="button" class="custbtn" *ngIf="!id" (click)="addTemplate()">
                Submit
              </button>
            </div>
            <div class="my_class text-center">
              <button type="button" class="custbtn" *ngIf="id" (click)="updateTemplate()">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>