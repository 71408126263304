<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Theme Association</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                  aria-hidden="true"></i></span>
            </div>

            <input class="form-control" type="text" placeholder="Search by Journey Name" aria-label="Search"
              [disabled]="disCompId == 0" #searchText>



          </div>
        </div>
        <div class="col-md-4" style="justify-content: right;">
          <input type="button" value="Associate New Theme" *ngIf="isWrite" class="custbtn ml-0 mt-md-1"
            routerLink="/corporate/newthemeassociation">
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-2">
          <select name="company" [(ngModel)]="disCompId" class="form-control formstyle"
            (change)="themeAssociationList($event.target.value)">
            <option value="0">
              Select Company
            </option>
            <ng-container *ngFor="let userObj of getCompanyobj">
              <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                {{userObj.customer_company_name}}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-6 mt-2" *ngIf="page=='direct-seller'">
          <select name="company" class="form-control formstyle" (change)="getListByDomainId($event.target.value)">
            <option value="0" selected disabled>Select Business Domain</option>
            <ng-container *ngFor="let obj of domainData">
              <option value={{obj.id}}>
                {{obj.domain_name}}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-6 mt-2" *ngIf="page!=='direct-seller'">
          <select name="company" class="form-control formstyle" (change)="getListForJourney($event.target.value)">
            <option value="" selected>Select Journey</option>
            <ng-container *ngFor="let obj of journeyDrpData">
              <option value={{obj.journey_id}}>
                {{obj.journey_name}}
              </option>
            </ng-container>
          </select>
        </div>
      </div>


    </div>


    <div class="col-md-4" style="justify-content: right;">

    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom mn-wit-700" width="100%" cellpadding="0" cellspacing="0">


      <tr>

        <th (click)="sorting('journey_name')" class="curPoint">Journey <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Product</th>
        <th>Category</th>
        <th (click)="sorting('theme_name')" class="curPoint">Theme Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Created Date</th>
        <th *ngIf="isEdit || isDelete">Actions</th>

      </tr>


      <tr *ngFor="let obj of ThemeAssociationObj">


        <td>{{obj.journey_name}}</td>
        <td>
          <p *ngIf="!obj.product_name">
            None
          </p>
          <p *ngIf="obj.product_name!=''">
            {{obj.product_name}}
          </p>
        </td>
        <td>{{obj.product_category}}</td>
        <td>{{obj.theme_name}}</td>
        <td>{{obj.createdon| date}}</td>
        <td data-title="Action" *ngIf="isEdit || isDelete">
          <div class="actBtns">
            <mat-slide-toggle [checked]="obj.isactive" *ngIf="isEdit" class="my-slider"
              (click)="updateStatus($event, obj)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
        (pageChange)="getThemeAssoicationData((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>
    <!-- <pagination-controls *ngIf="(ThemeAssociationObj | filter:text).length !== 0" class="my-pagination" (pageChange)="cp=$event">
      </pagination-controls> -->
  </div>
</div>
