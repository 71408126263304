import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { from, Subscription } from "rxjs";
import { DsuserService } from "../../../services/users/dsuser.service";
import { BaseComponent } from "../../base.component";

@Component({
  selector: "app-dsreport",
  templateUrl: "./dsreport.component.html",
  styleUrls: ["./dsreport.component.css"],
})
export class DsreportComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  reportSubscription: Subscription;
  dsReportList: any = {};
  companyId: string;
  dsId: string;
  selectedIndex = 'month';
  start;
  end;

  constructor(
    private route: ActivatedRoute,
    private dsregister: DsuserService,
    private datepipe: DatePipe,
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      selected: [{
        startDate: new FormControl(),
        endDate: new FormControl()
      },
      Validators.required]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.dsId = params.ds_id;
      this.companyId = params.customer_id;
    });

    var todayDate = new Date();
    var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
    this.start = this.datepipe.transform(firstDay.toLocaleDateString(), 'yyyy-MM-dd');
    this.end = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.form.patchValue({ selected: { startDate: firstDay, endDate: new Date() } });

    this.getdsExternalInfo(this.start, this.end);
  }

  getdsExternalInfo(from, to) {
    this.ngxSpinnerService.show();
    this.reportSubscription = this.dsregister
      .getdsExternalInfo(this.companyId, this.dsId, from, to)
      .subscribe(
        (data) => {
          if (data.status) {
            this.dsReportList = data.data;
            this.ngxSpinnerService.hide();
          }else {
            this.ngxSpinnerService.hide();
          }
        },
        (error) => {
          console.log(error);
          this.ngxSpinnerService.hide();
        }
      );
  }


  updateDSUser() {
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to Active the status?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.dsregister
            .updateDSUserStatus(this.companyId, this.dsId)
            .subscribe((data) => {
              this.ngxSpinnerService.hide();
              this.toastrService.success("User Activated Successfully");
              this.getdsExternalInfo(this.start, this.end);
            });
        }
      })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  get f() { return this.form.controls; }

  getFilterByTab(index) {
    this.selectedIndex = index;

    if (index == 'day') {
      let from = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      let to = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      this.form.patchValue({ selected: { startDate: new Date(), endDate: new Date() } });
      this.getdsExternalInfo(from, to);

    } else if (index == 'week') {

      var todayDate = new Date();
      var weekDay = todayDate.getDay();
      todayDate.setDate(todayDate.getDate() - weekDay);
      let from = this.datepipe.transform(todayDate.toLocaleDateString(), 'yyyy-MM-dd');
      let to = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

      // set date in the calendar
      this.form.patchValue({ selected: { startDate: todayDate, endDate: new Date() } });
      this.getdsExternalInfo(from, to);

    } else if (index == 'month') {

      var todayDate = new Date();
      var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      let from = this.datepipe.transform(firstDay.toLocaleDateString(), 'yyyy-MM-dd');
      let to = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

      this.form.patchValue({ selected: { startDate: firstDay, endDate: new Date() } });
      this.getdsExternalInfo(from, to);
    }
  }

  getFilterByCalendar() {
    let startDate = this.datepipe.transform(this.form.value.selected.startDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.form.value.selected.endDate, 'yyyy-MM-dd');

    // don't call api if end date is not selected
    if ((endDate == 'null') || (endDate == null) || (endDate == '') || (endDate == undefined)) {
      return false;
    }
    this.getdsExternalInfo(startDate, endDate);
  }



  ngOnDestroy() {
    this.reportSubscription.unsubscribe();
  }
}
