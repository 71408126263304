<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/supportuser">Support</a></li>
      <li class="breadcrumb-item active" aria-current="page">DS Approval List</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <!-- <div class="col-sm-8">

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" value="" type="text" placeholder="Search Seller name" aria-label="Search"
          #searchText>
      </div>
    </div> -->
    <div class="col-12">

    </div>
  </div>
  <div class="tblPrt">

    <!-- <div class="mt-3" *ngIf="allTablesobj.length==0">No Sensitive Tables</div> -->

    <!-- <div style="text-align: center;" *ngIf="allTablesobj && allTablesobj.length === 0"> -->
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">


      <tr>
        <th>Seller Name</th>
        <th>Case ID</th>
        <th>Interval</th>
        <th>Support User Name</th>
        <th>Action</th>
      </tr>

      <tr *ngFor="let item of DSApprovalList">
        <td>{{ item.seller_name }}</td>
        <td>{{ item.salesforce_case_id }}</td>
        <td>{{ item.time_interval.hours + ' hr' }}</td>
        <td>{{ item.support_user_name }}</td>
        <td>
          <button class="btn btn-margo-action" (click)="giveAccess(1, item)">Allow</button>
          <button class="btn btn-margo-action" (click)="giveAccess(2, item)">Deny</button>
        </td>
      </tr>

    </table>

    <!-- <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getRequestList(page=$event)" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination> 
      </div> -->

    <!-- </div> -->

  </div>