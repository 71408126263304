<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/replenishment-and-cross-sell">Premium campaign</a></li>
      <li class="breadcrumb-item active" aria-current="page">Customer Count</li>
    </ol>
  </nav>
</div>

<div class="container">

  <div class="row">

    <div class="col-md-8 mb-md-0 mb-1">
      <div class="input-group" *ngIf="dtyp === 'customer'">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" [(ngModel)]="stext" [formControl]="queryContactField" type="text"
          placeholder="Search" aria-label="Search" #searchText />
          

        <button *ngIf="launched!=1" class="csvBtn col-md-2 offset-2" (click)="exportFile()">
          <img class="customimg" src="../../../assets/custom/images/export.png">
        </button>
      </div>
    </div>




    <div class="col-md-2 offset-2" style="justify-content: right">
      <input type="button" value="BACK" class="custbtn" (click)="goBack()" />
    </div>

  </div>


  <div class="container" *ngIf="dtyp === 'ds'">

    <div class="row">

      <div class="tblPrt">

        <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">

          <tr>

            <th>DS Name</th>
            <th>Customer Count</th>
          </tr>

          <tr *ngFor="let obj of dsList">
            <td>{{obj.dsname}}</td>
            <td>{{obj.customer}}</td>
          </tr>

        </table>
        <div *ngIf="dsList.length == 0" style="text-align: center">
          <p>No Result Available</p>
        </div>
        <div class="mt-2 text-center difPagination">
          <ngb-pagination [collectionSize]="dsTotalRecords" [(page)]="dspage" [(pageSize)]="limit" [maxSize]="2"
            [rotate]="true" (pageChange)="getDsDataOnPageChange(dspage=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div>
      </div>

    </div>

  </div>


  <div class="container" *ngIf="dtyp === 'customer'">




    <div class="row">


      <div class="tblPrt">

        <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">

          <tr>
            <th>Contact Name</th>
            <th>Contact Email</th>
            <th>Contact Phone</th>
            <th>DS Name</th>
            <th>Journey Start Date</th>
            <th>Journey End Date</th>

          </tr>

          <tr *ngFor="let obj of customerList">
            <td>{{obj.contact_name}}</td>
            <td>{{obj.contact_email}}</td>
            <td>{{obj.contact_phone}}</td>
            <td>{{obj.dsname}}</td>
            <td>{{obj.journey_start_date|date}}</td>
            <td>{{obj.journey_end_date|date}}</td>
          </tr>

        </table>
        <div *ngIf="customerList.length == 0" style="text-align: center">
          <p>No Result Available</p>
        </div>
        <div class="mt-2 text-center difPagination">
          <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [(pageSize)]="limit" [maxSize]="2"
            [rotate]="true" (pageChange)="getDataOnPageChange(page=$event)" aria-label="Custom pagination">
            <ng-template ngbPaginationPrevious>Prev</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div>
      </div>

    </div>


  </div>

</div>