import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { MbService } from 'src/app/services/fbservice/mb.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { OrderPipe } from 'ngx-order-pipe';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-snippets',
  templateUrl: './snippets.component.html',
  styleUrls: ['./snippets.component.css']
})
export class SnippetsComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  today: number = Date.now();
  public messageSnippetObj: any = [];
  getCompanyobj: any = [];
  currentUser;
  companyID = 0;
  selectedCompany: any = '';

  ord = "asc";
  colname = "msg_type";
  page = 1;
  limit = 10;
  skip = 0;
  total: number = 0

  constructor(private mb: MbService,
    private clientService: ClientService,
  ) { super(); }

  ngOnInit() {
    localStorage.removeItem("edit_snippet_id");
    localStorage.removeItem("snippet_edit_company_id");

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    if (this.currentUser.admin_customer_id != '0') { 
        this.companyID = this.currentUser.admin_customer_id;
        this.selectedCompany = this.companyID; 

        this.getMessageData(); 

      }

    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.page = 1;
        }),
        switchMap(data => this.mb.getFbAllMessage(this.selectedCompany, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.messageSnippetObj = [] }
          else { this.messageSnippetObj = data.data.rows }
          this.total = data.data.total;
        } else {
          this.messageSnippetObj = [];
          this.selectedCompany = "";
          this.total = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.messageSnippetObj = [];
        this.selectedCompany = "";
        this.total = 0;
        this.ngxSpinnerService.hide();
      });
  }

  setOrder(value: string) {
    this.colname = value
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getMessageData();
  }

  MessageLogData() {
    let MessageLogs = {
      log_type: "Message Snippets View",
      log_name: "Message Snippets View",
      log_description: "Message Snippets View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(MessageLogs).subscribe(data => {
      //  console.log(data);
    }, error => {
      // console.log(error);
    });
  }

  getMessageData() {
    let searchTerm = this.searchText.nativeElement.value;
    this.skip = (this.page - 1) * this.limit;
    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.mb.getFbAllMessage(this.selectedCompany, this.limit, this.skip, this.colname, this.ord, searchTerm).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.messageSnippetObj = [] }
          else { this.messageSnippetObj = data.data.rows }
          this.total = data.data.total;
        } else {
          this.messageSnippetObj = [];
          this.selectedCompany = "";
          this.total = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      });
    } else {
      this.messageSnippetObj = [];
      this.selectedCompany = "";
      this.total = 0;
      this.ngxSpinnerService.hide();
    }
  }

  editMessage(id) {
    localStorage.setItem("edit_snippet_id", id);
    localStorage.setItem("snippet_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/addnewsnippets']);
  }

  deleteMessage(id) {
    this.confirmationDialogService.confirm(CommonMessages.confirmationTitle, 'Are you sure you want to Delete the Message Snippet?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.mb.delMessageSnippet(this.selectedCompany, id).subscribe(data => {
            if (data.status) {
              this.getMessageData();
              setTimeout(() => {
                this.toastrService.success('Deleted Successfully');
              }, 1000);
            } else {
            }
          }, error => {
            this.toastrService.error(error.message);
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }


  updateStatus(event, id, status) {
    event.preventDefault();

    let obj = {
      id: id,
      isactive: (status == true) ? false : true,
      company: this.selectedCompany
    }


    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')

      .then((confirmed) => {
        if (confirmed) {
          this.mb.updateMessageStatus(obj).subscribe(data => {
            this.toastrService.success("Status Updated Successfully");
            this.getMessageData();
          });
        } else {

        }
      }).catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
      // console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }

}
