import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { AuthserviceService } from '../services/auth/authservice.service';
import { AdminoneService } from '../services/adminone/adminone.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterService } from '../services/users/register.service';
import {ChatService} from "../services/chat/chat.service";
@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    emsg
    status: boolean = false;
    subscription: any;
    mc = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        private alertService: AlertService,
        public authService: AuthService,
        private authenticationService: AuthserviceService,
        private adminService: AdminoneService,
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        private registerService:RegisterService,
        private chatService:ChatService,
        private activatedRoute: ActivatedRoute
    ) {
      this.subscription = this.activatedRoute.queryParams
        .subscribe(params => {
          if (params.mc === '1') {
            this.mc = true;
          }
        });
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        if (localStorage.getItem('currentUser')) {
            this.authService.doLogout();
        }
        if(localStorage.getItem('accesToken')){
          let uData = JSON.parse(localStorage.getItem('userData'));
          let rId = parseInt(uData.role_id, 10);
          if (rId === 105) {
            this.router.navigateByUrl('corporate/mc-scheduler');
          } else {
            this.router.navigateByUrl('corporate/home');
          }
        }
    }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        let self = this;
        if (this.f.email.value === "undefined" && this.f.password.value === "undefined") {
            setTimeout(() => {
                this.toastrService.error("please Enter Username and Password")
            }, 1000);
        }
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        const value = {
            userName: this.f.email.value,
            password: this.f.password.value
        };
        this.spinner.show();
        localStorage.setItem('typeofuser', this.f.email.value);

        this.authenticationService.checkLogin(value, this.mc).subscribe(data => {
            if (data.status) {
                localStorage.setItem("accesToken", data.token);
               // console.log('***********',this.chatService);
                sessionStorage.setItem("userData",JSON.stringify(data.userData));
                localStorage.setItem("userData",JSON.stringify(data.userData));

                /*

                this.authenticationService.getUserDetails().subscribe(data => {
                    if (data.status) {

                        localStorage.setItem("UserData", JSON.stringify(data.data));
                        }

                    });
                  */

                this.toastrService.success(data.message);
                this.registerService.getAllRoleActionsByUser().subscribe(roledata => {
                    let homeRouteExist = false;
                    roledata.forEach(element => {
                        if(element.itemroute === 'corporate/home'){
                            homeRouteExist = true;
                        }
                    });
                    if(homeRouteExist === false){
                        let homeObj = {
                            role_id: 170,
                            permission_list: "17",
                            modulename: "Home",
                            moduleitemname: "Dashboard",
                            itemroute: "corporate/home"
                        }
                        roledata.push(homeObj);
                    }
                    if (roledata) {
                        this.registerService.RoleActionsDataByUser.next(roledata);
                    }
                    let uData = JSON.parse(localStorage.getItem('userData'));
                    // let rId = parseInt(uData.role_id, 10);
                    let rData = roledata[0];

                    if (rData.modulename === 'MC Connector') {
                        this.router.navigateByUrl('corporate/mc-scheduler');
                    } else {
                        this.router.navigateByUrl('corporate/home');
                    }
                });
            } else {
                setTimeout(function () {
                    self.status = false;
                }, 3000);
                self.status = true;
                self.emsg = data.message;
            }
            this.spinner.hide();
        }, error => {
            setTimeout(() => {
                this.toastrService.error(error);
            }, 1000);
            this.spinner.hide();
        });
        // this.authService.doLogin(value)
        //     .then(res => {
        //         this.setUserInStorage(res);
        //         localStorage.removeItem('currentLayoutStyle');
        //         let returnUrl = '/dashboard/sales';
        //         if (this.returnUrl) {
        //           returnUrl = this.returnUrl;
        //         }
        //         this.router.navigate([returnUrl]);
        //     }, err => {
        //         this.submitted = false;

        //     });
    }

    setUserInStorage(res) {
        if (res.user) {
            localStorage.setItem('currentUser', JSON.stringify(res.user));
        } else {
            localStorage.setItem('currentUser', JSON.stringify(res));
        }
    }

    //  #################  On Destroy component ###################  //

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }
}
