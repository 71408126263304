<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/supportuser">Support</a></li>
      <li class="breadcrumb-item active" aria-current="page">Create Request</li>
    </ol>
  </nav>
</div>

  <div class="container mt-2">
  
  <div class="card">
    
    <div class="card-body">
      <form  [formGroup]="supportUserCreateForm">
      <div class="row" *ngIf="roleID == 71">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Support User<span class="required">*</span>: </label>
            <div class="col-sm-8">
              <select name="supportuser" formControlName="supportuser" class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.supportuser.errors }">
                <option value="">------Select Support User------</option>
                <option *ngFor="let obj of supportUser" value={{obj.uid}}>
                  {{obj.admin_fname}}
                </option>
              </select>
              <div *ngIf="submitted && f.supportuser.errors" class="invalid-feedback">
                <div *ngIf="f.supportuser.errors.required">Support User is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Company<span class="required">*</span>: </label>
            <div class="col-sm-8">
              <select name="company" formControlName="company"  class="form-control formstyle" (change)="getCompanySellerList($event.target.value)"   [ngClass]="{ 'is-invalid': submitted && f.company.errors, 'readonly': isSupportUser }">
                <option value="">Select Company Name</option>
                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} >
                  {{userObj.customer_company_name}}
                </option>
              </select>
              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Company is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Select Direct Seller<span class="required">*</span>: </label>
            <div class="col-sm-8">
              <select name="sellerId" formControlName="sellerId" class="form-control formstyle"   [ngClass]="{ 'is-invalid': submitted && f.sellerId.errors }">
                <option value="">Select Direct Seller</option>
                <option *ngFor="let obj of themeObj" value={{obj.userid}}>
                  {{obj.full_name + ' (' + obj.username + ') '}} - {{obj.role_id == 76 ? 'DS' : 'Host'}}
                </option>
              </select>
              <div *ngIf="submitted && f.sellerId.errors" class="invalid-feedback">
                <div *ngIf="f.sellerId.errors.required">Direct Seller is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!isSupportUser">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Interval<span class="required">*</span>: </label>
            <div class="col-sm-8">
              <select name="timeInterval"  formControlName="timeInterval"  class="form-control formstyle"  [ngClass]="{ 'is-invalid': submitted && f.timeInterval.errors }">
                <option value="">------Select Time Interval------</option>
                <option  value="1 hr">
                 1 hr
                </option>
                <option  value="2 hr">
                  2 hr
                 </option>
                 <option  value="3 hr">
                  3 hr
                 </option>
                 <option  value="4 hr">
                  4 hr
                 </option>
              </select>
              <div *ngIf="submitted && f.timeInterval.errors" class="invalid-feedback">
                <div *ngIf="f.timeInterval.errors.required">Time Interval is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isSupportUser">
        <div class="col-xl-6 col-md-8">
          <div class="row form-group">

            <label class="col-sm-4 col-form-label">Case Id<span class="required">*</span>: </label>
            <div class="col-sm-8">

             <input type="text" name="case_id" formControlName="case_id"  class="form-control formstyle" appSpace min="1" maxlength="200" [ngClass]="{ 'is-invalid': submitted && f.case_id.errors }" >

             <div *ngIf="submitted && f.case_id.errors" class="invalid-feedback">
              <div *ngIf="f.case_id.errors.required">Case Id is required</div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center card-footer">
        <button class="custbtn" (click)="createSupportRequest()">
          Create Request
        </button>
      </div>
    </form>
  </div>

</div>
