import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-hq-report',
  templateUrl: './hq-report.component.html',
  styleUrls: ['./hq-report.component.css']
})
export class HqReportComponent  extends BaseComponent implements OnInit {

  constructor() {  super()}
  projectName = environment.projectName;

  ngOnInit() {
  }

}
