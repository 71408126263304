import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-campaign-builder',
  templateUrl: './message-campaign-builder.component.html',
  styleUrls: ['./message-campaign-builder.component.css']
})
export class MessageCampaignBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
