<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active">Customer Content Build Theme List</li>
    </ol>
  </nav>
  </div>
  <div class="container">
    <table class="bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td>
          <div>
            <select name="company" class="form-control formstyle"
              (change)="getSelectCompanyThemes($event.target.value)">
              <option value="">Select Company Name</option>
              <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                {{userObj.customer_company_name}}
              </option>
            </select>
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="col-sm-8">
            <table class="bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
              <tr>
                <th>Theme Name</th>
                <th>Action</th>
              </tr>
              <!-- <tr *ngFor="let obj of select_themeObj">
                <td> {{obj?.theme_name}}</td>
                <td><span class="fa fa-edit snipetIcon"  (click)="gettingThemeFields(select_company_id,obj.theme_id)"></span></td>
                <td><button (click)="addCBThemeFields(select_company_id,obj.theme_id)">Add Field</button></td>
              </tr> -->
            </table>
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td colspan="3">
          <div class="row" *ngIf="fstatus">
            <div class="col-md-6">
              <div class="row form-group">
                <div class="col-sm-8">
                  <div class="piInnerList scroller">
                    <ul class="filtMain">
                                            <li *ngFor="let item of allTablesobj_new" class="assoLi">
                        <label class="form-check-label" (change)='addCBField(item.column_name);'>
                          <input class="form-check-input1" type="checkbox" id="table{{item.column_name}}" name="tables">
                          {{item.column_name}}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="piInnerList1 scroller">
                <div class="filtMain">
                  <div class="form-group" *ngFor="let item of checkedTable">
                    <div class="row m-0">
                      <div class="col-sm-4">
                        <select id="field_type{{item}}" class="form-control formstyle"  (change)='changeCheckDataType(item,$event.target.value)'>
                          <option value="text" >Text</option>
                          <option value="integer" >Integer</option>
                          <option value="file" >File</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control" id="field_size"  (change)='changeCheckField(item,$event.target.value)' placeholder="field size" [formControl]="field_size" name="field_size">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button (click)="addingCBField(customer_id,themeid)">Save Field</button>
          </div>
         </td>
      </tr>
      <tr>
        <td colspan="2">

          <div class="piInnerList scroller">
            <div *ngFor="let obj of getCompanyfields">
              <div class="row">
                <div style="float:left" width="50%">
                  <input type={{obj.cb_field_type}} name='{{obj.cb_field_name}}' size={{obj.cb_field_size}}
                    placeholder={{obj.cb_field_value}}>
                </div>
                <div><span class="fa fa-edit snipetIcon"  (click)="gettingFieldsAttribute(obj.customer_id,obj.cbtaid)"></span>
                </div>
                <div><button (click)="deletefield(obj.customer_id,obj.cbtaid)">Delete</button></div>

              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div *ngIf="show4">
          <form [formGroup]="contentForm" (ngSubmit)="updateCBfield()">
            <div class="piInnerList scroller">Fields Update
              <div *ngFor="let obj of themefieldObj">
                <div class="row">
                  <div class="form-group" style="float:left" width="20% ">
                    <input type="text" value="{{obj.cb_field_name}}" formControlName='cb_field_name' placeholder="{{obj.cb_field_name}}"
                      [ngClass]="{ 'is-invalid': submitted && f.cb_field_name.errors }" readonly>
                  </div>
                  <div *ngIf="submitted && f.cb_field_name.errors" class="invalid-feedback">
                    <div *ngIf="f.cb_field_name.errors.required">Title is required</div>
                  </div>

                  <div class="form-group" style="float:left" width="20%">


                    <select formControlName='cb_field_type'
                      [ngClass]="{ 'is-invalid': submitted && f.cb_field_type.errors }">
                      <option *ngFor="let userObj of PageCollection" value="{{userObj}}">{{userObj}}</option>
                    </select>

                  </div>&nbsp; <div *ngIf="submitted && f.cb_field_type.errors" class="invalid-feedback">
                    <div *ngIf="f.cb_field_type.errors.required">type required</div>
                  </div>

                  <div class="form-group" style="float:left" width="20%">
                    <input formControlName='cb_field_size' type="text" value="{{obj.cb_field_size}}"
                      [ngClass]="{ 'is-invalid': submitted && f.cb_field_size.errors }">
                  </div>&nbsp;
                  <div *ngIf="submitted && f.cb_field_size.errors" class="invalid-feedback">
                    <div *ngIf="f.cb_field_size.errors.required">size is required</div>
                  </div>
                  <div class="form-group" style="float:left" width="20%">
                    <input formControlName='cb_field_value' type="text" value="{{obj.cb_field_value}}"
                      [ngClass]="{ 'is-invalid': submitted && f.cb_field_value.errors }">
                  
                      <input formControlName='cbtaid' type="hidden" value="{{obj.cbtaid}}"
                      [ngClass]="{ 'is-invalid': submitted && f.cbtaid.errors }">
                      <div *ngIf="submitted && f.cbtaid.errors" class="invalid-feedback">
                        <div *ngIf="f.cbtaid.errors.required">id is required</div>
                      </div>
                      <input formControlName='customerId' type="hidden" value="{{obj.customer_id}}"
                      [ngClass]="{ 'is-invalid': submitted && f.customerId.errors }">
                  </div>
                  <div *ngIf="submitted && f.customerId.errors" class="invalid-feedback">
                    <div *ngIf="f.customerId.errors.required">id is required</div>
                  </div>
                                   
                  <div><button>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </form></div>
        </td>
      </tr>
    </table>


  </div>