import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../services/report/report.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { ToastrService } from 'ngx-toastr';

import {forkJoin} from 'rxjs';


@Component({
  selector: 'app-reportsnewcntact',
  templateUrl: './reportsnewcntact.component.html',
  styleUrls: ['./reportsnewcntact.component.css']
})
export class ReportsnewcntactComponent  implements OnInit {
  systemUsed: number = 0;
  date_status=1;
  cur_date;
  olddate1;
  olddate2;
  companyId;
  cpdata;
  currentUser;
  company_status:boolean=true;
  buyUsed:number=0;
  buyPrice:number=0;
  reportData: any[] = [];
  monthsArr: any[] = [];
  selectedMonth: string = '';
  companyID: any = 0;
  companyList: any = [];
  monthlyOpeningBalance: number = 0;
  dataUsed: number = 0;
  dateArr: string[];
  endDate:any;
  currentMonth: boolean = true;
  totalMsgConsumedByAllCustomer: number = 0;
  closingBalance: number = 0;
  showResult:boolean=true;
  monthNames:string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  cdate;
  isHeadquater;
  constructor(private report: ReportService, 
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private clientService:ClientService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id; 
    this.companyID =this.currentUser.admin_customer_id; 
    // alert(this.companyId);
    //this.spinner.show();
    var my_date = new Date();
    this.cdate= my_date.getFullYear()+"-"+my_date.getMonth()+"-"+my_date.getDate();
    this.getMonthsArray();
    this.dateArr = this.getFirstLastDateOfMonth(my_date.getMonth(), my_date.getFullYear());
   
    this.olddate1= new Date(this.dateArr[0]);
    this.olddate2= new Date(this.dateArr[1]);
    this.cur_date= new Date();
    //alert(this.cur_date);
    if(this.cur_date.getTime()>this.olddate1.getTime() && this.cur_date.getTime()<this.olddate2.getTime())
    {
      this.date_status =1;
    }else{
      this.date_status =0;
    }
    this.selectedMonth = this.monthNames[my_date.getMonth()] + " : " + my_date.getFullYear();
    //this.getAllBU_New()
    this.isHeadquater = localStorage.getItem("customer_isheadquarter");
    //console.log("asaasa....", this.isHeadquater.length);
    
    if(this.companyID==0 || this.isHeadquater ==="true") {   this.isHeadquater = true; this.companyID =0; this.getAllBU(this.companyID);}
    else {   this.isHeadquater = false; this.fetchCustomerData(this.companyID); this.company_status = false;}
    //this.getContactBalance(this.dateArr[0], this.dateArr[1] ); 

   console.log("company id...",this.isHeadquater)
  }

  fetchCustomerData(id) {


    if(id==0) {
      this.showResult=false;
      return false;

    }
    this.companyID = id;
      this.showResult=true;
      var my_date = new Date();
      var monArr = this.selectedMonth.split(' : ');
      // console.log(this.monthNames, monArr, this.monthNames.indexOf(monArr[0]), parseInt(monArr[1]));
      if(my_date.getMonth() == this.monthNames.indexOf(monArr[0].trim()) && (my_date.getFullYear() == parseInt(monArr[1].trim())))
        this.currentMonth = true;
      else
        this.currentMonth = false;

      this.dateArr = this.getFirstLastDateOfMonth(this.monthNames.indexOf(monArr[0]), parseInt(monArr[1]));
      this.getContactBalance(this.dateArr[0], this.dateArr[1] );
      if(this.currentMonth) {
        //this.getContactConsumption(this.dateArr[0], this.dateArr[1],this.companyID);
        this.getContactConsumptionOfAllCustomers(this.dateArr[0],this.dateArr[1],);
      } else {
        //alert('dd');
        //this.gettotalpurchasecontacts(this.dateArr[0], this.dateArr[1]);
        this.getHistoricalDataMonthly(this.dateArr[0], this.dateArr[1],this.companyID);
        this.getHistoricalDataMonthlyOfAllCustomer(this.dateArr[0], this.dateArr[1],this.companyID );
      }
      //this.spinner.hide();
   
  }
  getAllBU_New() {
    this.spinner.show();
    this.clientService.getBUCompanies().subscribe(data => {
      this.companyList = [{"customer_id":0,"customer_company_name":"Select Company"}]
      this.companyList = this.companyList.concat(data.data)
      //this.companyList.push(data.data);    
      this.companyID = this.companyList[0].customer_id;
      this.spinner.hide();
      //console.log(this.companyList);
    }, error => {
      this.spinner.hide();
     // console.log(error);
    });
  }
  getAllBU(id:any) {
    //this.spinner.show();


    this.clientService.getBUCompanies().subscribe(data => {
     // console.log("getAllBU====",data)
     this.companyList = data.data;    
      
      this.spinner.hide();
    //  console.log(this.companyList);
    }, error => {
         this.spinner.hide();
     // console.log(error);
    });
  }

  getHistoricalDataMonthly(startDate, endDate,companyID) {
    this.spinner.show();
    this.report.getHistoricalContactConsumption(startDate, endDate,companyID).subscribe((data) => {
     if(data.data>=0){
      this.dataUsed = data.data; 
     }else{
      this.dataUsed = null;  }    
      this.spinner.hide();
    });
  }

  getHistoricalDataMonthlyOfAllCustomer(startDate, endDate,cid) {
    this.spinner.show();
    this.totalMsgConsumedByAllCustomer = 0;
    this.report.getHistoricalContactConsumedByAll(startDate, endDate,cid).subscribe((data) => {
  //  alert(data.data);
      if(data.status){
        this.totalMsgConsumedByAllCustomer = data.data != null ? data.data: 0;
        this.closingBalance = this.monthlyOpeningBalance - this.totalMsgConsumedByAllCustomer;
      this.spinner.hide();
      }else{
        this.closingBalance=0;
      }
    });
  }
 
  getContactBalance(startDate, endDate) {
    this.spinner.show();
    forkJoin(
      // as of RxJS 6.5+ we can use a dictionary of sources
      {
        contactBalance: this.report.getContactBalance(startDate, endDate),
        totalPurchase:this.report.gettotalpurchasecontacts(startDate, endDate),
        consumptionContact:this.report.getConsumtioncontacts(startDate,endDate,this.companyID),
        contactConsumption:this.report.getContactConsumption(startDate, endDate, this.companyID)
        //attribute: this.cb.getCompanyFieldAttribute(id)
      }
    ).subscribe(data => {

      if(data.contactBalance.data!=undefined){    
        if(data.contactBalance.datavalue>=0){        
        
          this.monthlyOpeningBalance = data.contactBalance.datavalue;
        }else{
        //  alert("XXXX"+data.data.contacts_count);
          this.monthlyOpeningBalance = data.contactBalance.data.contacts_count;
        }
      }else{   
        //alert("YYYYYY"+data.data);
          this.buyPrice=null;
          this.closingBalance=null;
          this.monthlyOpeningBalance=null;
          this.systemUsed=null;
      }   
       
        if(this.monthlyOpeningBalance==undefined){
          this.monthlyOpeningBalance=0;
          this.closingBalance=null;
             } 
   
     if(data.totalPurchase.data==null || data.totalPurchase.data=='undefined'){
      this.buyUsed = null;
      this.systemUsed = null;  
      this.buyPrice = null;
      //this.spinner.hide();     
    }else{
    this.buyUsed = data.totalPurchase.data;
    this.buyPrice = data.totalPurchase.price;
  //  this.spinner.hide();
    }


    if(data.consumptionContact.status==false || data.consumptionContact.data==null ||data.consumptionContact.data==""){
      //alert("AAAA"+data.data);
     this.systemUsed = null;
    // this.buyUsed=null;
    }else{
      this.systemUsed = Number(data.consumptionContact.data);
    } 
    
    if(Number(data.contactConsumption.data)>=0){
      this.dataUsed = data.contactConsumption.data;
     }else{
      this.dataUsed = null;
     }
     this.closingBalance= this.monthlyOpeningBalance+Number(this.buyUsed);
     this.closingBalance = this.closingBalance-Number(this.systemUsed);
    // alert("YYY"+this.closingBalance);
     this.closingBalance = this.closingBalance-Number(this.dataUsed);
  
     this.closingBalance = this.monthlyOpeningBalance - this.totalMsgConsumedByAllCustomer;
  
        this.spinner.hide();
    }), error => {
     // console.log(error);
      this.spinner.hide();
     
    }
  }
  getFirstLastDateOfMonth(month, year) {
    var my_date = new Date(year, month, 1);
    var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    let formatted_first_date = first_date.getFullYear() + "-" + (first_date.getMonth() + 1) + "-" + first_date.getDate();

    var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    let formatted_last_date = last_date.getFullYear() + "-" + (last_date.getMonth() + 1) + "-" + last_date.getDate();
  //  console.log(formatted_first_date, formatted_last_date);
    return ([formatted_first_date, formatted_last_date]);
  }

  getContactConsumptionOfAllCustomers(startDate,endDate) {
    this.spinner.show();
    this.totalMsgConsumedByAllCustomer = 0;
    this.companyList.forEach(element => {
      if(element.customer_id!=0){
        this.report.getContactConsumption(startDate,endDate,element.customer_id).subscribe((data) => {
          if(data.status)
            this.totalMsgConsumedByAllCustomer = this.totalMsgConsumedByAllCustomer+data.data;
          this.spinner.hide();
        });
      }
    });
  }

  getMonthsArray() {
    var today = new Date();
    var d: any;
    var month: string;
    var currentYear = today.getFullYear();
    if(today.getMonth() < 11)
      currentYear = currentYear - 1;

    var tempArr = [];
    for(var i = 11; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = this.monthNames[d.getMonth()];
      if(today.getMonth()-i === 0 && i!=6)
        currentYear = currentYear + 1;
      
      tempArr.push(month + " : " + currentYear)
    }
   // console.log("MonthsArr");
  //  console.log(tempArr);
    this.monthsArr = tempArr;
  }
  exportReportCsv() {
    this.spinner.show();
    if(this.companyID!=0){
      const parmas = {
        customer_id: this.companyID,
        csv : true
      };
      this.report.getDsSubscriptionList(parmas).subscribe(dataFile => {
      // console.log(">>>>>", dataFile);
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
        this.spinner.hide();
      }, err => {
        this.toastrService.error(err.message);
        this.spinner.hide();
      });
    }else{
      this.toastrService.error('Please Select The Company!');
      this.spinner.hide();
    }
  }
}
