<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Direct Seller</li>
    </ol>
  </nav>
 
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-xl-2 col-md-4 mt-2" *ngIf="isHeadquater">    
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="gettingReports(1,$event.target.value)">
        <option value="0" selected>Select Company</option>
        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
            {{userObj.customer_company_name}}
        </option>
      </select>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [min]="mindate"   [max]="maxdate" 
              [(ngModel)]="start_date" (ngModelChange)="updateCalDate()"  >
      <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker1 ></mat-datepicker>
    </div>
    <div class="col-xl-2 col-md-4 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly [min]="stDate"  [max]="maxdate"   
      [(ngModel)]="end_date"  >
      <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  <div class="col-xl-2 col-md-4 mt-2">
    <select name="status" class="form-control formstyle"  [(ngModel)]="status">
      <option value=null>Select Status</option>
      <option value="true">Activated</option>
      <option value="false">Cancelled</option>
    </select>
  </div>

  <div class="col-xl-4 col-md-6 mt-2">
    <button class="custbtn ml-0 mt-md-1" (click)="gettingReports(1,companyID)" style="align-items: center;">Search</button> 
  
    <button class="custbtn ml-0 mt-md-1" (click)="resetReport()" style="align-items: center;">Reset</button> 
    <!-- <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a> -->
  </div>
</div>

  
  <div class="tblPrt" *ngIf="showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">    
      <!-- <tr>
        <th>DS Name <i class=" fa fa-sort" aria-hidden="true"></i></th>
        <th>Company Name</th>
        <th>Status <br/>(Activated / Canceled)</th>
        <th>Date</th>
        <th>Last Login Date </th>
        <th>Days From <br/>Last log-in</th>
        </tr> -->
        <tr>        
        <th>Company Name</th>
        <th>DS Name <i class=" fa fa-sort" aria-hidden="true"></i></th>
        <th>Activated Y/N</th>
        <th>Date Activated</th>
        <th>Cancelled Y/N</th>
        <th>Last Logged in Date </th>
        <th>Days From Last Log-in</th>
        </tr>
        <tr *ngFor="let report of reportData | paginate: { id: 'server', itemsPerPage:10, currentPage: p, totalItems: total }">
          
          <td>{{report.customer_company_name}}</td>
          <td>{{report.full_name}}</td>
          <td>{{report.isactive== true?'Y' : 'N'}}</td>
          <td>{{report.start_date |date}}</td>
          <td>{{report.isactive== true?'N' : 'Y'}}</td>
          <td>{{report.lastloggedin |date }}</td>
          <td >{{report.daysFromLastLoggedin}} <span *ngIf="report.daysFromLastLoggedin || report.daysFromLastLoggedin == 0 ">days</span></td>
        </tr>
  </table>
  <pagination-controls *ngIf="clicked" class="my-pagination" (pageChange)="gettingReports($event,companyID)" id="server"></pagination-controls>
</div>
<div class="tblPrt" *ngIf="!showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td colspan="5">No results available</td>                            
      </tr>  
  </table>
</div>

