import { Component, OnInit } from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadService } from 'src/app/services/uploadfile/upload.service';


@Component({
  selector: 'app-cb-form-builder',
  templateUrl: './cb-form-builder.component.html',
  styleUrls: ['./cb-form-builder.component.css']
})
export class CbFormBuilderComponent extends BaseComponent implements OnInit {

  templateObj = [];
  themeObj = [];
  journeyObj = [];
  getCompanyobj = [];
  sectionObj = [];
  companyId=0;
  themeId;
  journeyId;
  templateId;
  myFormGroup:FormGroup;
  submitted = false;
  them_awskey;
  allData = [];
  id;
  fieldData;
  templatePreview;
  updatedArray = [];
  containerText = [];
  // templateData;
  formPreview;
  containerValue;
  containerNewValue;
  currentUser;
  companyCheck;
  cloneData = [];
  selectedClone = null;
  formChildData;
  status;

  constructor(private cb: CbService,
    public master: MasterService,
    public clientService: ClientService,
    private modalService: NgbModal,
    public fb: FormBuilder, private upload:UploadService) {
    super();
    this.containerText = [
      {value:'[[firstname]]', name:'Host First Name'},
      {value:'[[lastname]]', name:'Host Last Name'},
      {value:'[[contact_state]]', name:'Host State'},
      {value:'[[DS_first_name]]', name:'Rep First Name'},
      {value:'[[DS_last_name]]', name:'Rep Last Name'},
      {value:'[[product_name]]', name:'Product Name'},
      {value:'[[product_price]]', name:'Product Price'},
      {value:'[[product_url]]', name:'Product Shopping URL'},
      {value:'[[product_name_with_url]]', name:'Product Shopping URL With Name'},
      {value:'[[product_category_name]]', name:'Product Category Name'},
      {value:'[[product_category_name_with_url]]', name:'Product Category Name With URL'},
    ]
   }

  ngOnInit() {


    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if(this.currentUser.admin_customer_id) this.companyId = this.currentUser.admin_customer_id;
    if(this.currentUser.admin_customer_id!='0') this.getCompanyThemes(this.companyId);
    else this.getCompany();
    this.companyCheck = this.currentUser.admin_customer_id;
    this.createForm();
   // console.log("araa"+this.companyId)

    this.activatedRoute.queryParams
    .subscribe(params => {
      this.id = params.id;

      this.themeId = params.themeId,
      this.journeyId = params.journeyId;
      this.templateId = params.templateId;
      if(params.companyID) {
        this.companyId = params.companyID,
        this.getCompanyThemes(this.companyId);
        this.myFormGroup.controls.themeid.setValue(this.themeId)
        this.myFormGroup.controls.company.setValue(this.companyId);
      }
      if(params.themeId) {
        this.getCompanyJourneys(this.themeId);
        this.myFormGroup.controls.journeyid.setValue(this.journeyId)
      }
    });
    // get item details using id
    if (this.id && this.companyId && this.themeId && this.journeyId && this.templateId) {
      this.getSectionFields(this.templateId)
      .then(data=> {
        this.populateData();
      }).catch(err => {
        this.toastrService.error('Something went wrong');
      })
    }


  }

  selectContainerTextValue(index, index1) {
    if(this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['ContainerText'] && this.containerValue) {
      let oldValue = this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['ContainerText'].value;
      this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['ContainerText'].setValue(oldValue + this.containerValue);
    }
    if(this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['SMSText'] && this.containerValue) {
      let oldValue = this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['SMSText'].value;
      this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['SMSText'].setValue(oldValue + this.containerValue);
    }
  }
   createForm() {
    this.myFormGroup = this.fb.group({
      fieldArray: this.fb.array([]),
      company: [''],
      themeid: [''],
      journeyid:[''],
      ttaid:[''],
      ttfaid:[''],
      emailSubject:['']
    });
  }

  populateData() {
    this.ngxSpinnerService.show();
    this.cb.getThemeTemplateJourneyById(this.companyId, this.id).subscribe(data => {
      if(data.status) {
        this.templatePreview = data.data[0].template_preview || '';
        this.fieldData = JSON.parse(data.data[0].fieldvalue);
        this.setFormFieldValue(this.fieldData.fieldArray)
        this.myFormGroup.controls.emailSubject.setValue(this.fieldData.emailSubject);
        this.myFormGroup.controls.ttaid.setValue(data.data[0].ttaid);
        this.myFormGroup.controls.ttfaid.setValue(data.data[0].ttfaid);
          this.ngxSpinnerService.hide();
          return this.fieldData.fieldArray
      }
    })
  }

  // previewForm(modalName){
  //   let data = {};
  //   data['previewHTML'] = this.templateData.template_preview;
  //   data['formData'] = this.myFormGroup.value;
  //   this.formPreview = data;

  //   let self = this;
  //   self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //   }, (reason) => {
  //     console.log("reason", reason);
  //   });

  // }

  get fieldsArray() {
    return <FormArray>this.myFormGroup.get('fieldArray') as FormArray
  }

  public onEventLog(event: string, data: any,index:number,index1:number,index3,name): void {
   // console.log(event, data);
    //console.log(this.fieldsArray)
    this.fieldsArray.controls[index1]['controls'].attributesFieldsArray.controls[index].controls.attData.controls[index3].controls[name].setValue(data.color);
  }




  getCompany() {

    this.themeObj = [];
    this.templateObj = [];
    this.allData = [];
    this.journeyObj = [];
    let self = this;
    this.ngxSpinnerService.show();
    this.clientService.getBUCompanies().subscribe(data => {
      this.ngxSpinnerService.hide();
      self.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
      self.getCompanyobj = [];
    });
  }

  getCompanyThemes(id){



    if(id) {

   // console.log('ssss'+id);
     //this. = "";

    this.templateObj = [];
    this.allData = [];
    //this.fieldsArray.controls = [];
    this.updatedArray = [];
    this.journeyObj = [];
    //event.preventDefault();
    this.companyId = id;
    this.cloneData = [];
    this.selectedClone = null;
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCompanyTheme(id).subscribe(data => {
     // console.log('dddd')
     // console.log(data)
      self.themeObj = data.data.rows;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
    }
    else {
      this.journeyObj = [];
      this.templateObj = [];
      //this.fieldsArray = [];
      this.updatedArray = [];
      this.themeObj = [];
      this.myFormGroup.controls.themeid.setValue("");
      this.myFormGroup.controls.journeyid.setValue("");
      this.cloneData = [];
      this.selectedClone = null;

    }
  }

  getCompanyJourneys(id) {

    //console.log("company..."+this.companyId)
    if(id) {
      this.myFormGroup.controls.journeyid.setValue("");
    this.allData = [];
    this.fieldsArray.controls = [];
    this.updatedArray = [];
    this.journeyObj = [];
     //event.preventDefault();
     this.templateObj = [];
     this.themeId = id;
     this.cloneData= [];
     let self = this;
     this.ngxSpinnerService.show();
     this.cb.getCompanyThemesJourneys(this.companyId, id).subscribe(data => {
      this.ngxSpinnerService.hide();
       if(data.data) {
        self.journeyObj = data.data;
       }
     }, error => {
       self.journeyObj = [];
       this.ngxSpinnerService.hide();
     });
    }
    else {

       this.journeyObj = [];
       this.templateObj = [];
       //this.fieldsArray = [];
       this.updatedArray = [];
       this.cloneData = [];
       this.selectedClone = null;
    }
  }

  getCompanyTemplates(id) {
  //console.log("aaaa"+this.companyId)

    this.allData = [];
    this.fieldsArray.controls = [];
    this.updatedArray = [];
    this.cloneData = [];
    this.selectedClone = null;

    if(id) {
    //event.preventDefault();
    this.journeyId = id;
    let self = this;
    this.ngxSpinnerService.show();
    this.cb.getCompanyJourneyTemplates(this.companyId, this.themeId, 10, 0, '', '', '', id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.data) {
        self.templateObj = data.data.rows;
        this.myFormGroup.controls.ttaid.setValue(data.data.rows[0].ttaid);
        this.myFormGroup.controls.ttfaid.setValue(data.data.rows[0].ttfaid);
       // console.log('YES');
        this.cb.getCloneDatabyJourneyId(this.companyId, id)
          .subscribe(data2 => {
            if (data2.status) {
              this.cloneData = data2.data;
            }
          }, error => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error); });
        // this.getTemplatePreview(self.templateObj[0].templateid);
        this.getSectionFields(self.templateObj[0].templateid)
        .then(data => {
          if(this.id) {
            this.populateData();
          } else if(!this.id) {
            this.populateDefaultData();
          }
        }).catch(err => {
          this.toastrService.error('Something went wrong');
        })
      } else {
        self.templateObj = [];
      }
    }, error => {
      self.templateObj = [];
      this.ngxSpinnerService.hide();
    });
  } else {
    this.templateObj = [];
    //this.fieldsArray = [];
    this.updatedArray = [];
    this.cloneData = [];
    this.selectedClone = null;
   // this.cb.getDataTemplateThemebyJid()
  }

 }




//  getTemplatePreview(id) {
//   this.ngxSpinnerService.show();
//   this.cb.getTemplateHTMLPreview(this.companyId,id).subscribe(data => {
//     this.ngxSpinnerService.hide();
//     if(data.status) {
//       this.templateData = data.data;
//     }
//   })
//  }

getSectionFields(id) {
  let self = this;
  this.allData = [];
  this.fieldsArray.controls = [];
  this.ngxSpinnerService.show();
  this.updatedArray = [];
  // if(this.id) {
  //   await this.populateData();
  // } else {
  //   await this.populateDefaultData();
  // }
  return new Promise((resolve, reject) => {
    this.cb.getSectionFields(this.companyId,id).subscribe(data => {
      this.ngxSpinnerService.hide();
      if(data.data) {
        const groupedCollection = data.data.reduce((previous, current) => {
          if (!previous[current['section_name']]) {
              previous[current['section_name']] = [current];
          } else {
              previous[current['section_name']].push(current);
          }
          return previous;
      }, {});
      // this will return an array of objects, each object containing a group of objects

          this.allData = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        //  console.log(this.allData,this.fieldData.fieldArray,'===')

          this.allData.forEach((input_template, count)=>{
            let section_name = input_template.key;
            let fieldGroup;
            let attributeGroup = {};
            let group = {};
            let fieldUpdated;
            let attributesUpdated = {};
            let newGroup = {};
            group['section_name'] = new FormControl(section_name);
            newGroup['section_name'] = section_name;
            newGroup['updatedArray'] = [];
            group['attributesFieldsArray'] = new FormArray([]);

            input_template.value.forEach((data, index) => {
              if((index>0 && data.fieldid !== input_template.value[index-1].fieldid) || index == 0) {

                if(index > 0) {
                  group['attributesFieldsArray'].push(new FormGroup(fieldGroup));
                  newGroup['updatedArray'].push(fieldUpdated);
                }
                fieldGroup = {};
                fieldUpdated = {};
                fieldUpdated['attData'] = [];
                fieldUpdated['fieldid'] = data.fieldid;
                fieldUpdated['field_name'] = data.field_name;
                fieldUpdated['field_type'] = data.field_type;
                fieldUpdated['field_size'] = data.field_size;
                if(data.field_name=='SectionProperty'){
                  group['css_'+section_name] = new FormControl('');
                } else {
                  fieldGroup[data.field_name] = new FormControl('');
                  fieldGroup['css_'+data.field_name] = new FormControl('');
                }
                fieldGroup['attData'] = new FormArray([])
                if(data.attribute_name) {
                  attributeGroup = {};
                  attributesUpdated = {};
                  attributesUpdated['attributeid'] = data.attributeid;
                  attributesUpdated['attribute_name'] = data.attribute_name;
                  attributesUpdated['attribute_description'] = data.attribute_description;

                  attributesUpdated['attribute_type'] = data.attribute_type;
                  if(data.attribute_type == 'dropdown') {
                    attributesUpdated['listitem'] = data.listitem;
                  }
                  attributeGroup[data.attribute_name] = new FormControl('');
                  fieldGroup['attData'].push(new FormGroup(attributeGroup));
                  fieldUpdated['attData'].push(attributesUpdated);
                }
                if(index === 0 && input_template.value.length === 1) {
                  group['attributesFieldsArray'].push(new FormGroup(fieldGroup));
                  newGroup['updatedArray'].push(fieldUpdated)
                }
                if(index == input_template.value.length-1) {
                  group['attributesFieldsArray'].push(new FormGroup(fieldGroup));
                  newGroup['updatedArray'].push(fieldUpdated)
                }
              }
              else if(data.fieldid == input_template.value[index-1].fieldid) {
                if(data.attribute_name) {
                  attributeGroup = {};
                  attributesUpdated = {};
                  attributesUpdated['attributeid'] = data.attributeid;
                  attributesUpdated['attribute_name'] = data.attribute_name;
                  attributesUpdated['attribute_type'] = data.attribute_type;
                  attributesUpdated['attribute_description'] = data.attribute_description;
                  if(data.attribute_type == 'dropdown') {
                    attributesUpdated['listitem'] = data.listitem;
                  }
                  attributeGroup[data.attribute_name] = new FormControl('');
                  fieldGroup['attData'].push(new FormGroup(attributeGroup));
                  fieldUpdated['attData'].push(attributesUpdated);
                }
                if(index == input_template.value.length-1) {
                  group['attributesFieldsArray'].push(new FormGroup(fieldGroup));
                  newGroup['updatedArray'].push(fieldUpdated)
                }
              }

          })


          data = new FormGroup(group);
          this.fieldsArray.push(data);
          this.updatedArray.push(newGroup);
        })
        resolve(true)
      } else {
        this.allData = [];
      }
    }, error => {
      reject(false)
      this.ngxSpinnerService.hide();
      this.allData = [];
    });
  })
 }

 populateDefaultData() {
  this.ngxSpinnerService.show();
  this.cb.getDefaultFormData(this.companyId, this.themeId, this.templateObj[0].templateid, this.journeyId).subscribe(data => {
    this.ngxSpinnerService.hide();
  //  console.log(data);
    if(data.status) {
    //  console.log('Default Data', data.data[0].email_default_value);
       this.fieldData = JSON.parse(data.data[0].email_default_value);
       if(this.fieldData) {
        this.setFormFieldValue(this.fieldData.fieldArray)
       return this.fieldData;
      }
    }
  })
 }

 processFile(event: any, imageInput: any,j:number,i:number,name) {
  const file: File = imageInput.files[0];
  if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
    this.toastrService.error("Please select only (png, jpg or jpeg) files");
  } else {
    const reader = new FileReader();
    reader.onload = (event1) => {
      this.toUploadFiles(file.name, reader.result).then(data => {
        this.fieldsArray.controls[j]['controls'].attributesFieldsArray.controls[i].controls[name].setValue(this.them_awskey);
        this.ngxSpinnerService.hide();
      }).catch(err => {
        this.fieldsArray.controls[j]['controls'].attributesFieldsArray.controls[i].controls[name].setValue('');
        this.ngxSpinnerService.hide();
      })
    }
    reader.readAsDataURL(file);
  }
}

toUploadFiles(name,file) {
  let self = this;
  this.ngxSpinnerService.show();
  return new Promise((resolve, reject) => {
    this.upload.uploadImage(name,"images",file,2).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Location) {
            self.them_awskey = params.Location;
            resolve(self.them_awskey);
          }
        }else{
          reject();
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        reject();
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
    });
}

setValue(event) {
  let self = this;
  let url = event.target.src;
  if (url.indexOf("master_images") > 0) {
    url = url.substring(url.indexOf("master_images"), url.length)
    if (!this.isUrlValid(url)) {
      let awsUrl = self.master.getUrlfile(url);
      self.them_awskey = url;
      event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
  } else {
    event.target.src = '../../../assets/custom/images/broken.png';
  }
  event.target.onerror = null;
}

isUrlValid(userInput) {
  return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
}


onSubmit() {
  this.submitted = true;
  if(this.myFormGroup.invalid) {
    return;
  }

  this.fieldsArray.value.forEach((element, index) => {
    let css = '';
    let section_name = element.section_name;
    element.attributesFieldsArray.forEach((element,index1) => {
      let fieldName = this.updatedArray[index].updatedArray[index1].field_name;

      element.attData.forEach((element, index2) => {
        for(var key in element) {
          if(element.hasOwnProperty(key) && this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1]['controls'].attData.controls[index2].controls[key].value)
            css = css + key+':'+this.fieldsArray.controls[index]
            ['controls'].attributesFieldsArray.controls[index1]['controls'].attData.controls[index2].controls[key].value+';';

          }
      });

      if(fieldName == 'SectionProperty' && this.fieldsArray.controls[index]['controls']['css_'+section_name]) {
        this.fieldsArray.controls[index]['controls']['css_'+section_name].setValue(css);
      }
      else if(this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['css_'+fieldName]){
        this.fieldsArray.controls[index]['controls'].attributesFieldsArray.controls[index1].controls['css_'+fieldName].setValue(css);
      }
    css = '';
    });

  });
  let marketingData = JSON.parse(JSON.stringify(this.myFormGroup.value))
  marketingData.fieldArray.forEach(element => {

    element.attributesFieldsArray.forEach((data,index) => {
      delete data.attData;
    });
  });

  if(!this.id) {
    // this.myFormGroup.removeControl('ttaid');
    const data = {
      company: this.companyId,
      themeid: this.themeId,
      journeyid: this.journeyId,
      templateid: this.templateObj[0].templateid,
      fieldvalue: this.myFormGroup.value,
      removedData: marketingData,
      ttaid: this.myFormGroup.controls.ttaid.value,
      ttfaid: this.myFormGroup.controls.ttfaid.value
    }
      this.ngxSpinnerService.show();
      //this.router.navigate(['/corporate/cb-form-preview'])

      this.cb.addingDynamicForm(data).subscribe(data => {
      this.ngxSpinnerService.hide();
        if (data.status) {
          this.toastrService.success("Added Successfully")
          this.router.navigate(['/corporate/cb-form-builder-list'])
        } else {
          this.toastrService.error(data.message)
        }
      }, error => {
       // console.log(error);
       this.ngxSpinnerService.hide();
        this.toastrService.error('Something went wrong')
      });
  } else if(this.id) {
    const data = {
      company: this.companyId,
      themeid: this.themeId,
      journeyid: this.journeyId,
      templateid: this.templateId,
      ttaid: this.myFormGroup.controls.ttaid.value,
      ttfaid: this.myFormGroup.controls.ttfaid.value,
      fieldvalue: this.myFormGroup.value,
      template_preview : this.templatePreview,
      removedData: marketingData
    }
      this.ngxSpinnerService.show();
      this.cb.updateDynamicForm(data).subscribe(data => {
      this.ngxSpinnerService.hide();

        if (data.status) {
          this.toastrService.success('Updated Successfully');
          this.router.navigate(['/corporate/cb-form-builder-list']);
        } else {
          this.toastrService.warning(data.message);
        }
      }, error => {
       // console.log(error);
       this.ngxSpinnerService.hide();
        this.toastrService.error('Something went wrong');
      });
  }

}

//  ##############  Populate Form by Clone Data  ################  //
  populateFormByCloneData(val){
    if (val === '') {
      this.selectedClone = null;
    }
    if (val) {
      this.ngxSpinnerService.show();
      this.myFormGroup.controls.emailSubject.setValue('');
      this.cb.getPopulateData(this.companyId, val)
        .subscribe(data => {
        //  console.log(data);
          if(data.status) {

            this.selectedClone = data.data[0].template_preview;
            this.fieldData = JSON.parse(data.data[0].fieldvalue);
         //   console.log(this.fieldData);
          //  alert(this.fieldData.emailSubject);
            if (this.fieldData.emailSubject !== undefined ){
              this.myFormGroup.controls.emailSubject.setValue(this.fieldData.emailSubject);
            }
          //  console.log('Email Subject', this.myFormGroup.value.emailSubject);

            if (this.fieldData) {
              this.fieldsArray.patchValue(this.fieldData.fieldArray);
              //  return this.fieldData;
              this.ngxSpinnerService.hide();
            }
          } else {
            this.ngxSpinnerService.hide();
          }
        }, error => {
          this.ngxSpinnerService.hide();
          this.toastrService.error('Something went wrong');
        });
    }
  }

  previewForm(modalName){


    this.formChildData = this.selectedClone;
    let self = this;
    self.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    //  console.log("reason", reason);
    });

  }
  setFormFieldValue(fieldData){
    fieldData.forEach((obj, index) => {
      Object.entries(obj).forEach(([key, value]:any) => {
        if(this.fieldsArray.controls[index].get('section_name').value==obj['section_name']){
          if(key!='attributesFieldsArray'){
            this.fieldsArray.controls[index].get(key).patchValue(value);
          }else{
            value.forEach((obj2, index2) => {
              Object.entries(obj2).forEach(([key2, value2]:any) => {
                if(key2!='attData'){
                    this.fieldsArray.controls[index].get(key)?.get(`${index2}`)?.get(key2).patchValue(value2)
                  }else{
                    if(value2 && (typeof value2=='object') && value2.length>0){
                      value2.forEach((obj3, index3) => {
                        Object.entries(obj3).forEach(([key3, value3]:any) => {
                          if(value3){
                            let attData=this.fieldsArray.controls[index].get(key)?.get(`${index2}`)?.get(key2) as FormArray;
                            attData.controls.forEach(control => {
                              const formGroup = control as FormGroup;
                              if (formGroup.get(key3)) {
                                formGroup.patchValue({ [key3]: value3 });
                              }
                            });
                          }
                        })
                      })
                    }
                  }
              })
            })
          }
        }
      });
    });
  }
}
