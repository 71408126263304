import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DsuserService } from 'src/app/services/users/dsuser.service'
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import {LicenseService} from '../../../../services/license/license.service';
import { MasterService } from 'src/app/services/master/master.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-addnewseller',
  templateUrl: './addnewseller.component.html',
  styleUrls: ['./addnewseller.component.css']
})
export class AddnewsellerComponent extends BaseComponent implements OnInit {
  header = "Add New"
  dsRegisterForm: FormGroup;
  loading = false;
  submitted = false;
  isactive = "true"

  CustomerData;
  UsertypeData;
  customerID;
  random = Date.now().toString(36);
  chatUsers: any = [];
  showChatUsers: boolean = false;
  reportingID=0;
  userLevel=1;
  allBundleList: any = [];
  allCountryCode: any = [];
  cp = 1;
  showBussinessDomain: boolean = false;
  businessDomainData: any;
  company_id: any;
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'domain_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText:'Please select different customer id',
    maxHeight:150
  };
  constructor(
    private duserregisterService: DsuserService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,private LS: LicenseService,
    private masterServie: MasterService
  ) {
    super();
  }
  get f() { return this.dsRegisterForm.controls; }
  ngOnInit() {
    this.ngxSpinnerService.show();

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    if( this.customerID ==0) this.getDsCustomerdropdown();
    if( this.customerID >0) { this.loadBundles(this.customerID);}
    this.getCountryCode();

    this.dsRegisterForm = this.formBuilder.group({
      username: [''],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bundleid: ['', [Validators.required]],

      image: [''],
      table: [''],
      uid: [''],
      cid: [''],
      usertype: [''],
      useruniqueid: [this.random],
      isChat: [2],
      reportingUser: [''],
      isDirector: [0],
      is_independent_ds:[false,[Validators.required]],
      business_domain_id:[''],
      planType: ['', [Validators.required]],
      // countryCode: [''],
      user_home_phone : ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]]

    });


  }
  addNewDsUser() {
    this.submitted = true;

    if(this.f.is_independent_ds.value==true && this.f.business_domain_id.value ==''){
      this.dsRegisterForm.controls['business_domain_id'].setErrors({'required': true});
    }else{
      
    }
    if (this.dsRegisterForm.invalid) {
      return;
    }
    let fn = this.f.fname.value.trim();
    if (fn === '' || fn === undefined || fn === null ){
      this.toastrService.error('Please input correct First Name');
      return;
    }

    let ln = this.f.lname.value.trim();
    if (ln === '' || ln === undefined || ln === null ){
      this.toastrService.error('Please input correct Last Name');
      return;
    }
    let domainArr=[]
    if(this.f.is_independent_ds.value==true && this.f.business_domain_id.value !='' ){
       domainArr =  this.f.business_domain_id.value.map((e:any)=>{
        return e.id
      })
    }

    let addUser = {
      username: Date.now().toString(36),
      email: this.f.email.value,
      user_fname: this.f.fname.value,
      user_lname: this.f.lname.value,
      profile_image: this.f.image.value,
      tbl_squence: Number.parseInt(this.f.table.value),
      customer_id: Number.parseInt(this.f.cid.value),
      user_type: Number.parseInt(this.f.usertype.value),
      user_id: Number.parseInt(this.f.uid.value),
      isactive: this.isactive,
      userid_unique: Date.now().toString(36),
      roleid: 76,
      isChat:this.f.isChat.value,
      reportingUser: 0,
      isDirector:this.f.isDirector.value,
      user_privilege_level: 0,
      bundleid:this.f.bundleid.value,
      user_mobile_phone : this.f.user_home_phone.value,
      is_independent_ds: this.f.is_independent_ds.value,
      business_domain_id:domainArr,
      plantype:this.f.planType.value

    }

    // console.log("add user", addUser);
    // return;
      this.ngxSpinnerService.show();
        this.duserregisterService.createUser(addUser).subscribe(data => {
          if (data.status) {
            this.toastrService.success("Please verify your E-mail To Activate Your Account");
            this.router.navigateByUrl(`/corporate/dsuser`);
          } else {
            this.toastrService.error(data.message)
          }
          this.ngxSpinnerService.hide();
        }, error => {
         // console.log(error);
          this.ngxSpinnerService.hide();
        })




  }

  loadBundles(id) {
    this.ngxSpinnerService.show();
    this.LS.getAllBundles(id, this.cp).subscribe((data2: any) => {
      if (data2.status) {
        this.allBundleList = data2.data;
        this.ngxSpinnerService.hide();
      }else this.ngxSpinnerService.hide();
    });
    this.company_id=id;
    this.getBussinessDomain(id);
  }

  getCountryCode() {
    this.ngxSpinnerService.show();
    this.LS.getCountryCode().subscribe((data2: any) => {
      if (data2.status) {
        this.allCountryCode = data2.data;
        this.ngxSpinnerService.hide();
      }else this.ngxSpinnerService.hide();
    });

  }

  getDsCustomerdropdown() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.CustomerData = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  getDsUserTypedropdown() {
    let self = this;
    this.registerService.getType().subscribe(data => {
      self.UsertypeData = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }
  radioChange(status) {


   // console.log(status);
    if (status == 1) {


      this.showChatUsers = true;


    }
    else { this.showChatUsers = false; }
  }
  getBussinessDomain(id){
    this.masterServie.getBusinessDomain(10,0,id).subscribe(data => {
      this.businessDomainData = data.data.rows.filter((domain:any)=>{
        return domain.is_active==true
      })
      
    }, error => {
     console.log(error);
    })
  }

  independentDsChange(status:any){
    if (status == 1) {
      this.showBussinessDomain = true;
     
    }
    else {
      this.showBussinessDomain = false;
      this.dsRegisterForm.controls['business_domain_id'].setValue('') ;
    }
  }

}
