import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class ShopLiveService {
  public baseUrl;
  public baseUrlEvent;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/shoplive/';
    this.baseUrlEvent = baseService.baseUrl1 + 'api/event/';
  }

  // getCategoryList(companyId) {
  //   return this.http.get<any>(this.baseUrl + `getShopLiveCategory/${companyId}`, this.httpHeadersOptions())
  //     .pipe(
  //       map(data => {
  //         if (!data.status) {
  //           if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
  //             console.error(data.message);
  //             data.message = "Something went wrong.Please try again."
  //           }
  //         }
  //         return data;
  //       }),
  //       catchError(this.handleError));
  // }


  getCategoryList(companyId, limit, skip, colname:any = 'sl_cat_name', order:any='asc', status, searchText) {
    return this.http.get<any>(this.baseUrl + `getShopLiveCategory/${companyId}/${limit}/${skip}?sort=${colname}:${order}&status=${status}&search=${searchText}`, this.httpHeadersOptions())

      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addNewCategory(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingShopLiveCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getSLCategoryById(id, company) {
    return this.http.get<any>(this.baseUrl + `getShopLiveCategoryById/${id}/${company}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateCategory(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "updateShopLiveCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateCategoryStatus(id, company, body) {
    return this.http.post<any>(this.baseUrl + `updateshopLiveCatStatus/${id}/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delCategory(id, company) {
    return this.http.post<any>(this.baseUrl + `deleteshopLiveCategory/${id}/${company}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  cancelCat(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'cancelShopLiveCategory', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {

        }
        return data;
      }),
      catchError(this.handleError));
  }

  updatepostincategory(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'updateShopLiveProductCat', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {

        }
        return data;
      }),
      catchError(this.handleError));
  }

  getUnusedCategory(body) {
    return this.http.post<any>(this.baseUrl + 'getUnusedShopLiveCategory', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {
          // if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
          //   console.error(data.message);
          //   data.message = "Something went wrong.Please try again."
          // }
        }
        return data;
      }),
      catchError(this.handleError));
  }

  getProductList(companyId, limit, skip, colname, colname_val, searchText, catid,business_domain_id?:any) {
    return this.http.get<any>(this.baseUrl + `product/get/${companyId}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}&filter=&catId=${catid}&business_domain_id=${business_domain_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addNewProduct(companyId, value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `product/add/${companyId}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateProduct(id, company, body) {
    var body = body;
    return this.http.post<any>(this.baseUrl + `product/update/${id}/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delproduct(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "post/" + value.company + "/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCorporatePostByID(catID, company) {
    return this.http.get<any>(this.baseUrl + `product/getById/${catID}/${company}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  editCorporatePost(company, body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + `product/updateProduct/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  // Manage event APIs
  getCustomerListdrp(company) {
    return this.http.get<any>(this.baseUrlEvent + `contact/list/${company}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  checkEventIdExists(body:any,company:any) {
    return this.http.post<any>(this.baseUrlEvent + `checkEventIdExists/${company}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getThemeAssosciationBycidpid(company:any,productId:any,journeyId:any,limit:any,skip:any) {
    return this.http.get<any>(this.baseUrlEvent + `getThemeAssosciationBycidpid/${company}/${productId}/${journeyId}/${limit}/${skip}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addNewEvent(body:any,company:any) {
    return this.http.post<any>(this.baseUrlEvent + `add/${company}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateEvent(body:any,company:any) {
    return this.http.put<any>(this.baseUrlEvent + `updateEvent/${company}`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEventById(company:any,eventId:any) {
    return this.http.get<any>(this.baseUrlEvent + `getEventByID/${company}/${eventId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEvents(company:any,limit:any,skip:any,search:any,typeFilter:any) {
    return this.http.get<any>(this.baseUrlEvent + `getEvents/${company}/${limit}/${skip}?search=${search}&eventTypeId=${typeFilter}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteEvent(eventId:any,company:any) {
    return this.http.delete<any>(this.baseUrlEvent + `delEvent/${company}/${eventId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateEventStatus(company:any,eventId:any,status:any) {
    return this.http.get<any>(this.baseUrlEvent + `cancelEvent/${company}/${eventId}/${status}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getShopLiveEventUrl(company:any){
    return this.http.get<any>(this.baseUrlEvent + `shoplive/url/${company}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  marketOrder(value, company) {
    var body = value;
    return this.http.post<any>(this.baseUrlEvent + `marketOrder/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  syncEvent(eventObj:any, company:any) {
    return this.http.post<any>(this.baseUrlEvent + `syncEvent/${company}`, { "event_id": eventObj.id },  this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
    
  }
  addEventHostCohostRel(value, company) {
    var body = value;
    return this.http.post<any>(this.baseUrlEvent + `addEventHostCohostRel/${company}`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getTimeZoneByValue(value) {
    var body = { "value": value }
    return this.http.post<any>(this.baseUrlEvent + `getTimeZoneByValue`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEventInviteeDetail(company:any,eventId:any) {
    return this.http.get<any>(this.baseUrlEvent + `getEvents-details/${company}/${eventId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
