<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/segmentation-table">Segmentation Table</a>
      </li>
      <li *ngIf="!table_id" class="breadcrumb-item active" aria-current="page">
        Add New Table
      </li>
      <li *ngIf="table_id" class="breadcrumb-item active" aria-current="page">
        Edit Table
      </li>
    </ol>
  </nav>
</div>
<div class="container mt-2">
  <form class="form-horizontal mt-1" [formGroup]="tableForm">
      <div class="row">
          <div class="col-md-10 col-xl-7">

          
          <div class="card">
              <div class="card-body">
           
                <div class="mt-1">
                  <label for="exampleForm2"> Select Company <span style="color:red">*</span></label>
                  <select name="company" class="form-control formstyle" formControlName="company"
                   [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <option value=0>Select Company Name</option>
                    <ng-container *ngFor="let userObj of getCompanyobj" >
                      <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                          {{userObj.customer_company_name}}
                      </option>
                    </ng-container>
                  </select>
                  <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Company is required</div>
                  </div>

              </div>

              <div class="mt-1">
                  <label for="exampleForm2"> Table Name  <span style="color:red">*</span></label>
                  <input type="text" id="exampleForm2" formControlName="table_name"
                      class="form-control form-control-md" required
                      [ngClass]="{ 'is-invalid': submitted && f.table_name.errors }" maxlength="80" appSpace >
                  <div *ngIf="submitted && f.table_name.errors" class="invalid-feedback">
                      <div *ngIf="f.table_name.errors.required || f.table_name.hasError('whitespace')"> Name is required</div>
                       <div *ngIf="f.table_name.errors?.maxlength">You can enter only 80 charachters</div>
              
                
                  </div>
              </div>
              <div class="mt-1">
                  <label for="exampleForm2"> Table Description</label>
                  <input type="text"  formControlName="table_desc"
                      class="form-control form-control-md">
              </div>
              
              <div class="mt-1">
                <label for="exampleForm2"> Table Alias <span style="color:red">*</span></label>
                <input type="text" id="exampleForm2" formControlName="table_alias"
                    class="form-control form-control-md" required
                    [ngClass]="{ 'is-invalid': submitted && f.table_alias.errors }" maxlength="80" appSpace >
                <div *ngIf="submitted && f.table_alias.errors" class="invalid-feedback">
                    <div *ngIf="f.table_alias.errors.required || f.table_alias.hasError('whitespace')"> Table Alias is required</div>
                     <div *ngIf="f.table_alias.errors?.maxlength">You can enter only 80 charachters</div>
            
              
                </div>
            </div>

            <!-- <div class="mt-1">
              <label for="exampleForm2"> Table Section</label>

              <select
              formControlName="section_id"
              autocomplete="off"
              class="form-control formstyle"
              placeholder=""
            
            >
              <option  value="1">Segment</option>
              <option  value="2">Metadata</option>
              <option  value="3">Both</option>
            </select>
              
            
            
          </div> -->
          <div class="mt-1">
          <label for="attrForm"
          >Table Type
          </label>
          <select
            formControlName="table_type"
            autocomplete="off"
            class="form-control formstyle"
            placeholder=""
            disabled
          >
            <option  value="0">Mandatory</option>
            <option  value="1">Optional</option>
            
          </select>
              
          </div>
          <div class="card-footer">
              <div class="text-center my_class " *ngIf="isWrite" [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
                  <button type="button" class="custbtn" (click)="addTable()">Submit</button>
              </div>
              <div class="text-center my_class " *ngIf="isEdit" [ngClass]="actionStatus ? 'displaynone' :'displayblock'  ">
                  <button type="button" class="custbtn" (click)="UpdateTable()">Update</button>
              </div>
          </div>
          </div>
      </div>
      </div>
      </div>
  </form>
</div>