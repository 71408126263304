<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/segmentation">Segmentation Master</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Segmentation Master Home
      </li>

    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon" *ngIf="id === 0">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Audience and Entities</h5></legend>
    <div class="row">
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/audience">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Audience
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/entities">
          <i class="fa fa-th-large orange-dr-icon" aria-hidden="true"></i>Entities
        </button>
      </div>
   </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon" *ngIf="id === 0">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Attributes</h5></legend>
    <div class="row">
     <!-- <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/rolesmaster">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Segment Module
        </button>
      </div>
      -->
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/segmentation-attribute">
          <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>Attributes
        </button>
      </div>

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/attribute-group">
          <i class="fa fa-unlock-alt red-dr-icon" aria-hidden="true"></i>Attribute Group</button>
      </div>
<!--      <div class="col-xl-4  col-md-6">-->

<!--        <button class="cbtn mt-1" routerLink="/corporate/attribute-type">-->
<!--          <i class="fa fa-list-alt blue-sky-icon" aria-hidden="true"></i>Attribute Type-->
<!--        </button>-->
<!--      </div>-->

    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon" *ngIf="id === 0">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Operators</h5></legend>
    <div class="row">

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/operator">
          <i class="fa fa-crosshairs orange-dr-icon" aria-hidden="true"></i>Operator
        </button>
      </div>
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/operator-group">
          <i class="fa fa fa-file-text-o green-dr-icon" aria-hidden="true"></i>Operator Group
        </button>
      </div>

    </div>
  </fieldset>
</div>



<div class="container mt-2 theme-fa-icon" *ngIf="id === 0">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Association</h5></legend>

    <div class="row">

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/entities-audience-association-list">
          <i class="fa fa-user-plus purple-dr-icon" aria-hidden="true"></i>Audience & Entity
        </button>
      </div>

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/entities-attribute-association-list">
          <i class="fa fa-arrow-right blue-sky-icon" aria-hidden="true"></i>Entities & Attribute
        </button>
      </div>

      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/attribute-operator-association-list">
          <i class="fa fa-object-group green-dr-icon" aria-hidden="true"></i>Attributes & Operator
        </button>
      </div>

    </div>
  </fieldset>
</div>



<div class="container mt-2 theme-fa-icon" *ngIf="id === 0">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Tables</h5></legend>

    <div class="row">
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/segmentation-table">
          <i class="fa fa fa-file-text-o green-dr-icon" aria-hidden="true"></i>BU Tables
        </button>
      </div>
    </div>
  </fieldset>
</div>

