import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MasterService} from '../../../services/master/master.service';
import {ClientService} from '../../../services/clients/client.service';
import {AlertService} from '../../../_services/alert.service';
import { ActivatedRoute } from '@angular/router';




@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.css']
})
export class NotesComponent extends BaseComponent implements OnInit {

  notesForm: FormGroup;
  companyID;
  company_ID;
  submitted: boolean;
  currentUser;
  getCompanyobj;
  selectedCompany: any = null;
  status: boolean = true;
  controlEnabled:boolean=true;
  constructor(private master: MasterService, private clientService: ClientService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    super();
  }

    ngOnInit() {
      this.controlEnabled = true;
      this.company_ID = this.route.snapshot.queryParamMap.get('companyID');
      //console.log("companyID==",this.company_ID)
      if(this.company_ID!=undefined && this.company_ID!=null && this.company_ID!=''){
        this.master.getCompanyNotesDetails(this.company_ID).subscribe((data: any) => {
          if (data.status) {
            this.selectedCompany = data.data[0];
            this.notesForm.get('company').patchValue(this.company_ID);
            this.notesForm.get('allowedlength').patchValue(this.selectedCompany.note_length);
            this.notesForm.get('companyStatus').patchValue(this.selectedCompany.isactive);
          }
          this.ngxSpinnerService.hide();
        });
      }
      
      this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
      this.companyID = this.currentUser.admin_customer_id;

      this.notesForm = this.formBuilder.group({
        company: ['', Validators.required],
        allowedlength: ['', Validators.required],
        companyStatus: ['', Validators.required]
      });

      this.submitted = false;

      this.clientService.getBUCompanies().subscribe(data => {
        this.getCompanyobj = data.data;
      }, error => {
       // console.log(error);
      });

    }

  get f() { return this.notesForm.controls; }

  //   ###############################  Describe/Add Note Attributes  ################  //

  addNote() {
    this.submitted = true;

    if (this.notesForm.invalid) {
      return;
    }
    this.ngxSpinnerService.show();
    const obj = {
      lengthNum: this.notesForm.value.allowedlength,
      company: parseInt(this.notesForm.value.company, 10),
      isactive: this.notesForm.value.companyStatus
    };

    this.master.updateCompanyNoteDetails(obj).subscribe((data: any) => {
      if (data.status) {
        this.notesForm.reset();
        this.submitted = false;
        this.selectedCompany = null;
        this.toastrService.success('Notes updated Successfully');
        this.router.navigate(['/corporate/notes-list'])
      } else {
        this.toastrService.error('Can`t update Notes at this moment');
      }
      this.ngxSpinnerService.hide();
    });
  }

  //  ###############################  On Company Selected from Dropdown  ##################  //

  onCompanySelected() {
    this.ngxSpinnerService.show();
   // this.notesForm.get('allowedlength').reset();
    this.master.getCompanyNotesDetails(this.notesForm.value.company).subscribe((data: any) => {
      if (data.status) {
        this.selectedCompany = data.data[0];
        this.notesForm.get('allowedlength').patchValue(this.selectedCompany.note_length);
        this.notesForm.get('companyStatus').patchValue(this.selectedCompany.isactive);
        // this.notesForm.get('company').patchValue(this.companyID);
      }else{
        this.toastrService.error("No Result Available");
      }
      this.ngxSpinnerService.hide();
    });
  }

}
