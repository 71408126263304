
//Help and Tips - Service Cloud
export class KnowledgeArticle {
    id: string = "";
    title: string = "";
    summary: string = "";
}

export class KnowledgeArticleLayoutDetail {
    title: string = "";
    summary: string = "";
    answer: string = "";
}

//Support - Service Cloud
export class Case {
    subject: string = "";
    Origin: string = "Web";//Web or Email or Phone
    Status: string = "New";//New or Closed or Escalated
    Description: string="";
    SuppliedEmail:string="";//Email address on which Acknowledgement Email will be triggred(Case label: Web Email) 
    SuppliedName:string="";//Salutation Name on Acknowledgement Email(Case label: Web Name)
    Case_Originated_App__c:string="marGo-Admin";
}