<div class="container h-100 app-content">
  <div class="row h-100 justify-content-center align-items-center mt--4">
      <div class="col-md-4 col-sm-8 col-11">
          <div class="text-center mainLogLogo">
              <img src="../../assets/custom/images/margologo250.png" alt="branding logo">
          </div>
          
          <form class="form-horizontal text-center mt-1" [formGroup]="forgotForm" (ngSubmit)="forgotUserName()">
            <input type="text" formControlName="email" class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Please Enter Your Email">

            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is Required</div>
            </div>
            <div class="text-center">
              <button type="submit" class="custbtn mt-3">
                Submit
              </button>
              <button type="button" class="custbtn mt-2" routerLink="/login">
                Cancel
            </button>
            </div>
        
          </form>
        </div>
      </div>

  </div>
