import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import {CommonMessages,ClientMessages} from 'src/app/shared/messages/messages.enum';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent extends BaseComponent implements OnInit {
  public assetObj: any = [];
  cp = 1;
  ord = true;
  colname: "Asset Name";
  text;
  checked = false;

  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService
  ) { 
    super();
  }
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
    this.getAsset();
  }
  getAsset() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getAsset().subscribe(data => {
      self.assetObj = data;
     // console.log(self.assetObj);
    //  console.log(data);
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  editAsset(id) {
    this.router.navigate(['/corporate/newasset'], { queryParams: { assestsid: id } });
  }
  deleteAsset(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delAsset(obj).subscribe(data => {
            if (data.status) {
              this.getAsset();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateAssetStatus(event, id) {
    let value = event.source.checked;
    let obj = this.assetObj.find(element => {
      if (element.assestsid === id) {
        return true;
      }
    });
    obj = obj;
    obj.isactive = value;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', CommonMessages.activeInactiveConfirmation +" '"+ (value? "Active": "Inactive") +"'", 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {

          let assetObj = {
            
            isactive: obj.isactive,
            assestsid: id
          }
          this.master.updateAssetStatus(obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success(CommonMessages.statusUpdated);
            }
          }, error => {
            console.error(error);
          });





          
        }
      }).catch(() => {
        event.source.checked = !event.source.checked;
       // console.log(CommonMessages.dialogDismissMessage)
      });
  }
  clickEvent() { }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-theme-import.csv`, 'addingThemeBulk')
      .then(data => {
       // console.log(data);
        self.getAsset();
      })
      .catch(err => {
        console.error(err);
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_assests_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
