import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-support',
  templateUrl: './supportuser.component.html',
  styleUrls: ['./supportuser.component.css']
})
export class SupportUserComponent extends BaseComponent implements OnInit {
  currentUser;

  constructor() { super(); }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('userData'));
  }

}
