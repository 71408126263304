import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
@Component({
  selector: 'app-new-themeassociation',
  templateUrl: './new-themeassociation.component.html',
  styleUrls: ['./new-themeassociation.component.css']
})
export class NewThemeassociationComponent extends BaseComponent implements OnInit {
  getCompanyobj = [];
  customerID = '';
  companyId='';
  customerInfo: any;
  form: FormGroup
  submitted: boolean = false;
  JourneyId = "";
  ThemeId = "";
  isactive = true;
  ProductId: any = 0;

  shopProductList: any = 0;

  journeyDropDownId:any;
  themeDropDownId:any;
  page: any = 'master';
  domainData: any=[];
  domainId: any='';
  showProduct:boolean = false;
  constructor(private clientService: ClientService,
    private master: MasterService,
    private clientsService: ClientService) { super(); }

  ngOnInit() {
    this.page = this.master.getCataloguePage();
    this.ngxSpinnerService.show();

    this.customerInfo = JSON.parse(sessionStorage.getItem("userData"));


    if (this.customerInfo) {
        this.customerID = this.customerInfo.admin_customer_id;
    }
    if (this.customerInfo.admin_customer_id != '0') {
      this.getJourneyDropDown();
    }
    else {
      this.getCompany();
    }
  }


  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
       console.log(error);
    });
  }

  getJourneyDropDown() {
    let self = this;
    if(!this.companyId){
      self.journeyDropDownId = [];
      self.themeDropDownId=[];
      return}
    if(this.page=='direct-seller'){
      this.getBusinessDomain();
      this.JourneyId='70';
    }

    this.ngxSpinnerService.show();
    this.master.getCompanyJourney(this.companyId).subscribe(data => {
      self.journeyDropDownId = data;
      self.themeDropDownId=[];
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  applySelectFilter(event) {
    if(event.value==''){
      this.themeDropDownId=[];
      this.shopProductList=[];
      return;
    }
    // if (event.value != 70) {
    //   this.ProductId = 0;
    // }
    // if(this.page=='master'){
    //   this.onShopSelectProductList();
    //   this.getThemeDropDown();
    // }

    // new changes
    let journeyEle = this.journeyDropDownId.find((ele: any) => {
      return  ele.journey_id == this.JourneyId;
    })
    
    if (journeyEle && journeyEle.isproduct == true) {
      this.showProduct = true;
    } 
    else{
      this.ProductId = 0;
      this.showProduct = false;
    }
    if(this.page=='master'){
      this.onShopSelectProductList();
      this.getThemeDropDown();
    }
  }

  getThemeDropDown() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getCompanyTheme(this.companyId,9999,0,'theme_name','asc','','all',this.domainId).subscribe(data => {
      if (data.status) {
        self.themeDropDownId = data.data.rows;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      //  console.log(error);
    });
  }

  onShopSelectProductList() {
    let self = this;
    this.ngxSpinnerService.show();
    this.clientsService.getGroupProductList(this.companyId,this.domainId).subscribe(data => {
      if (data.status) {
        self.shopProductList = data.data;
      }
      this.ngxSpinnerService.hide();
    }, error => {
    });
  }


  addnewThemeAssociation() {
    if (this.JourneyId == "" || this.ThemeId == "") {
      this.toastrService.error("Please select journey and theme");
      return true;
    }
    // if (this.JourneyId == "70" && this.ProductId == 0) {
    //   this.toastrService.error("Please select product name")
    // }
    if (this.showProduct && this.ProductId == 0) {
      this.toastrService.error("Please select product name")
    }
    else {
      let themeassobj = {
        customerId: this.companyId,
        journeyId: this.JourneyId,
        themeId: this.ThemeId,
        isActive: this.isactive,
        productid: this.ProductId,
        company: this.companyId,
      }

      this.ngxSpinnerService.show();
      this.clientsService.addingNewThemeAssociation(themeassobj).subscribe(data => {
        if (data.status) {
          this.toastrService.success("Added Successfully")
          this.ThemeId = "";
          this.JourneyId = "";
          this.ProductId = 0;
          this.router.navigateByUrl('/corporate/themeassociation');
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.ngxSpinnerService.hide();
        console.log(error)
      });
    }
  }
  getBusinessDomain(){
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getBusinessDomain(100,0,this.companyId).subscribe(data => {
      self.domainData = data.data.rows;
      this.ngxSpinnerService.hide();
    }, error => {
      this.domainData=[];
      this.ngxSpinnerService.hide();
    });
}
  listByDomainName(id:any){
    this.domainId=id;
    this.onShopSelectProductList();
    this.getThemeDropDown();
  }
}
