import { BaseComponent } from '../../base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AudienceBuilderService } from '../../../services/segmentation/audience-builder.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-bu-audience',
  templateUrl: './test-bu-audience.component.html',
  styleUrls: ['./test-bu-audience.component.css']
})
export class TestBuAudienceComponent extends BaseComponent implements OnInit, OnDestroy {

  queryContactField: FormControl = new FormControl();
  search = '';
  companyId: any;
  customerID = 0;
  audId: any;
  audData: any;
  audienceList: any;
  show = false;
  limit = 10;
  offset = 0;
  cp = 1;
  totalItems = 0;
  subscription: any;
  campaign;
  constructor(private audienceBuilderService: AudienceBuilderService) {
    super();

    let uData = JSON.parse(localStorage.getItem('userData'));

    if (localStorage.getItem('companyId')) {
      this.companyId = localStorage.getItem('companyId');
    } else {
      this.companyId = parseInt(uData.admin_customer_id, 10);
    }

    this.subscription = this.activatedRoute.queryParams
      .subscribe(params => {
        this.audId = params.audid;

        this.campaign = params.campaign

        //  console.log('PARAMS', params);
        if (this.audId) {
          //this.fetchData();
          this.searchSubscribe();
        } else {
          this.router.navigateByUrl('/corporate/audience-builder');
        }
      });

  }

  ngOnInit() {
    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    
  }

  //  #############  Subscribe to Search box value change ##########################   //

  searchSubscribe() {
    this.queryContactField.valueChanges
      .pipe(
        map((query) => query),
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        })
      )
      .subscribe(query => {
        this.fetchData();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }

  //  #############  Fetch Data ####################################################  //

  fetchData() {
    this.offset = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.companyId,
      'audid': this.audId,
      'limit': this.limit,
      'offset': this.offset,
      'search': this.search
    };
    this.audienceBuilderService.getAudList(obj).subscribe(data => {
      if (data.status) {
        this.audData = data.data;
        this.audData.result.sort(this.compare);
        this.totalItems = parseInt(this.audData.totalCount, 10);
      } else {

        this.audData = [];
        this.show = true;
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.show = true;
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #############  Click on Back Button to go to View Audience Page ##############  //

  goToViewAudience() {
    let URL = '/corporate/view-bu-built-audience' + '?audid=' + this.audId;
    this.router.navigateByUrl(URL);
  }

  //  ############# Click on Schedule Button to got to Schedule Audience Page ######  //

  goToScheduleAudience() {


    let URL = '/corporate/schedule-bu-built-audience' + '?edit=1&audid=' + this.audId;
    this.router.navigateByUrl(URL);
  }

  //  ##############  Compare function for Sorting ###########  //

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const nameA = a.contact_fname.toUpperCase();
    const nameB = b.contact_fname.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }


  donwloadCSV() {
    this.ngxSpinnerService.show();
    let body = {
      "company": this.companyId,
      "audid": this.audId,
      'search': this.search
    };
    this.audienceBuilderService.exportAudListCSV(body).subscribe(data => {
      if (data.status) {
        let URL = environment.segmentationApiUrl + data.data.file;

        window.URL.revokeObjectURL(URL);

        var hiddenElement = document.createElement('a');
        hiddenElement.href = encodeURI(URL);
        hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded  
        hiddenElement.download = '';
        hiddenElement.click();

        this.toastrService.success("CSV downloaded Sucessfully");
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
    });
  }

  //  ############## On Destroy  #############################  //

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
