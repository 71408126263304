import { Component, OnInit } from '@angular/core';
import { MbService } from 'src/app/services/fbservice/mb.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { LogsService } from 'src/app/services/adminlogs/logs.service';
import { MasterService } from 'src/app/services/master/master.service';
import { RegisterService } from 'src/app/services/users/register.service';
import {ClientService}  from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators ,AbstractControl} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';


@Component({
  selector: 'app-addnewtemplate',
  templateUrl: './addnewtemplate.component.html',
  styleUrls: ['./addnewtemplate.component.css']
})
export class AddnewtemplateComponent implements OnInit {
  // categoryid":1,
  // "campaignid":7,
  // "name":"test",
  // "description":"test",
  // "screenshot":"test",
  // "url":"test",
  // "isactive": true
  name;
  categoryid="";
  campaignid;
  url
  description="";
  post_aws_screenshot;
  isactive = true;
  companyid;
  status: boolean = true;
  campaignDataObj;
  postCategoryObj;
  post_id;
  postObj;
  fileData;
  fileUploaded;
  getCompanyobj;
  screenshot_type = true;
  customer_id;
  submitted = false;
  themeForm: FormGroup;
  imageURL;
  displayMessage;
  productData:any=[];
  productURL;
  imageCropData;
  imgButtonText;
  isImage: boolean;
  imageSrc;
  them_awskey;
  screenshot;
  selectedCompany;
newMessage;
imageFlag=0;
controlEnabled:boolean=true;
domainData:any[]=[]
  constructor(
    private mb: MbService,
    private toastrService: ToastrService,
    private router: Router,
    private logs: LogsService,
    private ar: ActivatedRoute,
    private master: MasterService,
    private registerService: RegisterService,
    private formBuilder: FormBuilder,
    private clientsService:ClientService,
    public spinner: NgxSpinnerService,
    private upload:UploadService
  ) { }

  ngOnInit() {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;

    this.controlEnabled = true;
    if(this.customer_id==0)  this.getCompany();
    if(this.customer_id!=0)  { this.selectedCompany = this.customer_id; this.getBusinessDomain(); this.getpostCategoryData(this.customer_id); }




    let self = this;




    //get item details using id
    if (localStorage.getItem("edit_post_id")) {

      this.post_id=  localStorage.getItem("edit_post_id");
      //this.customer_id =  localStorage.getItem("post_edit_company_id");
      this.selectedCompany= localStorage.getItem("post_edit_company_id");
      this.getCategory(this.selectedCompany);
      this.UpdatePostLogData();
      this.spinner.show();
      this.mb.getPostByPostID(this.selectedCompany,this.post_id).subscribe(response => {
        this.spinner.hide();
        if (response.status) {
          this.status = false;
          self.postObj = response.data;
         // console.log(self.postObj);
          self.dataBinding();
        } else {

        }
      });
    } else {
      this.addPostLogData();
    }

    this.themeForm = this.formBuilder.group({
      categoryid: ['', Validators.required],
      name: ['', [Validators.required,this.noWhitespaceValidator]],
      description: ['', [Validators.required,this.noWhitespaceValidator]],
      url: ['',Validators.pattern(AppConstants.regex.websiteURL)],
      post_screenshot: [''],
      PersonalField:[''],
      product_url: [''],
      firstActive: [0],
      selectedCompany:[(this.customer_id==0)?'':this.customer_id, Validators.required],
      mtype : ['sms',Validators.required],
      domain:[0]
    });
  }
  get f() { return this.themeForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  addPostLogData() {

    let PostLogs = {
      log_type: "Adding New Post",
      log_name: "Adding New Post",
      log_description: "Adding New Post",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(PostLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  getProductList() {


    this.clientsService.getCompanyProductList(this.customer_id, 9999, 0).subscribe(data => {

     if( data.status && data.data != null) this.productData = data.data.rows;
    }, error => {
     console.log(error);
    });
  }
  UpdatePostLogData() {

    let PostLogs = {
      log_type: "Updating Post Data",
      log_name: "Updating Post Data",
      log_description: "Updating Post Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(PostLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }
  dataBinding() {


   // console.log(this.postObj);

    this.imageSrc =  this.postObj.post_images[0];

    this.imageURL = this.postObj.post_screenshot;


    if(this.imageSrc) {
      let ext = this.imageSrc.toString().split('.');
      if(ext[1] !== 'mp4') {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
    }

   if(this.postObj.product_url) { this.productURL = this.postObj.product_url; this.getProductList(); }



    this.themeForm = this.formBuilder.group({
      categoryid: [this.postObj.category_id, Validators.required],

      name: [this.postObj.post_name, [Validators.required,this.noWhitespaceValidator]],
      description: [this.postObj.post_description, [Validators.required,this.noWhitespaceValidator]],
      url: [this.postObj.post_url],
      post_screenshot: [this.postObj.post_screenshot],
      firstActive: [(this.postObj.post_screenshot)?0:1],
      selectedCompany:[this.selectedCompany, Validators.required],
      product_url:[this.postObj.product_url],
      mtype : [this.postObj.message_type],
      domain:[this.postObj.domain]
    });
    this.them_awskey = this.postObj.post_screenshot;
    if(this.postObj.post_screenshot) this.screenshot_type = true; else  this.screenshot_type = false;



  }




  getCompany() {

    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }



  selectedOption(name) {

    if (name != 'undefined') {
      if (name === "product_link") {
        this.getProductList();
      }

      else {

        this.productData = [];
        this.description = this.getDisplayMessage(name,this.f.description.value);
        this.newMessage = false;
        this.f.description.setValue(this.description);
      }
     //
     // console.log("sss"+this.description);

    }

  }

  selectedProduct(url) {

   //this.description += ' ';
    this.f.description.setValue(this.f.description.value+' <product url>');
    this.productURL = url;
  }


  getDisplayMessage(name,  newMessage) {


    this.description = newMessage;

      if (name === "full name") {

        this.description += ' <your full name>';

      } else if (name === "signature") {
       this.description += ' <your signature>'

      } else if (name === "title") {
         this.description += ' <your title>';

      }
      else if (name === "business_name") {
        this.description += ' <business name>';

      }
      else if (name === "seller_firstname") {
         this.description += ' <your first name>';

      }
      else if (name === "seller_lastname") {
        this.description += ' <your last name>';

      }

      else if (name === "shopping_url") {
        this.description += ' <website link>';

      }

      if (name === "companyname") {
        this.description += ' <company name>'
      }

   // console.log(this.displayMessage);
    return this.description;
  }

  getCategory(company) {

     if(company) {
      this.getpostCategoryData(company);
      this.selectedCompany = company;
      this.getBusinessDomain();
    }else{
      this.domainData=[];
      this.f.domain.setValue(0);
    }

  }
  getpostCategoryData(company) {


    let self = this;


    if(company) {
      this.spinner.show();
    this.mb.getPostCategoryByCompanyID(company).subscribe(data => {
      self.postCategoryObj = data.data;
      this.spinner.hide();

    }, error => {
     // console.log(error);
    });

  }else { self.postCategoryObj=[];this.spinner.hide();}
  }
  addNewPostData() {

   // console.log("ssss");
    this.submitted = true;
   // console.log(this.themeForm);
    if (this.themeForm.invalid) {
      return;
    }
    let { categoryid, name, description, url,post_screenshot,mtype,domain } = this.themeForm.value;

   // console.log(this.themeForm.value);


    if(this.f.post_screenshot.value!='') {


      var urlregex = new RegExp(
        "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");


      let pattern = urlregex.test(this.f.post_screenshot.value);

      //console.log("ssss",pattern)

      if(!pattern) {
        this.toastrService.error("Enter Valid URL");
        return;
            }
      }

      var obj = {
        name: name,
        description: description,
        screenshot: post_screenshot,
        isactive: true,
        categoryid: categoryid,
        campaignid: '',
        company: this.selectedCompany,
        url: url,
        iscorporate: true,
        product_url: (this.productURL) ? this.productURL : '',
        post_images: [],
        post_imgCaptions: [],
        msg_description:description,
        post_videocaptions: [],
        post_videos: [],
        message_type : mtype,
        domain:domain
      }

      // console.log("onj", obj);

      if (this.them_awskey) {
        if (/\.(jpe?g|png|jpg|gif)$/i.test(this.them_awskey) === true) {
          obj.post_images.push(this.them_awskey);
        }
        else if (/\.(mov|mp4)$/i.test(this.them_awskey) === true) {
          obj.post_videos.push(this.them_awskey);
        }
      }

    // console.log('OBJECT', obj);
    this.spinner.show();
    this.mb.addNewPost(obj).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/templatemanagement`);
      } else {
        this.toastrService.error(data.message)
      }
    }, error => {
      this.toastrService.error(error.message)
    });
  }

  updatePost() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { categoryid, name, description, url,post_screenshot,mtype,domain} = this.themeForm.value;



      if(this.f.post_screenshot.value!='') {


        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");


        let pattern = urlregex.test(this.f.post_screenshot.value);

        //console.log("ssss",pattern)

        if(!pattern) {
          this.toastrService.error("Enter Valid URL");
          return;
              }
        }

    let campobj = {
      post_name: name,
      post_description: description,
      screenshot: (this.them_awskey=="")?post_screenshot:'',
      isactive: this.postObj.isactive,
      post_id: Number.parseInt(this.post_id, 10),
      category_id: categoryid,
      campaign_id: '',
      post_url: url,
      company: this.selectedCompany,
      iscorporate: true,
      product_url: (this.productURL) ? this.productURL : this.postObj.productURL,
      post_images: [],
      post_imgCaptions: [],
      msg_description:description,
      post_videocaptions: [],
      post_videos: [],
      message_type:mtype,
      domain:domain
    }


    if (this.them_awskey) {
      if (/\.(jpe?g|png|jpg|gif)$/i.test(this.them_awskey) === true) {
        campobj.post_images.push(this.them_awskey);
      }
      else if (/\.(mov|mp4)$/i.test(this.them_awskey) === true) {
        campobj.post_videos.push(this.them_awskey);
        campobj.post_images.push(this.them_awskey);
      }
    }

    // console.log(campobj, "####");
    this.spinner.show();
    this.mb.updatePost(campobj).subscribe(data => {
      this.spinner.hide();
      this.toastrService.success("Record Updated successfully");
      this.router.navigateByUrl(`/corporate/templatemanagement`);
    }, error => {
      console.error(error);
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  processFile(event: any, imageInput: any) {
    let self = this;
    const file: File = imageInput.files[0];
    if (file.size <= 1024 * 1024 * 10) {
      if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
        this.toastrService.error("Please select only (png, jpg, jpeg, gif) files");
       // console.log("error ", file);
      } else {
        const reader = new FileReader();
        reader.onload = (event1) => {
          this.toUploadFiles(file.name, reader.result).then(data => {
            this.spinner.hide();
            if(data) {
            }
          }).catch(err => {
            this.toastrService.error('Something went wrong');
            this.spinner.hide();
          })
        }
        reader.readAsDataURL(file);
      }
    }  else {
      imageInput.value = '';
      this.toastrService.error("Unable to proceed upload, File is larger than 10 mb.");
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.spinner.show();
    return new Promise<any>((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.spinner.hide()
          if(res.status){
            let params=res.data;
            if (params.Key) {
              self.them_awskey = params.Key;
              resolve(true)
            }
          }else{
            reject(false)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(false)
          this.spinner.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
 }
 getBusinessDomain(){
  let self = this;
  this.master.getBusinessDomain(100,0,this.selectedCompany).subscribe(data => {
    self.domainData = data.data.rows;
  }, error => {
  });
}


}
