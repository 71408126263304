import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';

@Component({
  selector: 'app-add-content-builder-field-property',
  templateUrl: './add-content-builder-field-property.component.html',
  styleUrls: ['./add-content-builder-field-property.component.css']
})
export class AddContentBuilderFieldPropertyComponent extends BaseComponent implements OnInit {
  name;
  description;
  screenshot: String;
  IsActive;
  id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey;
  them_company;
  getCompanyobj:any=[];
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;

  submitted = false;
  templateForm: FormGroup;
  itemListDisplay:boolean =false;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
   // self.getTemplateSection(this.id);
    this.controlEnabled = true;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = params.companyID;

      });
    if (this.id) {
      this.UpdateTemplateLogData()
    }
    else {
      this.addTemplateLogData()
    }
    //get item details using id
    if (this.id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit"
      this.cb.getCBAttributeById(this.companyID,this.id).subscribe(response => {
       
        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          this.ngxSpinnerService.hide();
            self.dataBinding();
        } else {
        }
      });
    }
    this.templateForm = this.formBuilder.group({
      company: ['', Validators.required],
      attribute_name: ['', Validators.required],     
      attribute_type : ['', Validators.required],
      isrequire:[false],
      attribute_description : [''],      
      listitem:[]

     
    });
  }
  get f() { return this.templateForm.controls; }

 
  displayItemList(val) {

    if(val=="dropdown") this.itemListDisplay = true;
    else this.itemListDisplay = false;
  }
  addAttribute() {
    this.submitted = true;
  

    if (this.templateForm.invalid) {
      return;
    }
    let { company, attribute_name, attribute_description,attribute_type,isrequire,listitem } = this.templateForm.value;
    
    let themeObj = {
      attribute_name : attribute_name ,
      attribute_description: attribute_description ,
      isrequire:isrequire,      
      attribute_type: attribute_type,
      isactive: true,
      company: company,
      listitem:listitem
    }
    //console.log("themeObj", themeObj);
    this.ngxSpinnerService.show();
    this.cb.addingAttribute(themeObj).subscribe(data => {
    //  console.log("ddddd"+data.status);
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/content-builder-field-property`);
        this.ngxSpinnerService.hide();
      } else { 
      //  console.log(data);
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      //this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  addTemplateLogData() {
    let themeLogs = {
      log_type: "Adding New Template",
      log_name: "Adding New Template",
      log_description: "Adding New Template",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  UpdateTemplateLogData() {
    let themeLogs = {
      log_type: "Updating Template Data",
      log_name: "Updating Template Data",
      log_description: "Updating Template Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
    //  console.log(error);
    });
  }
  dataBinding() {
 // console.log("data binidning...."+this.themObj.attribute_type)
    
   
  if(this.themObj.attribute_type=='dropdown') this.itemListDisplay = true;
  else this.itemListDisplay = false;

      this.templateForm = this.formBuilder.group({
        company: [this.companyID.toString(), Validators.required],
        attribute_name: [this.themObj.attribute_name, Validators.required],
        attribute_description : [this.themObj.attribute_description],       
        attribute_type: [this.themObj.attribute_type, Validators.required],
        isrequire:[this.themObj.isrequire],       
        listitem:[this.themObj.listitem]
      });
    
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     // console.log(self.getCompanyobj);
    }, error => {
    //  console.log(error);
    });
  }
  updateAttribute() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    let { company, attribute_name, attribute_description,attribute_type,isrequire,listitem } = this.templateForm.value;
    
    
    let themobj = {
      attribute_name: attribute_name,
      attribute_description: attribute_description,
      isrequire:isrequire,     
      attribute_type: attribute_type,     
      attributeid: Number.parseInt(this.id, 10),
      company: company,
      listitem:listitem
    }
  //  console.log('themobj', themobj);
    this.ngxSpinnerService.show();
    this.cb.UpdateAttribute(themobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/content-builder-field-property`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  
 
}