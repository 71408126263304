<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Shop Live</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <div class="row">
    <div class="col-xl-4  col-md-6">
      <!-- routerLink="/corporate/shoplive-postmanage" -->
        <button class="cbtn mt-1" routerLink="/corporate/shoplive-postmanage">
          <i class="bi bi-pencil-square red-dr-icon" aria-hidden="true"></i>Shop Live Corporate Library 
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/shoplive-category">
        <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Shop Live Category 
      </button>
    </div>
    </div>
  </fieldset>
</div>