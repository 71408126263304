<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Product Catalogue</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [disabled]="companyID == 0"
          #searchText>
      </div>

    </div>


    <div class="col-md-6" style="justify-content: right;">

      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1"
        routerLink="/corporate/newproductcatalog">


      <button class="csvBtn ml-1" (click)="productUpload()" *ngIf="page=='master'">
        <img class="customimg" src="../../../assets/custom/images/product-upload.png"
          style="height: 45px; margin-top: -8px;">
      </button>

      <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport && page=='master'">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport && page=='master'">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
      <input type="button" value="WooCommerce" *ngIf="currentUser.is_bu==true" class="custbtn ml-0 mt-md-1"
        routerLink="/corporate/woocommerce">
    </div>
  </div>

  <div class="row">



    <div class="col-md-6 mt-2" *ngIf="disCompId === 0 ">
      <div>

        <select name="company" class="form-control formstyle" (change)="getDSLIST($event.target.value)">
          <option value=0>

            Select Company Name

          </option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>

      </div>

    </div>

    <div class="col-md-6 mt-2" *ngIf="disCompId === 0 && page=='direct-seller'">
      <div>
        <select name="company" class="form-control formstyle" (change)="getProductCatalog($event.target.value)">
          <option value='0'>
            Select DS
          </option>
            <option value={{userObj.user_ds_id}} *ngFor="let userObj of getDSobj">
              {{userObj.user_fname}} {{userObj?.username}}
            </option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom mn-wit-1160" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Image</th>
        <th (click)="sorting('product_sku')" class="pointer"> SKU <i class=" fa fa-sort text-black"
            aria-hidden="true"></i> </th>
        <th (click)="sorting('product_name')" class="pointer">
          Name <i class=" fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <!-- <th>Description </th> -->
        <th (click)="sorting('product_category')" class="pointer"> Category <i class=" fa fa-sort text-black"
            aria-hidden="true"></i>
        </th>
        <th>Inventory Id /<br /> Category Id </th>
        <th width="100">Price </th>
        <th (click)="sorting('modifiedon')" class="pointer"> ModifiedOn <i class=" fa fa-sort text-black"
            aria-hidden="true"></i>
        </th>
        <th> Cross-sell </th>


        <th *ngIf="isEdit || isDelete">Actions </th>
      </tr>

      <tr *ngFor="let obj of productObj">

        <td *ngIf="obj.product_image!=null">
          <img (error)="setValue($event)" [src]="obj.product_image" style="height:70px;">
        </td>
        <td *ngIf="obj.product_image==null">
          <img [src]="defaultPicture" style="height:70px;">
        </td>
        <td> {{obj.product_sku}} </td>
        <td>{{obj.product_name}}</td>
        <!-- <td>{{ (obj.product_description.length>15)? (obj.product_description | slice:0:15)+'..':(obj.product_description)
        }}</td> -->
        <td> {{obj.product_category}} </td>
        <td> {{obj.bu_product_id}} / {{obj.category_id}} </td>
        <td> {{obj.product_price}} </td>

        <td>{{obj.modifiedon | date:'mediumDate' }}</td>
        <td> <i class="fa fa-edit mr-1" (click)="addUpdateCrosssell(obj.product_catalog_id)" placement="bottom"
            ngbTooltip="Edit"></i> </td>
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-2" *ngIf="isEdit" (click)="editCatalog(obj.product_catalog_id)" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-2" *ngIf="isDelete" (click)="deleteCatalog($event,obj.product_catalog_id)"
              placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.product_catalog_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
        (pageChange)="getProductCatalogue((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>

    <!-- <pagination-controls *ngIf="(productObj | filter:text).length !== 0 && (productObj | filter:text).length !== 1" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->
  </div>
</div>
