import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master/master.service';
import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent extends BaseComponent implements OnInit {
  agreementForm: FormGroup;
  contents: any = {};
  submitted: boolean = false;
  currentDate: any = null;

  constructor(private masterService: MasterService,
    private formBuilder: FormBuilder) {
    super();

    this.agreementForm = this.formBuilder.group({
      title: ['', Validators.required],
      subtitle: ['', Validators.required],
      text: ['', Validators.required],
      selecteddate: [''],
    });
  }

  ngOnInit() {
    this.getTermConditions();
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '15rem',
    maxHeight: '35rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    outline: true,
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'insertImage'
      ]
    ]
  };

  get f() { return this.agreementForm.controls; }

  dataBinding() {
    let self = this;
      self.agreementForm.patchValue({
        title: self.contents.agreement_title,
        subtitle: self.contents.agreement_subtitle,
        text: self.contents.agreement_text,
        selecteddate: self.contents.last_revised_date.toString()
      });
      this.currentDate = self.contents.last_revised_date.toString();
  }



  getTermConditions() {
    this.ngxSpinnerService.show();
    this.masterService.getTermConditions().subscribe((data) => {
      if (data.status) {
        this.contents = data.data;
        this.currentDate = this.contents.last_revised_date.toString()
        this.dataBinding();
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  escapeStr(htmlStr) {
    return htmlStr.replace(/'"`“”’/g, escape);
    // replace(/&/g, "&amp;")
    // .replace(/</g, "&lt;")
    // .replace(/>/g, "&gt;")
    // .replace(/"/g, "&quot;")
  }

  updateContent() {
    this.submitted = true;
    if (this.agreementForm.invalid) {
      return;
    }
    let obj = {
      agreement_title: this.f.title.value,
      agreement_subtitle: this.f.subtitle.value,
      agreement_text: this.escapeStr(this.f.text.value),
      last_revised_date: new Date(this.f.selecteddate.value).toISOString().split('T')[0],
    }
    this.ngxSpinnerService.show();
    this.masterService.updateTermConditions(obj).subscribe((data) => {
      if (data.status) {
        this.toastrService.success('Term & Conditions updated sucessfully');
      } else {
        this.toastrService.success('Something went wrong');
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }
}
