import { Component, OnInit, isDevMode } from '@angular/core';
import { EinsteinReportsService } from 'src/app/services/einstein/einstein-reports.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { environment as prodEnvironment } from '../../../../environments/environment.prod';
import { BaseComponent } from '../../base.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-einstein-reports',
  templateUrl: './einstein-reports.component.html',
  styleUrls: ['./einstein-reports.component.css']
})
export class EinsteinReportsComponent extends BaseComponent implements OnInit {

  

   //SFDomainURL = 'https://mar-go-aditatechnologies-dev-ed.my.site.com';
  SFDomainURL = this.einsteinReportsService.sfDomainURL;

  

  



  lightningAppName = 'c:dashBoard';
  lightningComponentName = 'c:showDashBoard';


  // SFDomainURL = isDevMode() ? environment.saleforce.SFDomainURL : prodEnvironment.saleforce.SFDomainURL;
  // lightningAppName = isDevMode() ? environment.saleforce.lightningAppName : prodEnvironment.saleforce.lightningAppName;
  // lightningComponentName = isDevMode() ? environment.saleforce.lightningComponentName : prodEnvironment.saleforce.lightningComponentName;
  token = '';
  dashboardId='0FKDn000000XFKIOA4';//Default Dashboard-Service Summary Report
  companyName;
  DataSetName;
  BuName = "";
  sectionName;
  admin_customer_id;
  companyObj;
  isHeadQuarter;

  

  
  constructor(
    public einsteinReportsService: EinsteinReportsService,
    private deviceService: DeviceDetectorService,

    private route: ActivatedRoute) {
      super();
     }

  ngOnInit() {
   
   
    const isMobile = this.deviceService.isMobile();
    var data = JSON.parse(sessionStorage.getItem("userData"));

    this.admin_customer_id  = data.admin_customer_id;
   
    this.DataSetName = localStorage.getItem("DataSetName");
    this.companyName =  localStorage.getItem("CompanyName");
    this.isHeadQuarter = localStorage.getItem("customer_isheadquarter");
   

console.log("is head...",this.isHeadQuarter)

    if(this.admin_customer_id>0){

      if(this.isHeadQuarter=='true') {
        this.BuName= "{'datasets': {'"+this.DataSetName+"':[{'fields':['customer_parent_id'],'filter': { 'operator': 'matches',  'values':['"+this.admin_customer_id+"'] }} ]}}";
      }else {
     // this.BuName= "{'datasets': {'"+this.DataSetName+"':[{'fields':['customer_company_name'],'filter': { 'operator': 'matches',  'values':['"+this.companyName+"'] }} ]}}";
      this.BuName= "{'datasets': {'"+this.DataSetName+"':[{'fields':['customer_id'],'filter': { 'operator': 'matches',  'values':['"+this.admin_customer_id+"'] }} ]}}";
    
    }
    }
    else 
    this.BuName= "";

    console.log("bu name......",this.BuName)


    if(isMobile==true || localStorage.getItem("selectedDashboard")=="0FKDn000000XPd4OAG") {
      this.lightningAppName = 'c:dashBoard';
      this.lightningComponentName = 'c:showDashBoard';

    }
   

    this.ngxSpinnerService.show();
    this.route.data.subscribe(data => this.token = data.SFtoken);
    this.dashboardId = ((localStorage.getItem("selectedDashboard") != undefined && localStorage.getItem("selectedDashboard") != null)
      ? localStorage.getItem("selectedDashboard")
      : this.dashboardId);


    this.ngxSpinnerService.hide();
    // this.getSFToken();
  }
 

}
