import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actionmaster',
  templateUrl: './actionmaster.component.html',
  styleUrls: ['./actionmaster.component.css']
})
export class ActionmasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
