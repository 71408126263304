import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';

import { BaseComponent } from 'src/app/secondadmin/base.component';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-add-content-template',
  templateUrl: './add-content-template.component.html',
  styleUrls: ['./add-content-template.component.css']
})
export class AddContentTemplateComponent extends BaseComponent implements OnInit {
  name;
  description;
  screenshot: String;
  IsActive;
  id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey;
  them_company;
  getCompanyobj:any=[];
  getTemplateobj:any[]=[];
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;
  submitted = false;
  templateForm: FormGroup;
  controlEnabled:boolean = true;
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cb: CbService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    self.getCompany();
    this.controlEnabled = true;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.companyID = params.companyID;
      });
    if (this.id) {
      this.UpdateTemplateLogData()
    }
    else {
      this.addTemplateLogData()
    }
    //get item details using id
    if (this.id) {
      this.ngxSpinnerService.show();
      this.getCompanyTemplate(this.companyID, 'themename','asc');
      this.headers = "Edit"
      this.cb.getTemplateById(this.companyID,this.id).subscribe(response => {

        if (response.status) {
          this.status = false;
          self.themObj = response.data;
          // this.getTemplateobj = [{"name" : this.themObj.name,"templateid" : this.themObj.templateid}];
          this.ngxSpinnerService.hide();
            self.dataBinding();
        } else {
        }
      });
    }
    this.templateForm = this.formBuilder.group({
      company: ['', Validators.required],
      templateid: [''],
      ContentBlockName: [''],
      name: ['', Validators.required],
      description: [''],
      template_preview: [''],
      firstActive: [true],
      screenshot: ['',[Validators.required,this.ValidateUrl]]
    });


  }
  get f() { return this.templateForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
    this.f.screenshot.setValue('');
    this.them_awskey = '';
    if(!this.screenshot_type) {
      this.f.screenshot.setValidators([Validators.required]);
      this.f.screenshot.updateValueAndValidity();
    } else if(this.screenshot_type) {
      this.f.screenshot.setValidators([Validators.required, this.ValidateUrl]);
      this.f.screenshot.updateValueAndValidity();
    }
  }



  addTemplate() {
    this.submitted = true;
    //console.log(this.templateForm.get('themescreenshot'));
    // this.screenshot = this.isUrlValid(this.screenshot) ? this.screenshot : this.them_awskey ? this.them_awskey : "";
    //console.log("themescreenshot -- ", this.templateForm);
  //  console.log(this.f)
    if (this.templateForm.invalid) {
      return;
    }

   // console.log(this.templateForm.value);
    let { company, name,templateid, description, ContentBlockName, screenshot,template_preview } = this.templateForm.value;
  //  console.log(template_preview)
    screenshot = this.isUrlValid(this.f.screenshot.value) ? this.f.screenshot.value : '';
    let themeObj = {
      name: name,
      templateid: templateid,
      ContentBlockName: ContentBlockName,
      description: description,
      screenshot: screenshot || this.them_awskey,
      template_preview: template_preview,
      isactive: true,
      company: company
    }

    this.ngxSpinnerService.show();
    this.cb.addingTemplate(themeObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/content-builder-template`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  addTemplateLogData() {
    let themeLogs = {
      log_type: "Adding New Template",
      log_name: "Adding New Template",
      log_description: "Adding New Template",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  UpdateTemplateLogData() {
    let themeLogs = {
      log_type: "Updating Template Data",
      log_name: "Updating Template Data",
      log_description: "Updating Template Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });
  }
  dataBinding() {
    if (this.isUrlValid(this.themObj.screenshot)) {
      this.templateForm = this.formBuilder.group({
        company: [this.companyID.toString(), Validators.required],
        name: [this.themObj.name, Validators.required],
        templateid: [this.themObj.templateid],
        ContentBlockName: [this.themObj.ContentBlockName],
        description: [this.themObj.description],
        template_preview: [this.themObj.template_preview],
        firstActive: [true],
        screenshot: [this.themObj.screenshot, [Validators.required, this.ValidateUrl]]
      });
      this.screenshot_type = true;
    } else {
      this.templateForm = this.formBuilder.group({
        company: [this.companyID.toString(), Validators.required],
        name: [this.themObj.name, Validators.required],
        templateid: [this.themObj.templateid],
        ContentBlockName: [this.themObj.ContentBlockName],
        description: [this.themObj.description],
        template_preview: [this.themObj.template_preview],
        firstActive: [false],
        screenshot: [this.themObj.screenshot, [Validators.required]]
      });
      this.screenshot_type = false;
    }
    this.them_awskey = this.themObj.screenshot;
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    //  console.log(self.getCompanyobj);
    }, error => {
     // console.log(error);
    });
  }
  updateTemplate() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    let { company, name,templateid, description, ContentBlockName,screenshot,template_preview } = this.templateForm.value;
    screenshot = this.isUrlValid(this.f.screenshot.value) ? this.f.screenshot.value : '';
    let themobj = {
      name: name,
      templateid : templateid ,
      ContentBlockName: ContentBlockName,
      description: description,
      screenshot: screenshot || this.them_awskey,
      isactive: this.themObj.isactive,
      template_preview:template_preview,
      id: Number.parseInt(this.id, 10),
      company: company
    }
   // console.log('themobj', themobj);
    this.ngxSpinnerService.show();
    this.cb.UpdateTemplate(themobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/content-builder-template`);
        this.ngxSpinnerService.hide();
      }else {this.ngxSpinnerService.hide();}
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") >= 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  ValidateUrl(control: AbstractControl) {
    return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(control.value) ? null : { validUrl: true };
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");
     // console.log("error ", file);
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result).then(data => {
          this.them_awskey = data;
          this.templateForm.controls.screenshot.setValue(data);
        //  console.log(this.templateForm.value);
        //  console.log(this.them_awskey)
        }).catch(error => {
          this.ngxSpinnerService.hide();
          this.toastrService.error('Something went wrong. Please upload later')
        })
      }
      reader.readAsDataURL(file);
    }
  }

  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show();
    return new Promise((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.Key) {
              resolve(params.Key)
            }
          }else{
            reject(false)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(false)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }

  getCompanyTemplate(companyID, themename,order) {
    let self = this;
    self.ngxSpinnerService.show();
    this.clientService.getCompanyTemplate(companyID, themename,order).subscribe(data => {
      if(data.status){
        self.getTemplateobj = data.data;
        self.ngxSpinnerService.hide();
      }else {this.ngxSpinnerService.hide();}
    }, error => {
     console.log(error);
     self.ngxSpinnerService.hide();
    });
  }

  changeMkValue(value){
    console.log("changeMkValue",value);
    this.getTemplateobj.forEach(element => {
      if(element.templateid == value){
        // if(element.ContentBlockName != null){
          this.templateForm.patchValue({
            ContentBlockName : element.ContentBlockName,
            template_preview : element.template_preview
          });
        // }

      }
    });
  }

}
