import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-prospecttypemaster',
  templateUrl: './prospecttypemaster.component.html',
  styleUrls: ['./prospecttypemaster.component.css']
})
export class ProspecttypemasterComponent extends BaseComponent implements OnInit {

  public prospectObj: any = [];
  cp = 1;
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();

  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
  ) {
    super();
   }
  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
    this.getProspectData();
    this.ProspecttypeLogData()
  }
  ProspecttypeLogData() {
    let ProspecttypeLogs = {
      log_type:"Prospecttype Master View" ,
      log_name:"Prospecttype Master View" ,
      log_description:"Prospecttype Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(ProspecttypeLogs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });

}
  getProspectData() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getProspect().subscribe(data => {
      self.prospectObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  deleteProspect(id) {
    let obj = {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delProspect(obj).subscribe(data => {
            if (data.status) {
              this.getProspectData();
              this.toastrService.success("Deleted Successfully")
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  editProsPectty(id) {
    this.router.navigate(['/corporate/addnewprospect'], { queryParams: { prospect_id: id } });
  }
  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.prospectObj.find(element => {
      //console.log(element.get_master_company_type.company_type_id)
      if (element.get_master_prospect.pid === id) {
        return true;
      }
    });
    obj = obj.get_master_prospect
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.updateProspect(obj).subscribe(data => {
            // console.log(data)
            this.toastrService.success("Status Changed Successfully");
            this.ngxSpinnerService.hide();
            this.getProspectData();
          });
        }
        // else {
        //   this.master.updateProspect(obj).subscribe(data => {
        //     // console.log(data)

        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //     this.getProspectData();
        //   });
        // }
      }).catch(() => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('Please Choose the File Path ', `../../../assets/margo-import-files/margo-prospect-import.csv`, 'addingProspectBulk')
      .then(data => {
       // console.log(data);
        self.getProspectData();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_prospect_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}
