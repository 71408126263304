import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
@Component({
  selector: 'app-addnewcurrency',
  templateUrl: './addnewcurrency.component.html',
  styleUrls: ['./addnewcurrency.component.css']
})
export class AddnewcurrencyComponent extends BaseComponent implements OnInit {
  // name;
  // symbol;
  // description;
  // image;
  // IsActive;
  currency_id
  currencyObjId
  screenshot_type = true;
  status: boolean = true;
  currency_awskey:string="";
  currency_image;
  header = "Add New"

  themeForm: FormGroup;
  submitted = false;
  constructor(
    private master: MasterService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.currency_id = params.currency_id;
      });
    //get item details using id
    if (self.currency_id) {
      this.ngxSpinnerService.show();
      this.UpdateCurrencyLogData()
      this.header = "Edit"
      this.master.getCurrencyId(this.currency_id).subscribe(response => {

        if (response.status) {
          this.status = false;
          self.currencyObjId = response.data;
          this.ngxSpinnerService.hide();
          self.dataBinding();

        } else {

        }

      })
    } else {
      this.addCurrencyLogData();
    }
    this.themeForm = this.formBuilder.group({
      currencyName: ['', [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(20)]],
      currencySymbol: ['',[Validators.required,this.noWhitespaceValidator,Validators.maxLength(10)]],
      currencyDescription: [],
      currencyImage: [],
      firstActive: [true],
    });
  }
  get f() { return this.themeForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }

  addCurrencyLogData() {

    let CurrencyLogs = {
      log_type: "Adding New Currency",
      log_name: "Adding New Currency",
      log_description: "Adding New Currency",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CurrencyLogs).subscribe(data => {


    }, error => {
     // console.log(error);
    });

  }

  UpdateCurrencyLogData() {

    let CurrencyLogs = {
      log_type: "Updating Currency Data",
      log_name: "Updating Currency Data",
      log_description: "Updating Currency Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(CurrencyLogs).subscribe(data => {

    }, error => {
    //  console.log(error);
    });
  }

  dataBinding() {
    // this.name = this.currencyObjId.currency_name;
    // this.symbol = this.currencyObjId.currency_symbol;
    // this.description = this.currencyObjId.currency_description;
    // this.image = this.currencyObjId.currency_image;
    // this.IsActive = this.currencyObjId.isactive;
    this.currency_image = this.currencyObjId.currency_image;
    if (this.isUrlValid(this.currencyObjId.currency_image)) {
      this.themeForm = this.formBuilder.group({
        currencyName: [this.currencyObjId.currency_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(20)]],
        currencySymbol: [this.currencyObjId.currency_symbol,[Validators.required,this.noWhitespaceValidator,Validators.maxLength(10)]],
        currencyDescription: [this.currencyObjId.currency_description],
        currencyImage: [this.currencyObjId.currency_image],
        firstActive: [true],
      });
    } else {
      this.themeForm = this.formBuilder.group({
        currencyName: [this.currencyObjId.currency_name, [Validators.required,this.noWhitespaceValidator,Validators.pattern(AppConstants.regex.name),Validators.maxLength(20)]],
        currencySymbol: [this.currencyObjId.currency_symbol,[Validators.required,this.noWhitespaceValidator,Validators.maxLength(10)]],
        currencyDescription: [this.currencyObjId.currency_description],
        currencyImage: [],
        firstActive: [false],
      });
     // this.currency_awskey = this.currency_awskey.theme_screenshot;
      this.screenshot_type = false;
    }
  }

  addCurrency() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { currencyName, currencySymbol, currencyDescription, currencyImage } = this.themeForm.value;

    if (this.f.firstActive.value) {



      if (this.f.currencyImage.value) {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.currencyImage.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }


    }


    currencyImage = this.isUrlValid(currencyImage) ? currencyImage : this.currency_awskey ? this.currency_awskey : "";

    let currencyObj = {
      name: currencyName,
      symbol: currencySymbol,
      description: currencyDescription,
      image: currencyImage
    }



    this.ngxSpinnerService.show();
    this.master.addingCurrency(currencyObj).subscribe(data => {
      this.toastrService.success("Record Inserted successfully");
      this.router.navigateByUrl(`/corporate/currencymaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error.message);
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  UpdateCurrency() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      return;
    }
    let { currencyName, currencySymbol, currencyDescription, currencyImage } = this.themeForm.value;

    if (this.f.firstActive.value) {



      if (this.f.currencyImage.value != '') {

        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

        let pattern = urlregex.test(this.f.currencyImage.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }



    }

    currencyImage = this.isUrlValid(currencyImage) ? currencyImage : this.currency_awskey ? this.currency_awskey : "";
    let currencyObj = {
      currency_description: currencyDescription,
      currency_id: this.currency_id,
      currency_image: currencyImage,
      currency_name: currencyName,
      currency_symbol: currencySymbol,
      isactive: this.currencyObjId.isactive,
    }
    this.ngxSpinnerService.show();
    this.master.UpdateCurrency(currencyObj).subscribe(data => {
      this.toastrService.success("Record Update successfully");
      this.router.navigateByUrl(`/corporate/currencymaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.success(error.message);
      this.ngxSpinnerService.hide();
    });
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.currency_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg or jpeg) files");

    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            self.currency_awskey = params.Key;
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
