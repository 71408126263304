<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/productcatalog">Product Catalog</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/woocommerce">WooCommerce</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{page}}</li>
    </ol>
  </nav>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-xl-12" *ngIf="page=='Update'">
      <div class="card">
        <form class="form ng-untouched ng-pristine ng-invalid" (submit)="onEditPackageCred(editPackageCredForm)"
          #editPackageCredForm="ngForm">

          <div class="card-body">

            <div class="form-group" *ngFor="let field of schemaObj">
              <label>{{field.field_name}}
                <b *ngIf="field.is_required"><span class="required"></span></b>
              </label>
              <input [name]="field.field_name_alise" [ngModel]="packageCredData[field.field_name_alise]" placeholder=""
                type="text" class="form-control" [required]="field.is_required"
                aria-describedby="field.field_name_alise" />
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">

                <button [disabled]="!editPackageCredForm.valid" type="submit"
                  class="btn btn-round btn-margo-action">Save</button>

                <button class="btn btn-round btn-margo-action" (click)="onBack()">Back </button>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-12 col-xl-12" *ngIf="page=='Add'">
      <div class="card">

        <form class="form ng-untouched ng-pristine ng-invalid" (submit)="addForm(addPackageCredentialForm)"
          #addPackageCredentialForm="ngForm">
          <div class="card-body">

            <div class="form-group" *ngFor="let field of schemaObj">
              <label>{{field.field_name}}
                <b *ngIf="field.is_required"><span class="required"></span></b>
              </label>

              <input placeholder="" type="text" class="form-control" [name]="field.field_name_alise" ngModel
                [required]="field.is_required" aria-describedby="field.field_name_alise" />
            </div>


            <div class="row mt-2">
              <div class="col-12 text-center">


                <button [disabled]="!addPackageCredentialForm.valid" type="submit"
                  class="btn btn-round btn-margo-action">Save</button>

                <button class="btn btn-round btn-margo-action" (click)="onBack()">Back </button>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
