import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import { FormControl,FormArray,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import {CampaignBuilderService} from '../../../services/segmentation/campaign-builder.service';
import {AudienceBuilderService} from '../../../services/segmentation/audience-builder.service';
import { ClientService } from 'src/app/services/clients/client.service';
import {MasterService} from '../../../services/master/master.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import {BUSegmentationService} from '../../../services/segmentation/bu-segmentation.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
@Component({
  selector: 'app-create-campaign-builder',
  templateUrl: './create-campaign-builder.component.html',
  styleUrls: ['./create-campaign-builder.component.css']
})
export class CreateCampaignBuilderComponent extends BaseComponent implements OnInit, OnDestroy {
  campaignForm: FormGroup;
  submitted = false;
  header = 'Add New';
  status;
  actionStatus: boolean = true;
  audienceAddForm:boolean=true;
  campaignAddForm:boolean=false;
  currentUser;
  companyId;
  targetAudience:boolean=false;
  buTargetAudienceObj:any=[];
  addedCampaignId;
  campid: any;
  limit = 10;
  offset = 0;
  journeyObj=[];
  journeyTypeObj=[]
  showJourneyType:boolean=false;

  conditionForm: FormGroup;
  selectedAud: any;
  editCampId: any;
  editStatus = false;
  campaignObj: any;
  cp = 1;
  subscription: any;
  text = '';
  journeyStatus = null;
  backStatus = false;

  ord = 'asc';
  colname = 'aud_name';
  buTargetAudiencetotal: number = 0;
  page = 1;
  searchText:any = '';
  querySearchField : FormControl = new FormControl();

  // ord = 'desc';
  // colname = 'createdon';
  // totalNumber: number = 0;
  // page = 1;
  // limit = 10;
  // skip = 0;

  constructor(
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private campaignBuilderService: CampaignBuilderService,
    private audienceBuilderService: AudienceBuilderService,
    private fb:FormBuilder,
    private clientsService: ClientService,
    private master: MasterService,
    private cb: CbService,
    private segmentService: BUSegmentationService
    ) {
      super();
    this.subscription = this.activatedRoute.queryParams
      .subscribe(params => {
        this.editCampId = parseInt(params.editCampId, 10);
        let bck = params.backStatus;
        if (bck === 'true') {
          this.backStatus = true;
        } else {
          this.backStatus = false;
        }
      });
    }

  ngOnInit() {


    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.currentUser.admin_customer_id;
    this.checkAudienceData();

    this.campaignForm = this.formBuilder.group({
      camp_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      camp_desc: [''],
      journey_id: ['',Validators.required],
      mail_signature:[''],
      is_tested:[true],
    });

    //this.loadTargetAudienceList();
    this.searchTargetAudienceList();
  }


  searchTargetAudienceList() {
    this.querySearchField.valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      tap(() => {
        this.offset = 0;
        this.buTargetAudienceObj = [];
        this.ngxSpinnerService.show();
      })).subscribe(query => {
        this.searchText = query;
        this.loadTargetAudienceList();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }
  get f() { return this.campaignForm.controls; }

  //  ############  Check for Audience Data for BU  ##############  //

  checkAudienceData() {
    this.ngxSpinnerService.show();
    this.segmentService.checkAudienceDataForBU(this.companyId).subscribe(data => {
      if (data.status) {
        this.getJourneyList();
      } else {
        this.toastrService.error('Please add Segmentation Data for BU before adding New Campaign');
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/campaign-builder');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.router.navigateByUrl('/corporate/campaign-builder');
      this.ngxSpinnerService.hide();
    });
  }

  addBUCampaign() {
    this.submitted = true;

   // console.log(this.campaignForm);

    if (this.campaignForm.invalid) {
      return;
    }
    if(this.journeyTypeObj.length === 0){
      this.toastrService.error("No Journey Available");
      return;
    }



    let { camp_name, camp_desc,journey_id,mail_signature,is_tested} = this.campaignForm.value;
    this.ngxSpinnerService.show();

    let campaignBody = {
      company:this.companyId,
      camp_name: camp_name,
      camp_desc: camp_desc.trim(),
      journey_id: journey_id,
      mail_signature:mail_signature,
      is_tested: is_tested

    }
    this.campaignBuilderService.addingBUCampaign(campaignBody,this.companyId).subscribe(data => {
      if (data.status) {
        //this.addedCampaignId=data.data.campid
        this.toastrService.success("Campaign Added Successfully");
        this.campid = data.data.campid;
        this.loadTargetAudienceList();
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  loadTargetAudienceList() {
    this.offset = (this.page - 1) * this.limit;
    this.campaignAddForm=false;
    this.ngxSpinnerService.show();
    this.audienceBuilderService.getBUAudienceList(this.companyId, this.limit, this.offset, this.colname, this.ord, this.searchText).subscribe(data => {
      if (data.status) {
          if (data.data == null) {  this.buTargetAudienceObj = [] }
          else { this.buTargetAudienceObj = data.data.rows }
          this.buTargetAudiencetotal = data.data.total;
        
        if (this.editCampId) {

          let ft = this.buTargetAudienceObj.filter(element => element.audid === this.selectedAud);
        //  console.log('FT', ft);

          if (ft.length > 0) {
            this.buTargetAudienceObj = this.buTargetAudienceObj.filter(element => element.audid !== this.selectedAud);
            this.buTargetAudienceObj.unshift(ft[0]);
          } else {
            this.selectedAud = null;
          }
        }
        this.targetAudience=true;
      } else {
        this.buTargetAudienceObj = [];
        this.buTargetAudiencetotal = 0;
        this.targetAudience=false;
        console.log('targetAudience',this.targetAudience)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getJourneyList() {
    // this.ngxSpinnerService.show();
    this.campaignBuilderService.getSegementJourney(this.companyId).subscribe(data => {

      if (data.status) {

      this.journeyObj=data.data;
      if (this.editCampId){
        this.editStatus = true;
        this.header = 'EDIT';
        this.campid = this.editCampId;
        //  ###############  Get Data for Edit of selected campaign ##########  //

        this.campaignBuilderService.getBUCampaignById(this.companyId, this.editCampId).subscribe(data => {
          if (data.status) {

            this.campaignObj = data.data;
            this.campaignForm.get('camp_name').patchValue(this.campaignObj.camp_name);
            this.campaignForm.get('mail_signature').patchValue(this.campaignObj.mail_signature);
            this.campaignForm.get('camp_desc').patchValue(this.campaignObj.camp_desc);
            this.campaignForm.get('journey_id').patchValue(parseInt(this.campaignObj.journey_id, 10));
            this.campaignForm.get('is_tested').patchValue(this.campaignObj.is_tested);
            this.selectedAud = this.campaignObj.audid;
            this.journeyStatus = 'disabled';
            this.
            getJourneyType(this.campaignObj.journey_id);
            this.journeyStatus = 'disabled';
            if (this.backStatus) {
              this.loadTargetAudienceList();
            } else {
              this.campaignAddForm = true;
            }
          } else  {
            this.campaignObj = [];
            this.toastrService.error(data.message);
            this.ngxSpinnerService.hide();
            this.router.navigateByUrl('/corporate/campaign-builder');
          }
        }, error => {
         // console.log(error);
          this.ngxSpinnerService.hide();
        });
      } else {
        this.campaignAddForm = true;
      }
      } else  {
        this.journeyObj = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
  getJourneyType(val) {

  //  console.log("aaaa..",val)

    if(val) {

    this.ngxSpinnerService.show();

    this.cb.getJourneyByType(this.companyId,val).subscribe(data => {
      this.showJourneyType=true;

      if (data.status) {

      this.journeyTypeObj=data.data;


      } else  {
        this.journeyTypeObj = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }else {

    this.showJourneyType=false;

  }



  }

  chkAudForCampaign() {
    if (this.selectedAud === undefined || this.selectedAud === null) {
      this.toastrService.error('Please select an Audience');
      return;
    }
    let jid = this.campaignForm.value.journey_id;
    let jrny = this.journeyObj.filter(elem => elem.journey_id === parseInt(this.campaignForm.value.journey_id, 10));
    let cmpNm = this.campaignForm.value.camp_name;
    let cmpnDesc = this.campaignForm.value.camp_desc;
    let audid = this.selectedAud;
    let ispd = jrny[0].isproduct;
    let URL = '/corporate/schedule-campaign-builder' + '?audid=' + this.selectedAud + '&&jid=' + jid + '&&campId=' + this.campid
               + '&&isprod=' + ispd;
    this.router.navigateByUrl(URL);
  }

  /* onSubmit() {
    if (this.conditionForm.invalid) {
      this.toastrService.error("Select all required fields");
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      "audid":this.addedAudienceId,
      "aud_id":this.targetAudieceId,
      "company": this.companyId ,
      "query_data":this.conditionForm.value
    }

    this.audienceBuilderService.addingAudienceQuery(obj).subscribe(data => {
      if (data.status) {
        //this.addedAudienceId=data.data.audid
        this.toastrService.success("Audience Builder Query Added Successfully");
        let URL = '/corporate/view-bu-built-audience' + '?audid=' + this.addedAudienceId;
        this.router.navigateByUrl(URL);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  } */

  //   ################  Update Name , Desc, Journey for Campaign  ############ //

  updateBUCampaign(){
    this.submitted = true;

    if (this.campaignForm.invalid) {
      return;
    }
    if (this.journeyTypeObj.length === 0){
      this.toastrService.error('No Journey Available');
      return;
    }

    this.ngxSpinnerService.show();

    let campaignBody = {
      campid: this.editCampId,
      company: this.companyId,
      camp_name: this.campaignForm.value.camp_name,
      camp_desc: this.campaignForm.value.camp_desc.trim(),
      journey_id: this.campaignForm.value.journey_id,
      mail_signature: this.campaignForm.value.mail_signature,
      is_tested: this.campaignForm.value.is_tested,
    };

    this.campaignBuilderService.updateCampaignBasicDetails(campaignBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Campaign Updated Successfully');
        this.loadTargetAudienceList();
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  //  ################  Check if Audience count is 0 #############  //

  getAudCount(obj) {
    if (obj.total_count && obj.total_count > 0 && obj.total_count <=10) {
      return false;
    } else {
      return true;
    }
  }

  //  ################  Go Back to Campaign Builder form  #####################  //

  goBackToCampaignFormView() {
    this.targetAudience = false;
   // this.selectedAud = null;
    this.buTargetAudienceObj = [];
    this.campaignAddForm = true;
  }

  //  ################  On Destroy ######################  //

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
