import { Injectable } from '@angular/core';
import { MasterService } from '../master/master.service';
import { Observable, forkJoin, of } from 'rxjs';
import { ShopLiveService } from './shop-live.service';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketFlowService {
  userData: any;
  companyId: any;

  constructor(
    // private master: MasterService,
    private shopLiveService: ShopLiveService
    // private marketingCloudService: MarketingCloudService,
    // private timeZoneService: TimeZoneService
    
    ) {
      this.userData = JSON.parse(sessionStorage.getItem('userData'));
      this.companyId = this.userData.admin_customer_id;
     }
  public EventData;
  public EventHostCohostRelData;
  public MarkertOrder;
  public contactByIdData;
  createevent(): Observable<any> {
    return this.shopLiveService.getTimeZoneByValue(this.EventData.event_time_zone)  //first api call
      .pipe(
        map((data: any, index: number) => {
          if (data.status) {
            this.EventData.selectedTimezone = data.data[0].utc[0];
          }
          return this.EventData;
        }),
        mergeMap((data: any, index: number) => {
          return this.shopLiveService.addNewEvent(this.EventData,this.companyId);
        }),        
        map((data: any, index: number) => {
          if (this.EventHostCohostRelData) {
            this.EventHostCohostRelData.event_id = data.id;
          }
          this.MarkertOrder.product_id = data.id;
          return data;
        }),
        mergeMap((data: any, index: number) => {
          if (data.id) {
            var body={
              contactid:this.contactByIdData.contact_id,
              agreetojoin:false,
              agreetohost:true
            }
            return forkJoin( this.shopLiveService.syncEvent(data,this.companyId),this.shopLiveService.addEventHostCohostRel(this.EventHostCohostRelData,this.companyId), this.shopLiveService.marketOrder(this.MarkertOrder,this.companyId));
          }
          else {
            return of(data);
          }
        })
      );
  }
  createmarketorder(): Observable<any> {
    return this.shopLiveService.marketOrder(this.MarkertOrder,175);
  }
  // updatetheme(): Observable<any> {
  //   return this.master.updateMarketOrderThemeById(this.MarkertOrder);
  // }
  // syncDataWithMC() {
  //   this.marketingCloudService.syncDataWithMC().subscribe(data => {
  //     if (data.MC_Sync_Status) {
  //     } else {

  //     }
  //   });
  // }

}
