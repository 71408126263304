import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
@Component({
  selector: 'app-addnewcountry',
  templateUrl: './addnewcountry.component.html',
  styleUrls: ['./addnewcountry.component.css']
})
export class AddnewcountryComponent extends BaseComponent implements OnInit {
  // name;
  // code;
  // IsActive;
  country_id;
  countryObj;
  status = true;
  header = "Add New"

  countryForm: FormGroup;
  submitted = false;

  constructor(
    public master: MasterService,
    private formBuilder: FormBuilder
  ) { 
    super();
  }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        self.country_id = params.country_id;
      });
    //get item details using id
    if (self.country_id) {
      this.ngxSpinnerService.show();
      this.UpdateCountryLogData()
      this.header = "Edit"
      this.master.getCountryById(this.country_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.countryObj = response.data;
          self.dataBinding();
          this.ngxSpinnerService.hide();
        } else {

        }
      });
    } else {
      this.addCountryLogData();
    }
    this.countryForm = this.formBuilder.group({
      countryName: ['', Validators.required],
      countryCode: []
    });
  }
  get f() { return this.countryForm.controls; }
  addCountryLogData() {

    let countryLogs = {
      log_type: "Adding New Country",
      log_name: "Adding New Country",
      log_description: "Adding New Country",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(countryLogs).subscribe(data => {
     // console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  UpdateCountryLogData() {

    let countryLogs = {
      log_type: "Updating Country Data",
      log_name: "Updating Country Data",
      log_description: "Updating Country Data",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(countryLogs).subscribe(data => {
     // console.log(data);

    }, error => {
     // console.log(error);
    });

  }
  dataBinding() {
    // this.name = this.countryObj.country_name;
    // this.code = this.countryObj.country_code;
    // this.IsActive = this.countryObj.isactive;
    this.countryForm = this.formBuilder.group({
      countryName: [this.countryObj.country_name, Validators.required],
      countryCode: [this.countryObj.country_code],
    });
  }
  addNewCountry() {
    this.submitted = true;
    if (this.countryForm.invalid) {
      return;
    }
    let { countryName, countryCode } = this.countryForm.value;
    let countryBody = {
      name: countryName,
      code: countryCode
    }
    this.ngxSpinnerService.show();
    this.master.addingCountry(countryBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/countrymaster`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.toastrService.error(error.message)
      this.ngxSpinnerService.hide();
    });
  }
  updateCountry() {
    this.submitted = true;
    if (this.countryForm.invalid) {
      return;
    }
    let { countryName, countryCode } = this.countryForm.value;
    let countryobj = {
      country_name: countryName,
      country_code: countryCode,
      isactive: this.countryObj.isactive,
      country_id: Number.parseInt(this.country_id, 10)
    }
    this.ngxSpinnerService.show();
    this.master.updateCountry(countryobj).subscribe(data => {
      this.toastrService.success("Record Update successfully");
      this.router.navigateByUrl(`/corporate/countrymaster`);
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.success(error.message);
      this.ngxSpinnerService.hide();
    });

  }
}
