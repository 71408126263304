<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
  .cldrIcon {
    position: absolute;
top: 0px;
right: -20px;
color: #777 !important;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">opt-in-opt-out</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 themeTblReso">
  <div class="row"> 
    
    <div class="col-md-3 mt-2" *ngIf="isHeadquater">    
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="gettingReports(1,$event.target.value,'compChange')">
        <option selected value="0" >Select Company</option>
        <option *ngFor="let userObj of getCompanyobj" value="{{userObj.customer_id}}">
            {{userObj.customer_company_name}}
        </option>
      </select>
    </div>
 <!-- <div class="col-md-3 mt-2">
    <select name="user_type" class="form-control formstyle"  [(ngModel)]="user_type">              
        <option value="true" >All</option>
        <option value="true">Active</option>
        <option value="false">InActive</option>
    </select>
  </div>-->
  <div class="col-md-3 mt-2">
    <select name="report_status" class="form-control formstyle" (change)="gettingReports(1,companyID,'timeChange')"  [(ngModel)]="report_status">
        <option value="180" >Half-year</option>   
        <option value="7">Weekly</option>        
        <option value="30">Monthly</option>
        <option value="365">Year</option>
    </select>
  </div>
  <div class="col-md-2 mt-2">
    <button class="btn btn-margo-action formstyle ml-0 mt-md-1" (click)="gettingReports(0,companyID,'search')" style="align-items: center;">Search</button> 
  </div>
  <!-- <div class="col-md-2 mt-2">
    <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png" style="height: 45px;"> </a>
  </div> -->
</div>
<div class="tblPrt" *ngIf="showResult">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">     
  <tr>                     
    <th>Direct Seller</th>
    <th>Contact Name</th>
    <th>Governing(opt-in/opt-out) </th>
    <th>Rule specific </th>
    <th>Opt date </th>     
    <th>Opt Type</th>
    <th>Date of last message </th>        
  </tr>
    <tr *ngFor="let report of reportData">
      <td>{{report.dname}}</td>
      <td>{{report.contact_name}} </td>
      <td>0</td>
      <td>0</td>
      <td>{{report.createdon |date:'MM/dd/yyyy, h:mm a'}}</td>
      <td>{{report.log_type}}</td>         
      <td>0 </td>             
    </tr>  
  </table> 
  <!-- <div><pagination-controls class="my-pagination" (pageChange)="p = $event"></pagination-controls></div> -->
</div>
<div class="mt-3" *ngIf="!showResult" style="text-align:center">No results available</div>

<div class="col-sm-12 text-center difPagination" *ngIf="reportData == null || reportData.length == 0">
  <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [pageSize]="pageSize"
      [maxSize]="2" [rotate]="true" (pageChange)="getOPTinoutDS(companyId,report_status,user_type,page=$event)"
      aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
  </ngb-pagination>
</div>

