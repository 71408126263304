import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';


@Component({
  selector: 'app-add-templates',
  templateUrl: './add-templates.component.html',
  styleUrls: ['./add-templates.component.css']
})
export class AddTemplatesComponent extends BaseComponent implements OnInit, OnDestroy {
  customer_id;
  addTemplateForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  seletedOption:any=[];
  webinarSection: any = [];
  isUpdated = false;
  templateId = null;
  logoAWSkey: any = '';
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService,
    private upload:UploadService
  ) {super(); }
  updateInit(): void {
    let template =  localStorage.getItem("templateData");
    if(template){
      this.isUpdated = true;
      const { id ,name, description,background_image,sections,company } = JSON.parse(template);
        this.templateId = id;
        this.logoAWSkey = background_image;
         this.addTemplateForm.patchValue({
          name, description ,
        });
        this.getWebinarTemplateSection(company,id,10,0)
       // this.getWebinaeSection(company)
    }
  }
  ngOnDestroy(): void {
    if(this.isUpdated){
      localStorage.removeItem("templateData");
      this.templateId = null

    }
  }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', Validators.maxLength(100)],
      background_image: ['', Validators.required],
      sections: [[], Validators.required],
      company: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],

    })

    this.addTemplateForm.get("company").valueChanges.subscribe(selectedValue => {
      console.log(selectedValue);
      this.getWebinaeSection(selectedValue)
    })
  }


  getWebinaeSection(id:any){
    this.masterService.getWebinarSection(id).subscribe((data) => {
      if (data.status) {
        this.webinarSection = data.data;
      } else {
        this.webinarSection = [];
      }
    });
  }
  getCompany() {
    this.clientsService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.updateInit();
    }, error => {
      console.log(error);
    });
  }
  get f() { return this.addTemplateForm.controls; }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  onChange(id:number,checked:boolean){
   // this.addTemplateForm.controls.sections.setValue(checked)
    if(checked){
      this.seletedOption.push(id)
      this.addTemplateForm.controls.sections.setValue(this.seletedOption)
    }
    else{
      this.seletedOption = this.seletedOption.filter((ele:any)=>
      { return ele!=id }
      )
      this.addTemplateForm.controls.sections.setValue(this.seletedOption)
    }
}
checkedfunction(a: any) {
  if (this.addTemplateForm.controls.sections.value) {
    let checkArray = [];
    checkArray = this.addTemplateForm.controls.sections.value;
    return checkArray.some((ele) =>
      ele == a);
  }
}
addTemplate(){
  this.submitted = true;
  if (this.addTemplateForm.invalid) {
    return;
  }
  let requestData = this.addTemplateForm.value;
  requestData.background_image = this.logoAWSkey;
  this.ngxSpinnerService.show();
  this.masterService.addTemplates(requestData).subscribe(data=>{
    this.ngxSpinnerService.hide();
    if (data.status) {
      this.toastrService.success("Template added successfully");
      this.router.navigateByUrl(`/corporate/templates`);
    } else {
      this.toastrService.error(data.message);
    }
  },error => {
    this.ngxSpinnerService.hide();
    this.toastrService.error(error.message);
  });
}
updateTemplate(){
  this.submitted = true;
  if (this.addTemplateForm.controls.name.invalid && this.addTemplateForm.controls.description.invalid && this.addTemplateForm.controls.company.invalid &&this.addTemplateForm.controls.section.invalid) {
    return;
  }
  this.ngxSpinnerService.show();
  let requestData = { id: this.templateId,isactive:true , ...this.addTemplateForm.value}
  requestData.background_image = this.logoAWSkey;
  this.masterService.updateTemplates(requestData).subscribe(data=>{
    this.ngxSpinnerService.hide();
    if (data.status) {
      this.toastrService.success("Update Template successfully");
      this.router.navigateByUrl(`/corporate/templates`);
    } else {
      this.toastrService.error(data.message);
    }
  },error => {
    this.ngxSpinnerService.hide();
    this.toastrService.error(error.message);
  });
}
setValue(event) {
  let url = event.target.src;
  if (url.indexOf("master_images") > 0) {
    url = url.substring(url.indexOf("master_images"), url.length)
    if (!this.isUrlValid(url)) {
      let awsUrl = this.masterService.getUrlfile(url);
      this.logoAWSkey = url;
      event.target.src = this.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
  } else {
    event.target.src = '../../../assets/custom/images/broken.png';
  }
  event.target.onerror = null;
}
isUrlValid(userInput) {
  return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
}
processFile(event: any, imageInput: any) {
  this.ngxSpinnerService.show();
  const file: File = imageInput.files[0];
  if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
    this.toastrService.error("Please select only (png, jpg or jpeg) files");
    // console.log("error ", file);
  } else {
    const reader = new FileReader();
    reader.onload = (event1) => {
      this.imageUpload(file.name, reader.result);
    }
    reader.readAsDataURL(file);
  }
}

imageUpload(name,file){
  this.ngxSpinnerService.show();
  this.upload.uploadImage(name,"master_images",file).subscribe({
    next:(res:any)=>{
      this.ngxSpinnerService.hide()
      if(res.status){
        let params=res.data;
        if (params.Key) {
          this.logoAWSkey = params.Key;
        }
      }else{
        this.toastrService.error('Something went wrong !')
      }
    },
    error:(err:any)=>{
      this.ngxSpinnerService.hide()
      this.toastrService.error('Something went wrong !')
    }
  })
}
// toUploadFiles(file) {


//   let self = this;
//   //self.ngxSpinnerService.show();
//   return new Promise((resolve, reject) => {
//     //self.ngxSpinnerService.show()
//     self.masterService.uploadfile(file, "master_images", function (error, params) {
//       if (error) {
//         reject(error);
//         return;
//       }
//       if (params.Key) {
//         self.logoAWSkey = params.Key;
//        // self.addTemplateForm.controls.background_image.setValue(self.logoAWSkey)
//       //  self.addTemplateForm.controls['background_image'].setValue(self.logoAWSkey);
//         self.ngxSpinnerService.hide();
//         resolve(self.logoAWSkey);
//       }
//     });
//   })
// }
getWebinarTemplateSection(company,id,limit,skip){
  this.ngxSpinnerService.show();
  this.masterService.getWebinarTemplateSection(company,this.templateId,10,0)
  .pipe(
    map(result => result.data.rows.map(row => row.section_id))
  )
  .subscribe(data => {
    this.seletedOption = data;
    this.addTemplateForm.controls['sections'].setValue(data);
    this.addTemplateForm.controls['company'].setValue(company);
    console.log("🚀 ~ file: add-templates.component.ts:204 ~ AddTemplatesComponent ~ this.masterService.getTemplateList ~ data:", data)
    this.ngxSpinnerService.hide();
    // if (data.status) {
    //   if (data.data.rows == null) { this.templatesData = [] }
    //   else { this.templatesData = data.data.rows }
    //   this.resCatTotal = data.data.total;
    // } else {
    //   this.templatesData = [];
    //   this.resCatTotal = 0;
    // }
  }, error => { console.log(error);this.ngxSpinnerService.hide(); });
}
}
