import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + "api/"
  }


  getCompanySellerList(id, limit, skip) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companySeller/${id}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getDirectSellerList(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companySellerActive/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getClient() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "company/30/0", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  /*added by raj*/
  getContactList() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "contactList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getContactListById(id,company) {
    var body = {};
    return this.http.get<any>(this.baseUrl + "contact/contactByID/"+id+"/"+company, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getCompanyContactList(id, limit, skip) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `contact/companyContactList/${id}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyProductList(id, limit, skip) {
    return this.http.get<any>(this.baseUrl + `getProductList/${id}?limit=${limit}&skip=${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyEventTypeList(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyEventTypeList/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getcompanyJourneyAssetList(id, limit, skip) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyJourneyAssetList/${id}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getCompanyBUList(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyBUList/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyParentContactList(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyContactParentList/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getCompanyClientList(limit, skip, colname, colname_val, searchText) {
    return this.http.get<any>(this.baseUrl + `companyClientList/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())

      .pipe(

      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  /* added code by raj end here*/

  getThemeList() {
  //  var body = client_id;
    return this.http.get<any>(this.baseUrl + "companyrelation/themesList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getThemeListClient(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyrelation/getThemeListClient/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getThemeAssociation(id, limit:any=0, skip:any=0, colname:any="", order:any="", searchText:any="", type = "all",domain_id?:any,journey?:any) {
    var body = {};
    // var body = value;
    return this.http.get<any>(this.baseUrl + `theme/themeAssociation/${id}/${limit}/${skip}?sort=${colname}:${order}&search=${searchText}&business_domain_id=${domain_id}&journey=${journey}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getThemeAssociationBYCompanyID(id) {

    var body = {};
    return this.http.get<any>(this.baseUrl + `themeAssociation/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getProductList() {
    // var body = value;
    return this.http.get<any>(this.baseUrl + "companyrelation/productList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getJourneyList() {
    // var body = value;
    return this.http.get<any>(this.baseUrl + "companyrelation/journeyList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

getCustomerId() {
  return this.http.get<any>(this.baseUrl + "companyrelation/companyListData", this.httpHeadersOptions())
   .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getJourneyId() {
  return this.http.get<any>(this.baseUrl + "companyrelation/journeyListData", this.httpHeadersOptions())
   .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getThemeId() {
  return this.http.get<any>(this.baseUrl + "companyrelation/themesListData ", this.httpHeadersOptions())
   .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
  // addingCompany(value) {
  //   console.log(value);

  //   var body = value;
  //   return this.http.post<any>(this.baseUrl + "", body, this.httpHeadersOptions())
  //    .pipe(
  //     map(data=>{
  //       if(!data.status)
  //       {
  //         if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
  //         {
  //           console.error(data.message);
  //           data.message="Something went wrong.Please try again."
  //         }
  //       }
  //       return data;
  //     }),
  //     catchError(this.handleError));
  //  }
  createClient(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "company", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getClientById(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `company/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyUserList(id, limit, skip) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `companyUserList/${id}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getSubscriptionUserList(id, limit, skip) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `SubscriptionUserList/${id}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  updateClient(clientObj) {
    return this.http.put<any>(this.baseUrl + `company/`,clientObj, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateClientStatus(clientObj) {
    return this.http.put<any>(this.baseUrl + `companyStatus/`,clientObj, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  deleteClient(value) {
    let id = value;
   // console.log(id);
    return this.http.delete<any>(this.baseUrl + "company/" + id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingNewThemeAssociation(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "theme/themeAssociation", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingNewTheme(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "companyrelation/themesList", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingNewJourney(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "companyrelation/journeyList", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  addingCompany(value) {
  //  console.log(value);

    var body = value;
    return this.http.post<any>(this.baseUrl + "company", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  clientList() {
    return this.http.get<any>(this.baseUrl + "company/clientList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  headquartersList() {
    return this.http.get<any>(this.baseUrl + "company/headquartersList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getBUCompanies() {
    return this.http.get<any>(this.baseUrl + "company/bucompaniesList", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getBUDSList(company,limit,skip) {
    return this.http.get<any>(this.baseUrl + `contact/companyDirectSellerList/${company}/${limit}/${skip}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
                              //Template Api start
  getCompanyTemplate(id, colname: any = 'name', order: any = 'asc') {
    return this.http.get<any>(this.baseUrl +  `cb/getCompanyTemplate/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
                          //  template Api end

  getDsByCompanyId(compId,colname,colname_val) {
    return this.http.get<any>(this.baseUrl + `ds/getDsByCompanyId/${compId}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())

       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getDsByDate(body,compId,colname,colname_val) {
    return this.http.post<any>(this.baseUrl + `log/dslist/${compId}?sort=${colname}:${colname_val}`, body,this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getHostDsByCompanyId(compId,colname,colname_val) {
    return this.http.get<any>(this.baseUrl + `ds/getHostDsByCompanyId/${compId}?sort=${colname}:${colname_val}`, this.httpHeadersOptions())

       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyDBDetail(value) {
    var body ={customer_id:value} ;
    return this.http.post<any>(this.baseUrl + "getCompanyDBDetail", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateCompanyDBDetail(value) {
    var body =value ;
    return this.http.put<any>(this.baseUrl + "updateCompanyDBDetail", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  //Report for admin panel

  reportAllDSUser(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllDSUser/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  reportAllAdminUser(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllAdminUser/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  reportAllHostUser(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllHostUser/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  reportAllProspectUser(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllProspectUser/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  reportAllCustomerUser(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllCustomerUser/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  reportAllProspectCampaigns(id,jid) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllProspectCampaigns/${id}/${jid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }



  reportAllCustomerCampaigns(id,jid) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllCustomerCampaigns/${id}/${jid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  reportAllTeamCampaigns(id,jid) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllTeamCampaigns/${id}/${jid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  reportByTrainingType(id,typeId) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportByTrainingType/${id}/${typeId}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  updateThemeAssociationStatus(obj) {

    return this.http.put<any>(this.baseUrl + "theme/updateThemeJourneyStatus", obj, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getThemeByCompanyJourney(company:any, journey:any) {

    return this.http.get<any>(this.baseUrl + `theme/getThemeByJourneyId/${company}/${journey}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
/*
  gettingTables() {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `pii/gettingTables`, this.httpHeadersOptions())
       //    .pipe(
  //     map(data=>{
  //       if(!data.status)
  //       {
  //         if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
  //         {
  //           console.error(data.message);
  //           data.message="Something went wrong.Please try again."
  //         }
  //       }
  //       return data;
  //     }),
  //     catchError(this.handleError));
  }

  gettingTableFields(name) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `pii/gettingTableFields/${name}`, this.httpHeadersOptions())
       //    .pipe(
  //     map(data=>{
  //       if(!data.status)
  //       {
  //         if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
  //         {
  //           console.error(data.message);
  //           data.message="Something went wrong.Please try again."
  //         }
  //       }
  //       return data;
  //     }),
  //     catchError(this.handleError));
  }
*/









  reportAllEventCampaigns(id,jid) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllEventCampaigns/${id}/${jid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  reportAllContacts(id) {
    //var body = {};
    return this.http.get<any>(this.baseUrl + `host/reportAllContacts/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCompanyDetails(company_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `company/${company_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getProductListByserach(cid, limit, offset, text) {
    let URL = this.baseUrl + 'getProductListByserach/' + cid + '/' + limit + '/' + limit*offset + '/' + text;
    return this.http.get<any>(URL, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  shopJourneyCompanyProductList(id,domain_id?:any) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getProductListByCompanyId/${id}?business_domain_id=${domain_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getGroupProductList(id,domain_id?:any) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getGroupProductList/${id}?business_domain_id=${domain_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
  getRegDsByCompanyId(compId) {
    return this.http.get<any>(this.baseUrl + `ds/login-ds/${compId}`, this.httpHeadersOptions())

       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
}
