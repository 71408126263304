import {BaseComponent} from '../../base.component';
import {Component, OnInit} from '@angular/core';
import {McConnectorService} from '../../../services/mcconnector/mc-connector.service';

@Component({
  selector: 'app-mc-scheduler-stats',
  templateUrl: './mc-scheduler-stats.component.html',
  styleUrls: ['./mc-scheduler-stats.component.css']
})
export class McSchedulerStatsComponent extends BaseComponent implements OnInit{

  companyId: any;
  limit = 10;
  offset = 0;
  cp = 1;
  scheduleStats = [];
  status = false;
  totalItems = 0;
  constructor(private mcService: McConnectorService) {
    super();
    let uData = JSON.parse(localStorage.getItem('userData'));
    this.companyId = parseInt(uData.admin_customer_id, 10);
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.checkMCInformationData();
  }

  //  ##########  Check if MC Information is added for BU or not #############  //

  checkMCInformationData() {
    this.mcService.checkMcInfoData(this.companyId, 10, 0).subscribe(data => {
      if (data.status && data.data.length > 0) {
        this.checkTokenUrl();
      } else {
        this.toastrService.error(data.message);
        this.router.navigateByUrl('/corporate/mc-information');
        this.ngxSpinnerService.hide();
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##########  Check Connectivity with Current Data  ##   //

  checkTokenUrl() {
    this.mcService.checkMcTokenURL(this.companyId).subscribe(data => {
      if (data.status) {
        this.mcFetchSchedulerInfo();
      } else {
        this.toastrService.error('Cannot connect with provided Credentials');
        this.router.navigateByUrl('/corporate/mc-information');
        this.ngxSpinnerService.hide();
        // this.toastrService.error(data.message);
      }
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/mc-information');
    });
  }

  //  ############# Fetch the Scheduler Run Details  ######################  //

  mcFetchSchedulerInfo() {
    this.status = false;
    this.totalItems = 0;
    const offset = this.cp - 1;
    this.mcService.getSchedulerRunStats(this.companyId, this.limit, offset).subscribe(data => {

      if (data.status) {
        this.scheduleStats = data.data.data;
        this.totalItems = data.data.totalCount;
      } else {
        this.scheduleStats = [];
        //this.toastrService.error(data.message);
      }
      this.status = true;
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #################  Fetch details on Page change  ###################  //

  getPageData(page) {
    this.cp = page;
    this.mcFetchSchedulerInfo();
  }

  //  #################  Go to MC Schedule page view  ####################  //

  goToMcSchedule() {
    this.router.navigateByUrl('/corporate/mc-scheduler');
  }
}
