<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/cb-form-builder-list">Form Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="!id">Add</li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="id">Update</li>

    </ol>
  </nav>

<form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()" class="w-100">
<div class="row">
  <div class="col-md-4 mt-2" *ngIf="!id && companyCheck==0">
    <div>
      <select name="company" formControlName="company" class="form-control formstyle"   (change)="getCompanyThemes($event.target.value)">
        <option value="">--Select Company Name--</option>
          <option  *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
      </select>
    </div>
  </div>

  <div class="col-md-4 mt-2">
    <div>

      <select name="company" formControlName="themeid"  class="form-control formstyle"  (change)="getCompanyJourneys($event.target.value)">
        <option value="">--Select Theme Name--</option>
          <option *ngFor="let userObj of themeObj" value={{userObj.theme_id}}>
              {{userObj.theme_name}}
          </option>
      </select>
    </div>
  </div>

  <div class="col-md-4 mt-2">
    <div>
      <select name="company" formControlName="journeyid" class="form-control formstyle"  (change)="getCompanyTemplates($event.target.value)">
        <option value="">--Select Journey Name--</option>
          <option *ngFor="let userObj of journeyObj" value={{userObj.journeyid}}>
              {{userObj.journey_name}}
          </option>
      </select>
    </div>
  </div>

  <div class="col-md-4 mt-2" *ngIf="!id && cloneData.length>0">
    <div class="d-flex align-items-center">
      <select name="company"  class="form-control formstyle" (change)="populateFormByCloneData($event.target.value)"  >
        <option value="">--Select Clone--</option>
        <option  *ngFor="let data of cloneData" value={{data.ttfaid}}>
          {{data.theme_name}}
        </option>
      </select>
      <i class="fa fa-info-circle ml-2 linkForce"  placement="bottom"
         ngbTooltip="Preview" (click)="previewForm(formPreviewModel)" *ngIf="selectedClone"></i>
    </div>
  </div>

</div>

<div class="row" *ngIf="updatedArray?.length > 0">
  <div class="col-12 mt-2 mb-1">
    <h4 class="font-weight-bold mb-0">{{templateObj[0]?.name}}</h4>
  </div>

  <div class="col-6 mt-1 mb-1">
    <input type="text" class="form-control" formControlName="emailSubject" placeholder="Email Subject"/>
  </div>

    <ng-container formArrayName="fieldArray">
    <div class="container" *ngFor="let ctrl of myFormGroup.get('fieldArray')['controls']; let j = index" [formGroupName]="j">


      <div class="row">
        <div class="col-6 mt-1 mb-1">
          <h5 class="mb-0">{{updatedArray[j]?.section_name}}</h5>
        </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <ng-container formArrayName="attributesFieldsArray">
              <ng-container *ngFor="let ctrl1 of ctrl['controls'].attributesFieldsArray['controls']; let k = index" [formGroupName]="k">
                  <div class="col-12  mt-1">
                  <h5>{{updatedArray[j].updatedArray[k]?.field_name}}</h5>
                    <div class="col-6 mt-1 mb-1" *ngIf="updatedArray[j].updatedArray[k].field_name == 'ContainerText' || updatedArray[j].updatedArray[k].field_name == 'SMSText'">
                      <select class="form-control formstyle" [(ngModel)]="containerValue" [ngModelOptions]="{standalone: true}" (change)="selectContainerTextValue(j,k)">
                        <option>--Select Option--</option>
                        <option *ngFor="let data of containerText" [value]="data.value">{{data?.name}}</option>
                      </select>
                    </div>
                  </div>

                  <ng-container [ngSwitch]="updatedArray[j].updatedArray[k]?.field_type">
                    <ng-container *ngSwitchCase="'text'">
                      <div class="col-md-4 col-sm-6 mt-1">
                        <input type="text" class="form-control" formControlName="{{updatedArray[j].updatedArray[k]?.field_name}}"/>
                      </div>
                      <ng-container formArrayName="attData">
                      <div class="col-md-4 col-sm-6 mt-1" *ngFor="let ctrl2 of ctrl1['controls'].attData['controls']; let l = index" [formGroupName]="l">
                        <input type="text" class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l].attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"/>
                        <input class="form-control input" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" [(colorPicker)]="fieldsArray.controls[j]['controls'].attributesFieldsArray['controls'][k]['controls'].attData['controls'][l]['controls'][updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name].value" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l].attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"
                        (cpInputChange)="onEventLog('cpInputChange', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)" (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)"
                        (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)">
                        <span *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.listitem">
                        <select class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.listitem && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color'
                         && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'dropdown'" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}">
                         <option value="">--Select {{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}--</option>
                         <option *ngFor="let list of updatedArray[j].updatedArray[k]?.attData[l]?.listitem.split(',')" [value]="list">{{list}}</option>
                        </select>
                        </span>
                      </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'none'">
                    <ng-container formArrayName="attData">
                    <div class="col-md-4 col-sm-6 mt-1" *ngFor="let ctrl2 of ctrl1['controls'].attData['controls']; let l = index" [formGroupName]="l">
                      <input type="text" class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l].attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"/>
                        <input class="form-control input"  *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" [(colorPicker)]="fieldsArray.controls[j]['controls'].attributesFieldsArray['controls'][k]['controls'].attData['controls'][l]['controls'][updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name].value" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"
                        (cpInputChange)="onEventLog('cpInputChange', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)" (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)"
                        (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)">
                      <span *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.listitem">
                        <select class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color'
                        && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'dropdown'" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}">
                        <option value="">--Select {{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}--</option>
                        <option *ngFor="let list of updatedArray[j].updatedArray[k]?.attData[l]?.listitem.split(',')" [value]="list">{{list}}</option>
                       </select>
                      </span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'image'">

                    <div class="col-md-4 col-sm-6 mt-1" >
                      <input #imageInput class="form-control" type="file" accept=".png, .jpg, .jpeg"
                        (change)="processFile($event,imageInput,j,k,updatedArray[j].updatedArray[k]?.field_name)">
                        <div style="color: red;margin-left:10px ;">
                          Allowed file Types(.png, .jpg, .jpeg)
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mt-1" *ngIf="fieldsArray.value[j].attributesFieldsArray[k][updatedArray[j].updatedArray[k]?.field_name]" [ngClass]="status ? 'displaynone' :'displayblock' ">
                      <img src="{{fieldsArray.value[j].attributesFieldsArray[k][updatedArray[j].updatedArray[k]?.field_name]}}" alt="Logo" style="width:100px;background-color:#8bc34a;">
                    </div>
                    <ng-container formArrayName="attData">
                    <div class="col-md-4 col-sm-6 mt-1" *ngFor="let ctrl2 of ctrl1['controls'].attData['controls']; let l = index" [formGroupName]="l" >
                      <input type="text" class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"/>
                      <input class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" [(colorPicker)]="fieldsArray.controls[j]['controls'].attributesFieldsArray['controls'][k]['controls'].attData['controls'][l]['controls'][updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name].value" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"
                      (cpInputChange)="onEventLog('cpInputChange', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)" (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)">


                      <span *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.listitem">
                      <select class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color'
                        && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'dropdown'" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}">
                        <option value="">--Select {{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}--</option>
                        <option *ngFor="let list of updatedArray[j].updatedArray[k]?.attData[l]?.listitem.split(',')" [value]="list">{{list}}</option>
                       </select>
                      </span>

                    </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'textarea'" >
                    <div class="col-md-4 col-sm-6 mt-1">
                      <textarea  class="form-control" formControlName="{{updatedArray[j].updatedArray[k]?.field_name}}" maxlength="{{updatedArray[j].updatedArray[k]?.field_size}}" style="min-height: 100px;"></textarea>
                    </div>
                    <ng-container formArrayName="attData">
                    <div class="col-md-4 col-sm-6 mt-1" *ngFor="let ctrl2 of ctrl1['controls'].attData['controls']; let l = index" [formGroupName]="l" >
                      <input type="text" class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"/>
                      <input class="form-control input" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'color' && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'dropdown'" [(colorPicker)]="fieldsArray.controls[j]['controls'].attributesFieldsArray['controls'][k]['controls'].attData['controls'][l]['controls'][updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name].value" placeholder="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}"
                      (cpInputChange)="onEventLog('cpInputChange', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)" (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event,k,j,l,updatedArray[j].updatedArray[k]?.attData[l].attribute_name)">

                      <span *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.listitem">
                      <select class="form-control" *ngIf="updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type !== 'color'
                        && updatedArray[j].updatedArray[k]?.attData[l]?.attribute_type == 'dropdown'" formControlName="{{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}">
                        <option value="">--Select {{updatedArray[j].updatedArray[k]?.attData[l]?.attribute_name}}--</option>
                        <option *ngFor="let list of updatedArray[j].updatedArray[k]?.attData[l]?.listitem.split(',')" [value]="list">{{list}}</option>
                       </select>
                    </span>
                    </div>
                    </ng-container>
                  </ng-container>



                </ng-container>

              </ng-container>
              </ng-container>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
    </ng-container>
    <div class="col-12 text-center mb-2"><button type="submit" class="custbtn" *ngIf="!id">Submit</button></div>
    <div class="col-12 text-center mb-2"><button type="submit" class="custbtn" *ngIf="id">Update</button></div>

  </div>
</form>

</div>


<!--<ng-template  #formPreviewModel let-modal role="dialog" style="z-index:999999;" data-keyboard="false" data-backdrop="static">-->

<!--  <div class="modal-body">-->
<!--   <app-cb-form-preview [childFormData]="formPreview"></app-cb-form-preview>-->
<!-- </div>-->


<!-- </ng-template>-->

<ng-template  #formPreviewModel let-modal role="dialog" style="z-index:999999;" data-keyboard="false" data-backdrop="static">

  <div class="modal-body">

<!--    <app-cb-form-preview [childFormData]="formChildData" ></app-cb-form-preview>-->


    <P [innerHTML]="formChildData =='' ?'': formChildData | safeHTMLtags: 'html'"></P>
  </div>


</ng-template>

<!-- </div> -->

