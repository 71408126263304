<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Clients / Customers</li>
    </ol>
  </nav>
  </div>
  <div class="container mt-2 themeTblReso position-relative z-in99">
  <div class="row">

    <div class="col-sm-6">

        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                aria-hidden="true"></i></span>
          </div>
          <input class="form-control" value="" type="text" placeholder="Search Client name" aria-label="Search" #searchText>
        </div>
      </div>
      <div class="col-sm-4 mt-1 mt-sm-0" style="justify-content: right;">
        <input type="button" *ngIf="isWrite" value="Add New" class="custbtn mr-1 ml-0" routerLink="/corporate/clientnew">
        <!-- <button type="button" class="custbtn" (click)="location.back()">Back</button> -->
      </div>
    </div>

  <div class="row1 mt-1">

      <div id="no-more-tables">
          <table class=" cf bdrStCustom accordSmall">
          <thead class="cf">
            <tr>
              <!-- <th>	Industry Id</th> -->
              <th (click)="sorting('customer_company_name')" style="cursor: pointer">Client Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
              <th>Start Date</th>

              <th (click)="sorting('customer_contact_fname')"  style="cursor: pointer">Contact Name <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>

              <th>Contract Term</th>
              <th>Headquarter</th>
              <!-- <th>Added By</th>
              <th>Added Date</th> -->
              <th style="text-align: center" *ngIf="isEdit || isDelete">Action</th>
              <!-- <th>Actions</th> -->
            </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="isAllowed">
            <ngb-accordion *ngFor="let obj of companyObj">
              <ngb-panel title="{{obj.customer_company_name}}">
                <ng-template ngbPanelContent>
                    <tr id="{{obj.customer_id}}" class="multi-collapse">
                    <!-- <td>{{obj.get_customer_registration_fun.customer_id}}</td> -->



                    <td  *ngIf="obj.isdb==true" data-title="Client_Name" style="text-decoration:underline;cursor: pointer;" (click)="(obj.customer_parentid==0)?clientHeadquaterView(obj.customer_id):clientView(obj.customer_id)">
                      {{obj.customer_company_name?obj.customer_company_name:'N/A'}}</td>

                    <td  *ngIf="obj.isdb==false" data-title="Client_Name" >
                      {{obj.customer_company_name?obj.customer_company_name:'N/A'}}</td>


                  <!--<td style="underline;cursor: pointer;" (click)="clientView(obj.get_customer_registration_fun.customer_id)">
                      {{obj.get_customer_registration_fun.customer_company_name}}</td>-->

                    <td  data-title="Start Date">{{obj.start_date | date}}</td>
                    <td  data-title="Contact_Name">{{obj.customer_contact_fname + " " +obj.customer_contact_lname }}</td>
                    <td  data-title="Contract Term">{{obj.contract_in_years ? obj.customer_company_name+' Years':'N/A'}}</td>
                    <td data-title="Headquarter">{{obj.customer_parentid==0 ? 'Yes' : obj.headquartername ? obj.customer_company_name:'N/A'}}</td>
                    <!-- <td>{{obj.get_customer_registration_fun.createdname}}</td>
                    <td>{{obj.get_customer_registration_fun.createdon |date}}</td> -->
                    <td  data-title="Action" *ngIf="isEdit || isDelete">
                      <div class="actBtn">
                      <i class="fa fa-edit mr-1 linkForce" *ngIf="isEdit" (click)="editCompany(obj.customer_id)"></i>
                      <i class="fa fa-trash mr-1 linkForce" *ngIf="isDelete" (click)="deleteCompany(obj.customer_id)"></i>
                      <mat-slide-toggle [checked]="obj.isactive" *ngIf="isEdit" class="my-slider"
                        (change)="updateClientStatus($event,obj.customer_id)">
                      </mat-slide-toggle>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>
            </ng-container>
          </tbody>
            <ng-container *ngIf="!isAllowed">
              <tbody *ngFor="let obj of companyObj">

              <tr id="{{obj.customer_id}}" class="multi-collapse">
                <!-- <td>{{obj.get_customer_registration_fun.customer_id}}</td> -->



                <td *ngIf="obj.isdb==true" data-title="Client_Name" style="text-decoration:underline;cursor: pointer;" (click)="(obj.customer_parentid==0)?clientHeadquaterView(obj.customer_id):clientView(obj.customer_id)">
                  {{obj.customer_company_name}}
                </td>

                <td *ngIf="obj.isdb==false" data-title="Client_Name" >
                  {{obj.customer_company_name}}</td>


              <!--<td style="underline;cursor: pointer;" (click)="clientView(obj.get_customer_registration_fun.customer_id)">
                  {{obj.get_customer_registration_fun.customer_company_name}}</td>-->

                <td  data-title="Start Date">{{obj.start_date | date}}</td>
                <td  data-title="Contact_Name">{{obj.customer_contact_fname}}</td>
                <td  data-title="Contract Term">{{obj.contract_in_years?obj.contract_in_years+' Years':'-'}}  </td>
                <td data-title="Headquarter">{{obj.customer_parentid==0 ? 'Yes' : obj.headquartername}}</td>
                <!-- <td>{{obj.get_customer_registration_fun.createdname}}</td>
                <td>{{obj.get_customer_registration_fun.createdon |date}}</td> -->
                <td  data-title="Action" *ngIf="isEdit || isDelete">
                  <div class="actBtn">
                  <i class="fa fa-edit mr-1 linkForce" *ngIf="isEdit" (click)="editCompany(obj.customer_id)"></i>
                  <i class="fa fa-trash mr-1 linkForce" *ngIf="isDelete" (click)="deleteCompany(obj.customer_id)"></i>
                  <mat-slide-toggle [checked]="obj.isactive" *ngIf="isEdit" class="my-slider"
                    (change)="updateClientStatus($event,obj.customer_id)">
                  </mat-slide-toggle>
                  </div>
                </td>
              </tr>
            </tbody>
            </ng-container>

        </table>
        
        <div
        *ngIf="companyObj.length === 0"
        style="text-align: center"
        >
        <p>No Result Available</p>
        </div>
      </div>

  </div>

</div>

  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getCompanyData(page=$event)" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination> 
  </div>