import {BaseComponent} from '../../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentationService} from '../../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-add-attribute-operator-association',
  templateUrl: './add-attribute-operator-association.component.html',
  styleUrls: ['./add-attribute-operator-association.component.css']
})
export class AddAttributeOperatorAssociationComponent extends BaseComponent implements OnInit{

  operatorObj = [];
  operatorid = [];
  attributes = [];
  operatorList = [];
  attributeForm: FormGroup;
  submitted = false;
  show = false;
  limit = 9999;
  offset = 0;
  assocId;
  constructor(private formBuilder: FormBuilder, private segmentService: SegmentationService) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.assocId = params.assocId;
      });
  }

  ngOnInit() {

    this.getAttributes();
    this.attributeForm = this.formBuilder.group({
      aid: ['', Validators.required],
      oid: ['']
    });
  }

  get f() { return this.attributeForm.controls; }

  //  ###########  Get Attributes List  ##################  //

  getAttributes(){
    this.ngxSpinnerService.show();
    this.segmentService.getSegementAttributeList(this.limit, this.offset, 'createdon','desc').subscribe(data => {
      if (data.status) {
        this.attributes = data.data.rows;
        this.getOperatorsList();
        this.ngxSpinnerService.hide();
      } else {
        this.attributes = [];
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error);
    });
  }

  //  #################  Get Operators List   ################  //

  getOperatorsList(){
    this.segmentService.getOperatorList(this.limit, this.offset).subscribe(data => {
      if (data.status) {
        this.operatorList = data.data.rows;
        console.log('aaaaaaaaa',this.operatorList )
        if (this.assocId){
          this.getEditAssociation();
        } else {
          this.ngxSpinnerService.hide();
        }
      } else {
        this.operatorList = [];
        this.toastrService.error(data.message);
      }
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error);
    });
  }

  //  ##############  Populate Form with Values from assocID passed in URL  ########  //

  getEditAssociation(){
    this.attributeForm = this.formBuilder.group({
      aid: [this.assocId, Validators.required],
      oid: ['']
    });
    this.ngxSpinnerService.hide();
    this.getAttributeOperator(this.assocId);
  }

  //  ##############  Get Associated List on Attribute Dropdown Select  ###########  //

  getAttributeOperator(val){
    this.operatorObj = [];
    this.operatorid = [];
    this.show = false;
    this.submitted = false;
    if (val === '') {
      return;
    }

    this.ngxSpinnerService.show();
    this.segmentService.getAssociatedOperatorsForAttributes(val).subscribe(data => {

      if (data.status) {

        this.show = true;
        this.operatorObj = data.data;
        if (this.operatorObj.length > 0) {
          this.operatorObj.forEach(element => {
            this.operatorid.push(element.operator_id);
          });
        }
      } else  {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      //console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############# Add Operator-Attribute Association ##########  //

  addOperators(){
    this.submitted = true;
    if (this.attributeForm.invalid) {
      return;
    }
    if (this.operatorid.length === 0) {
      this.toastrService.error('Please select at least one Operator');
      return;
    }
    let obj = {
      'operator_id': this.operatorid,
      'field_id': this.attributeForm.value.aid
    };

    this.segmentService.addAttributeOperatorAssociation(obj).subscribe(data => {
      this.ngxSpinnerService.show();
      if (data.status) {
        this.toastrService.success('Association added Successfully');
        this.router.navigateByUrl('/corporate/attribute-operator-association-list');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ############# Select Operator on Check ################  //

  selectOperators(event, id) {
    if (event.target.checked) {
      this.operatorid.push(id);
    } else {
      this.operatorid.splice(this.operatorid.indexOf(id), 1);
    }
  }
}
