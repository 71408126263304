import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-pi',
  templateUrl: './pi.component.html',
  styleUrls: ['./pi.component.css']
})
export class PiComponent  extends BaseComponent implements OnInit {

  constructor() { super(); }
  customerID;

  ngOnInit() {

     var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
     this.customerID= customerInfo.admin_customer_id; 
  }

}
