import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinar-module',
  templateUrl: './webinar-module.component.html',
  styleUrls: ['./webinar-module.component.css']
})
export class WebinarModuleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
