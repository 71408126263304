<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/journeymaster">Journey Master</a></li>
            <li class="breadcrumb-item active">{{header}} Journey</li>
        </ol>
    </nav>
</div>
<div class="container mt-2">
    <form class="form-horizontal mt-1" [formGroup]="journeyForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">

            <div class="card">
                <div class="card-body">
                <div>
                    <label for="exampleForm2">Select Company Name {{header}}<span style="color:red">*</span></label>
                    <select formControlName="company" *ngIf="header == 'Add New'" class="form-control formstyle"
                        [ngClass]="{ 'is-invalid': submitted && f.company.errors }"  (change)="getCompanyJourney($event.target.value)">
                        <option value="">Select Comapny</option>
                        <ng-container *ngFor="let userObj of getCompanyobj" >
                            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                            {{userObj.customer_company_name}}
                        </option>
                        </ng-container>
                    </select>

                    <select formControlName="company" *ngIf="header == 'Edit'" disabled="controlEnabled" class="form-control formstyle"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }"  (change)="getCompanyJourney($event.target.value)">
                    <option value="">Select Comapny</option>
                    <ng-container *ngFor="let userObj of getCompanyobj" >
                        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                        {{userObj.customer_company_name}}
                    </option>
                    </ng-container>
                    </select>
                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                        <div *ngIf="f.company.errors.required">Company Name is required</div>
                    </div>
                </div>

                <div class="mt-1">
                    <label for="exampleForm2">Contact Journey Type</label>
                    <select formControlName="contact_type" class="form-control formstyle">
                        <option value="1">Team Member</option>
                        <option value="2">Customer</option>
                        <option value="4">Host</option>
                        <option value="0">None</option>
                    </select>
                </div>


                <div class="mt-1">
                    <label for="exampleForm2">Journey Parent Id</label>
                    <select formControlName="journeyParentId" class="form-control formstyle">
                        <option value="0">Root</option>
                        <option *ngFor="let userObj of journeyObj" value={{userObj.journey_id}}>
                            {{userObj.journey_name}}
                        </option>
                    </select>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Journey Name  <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="journeyName"
                        class="form-control form-control-md" required
                        [ngClass]="{ 'is-invalid': submitted && f.journeyName.errors }" maxlength="30" appSpace >
                    <div *ngIf="submitted && f.journeyName.errors" class="invalid-feedback">
                        <div *ngIf="f.journeyName.errors.required || f.journeyName.hasError('whitespace')">Journey Name is required</div>
                        <div *ngIf="f.journeyName.errors?.maxlength">You can enter only 30 charachters</div>


                    </div>
                </div>
                <div class="mt-1">

                    <label for="exampleForm2">Journey URL  <span style="color:red">*</span></label>
                    <input type="text"  formControlName="url"
                        class="form-control form-control-md" min="5" maxlength="499"  [ngClass]="{ 'is-invalid': submitted && f.url.errors }" appSpace>

                        <div *ngIf="submitted &&  f.url.errors" class="invalid-feedback">
                            <div *ngIf="f.url.errors.required  || f.journeyName.hasError('whitespace')">Journey URL is required</div>
                            <div *ngIf="f.url.errors.pattern">Space is not allowed in Journey URL</div>




                        </div>

                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Journey Description</label>
                    <input type="text"  formControlName="journeyDescription"
                        class="form-control form-control-md" min="1" maxlength="499">
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Journey Icon <span style="color:red">*</span></label>
                    <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                        <img src="{{journey_icon}}" alt="Journey Icon" style="height:150px;" (error)="setValue($event)">
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio1"
                                formControlName="firstActive" [value]="true" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio1">Url</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2"
                                formControlName="firstActive" [value]="false" (change)='radioChange()'>
                            <label class="custom-control-label" for="customRadio2">Upload a file</label>
                        </div>
                    </div>
                    <div *ngIf="screenshot_type" class="mt-1">
                        <input type="text" formControlName="journeyIcon"
                            placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                            class="form-control form-control-md">
                      </div>
                    <div *ngIf="!screenshot_type" class="mt-1">
                        <input class="form-control" #imageInput type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event,imageInput)">
                        <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, gif)</div>
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Is Segment</label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isSegement1"
                                formControlName="issegment" [value]="true" >
                            <label class="custom-control-label" for="isSegement1">YES</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isSegement2"
                                formControlName="issegment" [value]="false" >
                            <label class="custom-control-label" for="isSegement2">NO</label>
                        </div>
                    </div>
                </div>

                <div class="mt-1">
                    <label for="exampleForm2">Is Product</label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isProduct1"
                                formControlName="isproduct" [value]="true" >
                            <label class="custom-control-label" for="isProduct1">YES</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isProduct2"
                                formControlName="isproduct" [value]="false" >
                            <label class="custom-control-label" for="isProduct2">NO</label>
                        </div>
                    </div>
                </div>



                <div class="mt-1">
                    <label for="exampleForm2">Is One Day</label>

                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isoneday1"
                                formControlName="is_oneday" [value]="true" >
                            <label class="custom-control-label" for="isoneday1">YES</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="isoneday2"
                                formControlName="is_oneday" [value]="false" >
                            <label class="custom-control-label" for="isoneday2">NO</label>
                        </div>
                    </div>

                </div>



                <div class="mt-1">

                    <label for="exampleForm2">Market show id for email
                        <span style="color:red">*</span>
                    </label>
                    <input onkeypress="return event.charCode >= 48" min="1" type="number" formControlName="marketshowid_email" class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.marketshowid_email.errors }">

                    <div *ngIf="submitted && f.marketshowid_email.errors" class="invalid-feedback">
                        <div *ngIf="f.marketshowid_email.errors">
                            Market show id for email is required
                        </div>

                    </div>

                </div>


                <div class="mt-1">
                    <label for="exampleForm2">Market show id for mms
                        <span style="color:red">*</span>
                    </label>
                    <input onkeypress="return event.charCode >= 48" min="1" type="number" formControlName="marketshowid_mms" class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.marketshowid_mms.errors }">

                    <div *ngIf="submitted && f.marketshowid_mms.errors" class="invalid-feedback">
                        <div *ngIf="f.marketshowid_mms.errors">
                            Market show id for mms is required
                        </div>
                    </div>
                </div>


                <div class="mt-1">
                    <label for="exampleForm2">Journey Order</label>
                    <select formControlName="total_journey" class="form-control formstyle">
                        <option value="{{i+1}}" *ngFor='let in of counter(20) ;let i = index'>{{i+1}}</option>
                    </select>
                </div>

            </div>
            <div class="card-footer">
                <div class="text-center my_class " *ngIf="isWrite" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn" (click)="addJourney()">Submit</button>
                </div>
                <div class="text-center my_class " *ngIf="isEdit" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn" (click)="UpdateJourney()">Update</button>
                </div>
            </div>
            </div>
        </div>
        </div>
    </form>
</div>
