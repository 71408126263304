<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/shop-live">Shop Live</a></li>
      <li class="breadcrumb-item active" aria-current="page">Edit Corporate Library</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 mt-md-2">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal mt-1" [formGroup]="updateCategoryForm">
        <div class="card">
          <div class="card-body">
            <div class="form-group" *ngIf="customer_id===0">
              <label for="exampleForm2">Company Name <span class="required">*</span></label>
              <select formControlName="selectedCompany" *ngIf="post_id!= undefined" class="form-control formstyle"

               [attr.disabled]="controlEnabled" (change)="getCategory($event.target.value)"

                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    <!-- <option *ngFor="let Obj of getCompanyobj" value={{Obj.customer_id}}> -->
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">


            <div class="form-group">
              <label for="exampleForm2">Category Name <span class="required">*</span></label>
              <select formControlName="categoryid" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.categoryid.errors }">
                <option value="">Select Category</option>
                <option *ngFor="let Obj of postCategoryObj" value={{Obj.sl_cat_id}}>
                  {{Obj.sl_cat_name}}
                </option>
              </select>
              <div *ngIf="submitted && f.categoryid.errors" class="invalid-feedback">
                <div *ngIf="f.categoryid.errors.required">Category Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Post Name <span class="required">*</span></label>
              <input type="text" class="form-control" formControlName="name" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="50" appSpace>
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Post Name is
                  required</div>
                  <div *ngIf="f.name.errors?.maxlength">
                    You reached to maximum length
                  </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Description <span class="required">*</span> </label>
              <textarea class="form-control text-area h-25" rows="4" id="description" formControlName="description"
                maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" name="description"
                appSpace>
              </textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required || f.description.hasError('whitespace')">
                  Description is required</div>
                <div *ngIf="f.description.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">URL</label>
              <input type="text" class="form-control" formControlName="url" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
              <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                <div *ngIf="f.url.errors.pattern">Please enter valid URL</div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2">Post Image</label>

              <!-- <label for="exampleForm2">{{isImage?'Image':'Video'}}</label> -->
              <div class="row">

                <div class="col-sm-12 text-left uploadImgMain mb-1" *ngFor="let imgs of imgURL;index as i">
                  <i class="fa fa-trash-o" (click)="deletedImgFile(i, 'imgURL')" *ngIf="imgURL.length > 0"></i>
                  <img [src]="imgs" style="width: 130px;" (error)="setValue($event)"/>
                </div>

                <!-- <div class="col-sm-12 text-left uploadImgMain mb-1" *ngFor="let imgs of postImgURL; index as i">
                  <i class="fa fa-trash-o" (click)="deletedImgFile(i, 'postImgURL')" *ngIf="postImgURL.length > 0"></i>

                  <img [src]="imgs" style="width: 130px;" (error)="setValue($event)"/>
                </div>

                <div class="col-sm-12 text-left uploadImgMain" *ngFor="let videoURL of postVideoURLs; index as i">
                  <i class="fa fa-trash-o z-index-1" (click)="deletedVideoFile(i, 'postVideoURLs')" *ngIf="postVideoURLs.length > 0"></i>
                  <video width="50%" autoplay controls [src]="videoURL" (error)="setValue($event)">
                  </video>
                </div> -->

              <div class="col-sm-12 text-left uploadImgMain" *ngFor="let videoURL of videoURLs;index as i">

                <i class="fa fa-trash-o z-index-1" (click)="deletedVideoFile(i, 'videoURLs')" *ngIf="videoURLs.length > 0"></i>

                <video width="50%" autoplay controls [src]="videoURL" (error)="setValue($event)">
                </video>
              </div>

              <div class="col-sm-12 text-left uploadImgMain" *ngFor="let file of downloadfile as i">
                <i class="fa fa-trash-o"
                    (click)="deleteFile(i)" *ngIf="downloadfile.length > 0"></i>
                <a href="{{file}}" *ngIf="downloadfile.length > 0" style="margin-left: 30px">
                    <img  src="assets/images/doc_image.jpg" style="max-height: 35px; min-width: 35px;"/>
                </a>
              </div>

                <!-- <div class="col-sm-12 text-left uploadImgMain mb-1" *ngFor="let imgs of imgURL;index as i">

                    <i class="fa fa-trash-o" (click)="deletedImgFile(i, 'imgURL')" *ngIf="imgURL.length > 0"></i>

                    <img [src]="imgs" style="width: 130px;" (error)="setValue($event)"/>
                </div>

                <div class="col-sm-12 text-left uploadImgMain" *ngFor="let videoURL of videoURLs;index as i">

                    <i class="fa fa-trash-o z-index-1" (click)="deletedVideoFile(i, 'videoURLs')" *ngIf="videoURLs.length > 0"></i>

                    <video width="50%" autoplay controls [src]="videoURL" (error)="setValue($event)">
                    </video>
                </div>
                <div class="col-sm-12 text-left uploadImgMain" *ngFor="let file of downloadfile as i">
                    <i class="fa fa-trash-o"
                        (click)="deleteFile(i)" *ngIf="downloadfile.length > 0"></i>
                    <a href="{{file}}" *ngIf="downloadfile.length > 0" style="margin-left: 30px">
                        <img  src="assets/images/doc_image.jpg" style="max-height: 35px; min-width: 35px;"/>
                    </a>
                </div>  -->
            </div>
              <div>

                <!-- <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio1" formControlName="firstActive"
                    [value]="0" (change)='radioChange()'>
                  <label class="custom-control-label" for="customRadio1">Image URL</label>
                </div> -->
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="customRadio2" formControlName="firstActive"
                    [value]="0">
                  <label class="custom-control-label" for="customRadio2">Upload a file <span class="required">*</span></label>
                </div>
              </div>
              <!-- <div *ngIf="screenshot_type" class="mt-1">
                <input type="text" formControlName="post_screenshot" class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png, .gif)">

              </div> -->
              <!-- *ngIf="!screenshot_type"  -->
              <div class="mt-1">

                <input  #imageInput type="file"
                  accept=".png, .jpg, .jpeg, .gif, .mov, .mp4, .csv, .doc, .docs, .docx, .xls, .xlsx, .pptx, .txt"
                  (change)="processFile($event,imageInput)">
                <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif, .mov, .mp4, .csv,
                  .doc, .docs, .xls, .xlsx, .pptx, .txt)</div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleForm2">Select Business Domain Name</label>
              <select formControlName="business_domain_id" class="form-control formstyle">
                <option value="">Select Domain</option>
                <option *ngFor="let Obj of domainData" value={{Obj.id}}>
                  {{Obj.domain_name}}
                </option>
              </select>
            </div>
          </div>
          <div class="card-footer">
            <div class="my_class text-center">
              <button type="button" class="custbtn" (click)="updatePost()">Update</button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>

</div>
