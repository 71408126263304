<ngx-spinner  bdOpacity = 0.9
bdColor = "#333"
size = "large"
color = "#fff"
type = "ball-clip-rotate">
<p style="color: white" > Loading... </p>
</ngx-spinner>
<div class="container h-100 app-content">
    <div class="row h-100 justify-content-center align-items-center mt--4">
        <div class="col-md-4 col-sm-8 col-11">
            <div class="text-center mainLogLogo">
                <img src="../../assets/custom/images/margologo250.png" alt="branding logo">
            </div>
            <div style="font-size:15px;color:red;text-align:center;font-weight: bold;"
                [ngClass]="status ? 'displayblock' : 'displaynone'">
                {{emsg}}
            </div>
            <form class="form-horizontal text-center mt-2" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                <!-- <fieldset class="form-group position-relative has-icon-left"> -->
                <input type="text" formControlName="email" class="form-control formstyle mt2"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                    placeholder="Username" required>

                <div style="text-align:left" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Username is required</div>
                </div>
                <input type="password" placeholder="Password" formControlName="password"
                    class="form-control formstyle mt-2"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

                <div style="text-align:left" *ngIf="submitted && f.password.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
                <div class="forgt mt-2">
                    <a [routerLink]="['/forgot-user']" class="card-link">Forgot Username?</a>
                </div>
                <div class="forgt mt-2">
                    <a [routerLink]="['/forgot-pwd']" class="card-link">Forgot Password?</a>
                </div>
                <button type="submit" class="custbtn mt-2">
                    Login
                </button>
            </form>
        </div>
    </div>
</div>
