
  <div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/margoproducts">{{projectName}} products</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{projectName}} new user</li>
      </ol>
    </nav>
  </div>


<!-- <h3><a routerLink="/corporate/margoproducts">Back</a></h3> -->
<div class="container">
  <div class="row">
      <div class="col-sm-3 mt-2 mt-sm-0 mb-2 mb-sm-0"> 
          <h5>Role Information</h5>
      </div>
      <div class="col-sm-9 mt-2 mt-sm-0 mb-2 mb-sm-0">
          <button type="button" class="custbtn">Edit</button>&nbsp;
          <button type="button" class="custbtn">clone</button>&nbsp;
          <button type="button" class="custbtn">Delete</button>
      </div>
  </div>

  <div class="row">
      <div class="col-sm-4 mt-sm-4 mt-2">
          <label for="exampleForm2">Product Name</label>
          <input type="text" autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
      <div class="col-sm-4 mt-sm-4 mt-2">
          <label for="exampleForm2">Product Type</label>
          <input type="text" autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
      <div class="col-sm-4 mt-sm-4 mt-2">
          <label for="exampleForm2">Created Date</label>
          <input type="date" autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
  </div>


  <div class="row">
    <div class="col-sm-4 mt-2">
        <label for="exampleForm2">Created By</label>
        <input type="text"  autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
    </div>
      <div class="col-sm-4 mt-2">
          <label for="exampleForm2">Product Id</label>
          <input type="text"  autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
      <div class="col-sm-4 mt-2">
          <label for="exampleForm2">Product Description</label>
          <input type="text"  autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
      <div class="col-sm-4 mt-2">
          <label for="exampleForm2">Is Active</label>
          <input type="text"  autocomplete="off" id="exampleForm2" class="form-control form-control-lg">
      </div>
  </div>
</div>

