import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from "../../../../_services/alert.service";
import { ClientService } from 'src/app/services/clients/client.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-newrekinning',
  templateUrl: './newreskinning.component.html',
  styleUrls: ['./newreskinning.component.css']
})
export class NewreskinningComponent extends BaseComponent implements OnInit {
  theme_name;
  theme_description;
  screenshot: String;
  IsActive;
  them_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey = '';
  them_company;
  getCompanyobj;
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID:any;
  correctImage: boolean = true;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: FormGroup;

  bodybackgroundcolor = '';
  headerbackgroundcolor = '';
  iconcolor = '';
  bodytextcolor = '';
  dashboardtext = '';
  dsaliasname = '';
  companyLogo = '';
  modelLogo = '';

  url = '';
  bodyBackgroundImage = '';
  picture_outline_color = '';

  scroll_color = '';
  contact_heading_text = '';
  contact_heading_text_color = '';
  team_heading_text = '';
  team_heading_text_color = '';
  page_content_font_color = '';
  tab_color = '';
  tab_text_color = '';
  button_color = '';
  button_text_color = '';
  page = 'master';
  // companyId:any;
  getDSobj:any;
  dsId:any;
  smsService:any = 'vonage';
  userType='';
  emailTemplatelogo = '';
  minisite_header_bg_color  = "";
  minisite_header_text_color = "";
  minisite_footer_bg_color  = "";
  minisite_footer_text_color = "";
  minisite_body_bg_color  = "";
  minisite_body_text_color = "";
  minisite_button_text_color = "";
  minisite_primary_color = "";
  minisite_secondary_color = "";
    ds_id: any;
    email_template_logo:any;
    cartDisable:boolean=false;
    dsAvailableSenderNumbers: any;
    dsSenderNumber: any;
  domainDropdown: any[];
  constructor(
    private master: MasterService,
    private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit() {
    this.headers = localStorage.getItem('rskAction');
    this.page = this.master.getCataloguePage();
    if(localStorage.getItem('companyId')){
        this.companyID = localStorage.getItem('companyId');
        this.getDSLIST(this.companyID);
    }
    if(localStorage.getItem('companyId')){
      this.dsId = localStorage.getItem('dsId');
    }
    let self = this;
    self.getCompany();
    this.getBusinessDomainList();
    this.getSenderNumber();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.them_id = params.them_id;
        this.userType=params.user_type
      });






   // console.log("this.companyID-1", this.companyID);



   this.themeForm = this.formBuilder.group({
     company: [this.companyID, Validators.required],
     business_domain_id:['',Validators.required],
     dsid: [this.dsId],
     logo: [''],
     model_logo: [''],
     url: [''],
     backgroundImage: [''],
     bodybackgroundcolor: [this.bodybackgroundcolor],
     headerbackgroundcolor: [this.headerbackgroundcolor],
     iconcolor: [this.iconcolor],
     bodytextcolor: [this.button_text_color],
     dashboardtext: [this.dashboardtext],
     dsaliasname: ['How can marGo help today ?'],
     teamMemberLabel: [''],
     picture_outline_color: [this.picture_outline_color],
     scroll_color: [this.scroll_color],
     contact_heading_text: ['Contact'],
     contact_heading_text_color: [this.contact_heading_text_color],
     team_heading_text: ['Team Member'],
     team_heading_text_color: [this.team_heading_text_color],
     page_content_font_color: [this.page_content_font_color],
     tab_color: [this.tab_color],
     tab_text_color: [this.tab_text_color],
     button_color: [this.button_color],
     button_text_color: [this.button_text_color],
     isactive: true,
     logostatus: true,
     eventFlag: true,
     profileFlag: true,
     modelLogoFlag: true,
     default: false,
     smsService: [this.smsService],
    //  emailTemplatelogo = [''],
     minisite_header_bg_color: [""],
     minisite_header_text_color: [""],
     minisite_footer_bg_color: [""],
     minisite_footer_text_color: [""],
     minisite_body_text_color: [""],
     minisite_button_text_color: [""],
     minisite_primary_color: [""],
     minisite_secondary_color: [""],
     minisite_body_bg_color  : [""],
     currency: ['$'],
     mini_site_product_list: null,
     mini_site_add_to_cart: null,
     mini_site_display_price: null,
     email_template_logo: [],
     logo_height: [37],
     ds_sender_number: ['']
  });

    if (this.headers ==="Edit") {

      this.master.getReskinningById(this.companyID, this.them_id).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.themObj = response.data;

          self.dataBinding();
        } else {
        }
        this.ngxSpinnerService.hide();
      });
      this.UpdateThemeLogData()
    }
    else {
      this.addThemeLogData()
      this.defaultSetting();

    }
  }
  get f() { return this.themeForm.controls; }





  // radioChange() {
  //   this.screenshot_type = !this.screenshot_type;
  // }

  public onChangeColor(color: string, inputfield): void {
    let inputfieldData = inputfield;

    if (inputfieldData == 'bodybackgroundcolor') {
      this.themeForm.value.bodybackgroundcolor = color;
    }
    else if (inputfieldData == 'headerbackgroundcolor') {
      this.themeForm.value.headerbackgroundcolor = color;
    }
    else if (inputfieldData == 'iconcolor') {
      this.themeForm.value.iconcolor = color;
    }
    else if (inputfieldData == 'bodytextcolor') {
      this.themeForm.value.bodytextcolor = color;
    }
    else if (inputfieldData == 'dashboardtext') {
      this.themeForm.value.dashboardtext = color;
    }
    else if (inputfieldData == 'picture_outline_color') {
      this.themeForm.value.picture_outline_color = color;
    }
    else if (inputfieldData == 'scroll_color') {
      this.themeForm.value.scroll_color = color;
    }

    else if (inputfieldData == 'contact_heading_text_color') {
      this.themeForm.value.contact_heading_text_color = color;
    }
    else if (inputfieldData == 'team_heading_text_color') {
      this.themeForm.value.team_heading_text_color = color;
    }
    else if (inputfieldData == 'page_content_font_color') {
      this.themeForm.value.page_content_font_color = color;
    }
    else if (inputfieldData == 'tab_color') {
      this.themeForm.value.tab_color = color;
    }
    else if (inputfieldData == 'tab_text_color') {
      this.themeForm.value.tab_text_color = color;
    }
    else if (inputfieldData == 'button_color') {
      this.themeForm.value.button_color = color;
    }
    else if (inputfieldData == 'button_text_color') {
      this.themeForm.value.button_text_color = color;
    }
    else if (inputfieldData == "minisite_header_bg_color") {
      this.themeForm.value.minisite_header_bg_color = color;
    }
    else if (inputfieldData == "minisite_header_text_color") {
      this.themeForm.value.minisite_header_text_color = color;
    }
    else if (inputfieldData == "minisite_footer_bg_color") {
      this.themeForm.value.minisite_footer_bg_color = color;
    }
    else if (inputfieldData == "minisite_footer_text_color") {
      this.themeForm.value.minisite_footer_text_color = color;
    }
    else if (inputfieldData == "minisite_body_bg_color") {
      this.themeForm.value.minisite_body_bg_color = color;
    }
    else if (inputfieldData == "minisite_body_text_color") {
      this.themeForm.value.minisite_body_text_color = color;
    }
    else if (inputfieldData == "minisite_button_text_color") {
      this.themeForm.value.minisite_button_text_color = color;
    }
    else if (inputfieldData == "minisite_primary_color") {
      this.themeForm.value.minisite_primary_color = color;
    }
    else if (inputfieldData == "minisite_secondary_color") {
      this.themeForm.value.minisite_secondary_color = color;
    }


    // else if (inputfieldData == 'dsaliasname') {
    //   this.themeForm.value.dsaliasname = color;
    // }





  }

  defaultSetting() {
    this.ngxSpinnerService.show();
    this.master.getReskinningByUserType(this.companyID,'master').subscribe(res => {
      this.ngxSpinnerService.hide();
      let themeData=res.data.rows;
      this.bodybackgroundcolor = themeData.body_background_color;
      this.headerbackgroundcolor = themeData.header_background_color;
      this.companyLogo = themeData.logo;
      this.modelLogo = themeData.model_logo;
      this.bodyBackgroundImage = themeData.background_image;
      this.iconcolor = themeData.icon_color;
      this.picture_outline_color = themeData.picture_outline_color;
      this.scroll_color = themeData.scroll_color;
      this.dashboardtext = themeData.dashboard_text;
      this.bodytextcolor = themeData.body_text_color;
      this.contact_heading_text_color = themeData.contact_heading_text_color;
      this.team_heading_text_color = themeData.team_heading_text_color;
      this.page_content_font_color = themeData.page_content_font_color;
      this.tab_color = themeData.tab_color;
      this.tab_text_color = themeData.tab_text_color;
      this.button_color = themeData.button_color;
      this.button_text_color = themeData.button_text_color;
      this.minisite_header_bg_color = themeData.minisite_header_bg_color ? themeData.minisite_header_bg_color : '#fff';
      this.minisite_header_text_color = themeData.minisite_header_text_color ? themeData.minisite_header_text_color : '#000';
      this.minisite_footer_bg_color = themeData.minisite_footer_bg_color ? themeData.minisite_footer_bg_color : '#f4f4f4';
      this.minisite_footer_text_color = themeData.minisite_footer_text_color ? themeData.minisite_footer_text_color : '#000';
      this.minisite_body_bg_color = themeData.minisite_body_bg_color ? themeData.minisite_body_bg_color : '#f4f4f4';
      this.minisite_body_text_color = themeData.minisite_body_text_color ? themeData.minisite_body_text_color : '#000';
      this.minisite_primary_color = themeData.minisite_primary_color ? themeData.minisite_primary_color : '#009FAE';
      this.minisite_secondary_color = themeData.minisite_secondary_color ? themeData.minisite_secondary_color : '#009FAE';
      this.minisite_button_text_color = themeData.minisite_button_text_color ? themeData.minisite_button_text_color : '#fff';
      this.emailTemplatelogo =  themeData.email_template_logo ? themeData.email_template_logo :'';
      this.dsSenderNumber = this.themObj?.ds_sender_number ? this.themObj?.ds_sender_number  : '';


      this.themeForm = this.formBuilder.group({
        company: [this.companyID, Validators.required],
        business_domain_id:['',Validators.required],
        dsid: [this.dsId],
        logo: [],
        model_logo: [],
        url: [''],
        backgroundImage: [''],
        bodybackgroundcolor: [this.bodybackgroundcolor],
        headerbackgroundcolor: [this.headerbackgroundcolor],
        iconcolor: [this.iconcolor],
        bodytextcolor: [this.button_text_color],
        dashboardtext: [this.dashboardtext],
        dsaliasname: ['How can marGo help today ?'],
        teamMemberLabel: [''],
        picture_outline_color: [this.picture_outline_color],
        scroll_color: [this.scroll_color],
        contact_heading_text: ['Contact'],
        contact_heading_text_color: [this.contact_heading_text_color],
        team_heading_text: ['Team Member'],
        team_heading_text_color: [this.team_heading_text_color],
        page_content_font_color: [this.page_content_font_color],
        tab_color: [this.tab_color],
        tab_text_color: [this.tab_text_color],
        button_color: [this.button_color],
        button_text_color: [this.button_text_color],
        isactive: true,
        logostatus: true,
        eventFlag: true,
        profileFlag: true,
        modelLogoFlag: true,
        default: false,

        minisite_header_bg_color: [this.minisite_header_bg_color],
        minisite_header_text_color: [this.minisite_header_text_color],
        minisite_footer_bg_color: [this.minisite_footer_bg_color],
        minisite_footer_text_color: [this.minisite_footer_text_color],
        minisite_body_bg_color : [this.minisite_body_bg_color],
        minisite_body_text_color: [this.minisite_body_text_color],
        minisite_button_text_color: [this.minisite_button_text_color],
        minisite_primary_color: [this.minisite_primary_color],
        minisite_secondary_color: [this.minisite_secondary_color],

        currency:['$'],
        mini_site_product_list:true,
        mini_site_add_to_cart:true,
        mini_site_display_price:true,
        logo_height:[37],
        ds_sender_number:[this.dsSenderNumber]

      });
    }, error => {
      this.ngxSpinnerService.hide();
    });

  }

  clearAllSetting() {
    this.themeForm.reset();
    this.bodybackgroundcolor = '';
    this.companyLogo = '';
    this.modelLogo = '';
    this.headerbackgroundcolor = '';
    this.iconcolor = '';
    this.bodytextcolor = '';
    this.dashboardtext = '';
    this.picture_outline_color = '';
    this.scroll_color = '';
    this.bodyBackgroundImage = '';
    this.dsaliasname = '';
    this.contact_heading_text_color = '';
    this.team_heading_text_color = '';
    this.page_content_font_color = '';
    this.tab_color = '';
    this.tab_text_color = '';
    this.button_color = '';
    this.button_text_color = '';
    this.modelLogo = '';

    this.minisite_header_bg_color= '';
    this.minisite_header_text_color= '';
    this.minisite_footer_bg_color= '';
    this.minisite_footer_text_color= '';
    this.minisite_body_bg_color = '';
    this.minisite_body_text_color= '';
    this.minisite_button_text_color= '';
    this.minisite_primary_color= '';
    this.minisite_secondary_color= '';
    this.emailTemplatelogo = '';
    this.dsSenderNumber = '';
}

  addNewReskinning() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      console.log("invalid form", this.themeForm);
      return;
    }

    let logo;
    let reskiningLogo = localStorage.getItem('reskiningLogo');
    if (reskiningLogo) {
      logo = reskiningLogo;
    } else {
      logo = this.companyLogo;
    }
    console.log("--logo--", logo);


    let ModelLogo;
    let reskiningModelLogo = localStorage.getItem('reskiningModelLogo');
    if (reskiningModelLogo) {
      ModelLogo = reskiningModelLogo;
    } else {
      ModelLogo = this.modelLogo;
    }
    console.log("--ModelLogo--", ModelLogo);





    let backgroundImage;
    let reskiningBackgroundImage = localStorage.getItem('reskiningBackgroundImage');
    if (reskiningBackgroundImage) {
      backgroundImage = reskiningBackgroundImage;
    } else {
      backgroundImage = this.bodyBackgroundImage;
    }




    let { company,dsid,business_domain_id, bodybackgroundcolor, headerbackgroundcolor, iconcolor, bodytextcolor, dashboardtext, dsaliasname, picture_outline_color, scroll_color, contact_heading_text, contact_heading_text_color, team_heading_text, team_heading_text_color, page_content_font_color, tab_color, tab_text_color, button_color, button_text_color, url, smsService,
      minisite_header_bg_color,
      minisite_header_text_color,
      minisite_footer_bg_color,
      minisite_footer_text_color,
      minisite_body_bg_color,
      minisite_body_text_color,
      minisite_button_text_color,
      minisite_primary_color,
      minisite_secondary_color,
      ds_id,
      currency,
      mini_site_product_list,
      mini_site_add_to_cart,
      mini_site_display_price,
      logo_height,
      ds_sender_number
     } = this.themeForm.value;


    let reskinningObj = {
      company: company,
      domain_id: business_domain_id,
      dsid: dsid,
      logo: logo,
      url: url,
      backgroundImage: backgroundImage,
      bodybackgroundcolor: bodybackgroundcolor || this.bodybackgroundcolor,
      headerbackgroundcolor: headerbackgroundcolor || this.headerbackgroundcolor,
      iconcolor: iconcolor || this.iconcolor,
      bodytextcolor: bodytextcolor || this.bodytextcolor,
      dashboardtext: dashboardtext || this.dashboardtext,
      dsaliasname: dsaliasname || '',

      picture_outline_color: picture_outline_color || this.picture_outline_color,
      scroll_color: scroll_color || this.scroll_color,
      contact_heading_text: contact_heading_text || this.contact_heading_text,

      contact_heading_text_color: contact_heading_text_color || this.contact_heading_text_color,
      team_heading_text: team_heading_text || this.team_heading_text,
      team_heading_text_color: team_heading_text_color || this.team_heading_text_color,
      page_content_font_color: page_content_font_color || this.page_content_font_color,
      tab_color: tab_color || this.tab_color,
      tab_text_color: tab_text_color || this.tab_text_color,
      button_color: button_color || this.button_color,
      button_text_color: button_text_color || this.button_text_color,
      isactive: true,
      logostatus: true,
      eventFlag: true,
      profileFlag: true,
      modelLogoFlag: true,
      modelLogo: ModelLogo,
      sms_service: smsService,
      minisite_header_bg_color: minisite_header_bg_color || this.minisite_header_bg_color,
      minisite_header_text_color: minisite_header_text_color || this.minisite_header_text_color,
      minisite_footer_bg_color: minisite_footer_bg_color || this.minisite_footer_bg_color,
      minisite_footer_text_color: minisite_footer_text_color || this.minisite_footer_text_color,
      minisite_body_bg_color: minisite_body_bg_color || this.minisite_body_bg_color,
      minisite_body_text_color: minisite_body_text_color || this.minisite_body_text_color,
      minisite_button_text_color: minisite_button_text_color || this.minisite_button_text_color,
      minisite_primary_color: minisite_primary_color || this.minisite_primary_color,
      minisite_secondary_color: minisite_secondary_color || this.minisite_secondary_color,
      ds_id: ds_id,
      currency: currency,
      mini_site_product_list: mini_site_product_list,
      mini_site_add_to_cart: mini_site_add_to_cart,
      mini_site_display_price: mini_site_display_price,
      logo_height: logo_height,
      ds_sender_number: ds_sender_number

    }

    // console.log("reskinningObj", reskinningObj);
    // return false;

    this.ngxSpinnerService.show();
    this.master.addingReskinning(reskinningObj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/reskinning`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });

  }

  addThemeLogData() {
    let themeLogs = {
      log_type: "Adding New Reskinning",
      log_name: "Adding New Reskinning",
      log_description: "Adding New Reskinning",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    }, error => {
       console.log(error);
    });
  }

  UpdateThemeLogData() {
    let themeLogs = {
      log_type: "Updating Reskinning Data",
      log_name: "Updating Reskinning Data",
      log_description: "Updating Reskinning Data",
      moduleid: 1,
      moduleitemid: 7,

      per_id: 1

    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //console.log(data);
    }, error => {
      // console.log(error);
    });
  }


  dataBinding() {


    this.companyLogo = this.themObj.logo;
    this.modelLogo = this.themObj.model_logo;
    this.url = this.themObj.url;
    this.bodyBackgroundImage = this.themObj.background_image;
    this.bodybackgroundcolor = this.themObj.body_background_color;
    this.headerbackgroundcolor = this.themObj.header_background_color;
    this.iconcolor = this.themObj.icon_color;
    this.bodytextcolor = this.themObj.body_text_color;
    this.dashboardtext = this.themObj.dashboard_text;
    this.dsaliasname = this.themObj.ds_alias_name;
    this.picture_outline_color = this.themObj.picture_outline_color;
    this.scroll_color = this.themObj.scroll_color;
    this.contact_heading_text_color = this.themObj.contact_heading_text_color;
    this.team_heading_text_color = this.themObj.team_heading_text_color;
    this.page_content_font_color = this.themObj.page_content_font_color;
    this.tab_color = this.themObj.tab_color;
    this.tab_text_color = this.themObj.tab_text_color;
    this.button_color = this.themObj.button_color;
    this.button_text_color = this.themObj.button_text_color;
    this.smsService = this.themObj.sms_service;
    this.minisite_header_bg_color = this.themObj.minisite_header_bg_color ? this.themObj.minisite_header_bg_color : '#fff';
    this.minisite_header_text_color = this.themObj.minisite_header_text_color ? this.themObj.minisite_header_text_color : '#000';
    this.minisite_footer_bg_color = this.themObj.minisite_footer_bg_color ? this.themObj.minisite_footer_bg_color : '#f4f4f4';
    this.minisite_footer_text_color = this.themObj.minisite_footer_text_color ? this.themObj.minisite_footer_text_color : '#000';
    this.minisite_body_bg_color = this.themObj.minisite_body_bg_color ? this.themObj.minisite_body_bg_color : '#f4f4f4';
    this.minisite_body_text_color = this.themObj.minisite_body_text_color ? this.themObj.minisite_body_text_color : '#000';
    this.minisite_primary_color = this.themObj.minisite_primary_color ? this.themObj.minisite_primary_color : '#009FAE';
    this.minisite_secondary_color = this.themObj.minisite_secondary_color ? this.themObj.minisite_secondary_color : '#009FAE';
    this.minisite_button_text_color = this.themObj.minisite_button_text_color ? this.themObj.minisite_button_text_color : '#fff';
    this.emailTemplatelogo = this.themObj.email_template_logo ? this.themObj.email_template_logo : '';
    this.dsSenderNumber = this.themObj?.ds_sender_number ? this.themObj?.ds_sender_number : '';

    // console.log("smsService", this.themObj.sms_service);

    this.themeForm = this.formBuilder.group({
      company: [this.themObj.client_id, Validators.required],
      business_domain_id: [this.themObj.domain_id, Validators.required],
      dsid: [this.themObj.ds_id],
      logo: [''],

      url: [this.themObj.url],
      backgroundImage: [''],

      bodybackgroundcolor: [''],
      headerbackgroundcolor: [''],
      iconcolor: [''],
      bodytextcolor: [''],
      dashboardtext: [''],
      dsaliasname: [this.themObj.ds_alias_name],

      picture_outline_color: [],
      scroll_color: [],
      contact_heading_text: [this.themObj.contact_heading_text],
      team_heading_text: [this.themObj.team_heading_text],

      contact_heading_text_color: [''],
      team_heading_text_color: [''],
      page_content_font_color: [''],
      tab_color: [''],
      tab_text_color: [''],
      button_color: [''],
      button_text_color: [''],

      isactive: true,
      logostatus: this.themObj.logo_status,
      eventFlag: this.themObj.event_flag,

      profileFlag: this.themObj.profile_obj,

      modelLogoFlag: this.themObj.model_logo_flag,
      default: false,
      smsService: [this.smsService],
      minisite_header_bg_color: [this.minisite_header_bg_color],
      minisite_header_text_color: [this.minisite_header_text_color],
      minisite_footer_bg_color: [this.minisite_footer_bg_color],
      minisite_footer_text_color: [this.minisite_footer_text_color],
      minisite_body_bg_color: [this.minisite_body_bg_color],
      minisite_body_text_color: [this.minisite_body_text_color],
      minisite_button_text_color: [this.minisite_button_text_color],
      minisite_primary_color: [this.minisite_primary_color],
      minisite_secondary_color: [this.minisite_secondary_color],

      currency: ['$'],
      mini_site_product_list: true,
      mini_site_add_to_cart: true,
      mini_site_display_price: true,
      logo_height: [37],
      ds_sender_number: [this.dsSenderNumber]
    });

  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
  updateReskinning() {
    this.submitted = true;
    let logo;
    let modellogo;
    let reskiningLogo = localStorage.getItem('reskiningLogo');
    if (reskiningLogo) {
      logo = reskiningLogo;
    } else {
      logo = this.companyLogo;
    }

    let reskiningModelLogo = localStorage.getItem('reskiningModelLogo');
    if (reskiningModelLogo) {
      modellogo = reskiningModelLogo;
    } else {
      modellogo = this.modelLogo;
    }

    let backgroundImage;
    let reskiningBackgroundImage = localStorage.getItem('reskiningBackgroundImage');
    if (reskiningBackgroundImage) {
      backgroundImage = reskiningBackgroundImage;
    } else {
      backgroundImage = this.bodyBackgroundImage;
    }


    // if (this.themeForm.invalid || !this.correctImage) {
    //   return;
    // }


    let { company, business_domain_id, bodybackgroundcolor, headerbackgroundcolor, iconcolor, bodytextcolor, dashboardtext, dsaliasname, picture_outline_color, scroll_color, contact_heading_text, contact_heading_text_color, team_heading_text, team_heading_text_color, page_content_font_color, tab_color, tab_text_color, button_color, button_text_color, logostatus, eventFlag, modelLogoFlag, profileFlag, url, smsService,
      minisite_header_bg_color,
      minisite_header_text_color,
      minisite_footer_bg_color,
      minisite_footer_text_color,
      minisite_body_bg_color,
      minisite_body_text_color,
      minisite_button_text_color,
      minisite_primary_color,
      minisite_secondary_color,
      ds_id,
      currency,
      mini_site_product_list,
      mini_site_add_to_cart,
      mini_site_display_price,
      logo_height,
      ds_sender_number
    } = this.themeForm.value;




    let reskinningObj = {
      id: this.them_id,
      company: company,
      domain_id: business_domain_id,
      logo: logo,
      modellogo: modellogo,

      url: url,
      backgroundImage: backgroundImage,

      bodybackgroundcolor: bodybackgroundcolor || this.bodybackgroundcolor,
      headerbackgroundcolor: headerbackgroundcolor || this.headerbackgroundcolor,
      iconcolor: iconcolor || this.iconcolor,
      bodytextcolor: bodytextcolor || this.bodytextcolor,
      dashboardtext: dashboardtext || this.dashboardtext,
      dsaliasname: dsaliasname || this.dsaliasname,

      picture_outline_color: picture_outline_color || this.picture_outline_color,
      scroll_color: scroll_color || this.scroll_color,
      contact_heading_text: contact_heading_text || this.contact_heading_text,

      contact_heading_text_color: contact_heading_text_color || this.contact_heading_text_color,
      team_heading_text: team_heading_text || this.team_heading_text,
      team_heading_text_color: team_heading_text_color || this.team_heading_text_color,
      page_content_font_color: page_content_font_color || this.page_content_font_color,
      tab_color: tab_color || this.tab_color,
      tab_text_color: tab_text_color || this.tab_text_color,
      button_color: button_color || this.button_color,
      button_text_color: button_text_color || this.button_text_color,
      logostatus: logostatus,
      eventFlag: eventFlag,
      profileFlag: profileFlag,
      modelLogoFlag: modelLogoFlag,
      isactive: true,
      sms_service: smsService,

      minisite_header_bg_color: minisite_header_bg_color || this.minisite_header_bg_color,
      minisite_header_text_color: minisite_header_text_color || this.minisite_header_text_color,
      minisite_footer_bg_color: minisite_footer_bg_color || this.minisite_footer_bg_color,
      minisite_footer_text_color: minisite_footer_text_color || this.minisite_footer_text_color,
      minisite_body_bg_color: minisite_body_bg_color || this.minisite_body_bg_color,
      minisite_body_text_color: minisite_body_text_color || this.minisite_body_text_color,
      minisite_button_text_color: minisite_button_text_color || this.minisite_button_text_color,
      minisite_primary_color: minisite_primary_color || this.minisite_primary_color,
      minisite_secondary_color: minisite_secondary_color || this.minisite_secondary_color,








      ds_id: ds_id,
      currency: currency,
      mini_site_product_list: mini_site_product_list,
      mini_site_add_to_cart: mini_site_add_to_cart,
      mini_site_display_price: mini_site_display_price,
      logo_height: logo_height,
      ds_sender_number: ds_sender_number
    }

    // return false;
    //  console.log("--reskinningObj--", reskinningObj);
    // return false;
    this.ngxSpinnerService.show();
    this.master.UpdateReskinning(reskinningObj).subscribe(data => {
      if (data.status) {
        localStorage.removeItem('reskiningLogo');
        localStorage.removeItem('reskiningBackgroundImage');
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/reskinning`);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }




  processFile(event: any, imageInput: any, fileType: any) {

    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      this.correctImage = false;
      return false;
    } else {
      const reader = new FileReader();
      this.correctImage = true;
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result,fileType);
      }
      reader.readAsDataURL(file);
    }
  }


  toUploadFiles(name,file ,fileType) {
    let self = this;
    this.ngxSpinnerService.show()
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            if (fileType == 'logo') {
              localStorage.setItem('reskiningLogo', params.Location);
              self.companyLogo = params.Location;
            }
            else if (fileType == 'model_logo') {
              localStorage.setItem('reskiningModelLogo', params.Location);
              self.modelLogo = params.Location;
            }
            else {
              localStorage.setItem('reskiningBackgroundImage', params.Location);
              self.bodyBackgroundImage = params.Location;
            }
          }
        }
        else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }


  cancelBtb() {

    this.router.navigateByUrl(`/corporate/reskinning`);
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  getDSLIST(id) {
    this.companyID = id;
    if (this.page == 'direct-seller') {
      if (id != 0) {
        this.getDirectSeller();
      }
    }
  }

  getDirectSeller() {
    let self = this;
    this.ngxSpinnerService.show();
    let limit = 100;
    let skip = 0;
    this.clientService.getBUDSList(this.companyID, limit, skip).subscribe(data => {
      if (data.status) {
        self.getDSobj = data.data.rows;
      } else {
        self.getDSobj = [];
      }
      this.ngxSpinnerService.hide();
      //console.log("self.getDSobj", self.getDSobj);
    }, error => {
      //  console.log(error);
    });
  }
  onDisableDisplayPrice(){
    this.f.mini_site_add_to_cart.setValue(false);
  }
  getBusinessDomainList() {
    this.master.getBusinessDomain(500, 0, this.companyID).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.domainDropdown = res.data.rows;
        }
      }
      ,
      error: (error: any) => {
        this.domainDropdown = [];
        console.log(error.message);

      }
    })
  }
  getSenderNumber() {
    this.master.getReskinningSenderNumber(this.companyID).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.dsAvailableSenderNumbers = res.data;
        }
      }
      ,
      error: (error: any) => {
        this.dsAvailableSenderNumbers = [];
        console.log(error.message);
      }
    })
  }
  ngOnDestroy(){
    localStorage.removeItem('rskAction');
  }
}
