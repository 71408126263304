<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Reports</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon pb-3" *ngIf="marketReportShow">
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">{{projectName}} Direct Seller Reports</h5>
  </legend>
  <fieldset class="scheduler-border">
    <div class="row">
      <div class="col-xl-4 col-md-6" *ngIf="customerID==0">
        <button class="cbtn mt-1" routerLink="/corporate/report-csr">
          <i class="fa fa-users orange-dr-icon" aria-hidden="true"></i>Customer
          Subscription Report
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/report-ds">
          <i class="bi bi-currency-dollar red-dr-icon" aria-hidden="true"></i>Direct
          Seller Subscription
        </button>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon pb-3">
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">Contacts Reports</h5>
  </legend>
  <fieldset class="scheduler-border">

    <div class="row">
      <div class="col-xl-4 col-md-6" *ngIf="marketReportShow">
        <button
          class="cbtn mt-1"
          routerLink="/corporate/report-contact-inventory"
        >
          <i class="fa fa-address-book green-lt-icon" aria-hidden="true"></i
          >Contacts inventory
        </button>
      </div>
<!-- 
      <div class="col-xl-4 col-md-6" *ngIf="marketReportShow">
        <button class="cbtn mt-1" routerLink="/corporate/report-contact-usage">
          <i class="bi bi-person-lines-fill blue-lt-icon"></i>Contacts
          Usage
        </button>
      </div> -->

      <div class="col-xl-4 col-md-6">
        <button
          class="cbtn mt-1"
          routerLink="/corporate/report-contact-market-activity"
        >
          <i class="fa fa-address-card-o orange-dr-icon" aria-hidden="true"></i
          >Contacts marketing activity
        </button>
      </div>

      <!-- <div class="col-xl-4 col-md-6" *ngIf="marketReportShow">
        <button class="cbtn mt-1" routerLink="/corporate/report-msg-utilized">
          <i class="fa fa-envelope-o purple-dr-icon" aria-hidden="true"></i>Message
          Usage
        </button>
      </div> -->
    </div>
  </fieldset>
</div>

<div class="container mt-1 theme-fa-icon pb-3" >
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">E-commerce</h5>
  </legend>
  <fieldset class="scheduler-border">

    <div class="row">
      <div class="col-xl-4 col-md-6" *ngIf="marketReportShow">
        <button class="cbtn mt-1" routerLink="/corporate/report-ecom-summary">
          <i class="bi bi-cart red-dr-icon" aria-hidden="true"></i
          >E-commerce Summary Report
        </button>
      </div>

      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/report-ecom-detail">
          <i class="bi bi-journal-text orange-dr-icon" aria-hidden="true"></i
          >E-commerce Detail Report
        </button>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon pb-3" *ngIf="marketReportShow">
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">Campaign Reports</h5>
  </legend>
  <fieldset class="scheduler-border">

    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button
          class="cbtn mt-1"
          routerLink="/corporate/report-basic-services-summary"
        >
          <i class="bi bi-file-earmark-text green-lt-icon" aria-hidden="true"></i>Basic
          Services Summary Report
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button
          class="cbtn mt-1"
          routerLink="/corporate/report-premium-services-summary"
        >
          <i class="bi bi-wallet  purple-dr-icon" aria-hidden="true"></i>Premium
          Services Summary Report
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button
          class="cbtn mt-1"
          routerLink="/corporate/report-customer-invoice"
        >
          <i class="bi bi-receipt-cutoff blue-lt-icon" aria-hidden="true"></i
          >Customer Invoice Report
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/report-opt-in-opt-out">
          <i class="bi bi-arrow-left-right red-dr-icon" aria-hidden="true"></i
          >Opt-in Opt-Out report
        </button>
      </div>
     <!-- <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/report-csvDownload">

          <i class="bi bi-file-arrow-down red-dr-icon" aria-hidden="true"></i>Download DS Login CSV

        </button>
      </div>-->
    </div>
  </fieldset>
</div>

<!--<div class="container mt-1 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border"><h5 class="font-weight-bold">Facebook </h5></legend>
    
    <div class="row">

    
    
    <div class="col-xl-4  col-md-6">

      <button class="cbtn mt-1 " routerLink="/corporate/reports">
        <i class="fa fa-th-list blue-sky-icon" aria-hidden="true"></i>Posts
      </button>
    </div>
   
    
    <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/messagelib">
          <i class="fa fa-envelope-o red-dr-icon" aria-hidden="true"></i>Series
        </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/messagelib">
        <i class="fa fa-envelope-o red-dr-icon" aria-hidden="true"></i>Post Libraries
      </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/messagelib">
        <i class="fa fa-envelope-o red-dr-icon" aria-hidden="true"></i>Categories
      </button>
    </div>
    <div class="col-xl-4  col-md-6">
      <button class="cbtn mt-1" routerLink="/corporate/messagelib">
        <i class="fa fa-envelope-o red-dr-icon" aria-hidden="true"></i>Snippet
      </button>
    </div>
    
    

  </div>
</fieldset>
</div>

-->

<!-- <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    
      <tr>
        <th>Report Name <i class=" fa fa-sort" aria-hidden="true"></i></th>
        <th>Total Count</th>
          <th>Active<i class=" fa fa-sort" aria-hidden="true"></i> </th>
          <th>In Active</th>
        </tr>
        <tr>
          <td>DS Subscriptions</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Prospect</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Customer</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Team Member</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Hosts</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Guest</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Campaign</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Events</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Hosts</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Facebook Post</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Facebook Series</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>SMS OPT-IN / OPT-OUT</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Email OPT-IN/OPT-OUT</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

      

  </table>
 -->
