<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/journey-span">Journey Span</a></li>
            <li class="breadcrumb-item active">{{header}} Journey Span</li>
        </ol>
    </nav>
</div>
<div class="container mt-2">
    <form class="form-horizontal mt-1" [formGroup]="journeySpanForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">

                <div class="card">
                    <div class="card-body">
                        <div>
                            <label for="exampleForm2">Company Name<span style="color:red">*</span></label>
                            <select formControlName="companyId" *ngIf="header == 'Add'" class="form-control formstyle"
                              [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }"
                                (change)="onSelectCompany($event.target.value)">
                                <option value=""selected disabled>Select Company</option>
                                <ng-container *ngFor="let userObj of getCompanyobj">
                                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                        {{userObj.customer_company_name}}
                                    </option>
                                </ng-container>
                            </select>
                            <select formControlName="companyId" *ngIf="header == 'Edit'" 
                            [(ngModel)]="companyId" class="form-control formstyle" >
                                <option value="">Select Company</option>
                                <ng-container *ngFor="let userObj of getCompanyobj">
                                    <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                        {{userObj.customer_company_name}}
                                    </option>
                                </ng-container>
                            </select>
                            <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                                <div *ngIf="f.companyId.errors.required">Company Name is required</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Journey<span style="color:red">*</span></label>
                            <select formControlName="journeyId" [(ngModel)]="journeyId" class="form-control formstyle">
                                <option value="">Select journey</option>
                                <ng-container *ngFor="let journey of journeyObj">
                                    <option value={{journey?.journey_id}}>
                                        {{journey?.journey_name}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>

                        <div class="mt-1">
                            <label for="exampleForm2">Directseller<span style="color:red">*</span></label>
                            <select formControlName="dsId" [(ngModel)]="dsId" class="form-control formstyle">
                                <option value="">Select directseller</option>
                                <ng-container *ngFor="let data of dsUserObj">
                                    <option value={{data.userid}}>
                                        {{(data.contactname).indexOf(' ') >0 ? (data.contactname).substring(0, (data.contactname).indexOf(' ')): (data.contactname)}} - {{data.username}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>


                        <div class="mt-1">
                            <label for="exampleForm2">Journey Span (In Days)<span style="color:red">*</span></label>
                            <input type="number" id="exampleForm2" formControlName="spanTime" [(ngModel)]="spanTime"
                                class="form-control form-control-md" required
                                [ngClass]="{ 'is-invalid': submitted && f.spanTime.errors }" maxlength="30" appSpace>
                            <div *ngIf="submitted && f.spanTime.errors" class="invalid-feedback">
                                <div *ngIf="f.spanTime.errors.required || f.spanTime.hasError('whitespace')">Journey
                                    span is required</div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label for="screenshotimage">Journey Screenshot</label>
                            <div *ngIf="screenShot">
                                <img src="{{screenShot}}" alt="margo" style="
            width: 200px" (error)="setValue($event)">
                            </div>
                            <input #imageInput id="screenshotimage" type="file" accept=".png, .jpg, .jpeg, .gif"
                                (change)="processFile($event,imageInput)" class="form-control">
                            <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg, .gif)</div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="text-center my_class " *ngIf="header=='Add'"
                            [ngClass]="status ? 'displayblock' : 'displaynone'">
                            <button type="button" class="custbtn" (click)="addJourneySpan()">Submit</button>
                        </div>
                        <div class="text-center my_class " *ngIf="header=='Edit'"
                            [ngClass]="status ? 'displaynone' :'displayblock'  ">
                            <button type="button" class="custbtn" (click)="updateJourneySpan()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>