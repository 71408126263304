<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/productcatalog">Product Catalog</a></li>
      <li class="breadcrumb-item active" aria-current="page">WooCommerce</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-7 text-center">
      <div class="buttonToggle d-flex mt-0">
        <input type="button" value="Package Credential" class="custbtn ml-0 mt-md-1" (click)="getPackageCredential()"
          [disabled]="isPackageCredential" [ngClass]="isPackageCredential?'selectedBtn':''">
        <input type="button" value="Aggregate Mapping" class="custbtn ml-0 mt-md-1"
          [ngClass]="isAgreegateMaping?'selectedBtn':''" (click)="getObjectListing(); getAgreegateMaping()"
          [disabled]="isAgreegateMaping">
        <input type="button" value="Cron-Schedular" class="custbtn ml-0 mt-md-1" (click)="getCronSchedular()"
          [ngClass]="isCronSchedular?'selectedBtn':''" [disabled]="isCronSchedular">
      </div>
    </div>
    <div class="col-md-12 pt-2">
      <div *ngIf="isPackageCredential">

        <div class="row align-items-center">
          <div class="col-sm-6 pt-sm-2 mb-2 mb-sm-0">

            <a>
              <input type="button" class="btn btn-margo-action btn-round mt-1 ml-0" (click)="addCredential('Add')"
                value="+ Add">
            </a>
          </div>

          <div class="col-sm-6 mt-1 mt-sm-0">

            <div class="d-flex align-items-center w-100 justify-content-end">
            </div>
          </div>

          <div class="col-12 pt-1">
            <div class="tblPrt">
              <table class="mt-1 bdrStCustom mn-wit-1160" width="100%" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Package Name</th>
                    <th>Package Env</th>
                    <th>Connection Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let credentialObj of packageCreadential">
                    <td>{{credentialObj.connector_name}}</td>
                    <td>{{credentialObj.connector_env}}</td>
                    <td>
                      <span class="badge badge-success" style='cursor:pointer'
                        [ngClass]="{'badge-danger':credentialObj.connection_status==false}">
                        {{credentialObj.connection_status==true?'Active':'Inactive'}}
                      </span>
                    </td>
                    <td>
                      <i (click)="addCredential('Update',credentialObj.credential_id)"
                        class="fa fa-edit mr-2 button"></i>
                      <i class="fa fa-trash button" (click)="deleteCredential(credentialObj.credential_id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="totalCountPS == 0" style="text-align: center;">
              No result available
            </div>
            <div class="row">
              <div class="col-sm-12 text-center difPagination" *ngIf="totalCountPS > 10">
                <ngb-pagination [collectionSize]="totalCountPS" [(page)]="pagePS" [pageSize]="limitCron" [maxSize]="50"
                  [rotate]="true" (pageChange)="getPackageCredential(pagePS=$event)" aria-label="Custom pagination">

                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isAgreegateMaping" class="container">
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class=" searchDropNw tablCl flex-fill px-1 col-md-4">
          <select name="scheduletype" class="custom-select border-trns" [(ngModel)]='selectedObject'
            (change)="getAgreegateMaping()">
            <option value="" disabled selected hidden>Select Object</option>
            <option value='products'>Products</option>
            <!-- <option *ngFor="let object of objectList" [value]='object.object_name'>{{object.object_name}}</option> -->
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-1">
          <div class="tblPrt">
            <table class="mt-1 bdrStCustom mn-wit-1160" width="100%" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th class='text-center bg-color'>WooCommerce</th>
                  <th class='text-center bg-color'>Mapping</th>
                  <th class='text-center bg-color'>Margo</th>
                </tr>
                <tr>
                  <th class='text-center'>Object Field Name</th>
                  <th></th>
                  <th class='text-center'>Table Field Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let obj of agregateMaping">
                  <td class='text-center'> {{obj.object_field_name}}</td>
                  <td class='text-center'>{{'--------------------------- >'}}</td>
                  <td class='text-center'> {{obj.table_field_name}} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="agregateMaping.length == 0" style="text-align: center;">
            No result available
          </div>
          <!-- <div class="row">
            <div class="col-sm-12 text-center difPagination" *ngIf="agregateMaping.length > 0">

              <ngb-pagination [collectionSize]="agregateMaping.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="50" [rotate]="true" (pageChange)="getObjectField(page=$event)"
                aria-label="Custom pagination">

                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
              </ngb-pagination>
            </div>
          </div> -->

        </div>
      </div>
    </div>
    <ng-container *ngIf="isCronSchedular">
      <div class="container">
        <div class="row mt-4">
          <div class="col-12 pt-1">
            <div class="tblPrt">
              <table class="mt-1 bdrStCustom mn-wit-1160" width="100%" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Query Field</th>
                    <th>Cron Time</th>
                    <th>Schedule Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let cron of schemaLists">
                    <td>{{cron.name}}</td>
                    <td>{{cron.query_field}} </td>
                    <td>{{cron.crontime}} </td>
                    <td>{{cron.scheduletype}} </td>
                    <td>
                      <span class="badge badge-success" style='cursor:pointer' (click)="updateCroneStatus(cron.id,cron.is_active)"
                        [ngClass]="{'badge-danger':cron.is_active==false}">
                        {{cron.is_active==true?'Active':'Inactive'}}
                      </span>
                    </td>
                    <td>
                      <i (click)="navigateUpdateCronPage(cron.id)" title="Update" class="fa fa-edit mr-1 button"></i>
                      <i (click)="getProductFromWoocommerce()" title="Get product from Woocommerce"
                        class="fa fa-cloud-download mr-1 button"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="schemaLists?.length == 0" style="text-align: center;">
              No result available
            </div>
            <div class="row">
              <div class="col-sm-12 text-center difPagination" *ngIf="totalCron > 10">

                <ngb-pagination [collectionSize]="totalCron" [(page)]="pageCron" [pageSize]="pageSize" [maxSize]="50"
                  [rotate]="true" (pageChange)="getCroneList(page=$event)" aria-label="Custom pagination">

                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mt-4">
          <div class="col-12 pt-1">
            <div class="tblPrt">
              <h3>Cron-Schedular Logs</h3>
              <table class="mt-1 bdrStCustom mn-wit-1160" width="100%" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Object Name</th>
                    <th>Name</th>
                    <th>Method</th>
                    <th>Sync Status</th>
                    <th>Created On</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let cron of cronLogsList">
                    <td>{{cron.object_name}}</td>
                    <td>{{cron.data?.name}}</td>
                    <td>{{cron.methodtype}}</td>
                    <td>{{cron.sync_status}}</td>
                    <td>{{cron.created_dt | date:'MMM d, y, h:mm a'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="cronLogsList.length == 0" style="text-align: center;">
              No result available
            </div>
            <!-- <div class="row">
              <div class="col-sm-12 text-center difPagination" *ngIf="totalCount > 0">

                <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [pageSize]="pageSize" [maxSize]="50"
                  [rotate]="true" (pageChange)="getObjectField(page=$event)" aria-label="Custom pagination">

                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
