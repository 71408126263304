<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Premium Campaign</li>
    </ol>
  </nav>
</div>


<div class="container mt-2 theme-fa-icon" >
  <fieldset class="scheduler-border">
<!--    <legend class="scheduler-border"><h5 class="font-weight-bold">Marketing Cloud Master</h5></legend>-->

    <div class="row">

     
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" (click)="goForAudience()">
          <i class="fa fa-users red-dr-icon" aria-hidden="true"></i>Audience
        </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" (click)="goForCrossSell()">
          <i class="fa fa-random green-dr-icon" aria-hidden="true"></i>Cross-sell
        </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" (click)="goForReplenishment()">
          <i class="fa fa-expand orange-dr-icon" aria-hidden="true"></i>Replenishment
        </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" (click)="goForSetting()">
          <i class="fa fa-wrench blue-sky-icon" aria-hidden="true"></i>Setting
        </button>
      </div>

    </div>

  </fieldset>
</div>
