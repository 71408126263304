import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';



@Component({
  selector: 'app-content-builder-journey',
  templateUrl: './content-builder-journey.component.html',
  styleUrls: ['./content-builder-journey.component.css']
})
export class ContentBuilderJourneyComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  public themeObj: any = [];
  public jorneyType: any = [];
  totalCount: number = 0;
  ord = 'asc';
  colname = "journey_name";
  limit = 10;
  offset = 0;
  page = 1;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  journeyTypeId: any = "";
  text;

  today: number = Date.now();
  constructor(
    private clientService: ClientService,
    private cb: CbService) { super(); }

  ngOnInit() {
    this.getCompany();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.cb.getCompanyJourney(this.companyID, this.limit, this.offset, this.colname, this.ord, this.searchText.nativeElement.value, this.journeyTypeId))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.themeObj = [] }
          else { this.themeObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.themeObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  onCompanyChange(company) {
    this.page = 1;
    this.companyID = company;
    if(this.companyID == 0){this.themeObj = [];this.totalCount = 0;this.page = 1;return;}
    this.getCompanyJourney();
    this.getCompanyJourneyType();
  }

  changeJourney() {
    this.page = 1;
    this.getCompanyJourney()
  }

  setOrder(value: string) {
    this.colname = value;
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else { this.ord = 'asc' }
    this.getCompanyJourney();
  }

  templateLogData() {
    let themeLogs = {
      log_type: "Template Content Builder Master View",
      log_name: "Template Content Builder Master View",
      log_description: "Template Content Builder Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });
  }

  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = this.themeObj.find(element => {
      if (element.journeyid === id) {
        return true;
      }
    });
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;
          this.cb.updateJourneyStatus(obj).subscribe(data => {
            // this.getTheme();
            this.getCompanyJourney()
            this.toastrService.success("Journey Status Changed Successfully")
            this.ngxSpinnerService.hide();
          });
        } else {
        }
      }).catch((err) => {
        // console.log(err)
        // event.source.checked = !event.source.checked;
        // this.checked = obj.isactive;
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  getCompanyJourney() {
    let searchTerm = this.searchText.nativeElement.value;
    this.offset = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.cb.getCompanyJourney(this.companyID, this.limit, this.offset, this.colname, this.ord, searchTerm, this.journeyTypeId).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.themeObj = [] }
        else { this.themeObj = data.data.rows }
        this.totalCount = data.data.total;
        this.ngxSpinnerService.hide();
      } else {
        this.themeObj = [];
        this.totalCount = 0;
        this.jorneyType = [];
        this.ngxSpinnerService.hide();
      }
    }, error => {
      //  console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getCompanyJourneyType() {
    this.page = 1;
    //this.ngxSpinnerService.show();
    this.cb.getCBJourneyType(this.companyID).subscribe(data => {
      if (data.data) {
        this.jorneyType = data.data;
        this.ngxSpinnerService.hide();
      }
      else {
        this.jorneyType = [];//this.ngxSpinnerService.hide();
      }
    }, error => {
      //console.log(error);
      //this.ngxSpinnerService.hide();
    });
  }

  // getJourneyByType(id, name = null) {
  //   //event.preventDefault();
  //   //this.companyID =id;
  //   // console.log("company themes:"+ id);
  //   let self = this;
  //   this.ngxSpinnerService.show();

  //   if (id == "all") {
  //     this.getCompanyJourney();
  //   } else {
  //     this.cb.getJourneyByType(this.companyID, id, this.text).subscribe(data => {
  //       if (data.data) {
  //         self.themeObj = data.data;
  //         this.ngxSpinnerService.hide();
  //       }
  //       else this.ngxSpinnerService.hide();
  //     }, error => {
  //       //  console.log(error);
  //       this.ngxSpinnerService.hide();
  //     });
  //   }
  // }

  editJourney(id) {
    this.router.navigate(['/corporate/add-cb-journey'], { queryParams: { companyID: this.companyID, id: id } });
  }

  deleteJourney(id) {
    let obj = {
      id: id,
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.cb.deleteJourney(this.companyID, id).subscribe(data => {
            if (data.status) {
              this.getCompanyJourney()
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }




  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      // console.log(self.getCompanyobj);
    }, error => {
      // console.log(error);
    });
  }
}