import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { CommonMessages, ClientMessages } from 'src/app/shared/messages/messages.enum';

@Component({
  selector: 'app-clientdetails-card',
  templateUrl: './clientdetails-card.component.html',
  styleUrls: ['./clientdetails-card.component.css']
})
export class ClientdetailsCardComponent implements OnInit {

  companyObj;
  client_id;
  clientDataSingle_g;
  constructor(
    private clientsService: ClientService,
    private router: Router,
    private toastrService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit() {
    let self = this;
    self.getCompanyData()
    if(localStorage.getItem("updatedClientId")) this.getClientById(localStorage.getItem("updatedClientId"));
   
  }
  getClientById(client_id_local) {
    this.clientsService.getClientById(client_id_local).subscribe(clientDataSingle => {
     // console.log(clientDataSingle.status);
      if(clientDataSingle.status){
        this.clientDataSingle_g = clientDataSingle.data;
        // console.log(this.clientDataSingle_g);
      }
    }, err => {
      console.error(err);
    })
  }
  getCompanyData() {
    let self = this;
    this.clientsService.getClient().subscribe(data => {
      self.companyObj = data;
      // console.log(self.companyObj);

    }, error => {
     // console.log(error);
    });
  }
}
