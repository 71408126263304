<ngx-spinner bdOpacity=0.9 bdColor="#333" size="large" color="#fff" type="ball-clip-rotate">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/menumaster">Menu Master</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{header}} Menu</li>
      </ol>
    </nav>
  </div>

<div class="container mt-2">
    <div class="row">
        <div class="col-sm-10 col-md-7">
            <div class="card">
                <div class="card-body">

                
            <div>
                <label for="exampleForm2">Parent Id</label>
                <select [(ngModel)]="parentid" class="form-control formstyle">
                    <option value="0">Root </option>
                    <option *ngFor="let obj of getMeunObj" value={{obj.menuid}}>
                        {{obj.menu_name}}
                    </option>
                </select>
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Name</label>
                <input type="text" id="exampleForm2" [(ngModel)]="name" class="form-control form-control-md">
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Type</label>
                <select [(ngModel)]="type" class="form-control formstyle">
                    <option value="undefined" selected > --select Type-- </option>
                    <option value="margo">Margo</option>
                    <option value="bu">BU</option>
                    <option value="ds">Direct Seller</option>
                    <option value="hmmargo">HM Margo</option>
                    <option value="hmbu">HM BU</option>
                    <option value="hmds">HM Direct Seller</option>
                </select>

            </div>
            <div class="mt-1">
                <label for="exampleForm2">Description</label>
                <input type="text" id="exampleForm2" [(ngModel)]="description" class="form-control form-control-md">
            </div>

            <div class="mt-1">
                <label for="exampleForm2">Icon</label>
                <input type="text" id="exampleForm2" [(ngModel)]="icon" class="form-control form-control-md">
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Link Type</label>
                <select [(ngModel)]="linktype" class="form-control formstyle" (change)="fetchModuleItems()">
                    <option value="undefined" selected >--Select Link Type--</option>
                    <option value="internal">Internal</option>
                    <option value="external">External</option>
                </select>
            </div>
            <div *ngIf="linktype=='external'" class="mt-1">
                <label for="exampleForm2">Link</label>
                <input type="text" id="exampleForm2" [(ngModel)]="link" class="form-control form-control-md">
            </div>
            <div *ngIf="linktype=='internal'">
                <label for="exampleForm2">Module Item</label>
                <select [(ngModel)]="moduleitemid" class="form-control formstyle">
                    <option value="0">--Select--</option>
                    <option *ngFor="let obj of getModuleItemObj" value={{obj.moduleitemid}}>
                        {{obj.module_item_name}}
                    </option>
                </select>
            </div>
            <div class="mt-1">
                <label for="exampleForm2">Menu Icon</label>
                <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                    <img src="{{icon}}" alt="Menu Icon" style="height:150px;" (error)="setValue($event)">
                </div>
                <div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" [(ngModel)]="screenshot_type"
                            name="active" [value]="true">
                        <label class="custom-control-label" for="customRadio1">Url</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" name="active"
                            [(ngModel)]="screenshot_type" [value]="false">
                        <label class="custom-control-label" for="customRadio2">Upload a file</label>
                    </div>
                </div>
                <div *ngIf="screenshot_type" class="mt-1">
                    <input type="text" id="exampleForm1" [(ngModel)]="icon" class="form-control form-control-md">
                </div>
                <div *ngIf="!screenshot_type" class="mt-1">
                    <input #imageInput type="file" accept=".png, .jpg, .jpeg" (change)="processFile($event,imageInput)">
                    <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                </div>
            </div>
            
        </div>
        <div class="card-footer mt-1">
            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                <button type="button" class="custbtn" *ngIf="isWrite" (click)="addNewMenu()">Submit</button>
            </div>
            <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateMenu()">Update</button>
            </div>
        </div>
    </div>
        </div>
    </div>