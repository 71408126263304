import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../../services/clients/client.service';
import { BaseComponent } from '../../../base.component';
import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';

@Component({
  selector: 'app-new-media',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.css']
})
export class NewCategoryComponent extends BaseComponent implements OnInit {
  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  screenshot;
  category_id;
  categoryObj;
  screenshot_type;
  customer_id;
  selectedCompany;

  constructor(
    private shopLiveService: ShopLiveService,
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
  ) { super(); }

  ngOnInit() {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', Validators.maxLength(100)],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }

  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  get f() { return this.addCategoryForm.controls; }

  addNewCategoryData() {
    this.submitted = true;
    if (this.addCategoryForm.invalid) {
      return;
    }
    let { name, description, selectedCompany } = this.addCategoryForm.value;
    let obj = {
      cat_name: name,
      cat_description: description,
      status: true,
      company: selectedCompany
    }
    this.shopLiveService.addNewCategory(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Category added successfully");
        this.router.navigateByUrl(`/corporate/shoplive-category`);
      } else {
        this.toastrService.error(data.message);
      }
    }, error => {
      this.toastrService.error(error.message);
    });
  }

  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
