import {Component, OnInit} from '@angular/core';
import {MasterService} from '../../../../services/master/master.service';
import {ClientService} from '../../../../services/clients/client.service';
import {AlertService} from '../../../../_services/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../../base.component';

@Component({
  selector: 'app-addattribute',
  templateUrl: './addattribute.component.html',
  styleUrls: ['./addattribute.component.css']
})
export class AddattributeComponent extends BaseComponent implements OnInit {

  attrForm: FormGroup;
  companyID;
  submitted: boolean;
  currentUser;
  getCompanyobj;
  status: boolean = true;

  constructor(private master: MasterService, private clientService: ClientService,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {
    super();
  }
  ngOnInit() {

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyID = this.currentUser.admin_customer_id;

    this.attrForm = this.formBuilder.group({
      company: ['', Validators.required],
      attrname: ['', Validators.required],
      attrdesc: ['']
    });



    this.submitted = false;

    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
     // console.log(error);
    });
  }
  get f() { return this.attrForm.controls; }

  //  ################# New Attribute Addition Method ###################  //
  addNewAttr() {
    this.submitted = true;
    if (this.companyID !== 0) {
      let comp = this.getCompanyobj.filter(x => x.customer_id === this.companyID);
      this.attrForm.get('company').patchValue(comp);
    }
    if (this.attrForm.invalid) {
      return;
    }
    var attributeName = this.attrForm.value.attrname.trim();
    if ((attributeName === undefined || attributeName === null ||attributeName === '') ) {
        this.toastrService.error('Attribute name should be Valid');
      return;
    }
    var attributeDesc = this.attrForm.value.attrdesc.trim();
    if ((attributeDesc === undefined || attributeDesc === null || attributeDesc === '')) {
      this.attrForm.get('attrdesc').patchValue('');
    }

    if (this.companyID !== 0) {
      let cid = this.companyID;
      var obj = {
        name: attributeName,
        description: this.attrForm.value.attrdesc,
        company: cid,
        customerid: cid
      };
    }

    if (this.companyID === 0) {
      let cid = this.companyID;
      var obj = {
        name: attributeName,
        description: this.attrForm.value.attrdesc,
        company: this.attrForm.value.company,
        customerid: cid
      };
    }


    let body = JSON.stringify(obj);
    this.ngxSpinnerService.show();
    this.submitted = false;
    this.master.addAttribute(body).subscribe(data => {

      if (data.status) {

        this.toastrService.success('Attribute Added Successfully');
        this.router.navigateByUrl('/corporate/attributes');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      //this.attrForm.reset();
      this.ngxSpinnerService.hide();
    });
  }

}
