<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/attribute-type">Attribute Type List</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ header }} Attribute Type
      </li>
    </ol>
  </nav>
</div>


<div class="container" >
  <form class="form-horizontal mt-1" [formGroup]="attrTypeForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">

            <div>
              <label for="attrTypeForm">Attribute Type Name <span style="color:red">*</span></label>
              <input type="text" appSpace maxlength="80" formControlName="attrname" class="form-control form-control-md"
                     placeholder="Provide Attribute Type Name" [ngClass]="{ 'is-invalid': submitted && f.attrname.errors }" required>
              <div *ngIf="submitted && f.attrname.errors" class="invalid-feedback">
                <div *ngIf="f.attrname.errors.required">Attribute Type Name is required</div>
              </div>
            </div>

            <div>
              <label for="attrTypeForm">Attribute Type Description </label>
              <textarea appSpace formControlName="attrdesc" maxlength="255" class="form-control form-control-md"></textarea>
            </div>

          </div>

          <div class="card-footer text-center mt-1" *ngIf="status">
            <div class="my_class text-center" *ngIf="isWrite" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn"  (click)="addNewAttrType()" >Submit</button>
            </div>
          </div>
          <div class="card-footer text-center mt-1" *ngIf="!status">
            <div class="my_class text-center" *ngIf="isEdit" [ngClass]="status ? 'displaynone' : 'displayblock'">
              <button type="submit" class="custbtn"  (click)="updateAttributeType()" >Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
