import { Component, OnInit,Renderer2, ViewChild, ElementRef} from '@angular/core';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';


import { OrderPipe } from 'ngx-order-pipe';
import { ClientService } from 'src/app/services/clients/client.service';
import { FormBuilder,FormControl,FormGroup, Validators,NgForm } from '@angular/forms';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cont-build-assoiciation',
  templateUrl: './cont-build-assoiciation.component.html',
  styleUrls: ['./cont-build-assoiciation.component.css']
})
export class ContBuildAssoiciationComponent extends BaseComponent implements OnInit {
  selectedAll: any;
  allTableFieldsobj;
  checkedAll=false;
  marked = false;
  theCheckbox = false;
  show: boolean= false;
  fdstatus:boolean=false;
  checkedTable:any=[];
  checkedTheme:any=[];
  changeCheckFileSize= {};
   checkedDatatype = {};
  sensitveTablesobj:any=[];
  nonSensitiveTables:any=[];
  input_data:any=[];
  
//************** Company & Theme*********************/
public themeObj: any = [];
  cp = 1;
  ord = true;
  colname: "Theme Name";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID ;
  fields:any=[];
  field_size:any=[];
  allTablesobj;
  type_text:any;
  size_value:any;

//***********************************/
  constructor( 
    private formBuilder: FormBuilder,
    
    private orderPipe: OrderPipe,
    private cb: CbService,
   
    private clientService:ClientService,
    private renderer: Renderer2
  ){  super();
   
      this.getCompany();
     this.gettingContentBuilderFields();
  }

  ngOnInit() {
   // this.gettingContentBuilderFields(); 
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
   // alert(this.currentUser+""+sessionStorage.getItem('userData'));
  this.companyID = this.currentUser.admin_customer_id; 
  //  this.addCBThemeField();
        
   
  }
/************************New***************************/
changeCheckTheme(d) {  
 
  this.gettingContentBuilderFields();
  this.show =this.show;
  
  let abc = d;
  var index = this.checkedTheme.indexOf(abc); 
  if (index > -1) { 
    this.checkedTheme.splice(index, 1);
  //  console.log(this.checkedTheme);
   }
   else{
   // this.show = !this.show;
     this.checkedTheme.push(abc)
    // console.log(this.checkedTheme);
   }     
}

  changeCheckTables(a) {    
    this.fdstatus=!this.fdstatus;
    let abc = a;
    var index = this.checkedTable.indexOf(abc); 
    if (index > -1) { 
      this.checkedTable.splice(index, 1);
    //  console.log(this.checkedTable);
     }
     else{       
       this.checkedTable.push(abc); 
           
     //  console.log(JSON.stringify(this.checkedTable));
     }     
  }

  changeCheckField(k1,v1) {    
    let key = k1;
    let value=v1;
    this.changeCheckFileSize[key] = value;
    this.input_data[key]='text';
  }
 
  changeCheckDataType(key2,value2) {    
    this.ngxSpinnerService.show();  
    let key = key2;
    let value=value2;
  //  alert(value);
    this.checkedDatatype[key] = value;   
    if(value=='file' || value==='integer'){
      this.type_text='hidden';
      this.size_value='10000';
     this.input_data[key]='hidden';
     this.ngxSpinnerService.hide();
    }else{
      this.input_data[key]='text';
      this.ngxSpinnerService.hide();
    }
  
  }

  addCBThemeField(){
     if (this.checkedTable.length>0 && this.checkedTheme.length>0) {
     
      var obj ={
        selectedFields:this.checkedTable,
        selectedTheme:this.checkedTheme,
        selectedDatatype:this.checkedDatatype,
        selectedFileSize:this.changeCheckFileSize,      
        dbType: environment.awsKey,
        customerId:this.companyID
            }
       
       this.cb.addNewThemeData(obj).subscribe(data => {
              if (data.status) {
                this.toastrService.success("Record Inserted successfully");
                this.router.navigateByUrl(`/corporate/contentbuilder-view`);
                
              } else {
                this.toastrService.error(data.message)
              }
            }, error => {
              this.toastrService.error(error.message)
            });
          } else {
           // this.toastrService.error("Please select at least one theme.")
          }
    }
  
  /************************New***************************/
  gettingContentBuilderFields() {   
     let self = this;
      this.cb.gettingContentBuilderFields().subscribe(data => {
        self.allTablesobj = data.data;
       // console.log(data.data); 
        }, error => {
      //  console.log(error);
      });
    }
   
    themeLogData() {
      let themeLogs = {
        log_type: "Theme Master View",
        log_name: "Theme Master View",
        log_description: "Theme Master View",
        moduleid: 1,
        moduleitemid: 7,
        per_id: 14
      }
      this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);
  
      }, error => {
      //  console.log(error);
      });
  
    }
    
  
    getCompanyTheme(id){
      //event.preventDefault();
      this.companyID =id;
     // console.log("company themes:"+ id);
      let self = this;
    //  alert(id);
      this.ngxSpinnerService.show();
      this.cb.getCompanyThemes(id).subscribe(data => {
        self.themeObj = data.data;
      //  alert(data);
        this.ngxSpinnerService.hide();
      }, error => {
       // console.log(error);
        this.ngxSpinnerService.hide();
      });
  
    }
    editTheme(id) {  
      this.router.navigate(['/corporate/newtheme'], { queryParams: { companyID: this.companyID,them_id: id } });
    }
   
    getCompany() {     
      let self = this;
      this.clientService.getBUCompanies().subscribe(data => {
        self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
      }, error => {
        //console.log(error);
      });
    }

}
