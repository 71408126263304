<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">License</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 theme-fa-icon" >
  <legend class="scheduler-border"><h5 class="font-weight-bold">License</h5></legend>
  <fieldset class="scheduler-border">
    <div class="row">
      <div class="col-xl-4  col-md-6">

        <button class="cbtn mt-1" routerLink="/corporate/license-bundle-listing">
          <i class="fa fa-book blue-lt-icon" aria-hidden="true"></i>Bundle Management

        </button>
      </div>

     

    </div>
  </fieldset>
</div>
