<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/fb">Facebook</a></li>
      <li class="breadcrumb-item active" aria-current="page">FB Reports</li>
    </ol>
  </nav>
</div>



<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Post Library</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{dsPostObjCount}}</b> Post</p>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">BU Post Library</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{buPostObjCount}}</b> BU Post</p>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">HQ Post Library</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{buHqPostObjCount}}</b> HQ Post</p>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Post Report</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{totalePostObjCount}}</b> Post</p>
      </div>
    </div>
  </fieldset>
</div>

<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Compaign Report</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{postCampaignObjCount}}</b> Compaign</p>
      </div>
    </div>
  </fieldset>
</div>
<div class="container mt-2 theme-fa-icon">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Scheduler Report</h5>
    </legend>
    <div class="row">
      <div class="col-md-12">
        <p>&nbsp;</p>
        <p><b>{{schedulerPostObjCount}}</b> Scheduler</p>
      </div>
    </div>
  </fieldset>
</div>