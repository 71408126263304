import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-add-bu-table',
  templateUrl: './add-bu-table.component.html',
  styleUrls: ['./add-bu-table.component.css']
})


export class AddBuTableComponent extends BaseComponent implements OnInit {


  table_id; 
  tableObj;  
  tableForm: FormGroup;
  submitted = false;
  status;
  actionStatus: boolean = true;
  getCompanyobj = [];

  constructor(
  
    private formBuilder: FormBuilder,  
    public toaster: ToastrService,
    private segmentService: SegmentationService,private clientService: ClientService,

  ) {
    super();
  }

  ngOnInit() {


    //this.ngxSpinnerService.show();
   
    let self = this;

    if(localStorage.getItem("deleteTableId")) this.table_id = localStorage.getItem("deleteTableId")
    if (this.table_id) {
      this.ngxSpinnerService.show();
      this.UpdateLogData()
     
     
      this.segmentService.getTableId(this.table_id).subscribe(response => {
        
        if (response.status) {   
          this.actionStatus = false;      
          self.tableObj = response.data[0];
          this.status =   self.tableObj.isactive    
          self.dataBinding();

        } 
        this.ngxSpinnerService.hide();
      });
    } else {
      this.addLogData()
    }
    this.tableForm = this.formBuilder.group({
      company:     [0, [Validators.required,Validators.min(1)]],
      table_name: ['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      table_alias:['', [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
      section_id: [1],
      table_desc: [''],
      table_type: [0]
     
    });
    this.getCompany();
  }
  get f() { return this.tableForm.controls; }

 

  addLogData() {

    let Logs = {
      log_type: "Adding New Table",
      log_name: "Adding New Table",
      log_description: "Adding New Table",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(Logs).subscribe(data => {
     // console.log(data);
    }, error => {
     // console.log(error);
    });

  }
  
  UpdateLogData() {

    let logs = {
      log_type: "Updating Table",
      log_name: "Updating Table",
      log_description: "Updating Table",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(logs).subscribe(data => {
    //  console.log(data);

    }, error => {
    //  console.log(error);
    });

  }
  addTable() {
    this.submitted = true;
   
    if (this.tableForm.invalid) {
      return;
    }

    let { company,table_name, table_desc,table_alias,table_type,section_id} = this.tableForm.value;


    this.ngxSpinnerService.show();
    
    let body = {
      company : company,
      table_name: table_name,
      table_desc: table_desc,
      table_alias: table_alias,
      table_type: table_type,
      section_id: section_id,
      status:true

    }

    this.segmentService.addingTable(body).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/segmentation-table`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
 
  dataBinding() {
    
      this.tableForm = this.formBuilder.group({
        company : [this.tableObj.company, [Validators.required,Validators.min(1)]],
        table_name: [this.tableObj.table_name,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        table_alias: [this.tableObj.table_alias,  [Validators.required,this.noWhitespaceValidator,Validators.maxLength(80)]],
        table_desc: [this.tableObj.table_desc],  
        section_id: [this.tableObj.section_id],     
        table_type: [(this.tableObj.table_type)?1:0],          
       
       
      });
     
  }
  UpdateTable() {
  
    this.submitted = true;
    if (this.tableForm.invalid) {
      return;
    }
    let { company,table_name, table_desc,table_alias,table_type,section_id} = this.tableForm.value;

    let body = {
      company : company,
      table_name: table_name,
      table_desc: table_desc,
      table_alias: table_alias,
      table_type: table_type,
      section_id: section_id,
      status:this.status,
      id:this.table_id
    
    }

    this.ngxSpinnerService.show();
    this.segmentService.updateTable(body).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/segmentation-table`);
        
      }
      else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });

  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

getCompany() {
  this.ngxSpinnerService.show();
  this.clientService.getBUCompanies().subscribe(data => {
    this.getCompanyobj = data.data;
    this.ngxSpinnerService.hide();
  }, error => {
     console.log(error);
     this.ngxSpinnerService.hide();
  });
}
}

