import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-update-faq-category',
  templateUrl: './update-faq-category.component.html',
  styleUrls: ['./update-faq-category.component.css']
})
export class UpdateFaqCategoryComponent extends BaseComponent implements OnInit {

  updateCategoryForm: FormGroup;
  controlEnabled: boolean = true;
  status: boolean = true;
  submitted = false;
  getCompanyobj: any = [];
  screenshot;
  category_id;
  categoryObj;
  screenshot_type;
  customer_id;
  selectedCompany;

  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) { super(); }

  ngOnInit() {
    this.controlEnabled = true;
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    let self = this;

    //get item details using id
    if (localStorage.getItem("edit_category_id")) {
      this.category_id = localStorage.getItem("edit_category_id");
      this.selectedCompany = localStorage.getItem("category_edit_company_id");

      this.masterService.getFaqCategoryById(this.category_id, this.selectedCompany).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.categoryObj = response.data;
          self.dataBinding();
        }
      });
    }

    this.updateCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', Validators.maxLength(100)],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }

  get f() { return this.updateCategoryForm.controls; }

  dataBinding() {
    this.updateCategoryForm = this.formBuilder.group({
      name: [this.categoryObj.name, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: [this.categoryObj.description, Validators.maxLength(100)],
      selectedCompany: [this.selectedCompany, Validators.required]
    });
  }

  updateCategory() {
    this.submitted = true;
    if (this.updateCategoryForm.invalid) {
      return;
    }
    let { name, description, selectedCompany } = this.updateCategoryForm.value;
    let categoryobj = {
      id: Number.parseInt(this.category_id, 10),
      name: name,
      description: description,
      company: selectedCompany,
      isactive:this.categoryObj.is_active
    }
    this.ngxSpinnerService.show();
    this.masterService.updateFaqCategory(categoryobj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Category Updated successfully");
        this.router.navigateByUrl(`/corporate/faq-category`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
    });
  }

  isUrlValid(userInput) {
    return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
