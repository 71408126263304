<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Support Message Library </li>
    </ol>
  </nav>
</div>

<div class="row3">
  <div class="col-md-6 mt-2">
  
  
    <select name="company" class="form-control formstyle"  (change)="changeMessageLib($event.target.value)">
      <option value="">Select Company Name</option>
      <ng-container *ngFor="let userObj of getCompanyobj" >
        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
        <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
            {{userObj.customer_company_name}}
        </option>
        </ng-container>
    </select>
    


</div>
</div>


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText
        [disabled]="companyID == 0" >
      </div>

    </div>

  
  <div class="col-md-4" style="justify-content: right;">
    <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/newmessagelib" >
   <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
      <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
    </button>
    <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
      <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
    </button>
  </div>
  </div>
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0">

      <tr>
        <!-- <th>Theme Id</th> -->
        <th (click)="sorting('help_type')" class="curPoint" width="10%">Message Type <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        <th (click)="sorting('message_body')" class="curPoint" >Message Body <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        
       
        
        <!--<th width="200">ModifiedBy</th>-->
        <th width="20%">Created Date</th>
        <th style="text-align: left;" width="25%" *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let obj of messagelibObj">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->
        <td>{{obj.help_type==1?"Support text":"Quick text"}}</td>
        <td>{{obj.message_body}}</td>
        
      
        <td>{{obj.createdon | date:'mediumDate' }}</td>
        <!--<td>{{obj.get_master_theme.modifiedname }}</td>
        <td>{{obj.get_master_theme.modifiedon |date}}</td>-->

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
          <i class="curPoint fa fa-edit mr-1" *ngIf="isEdit" (click)="editMessagelib(obj.help_message_id)"></i>
          <span class="curPoint fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteMessagelib(obj.help_message_id)"></span>
          <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
            (change)="updateMessagelibStatus($event,obj.help_message_id)">
          </mat-slide-toggle>
        </div>
        </td>         
        </tr>
        

    </table>
    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>
    
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getMessagelib((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination> 
    </div>
  </div> 
  </div> 
 
    