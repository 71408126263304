<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/reskinning">Reskinning</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{headers=='Add' ? 'Add new Reskinning' : 'Edit Reskinning'}} </li>

        </ol>
    </nav>
</div>



<form [formGroup]="themeForm">
    <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading">Common</h5>
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <div class="col-md-6 col-sm-6">
                        <label for="exampleForm2"> Select Company Name
                            <span style="color:red">*</span>
                        </label>
                        <select formControlName="company" *ngIf="headers == 'Add'"
                            class="form-control formstyle" [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                            (change)="getDSLIST($event.target.value)">
                            <option value="">Select Company</option>
                            <ng-container *ngFor="let userObj of getCompanyobj">
                                <option value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </ng-container>
                        </select>

                        <select disabled="true" formControlName="company" *ngIf="headers == 'Edit'"
                            disabled="controlEnabled" class="form-control formstyle"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                            <option value="">Select Company</option>
                            <ng-container *ngFor="let userObj of getCompanyobj">
                                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                                    {{userObj.customer_company_name}}
                                </option>
                            </ng-container>
                        </select>

                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">Company Name is required</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6">
                        <label for="exampleForm2"> Select Business Domain
                            <span style="color:red">*</span>
                        </label>
                        <select  formControlName="business_domain_id" class="form-control formstyle" *ngIf="headers == 'Add'"> 
                            <option value="">Select domain</option>
                            <ng-container *ngFor="let obj of domainDropdown">
                                <option value={{obj.id}}>
                                    {{obj.domain_name}}
                                </option>
                            </ng-container>
                        </select>
                        <select disabled="true" formControlName="business_domain_id" class="form-control formstyle" *ngIf="headers == 'Edit'"> 
                            <option value="">Select domain</option>
                            <ng-container *ngFor="let obj of domainDropdown">
                                <option value="0">BU</option>
                                <option value={{obj.id}}>
                                    {{obj.domain_name}}
                                </option>
                            </ng-container>
                        </select>

                        <div *ngIf="submitted && f.business_domain_id.errors" class="invalid-feedback">
                            <div *ngIf="f.business_domain_id.errors.required">Business Domain is required</div>
                        </div>
                    </div>

                    <div class="col-md-12 col-sm-12 ">
                        <label for="exampleForm2" *ngIf="page=='direct-seller'"> Select DS
                            <span style="color:red">*</span>
                        </label>
                        <select disabled="true" *ngIf="headers == 'Add' && page=='direct-seller'" formControlName="dsid"
                            class="form-control formstyle">
                            <option value="">Select Direct Seller</option>
                            <ng-container *ngFor="let userObj of getDSobj">
                                <option value={{userObj.user_ds_id}}>
                                    {{userObj.user_fname}}
                                </option>
                            </ng-container>
                        </select>

                        <select disabled="true" *ngIf="headers == 'Edit' && page=='direct-seller'"
                            formControlName="dsid" class="form-control formstyle">
                            <option value="">Select Direct Seller</option>
                            <ng-container *ngFor="let userObj of getDSobj">
                                <option value={{userObj.user_ds_id}}>
                                    {{userObj.user_fname}}
                                </option>
                            </ng-container>
                        </select>


                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">Company Name is required</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Logo <span class="label_tooltrip">(Dimension should be 120 X 50)
                            </span>
                            <span style="color:red">*</span>
                        </label>
                        <input formControlName="logo" #imageInput1 type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event, imageInput1, 'logo')"
                            [ngClass]="{ 'is-invalid': submitted && f.logo.errors }">
                        <div style="color: rgb(216, 114, 114);margin-left:10px ;">Allowed file Types(.png, .jpg,
                            .jpeg)</div>


                        <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">
                                Logo is required
                            </div>
                        </div>

                        <div>
                            <img src="{{companyLogo}}" style="width: 200px;">
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Margo Logo Status
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg1"
                                     formControlName="logostatus" [value]="true">
                                <label class="custom-control-label" for="customRadiolg1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg2"
                                 formControlName="logostatus" [value]="false">
                                <label class="custom-control-label" for="customRadiolg2">In Active</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Model Logo <span class="label_tooltrip">(Dimension should be 190 X
                                165) </span>
                            <span style="color:red">*</span>
                        </label>
                        <input formControlName="model_logo" #imageInput3 type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event, imageInput3, 'model_logo')">
                        <div style="color: rgb(216, 114, 114);margin-left:10px ;">Allowed file Types(.png, .jpg,
                            .jpeg)</div>

                        <div>
                            <img src="{{modelLogo}}" style="width: 100px;">
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Model Logo Status
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolgg3"
                                    formControlName="modelLogoFlag" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="customRadiolgg3">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolgg4"
                                    formControlName="modelLogoFlag" [value]="false">
                                <label class="custom-control-label" for="customRadiolgg4">In Active</label>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Background Image <span class="label_tooltrip">(Dimension should be
                                1920 X 1080) </span></label>
                        <input formControlName="backgroundImage" #imageInput2 type="file" accept=".png, .jpg, .jpeg"
                            (change)="processFile($event, imageInput2, 'backgroundImage')"
                            [ngClass]="{ 'is-invalid': submitted && f.logo.errors }">
                        <div style="color: rgb(216, 114, 114);margin-left:10px ;">Allowed file Types(.png, .jpg,
                            .jpeg)</div>


                        <!-- <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">
                                Logo Name is required
                            </div>
                        </div> -->

                        <div>
                            <img src="{{bodyBackgroundImage}}" style="width: 200px;">
                        </div>
                    </div>



                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2"> Header Background Color

                        </label>
                        <input readonly maxlength="7" formControlName="headerbackgroundcolor"
                            [style.background]="headerbackgroundcolor" [(colorPicker)]="headerbackgroundcolor"
                            class="form-control" (colorPickerChange)="onChangeColor($event,'headerbackgroundcolor')"
                            [value]="headerbackgroundcolor" />
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Header Icon Color

                        </label>
                        <input readonly maxlength="7" formControlName="iconcolor" [style.background]="iconcolor"
                            [(colorPicker)]="iconcolor" class="form-control"
                            (colorPickerChange)="onChangeColor($event,'iconcolor')" [value]="iconcolor" />
                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Profile picture outline color
                        </label>

                        <input readonly maxlength="7" formControlName="picture_outline_color"
                            [style.background]="picture_outline_color" [(colorPicker)]="picture_outline_color"
                            class="form-control" (colorPickerChange)="onChangeColor($event,'picture_outline_color')"
                            [value]="picture_outline_color" />
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Scroll bar color
                        </label>

                        <input readonly maxlength="7" formControlName="scroll_color" [style.background]="scroll_color"
                            [(colorPicker)]="scroll_color" class="form-control"
                            (colorPickerChange)="onChangeColor($event,'scroll_color')" [value]="scroll_color" />
                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Url on Dashboard
                        </label>
                        <input maxlength="100" formControlName="url" class="form-control">
                    </div>



                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Event button on my event page ( Edit / Cancel / Delete )
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg3"
                                    formControlName="eventFlag" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="customRadiolg3">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadiolg4"
                                    formControlName="eventFlag" [value]="false">
                                <label class="custom-control-label" for="customRadiolg4">In Active</label>
                            </div>
                        </div>
                    </div>



                    <!-- <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">Body Text Color
                        </label>

                        <input [style.background]="bodybackgroundcolor" [(colorPicker)]="bodybackgroundcolor"
                            class="form-control" (colorPickerChange)="onChangeColor($event,'bodybackgroundcolor')"
                            [value]="bodybackgroundcolor" />
                    </div> -->

                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading formfield">
            Landing Page
        </h5>

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Landing page text <span class="label_tooltrip">(Maximum Allowed length : 50) </span>
                        </label>
                        <input  maxlength="50" type="text" class="form-control" formControlName="dsaliasname">
                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Landing page text color
                        </label>
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="dashboardtext" [(colorPicker)]="dashboardtext"
                            formControlName="dashboardtext" (colorPickerChange)="onChangeColor($event,'dashboardtext')"
                            [value]="dashboardtext">
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Hide/Show option for Password and change profile picture (Profile Page)

                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="profileRadiol1"
                                    formControlName="profileFlag" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="profileRadiol1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="profileRadiol2"
                                    formControlName="profileFlag" [value]="false">
                                <label class="custom-control-label" for="profileRadiol2">In Active</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Quick Message Service {{this.smsService}}
                        </label>
                        <select class="form-control" formControlName="smsService">
                            <option value="vonage" [selected]="this.smsService==='vonage'">vonage</option>
                            <option value="clicksend" [selected]="this.smsService==='clicksend'">clicksend</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading formfield">
            Market
        </h5>

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Contact heading text <span class="label_tooltrip">(Maximum Allowed length : 20) </span>
                        </label>


                        <input maxlength="20" type="text" class="form-control" formControlName="contact_heading_text">

                    </div>


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Contact heading text color
                        </label>

                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="contact_heading_text_color" [(colorPicker)]="contact_heading_text_color"
                            formControlName="contact_heading_text_color"
                            (colorPickerChange)="onChangeColor($event,'contact_heading_text_color')"
                            [value]="contact_heading_text_color">
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Team member heading text <span class="label_tooltrip">(Maximum Allowed length : 20) </span>
                        </label>
                        <input maxlength="20" type="text" class="form-control" formControlName="team_heading_text">
                    </div>



                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Team member heading text color
                        </label>

                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="team_heading_text_color" [(colorPicker)]="team_heading_text_color"
                            formControlName="team_heading_text_color"
                            (colorPickerChange)="onChangeColor($event,'team_heading_text_color')"
                            [value]="team_heading_text_color">
                    </div>

                    <!-- <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Page content color
                        </label>
                        <input maxlength="7" type="text" class="form-control" [style.background]="page_content_font_color"
                            [(colorPicker)]="page_content_font_color" formControlName="page_content_font_color"
                            (colorPickerChange)="onChangeColor($event,'page_content_font_color')"
                            [value]="page_content_font_color">

                    </div> -->


                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Tab color
                        </label>
                        <input readonly maxlength="7" type="text" class="form-control" [style.background]="tab_color"
                            [(colorPicker)]="tab_color" formControlName="tab_color"
                            (colorPickerChange)="onChangeColor($event,'tab_color')" [value]="tab_color">
                    </div>


                    <!-- <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Tab text color
                        </label>


                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="tab_text_color" [(colorPicker)]="tab_text_color"
                            formControlName="tab_text_color"

                            (colorPickerChange)="onChangeColor($event,'tab_text_color')" [value]="tab_text_color">
                    </div> -->

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Button color
                        </label>
                        <input readonly maxlength="7" type="text" class="form-control" [style.background]="button_color"
                            [(colorPicker)]="button_color" formControlName="button_color"
                            (colorPickerChange)="onChangeColor($event,'button_color')" [value]="button_color">
                    </div>

                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Button text color
                        </label>
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="button_text_color" [(colorPicker)]="button_text_color"
                            formControlName="button_text_color"
                            (colorPickerChange)="onChangeColor($event,'button_text_color')" [value]="button_text_color">
                    </div>

                    <!-- <div class="col-md-10 col-xl-12">
                        <label for="exampleForm2">Is Active
                            <span style="color:red">*</span>
                        </label>

                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio1"
                                    formControlName="isactive" [value]="true" aria-selected="true">
                                <label class="custom-control-label" for="customRadio1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio2"
                                    formControlName="isactive" [value]="false">
                                <label class="custom-control-label" for="customRadio2">In Active</label>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="col-md-10 col-xl-12" [ngClass]="status ? 'displaynone' :'displayblock' "
                        *ngIf="isEdit">
                        <label title="It help to select the default color " for="exampleForm2">Default Color
                        </label>

                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="default" class="custom-control-input"
                                    id="customRadio3" aria-selected="true" (click)="defaultSetting(false)"
                                    [value]="false">
                                <label class="custom-control-label" for="customRadio3">No</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="default" class="custom-control-input"
                                    id="customRadio4" (click)="defaultSetting(true)" [value]="true">
                                <label class="custom-control-label" for="customRadio4">Yes</label>
                            </div>
                        </div>
                    </div> -->



                </div>
            </div>
        </div>

    </div>
    <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading formfield">Mini Site</h5>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Product List
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="productList1"
                                    formControlName="mini_site_product_list" [value]="true" aria-selected="true" />
                                <label class="custom-control-label" for="productList1">Enable</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="productList2"
                                    formControlName="mini_site_product_list" [value]="false" />
                                <label class="custom-control-label" for="productList2">Disable</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Display Price
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="displayPrice1"
                                    formControlName="mini_site_display_price" [value]="true" aria-selected="true" />
                                <label class="custom-control-label" for="displayPrice1">Enable</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
    
                                <input type="radio" class="custom-control-input" id="displayPrice2"
                                    (change)="onDisableDisplayPrice()" formControlName="mini_site_display_price"
                                    [value]="false" />
                                <label class="custom-control-label" for="displayPrice2">Disable</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            Add To Cart
                        </label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="addCart1"
                                    formControlName="mini_site_add_to_cart" [value]="true" aria-selected="true" />
                                <label class="custom-control-label" for="addCart1">Enable</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="addCart2"
                                    formControlName="mini_site_add_to_cart" [value]="false" />
                                <label class="custom-control-label" for="addCart2">Disable</label>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_header_bg_color"> Header BG Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_header_bg_color +'!important'"
                            [(colorPicker)]="minisite_header_bg_color" formControlName="minisite_header_bg_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_header_bg_color')"
                            [value]="minisite_header_bg_color" />
                    </div>
    
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_header_text_color"> Header Text Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_header_text_color +'!important'"
                            [(colorPicker)]="minisite_header_text_color" formControlName="minisite_header_text_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_header_text_color')"
                            [value]="minisite_header_text_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_footer_bg_color"> Footer BG Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_footer_bg_color +'!important'"
                            [(colorPicker)]="minisite_footer_bg_color" formControlName="minisite_footer_bg_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_footer_bg_color')"
                            [value]="minisite_footer_bg_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_footer_text_color"> Footer Text Color </label>
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_footer_text_color +'!important'"
                            [(colorPicker)]="minisite_footer_text_color" formControlName="minisite_footer_text_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_footer_text_color')"
                            [value]="minisite_footer_text_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_body_bg_color "> Body BG Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_body_bg_color  +'!important'"
                            [(colorPicker)]="minisite_body_bg_color" formControlName="minisite_body_bg_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_body_bg_color ')"
                            [value]="minisite_body_bg_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_body_text_color"> Body Text Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_body_text_color +'!important'"
                            [(colorPicker)]="minisite_body_text_color" formControlName="minisite_body_text_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_body_text_color')"
                            [value]="minisite_body_text_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_button_text_color"> Button Text Color </label>
    
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_button_text_color +'!important'"
                            [(colorPicker)]="minisite_button_text_color" formControlName="minisite_button_text_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_button_text_color')"
                            [value]="minisite_button_text_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_primary_color">Pprimary Color </label>
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_primary_color +'!important'"
                            [(colorPicker)]="minisite_primary_color" formControlName="minisite_primary_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_primary_color')"
                            [value]="minisite_primary_color" />
                    </div>
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="minisite_secondary_color">Secondary Color </label>
                        <input readonly maxlength="7" type="text" class="form-control"
                            [style.background]="minisite_secondary_color +'!important'"
                            [(colorPicker)]="minisite_secondary_color" formControlName="minisite_secondary_color"
                            (colorPickerChange)="onChangeColor($event, 'minisite_secondary_color')"
                            [value]="minisite_secondary_color" />
                    </div>
                    <div class="col-md-10 col-xl-12">
                        <div>
                            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                                <button type="submit" class="custbtn" *ngIf="isWrite"
                                    (click)="addNewReskinning()">Submit</button>
                                <button type="button" class="custbtn" (click)="defaultSetting()"> Reset</button>
                                <button type="button" class="custbtn" (click)="clearAllSetting()"> Clear</button>
                                <button type="submit" class="custbtn" *ngIf="isWrite" (click)="cancelBtb()">Cancel</button>
                            </div>
                            <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                                <button type="button" class="custbtn" *ngIf="isEdit"
                                    (click)="updateReskinning()">Update</button>
                                <button type="button" class="custbtn" *ngIf="isEdit" (click)="defaultSetting()">
                                    Reset</button>
                                <button type="submit" class="custbtn" *ngIf="isEdit && userType=='master'"
                                    (click)="cancelBtb()">Cancel</button>
                            </div>
                        </div>
    
                    </div>
    
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <h5 class="mb-1 font-weight-bold form_heading formfield">Quick Message Settings</h5>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-6 formfield">
                        <label for="exampleForm2">
                            SMS / MMS Sender Virtual Number
                        </label>
    
                        <select name="ds_sender_number" id="ds_sender_number" class="form-control"
                            formControlName="ds_sender_number">
                            <option selected value="">Select</option>
                            <option [value]="num?.phone_no" *ngFor="let num of dsAvailableSenderNumbers">+{{num?.phone_no}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ds_sender_number.errors" class="error-text">
                            <div *ngIf="f.ds_sender_number.errors.required">SMS / MMS Sender Virtual Number is required
                            </div>
                        </div>
                    </div>

    
                </div>
            </div>
        </div>
    </div> -->
</form>
