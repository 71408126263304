<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Business Domain</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search"
           #searchText>
      </div>

    </div>


    <div class="col-md-4" style="justify-content: right;">

      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1" routerLink="/corporate/addBusinessDomain">
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2" *ngIf="disCompId === 0">
      <div>

        <select name="company" class="form-control formstyle" (change)="getCompanyId($event.target.value)">
          <option value = 0 >

            Select Company Name

          </option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
            </option>
          </ng-container>
        </select>

      </div>

    </div>
  </div>
</div>
<div class="container">
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <th>Domain Icon</th>
      <th>Domain Name</th>
      <th>Created On </th>
      <th>ModifiedOn</th>
      <th>Is Company</th>
      <th>Is View</th>
      <th> Status </th>


      <th *ngIf="isEdit || isDelete">Actions </th>
    </tr>

    <tr *ngFor="let obj of productObj;index as i">
      <!-- <td>{{ i + 1 + ((skip+1) - 1) * limit }}</td> -->
      <td><img [src]="obj.domain_image" alt="Not Found" (error)="setValue($event)"
        style="height:50px;"></td>

      <td> {{obj.domain_name}} </td>
      <td> {{obj.created_dt | date:'mediumDate'}} </td>
      <td>{{obj.updated_dt | date:'mediumDate' }}</td>
      <td>{{obj.is_company?'Yes':'No'}}</td>
      <td>{{obj?.is_view?'Yes':'No'}}</td>
      <td>
        <span
          class="badge badge-success"
          [ngClass]="{
            'badge-danger': obj.is_active !== true
          }"
        >
          {{
            obj.is_active == true
              ? "Active"
              : "Inactive"
          }}
        </span>
      </td>
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
          <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editDomain(obj.id)" placement="bottom"
            ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteCatalog($event,obj.id)"
            placement="bottom" ngbTooltip="Delete"></span>
          <mat-slide-toggle class="my-slider" [checked]="obj.is_active" *ngIf="isEdit"
            (click)="updateStatus($event,obj.id)">
          </mat-slide-toggle>
        </div>
      </td>
    </tr>

  </table>
  <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
  <div style="text-align: center;" *ngIf="totalCount == 0">
    <p>No Result Available</p>
  </div>

  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
      (pageChange)="getProductCatalogue((cp=$event))" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

  <!-- <pagination-controls *ngIf="(productObj | filter:text).length !== 0 && (productObj | filter:text).length !== 1" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->
</div>
</div>
