<div class="container">

	<nav aria-label="breadcrumb" class="breadCust">
	  <ol class="breadcrumb">
		<li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
		<li class="breadcrumb-item"><a routerLink="/corporate/license">License</a></li>
		<li class="breadcrumb-item"><a routerLink="/corporate/license-bundle-listing">License Bundle</a></li>
		<li class="breadcrumb-item active" aria-current="page">Add License Bundle</li>
	  </ol>
	</nav>
  </div>
  
  <div class="container" *ngIf="bundleCreationPage">
	
  
	  <div class="form-group row">
		  <label for="staticEmail" class="col-sm-2 col-form-label">Company Name</label>
		  <div class="col-sm-10">
		  <select class="form-control formstyle max-w-190"
		   (change)="loadBundleForm($event.target.value)" [(ngModel)]="selectedCompany">
		   <option value="0">Please select company</option>
		   <ng-container *ngFor="let userObj of getCompanyobj" >
			<option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
	  <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}> -->
	  {{userObj.customer_company_name}}
	  </option>
	  </ng-container>
	  </select>
		  </div>
  
		</div>
	  <form *ngIf="showForm" class="form-horizontal mt-1" [formGroup]="bundleForm">

		<div class="card mt-2">
			<div class="card-body">
				<div class="row">
					<div class="col-xl-4 mb-1">
						<label for="staticEmail" class="col-form-label">Bundle Name <span style="color:red">*</span></label>
						<input formControlName="bundleName" type="text" class="form-control max-w-1501 formstyle" id="bundleName"  [ngClass]="{ 'is-invalid': submitted && f.bundleName.errors }" maxlength="50">
						<div *ngIf="submitted && f.bundleName.errors" class="invalid-feedback">
							<div *ngIf="f.bundleName.errors.required">Bundle Name is required</div>
						</div>
					
					</div>
					<div class="col-xl-4 col-md-6 mb-1">
						<label for="staticEmail" class="col-form-label">License Period <span style="color:red">*</span></label>
						<select class="form-control mr-sm-2 max-w-1501 formstyle " id="inlineFormCustomSelect" formControlName="licensePeriod" [ngClass]="{ 'is-invalid': submitted && f.licensePeriod.errors }">
							<option value="">License Period</option>
							<option value="{{item.id}}" *ngFor="let item of newduration">{{item.value}}</option>
					
						  </select>
						  <div *ngIf="submitted && f.licensePeriod.errors" class="invalid-feedback">
							<div *ngIf="f.licensePeriod.errors.required">License Period is required</div>
						</div>
					</div>
					<div class="col-xl-4 col-md-6 mb-1">
						<label for="staticEmail" class="col-form-label">Start Date <span style="color:red">*</span></label>
					
					<!-- [min]="minDate"-->
						<input class="form-control formstyle mb-1 max-w-1501" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   
		  formControlName="start_date"  autocomplete="off"  [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }" >
				
		  
		  <mat-datepicker-toggle  matSuffix [for]="picker1" class="cldrIcon" ></mat-datepicker-toggle>
				<mat-datepicker #picker1   ></mat-datepicker>
				<div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
					<div *ngIf="f.start_date.errors.required">Start Date is required</div>
				</div>
					</div>

					<div class="col-xl-4 col-md-6 mb-1">
						<label for="staticEmail" class="col-form-label">Total Member <span style="color:red">*</span></label>
						<input formControlName="totalMember" type="number" class="form-control formstyle max-w-1501" id="totalMember" aria-describedby="totalMember" min="1" max="5000000"  (keyup)="manualTeamBundlePrice($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.totalMember.errors }">
						<div *ngIf="submitted && f.totalMember.errors" class="invalid-feedback">
							<div *ngIf="f.totalMember.errors.required">Total Member is required</div>
							<div *ngIf="f.totalMember.errors.max">Total Member is greater than 5000000</div>
						</div>
					
					</div>

					<div class="col-xl-4 col-md-6 mb-1">
						<label for="staticEmail" class="col-form-label">Bundle Price <span style="color:red">*</span></label>
						<input formControlName="bundlePrice" type="number" step="any" min="1" max="500000000000000" class="form-control formstyle max-w-1501" id="bundlePrice" aria-describedby="bundlePrice" (keyup)="manualBundlePrice($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.bundlePrice.errors }">
						<span class="sugPrice mt-1">(Suggested price 240.000 / $12 per pers.)</span>
						<div *ngIf="submitted && f.bundlePrice.errors" class="invalid-feedback" style="margin-top: 8px;">
							<div *ngIf="f.bundlePrice.errors.required"><p><br>Bundle Price is required</p></div>
							<div *ngIf="f.bundlePrice.errors.max"><p><br> The price should be less than 500000000000000</p></div>
						</div>
					</div>


					<div class="col-xl-4 col-md-6 mb-1 mt-5" *ngIf="costPerCustomer">
					
						<div class="col-sm-10 pt-2">
							<label for="staticEmail" class="col-form-label">Cost per person:</label>  ${{costPerCustomer}} per person
						 
				
						</div>
					  </div>

				</div>
			</div>
		</div>

	  
  
	  <div class="tblPrt">
  
	  <table cellpadding="0" cellspacing="0" class="bdrStCustom" width="100%" style="min-width: 850px;">
	  <tr>
		  <th width="150">&nbsp;</th>
		  <th valign="top" width="100" title="Suggested cost per Person" style="text-align: center;">Cost/Person</th>
		  <th valign="top" title="Access" style="text-align: center;">Access</th>
		  <th valign="top" title="My profile" style="text-align: center;">Profile</th>
		  <th valign="top" title="My payment" style="text-align: center;">Payment</th>
		  <th valign="top" title="My contacts" style="text-align: center;">Contacts</th>
		  <th valign="top" title="My Events" style="text-align: center;">Events</th>
		  <th valign="top" title="My Team" style="text-align: center;">Team</th>
		  <th valign="top" title="My Purchase" style="text-align: center;">Purchase</th>
		  <th valign="top" title="My Hosts" style="text-align: center;">Hosts</th>
		  <th valign="top" title="My Campaigns" style="text-align: center;">Campaigns</th>
  
	  </tr>
  
	  <tr *ngFor="let item of licenseObj" >
		  <td align="left" style="padding-left: 10px;">{{item.license_name}}</td>
		  <td align="center">{{item.suggest_cost}}
			  <input type="hidden" formControlName="suggestCost" id="suggestCost_{{item.license_id}}" value="{{item.suggest_cost}}">
  
	  </td>
		  <td align="center">
			  <label class="custmCheck">
				  <input type="checkbox" name="group" value="{{item.license_id}}" id="licenseId_{{item.license_id}}" (click)="selectLicense($event.target.value)" [checked]="checked(item.license_id)" >
				  <span class="checkmark"></span>
			  </label>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_profile==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_profile!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_payment==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_payment!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_contacts==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_contacts!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_events==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_events!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_team==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_team!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
			<div *ngIf="item.menu_purchase==1" class="checkmarkRight">&#x2713;</div>
			<div *ngIf="item.menu_purchase!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
			  </td>
		  <td align="center">
		<div *ngIf="item.menu_hosts==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_hosts!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
		  <td align="center">
		<div *ngIf="item.menu_campaigns==1" class="checkmarkRight">&#x2713;</div>
		<div *ngIf="item.menu_campaigns!=1" class="checkmarkRight multiSignRed">&#x2715;</div>
		  </td>
  
		  
	  </tr>
	  
		  
  </table>
  
  
  
  </div>
  <div class="footerBtnLsn">
	  <div class="footBtnInnLsn text-center">
		  <button class="custbtn mb-1 ng-star-inserted" (click)="goCancel()">Cancel</button>
		  <button class="custbtn mb-1 ng-star-inserted" (click)="goSubmit()">Submit</button>
	  </div>
  </div>
	  </form>
  </div>
  <div class="container" *ngIf="invoiceCreationPage">
  
   
		  <div class="row">
			  <div class="col-12 text-center mainHeadLsn">
				  Example Pvt. Ltd.
			  </div>
		  </div>
		  <div class="row">
			  <div class="col-12 lsnEmailDtl pt-2 pb-2">
				  <div class="row">
					  <div class="col-sm-3">
						  <span class="roadDtlInn">25 School Road</span>
						  <span class="roadDtlInn">Evansville, IN 47711</span>
					  </div>
					  <div class="col-sm-3">
						  <span class="roadDtlInn">P: 87668998</span>
						  <span class="roadDtlInn">Evansville, IN 47711</span>
					  </div>
					  <div class="col-sm-3">
						  <span class="roadDtlInn">Email: example@co.com</span>
						  <span class="roadDtlInn">Website: http://www/example.com</span>
					  </div>
				  </div>
			  </div>
		  </div>
  
		  <div class="row">
			  <div class="col-12 lsnAddrslDtl pt-2 pb-2">
				  <div class="row">
					  <div class="col-sm-3">
						  <span class="roadDtlInn">Bill To: {{companyInfo.customer_company_name}}</span>
						  <span class="roadDtlInn"> {{companyInfo.customer_billing_address1}}, {{companyInfo.customer_billing_city}}, {{companyInfo.customer_billing_state}}<br>{{companyInfo.customer_billing_zip}}</span>
					  </div>
					  <div class="col-sm-3">
						  <span class="roadDtlInn" *ngIf="companyInfo.customer_phone">Phone: {{companyInfo.customer_phone}}</span>
						  <span class="roadDtlInn" *ngIf="companyInfo.customer_contact_email">Email:  {{companyInfo.customer_contact_email}}</span>
					  </div>
					  <div class="col-sm-3">
						  <span class="roadDtlInn">Invoice #: 56643334</span>
						  <span class="roadDtlInn">Invoice Date: {{this.todayDate | date:'MMM d, y'}}</span>
					  </div>
				  </div>
			  </div>
		  </div>
  
		  <div class="row itemDescrt mt-2">
			  <div class="col-md-8">
				  <table cellspacing="0" cellpadding="0" border="0" width="100%">
					  <tr>
						  <th>Item #</th>
						  <th>Description</th>
						  <th>Qty</th>
						  <th>Unit Price</th>
					  </tr>
					  <tr>
						  <td align="center">1</td>
						  <td align="center">Bundle: LUX009182</td>
						  <td align="center">1</td>
						  <td align="center">$ 2,40,000,00</td>
					  </tr>
				  </table>
				  <div class="makAllCh">
					  <span class="macHead">Make all cheaque payable to Example Pvt. Ltd.</span>
					  <span class="macDesc">Total due in 22 days. Overdue accounts subject to a service charge of 1.1% per month.</span>
				  </div>
			  </div>
  
			  <div class="col-md-4 totalAmt">
				  <table cellspacing="0" cellpadding="0" border="0" width="100%">
					  <tr>
						  <th>Discount</th>
						  <th>Price</th>
						  
					  </tr>
					  <tr>
						  <td align="right">$ 40,000,00</td>
						  <td align="right">$ 2.00.000,00</td>	
  
					  </tr>
					  <tr>
						  <td align="right">Invoice Subtotal</td>
						  <td align="right">&nbsp;</td>	
											  
					  </tr>
					  <tr>
						  <td align="right">Taxt Rate</td>
						  <td align="right">0.00%</td>	
											  
					  </tr>
					  <tr>
						  <td align="right">Sales Tax</td>
						  <td align="right">&nbsp;</td>	
											  
					  </tr>
					  <tr>
						  <td align="right">Other</td>
						  <td align="right">&nbsp;</td>	
											  
					  </tr>
					  <tr>
						  <td align="right">Deposit Received</td>
						  <td align="right">&nbsp;</td>	
											  
					  </tr>
					  <tr>
						  <td align="right">Total</td>
						  <td align="right">$ 2.00.000,00</td>	
											  
					  </tr>
				  </table>
				  
			  </div>
  
		  </div>
  
	  <div class="footerBtnLsn">
		<div class="footBtnInnLsn text-center">
		  <button class="mainBtnBle mb-1" (click)="showCreationForm()" >Back</button>
		  <button class="mainBtnBle mb-1" (click)="confirmInvoice()">Confirm</button>
		</div>
	  </div>
  </div>
  

