import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.css']
})
export class GeolocationComponent extends BaseComponent implements OnInit {
  csvFileName: string = 'Choose File';
  fileToUpload: File = null;

  constructor( private master: MasterService ) { 
    super();
  }
  
  ngOnInit() {
    
  }

  uploadFile() {
    this.master.uploadGeoCSVFile(this.fileToUpload).subscribe(res => {
     // console.log(res);
    });
    // this.master.getContacttype();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.csvFileName = this.fileToUpload.name;
  }
}

