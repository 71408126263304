<!-- Breadcrumb for application -->
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Sender Profile</li>
      </ol>
    </nav>
  </div>
  <div class="container mt-1 theme-fa-icon" >
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">
        <h5 class="font-weight-bold">Sender Profile</h5>
      </legend>
      <div class="row">
        <div class="col-xl-4 col-md-6">
          <button class="cbtn mt-1" routerLink="/corporate/sender-profile-list">
            <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Sender Profile List
          </button>
        </div>
      </div>
    </fieldset>
  </div>
  