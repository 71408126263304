import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Location } from '@angular/common';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  public userObj: any = [];
  getCompanyobj: any = [];
  colname: string = 'admin_fname';
  ord = 'asc';
  customerID;
  companyId: any = '';
  status = 3;
  totalUsers: any;
  totalCount: number = 0;
  page = 1;
  limit = 10;
  skip = 0;

  constructor(
    public location: Location,
    private clientService: ClientService,
  ) {
    super();
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.customerID = customerInfo.admin_customer_id;

    if (this.customerID != 0) {
      this.companyId = this.customerID;
      this.getData();
    }
    else { this.getCompany(); }

    this.UserLogData()
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(()=> {
          this.skip = 0;
          this.page = 1;
          if(this.companyId == ''){console.log("companyid afterview=>",this.companyId); return};
        }),
        switchMap(data => this.registerService.getUser(this.companyId, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value, (this.status == 1 ? 'true' : this.status == 2 ? 'false' : '')))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.userObj = [] }
          else { this.userObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.userObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  changeStatus() {
    this.page = 1;
    if(this.companyId != ''){this.getData();}
  }
  
  getData() {
    if(this.companyId == ''){       
      this.userObj = [];
      this.totalCount = 0;
      return
      }
    let searchTerm = this.searchText.nativeElement.value;
    let status = this.status == 1 ? 'true' : this.status == 2 ? 'false' : ''
    this.skip = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.registerService.getUser(this.companyId, this.limit, this.skip, this.colname, this.ord, searchTerm, status).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.userObj = [] }
        else { this.userObj = data.data.rows }
        this.totalCount = data.data.total;
        this.ngxSpinnerService.hide();
      } else {
        this.userObj = [];
        this.totalCount = 0;
        this.ngxSpinnerService.hide();
      }
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  sorting(col) {
    this.colname = col
    if(this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getData()
  }

  editUser(id) {
    this.router.navigate(['/corporate/newuser'], { queryParams: { uid: id } });
  }

  deleteUser(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are You Sure You Want To Delete User?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          let userObject = {
            id: Number.parseInt(id, 10),
            isactive: false,
            isdelete: true
          }
          // console.log(userObject)
          this.ngxSpinnerService.show();
          this.registerService.updateUserStatus(userObject).subscribe(data => {
            if (data.status) {
              this.toastrService.success('User Delete Successfully');
              // this.status = 1;
              this.getData();
            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          },
            error => this.ngxSpinnerService.hide());
        }

      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  UserLogData() {
    let UserLogs = {
      log_type: "User View",
      log_name: "User  View",
      log_description: "User  View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(UserLogs).subscribe(data => {
      //  console.log(data);
    }, error => {
      // console.log(error);
    });
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      //  console.log(error);
    });
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.userObj.find(element => {
      if (element.uid === id) {
        return true;
      }
    });
    obj = obj;
    let stat = 'INACTIVE';
    if (!obj.isactive) {
      stat = 'ACTIVE';
    }
     this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          let userObject = {

            id: Number.parseInt(id, 10),
            isactive: !obj.isactive
          }
          this.ngxSpinnerService.show();
          this.registerService.updateUserStatus(userObject).subscribe(data => {
            if (data.status) {
              this.toastrService.success('Status Changed Successfully');
              // this.status = 1;
              this.getData();
            } else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          },
            error => this.ngxSpinnerService.hide());
        }

      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
}