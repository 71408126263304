import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { PiiService } from 'src/app/services/pii/pii.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';


@Component({
  selector: 'app-pii-data-view',
  templateUrl: './pii-data-view.component.html',
  styleUrls: ['./pii-data-view.component.css']
})
export class PiiDataViewComponent extends BaseComponent implements OnInit {

  constructor(   
    private orderPipe: OrderPipe,
    private pii: PiiService,
    private clientService:ClientService

  ) {  super();}
  allTablesobj:any=[];
  companyID;
  getCompanyobj;
  customerID;
  obj;
  getTablesAction:any=[];

  ngOnInit() {  
    //this.gettingSensitiveTables();
    localStorage.setItem('comapny_id',  "");
     var customerInfo =  JSON.parse(sessionStorage.getItem("userData"));
     this.customerID= customerInfo.admin_customer_id; 
     
     if(this.customerID==0)this.getCompany();
     else this.gettingSensitiveTables(this.customerID);
  }

  gettingSensitiveTables(id) {
    let self = this;
    if(id == '' || id == 0){       
       self.allTablesobj = [];       
       self.getTablesAction = []; 
       return}
    this.ngxSpinnerService.show();
    this.companyID = id;
    localStorage.setItem('comapny_id',  this.companyID);

    this.pii.gettingSensitiveTables(id).subscribe(data => {
      if(data.status) { 
        self.allTablesobj = data.data;       
        self.getTablesAction = [];     
        self.allTablesobj.forEach(element => {
          self.getTabelFieldsActionFunction(element.pii_tbl_id, element.table_name,this.companyID);
        });
      }
      else self.allTablesobj = "";
  
     
      this.ngxSpinnerService.hide();
      
      
    }, error => {
    //  console.log(error);
    });

  //  console.log(self.getTablesAction);
   }

   
   getTabelFieldsActionFunction(pid, name,cid) {
  
    let self = this;    
    this.pii.gettingSenstiveTableFields(pid,cid).subscribe(data => {
  
      let tempObj = {
        title: name,
        data: data.data
      }
    self.getTablesAction.push(tempObj);  
   // console.log(self.getTablesAction);
     
    }, err => {
     // console.log(err);
      this.ngxSpinnerService.hide();
    });
  }



   deleteTable(id) {
    let obj = {
      id: id,
      company: this.companyID
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
         
          this.pii.delTable(id,this.companyID).subscribe(data => {
            if (data.status) {
              this.gettingSensitiveTables(this.companyID);
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
                
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
          }, error => {
           // console.log(error);
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  
getCompany() {
   
  let self = this;
  this.clientService.getBUCompanies().subscribe(data => {
    self.getCompanyobj = data.data;
    this.ngxSpinnerService.hide();
   // console.log(self.getCompanyobj);
  }, error => {
   // console.log(error);
  });
}

showFields() {
// console.log("sssss");

}

}
