import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-clientheadquaterdetails-list',
  templateUrl: './clientheadquaterdetails-list.component.html',
  styleUrls: ['./clientheadquaterdetails-list.component.css']
})
export class ClientheadquaterdetailsListComponent implements OnInit {
  client_id;
  clientDataSingle_g;
  contactObj = [];
  contactUserObj;
  contactBUObj;
  subscriptionUserObj;
  subsLimit = 10;
  subsSkip = 0;
  subsPage = 1;
  subsTotal: number = 0;

  userLimit = 10;
  userSkip = 0;
  userPage = 1;
  userTotal: number = 0;

  contactLimit = 10;
  contactSkip = 0;
  contactPage = 1;
  contactTotal: number = 0;

  buLimit = 10;
  buSkip = 0;
  buPage = 1;
  buTotal: number = 0;

  constructor(
    private clientsService: ClientService,
    private router: Router,
    public location: Location,
    private master: MasterService,
  ) { }

  ngOnInit() {

    var customerInfo = JSON.parse(sessionStorage.getItem("userData"));

    if (localStorage.getItem("updatedClientId")) {
      this.client_id = localStorage.getItem("updatedClientId")
    }

    if (this.client_id) {
      //  console.log(self.client_id);
      this.getCompanyUser();
      this.getSubscriptionUser();
      if (customerInfo.admin_customer_id !== 61) {
        // this.getContacts(customerInfo.admin_customer_id);
      }
      this.getBUList();
    }

  }
  getCompanyUser() {
    let self = this;
    this.userSkip = (this.userPage - 1) * this.userLimit;
    this.clientsService.getCompanyUserList(this.client_id, this.userLimit, this.userSkip).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.contactUserObj = [] }
        else { self.contactUserObj = data.data.rows };
        self.contactTotal = data.data.total;
      }

    }, err => {
      console.error(err);
    })
  }

  getSubscriptionUser() {
    let self = this;
    this.subsSkip = (this.subsPage - 1) * this.subsLimit;
    this.clientsService.getSubscriptionUserList(this.client_id, this.subsLimit, this.subsSkip).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.subscriptionUserObj = [] }
        else {
          self.subscriptionUserObj = data.data.rows;
          self.subscriptionUserObj.forEach(element => {
            if (element.attachment) {
              if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt|pdf)$/i.test(element.attachment) === true) {
                element.attachment = this.downloadFile(element.attachment);
                element.imageType = 1;
              } else {
                element.imageType = 0;
              }
            }
          });
        }
        self.subsTotal = data.data.total;
      }
      console.log(self.subscriptionUserObj);
    }, err => {
      console.error(err);
    })
  }

  getContacts() {
    let self = this;
    this.contactSkip = (this.contactPage - 1) * this.contactLimit;
    this.clientsService.getCompanyContactList(this.client_id, this.contactLimit, this.contactSkip).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { self.contactObj = [] }
        else { self.contactObj = data.data.rows; }
        self.contactTotal = data.data.total;
      }
    }, error => {
      // console.log(error);
    });
  }

  getBUList() {
    let self = this;
   // this.buSkip = (this.buPage - 1) * this.buLimit;
    this.clientsService.getCompanyBUList(this.client_id).subscribe(data => {
      // console.log("test bu",data.data);
      if (data.status) {
        self.contactBUObj = data.data;
      } else {
        self.contactBUObj = [];
      }

    }, error => {
      //  console.log(error);
    });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  downloadFile(event) {
    let url = event;
    url = url.substring(url.indexOf("master_images"), url.length);
    console.log('url', url)
    if (url) {
      return this.master.getUrlfile(url);
      // this.downloadfile = awsUrl;
    }
  }

  clientHeadquaterView(id) {
    localStorage.setItem("updatedClientId", id);
    this.router.navigate(['/corporate/clientheadquaterhome'], { queryParams: { client_id: id } });
  }

  clientView(id) {
    localStorage.setItem("updatedClientId", id);
    this.router.navigate(['/corporate/clienthome'], { queryParams: { client_id: id } });
  }
}

