import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { MasterService } from "src/app/services/master/master.service";

@Component({
  selector: "app-woo-commerce-list",
  templateUrl: "./woo-commerce-list.component.html",
  styleUrls: ["./woo-commerce-list.component.css"],
})
export class WooCommerceListComponent extends BaseComponent implements OnInit {
  packageCreadential: any = [];
  connectorId: number = 1;
  totalCountPS: number;
  pagePS = 1;
  pageSizePS = 2;
  pageSize = 10;
  skipPS = 0;
  limit = 10;
  currentUser: any;
  isPackageCredential: boolean = true;
  isObjectListing: boolean = false;
  isObjectField: boolean = false;
  isAgreegateMaping = false;
  isCronSchedular = false;
  objectList: any = [];
  cronLogsList: any[] = [];
  //====Cron Schdeular Variables==
  schemaLists: any[] = [];
  pageCron = 1;
  limitCron = 10;
  totalCron: number;
  //====Aggregate mapping Variables====
  agregateMaping: any[] = [];
  selectedObject: any = 'products'
  companyId:any;
  constructor(private master: MasterService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("userData"));
    this.companyId = this.currentUser.admin_customer_id;
    if (localStorage.getItem("moduleName") == "PackageCredential") {
      this.isPackageCredential = true;
      this.isObjectListing = false;
      this.isObjectField = false;
      this.isAgreegateMaping = false;
      this.isCronSchedular = false;
      this.getPackageCredential();
    } else if (localStorage.getItem("moduleName") == "AgreegateMaping") {
      this.isPackageCredential = false;
      this.isObjectListing = false;
      this.isObjectField = false;
      this.isAgreegateMaping = true;
      this.isCronSchedular = false;
      this.getAgreegateMaping();
    } else if (localStorage.getItem("moduleName") == "CronSchedular") {
      this.isPackageCredential = false;
      this.isObjectListing = false;
      this.isObjectField = false;
      this.isAgreegateMaping = false;
      this.isCronSchedular = true;
      this.getCronSchedular();
    } else {
      this.getPackageCredential();
    }
  }
  getPackageCredential() {
    this.isPackageCredential = true;
    this.isObjectListing = false;
    this.isObjectField = false;
    this.isAgreegateMaping = false;
    this.isCronSchedular = false;

    // this.productText = "";
    // this.corporateText = "";

    this.skipPS = (this.pagePS - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master
      .getCredentialPackageList(
        this.companyId,
        this.currentUser.uid,
        this.connectorId,
        this.limit,
        this.skipPS
      )
      .subscribe(
        (data) => {
          this.packageCreadential = [];
          if (data.status) {
            this.packageCreadential = data.data?.rows || [];
            this.totalCountPS = data.data?.total || 0;
          } else {
            this.packageCreadential = [];
            this.totalCountPS = 0;
          }
          this.ngxSpinnerService.hide();
        },
        (err) => {
          this.ngxSpinnerService.hide();
          console.log(err);
        }
      );
  }
  addCredential(type: any, id: any = 0) {
    if (type == "Add") {
      this.router.navigate(["corporate/woocommerce/update"], {
        queryParams: { path: "Add" },
      });
    } else if (type == "Update") {
      localStorage.setItem("formId", id);
      this.router.navigate(["corporate/woocommerce/update"], {
        queryParams: { path: "Update" },
      });
    }
  }
  getObjectListing() {}
  getCronSchedular() {
    this.isPackageCredential = false;
    this.isObjectListing = false;
    this.isObjectField = false;
    this.isAgreegateMaping = false;
    this.isCronSchedular = true;
    this.getCroneList();
    this._getCroneLogsList();
  }
  _getCroneLogsList() {
    this.ngxSpinnerService.show();
    this.master
      .getCroneLogsList(this.companyId, this.currentUser.uid, this.connectorId)
      .subscribe(
        (response) => {
          this.ngxSpinnerService.hide();
          if (response.status) {
            this.cronLogsList = response.data;
          }
        },
        (err) => {
          this.cronLogsList = [];
          this.ngxSpinnerService.hide();
          console.log("error", err);
        }
      );
  }
  deleteCredential(id) {
    let obj = {
      id: id,
    };
    this.confirmationDialogService
      .confirm(
        "PLEASE CONFIRM",
        "Are you sure you want to Delete ?",
        "Yes",
        "No"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.deletePackageCredential(this.companyId, id).subscribe(
            (data) => {
              if (data.status) {
                this.getPackageCredential();
                setTimeout(() => {
                  this.toastrService.success("Deleted Successfully");
                }, 1000);
              } else {
                this.toastrService.error(data.message);
              }
              this.ngxSpinnerService.hide();
            },
            (error) => {
              // console.log(error);
              this.ngxSpinnerService.hide();
            }
          );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
  //===========Agreegate Mapping==========
  getAgreegateMaping() {
    this.isPackageCredential = false;
    this.isObjectListing = false;
    this.isObjectField = false;
    this.isAgreegateMaping = true;
    this.isCronSchedular = false;
    this.ngxSpinnerService.show();

    let body = {
      object_name: this.selectedObject,
      connector_id: this.connectorId,
    }
    this.master.getMapFiels(this.companyId,body).subscribe((response) => {
      if (response.status) {
        this.agregateMaping = response.data;
      }else{
        this.agregateMaping=[];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.agregateMaping=[]
      // console.log("--error--", error);
    });
  }
  //======Cron Schedular
  getCroneList() {
    this.ngxSpinnerService.show();
    let body = {
      connector_id: this.connectorId,
    };
    let skipCron = (this.pageCron - 1) * this.limit;
    this.master
      .getCroneList(this.companyId, this.currentUser.uid, this.limitCron, skipCron, body)
      .subscribe(
        (response) => {
          this.ngxSpinnerService.hide();
          if (response.status) {
            this.schemaLists = response.data?.rows || [];
            this.totalCron = response.data?.total;
          } else {
            this.schemaLists = [];
            this.totalCron = 0;
          }
        },
        (err) => {
          this.schemaLists = [];
          this.totalCron = 0;
        }
      );
  }
  updateCroneStatus(id:any,status:any) {
    this.confirmationDialogService
      .confirm(
        "PLEASE CONFIRM",
        "Are you sure you want to change the status?",
        "Yes",
        "No"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let body = {
            id: id,
            status: !status,
          };
          this.master.updateCronStatus(this.companyId, body).subscribe(
            (data) => {
              if (data.status) {
                this.getCroneList();
                this.toastrService.success("Cron Schedular Status Changed Successfully");
              } else {
                this.toastrService.error(data.message);
              }
              this.ngxSpinnerService.hide();
            },
            (error) => {
              // console.log(error);
              this.ngxSpinnerService.hide();
            }
          );
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
  navigateUpdateCronPage(id: any) {
    localStorage.setItem('formId', id);
    this.router.navigate(['corporate/woocommerce/cron-update'], { queryParams: { path: 'Update' } });
  }
  getProductFromWoocommerce() {
    let payload = {
      admin_id: this.currentUser.uid,
      connector_id: this.connectorId,
      query_field: 'date_modified',
      company: this.companyId
    }
    this.ngxSpinnerService.show();
    this.master.getProductFromWoocommerce(this.companyId,payload).subscribe((data) => {
      this.toastrService.success(
        "Poduct fetch"
      );
      this.ngxSpinnerService.hide();
    });

  }
  ngOnDestroy(){
    localStorage.removeItem("moduleName")
  }
}
