<div class="app-content content">
    <div class="content-wrapper" *ngIf="!isloading">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-sm-4 col-xs-12  col-10 box-shadow-0 p-0" *ngIf="isTokenValid">

                        <div class="text-center col-12">
                            <img class="col-sm-12" src="../../assets/custom/images/margologo250.png"
                                alt="branding logo">
                        </div>
                        <form class="form-horizontal text-center" [formGroup]="Resetform" (ngSubmit)="resetPassword()">
                            <input type="password" formControlName="password" class="form-control formstyle mt-1"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="user-name"
                                placeholder="New Password" required>
                            <div style="text-align: left;" *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                            <input type="password" placeholder="Confirm Password" formControlName="cpassword"
                                class="form-control formstyle mt-1"
                                [ngClass]="{ 'is-invalid': submitted && f.cpassword.errors }" />
                            <div style="text-align: left;" *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.cpassword.errors.mustMatch">Passwords must match</div>
                            </div>
                            <div class="text-center mt-2 d-inline">
                                <button type="submit" class="custbtn mt-2" >
                                    Submit
                                </button>
                                <button type="button" class="custbtn mt-2 ml-2 " (click)="gotoLogin()">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12  col-10 box-shadow-0 p-0" *ngIf="!isTokenValid">

                        <div class="text-center col-12">
                            <img class="col-sm-12" src="../../assets/custom/images/margologo250.png"
                                alt="branding logo">
                        </div>
                        <span>
                            Your token has been expired. Please generate a new token with submitting form again.
                        </span>
                        <div class="col-12 text-center">
                            <button type="button" class="custbtn mt-2" (click)="gotopassreset()">
                                ok
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>