<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item" ><a routerLink="/corporate/notes-list">Notes</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{company_ID!=null?'Update':'Add'}} Notes</li>
    </ol>
  </nav>
</div>


<div class="container" >
  <form class="form-horizontal mt-1" [formGroup]="notesForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">
            <div *ngIf="companyID === 0">
              <label for="notesForm">Select Company Name <span style="color:red">*</span></label>
              <select formControlName="company" *ngIf="company_ID == null" class="form-control formstyle" [(ngModel)]="company_ID"
                      [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)="onCompanySelected()">
                      <ng-container *ngFor="let userObj of getCompanyobj" >
                        <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
                      <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} > -->
                  {{userObj.customer_company_name}}
                </option>
                </ng-container>
              </select>

              <select formControlName="company" *ngIf="company_ID != null" disabled="controlEnabled" class="form-control formstyle" [(ngModel)]="company_ID"
              [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)="onCompanySelected()">
              <ng-container *ngFor="let userObj of getCompanyobj" >
                <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              <!-- <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} > -->
          {{userObj.customer_company_name}}
        </option>
        </ng-container>
      </select>
              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Company Name is required</div>
              </div>
            </div>

            <div *ngIf="selectedCompany">
              <label for="notesForm">Allowed Length <span style="color:red">*</span></label>
              <input type="number" appSpace min="1" maxlength="10" formControlName="allowedlength" class="form-control form-control-md"
                     [ngClass]="{ 'is-invalid': submitted && f.allowedlength.errors }" required>
              <div *ngIf="submitted && f.allowedlength.errors" class="invalid-feedback">
                <div *ngIf="f.allowedlength.errors.required">Notes Length is required</div>
              </div>
            </div>

            <div *ngIf="selectedCompany">
              <label for="notesForm">Company Notes Status <span style="color:red">*</span></label>
                <mat-slide-toggle class="my-slider offset-1"  formControlName="companyStatus" title="Enabled/Disabled"  *ngIf="isEdit">
                </mat-slide-toggle>
            </div>

          </div>

          <div class="card-footer text-center mt-1" *ngIf="selectedCompany">
            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="custbtn"  (click)="addNote()" >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
