import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { ReportService} from '../../../services/report/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from "@angular/common";
@Component({
  selector: 'app-csv-download',
  templateUrl: './csv-download.component.html',
  styleUrls: ['./csv-download.component.css']
})
export class CsvDownloadComponent implements OnInit {

  maxdate = new Date();
  start_date=null;
  end_date=null;
  companyId = 0;
  currentUser;
  isHeadquater ;
  datePipe = new DatePipe('en-US');

  constructor(      private report:ReportService,
    public toastrService: ToastrService,
    private spinner : NgxSpinnerService,
    private clientService : ClientService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId= this.currentUser.admin_customer_id;

   this.isHeadquater = localStorage.getItem("customer_isheadquarter");
  }

  donwloadCSV() {
    if(this.start_date == null  && this.end_date == null ) {
      this.toastrService.error("Please select start date and end date");
      return false;
    }

    if(this.start_date>this.end_date){
      setTimeout(() => {
        this.toastrService.error("Please select from date less than to date");
      }, 1000);
      return false;
    }
    // this.ngxSpinnerService.show();
    this.start_date = this.datePipe.transform(this.start_date, 'yyyy/MM/dd');
    this.end_date = this.datePipe.transform(this.end_date, 'yyyy/MM/dd');
    console.log("donwloadCSV-",this.start_date,"--",this.end_date);
    // return ;
    let body = {
      "from_date": this.start_date,
      "to_date": this.end_date
    };
   
    this.report.LoginHistoryCsv(body).subscribe(data => {
      if (data.status) {

        window.URL.revokeObjectURL(data.file);

        var hiddenElement = document.createElement('a');
        hiddenElement.href = encodeURI(data.file);
        hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded  
        hiddenElement.download = '';
        hiddenElement.click();

        this.toastrService.success("CSV downloaded Sucessfully");
      } else {
        this.toastrService.error(data.message);
      }
      // this.ngxSpinnerService.hide();
    }, error => {
    });
  }

}
