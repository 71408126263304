<!-- Breadcrumb for application -->
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/pii-report">PII & DSAR Reports</a></li>
        <li class="breadcrumb-item active" aria-current="page">Indivisual Data Report</li>
      </ol>
    </nav>
</div>

<div class="container">
    <div class="row">
      <div class="col">
          <h5 class="mb-1">Individual Name: <span> Mukesh Kumar Suman</span></h5>
          <div class="card box-shadow-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-2">
                    <label> Name:</label>
                  </div>
                  <div class="col-12 col-sm-4">
                    <p>Mukesh Kumar Suman</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label> Email:</label>
                  </div>
                  <div class="col-12 col-sm-4">
                    <p>mukesh.k@aditatechnologies.com</p>
                  </div>
                
                  <div class="col-12 col-sm-2">
                    <label>Address:</label>
                  </div>
                  <div class="col-12 col-sm-4">
                    <p> New Delhi, India</p>
                  </div>
                  
                  <div class="col-12 col-sm-2">
                    <label>Phone No:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>9818701955</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Home Phone No:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>9818701955</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Created Date:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>20 Jan, 2020 10:00 PM</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Opt-in Request Date:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>22 Jan, 2020 10:00 PM</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Opt-in Written Accepetance Date:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>23 Jan, 2020 10:00 PM</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Opt-in Accepetance Type:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>SMS - 23 Jan, 2020 11:00PM</p>
                  </div>
                  <div class="col-12 col-sm-2">
                    <label>Facebook App register Date:</label>
                  </div>
                  <div class="col-14 col-sm-4">
                    <p>FB - 23 Jan, 2020 11:00PM</p>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>

<div class="container mt-2 themeTblReso">
    <h4 class="mt-1">CURD Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Section Name</th>
          <th>Operation Name</th>
          <th width="10%">Visit IP</th>
          <th width="10%">Created by</th>
          <th width="20%">Date / Time</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>
    <h4 class="mt-1">Visit Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Page Name</th>
          <th>Module Name</th>
          <th width="10%">Visit IP</th>
          <th width="10%">Created by</th>
          <th width="20%">Date / Time</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>
    <h4 class="mt-1">Facebook Single Post Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Post Name</th>
          <th>Image</th>
          <th width="10%">Posted Category</th>
          <th width="10%">Posted Date</th>
          <th width="20%">IP Address</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>
    <h4 class="mt-1">Facebook Campaign Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Campaign Name</th>
          <th>Series of FB Post</th>
          <th width="10%">Posted Category</th>
          <th width="10%">Posted Date</th>
          <th width="20%">IP Address</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Shop Journey Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Journey Name</th>
          <th>Product Name</th>
          <th>Contact Type</th>
          <th width="10%">Journey Date</th>
          <th width="10%">Total Click</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="6">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Host Journey Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Journey Name</th>
          <th>Contact Type</th>
          <th width="10%">Journey Date</th>
          <th width="10%">Total Click</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>
    <h4 class="mt-1">Join Journey Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Journey Name</th>
          <th>Contact Type</th>
          <th width="10%">Journey Date</th>
          <th width="10%">Total Click</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Team Encouragement Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Journey Name</th>
          <th width="10%">Journey Date</th>
          <th width="10%">Total Click</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="4">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Team Training Report</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Journey Name</th>
          <th>Training Type</th>
          <th width="10%">Journey Date</th>
          <th width="10%">Total Click</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Event Report as Host</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Event Name</th>
          <th>Event Type</th>
          <th width="10%">Event Date</th>
          <th width="10%">Total Guest</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Event Report as co-Host</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Event Name</th>
          <th>Event Type</th>
          <th width="10%">Event Date</th>
          <th width="10%">Total Guest</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>

    <h4 class="mt-1">Event Report as Guest / Invitee</h4>
    <div class="tblPrt">
        <table class="mt-1 bdrStCustom" width="90%" cellpadding="0" cellspacing="0">
        <tr>
          <th>Event Name</th>
          <th>Event Type</th>
          <th width="10%">Event Date</th>
          <th width="10%">Total Guest</th>
          <th width="20%">Direct Seller Name</th>
        </tr>
        <tr>
            <td colspan="5">No Result Available</td>
        </tr>
        </table>
    </div>
</div>