<div class="container bdrStCustom">
  <div class="card box-shadow-1">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 accordHead">
          <h5 class="mb-0 font-weight-bold">Theme List</h5>
          <i class="fa fa-plus accordIcon" aria-hidden="true" (click)="isCollapsed1 = !isCollapsed1"
            [attr.aria-expanded]="!isCollapsed1" aria-controls="theme"></i>
        </div>

        <div [ngbCollapse]="isCollapsed1" class="col-md-12 mt-1">
          <div class="scrlTabl">
          <table class="table table-borderless table-responsive" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <th>Name</th>
              <th>Created Date</th>
              <th>Status</th>
            </tr>

            <tr *ngFor="let obj of ThemeObj">
              <td>{{obj.theme_name}}</td>
              <td>{{obj.createdon | date}}</td>
              <td>{{obj.isactive}}</td>
            </tr>
          </table>
          </div>

          <!-- <div class="paginCont" *ngIf="ThemeObj?.length>0">
            <pagination-controls class="my-pagination"  id="themePaging" (pageChange)="cp2=$event"></pagination-controls>
          </div> -->
          <div class="mt-2 text-center difPagination" *ngIf="themeTotal > 10">
            <ngb-pagination [collectionSize]="themeTotal" [(page)]="themePage" [maxSize]="2" [rotate]="true" (pageChange)="themeList(themePage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div>
         
        </div>
      </div>
    </div>
  </div>

  <div class="card box-shadow-1">
    <div class="card-body">
      <div class="row">

        <div class="col-md-12 accordHead">
          <h5 class="mb-0 font-weight-bold">Themes Associations</h5>
          <i class="fa fa-plus accordIcon" aria-hidden="true" (click)="isCollapsed2 = !isCollapsed2"
            [attr.aria-expanded]="!isCollapsed2" aria-controls="theme"></i>
        </div>

        <div [ngbCollapse]="isCollapsed2" class="col-md-12 mt-1">
          <div class="scrlTabl">
          <table class="table table-borderless table-responsive wit-490" cellpadding="0" cellspacing="0" border="0">
            <tr>              
              <th>Journey</th>
              <th>Product</th>
              <th>Category</th>
              <th>Theme Name</th>
              
            </tr>

            <tr *ngFor="let obj of ThemeAssociationObj">
                      
              <td>{{obj.journey_name}}</td>
              <td><p *ngIf="!obj.product_name">  
                None
            </p>
            <p *ngIf="obj.product_name!=''">  
              {{obj.product_name}}
          </p>
          </td>
        
          <td>{{obj.product_category}}</td>
              <td>{{obj.theme_name}}</td>
              
            </tr>
          </table>
          </div>

          <!-- <div class="mt-2 text-center difPagination" *ngIf="assocTotal > 10">
            <ngb-pagination [collectionSize]="assocTotal" [(page)]="assocPage" [maxSize]="5" [rotate]="true" (pageChange)="themeAssociationList(assocPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div> -->
          
          <div id="themeassociation" [ngbCollapse]="isCollapsed2" *ngIf="companyId!=414">
            <div class="card mt-1">
              <div class="card-body">
                <!-- <div>
            <label for="exampleForm2">Customer Id</label>
            <select [(ngModel)]="CustomerId" class="form-control formstyle">
              <option *ngFor="let obj of customerDropDownId" value={{obj.customer_id}}>
                {{obj.customer_company_name}}
              </option>
            </select>
          </div>-->
                <div class="row">
                  <div class="col-7 col-md-3">
                    <label for="exampleForm2" class="font-weight-bold">Journey Id</label>
                    
                    <select [(ngModel)]="JourneyId" class="form-control formstyle" (ngModelChange)="applySelectFilter($event)">
                      <option value="">--Select Journey--</option>
                      <option *ngFor="let obj of journeyDropDownId" value={{obj.journey_id}}>
                        {{obj.journey_name}} - {{obj.journey_description}}
                      </option>
                    </select>
                  </div>
                  

                  <div class="col-7 col-md-3 mt-1 mt-md-0"  [hidden]="!showProduct"  >
                    <label for="exampleForm2" class="font-weight-bold">Product Id</label>
                    <select [(ngModel)]="ProductId" class="form-control formstyle">
                      <option value="0" selected>--Select Product--</option>                       
                      <option *ngFor="let obj of shopProductList" value={{obj.product_catalog_id}}>
                        {{obj.product_name +" - "+ obj.product_category}}
                      </option>
                    </select>
                  </div>
               
                  <div class="col-7 col-md-3 mt-1 mt-md-0">
                    <label for="exampleForm2" class="font-weight-bold">Theme Id</label>
                    <select [(ngModel)]="ThemeId" class="form-control formstyle">
                      <option value="">--Select Theme--</option> 
                      <option *ngFor="let obj of themeDropDownId" value={{obj.theme_id}}>
                        {{obj.theme_name}}
                      </option>1
                    </select>
                  </div>

                  <div class="col-7 col-md-3 mt-md-1">
                    <input type="button" class="custbtn mt-4" value="Add New" (click)="addnewThemeAssociation()">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2 text-center difPagination" *ngIf="assocTotal > 10">
            <ngb-pagination [collectionSize]="assocTotal" [(page)]="assocPage" [maxSize]="2" [rotate]="true" (pageChange)="themeAssociationList(assocPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card box-shadow-1">
    <div class="card-body">
      <div class="row">

        <div class="col-md-12 accordHead">
          <h5 class="mb-0 font-weight-bold">Event Type List</h5>
          <i class="fa fa-plus accordIcon" aria-hidden="true" (click)="isCollapsed6 = !isCollapsed6"
            [attr.aria-expanded]="!isCollapsed6" aria-controls="theme"></i>
        </div>

        <div [ngbCollapse]="isCollapsed6" class="col-md-12 mt-1">
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive wit-290" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>Event Type Name</th>
                <th>Event Type Description</th>
              </tr>
  
              <tr *ngFor="let obj of companyEventTypeObj">
                <td>{{obj.journey_name}}</td>
                <td>{{obj.journey_description}}</td>
                
              </tr>
  
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="card box-shadow-1">
    <div class="card-body">
      <div class="row">

        <div class="col-md-12 accordHead">
          <h5 class="mb-0 font-weight-bold">Journey Asset List</h5>
          <i class="fa fa-plus accordIcon" aria-hidden="true" (click)="isCollapsed7 = !isCollapsed7"
            [attr.aria-expanded]="!isCollapsed7" aria-controls="theme"></i>
        </div>

        <div [ngbCollapse]="isCollapsed7" class="col-md-12 mt-1">
          <div class="scrlTabl">
            <table class="table table-borderless table-responsive wit-290" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <th>Journey Asset Name</th>
                <th>Journey Asset Description</th>
              </tr>
              <tr *ngFor="let obj of companyJourneyAssetObj">
                <td>{{obj.assests_name}}</td>
                <td>{{obj.assests_description}}</td>
              </tr>
            </table>
            <div class="mt-2 text-center difPagination" *ngIf="journeyTotal > 10">
              <ngb-pagination [collectionSize]="journeyTotal" [(page)]="journeyPage" [maxSize]="2" [rotate]="true" (pageChange)="journeyList(journeyPage=$event)" aria-label="Custom pagination">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
              </ngb-pagination> 
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="card box-shadow-1">
    <div class="card-body">
      <div class="row">

        <div class="col-md-12 accordHead">
          <h5 class="mb-0 font-weight-bold">Product List</h5>
          <i class="fa fa-plus accordIcon" aria-hidden="true" (click)="isCollapsed8 = !isCollapsed8"
            [attr.aria-expanded]="!isCollapsed8" aria-controls="theme"></i>
        </div>

        <div [ngbCollapse]="isCollapsed8" class="col-md-12 mt-1">
          <div class="scrlTabl">
          <table class="table table-borderless table-responsive wit-520" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Price</th>
              <th>Created Date</th>
              <th>Status</th>
            </tr>

            <tr *ngFor="let obj of companyProductObj">
              <td>{{obj.product_name}}</td>
              <td>{{obj.product_category}}</td>
              <td>${{obj.product_price}}</td>
              <td>{{obj.createdon| date}}</td>
              <td>{{obj.isactive}}</td>
            </tr>

          </table>
          </div>
          <div class="mt-2 text-center difPagination" *ngIf="productTotal > 10">
            <ngb-pagination [collectionSize]="productTotal" [(page)]="productPage" [maxSize]="2" [rotate]="true" (pageChange)="companyProductList(productPage=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div>
        </div>
      </div>
    </div>
  </div>




</div>