import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { MasterService } from 'src/app/services/master/master.service';
import { ClientService } from 'src/app/services/clients/client.service';
import {SupportService} from 'src/app/services/support/support.service'
import { BaseComponent } from '../../base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-supportuser-list',
  templateUrl: './supportuser-request-list.component.html',
  styleUrls: ['./supportuser-request-list.component.css']
})

export class SupportUserListRequestComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  selectedAll: any;
  requestList: any = [];
  currentUser: any;
  url;
  ord = 'desc';
  colname= 'createdon';
  totalCount: number;
  page = 1;
  limit = 10;
  skip = 0;
  body;
  isFetching = false;

  constructor(
    private supportService : SupportService,
    private spinner : NgxSpinnerService) {
    super();
  }

  ngOnInit() {

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.spinner.show();
    //this.updateCBfield();
    this.getRequestList();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(()=> {
          this.skip = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.supportService.getSupportRequest(this.body, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.requestList = [] }
          else { 
            this.requestList = res.data.rows;
            this.isFetching = false;
          }
          this.totalCount = res.data.total;
        } else {
          this.requestList = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col) {
    this.colname = col
    if(this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getRequestList();
  }

  getRequestList() {
    let searchTerm = this.searchText.nativeElement.value;
    this.skip = (this.page - 1) * this.limit;
    if(this.currentUser.role_id == 71) {
      this.body = {roleId:this.currentUser.role_id}
    }else {
      this.body = {roleId:this.currentUser.role_id, supportTypeId: 2}
    }
    this.isFetching = true;
    this.ngxSpinnerService.show();
    this.supportService.getSupportRequest(this.body, this.limit, this.skip, this.colname, this.ord, searchTerm).subscribe((data)=>{
      if (data.status) {
        if (data.data.rows == null) { this.requestList = [] }
        else { this.requestList = data.data.rows };
        this.totalCount = data.data.total;
        this.isFetching = false;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
   })
  }

  /** logged in for given time */
  getDsSession(sellerId, supportId, caseId,interval) {
    interval = interval.substring(1,2);
    // if (window.location.hostname == "localhost") {
    //   this.url = "http://localhost:4200";
    // }
    // else if (window.location.hostname == "dev-admin.margo.me") {
    //   this.url = "https://dev-ds.margo.me";
    // }
    // else if (window.location.hostname == "qa-admin.margo.me") {
    //   this.url = "https://qa-ds.margo.me";
    // }
    // else {
    //   this.url = "https://test-ds.margo.me";
    // }
    let obj = { sellerId: sellerId, supportId: supportId, caseId: caseId,interval:parseInt(interval) }
    console.log('OBJ', obj);
    // return
    this.supportService.getDsSession(obj).subscribe(data => {
      if (data.status) {
        //alert(window.location.hostname);
        // this.router.navigateByUrl('http://localhost:4200/directseller/home');
        window.open(`${environment.dsURL}/login/true/${data.token}/${data.customer_company_name}/${encodeURIComponent(data.customer_company_logo)}`, '_blank');
        //window.open(`https://dev-ds.margo.me/login/true/${data.token}/${data.customer_company_name}/${encodeURIComponent(data.customer_company_logo)}`, '_blank');
      }
    });
  }






}