import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/services/master/master.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UploadService } from 'src/app/services/uploadfile/upload.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-import-files-dialog',
  templateUrl: './import-files-dialog.component.html',
  styleUrls: ['./import-files-dialog.component.css']
})
export class ImportFilesDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() url: string;
  @Input() companyId: string;
  constructor(private activeModal: NgbActiveModal,
    private masterService: MasterService,
    private toastrService: ToastrService,
    private upload:UploadService, private ngxSpinnerService:NgxSpinnerService) { }
  value = 0;
  width = "0%";
  fileName;
  status = true;
  display = "none";
  myFiles: string[] = [];
  myNewFiles: string[] = [];
  fileUploadStatus = false;
  productCataloguePath = environment.product_catalogue_path;
  // productCataloguePath = 'Products/dev';

  ngOnInit() {
    // console.log(this.url);
  }

  processFile(event: any, files: any) {
    // const file: File = files.files[0];
    // this.fileName = file.name;
    // const reader = new FileReader();
    // this.toUploadFiles(file);
    // reader.readAsDataURL(file);
    const file: File = files.files[0];
    if (/\.(csv)$/i.test(file.name) === false) {
      this.toastrService.error("Please Follow same formate .csv");
      // console.log("error ", file);
    } else {
      const reader = new FileReader();
      // this.toUploadFiles(file);
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  toUploadFiles(name,file) {
    let self = this;
    this.ngxSpinnerService.show();
    this.upload.uploadImage(name,environment.awsKey,file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            let { key } = params;
            self.display = "flex";
            let id = setInterval(() => {
              self.value += 1;
              if (self.value <= 90) {
                self.width = `${self.value}%`;
              }
            }, 100);
            self.masterService.importBulk(self.url, { "key": key, "company": self.companyId }).subscribe(data => {
              if (data.status) {
                clearInterval(id);
                self.width = `100%`;
                self.toastrService.success(data.message);
                self.displayBox(data.status);
              } else {
                clearInterval(id);
                self.toastrService.error(data.message);
                self.width = `100%`;
                self.status = false
                self.displayBox(data.status);
              }
            }, err => {
              clearInterval(id);
              self.status = false
              self.width = `0%`;
              self.displayBox(false);
              console.error(err);
            })
          }
        }
        else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  displayBox(value) {
    let self = this;
    setTimeout(() => {
      self.activeModal.close(value)
    }, 1000);
  }
  public dismiss() {
    this.activeModal.dismiss();
  }

  processFiles(event: any, files: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file: File = event.target.files[i];
      if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
        this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
        return false;
      }
      let skuNumber = event.target.files[i].name.split('.').slice(0, -1).join('.');
      this.myFiles.push(skuNumber);

    }
    let body = {
      company: this.companyId,
      product: this.myFiles,
      product_path : this.productCataloguePath,
      page: 'bulk_update'
    }
    let self = this;

    this.masterService.renameProductImage(body).subscribe(data => {
      if (data.status) {
        // console.log("--data--", data);
        this.myNewFiles = data.result;
        const matched_sku = this.myFiles.filter(element => data.sku.includes(element));
        //for (var i = 0; i < event.target.files.length; i++) {
          self.display = "flex";
          let id = setInterval(() => {
            self.value += 1;
            if (self.value <= 90) {
              self.width = `${self.value}%`;
            }
          }, 100);
          for (var i = 0; i < matched_sku.length; i++) {
            const file: File = event.target.files[i];
            let newFileName = this.getFileName(this.myNewFiles, matched_sku[i]);
            const reader = new FileReader();
            reader.onload = (event1) => {
              this.toUploadFilesmultiple(newFileName, reader.result);
            }
            // this.toUploadFilesmultiple(file, newFileName);
            reader.readAsDataURL(file);
          }


      }else{
        self.toastrService.error(data.message);
      }
    });

  }


  getFileName(arrayObj, filename) {
    //console.log("arrayObj", arrayObj);
    //console.log("filename", filename);
    var findFile = arrayObj.find(function (data, index) {
      if (data.product_sku == filename)
        return true;
    });
    return findFile.product_name;
  }


  toUploadFilesmultiple(newFileName,file) {
    // console.log("file name", file);
    let self = this;
    this.ngxSpinnerService.show();
    this.upload.uploadImage(newFileName,this.productCataloguePath,file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.Key) {
            self.width = `100%`;
            self.displayBox(true);
            self.toastrService.success("Product Upload successfully");
            setTimeout(() => {},1000);
        }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }

  // getProductCatalogue() {
  //   let self = this;
  //   this.masterService.getProductCatalog(this.companyId).subscribe(data => {
  //       self.productObj = data.data;

  //   }, error => {

  //   });
  // }
}
