<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>

      <li class="breadcrumb-item"><a routerLink="/corporate/license-assign-listing">Bundle Assign Management</a></li>
      <li class="breadcrumb-item active" aria-current="page">Bundle Assign</li>

    </ol>
  </nav>
</div>

<div class="container mt-1 theme-fa-icon" >

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Company Name</label>
    <div class="col-sm-10">
      <select class="form-control formstyle"
       (change)="loadBundleForm($event.target.value)">
       <option value="">Please select company</option>
  <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
  {{userObj.customer_company_name}}
  </option>
  </select>
    </div>

  </div>

<div  *ngIf="showForm">

  <div class="row">
    <div class="col-md-12">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

    </div>
  </div>
  <form *ngIf="showForm" class="form-horizontal mt-1" [formGroup]="bundleAssignForm">

    <div class="form-group row mt-2">
      <label for="staticEmail" class="col-sm-2 col-form-label">Bundle Name</label>
      <div class="col-sm-10">

        <select class="custom-select mr-sm-2 max-w-130" style="max-width: 150px;" id="inlineFormCustomSelect" formControlName="bundleID" [ngClass]="{ 'is-invalid': submitted && f.bundleID.errors }">

          <option value="" selected>Bundle Name</option>
          <option *ngFor="let items of allBundleList" value="{{items.bundle_id}}">{{items.bundle_name}}</option>
        
        </select>



        <div *ngIf="submitted && f.bundleID.errors" class="invalid-feedback">
          <div *ngIf="f.bundleID.errors.required">Bundle Name is required</div>
        </div>

      </div>	    
    </div>
<div class="tableScrollLsnc">


	<table cellpadding="0" cellspacing="0" class="bundleTableMain">
	<tr>
		<th width="60" align="center">&nbsp;</th>
		<th width="150">Employee Name</th>
		<th>Email</th>

			

	</tr>

	<tr *ngFor="let item of companyDS | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:50,currentPage:cp }">
		<td align="center">
			<label class="custmCheck">
				<input type="checkbox" formControlName="checkedUser" value="{{item.user_ds_id}}"  (click)="selectUser($event.target.value)" >
				<span class="checkmark"></span>
			</label>
		</td>

		<td align="left">{{item.user_fname}} {{item.user_lname}}</td>
		<td align="left">{{item.user_email_primary}}</td>
 
 		</tr>


		
</table>

<div class="text-center mt-3" *ngIf="(companyDS | filter:text).length !== 0">
  <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
</div>

</div>
<div class="footerBtnLsn">
	<div class="form-group row">
	           <button class="mainBtnBle" (click)="goSubmit()">Assign Bundle</button>
	   
	    </div>	    
	  </div>

</form>
</div>

</div>