<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item active">Menu Master</li>
    </ol>
  </nav>
</div>

<div class="container mt-2">
  <div class="row">
    <div class="col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/addnewmenu">
      <button class="csvBtn" (click)="importFile()" *ngIf="isImport" placement="bottom" ngbTooltip="Import">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport" placement="bottom" ngbTooltip="Export">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>


  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" style="width: 1520px;"> 

      <tr>
        <!-- <th>Menu Id</th> -->
        <!-- <th>Menu Parent Id</th> -->
        <th>Menu Icon</th>
        <th>Menu Type</th>
        <th (click)="chgord('subc1')" style="cursor: pointer;">Menu Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Menu Description</th>
        <th>Menu Link</th>
        <th>Menu Link Type</th>
        <th>Is Active</th>
        <th>Created By</th>
        <th (click)="chgord('subc1')" style="cursor: pointer;">Created On <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Modified By</th>
        <th>Modified On</th>
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr *ngFor="let item of menuObj | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp} ">
        <td><img [src]="item.get_master_menu.menu_icon" alt="Not Found" (error)="setValue($event)" style="height:50px;">
        </td>
        <!-- <td>{{item.get_master_menu.menuid}}</td> -->
        <!-- <td>{{item.get_master_menu.menu_parent_id}}</td> -->
        <td>{{item.get_master_menu.menu_type}}</td>
        <td>{{item.get_master_menu.menu_name}}</td>
        <td>{{item.get_master_menu.menu_description}}</td>

        <!-- <td>{{item.get_master_menu.menu_icon}}</td> -->
        <td>{{item.get_master_menu.menu_link}}</td>
        <td>{{item.get_master_menu.menu_link_type}}</td>
        <td>{{item.get_master_menu.isactive}}</td>
        <td>{{item.get_master_menu.createdname}}</td>

        <td>{{item.get_master_menu.createdon | date:'medium'}}</td>
        <td>{{item.get_master_menu.modifiedname }}</td>
        <td>{{item.get_master_menu.modifiedon | date:'medium'}}</td>
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
          <i class="fa fa-edit mr-1"  *ngIf="isEdit" (click)="editMenu(item.get_master_menu.menuid)" placement="bottom"
            ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteMenu(item.get_master_menu.menuid)" placement="bottom"
            ngbTooltip="Delete"></span>
          <mat-slide-toggle [checked]="item.get_master_menu.isactive" class="my-slider" *ngIf="isDelete"
            (click)="updateStatus($event,item.get_master_menu.menuid)">
          </mat-slide-toggle>
          </div>

        </td>
      </tr>


  </table>
  <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
</div>
</div>