import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/clients/client.service';
import { ExtcompanysellerbuService } from 'src/app/services/extcompanysellerbu/extcompanysellerbu.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-ext-company-seller-bu',
  templateUrl: './ext-company-seller-bu.component.html',
  styleUrls: ['./ext-company-seller-bu.component.css']
})
export class ExtCompanySellerBuComponent extends BaseComponent implements OnInit {
  companyID = 0;
  currentUser: any;
  selectedCompany;
  tablesList: any = [];
  getCompanyobj: any = [];
  buDataList: any = [];
  headerkyes: any = [];
  dataList: any = [];

  tableFieldList: any = [];

  totalCount: number = 0;
  page = 1;
  limit = 10;
  offset = 0;
  selectedTablename: string;


  selectedFieldname:any = '';

  searchText;


  constructor(private clientService: ClientService,
    private companysellerbuService: ExtcompanysellerbuService) { super(); }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.getTablesData(this.companyID);
      this.selectedCompany = this.companyID;
    }
    if (this.currentUser.admin_customer_id == '0') { this.getCompany() }
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  getTablesData(company) {
    this.selectedCompany = company;
    if (company) {
      this.ngxSpinnerService.show();
      this.companysellerbuService.getTableList(this.selectedCompany).subscribe(data => {
        if (data.status) {
          this.tablesList = data.data;
        } else {
          this.tablesList = [];
        }
        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
      });
    } else {
      this.tablesList = [];
    }
  }

  onTableChange(tablename) {
    this.selectedTablename = tablename;
    this.page = 1;

    this.selectedFieldname = '';
    this.searchText = '';
    this.getBUListbyTablename();
  }

  onFieldNameChange(fieldname) {
    this.selectedFieldname = fieldname;

    this.page = 1;
    this.searchText = '';
  }

  getBUListbyTablename() {
    this.offset = (this.page - 1) * this.limit;
    this.searchText = this.searchText.trim();
    if (this.selectedTablename != '') {
      this.ngxSpinnerService.show();
      this.companysellerbuService.buListByFieldNameSearch(this.selectedCompany, this.selectedTablename, this.limit, this.offset, this.selectedFieldname, this.searchText)
        .subscribe(data => {
          this.dataList = [];
          if (data.status) {
            this.buDataList = data.data.results;
            this.totalCount = data.data.total;
            this.tableFieldList = data.data.fieldname;

            this.headerkyes = [...Object.keys(this.buDataList[0])];

            this.buDataList.forEach(element => {
              let val = { ...Object.values(element) };
              this.dataList.push(val);
            });
          } else {
            this.buDataList = [];
            this.tableFieldList = [];
          }
          this.ngxSpinnerService.hide();
        }, err => {
          console.log(err);
          this.ngxSpinnerService.hide();
        })

    } else {
      this.buDataList = [];
      this.tableFieldList = [];
    }
  }

  buListByFieldNameSearch() {
    this.offset = (this.page - 1) * this.limit;
    this.searchText = this.searchText.trim();
    if (this.selectedFieldname) {
      this.ngxSpinnerService.show();
      this.companysellerbuService.buListByFieldNameSearch(this.selectedCompany, this.selectedTablename, this.limit, this.offset, this.selectedFieldname, this.searchText)
        .subscribe(data => {
          this.dataList = [];
          if (data.status) {
            this.buDataList = data.data.results;
            this.totalCount = data.data.total;
            this.tableFieldList = data.data.fieldname;

            this.headerkyes = [...Object.keys(this.buDataList[0])];

            this.buDataList.forEach(element => {
              let val = { ...Object.values(element) };
              this.dataList.push(val);
            });
          } else {
            this.buDataList = [];
            this.tableFieldList = [];
          }
          this.ngxSpinnerService.hide();
        }, err => {
          console.log(err);
          this.ngxSpinnerService.hide();
        })

    } else {
      this.buDataList = [];
      this.tableFieldList = [];
    }
  }
}