import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-add-faq-library',
  templateUrl: './add-faq-library.component.html',
  styleUrls: ['./add-faq-library.component.css']
})
export class AddFaqLibraryComponent extends BaseComponent implements OnInit {

  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  customer_id;
  submitted: boolean = false;
  categoryObj;
  selectedCompany;
  screenshot_type = true;
  them_awskey;
  category_id;
  imgURL = [];
  videoURLs = [];
  fileURLs = [];
  product_files: any[];
  postUrl = [];
  screenshotUrl = [];
  fileUploadErrMsg: any;

  slCatPage = 1;
  slCatLimit = 100;
  slCatSkip = 0;
  slCatTotal: number = 0;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;
  imageChangedEvent: any;
  croppedImage: any;
  @ViewChild("imageInput") imageInput!: ElementRef;
  constructor(
    private clientService: ClientService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.customer_id = JSON.parse(
      sessionStorage.getItem("userData")
    ).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    if (this.customer_id != 0) {
      this.getCategoryData(this.customer_id);
    }

    this.addCategoryForm = this.formBuilder.group({
      categoryid: ["", Validators.required],
      faqType: ["", Validators.required],
      question: [
        "",
        [
          Validators.required,
         
          this.noWhitespaceValidator,
        ],
      ],
      answer: [
        "",
        [
          Validators.required,
         
          this.noWhitespaceValidator,
        ],
      ],
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
    });
  }

  get f() {
    return this.addCategoryForm.controls;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(
      (data) => {
        this.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCategory(company) {
    if (company) {
      this.getCategoryData(company);
      this.selectedCompany = company;
    }
  }
  getCategoryData(company) {
    if (company) {
      this.ngxSpinnerService.show();
      let status = true;

      this.masterService
        .getFaqCategoryList(company, this.slCatLimit, this.slCatSkip, "","name","asc")
        .subscribe(
          (data) => {
            if (data.status) {
              if (data.data.rows == null) {
                this.categoryObj = [];
              } else {
                this.categoryObj = data.data.rows;
              }
              this.slCatTotal = data.data.total;
            } else {
              this.categoryObj = [];
              this.slCatTotal = 0;
            }
            this.ngxSpinnerService.hide();
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.categoryObj = [];
      this.ngxSpinnerService.hide();
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  addNewPostData() {
    this.submitted = true;
    let { categoryid, question, answer,faqType } =this.addCategoryForm.value;
    if (this.addCategoryForm.invalid) {
      return;
    }
    let obj = {
      category_id: categoryid,
      question: question,
      answer: answer,
      isactive:true,
      company:this.selectedCompany,
      faqType: faqType,
    };
    this.ngxSpinnerService.show();
    console.log("--OBJ--", obj);
    // return false;
    this.masterService.addFAQ(obj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success("Record Inserted successfully");
          this.router.navigateByUrl(`/corporate/faq-library`);
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.toastrService.error(error.message);
      }
    );
  }

}
