import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { SegmentationService } from '../../../../services/segmentation/segmentation.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-operator-group',
  templateUrl: './operator-group.component.html',
  styleUrls: ['./operator-group.component.css']
})

export class OperatorGroupComponent extends BaseComponent implements OnInit {
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  totalCount: number = 0;
  operatorObj: any = [];
  ord = 'desc';
  colname = "createdon";
  limit = 10;
  offset = 0;
  page = 1;
  today: number = Date.now();

  constructor(
    private segmentService: SegmentationService
  ) { super(); }

  ngOnInit() {
    localStorage.removeItem("deleteOperatorId")
    this.getOperator();
    this.logdata();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.segmentService.getOperatorGroupList(this.limit, this.offset, this.colname, this.ord, this.searchText.nativeElement.value))
      ).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.operatorObj = [] }
          else { this.operatorObj = data.data.rows }
          this.totalCount = data.data.total;
        } else {
          this.operatorObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  logdata() {
    let themeLogs = {
      log_type: "Operator Segementation View",
      log_name: "Operator Segementation View",
      log_description: "Operator Segementation View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);
    }, error => {
      // console.log(error);
    });

  }

  getOperator() {
    let searchTerm = this.searchText.nativeElement.value;
    this.offset = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.segmentService.getOperatorGroupList(this.limit, this.offset, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.operatorObj = [] }
        else { this.operatorObj = data.data.rows }
        this.totalCount = data.data.total;
        this.ngxSpinnerService.hide();
      } else {
        this.operatorObj = [];
        this.totalCount = 0;
        this.ngxSpinnerService.hide();
      }
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();

    });
  }

  sorting(col) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    } this.getOperator();
  }

  deleteOperatorGroup(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.segmentService.deleteOperatorGroupList(id).subscribe(data => {
            if (data.status) {
              this.getOperator();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            }
            else {
              this.toastrService.error(data.message)
            }
            this.ngxSpinnerService.hide();
          }, error => {
            //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  editOperatorGroup(id) {
    localStorage.setItem("deleteOperatorId", id);
    this.router.navigate(['/corporate/add-operator-group']);
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.operatorObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.operator_group_id === id) {
        return true;
      }
    });
    //obj = obj.get_master_journey;

    // console.log("*********", obj)

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' In Active?' : ' Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          obj.status = !obj.isactive;

          this.segmentService.updateOperatorGroup(obj, obj.operator_group_id).subscribe(data => {
            if (data.status) {
              this.getOperator();
              this.toastrService.success("Status Changed Successfully");
            }
            else {
              this.toastrService.error(data.message)
            }

          });
        }
        // else {
        //   this.master.updateJourney(obj).subscribe(data => {
        //     this.getJourney();
        //   });
        // }
      }).catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }



}


