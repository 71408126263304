import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sender-profile-list',
  templateUrl: './sender-profile-list.component.html',
  styleUrls: ['./sender-profile-list.component.css']
})
export class SenderProfileListComponent extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany: any = '';
  currentUser;
  getCompanyobj: any = [];
  profileObj: any = [];
  profileTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "title";
  constructor(private clientService: ClientService,
    private masterService: MasterService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));

    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getProfileList();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  getProfileList() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();
      this.masterService.getSenderProfileList(this.selectedCompany, this.slCatLimit, this.slCatSkip, searchTerm, '', '').subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.profileObj = [] }
          else { this.profileObj = data.data.rows }
          this.profileTotal = data.data.total;
        } else {
          this.profileObj = [];
          this.profileTotal = 0;
        }
      }, error => { console.log(error); this.ngxSpinnerService.hide(); });
    } else {
      this.profileObj = [];
      this.selectedCompany = "";
      this.profileTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.masterService.getSenderProfileList(this.selectedCompany, this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value, '', ''))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.profileObj = [] }
          else { this.profileObj = res.data.rows }
          this.profileTotal = res.data.total;
        } else {
          this.profileObj = [];
          this.profileTotal = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.profileObj = [];
        this.profileTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }
  gotoedit(id) {
    // localStorage.setItem("senderProfileData", JSON.stringify(profiledata));
    
    localStorage.setItem("senderProfileId",id);
    localStorage.setItem("senderProfileCompanyId",this.selectedCompany);
    this.router.navigateByUrl(`/corporate/sender-profile-update`)
  }
  gotoview(id) {
    // localStorage.setItem("senderProfileData", JSON.stringify(profiledata));
    
    localStorage.setItem("senderProfileId",id);
    localStorage.setItem("senderProfileCompanyId",this.selectedCompany);
    this.router.navigateByUrl(`/corporate/sender-profile-view`)
  }
}

