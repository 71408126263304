<div class="scroller">
<div class="wholePop filtMain">
  <div class="modal-header d-block dialHead">
    <img src="assets/custom/images/margoLogoIcon.png">
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="card box-shadow-3"> -->
    <div class="mt-3" style="text-align: left;">
      <!-- <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Log ID</label>
        </div>
        <div class="col-4 col-sm-6">
          <p *ngIf="true" class="card-text">
            {{viewobj.lid}}</p>
        </div>
      </div> -->

      <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Log Name</label>
        </div>
        <div class="col-4 col-sm-6">
          <p *ngIf="true" class="card-text">
            {{viewobj.log_name ? viewobj.log_name : viewobj.method}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Log Description</label>
        </div>
        <div class="col-4 col-sm-6">
          <p *ngIf="true" class="card-text">
            {{viewobj.log_description ? viewobj.log_description : viewobj.description}}</p>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Log Type</label>
        </div>
        <div class="col-4 col-sm-6">
          <p *ngIf="true" class="card-text">
            {{viewobj.log_type}}</p>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Page URl</label>
        </div>
        <div class="col-4 col-sm-6">
          <p class="card-text" *ngIf="true">
            {{viewobj.username}} </p>
        </div>
      </div> -->
      <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">User</label>
        </div>
        <div class="col-4 col-sm-6" *ngIf='true'>
          <p class="card-text" >
            <span *ngIf="!(viewobj.createdname) && !(viewobj.username) ; else elseBlock">{{viewobj.name}}</span>
            <ng-template #elseBlock>{{(viewobj.createdname)? viewobj.createdname : viewobj.username }} 
            </ng-template>
            </p>
        </div>
  
      </div>
      <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">IP Address</label>
        </div>
        <div class="col-4 col-sm-6">
          <p class="card-text" *ngIf="true">
            {{viewobj.ipaddress}} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-8 col-sm-6">
          <label style="font-weight: 600; color: black;">Date Time</label>
        </div>
        <div class="col-4 col-sm-6">
          <p class="card-text" *ngIf="true">
            {{viewobj.createdon| date:'MMM d, y, h:mm a'}} </p>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <!-- <hr> -->
  <!-- <div class="modal-footer">
    <p>Add remaining all field from log table.</p> 
  </div>
  -->
</div>
</div>