import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { FbService } from 'src/app/services/fbservice/fb.service';
import { MasterService } from 'src/app/services/master/master.service';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { ShopLiveService } from './../../../../services/shop-live/shop-live.service';
import Swal from 'sweetalert2';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-shoplive-editpost',
  templateUrl: './shoplive-editpost.component.html',
  styleUrls: ['./shoplive-editpost.component.css']
})
export class ShopliveEditpostComponent extends BaseComponent implements OnInit {
  updateCategoryForm: FormGroup;
  getCompanyobj: any = [];
  customer_id;
  controlEnabled: boolean = true;
  submitted: boolean = false;
  postCategoryObj;
  selectedCompany;
  screenshot_type = true;
  them_awskey;
  category_id;
  categoryObj;
  post_id;
  status: boolean = true;
  imageSrc;
  isImage: boolean;
  imgURL = [];
  videoURLs = [];
  fileURLs = [];
  downloadfile = [];
  product_files: any[];

  postImgURL = [];
  postVideoURLs = [];
  postUrls = [];

  slCatPage = 1;
  slCatLimit = 1000;
  slCatSkip = 0;
  slCatTotal: number = 0;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;

  constructor(
    private clientService: ClientService,
    private fb: FbService,
    private formBuilder: FormBuilder,
    private master: MasterService,
    private shopLiveService: ShopLiveService,
    public cd: ChangeDetectorRef,
    private upload:UploadService) {
    super();
    this.updateCategoryForm = this.formBuilder.group({
      categoryid: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
      url: ['', Validators.pattern(AppConstants.regex.websiteURL)],
      post_screenshot: [''],
      firstActive: [0],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],
      business_domain_id:['']
    });
  }

  ngOnInit() {
    // this.getBusinessDomain();
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    this.controlEnabled = true;
    if (this.customer_id == 0) this.getCompany();
    if (this.customer_id != 0) { this.getpostCategoryData(this.customer_id); }

    let self = this;
    if (localStorage.getItem("edit_sl_post_id")) {
      this.post_id = localStorage.getItem("edit_sl_post_id");
      this.selectedCompany = localStorage.getItem("sl_post_edit_company_id");
      this.getCategory(this.selectedCompany);
      this.ngxSpinnerService.show();

      this.shopLiveService.getCorporatePostByID(this.post_id, this.selectedCompany).subscribe(response => {
        if (response.status) {
          this.status = false;
          self.categoryObj = response.data;
          self.dataBinding();

        }
      });
    }
  }

  get f() { return this.updateCategoryForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  setValue(event) {
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = this.master.getUrlfile(url);
        //this.them_awskey = url;
        event.target.src = this.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  downloadFile(event) {
    let url = event[0];
    console.log('event', url);
    url = url.substring(url.indexOf("master_images"), url.length);
    console.log('url', url)
    if (url) {
      let awsUrl = this.master.getUrlfile(url);
      this.downloadfile[0] = awsUrl;
      console.log('awsUrl', awsUrl)
    }
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  getCategory(company) {
    if (company) {
      this.getpostCategoryData(company);
      this.selectedCompany = company;
      this.getBusinessDomain()
    }
  }

  // getpostCategoryData(company) {
  //   if (company) {
  //     this.ngxSpinnerService.show();
  //     this.shopLiveService.getCorporateCategoryList(company, true).subscribe(data => {
  //       this.postCategoryObj = data.data;
  //       this.ngxSpinnerService.hide();
  //     }, error => {
  //       // console.log(error);
  //     });
  //   } else { this.postCategoryObj = []; this.ngxSpinnerService.hide(); }
  // }

  getpostCategoryData(company) {
    if (company) {
      this.ngxSpinnerService.show();
      let status = true;


      this.shopLiveService.getCategoryList(company, this.slCatLimit, this.slCatSkip,  this.slCatColname,  this.slCatord, status, '').subscribe(data => {


        if (data.status) {
          if (data.data.rows == null) { this.postCategoryObj = [] }
            else { this.postCategoryObj = data.data.rows }
            this.slCatTotal = data.data.total;
        } else {
          this.postCategoryObj = [];
          this.slCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
      });
    } else { this.postCategoryObj = []; this.ngxSpinnerService.hide(); }
  }

  processFile(event: any, imageInput: any) {
    let self = this;
    const file: File = imageInput.files[0];
    if (file.size <= 1024 * 1024 * 4) {
      if (/\.(jpe?g|png|jpg|gif|mov|mp4|csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(file.name) === false) {
        self.toastrService.error("Please select only (png, jpg, jpeg, gif, mov, mp4, csv, doc, xls, pptx or txt) files");
      } else {
        const reader = new FileReader();
        reader.onload = (event1) => {
          this.toUploadFiles(file.name, reader.result).then(data => {
            self.ngxSpinnerService.hide();
            if (data) {
            }
          }).catch(err => {
            self.toastrService.error('Something went wrong');
            self.ngxSpinnerService.hide();
          })
        }
        reader.readAsDataURL(file);
      }
    } else {
      this.toastrService.error("Unable to proceed upload, File is larger than 4 mb.");
    }
  }

  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    return new Promise<any>((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.key) {
              if (/\.(jpe?g|png|jpg|gif)$/i.test(params.Key) === true) {
                self.imgURL[0] = params.Key;
                resolve(true);
              } else if (/\.(mov|mp4)$/i.test(params.Key) === true) {
                self.videoURLs[0] = params.Key;
                resolve(true);
              } else if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(params.Key) === true) {
                self.fileURLs[0] = params.Key;
                resolve(true);
              }
            }
          }else{
            reject(false)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(false)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }

  dataBinding() {
    this.imageSrc = this.categoryObj.sl_product_files;

    let pattern = new RegExp('^(https?|ftp)://');
    if (this.imageSrc) {
      if (/\.(jpe?g|png|jpg|gif)$/i.test(this.imageSrc) === true && pattern.test(this.imageSrc)) {
        this.postImgURL = this.imageSrc;
        this.isImage = true;
      } else if (/\.(mp4|mov)$/i.test(this.imageSrc) === true && pattern.test(this.imageSrc)) {
        this.postVideoURLs = this.imageSrc;
        this.isImage = true;
      } else if (/\.(jpe?g|png|jpg|gif)$/i.test(this.imageSrc) === true) {
        this.imgURL = this.imageSrc;
      } else if (/\.(mp4|mov)$/i.test(this.imageSrc) === true) {
        this.videoURLs = this.imageSrc;
      } else if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(this.imageSrc) == true) {
        this.fileURLs = [...this.imageSrc];
        this.downloadFile(this.fileURLs[0]);
      }
    }
    this.updateCategoryForm = this.formBuilder.group({
      categoryid: [this.categoryObj.sl_cat_id, Validators.required],
      name: [this.categoryObj.sl_product_name, [Validators.required, this.noWhitespaceValidator]],
      description: [this.categoryObj.sl_product_desc, [Validators.required, this.noWhitespaceValidator]],
      url: [this.categoryObj.sl_product_url],
      post_screenshot: [this.isImage ? this.categoryObj.sl_product_files[0] : ''],

      //firstActive: [this.isImage ? 0 : 1],
      firstActive: [this.isImage ? 0 : 0],

      selectedCompany: [this.selectedCompany, Validators.required],
      business_domain_id:[this.categoryObj.business_domain_id]
    });
    if (this.isImage) this.screenshot_type = true; else this.screenshot_type = false;
  }


  uploadByUrlImgVideo() {
    let pattern = new RegExp('^(https?|ftp)://');
    let post_screenshot = this.f.post_screenshot.value;
    if ((post_screenshot != '') && (/\.(jpe?g|png|jpg|gif)$/i.test(post_screenshot) === true && pattern.test(post_screenshot))) {
     this.postImgURL.splice(this.postImgURL.length - 1, 1, post_screenshot);
    } else if ((post_screenshot != '') && (/\.(mp4|mov)$/i.test(post_screenshot) === true && pattern.test(post_screenshot))) {
      this.postVideoURLs.splice(this.postVideoURLs.length - 1, 1, post_screenshot);
    }
  }


  updatePost() {


    this.submitted = true;
    let { categoryid, name, description, url, post_screenshot , business_domain_id} = this.updateCategoryForm.value;

    this.uploadByUrlImgVideo();
    // this.postUrls = [...this.postImgURL, ...this.postVideoURLs];
    this.product_files = [...this.imgURL, ...this.videoURLs, ...this.fileURLs];

    // console.log("product_files", this.product_files);

    // return false;

    // if ((this.product_files.length == 0 || this.product_files[0] == undefined || this.product_files[0] == null) &&
    //   (this.postUrls.length == 0 || this.postUrls[0] == undefined || this.postUrls[0] == null)) {
    //   this.toastrService.error('Please select file.');
    //   return false;
    // }

    if (this.updateCategoryForm.invalid) {

      console.log("this.updateCategoryForm.invalid", this.updateCategoryForm);
      return;
    }


    // if (this.postUrls.length > 0) {
    //   var urlregex = new RegExp(
    //     "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    //   let pattern = urlregex.test(this.postUrls[0]);
    //   console.log(this.postUrls[0])
    //   if (!pattern) {
    //     this.toastrService.error("Enter Valid URL");
    //     return false;
    //   }
    // }


    let obj = {
      product_name: name,
      product_desc: description,
      product_id: Number.parseInt(this.categoryObj.sl_product_id, 10),
      product_cat_id: categoryid,
      product_url: url,
      product_files: this.product_files,
      business_domain_id:business_domain_id
    }
    // obj.product_files = [...this.postUrls, ...this.product_files];
    if (obj.product_files.length > 1) {
      this.toastrService.error('Please select one file.');
      return false;
    }

    console.log("####", obj);

    // return false;
    this.ngxSpinnerService.show();
    this.shopLiveService.editCorporatePost(this.selectedCompany, obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Updated successfully");
        this.router.navigateByUrl(`/corporate/shoplive-postmanage`);
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
    });
  }

  deletedImgFile(index_key, val) {
    if (this.imgURL.length > 0 && val == 'imgURL') { this.imgURL.splice(index_key, 1) }
    else if (this.postImgURL.length > 0 && val == 'postImgURL') {

      this.postImgURL.splice(index_key, 1);
      this.updateCategoryForm.get('post_screenshot').patchValue('');
    }
    this.cd.detectChanges();
    Swal.fire({
      icon: 'success',
      title: 'Removed!',
      showConfirmButton: false,
      timer: 1500
    })
  }


  deletedVideoFile(index_key, val) {
    if (this.videoURLs.length > 0 && val == 'videoURLs') { this.videoURLs.splice(index_key, 1) }
    else if (this.postVideoURLs.length > 0 && val == 'postVideoURLs') {

      this.postVideoURLs.splice(index_key, 1);
      this.updateCategoryForm.get('post_screenshot').patchValue('');
    }
    Swal.fire({
      icon: 'success',
      title: 'Removed!',
      showConfirmButton: false,
      timer: 1500
    })
  }


  deleteFile(index_key) {
    this.downloadfile.splice(index_key, 1);
    this.fileURLs = [];
    this.cd.detectChanges();
    Swal.fire({
      icon: 'success',
      title: 'Removed!',
      showConfirmButton: false,
      timer: 1500
    })
  }
  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.selectedCompany).subscribe(data => {
      self.domainData = data.data.rows;
    }, error => {
      this.domainData=[]
    });
  }
}
