import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'app-moduleitems',
  templateUrl: './moduleitems.component.html',
  styleUrls: ['./moduleitems.component.css']
})
export class ModuleitemsComponent extends BaseComponent implements OnInit {

  public moduleObj: any = [];
  cp = 1;
  ord = true;
  colname: "Name";
  text;
  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService) 
    { 
      super();
    }

  chgord(col) {
    this.ord = !this.ord
    this.colname = col
  }
  ngOnInit() {
   
    this.getModuleItemsData();
    this.ModuleItemsLogData()
  }

  ModuleItemsLogData() {
    let ModuleItemsLogs = {
      log_type:"ModuleItems Master View" ,
      log_name:"ModuleItems Master View" ,
      log_description:"ModuleItems Master View" ,
      moduleid:1 ,
      moduleitemid:7,
      per_id:14
    }
    this.logs.addNewLogs(ModuleItemsLogs).subscribe(data => {
    //  console.log(data);
      
    }, error => {
    //  console.log(error);
    });
  
}

  getModuleItemsData() {
    let self = this;
  this.ngxSpinnerService.show();
    this.master.getModuleItems().subscribe(data => {
    //  console.log(data);
      self.moduleObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
      // this.alertService.error(error);
      
    });
  }
  editModuleItem(id) {
  //  console.log();
    // this.router.navigateByUrl(`/corporate/addnewjourney`,["fragment",]);
    this.router.navigate(['/corporate/addnewmoduleitems'], { queryParams: { moduleitemid: id } });
  }
  deleteModuleItemData(id) {

    //Logic to delete the item

    let obj =
    {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delModuleItems(obj).subscribe(data => {
          //  console.log(data);
            if (data.status) {
              this.getModuleItemsData();
              this.toastrService.success("Deleted Successfully")
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
           // console.log(error);
            // this.alertService.error(error);

          });
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.moduleObj.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.get_master_module_item_fun.moduleitemid === id) {
        return true;
      }
    });
    obj = obj.get_master_module_item_fun;
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          this.master.updateModuleItems(obj).subscribe(data => {
          //  console.log(data)
            this.getModuleItemsData()
            this.toastrService.success("Status Changed Successfully");
            this.ngxSpinnerService.hide();
          });
        }
        // else {
        //   this.master.updateModuleItems(obj).subscribe(data => {
        //     console.log(data)
        //     this.getModuleItemsData()
        //     setTimeout(() => {
        //       this.toastrService.success("Status Changed Successfully");
        //     }, 1000)
        //   });
        // }
      }).catch(() => {
      //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }
  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-moduleitem-import.csv`, 'addingModuleItemsBulk')
      .then(data => {
       // console.log(data);
        self.getModuleItemsData();
      })
      .catch(err => {
       // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_module_item_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}