<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/master">Webinar Module</a></li>
          <li class="breadcrumb-item"><a routerLink="/corporate/faq-category">Faq Category</a></li>
          <li class="breadcrumb-item active" aria-current="page">Add Category</li>

      </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="addCategoryForm">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">

              <label for="exampleForm2">Company Name <span class="required">*</span></label>

              <select formControlName="selectedCompany" class="form-control formstyle ssss"
                *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>

            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">

            <div class="form-group">
              <label for="name">Category Name <span class="required">*</span></label>
              <input type="text" class="form-control" name="name" formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="50" appSpace>
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required</div>
                <div *ngIf="f.name.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="name">Category Description</label>
              <textarea class="form-control text-area h-25" rows="5" maxlength="100" appSpace formControlName="description"></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <button class="btn btn-round btn-margo-action" (click)="addNewCategoryData()">Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
