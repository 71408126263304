<div class="container">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="breadCust">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Schedule Journey List</li>
                </ol>
              </nav>
        </div>   
    </div>
  </div>

  <div class="container mt-4 themeTblReso">
    <div class="row">
      <div class="col-md-4">
        <!-- <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                aria-hidden="true"></i></span>
          </div>
  
          <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText 
            [disabled]="companyID === 0" >
        </div> -->
      </div>
      <div class="col-md-6 offset-2" >
        <div  style="justify-content: right" >
          <input type="button" value="Add Schedule For Journey"  class="custbtn red-lt-icon ml-0 " routerLink="/corporate/add-schedule-journey-list"/>    
        </div>
      </div>   
    </div>
  </div>
<div class="container mt-4 themeTblReso">
  <div class="row">
    <div class="col-md-4">
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="onCompanyChange($event.target.value)">

        <option value= 0>------Select Company Name------</option>
        <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
          {{userObj.customer_company_name}}
        </option>
      </select>
    </div>

    <div class="col-md-4">
      <select class="form-control formstyle" [(ngModel)]="jid" (change)="onJourneySelect($event.target.value)">
        <option value="">------Select Journey Type------</option>
        <option *ngFor="let item of journeyTypeObj" value={{item.journey_id}}>
          {{item.journey_name}}
        </option>
      </select>
    </div>
  </div>
</div>

 <div class="tblPrt" *ngIf="journeyList.length > 0 && totalCount > 0">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <th>Theme Name</th>
      <th>Journey Name </th>
      <th>Master Journey Name</th>
      <th>Schedule Type</th>
      <th>Interval </th>
      <th>Interval Type</th>
    </tr>

    <tr *ngFor="let obj of journeyList">
      <td>{{obj?.theme_name}}</td>
      <td>{{obj.journey_name}}</td>
      <td>{{obj.master_journey_name}}</td>
      <td *ngIf="obj.schedule_type === true">Automatic</td>
      <td *ngIf="obj.schedule_type === false">Manual</td>
      <td>{{obj.schedule_interval}}</td>
      <td>{{obj.schedule_interval_type}}</td>
    </tr>

  </table>
  <div style="text-align: center;" *ngIf="totalCount == 0">
    <p>No Result Available</p>
  </div>
  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 0">
    <ngb-pagination [collectionSize]="totalCount" [pageSize]="limit" [(page)]="page" [maxSize]="2" [rotate]="true"
      (pageChange)="getPageChange(page = $event)" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div> 