import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ClientService } from "src/app/services/clients/client.service";
import { SegmentationService } from "src/app/services/segmentation/segmentation.service";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute } from "@angular/router";
import { MasterService } from 'src/app/services/master/master.service';
import { Boolean } from "aws-sdk/clients/apigateway";


@Component({
    selector: 'app-cross-sell-component',
    templateUrl: './cross-sell.component.html',
    styleUrls: ['./cross-sell.component.css']
})
export class CrosssellListComponent extends BaseComponent implements OnInit {
    checkBoxDisable: boolean = true;
    customerId: any;
    isHeadquarter = false;
    buForm: FormGroup;
    getCompanyobj = [];
    selectedBuId: any;
    form: FormGroup;
    selectedIndex = 'week';
    audTyp = 1;
    limit = 10;
    skip = 0;
    list = [];
    totalRecords = 0;
    page = 1;
    defaultPicture = 'assets/custom/images/broken.png';
    maxdate = new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    mindate = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    startDate = '';
    endDate = '';
    checkedList = [];
    campaignType: any = 0;
    isAllChecked: boolean = false;
    keyword = 'name';
    contactStartDate = '';
    paramslist: any;
    getDSList = [];
    dsID: number = 0;
    isExport: Boolean = true;

    selectedDS: any = 'Select all';

    isShopFlag: boolean = false;

    constructor(private formBuilder: FormBuilder, private clientService: ClientService,
        private segmentationService: SegmentationService,
        protected activeRoute: ActivatedRoute,
        private master: MasterService,) {
        super();
        this.form = this.formBuilder.group({
            startdate: new FormControl(),
            enddate: new FormControl(),
            campType: [0],
            dsid: [0],
        });



        this.activeRoute.queryParams
            .subscribe(params => {
                this.paramslist = params;
            });


    }

    ngOnInit(): void {
        this.ngxSpinnerService.show();
        let user = JSON.parse(localStorage.getItem('userData'));
        this.customerId = user.admin_customer_id;
        if (this.customerId === 0) {
            this.getCompanyList();
        } else {
            let hq = JSON.parse(localStorage.getItem('customer_isheadquarter'));
            if (hq) {
                this.isHeadquarter = true;
                this.getCompanyList();
            } else {
                this.selectedBuId = this.customerId;

                if (this.paramslist.comp) {
                    this.patchValues();
                } else {
                    this.getFilterByTab('week');
                }

            }
        }



        this.buForm = this.formBuilder.group({
            buid: [''],
        });


    }

    getAllDsList(st, end) {
        
        let obj = {
            "company": this.selectedBuId,
            "audType": this.audTyp,
            "from": st,
            "to": end,
            "isshop": this.isShopFlag,
            "launchedFlag": this.campaignType,
        }
        this.segmentationService.getAllData(obj).subscribe(data => {
            if (data.status) {
                if(this.dsID==0){
                    this.getDSList = data.data.rows;
                }
            
            } else {
                this.getDSList = [];
            }
            this.ngxSpinnerService.hide();
        }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
        });
    }

    // ##########  Get BU Company List  #####################  //

    getCompanyList() {

        this.clientService.getBUCompanies().subscribe(data => {
            if (data.status) {

                this.getCompanyobj = data.data;

                if (this.paramslist.comp) {

                    this.buForm.get('buid').patchValue(this.paramslist.comp);
                    this.selectedBuId = this.buForm.value.buid;
                    this.patchValues();
                }

            } else {
                this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
        }, error => {
            this.toastrService.error(error);
            this.getCompanyobj = [];
            this.ngxSpinnerService.hide();
        });
    }

    patchValues() {

        if (this.paramslist.shop === true) {
            this.form.get('campType').patchValue(this.paramslist.launchedFlag);
            this.campaignType = this.paramslist.launchedFlag;
            this.isShopFlag = true;
        }

        this.selectedIndex = this.paramslist.indx;
        // this.page = this.paramslist.pg;
        // this.skip = this.page - 1;
        if (this.paramslist.indx === 'custom') {
            let fr = this.paramslist.from.split('-');
            let t = this.paramslist.to.split('-');
            this.form.get('startdate').patchValue(new Date(fr[0], fr[1] - 1, fr[2], 0, 0, 0));
            this.form.get('enddate').patchValue(new Date(t[0], t[1] - 1, t[2], 0, 0, 0));
            // this.fetchRecords();
        } else {
            this.selectedIndex = this.paramslist.indx;
            this.form.get('startdate').patchValue(null);
            this.form.get('enddate').patchValue(null);
            //  this.getDatevalues(this.paramslist.indx);
        }
        this.getDataOnPageChange(this.paramslist.pg);
    }

    getFilterByTab(val) {

        this.selectedIndex = val;
        this.skip = 0;
        this.list = [];
        this.totalRecords = 0;
        this.page = 1;
        this.checkedList = [];
        this.isAllChecked = false;
        this.startDate = '';
        this.endDate = '';
        this.form.get('startdate').patchValue(null);
        this.form.get('enddate').patchValue(null);
        this.getDatevalues(val);

    }

    getDatevalues(val) {

        if (val === 'custom') {
            this.getData(this.startDate, this.endDate);
            // this.getAllDsList(this.startDate, this.endDate);
            return;
        }
        if (val === 'day') {

            const offset = new Date().getTimezoneOffset();
            let dt = new Date(new Date().getTime() - (offset * 60 * 1000));
            let dt1 = new Date(new Date().getTime() - (offset * 60 * 1000));
            let stdt = dt.toISOString().split('T')[0];
            let enddt = dt.toISOString().split('T')[0];

            this.startDate = stdt;
            this.endDate = enddt;
            this.getData(stdt, enddt);


        }
        if (val === 'week') {
      
            const offset = new Date().getTimezoneOffset();
            var todayDate = new Date();
            var toDate = new Date(new Date().getTime() - (offset * 60 * 1000));
            var weekDay = todayDate.getDay() - 1;
            var weekEnd = 7 - todayDate.getDay();
            todayDate.setDate(todayDate.getDate() - weekDay);
            toDate.setDate(toDate.getDate() + weekEnd);
            let stdt = todayDate.toISOString().split('T')[0];
            let enddt = toDate.toISOString().split('T')[0];

            this.startDate = stdt;
            this.endDate = enddt;
            this.getData(stdt, enddt);
        }
        if (val === 'month') {
            const offset = new Date().getTimezoneOffset();


            let dt = new Date(new Date().getTime() - (offset * 60 * 1000));


            let dt1 = new Date(new Date().getTime() - (offset * 60 * 1000) + 30 * 24 * 60 * 60 * 1000);
            let stdt = dt.toISOString().split('T')[0];
            let enddt = dt1.toISOString().split('T')[0];

            this.startDate = stdt;
            this.endDate = enddt;

            this.getData(stdt, enddt);
            // this.getAllDsList(stdt, enddt);
        }
    }

    getData(st, end) {

        this.getAllDsList(st, end);

        const offset = new Date().getTimezoneOffset();


        let date = new Date(new Date().getTime() - (offset * 60 * 1000));

        if (this.campaignType == 0) {
            this.isExport = true;
        } else if (this.campaignType == 1) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }
        let today = date.toISOString().split('T')[0];
        this.ngxSpinnerService.show();
        let obj = {
            "company": this.selectedBuId,
            "audType": this.audTyp,

            "limit": this.limit,
            "offset": this.limit * this.skip,

            "from": st,
            "to": end,
            "isshop": this.isShopFlag,
            "launchedFlag": this.campaignType,
            "dsid": this.dsID,
        }
        this.segmentationService.getCrossSellReplenishmentAudList(obj).subscribe(data => {


            if (data.status) {


                if (data.data.rows == null) { this.list = [] }
                else {
                    this.list = data.data.rows;
                    this.list.forEach(element => {


                        element.isSelected = false;
                        element.contactStartDate = element.journey_start_date.substring(0, 10);
                        const date1: Date = new Date(today);
                        const date2: Date = new Date(element.journey_end_date.substring(0, 10));
                        if (date2.getTime() > date1.getTime()) {
                            element.checkBoxDisable = false;
                        } else {
                           element.checkBoxDisable = true;
                        }
                    });
                }
                this.totalRecords = data.data.total;
            } else {
                //this.toastrService.error(data.message);
                this.list = [];
                this.totalRecords = 0;
            }
            this.ngxSpinnerService.hide();
        }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
        });
    }

    selectedCampType(value) {
        if (value == 1) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }

        this.checkedList = [];
        this.list = [];
        this.isAllChecked = false;

        if (value == 0) {
            this.isShopFlag = false;
            if ((this.selectedIndex == 'day') || (this.selectedIndex == 'month')) {
                this.selectedIndex = 'week';
            }
        } else {
            this.isShopFlag = true;
        }

        this.getDatevalues(this.selectedIndex);
    }


    getDataOnPageChange(p) {
        this.page = p;
        this.skip = this.page - 1;
        this.checkedList = [];
        this.isAllChecked = false;
        if (this.selectedIndex === 'custom') {
            this.fetchRecords();
        } else {
            this.getDatevalues(this.selectedIndex);
        }
    }

    fetchRecords() {

        this.startDate = '';
        this.endDate = '';
        this.list = [];
        this.checkedList = [];
        this.isAllChecked = false;

        if (!this.form.value.startdate) {
            this.toastrService.error('Please provide correct Start Date value');
            return;
        }
        if (!this.form.value.enddate) {
            this.toastrService.error('Please provide correct End Date value');
            return;
        }
        if (this.form.value.startdate.getTime() > this.form.value.enddate.getTime()) {
            this.toastrService.error('End Date should be equal to or greater than Start Date');
            return;
        }
        const offset = new Date().getTimezoneOffset();
        let dt = new Date(this.form.value.startdate.getTime() - (offset * 60 * 1000));
        let dt1 = new Date(this.form.value.enddate.getTime() - (offset * 60 * 1000));
        let stdt = dt.toISOString().split('T')[0];
        let enddt = dt1.toISOString().split('T')[0];

        this.startDate = stdt;
        this.endDate = enddt;
        this.getData(stdt, enddt);
    }


    filterByDs(id) {
        this.checkedList = [];
        this.dsID = id;
        this.form.get('dsid').patchValue(id);

        if (this.selectedIndex != 'custom') {
            console.log("getFilterByTab");
            this.getFilterByTab(this.selectedIndex);
        } else {
            this.getData(this.startDate, this.endDate);
        }

    }

    /*  ON BU SELECTED FROM DROPDOWN  */
    onBuSelected() {
        // this.ngxSpinnerService.show();
        this.selectedBuId = this.buForm.value.buid;
        this.skip = 0;
        this.page = 1;
        this.list = [];
        this.totalRecords = 0;
        this.selectedIndex = 'week';
        this.checkedList = [];
        this.isAllChecked = false;

        this.startDate = '';
        this.endDate = '';
        this.form.get('campType').patchValue(0);


        if (this.selectedBuId) {
            this.getFilterByTab('week');
        }
    }

    getCustomers(obj) {
        let count = parseInt(obj.customer, 10);
        if (count === 0) {
            return;
        } else {
            let URL = '/corporate/customercountlist?company=' + this.selectedBuId + '&prodId=' + obj.id + '&from='
                + this.startDate + '&to=' + this.endDate + '&audType=' + this.audTyp + '&navtype=' +
                'cross' + '&dtyp=' + 'customer' + '&isshop=' + this.isShopFlag + '&indx=' + this.selectedIndex + '&page=' + this.page + '&contactStartDate=' + obj.contactStartDate + '&dsid=' + this.dsID + '&launchedFlag=' + this.campaignType;


            this.router.navigateByUrl(URL);
        }
    }

    getDSCustomers(obj) {
        let count = parseInt(obj.ds_count, 10);
        if (count === 0) {
            return;
        } else {

            let URL = '/corporate/customercountlist?company=' + this.selectedBuId + '&prodId=' + obj.id + '&from='
                + this.startDate + '&to=' + this.endDate + '&audType=' + this.audTyp + '&navtype=' + 'cross' + '&dtyp=' + 'ds' + '&isshop=' + this.isShopFlag + '&indx=' + this.selectedIndex + '&page=' + this.page + '&contactStartDate=' + obj.contactStartDate + '&launchedFlag=' + this.campaignType;


             this.router.navigateByUrl(URL);
        }
    }

    onSelectUnselect() {
        if (this.list == null) {
            return false;
        }
        return (
            this.list.filter((t) => t.isSelected).length > 0 && !this.isAllChecked
        );
    }


    checkUncheckAll(isSelected: boolean) {
        const offset = new Date().getTimezoneOffset();


        let date = new Date(new Date().getTime() - (offset * 60 * 1000));


        let today = date.toISOString().split('T')[0];
        this.isAllChecked = isSelected;
        if (this.list == null) {
            return;


        }

        for (let i = 0; i < this.list.length; i++) {
            const date1: Date = new Date(today);
            const date2: Date = new Date(this.list[i].journey_start_date.substring(0, 10));
            if (date2.getTime() > date1.getTime()) {
                this.list[i].isSelected = isSelected
            } else {
                this.list[i].isSelected = false;
            }
        }

        /*
            this.list.forEach((t) => (t.isSelected = isSelected ));
        */

        this.getCheckedItemList()

    }

    getCheckedItemList() {
        this.checkedList = [];
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].isSelected) {
                this.checkedList.push(this.list[i]);
            }
        }
        console.log('checkedList data', this.checkedList);
    }

    isAllSelected() {
        this.isAllChecked = this.list.every(function (item: any) { return item.isSelected == true })
        this.getCheckedItemList();
    }


    launchCampaign() {
        let productId = [];
        let selectedIds = [];
        let ai_type;
        if (this.checkedList.length > 0) {
            for (let item of this.checkedList) {
                ai_type = item.ai_type;
                productId.push(item.id);
                selectedIds.push(item.aid);
            }

        }

        let obj = {
            "company": this.selectedBuId,
            "product_id": productId,
            "ai_type": ai_type,
            "selectedids": selectedIds,
            "dsid": this.dsID,
            "from": this.startDate,
            "to": this.endDate,
        }

        // console.log('OBJ', obj)

        // return false;
        this.ngxSpinnerService.show();
        this.segmentationService.launchCrossSellRepCampaign(obj).subscribe(data => {
            if (data.status) {
                this.checkedList = [];
                this.isAllChecked = false;
                if (this.selectedIndex === 'custom') {
                    this.fetchRecords();
                } else {
                    this.getDatevalues(this.selectedIndex);
                }
                setTimeout(() => {
                    this.toastrService.success('Campaign Launched Succesfully');
                }, 1000)
            } else {
                this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
        }, error => {
            this.toastrService.error(error);
            this.ngxSpinnerService.hide();
        });
    }

    exportFile() {

        this.ngxSpinnerService.show();
        if (this.selectedBuId != 0) {
            let data = {
                "tableName": "crosssell-campaign-" + this.startDate + '-' + this.endDate,

                "cid": this.selectedBuId,
                "from": this.startDate,
                "to": this.endDate,
                "audType": this.audTyp,

                "isshop": this.isShopFlag,
                "launchedFlag": this.campaignType,
                "dsid": this.dsID,
                "productId": 0,
            }

            // console.log("data", data);
            // return false;

            this.master.exportCustomData(data).subscribe(dataFile => {

                if (dataFile.status) {
                    let selBox = document.createElement('a');
                    var att = document.createAttribute("href");
                    att.value = dataFile.file;
                    var att1 = document.createAttribute("download");
                    selBox.setAttributeNode(att);
                    selBox.setAttributeNode(att1);
                    document.body.appendChild(selBox);
                    selBox.click();


                    this.ngxSpinnerService.hide();

                }
            }, err => {
                this.toastrService.error(err.message);
            });
        } else {
            this.toastrService.error('Please Select The Company!');
        }

    }
}