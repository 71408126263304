import { Component, OnInit } from '@angular/core';
import {ChatService} from "../services/chat/chat.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-secondadmin',
  templateUrl: './secondadmin.component.html',
  styleUrls: ['./secondadmin.component.css']
})
export class SecondadminComponent implements OnInit {

  messageNotification$:any;
  status$:any;
  subject$:any;
  disconnect$:any;

  constructor( private chatService:ChatService, private toastrService:ToastrService,) { 
    this.subject$=this.chatService.init().subscribe((value)=>{

      this.status$= chatService.status.subscribe((value)=>{
        //this.toastrService.success(value.user_name+' is '+value.status_name);
      });
      this.disconnect$=this.chatService.disconnect.subscribe(()=>{
            this.unsubscribe();
      })
      this.messageNotification$=chatService.messageNotification.subscribe((value)=>{


       // console.log('****messageNotification********',value);
        this.toastrService.success(value.user_name+': '+ (value.content.toString().length>50


          ? value.content.substring(0,47).concat('...')
          : value.content));
      });
   //   console.log('*****socket created from login***',value);


    });

  }

  ngOnInit() {
    
  }
  

  ngOnDestroy(): void {
 //   console.log('*******FirstadminComponent***ngOnDestroy**');
       this.unsubscribe();
  }
 
  unsubscribe(){
    if (this.subject$) {
    this.subject$.unsubscribe();
    }
    if (this.messageNotification$) this.messageNotification$.unsubscribe();
    if (this.status$) this.status$.unsubscribe();
    if (this.disconnect$) this.disconnect$.unsubscribe();
    this.chatService.close();
    
  }
}
