
<!-- Breadcrumb for application -->
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/corporate/pi">PII & DSAR</a></li>
       <li class="breadcrumb-item active" aria-current="page">Individual Profile</li>
      </ol>
    </nav>
</div>
<!-- Container for application -->
<div *ngIf="!showProfile" class=" col-md-12 container mt-2">
  <div class="rowTheme">
    <div class="themContain row">

    <div class="col-md-4">
        <label for="exampleForm2">First Name</label>
        <input type="text" class="form-control" [(ngModel)]="fname" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-4">
        <label for="exampleForm2">Last Name</label>
        <input type="text" class="form-control" [(ngModel)]="lname" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-4 ">
        <label for="exampleForm2">Email Address</label>
        <input type="text" class="form-control" [(ngModel)]="email" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-4">
        <label for="exampleForm2">Phone No</label>
        <input type="text" class="form-control" [(ngModel)]="phone" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-4">
        <label for="exampleForm2">Address</label>
        <input type="text" class="form-control" [(ngModel)]="address" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-2">
        <label for="exampleForm2">City</label>
        <input type="text" class="form-control" [(ngModel)]="city" class="form-control form-control-md">
      </div>
      <div class="mt-1 col-md-2">
        <label for="exampleForm2">State</label>
        <input type="text" class="form-control" [(ngModel)]="state" class="form-control form-control-md">
      </div>
      <div class="my_class text-center mt-3 mb-3 col-md-12">
        <button type="button" class="custbtn mt-2" (click)=searchtIndividualProfileData()>Search</button>
        <button type="button" class="custbtn mt-2" (click)="ResetFormData()">Reset</button>
      </div>
  </div>
</div>
</div>

  <div  *ngIf="!showProfile" class="container mt-2 themeTblReso">


    <div class="tblPrt" *ngIf="(allProfileDataObj && allProfileDataObj.length>0) || isDataEmpty">

      <h4 class="mt-1">Individual Profile</h4>

        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">

        <tr>

          <th>First Name </th>
          <th>Last Name </th>
          <th>Email</th>
          <th>Phone</th>
          <th>DS Name</th>
          <th>User Type</th>
          <th>Created On</th>
          <th>View</th>


        </tr>
        <tr *ngFor="let item of allProfileDataObj" >
            <td >{{item.first_name}}</td>
            <td >{{item.last_name}}</td>
            <td >{{item.email}}</td>
            <td >{{item.phone}}</td>
            <td >{{item.ds_name}}</td>
            <td >
              <ng-template [ngIf]="item.source_id==1">Customer/Prospect</ng-template>
              <ng-template [ngIf]="item.source_id==2">Direct Seller</ng-template>
              <ng-template [ngIf]="item.source_id==3">Company Contact</ng-template>
            </td>
            <td ><div style="min-width: 100px;display: inline-block;">{{item.createdon | date }}</div></td>
            <td  >
             <a (click)="viewProfile(item.pii_individual_id)">View</a>
          </td>
        </tr>
        <tr *ngIf="isDataEmpty">
          <td colspan='8'>
            <p class='text-center'>No Records Available</p>
          </td>
        </tr>
        </table>


        <!-- <ngb-pagination *ngIf="totalCount>10" [collectionSize]="totalCount" (pageChange)="searchtIndividualProfileDataPaging(page=$event)" [(page)]="page" [maxSize]="2"
        [boundaryLinks]="true">
      </ngb-pagination> -->


      <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="searchtIndividualProfileDataPaging(page=$event)" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>

    </div>
</div>

<app-bu-report [profileID]="profileID" *ngIf="showProfile"  (messageToEmit)="backToSearchPage($event)"></app-bu-report>

