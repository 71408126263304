<div class="container">
  <div class="row">
    <div class="col">
      <h4 class="font-weight-bold ml-2 mb-3" *ngIf="clientDataSingle_g">Company Name : <span>
          {{clientDataSingle_g.customer_company_name}}</span></h4>
      <div class="card box-shadow-1">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-4">
              <label>Company Name:</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p *ngIf="clientDataSingle_g" class="card-text">{{clientDataSingle_g.customer_company_name}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 ">
              <label>Company Address:</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p *ngIf="clientDataSingle_g" class="card-text"> {{clientDataSingle_g.customer_address1}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 ">
              <label>Primary Contact Name:</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p class="card-text" *ngIf="clientDataSingle_g">{{clientDataSingle_g.customer_contact_fname}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 ">
              <label>Primary Contact Number:</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p class="card-text" *ngIf="clientDataSingle_g">{{clientDataSingle_g.customer_phone}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 ">
              <label>Primary Contact Email:</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p class="card-text" *ngIf="clientDataSingle_g"> {{clientDataSingle_g.customer_contact_email}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 ">
              <label>Website</label>
            </div>
            <div class="col-12 col-sm-8 mb-1 mb-sm-0">
              <p class="card-text" *ngIf="clientDataSingle_g">
                <a href="{{clientDataSingle_g.customer_website}}" target="_blank">{{clientDataSingle_g.customer_website}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>