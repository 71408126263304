import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base.component';
import {SegmentationService} from '../../../../services/segmentation/segmentation.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-attribute-group',
  templateUrl: './attribute-group.component.html',
  styleUrls: ['./attribute-group.component.css']
})
export class AttributeGroupComponent extends BaseComponent implements OnInit{
  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  totalCount: number = 0;
  attrList = [];
  ord = 'desc';
  colname = "createdon";
  limit = 10;
  offset = 0;
  page = 1;
  constructor(private segmentService: SegmentationService) {
    super();
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getList();
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.offset = 0;
          this.page = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data =>  this.segmentService.getAttributeGroupList(this.limit, this.offset, this.colname, this.ord, this.searchText.nativeElement.value))
      ).subscribe(data => {
        if (data.status) {
          if (data.data.rows == null) { this.attrList = [] }
          else { this.attrList = data.data.rows }
          this.totalCount = data.data.total;
        } else {
          this.attrList = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  //  ###############  Get Attributes Group List  #######  //

  getList(){
    let searchTerm = this.searchText.nativeElement.value;
    this.offset = (this.page - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.segmentService.getAttributeGroupList(this.limit, this.offset, this.colname, this.ord, searchTerm).subscribe(data => {
      if (data.status) {
        if (data.data.rows == null) { this.attrList = [] }
        else { this.attrList = data.data.rows }
        this.totalCount = data.data.total;
      } else {
        this.attrList = [];
        this.totalCount = 0;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //   ##############  Set Order ########################  //

  sorting(col) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getList()
  }

  //   ##############  Edit Attribute Group  ############  //

  editAttribute(id){
    let URL = '/corporate/new-attribute-group';


    this.router.navigate([URL], { queryParams: {'attrId': id} });

    
  }

  //  ##############  Delete Attribute Group ############  //

  deleteAttribute(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to delete the Attribute Group'  + '??', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          // console.log(userObject)
          this.ngxSpinnerService.show();
          this.segmentService.deleteAttributeGroup(id).subscribe(data => {
              if (data.status) {
                this.toastrService.success('Group Deleted Successfully');
                this.getList();
              } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
              }
            },
            error => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ############# Change Attribute Group Status #############  //

  updateAttributeStatus(event, obj){
    event.preventDefault();

    let stat = 'InActive';
    if (!obj.isactive) {
      stat = 'Active';
    }

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To ' + stat + '??', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          const userObject = {
            groupname: obj.group_name,
            groupdesc: obj.group_desc,
            status: !obj.isactive
          };
          // console.log(userObject)
          this.ngxSpinnerService.show();
          this.segmentService.updateAttributeGroupFields(userObject, obj.field_group_id).subscribe(data => {
              if (data.status) {
                this.toastrService.success('Status Changed Successfully');
                this.getList();
              } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
              }
            },
            error => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
     // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }
}
