<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/audience">Audience</a>
      </li>


      <li *ngIf="!audience_id" class="breadcrumb-item active" aria-current="page">
        Add New Audience
      </li>
      <li *ngIf="audience_id" class="breadcrumb-item active" aria-current="page">
        Edit Audience
      </li>
      
    </ol>
  </nav>
</div>
<div class="container mt-2">
  <form class="form-horizontal mt-1" [formGroup]="audienceForm">
      <div class="row">
          <div class="col-md-10 col-xl-7">

          
          <div class="card">
              <div class="card-body">
           

              <div class="mt-1">
                  <label for="exampleForm2"> Name  <span style="color:red">*</span></label>
                  <input type="text" id="exampleForm2" formControlName="audience_name"
                      class="form-control form-control-md" required
                      [ngClass]="{ 'is-invalid': submitted && f.audience_name.errors }" maxlength="80" appSpace >
                  <div *ngIf="submitted && f.audience_name.errors" class="invalid-feedback">
                      <div *ngIf="f.audience_name.errors.required || f.audience_name.hasError('whitespace')"> Name is required</div>
                       <div *ngIf="f.audience_name.errors?.maxlength">You can enter only 80 charachters</div>
              
                
                  </div>
              </div>
              <div class="mt-1">
                  <label for="exampleForm2"> Description</label>
                  <input type="text"  formControlName="audience_desc"
                      class="form-control form-control-md">
              </div>
              

              
          </div>
          <div class="card-footer">
              <div class="text-center my_class " *ngIf="isWrite" [ngClass]="actionStatus ? 'displayblock' : 'displaynone'">
                  <button type="button" class="custbtn" (click)="addAudience()">Submit</button>
              </div>
              <div class="text-center my_class " *ngIf="isEdit" [ngClass]="actionStatus ? 'displaynone' :'displayblock'  ">
                  <button type="button" class="custbtn" (click)="UpdateAudience()">Update</button>
              </div>
          </div>
          </div>
      </div>
      </div>
  </form>
</div>