<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/usertypes">Users</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Admin List</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6 mb-md-0 mb-1" *ngIf="customerID == 0">
          <select name="company" class="form-control formstyle" [(ngModel)]="companyId" (change)="getData()">
            <option value="">Select Company</option>
            <option value="0">marGo</option>
            <option *ngFor="let userObj of getCompanyobj" value="{{ userObj.customer_id }}">
              {{ userObj.customer_company_name }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-md-0 mb-1">
          <select name="status" class="form-control formstyle" (change)="changeStatus()" [(ngModel)]="status"
           [disabled]="companyId == '' ">
            <option [ngValue]="3">All User</option>
            <option [ngValue]="1">Active User</option>
            <option [ngValue]="2">Inactive User</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="row">
        <div class="col-md-8 mb-md-0 mb-1">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                  aria-hidden="true"></i></span>
            </div>

            <input class="form-control" type="text" placeholder="Search" 
            [disabled]="companyId == '' " aria-label="Search" #searchText />
          </div>
        </div>
        <div class="col-md-2" style="justify-content: right">
          <input type="button" value="Add New" class="custbtn mt-md-1 mt-2 ml-0" routerLink="/corporate/newuser" />
        </div>
      </div>
    </div>

    <div class="col-12 tblPrt">
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
        <tr>
          <th (click)="sorting('admin_fname')" style="cursor: pointer">
            Name <i class="fa fa-sort text-black" aria-hidden="true"></i>
          </th>
          <th (click)="sorting('admin_email')" style="cursor: pointer">
            Email <i class="fa fa-sort text-black" aria-hidden="true"></i>
          </th>
          <th>Username</th>

          <th>Roles</th>
          <th>Company</th>
          <th (click)="sorting('createdon')" style="cursor: pointer">
            CreatedOn <i class="fa fa-sort text-black" aria-hidden="true"></i>
          </th>

          <th *ngIf="isEdit || isDelete">Actions</th>
        </tr>

        <tr *ngFor="let obj of userObj">
          <td>
            {{ obj.admin_fname + " " + obj.admin_lname }}
          </td>
          <td>{{ obj.admin_email }}</td>
          <td>{{ obj.admin_username }}</td>
          <td>{{ obj.role_name }}</td>
          <td>
            {{
            obj.customer_company_name ? obj.customer_company_name : "marGo"
            }}
          </td>
          <td>{{ obj.createdon | date }}</td>
          <td data-title="Action" *ngIf="isEdit || isDelete">
            <div class="actBtns">
              <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editUser(obj.uid)" placement="bottom"
                ngbTooltip="Edit"></i>
              <i class="fa fa-trash mr-1 linkForce" *ngIf="isDelete" (click)="deleteUser(obj.uid)"></i>
              <mat-slide-toggle [checked]="obj.isactive" *ngIf="isEdit" class="my-slider"
                (click)="updateStatus($event, obj.uid)">
              </mat-slide-toggle>
            </div>
          </td>
        </tr>
      </table>
      <div *ngIf="userObj.length === 0" style="text-align: center">
        <p>No Result Available</p>
      </div>
      <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
          (pageChange)="getData((page = $event))" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>