<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Prospect type</li>
    </ol>
  </nav>
</div> 


<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="text">
      </div>

    </div>
    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mr-1" routerLink="/corporate/addnewprospect">
      <button class="csvBtn" *ngIf="isImport" (click)="importFile()">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" *ngIf="isExport" (click)="exportFile()">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>

  </div>
  <div class="tblPrt">

    <table class="mt-1 bdrStCustom" width="" cellpadding="0" cellspacing="0">
      <tr>
        <!-- <th>Pid</th> -->
        <th>Prospecttype icon</th>
        <th (click)="chgord('x')" class="cursor"> Prospecttype Name <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        <th>Prospecttype description</th>
        
        <th>CreatedBy </th>
        <th (click)="chgord('x')" class="cursor"> CreatedOn <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></th>
        
        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr
        *ngFor="let obj of prospectObj  | filter:text | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp }">
        <!-- <td>{{obj.get_master_prospect.pid}}</td> -->
        <td><img [src]="obj.get_master_prospect.picon" alt="Not Found" (error)="setValue($event)" style="height:50px;"></td>
        <td>{{obj.get_master_prospect.pname}}</td>
        <td>{{obj.get_master_prospect.pdescription}}</td>
        <!-- <td>{{obj.get_master_prospect.picon}}</td> -->
        
        <td>{{obj.get_master_prospect.createdname}}</td>
        <td>{{obj.get_master_prospect.createdon  | date:'medium'}}</td>
        
        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1 cursor" *ngIf="isEdit" (click)="editProsPectty(obj.get_master_prospect.pid)"  placement="bottom" ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1 cursor" *ngIf="isDelete" (click)="deleteProspect(obj.get_master_prospect.pid)" placement="bottom" ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider cursor" [checked]="obj.get_master_prospect.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.get_master_prospect.pid)">
            </mat-slide-toggle>
          </div>

        </td>
      </tr>

  </table>
  <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
</div>
</div>