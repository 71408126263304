<style>
  .my-pagination /deep/ .pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Theme Master</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText
        [disabled]="companyID == 0 ">
      </div>

    </div>


    <div class="col-md-4" style="justify-content: right;">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn ml-0 mt-md-1" routerLink="/corporate/newtheme">
      <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>
  </div>

  <div class="row"><div class="col-md-6 mt-2" *ngIf="disCompId === 0">
    <div>

      <select name="company" class="form-control formstyle"  (change)="getCompanyThemes($event.target.value)">
        <option value="0">Select Company Name</option>
        <ng-container *ngFor="let userObj of getCompanyobj" >
          <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{userObj.customer_company_name}}
          </option>
        </ng-container>
      </select>

  </div>

  </div>
  <div class="col-md-6 mt-2" *ngIf='companyID!=0'>
    <div>

      <select name="company" class="form-control formstyle" (change)="getDomainList($event.target.value)">
        <option value="">Select Business Domain</option>
        <ng-container *ngFor="let userObj of domainData" >
          <option *ngIf="userObj.is_active == true" value={{userObj.id}}>
              {{userObj.domain_name}}
          </option>
        </ng-container>
      </select>

  </div>

  </div></div>
</div>
<div class="container">
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
            <tr>
        <!-- <th>Theme Id</th> -->
        <th>Theme Screenshot</th>
        <th (click)="sorting('theme_name')" style="cursor: pointer;">Theme Name <i
            class=" fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Theme Journey </th>
        <th>Business Domain</th>
        <th (click)="sorting('createdon')" style="cursor: pointer;"> CreatedOn <i
            class=" fa fa-sort text-black" aria-hidden="true"></i></th>

        <th *ngIf="isEdit || isDelete">Actions</th>
      </tr>

      <tr
        *ngFor="let obj of themeObj">
        <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->
        <td><img [src]="obj.theme_screenshot" alt="Not Found" (error)="setValue($event)"
            style="height:50px;"></td>
            
        <td>{{obj.theme_name}}</td>
        <td>{{obj?.journey_name}}</td>
        <td [innerText]="checkDomain(obj.theme_category_id)"></td>
        <td>{{obj.createdon | date:'mediumDate' }}</td>

        <td *ngIf="isEdit || isDelete">
          <div class="actBtn">
            <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editTheme(obj.theme_id)" placement="bottom"
              ngbTooltip="Edit"></i>
            <span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteTheme(obj.theme_id)" placement="bottom"
              ngbTooltip="Delete"></span>
            <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
              (click)="updateStatus($event,obj.theme_id)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>

    </table>
    <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->

    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getThemeData((cp=$event))" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>

    <div style="text-align: center;" *ngIf="totalCount == 0">
      <p>No Result Available</p>
    </div>

    <!-- <pagination-controls *ngIf="(themeObj | filter:text).length !== 0 && (themeObj | filter:text).length !== 1" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->
  </div>
</div>

