<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/webinar">Webinar Module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Faq Category</li>
    </ol>
  </nav>
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">
    <div class="col-md-4" *ngIf="companyID==0">
      <div>
        <select name="company" class="form-control formstyle" (change)="getCategory()" [(ngModel)]="selectedCompany">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>
        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText/>
      </div>
    </div>

    <div class="col-md-4 mt-md-0 mt-2" style="justify-content: right">
      <input type="button" value="Add New" class="custbtn mt-md-1 mt-1 ml-0" routerLink="/corporate/add-faq-category" />
    </div>
  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th (click)="setOrder('name')" style="cursor: pointer">Category Name <i class="fa fa-sort text-black" aria-hidden="true"></i></th>
        <th>Category Description</th>
        <th (click)="setOrder('created_on')" style="cursor: pointer">
          Created On <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Actions</th>
      </tr>
      <tr *ngFor="let category of categoryObj">
        <td>{{category?.name}}</td>
        <td>{{category?.description}}</td>
        <td>{{category?.created_on | date: "mediumDate"}}</td>
        <td>
          <div class="actBtn">
            <i *ngIf="isEdit"  class="fa fa-edit mr-1 linkForce" (click)="editPost(category.id)"></i>
            <i *ngIf="isEdit" class="fa fa-trash mr-1 linkForce"
              (click)="delCategory($event, category.id)"></i>
            <mat-slide-toggle *ngIf="isEdit" class="my-slider" [checked]="category.is_active"
              (click)="updateStatus($event, category.id, category.is_active)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="resCatTotal == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="resCatTotal > 10">
      <ngb-pagination [collectionSize]="resCatTotal" [(page)]="slCatPage" [maxSize]="2" [rotate]="true" (pageChange)="getCategory(slCatPage=$event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
