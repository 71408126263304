import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-create-media-sub',
  templateUrl: './create-media-sub.component.html',
  styleUrls: ['./create-media-sub.component.css']
})
export class CreateMediaSubComponent extends BaseComponent implements OnInit {

  customer_id;
  addMediaSubscriptionForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    this.addMediaSubscriptionForm = this.formBuilder.group({
      media_name: ['', [Validators.required, Validators.maxLength(50)]],
      point: ['',[Validators.required]],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f() { return this.addMediaSubscriptionForm.controls; }
  addNewSubscriptionData(){
    this.submitted = true;
    if (this.addMediaSubscriptionForm.invalid) {
      return;
    }
    let { selectedCompany } = this.addMediaSubscriptionForm.value;
    this.masterService.addSubscriptionMedia(this.addMediaSubscriptionForm.value,selectedCompany).subscribe(data=>{
      if (data.status) {
        this.toastrService.success("Subscription media added successfully");
        this.router.navigateByUrl(`/corporate/subscription-media-list`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.toastrService.error(error.message);
    });
  }

}
