import { Component, OnInit, isDevMode } from '@angular/core';
import { throwError,BehaviorSubject } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-einstein-dashboard-reports',
  templateUrl: './einstein-dashboard-reports.html',
  styleUrls: ['./einstein-dashboard-reports.css']
})
export class EinsteinDashboardReportsComponent implements OnInit {

  constructor(
    public router: Router,
    private deviceService: DeviceDetectorService
  ) { 
   
  }
 
 
  ngOnInit() {

   
   
  }
  openreport(type) {
  
    const isMobile = this.deviceService.isMobile();


    if(type==1) {
      if(isMobile==true)
     { localStorage.setItem("selectedDashboard","0FK7X000000CgrKWAS"); }
      //else localStorage.setItem("selectedDashboard","0FK4F000000CgVEWA0")
      else { localStorage.setItem("selectedDashboard","0FK7X000000CgqqWAC"); }

      console.log("111111111111111...................");

      localStorage.setItem("DataSetName","Dataset_Premium_Services_Summary");
      

    }
    else if(type==2) {

      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FK7X000000CgrUWAS");
      else localStorage.setItem("selectedDashboard","0FK7X000000Cgp4WAC")

      console.log("222222222222222222...................");
      localStorage.setItem("DataSetName","Dataset_Ecommerce_Details");
 

    }
    else if(type==3) {
      
      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FK7X000000CgrPWAS");
      else localStorage.setItem("selectedDashboard","0FK7X000000CgrAWAS")

      localStorage.setItem("DataSetName","Dataset_Customer_Subscription");
      console.log("333333333333333333...................");
 

    }
    else if(type==4) {
      
      if(isMobile==true)
      localStorage.setItem("selectedDashboard","0FK7X000000CgrZWAS");
      else localStorage.setItem("selectedDashboard","0FK7X000000Cgr5WAC")


      console.log("5555555555555555555...................");
      localStorage.setItem("DataSetName","Dataset_Contact_Optin_Out");
 

    }
    else  {
      
       localStorage.setItem("selectedDashboard","0FK4F000000CgYDWA0")
       console.log("44444444444444...................");
 

    }
    
    
    
    this.router.navigateByUrl('/corporate/einstein-reports');
   


  }

  ngOnChanges() {

  }

 

}
