import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';
import {SegmentationService} from '../../../../../services/segmentation/segmentation.service';

@Component({
  selector: 'app-update-audience-segmentation',
  templateUrl: './update-audience-bu-segmentation.html',
  styleUrls: ['./update-audience-bu-segmentation.css']
})
// tslint:disable-next-line:component-class-suffix
export class UpdateAudienceBuSegmentation extends BaseComponent implements OnInit {

  buselected = '';
  buid: any;
  show = false;
  masterAudienceActiveList = [];
  buAudienceList = [];
  audienceIds = [];
  constructor(private segmentService: BUSegmentationService, private masterSegmentService: SegmentationService) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }

    this.getMasterActiveAudienceList();
  }

  //  ################  Fetch Active Audience List from Master ######################  //

  getMasterActiveAudienceList() {
    this.ngxSpinnerService.show();
    this.show = false;
    this.masterSegmentService.getMasterActiveAudienceList().subscribe(data => {
      if (data.status) {
        this.masterAudienceActiveList = data.data;
        this.getActiveAudienceForBU();
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/bu-segmentation');
    });
  }

  //  ###########  Get Active Audience for BU ########################  //

  getActiveAudienceForBU(){
    this.segmentService.getAudienceListforBU(this.buid).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.hide();
        this.audienceIds = [];
        this.buAudienceList = data.data;
        if (this.buAudienceList.length > 0) {
          this.buAudienceList.forEach(element => {
            this.audienceIds.push(element.audience_id);
          });
        }
        this.show = true;

      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
        this.router.navigateByUrl('/corporate/bu-segmentation');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.ngxSpinnerService.hide();
      this.router.navigateByUrl('/corporate/bu-segmentation');
    });
  }

  //  ############  Check Audience from List ##################  //

  selectAudience(event, id){
    if (event.target.checked) {
      this.audienceIds.push(id);
    } else {
      this.audienceIds.splice(this.audienceIds.indexOf(id), 1);
    }
  }

  //  ############  Update Audience List    ################## //

  updateAudience(){
    if (this.audienceIds.length === 0) {
      this.toastrService.error('Please select at least one Audience');
      return;
    }
    let obj = {
      'audience_id': this.audienceIds,
      'company': this.buid
    };
    this.ngxSpinnerService.show();
    this.segmentService.updateAudienceForBU(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Audience updated Successfully');
        this.router.navigateByUrl('/corporate/bu-segmentation');

      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

}
