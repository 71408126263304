import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { BaseComponent } from '../../base.component';
import { debounceTime, distinctUntilChanged, ignoreElements, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-module-master',
  templateUrl: './module-master.component.html',
  styleUrls: ['./module-master.component.css']
})
export class ModuleMasterComponent extends BaseComponent implements OnInit, AfterViewInit {
  today: number = Date.now();
  public moduleObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "name";
  public moduleArray: any = [];
  public isCollapsed = true;
  moduleChiledObj;

  title = 'app';
  moduleStatus = false;
  data: any[] = [];
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  constructor(
    private master: MasterService,
    public ImportFilesDialogService: ImportFilesDialogService,
  ) {
    // for (let i = 0; i < 5; i++) {
    //   const user = {
    //     name: 'Siva',
    //     email: 'Siva@gamilc.om',
    //     phone: '00000000',
    //     details: [],
    //   };

    //   for (let j = 0; j < 2; j++) {
    //     const details = {
    //       street: 'Streeet',
    //       zipCode: 'zipcode',
    //       city: 'city',
    //       details: []
    //     };
    //     user.details.push(details);

    //     for (let k = 0; k < 2; k++) {
    //       const details = {
    //         street: 'Streeet',
    //         zipCode: 'zipcode',
    //         city: 'city',
    //         details: []
    //       };
    //       user.details[j].details.push(details);
    //       for (let l = 0; l < 2; l++) {
    //         const details = {
    //           street: 'Streeet',
    //           zipCode: 'zipcode',
    //           city: 'city',
    //         };
    //         user.details[j].details[k].details.push(details);
    //       }
    //     }
    //   }

    //   this.data.push(user);
    //   console.log(this.data);
    // }
    super();
  }
  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }
  ngOnInit() {

    this.getModuleData();
    this.ModuleLogData()
  }

  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.master.getAllModules(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {

        if (res.status) {
          if (res.data.rows == null) { this.moduleObj = [] }
          else { this.moduleObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.moduleObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getModuleData();
  }

  ModuleLogData() {
    let ModuleLogs = {
      log_type: "Module Master View",
      log_name: "Module Master View",
      log_description: "Module Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(ModuleLogs).subscribe(data => {
      // console.log(data);

    }, error => {
      // console.log(error);
    });

  }
  isCollapsedfunction(moduleid, i) {
    // console.log(moduleid, i);
    // this.isCollapsed = !this.isCollapsed;
    let self = this;
    let body = {
      "pid": moduleid,
      "limit": 250,
      "skip": 0
    }
    this.master.getModule(body).subscribe(data => {
      //  console.log(data);
      if (moduleid == data[0].get_master_module_fun.module_parent_id) {
        this.isCollapsed = false;
        // self.moduleChiledObj = data;
        // self.moduleStatus = true;
      }

    }, error => {
      // console.log(error);
    });
    //  console.log(this.isCollapsed);
  }
  getModuleData() {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getAllModules(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      self.moduleObj = response.data.rows;
      self.totalCount = response.data.total;
      self.moduleStatus = true;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }


  getChildModuleData(id:any) {
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    // let id = ;
    this.master.getChildModules(10, 0, this.colname, this.ord, this.searchText.nativeElement.value, id).subscribe(response => {
      self.moduleChiledObj = response.data.rows;
      // self.totalCount2 = response.data.total;
      self.moduleStatus = true;
      this.ngxSpinnerService.hide();
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  receiveMessage(event) {
    // console.log("module", event);
    if (event._id && event.name === "delete") {
      //  console.log("delete", event)
      this.toastrService.error("You dont't have permission to delete this Module.");
    } else if (event._id && event.name === "edit") {
      // console.log("edit", event);
      this.editModule(event._id);
    } else if (event._id && event.name === "active") {
      // console.log("active", event)
      this.activeStatus(event._id, event.row_obj);
    }
  }

  editModule(id) {
    this.router.navigate(['/corporate/addnewmodule'], { queryParams: { moduleid: id } });
  }

  getDataFromId(id) {
    var obj = [];
    for (var i = 0; i < this.moduleArray.length; i++) {
      //  console.log(this.moduleArray[i].get_master_module_fun.module_parent_id, id);
      if (this.moduleArray[i].get_master_module_fun.module_parent_id == id) {
        obj.push(this.moduleArray[i]);
      }
    }

    return obj;
  }

  deleteModule(id) {
    //Logic to delete the item
    let obj =
    {
      id: id
    }
    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.delModule(obj).subscribe(data => {
            // console.log(data);
            if (data.status) {
              this.getModuleData();
              this.toastrService.success("Deleted Successfully")
            } else {
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
            // this.alertService.error(error);

          });
        }
      }).catch(() => {
        this.ngxSpinnerService.hide();
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }

  updateStatus(event, id) {

    //event.preventDefault();
    let obj = this.data.find(element => {
      // console.log(element.get_master_company_type.company_type_id)
      if (element.id === id) {
        return true;
      }
    });
    this.activeStatus(id, obj);
  }

  activeStatus(row_id, row_obj) {
    let { active, createdname, description, id, name, parentid } = row_obj;

    let obj = {
      moduleid: id,
      module_name: name,
      module_description: description,
      module_parent_id: parentid,
      isactive: active,
    }
    //console.log("*********", obj)

    this.confirmationDialogService.confirm('PLEASE CONFIRM', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !active;
          // console.log(obj);
          this.master.updateModule(obj).subscribe(data => {
            // console.log(data)
            this.getModuleData()
            setTimeout(() => {
              this.toastrService.success("Status Changed Successfully");
            }, 1000)
          });
        }
        else {
          //   this.master.updateModule(obj).subscribe(data => {
          //     console.log(data)
          //     this.getModuleData()
          //     setTimeout(() => {
          //       this.toastrService.success("Status Changed Successfully");
          //     }, 1000)
          //   });
        }
        this.ngxSpinnerService.hide();
      }).catch(() => {

        //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }

  importFile() {
    let self = this;
    self.ImportFilesDialogService.confirm('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-module-import.csv`, 'addingModuleBulk')
      .then(data => {
        //   console.log(data);
        self.getModuleData();
      })
      .catch(err => {
        //  console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      })
  }
  exportFile() {
    let tablename = {
      "tableName": "master_module_tbl"
    }
    this.master.exportBulk(tablename).subscribe(dataFile => {
      if (dataFile.status) {
        let selBox = document.createElement('a');
        var att = document.createAttribute("href");
        att.value = dataFile.file;
        var att1 = document.createAttribute("download");
        selBox.setAttributeNode(att);
        selBox.setAttributeNode(att1);
        document.body.appendChild(selBox);
        selBox.click();
      }
    }, err => {
      this.toastrService.error(err.message);
    });
  }
}