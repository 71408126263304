<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/usertypes">Users</a></li>
      <li class="breadcrumb-item active" aria-current="page">Host</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-6 mb-md-0 mb-1">
          <select name="company" class="form-control formstyle" [(ngModel)]="companyId" (change)="getDsByCompanyId()">
            <option value="">Select Company Name</option>
              <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                  {{userObj.customer_company_name}}
              </option>
          </select>
        </div>

        <div class="col-md-6 mb-md-0 mb-1" >

          <select name="status" class="form-control formstyle"  (change)="getHostindirectSeller()" [(ngModel)]="status"

          *ngIf="companyId > 0" >
            <option value="3">All User</option>
            <option value="1">Active User</option>
            <option value="2">Inactive User</option>
          </select>
        </div>
      </div>
  </div>
<div class="col-md-4">
  <div class="row">
    <div class="col-md-12 mb-md-0 mb-1">

      <select name="ds" class="form-control formstyle" [(ngModel)]="dsUserId" (change)="getHostindirectSeller()"

        *ngIf="companyId > 0" >
        <option value = 0 >Select Direct Seller</option>
          <option *ngFor="let data of directSellers" value={{data.userid}}>
              {{data.username}} - Host Count ( {{data.total}} ) 
          </option>
      </select>

    </div>

  <!-- <div class="col-md-4 mb-md-0 mb-1" style="justify-content: right;">
     <input type="button" value="Add New" class="custbtn" *ngIf="isWrite" routerLink="/corporate/adddsuser" >

  </div> -->
</div>
</div>

  </div>

  <div class="row" >


    <div class=" col-md-4 mt-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>


        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText [disabled]="userObj == null || userObj.length == 0">

      </div>


    </div>

  <div class="pl-3 mt-3 pt-2" *ngIf="resendStatus && (status==2 || status==3)">
    <button  type="button" class="custbtn" (click)="sendActivationLink(userObj)"
    data-sidebar="menu-light" id="light-menu" *ngIf="resendStatus && checkedList.length== 0 && status==2">Resend All
    </button>
    <button  type="button" class="custbtn" (click)="sendActivationLink(checkedList)"
    data-sidebar="menu-light" id="light-menu" *ngIf="resendStatus && checkedList.length > 0">Resend Checked
    </button>
  </div>

  <div class="col-md-8 mt-2" *ngIf="allBundleList.length > 0">
    <div class="row mt-3" >
    <div class="col-md-12"><button class="custbtn" (click)="reset()" >Reset</button></div>
    </div>
  </div>

</div>


<div class="tblPrt mt-2 overflow-auto">


  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="userObj.length !== 0">
      <tr>

        <th> <span *ngIf="status==1 && customerID === 0">Select</span></th>
        <th><span (click)="setOrder('full_name')" style="cursor: pointer;">Name / UserName / Email <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></span></th>
            <th>Is Active</th>

        <th style="cursor: pointer;"><span (click)="setOrder('createdon')">Created On <i class=" fa fa-sort text-black"
            aria-hidden="true"></i></span></th>
        <th><span *ngIf="customerInfo.role_id != 100">Actions</span></th>
        <!-- <th>Reports</th> -->
      </tr>
      <tr *ngFor="let obj of userObj">

        <td >
          <span *ngIf="status==1 && obj.isactive && obj.password && customerID == 0">
          <input type="checkbox" (change)="onUserSelectedForAssignment($event.target.checked, obj)" [checked]="obj.isSelected"  #checkboxes></span>

        <span *ngIf="obj.isactive==false ||  obj.password==null || obj.password=='' || customerID!=0">  <input type="checkbox" (change)="onCheckboxChange(obj,$event)" value="{{obj.userid}}"  #checkboxes *ngIf="!obj.isactive || !obj.password"></span>        
        </td>
        <td><span (click)="dsLogsView(obj.userid)" style="text-decoration:underline;cursor: pointer;">
          {{obj.full_name}}<br/>
          {{obj.username}}</span><br/>
          <span class="ellipsis" title="{{obj.email}}">{{obj.email}}</span>      
        </td>
        <td>{{(obj.isactive) ? "Active" : "Inactive"}}</td>
        <td>
          <p class="mb-0">{{obj.createdon | date:'mediumDate'}}</p>
        </td>
        <td style="width: 200px;">
          <span *ngIf="customerInfo.role_id != 100">
          <a *ngIf="obj.isactive && obj.password"  (click)="resetpassword(obj.username)">Reset Password</a>
          <mat-slide-toggle *ngIf="obj.isactive && obj.password" [checked]="obj.isactive"  class="my-slider ml-1"
            (click)="updateStatus($event, obj.userid,obj.isactive)">
            </mat-slide-toggle>
          <p *ngIf="!obj.isactive || !obj.password" ><a   (click)="sendActivationLink([obj])">Resend Link</a></p>
        </span>
        </td>
        <!-- <td class="viewReport"> <span (click)="dsreportView(obj.userid)"> View </span> </td> -->
      </tr>

  </table>
  <div *ngIf="total == 0" style="text-align: center;margin-top: 70px;">
    <p>No Result Available</p>
    </div>
  <div class="mt-2 text-center difPagination" *ngIf="total > 10">
    <ngb-pagination [collectionSize]="total" [(page)]="dsPage" [maxSize]="2" [rotate]="true"
      (pageChange)="getHostindirectSeller(dsPage=$event)">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div>
</div>

