import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from 'src/app/secondadmin/base.component';

@Component({
  selector: 'app-newjourneytype',
  templateUrl: './newjourneytype.component.html',
  styleUrls: ['./newjourneytype.component.css']
})
export class NewjourneytypeComponent extends BaseComponent implements OnInit {

  journey_type_name;
  journey__type_description;
  journey_type_icon;
  journey_type_parent_id;
  isactive;
  journey_type_id;
  journeytypeObj;
  status: boolean = true;

  constructor(private master: MasterService) { 
      super();
    }

  ngOnInit() {
    let self = this;
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.journey_type_id = params.journey_type_id;
      });
    //get item details using id
    if (this.journey_type_id) {
      this.master.getJourneytypeById(this.journey_type_id).subscribe(response => {
       // console.log(response);
        if (response.status) {
          this.status = false;
          self.journeytypeObj = response.data;
        //  console.log(self.journeytypeObj)
          self.dataBinding();
        } else {

        }
      });
    }
  }

  addNewJourneytype() {
    if( this.journey_type_name &&  this.journey_type_name.trim()){
    let journeytypeObj = {
      name: this.journey_type_name,
      description: this.journey__type_description,
      journey_type_icon: this.journey_type_icon,
      journey_type_parent_id: this.journey_type_parent_id,
      isactive: this.isactive
    }
    this.master.addingJourneytype(journeytypeObj).subscribe(data => {
      if (data.status) {
        setTimeout(() => {
          this.toastrService.success("Added Successfully")
        }, 1000);
        this.router.navigateByUrl(`/corporate/journeytype`);
      } else {
      }
    }, error => {
     // console.log(error);
    });
  }else{
    this.toastrService.error("Please Fill all the fields")
  }
  }
  dataBinding() {
    this.journey_type_name = this.journeytypeObj.journey_type_name;
    this.journey__type_description = this.journeytypeObj.journey__type_description;
    this.journey_type_icon = this.journeytypeObj.journey_type_icon;
    this.journey_type_parent_id = this.journeytypeObj.journey_type_parent_id;
    this.isactive = this.journeytypeObj.isactive;
  }
  updateJourneytype() {
    if( this.journey_type_name && this.journey_type_name.trim()){
      let journeytypeObj = {
        journey_type_name: this.journey_type_name,
        journey__type_description: this.journey__type_description,
        journey_type_icon: this.journey_type_icon,
        journey_type_parent_id: this.journey_type_parent_id,
        isactive: this.isactive,
        journey_type_id: this.journey_type_id
      }
      this.master.UpdateJourneytype(journeytypeObj).subscribe(data => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully")
          this.router.navigateByUrl(`/corporate/journeytype`);
        }
      }, error => {
        console.error(error);
      });
    }else{
      this.toastrService.error("Please Fill name & screenshot fields")
    }
  }
  
}

