
<div class="container mt-2 themeTblReso">

  <div class="card box-shadow-3">
    <div class="card-text ml-2 mt-2" *ngIf="profileObj.source_id==1">
      <h4 class="mt-1">Complete Profile Information</h4>
     <p><b>User Type: </b>
       <ng-template [ngIf]="profileObj.source_id==1">Customer/Prospect</ng-template>
      <ng-template [ngIf]="profileObj.source_id==2">Direct Seller</ng-template>
      <ng-template [ngIf]="profileObj.source_id==3">Company Contact</ng-template>
    </p>
     <p *ngIf="contactObj.contact_fname"><b>First Name:</b> {{contactObj.contact_fname}} </p>
     <p *ngIf="contactObj.contact_lname"><b>Last Name:</b> {{contactObj.contact_lname}} </p>
     <p *ngIf="contactObj.contact_email"><b>Email ID:</b> {{contactObj.contact_email}} </p>
     <p *ngIf="contactObj.contact_phone"><b>Contact No.</b> {{contactObj.contact_phone}} </p>
     <p *ngIf="contactObj.contact_address"><b>Address:</b> {{contactObj.contact_address}} </p>

    </div>
    <div class="card-text ml-2 mt-2" *ngIf="profileObj.source_id==2">
      <h4 class="mt-1">Complete Profile Information</h4>
      <p><b>User Type: </b>
        <ng-template [ngIf]="profileObj.source_id==1">Customer/Prospect</ng-template>
       <ng-template [ngIf]="profileObj.source_id==2">Direct Seller</ng-template>
       <ng-template [ngIf]="profileObj.source_id==3">Company Contact</ng-template>
     </p>
     <p *ngIf="dSObj.user_fname"><b>First Name:</b> {{dSObj.user_fname}} </p>
     <p *ngIf="dSObj.user_lname"><b>Last Name:</b> {{dSObj.user_lname}} </p>
     <p *ngIf="dSObj.user_email_primary"><b>Email ID:</b> {{dSObj.user_email_primary}} </p>
     <p *ngIf="dSObj.user_mobile_phone"><b>Contact No.</b> {{dSObj.user_mobile_phone}} </p>
     <p *ngIf="dSObj.user_address1"><b>Address:</b> {{dSObj.user_address1}} {{dSObj.user_address2}} </p>

    </div>

    </div>
    <h4 class="mt-3">Individual Profile Linking</h4>
    <div class="tblPrt">

      <div *ngIf="allProfileDataObj && allProfileDataObj.length==0" >No Result Available</div>
      <table *ngIf="allProfileDataObj && allProfileDataObj.length>0" class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">

        <tr>

          <th>First Name </th>
          <th>Last Name </th>
          <th>Email</th>
          <th>Phone</th>
          <th>DS Name</th>
          <th>Contact Type</th>
          <th>Created On</th>


        </tr>
        <tr *ngFor="let item of allProfileDataObj | orderBy:colname:ord | paginate:{itemsPerPage:10,currentPage:cp}" >
            <td >{{item.first_name}}</td>
            <td >{{item.last_name}}</td>
            <td >{{item.email}}</td>
            <td >{{item.phone}}</td>
            <td >{{item.ds_name}}</td>
            <td >{{item.contact_type_name}}</td>
            <td >{{item.createdon | date }}</td>


        </tr>
        </table>


        <pagination-controls *ngIf="allProfileDataObj && allProfileDataObj.length>0" class="my-pagination" (pageChange)="cp=$event"></pagination-controls>
    </div>

    <h4 class="mt-1" *ngIf="profileObj.source_id==1">Individual Opt-in / Opt-out Report</h4>
    <div class="tblPrt" *ngIf="profileObj.source_id==1">
        <table  class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
        <tr>

          <th width="10%">Opt Type</th>
          <th width="10%">Lag </th>
          <th width="10%">IP Address</th>
          <th width="10%">Location</th>
          <th width="10%">Browser</th>
          <th width="10%">Opt Date</th>

        </tr>
        <tr *ngIf="optInObj && optInObj.length==0" >
            <td colspan="8">No Result Available</td>
        </tr>

        <tr *ngFor="let item of optInObj">

        <td >{{item.log_type}}</td>
        <td >{{item.lat}}</td>
        <td >{{item.ipaddress}}</td>
        <td >{{item.location}}</td>
        <td >{{item.machine_info}}</td>
        <td >{{item.createdon | date}}</td>
        </tr>
        </table>
    </div>
    <h4 class="mt-1" *ngIf="profileObj.source_id==2">Web Access</h4>
    <div class="tblPrt" *ngIf="profileObj.source_id==2">
        <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
          <tr>

            <th width="10%">Log</th>
            <th width="10%">Lag </th>
            <th width="10%">IP Address</th>
            <th width="10%">Location</th>
            <th width="10%">Browser</th>
            <th width="10%">Created on</th>

          </tr>
        <tr *ngIf="webLogsObj && webLogsObj.length==0" >
            <td colspan="8">No Result Available</td>
        </tr>
        <tr *ngFor="let item of webLogsObj">

        <td >{{item.log_type}}</td>
        <td >{{item.lat}}</td>
        <td >{{item.ipaddress}}</td>
        <td >{{item.location}}</td>
        <td >{{item.machine_info}}</td>
        <td >{{item.createdon | date}}</td>
        </tr>
        </table>


        <ngb-pagination *ngIf="webLogsObj && webLogsObj.length>0" [collectionSize]="totalWebLog" (pageChange)="getWebLogsData(csvWebID,customerID,pageWebLog=$event)" [(page)]="pageWebLog" [maxSize]="2"
        [boundaryLinks]="true">
      </ngb-pagination>

    </div>

    <div class="my_class text-center">
      <button type="button" class="custbtn mt-2" (click)="donwloadCSV()" >Right to information</button>

      <!-- <button type="button" class="custbtn mt-2">Right to be Forgotten</button> -->
      <button type="button" class="custbtn mt-2" (click)="backToSearchPage()">Back</button>
    </div>

</div>
