<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/permissions">Permissions</a></li>
            <li class="breadcrumb-item active">{{header}} Permissions</li>
        </ol>
    </nav>
</div>

<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="permissionForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Permission Parent Id</label>
                    <select formControlName="permissionsParentId" class="form-control formstyle">
                        <option value="0" selected>Root</option>
                        <!-- <option *ngFor="let obj of getRoleObj1" value={{obj.role_id}}>
                                                {{obj.role_name}}
                                            </option> -->
                    </select>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Permission Name <span style="color:red">*</span></label>
                    <input type="text" id="exampleForm2" formControlName="permissionsName"
                        class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.permissionsName.errors }" required maxlength="20" appSpace>
                    <div *ngIf="submitted && f.permissionsName.errors" class="invalid-feedback">
                        <div *ngIf="f.permissionsName.errors.required || f.permissionsName.hasError('whitespace')">Permission Name is required</div>
                        <div *ngIf="f.permissionsName.errors.pattern">Permission Name is not a valid format</div>
                        <div *ngIf="f.permissionsName.errors?.maxlength">You can enter only 20 charachters</div>
               
                  
                    </div>
                </div>

                <div class="mt-1">
                    <label for="exampleForm2"> Permission Description</label>
                    <input type="text" id="exampleForm2" formControlName="permissionsDescription"
                        class="form-control form-control-md" min="1" maxlength="499">
                </div>

                <div class="mt-1">
                    <label class="mt-1">Is Active</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio1" formControlName="isactive"
                            [value]="true">
                        <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="customRadio2" formControlName="isactive"
                            [value]="false">
                        <label class="custom-control-label" for="customRadio2">Inactive</label>
                    </div>
                </div>

                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewPermission()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="updatePermission()">Update</button>
                </div>
            </div>
        </div>
    </form>

</div>