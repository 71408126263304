<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/industrymaster">Industry</a></li>
      <li class="breadcrumb-item active">Add New Industry</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 mt-md-3">
  <form class="form-horizontal mt-1" [formGroup]="themeForm">
    <div class="rowTheme">
      <div class="themContain">
        <div>
          <label for="exampleForm2">Industry Name <span style="color:red">*</span></label>
          <input type="text" id="exampleForm2" formControlName="industryName" class="form-control form-control-md"
            [ngClass]="{ 'is-invalid': submitted && f.industryName.errors }" required maxlength="30" appSpace>
          <div *ngIf="submitted && f.industryName.errors" class="invalid-feedback">
            <div *ngIf="f.industryName.errors.required || f.industryName.hasError('whitespace')">Industry Name is required</div>
            <div *ngIf="f.industryName.errors.pattern">>Industry Name is not a valid format</div>
            <div *ngIf="f.industryName.errors?.maxlength">You can enter only 30 charachters</div>

          </div>
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Industry Description</label>
          <input type="text" id="exampleForm2" formControlName="industryDescription"
            class="form-control form-control-md">
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Industry Logo</label>
          <div [ngClass]="status ? 'displaynone' :'displayblock' ">
            <img src="{{industry_log}}" alt="Margo" style="height:150px;" (error)="setValue($event)">
          </div>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="customRadio1" formControlName="firstActive"
                [value]="true" (change)='radioChange()'>
              <label class="custom-control-label" for="customRadio1">Url</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="customRadio2" formControlName="firstActive"
                [value]="false" (change)='radioChange()'>
              <label class="custom-control-label" for="customRadio2">Upload a file</label>
            </div>
          </div>
          <div *ngIf="screenshot_type" class="mt-1">
            <input type="text" id="exampleForm1" formControlName="industryLogo" class="form-control form-control-md"
              placeholder="https://example.com/image(.jpg,.jpeg,.png)">
          </div>
          <div *ngIf="!screenshot_type" class="mt-1">
            <input #imageInput type="file" accept=".png, .jpg, .jpeg" (change)="processFile($event,imageInput)">
            <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
          </div>
        </div>
        <div>
          <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
            <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addNewIndustry()">Submit</button>
          </div>
          <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'">
            <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="UpdateIndustry()">Update</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>