<!-- Breadcrumb for application -->
<!-- <div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/helps">Helps & Tips</a></li>
      <li class="breadcrumb-item active" aria-current="page">Contact Support</li>
      
    </ol>
  </nav>
</div> -->
<!-- Master Container 1 -->
<div class="container mt-1">

  <div class="row">
  
    <div class="col-md-5 mb-1">
  
      <fieldset class="scheduler-border">
        <legend class="scheduler-border"><h5 class="font-weight-bold">Helps & Tips</h5></legend>
        
        <div class="row theme-fa-icon">
          <div class="col-md-12">
            <button class="cbtn mt-1" routerLink="/corporate/tips">
              <i class="fa fa-graduation-cap orange-dr-icon" aria-hidden="true"></i>Knowledge Base 
            </button>
  
            <!-- <button class="cbtn mt-1" routerLink="/corporate/support">
              <img class="customimg" src="../../../assets/custom/images/remastermodules/Theme.png"> Contact Support 
            </button> -->
          </div>
        </div>
  
      </fieldset>
      <ng-template #libraryModal let-modal>
        <div class="scroller">
          <div class="wholePop filtMain">
            <div class="modal-header">
              <h4 class="modal-title">Select {{libraryType}} snippet</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card mb-2 mb-md-0">
                <div class="card-header scrlCont" id="container">
                  <ul class="filtMain">
                    <li *ngFor="let item of library;"  class="filter-select-list" (click)="selectSubjectMessage(item.message_body || item.subject_name,libraryType)" >
                      <a placement="left" ngbTooltip="{{item.message_body || item.subject_name}}" style="cursor: pointer;"  class="artist-name libTextOverflow" >
                        {{item.message_body || item.subject_name}}</a>
                        </li>          
                  </ul>
                  
                </div>
              </div>
            </div>
  
          </div>

        </div>
      </ng-template>
      
      <fieldset class="scheduler-border mt-1">
        <legend class="scheduler-border"><h5 class="font-weight-bold">Contact Our Support Team</h5></legend>
        
        <div class="contFrmRow mt-1">
          <label for="exampleForm2">Subject</label>
          <!-- <span class="drctOpt"><a (click)="getLibraryData(libraryModal,'Subject')">Snippet</a></span> -->
          <input type="text" class="form-control" [(ngModel)]="subject"
              class="form-control form-control-lg">
        </div>
          
          <div class="contFrmRow mt-1">
              <label for="exampleForm2">Your Message</label>
              <!-- <span class="drctOpt"><a (click)="getLibraryData(libraryModal,'Message')">Snippet</a></span> -->
              <textarea  class="form-control" [(ngModel)]="description" class="form-control form-control-lg">
  
              </textarea>
          </div>
          
          <div class="my_class  text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <!-- <div class="col-12 col-md-3"> -->
                  <button type="submit" class="custbtn mt-2" (click)="submitCase()">Submit</button>
              <!-- </div> -->
          </div>
        
      </fieldset>
  
    </div>
  
  <div class="col-md-7">
  
    <fieldset class="scheduler-border">
      <legend class="scheduler-border"><h5 class="font-weight-bold">Direct Seller</h5></legend>
  
        <div class="row">
      
      <div class="col-md-12 mt-2">
        <div class="dirSellImg"><img src="../../assets/custom/images/teamWork.jpg"></div>
        <p class="mt-1">When the direct seller selects Help & Tips from the menu, the direct seller will have the opportunity to look for help using the {{projectName}} Knowledge Base or contacting the {{projectName}} support team.
          If the Seller selects the Knowledge Base, they will be redirected to the knowledge base homepage where they can search for help
        </p>
      </div>
      
      </div>
  
  
    </fieldset>
    </div>
    </div>
  </div>
  
  
  