<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Content Builder Fields</li>
    </ol>
  </nav>
</div>
<div class="container mt-1">
<form [formGroup]="addfieldForm" (ngSubmit)="addField()">

  <div class="row">
    <div class="col-md-10 col-xl-7">

      <div class="card mt-1">

        <div class="card-body">

          <div>
            <label for="exampleForm2">Company Name <span style="color:red">*</span></label>
            <select formControlName="company" class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}}>
                    {{userObj.customer_company_name}}
                </option>
            </select>
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Company Name is required</div>
            </div>
        </div>

        <div class="mt-2">
          <label for="exampleForm2">Field Name<span style="color:red">*</span></label>
          <input type="text" formControlName="field_name" class="form-control formstyle mt2"
          [ngClass]="{ 'is-invalid': submitted && f.field_name.errors }" id="field_name"
          placeholder="field name" required>
          <div style="text-align:left" *ngIf="submitted && f.field_name.errors" class="invalid-feedback">
            <div *ngIf="f.field_name.errors.required">field name is required</div>
        </div>
      </div>
      <div class="mt-2">
        <label for="exampleForm2"> Data type<span style="color:red">*</span></label>
        <select class="form-control"   formControlName='datatype' [ngClass]="{ 'is-invalid': submitted && f.datatype.errors }" id="datatype"
          placeholder="datatype" required>
            <option value="text">Text</option>
            <option value="integer">integer</option>
            <option value="text">File</option>            
          </select> 
          <div style="text-align:left" *ngIf="submitted && f.datatype.errors"
          class="invalid-feedback">
          <div *ngIf="f.datatype.errors.required">datatype is required</div>
      </div>
    </div>
    <div *ngIf="!fieldName"><button type="button" class="custbtn mt-2" (click)="addField()">
      Add Field
  </button></div>
  <div *ngIf="fieldName"><button type="button" class="custbtn mt-2" (click)="editField()">
    Edit Field
</button></div>
</div></div></div></div>
  
  </form>
  </div>
