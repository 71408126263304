import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base.component';
import {SegmentationService} from '../../../../services/segmentation/segmentation.service';


@Component({
  selector: 'app-attribute-type',
  templateUrl: './attribute-type.component.html',
  styleUrls: ['./attribute-type.component.css']
})
export class AttributeTypeComponent extends BaseComponent implements OnInit{

  limit = 9999;
  offset = 0;
  text = '';
  attrTypeList = [];
  p = 1;
  attrLength = 0;
  order: string = 'group_name';
  reverse: boolean = false;
  constructor(private segmentService: SegmentationService) {
    super();
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getList();
  }

  //  ###############  Get Attributes Type List  #######  //

  getList(){
    this.segmentService.getAttributeTypeList(this.limit, this.offset).subscribe(data => {
      if (data.status){
        this.attrTypeList = data.data;
      //  console.log(this.attrTypeList);
        this.attrLength = this.attrTypeList.length;
      } else {
        this.attrTypeList = [];
        this.attrLength = 0;
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //   ##############  Set Order ########################  //

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  //  ############  Edit Attribute Type ###################  //

  editAttributeType(id){
    let URL = '/corporate/new-attribute-type' ;


    this.router.navigate([URL], { queryParams: {'attrId' : id} });

  }

  //  ###########  Delete Attribute Type ##################  //

  deleteAttributeType(id){
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to delete the Attribute Type'  + '??', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          // console.log(userObject)
          this.ngxSpinnerService.show();
          this.segmentService.deleteAttributeType(id).subscribe(data => {
              if (data.status) {
                this.toastrService.success('Type Deleted Successfully');
                this.p = 1;
                this.getList();
              } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
              }
            },
            error => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }

  //  ##########  Update Attribute Type Status ###########  //

  updateAttributeTypeStatus(event, obj){
    event.preventDefault();

    let stat = 'InActive';
    if (!obj.isactive) {
      stat = 'Active';
    }

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To ' + stat + '??', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          const userObject = {
            field_type_name: obj.field_type_name,
            field_type_desc: obj.field_type_desc,
            status: !obj.isactive
          };
          // console.log(userObject)
          this.ngxSpinnerService.show();
          this.segmentService.updateAttributeTypeFields(userObject, obj.field_type_id).subscribe(data => {
              if (data.status) {
                this.toastrService.success('Status Changed Successfully');
                this.p = 1;
                this.getList();
              } else {
                this.toastrService.error(data.message);
                this.ngxSpinnerService.hide();
              }
            },
            error => this.ngxSpinnerService.hide());
        }
      }).catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });
  }
}
