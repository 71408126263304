<div class="container h-100 app-content">
    <div class="row h-100 justify-content-center align-items-center mt--4">
        <div class="col-md-4 col-sm-8 col-11">
            <div class="text-center mainLogLogo">
                <img src="../../assets/custom/images/margologo250.png" alt="branding logo">
            </div>
            
                        <form class="form-horizontal text-center mt-1" [formGroup]="forgotForm"
                            (ngSubmit)="forgotPassword()">

                            <input type="text" formControlName="username" class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="user-name"
                                placeholder="Please Enter Your Username">
                            <!-- <div class="form-control-position">
                            <i class="feather ft-user"></i>
                        </div> -->
                            <div style="text-align: left;" *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">username is Required</div>
                            </div>
                            <div class="forgt mt-2">
                                <a [routerLink]="['/login']" class="card-link">Login Here!</a>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="custbtn mt-2">
                                    <!-- <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i> -->
                                    <!-- <i class="feather ft-unlock" *ngIf="!submitted"></i>  -->
                                    Submit
                                </button>
                                <button type="button" class="custbtn mt-2" routerLink="/login">
                                    Cancel
                                </button>
                            </div>
                            
                            
                        </form>
                      </div>
                    </div>
                    </div>
