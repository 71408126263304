import { ShopLiveService } from 'src/app/services/shop-live/shop-live.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-shoplive-newpost',
  templateUrl: './shoplive-newpost.component.html',
  styleUrls: ['./shoplive-newpost.component.css']
})
export class ShopliveNewpostComponent extends BaseComponent implements OnInit {
  addCategoryForm: FormGroup;
  getCompanyobj: any = [];
  customer_id;
  submitted: boolean = false;
  categoryObj;
  selectedCompany;
  screenshot_type = true;
  them_awskey;
  category_id;
  imgURL = [];
  videoURLs = [];
  fileURLs = [];
  product_files: any[];
  postUrl = [];
  screenshotUrl = [];
  fileUploadErrMsg:any;

  slCatPage = 1;
  slCatLimit = 100;
  slCatSkip = 0;
  slCatTotal: number = 0;

  slCatord = "asc";
  slCatColname = "sl_cat_name";
  domainData: any;


  constructor(private clientService: ClientService,
    private shopLiveServce: ShopLiveService,
    private formBuilder: FormBuilder,
    private master: MasterService,
    private upload:UploadService) {
    super();
  }

  ngOnInit() {
    // this.getBusinessDomain()
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    if (this.customer_id != 0) { this.getCategoryData(this.customer_id); }

    this.addCategoryForm = this.formBuilder.group({
      categoryid: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
      description: ['', [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
      url: ['', Validators.pattern(AppConstants.regex.websiteURL)],
      post_screenshot: [''],
      firstActive: [0],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required],
      business_domain_id:['']
    });
  }

  get f() { return this.addCategoryForm.controls; }

  radioChange() {
    this.screenshot_type = !this.screenshot_type;
    if (this.screenshot_type) {
      this.imgURL = [];
      this.videoURLs = [];
      this.fileURLs = [];
    } else {
      this.addCategoryForm.get('post_screenshot').patchValue('');
      this.postUrl = [];
    }
  }

  setValue(event) {
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = this.master.getUrlfile(url);
        this.them_awskey = url;
        event.target.src = this.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  getCategory(company) {
    if (company) { this.getCategoryData(company); this.selectedCompany = company;this.getBusinessDomain() }
  }
  getCategoryData(company) {
    if (company) {
      this.ngxSpinnerService.show();
      let status = true;


      this.shopLiveServce.getCategoryList(company, this.slCatLimit, this.slCatSkip, this.slCatColname,  this.slCatord, status, '').subscribe(data => {


        if (data.status) {
          if (data.data.rows == null) { this.categoryObj = [] }
            else { this.categoryObj = data.data.rows }
            this.slCatTotal = data.data.total;
        } else {
          this.categoryObj = [];
          this.slCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
      });
    } else { this.categoryObj = []; this.ngxSpinnerService.hide(); }
  }

  processFile(event: any, imageInput: any) {
    let self = this;
    const file: File = imageInput.files[0];
    if (file.size <= 1024 * 1024 * 4) {
      if (/\.(jpe?g|png|jpg|gif|mov|mp4|csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(file.name) === false) {
        self.toastrService.error("Please select only (png, jpg, jpeg, gif, mov, mp4, csv, docs, xls, pptx or txt) files");
      } else {
        const reader = new FileReader();
        reader.onload = (event1) => {
          this.toUploadFiles(file.name, reader.result).then(data => {
            self.ngxSpinnerService.hide();
            if (data) {
            }
          }).catch(err => {
            self.toastrService.error('Something went wrong');
            self.ngxSpinnerService.hide();
          })
        }
        reader.readAsDataURL(file);
      }
    } else {
      this.toastrService.error("Unable to proceed upload, File is larger than 4 mb.");
    }
  }
  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    return new Promise<any>((resolve, reject) => {
      this.upload.uploadImage(name,"master_images",file).subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide()
          if(res.status){
            let params=res.data;
            if (params.key) {
              if (/\.(jpe?g|png|jpg|gif)$/i.test(params.Key) === true) {
                self.imgURL[0] = params.Key;
                resolve(true);
              } else if (/\.(mov|mp4)$/i.test(params.Key) === true) {
                self.imgURL[0] = params.Key;
                resolve(true);
              } else if (/\.(csv|doc|docs|docx|xls|xlsx|pptx|txt)$/i.test(params.Key) === true) {
                self.imgURL[0] = params.Key;
                resolve(true);
              }
            }
          }else{
            reject(false)
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          reject(false)
          this.ngxSpinnerService.hide()
          this.toastrService.error('Something went wrong !')
        }
      })
    })
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  addNewPostData() {
    this.submitted = true;
    let { categoryid, name, description, url, post_screenshot ,business_domain_id} = this.addCategoryForm.value;

    if (this.f.post_screenshot.value != '') {
      this.postUrl.push(post_screenshot);
    }
    this.screenshotUrl = [...this.postUrl];
    this.product_files = [...this.imgURL, ...this.videoURLs, ...this.fileURLs];

    if ((this.product_files.length == 0 || this.product_files[0] == undefined || this.product_files[0] == null) &&
      (this.screenshotUrl.length == 0 || this.screenshotUrl[0] == undefined || this.screenshotUrl[0] == null)) {
      //this.fileUploadErrMsg = 'Please select file';
      this.toastrService.error('Please select file.');
      return false;
    }
    if (this.addCategoryForm.invalid) {
      return;
    }

    // if (this.f.post_screenshot.value != '') {
    //   var urlregex = new RegExp(
    //     "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    //   let pattern = urlregex.test(this.f.post_screenshot.value);
    //   if (!pattern) {
    //     this.toastrService.error("Enter Valid URL");
    //     return false;
    //   }
    // }

    let obj = {
      product_name: name,
      product_desc: description,
      product_url: url,
      product_cat_id: categoryid,
      product_files: [this.imgURL],
      business_domain_id:business_domain_id
    }
    // obj.product_files = [...this.screenshotUrl, ...this.product_files];
    // console.log('OBJECT', obj, this.selectedCompany);

    this.ngxSpinnerService.show();

    this.shopLiveServce.addNewProduct(this.selectedCompany, obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Record Inserted successfully");
        this.router.navigateByUrl(`/corporate/shop-live`);
      } else {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error(error.message)
    });
  }
  getBusinessDomain(){
    let self = this;
    this.master.getBusinessDomain(100,0,this.selectedCompany).subscribe(data => {
      self.domainData = data.data.rows;
    }, error => {
      this.domainData=[]
    });
  }
}
