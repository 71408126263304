<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Subscription Module</li>
    </ol>
  </nav>
</div>
<div class="container mt-1 theme-fa-icon" >
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <h5 class="font-weight-bold">Subscription</h5>
    </legend>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/subscription-list">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Subscription Data
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/subscriptionLimit">
          <i class="bi bi-tags blue-lt-icon" aria-hidden="true"></i>Subscription Limit
        </button>
      </div>
      <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/subscription-media-list">
          <i class="bi bi-layout-three-columns orange-dr-icon" aria-hidden="true"></i>Subscription Media
        </button>
      </div>
      <!-- <div class="col-xl-4 col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/faq">
          <i class="bi bi-gear green-lt-icon" aria-hidden="true"></i>Webinar Library
        </button>
      </div> -->
    </div>
  </fieldset>
</div>
