export enum ClientMessages {
    ClientAddedSuccessMessage = "Client Added Successfully.",
    ClientUpdatedSuccessMessage = "Client Updated Successfully.",
    ClientDeletedSuccessMessage = "Client Deleted Successfully.",
  }

  export enum CommonMessages{
    statusUpdated = "Status Updated Successfully",
    deleteConfirmation ="ARE YOU SURE YOU WANT TO DELETE ?",
    activeInactiveConfirmation="Are you sure you want to change the status?",
    confirmationTitle ="Please confirm", 
    dialogDismissMessage='User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
  }

  
  export enum HelpAndTipsMessages{
    caseLogged = "Case logged successfully. Support team will get in touch with you via Email."
  }