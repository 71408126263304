<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/companytypemaster">Company Type</a></li>
      <li class="breadcrumb-item active">Add New Company Type</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 mt-md-3">
  <form class="form-horizontal mt-1" [formGroup]="companyForm">
    <div class="rowTheme">
      <div class="themContain">
        <div>
          <label for="exampleForm2">Company Type Parent Name</label>
          <select formControlName="companyParentId" class="form-control formstyle">
            <option value="0">Root</option>
            <option *ngFor="let userObj of companyTypeObj" value={{userObj.company_type_id}}>
              {{userObj.company_type_name}}
            </option>
          </select>
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Company Type Name <span style="color:red">*</span></label>
          <input type="text" class="form-control" formControlName="companyName" class="form-control form-control-md"
            [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" maxlength="30" appSpace>
          <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
            <div *ngIf="f.companyName.errors.required || f.companyName.hasError('whitespace')">Company Type Name is required</div>
            <div *ngIf="f.companyName.errors.pattern">Company Type Name is not a valid format</div>
            <div *ngIf="f.companyName.errors?.maxlength">You can enter only 30 charachters</div>
   
          </div>
        </div>
        <div class="mt-1">
          <label for="exampleForm2">Company Description</label>
          <input type="text" class="form-control" formControlName="companyDescription"
            class="form-control form-control-md">
        </div>

        <div class="my_class text-center mt-2" [ngClass]="status ? 'displayblock' : 'displaynone'">
          <button type="submit" class="custbtn mt-2" (click)="addNewCompany()">Submit</button>
        </div>
        <div class="my_class text-center mt-2" [ngClass]="status ? 'displaynone' :'displayblock'  ">
          <button type="button" class="custbtn mt-2"  (click)="updateCompany()">Update</button>
        </div>
      </div>
    </div>
  </form>
</div>