<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/webinar">Webinar Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/poll">Poll</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Poll</li>
  
        </ol>
    </nav>
  </div>
  
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <form class="form-horizontal" [formGroup]="addPollForm">
          <div class="card">
            <div class="card-body">
  
              <div class="form-group" *ngIf="customer_id===0">
  
                <label for="exampleForm2">Poll Name <span class="required">*</span></label>
  
                <select formControlName="selectedCompany" class="form-control formstyle ssss"
                  *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                  <option value="">Select Company Name</option>
                  <ng-container *ngFor="let Obj of getCompanyobj">
                    <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                      {{Obj.customer_company_name}}
                    </option>
                  </ng-container>
                </select>
  
                <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                  <div *ngIf="f.selectedCompany.errors.required">Poll Name is required</div>
                </div>
              </div>
  
              <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
                [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
  
              <div class="form-group">
                <label for="name">Poll Name <span class="required">*</span></label>
                <input type="text" class="form-control" name="name" formControlName="name"
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" appSpace>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required</div>
                  
                </div>
  
              </div>
              <div class="form-group">
                <label for="name">Poll Description</label>
                <textarea class="form-control text-area h-25" rows="5"  appSpace formControlName="description"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                 
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-12">
                  
                  
                </div>
              </div>   -->
              <div class="form-group">
                <label>Poll Options <span class="required">*</span> </label>
                <div *ngIf="submitted && f.options.errors" class="invalid-feedback">
                  <div *ngIf="f.options.errors.required">Please select poll option</div>
                </div>
                <br>
                  <div class="form-check " *ngFor="let option of webinarPollOptions; let i = index">
                    <input #checkOption class="form-check-input" type="checkbox"
                     name="options" id="optionCheck{{i}}" value="option1"  
                    [ngClass]="{ 'is-invalid': submitted && f.options.errors}"
                    (change)="onChange(option.id,checkOption.checked)"
                    >
                    <label class="form-check-label" for="optionCheck{{i}}">
                     {{ option.option_name }}
                    </label>
                  </div>
                  <!-- <div *ngIf="submitted && f.options.errors" class="invalid-feedback">
                    <div *ngIf="f.options.errors.required">Options is required</div>
                    
                  </div>                -->
                    

               </div> 
            </div>
            <div class="card-footer">
              <div class="text-center">
                <button class="btn btn-round btn-margo-action" (click)="addNewPollData()">Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  