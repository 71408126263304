<style>
  .my-pagination /deep/ .pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/master">Master module</a></li>
      <li class="breadcrumb-item active" aria-current="page">Block IP Host</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
      </div>

    </div>


    <div class="col-md-4" style="justify-content: right;">
        <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/newblockiphost">
        <!-- <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
          <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
        </button>
        <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
          <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
        </button> -->
      </div>
  </div>

  <div class="row">
    <div class="col-md-6 mt-2">
      <div>
        <select name="company" class="form-control formstyle" (change)="fetchHostListByType($event.target.value)">
          <option value="">
            Select HostType
          </option>
          <option value="">All</option>
          <option value="HOSTNAME">HOSTNAME</option>
          <option value="IP">IP</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="container">
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <th>Host Name</th>
      <th>Host Type</th>
      <th (click)="sorting('createdon')" style="cursor: pointer;"> CreatedOn <i class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th *ngIf="isEdit || isDelete">Actions</th>
    </tr>

    <tr *ngFor="let obj of blacklistedHostList">
      <td>{{obj.host}}</td>
      <td>{{obj.host_type}}</td>
      <td>{{obj.createdon | date:'mediumDate' }}</td>
      <td *ngIf="isEdit || isDelete">
        <div class="actBtn">
          <!-- <i class="fa fa-edit mr-1" style="cursor: pointer;" *ngIf="isEdit" (click)="editHost(obj.hostid)" placement="bottom"
            ngbTooltip="Edit"></i> -->
          <span class="fa fa-trash mr-1" style="cursor: pointer;" *ngIf="isDelete" (click)="deleteHost(obj.hostid)" placement="bottom"
            ngbTooltip="Delete"></span>
          <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
            (click)="updateStatus($event,obj.hostid)">
          </mat-slide-toggle>
        </div>
      </td>
    </tr>

  </table>

  <div style="text-align: center;" *ngIf="totalCount == 0">
    <p>No Result Available</p>
  </div>

  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true"
      (pageChange)="fetchBlacklistedhost((cp=$event))" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

</div>
</div>