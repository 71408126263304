import { Component, OnInit , Input} from '@angular/core';
declare var $Lightning: any;
@Component({
  selector: 'app-einstein-dashboard',
  templateUrl: './einstein-dashboard.component.html',
  styleUrls: ['./einstein-dashboard.component.css']
})
export class EinsteinDashboardComponent implements OnInit {

  @Input()
  token: String;

  @Input()
  appName: String;

  @Input()
  url: String;

  @Input()
  componentName: String;

  @Input()
  DashboardId: String;

  @Input()
  BuName: String;

  constructor() { }

  ngOnInit() {
   console.log('***************thisLightininig',this.DashboardId,this.BuName);
    this.initializeApp(this.DashboardId,this.BuName);
    console.log("ssssssssss...",$Lightning)
  }

  initializeApp(dashboardId,buName) {
    $Lightning.use(this.appName,
       () => {

        this.createComponent(dashboardId,buName);
      }, this.url, this.token);

  }
   createComponent(dashboardId,buName) {

  

    $Lightning.createComponent(

      this.componentName, // Lightning component name in Salesforce Einstein
      {
        accessToken: this.token,
        DashboardId:dashboardId,
        BuName:buName
      },
      'dashboard'// Container ID
    );
  }


}
