import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { AlertService } from 'src/app/_services/alert.service';
import { UploadService } from 'src/app/services/uploadfile/upload.service';

@Component({
  selector: 'app-new-business-domain',
  templateUrl: './new-business-domain.component.html',
  styleUrls: ['./new-business-domain.component.css']
})
export class NewBusinessDomainComponent extends BaseComponent implements OnInit {
  headers='Add';
  submitted = false;
  domainForm: FormGroup;
  domain_id: any;
  status: boolean = true;
  domainData:any;
  getCompanyobj: any;
  companyId: any;
  screenshot_type = true;
  correctImage:boolean=true;
  them_awskey='';
  domain_screenshot:any;
  constructor(
    private master: MasterService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private clientService: ClientService,
    private upload:UploadService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCompany()
    this.activatedRoute.queryParams
    .subscribe(params => {
      this.domain_id = params.id;
      this.companyId=params.companyId
    });
       //get item details using id
       if (this.domain_id) {
        this.ngxSpinnerService.show();
        this.headers = "Edit";
        this.master.getBusinessDomainById(this.domain_id,this.companyId).subscribe(response => {
          this.ngxSpinnerService.hide();
          this.status = false;
          if (response.status) {
            this.status = false;
            this.domainData = response.data;
            this.dataBinding()


          } else {
          }
        });
      }

    this._createDomainForm();


  }
  _createDomainForm(){
    this.domainForm = this.formBuilder.group({
      company:[{value:'', disabled:this.headers == 'Edit'},Validators.required],
      domainName: ['', [Validators.required]],
      firstActive: [true],
      domain_image: [''],
      is_company:[false],
      is_view:[true]
    });
  }

  dataBinding() {
    // this.domain_screenshot = this.isUrlValid(this.domainData.domain_image) ? this.domainData.domain_image :'';

    if (this.isUrlValid(this.domainData.domain_image)) {
      this.domainForm = this.formBuilder.group({
        company: [this.domainData.company_id.toString(), Validators.required],
        domainName: [this.domainData.domain_name, [Validators.required]],
        firstActive: [true],
        domain_image: [this.domainData.domain_image],
        is_company:[this.domainData.is_company],
        is_view:[this.domainData.is_view]
      });
      this.domain_screenshot = ''
      this.screenshot_type = true;
    } else {
      this.domainForm = this.formBuilder.group({
        company: [this.domainData.company_id.toString(), Validators.required],
        domainName: [this.domainData.domain_name, [Validators.required]],
        firstActive: [false],
        domain_image: [''],
        is_company:[this.domainData.is_company],
        is_view:[this.domainData.is_view]
      });
      this.domain_screenshot=this.domainData?.domain_image;
      this.them_awskey = this.domainData?.domain_image;
      this.screenshot_type = false;
    }
  }

  get f() { return this.domainForm.controls; }

  addNewDomain() {
    this.submitted = true;
    if (this.domainForm.value.domainName !== undefined || this.domainForm.value.domainName !== null || this.domainForm.value.domainName !== '') {
      let val = this.domainForm.value.domainName.trim();
      this.domainForm.get('domainName').patchValue(val);
    }

    if (this.domainForm.invalid) {
      return;
    }
    let domain_image = this.domainForm.value.domain_image;
    let domain_image_var = domain_image ? domain_image : this.them_awskey;

    if (this.f.firstActive.value) {
      if (this.f.domain_image.value != '') {
        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
        let pattern = urlregex.test(this.f.domain_image.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }

      if (this.f.domain_image.value == '') {
        this.toaster.error("Enter Screenshot URL");
        return;
      }
    } else {
      if (this.them_awskey == '') {
        this.toaster.error("Upload domain Image");
        return;
      }
    }


    let obj={
      domain_name:this.domainForm.value.domainName,
      company:this.domainForm.value.company,
      domain_image: domain_image_var,
      is_company:this.domainForm.value.is_company,
      is_view:this.domainForm.value.is_view

    }
    this.ngxSpinnerService.show();
    this.master.addingBusinessDomain(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Added Successfully")
        this.router.navigateByUrl(`/corporate/businessDomain`);
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });

  }

  updateDomain() {
    this.submitted = true;

    if (this.domainForm.invalid) {
      return;
    }
    let domain_image = this.domainForm.value.domain_image;
    let domain_image_var = domain_image ? domain_image : this.them_awskey;
    if (this.f.firstActive.value) {
      if (this.f.domain_image.value != '') {
        var urlregex = new RegExp(
          "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
        let pattern = urlregex.test(this.f.domain_image.value);
        //console.log("ssss",pattern)

        if (!pattern) {
          this.toaster.error("Enter Valid URL");
          return;
        }
      }

      if (this.f.domain_image.value == '') {
        this.toaster.error("Enter Screenshot URL");
        return;
      }
    } else {
      if (this.them_awskey == '') {
        this.toaster.error("Upload domain Image");
        return;
      }
    }

    let obj={
      id:this.domain_id,
      domain_name:this.domainForm.value.domainName,
      company:this.companyId,
      domain_image: domain_image_var,
      is_company:this.domainForm.value.is_company,
      is_view:this.domainForm.value.is_view
    }
    this.ngxSpinnerService.show();
    this.master.UpdateDomain(obj).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully")
        this.router.navigateByUrl(`/corporate/businessDomain`);
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message)
      }
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
  }
  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      // console.log(error);
    });
  }
  radioChange() {
    this.screenshot_type = !this.screenshot_type;
  }
  processFile(event: any, imageInput: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false) {
      this.toastrService.error("Please select only (png, jpg, gif or jpeg) files");
      this.correctImage=false;
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (event1) => {
        this.toUploadFiles(file.name, reader.result);
      }
      reader.readAsDataURL(file);
      this.correctImage=true;
    }
  }
  toUploadFiles(name,file) {
    this.ngxSpinnerService.show();
    let self = this;
    this.upload.uploadImage(name,"master_images",file).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide()
        if(res.status){
          let params=res.data;
          if (params.key) {
            self.setImageInForm(params.Key);
          }
        }else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide()
        this.toastrService.error('Something went wrong !')
      }
    })
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  setImageInForm(key:any)
  {
    this.them_awskey = key;
    this.domainForm.patchValue({
      domain_image : key
    })
    this.ngxSpinnerService.hide();
  }
}
