<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
        <li  class="breadcrumb-item active" aria-current="page">
            Manage Event
        </li>
      </ol>
    </nav>
  </div>
  
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="row">  
          <div class="col-md-6 mb-md-0 mb-1">
            <select name="status" class="form-control formstyle" (change)="getEventList(1)"  [(ngModel)]="eventTypeFilter">
              <option Value=''>All Events</option>
              <option Value=127>In-Person</option>
              <option Value=128>Virtual</option>
              <option Value=129>In-Person & Virtual</option>
            </select>
          </div>
        </div>
        
      </div>
      <div class="col-md-6 col-xl-4 mt-2 mt-md-0 text-sm-right">
        <div class="row">
          <div class="col-md-10">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text cyan lighten-2" id="basic-text1">
                  <i aria-hidden="true" class="fa fa-search text-white"></i>
                </span>
              </div>
              <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText>
            </div>
          </div>
          <div class="col-md-2">
            <input type="button" value="+ New Event" class="custbtn mr-1 ml-0 mb-2" style="min-width: auto;max-width: 150px;" (click)="onClickAdd()">
          </div>
        </div>

      </div>

      <div class="col-12">

        <div class="tblPrt">
          <table class="mt-1 bdrStCustom" width="100%" cellspacing="0" cellpadding="0">
            <tr>
                <th>Event Name</th>
                <th>Category</th>
                <th>Theme Name</th>
                <th>Invitees</th>
                <th>Start Data / Time</th>
                <th>Is Active</th>
                <th>Actions</th>
             
            </tr>
  
            <tr *ngFor="let obj of eventList">
  
              <td title="{{obj?.event_description}}" [ngClass]="{'aud-desc-color ': obj?.event_description}">{{obj.event_name}}</td>
              <td [innerText]="checkEventType(obj?.event_type_id)"></td>
              <td>{{obj?.theme_name}}</td>
              <td style="text-align: center;" (click)="openInviteeModal(obj?.event_id,obj?.total_invitee)"><a >{{obj.total_invitee > 0 ? obj.total_invitee : 0}}</a></td>
              <td>{{obj.event_date | date}} at {{obj.event_start_time}} {{obj.event_time_zone_abbr?obj.event_time_zone_abbr:obj.event_time_zone}}</td>
              <td>{{(obj.isactive) ? "Active" : "Inactive"}}</td>
              <td>
                <div class="actBtn">
                  <i class="fa fa-info mr-2 linkForce" style="vertical-align:top;" (click)="onClickView(obj)" title="view event"></i>
                  <i class="fa fa-edit mr-2 linkForce" style="vertical-align:top;" (click)="onClickEdit(obj)" title="edit event"></i>
                  <mat-slide-toggle [checked]="obj.isactive"  class="my-slider ml-1"
                  (click)="updateStatus(obj?.event_id,obj?.isactive)">
                  </mat-slide-toggle>
                  <!-- <i class="fa fa-trash mr-2 linkForce" style="vertical-align: top;" (click)="deleteComfirm(obj.event_id)" title="delete event"></i> -->
                  <!-- <span class="fa fa-trash mr-1 cursor" style="vertical-align:top;" placement="bottom"  ngbTooltip="Delete" (click)="deleteComfirm(obj.event_id)"></span> -->
                </div>
              </td>
             
            </tr>
  
          </table>
          <div *ngIf="totalNumber == 0" style="text-align: center">
            <p>No Result Available</p>
          </div>
          <div class="mt-2 text-center difPagination" *ngIf="totalNumber > 10">
            <ngb-pagination [collectionSize]="totalNumber" [(page)]="page" [maxSize]="2" [rotate]="true" (pageChange)="getEventList(page=$event)" aria-label="Custom pagination">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination> 
          </div>
        </div>
      </div>
    </div>
  </div>
 