<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/blockiphost">Block IP Host</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Block IP Host</li>

        </ol>
    </nav>
</div>

<div class="container">
    <form class="form-horizontal mt-1" [formGroup]="blockIPHostForm">
        <div class="row">
            <div class="col-md-10 col-xl-7">

                <div class="card mt-1">

                    <div class="card-body">
                        <div class="mb-2">
                            <label for="exampleForm2">Host Name <span style="color:red">*</span></label>
                            <input type="text" formControlName="host" class="form-control form-control-md"
                                [ngClass]="{ 'is-invalid': submitted && f.host.errors }" required maxlength="50"
                                appSpace>
                            <div *ngIf="submitted && f.host.errors" class="invalid-feedback">
                                <div *ngIf="f.host.errors.required || f.host.hasError('whitespace')">Host name is required
                                </div>
                                <div *ngIf="f.host.errors?.maxlength">You can enter only 50 charachters</div>
                            </div>
                        </div>
                        <div class="mb-2">
                            <label for="exampleForm2">Host Type <span style="color:red">*</span></label>
                            <select formControlName="type" class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                <option value="">----Select Type----</option>
                                <option value="HOSTNAME">HOSTNAME</option>
                                <option value="IP">IP</option>
                            </select>

                            <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                <div *ngIf="f.type.errors.required">Host type is required</div>
                            </div>
                        </div>

                        <label class="mt-1">Is Active</label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio1"
                                    formControlName="isactive" name="isactive" [value]="true">
                                <label class="custom-control-label" for="customRadio1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customRadio2"
                                    formControlName="isactive" name="isactive" [value]="false">
                                <label class="custom-control-label" for="customRadio2">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer text-center mt-1">
                        <div class="my_class text-center">
                            <button type="submit" class="custbtn" (click)="addblockIPHost()" *ngIf="isWrite">Submit</button>
                        </div>
                        <div class="my_class text-center">
                            <button type="button" class="custbtn" *ngIf="isEdit" (click)="updateblockIPHost()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>