<div class="row">
  <div class="col-md-6 mb-md-0 mb-1" *ngIf="customerID==0">
    <select name="company" class="form-control formstyle" [(ngModel)]="companyId" (change)="getCompanySellerNew(1)">
      <option value="select">Select Company Name</option>
      <option *ngFor="let company of companyObj" value={{company.customer_id}}>
        {{company.customer_company_name}}
      </option>
    </select>
  </div>

  <div class="col-md-6 mb-md-0 mb-1" *ngIf="userDetails.length !== 0 || companyId  != 'select'">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
            aria-hidden="true"></i></span>
      </div>
      <input class="form-control" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="searchText"
        (keyup)="getCompanySellerNew(1);">
    </div>
  </div>

  <div class="col-md-2 mb-md-0 mb-1">
    <!-- oninput="this.value =  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" -->
    <form [formGroup]="specificWalletForm"
      *ngIf="(checkedList.length > 0 || allCheckedList.length > 0) && userDetails.length > 0">
      <input type="text" autocomplete="off" formControlName="amount" min="0" class="form-control formstyle"


        placeholder="0.00" [ngClass]="{ 'is-invalid': submitted && f.errors }" />



      <div *ngIf="submitted && f?.errors" class="invalid-feedback">
        <div *ngIf="f.errors?.required" class="ml-5">
          Amount is required.
        </div>


        <!-- <div *ngIf="f.errors?.maxlength"> You reached to maxlength</div> -->


        <div *ngIf="f.errors.pattern"> Please enter numeric value</div>
      </div>
    </form>
  </div>

  <div class="col-md-2 mb-md-0 mb-1"
    *ngIf="(checkedList.length >0 || allCheckedList.length > 0) && userDetails.length > 0">
    <div class="col-md-12 text-right mt-1">

      <button type="submit" class="custbtn" appThrottleClick [throttleTime]="5000"
        (throttledClick)="addAmount()">Submit</button>
    </div>
  </div>
</div>

<div class="tblPrt mt-2">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="userDetails.length !== 0">
    <tr>
      <th>
        <input type="checkbox" [(ngModel)]="isAllChecked" [checked]="isAllChecked" name="list_name"
          (change)="checkUncheckAll($event)" />
      </th>
      <th (click)="setOrder('full_name')" style="cursor: pointer;"> Name / UserName / Email <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th class='text-right'>Credit Points</th>
      <th>Is Active</th>
      <th>Subscription Details</th>
      <th>SMS No.</th>
      <th (click)="setOrder('get_ds_user_login_fun.createdon')" style="cursor: pointer;">Created On <i
          class=" fa fa-sort text-black" aria-hidden="true"></i></th>
      <th>Action</th>
    </tr>
    <tr
      *ngFor="let obj of userDetails | orderBy: order:reverse:'case-insensitive' | paginate:{itemsPerPage:10,currentPage:cp,totalItems:total }">
      <td>
        <input type="checkbox" (change)="onCheckboxChange(obj,$event)" value="{{obj.userid}}" [checked]="isAllChecked"
          [disabled]="isAllChecked" #checkboxes>
      </td>

      <!-- (click)="dsLogsView(obj.userid)" style="text-decoration:underline;cursor: pointer;" -->
      <td style="text-decoration:underline">

        {{obj.full_name}}<br />
        {{obj.username}}<br />
        <span class="ellipsis" title="{{obj.email}}">{{obj.email}}</span>
      </td>
      <td class='text-right'>{{obj.masteramount ? (obj.masteramount | number : '1.2-2'):' 0.00 '}}</td>
      <td>{{(obj.isactive) ? "Active" : "Inactive"}}</td>

      <td style='white-space: nowrap;'>
        <p class='m-0'><b>Name:</b> {{obj?.title || ''}}</p>
        <p class='m-0'><b>Plan Type:</b> {{(obj?.subscription_type | titlecase) || ''}}</p>
        <p class='m-0'><b>Start Date:</b> {{(obj?.sub_start_date | date:'MMM dd, YYYY hh:mm a') || ''}}</p>
        <p class='m-0'><b>End Date:</b> {{(obj?.sub_end_date | date:'MMM dd, YYYY hh:mm a') || ''}}</p>
      </td>
      <td><span *ngIf="obj.ds_sender_number">+{{ obj?.ds_sender_number }}</span></td>
      <td>
        <p class="mb-0">{{obj.createdon | date:'mediumDate'}}</p>
      </td>
      <td class="text-center">
        <a href="javascript:void(0)" (click)="getSenderNumber(obj.userid,smsModal);">Add SMS No.</a>
        <button  type="button" class="btn vl custbtn text-uppercase" (click)="openSubModal(obj,subscriptionModal)">
          Renew
        </button>
      </td>
    </tr>
  </table>

  <div *ngIf="userDetails.length === 0" style="text-align: center;margin-top: 70px;">
    <p>No Result Available</p>
  </div>
  <div class="text-center" *ngIf="userDetails.length !== 0">
    <pagination-controls class="my-pagination" (pageChange)="getCompanySellerNew($event)"></pagination-controls>
  </div>
</div>
<ng-template #subscriptionModal let-modal>
  <div class="scroller">
    <div class="wholePop filtMain">
      <div class="modal-header dialHead w-100 d-flex align-items-center">
        <h4 class="modal-title">Renew Subscription</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');plan_type='';isSubmit=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="row d-flex justify-content-center">
          <div class="col-md-6 col-sm-12 mb-4">
            <select name="plan_type" [(ngModel)]="plan_type" class="form-control formstyle srchWith">
            <option value = '' >Select Plan Type</option>
            <option value='monthly'>Monthly</option>
            <option value='yearly'>Yearly</option>
          </select>
          <div *ngIf='isSubmit && !plan_type'>
            <small class='text-danger'>Plan type is required</small>
          </div>
          </div>
          <div class="col-12 text-center mb-2">
            <button type="submit" class="btn vl custbtn text-uppercase" (click)='renewPlanType()'>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #smsModal let-modal>
  <div class="scroller">
    <div class="wholePop filtMain">
      <div class="modal-header dialHead w-100 d-flex align-items-center">
        <h4 class="modal-title">Add / Edit SMS number</h4>
        <button type="button" class="close" aria-label="Close" (click)="newsenderNumber='';modal.dismiss('Cross click');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="row d-flex justify-content-center">
          <div class="col-md-6 col-sm-12 mb-4">
            <label for="example">Please Select SMS No.</label>
            <select name="plan_type" [(ngModel)]="newsenderNumber" class="form-control formstyle srchWith">
              <option value='' selected disabled>select</option>
              <option [value]="smsno.phone_no" *ngFor="let smsno of dsAvailableSenderNumbers" >+{{ smsno.phone_no }}</option>
          </select>
         
          </div>
          <div class="col-12 text-center mb-2">
            <button type="submit" class="btn btn-margo-action" (click)="updateSmsNo();">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>