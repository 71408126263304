<!-- Breadcrumb for application -->
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Support</li>
    </ol>
  </nav>
</div>

<div class="container mt-2 theme-fa-icon">
  <legend class="scheduler-border">
    <h5 class="font-weight-bold">Support Navigation</h5>
  </legend>
  <fieldset class="scheduler-border">

    <div class="row">


      <div class="col-xl-4  col-md-6" *ngIf="isWrite">
        <button class="cbtn mt-1" routerLink="/corporate/supportuser-create-request">
          <i class="fa fa-user-times orange-dr-icon" aria-hidden="true"></i>Create Request
        </button>
      </div>

      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/supportuser-list-request">
          <i class="fa fa-search red-dr-icon" aria-hidden="true"></i>List Request
        </button>
      </div>
      <div class="col-xl-4  col-md-6">
        <button class="cbtn mt-1" routerLink="/corporate/supportuser-logs">
          <i class="bi bi-list-task blue-lt-icon" aria-hidden="true"></i>Logs
        </button>
      </div>

      <div class="col-xl-4  col-md-6" *ngIf="currentUser.role_id == 248">

        <button class="cbtn mt-1" routerLink="/corporate/ds-approval-list">
          <i class="fa fa-search red-dr-icon" aria-hidden="true"></i>DS Approval List
        </button>
      </div>

    </div>
  </fieldset>
</div>
