<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/sender-profile">Sender Profile</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/sender-profile-list">Sender Profile List</a></li>
            <li class="breadcrumb-item active" aria-current="page">View Sender Profile</li>

        </ol>
    </nav>
</div>

<div class="container">
        <div class="row">
            <div class="col-md-12 col-xl-12">
                <div class="card mt-1">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="mt-1">
                                    <h5 for="exampleForm2"><b>Sender Information :</b></h5> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Sender Name</label>
                                    <input type="text" [(ngModel)]="profileObj.sendername" class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Sender Email</label>
                                    <input type="text" [(ngModel)]="profileObj.senderemail" class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Email Domain</label>
                                    <input type="text" [(ngModel)]="profileObj.emaildomain" class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Status</label>
                                    <input type="text" [(ngModel)]="profileObj.status" class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-1">
                                    <label for="exampleForm2">Comment</label>
                                    <input type="text" [(ngModel)]="profileObj.comment" class="form-control form-control-md"
                                         readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-md-12">
                                <div class="mt-1">
                                    <h5 for="exampleForm2"><b>DNS Records :</b></h5> 
                                </div>
                            </div>
                        </div>
                        <div class="row container ">
                            <div class="tblPrt col-md-10 col-sm-12">
                                <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <!-- <th>S.No.</th> -->
                                        <th>Priority</th>
                                        <th>Type</th>
                                        <th>Key</th>
                                        <th>Value</th>  
                                        <th>Status</th>
                                      </tr>
                                      <tr *ngFor="let mx of profileObj.maxrecord; let i = index" >
                                        <!-- <td>{{i+1}}</td> -->
                                        <td>{{mx?.mxpriority}}</td>
                                        <td>{{mx?.mxtype}}</td>
                                        <td>{{mx?.mxkey}}</td>
                                        <td>{{mx?.mxvalue   }}</td>
                                        <td>{{mx?.status}}</td>
                                      </tr>
                                </table>
                                <div *ngIf="!profileObj.maxrecord" style="text-align: center">
                                  <p>No Result Available</p>
                                </div>
                              </div>
                        </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-md-12">
                                <div class="mt-1">
                                    <h5 for="exampleForm2"><b>Activity Logs :</b></h5> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row container ">
                        <div class="tblPrt col-md-10 col-sm-12">
                            <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>Update Date</th>
                                    <th>Updated By</th>
                                    <th>Sender Profile Status</th>
                                    <th>Remarks</th>
                                  </tr>
                                  <tr *ngFor="let log of senderProfileLog" >
                                    <td>{{log?.createdon | date : 'd MMM, y, h:mm a'}}</td>
                                    <td>{{log.usertype=='ds'?'Directseller':'Admin'}}</td>
                                    <td>{{log?.status}}</td>
                                    <td>{{log?.action}}</td>
                                  </tr>
                            </table>
                            <div *ngIf="senderProfileLog.length<1" style="text-align: center">
                              <p>No Result Available</p>
                            </div>
                          </div>
                    </div>

                 
                </div>
            </div>
        </div>
</div>