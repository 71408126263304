<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">
         BU Selection for Segmentation
      </li>
    </ol>
  </nav>
</div>

<div class="container" >
  <form class="form-horizontal mt-1" [formGroup]="buForm" >
    <div class="row">
      <div class="col-md-10 col-xl-7">

        <div class="card mt-1">

          <div class="card-body">

            <div>

              <label for="buForm">BU List <span class="required">*</span>
              </label>
              <select
                autocomplete="off"
                formControlName="buid"
                class="form-control formstyle"
                              >
                <option value="">Select BU</option>
                <option *ngFor="let obj of getCompanyobj" value="{{obj.customer_id}}">
                  {{ obj.customer_company_name }}
                </option>
              </select>


            </div>

<div style="text-align: center;" >

  <button type="submit" class="custbtn mt-3" (click)="setCompanyDetailsforSegmentation()">
    Submit
</button>

 
</div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
