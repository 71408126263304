import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';



@Component({
  selector: 'app-contentTemplate',
  templateUrl: './content-template.component.html',
  styleUrls: ['./content-template.component.css']
})
export class ContentTemplateComponent extends BaseComponent implements OnInit {
  public themeObj: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "name";
  text;
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  image;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe,
    private clientService: ClientService,
    private cb: CbService,
  ) {
    super();
  }
  ngOnInit() {
    //this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    // if(this.currentUser.admin_customer_id) this.companyID = this.currentUser.admin_customer_id;
    //if(this.currentUser.admin_customer_id!='0') this.getTheme();
    // this.templateLogData();
    this.getCompany();
  }
  order: string = 'name';
  reverse: boolean = false;


  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap(data => this.cb.getCompanyTemplate(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.themeObj = [] }
          else { this.themeObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.themeObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.getCompanyTemplate();
  }

  templateLogData() {
    let themeLogs = {
      log_type: "Template Content Builder Master View",
      log_name: "Template Content Builder Master View",
      log_description: "Template Content Builder Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
      //  console.log(data);

    }, error => {
      //  console.log(error);
    });

  }

  // filterResult() {
  //   this.cp=1;
  //   this.getCompanyTemplate(this.companyID, this.text);
  // }

  getCompanyTemplate() {
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.cb.getCompanyTemplate(this.companyID, this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value).subscribe(response => {
      if (response.data) {
        this.themeObj = response.data.rows;
        this.totalCount = response.data.total;
        this.ngxSpinnerService.hide();
      }
      else { this.themeObj = []; this.ngxSpinnerService.hide(); }
    }, error => {
      // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  fetchCompanyTemplate(id) {
    this.companyID = id;
    if(this.companyID == 0){this.themeObj = [];this.totalCount = 0;this.cp = 1;return;}
    this.getCompanyTemplate();
  }

  editTemplate(id) {

    this.router.navigate(['/corporate/add-content-builder-template'], { queryParams: { companyID: this.companyID, id: id } });
  }
  deleteTemplate(id) {
    let obj = {
      id: id,

    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to Delete ?', 'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.cb.deleteTemplate(this.companyID, id).subscribe(data => {
            if (data.status) {
              this.fetchCompanyTemplate(this.companyID)
              //this.getTheme();
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            //  console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  openImage(image) {
    let self = this
    if (image && image.indexOf("master_images") >= 0) {
      image = image.substring(image.indexOf("master_images"), image.length)
      if (!this.isUrlValid(image)) {
        let awsUrl = self.master.getUrlfile(image);
        this.image = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        this.image = '../../../assets/custom/images/broken.png';
      }
    } else {
      this.image = '../../../assets/custom/images/broken.png';
    }
    this.viewImageService.confirm('View Screenshot', this.image, 'Close')
      .then((confirmed) => {
        //  console.log(confirmed);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = this.themeObj.find(element => {
      if (element.templateid === id) {
        return true;
      }
    });
    //obj = obj.get_master_theme;
    // obj.isactive = value;
    //console.log(obj);


    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;

          //  console.log(obj);

          this.cb.updateTemplateStatus(obj).subscribe(data => {
            // this.getTheme();
            this.fetchCompanyTemplate(this.companyID)
            this.toastrService.success("Template Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          // obj.isactive = confirmed;
          // this.master.UpdateTheme(obj).subscribe(data => {
          //   this.getTheme();
          // });
        }


      }).catch((err) => {
        // console.log(err)
        // event.source.checked = !event.source.checked;
        // this.checked = obj.isactive;
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });


  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getCompany() {

    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }
}
