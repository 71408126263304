<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master-segmentation">Segmentation Master</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Attribute Group List
      </li>
    </ol>
  </nav>
</div>


<div class="container mt-2 themeTblReso">

      <div class="row">
        <div class="col-md-6 mb-md-0 mb-1">
          <div class="input-group" >
            <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
                                                                            aria-hidden="true"></i></span>
            </div>

            <input class="form-control" type="text" placeholder="Search by Group Name" aria-label="Search" #searchText>
          </div>

        </div>
        <div class="col-md-6" style="justify-content: right">
          <input
            type="button"
            value="Add New"
            *ngIf="isWrite"
            class="custbtn mt-md-1 mt-2 ml-0"
            routerLink="/corporate/new-attribute-group"
          />

        </div>
      </div>

  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th class="curPoint"
          (click)="sorting('group_name')"
        >
          Attribute Group Name <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th> Attribute Description </th>
        <th class="curPoint" (click)="sorting('createdon')">Created On
          <i class="fa fa-sort text-black" aria-hidden="true"></i>
        </th>
        <th>Actions</th>
      </tr>

      <tr
        *ngFor="let obj of attrList">
        <td>{{ obj.group_name }}</td>
        <td>{{ obj.group_desc }}</td>
        <td>{{ obj.createdon | date}}</td>
        <td>
          <div class="actBtn">
            <i class="fa fa-edit mr-1 curPoint"
              *ngIf="isEdit"
              (click)="editAttribute(obj.field_group_id)"
              placement="bottom"
              ngbTooltip="Edit">
            </i>
            <i class="fa fa-trash mr-1 curPoint
            " *ngIf="isDelete"
               (click)="deleteAttribute(obj.field_group_id)">
            </i>
            <mat-slide-toggle
              [checked]="obj.isactive"
              *ngIf="isEdit"
              class="my-slider"
              (click)="updateAttributeStatus($event, obj)">
            </mat-slide-toggle>
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="totalCount == 0" style="text-align: center">
      <p>No Result Available</p>
    </div>
    <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
      <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="2" [rotate]="true"
        (pageChange)="getList(page = $event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>

  </div>
