import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { ImportFilesDialogService } from 'src/app/_utils/import-files-dialog/import-files-dialog.service';
import { OrderPipe } from 'ngx-order-pipe';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
@Component({
  selector: 'app-productcatalogmaster',
  templateUrl: './productcatalogmaster.component.html',
  styleUrls: ['./productcatalogmaster.component.css']
})
export class ProductcatalogmasterComponent extends BaseComponent implements OnInit, AfterViewInit {
  public productObj: any = [];
  defaultPicture = 'assets/custom/images/broken.png';
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "product_name";
  checked = false;
  getCompanyobj;
  getDSobj;
  currentUser;
  companyID = 0;
  disCompId: any = 0;
  dsID: any = 0;
  page: any = 'master';
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(private master: MasterService,
    private ImportFilesDialogService: ImportFilesDialogService,
    private orderPipe: OrderPipe,
    private clientService: ClientService
  ) {
    super();
  }
  ngOnInit() {
    // let page;
    this.page = this.master.getCataloguePage();

    // if (localStorage.getItem('page')) {
    //   this.page = localStorage.getItem('page');
    // }
    // console.log("this.page", this.page);


    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.disCompId = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id)
      this.companyID = this.currentUser.admin_customer_id;
    if (this.currentUser.admin_customer_id != '0')
      this.getProductCatalogue();
    this.productLogData();
    this.getCompany();
  }
  order: string = 'get_master_theme.theme_name';
  reverse: boolean = false;



  ngAfterViewInit() {
    // this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
          console.log(this.searchText.nativeElement.value)
        }),
        switchMap(data => this.master.getProductCatalog(this.companyID, this.dsID, this.limit, this.skip, this.searchText.nativeElement.value, '', ''))
      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.productObj = [] }
          else { this.productObj = res.data.rows }
          this.totalCount = res.data.total;
        } else {
          this.productObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  // setOrder(value: string) {
  //   if (this.order === value) {
  //     this.reverse = !this.reverse;
  //   }
  //   this.order = value;
  // }

  sorting(col: string) {

    if (this.companyID != 0) {
      this.colname = col
      if (this.ord == 'asc') {
        this.ord = 'desc'
      } else {
        this.ord = 'asc'
      }
      this.getProductCatalogue();
    } else {
      this.toastrService.error("Please select company");
    }

  }

  productLogData() {
    let themeLogs = {
      log_type: "Product Catalog Master View",
      log_name: "Product Catalog Master View",
      log_description: "Product Catalog Master View",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(themeLogs).subscribe(data => {
    }, error => {
      console.log(error);
    });

  }

  getTheme() {
    let self = this;
    this.ngxSpinnerService.show();
    this.master.getTheme(this.companyID).subscribe(data => {
      self.productObj = data;
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
    });
  }


  getProductCatalogue() {
    if (this.companyID == 0) { return }
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master.getProductCatalog(this.companyID, this.dsID, this.limit, this.skip, this.searchText.nativeElement.value, this.colname, this.ord).subscribe(response => {
      this.ngxSpinnerService.hide();
      if (response.status) {
        self.productObj = response.data.rows;
        self.totalCount = response.data.total;
      }

    }, error => {
      this.ngxSpinnerService.hide();
    });
  }


  getCompanyThemes(id) {
    this.ngxSpinnerService.show();
    if (id) {
      this.companyID = id;
      let self = this;
      this.master.getCompanyTheme(id).subscribe(data => {
        self.productObj = data.data.rows;
        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
    } else {
      this.productObj = [];
      this.ngxSpinnerService.hide();

    }
  }


  getProductCatalog(id) {
    // this.dsID = id;
    if (id != 0) {
      this.companyID = id;
      this.getProductCatalogue();
    } else {
      this.productObj = [];
      this.totalCount = 0;
      this.cp = 1;
      // this.ngxSpinnerService.hide();

    }
  }



  editCatalog(id) {
    this.router.navigate(['/corporate/newproductcatalog'], { queryParams: { companyID: this.companyID, id: id } });
  }

  addUpdateCrosssell(id) {
    this.router.navigate(['/corporate/crosssellproductcatalogue'], { queryParams: { companyID: this.companyID, id: id } });
  }

  deleteCatalogOLD(id) {
    let obj = {
      id: id,
    }
    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          //obj.isdelete = !obj.isdelete;
          //obj.company = this.companyID;

          this.master.delCatalog(this.companyID, id).subscribe(data => {
            if (data.status) {
              this.getProductCatalog(this.companyID)
              setTimeout(() => {
                this.toastrService.success("Deleted Successfully");
              }, 1000)
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            // console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }


  deleteCatalog(event, id) {
    event.preventDefault();
    let self = this;
    let obj = this.productObj.find(element => {
      if (element.product_catalog_id === id) {
        return true;
      }
    });

    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isdelete = !obj.isdelete;
          obj.company = this.companyID;
          this.master.UpdateCatalogStatus(obj, this.companyID, obj.product_catalog_id).subscribe(data => {
            // self.getCompanyThemes(this.companyID);
            self.getProductCatalog(this.companyID);
            this.toastrService.success("Product Catalogue delete Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          this.toastrService.error(" error : update catalogue");
        }

      }).catch((err) => {
        // this.toastrService.error(err);
      });


  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.productObj.find(element => {
      if (element.product_catalog_id === id) {
        return true;
      }
    });

    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;
          this.master.UpdateCatalogStatus(obj, this.companyID, obj.product_catalog_id).subscribe(data => {
            this.toastrService.success("Product Catalogue Status Changed Successfully ")
            this.ngxSpinnerService.hide();
          });
        } else {
          this.toastrService.error(" error : update catalogue");
        }

      }).catch((err) => {


        this.toastrService.error(err);


      });


  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : '../../../assets/custom/images/broken.png';
      } else {
        event.target.src = '../../../assets/custom/images/broken.png';
      }
    } else {
      event.target.src = '../../../assets/custom/images/broken.png';
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }
  // chgord(col) {
  //   this.ord = !this.ord
  //   this.colname = col
  // }
  importFile() {
    let self = this;
    if (self.companyID != 0) {
      let tablename = {
        "tableName": "product_catalog_id",
        "cid": this.companyID
      }
      self.ImportFilesDialogService.confirm1('PLEASE UPLOAD A CSV FILE USING THE SAMPLE FORMAT ', `../../../assets/margo-import-files/margo-product-catalogue-import.csv`, 'addingProductCatalogue', this.companyID)
        .then(data => {
          self.getProductCatalogue();
        })
        .catch(err => {

        })
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }


  exportFile() {
    if (this.companyID != 0) {
      let tablename = {
        "tableName": "product_catalog_tbl",
        "cid": this.companyID
      }
      this.master.exportBulk(tablename).subscribe(dataFile => {
        console.log("--dataFile--", dataFile);
        if (dataFile.status) {
          let selBox = document.createElement('a');
          var att = document.createAttribute("href");
          att.value = dataFile.file;
          var att1 = document.createAttribute("download");
          selBox.setAttributeNode(att);
          selBox.setAttributeNode(att1);
          document.body.appendChild(selBox);
          selBox.click();
        }
      }, err => {
        this.toastrService.error(err.message);
      });
    } else {
      this.toastrService.error('Please Select The Company!');
    }

  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      //  console.log(self.getCompanyobj);
    }, error => {
      //  console.log(error);
    });
  }

  getDSLIST(id) {
    if (this.page == 'master') {
      this.companyID = id;
      this.dsID = 0;
      this.getProductCatalogue();
    } else {
      this.getDSobj = [];
      this.productObj = [];

      if (id != 0) {
        this.companyID = id;
        this.getDirectSeller();
      } else {
        this.productObj = [];
        this.totalCount = 0;
        this.cp = 1;
      }
    }
  }

  getDirectSeller() {
    let self = this;
    this.ngxSpinnerService.show();
    //let company;
    let limit = 100;
    let skip = 0;
    this.clientService.getBUDSList(this.companyID, limit, skip).subscribe(data => {
      if (data.status) {
        self.getDSobj = data.data.rows;
      } else {
        self.getDSobj = [];
      }
      this.ngxSpinnerService.hide();

      console.log("self.getDSobj", self.getDSobj);
    }, error => {
      //  console.log(error);
    });
  }

  productUpload() {
    let self = this;
    if (self.companyID != 0) {
      self.ImportFilesDialogService.confirm1('PLEASE UPLOAD MULTIPLE PRODUCT IMAGES', `update_bulk_product_images`, 'addingThemeBulk', this.companyID)
        .then(data => {
          // self.getTheme();
        })
        .catch(err => {
          // console.log("error in product upload model");
        })
    } else {
      this.toastrService.error('Please Select The Company!');
    }
  }






}
