<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/webinar">Webinar Module</a></li>
            <li class="breadcrumb-item"><a routerLink="/corporate/templates">Templates</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{!isUpdated?'Add':'Update'}} Template</li>

        </ol>
    </nav>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-8">
            <form class="form-horizontal" [formGroup]="addTemplateForm">
                <div class="card">
                    <div class="card-body">

                        <div class="form-group">

                            <label for="exampleForm2">Company Name <span class="required">*</span></label>

                            <select formControlName="company" class="form-control formstyle ssss"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                                <option value="">Select Company Name</option>
                                <ng-container *ngFor="let Obj of getCompanyobj">
                                    <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                                        {{Obj.customer_company_name}}
                                    </option>
                                </ng-container>
                            </select>

                            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                <div *ngIf="f.company.errors.required">Company is required</div>
                            </div>
                        </div>

                        <input *ngIf="customer_id!=0" type="hidden" formControlName="company"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">

                        <div class="form-group">
                            <label for="name">Template Name <span class="required">*</span></label>
                            <input type="text" class="form-control" name="name" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="50" appSpace>
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Name is required
                                </div>
                                <div *ngIf="f.name.errors?.maxlength">
                                    You reached to maxlength
                                </div>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="name">Template Description</label>
                            <textarea class="form-control text-area h-25" rows="5" maxlength="100" appSpace
                                formControlName="description"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors?.maxlength">
                                    You reached to maxlength
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                <div class="col-12">
                  
                  
                </div>
              </div>   -->
                        <!-- <div class="form-group">
                            <label>Section <span class="required">*</span> </label>
                            <select formControlName="sections" class="form-control formstyle ssss"
                            [ngClass]="{ 'is-invalid': submitted && f.sections.errors }">
                            <option value="">Select Section</option>
                            <ng-container *ngFor="let section of webinarSection">
                                <option  value={{section?.isactive}}>
                                    {{section.name}}
                                </option>
                            </ng-container>
                        </select>

                        </div> -->

                        <div class="form-group">
                            <label>Section <span class="required">*</span> </label>
                            <br>
                              <div class="form-check " *ngFor="let option of webinarSection; let i = index">
                                <input #checkOption class="form-check-input" type="checkbox"
                                 name="options" id="optionCheck{{i}}" value="option1"  
                                [ngClass]="{ 'is-invalid': submitted && f.sections.errors}"
                                (change)="onChange(option.id,checkOption.checked)"
                                [checked]="checkedfunction(option.id)"
                                >
                                <label class="form-check-label" for="optionCheck{{i}}">
                                 {{ option.name }}
                                </label>
                              </div>

                         </div>     
                        <div class="form-group">
                            <label>Background Image <span class="required">*</span> </label>
                            <br>
                            <img src="{{logoAWSkey}}" alt="logo" (error)="setValue($event)" height="50px">
                            <input #imageInput type="file" class="form-control" name="background_image" 
                            [ngClass]="{ 'is-invalid': submitted && f.background_image.errors }" accept=".png, .jpg, .jpeg"  formControlName="background_image"
                                (change)="processFile($event,imageInput)" >
                            <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                            <div *ngIf="submitted && f.background_image.errors" class="invalid-feedback">
                                <div *ngIf="f.background_image.errors.required">Please select image</div>
                            </div>
                            <br>



                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="text-center">
                            <button class="btn btn-round btn-margo-action" *ngIf="!isUpdated" (click)="addTemplate()">Save
                            </button>
                            <button class="btn btn-round btn-margo-action" *ngIf="isUpdated" (click)="updateTemplate()">Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>