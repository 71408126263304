import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
import { CbService } from 'src/app/services/contentbuilder/contentbuilder.service';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';

@Component({
  selector: 'app-contentbuildfield',
  templateUrl: './contentbuildfield.component.html',
  styleUrls: ['./contentbuildfield.component.css']
})
export class ContentbuildfieldComponent extends BaseComponent implements OnInit {
  currentUser;
  companyId;
  addfieldForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  datavalue:any;
  allTablesob="";
  getCompanyobj:any=[];
  fieldName;
  fieldType;

  constructor(
    private formBuilder: FormBuilder,
    private orderPipe: OrderPipe,
    private cb: CbService,
    private clientService:ClientService,
    ) { super();}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
   this.companyId= this.currentUser.admin_customer_id;
    this.addfieldForm = this.formBuilder.group({
      company: ['', Validators.required],
      field_name: ['', Validators.required],
      datatype: ['', Validators.required]
  });

  this.activatedRoute.queryParams
      .subscribe(params => {
        this.fieldName = params.fieldName;
        this.fieldType = params.fieldType;
        this.companyId = params.company;
      });
      if(this.fieldName)  this.dataBinding();
      
  //this.addField();
  this.getCompany();
  }
  get f() { return this.addfieldForm.controls; }

  dataBinding() {
  
      this.addfieldForm = this.formBuilder.group({
        company: [this.companyId, Validators.required],
        field_name: [this.fieldName.toString(), Validators.required],
        datatype: [this.fieldType.toString(), Validators.required],
        
      });
     
  }


  getCompany() {     
    this.ngxSpinnerService.show();
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
     // console.log(self.getCompanyobj);
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
    });
  }



  addField(){
     
  this.submitted = true;
  // stop here if form is invalid
  if (this.addfieldForm.invalid) {
      return;
  }
  let value = {
      fieldName: this.f.field_name.value,
      datatype: this.f.datatype.value,
      company: this.f.company.value,
  };
  
  this.cb.addingContentBuilderFields(value).subscribe(data => {
  //  console.log(data);
    if (data.status) {
      this.toastrService.success("Record Inserted successfully");
      this.router.navigateByUrl(`/corporate/contentbuilder-view`);
      
    } else {
      this.toastrService.error(data.message)
    }
   // alert(data);
    
  }, error => {
  //  console.log(error);
  });}

 editField(){
     
    this.submitted = true;
    // stop here if form is invalid
    if (this.addfieldForm.invalid) {
        return;
    }
    let value = {
        fieldName: this.f.field_name.value,
        datatype: this.f.datatype.value,
        company: this.f.company.value,
        oldFieldName: this.fieldName,
    };
    
    this.cb.updateContentBuilderFields(value).subscribe(data => {
     // console.log(data);
      if (data.status) {
        this.toastrService.success("Record Updated successfully");
        this.router.navigateByUrl(`/corporate/contentbuilder-view`);
        
      } else {
        this.toastrService.error(data.message)
      }
     // alert(data);
      
    }, error => {
     // console.log(error);
    });}


 
}
