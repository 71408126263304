import { Component, OnInit } from '@angular/core';
import { NgFlowchart, NgFlowchartStepComponent } from '@joelwenzel/ng-flowchart';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-route-step',
  templateUrl: './route-step.component.html',
  styleUrls: ['./route-step.component.css']
})
export class RouteStepComponent  extends NgFlowchartStepComponent {
  // rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  // rskmodellogo = this.ReskinningService.rskModelLogo;
  routes = [];
  conditions = [
    { id: 1, name: 'Contact campagin' },
    { id: 2, name: 'Contact device' },
    { id: 3, name: 'Contact segment' },
    { id: 4, name: 'Contact owner' },
    { id: 5, name: 'Contact tags' }
  ]
  showNewRouteOption: boolean = false;
  newRouteCondition = null;
  conditionValue = null;
  actionValue = null;
  activeButton = 'immediately';
  executionData = { type: '', value: '' }
  conditionFlowType = '';
  constructor(private ngbModal: NgbModal) {
    super();
  }
  ngOnInit(): void {
    console.log(this.data);

  }

  getDropPositionsForStep(step: NgFlowchart.Step): NgFlowchart.DropPosition[] {
    if (step.type !== 'route-step') {
      return ['BELOW']
    }
    else {
      return ['LEFT', 'RIGHT'];
    }

  }
  onAddRoute() {
    if (!this.actionValue) {
      return;
    }
    if (this.activeButton != 'immediately' && !this.executionData.value) { return };
    if (this.activeButton == 'date-time') { this.executionData.type = 'date' };

    let selectedAction = this.conditions.filter((e) => e.id == this.actionValue)
    let route = {
      name: selectedAction[0].name,
      condition: '',
      sequence: null,
      nodeType: 'action',
      executionType: '',
      executionData: {},
      conditionId: selectedAction[0].id,
      conditionFlowType: ''
    }
    let index = this.routes.push(route);
    route.sequence = index;
    route.executionType = this.activeButton;
    route.executionData = this.executionData;
    route.conditionFlowType = this.conditionFlowType


    this.addChild({
      template: RouteStepComponent,
      type: 'route-step',
      data: route
    }, {
      sibling: true
    });
    this.ngbModal.dismissAll();
  }
  onAddNewRoute(modalEle: HTMLElement) {
    if (this.hasChildren() && this.data.nodeType == 'action') {
      return;
    }

    this.showNewRouteOption = false;
    this.ngbModal.open(modalEle, { size: 'lg' });
  }

  createConditionRoutes() {
    if (!this.conditionValue) {
      return;
    }

    if (this.activeButton != 'immediately' && !this.executionData.value) { return };
    if (this.activeButton == 'date-time') { this.executionData.type = 'date' }

    let conditionObj = this.conditions.filter((e) => e.id == this.conditionValue)
    let route = {
      name: conditionObj[0].name,
      condition: '',
      sequence: null,
      nodeType: 'condition',
      conditionId: 0,
      executionType: '',
      executionData: {},
      conditionFlowType: ''
    }
    console.log();

    let index = this.routes.push(route);
    route.sequence = index;
    route.conditionId = conditionObj[0].id;
    route.executionType = this.activeButton;
    route.executionData = this.executionData;
    route.conditionFlowType = this.conditionFlowType;

    this.addChild({
      template: RouteStepComponent,
      type: 'route-step',
      data: route
    },
      {
        sibling: true
      });
    this.ngbModal.dismissAll();
  }

  resetValues() {
    this.activeButton = 'immediately';
    this.executionData = { type: '', value: '1' };
  }
  delete() {
    this.destroy();
  }
  onAddNewConditionRoute(modalEle: HTMLElement, conditionFlowType) {
    this.conditionFlowType = conditionFlowType;
    let childrenData = this.children.map((e: any) => {
      return e.data
    });
    let res = childrenData.find((e) => { return e.conditionFlowType == this.conditionFlowType })
    if (res) {
      return;
    }
    if (this.hasChildren() && this.data.nodeType == 'action') {
      return;
    }
    this.showNewRouteOption = false;
    this.ngbModal.open(modalEle, { size: 'lg' });
  }
}
