<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/corporate/master">Master module</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Support Subject Library
      </li>
    </ol>
  </nav>

  <div class="row">
    <div class="col-md-6 mt-2">
      <div>
        <select name="company" class="form-control formstyle" (change)="changeSubjectLib($event.target.value)">
          <option value="">Select Company Name</option>
          <ng-container *ngFor="let userObj of getCompanyobj">
            <option *ngIf="userObj.dbstatus == true" value={{userObj.customer_id}}>
              <!-- <option
            *ngFor="let userObj of getCompanyobj"
            value="{{ userObj.customer_id }}"
          > -->
              {{ userObj.customer_company_name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text cyan lighten-2" id="basic-text1"><i class="fa fa-search text-white"
              aria-hidden="true"></i></span>
        </div>

        <input class="form-control" type="text" placeholder="Search" aria-label="Search" #searchText />
      </div>
    </div>

    <div class="col-md-4" style="justify-content: right">
      <input type="button" value="Add New" *ngIf="isWrite" class="custbtn mt-md-1 ml-0" routerLink="/corporate/newsubjectlib" />

      <button class="csvBtn ml-1" (click)="importFile()" *ngIf="isImport">
        <img class="customimg" src="../../../assets/custom/images/import.png" style="height: 45px; margin-top: -8px;">
      </button>
      <button class="csvBtn" (click)="exportFile()" *ngIf="isExport">
        <img class="customimg" src="../../../assets/custom/images/export.png" style="height: 45px; margin-top: -8px;">
      </button>
    </div>

    <div class="tblPrt col-12">
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
        <tr>
          <th (click)="sorting('subject_name')" class="curPoint">
            Subject Name <i class="fa fa-sort text-black" aria-hidden="true"></i>
          </th>

          <!--<th width="200">ModifiedBy</th>-->
          <th width="20%">Created Date</th>
          <th width="25%" *ngIf="isEdit || isDelete">
            Actions
          </th>
        </tr>

        <tr *ngFor="let obj of subjectlibObj">
          <!-- <td>{{obj.get_master_theme.theme_id}}</td> -->

          <td>{{ obj.subject_name }}</td>

          <td>{{ obj.createdon | date: "mediumDate" }}</td>
          <!--<td>{{obj.get_master_theme.modifiedname }}</td>
          <td>{{obj.get_master_theme.modifiedon |date}}</td>-->

          <td *ngIf="isEdit || isDelete">
            <div class="actBtn">
              <i class="curPoint fa fa-edit mr-2" *ngIf="isEdit" (click)="editSubjectlib(obj.help_subject_id)"></i>
              <span class="curPoint fa fa-trash mr-2" *ngIf="isDelete"
                (click)="deleteSubjectlib(obj.help_subject_id)"></span>
              <mat-slide-toggle class="my-slider" [checked]="obj.isactive" *ngIf="isEdit"
                (change)="updateSubjectlibStatus($event, obj.help_subject_id)">
              </mat-slide-toggle>
            </div>
          </td>
        </tr>
      </table>

      <div style="text-align: center;" *ngIf="totalCount == 0">
        <p>No Result Available</p>
      </div>
      
      <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
        <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="2" [rotate]="true" (pageChange)="getSubjectlib((cp=$event))" aria-label="Custom pagination">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination> 
      </div>

    </div>

  </div>

</div>