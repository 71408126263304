
<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
            <li class="breadcrumb-item">
                <a routerLink="/corporate/events">Manage Event</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{header}} event
            </li>
        </ol>
    </nav>
</div>

<div class="container">
    <div class="row">
      <div class="col-12 col-xl-9 col-lg-10 mt-1">
        <div class="card">
          <div class="card-content">
            <form [formGroup]="EventForm">
              <div class="card-body">
                <div class="row formSpace">
                  <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="exampleForm2 ">Category <span class="required">*</span></label>
                            <select formControlName="parent_event_type" (change)="addEventFormControl($event.target.value)"
                            class="form-control formstyle" [(ngModel)]="parentjourney" 
                            [ngClass]="{ 'is-invalid': submitted && f.parent_event_type.errors }" id="parent_event_type"
                             required  >
                             <option value="" selected disabled>Select event category</option>
                             <option value=127>In-Person</option>
                             <option value=128>Virtual</option>
                             <option value=129>In-Person & Virtual</option>
                            </select>
                            <!-- <input *ngIf="header=='Update'"  type="text" class="form-control" [value]="getTypeName()" readonly> -->
                            <div *ngIf="submitted && f.parent_event_type.errors" class="invalid-feedback">
                                <div *ngIf="f.parent_event_type.errors.required">Event category is required
                                </div>
                            </div>
    
                          </div>
                        </div>
                      </div>                    <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="exampleForm2 ">Contact <span class="required">*</span></label>
                            <select formControlName="contact"
                            class="form-control formstyle" [(ngModel)]="selectedContact"
                            [ngClass]="{ 'is-invalid': submitted && f.contact.errors }" id="contact"
                             required >
                             <option value="" selected disabled>Select contact</option>
                             <option [value]="contact.contact_id" *ngFor="let contact of contactList">{{contact?.contact_fname}} {{contact?.contact_lname}}</option>
                          </select>
                            <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                                <div *ngIf="f.contact.errors.required">Contact is required
                                </div>
                            </div>
    
                          </div>
                        </div>
                      </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="exampleForm2 ">Event Name <span class="required">*</span></label>
                          <input type="text" autocomplete="off" required class="form-control" [(ngModel)]="event_name"
                            id="event_name" formControlName="valEventName" (keyup)="checkEventNameLength($event)"
                            maxLength="500" [ngClass]="{ 'is-invalid': submitted && f.valEventName.errors}" required>
  
                          <div *ngIf="submitted && f.valEventName.errors" class="invalid-feedback">
                            <div *ngIf="f.valEventName.errors.required">Event Name is required</div>
                          </div>
  
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="exampleForm2 ">Date <span class="required">*</span></label>
                          <input class="form-control formstyle" matInput [matDatepicker]="pickerEv" readonly
                          formControlName="valEventDate" [ngClass]="{ 'is-invalid': submitted && f.valEventDate.errors }" [min]="minDate"
                          [(ngModel)]="event_date" required>
                          <mat-datepicker-toggle matSuffix [for]="pickerEv" class="cldrIcon"></mat-datepicker-toggle>
                          <mat-datepicker #pickerEv></mat-datepicker>
                          <div *ngIf="submitted && f.valEventDate.errors" class="invalid-feedback">
                            <div *ngIf="f.valEventDate.errors.required">Date is required</div>
                            <div *ngIf="f.valEventDate.errors.date">Date & time should be greater than or equal to today's
                              date & time.</div>
                            <div *ngIf="f.valEventDate.errors.matDatepickerMin">Date should be greater than or equal to today
                              date.</div>
                          </div>
  
                        </div>
                      </div>
                    </div>
  
  
  
                    <div class="row">
                      <div class="col-sm-8 selctTime">
                        <div class="form-group">
                            <label for="exampleForm2 ">Time <span class="required">*</span></label>
                            <ngb-timepicker class="minTimeW" [readonlyInputs]="'readOnly'"
                            formControlName="time" [(ngModel)]="time" [meridian]="true"
                            [minuteStep]="5"></ngb-timepicker>
                          <div *ngIf="submitted && f.time.errors" class="invalid-feedback">
                            <div *ngIf="f.time.errors.required">Time is required</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="exampleForm2" class="col-12">Time Zone <span class="required">*</span></label>
                            <div class="col-12">
                                <!-- <mat-form-field class="w-100">
                                                                    <mat-select class="form-control" formControlName="valEventTimeZone"
                                                                      [(ngModel)]="event_time_zone" #singleSelect
                                                                      [ngClass]="{ 'is-invalid': submitted && f.valEventTimeZone.errors }">
                                                            
                                                                      <mat-option *ngFor="let timezonedata of filteredTimezones "
                                                                        [value]="timezonedata.value">
                                                                        {{timezonedata.text}}
                                                                      </mat-option>
                                                                    </mat-select>
                                                                    
                                                                    <div *ngIf="submitted && f.valEventTimeZone.errors" class="invalid-feedback">
                                                                      <div *ngIf="f.valEventTimeZone.errors.required">Event time zone is required</div>
                                                                    </div>
                                                                  </mat-form-field> -->
                    
                    
                                <select formControlName="valEventTimeZone" class="form-control formstyle" [(ngModel)]="event_time_zone"
                                    [ngClass]="{ 'is-invalid': submitted && f.valEventTimeZone.errors }" id="valEventTimeZone">
                                    <option value="" selected disabled>Select time zone</option>
                                    <option *ngFor="let timezonedata of timezones" [value]="timezonedata.value"> {{timezonedata.text}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.valEventTimeZone.errors" class="invalid-feedback">
                                    <div *ngIf="f.valEventTimeZone.errors.required">Time zone is required
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    
                    </div>
  
                    <div class="row addrsZone" *ngIf="parentjourney!=128" >
                      <div class="col-12">
  
                        <div class="form-group">
                          <label for="formExapmple2">Address 1 <span class="required">*</span></label>
                          <input type="text" autocomplete="off" class="form-control" [(ngModel)]="event_address1"
                            id="event_address1" formControlName="valAddress1"
                            [ngClass]="{ 'is-invalid': submitted && f.valAddress1.errors}" required (blur)="drawMap()">
                          <div *ngIf="submitted && f.valAddress1.errors" class="invalid-feedback">
                            <div *ngIf="f.valAddress1.errors.required">Event address is required</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Address 2</label>
                          <input type="text" autocomplete="off" class="form-control" [(ngModel)]="event_address2"
                            [ngModelOptions]="{standalone: true}" id="event_address2" (blur)="drawMap()" />
  
                        </div>
                        <div class="form-group">
                          <label for="">Country <span class="required">*</span></label>
                          <select formControlName="valcountry" class="form-control" [(ngModel)]="event_country"
                            placeholder="event country" [ngClass]="{ 'is-invalid': submitted && f.valcountry.errors }"
                            (change)="getStatesOfCountry($event.target.value)" required>
                            <option  value="">--Select Country-- </option>
                            <option *ngFor="let Obj of countriesData" [value]="Obj.id">
                              {{Obj.name}}
                            </option>
                          </select>
                          <div *ngIf="submitted && f.valcountry.errors" class="invalid-feedback">
                            <div *ngIf="f.valcountry.errors.required">Country is required</div>
                          </div>
                        </div>
                        <div class="form-group">
  
                          <label for="">State <span class="required">*</span></label>
                          <select formControlName="valstate" class="form-control formstyle"
                            (change)="getCitiesOfState($event.target.value,f.valcountry.value)" [(ngModel)]="event_state"
                            [ngClass]="{ 'is-invalid': submitted && f.valstate.errors }" placeholder="State" required>
                            <option selected value="">--Select State-- </option>
                            <option *ngFor="let Obj of stateData" [value]="Obj.id">
                              {{Obj.name}}
                            </option>
                          </select>
                          <div *ngIf="submitted && f.valstate.errors" class="invalid-feedback">
                            <div *ngIf="f.valstate.errors.required">State is required</div>
                          </div>
  
                        </div>
  
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">City <span class="required">*</span></label>
                              <select class="form-control" [(ngModel)]="event_city" formControlName="valEventCity"
                                (blur)="drawMap()" [ngClass]="{ 'is-invalid': submitted &&  f.valEventCity.errors }"
                                placeholder="City" required>
  
                                <option selected value="">--Select City-- </option>
                                <option *ngFor="let Obj of cityData" [value]="Obj.id">
                                  {{Obj.name}}
                                </option>
                              </select>
                              <div *ngIf="submitted && f.valEventCity.errors" class="invalid-feedback">
                                <div *ngIf="f.valEventCity.errors.required">City is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Zip Code <span class="required">*</span></label>
                              <input type="text" autocomplete="off" class="form-control" [(ngModel)]="event_zip"
                                formControlName="valZipCode" [ngClass]="{ 'is-invalid': submitted && f.valZipCode.errors}"
                                required>
  
                              <div *ngIf="submitted && f.valZipCode.errors" class="invalid-feedback">
                                <div *ngIf="f.valZipCode.errors.required">Zip Code is required</div>
                                <div *ngIf="f.valZipCode.errors.pattern">Number Only</div>
                                <div *ngIf="f.valZipCode.errors.maxlength">Zipcode maxlength (10) reached</div>
                                <div *ngIf="f.valZipCode.errors.ziperror">Zipcode must be {{f.valZipCode.errors.zipcodeminlength}}  characters</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group" *ngIf="parentjourney==128">
                      <label for="formExample2">Event URL <span class="required">*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="event_url" placeholder="http://example.com"
                        formControlName="valEvent_URL" [ngClass]="{ 'is-invalid': submitted && f.valEvent_URL.errors}"
                        >
                      <div *ngIf="submitted && f.valEvent_URL.errors" class="invalid-feedback">
                        <div *ngIf="f.valEvent_URL.errors.required">Event URL is required</div>
                        <div *ngIf="f.valEvent_URL.errors.validUrl">Enter valid Event URL</div>
                      </div>

                      <button type="button" class="btn btn-round btn-margo-action mt-1 mb-1"
                        (click)="getslEventUrl()">ShopLive Event
                      </button>
                    </div>
  
                    <div  class="form-group" *ngIf="parentjourney==128">
  
                      <!-- <h5 class=" font-weight-bold">Event Type 
                        <i class="fa fa-info-circle" aria-hidden="true" (click)="viewMoreRequests(viewEventInfo)"></i>
                      </h5> -->
                      <span>
                        <label for="">Event Type </label>
                        <i class="fa fa-info-circle ml-1" aria-hidden="true" (click)="viewMoreRequests(viewEventInfo)"></i>
                      </span>
                      <div class="cardsDet">
                        <label class="custRadioLeft d-inline-block ml-1">
                          <input  type="radio" name="valEvent_Type" [(ngModel)]="manyToMany" formControlName="valEvent_Type" [value]='false'>
                          Webinar Demo
                          <span class="radiomarkLeft"></span>
                        </label>
  
                        <label class="custRadioLeft d-inline-block ml-1">
                          <input type="radio" name="valEvent_Type" [(ngModel)]="manyToMany" formControlName="valEvent_Type" [value]='true'>
                          Interactive Demo
                          <span class="radiomarkLeft"></span>
                        </label>
  
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-6 mt-2 mt-md-0">
                    <div class="form-group">
                      <label>Shopping URL </label>
                      <input type="text" class="form-control" [(ngModel)]="shopping_url"
                        [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="form-group">
                      <label>Host URL </label>
                      <input type="text" class="form-control" [(ngModel)]="host_url"
                        [ngModelOptions]="{standalone: true}">
                    </div>
  
                    <div class="form-group">
                      <label>Event ID </label>
                      <input type="text" class="form-control" autocomplete="off" (paste)="onPaste($event)"
                        [(ngModel)]="event_url_id" formControlName="valEventID"
                        [ngClass]="{ 'is-invalid': submitted && f.valEventID.errors}">
                      <div *ngIf="submitted && f.valEventID.errors" class="invalid-feedback">
                        <div *ngIf="f.valEventID.errors.pattern">Event ID is numeric only</div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="parentjourney==129">
                      <h6 class="font-weight-bold">Virtual Information
                        <i class="fa fa-info-circle" aria-hidden="true" (click)="viewMoreRequests(viewVirtualInfo)"></i>
                      </h6>
                      <label for="">Event URL <span class="required">*</span></label>
                      <input type="text" class="form-control" placeholder="http://example.com"
                        formControlName="valEventURL" [(ngModel)]="event_url"
                        [ngClass]="{ 'is-invalid': submitted && f.valEventURL.errors}" required>
                      <div *ngIf="submitted && f.valEventURL.errors" class="invalid-feedback">
                        <div *ngIf="f.valEventURL.errors.required">Event URL is required</div>
                        <div *ngIf="f.valEventURL.errors.validUrl">Enter valid Event URL</div>
                      </div>
  
  
  
                      <button type="button" class="btn btn-round btn-margo-action mt-1 mb-1"
                        (click)="getslEventUrl()">ShopLive Event
                      </button>
  
  
                    </div>
  
                    <div class="form-group" *ngIf="parentjourney==129">
                      <span>
                    <label for="">Event Type </label>
                    <i class="fa fa-info-circle ml-1" aria-hidden="true" (click)="viewMoreRequests(viewEventInfo)"></i>
                  </span>
                      <!-- <h6 class="font-weight-bold">Event Type
                        <i class="fa fa-info-circle" aria-hidden="true" (click)="viewMoreRequests(viewEventInfo)"></i>
                      </h6> -->
                      <div class="cardsDet">
                        <label class="custRadioLeft d-inline-block ml-1">
                          <input  type="radio" name="valEvent_Type" [(ngModel)]="manyToMany" formControlName="valEvent_Type" [value]='false'>
                          Webinar Demo
                          <span class="radiomarkLeft"></span>
                        </label>
  
                        <label class="custRadioLeft d-inline-block ml-1">
                          <input type="radio" name="valEvent_Type" [(ngModel)]="manyToMany" formControlName="valEvent_Type" [value]='true'>
                          Interactive Demo
                          <span class="radiomarkLeft"></span>
                        </label>
  
                      </div>
                    </div>
                    <!-- <div class="form-group" *ngIf="parentjourney!==129 && mapURL">
                      <label class="font-weight-bold">Map</label>
                      <div class="mapState mt-1" *ngIf="!mapURL.includes('addressNotFound')">
                        <img src="{{mapURL}}">
                      </div>
                      <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
                        <div class="alert alert-light" role="alert">
                          <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
                        </div>
                      </div>
                    </div> -->
                    <div class="form-group">
                      <label class="col-6 p-0">Description</label>
                      <label _ngcontent-ptn-c35="" class="mt-1 col-6 text-right p-0">{{desShowLengthEvent}} of
                          {{desAllowedLengthEvent}}</label>
                      <textarea class="form-control"rows="3" [(ngModel)]="eventDescription" type='text'
                          (keyup)="countDesLength($event)" [ngModelOptions]="{standalone: true}"></textarea>
  
                  </div>
                    <div class="form-group">
                      <label>Image/Video </label>
                      <div class='form-group' *ngIf="selelctedImage">
                        <img *ngIf="selelctedImage.resource_type=='image'" [src]="selelctedImage?.resource_preview_url" width='100px' height='auto'/>
                        <video *ngIf="selelctedImage.resource_type=='video'" height="auto" width="100%" [controls]='true'>
                          <source [src]="selelctedImage?.resource_preview_url" type="video/mp4">
                          Your browser does not support the video.
                        </video>
                       </div>
                      <div class="button-col">
                        <!-- <a (click)="getResourceImageList();viewResourceImageModal(resourceImage);this._getResourceCategory('')">
                            <input type="button" class="btn btn-toggle-light leftBtnRound flex-fill selected my-0 ml-0"
                            value="{{!this.selelctedImage?'Add Image/Video':'Update Image/Video'}}">
                        </a> -->
                        <button type="button" class="btn btn-round btn-margo-action mt-1 mb-1" 
                        (click)="getResourceImageList('change');viewResourceImageModal(resourceImage);this._getResourceCategory('')">{{!this.selelctedImage?'Add Image/Video':'Update Image/Video'}}
                      </button>
  
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </form>
  
  
  
            <form class="form-horizontal mt-1" [formGroup]="metaForm" *ngIf="metaVisibility">
              <h5 class="mb-1 ml-1 font-weight-bold subTitleHead_3">
                Meta Fields
              </h5>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6" *ngFor="let fd of t.controls; let i = index">
                      <div class="form-group" [formGroup]="fd">
                        <label>{{fd.controls.lname.value}}</label>
                        <input type="text" formControlName="metafname" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && fd.controls.metafname.errors }" />
                        <div *ngIf="fd.controls.metafname.errors">
                          <div *ngIf="fd.controls.metafname.errors.maxlength">
                            <span style="color: red;">
                              <small> *Maximum allowed length reached for the field</small>
                            </span>
                          </div>
                          <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Integer'">
                            <span style="color: red;">
                              <small> *Only Integers 0-9 are Allowed</small>
                            </span>
                          </div>
                          <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Float'">
                            <span style="color: red;">
                              <small> *Only Floating values are Allowed e.g: 123.32</small>
                            </span>
                          </div>
                          <div *ngIf="fd.controls.metafname.errors.boolCheck && fd.controls.ftyp.value === 'Boolean'">
                            <span style="color: red;">
                              <small> *Only true or false values are Allowed</small>
                            </span>
                          </div>
                          <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Tmstamp'">
                            <span style="color: red;">
                              <small> *Timestamp values should be like 2020-11-15T21:11:11.000Z</small>
                            </span>
  
                          </div>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="card-footer">
              <div class="form-group text-center mb-0">
                <!--   <button class="btn btn-round btn-margo-action" (click)="addEvent()">Next</button> -->
                <!-- <a (click)="goBack()" class="btn btn-round btn-margo-action backButtonRight">Back</a> -->
                <button type="submit" class="btn btn-round btn-margo-action" (click)="OnFormSubmit()">Next</button>
                <button type="button" (click)="onCancel()" class="btn btn-round btn-margo-cancel">Cancel</button>
                <!-- <button class="btn btn-round btn-margo-action"  routerLink="/directseller/myevent">Back</button> -->
              </div>
            </div>
  
            <!-- <ng-template #viewVirtualInfo let-modal>
              <div class="wholePop">
                <div class="modal-header dialHead w-100">
                  <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5 class="mb-1 text-center">The Event URL is used for virtual events (ShopLive, Facebook Live, etc.). 
                    Upon landing on this page you have 3 options depending on your event type.</h5>
                  <div class="pt-1">
                    <ul class="infotext">
                      <li><span> Option - 1 </span> Combo event – ensure the Event URL contains an accurate link to the
                        virtual portion of your combo event.  If you want to use ShopLive simply click “Make this a
                        ShopLive event” and it will populate the correct Event Url.</li>
                      <li> <span> Option - 2 </span> Virtual event – ensure the Event URL contains an accurate link to
                        your virtual event.  If you want to use ShopLive simply click “Make this a ShopLive event” and it
                        will populate the correct Event Url.</li>
                      <li> <span> Option - 3 </span> In-person only event – Leave the default URL in place.</li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer text-center d-block border-0">
                  <button type="button" class="btn btn-margo-action btn-round"
                    (click)="modal.dismiss('Cross click')">OK</button>
                </div>
              </div>
            </ng-template>
  
            <ng-template #viewEventInfo let-modal>
              <div class="wholePop">
                <div class="modal-header dialHead w-100">
                  <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5 class="mb-1 text-center">Event Type</h5>
                  <div class="pt-1">
                    <ul class="infotext">
                      <li><span> Webinar Demo </span>is an online event that is hosted by an organizer/host and broadcast to a select group of individuals. The audience can ask questions in real-time through an instant messaging tool or e-mail.</li>
                      <li> <span> Interactive Demo </span>allows larger groups to generate questions, suggestions, and solutions all at the same time, making sure all participants have an opportunity to contribute. All participants can Enable Disable their Mic and camera.</li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer text-center d-block border-0">
                  <button type="button" class="btn btn-margo-action btn-round"
                    (click)="modal.dismiss('Cross click')">OK</button>
                </div>
              </div>
            </ng-template> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #resourceImage let-modal>
    <div class="scroller">
        <div class="wholePop filtMain" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="imgResourceOnCat('scroll')" [scrollWindow]="false">
            <div class="modal-header dialHead w-100">
                <h4 class="modal-title mb-0 float-left">Add Image/Video</h4>
                <button *ngIf="!isimageUpdate" type="button" class="close" aria-label="Close" (click)="this.selelctedImage=null;modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="scroller1">
                    <div class="row ">
                        <div class="row col-md-12 text-right mb-3" *ngIf="!isimageUpdate">
                          <div class="col-md-6">
                            <select class="form-control"  [(ngModel)]="selectedImgCat" (change)="imgResourceOnCat('change')">
                               <option value="" selected >All Categories</option>
                               <option *ngFor="let item of imageCategoryList" value="{{item.cate_id}}">
                                   {{item.category_name}}
                               </option>
                            </select>
                          </div>
                          <div class="col-md-6 text-right">
                            <button (click)="_getResourceCategory('');isimageUpdate=!isimageUpdate" type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                             >Add New Image/Video
                           </button>
                          </div>
                        </div>
                        <ng-container *ngIf="!isimageUpdate">
                          <div class="col-md-3 text-center popDialImg" *ngFor="let res of imageList">
                            <ng-container *ngIf="res.resource_type=='image'">
                              <label [for]="res.res_id">
                                <img [src]="res?.resource_preview_url" class='mb-1'
                                  [alt]="res?.resource_title" height="auto" width="100%"/>
                              </label>
                              <p><input type="radio" name="selectedImage" [id]='res.res_id' (change)="this.selelctedImage=res" [checked]="res.res_id==selelctedImage?.res_id"> {{res?.resource_title}}</p>
                            </ng-container>
                            <ng-container *ngIf="res.resource_type=='video'">
                              <label [for]="res.res_id">
                                <video class='mb-1' height="auto" width="100%" [controls]='true'>
                                  <source [src]="res?.resource_preview_url" type="video/mp4">
                                  Your browser does not support the video.
                                </video>
                              </label>
                              <p><input type="radio" name="selectedImage" [id]='res.res_id' (change)="this.selelctedImage=res" [checked]="res.res_id==selelctedImage?.res_id"> {{res?.resource_title}}</p>
                            </ng-container>
                        </div>
                        <p class='col-md-12 text-center' *ngIf="!imageList">No Records Available !!</p>
                        </ng-container>
                        <div class="col-md-12" *ngIf="isimageUpdate">
                          <div class="col-md-12">
                            <form [formGroup]="imageForm">
                              <div class="form-group">
                                <label class="txtBtn float-left">Save To Library: <span class="required">*</span></label>
                                <label class="txtBtn float-right">
                                    <a (click)="_createCategoryForm();viewCategory(categorymodel);_getResourceCategory('')" class="txtBtn float-right blue-lt-txt">Add Categories</a>
                                </label>
                                <select class="form-control mt-1" formControlName="cate_id"
                                    [ngClass]="{ 'is-invalid': imageSubmitted && res.cate_id.errors }">
                                    <option value="" selected hidden disabled>All Categories</option>
                                    <option *ngFor="let item of imageCategoryList" value="{{item.cate_id}}">
                                        {{item.category_name}}
                                    </option>
                                </select>
                                <div *ngIf="imageSubmitted && res.cate_id.errors" class="invalid-feedback">
                                    <div *ngIf="res.cate_id.errors.required"> Category Name is required</div>
                                </div>
                            </div>
                              <div class="form-group">
                                <label >Name: <span class="required">*</span></label>
                                <input type="text" autocomplete="off" required class="form-control"
                                    id="resource_title" formControlName="resource_title" maxLength="500"
                                    [ngClass]="{ 'is-invalid': imageSubmitted && res.resource_title.errors}"
                                    required>
  
                                <div *ngIf="imageSubmitted && res.resource_title.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="res.resource_title.errors.required">Name is required
                                    </div>
                                </div>
  
                            </div>
                   
                             <div class="form-group">
                              <label>Select Type: <span class="required">*</span></label><br>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="type" id="image" value="image" (change)="mediaType='image';videoUrl=''">
                                  <label class="form-check-label" for="image">Image</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="type" id="video" value="video" (change)="mediaType='video';croppedImage=''">
                                  <label class="form-check-label" for="video">Video</label>
                                </div>
                             </div>
                             <ng-container *ngIf="mediaType=='image'">
                              <div class='form-group' *ngIf="croppedImage">
                                <img [src]="croppedImage" width='100px' height='auto'/>
                              </div>
                              <div class="form-group">
                                <div class="upload-btn-wrapper">
                                  <button class="custbtn">UPLOAD AN IMAGE</button>
                                  <input type="file" accept="image/*" (change)="fileChangeEvent($event,cropperImage)">
                                  <i class="fa fa-upload"></i>
                                </div>
                                <div class="image-feedback" *ngIf="imageSubmitted && !croppedImage">Image is required</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="mediaType=='video'">
                            <div class='form-group' *ngIf="videoUrl">
                              <video width="100%" height="auto" [controls]='true'>
                                <source [src]="videoUrl" type="video/mp4">
                                Your browser does not support the video.
                              </video>
                            </div>
                            <div class="form-group">
                              <div class="upload-btn-wrapper">
                                <button class="custbtn">UPLOAD AN VIDEO</button>
                                <input type="file" accept="video/*" (change)="videoUpload($event)">
                                <i class="fa fa-upload"></i>
                              </div>
                              <div class="image-feedback" *ngIf="imageSubmitted && !videoUrl">video is required</div>
                            </div>
                         </ng-container>
                            </form>
                          </div>
                          <div class="d-block text-center modal-footer border-0">
                            <button type="button" (click)="saveImageData()" class="mr-1 btn btn-margo-cancel btn-round text-uppercase"
                                >Save</button>
                            <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                                (click)="backToImageList()">Back</button>
                        </div>
                        </div>
                        <div class="col-md-12" *ngIf="!isimageUpdate && imageList">
                          <div class="d-block text-center modal-footer border-0">
                            <button type="button" (click)="modal.dismiss('Cross click')" class="mr-1 btn btn-margo-cancel btn-round text-uppercase"
                                >Save</button>
                            <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                                (click)="modal.dismiss('Cross click');this.selelctedImage=null">Back</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>
  <ng-template #cropperImage let-modal>
    <div class="scroller">
        <div class="wholePop filtMain">
            <div class="modal-header dialHead w-100">
                <h4 class="modal-title mb-0 float-left">Image Editor</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');imageChangedEvent=null;croppedImage=null">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="scroller1">
                    <div class="row ">
                      <image-cropper
                       [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="true" [aspectRatio]="400 / 191"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded($event)"
                      ></image-cropper>
  
                    </div>
                </div>
            </div>
            <div class="d-block text-center modal-footer border-0">
                <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                    (click)="uploadImage();uploadOriginalImage();modal.dismiss('Cross click')">Save</button>
            </div>
        </div>
    </div>
  </ng-template>
  <ng-template #categorymodel let-modal role="dialog" style="z-index:999999" data-keyboard="false"
  data-backdrop="static">
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead card-header">
              <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
              <button type="button" class="close" aria-label="Close" (click)="closeCategory(modal)">
                  <span aria-hidden="true">&times;</span>
              </button>
  
  
          </div>
          <div class="profile-b text-center p-1">
              <div class="d-block">
                  <h5 class="text-uppercase">MANAGE CATEGORIES</h5>
              </div>
          </div>
  
          <div class="modal-body">
              <div class="row">
                <div class="col-12" *ngIf="showList">
                  <div class="card">
                      <div class="card-header pt-0">
                          <div class="mt-1">
                              <button class="btn btn-margo-action ml-0" (click)="addCategory()">ADD CATEGORY</button>
                          </div>
                      </div>
                      <div class="card-body filtMain1">
                          <h5 class="font-weight-bold"> CATEGORIES</h5>
                          <div class="row mt-1" *ngFor="let item of imageCategoryList">
                              <div class="col-9 col-sm-10 col-md-8">{{item.category_name}} </div>
                              <div class="col-3 col-sm-2 col-md-4 text-right">
                                      <span class="fa fa-edit mr-1 trm-1" style="color: #006DA7 !important"
                                      (click)="editCategory(item.cate_id, item)"></span>
  
                                  <span class="fa fa-trash " style="color: #006DA7 !important"
                                      (click)="cancelEvent(item.cate_id,item.category_name)"></span>
                              </div>
                          </div>
                          <div *ngIf="!imageCategoryList || (imageCategoryList && !imageCategoryList.length>0)">
                              <samp>NO RESULTS AVAILABLE.</samp>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-12">
                <div class="card" *ngIf="add">
                  <div id='d1' class="mt-1">
                      <div class="card-header pt-0 ">
                          <h4 class="mb-0">ADD CATEGORY</h4>
                      </div>
                  </div>
                  <div class="card-body filtMain1">
                      <form class="form-horizontal" [formGroup]="categForm">
                          <div class="form-group">
                              <label for="catname" class="col-8">Category Name: <span class="required">*</span></label>
                              <label _ngcontent-ptn-c35="" class="mt-1 col-4 text-right">{{showLength}} of
                                  {{allowedLength}}</label>
                              <input type="text" appSpace class="form-control" name='catname' formControlName="catname"
                                  [ngClass]="{ 'is-invalid': submittedCat && catf.catname.errors }" maxLength="50"
                                  (keyup)="countLength($event)">
                              <div *ngIf="submittedCat && catf.catname.errors" class="invalid-feedback">
                                  <div *ngIf="catf.catname.errors.required || catf.catname.errors.whitespace">Category Name is
                                      required</div>
                              </div>
                          </div>
                          <div class="form-group">
                              <label for="catname" class="col-8">Category Description:</label>
                              <label _ngcontent-ptn-c35="" class="mt-1 col-4 text-right">{{desShowLength}} of
                                  {{desAllowedLength}}</label>
                              <textarea appSpace class="form-control" rows="4" name='catdescription'
                                  formControlName="catdescription" maxlength="100"
                                  (keyup)="countDesLengthCat($event)"></textarea>
                          </div>
                          <div class="text-center">
  
                              <button type="submit" class="btn btn-round btn-margo-action"
                                  (click)="saveCategory()">SAVE</button>
                              <button type="submit" class="btn btn-round btn-margo-action"
                                  (click)="cancelCategory()">CANCEL</button>
                          </div>
                      </form>
                  </div>
              </div>
              <div class="card" *ngIf="edit">
                <div id='d2' class="mt-1">
                    <div class="card-header pt-0">
                        <h4 class="mb-0">UPDATE CATEGORY</h4>
                    </div>
                </div>
                <div class="card-body filtMain">
                    <form class="form-horizontal" [formGroup]="categFormEdit">
                        <div class="form-group">
                            <label for="catname" class="col-8">Category Name: <span class="required">*</span></label>
                            <label _ngcontent-ptn-c35="" class="mt-1 col-4 text-right">{{showLength}} of
                                {{allowedLength}}</label>
                            <input type="hidden" class="form-control" name='catid' formControlName="catid"
                                [ngClass]="{ 'is-invalid': submittedCat && fed.catid.errors }">
                            <input type="text" appSpace class="form-control" name='catname' formControlName="catname"
                                [ngClass]="{ 'is-invalid': submittedCat && fed.catname.errors }" maxLength="50"
                                (keyup)="countLength($event)">
                            <div *ngIf="submittedCat && fed.catname.errors" class="invalid-feedback">
                                <div *ngIf="fed.catname.errors.required || fed.catname.errors.whitespace"> Name is
                                    required</div>
                            </div>
                        </div>
                         <div class="form-group">
                            <label for="catname" class="col-8">Category Description:</label>
                            <label _ngcontent-ptn-c35="" class="mt-1 col-4 text-right">{{desShowLength}} of
                                {{desAllowedLength}}</label>
                            <textarea appSpace class="form-control" rows="4" name='catdescription'
                                formControlName="catdescription" maxlength="100"
                                (keyup)="countDesLengthCat($event)"></textarea>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-round btn-margo-action" (click)="updateCat()">UPDATE</button>
                            <button type="submit" class="btn btn-round btn-margo-action"
                                (click)="cancelCategory()">CANCEL</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card" *ngIf="deleteCategory">
              <div id='d2'>
                  <div class="card-header pt-0">
                      <h4 class="mb-0 text-center">DELETE CATEGORY</h4>
                  </div>
              </div>
              <div class="card-body">
                <h5 class="text-center"> CONFIRM , YOU WANT TO DELETE </h5>
                <div class="text-center">
                  <button type="button" class="btn btn-margo-action btn-round text-uppercase"
                      (click)="delCategoryUserData();">YES</button>
                  <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                      (click)="cancelCategory()">NO</button>
  
              </div>
              </div>
              </div>
              </div>
              </div>
          </div>
      </div>
  </div>
  </ng-template>
  
  