<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/supportuser">Support</a></li>
      <li class="breadcrumb-item active" aria-current="page">Support User Logs</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-sm-2 mt-3 ml-3 mb-3" *ngIf='company_id==0'>
      <span class="logs-label">Company Name <span style="color:red">*</span></span>
      <select name="company" id="company" class="form-control formstyle"
        (change)="getCompanySupportList($event.target.value)" [(ngModel)]="bu_id">
        <option value="">Select Company</option>
        <option *ngFor="let userObj of getCompanyobj"
          value='{"id":"{{userObj.customer_id}}","company_name":"{{userObj.customer_company_name}}"}'>
          {{userObj.customer_company_name}}
        </option>
      </select>
    </div>
    <div class="col-sm-2 mt-3 ml-3 mb-3">
      <span class="logs-label"> Support User <span style="color:red">*</span></span>
      <select name="company" class="form-control formstyle" (change)="getCompanySupportSellerList($event.target.value)"
        [(ngModel)]="support_id">
        <option value="">Select Support User</option>
        <option *ngFor="let userObj of supportUser"
          value='{"id":"{{userObj.uid}}","supportuser_name":"{{userObj.full_name}}"}'>
          {{userObj.full_name}}
        </option>
      </select>
    </div>
    <div class="col-sm-2 mt-3 ml-3 mb-3">
      <span class="logs-label"> Direct Seller <span style="color:red">*</span></span>
      <select name="sellerId" class="form-control formstyle" [(ngModel)]="sellerId"
        (change)="getCaseId($event.target.value)">
        <option value="">Select Direct Seller</option>
        <option *ngFor="let obj of sellerObj" value='{"id":"{{obj.userid}}","seller_name":"{{obj.full_name}}"}'>
          {{obj.full_name}}
        </option>
      </select>
    </div>
    <div class="col-sm-2 mt-3 ml-3 mb-3">
      <span class="logs-label"> Case Id <span style="color:red">*</span></span>
      <select name="case_id" class="form-control formstyle" [(ngModel)]="case_id">
        <option value="">Select Case Id</option>
        <option *ngFor="let obj of caseObj" value={{obj.salesforce_case_id}}>
          {{obj.salesforce_case_id}}
        </option>
      </select>
    </div>

    <div class="col-sm-2 mt-3 mb-3">
      <span class="logs-label"></span>
      <button class="btn btn-margo-action ml-0 mt-4 form-control formstyle" (click)="getLogBycaseId()"
        style="align-items: center;">Search</button>
    </div>

  </div>
  <div class="tblPrt">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0"
      *ngIf="directSellerSupportCrudLog?.length != 0">
      <thead>
        <tr>
          <th (click)="adminSorting('lid')" style="cursor:pointer">Log Id <i class=" fa fa-sort text-black"
              aria-hidden="true"></i>
          </th>
          <th (click)="adminSorting('log_name')" style="cursor: pointer">Log Name <i class=" fa fa-sort text-black"
              aria-hidden="true"></i></th>
          <th (click)="adminSorting('log_description')" style="cursor: pointer">Log Description <i
              class=" fa fa-sort text-black" aria-hidden="true"></i></th>
          <th>IP Address</th>
          <th>Date Time</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let item of directSellerSupportCrudLog">
          <td>{{item.lid}}</td>
          <td>{{item.log_name}}</td>
          <td>{{item.log_description}}</td>
          <td>{{item.ipaddress}}</td>
          <td>{{item.createdon | date:'MMM d, y, h:mm a'}}</td>
        </tr>
      </tbody>
    </table>

    <div class="mt-2 text-center difPagination" *ngIf="supportLogTotalCount > 2">


      <ngb-pagination [collectionSize]="supportLogTotalCount" [(page)]="sLogPage" [pageSize]="20" [maxSize]="2"
        [rotate]="true" (pageChange)="getLogBycaseId(sLogPage=$event)">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
    <div *ngIf="supportLogTotalCount== 0" style="margin-left: 355px;">
      <p><b>No Log created Yet....</b></p>
    </div>
  </div>
