import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { environment } from '../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MbService {
  public baseUrl;
  public newBaseUrl;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/sms/';
    this.newBaseUrl = baseService.baseUrl1 + 'api/fbadmin/'
  }
  getCampaign() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "campaign/100/0", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getPostManagementData(value) {
    var body = {};
    return this.http.get<any>(this.baseUrl + "fbpostadmin/1000/0/" + value, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getPostBUData(company, limit, skip, colname, colname_val,mtype, searchText, status,domain_id) {
    return this.http.get<any>(this.baseUrl + `smsPostByCompanyID/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}&message_type=${mtype}&status=${status}&domain=${domain_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  postCampaignBycompanyId() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "postCampaignBycompanyId/1000/0", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  cancelCat(value) {
    let body = value;
    return this.http.put<any>(this.baseUrl + 'smsCategory', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {

        }
        return data;
      }),
      catchError(this.handleError));
  }

  delCategoryUserData(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'delCategoryUserData', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }



  updatepostincategory(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'updatepostincategory', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {

        }
        return data;
      }),
      catchError(this.handleError));
  }

  getUnusedCategory(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'getUnusedCategory', body, this.httpHeadersOptions()).pipe(
      map(data => {
        if (!data.status) {

        }
        return data;
      }),
      catchError(this.handleError));

  }


  fbSchedulerByCompanyId() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "fbSchedulerByCompanyId/1000/0", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getCategoryData(company, limit, skip, colname, colname_val, iscorporate, searchText) {
    return this.http.get<any>(this.baseUrl + `smsCompanyCategory/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&isCorporate=${iscorporate}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getLiveFbPostCategory() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "getLiveFbPostCategory/100/0/true", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getPostCategoryByCompanyID(company) {
    var body = {};
    return this.http.get<any>(this.baseUrl + "getSmsCategoryByCompanyID/" + company + "/100/0/true", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }







  addNewCampaign(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "campaign", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addNewCategory(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "smsCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addNewPost(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "post", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getcampaignDatadropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "/campaignData", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getpostCategoryDatadropdown() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "postCategoryData", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  delCampaign(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "campaign/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  delCategory(company:any,value:any) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "smsCategory/" + company+'/'+id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  delMessageSnippet(company, id) {

    return this.http.delete<any>(this.baseUrl + "message/" + company + "/" + id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  delPost(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "post/" + value.company + "/" + id.id, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCampaignById(company_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `campaign/${company_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateCampaign(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "campaign", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCategoryById(company, category_id) {
    var body = {};

    return this.http.get<any>(this.baseUrl + `smsCategory/${company}/${category_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getMessageById(company, msg_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `postSnippet/${company}/${msg_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }




  updateCategory(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "smsCategory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateCategoryStatus(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "updateCategoryStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  updateMessageStatus(body) {
    console.log("body", body);
    var body = body;
    return this.http.put<any>(this.baseUrl + "updateMessageStatus", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  updateSnippet(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "message", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getPostById(company_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `post/${company_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getPostByPostID(company, post_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getPostByPostID/${company}/${post_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  updatePost(body) {
    var body = body;
    return this.http.put<any>(this.baseUrl + "post", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getMessageData() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "message/100/0/true", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getFbAllMessage(company, limit, skip, colname, colname_val, searchText) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getMbAllMessage/${company}/${limit}/${skip}?sort=${colname}:${colname_val}&search=${searchText}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }



  addNewmsg(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "message", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getFbUsers() {
    var body = {};
    return this.http.get<any>(this.baseUrl + "account/100/0", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
