import { Component, OnInit ,} from '@angular/core';
import { FormControl,FormArray,FormBuilder, FormGroup, Validators, AbstractControl,ValidationErrors} from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ToastrService } from 'ngx-toastr';
import {AudienceBuilderService} from '../../../services/segmentation/audience-builder.service';
import { ClientService } from 'src/app/services/clients/client.service';
import {MasterService} from '../../../services/master/master.service';
import csc from 'country-state-city';
import { of } from 'rxjs';
import {DatePipe,Location} from '@angular/common';
import {BUSegmentationService} from '../../../services/segmentation/bu-segmentation.service';


@Component({
  selector: 'app-create-audience-builder',
  templateUrl: './create-audience-builder.component.html',
  styleUrls: ['./create-audience-builder.component.css']
})
export class CreateAudienceBuilderComponent extends BaseComponent implements OnInit {


audienceForm: FormGroup;
submitted = false;
status;
actionStatus: boolean = true;
targetAudience:boolean=false;
targetEntities:boolean=false;
audienceAddForm:boolean=true;
currentUser;
companyId;
buTargetAudienceObj:any=[]
buEntitiesObj:any=[]
buEntitieAttributesObj:any=[]
buAttributeOperatorObj:any=[]

conditionForm: FormGroup;

showAndOr:boolean=false;
showDataValue:any=[];
getdataValueObj:any=[]
stateData:any=[]
cityData:any=[]
addedAudienceId;
targetAudieceId;

dataLabelName=[];
companyProductObj;
companyAttributeObj= [];

companyProductDataObj=[];
companyAttributeDataObj= [];
displayState=[];
displayCity=[];
selectedCountry=[];
audienceTarget="";
selectedBUAud: any;
  constructor(

    private formBuilder: FormBuilder,
    public toaster: ToastrService,
    private audienceBuilderService: AudienceBuilderService,
    private fb:FormBuilder,
    private clientsService: ClientService,
    private master: MasterService,
    public datePipe: DatePipe,
    private segmentService: BUSegmentationService,
    private _location: Location
  ) {
    super();


  }

  ngOnInit() {

  
    localStorage.removeItem("editAudience");  
    localStorage.removeItem("BackStepAudience"); 
    this.checkAudienceData();    
    
  



    

  }

  get f() { return this.audienceForm.controls; }


  //  ############  Check for Audience Data for BU  ##############  //

  checkAudienceData() {
    this.ngxSpinnerService.show();

    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    this.companyId = this.currentUser.admin_customer_id;

    this.segmentService.checkAudienceDataForBU(this.companyId).subscribe(data => {
      if (data.status) {

        this.loadTargetAudienceList();
        this.addedAudienceId = localStorage.getItem("editAudienceID");   
    
        this.conditionForm = this.fb.group({
          audienceConditions: this.fb.array([]) ,
        });
    
       


        this.ngxSpinnerService.hide();
      } else {
        this.toastrService.error('Please add Segmentation Data for BU before adding New Audience from Audience builder');
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/audience-builder');
      }
    }, err2 => {
      this.toastrService.error(err2);
      this.router.navigateByUrl('/corporate/audience-builder');
      this.ngxSpinnerService.hide();
    });
  }

 
  loadTargetAudienceList() {


    this.audienceAddForm=false;

    this.ngxSpinnerService.show();

    this.audienceBuilderService.getTargetAudienceList(this.companyId).subscribe(data => {

      if (data.status) {

        this.buTargetAudienceObj = data.data;
      //  console.log('Full AUD DATA', this.buTargetAudienceObj);

    if(localStorage.getItem("audienceTarget"))  this.audienceTarget = localStorage.getItem("audienceTarget");
   
    this.targetAudience=true;
      } else  {
        this.buTargetAudienceObj = [];
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });



  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

assignTarget(id) {

  //this.targetAudieceId=id;


  this.audienceTarget=id;

  console.log("selected target...",this.audienceTarget)


}




goTargetAudienSubmit() {


 if(this.audienceTarget)  localStorage.setItem("audienceTarget",this.audienceTarget);


  console.log("target.....",this.audienceTarget)
  if(this.audienceTarget=="") {


    this.toastrService.error("Please select target audience");
    return false;
 
   }

if((this.audienceTarget==localStorage.getItem("audienceTarget")) && localStorage.getItem("cloneAudience")) {

 
  this.router.navigateByUrl('/corporate/clone-audience-builder');
}
else {


  console.log("target 1.....",this.audienceTarget)
 
  this.ngxSpinnerService.show();
  //localStorage.setItem("audienceTarget",this.audienceTarget);

  this.audienceBuilderService.getBUEntitiesList(this.companyId,999,0,this.audienceTarget).subscribe(data => {

    if (data.status) {

      let obj = this.buTargetAudienceObj.filter(elem => elem.aud_id === parseInt(this.audienceTarget, 10));

      this.selectedBUAud = obj[0];

      this.audienceAddForm=false;
      this.targetAudience=false;
      this.targetEntities=true;

      this.buEntitiesObj.push(data.data);
      this.removeAllAudienceQuery();

     
      this.removeAllAudienceQuery();

    } else  {
      this.buEntitiesObj = [];
    }
    this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    this.ngxSpinnerService.hide();
  });


} 


}

audienceConditions() : FormArray {

   return this.conditionForm.get("audienceConditions") as FormArray
}

newCondition(): FormGroup {
  return this.fb.group({
    open_close: "",
    entity_id: ["",Validators.required] ,
    field_id: ["",Validators.required] ,
    operator_id: ["",Validators.required],
    data_value:["",Validators.required],
    conditionAndOr:"AND",
    dataValueState:"",
    dataValueCountry:"",
    data_order:"",
    dataValueProduct:''
  })
}

addCondition() {

    if (this.audienceConditions().length >= 10) {

      this.toastrService.error("Can't add more than 10 Conditions in one Audience");

      return;
    }
  this.showAndOr=true;
  this.audienceConditions().push(this.newCondition());

  this.goTargetAudienSubmit();


}

removeCondition(i:number) {

  this.buEntitiesObj.splice(i,1);
  this.buEntitieAttributesObj.splice(i,1);
  this.buAttributeOperatorObj.splice(i,1);
  if(this.companyAttributeDataObj) this.companyAttributeDataObj.splice(i,1);
  if(this.companyProductDataObj[i]) this.companyProductDataObj.splice(i,1);
  if(this.getdataValueObj) this.getdataValueObj.splice(i,1);
  if(this.stateData) this.stateData.splice(i,1);
  if(this.cityData) this.cityData.splice(i,1);
  this.audienceConditions().removeAt(i);
}

onSubmit() {

//console.log("saq...",this.conditionForm.value.audienceConditions.length);
//console.log("validatior....",this.conditionForm);

  if (this.conditionForm.invalid) {
    this.toastrService.error("Select all required fields");
    return;
  }

  if(this.conditionForm.value.audienceConditions.length==0) {
    this.toastrService.error("Select query condition");
    return;
  }

  let isOp;
  this.conditionForm.value.audienceConditions.forEach(element => {
    let val = element.open_close;
    if (val === '1') {
      if (isOp === true) {
        this.toastrService.error('Opening braces should be followed by a closing braces only');
        return;
      } else {
        isOp = true;
      }
    }
    if (val === '0') {
      if (isOp === false) {
        this.toastrService.error('Closing braces should only be succeded by Opening braces');
        return;
      } else isOp = false;
    }
  });
  if (isOp === true) {
    this.toastrService.error('Opened braces should be closed by Closing braces');
    return;
  }
  
  this.ngxSpinnerService.show();
  let obj = {
    "audid":this.addedAudienceId,
    "aud_id":this.audienceTarget,
    "company": this.companyId ,
    "query_data":this.conditionForm.value
           }

          var k=0;
           this.conditionForm.value.audienceConditions.forEach(element => {
            let fieldaData = this.buEntitieAttributesObj[k].filter(item => item.field_id == element.field_id);
            if(fieldaData && fieldaData[0].table_id) element.table_id = fieldaData[0].table_id;

                
             k++;
             element.data_order = k;
           });

      this.audienceBuilderService.addingAudienceQuery(obj).subscribe(data => {
             if (data.status) {

               this.toastrService.success("Audience Builder Query Added Successfully");
               let URL = '/corporate/view-bu-built-audience' + '?audid=' + this.addedAudienceId;
               this.router.navigateByUrl(URL);

             } else {
               this.toastrService.error(data.message)
             }
             this.ngxSpinnerService.hide();
           }, error => {
            // console.log(error);
             this.ngxSpinnerService.hide();
           });
}


entityAttributes(val,index) {

  this.buAttributeOperatorObj[index] = [];
  this.dataLabelName[index]=[];
  this.audienceConditions().controls[index].get("data_value").patchValue("");
  this.audienceConditions().controls[index].get("dataValueState").patchValue("");
  this.audienceConditions().controls[index].get("dataValueCountry").patchValue("");

  
  


  this.ngxSpinnerService.show();

  if(val) {

  this.audienceBuilderService.getBUEntityAttributes(this.companyId,val).subscribe(data => {

    if (data.status) {


      this.buEntitieAttributesObj[index]=data.data[0].attributeList;
    //  console.log('ATTRS' ,index, this.buEntitieAttributesObj);
     // this.targetAudience=true;
    } else  {
      this.toastrService.error(data.message)
    }
    this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    this.ngxSpinnerService.hide();
  });
}
else {
  this.ngxSpinnerService.hide();
  this.buEntitieAttributesObj[index] = [];

}
}

  attributeOperators(val,index) {


    this.ngxSpinnerService.show();
    this.audienceConditions().controls[index].get("data_value").patchValue("");
    this.audienceConditions().controls[index].get("dataValueState").patchValue("");
    this.audienceConditions().controls[index].get("dataValueCountry").patchValue("");


    if(val) {
    this.showDataValue[index]=true;

    let selectedAttribute=this.buEntitieAttributesObj[index].filter(item=>item.field_id==val);

   // console.log("sssss...",selectedAttribute[0].field_name);


    if(selectedAttribute && (selectedAttribute[0].field_name.includes('Country') || selectedAttribute[0].field_name.includes('State') || selectedAttribute[0].field_name.includes('City'))) {

        this.dataLabelName[index] = "Country";
        this.audienceBuilderService.getAllCountries().subscribe(data => {


          if (data) {
            this.getdataValueObj[index] = data

          } else {
            //this.toastrService.error(data.message)
          }
          //this.ngxSpinnerService.hide();
        }, error => {
        //  console.log(error);
          //this.ngxSpinnerService.hide();
        })



        //this.getdataValueObj[index] = csc.getAllCountries();
        if(selectedAttribute[0].field_name.includes('Country')) { this.displayState[index]=false; this.displayCity[index]=false; }
        if(selectedAttribute[0].field_name.includes('State')) { this.displayState[index]=true; this.displayCity[index]=false; }
        if(selectedAttribute[0].field_name.includes('City')) {  this.displayCity[index]=true; this.displayState[index]=true; }
       //  console.log("selected attribute....",  this.conditionForm.get("dataValue"));

    }

    else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Product'))) {

      this.ngxSpinnerService.show();
      this.dataLabelName[index] = "wishlist";

      this.clientsService.getCompanyProductList(this.companyId, 9999, 0).subscribe(data => {


        this.companyProductDataObj[index] = data.data.rows;

       // console.log(data.data);
        this.ngxSpinnerService.hide();

      }, error => {
       // console.log(error);
      });


  }
  else if(selectedAttribute && selectedAttribute[0].field_name=='Attribute') {

    this.ngxSpinnerService.show();
    this.dataLabelName[index] = "Attribute";
    this.master.getFullAttributes(this.companyId, 9999).subscribe(data => {
      this.companyAttributeDataObj[index]= data.data.rows;
     // this.shwSearch = true;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
      this.ngxSpinnerService.hide();
    });
}

else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Type '))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "Journey";
}

else if(selectedAttribute && (selectedAttribute[0].field_name.includes('OPT IN SMS'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "optINSMS";
}
else if(selectedAttribute && (selectedAttribute[0].field_name.includes('OPT IN Email'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "optINEmail";
}

else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Agreed to Host'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "agreedToHost";
}
else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Agreed to Join'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "agreedToJoin";
}
else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Sex'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "gender";


}
else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Open'))) {
  this.ngxSpinnerService.show();
  this.dataLabelName[index] = "open";
}

   else if(selectedAttribute && (selectedAttribute[0].field_name.includes('Date'))) {

      this.dataLabelName[index] = "inputdate";

  }
    else  {

      this.dataLabelName[index] = "inputvalue";

    }
/*
    if(selectedAttribute && selectedAttribute[0].field_name=='country') {
      this.dataLabelName[index] = "Country";
      this.getdataValueObj[index] = csc.getAllCountries();
      console.log("selected attribute....",  this.conditionForm.get("dataValue"));
    }
    else if(selectedAttribute && selectedAttribute[0].field_name=='State') {

      this.dataLabelName[index] = "State";
      this.getdataValueObj[index] =  csc.getStatesOfCountry('101');
      console.log("selected attribute....",  this.conditionForm.get("dataValue"));
    }

*/



    this.audienceBuilderService.getBUAttributeOperator(this.companyId,val).subscribe(data => {

      if (data.status) {

        this.buAttributeOperatorObj[index]=data.data[0].operatorList;
       // this.targetAudience=true;
      } else  {
        this.toastrService.error(data.message)
      }
      this.ngxSpinnerService.hide();
    }, error => {
     // console.log(error);
      this.ngxSpinnerService.hide();
    });
  }else {
    this.ngxSpinnerService.hide();
    this.buAttributeOperatorObj[index] = [];
    this.dataLabelName[index]=[];
    this.audienceConditions().controls[index].get("data_value").patchValue("");

  }



}

dataValueLoad(val,index,type) {





}




showState(id,index) {
  // console.log("State",csc.getStatesOfCountry(id));
  this.selectedCountry[index]=id;
  this.cityData[index] = [];
  this.stateData[index] = [];
  if (id === '' || id === null || id === undefined) {
    return;
  }
  this.audienceBuilderService.getStatesOfCountry(id).subscribe(data => {
    if (data) {
      this.stateData[index] = data;
    } else {
      //this.toastrService.error(data.message)
    }
    //this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    //this.ngxSpinnerService.hide();
  })


  //this.stateData[index] = csc.getStatesOfCountry(id)
}


showCity(id,index) {

 // console.log("selected country.....",this.selectedCountry[index]);

 this.cityData[index] = [];
 if (id === '' || id === null || id === undefined) {
   return;
 }
  this.audienceBuilderService.getCitiesOfState(this.selectedCountry[index],id).subscribe(data => {
    if (data) {
      this.cityData[index] = data
    } else {
      //this.toastrService.error(data.message)
    }
    //this.ngxSpinnerService.hide();
  }, error => {
   // console.log(error);
    //this.ngxSpinnerService.hide();
  })

  // console.log("cities",csc.getCitiesOfState(id));
  //this.cityData[index] = csc.getCitiesOfState(id)
}

backAudiencePage() {


    localStorage.setItem("audienceTarget",this.audienceTarget)
    localStorage.setItem("BackStepAudience",'1')
    let URL = '/corporate/manage-audience-builder';
    this.router.navigateByUrl(URL);



}

backAudienceEntityPage() {

  this.targetAudience=true;
  this.targetEntities =false;


}

removeAllAudienceQuery() {

  this.audienceBuilderService.deleteAllAudienceQuery(this.companyId, this.addedAudienceId ).subscribe(data => {

    if (data.status) {

      return true;

    }
    else {
      console.log(data.message);
    }
   
  }, error => {
   // console.log(error);
   
  });

}

  checkStateCity(control: AbstractControl) : ValidationErrors | null {

      if((control.value as string).indexOf(' ') >= 0){
          return {cannotContainSpace: true}
      }

      return null;
  }









}
