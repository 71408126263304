<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;

  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/contentbuilder">Content Builder</a></li>
      <li class="breadcrumb-item active" aria-current="page">Content Builder Fields</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
   <div class="col-12">
      <input *ngIf="isWrite"  type="button" value="Add New" class="custbtn" routerLink="/corporate/add-content-builder-field">
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-6">  
          <select name="company" class="form-control formstyle" (change)="gettingContentBuilderFields($event.target.value)">
            <option value="">Select Company Name</option>
            <option *ngFor="let userObj of getCompanyobj" value={{userObj.customer_id}} >
              {{userObj.customer_company_name}}
            </option>
          </select>
        </div>
      
    
  </div>
  <div class="tblPrt">

    <!-- <div class="mt-3" *ngIf="allTablesobj.length==0">No Sensitive Tables</div> -->
    
    <!-- <div style="text-align: center;" *ngIf="allTablesobj && allTablesobj.length === 0"> -->
      <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="allTablesobj.length>0">
  
  
        <tr>
          <!-- <th>Theme Id</th> -->
          <th>Field Name</th>          
          
          <th style="cursor: pointer;"> Data Type<i   class=" fa fa-sort text-black" aria-hidden="true"></i></th>
          <th>Action</th>
        </tr>
  
        <tr *ngFor="let obj of allTablesobj">
          <td>{{obj.column_name}}</td>
          <td>{{obj.data_type}}</td>
          <td *ngIf="isEdit || isDelete">
            <div class="actBtn">
              <i class="fa fa-edit mr-1" *ngIf="isEdit" (click)="editFields(companyId,obj.column_name,obj.data_type)" placement="bottom"
                ngbTooltip="Edit"></i>
              <!--<span class="fa fa-trash mr-1" *ngIf="isDelete" (click)="deleteTheme(obj.theme_id)" placement="bottom"
                ngbTooltip="Delete"></span>-->
              
            </div>
          </td>
        </tr>
  
      </table>

      
    <!-- </div> -->
    
  </div>