
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">E-commerce Detail Report</li>
    </ol>
  </nav>
  <!-- <div>
    <span class="imageWrapper"> <img class="customimg" src="../../../assets/custom/images/export.png"></span>
  </div> -->
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">

    <div class="col-md-3 mt-2"  *ngIf="isHeadquater">
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="fetchCustomerData(1,$event.target.value)">
        <option selected="selected" value=0>Select Company</option>
        <option *ngFor="let userObj of companyList" [value]="userObj.customer_id">{{userObj.customer_company_name}}
        </option>
      </select>
    </div>
    <!-- <div  class="col-md-9 mt-2">
      <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a>
    </div> -->
    
    <!--<div class="col-md-2 mt-2">
      <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker1" readonly   [max]="maxdate"   
                [(ngModel)]="start_date"  >
                <mat-datepicker-toggle matSuffix [for]="picker1" class="cldrIcon"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
      </div>
      <div class="col-md-2 mt-2">     
          <input class="form-control formstyle mb-1" placeholder="mm/dd/yy" matInput [matDatepicker]="picker" readonly   [max]="maxdate"   
                [(ngModel)]="end_date"  >
                <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
    
    <div  class="col-md-2 mt-2">
     
      <button class="btn btn-margo-action formstyle gp-mr-5" (click)="fetchCustomerData(1)" style="align-items: center;">Search</button> 
  
    </div>
    <div class="col-md-2 mt-2">
      <button class="btn btn-margo-action ml-0 formstyle gp-mr-5" (click)="resetReport()" style="align-items: center;">Reset</button> 
   
    <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png"> </a>
    
    </div>-->
  </div>
  <div class="tblPrt" *ngIf="showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <th>Direct Seller Name</th>
        <th>Contact Name</th>
        <th>Product Name</th>
        <th>Date Purchased</th>
        <th>Messages Clicked</th>
        <th>Attributed No. of Purchased</th>
        <th>Total Attributed Purchased Amount</th>
        <th>Total Purchased Amount</th>
      </tr>
      <ng-container *ngIf = "totalRecords > 0">
        <tr *ngFor="let itm of reportData | paginate: { id: 'server', itemsPerPage: limit, currentPage: page, totalItems: totalRecords }">
          <td>{{itm.dsname}}</td>
          <td>{{itm.contact_name}}</td>
          <td>{{itm.product_name}}</td>
          <td>{{itm.invoice_date == null?'0':itm.invoice_date | date:'medium'}}</td>
          <td>{{itm.message_by == null?'0':itm.message_by}}</td>
          <td>{{itm.product_quantity == null?'0':itm.product_quantity}}</td>
          <td>${{itm.product_line_item_amount == null?'0':itm.product_line_item_amount}}</td>
          <td>${{itm.total_amt == null?'0':itm.total_amt}}</td>
        </tr>
      </ng-container>
    </table>

    <div class="has-text-centered text-center ulPage" *ngIf = "totalRecords > 0 && showResult ">

      <pagination-controls (pageChange)="changePage($event)" id="server"></pagination-controls>
    </div>
  </div>
  <div class="tblPrt" *ngIf="!showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
        <tr>
          <td colspan="5">No results available</td>                            
        </tr>  
    </table>
  </div> 
</div>
