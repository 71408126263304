import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-updateclientsdbdetails',
  templateUrl: './updateclientsdbdetails.component.html',
  styleUrls: ['./updateclientsdbdetails.component.css']
})
export class UpdateClientsDBDetailsComponent extends BaseComponent implements OnInit {
  isEdit=false;
  header = "Update";
  getCompanyobj;
  companyID;
  company;
  getCompanyDBobj;
  updateclientdbForm: FormGroup;
  submitted = false;
  getCompanyDB: { customerid: string; customerdbid: string; customerdbhost: string; customerdbname: string; customerdbusername: string; customerdbpassword: string; customerdbport: string; isactive: boolean; };
  
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    public location: Location
  ) {
    super();
  }

  ngOnInit() {
    this.ngxSpinnerService.show();
    this.getCompanyDB ={"customerid":"","customerdbid":'0',"customerdbhost":"","customerdbname":"","customerdbusername":"","customerdbpassword":"","customerdbport":"","isactive":true}
    this.getCompany();
    this.updateclientdbForm = this.formBuilder.group({
      customerid: ['', Validators.required],
      customerdbhost: ['', Validators.required],
      customerdbname: ['', Validators.required],
      customerdbusername: ['', Validators.required],
      customerdbpassword: ['', Validators.required],
      customerdbport: ['', Validators.required],
      isactive: [true, Validators.required],
    });
    this.UpdateClientDBDetailsLogData();
  }
  get f() { return this.updateclientdbForm.controls; }


  UpdateClientDBDetailsLogData() {
    let journeyLogs = {
      log_type: "Updating Client DB Details",
      log_name: "Updating Client DB Details",
      log_description: "Updating Client DB Details",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }
    this.logs.addNewLogs(journeyLogs).subscribe(data => {
    //  console.log(data);
    }, error => {
     // console.log(error);
    });

  }


  UpdateClientDBDetails() {
    this.submitted = true;
    if (this.updateclientdbForm.invalid) {
      return;
    }

    let clientDBDetailsBody = {
      customerdbhost: this.f.customerdbhost.value,
      customerdbname: this.f.customerdbname.value,
      customerdbusername: this.f.customerdbusername.value,
      customerdbpassword: this.f.customerdbpassword.value,
      customerdbport: this.f.customerdbport.value,
      customerdbid: this.getCompanyDBobj?this.getCompanyDBobj.customerdbid:0,
      customerid: this.f.customerid.value,
      isactive:this.f.isactive.value
    }
    
    this.ngxSpinnerService.show();
    this.clientService.updateCompanyDBDetail(clientDBDetailsBody).subscribe(data => {
      if (data.status) {
        this.toastrService.success(" Updated Successfully");  
        this.getCompanyDB.customerid="";        
        this.updateclientdbForm.patchValue(this.getCompanyDB);     
      }
      else
      {
        if(data.message)
        {
        this.toastrService.error(data.message);
        }
        else{
          this.toastrService.error("Something went wrong.Please try again.");
        }
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.error(error);
      this.ngxSpinnerService.hide();
    });
    
  }

  getCompany() {
    let self = this;
    this.clientService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
    //  console.log(error);
    });
  }

  getClientDBDetail(id) {
    this.companyID = id;
  //  console.log("company Journey:" + id);
    let self = this;
    let getCompanyDB ={"customerid":id,"customerdbid":'0',"customerdbhost":"","customerdbname":"","customerdbusername":"","customerdbpassword":"","customerdbport":"","isactive":true}
    this.updateclientdbForm.patchValue(getCompanyDB);
    this.ngxSpinnerService.show();
    this.clientService.getCompanyDBDetail(this.companyID).subscribe(data => {
      if (data.status) {
        self.getCompanyDBobj = data.data;
        this.isEdit=true;
        this.updateclientdbForm.patchValue(self.getCompanyDBobj);
      }
      else {
        this.isEdit=false;
        self.getCompanyDB.customerid=id;
        self.getCompanyDBobj ={}
        this.updateclientdbForm.patchValue(self.getCompanyDB);
        this.toastrService.error("No Result available.Please add new one.");
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });

  }
}
