<div class="container">
    <nav aria-label="breadcrumb" class="breadCust">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/corporate/master">Master Module</a></li>
            <li class="breadcrumb-item "><a routerLink="/corporate/trainingtypemaster">Training Type Master</a></li>
            <li class="breadcrumb-item active">{{header}} Training</li>
        </ol>
    </nav>
</div>

<div class="container mt-2 mt-md-3">
    <form class="form-horizontal mt-1" [formGroup]="trainingtypeForm">
        <div class="rowTheme">
            <div class="themContain">
                <div>
                    <label for="exampleForm2">Training Type Name<span style="color:red">*</span></label>
                    <input type="text" formControlName="name" class="form-control form-control-md"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required maxlength="30" appSpace>
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required || f.name.hasError('whitespace')">Training Type Name is required</div>
                        <div *ngIf="f.name.errors.pattern">Training Type Name is not a valid format</div>
                        <div *ngIf="f.name.errors?.maxlength">You can enter only 30 charachters</div>
                
                  
                    </div>
                </div>
                <div class="mt-1">
                    <label for="exampleForm2">Training Type Description</label>
                    <input type="text" formControlName="description" min="1" maxlength="99" class="form-control form-control-md">
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                    <button type="button" class="custbtn mt-2" *ngIf="isWrite" (click)="addTrainingTypeMaster()">Submit</button>
                </div>
                <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock'  ">
                    <button type="button" class="custbtn mt-2" *ngIf="isEdit" (click)="UpdateTraining()">Update</button>
                </div>
            </div>
        </div>
    </form>
</div>