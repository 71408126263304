<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/contentbuilder">Content Builder</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/corporate/cb-theme-template-association"
          >Theme Template Association</a
        >
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Clone Theme Template Association
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="addForm">
    <div class="row">
      <div class="col-md-10 col-xl-7">
        <div class="card mt-1">
          <div class="card-body">
            <div class="form-group" *ngIf="companyCheck == 0">
              <label for="exampleForm2"
                >Select Company Name <span style="color: red">*</span></label
              >

              <select
                formControlName="company" *ngIf="headers == 'Add'"
                class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                (change)="getCompanyThemes($event.target.value)"
              >
                <option value="">Company Name</option>
                <option
                  *ngFor="let userObj of getCompanyobj"
                  value="{{ userObj.customer_id }}"
                >
                  {{ userObj.customer_company_name }}
                </option>
              </select>

              <select
              formControlName="company" *ngIf="headers == 'Edit'" disabled="controlEnabled"
              class="form-control formstyle"
              [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
              (change)="getCompanyThemes($event.target.value)"
            >
              <option value="">Company Name</option>
              <option
                *ngFor="let userObj of getCompanyobj"
                value="{{ userObj.customer_id }}"
              >
                {{ userObj.customer_company_name }}
              </option>
            </select>

              <div
                *ngIf="submitted && f.company.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.company.errors.required">
                  Company Name is required
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="companyCheck > 0">
              <input
                type="hidden"
                formControlName="company"
                class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
              />
            </div>
            <div class="form-group">
              <label for="exampleForm2"
                >From Theme Name <span style="color: red">*</span></label
              >
              <select
                formControlName="themeid"
                class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.themeid.errors }"
              >
                <option value="">Theme Name</option>
                <option
                  *ngFor="let userObj of themeObj"
                  value="{{ userObj.theme_id }}"
                >
                  {{ userObj.theme_name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.themeid.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.themeid.errors.required">
                  Theme Name is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleForm2"
                >To Clone Theme Name <span style="color: red">*</span></label
              >
              <select
                formControlName="to_themeid"
                class="form-control formstyle"
                [ngClass]="{ 'is-invalid': submitted && f.to_themeid.errors }"
              >
                <option value="">Theme Name</option>
                <option
                  *ngFor="let userObj of themeObj"
                  value="{{ userObj.theme_id }}"
                >
                  {{ userObj.theme_name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.to_themeid.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.themeid.errors.required">
                  Theme Name is required
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-center">
            <div class="my_class text-center">
              <button
                type="button"
                class="custbtn"
                (click)="addTemplate()"
              >
                Submit
              </button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
