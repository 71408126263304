import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-add-subs-limit',
  templateUrl: './add-subs-limit.component.html',
  styleUrls: ['./add-subs-limit.component.css']
})
export class AddSubsLimitComponent extends BaseComponent implements OnInit {
  customer_id;
  addLimitForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  selectedCompany:any ='';
  subsObj:any = [];
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService,
  ) { 

    super();
  }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();

    this.addLimitForm = this.formBuilder.group({
      selectedCompany: [''],
      sub_id: ['', [Validators.required]],                                  
      component_name: ['', [Validators.required, Validators.maxLength(100)]],
      component_limit: ['',[]],
      time_limit: ['', []],
      orderno: ['',[]]   
    });

    this.subscriptionList();





  }

  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }

  subscriptionList(){
    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();
      this.masterService.getSubscriptionListDropdown(this.selectedCompany,1000,0).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.subsObj = [] }
          else { this.subsObj = data.data.rows }
        } else {
          this.subsObj = [];
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.subsObj = [];
      this.selectedCompany = "";
      this.ngxSpinnerService.hide();
    }
  }







  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f() { return this.addLimitForm.controls; }


  addNewCategoryData(){
    this.submitted = true;
    if (this.addLimitForm.invalid) {
      return;
    }
    let { sub_id, component_name, component_limit, time_limit, orderno  } = this.addLimitForm.value;
    let obj = {
      sub_id: sub_id,                                  
      component_name: component_name,
      component_limit: component_limit,
      time_limit:time_limit,
      orderno: orderno 
    }
    this.masterService.createSubscriptionLimit(this.selectedCompany,obj).subscribe(data=>{
      if (data.status) {
        this.toastrService.success("Subscription limit added successfully");
        this.router.navigateByUrl(`/corporate/subscriptionLimit`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.toastrService.error(error.message);
    });
  }
}
