import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/secondadmin/base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';

@Component({
  selector: 'app-edit-media-sub',
  templateUrl: './edit-media-sub.component.html',
  styleUrls: ['./edit-media-sub.component.css']
})
export class EditMediaSubComponent extends BaseComponent implements OnInit {

  customer_id;
  updateMediaSubscriptionForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  subs_id: string;
  selectedCompany: string;
  subscriptionObj:any;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService:MasterService
  ) {super(); }

  ngOnInit(): void {
    this.customer_id = JSON.parse(sessionStorage.getItem('userData')).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
        //get item details using id
        if (localStorage.getItem("edit_subscription_media_id")) {
          this.subs_id = localStorage.getItem("edit_subscription_media_id");
          this.selectedCompany = localStorage.getItem(
            "edit_subscription_media_company_id"
          );

          this.masterService
            .getSubscriptionMediaById(this.selectedCompany, this.subs_id)
            .subscribe((response) => {
              if (response.status) {
                this.status = false;
                this.subscriptionObj = response.data;
                this.dataBinding();
              }
            });
        }
    this.updateMediaSubscriptionForm = this.formBuilder.group({
      media_name: ['', [Validators.required, Validators.maxLength(50)]],
      point: ['',[Validators.required]],
      selectedCompany: [(this.customer_id == 0) ? '' : this.customer_id, Validators.required]
    });
  }
  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(data => {
      self.getCompanyobj = data.data;
    }, error => {
      console.log(error);
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f() { return this.updateMediaSubscriptionForm.controls; }
  updateNewSubscriptionData(){
    this.submitted = true;
    if (this.updateMediaSubscriptionForm.invalid) {
      return;
    }
    let { selectedCompany } = this.updateMediaSubscriptionForm.value;
    this.masterService.updateSubscriptionMedia(selectedCompany,this.subs_id,this.updateMediaSubscriptionForm.value).subscribe(data=>{
      if (data.status) {
        this.toastrService.success("Subscription media updated successfully");
        this.router.navigateByUrl(`/corporate/subscription-media-list`);
      } else {
        this.toastrService.error(data.message);
      }
    },error => {
      this.toastrService.error(error.message);
    });
  }
  dataBinding() {
    this.updateMediaSubscriptionForm = this.formBuilder.group({
      media_name: [this.subscriptionObj?.media_name, [Validators.required, Validators.maxLength(50)]],
      point: [this.subscriptionObj?.point,[Validators.required]],
      selectedCompany: [
        this.selectedCompany,
        Validators.required,
      ],
    });
  }
}
