<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/corporate/subscriptionModule">Subscription Module</a></li>
          <li class="breadcrumb-item" disabled><a routerLink="/corporate/subscription-media-list">Subscription Media</a></li>
          <li class="breadcrumb-item active" aria-current="page">Add Subscription Media<li>

      </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="addMediaSubscriptionForm">
        <div class="card">
          <div class="card-body">
            <div class="form-group" *ngIf="customer_id===0">

              <label for="exampleForm2">Company Name <span class="required">*</span></label>

              <select formControlName="selectedCompany" class="form-control formstyle ssss"
                *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>

            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
            <div class="form-group">
              <label for="media_name">Subscription Media Name <span class="required">*</span></label>
              <input type="text" class="form-control" name="media_name" formControlName="media_name"
                [ngClass]="{ 'is-invalid': submitted && f.media_name.errors }" maxlength="50">
              <div *ngIf="submitted && f.media_name.errors" class="invalid-feedback">
                <div *ngIf="f.media_name.errors.required">Media name is required</div>
                <div *ngIf="f.media_name.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="point">Subscription Media Point</label>
              <input type="number" class="form-control" name="point" formControlName="point"
              [ngClass]="{ 'is-invalid': submitted && f.point.errors }">
              <div *ngIf="submitted && f.point.errors" class="invalid-feedback">
                <div *ngIf="f.point.errors.required">Media point is required</div>
                <!-- <div *ngIf="f.point.errors?.maxlength">
                  You reached to maxlength
                </div> -->
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <button class="btn btn-round btn-margo-action" (click)="addNewSubscriptionData()">Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
