import {BaseComponent} from '../../../../base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BUSegmentationService} from '../../../../../services/segmentation/bu-segmentation.service';

@Component({
  selector: 'app-bu-add-attributes',
  templateUrl: './add-bu-attributes-segmentation.component.html',
  styleUrls: ['./add-bu-attributes-segmentation.component.css']
})
export class AddBuAttributesSegmentationComponent extends BaseComponent implements OnInit{

  buselected: any;
  buid: any;
  attrId: any;
  attr: any;
  header = 'Add';
  status = true;
  submitted = false;
  attrForm: FormGroup;

  constructor(private segmentService: BUSegmentationService, private formBuilder: FormBuilder) {
    super();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.attrId = params.attrId;
      });
    if (localStorage.getItem('bucompanyid') && localStorage.getItem('bucompanyname')) {
      this.buselected = localStorage.getItem('bucompanyname');
      this.buid = parseInt(localStorage.getItem('bucompanyid'), 10);
    } else {
      this.router.navigateByUrl('/corporate/selectbu-segmentation');
    }
  }

  ngOnInit() {
    this.attrForm = this.formBuilder.group({
      attrname: ['', Validators.required],
      attrdesc: ['']
    });
    if (this.attrId) {
      this.header = 'Edit';
      this.segmentService.getBUEntityById(this.buid, this.attrId).subscribe(data => {
        if (data.status){
          this.attr = data.data[0];
          this.status = false;
          this.attrForm.get('attrname').patchValue(this.attr.field_name);
          this.attrForm.get('attrdesc').patchValue(this.attr.field_desc);
        } else {
          this.router.navigateByUrl('/corporate/bu-attributes');
        }
        this.ngxSpinnerService.hide();

      }, error => {
        this.toastrService.error(error);
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/corporate/bu-attributes');
      });
    }
  }

  get f() { return this.attrForm.controls; }

  //  ##########  Add Attribute for BU  ####################  //

  addNewBUAttribute(){
    this.submitted = true;
    if (this.attrForm.invalid) {
      return;
    }
    let attrnm = this.attrForm.value.attrname.trim();
    if (attrnm === '' || attrnm === null || attrnm === undefined){
      this.toastrService.error('Please provide a valid Attribute Name');
      return;
    }
    this.ngxSpinnerService.show();
    let obj = {
      'company': this.buid,
      'entity_name': this.attrForm.value.attrname.trim(),
      'entity_desc': this.attrForm.value.attrdesc.trim(),
      'status': true
    };

    this.segmentService.addNewBUEntity(obj).subscribe(data => {
      if (data.status){
        this.toastrService.success('Entity added successfully');
        this.router.navigateByUrl('/corporate/bu-entities');
      } else {
        this.toastrService.error(data.message);
      }
      this.ngxSpinnerService.hide();

    }, error => {
      this.toastrService.error(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  #########  Update Attribute Data for BU ##############  //

  updateBUAttribute(){

  }
}
