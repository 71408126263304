import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BaseserviceService } from '../baseservice.service';
import { catchError,map,} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {Resolve,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EinsteinReportsService implements Resolve<any> {
  public baseUrl;
  public sfDomainURL;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1,
    this.sfDomainURL = baseService.sfDomainURL
  }

  //Get SF Token for Einstein reports
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.http.get<any>(this.baseUrl + "api/auth/getSFToken", this.httpHeadersOptions())
      .pipe(
        map(data => {
            return data;
        }),
        catchError(this.handleError));
    }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

//tohandle the error
private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {

    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // return an observable with a user-facing error message
  return throwError('Something bad happened; please try again later.');
};
}
