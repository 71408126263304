import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BaseComponent } from "src/app/secondadmin/base.component";
import { ClientService } from "src/app/services/clients/client.service";
import { MasterService } from "src/app/services/master/master.service";

@Component({
  selector: "app-edit-subscription",
  templateUrl: "./edit-subscription.component.html",
  styleUrls: ["./edit-subscription.component.css"],
})
export class EditSubscriptionComponent extends BaseComponent implements OnInit {
  customer_id;
  updateSubscriptionForm: FormGroup;
  getCompanyobj: any = [];
  status: boolean = true;
  submitted = false;
  subs_id: string;
  selectedCompany: string;
  subscriptionObj:any;
  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientService,
    private masterService: MasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.customer_id = JSON.parse(
      sessionStorage.getItem("userData")
    ).admin_customer_id;
    if (this.customer_id == 0) this.getCompany();
    //get item details using id
    if (localStorage.getItem("edit_subscription_id")) {
      this.subs_id = localStorage.getItem("edit_subscription_id");
      this.selectedCompany = localStorage.getItem(
        "edit_subscription_company_id"
      );

      this.masterService
        .getSubscriptionDataById(this.selectedCompany, this.subs_id)
        .subscribe((response) => {
          if (response.status) {
            this.status = false;
            this.subscriptionObj = response.data;
            this.dataBinding();
          }
        });
    }
    this.updateSubscriptionForm = this.formBuilder.group({
      title: ["", [Validators.required, Validators.maxLength(50)]],
      slug_title: ["", [Validators.maxLength(50), Validators.required]],
      subscription_type: ["monthly", Validators.required],
      allocate_point: ["",Validators.required],
      price: ["",Validators.required],
      gst_rate: ["",Validators.required],
      half_yearly_discount: [""],
      yearly_discount: [""],
      orderno: [""],
      selectedCompany: [
        this.customer_id == 0 ? "" : this.customer_id,
        Validators.required,
      ],
      self_referral_point:['',Validators.required],
      ds_referral_point:['',Validators.required]
    });
  }

  dataBinding() {
    this.updateSubscriptionForm = this.formBuilder.group({
      title: [this.subscriptionObj?.title, [Validators.required, Validators.maxLength(50)]],
      slug_title: [this.subscriptionObj?.slug_title, [Validators.maxLength(50), Validators.required]],
      subscription_type: [this.subscriptionObj?.subscription_type, Validators.required],
      allocate_point: [this.subscriptionObj?.allocate_point,Validators.required],
      price: [this.subscriptionObj?.price,Validators.required],
      gst_rate: [this.subscriptionObj?.gst_rate,Validators.required],
      half_yearly_discount: [this.subscriptionObj?.half_yearly_discount],
      yearly_discount: [this.subscriptionObj?.yearly_discount],
      orderno: [this.subscriptionObj?.orderno],
      selectedCompany: [
        this.selectedCompany,
        Validators.required,
      ],
      self_referral_point:[this.subscriptionObj?.self_referral_point,Validators.required],
      ds_referral_point:[this.subscriptionObj?.ds_referral_point,Validators.required]
    });
  }

  getCompany() {
    let self = this;
    this.clientsService.getBUCompanies().subscribe(
      (data) => {
        self.getCompanyobj = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  get f() {
    return this.updateSubscriptionForm.controls;
  }
  updateSubscriptionData() {
    this.submitted = true;
    if (this.updateSubscriptionForm.invalid) {
      return;
    }
    let { selectedCompany } = this.updateSubscriptionForm.value;
    this.masterService
      .updateSubscriptionData(selectedCompany,this.subs_id,this.updateSubscriptionForm.value)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toastrService.success("Subscription update successfully");
            this.router.navigateByUrl(`/corporate/subscription-list`);
          } else {
            this.toastrService.error(data.message);
          }
        },
        (error) => {
          this.toastrService.error(error.message);
        }
      );
  }
}
