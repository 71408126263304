<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness">Readiness Module</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/readiness-activity-list">Readiness Activity</a></li>
      <li class="breadcrumb-item active" aria-current="page">Update Readiness Activity</li>

    </ol>
  </nav>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <form class="form-horizontal" [formGroup]="addActivityForm" (ngSubmit)="onSubmit()">
        <div class="card">
          <div class="card-body">

            <div class="form-group" *ngIf="customer_id===0">

              <label for="exampleForm2">Company Name <span class="required">*</span></label>

              <select formControlName="selectedCompany" class="form-control formstyle" (change)='getParentList()'
                *ngIf="category_id == undefined" [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
                <option value="">Select Company Name</option>
                <ng-container *ngFor="let Obj of getCompanyobj">
                  <option *ngIf="Obj.dbstatus == true" value={{Obj.customer_id}}>
                    {{Obj.customer_company_name}}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="submitted && f.selectedCompany.errors" class="invalid-feedback">
                <div *ngIf="f.selectedCompany.errors.required">Company Name is required</div>
              </div>
            </div>
            <input *ngIf="customer_id!=0" type="hidden" formControlName="selectedCompany"
              [ngClass]="{ 'is-invalid': submitted && f.selectedCompany.errors }">
            <div class="form-group">
              <label for="parent_id">Parent Activity <span class="required">*</span></label>
              <select name="company" formControlName='parent_id' class="form-control formstyle" required
                [ngClass]="{ 'is-invalid': submitted && f.parent_id.errors }">
                <option value="" disabled hidden selected>Select Parent Activity</option>
                <option value="0">Root</option>
                <ng-container *ngFor="let userObj of parentList">
                  <option value={{userObj.id}}>
                    {{ userObj.title }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="submitted && f.parent_id.errors" class="invalid-feedback">
                <div *ngIf="f.parent_id.errors.required">Parent activity is
                  required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="title">Title <span class="required">*</span></label>
              <input type="text" class="form-control" name="title" formControlName="title"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="50" appSpace>
              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required || f.title.hasError('whitespace')">Title is required</div>
                <div *ngIf="f.title.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Description <span class="required">*</span></label>
              <textarea class="form-control text-area h-25" rows="5" maxlength="100" appSpace
                formControlName="description"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">Description is required</div>
                <div *ngIf="f.description.errors?.maxlength">
                  You reached to maxlength
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="weight">Weight <span class="required">*</span></label>
              <input type="number" class="form-control" name="weight" formControlName="weight"
                [ngClass]="{ 'is-invalid': submitted && f.weight.errors }">
              <div *ngIf="submitted && f.weight.errors" class="invalid-feedback">
                <div *ngIf="f.weight.errors.required">Weight is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="orderno">Order No. <span class="required">*</span></label>
              <input type="number" class="form-control" name="orderno" formControlName="orderno"
                [ngClass]="{ 'is-invalid': submitted && f.orderno.errors }">
              <div *ngIf="submitted && f.orderno.errors" class="invalid-feedback">
                <div *ngIf="f.orderno.errors.required">Order no. is required</div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <button class="btn btn-round btn-margo-action" type='submit'>Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
