import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master/master.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-block-ip-host',
  templateUrl: './block-ip-host.component.html',
  styleUrls: ['./block-ip-host.component.css']
})
export class BlockIpHostComponent extends BaseComponent implements OnInit, AfterViewInit {
  public blacklistedHostList: any = [];
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = 'asc';
  colname = "createdon";
  filter = '';
  currentUser;
  @ViewChild('searchText', { static: true }) searchText: ElementRef;

  constructor(private masterService: MasterService) { 
    super();
  }

  ngOnInit() {
    this.fetchBlacklistedhost();
    this.currentUser = JSON.parse(localStorage.getItem('userData'));
  }

  ngAfterViewInit(): void {
    this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, 'keyup')
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.skip = 0;
        this.cp = 1;
        this.ngxSpinnerService.show();
      }),
      switchMap(data => this.masterService.getBlacklistedhost(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value, this.filter))
    )
    .subscribe(response => {
      if(response.data.rows) {
        this.blacklistedHostList = response.data.rows;
        this.totalCount = response.data.total;
      }else {
        this.blacklistedHostList = [];
        this.totalCount = 0;
      }
      this.ngxSpinnerService.hide();
    });
  }

  fetchBlacklistedhost() {
    this.ngxSpinnerService.show();
    this.skip = (this.cp - 1) * this.limit;
    this.masterService.getBlacklistedhost(this.limit, this.skip, this.colname, this.ord, this.searchText.nativeElement.value, this.filter).subscribe(response => {
      this.ngxSpinnerService.hide();
      if(response.data.rows) {
        this.blacklistedHostList = response.data.rows;
        this.totalCount = response.data.total;
      }else {
        this.blacklistedHostList = [];
        this.totalCount = 0;
      }
    }, error => {
      this.toastrService.error('Something went wrong!');
      this.ngxSpinnerService.hide();
    })
  }

  sorting(col: string) {
    this.colname = col
    if (this.ord == 'asc') {
      this.ord = 'desc'
    } else {
      this.ord = 'asc'
    }
    this.fetchBlacklistedhost();
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.blacklistedHostList.find(element => {
      if (element.hostid === id) {
        return true;
      }
    });
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          let item = {
            "isactive" : !obj.isactive,
            "modifiedby" : this.currentUser.uid
          }
          this.ngxSpinnerService.show();
          this.masterService.updateBlacklistedhost(id, item).subscribe(data => {
            this.ngxSpinnerService.hide();
            this.toastrService.success("Host Status Changed Successfully ")
            this.fetchBlacklistedhost();
          });
        }
      }).catch((err) => {
      });
  }

  deleteHost(id) {
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.deleteBlacklistedhost(id).subscribe(data => {
            this.ngxSpinnerService.hide();
            this.toastrService.success("Host Status Deleted Successfully!")
            this.fetchBlacklistedhost();
          });
        }
      }).catch((err) => {
      });
  }

  editHost(id) {
    this.router.navigate(['/corporate/newblockiphost'], { queryParams: { hostid: id } });
  }

  fetchHostListByType(value) {
    this.filter = value;
    this.fetchBlacklistedhost();
  }

}
