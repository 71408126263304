import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ClientService } from 'src/app/services/clients/client.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CommonMessages } from 'src/app/shared/messages/messages.enum';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs'; 
@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html',
  styleUrls: ['./faq-category.component.css']
})
export class FaqCategoryComponent  extends BaseComponent implements OnInit {

  @ViewChild('searchText', { static: true }) searchText: ElementRef;
  companyID = 0;
  selectedCompany : any = '';
  currentUser;
  getCompanyobj: any = [];
  categoryObj: any = [];
  resCatTotal: number = 0;
  slCatPage = 1;
  slCatLimit = 10;
  slCatSkip = 0;
  slCatord = "asc";
  slCatColname = "name";
  constructor(private clientService: ClientService,
    private masterService:MasterService) {
    super();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('userData'));
    if (this.currentUser.admin_customer_id != '0') {
      this.companyID = this.currentUser.admin_customer_id;
      this.selectedCompany = this.companyID;
      this.getCategory();
    }
    if (this.currentUser.admin_customer_id == '0') this.getCompany();
  }
  getCompany() {
    this.clientService.getBUCompanies().subscribe(data => {
      this.getCompanyobj = data.data;
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  getCategory() {
    let searchTerm = this.searchText.nativeElement.value;
    this.slCatSkip = (this.slCatPage - 1) * this.slCatLimit;

    if (this.selectedCompany !== "") {
      this.ngxSpinnerService.show();

      this.masterService.getFaqCategoryList(this.selectedCompany,this.slCatLimit, this.slCatSkip, searchTerm,this.slCatColname,this.slCatord).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          if (data.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = data.data.rows }
          this.resCatTotal = data.data.total;
        } else {
          this.categoryObj = [];
          this.resCatTotal = 0;
        }
      }, error => { console.log(error);this.ngxSpinnerService.hide(); });
    } else {
      this.categoryObj = [];
      this.selectedCompany = "";
      this.resCatTotal = 0;
      this.ngxSpinnerService.hide();
    }
  }
  setOrder(value: string) {
    this.slCatColname = value
    if (this.slCatord == 'asc') {
      this.slCatord = 'desc'
    } else {
      this.slCatord = 'asc'
    } this.getCategory()
  }
  editPost(id:any) {
    localStorage.setItem("edit_category_id", id);
    localStorage.setItem("category_edit_company_id", this.selectedCompany);
    this.router.navigate(['/corporate/update-faq-category']);
  }
  updateStatus(event, id, status) {
    event.preventDefault();
    let obj = {
      isactive: (status == true) ? false : true,
      company:this.selectedCompany,
      id:id
    }
    this.confirmationDialogService.confirm('Please confirm', 'Are you sure you want to change the status To' + ((obj.isactive) ? ' Active?' : ' In Active?'), 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.updateFaqCategoryStatus(obj).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Updated Successfully");
              this.getCategory();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  delCategory(event, id) {
    event.preventDefault();
    // let obj = {
    //   is_delete: true,
    //   company:this.selectedCompany,
    //   cate_id:id
    // }
    this.confirmationDialogService.confirm('Please confirm', 'ARE YOU SURE YOU WANT TO DELETE ?', 'Ok', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.masterService.deleteFaqCategory(this.selectedCompany,id).subscribe(data => {
            if (data.status) {
              this.toastrService.success("Status Deleted Successfully");
              this.getCategory();
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          });
        }
      }).catch(() => console.log(CommonMessages.dialogDismissMessage));
  }
  ngAfterViewInit() {
    this.searchText.nativeElement.value = '';
    this.ngxSpinnerService.show();
    fromEvent(this.searchText.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.slCatSkip = 0;
          this.slCatPage = 1;
        }),

        switchMap(data => this.masterService.getFaqCategoryList(this.selectedCompany,this.slCatLimit, this.slCatSkip, this.searchText.nativeElement.value,this.slCatColname,this.slCatord))

      )
      .subscribe(res => {
        if (res.status) {
          if (res.data.rows == null) { this.categoryObj = [] }
          else { this.categoryObj = res.data.rows }
          this.resCatTotal = res.data.total;
        } else {
          this.categoryObj = [];
          this.resCatTotal = 0;
        }
        this.ngxSpinnerService.hide();
      },error => {
        this.categoryObj = [];
        this.resCatTotal = 0;
        this.ngxSpinnerService.hide();
      });
  }

}
