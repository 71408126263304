<div class="app-content content">



  <div class="content-wrapper">
    <div class="container mt-5 mt-md-3">
      <div class="row">
        <div class="col-12 col-xl-9">
          <div class="card">
            <div class="card-content">
              <div class="card-header">
                <h4 class="mb-0">My Profile</h4>
              </div>
              
              <div class="card-body">
                <div class="row">
                <div class="col-md-4 text-center">
                 
  
                  <div class="media d-flex">
                    <div class="media-body additProImg">
                      <img src="{{userProfileDetails?.photo}}" (error)="setValue($event)">
                    </div>
                  </div>
                </div>
  
                <div class="col-md-8 mt-2 mt-md-0">
                  <h4 class="font-weight-bold" *ngIf="userProfileDetails">{{userProfileDetails?.admin_fname}} {{userProfileDetails?.admin_lname}}</h4>
                  <div>{{userProfileDetails?.title}}</div>
                  <p *ngIf="userProfileDetails && userData.role_id != 100" class="mt-4"><a [routerLink]="['/myprofile/editprofile']" class="card-link">
                    <i class="fa fa-pencil-square blue-dr-icon" aria-hidden="true"></i> Edit Profile</a>
                  </p>

                  <div class="row mt-1 mb-2" *ngIf="userProfileDetails?.email_primary">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Email</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{userProfileDetails?.email_primary}}</div>
                  </div>
                  <div class="row mb-2" *ngIf="userProfileDetails?.mobile_phone">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Mobile Number</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{userProfileDetails?.mobile_phone}}</div>
                  </div>
                  <div class="row  mb-2" *ngIf="userProfileDetails?.home_phone">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Home Number</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{userProfileDetails?.home_phone}}</div>
                  </div>
                  <!-- <div class="row  mb-1" *ngIf="userProfileDetails.signature">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Signature</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{userProfileDetails.signature}}</div>
                  </div> -->
                  <div class="row mb-2" *ngIf="userProfileDetails?.birthdate" >
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Birth Date</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{userProfileDetails?.birthdate | date:'mediumDate'}}</div>
                  </div>

                  <div class="row mb-2" *ngIf="userProfileDetails?.company_name" >
                    <div class="col-md-3 col-sm-4 font-weight-bold">
                      Company
                    </div>
                    <div class="col-md-9 col-sm-8">
                      {{userProfileDetails?.company_name}}
                    </div>
                  </div>
                  <div class="row mb-4" *ngIf="userProfileDetails">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Address</div>
                    <div class="col-8" *ngIf="userProfileDetails">
                      {{userProfileDetails.address1  +' '+ userProfileDetails.address2 | titlecase}} <br/>
                      {{userProfileDetails.city  | titlecase}} <br/>
                      {{userProfileDetails.state  | titlecase}}   <br/>
                      <span class="text-uppercase">{{userProfileDetails.country  | titlecase}}</span>
                      - {{userProfileDetails.zipcode}} <br/>
                    </div>
                  </div>

                  <div class="row  mt-3"  *ngIf="userProfileDetails?.facebook_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-facebook-square txt-color-fb socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.facebook.com/{{userProfileDetails?.facebook_id }}"
                  target="_blank">{{userProfileDetails?.facebook_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.twitter_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-twitter-square txt-color-twt socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://twitter.com/{{userProfileDetails?.twitter_id }}"
                  target="_blank">{{userProfileDetails?.twitter_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.instagram_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-instagram txt-color-insta socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.instagram.com/{{userProfileDetails?.instagram_id }}/"
                  target="_blank">{{userProfileDetails?.instagram_id}}</a></div>
                  </div>

                  <div class="row  mt-3" *ngIf="userProfileDetails?.linkedin_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-linkedin txt-color-linkIn socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.linkedin.com/in/{{userProfileDetails?.linkedin_id }}/"
                      target="_blank">{{userProfileDetails?.linkedin_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.instagram_biz_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-instagram txt-color-insta socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://business.instagram.com//in/{{userProfileDetails?.instagram_biz_id }}/"
                      target="_blank">{{userProfileDetails?.instagram_biz_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.youtube_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-youtube txt-color-youtube socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.youtube.com/in/{{userProfileDetails?.youtube_id}}/"
                target="_blank">{{userProfileDetails?.youtube_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.google_my_biz_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-google-plus red-ggl-icon socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.google.com/intl/en_in/business/{{userProfileDetails?.google_my_biz_id }}/"
                  target="_blank">{{userProfileDetails?.google_my_biz_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.sina_wiebo_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-weibo red-sw-icon socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.weibo.com/{{userProfileDetails?.sina_wiebo_id }}/"
                      target="_blank">{{userProfileDetails?.sina_wiebo_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.we_chat_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-wechat txt-color-wechat socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.wechat.com/en{{userProfileDetails?.we_chat_id }}/"
                target="_blank">{{userProfileDetails?.we_chat_id}}</a></div>
                  </div>
                  <div class="row  mt-3" *ngIf="userProfileDetails?.whatsapp_id">
                    <div class="col-2 col-md-3 col-sm-4"><i class="fa fa-whatsapp txt-color-whatsaap socialIconSize"
                        aria-hidden="true"></i></div>
                    <div class="col-10 col-md-9 col-sm-8">
                      <a href="https://www.whatsapp.com/{{userProfileDetails?.whatsapp_id}}/"
                      target="_blank">{{userProfileDetails?.whatsapp_id}}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

   
  </div>
</div>