<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/corporate/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/corporate/reports">Reports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Message Usage</li>
    </ol>
  </nav>
  <!-- <div>
    <span class="imageWrapper">  <img class="customimg"
          src="../../../assets/custom/images/export.png"></span>
  </div> -->
</div>

<div class="container mt-1 themeTblReso">
  <div class="row">

    <div class="col-md-3 mt-2" *ngIf="isHeadquater">
      <select name="company" class="form-control formstyle" [(ngModel)]="companyID" (change)="fetchCustomerData()">
        <option selected="selected" value=0>Select Company</option>
        <option *ngFor="let userObj of companyList" [value]="userObj.customer_id">{{userObj.customer_company_name}}</option>
      </select>
    </div>
    <div class="col-md-3 mt-2">
      <select name="month" class="form-control formstyle" [(ngModel)]="selectedMonth" (change)="fetchCustomerData()">
        <option value=''>Select Month</option>
        <option *ngFor="let ele of monthsArr" [value]="ele">{{ele}}</option>
      </select>
    </div>
    <!-- <div class="col-md-6 mt-2">
      <a (click)="exportReportCsv()" class="csvBtnDn"> <img class="customimg_2" src="../../../assets/custom/images/export.png" style="height: 45px;"> </a>
    </div> -->
    <!-- <div class="col-sm-2">
      <span style="margin-left: 30px;"></span>
      <button class="btn btn-margo-action ml-0 form-control formstyle" (click)="generateReport()" style="align-items: center;">Search</button>
    </div> -->
  </div>

  

  <div class="tblPrt mt-4" *ngIf="showResult">
    
    <div *ngIf="selectedMonth != ''">Report Date : {{dateArr[0]}} to {{dateArr[1]}}</div>
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0" *ngIf="selectedMonth != '' && companyID != ''">
      <tr>
        <th>Report Item Name</th>
        <th>Item Value</th>
      </tr>
      <tr>
        <td>Opening Balance</td>

        <td>{{!!monthlyOpeningBalance?monthlyOpeningBalance:'0'}}</td>

      </tr>
      <tr>
        <td>Messages Consumed</td>
        <td>{{!!dataUsed?dataUsed:'0'}}</td>
      </tr>
      <tr>
        <td>Closing Balance </td>
        <td>{{!!closingBalance ? closingBalance : '0'}}</td>
      </tr>

    </table>


    <div class="alert alert-light" *ngIf="selectedMonth == '' || companyID == ''">
      Kindly select Company and Month for fetching the report.
    </div>

  </div>
  <div class="tblPrt" *ngIf="!showResult">
    <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td colspan="5">No results available</td>                            
      </tr>  
    </table>
  </div>

