<div class="container mt-2">
  <h5 class="font-weight-bold mt-4 mb-4">Data Extension Mapping</h5>
  <form  class="form-horizontal mt-1" [formGroup]="dataextensionform">
    <div class="row">
      <div class="col-md-10 col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="mt-1">
<!--              <div class="row">-->
<!--                <div class="col-3">-->
<!--                  <label for="extension" class="position-relative pr-2">Extension ID/ Name <span style="color: red">*</span></label>-->
<!--                </div>-->
<!--                <div class="col-6">-->
<!--                  <select formControlName="dataExtension" autocomplete="off" class="form-control formstyle "-->
<!--                          (change)="selectDataExtensionFromDropdown($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.dataExtension.errors }">-->
<!--                    <option value=""><&#45;&#45; Select Data Extension &ndash;&gt;</option>-->
<!--                    <option *ngFor="let dt of mcDataExtension" value="{{dt.id}}">{{dt.name}}</option>-->
<!--                  </select>-->
<!--                  <div *ngIf="submitted && f.dataExtension.errors" class="invalid-feedback">-->
<!--                    <div *ngIf="f.dataExtension.errors.required">Data Extension is required</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div class="row mt-3 " *ngIf="mcMappedData.length > 0">
                <div class="col-12">
                <div class="tblPrt ">
                  <table class="mt-1 bdrStCustom ml-6" width="100%" cellspacing="0" cellpadding="0">
                    <tr>
                      <th >Extension Name <i class="fa"></i></th>
                      <th >Attribute Name</th>
                      <th >MC Attribute Name</th>
                    </tr>
                    <tr *ngFor="let obj of mcMappedData">
                      <td >{{obj.extention_name}}</td>
                      <td >{{obj.margo_attribute_name}}</td>
                      <td>{{obj.mc_attribute_name}}</td>
                    </tr>

                  </table>
                </div>
                </div>

              </div>

            </div>

          </div>

          <div class="card-footer text-center mt-1" *ngIf="mcMappedData.length > 0">
            <div class="my_class text-center" *ngIf="isWrite" >
              <button type="submit" class="custbtn"  (click)="submit()" >Update</button>
              <button type="submit" class="custbtn"  (click)="goToMcHome()" >Home</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
